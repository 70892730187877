import * as Sentry from "@sentry/react";
import Router from "next/router";
import { routes } from "route-configs";
import { logToSentry } from "utils/tracker";
import { toast } from "./toastr";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'err' implicitly has an 'any' type.
export const defaultErrorHandler = (err): void => {
  if (
    err &&
    err.status === 401 &&
    !Router.asPath.startsWith(routes.login.main)
  ) {
    Router.push("/logout");
    logToSentry("User was logged out", {
      reason: "unauthorized response (defaultErrorHandler)",
    });
  } else if (err && err.status === 400) {
    toast.failure(err.data.errors[0].detail);
  } else if (err && err.status === 403) {
    toast.failure("You do not have permission to perform this action");
  } else if (err && err.status === 404) {
    // do nothing
  } else if (err && err.status === 500) {
    toast.info("Server error. Something went wrong!");
  } else if (!err && err.request && !err.request.status) {
    toast.info(
      "No Internet connection. Please check your connection and try again."
    );
  } else {
    // notify user and console
    toast.failure("Sorry, an unknown error occurred.");

    // send to sentry
    Sentry.captureException(err, {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ logger: string; }' is not assi... Remove this comment to see the full error message
      logger: "javascriptDefaultErrorHandler",
    }); // This should now also be handled by the sentry middleware

    // TODO: Move this to redux middleware
    // Show sentry feedback dialog
    Sentry.showReportDialog();
  }
};
