import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { pulseConstants } from "common/inputs/Pulse/Pulse.constants";
import { pulsePrompts } from "common/inputs/Pulse/PulseTextAnswer";
import { TextViewer } from "common/misc/TextViewer/TextViewer";
import { Tooltip } from "common/overlay";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { messages } from "modules/users/[id]/progressReports/[id]/UserProgressReportDetails/UserProgressReportDetails.messages";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  comment: string | null;
  pulse: number | null;
};

export const CheckInPulseSection = ({
  className,
  comment,
  pulse,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const [privateMode] = usePrivateMode();
  const isValidPulse = pulse && pulse > 0 && pulse < 6;
  if (privateMode) return null;

  return (
    <section className={twClass("space-y-4", className)}>
      <div>
        <h4 className="mt-0 flex items-center gap-1">
          <FormattedMessage defaultMessage="Pulse" id="pulse" />
          <Tooltip
            content={intl.formatMessage({
              defaultMessage:
                "This section of your Reflections will only be visible to you and your manager.",
              id: "zMfU3+",
            })}
          >
            <Icon name="lock" size="lg" type="outlined" />
          </Tooltip>
        </h4>
      </div>

      <div className="space-y-2 divide-y divide-slate-200 rounded border py-2 bg-white border-slate-200">
        <div className="flex items-center gap-2.5 px-3">
          <span>{intl.formatMessage(messages.pulseAnswer)}:</span>
          {isValidPulse && (
            <span className="space-x-1">
              <span>{pulseConstants[pulse]!.emoji}</span>
              <span>{pulse}</span>
            </span>
          )}
        </div>
        <div className="px-3 pt-2">
          {isValidPulse && isSet(comment) && (
            <h5 className="my-0">{intl.formatMessage(pulsePrompts[pulse]!)}</h5>
          )}
          <TextViewer
            className="grow overflow-auto border-none p-0"
            html={comment}
            placeholderHtml={intl.formatMessage(messages.pulseEmptyState)}
          />
        </div>
      </div>
    </section>
  );
};
