import { Dayjs } from "dayjs";
import { useIntl } from "react-intl";
import { Month } from "../Targets/TargetList/TargetList.types";

/**
 * generates a list of 12 consecutive months, starting from the start date.
 *
 * @param startDate
 */
export const useKpiTargetMonths = (startDate: Dayjs): Month[] => {
  const intl = useIntl();

  const months: Month[] = [];
  let date = startDate;

  for (let i = 1; i <= 12; i++) {
    months.push({
      date,
      id: i,
      label: date.locale(intl.locale).format("MMM"),
    });

    date = date.add(1, "months");
  }

  return months;
};
