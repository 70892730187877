import { defineMessages, useIntl } from "react-intl";
import { roles, UserRole } from "constants/roles";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";

type Option = {
  label: string;
  value: UserRole;
};

type Hook = Option[];

export const roleNames: {
  [key: string]: {
    defaultMessage: string;
    id: string;
  };
} = defineMessages({
  [roles.admin]: {
    defaultMessage: "Admin",
    id: "settings:user:role:admin",
  },
  [roles.standard]: {
    defaultMessage: "Standard",
    id: "settings:user:role:standard",
  },
  [roles.superAdmin]: {
    defaultMessage: "Superadmin",
    id: "settings:user:role:superadmin",
  },
  [roles.viewOnly]: {
    defaultMessage: "View-only",
    id: "settings:user:role:viewonly",
  },
});

export const useRoleOptions = (initialRole?: string): Hook => {
  const intl = useIntl();
  const company = useCompany();
  const currentUser = useCurrentUser();
  const sessionUserRole = currentUser?.role || "";
  const options: Option[] = [
    {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      label: intl.formatMessage(roleNames[roles.standard]),
      value: roles.standard,
    },
  ];

  if (initialRole === roles.admin || sessionUserRole === roles.superAdmin) {
    options.push({
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      label: intl.formatMessage(roleNames[roles.admin]),
      value: roles.admin,
    });
  }

  if (
    sessionUserRole === roles.superAdmin ||
    initialRole === roles.superAdmin
  ) {
    options.push({
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      label: intl.formatMessage(roleNames[roles.superAdmin]),
      value: roles.superAdmin,
    });
  }
  if (company.viewOnlyLicenses > 0) {
    options.push({
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      label: intl.formatMessage(roleNames[roles.viewOnly]),
      value: roles.viewOnly,
    });
  }
  return options;
};
