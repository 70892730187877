import { createContext, useContext } from "react";

/**
 * Src: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export const createNonNullableCtx = <A extends object | null>() => {
  const ctx = createContext<A | undefined>(undefined);
  const useCtx = () => {
    const c = useContext(ctx);
    if (c === undefined)
      throw new Error("useCtx must be inside a Provider with a value");
    return c;
  };
  const useNullableCtx = () => useContext(ctx);
  return [useCtx, ctx.Provider, useNullableCtx] as const; // 'as const' makes TypeScript infer a tuple
};
