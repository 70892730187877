import { FieldValidator, useField } from "formik";
import { camelCase, isEmpty } from "lodash";
import zxcvbn from "zxcvbn";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { Input } from "common/inputs/Input/Input";
import { useToggle } from "hooks/useToggle/useToggle";
import { PasswordStrength } from "./PasswordStrength/PasswordStrength";

interface Props extends React.ComponentProps<typeof Input> {
  name: string;
  showStrength?: boolean;
  showToggle?: boolean;
  validate?: FieldValidator;
}

export const PasswordField = ({
  name,
  validate,
  showStrength,
  ...props
}: Props): JSX.Element => {
  const [hidden, toggleHidden] = useToggle(true);
  const [field, meta, helpers] = useField({ name, validate });
  return (
    <>
      <Input
        data-cy={props["data-cy"] || camelCase(props.label)}
        data-testid={props["data-testid"] ?? camelCase(props.label)}
        errorText={meta.touched && meta.error}
        field={field}
        id={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
        suffix={
          <IconButton
            name={hidden ? "visibility" : "visibility_off"}
            iconType="outlined"
            onClick={toggleHidden}
            size="lg"
          />
        }
        // It's okay to spread here, since the component is type safe. Not doing so here would cause a lot of duplication.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        type={hidden ? "password" : "text"}
      />
      <Show when={showStrength && (meta.touched || !isEmpty(field.value))}>
        <PasswordStrength
          password={zxcvbn(field.value ?? "")}
          setError={helpers.setError}
        />
      </Show>
    </>
  );
};
