import React, { ReactNode } from "react";
import { ErrorMessage } from "common/placeholders/ErrorMessage/ErrorMessage";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
  image?: ReactNode;
};

/**
 * a page placeholders that spans the entire available space. by default, it renders:
 * ```
 * <div class="w-full h-full flex flex-col items-center justify-center">
 *   {empty okr image}
 *   <span>Something went wrong. Please contact...</span>
 * </div>
 * ```
 */
export const PagePlaceholder = ({
  children = <ErrorMessage />,
  className,
}: Props): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex h-full w-full flex-col items-center justify-center",
        className
      )}
    >
      {children}
    </div>
  );
};
