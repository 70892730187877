import { CommentEventFormatters } from "./commentFormatters";
import { CommitEventFormatters } from "./commitFormatters";
import { CompanyEventFormatters } from "./companyFormatters";
import { GroupEventFormatters } from "./groupFormatters";
import { InitiativeEventFormatters } from "./initiativeFormatters";
import { KeyResultEventFormatters } from "./keyResultFormatters";
import { kpiFormatters } from "./kpiFormatters";
import { ObjectiveEventFormatters } from "./objectiveFormatters";
import { TagEventFormatters } from "./tagFormatters";
import { TimeframeEventFormatters } from "./timeframeFormatters";
import { EventFormatter } from "./types";
import { UserEventFormatters } from "./userFormatters";

export const eventFormatters: Record<string, EventFormatter> = {
  ...KeyResultEventFormatters,
  ...kpiFormatters,
  ...CommitEventFormatters,
  ...TagEventFormatters,
  ...InitiativeEventFormatters,
  ...ObjectiveEventFormatters,
  ...UserEventFormatters,
  ...GroupEventFormatters,
  ...TimeframeEventFormatters,
  ...CommentEventFormatters,
  ...CompanyEventFormatters,
};
