import React from "react";
import styled from "styled-components";
import { Icon } from "common/icons";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { colorTheme } from "constants/colorTheme";
import { MetricValue } from "constants/metric";
import * as Typography from "constants/typography";

const Container = styled.div<{ small: boolean; value: number }>`
  display: flex;
  height: 20px;
  align-items: center;
  ${(props) =>
    props.value !== 0 &&
    `
  color: ${
    props.color ||
    (props.value > 0 ? colorTheme.green[500] : colorTheme.red[500])
  };
  `};

  ${(props) =>
    props.small &&
    `
  i {
    font-size: 15px;
  }
  `};
`;

const Digit = styled.div<{ small: boolean }>`
  font-size: ${(props) =>
    props.small ? Typography.fontSizeSmall : Typography.fontSizeRegular};
  font-weight: ${Typography.fontWeightBold};
  margin-left: 1px;
`;

type ProgressDeltaProps = {
  className?: string;
  color?: string;
  "data-testid"?: string;
  metric?: MetricValue;
  small?: boolean;
  value?: number;
};

export const ProgressDelta = ({
  value = 0,
  small = false,
  color,
  metric,
  className,
  "data-testid": dataTestid,
}: ProgressDeltaProps): JSX.Element | null => {
  /*
      redux sync bug https://github.com/Perdoo/perdoo-api-v2/blob/master/frontend/src/app/components/ShortProgressUpdate.js#L57
      still uses redux so something it tries to render the ProgressDelta before the value has been loaded
      Fixing it here in-case it happens in other parts of our app
     */
  if (!value) {
    return null;
  }

  if (value === 0) {
    return null;
  }
  const iconName = value > 0 ? "arrow_upward" : "arrow_downward";

  return (
    <Container
      className={className}
      color={color}
      data-testid={dataTestid}
      small={small}
      value={value}
    >
      <Icon name={iconName} size={small ? "md" : "xl"} />
      {value !== 0 && (
        <Digit className="truncate" small={small}>
          <FormattedMetric value={value} unit={metric} />
        </Digit>
      )}
    </Container>
  );
};
