import {
  CurrentUserFragment,
  ReportUserFragment,
  User,
  UserDefaultFragment,
  UserGoalsUpToDatePerTimeframeFragment,
  UserStatusFragment,
  UserStreakFragment,
} from "@graphql";
import { mockCompany_deprecated } from "mocks/entities/company";
import { mockGroup } from "mocks/entities/group";
import { aUser_WIP } from "mocks/factories/user";
import { UserStatuses } from "modules/users/[id]/UserProfile/UserProfile.constants";
import { unmapEdges } from "utils/mapEdges";
import { getRandomInt } from "utils/testsDummy";
import { Tuple } from "utils/Tuple";

// TODO: construct a better fragment
export type MockUser = CurrentUserFragment;

// TODO: use the User type provided by codegen and add the missing props.
/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockCurrentUser: MockUser = {
  __typename: "user",
  accountStatus: UserStatuses.Active,
  autoSlackChannel: null,
  avatar: "https://example.org/avatar01.png",
  canCreate: [],
  canDelete: true,
  canPatch: true,
  checkInDue: true,
  checkInHasGoals: true,
  checkInStreak: 0,
  company: mockCompany_deprecated,
  companyId: mockCompany_deprecated.id,
  createdDate: "2022-01-01T00:00Z",
  directReports: {
    __typename: "userConnection",
    edges: [],
  },
  email: "adam.smith@example.org",
  firstName: "Adam",
  fullName: "Adam Smith",
  goalsUpdateDue: 2,
  groupLeaderOf: { __typename: "groupConnection", edges: [] },
  groups: {
    __typename: "groupConnection",
    edges: unmapEdges([mockGroup], "groupEdge"),
  },
  id: "mock-current-user-001",
  intercomHash: null,
  isActive: true,
  jobPosition: "OG",
  language: "en",
  lastCheckInDate: "2022-01-01T00:00Z",
  lastLoginDate: "2022-01-01T00:00Z",
  lastName: "Smith",
  lastNotificationRead: "2022-01-01T00:00Z",
  latestCheckIn: null,
  manager: null,
  manualSlackChannel: null,
  notificationsEnabled: true,
  persona: null,
  role: "superadmin",
  slackEnabled: false,
  status: "Active",
};

/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockUsers: Tuple<MockUser, 4> = [
  {
    ...mockCurrentUser,
    avatar: null,
    email: "clarice.robel@example.org",
    firstName: "Clarice",
    fullName: "Clarice Robel",
    id: "mock-active-user-001",
    lastName: "Robel",
    role: "standard",
  },
  {
    ...mockCurrentUser,
    email: "otto.graf@example.org",
    firstName: "Otto",
    fullName: "Otto Graf",
    id: "mock-active-user-002",
    lastName: "Graf",
    role: "admin",
  },
  {
    ...mockCurrentUser,
    email: "quimby.ingmeen@example.org",
    firstName: "Quimby",
    fullName: "Quimby Ingmeen",
    id: "mock-active-user-003",
    lastName: "Ingmeen",
    role: "superadmin",
  },
  {
    ...mockCurrentUser,
    avatar: null,
    email: "stew.ng@example.org",
    firstName: "Stew",
    fullName: "Stew Ng",
    id: "mock-active-user-004",
    lastName: "Ng",
    role: "viewonly",
  },
];

type ReportUser = ReportUserFragment &
  UserStatusFragment &
  UserStreakFragment &
  UserGoalsUpToDatePerTimeframeFragment & {
    lastCheckInDate?: string | null;
  };
type MockReportUser = ApolloMock<Omit<ReportUser, "lastCheckInDate">> &
  Pick<ReportUser, "lastCheckInDate">;

/**
 * don't use directly, instead call the factory with these fields as arguments.
 * @example aUser(...DEFAULT_REPORT_USER)
 */
export const DEFAULT_REPORT_USER: MockReportUser = {
  __typename: "user",
  avatar: "https://example.org/avatar01.png",
  checkInStreak: 5,
  email: "mock-user-001@example.org",
  firstName: "Mock",
  fullName: "Mock User",
  goalsUpToDate: 0.75,
  id: "mock-user-000-OG",
  isActive: true,
  jobPosition: "OG",
  lastCheckInDate: "2022-01-01T00:00Z",
  lastName: "User",
} satisfies Partial<User>;

let reportUserIndex = 0;
/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockReportUserFactory = (
  count: number,
  params?: Partial<MockReportUser>
): MockReportUser[] => {
  return Array.from({ length: count }).map(() => {
    const userId = reportUserIndex.toString().padStart(4, "0");
    reportUserIndex++;
    const username = `mock-user-${userId}`;
    const uniqueParams: Partial<MockReportUser> = {
      checkInStreak: getRandomInt(5, 10),
      email: `${username}@example.org`,
      fullName: `Mock user ${userId}`,
      id: username,
    };
    return { ...DEFAULT_REPORT_USER, ...uniqueParams, ...params };
  });
};

/**
 * mock user in engagement report → best / worst updaters
 */
export const mockUpdater = aUser_WIP({
  ...DEFAULT_REPORT_USER,
  firstName: "Adam",
  fullName: "Adam Smith",
});

// we need to create the users here, so we can access their properties in tests
export const mockReportUsersWithStreak = mockReportUserFactory(6);
export const mockReportUsersNoStreak = mockReportUserFactory(3, {
  checkInStreak: 0,
  lastCheckInDate: null,
});
export const mockReportUsersLostStreak = mockReportUserFactory(3, {
  checkInStreak: 0,
  lastCheckInDate: "2015-01-01",
});

export const mockReportUsers = [
  ...mockReportUsersWithStreak,
  ...mockReportUsersNoStreak,
  ...mockReportUsersLostStreak,
];

export type MockGoalLead = UserDefaultFragment & { avatar: string };
export type MockGoalContributor = Omit<UserDefaultFragment, "email"> & {
  avatar: string | null;
};

/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockGoalLeadFactory = (count: number): MockGoalLead[] => {
  return Array.from({ length: count }).map((_, index) => {
    const userId = index.toString().padStart(4, "0");
    const username = `mock-user-${userId}`;
    return {
      __typename: "user",
      avatar: `https://example.org/${username}`,
      email: `${username}@example.org`,
      fullName: `Mock user ${userId}`,
      id: username,
    };
  });
};

export const mockRoadmapLeads = mockGoalLeadFactory(5) as Tuple<
  MockGoalLead,
  5
>;

export const mockObjectiveContributors = mockGoalLeadFactory(
  2
) as unknown as Tuple<MockGoalContributor, 2>;
