import { useState } from "react";
import { Cursor } from "common/table/types";

type Hook = {
  page: Cursor;
  resetPage: () => void;
  setPage: (p: Cursor) => void;
};

export const useReportPagination = (initialPage: Cursor): Hook => {
  const [page, setPage] = useState<Cursor>(initialPage);
  const resetPage = () => {
    setPage(initialPage);
  };

  return {
    page,
    resetPage,
    setPage,
  };
};
