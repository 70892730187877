import { Field } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { Label } from "common/inputs/Label/Label";
import { cadenceIntervalTypes } from "../../cadence/constants";
import { FormInput } from "../FormInput/FormInput";
import { FormSelect } from "../FormSelect/FormSelect";

const messages = defineMessages({
  cadenceNameLabel: {
    defaultMessage: "Name",
    id: "cadence:new:cadence:name:label",
  },
  cadenceResetEveryLabel: {
    defaultMessage: "Reset every",
    id: "cadence:new:cadence:reset_every:label",
  },
});

export const CustomCadenceForm = (): JSX.Element => {
  const intl = useIntl();
  return (
    <div>
      <Row key="row-1">
        <Col xs={12}>
          <Field
            component={FormInput}
            data-cy="customCadenceName"
            label={intl.formatMessage(messages.cadenceNameLabel)}
            name="custom.name"
          />
        </Col>
      </Row>
      <Row key="row-2">
        <Col xs={12}>
          <Label>{intl.formatMessage(messages.cadenceResetEveryLabel)}</Label>
        </Col>
      </Row>
      <Row key="row-3">
        <Col xs={6}>
          <Field
            component={FormInput}
            name="custom.intervalLength"
            type="number"
          />
        </Col>
        <Col xs={6}>
          <Field
            component={FormSelect}
            name="custom.intervalType"
            options={Object.values(cadenceIntervalTypes).map((value) => ({
              label: value,
              value,
            }))}
          />
        </Col>
      </Row>
    </div>
  );
};
