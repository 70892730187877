import React from "react";
import { AsanaTaskIcon } from "../AsanaTaskIcon/AsanaTaskIcon";

type Props = {
  task: {
    gid: string;
    name: string;
    permalinkUrl: string;
  };
};

export const AsanaTask = ({ task }: Props): JSX.Element => (
  <div className="flex items-center gap-2">
    <AsanaTaskIcon />
    <a href={task.permalinkUrl} rel="noreferrer" target="_blank">
      {task.name}
    </a>
  </div>
);
