import React, { ReactNode } from "react";
import { Avatar } from "common/avatar";
import { Icon } from "common/icons/Icon/Icon";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";

interface Props {
  avatarUrl?: string;
  children: ReactNode;
}

export const UserDropdown = ({ avatarUrl, children }: Props): JSX.Element => {
  return (
    <Dropdown
      button={
        <div className="flex items-center text-slate-500 hover:cursor-pointer">
          <Avatar url={avatarUrl} />
          <Icon name="arrow_drop_down" size="xl" />
        </div>
      }
      id="user-dropdown"
    >
      {children}
    </Dropdown>
  );
};
