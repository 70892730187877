import { isNil } from "lodash";
import React, { Component, MouseEventHandler, ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { onClickOutside } from "common/misc/onClickOutside/onClickOutside";
import { twClass } from "utils/twClass";
import { twMerge } from "utils/twMerge";

export const DROPDOWN_ROOT_ID = "dropdown-root";

const StyledMenu = styled.ul<
  Pick<Props, "hasShadow" | "isOpen" | "limitHeight" | "zIndex">
>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  padding: 5px 0;
  margin: 2px 0;
  font-size: 14px;
  list-style: none;

  > li > a {
    display: flex;
    align-items: center;
    padding: 3px 13px;
  }
`;

type Props = {
  children?: ReactNode;
  className?: string;
  hasShadow?: boolean;
  id?: string;
  isOpen: boolean;
  limitHeight?: boolean;
  onClose: MouseEventHandler<HTMLElement>;
  zIndex?: number;
};

class DropdownMenuDetails extends Component<Props> {
  public static defaultProps = {
    children: [],
    hasShadow: true,
    id: "",
    limitHeight: false,
    zIndex: 3000,
  };

  constructor(props: Props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);

    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!Element.prototype.matches)
      Element.prototype.matches =
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'msMatchesSelector' does not exist on typ... Remove this comment to see the full error message
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;

    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!Element.prototype.closest) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 's' implicitly has an 'any' type.
      Element.prototype.closest = (s) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let el = this;
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'this' is not assignable to param... Remove this comment to see the full error message
        if (!document.documentElement.contains(el)) return null;
        do {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'matches' does not exist on type 'Dropdow... Remove this comment to see the full error message
          if (el.matches(s)) return el;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'parentElement' does not exist on type 'D... Remove this comment to see the full error message
          el = el.parentElement || el.parentNode;
        } while ( // TODO: [no-unnecessary-condition] remove and fix
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          el !== null &&
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'nodeType' does not exist on type 'Dropdo... Remove this comment to see the full error message
          el.nodeType === 1
        );
        return null;
      };
    }
  }

  handleClickOutside(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { onClose, isOpen } = this.props;

    const target = e.target as HTMLElement;
    if (
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      target &&
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      target.closest &&
      !target.closest(".dropdown.open") &&
      isOpen
    ) {
      // This condition makes sure that this isn't fired when you click on the dropdown button itself
      // If this wasn't here, the onClose would fire twice, once from here, and once from the button itself,
      // so the dropdown would never close. e.stopPropagation is not a good idea here because then,
      // if you click on another dropdown button while this is open, this dropdown would close,
      // but the other one would not open. In other words, it would require two clicks to open
      // another dropdown if this dropdown was open
      onClose(e);
    }
  }

  render(): JSX.Element | null {
    const { className, zIndex, hasShadow, limitHeight, id, isOpen, children } =
      this.props;

    const dropdownRoot = document.getElementById(DROPDOWN_ROOT_ID);

    if (isNil(dropdownRoot)) {
      return null;
    }

    const menu = (
      <StyledMenu
        aria-labelledby={id}
        className={twClass(
          "dropdown-menu print:hidden",
          "rounded border bg-white border-slate-300",
          "min-w-42",
          {
            "max-h-64 overflow-auto": limitHeight,
            open: isOpen,
            "shadow-lg": hasShadow,
          },
          className
        )}
        data-testid={id}
        hasShadow={hasShadow}
        isOpen={isOpen}
        limitHeight={limitHeight}
        role="menu"
        zIndex={zIndex}
      >
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return React.cloneElement(child, {
            // @ts-expect-error ts-migrate(2339) FIXME: No overload matches this call.
            className: twMerge("text-slate-800", child.props.className),
          });
        })}
      </StyledMenu>
    );

    return ReactDOM.createPortal(menu, dropdownRoot);
  }
}

export const DropdownMenu = onClickOutside(DropdownMenuDetails);
