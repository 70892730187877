import { ProgressDriver } from "@graphql";
import React, { useMemo } from "react";
import { For } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectRoot } from "common/inputs/Select/SelectRoot/SelectRoot";
import { useSelect } from "common/inputs/Select/useSelect";
import { ObjectiveProgressDriver } from "common/objective/ObjectiveProgressDriver/ObjectiveProgressDriver";

type Option = {
  id: ProgressDriver;
};

const Select = makeSelect<Option>();

type SelectRootProps = React.ComponentProps<typeof SelectRoot>;

type Props = Omit<SelectRootProps, "children" | "onChange" | "value"> & {
  onChange?: (u: ProgressDriver | null) => void;
  value: ProgressDriver;
};

const options = [
  { id: ProgressDriver.AlignedObjectives },
  { id: ProgressDriver.KeyResults },
];

export const ProgressDriverSelect = ({
  onChange,
  value,
  ...selectRootProps
}: Props): JSX.Element => {
  const defaultItem = useMemo(() => ({ id: value }), [value]);
  const { selected, visibleOptions, handleSelect } = useSelect<Option>({
    defaultItem,
    options,
  });

  const handleChange = (option: Option | null) => {
    handleSelect(option);
    onChange?.(option?.id ?? null);
  };

  return (
    <Select.Root {...selectRootProps} onChange={handleChange} value={selected}>
      <Select.Trigger>
        <Select.InlineValue>
          <ObjectiveProgressDriver progressDriver={selected?.id} />
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown>
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <ObjectiveProgressDriver progressDriver={option.id} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
