import {
  UserAvatarFragment,
  UserDefaultFragment,
  UserJobFragment,
} from "@graphql";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { Clickable } from "common/navigation/Clickable";
import { ModalHeader } from "common/overlay/Modal/ModalHeader/ModalHeader";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useCompleteOneOnOneContext } from "../context/completeOneOnOneContext";
import {
  CompleteMeetingInput,
  OneOnOneCompleteDatePicker,
} from "./OneOnOneCompleteDatePicker";

type Props = {
  description?: string | null;
  handleCompleteMeeting: (input: CompleteMeetingInput) => Promise<{
    hasError: boolean;
  }>;
  otherUser?: UserDefaultFragment & UserJobFragment & UserAvatarFragment;
  seriesId: string;
};

export const OneOnOneCompleteFormHeader = ({
  otherUser,
  seriesId,
  description,
  handleCompleteMeeting,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { goToOneOnOneMeeting } = useModalRouter();

  const { query } = useRouter();
  const { mId } = query;
  const { meetings } = useCompleteOneOnOneContext();
  const activeMeeting = meetings.find(({ id }) => id === mId);

  const DescriptionButton = (
    <div className="flex min-w-0 items-center pl-2.5">
      <Show
        fallback={
          <Clickable
            data-cy="t1KbC61R5IXK2IYuyEly2"
            className="w-full"
            onClick={() => goToOneOnOneMeeting({ editSeries: true, seriesId })}
          >
            <span className="text-base font-normal text-blue-500">
              <FormattedMessage defaultMessage="Add description" id="JB4Jq1" />
            </span>
          </Clickable>
        }
        when={Boolean(description)}
      >
        <div
          className="flex-1 w-full text-base font-normal text-slate-800 whitespace-nowrap truncate"
          title={description ?? undefined}
        >
          {description}
        </div>
      </Show>
    </div>
  );

  const conferenceLink = activeMeeting?.gCalEvent?.conference?.link;

  return (
    <ModalHeader
      className="flex flex-row flex-nowrap"
      editVisible={false}
      entityId={otherUser?.id ?? ""}
      infoElement={DescriptionButton}
      subtext={
        <div className="flex items-center space-x-4">
          <OneOnOneCompleteDatePicker
            handleCompleteMeeting={handleCompleteMeeting}
          />
          {conferenceLink && (
            <Button
              className="h-10"
              href={conferenceLink}
              hrefTarget="_blank"
              icon="videocam"
              variant="ghost"
            >
              <FormattedMessage defaultMessage="Join video call" id="9/EkDf" />
            </Button>
          )}
        </div>
      }
      title={intl.formatMessage(
        { defaultMessage: "1:1 with {user}", id: "ga69UP" },
        { user: otherUser?.fullName }
      )}
    />
  );
};
