import classNames from "clsx";
import isEmpty from "lodash/isEmpty";
import React, { forwardRef, ReactNode } from "react";
import { Icon } from "common/icons/Icon/Icon";
import { PremiumBadge } from "common/inAppUpgrade/PremiumBadge/PremiumBadge";
import { Clickable } from "common/navigation/Clickable";
import { twClass } from "utils/twClass";

export enum TabSize {
  "base" = "py-2",
  "md" = "py-4",
  "sm" = "py-1",
}

interface Props {
  active?: boolean;
  children?: ReactNode;
  className?: string;
  "data-cy"?: string;
  external?: boolean;
  onClick?: () => void;
  showBadge?: boolean;
  size?: keyof typeof TabSize;
  subtitle?: string;
  subtitleClass?: string;
  /** @deprecated use {@link children} instead */
  title?: React.ReactNode;
  vertical?: boolean;
}

/**
 * ! do not use for page headers ! for these cases use {@link TabLink}, which render a proper link.
 */
export const Tab = forwardRef<HTMLButtonElement, Props>(
  (
    {
      active = false,
      children,
      className,
      external = false,
      onClick,
      showBadge,
      size = "md",
      subtitle = "",
      subtitleClass,
      title,
      vertical = false,
      "data-cy": dataCy,
    },
    ref
  ): JSX.Element => {
    return (
      <Clickable
        ref={ref}
        className={classNames(className, "cursor-pointer hover:text-blue-500", {
          "border-b-4 border-blue-500": !vertical && active,
          "border-b-4 border-white": !vertical && !active,
          "px-0": vertical,
          "text-blue-500": active,
          "text-slate-800": !active,
        })}
        data-cy={dataCy}
        onClick={onClick}
      >
        <div
          className={`m-0 flex items-center justify-between ${TabSize[size]}`}
        >
          <div className="flex flex-col font-semibold">
            <div className="flex items-center space-y-0.5 space-x-1">
              <span>{title}</span>
              {children}
              {showBadge && <PremiumBadge size="small" />}
              {external && <Icon name="open_in_new" />}
            </div>

            {vertical && !isEmpty(subtitle) && (
              <span
                className={twClass(
                  "mt-3 text-base text-slate-500",
                  subtitleClass
                )}
              >
                {subtitle}
              </span>
            )}
          </div>
          {vertical && (
            <Icon className="ml-2" name="keyboard_arrow_right" size="2xl" />
          )}
        </div>
      </Clickable>
    );
  }
);

Tab.displayName = "Tab";
