import {
  GoalUpdateCycle,
  namedOperations,
  useUpsertCadenceMutationMutation,
} from "@graphql";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { isNil, omit } from "lodash";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Button } from "common/buttons/Button/Button";
import { messages } from "common/cadence/messages";
import { CustomCadenceForm } from "common/form/CustomCadenceForm/CustomCadenceForm";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { SupportTip } from "common/support";
import { MODAL_TYPES } from "constants/index";
import {
  ADD_CADENCE,
  CHANGE_DEFAULT_GOAL_UPDATE_CYCLE,
} from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useGoalUpdateFrequencyOptions } from "hooks/useGoalUpdateFrequencyOptions/useGoalUpdateFrequencyOptions";
import { showModal } from "legacy/actions/actions";
import { handleErrors } from "utils/graphql/handleErrors";
import { track } from "utils/tracker";
import {
  cadenceIntervalTypes,
  cadencePresets,
  sortedCadencePresets,
} from "../../constants";
import { AddCadenceModalInfo } from "./AddCadenceModalInfo/AddCadenceModalInfo";

const customCadenceOption = { label: "Custom", value: "custom" };

type Values = {
  addTimeframes: boolean;
  custom: {
    intervalLength: number;
    intervalType: string;
    name: string;
  };
  goalUpdateCycle: string;
  preset: string;
};

const validationSchema = Yup.object().shape({
  custom: Yup.object().when("preset", {
    is: "custom",
    then: (schema) =>
      schema.shape({
        intervalLength: Yup.number()
          .typeError("Must be a number")
          .positive("Must be a positive number")
          .required("Required"),
        name: Yup.string().required("Required"),
      }),
  }),
  preset: Yup.string().required(),
});

const isCustomSelected = (preset: string) => {
  return preset === customCadenceOption.value;
};

type Props = {
  callback?: (cadence: { id: string } | null) => void;
  existingCadences: string[];
  onRequestClosed: () => void;
  open: boolean;
};

export const AddCadenceModal = ({
  onRequestClosed,
  open,
  existingCadences,
  callback,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const company = useCompany();
  const user = useCurrentUser();
  const [addCadence] = useUpsertCadenceMutationMutation({
    refetchQueries: [namedOperations.Query.getTimelineCadences],
  });

  const [whichSaveButtonClicked, setWhichSaveButtonClicked] = useState<
    "saveAndAddTimeframes" | "save" | null
  >(null);
  const goalUpdateFrequencyOptions = useGoalUpdateFrequencyOptions();

  const selectOptions = sortedCadencePresets().map((preset) => {
    const isexistingCadence = existingCadences.includes(preset.name);
    const label = isexistingCadence
      ? intl.formatMessage(messages.existingCadence, {
          cadenceName: preset.name,
        })
      : preset.name;
    return {
      disabled: isexistingCadence,
      label,
      value: preset.id,
    };
  });
  const onSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    const input = isCustomSelected(values.preset)
      ? {
          ...values.custom,
          goalUpdateCycle: values.goalUpdateCycle,
          tfFormatter: `${values.custom.name} {counter}`,
        }
      : omit(
          {
            goalUpdateCycle: values.goalUpdateCycle,
            ...cadencePresets[values.preset],
          },
          ["id", "description"]
        );
    const res = await addCadence({ variables: { input } });
    const newCadence = res.data?.upsertCadence?.cadence;
    if (!isNil(newCadence)) {
      callback?.(newCadence);
    }
    track(ADD_CADENCE);
    actions.setSubmitting(false);
    const { hasError } = handleErrors(res, res.data?.upsertCadence?.errors);
    if (hasError) return;
    if (input.goalUpdateCycle !== GoalUpdateCycle.Weekly) {
      track(CHANGE_DEFAULT_GOAL_UPDATE_CYCLE, {
        cadenceId: newCadence?.id,
        frequency: input.goalUpdateCycle,
        user: user?.id,
      });
    }
    if (values.addTimeframes) {
      dispatch(
        showModal(MODAL_TYPES.ADD_TIMEFRAME_MODAL, {
          initialCadence: newCadence,
        })
      );
    }
    onRequestClosed();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage(messages.addCadence)}
    >
      <Formik
        initialValues={{
          addTimeframes: false,
          custom: {
            intervalLength: 1,
            intervalType: cadenceIntervalTypes.WEEK,
            name: "Custom cadence",
          },
          goalUpdateCycle: "WEEKLY",
          preset:
            selectOptions.find((cadence) => !cadence.disabled)?.value ?? "",
        }}
        onSubmit={onSubmit}
        render={({ isSubmitting, submitForm, values, setFieldValue }) => (
          <Form>
            <div className="flex w-full">
              <div className="mr-2 w-[60%] border-r-[1px] pr-[30px] pt-[10px] border-slate-300">
                <Field
                  autoFocus
                  component={FormSelect}
                  defaultValue={selectOptions.find(
                    (cadence) => !cadence.disabled
                  )}
                  label={intl.formatMessage(messages.nameLabel)}
                  name="preset"
                  onChange={(selected: { label: string; value: string }) => {
                    setFieldValue("preset", selected.value);
                    const setDefaultGoalUpdateCycleFrequency = () => {
                      const selectedPreset = cadencePresets[selected.value];
                      if (
                        selectedPreset?.intervalType ===
                        cadenceIntervalTypes.YEAR
                      ) {
                        setFieldValue("goalUpdateCycle", "MONTHLY");
                      } else {
                        setFieldValue(
                          "goalUpdateCycle",
                          company.checkInFrequency
                        );
                      }
                    };
                    setDefaultGoalUpdateCycleFrequency();
                  }}
                  options={[
                    ...sortedCadencePresets().map((preset) => ({
                      label: preset.name,
                      value: preset.id,
                    })),
                    customCadenceOption,
                  ]}
                />

                {isCustomSelected(values.preset) && <CustomCadenceForm />}
                <Field
                  component={FormSelect}
                  label={
                    <span className="flex gap-1">
                      {intl.formatMessage(messages.goalUpdateCycleLabel)}
                      <SupportTip
                        description={intl.formatMessage({
                          defaultMessage:
                            "How often is the lead prompted to update goals in this cadence",
                          id: "8unZ4p",
                        })}
                      />
                    </span>
                  }
                  name="goalUpdateCycle"
                  options={goalUpdateFrequencyOptions}
                  placeholder={intl.formatMessage(
                    messages.goalUpdateCycleLabel
                  )}
                />
              </div>
              <AddCadenceModalInfo />
            </div>
            <ModalFooter>
              <Button
                data-cy="saveCadenceAddTimeframes"
                disabled={isSubmitting}
                loading={
                  isSubmitting &&
                  whichSaveButtonClicked === "saveAndAddTimeframes"
                }
                onClick={() => {
                  setWhichSaveButtonClicked("saveAndAddTimeframes");
                  setFieldValue("addTimeframes", true);
                  submitForm();
                }}
                type="submit"
              >
                <FormattedMessage
                  defaultMessage="Save and add timeframes"
                  id="cadence:modal:button:save_and_create_tfs"
                />
              </Button>
              <Button
                data-cy="saveCadence"
                disabled={isSubmitting}
                loading={isSubmitting && whichSaveButtonClicked === "save"}
                onClick={() => {
                  setWhichSaveButtonClicked("save");
                  submitForm();
                }}
                type="submit"
              >
                {intl.formatMessage({
                  defaultMessage: "Save",
                  id: "global:save",
                })}
              </Button>
              <Button
                data-cy="zJeTSZcwTon-GF82_cBvk"
                onClick={onRequestClosed}
                type="button"
                variant="ghost"
              >
                {intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "global:cancel",
                })}
              </Button>
            </ModalFooter>
          </Form>
        )}
        validationSchema={validationSchema}
      />
    </Modal>
  );
};
