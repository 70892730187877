import {
  useGetCompanyFeaturesQuery,
  useGetCompanyForHookQuery,
} from "@graphql";
import { DEFAULT_COMPANY_GOAL_UPDATE_CYCLE as DEFAULT_COMPANY_CHECK_IN_FREQUENCY } from "config";
import { useIntl } from "react-intl";
import { auth } from "common/authHelper";
import {
  getIsOnFreeOrTrialPlan,
  getIsOnFreePlan,
  getIsOnTrialPlan,
} from "modules/company/Company/Company.utils";
import { toast } from "utils/toastr";

export type FeatureTier =
  | "FREE"
  | "TRIAL"
  | "PREMIUM"
  | "PROFESSIONAL"
  | "SUPREME"
  | "EXPIRED_TRIAL";

export interface CompanyQuery {
  currentCompany: CompanyNode;
}

export type UseCompanyHook = ReturnType<typeof useCompany>;

export const useCompany = () => {
  const intl = useIntl();
  const { data, loading, error } = useGetCompanyForHookQuery();

  if (error) {
    toast.failure("Error while fetching company.");
  }

  const company = data?.currentCompany;
  return {
    ...company,
    avatar:
      company?.avatar ||
      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    canPatch: company?.canPatch ?? false,
    checkInFrequency:
      company?.checkInFrequency ?? DEFAULT_COMPANY_CHECK_IN_FREQUENCY,
    id: company?.id,
    isOnFreeOrTrialPlan: company ? getIsOnFreeOrTrialPlan(company) : false,
    isOnFreePlan: company ? getIsOnFreePlan(company) : true,
    isOnTrialPlan: company ? getIsOnTrialPlan(company) : false,
    kpisEnabled: company?.kpisEnabled ?? false,
    loading,
    mpq: company?.mpq ?? 0,
    name:
      company?.name ||
      intl.formatMessage({
        defaultMessage: "Loading...",
        id: "global:loading",
      }),
    onlySendCheckInRemindersToLeads:
      company?.onlySendCheckInRemindersToLeads ?? false,
    timezone: company?.timezone ?? "UTC",
    ultimateGoal: company?.ultimateGoal || "",
    viewOnlyLicenses: company?.viewOnlyLicenses ?? 0,
  };
};

export const useCompanySettings = () => {
  const { data, error } = useGetCompanyForHookQuery();

  if (error && auth.loggedIn()) {
    toast.failure("Error while fetching company.");
  }

  const features = data?.currentCompany;
  return {
    checkInsEnabled: features?.checkInsEnabled ?? false,
    customReportGraphsEnabled: features?.customReportGraphsEnabled ?? false,
    flexibleObjectiveProgressDriver:
      features?.flexibleObjectiveProgressDriver ?? false,
    kpisEnabled: features?.kpisEnabled ?? false,
    kudosEnabled: features?.kudosEnabled ?? false,
    oneOnOnesEnabled: features?.oneOnOnesEnabled ?? false,
    performanceReviewsEnabled: features?.performanceReviewsEnabled ?? false,
    pillarsEnabled: features?.pillarsEnabled ?? false,
    privateOkrs: features?.privateOkrs ?? false,
    pulse: features?.pulse ?? false,
    reflections: features?.reflections ?? false,
    sharedGoals: features?.sharedGoals ?? false,
    stretchGoals: features?.stretchGoals ?? false,
    teamReportsGracePeriod: features?.teamReportsGracePeriod ?? 0,
    teamReviewsEnabled: features?.teamReflectionsEnabled ?? false,
    timelineEnabled: features?.timelineEnabled ?? false,
  };
};

export const useIsFlexibleObjectiveProgressEnabled = (): boolean => {
  const { data } = useGetCompanyFeaturesQuery();
  return data?.currentCompany.flexibleObjectiveProgressDriver ?? false;
};
