import React from "react";
import { FormattedMessage } from "react-intl";

export const helper = {
  contributors: (
    <FormattedMessage
      defaultMessage="Individual(s) responsible for helping the Lead complete a Result"
      id="result:forms:contributors:helper:text"
    />
  ),
  dueDate: (
    <FormattedMessage
      defaultMessage="Select a date the Result needs to be completed by"
      id="result:form:due_date:helper:text"
    />
  ),
  keyResult: (
    <FormattedMessage
      defaultMessage="Initiatives usually push progress of a Key Result. If this is the case select the Key Result this Initiative will be directly supporting."
      id="result:form:helper:text:key_result"
    />
  ),
  lead: (
    <FormattedMessage
      defaultMessage="The person responsible for updating the Result and ensuring the right work is being done to achieve it."
      id="result:form:lead:helper:info"
    />
  ),
  name: (
    <div>
      <p className="mt-2.5">
        <FormattedMessage
          defaultMessage="Need inspiration ? Check out {examples}"
          id="examples:learnAboutOkr"
          values={{
            examples: (
              <a
                href="https://support.perdoo.com/en/collections/2091196-learn-about-okr#okr-kpi-and-strategic-pillars-example-library"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="examples"
                  id="wizard:examples"
                />
              </a>
            ),
          }}
        />
      </p>
    </div>
  ),
  objective: (
    <FormattedMessage
      defaultMessage="Select the Objective that this Result defines success for"
      id="result:form:objective:helper:info"
    />
  ),
  startValue: (
    <FormattedMessage
      defaultMessage="Use your internal benchmark to accurately measure improvement. If you don't have a starting point yet, research for industry benchmarks or simply start from 0."
      id="result:form:start_value:helper:text"
    />
  ),
};
