import {
  ClosingNoteDefaultFragment,
  ClosingNoteQuestionsFragment,
  ClosingNoteRatingFragment,
  ClosingNoteStatusFragment,
  Maybe,
} from "@graphql";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { Rating } from "common/misc/Rating/Rating";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";

type Objective = {
  closingNote: Maybe<
    ClosingNoteDefaultFragment &
      ClosingNoteQuestionsFragment &
      ClosingNoteRatingFragment &
      ClosingNoteStatusFragment
  >;
};

type Props = {
  className?: string;
  objective: Objective;
};

export const ObjectiveClosingNotes = ({
  objective,
  className,
}: Props): JSX.Element => {
  const { closingNote } = objective;

  if (isNil(closingNote)) {
    return (
      <div className={className}>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={twClass("space-y-6", className)}
      data-testid="closingNotesTab"
    >
      <div className="flex items-center space-x-5">
        {closingNote.executionRating && (
          <Rating value={closingNote.executionRating} />
        )}
        <div className="font-semibold">
          <FormattedMessage
            defaultMessage="This objective was closed on {date}"
            id="closingNotes:closedObjectiveMessage"
            values={{
              date: <FormattedDate value={closingNote.createdDate} />,
            }}
          />
        </div>
      </div>

      <div className="space-y-6">
        {mapEdges(closingNote.questions.edges).map((question) => (
          <div
            key={question.id}
            className="space-y-2"
            data-testid="closingNoteQuestion"
          >
            <div className="font-semibold">{question.text}</div>
            <div className="border-l py-2 pl-4">
              {isSet(question.answer) && !isEmpty(question.answer) ? (
                <DangerousHTML html={question.answer} />
              ) : (
                <div className="text-slate-500">
                  <FormattedMessage
                    defaultMessage="No answer"
                    id="closingNotes:noAnswer"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
