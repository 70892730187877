import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
  htmlFor?: string;
};

export const Label = ({ children, className, htmlFor }: Props): JSX.Element => (
  <label className={twClass("text-sm", className)} htmlFor={htmlFor}>
    {children}
  </label>
);
