import { useRouter } from "next/router";
import { StrategicPillarModalType } from "hooks/useModalRouter/useModalRouter";
import { AddStrategicPillar } from "./AddStrategicPillar/AddStrategicPillar";
import { EditStrategicPillar } from "./EditStrategicPillar/EditStrategicPillar";

export const StrategicPillarModals = (): JSX.Element | null => {
  const { query } = useRouter();
  const { type, pId } = query;

  if (type === StrategicPillarModalType.ADD) {
    return <AddStrategicPillar open />;
  }
  if (type === StrategicPillarModalType.EDIT && pId) {
    return (
      <EditStrategicPillar id={pId as string} open />
    );
  }
  return null;
};
