import { OneOnOneMeetingItemTemplate } from "@graphql";
import { Derive } from "@shoooe/derive";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons";

type MeetingItemTemplate = Derive<
  OneOnOneMeetingItemTemplate,
  {
    canDelete: true;
    canPatch: true;
    createdBy: {
      fullName: true;
      id: true;
    };
    description: true;
    id: true;
    name: true;
    talkingPoints: true;
  }
>;

type Props = {
  template: MeetingItemTemplate;
};

export const OneOnOneMeetingItemTemplateDetails = ({
  template,
}: Props): JSX.Element => {
  return (
    <>
      <div className="flex flex-col">
        <span className="text-sm text-slate-500">
          <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
        </span>
        <span>
          {template.description ? template.description : "No description added"}
        </span>
      </div>
      <div>
        <span className="text-sm text-slate-500">
          <FormattedMessage defaultMessage="Talking Points" id="Xup/rQ" />
        </span>
        <div className="flex flex-col space-y-3 pt-3">
          {template.talkingPoints.map((talkingPoint, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="flex items-center space-x-2">
              <Icon
                className="rounded-full border p-1.5"
                name="forum"
                size="lg"
                type="round"
              />
              <span>{talkingPoint}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
