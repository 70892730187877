import React from "react";
import { Commit } from "../../commit/Commit/Commit";
import { EventTypes } from "../constants";
import { FormattedCommitEvent } from "../FormattedCommitEvent/FormattedCommitEvent";
import { EventFormatter } from "./types";

const { CommitCreated } = EventTypes;

export const CommitEventFormatters: Record<string, EventFormatter> = {
  [CommitCreated]: {
    component: FormattedCommitEvent,
    getComment: (attr, showDetails: boolean) => {
      const { commit } = attr;
      return commit ? (
        <Commit commit={commit} showDetails={showDetails} />
      ) : null;
    },
  },
};
