import { ProgressDriver } from "@graphql";
import { FormattedMessage } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import { GoalActivity } from "common/goalModal/GoalActivity/GoalActivity";
import { GoalActivityHeader } from "common/goalModal/GoalActivity/GoalActivityHeader/GoalActivityHeader";
import { GoalDisclosure } from "common/goalModal/GoalDisclosure/GoalDisclosure";
import { objectTypes } from "constants/objects";
import { twClass } from "utils/twClass";
import { AlignedObjectives } from "./AlignedObjectives/AlignedObjectives";
import { ObjectiveClosingNotes } from "./ObjectiveClosingNotes/ObjectiveClosingNotes";
import { ObjectiveDescription } from "./ObjectiveDescription/ObjectiveDescription";
import { ObjectiveResults } from "./ObjectiveResults/ObjectiveResults/ObjectiveResults";

export const ObjectiveSummary = () => {
  const objective = useObjectiveContext();
  return (
    <div className="pl-2">
      <Show when={objective.completed}>
        <GoalDisclosure
          data-testid="objectiveClosingNotes"
          title={
            <FormattedMessage
              defaultMessage="Closing notes"
              id="global:closingNotes"
            />
          }
        >
          <ObjectiveClosingNotes className="py-4" objective={objective} />
        </GoalDisclosure>
      </Show>

      <GoalDisclosure
        data-testid="objectiveDescriptionHeader"
        title={
          <FormattedMessage
            defaultMessage="Description"
            id="objective:forms:description:label"
          />
        }
      >
        <ObjectiveDescription />
      </GoalDisclosure>

      <div
        className={twClass("flex flex-col", {
          "flex-col-reverse	":
            objective.progressDriver === ProgressDriver.AlignedObjectives,
        })}
      >
        <ObjectiveResults />
        <AlignedObjectives />
      </div>

      <GoalDisclosure
        data-testid="objectiveActivity"
        title={<GoalActivityHeader />}
      >
        <GoalActivity
          entityId={objective.id}
          entityType={objectTypes.objective}
        />
      </GoalDisclosure>
    </div>
  );
};
