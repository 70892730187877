import React, { DependencyList, useEffect } from "react";
import { DefaultInfoView } from "../DefaultInfoView/DefaultInfoView";
import { HelpCenterContent, useHelpCenter } from "./useHelpCenter";

/**
 * This sets the page help center content if there's one specific to the
 * given page.
 * @note IMPORTANT: This is meant to be used only once per page.
 */
export const useHelpCenterContent = (
  content: HelpCenterContent,
  deps: DependencyList = []
): void => {
  const { setHelpCenter } = useHelpCenter();

  useEffect(() => {
    setHelpCenter(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    return () => {
      // TODO: this will be triggered when moving from timeframes to cadences
      // briefly, we should probably throttle the `setHelpCenter` function for something
      // like 500ms to avoid that.
      setHelpCenter({ element: <DefaultInfoView />, type: "default" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
