import React from "react";
import { TextButton } from "common/buttons";
import { For } from "common/controlFlow";
import { Dropdown } from "common/overlay/Dropdown";
import { CONTEXTUAL_SIDEBAR_SWITCH } from "constants/tracking";
import { PeopleManagementTabOption } from "hooks/useContextualSidebarTabs/useContextualSidebarTabs";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS } from "./PeopleManagementSidebarTabBar";

type Option = {
  id: PeopleManagementTabOption;
  label: React.ReactNode;
};

type Props = {
  handleTabChange: (newTab: PeopleManagementTabOption) => void;
  options: Option[];
  tab: PeopleManagementTabOption;
};

export const PeopleManagementSidebarDropdown = ({
  options,
  tab,
  handleTabChange,
}: Props): JSX.Element => {
  const changeTabHandler = (newTab: PeopleManagementTabOption) => () => {
    handleTabChange(newTab);
    track(CONTEXTUAL_SIDEBAR_SWITCH, {
      view: newTab,
    });
  };

  return (
    <div className="w-full border-b px-5">
      <Dropdown data-testid="sidebar-user-goals-kudos-dropdown">
        <Dropdown.Trigger>
          <TextButton
            className="px-0 text-base font-semibold text-slate-800"
            data-cy="sidebarDropdownButton"
            icon="menu"
            size="small"
            text={PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS[tab].label}
          />
        </Dropdown.Trigger>
        <Dropdown.Content className="space-y-1" placement="bottom-left">
          <For each={options}>
            {({ id, label }) => (
              <Dropdown.Item
                className={twClass("pt-1", {
                  "text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700":
                    tab === id,
                })}
                data-cy="SIfUfobVfUJRlDb3uj4Ql"
                onClick={changeTabHandler(id)}
                selected={tab === id}
              >
                {label}
              </Dropdown.Item>
            )}
          </For>
        </Dropdown.Content>
      </Dropdown>
    </div>
  );
};
