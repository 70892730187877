import { isEmpty } from "lodash";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'accu' implicitly has an 'any' type.
const convertFiltersToPath = (accu, curr) => {
  if (curr.selected) {
    if (curr.type === "range") {
      return {
        ...accu,
        [`${curr.filterName}_min`]: curr.selected[0],
        [`${curr.filterName}_max`]: curr.selected[1],
      };
    }
    const filterSelected = curr.selected
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selected' implicitly has an 'any' type.
      .map((selected) => selected.id)
      .join(",");
    if (filterSelected) {
      return {
        ...accu,
        [curr.filterName]: filterSelected,
      };
    }
  }
  return accu;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'exploreView' implicitly has an 'any' ty... Remove this comment to see the full error message
export const getFiltersPath = (exploreView) => {
  const result = exploreView
    ? Object.values(exploreView.filters).reduce(convertFiltersToPath, {})
    : {};

  if (exploreView.sort) {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    result.sort = exploreView.sort;
  }

  if (exploreView.searchQuery) {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    result.name__icontains = exploreView.searchQuery;
  }

  return !isEmpty(result) ? result : null;
};
