import React, { ReactNode } from "react";
import { Clickable } from "common/navigation/Clickable";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type Option<T> = {
  label?: ReactNode;
  pill?: string;
  tooltip?: string;
  value: T;
};

export type ButtonGroupOption<T> = Option<T>;

type Size = "md" | "lg";

interface Props<T> {
  className?: string;
  itemClass?: string;
  onChange: (selected: T) => void;
  options: Option<T>[];
  size?: Size;
  value: T;
}

export const ButtonGroup = <T extends string>({
  className,
  itemClass,
  onChange,
  options,
  value,
  size = "md",
}: Props<T>): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex rounded border bg-white border-slate-300",
        className
      )}
    >
      {options.map((option) => (
        <WithTooltip key={option.value} tooltip={option.tooltip}>
          <Clickable
            key={option.value}
            className={twClass(
              "relative w-full cursor-pointer text-slate-800 hover:text-blue-600 text-center",
              {
                "px-3 py-1 text-xs font-semibold": size === "md",
                "px-4 py-2 text-sm font-normal": size === "lg",
                "rounded ring-1 ring-blue-500": value === option.value,
              },
              itemClass
            )}
            data-cy="button-group-option"
            onClick={() => onChange(option.value)}
          >
            {isSet(option.pill) && (
              <div className="absolute -left-4 -top-3 rounded-full p-1 text-xs font-semibold leading-none text-white bg-green-500">
                {option.pill}
              </div>
            )}
            <div className="whitespace-nowrap">
              {option.label ?? option.value}
            </div>
          </Clickable>
        </WithTooltip>
      ))}
    </div>
  );
};
