import { gql } from "@apollo/client";
import { StatusBreakdownDefaultFieldsFragmentDoc } from "@graphql";

const GROUP_FRAGMENT = gql`
  fragment GroupFragment on group {
    id
    name
    archived
    avatar
    mission
    kpisEnabled
    slackChannel
    archivedDate
    cadence {
      id
    }
    members(isActive: true) {
      edges {
        node {
          id
          fullName
          avatar
        }
      }
    }
    parentGroups {
      edges {
        node {
          id
          name
          avatar
        }
      }
    }
    groupLeader {
      id
      fullName
      avatar
    }
    subGroups(archivedDate_Isnull: true) {
      totalCount
      edges {
        node {
          id
          avatar
          name
        }
      }
    }
  }
`;

export const ARCHIVE_GROUP_BULK_MUTATION = gql`
  mutation archiveGroupsBulkMutation($ids: [UUID!]!, $archived: Boolean!) {
    archiveGroupsBulk(ids: $ids, archived: $archived) {
      updatedIds
      failedIds
    }
  }
`;

export const BOOT_PRELOAD_GROUPS = gql`
  query getGroups {
    allGroups {
      edges {
        node {
          id
          name
          avatar
          archived
          archivedDate
          kpisEnabled
          canPatch
          cadence {
            id
          }
          groupLeader {
            id
          }
        }
      }
    }
  }
`;

export const DELETE_GROUP_BULK_MUTATION = gql`
  mutation deleteGroupsBulkMutation($ids: [UUID!]!) {
    deleteGroupsBulk(ids: $ids) {
      deletedIds
      failedIds
    }
  }
`;

export const DELETE_GROUP_MUTATION = gql`
  mutation deleteGroupMutation($input: DeleteGroupMutationInput!) {
    deleteGroup(input: $input) {
      group {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_GROUP_HEADER = gql`
  query getGroupHeader($id: UUID!, $objectiveStage: String) {
    group(id: $id) {
      ...GroupFragment
      canPatch
      objectives(stage: $objectiveStage, private: false) {
        totalCount
        averageProgress
        statusBreakdown {
          ...StatusBreakdownDefaultFields
        }
      }
      kpis(private: false, archived: false) {
        totalCount
        healthyCount
      }
    }
  }
  ${GROUP_FRAGMENT}
  ${StatusBreakdownDefaultFieldsFragmentDoc}
`;

export const GET_GROUP_KPI_COUNT = gql`
  query getGroupKPICount($id: UUID!) {
    group(id: $id) {
      id
      name
      kpis {
        totalCount
      }
    }
  }
`;

export const GET_GROUP_PROGRESS = gql`
  query getGroupProgress($id: UUID!) {
    group(id: $id) {
      id
      objectives(stage: "ACTIVE") {
        totalCount
        averageProgress
        statusBreakdown {
          ...StatusBreakdownDefaultFields
        }
      }
    }
  }
  ${StatusBreakdownDefaultFieldsFragmentDoc}
`;

export const GROUP_DEFAULT_FRAGMENT = gql`
  fragment GroupDefaultFields on group {
    id
    name
    email
    avatar
    mission
    customData
    slackChannel
    canPatch
    canDelete
    createdDate
    kpisEnabled
    archived
    archivedDate
    status {
      ...StatusBreakdownDefaultFields
      __typename @include(if: false)
    }
    kpiStatus
    progress
    cadence {
      id
    }
    subGroups {
      edges {
        node {
          id
        }
      }
    }
    parentGroups {
      edges {
        node {
          id
        }
      }
    }
    groupLeader {
      id
    }
    members {
      edges {
        node {
          id
        }
      }
    }
    objectives {
      edges {
        node {
          id
        }
      }
    }
    kpis {
      edges {
        node {
          id
        }
      }
    }
  }
  ${StatusBreakdownDefaultFieldsFragmentDoc}
`;

export const ORDERED_GROUPS_QUERY = gql`
  query getOrderedGroups(
    $idsExclude: String
    $includeCompany: Boolean = false
    $withNoParent: Boolean
    $members_Id_Contains: UUID
    $excludeArchived: Boolean
  ) {
    allGroups(
      idsExclude: $idsExclude
      members_Id_Contains: $members_Id_Contains
      orderBy: "name"
      parentGroups_Isnull: $withNoParent
      archivedDate_Isnull: $excludeArchived
    ) {
      edges {
        node {
          id
          name
          avatar
          archived
          canCreateKpis
          parentGroups {
            totalCount
          }
          subGroups(archivedDate_Isnull: true) {
            edges {
              node {
                id
                name
                avatar
                archived
                groupLeader {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
    }
    currentCompany @include(if: $includeCompany) {
      id
      name
      avatar
    }
  }
`;

export const UPSERT_GROUP_MUTATION = gql`
  mutation upsertGroupMutation($input: UpsertGroupMutationInput!) {
    upsertGroup(input: $input) {
      group {
        ...GroupFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${GROUP_FRAGMENT}
`;
