import { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { getCompleteLink, routes } from "route-configs";
import { GROUPS_TYPE_CHOICES } from "common/group/GroupSelector/GroupSelector.constants";

const messages = defineMessages({
  headerAll: {
    defaultMessage: "Company & all teams",
    id: "executive:reports:header:allCompanyAndTeams",
  },
  headerCompany: {
    defaultMessage: "Company only",
    id: "executive:reports:header:company",
  },
  headerTop: {
    defaultMessage: "Company & top-level teams",
    id: "executive:reports:header:topLevel",
  },
});

type Hook = {
  link: string;
  title: ReactNode;
};

// TODO: merge with useReportQueryParams
/**
 * gets the header and link based on the selected group type.
 * @see {@link GROUPS_TYPE_CHOICES}
 */
export const useReportGroupFilterHeader = (
  groupsType: string,
  groupId?: string
): Hook => {
  const intl = useIntl();
  const companyProfileLink = routes.company.okrs;
  switch (groupsType) {
    case GROUPS_TYPE_CHOICES.ALL:
    default:
      return {
        link: companyProfileLink,
        title: intl.formatMessage(messages.headerAll),
      };
    case GROUPS_TYPE_CHOICES.COMPANY:
      return {
        link: companyProfileLink,
        title: intl.formatMessage(messages.headerCompany),
      };
    case GROUPS_TYPE_CHOICES.GROUP: {
      const groupProfileLink = getCompleteLink(routes.groups.single.okrs, {
        id: groupId,
      }).absolutePath;

      return {
        link: groupProfileLink,
        title: "Group",
      };
    }
    case GROUPS_TYPE_CHOICES.TOP_LEVEL:
      return {
        link: companyProfileLink,
        title: intl.formatMessage(messages.headerTop),
      };
  }
};
