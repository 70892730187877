import { OneOnOneRepeatFrequency } from "@graphql";
import dayjs from "dayjs";
import { SelectSeries } from "common/select/SeriesSelect/SeriesSelect";
import { ONE_ON_ONE_TIME_INCREMENT } from "./constants";

export const oneOnOneDefaults = {
  defaultDueDate: (): string => dayjs().toISOString(),
  defaultMeetingTime: (): string =>
    dayjs().ceil("minutes", ONE_ON_ONE_TIME_INCREMENT).format("HH:mm:ss"),
  defaultRepeatFrequency: OneOnOneRepeatFrequency.Weekly,
};

export const getSeriesCounterPeer = (series: SelectSeries, userId?: string) => {
  if (userId === series.attendee.id) {
    return series.organizer;
  }
  return series.attendee;
};

export const getMeetingLocalTime = (
  dueDate: string | null,
  meetingTime: string | null,
  timezone: string
) => {
  if (!dueDate || !meetingTime) return null;
  const meetingDate = dayjs(dueDate);
  const meetingDateTime = dayjs.tz(
    `${meetingDate.format("YYYY-MM-DD")} ${meetingTime}`,
    timezone
  );
  return meetingDateTime.local();
};
