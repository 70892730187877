import { useGetAsanaProjectQuery } from "@graphql";
import { AsanaProject } from "./AsanaProject/AsanaProject";

type Props = {
  projectGid: string;
};

export const AsanaProjectByGid = ({
  projectGid,
}: Props): JSX.Element | null => {
  const { data: asanaTaskData } = useGetAsanaProjectQuery({
    variables: { gid: projectGid },
  });

  const project = asanaTaskData?.asanaProject ?? null;
  if (!project) {
    return null;
  }
  return <AsanaProject project={project} />;
};
