import { size } from "lodash";
import { getIsOnFreeOrTrialPlan } from "modules/company/Company/Company.utils";
import { useGetActiveUsersQuery, useGetCompanyQuery } from "../../@graphql";

type Hook = {
  freeUserLimit: number;
  userLimitReached: boolean;
};

export const useValidateUserLimit = (): Hook => {
  const { data: activeUsersData } = useGetActiveUsersQuery();
  const { data } = useGetCompanyQuery();

  if (!data) {
    return {
      freeUserLimit: 0,
      userLimitReached: false,
    };
  }

  const activeUsersCount = size(activeUsersData?.allUsers.edges);
  const freeUserLimit = data.currentCompany.freeUserLimit;

  const userLimitReached =
    activeUsersCount >= freeUserLimit &&
    getIsOnFreeOrTrialPlan(data.currentCompany);

  return {
    freeUserLimit,
    userLimitReached,
  };
};
