import { PerdooApiObjectiveStageChoices } from "@graphql";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonGroup } from "common/buttons/index";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { useObjectiveFormContext } from "common/objective/utils/useObjectiveFormContext/useObjectiveFormContext";
import { checkIfTimeframeIsSetToFuture } from "common/objective/utils/utils";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { supportLinks } from "modules/company/Company/Company.constants";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { messages } from "../ObjectiveForm.messages";
import { ObjectiveFormValues } from "../ObjectiveForm.types";

export const ObjectiveStages = (): JSX.Element => {
  const { timeframe } = useObjectiveFormContext();
  const {
    values: { stage },
    setFieldValue,
  } = useFormikContext<ObjectiveFormValues>();
  const draftOptions = [
    { label: "Active", value: PerdooApiObjectiveStageChoices.Active },
    {
      label: (
        <div className="flex items-center">
          <FormattedMessage {...messages.draft} />
          <Show
            when={isSet(timeframe) && checkIfTimeframeIsSetToFuture(timeframe)}
          >
            <Tooltip
              content={
                <div className="m-2 text-sm font-normal">
                  <FormattedMessage
                    {...messages.timeFrameInfo}
                    values={{
                      link: (
                        <AnchorNext
                          href={
                            supportLinks.privateOKRsConfigure.getStartedLink
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage {...messages.learnMore} />
                        </AnchorNext>
                      ),
                    }}
                  />
                </div>
              }
              data-placement="top"
              interactive
              spanClass="text-xs pd-10 font-normal"
            >
              <Icon className="m-0 pl-1" name="info_outline" size="sm" />
            </Tooltip>
          </Show>
        </div>
      ),
      value: PerdooApiObjectiveStageChoices.Draft,
    },
  ];

  return (
    <ButtonGroup
      className="h-10"
      itemClass={twClass(
        "px-4 py-2 h-full",
        "text-sm leading-5 text-slate-800 hover:text-blue-500",
        "font-medium font-sans flex items-center"
      )}
      onChange={(newStage: PerdooApiObjectiveStageChoices) => {
        setFieldValue("stage", newStage);
      }}
      options={draftOptions}
      value={stage as PerdooApiObjectiveStageChoices}
    />
  );
};
