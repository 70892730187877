import { useField } from "formik";
import { isArray } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { GroupMultiField } from "common/form/GroupMultiField/GroupMultiField";
import { Toggle } from "common/inputs/Toggle/Toggle";
import { messages } from "common/kpi/KpiForm/KpiForm.messages";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { OwnerSelect } from "common/select/OwnerSelect/OwnerSelect";
import { useCompanySettings } from "hooks/useCompany/useCompany";
import { FormControl } from "../FormControl/FormControl";

type Props = {
  helperText?: ReactNode;
  isShared: boolean;
  label?: string;
  name: string;
};

export const SharedGroupField = ({
  name,
  label,
  helperText,
  isShared,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);

  const { show: sharedGoalsAvailable } = useFeatureAccess(Feature.sharedGoals);

  const [sharedOwnershipEnabled, setSharedOwnershipEnabled] =
    useState(isShared);

  useEffect(() => {
    if (!sharedOwnershipEnabled) {
      if (isArray(field.value) && field.value.length > 1) {
        helpers.setValue(field.value[0]);
      }
    }
  }, [sharedOwnershipEnabled]);

  const toggleButton = (
    <div className="flex text-sm font-normal text-black">
      <Tooltip
        content={intl.formatMessage(messages.sharedOwnershipTooltip)}
        maxWidth="260px"
      >
        <div className="mr-2 ">
          {intl.formatMessage(messages.sharedOwnership)}
        </div>
      </Tooltip>
      <Toggle
        on={sharedOwnershipEnabled}
        onToggle={() => setSharedOwnershipEnabled(!sharedOwnershipEnabled)}
        size="small"
      />
    </div>
  );

  const hasMultipleOwners = isArray(field.value) && field.value.length > 1;

  if (!hasMultipleOwners && (!sharedOwnershipEnabled || !sharedGoalsAvailable)) {
    return (
      <FormControl
        fieldAddOn={sharedGoalsAvailable ? toggleButton : null}
        helperText={helperText}
        label={label}
        errorText={meta.error}
      >
        <OwnerSelect
          creatable
          hasError={!!meta.error}
          onChange={helpers.setValue}
          value={isArray(field.value) ? field.value[0] : field.value}
        />
      </FormControl>
    );
  }

  return (
    <GroupMultiField
      data-cy="fieldOwner"
      fieldAddOn={toggleButton}
      helperText={helperText}
      includeCompany
      label={label}
      name={name}
    />
  );
};
