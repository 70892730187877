import { ReactNode } from "react";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Icon } from "common/icons";
import { IconNameKey, IconType } from "common/icons/Icon/Icon.types";
import { Clickable } from "common/navigation/Clickable";
import { useObjectiveAlignmentModal } from "common/overlay/ObjectiveAlignmentModal/hooks/useObjectiveAlignmentModal";
import { TextOverflowTooltip } from "common/overlay/Tooltip/TextOverflowTooltip/TextOverflowTooltip";
import { twClass } from "utils/twClass";

interface Props {
  iconClassName?: string;
  iconName: IconNameKey;
  iconType?: IconType;
  showOverflowTooltip?: boolean;
  text?: ReactNode;
}

export const AlignmentOption = ({
  iconName,
  iconClassName,
  text,
  iconType,
  showOverflowTooltip = true,
}: Props) => {
  const objective = useObjectiveContext();
  const { openModal } = useObjectiveAlignmentModal();
  const disabled = objective.private || !objective.canPatch;
  return (
    <Clickable
      data-cy="openAlignmentModal"
      className={twClass(
        "px-2 w-full",
        "flex items-center text-slate-800 truncate overflow-hidden border border-transparent -ml-2",
        "h-10 rounded",
        "space-x-1",
        {
          "cursor-not-allowed": disabled,
          "hover:bg-white hover:border-slate-300 cursor-pointer": !disabled,
        }
      )}
      onClick={() => openModal({ objective })}
      disabled={disabled}
    >
      <Icon name={iconName} className={iconClassName} type={iconType} />
      <TextOverflowTooltip tooltip={showOverflowTooltip && text}>
        {text}
      </TextOverflowTooltip>
    </Clickable>
  );
};
