import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alignedObjective: {
    defaultMessage: "Aligned Objective",
    id: "objective:form:progressCalculation:alignedObjective",
  },
  alignedObjectiveHelper: {
    defaultMessage:
      "Aligned objectives will drive  progress of this OKR. The progress of this objective will be calculated & based on the objectives that are aligned to it.{learnMore}",
    id: "objective:form:progressCalculation:alignedObjective:helper",
  },
  keyResults: {
    defaultMessage: "Key Results",
    id: "objective:form:progressCalculation:keyResults",
  },
  keyResultsHelper: {
    defaultMessage:
      "Progress will be based on its Key Results. Objective's progress will be based on its Key Results only. Initiatives do not affect the Objective’s progress.{learnMore}",
    id: "objective:form:progressCalculation:keyResults:helper",
  },
  learnMore: {
    defaultMessage: "Learn more",
    id: "global:learnMore",
  },
  progressCalulation: {
    defaultMessage: "Progress Calculation",
    id: "objective:form:progressCalculation:title",
  },
});
