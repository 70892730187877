import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";
import { GoalSidebarItemContent } from "./GoalSidebarItemContent";
import { GoalSidebarItemTitle } from "./GoalSidebarItemTitle";

interface Props {
  children: ReactNode;
  className?: string;
}

export const GoalSidebarItem = ({ children, className }: Props) => {
  return (
    <div
      className={twClass("flex w-full min-h-9 items-center", className)}
      data-testid="goalSidebarItem"
    >
      {children}
    </div>
  );
};

GoalSidebarItem.Title = GoalSidebarItemTitle;
GoalSidebarItem.Content = GoalSidebarItemContent;
