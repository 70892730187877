import { CheckInFrequency, GoalUpdateCycle } from "@graphql";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const pjson = require("../package.json");

type EnvType =
  | "production"
  | "deploy-preview"
  | "stage"
  | "internal"
  | "development";

// using process.env over import.meta.env here for compatibility with ts-jest
// import not to destructure process.env, as else the vars won't be injected at build time
// injection configured in vite.config.ts
/* eslint-disable prefer-destructuring */
const NEXT_PUBLIC_CONTEXT = process.env.NEXT_PUBLIC_CONTEXT;
const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL;
const NEXT_PUBLIC_GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
const NEXT_PUBLIC_SLACK_CLIENT_ID = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID;
const NEXT_PUBLIC_CHARGEBEE_SITE = process.env.NEXT_PUBLIC_CHARGEBEE_SITE;
const NEXT_PUBLIC_REVIEW_ID = process.env.NEXT_PUBLIC_REVIEW_ID;
const NEXT_PUBLIC_POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
const NEXT_PUBLIC_INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
const NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
const NODE_ENV = process.env.NODE_ENV;
const MODE = process.env.MODE;
const NEXT_PUBLIC_AMCHARTS_KEY = process.env.NEXT_PUBLIC_AMCHARTS_KEY;
const PACKAGE_VERSION = pjson.version;
/* eslint-enable prefer-destructuring */

const getReviewBackendUrl = (): string | null =>
  NEXT_PUBLIC_REVIEW_ID
    ? `https://perdoo-pr-${NEXT_PUBLIC_REVIEW_ID}.herokuapp.com`
    : null;

const INTERCOM_SANDBOX_APP_ID = "u8l7wtcu";
export const CONTEXT: EnvType = (NEXT_PUBLIC_CONTEXT ||
  "development") as EnvType;
export const API_URL: string =
  getReviewBackendUrl() || (NEXT_PUBLIC_API_URL as string);
export const SLACK_CLIENT_ID: string = NEXT_PUBLIC_SLACK_CLIENT_ID as string;
export const RELEASE = `v${PACKAGE_VERSION}`;
export const REVIEW_ID = NEXT_PUBLIC_REVIEW_ID;
export const POSTHOG_KEY = NEXT_PUBLIC_POSTHOG_KEY;
export const GOOGLE_TAG_MANAGER_ID = NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
export const CHARGEBEE_SITE: string = NEXT_PUBLIC_CHARGEBEE_SITE as string;
export const IS_PRODUCTION = CONTEXT === "production";
export const IS_DEVELOPMENT = NODE_ENV === "development";
export const GOOGLE_CLIENT_ID: string = NEXT_PUBLIC_GOOGLE_CLIENT_ID as string;
export const AMCHARTS_KEY: string = NEXT_PUBLIC_AMCHARTS_KEY as string;
export const DEFAULT_COMPANY_GOAL_UPDATE_CYCLE = CheckInFrequency.Weekly;
export const INTERCOM_APP_ID =
  NEXT_PUBLIC_INTERCOM_APP_ID ?? INTERCOM_SANDBOX_APP_ID;

if (NODE_ENV === "production" || NODE_ENV === "development") {
  // eslint-disable-next-line no-console
  console.log("Environment variables:", {
    API_URL,
    CONTEXT,
    INTERCOM_APP_ID,
    IS_DEVELOPMENT,
    IS_PRODUCTION,
    MODE,
    NEXT_PUBLIC_AMCHARTS_KEY,
    NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_CHARGEBEE_SITE,
    NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    NEXT_PUBLIC_REVIEW_ID,
    NEXT_PUBLIC_SLACK_CLIENT_ID,
    NODE_ENV,
    PACKAGE_VERSION,
    POSTHOG_KEY,
    RELEASE,
  });
}
