import { isNil } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import {
  OneOnOneMeetingModalType,
  useModalRouter,
} from "hooks/useModalRouter/useModalRouter";
import { partialIdentifyUser } from "utils/tracker";
import {
  AddOneOnOneMeetingModal,
  OneOnOneCreatedCallback,
} from "./AddOneOnOneMeetingModal";
import { EditOneOnOneMeetingModal } from "./EditOneOnOneMeetingModal";
import { OneOnOneModal } from "./OneOnOneModal";
import { RescheduleOneOnOneMeetingModal } from "./RescheduleOneOnOneMeetingModal";

export const OneOnOneMeetingModalBase = (): JSX.Element | null => {
  const me = useCurrentUser();
  const { show: showOneOnOneMeetings } = useFeatureAccess(Feature.oneOnOnes);
  const { goToOneOnOneMeeting } = useModalRouter();
  const {
    query: {
      attendee,
      type,
      seriesId,
      mId,
      reschedule,
      redirectAfter,
      editSeries,
    },
  } = useRouter();

  useEffect(() => {
    if (me?.id) partialIdentifyUser(me.id, { hasCreated1on1s: true });
  }, [me?.id]);

  if (!me) {
    return null;
  }

  if (isNil(showOneOnOneMeetings) || !showOneOnOneMeetings) {
    return null;
  }

  if (type === OneOnOneMeetingModalType.ADD) {
    const onOneOnOneCreated: OneOnOneCreatedCallback | undefined = redirectAfter
      ? (oneOnOneSeries) => {
          goToOneOnOneMeeting({
            mId: oneOnOneSeries.nextMeeting?.id,
            seriesId: oneOnOneSeries.id,
            type: OneOnOneMeetingModalType.COMPLETE,
          });
        }
      : undefined;
    return (
      <AddOneOnOneMeetingModal
        attendee={attendee as string}
        onOneOnOneCreated={onOneOnOneCreated}
      />
    );
  }
  if (type === OneOnOneMeetingModalType.EDIT && seriesId) {
    return <EditOneOnOneMeetingModal seriesId={seriesId as string} />;
  }

  if (type === OneOnOneMeetingModalType.RESCHEDULE && seriesId && mId) {
    return (
      <RescheduleOneOnOneMeetingModal
        meetingId={mId as string}
        seriesId={seriesId as string}
      />
    );
  }

  if (type === OneOnOneMeetingModalType.COMPLETE && seriesId && mId) {
    return (
      <>
        {editSeries && (
          <EditOneOnOneMeetingModal seriesId={seriesId as string} />
        )}
        {reschedule && (
          <RescheduleOneOnOneMeetingModal
            meetingId={mId as string}
            seriesId={seriesId as string}
          />
        )}
        <OneOnOneModal
          meetingId={mId as string}
          seriesId={seriesId as string}
        />
      </>
    );
  }

  return null;
};
