import { UserDefaultFragment } from "@graphql";
import React from "react";
import { getLinkByType } from "route-configs";
import { AnchorNext } from "common/navigation";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  user: UserDefaultFragment;
};

/**
 * individual user displayed inside a report widget. renders full name with link to profile.
 */
export const ReportCardUserName = ({ className, user }: Props): JSX.Element => {
  const profileLink = getLinkByType("user", user.id);

  return (
    <AnchorNext
      className={twClass(
        "grow truncate text-slate-800 hover:text-blue-500",
        className
      )}
      href={profileLink}
    >
      {user.fullName}
    </AnchorNext>
  );
};
