import {
  PerformanceReviewDefaultFragment,
  PerformanceReviewUsersFragment,
} from "@graphql";
import { createNonNullableCtx } from "common/context/utils";

export type PerformanceReviewCardType = PerformanceReviewDefaultFragment &
  PerformanceReviewUsersFragment;

type CardContextType = NonNullable<PerformanceReviewCardType>;

export const [usePerformanceReviewCardContext, PerformanceReviewCardProvider] =
  createNonNullableCtx<CardContextType>();
