import { IntegrationSettingsFragment, useGetJiraIssueQuery } from "@graphql";
import React from "react";
import { AnchorNext } from "common/navigation";

interface Props {
  integration: IntegrationSettingsFragment;
  jiraIssueKey: string;
}

export const JiraIssue: React.FC<Props> = ({ jiraIssueKey, integration }) => {
  const { data } = useGetJiraIssueQuery({
    variables: { jiraIssueKey },
  });

  const issue = data?.jiraIssue;

  if (!issue) return null;
  return (
    <div key={issue.id} className="flex items-center">
      {issue.iconUrl && (
        // `<Image>` cannot be used here because Next.JS doesn't support the
        // image type.
        // eslint-disable-next-line @next/next/no-img-element
        <img alt="jira icon" height={14} src={issue.iconUrl} width={14} />
      )}
      <div className="mx-2 flex-shrink-0 text-xs text-slate-500">
        {issue.key}
      </div>
      <AnchorNext
        data-cy={`jira-issue-${jiraIssueKey}`}
        className="truncate"
        href={`${integration.settings?.url}/browse/${issue.key}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="truncate">{issue.summary}</div>
      </AnchorNext>
    </div>
  );
};
