import { PerdooApiObjectiveStageChoices } from "@graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getCompleteLink, routes } from "route-configs";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { InlineGoalUpdateFrequencySelect } from "common/inputs/InlineGoalUpdateFrequencySelect/InlineGoalUpdateFrequencySelect";
import { InlineObjectiveStageSelect } from "common/inputs/InlineObjectiveStageSelect/InlineObjectiveStageSelect";
import { InlineObjectiveVisibilitySelect } from "common/inputs/InlineObjectiveVisibilitySelect/InlineObjectiveVisibilitySelect";
import { InlineOwnerSelect } from "common/inputs/InlineOwnerSelect/InlineOwnerSelect";
import { InlineTagsSelect } from "common/inputs/InlineTagsSelect/InlineTagsSelect";
import { InlineTimeframeSelect } from "common/inputs/InlineTimeframeSelect/InlineTimeframeSelect";
import { InlineUserMultiSelect } from "common/inputs/InlineUserMultiSelect/InlineUserMultiSelect";
import { InlineUserSelect } from "common/inputs/InlineUserSelect/InlineUserSelect";
import { SelectLinkButton } from "common/misc/SelectLinkButton/SelectLinkButton";
import { SemiCircleProgress } from "common/misc/SemiCircleProgress/SemiCircleProgress";
import { AnchorNext } from "common/navigation";
import { useInlineObjectiveActions } from "common/objective/hooks/useInlineObjectiveActions/useInlineObjectiveActions";
import { ObjectiveStatus } from "common/objective/ObjectiveStatus/ObjectiveStatus";
import { ProgressDriverSelect } from "common/select/ProgressDriverSelect/ProgressDriverSelect";
import { SupportTip } from "common/support";
import { allRolesButViewonly } from "constants/roles";
import { useCompany, useCompanySettings } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { roleNames } from "hooks/useRoleOptions/useRoleOptions";
import { mapEdges } from "utils/mapEdges";
import { GoalSidebarItem } from "../../../../../goalModal/GoalSidebarItem/GoalSidebarItem";
import { ObjectiveAlignment } from "./ObjectiveAlignment/ObjectiveAlignment";
import { ObjectiveInitiativeProgress } from "./ObjectiveInitiativeProgress/ObjectiveInitiativeProgress";
import { ObjectivePrivacy } from "./ObjectivePrivacy";
import { messages } from "./ObjectiveSidebar.messages";
import { ObjectiveStageActions } from "./ObjectiveStageActions/ObjectiveStageActions";
import { ObjectiveTimeframeHeader } from "./ObjectiveTimeframe/ObjectiveTimeframeHeader";

export const ObjectiveSidebar = () => {
  const intl = useIntl();
  const company = useCompany();
  const currentUser = useCurrentUser();
  const objective = useObjectiveContext();
  const { flexibleObjectiveProgressDriver } = useCompanySettings();
  const { show: privateGoalsAvailable } = useFeatureAccess(
    Feature.privateGoals
  );
  const {
    setProgressDriver,
    setUpdateFrequency,
    setOwners,
    setStage,
    setVisibility,
    setLead,
    setTimeframe,
    setContributors,
    setTags,
  } = useInlineObjectiveActions(objective);
  const isAligned =
    !!objective.kpi || !!objective.strategicPillar || !!objective.parent;
  const contributors = useMemo(
    () => objective.contributors.edges.map((edge) => edge.node.id),
    [objective]
  );
  const tags = useMemo(
    () => objective.tags.edges.map((edge) => edge.node.id),
    [objective]
  );
  const owners = useMemo(() => {
    const groups = mapEdges(objective.groups.edges).map((node) => node.id);
    if (objective.isCompanyGoal && !!company.id) {
      groups.push(company.id);
    }
    return groups;
  }, [objective, company.id]);

  const getOwnerLink = () => {
    if (owners.length === 1) {
      const ownerId = owners[0];
      if (ownerId === company.id) return routes.company.okrs;
      return getCompleteLink(routes.groups.single.okrs, {
        id: ownerId,
      }).absolutePath;
    }
    return null;
  };

  return (
    <div className="m-6" data-testid="objectiveSidebar">
      <div className="flex flex-col space-y-4 pb-4 border-b border-slate-300">
        <ObjectiveStageActions objective={objective} />
        <div className="font-semibold">
          <FormattedMessage defaultMessage="Objective details" id="dvIBv7" />
        </div>
      </div>
      <div className="py-4 text-slate-500 text-sm border-b border-slate-300">
        <div className="flex flex-col space-y-2">
          <GoalSidebarItem className="h-10">
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div>
                  <FormattedMessage
                    defaultMessage="Progress"
                    id="global:progress"
                  />
                </div>
                <SupportTip
                  iconSize="lg"
                  description={
                    messages.progressTooltip[objective.progressDriver]
                  }
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <SemiCircleProgress size="lg" progress={objective.progress} />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div>
                  <FormattedMessage
                    defaultMessage="Status"
                    id="global:status"
                  />
                </div>
                <SupportTip
                  iconSize="lg"
                  description={messages.statusTooltip[objective.progressDriver]}
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <ObjectiveStatus
                className="text-slate-800"
                objective={objective}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={flexibleObjectiveProgressDriver}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <div className="flex space-x-1 items-center">
                  <div>
                    <FormattedMessage
                      defaultMessage="Progress driver"
                      id="mIuIO8"
                    />
                  </div>
                  <SupportTip
                    iconSize="lg"
                    description={
                      messages.progressDriverToolip[objective.progressDriver]
                    }
                  />
                </div>
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <ProgressDriverSelect
                  data-testid="progressDriverSelect"
                  data-cy="progressDriverSelect"
                  value={objective.progressDriver}
                  onChange={setProgressDriver}
                  disabled={!objective.canPatch}
                />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>

          <Show when={(objective.initiatives?.totalCount ?? 0) > 0}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage
                  defaultMessage="Initiatives"
                  id="home:global:inititiatives"
                />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <ObjectiveInitiativeProgress />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>
        </div>
      </div>

      <div className="py-4 text-slate-500 text-sm border-b border-slate-300">
        <div className="flex flex-col space-y-2">
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Owner" id="global:owner" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <SelectLinkButton
                href={getOwnerLink()}
                tooltip={
                  <FormattedMessage defaultMessage="Open profile" id="z6rKH9" />
                }
              >
                <InlineOwnerSelect
                  disabled={!objective.canPatch}
                  value={owners}
                  onChange={setOwners}
                  data-cy="ownersSelect"
                />
              </SelectLinkButton>
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Lead" id="global:lead" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <SelectLinkButton
                href={
                  objective.lead?.id
                    ? getCompleteLink(routes.users.single.okrs, {
                        id: objective.lead.id,
                      }).absolutePath
                    : null
                }
                tooltip={
                  <FormattedMessage defaultMessage="Open profile" id="z6rKH9" />
                }
              >
                <InlineUserSelect
                  value={objective.lead?.id ?? null}
                  variables={{
                    roleIn: allRolesButViewonly.join(),
                  }}
                  onChange={setLead}
                  data-testid="leadSelect"
                  data-cy="leadSelect"
                  disabled={!objective.canPatch}
                />
              </SelectLinkButton>
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <ObjectiveTimeframeHeader />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content className="overflow-visible">
              <InlineTimeframeSelect
                value={objective.timeframe.id}
                data-testid="timeframeSelect"
                data-cy="timeframeSelect"
                onChange={setTimeframe}
                className="max-w-[180px]"
                disabled={!objective.canPatch}
                hasError={objective.timeframe.isPast ?? undefined}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div>
                  <FormattedMessage
                    defaultMessage="Alignment"
                    id="global:alignment"
                  />
                </div>

                <SupportTip
                  iconSize="lg"
                  description={intl.formatMessage({
                    defaultMessage:
                      "Pick whether this Objective supports a Strategic Pillar, another Objective or supports a KPI",
                    id: "K7JljD",
                  })}
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <ObjectiveAlignment />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem className="items-start">
            <GoalSidebarItem.Title>
              <div className="flex items-center">
                <FormattedMessage
                  defaultMessage="Tags"
                  id="company:settings:tags:title"
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineTagsSelect
                value={tags}
                onChange={setTags}
                data-cy="tagsSelect"
                disabled={!objective.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem className="items-start">
            <GoalSidebarItem.Title>
              <div className="h-6 flex items-center">
                <FormattedMessage
                  defaultMessage="Contributors"
                  id="global:contributors"
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineUserMultiSelect
                value={contributors}
                onChange={setContributors}
                data-cy="contributorsSelect"
                addText={
                  <FormattedMessage
                    defaultMessage="Add contributor"
                    id="pkW5hu"
                  />
                }
                disabled={!objective.canPatch}
                options={{ excludeViewOnly: true }}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
        </div>
      </div>

      <div className="py-4 text-slate-500 text-sm">
        <div className="flex flex-col space-y-2">
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div>
                  <FormattedMessage
                    defaultMessage="Update. freq."
                    id="AwjGtN"
                  />
                </div>
                <SupportTip
                  iconSize="lg"
                  description={intl.formatMessage({
                    defaultMessage:
                      "Select a custom update frequency for this goal. " +
                      "Note: your default update frequency is based on timeframe settings.",
                    id: "objective:form:goalUpdateFrequency:tooltip",
                  })}
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineGoalUpdateFrequencySelect
                data-testid="updateFrequencySelect"
                data-cy="updateFrequencySelect"
                onChange={setUpdateFrequency}
                value={objective.goalUpdateCycle}
                disabled={!objective.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage
                defaultMessage="Stage"
                id="objective:list:stage"
              />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineObjectiveStageSelect
                data-cy="stageSelect"
                value={objective.stage}
                onChange={setStage}
                exclude={[PerdooApiObjectiveStageChoices.Closed]}
                disabled={!objective.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={privateGoalsAvailable}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage defaultMessage="Visibility " id="1ExHW5" />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <InlineObjectiveVisibilitySelect
                  isPrivate={objective.private}
                  onChange={setVisibility}
                  disabled={isAligned || !objective.canPatch}
                  data-cy="visibilitySelect"
                  tooltip={
                    isAligned ? (
                      <FormattedMessage
                        defaultMessage="Aligned Objectives can only be public"
                        id="FbHf2B"
                      />
                    ) : null
                  }
                />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>

            <Show
              when={
                objective.private &&
                (objective.privateToUsers?.edges.length ?? 0) > 0
              }
            >
              <GoalSidebarItem>
                <GoalSidebarItem.Title>
                  <div className="flex space-x-1 items-center">
                    <div>
                      <FormattedMessage
                        defaultMessage="Private to  "
                        id="objective:private:subtext:privateTo"
                      />
                    </div>
                    <SupportTip
                      iconSize="lg"
                      description={intl.formatMessage({
                        defaultMessage:
                          "Only Objective lead, Result leads, and contributors can see private OKRs.",
                        id: "objective:private:contributingUsers:notice",
                      })}
                    />
                  </div>
                </GoalSidebarItem.Title>
                <GoalSidebarItem.Content>
                  <ObjectivePrivacy />
                </GoalSidebarItem.Content>
              </GoalSidebarItem>
            </Show>
          </Show>
        </div>
      </div>

      <Show when={!objective.canPatch}>
        <div className="flex space-x-2 items-center py-4 text-slate-500 text-sm border-t border-slate-300">
          <Icon name="error_outline" />
          <div>
            {currentUser?.role && (
              <FormattedMessage
                defaultMessage="As a {role} user editing possibilities are restricted. {learnMore}"
                id="wslZHv"
                values={{
                  learnMore: (
                    <AnchorNext
                      href="https://support.perdoo.com/en/articles/1588568-roles-rights"
                      data-cy="rolesLink"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FormattedMessage
                        defaultMessage="Learn more"
                        id="TdTXXf"
                      />
                    </AnchorNext>
                  ),
                  role: <FormattedMessage {...roleNames[currentUser.role]} />,
                }}
              />
            )}
          </div>
        </div>
      </Show>
    </div>
  );
};
