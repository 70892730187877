import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  de: {
    defaultMessage: "Deutsch",
    id: "settings:user:language:de",
  },
  en: {
    defaultMessage: "English",
    id: "settings:user:language:en",
  },
  es: {
    defaultMessage: "Español",
    id: "settings:user:language:es",
  },
  fr: {
    defaultMessage: "Français",
    id: "settings:user:language:fr",
  },
  nl: {
    defaultMessage: "Dutch",
    id: "settings:user:language:nl",
  },
  pl: {
    defaultMessage: "Polski",
    id: "settings:user:language:pl",
  },
  pt: {
    defaultMessage: "Português",
    id: "settings:user:language:pt",
  },
});

export type Lang = keyof typeof messages;

/** if you add a language here, you should also load it for dayjs in {@link src/utils/boot},
 * to be able to use localized date names (weekdays, months).
 */
export const AVAILABLE_LANGUAGES: Lang[] = [
  "de",
  "en",
  "es",
  "fr",
  "nl",
  "pl",
  "pt",
];

export const isSupportedLang = (lang: string) =>
  AVAILABLE_LANGUAGES.includes(lang as Lang);

export const DEFAULT_LANGUAGE: Lang = "en";

export const useLanguageOptions = () => {
  const intl = useIntl();
  return AVAILABLE_LANGUAGES.map((lang) => ({
    label: intl.formatMessage(messages[lang]),
    value: lang,
  }));
};
