import { useQuery } from "@apollo/client";
import {
  ObjectiveDefaultLegacyFragment,
  useGetFormActiveObjectivesQuery,
} from "@graphql";
import { apolloClient } from "apolloClient";
import classNames from "clsx";
import {
  camelCase,
  find,
  get,
  includes,
  lowerCase,
  omit,
  orderBy,
} from "lodash";
import React, { useEffect, useState } from "react";
import { Props as ReactSelectProps } from "react-select";
import { StackedAvatar } from "common/avatar";
import { MenuList } from "common/form/FormObjectiveParentSelect/MenuList/MenuList";
import { TimeframesSelect } from "common/form/FormObjectiveParentSelect/TimeframesSelect/TimeframesSelect";
import { objectiveStages } from "common/objective/constants";
import { GET_FORM_CLOSED_OBJECTIVE } from "common/objective/queries";
import { Select } from "legacy/components/Select/Select";
import { BOOT_PRELOAD_TIMEFRAME_QUERY } from "modules/login/queries";
import { mapEdges } from "utils/mapEdges";

type Props = Pick<
  ReactSelectProps,
  "field" | "form" | "placeholder" | "styles" | "autoFocus"
> & {
  "data-cy"?: string;
  label?: string;
  objectiveId?: string;
  optional?: boolean;
};

interface GroupedObjectives {
  objectives: ObjectiveDefaultLegacyFragment[];
  timeframe: TimeframeNode;
}

interface TimeframeQuery {
  timeframes: NodeConnectionExtended<TimeframeNode>;
}

const filterOptions = ({ data }: any, query: string) =>
  includes(lowerCase(data.name), lowerCase(query));

const getLabel = (value: ObjectiveDefaultLegacyFragment) => {
  const sharedOwners = value.groups.edges.map(({ node }) =>
    omit(node, "__typename")
  );
  if (value.isCompanyGoal) sharedOwners.unshift({ ...value.company });

  return (
    <div className="flex w-full items-center">
      <div className={classNames("w-10", { "w-14": sharedOwners.length > 1 })}>
        <StackedAvatar urls={sharedOwners.map((owner) => owner.avatar ?? "")} />
      </div>
      <div className="flex-grow pl-1">{value.name}</div>
    </div>
  );
};

const getClosedObjective = (objectiveId: string) =>
  apolloClient.query({
    query: GET_FORM_CLOSED_OBJECTIVE,
    variables: {
      objectiveId,
    },
  });

export const objectiveParentSelectStages = [
  objectiveStages.active,
  objectiveStages.draft,
].join(",");

export const FormObjectiveParentSelect: React.FC<Props> = ({
  field,
  form: { errors, submitCount },
  label,
  objectiveId,
  optional,
  placeholder,
  styles,
  autoFocus,
  "data-cy": dataCy,
}) => {
  const [selectedTfs, setSelectedTfs] = useState<string[]>([]);
  const [loadingValue, setLoadingValue] = useState(false);
  const [value, setValue] = useState<ObjectiveDefaultLegacyFragment | null>(
    null
  );
  const [groupedOptions, setGroupedOptions] = useState<
    Record<string, GroupedObjectives>
  >({});

  const { data, loading } = useGetFormActiveObjectivesQuery({
    fetchPolicy: "cache-and-network",
    variables: { objectivesStages: objectiveParentSelectStages },
  });

  const { data: timeframeData, loading: loadingTimeframe } =
    useQuery<TimeframeQuery>(BOOT_PRELOAD_TIMEFRAME_QUERY);

  useEffect(() => {
    const groupedObjectives: Record<string, GroupedObjectives> = {};
    const timeframes = timeframeData?.timeframes.edges.reduce<
      Record<string, TimeframeNode>
    >((memo, { node }) => {
      return {
        ...memo,
        [node.id]: node,
      };
    }, {});
    mapEdges(data?.objectives.edges)
      .filter(({ id }) => id !== objectiveId)
      .forEach((node) => {
        const current = groupedObjectives[node.timeframe.id]?.objectives ?? [];

        groupedObjectives[node.timeframe.id] = {
          objectives: [...current, node],
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'TimeframeNode | { name: string; __typename: ... Remove this comment to see the full error message
          timeframe: timeframes
            ? timeframes[node.timeframe.id]
            : { ...node.timeframe, name: "..." },
        };
      });
    setGroupedOptions(groupedObjectives);
  }, [objectiveId, loading, loadingTimeframe]);

  useEffect(() => {
    if (data?.objectives && field.value) {
      const value = find(
        data.objectives.edges,
        ({ node }) => node.id === field.value
      );

      if (value?.node) {
        setValue(value.node);
        return;
      }

      setLoadingValue(true);
      getClosedObjective(field.value)
        .then((response) => setValue(response.data.objective))
        .finally(() => setLoadingValue(false));
    } else {
      setValue(null);
    }
  }, [field.value, loading]);

  const onChange = (value: ObjectiveDefaultLegacyFragment | null) =>
    field.onChange({
      target: {
        name: field.name,
        value: value?.id ?? null,
      },
    });

  const getTimeframes = () => {
    const timeframeIds = Object.keys(groupedOptions);
    const timeframes = timeframeIds.map(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      (tfId) => groupedOptions[tfId].timeframe
    );

    return orderBy(timeframes, ["duration", "startDate"], ["desc", "asc"]);
  };

  const toggleSelectedTf = (tfId: string) => {
    let newSelected;
    if (selectedTfs.includes(tfId)) {
      newSelected = selectedTfs.filter((id) => id !== tfId);
    } else {
      newSelected = [...selectedTfs, tfId];
    }

    setSelectedTfs(newSelected);
  };

  const getOptions = () => {
    const timeframes = getTimeframes();
    let filteredTfs = [...timeframes];

    if (selectedTfs.length !== 0) {
      filteredTfs = timeframes.filter((tf) => selectedTfs.includes(tf.id));
    }

    return filteredTfs.map((tf) => ({
      label: tf.name || "Timeframe",
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      options: groupedOptions[tf.id].objectives,
    }));
  };

  const errorText = submitCount > 0 && get(errors, field.name);

  return (
    <Select
      autoFocus={autoFocus}
      clearAll={() => setSelectedTfs([])}
      components={{ MenuList }}
      data-cy={dataCy || camelCase(label)}
      errorText={errorText}
      filterOption={filterOptions}
      getOptionLabel={getLabel}
      getOptionValue={({ id }: ObjectiveDefaultLegacyFragment) => id}
      id={field.name}
      isClearable={optional}
      isInForm
      label={label}
      loading={loading || loadingValue}
      name={field.name}
      onBlur={field.onBlur}
      onChange={onChange}
      optional={optional}
      options={getOptions()}
      placeholder={placeholder}
      styles={styles}
      timeframesSelect={
        <TimeframesSelect
          selected={selectedTfs}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'TimeframeNode[]' is not assignable to type '... Remove this comment to see the full error message
          timeframes={getTimeframes()}
          toggle={toggleSelectedTf}
        />
      }
      value={value}
    />
  );
};
