import { namedOperations } from "@graphql";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ShowFeature } from "common/access/ShowFeature";
import { Icon } from "common/icons/Icon/Icon";
import { ListItem } from "common/misc/ListItem/ListItem";
import { objectiveStages } from "common/objective/constants";
import { ProfileOkrListContext } from "common/objective/context";
import { useCloseObjectiveModal } from "common/objective/modals/CloseObjectiveModal/hooks/useCloseObjectiveModal/useCloseObjectiveModal";
import { DeleteObjectiveAction } from "common/objective/ObjectiveWrapper/ObjectiveActions/DeleteObjective/DeleteObjective";
import { useReopenObjectiveConfirmationModal } from "common/objective/ReopenObjectiveConfirmationModal";
import { MODAL_TYPES } from "constants/index";
import { useCompany } from "hooks/useCompany/useCompany";
import { useObjectiveActions } from "hooks/useObjectiveActions/useObjectiveActions";
import { showModal } from "legacy/actions/actions";

const refetchQueries = [
  namedOperations.Query.objectiveQuery,
  namedOperations.Query.getCompanyHeader,
  namedOperations.Query.getGroupProgress,
  namedOperations.Query.getProfileObjectives,
  namedOperations.Query.getRoadmap,
  namedOperations.Query.getStandardRoadmap,
  namedOperations.Query.getTimelineObjectives,
  namedOperations.Query.getTimelineStrategicPillars,
  namedOperations.Query.getUnalignedObjectives,
];

interface Props {
  childrenCount?: number;
  // TODO type objective
  objective: any;
  onClose: () => void;
  timeframeId?: string;
}

export const ObjectiveModalActions = ({
  onClose,
  objective,
  timeframeId,
}: Props): JSX.Element[] => {
  const dispatch = useDispatch();
  const { id: companyId } = useCompany();
  const intl = useIntl();
  const { reFetchQueries: profileOkrListRefetchQueries } = useContext(
    ProfileOkrListContext
  );
  const { openModal: openReopenModal } = useReopenObjectiveConfirmationModal();

  const { activateObjective } = useObjectiveActions({
    objective,
  });

  const addSubObjective = () =>
    dispatch(
      showModal(MODAL_TYPES.ADD_OBJECTIVE, {
        groupPrefilledId: objective.group?.id || companyId,
        parent: objective.id,
        refetchQueries: [...refetchQueries, "SubObjectives"],
        tfId: timeframeId,
      })
    );

  const openObjCloneModal = () => {
    dispatch(
      showModal(MODAL_TYPES.CLONE_OBJECTIVE, {
        passedObjectiveId: objective.id,
        refetchQueries: profileOkrListRefetchQueries,
      })
    );
  };

  const { openCloseObjectiveModal } = useCloseObjectiveModal();
  const openObjClosingModal = () => openCloseObjectiveModal({ objective });

  const actions = [];

  if (objective?.canPatch) {
    actions.push(
      <ListItem
        key="add_subobjective"
        data-cy="add-subobjective"
        onClick={addSubObjective}
      >
        <Icon className="mr-2" name="add" size="2xl" />
        <FormattedMessage
          defaultMessage="Add Sub-Objective"
          id="objective:addSubObjective"
        />
      </ListItem>
    );

    if (objective.stage === objectiveStages.closed) {
      actions.push(
        <ListItem
          key="edit-closed"
          data-cy="edit-closed-objective"
          onClick={openObjClosingModal}
        >
          <Icon className="mr-2" name="edit" size="2xl" />
          <FormattedMessage
            defaultMessage="Edit Closing Note"
            id="objective:edit:close"
          />
        </ListItem>
      );
      actions.push(
        <ListItem
          key="reopen"
          data-cy="reopen-objective"
          onClick={() => openReopenModal({ objective })}
        >
          <Icon className="mr-2" name="unarchive" size="2xl" />
          <FormattedMessage
            defaultMessage="Reopen Objective"
            id="objective:reopen"
          />
        </ListItem>
      );
    } else {
      actions.push(
        <ListItem
          key="close"
          data-cy="close-objective"
          onClick={openObjClosingModal}
        >
          <Icon className="mr-2" name="archive" size="2xl" type="outlined" />
          <FormattedMessage
            defaultMessage="Close Objective"
            id="objective:close"
          />
        </ListItem>
      );
    }

    if (objective.stage === objectiveStages.draft) {
      actions.push(
        <ListItem
          data-cy="p5gnIY7l-X26O5vmCmMQd"
          key="activate"
          onClick={activateObjective}
        >
          <Icon className="mr-2" name="unarchive" size="2xl" />
          {intl.formatMessage({
            defaultMessage: "Activate Objective",
            id: "objective:activate",
          })}
        </ListItem>
      );
    }

    actions.push(
      <ShowFeature key="clone" feature="MENU_CLONE_OBJECTIVE">
        <ListItem
          data-cy="rZM2NuGJDMOwrr6GjO3C5"
          key="addi"
          onClick={openObjCloneModal}
        >
          <Icon className="mr-2" name="file_copy" size="2xl" type="outlined" />
          {intl.formatMessage({
            defaultMessage: "Clone Objective",
            id: "objective:clone",
          })}
        </ListItem>
      </ShowFeature>
    );

    actions.push(
      <DeleteObjectiveAction
        showIcon
        key="delete"
        id={objective.id}
        name={objective.name}
        onDelete={onClose}
      />
    );
  }

  return actions;
};
