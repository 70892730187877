import {
  BookmarkTargetType,
  useGetObjectiveQuery,
  useGetObjectiveTimelineQuery,
} from "@graphql";
import { useRouter } from "next/router";
import { ShowFeature } from "common/access/ShowFeature";
import { BookmarkButton } from "common/buttons/BookmarkButton/BookmarkButton";
import { ObjectiveContextProvider } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import {
  TimelineBag,
  TimelineContext,
} from "common/event/TimelineContext/TimelineContext";
import { useHelpCenterContent } from "common/layout/HelpCenter/hooks/useHelpCenterContent";
import { ResultTypesInfoView } from "common/layout/HelpCenter/ResultTypesInfoView/ResultTypesInfoView";
import { ObjectiveModalHeader } from "common/objective/modals/ObjectiveModal/ObjectiveDetailsModal/ObjectiveModalHeader/ObjectiveModalHeader";
import { Modal } from "common/overlay/Modal/Modal";
import { useGoalTimelineFilter } from "hooks/useGoalTimelineFilter/useGoalTimelineFilter";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { usePageTrack } from "hooks/usePageTrack/usePageTrack";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { handleError } from "utils/utils";
import { ObjectiveDetails } from "./ObjectiveDetails/ObjectiveDetails";
import { ObjectiveModalActions } from "./ObjectiveModalActions/ObjectiveModalActions";
import { ResultDetails } from "./ResultDetails/ResultDetails";

interface Props {
  id: string;
  resultId?: string;
}

export const ObjectiveDetailsModal = ({
  id,
  resultId,
}: Props): JSX.Element | null => {
  const { filterOptions, setTimelineFilter, timelineFilter } =
    useGoalTimelineFilter();
  useHelpCenterContent({ element: <ResultTypesInfoView />, type: "results" });
  const { close } = useModalRouter();
  usePageTrack("Objective details", { version: "post-november-2023" });
  const { query } = useRouter();

  const { data, error } = useGetObjectiveQuery({
    fetchPolicy: "cache-and-network",
    onError: (err) => {
      handleError(err);
      close();
    },
    variables: { objectiveId: id },
  });

  const {
    loading: timelineLoading,
    data: timelineData,
    refetch,
    fetchMore,
  } = useGetObjectiveTimelineQuery({
    variables: {
      eventType: timelineFilter.value,
      id,
    },
  });

  const stack = query.stack ?? false;

  const objective = data?.objective;

  const timelineBag: TimelineBag = {
    fetchMore,
    filterOptions,
    setTimelineFilter,
    timelineData: timelineData?.objective?.events,
    timelineFilter,
    timelineLoading,
    updateTimeline: (timelineProps) => {
      refetch(timelineProps);
    },
  };

  const actions = ObjectiveModalActions({
    objective,
    onClose: close,
    timeframeId: objective?.timeframe.id,
  });

  if (error) {
    return null;
  }

  let modalProps = {};

  if (isSet(data) && objective) {
    modalProps = {
      extraIconButtons: [
        <ShowFeature key="bookmark-button" feature="BUTTON_BOOKMARK_STAR">
          <BookmarkButton
            data-cy="objectiveBookmark"
            targetId={objective.id}
            targetType={BookmarkTargetType.Objective}
          />
        </ShowFeature>,
      ],
      renderActions: () => {
        if (actions.length === 0) return null;
        return actions;
      },
      title: <ObjectiveModalHeader objective={objective} />,
      titleClassName: "mx-0 pb-4",
    };
  }

  return (
    <TimelineContext.Provider value={timelineBag}>
      <Modal
        backdropClass={twClass({ "!z-modal-prio-backdrop": stack })}
        className={twClass({ "!z-modal-prio": stack })}
        // we don't want to accidentally close modal while adding a progress update
        disableEsc
        isOpen
        loading={!data}
        onClose={close}
        scrollable={false}
        showFooter={false}
        showHeaderBodyDivider
        size="xl"
        title="Loading..."
        data-testid={!!resultId ? "resultModal" : "objectiveModal"}
        {...modalProps}
      >
        <ObjectiveContextProvider value={objective!}>
          <Show
            when={!isSet(resultId)}
            fallback={<ResultDetails resultId={resultId!} />}
          >
            <ObjectiveDetails />
          </Show>
        </ObjectiveContextProvider>
      </Modal>
    </TimelineContext.Provider>
  );
};
