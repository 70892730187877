import Fuse from "fuse.js";
import { find, isEmpty } from "lodash";
import { useIntl } from "react-intl";
import {
  COMBO_EMPTY_ID,
  COMBO_NEW_ITEM_ID,
} from "legacy/components/Combo/Combo.constants";
import { messages } from "legacy/components/Combo/Combo.messages";
import { ComboItem } from "legacy/components/Combo/Combo.types";
import { isSet } from "utils/isSet";

type Props<Item> = {
  allItems: Item[];
  /** use to support adding new items when no matches found. will add "{query} (add new)" to results. */
  emptyAddNew?: boolean;
  /** max number of items to show in the selector. unset for no limit. */
  limitItems?: number;
  /** the search query typed by the user */
  query: string;
  /** use to show "No results" if nothing found. */
  showEmptyState: boolean;
};

/**
 * performs fuzzy search on items based on given query.
 */
export const useComboItems = <Item extends ComboItem>({
  allItems,
  query,
  emptyAddNew,
  limitItems,
  showEmptyState,
}: Props<Item>): Item[] => {
  const intl = useIntl();

  let items = allItems;

  // empty string would return no matches, so we're only using Fuse if there is a query.
  // @see {@link https://github.com/krisk/Fuse/issues/229}
  if (query) {
    items = new Fuse(allItems, {
      isCaseSensitive: false,
      keys: ["name", "fullName"],
      threshold: 0.4,
    })
      .search(query)
      .map((r) => r.item);
  }

  if (isSet(limitItems)) {
    items = items.slice(0, limitItems);
  }

  const exactMatch = find(allItems, (i) => i.name === query);
  if (emptyAddNew && !exactMatch && query !== "") {
    const addNew = intl.formatMessage(messages.addNew);
    // @ts-expect-error if you pass Item as something else, e.g. that requires "owner", then the item we're trying to push will be missing that field,
    // resulting in a type error. not sure how to solve.
    items.push({
      id: COMBO_NEW_ITEM_ID,
      label: `${query} (${addNew})`,
      name: query,
    });
  }

  const showEmptyStateIfNoResults = showEmptyState || !emptyAddNew;
  if (isEmpty(items) && showEmptyStateIfNoResults) {
    // @ts-expect-error if you pass Item as something else, e.g. that requires "owner", then the item we're trying to push will be missing that field,
    // resulting in a type error. not sure how to solve.
    items.push({
      disabled: true,
      id: COMBO_EMPTY_ID,
      name: intl.formatMessage(messages.emptyState),
    });
  }
  return items;
};
