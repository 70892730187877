import { namedOperations, useGetStrategicPillarQuery } from "@graphql";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Modal } from "common/overlay/Modal/Modal";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { UPDATE_STRATEGIC_PILLAR } from "constants/tracking";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useUpsertStrategicPillar } from "hooks/useUpsertStrategicPillar/useUpsertStrategicPillar";
import { track } from "utils/tracker";
import { StrategicPillarForm } from "../StrategicPillarForm/StrategicPillarForm";

const messages = defineMessages({
  addStrategicPillar: {
    defaultMessage: "Edit Strategic Pillar",
    id: "strategicpillar:edit",
  },
  addStrategicPillarError: {
    defaultMessage: "Error adding Strategic Pillar",
    id: "strategicpillar:add:error",
  },
  strategicPillarUpdated: {
    defaultMessage: "Strategic Pillar Updated!",
    id: "strategicpillar:updated",
  },
});

const refetchQueries = [
  namedOperations.Query.getTimelineStrategicPillars,
  namedOperations.Query.getRoadmap,
];

type Props = {
  id: string;
  open: boolean;
};

export const EditStrategicPillar = ({
  id,
  open = false,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { close } = useModalRouter();
  const { upsertStrategicPillar } = useUpsertStrategicPillar(refetchQueries);
  const { loading, data } = useGetStrategicPillarQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id,
    },
  });

  const onSubmit = async (values: any) => {
    await upsertStrategicPillar!({
      description: values.description,
      id,
      name: values.name,
    });
    track(UPDATE_STRATEGIC_PILLAR, { uiElementId: "roadmap" });
    close();
  };
  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={close}
      title={intl.formatMessage(messages.addStrategicPillar)}
    >
      {loading && (
        <div className="flex h-64 content-center items-center justify-center">
          <Spinner />
        </div>
      )}
      {!loading && data?.strategicPillar && (
        <StrategicPillarForm
          initialValues={{
            description: data.strategicPillar.description || "",
            name: data.strategicPillar.name,
          }}
          onCancel={close}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};
