import { isEmpty } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { Toggle } from "common/inputs/Toggle/Toggle";
import { SUB_GROUPS_FILTER_TOGGLE } from "constants/tracking";
import { FilterItem } from "legacy/components/filters/types";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";

type Props = {
  activeFilterItems: FilterItem[];
  includeSubgroups: boolean;
  onToggle: () => void;
};

export const FilterSubgroupsToggle = ({
  activeFilterItems,
  includeSubgroups,
  onToggle,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  if (isEmpty(activeFilterItems)) {
    return null;
  }

  const handleToggle = () => {
    const enablingSubgroups = !includeSubgroups;
    if (enablingSubgroups) {
      track(SUB_GROUPS_FILTER_TOGGLE);
    }
    onToggle();
  };

  return (
    <div
      className={twClass(
        "flex items-center",
        "text-base text-slate-800",
        "border-t-px border-slate-200",
        "space-x-4 p-3"
      )}
    >
      <Toggle className="flex" on={includeSubgroups} onToggle={handleToggle} />
      <span>
        {intl.formatMessage({
          defaultMessage: "Include Subteams",
          id: "pSg035",
        })}
      </span>
    </div>
  );
};
