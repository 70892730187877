import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { ListItem } from "common/misc/ListItem/ListItem";
import { Dropdown } from "../../../legacy/components/Dropdown/Dropdown";

export type Frequency = "D" | "W" | "M" | "Q" | "Y";

interface Props {
  freq: Frequency | undefined;
  setFreq?: (val: Frequency | undefined) => void;
}

const freqOptions: Frequency[] = ["D", "W", "M", "Q", "Y"];
const freqLabels: { [key in Frequency]: string } = {
  D: "Day",
  M: "Month",
  Q: "Quarter",
  W: "Week",
  Y: "Year",
};

const messages = defineMessages({
  asItHappened: {
    defaultMessage: "As it happened",
    id: "pot:asItHappened",
  },
});

export const FrequencyDropdown = ({ freq, setFreq }: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <Dropdown
      button={
        <div className="flex cursor-pointer items-center text-sm text-blue-500 hover:text-blue-700">
          <span>
            {freq
              ? freqLabels[freq]
              : intl.formatMessage(messages.asItHappened)}
          </span>
          <Icon className="ml-1" name="keyboard_arrow_down" size="lg" />
        </div>
      }
      id="freq-dd"
    >
      <ListItem
        data-cy="N_WhODUcOJtMpS8krmSS9"
        key="none"
        onClick={() => setFreq?.(undefined)}
      >
        {intl.formatMessage(messages.asItHappened)}
      </ListItem>
      {freqOptions.map((value) => (
        <ListItem
          data-cy="OuCostTvt_Kost3jVAsUv"
          key={value}
          onClick={() => setFreq?.(value)}
        >
          {freqLabels[value]}
        </ListItem>
      ))}
    </Dropdown>
  );
};
