import { omit } from "lodash";
// this util is not included officially and tends to be moved around.
// if it breaks after upgrade, check https://github.com/vercel/next.js/discussions/22025
import { resolveHref } from "next/dist/client/resolve-href";
import { useRouter } from "next/router";

interface Config {
  baseUrl?: string;
  stack?: boolean;
  tab?: string;
}

export enum ObjectiveModalType {
  "ADD" = "OBJ_ADD",
  "EDIT" = "OBJ_EDIT",
}

export enum StrategicPillarModalType {
  "ADD" = "PILLAR_ADD",
  "EDIT" = "PILLAR_EDIT",
}

export enum PerformanceReviewModalType {
  "ADD" = "PR_ADD",
  "EDIT" = "PR_EDIT",
  "SUBMIT" = "PR_SUBMIT",
}

type PerformanceReviewParams = {
  prId?: string;
  revieweeIds?: string[] | string;
  reviewerId?: string;
  type: PerformanceReviewModalType;
};

export enum OneOnOneMeetingModalType {
  "ADD" = "OM_ADD",
  "COMPLETE" = "OM_COMPLETE",
  "EDIT" = "OM_EDIT",
  RESCHEDULE = "RESCHEDULE",
}

type ObjectiveParams = {
  oId?: string;
  redirectAfter?: boolean;
  type?: ObjectiveModalType;
};

type PillarParams = {
  pId?: string;
  type?: StrategicPillarModalType;
};

type OneOnOneMeetingParams = {
  attendee?: string;
  editSeries?: boolean;
  mId?: string;
  organizer?: string;
  redirectAfter?: boolean;
  reschedule?: boolean;
  seriesId?: string;
  tabUserId?: string;
  type?: OneOnOneMeetingModalType;
};

type KpiParams = {
  kpiId: string;
  timeframe?: string;
};

type ResultConfig = Config & { oId?: string; stack?: boolean };

export type UseModalRouterHook = ReturnType<typeof useModalRouter>;

export const useModalRouter = () => {
  const router = useRouter();
  const { query } = router;

  // TODO: Once all routes are migrated we can rely on the next-router pathname here
  // pro tip: this is not as simple as it seems. replacing with asPath doesn't work.
  // replacing with pathname from next router results in an error:
  // `Error: The provided `href` (/groups/[id]/okrs?oId=c9a133cc-d7a5-4cba-ba2d-f4e6303bad9a) value
  // is missing query values (id) to be interpolated properly.`
  const { pathname } = window.location;

  const checkQueryParamsPresent = (requiredKeys: string[]) =>
    requiredKeys.every((key) => Object.keys(query).includes(key));

  const closeHref = () => resolveHref(router, {});

  const closeResultHref = () =>
    resolveHref(router, { pathname, query: omit(query, "rId") });

  const close = () => {
    if (checkQueryParamsPresent(["oId"])) {
      const href = resolveHref(router, {
        pathname,
        query: omit(query, "oId", "tab", "rId", "stack", "id"),
      });
      router.push(href, undefined, { shallow: true });
      return;
    }
    if (checkQueryParamsPresent(["kpiId"])) {
      const href = resolveHref(router, {
        pathname,
        query: omit(query, "kpiId", "stack", "id"),
      });
      router.push(href, undefined, { shallow: true });
      return;
    }
    if (checkQueryParamsPresent(["reschedule", "seriesId"])) {
      const href = resolveHref(router, {
        pathname,
        query: omit(query, "reschedule"),
      });
      router.push(href, undefined, { shallow: true });
      return;
    }
    if (checkQueryParamsPresent(["editSeries", "seriesId"])) {
      const href = resolveHref(router, {
        pathname,
        query: omit(query, "editSeries"),
      });
      router.push(href, undefined, { shallow: true });
      return;
    }
    router.push(pathname, undefined, { shallow: true });
  };

  const closeResult = () =>
    router.push(closeResultHref(), undefined, { shallow: true });

  const objectiveHref = (
    params: ObjectiveParams,
    { tab, baseUrl, stack }: Config = {}
  ): string =>
    resolveHref(router, {
      pathname: baseUrl || pathname,
      query: {
        ...(stack && { ...query, stack }),
        ...params,
        ...(tab && { tab }),
      },
    });

  const pillarHref = (
    params: PillarParams,
    { tab, baseUrl, stack }: Config = {}
  ): string =>
    resolveHref(router, {
      pathname: baseUrl || pathname,
      query: {
        ...(stack && { ...query, stack }),
        ...params,
        ...(tab && { tab }),
      },
    });

  const performanceReviewHref = (
    params: PerformanceReviewParams,
    { tab, baseUrl }: Config = {}
  ): string =>
    resolveHref(router, {
      pathname: baseUrl || pathname,
      query: { ...params, ...(tab && { tab }) },
    });

  const oneOnOneMeetingHref = (
    { reschedule, editSeries, ...rest }: OneOnOneMeetingParams,
    { tab, baseUrl }: Config = {}
  ): string =>
    resolveHref(router, {
      pathname: baseUrl || pathname,
      query: {
        ...(reschedule ? { ...query, reschedule } : { ...rest }),
        ...(editSeries ? { ...query, editSeries } : { ...rest }),
        ...(tab && { tab }),
      },
    });

  const kpiHref = (
    { kpiId, timeframe }: KpiParams,
    { tab, baseUrl, stack }: Config = {}
  ): string =>
    resolveHref(router, {
      pathname: baseUrl || pathname,
      query: {
        ...(stack && { ...query, stack }),
        kpiId,
        ...(tab && { tab }),
        ...(timeframe && { timeframe }),
      },
    });

  const resultHref = (
    rId: string,
    { tab, baseUrl, oId, stack }: ResultConfig = {}
  ) =>
    resolveHref(router, {
      pathname: baseUrl || pathname,
      query: {
        ...query,
        rId,
        ...(oId && { oId }),
        ...(tab && { tab }),
        ...(stack && { stack }),
      },
    });

  const tabHref = (tab: string) =>
    resolveHref(router, { pathname, query: { ...query, tab } });

  const goToObjective = (params: ObjectiveParams, config: Config = {}) => {
    return router.push(objectiveHref(params, config));
  };

  const goToPillar = (params: PillarParams, config: Config = {}) => {
    return router.push(pillarHref(params, config));
  };

  const goToKpi = (params: KpiParams, config: Config = {}) =>
    router.push(kpiHref(params, config));

  const goToResult = (oId: string, config: ResultConfig = {}) =>
    router.push(resultHref(oId, config));

  const goToPerformanceReviews = (
    params: PerformanceReviewParams,
    config: Config = {}
  ) => router.push(performanceReviewHref(params, config));

  const goToOneOnOneMeeting = (
    params: OneOnOneMeetingParams,
    config: Config = {}
  ) => router.push(oneOnOneMeetingHref(params, config));

  return {
    close,
    closeHref,
    closeResult,
    closeResultHref,
    goToKpi,
    goToObjective,
    goToOneOnOneMeeting,
    goToPerformanceReviews,
    goToPillar,
    goToResult,
    kpiHref,
    objectiveHref,
    performanceReviewHref,
    pillarHref,
    resultHref,
    tabHref,
  };
};
