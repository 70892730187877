import { namedOperations, useUpsertKpiBoardMutation } from "@graphql";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { FormInput } from "common/form/FormInput/FormInput";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { ADD_KPI_BOARD } from "constants/tracking";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { messages } from "./UpsertKpiBoardModal.messages";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required")
    .max(100, "The Name must be 100 or less characters long."),
});

type KpiBoardValues = {
  id?: string;
  name: string;
};

interface Props {
  callback?: (boardId?: string) => void;
  itemToEdit?: KpiBoardValues;
  onRequestClosed: () => void;
  open: boolean;
}

export const UpsertKpiBoardModal = ({
  callback,
  itemToEdit,
  open,
  onRequestClosed,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [errors, setErrors] = useState<string>("");
  const [upsertKpiBoard] = useUpsertKpiBoardMutation({
    refetchQueries: [namedOperations.Query.getAllKpiBoards],
  });

  const initialValues: KpiBoardValues = {
    id: itemToEdit?.id,
    name: itemToEdit?.name ?? "",
  };
  const onSubmitHandler = async (values: KpiBoardValues) => {
    const response = await upsertKpiBoard({
      variables: values,
    });
    const responseErrors = response.data?.upsertKpiBoard?.errors;
    if (responseErrors && responseErrors.length) {
      toast.failure(intl.formatMessage(messages.errorAddingBoard));
      setErrors(responseErrors.map((e) => e.messages).join(" "));
      return null;
    }

    if (!values.id) {
      track(ADD_KPI_BOARD, {
        board_count:
          response.data?.upsertKpiBoard?.kpiBoard?.company.kpiBoards
            ?.totalCount,
        name: values.name,
      });
    }

    const boardId = response.data?.upsertKpiBoard?.kpiBoard?.id;
    onRequestClosed();
    callback?.(boardId);
  };

  return (
    <Modal
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title={
        itemToEdit ? (
          <FormattedMessage defaultMessage="Rename Board" id="HsGpSW" />
        ) : (
          <FormattedMessage
            defaultMessage="Add Board"
            id="kpi-boards:add_board:dialog_title"
          />
        )
      }
    >
      <div className="pt-4">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {({ submitForm }) => (
            <Form id="add-kpi-board-form">
              <Field
                autoFocus
                component={FormInput}
                label={
                  <FormattedMessage
                    defaultMessage="Name"
                    id="kpi-boards:add-new-board-modal:name-field-label"
                  />
                }
                name="name"
                placeholder={intl.formatMessage({
                  defaultMessage: "Add name",
                  id: "kpi-boards:add-new-board-modal:name-field-placeholder",
                })}
                type="text"
                id="kpiBoardName"
              />
              <ModalFooter className="flex items-center">
                {/* need to pass onClick because ModalFooter creates a portal outside the form, so the "submit" event doesn't work. */}
                <Button
                  data-cy="Gvf1wXyeAvbO-JpTX51e6"
                  onClick={submitForm}
                  type="submit"
                >
                  <FormattedMessage
                    defaultMessage="Save"
                    id="kpi-boards:add-new-board-modal:save-button-label"
                  />
                </Button>
                <Button
                  data-cy="r9cnVWv6tTgZpsnmY-_ew"
                  onClick={onRequestClosed}
                  variant="ghost"
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="kpi-boards:add-new-board-modal:cancel-button-label"
                  />
                </Button>
                <div className="text-sm text-red-500">{errors}</div>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
