import { OneOnOneMeetingItemType } from "@graphql";

export type DragNDropItemTypes = {
  actionItems: string;
  talkingPoints: string;
};

export const ItemTypes: DragNDropItemTypes = {
  actionItems: OneOnOneMeetingItemType.ActionItem,
  talkingPoints: OneOnOneMeetingItemType.TalkingPoint,
};
