import { Editor } from "@tiptap/react";
import { BlockPicker } from "react-color";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Panel } from "common/overlay/Panel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useToggle } from "hooks/useToggle/useToggle";

type Props = {
  editor: Editor;
};
const ColorPicker = ({ editor }: Props) => {
  const [tooltipOpen, toggleTooltipOpen] = useToggle(false);
  const color = editor.getAttributes("textStyle").color;
  return (
    <div className="flex">
      <WithTooltip
        className="overflow-hidden"
        interactive
        maxWidth=""
        placement="right"
        theme="perdoo-white"
        tooltip={
          tooltipOpen && (
            <Panel id="color-picker" onClose={toggleTooltipOpen}>
              <BlockPicker
                color={color}
                onChange={(value) => {
                  editor.chain().setColor(value.hex).run();
                  toggleTooltipOpen();
                }}
                triangle="hide"
              />
            </Panel>
          )
        }
        visible={tooltipOpen}
      >
        <IconButton
          data-cy="KT9ySJbcyOrfueQ3clHAM"
          onClick={toggleTooltipOpen}
          name="color_lens"
        />
      </WithTooltip>
    </div>
  );
};

export default ColorPicker;
