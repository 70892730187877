import React from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { For, Show } from "common/controlFlow";
import { useKudos } from "common/kudos/hooks/useKudos/useKudos";
import { KudoActions } from "common/kudos/KudoActions/KudoActions";
import { KudoSummary } from "common/kudos/KudoSummary/KudoSummary";
import { Spinner } from "common/placeholders/Spinner/Spinner";

type Props = {
  userId: string;
};
export const PerformanceReviewSidebarKudos = ({
  userId,
}: Props): JSX.Element | null => {
  const { kudos, isLoading, loadMore, hasNextPage } = useKudos({
    pageSize: 5,
    variables: { mentionedUser: userId },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-2">
        <Spinner />
      </div>
    );
  }

  const emptyMessage = (
    <div className="flex items-center justify-center">
      <FormattedMessage
        defaultMessage="No Kudos received"
        id="userKudos:noKudosReceived"
      />
    </div>
  );
  return (
    <div className="space-y-4 px-5 py-2">
      <For each={kudos} fallback={emptyMessage}>
        {(kudo) => (
          <KudoSummary
            key={kudo.id}
            actions={<KudoActions kudo={kudo} />}
            kudo={kudo}
          />
        )}
      </For>
      <Show when={hasNextPage}>
        <div className="flex items-center justify-center">
          <TextButton
            className="m-0 p-0 text-blue-500"
            data-cy="yZqyCqSmO3me88MwnZC97"
            onClick={loadMore}
            size="small"
            text={
              <FormattedMessage
                defaultMessage="Load more..."
                id="global:loadMore"
              />
            }
          />
        </div>
      </Show>
    </div>
  );
};
