import React from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { Icon } from "common/icons";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";

export const ResultTypeGuideToggle = (): JSX.Element | null => {
  const { toggleHelpCenter } = useHelpCenter();
  return (
    <TextButton
      data-cy="4jQ_LpwQOrhwM3EQvUFwv"
      className="p-0"
      onClick={toggleHelpCenter}
      size="small"
      text={
        <span className="flex items-center space-x-1 text-sm">
          <span>
            <FormattedMessage
              defaultMessage="What is the difference?"
              id="resultForm:resultType:whatIsTheDifference"
            />
          </span>
          <Icon name="help_outline" size="xl" />
        </span>
      }
    />
  );
};
