import { useQuery } from "@apollo/client";
import {
  GetKpiGroupsDocument,
  GetKpiGroupsQueryData,
  GetObjectiveGroupsDocument,
  GetObjectiveGroupsQueryData,
  Group,
  GroupDefaultFragment,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import { convertCursorToPage } from "common/table/hooks/usePage/usePage";
import { Cursor } from "common/table/types";
import { GoalType } from "constants/goal.types";
import { mapEdges } from "utils/mapEdges";

export type Owner = Derive<Group, { avatar: true; id: true; name: true }> & {
  isCompany: boolean;
};

const getAllGoalOwners = (
  goal: GetKpiGroupsQueryData["kpi"] | GetObjectiveGroupsQueryData["objective"],
  pageSize: number
) => {
  let totalCount;
  let owners: Owner[] = [];
  if (goal) {
    const { pageInfo } = goal.groups;
    const pageIndex = !isNil(pageInfo.startCursor)
      ? convertCursorToPage(pageInfo.startCursor, pageSize)
      : 0;

    totalCount = goal.groups.totalCount;
    owners = mapEdges<GroupDefaultFragment>(goal.groups.edges).map((node) => {
      return { ...node, isCompany: false };
    });

    if (goal.isCompanyGoal) {
      totalCount += 1;
      if (pageIndex === 0)
        owners.unshift({
          avatar: goal.company.avatar,
          id: goal.company.id || "",
          isCompany: true,
          name: goal.company.name,
        });
    }
    return {
      owners,
      pageInfo,
      totalCount,
    };
  }
  return { owners: [], pageInfo: undefined, totalCount: undefined };
};

export const useGoalOwners = (goalType: GoalType, id: string, page: Cursor) => {
  let pageSize = 5;
  let query = GetObjectiveGroupsDocument;

  if ("first" in page) {
    pageSize = page.first;
  } else if ("last" in page) {
    pageSize = page.last;
  }

  if (goalType === "kpi") {
    query = GetKpiGroupsDocument;
  }
  const { data, loading } = useQuery(query, {
    skip: !id,
    variables: {
      after: "after" in page ? page.after : "",
      before: "before" in page ? page.before : "",
      first: "first" in page ? page.first : undefined,
      id,
      last: "last" in page ? page.last : undefined,
    },
  });

  if (loading || !data) {
    return { owners: [], pageInfo: undefined, totalCount: undefined };
  }
  const goal = data[goalType];
  return getAllGoalOwners(goal, pageSize);
};
