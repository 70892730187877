import React from "react";
import { defineMessage, useIntl } from "react-intl";

export const errorMessage = defineMessage({
  defaultMessage:
    "Something went wrong. Please contact our Support Team if this happens again.",
  id: "global:something:wrong:extended",
});

type Props = {
  className?: string;
};

/**
 * <span>Something went wrong</span>
 */
export const ErrorMessage = ({ className }: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <p data-cy="errorMessage" className={className}>
      {intl.formatMessage(errorMessage)}
    </p>
  );
};
