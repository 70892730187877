import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { For, Show } from "common/controlFlow";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { AlignedObjective } from "hooks/useGetAlignedObjectives/useGetAlignedObjectives";
import {
  ObjectiveSelectFilters,
  useObjectiveSelect,
} from "./hooks/useObjectiveSelect";
import { ObjectiveSelectItem } from "./ObjectiveSelectItem";

export type ObjectiveSelectType = AlignedObjective;

type Props = {
  autoFocus?: boolean;
  "data-cy"?: string;
  disabled?: boolean;
  excludedIds?: string[];
  filters?: ObjectiveSelectFilters;
  hasError?: boolean;
  onChange: (id: string) => void;
  placeholder?: ReactNode;
  value?: string;
};

/**
 * list of objectives to select from. used for aligning to an existing objective.
 */
export const ObjectiveSelect = ({
  value,
  onChange,
  filters,
  autoFocus,
  disabled,
  hasError,
  "data-cy": dataCy,
  placeholder,
  excludedIds,
}: Props): JSX.Element | null => {
  const {
    handleChange,
    Select,
    selected,
    loading,
    handleSearch,
    visibleOptions,
  } = useObjectiveSelect({
    excludedIds,
    filters,
    onChange,
    value,
  });

  return (
    <Select.Root
      data-cy={dataCy}
      onChange={handleChange}
      value={selected}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.Value autoFocus={autoFocus} hasError={hasError}>
          <Show fallback={<SpinnerFit />} when={!loading}>
            {selected ? (
              <ObjectiveSelectItem objective={selected} />
            ) : (
              placeholder ?? (
                <FormattedMessage
                  defaultMessage="No objective selected"
                  id="1PbdR7"
                />
              )
            )}
          </Show>
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown className="divide-y divide-slate-300 w-84">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option key={option.id} value={option}>
                <ObjectiveSelectItem size="lg" objective={option} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
