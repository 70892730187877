import { get } from "lodash";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCadenceModal } from "common/cadence/modals/AddCadenceModel/AddCadenceModal";
import { EditCadenceModal } from "common/cadence/modals/EditCadenceModal/EditCadenceModal";
import { EditCompanyModal } from "common/company/modals/EditCompanyModal/EditCompanyModal";
import { Show } from "common/controlFlow";
import { ExploreViewModal } from "common/exploreView/modals/ExploreViewModal/ExploreViewModal";
import { AddGroupModal } from "common/group/modals/AddGroupModal/AddGroupModal";
import { EditGroupModal } from "common/group/modals/EditGroupModal/EditGroupModal";
import { AddKpiModal } from "common/kpi/modals/AddKpiModal";
import { KpiSetTargetAddObjectiveModal } from "common/kpi/modals/KpiSetTargetAddObjectiveModal";
import { UpsertKpiBoardModal } from "common/kpi/modals/UpsertKpiBoardModal/UpsertKpiBoardModal";
import { AddObjectiveModal } from "common/objective/modals/AddObjectiveModal/AddObjectiveModal";
import { CloneObjectiveModal } from "common/objective/modals/CloneObjectiveModal/CloneObjectiveModal";
import { CloseObjectiveModal } from "common/objective/modals/CloseObjectiveModal/CloseObjectiveModal";
import { ObjectiveModals } from "common/objective/modals/ObjectiveModals";
import {
  ReopenObjectiveConfirmationModal,
  useReopenObjectiveConfirmationModal,
} from "common/objective/ReopenObjectiveConfirmationModal";
import { OneOnOneMeetingModalBase } from "common/oneOnOnes/modals/OneOnOneMeetingModalBase";
import { PerformanceReviewModalBase } from "common/performanceReview/modals/PerformanceReviewModalBase";
import { AddResultModal, ArchiveResultModal } from "common/result";
import { MoveResultModal } from "common/result/modals/MoveResultModal/MoveResultModal";
import { AddTagModal } from "common/tag/modals/AddTagModal/AddTagModal";
import { EditTagModal } from "common/tag/modals/EditTagModal/EditTagModal";
import { useIsProtectedRoute } from "common/topLevel/useIsProtectedRoute/useIsProtectedRoute";
import { InviteUserModal } from "common/user/InviteUserModal/InviteUserModal";
import { MODAL_TYPES } from "constants/index";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import { hideModal } from "legacy/actions/actions";
import { ReduxState } from "legacy/reducers/types";
import { StrategicPillarModals } from "modules/mapsv3/Roadmap/AlignedView/TreeRoot/Family/MapsStrategicPillar/StrategicPillarModals";
import { UltimateGoalModal } from "modules/mapsv3/Roadmap/MapsUltimateGoal/UltimateGoalModal/UltimateGoalModal";
import { AddTimeframeModal } from "modules/settings/company/timeframes/modals/AddTimeframeModal/AddTimeframeModal";
import { UserProfileEditModal } from "modules/users/[id]/UserProfile/UserProfileEditModal/UserProfileEditModal";
import { UserListModal } from "modules/users-list/UserListPage/UserListModal/UserListModal";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import { useConfirmationModal } from "../ConfirmationModal/hooks/useConfirmationModal";
import { GoalModal } from "../GoalModal/GoalModal";
import { ItemsListModal } from "../ItemsListModal/ItemsListModal";
import { useObjectiveAlignmentModal } from "../ObjectiveAlignmentModal/hooks/useObjectiveAlignmentModal";
import { ObjectiveAlignmentModal } from "../ObjectiveAlignmentModal/ObjectiveAlignmentModal";
import { ReloadModal } from "../ReloadModal/ReloadModal";
import { SalesModals } from "../SalesModals/SalesModals";
import { useUpdateOwnerAndAmbassadorsModal } from "../UpdateOwnerAndAmbassadorsModal/hooks/useUpdateOwnerAndAmbassadorsModal/useUpdateOwnerAndAmbassadorsModal";
import { UpdateOwnerAndAmbassadorsModal } from "../UpdateOwnerAndAmbassadorsModal/UpdateOwnerAndAmbassadorsModal";

// TODO: FIXME ESLint: Unexpected any. Specify a different type.(@typescript-eslint/no-explicit-any)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ModalProps = any;

type Modal = {
  component: (props: ModalProps) => ReactNode;
  type: keyof typeof MODAL_TYPES;
};

export const MODALS: Modal[] = [
  { component: UserProfileEditModal, type: MODAL_TYPES.EDIT_USER },
  { component: AddGroupModal, type: MODAL_TYPES.ADD_GROUP },
  { component: EditGroupModal, type: MODAL_TYPES.EDIT_GROUP },
  { component: AddObjectiveModal, type: MODAL_TYPES.ADD_OBJECTIVE },
  { component: CloseObjectiveModal, type: MODAL_TYPES.CLOSE_OBJECTIVE },
  { component: AddCadenceModal, type: MODAL_TYPES.ADD_CADENCE_MODAL },
  { component: AddTimeframeModal, type: MODAL_TYPES.ADD_TIMEFRAME_MODAL },
  { component: AddKpiModal, type: MODAL_TYPES.ADD_KPI },
  { component: AddTagModal, type: MODAL_TYPES.ADD_TAG },
  { component: EditTagModal, type: MODAL_TYPES.EDIT_TAG },
  { component: InviteUserModal, type: MODAL_TYPES.ADD_USER },
  { component: ExploreViewModal, type: MODAL_TYPES.EXPLORE_VIEW_MODAL },
  { component: UserListModal, type: MODAL_TYPES.USER_LIST },
  { component: CloneObjectiveModal, type: MODAL_TYPES.CLONE_OBJECTIVE },
  { component: EditCadenceModal, type: MODAL_TYPES.EDIT_CADENCE_MODAL },

  { component: ItemsListModal, type: MODAL_TYPES.ITEMS_LIST },
  { component: EditCompanyModal, type: MODAL_TYPES.EDIT_COMPANY },
  { component: AddResultModal, type: MODAL_TYPES.ADD_RESULT },
  { component: MoveResultModal, type: MODAL_TYPES.MOVE_RESULT },
  { component: ReloadModal, type: MODAL_TYPES.RELOAD_APP },
  { component: ArchiveResultModal, type: MODAL_TYPES.ARCHIVE_RESULT },
  {
    component: KpiSetTargetAddObjectiveModal,
    type: MODAL_TYPES.KPI_SET_TARGET_ADD_OBJECTIVE,
  },
  { component: UltimateGoalModal, type: MODAL_TYPES.ULTIMATE_GOAL },
  { component: UpsertKpiBoardModal, type: MODAL_TYPES.ADD_KPI_BOARD },
];

export const GlobalModals = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { query } = useRouter();
  const isProtectedRoute = useIsProtectedRoute();
  const inTeams = useInTeams();

  const { chatSales, kpiId, oId, rId, type, upgradeNow } = query;
  const { closeModal: closeReopenModal, modalData: reopenModalData } =
    useReopenObjectiveConfirmationModal();
  const { modalProps: confirmationModalProps } = useConfirmationModal();
  const { modalProps: updateOwnerAndAmbassadorsModalProps } =
    useUpdateOwnerAndAmbassadorsModal();
  const { modalProps: objectiveAlignmentModalProps } =
    useObjectiveAlignmentModal();

  const modalData = useSelector<ReduxState>((state) => state.globals.modals);
  if (!isProtectedRoute || inTeams) return null;
  return (
    <div>
      {MODALS.map((modal) => (
        <div key={modal.type}>
          {/* lodash cannot figure out the type after get() correctly, because modalData is untyped */}
          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
          {get(modalData, `${modal.type}.open`) && (
            <modal.component
              onRequestClosed={() => dispatch(hideModal(modal.type))}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...get(modalData, modal.type, {})}
            />
          )}
        </div>
      ))}
      <Show when={kpiId || oId || rId}>
        <GoalModal />
      </Show>
      <Show when={chatSales || upgradeNow}>
        <SalesModals />
      </Show>
      <Show when={type}>
        <PerformanceReviewModalBase />
        <OneOnOneMeetingModalBase />
        <ObjectiveModals />
        <StrategicPillarModals />
      </Show>
      {!!reopenModalData && (
        <ReopenObjectiveConfirmationModal
          objective={reopenModalData.objective}
          onClose={closeReopenModal}
        />
      )}
      {!!updateOwnerAndAmbassadorsModalProps && (
        <UpdateOwnerAndAmbassadorsModal
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...updateOwnerAndAmbassadorsModalProps}
        />
      )}
      {!!confirmationModalProps && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ConfirmationModal {...confirmationModalProps} />
      )}
      {!!objectiveAlignmentModalProps && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ObjectiveAlignmentModal {...objectiveAlignmentModalProps} />
      )}
    </div>
  );
};
