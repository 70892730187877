import React from "react";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { OwnerSelectOption } from "common/select/OwnerSelect/OwnerSelect.types";
import { useGroupCreation } from "hooks/useGroupCreation/useGroupCreation";
import { Select } from "legacy/components/Select/Select";
import {
  isNewSelectItem,
  SelectNewItem,
} from "legacy/components/Select/Select.types";
import { isSet } from "utils/isSet";

type Group = OwnerSelectOption;

type Props = {
  autoFocus?: boolean;
  creatable?: boolean;
  "data-cy"?: string;
  disabled?: boolean;
  errorText?: string;
  groups: Group[];
  /** for using inside form */
  name?: string;
  onChange: (g: Group[]) => void;
  selectedGroups: Group[];
};

export const GroupMultiSelect = ({
  autoFocus,
  creatable,
  "data-cy": dataCy,
  disabled,
  groups,
  name,
  onChange,
  selectedGroups,
  errorText,
}: Props): JSX.Element => {
  const { createGroup, isCreating } = useGroupCreation();

  const handleChange = (newGroups: Group[] | null) => {
    newGroups ??= [];
    onChange(newGroups);
  };

  const handleCreate = async (name: string) => {
    const newGroup = await createGroup(name);
    if (!newGroup) return;
    handleChange([...selectedGroups, newGroup]);
  };

  const getEntity = (group: Group | SelectNewItem) => {
    if (isNewSelectItem(group)) {
      if (!creatable) return null;
      return (
        <FormattedMessage
          defaultMessage="Create new group {name}"
          id="F6L858"
          values={{ name: group.value }}
        />
      );
    }

    return (
      <Entity avatar={group.avatar} avatarSize="extraSmall" name={group.name} />
    );
  };

  const search = (
    { data }: { data: Group | SelectNewItem },
    query: string
  ): boolean => {
    if (isNewSelectItem(data)) {
      if (!creatable) return false;
      return data.value.toLowerCase().includes(query.toLowerCase());
    }
    return data.name.toLowerCase().includes(query.toLowerCase());
  };

  return (
    <Select
      autoFocus={autoFocus}
      className="groupMultiSelect"
      closeMenuOnSelect={false}
      creatable={creatable}
      data-cy={dataCy ?? "groupMultiSelect"}
      disabled={disabled}
      filterOption={search}
      formatCreateLabel={(query: string) => (
        <FormattedMessage
          defaultMessage="Create new group {name}"
          id="F6L858"
          values={{ name: query }}
        />
      )}
      getOptionLabel={getEntity}
      getOptionValue={({ id }: Group) => id}
      isInForm={isSet(name)}
      isLoading={isCreating}
      isMulti
      isValidNewOption={(query: string) => query}
      name={name}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={groups}
      value={selectedGroups}
      errorText={errorText}
    />
  );
};
