import { namedOperations, useAddKpiMutation } from "@graphql";
import { useIntl } from "react-intl";
import { toast } from "utils/toastr";

export const useArchiveKpi = () => {
  const intl = useIntl();
  const [editKpi] = useAddKpiMutation({
    refetchQueries: [
      namedOperations.Query.getRoadmap,
      namedOperations.Query.getGrowthBoardKpis,
      namedOperations.Query.getKpiBoardKpis,
      namedOperations.Query.getUserOkrs,
      namedOperations.Query.reportKpisCount,
      namedOperations.Query.KpisByUser,
    ],
  });

  const archiveKpi = async (id: string) => {
    await editKpi({ variables: { input: { archived: true, id } } });
    toast.success(
      intl.formatMessage({ defaultMessage: "KPI archived", id: "368LsT" })
    );
  };

  const unarchiveKpi = async (id: string) => {
    await editKpi({ variables: { input: { archived: false, id } } });
    toast.success(
      intl.formatMessage({ defaultMessage: "KPI unarchived", id: "9zgI/G" })
    );
  };

  return { archiveKpi, unarchiveKpi };
};
