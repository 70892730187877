import { useContext } from "react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";
import { ReleaseContextRef } from "./ReleaseContextRef/ReleaseContextRef";

export const ReleaseAlert = () => {
  const { isCollapsed } = useContext(CollapsibleContext);
  const [showBanner, setShowBanner] = useState(false);
  React.useImperativeHandle(ReleaseContextRef, () => ({
    setShowBanner,
    showBanner,
  }));

  if (showBanner !== true) return null;

  return (
    <button onClick={() => window.location.reload()}>
      <WithTooltip
        placement="right"
        className="block"
        tooltip={
          <FormattedMessage
            defaultMessage="Click to refresh the page"
            id="DIYz9I"
          />
        }
      >
        <div
          className={twClass(
            "text-slate-300 bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-600",
            "rounded p-0.5 mx-2 text-sm bg-[length:200%] animate-gradient"
          )}
        >
          <div className="flex justify-between p-2 border bg-slate-800 border-slate-800 rounded">
            <div className="flex w-full space-x-1.5">
              <Icon className="text-yellow-500" name="new_releases" size="lg" />
              {!isCollapsed && (
                <div>
                  <FormattedMessage
                    defaultMessage="New version available"
                    id="dAOq4D"
                  />
                </div>
              )}
            </div>
            <Icon
              className="text-slate-300 animate-pulse"
              name="autorenew"
              size="lg"
            />
          </div>
        </div>
      </WithTooltip>
    </button>
  );
};
