import {
  PerformanceReviewActorStatusesFragment,
  PerformanceReviewRepeatFrequency,
  UserAvatarFragment,
  UserFullNameFragment,
} from "@graphql";
import { Field, useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Avatar } from "common/avatar";
import { Button } from "common/buttons";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { usePerformanceReviewFrequencyOptions } from "common/performanceReview/PerformanceReviewForm/usePerformanceReviewFrequencyOptions/usePerformanceReviewFrequencyOptions";
import { messages } from "./performanceReviewForm.messages";

export type EditPerformanceValues = {
  dueDate: string | null;
  id: string;
  repeatFrequency: PerformanceReviewRepeatFrequency;
};

type Reviewee = PerformanceReviewActorStatusesFragment & {
  user: (UserFullNameFragment & UserAvatarFragment) | null;
};

type EditPerformanceReviewFormProps = {
  onCancel: () => void;
  reviewee: Reviewee | null;
};

export const EditPerformanceReviewForm = ({
  reviewee,
  onCancel,
}: EditPerformanceReviewFormProps): JSX.Element => {
  const frequencyOptions = usePerformanceReviewFrequencyOptions();
  const intl = useIntl();
  const { isSubmitting, submitForm, handleBlur, values } =
    useFormikContext<EditPerformanceValues>();

  return (
    <>
      {reviewee && reviewee.user && (
        <div className="pb-1 pt-4">
          <span className="flex flex-grow items-center pb-1 text-sm">
            {intl.formatMessage(messages.reviewee)}
          </span>
          <div className="flex items-center space-x-2">
            <Avatar url={reviewee.user.avatar} />
            <span className="text-base font-normal text-slate-800">
              {reviewee.user.fullName}
            </span>
          </div>
        </div>
      )}
      <Field
        component={FormDatePicker}
        label={intl.formatMessage(messages.dueDate)}
        name="dueDate"
        onBlur={handleBlur("dueDate")}
        value={values.dueDate}
      />
      <Field
        component={FormSelect}
        data-testid="repeatFrequency"
        label={intl.formatMessage(messages.repeatFrequency)}
        name="repeatFrequency"
        options={frequencyOptions}
      />
      <ModalFooter>
        <Button
          data-cy="saveEditPerformanceReview"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={submitForm}
          type="submit"
        >
          <FormattedMessage defaultMessage="Save" id="global:Save" />
        </Button>
        <Button
          data-cy="OEsGnC306t0tz00PMbMOK"
          onClick={onCancel}
          type="button"
          variant="ghost"
        >
          <FormattedMessage defaultMessage="Cancel" id="global:cancel" />
        </Button>
      </ModalFooter>
    </>
  );
};
