import { flattenDeep, omit, size } from "lodash";
import { isSet } from "utils/isSet";

const routes = {
  checkIn: {
    complete: "/checkin/complete",
    main: "/checkin",
  },
  company: {
    main: "/company",
    okrs: "/company/okrs",
    progressReports: {
      base: "/company/progress-reports",
    },
    subGroups: {
      base: "/company/sub-groups",
    },
  },
  explore: {
    main: "/reports/custom",
  },
  exploreKpis: {
    list: "/reports/custom/kpis",
    main: "/reports/custom/kpis",
  },
  exploreObjectives: {
    list: "/reports/custom/objectives",
    main: "/reports/custom/objectives",
  },
  exploreResults: {
    list: "/reports/custom/results",
    main: "/reports/custom/results",
  },
  forgotPassword: "/forgot-password",
  groups: {
    list: "/groups-list",
    main: "/groups",
    single: {
      detail: "/groups/:id/detail",
      main: "/groups/:id",
      members: "/groups/:id/members",
      okrs: "/groups/:id/okrs",
      progressReports: {
        base: "/groups/:id/progress-reports",
        report: {
          base: "/groups/:id/progress-reports/:cstart",
        },
      },
      subGroups: {
        base: "/groups/:id/sub-groups",
      },
    },
  },
  growthBoard: {
    main: "/growth-board",
  },
  home: {
    main: "/home",
  },
  invitations: "/invitations/:id",
  kpiBoard: {
    board: "/kpi-boards/:id?",
    main: "/kpi-boards",
  },
  login: {
    callback: "/login/ssocallback",
    googlecallback: "/login/googlessocallback",
    jwt: "/login/jwt",
    main: "/login",
    msTeams: "/login/ms_teams",
    regular: "/login/regular", // ?access_token=:access_token&expires_in=:expires_in&id_token=:id_token&token_type=:token_type&state=:state",
    sso: "/login/sso",
    ssoSamlCallback: "/login/sso/callback",
  },
  loginAs: "/login_as",
  logout: "/logout",
  main: "/",
  mapsv3: {
    main: "/strategy/mapsv3",
  },
  msTeams: {
    authenticate: "/ms-teams/authenticate",
    base: "/ms-teams",
    checkIn: {
      base: "/ms-teams/check-in",
      complete: "/ms-teams/check-in/complete",
      form: "/ms-teams/check-in/form",
    },
    company: {
      main: "/ms-teams/company",
      okrs: "/ms-teams/company/okrs",
    },
    configureProfile: "/ms-teams/configure-profile",
    forgotPassword: "/ms-teams/login/forgot-password",
    groups: {
      main: "/ms-teams/groups",
      single: {
        main: "/ms-teams/groups/:id",
        okrs: "/ms-teams/groups/:id/okrs",
      },
    },
    login: {
      regular: "/ms-teams/login",
      sso: "/ms-teams/login/sso",
      ssoSamlCallback: "/ms-teams/login/sso/callback",
    },
    main: "/ms-teams/check-in",
  },
  peopleManagement: {
    main: "/people-management",
    oneOnOne: "/people-management/one-on-ones",
    performanceReviews: "/people-management/performance-reviews",
  },
  performanceReports: {
    main: "/reports/performance",
  },
  quickStart: {
    main: "/start",
  },
  reports: {
    main: "/reports/engagement",
  },
  resetPassword: "/reset-password/:id",
  settings: {
    company: {
      billing: "/settings/company/billing",
      cadences: "/settings/company/cadences",
      general: {
        base: "/settings/company/general",
        checkins: {
          base: "/settings/company/general/checkins",
          teams: "/settings/company/general/checkins/teams",
          users: "/settings/company/general/checkins/users",
        },
        features: "/settings/company/general/features",
        info: "/settings/company/general/info",
        notifications: "/settings/company/general/notifications",
        security: "/settings/company/general/security",
        tags: "/settings/company/general/tags",
        templates: "/settings/company/general/templates",
      },
      integrations: {
        api: "/settings/company/integrations/api",
        asana: "/settings/company/integrations/asana",
        base: "/settings/company/integrations",
        gsheets: "/settings/company/integrations/gsheets",
        hris: "/settings/company/integrations/hris",
        jira: "/settings/company/integrations/jira",
        powerBi: "/settings/company/integrations/power-bi",
        scim: "/settings/company/integrations/scim",
        slack: "/settings/company/integrations/slack",
        sso: "/settings/company/integrations/login-sso",
        teams: "/settings/company/integrations/teams",
        zapier: "/settings/company/integrations/zapier",
      },
      main: "/settings/company",
      timeframes: "/settings/company/timeframes",
    },
    main: "/settings",
    user: {
      access: "/settings/user/access",
      apiTokens: "/settings/user/api-tokens",
      integrations: "/settings/user/integrations",
      main: "/settings/user",
      notifications: "/settings/user/notifications",
      profile: "/settings/user/profile",
    },
  },
  strategy: {
    main: "/strategy",
  },
  timeline: {
    main: "/strategy/timeline",
  },
  user: {
    welcome: "/user/welcome",
  },
  users: {
    list: "/users-list",
    main: "/users",
    single: {
      directReports: "/users/:id/directReports",
      initiatives: "/users/:id/initiatives",
      keyResults: "/users/:id/keyresults",
      kudos: "/users/:id/kudos",
      main: "/users/:id",
      notfound: "users/:id/not-found",
      objectives: "/users/:id/objectives",
      okrs: "/users/:id/okrs",
      progressReports: {
        base: "/users/:id/progress-reports",
        report: {
          base: "/users/:id/progress-reports/:cstart",
        },
      },
      timeline: "/users/:id/timeline",
    },
  },
  welcome: "/welcome/:id",
};

const flattenObject = (myobj: Record<string, any>): any[] => {
  return Object.keys(myobj).map((key) => {
    const value = myobj[key];
    if (typeof value === "object") {
      return flattenObject(value);
    }
    return value;
  });
};

/* These are the paths that are allowed to be redirected to as we don't want to
redirect to arbitrary pathname once the user logs in. */
const redirectPaths = flattenDeep(flattenObject(omit(routes, "main")));

const formatLink = (
  link: string,
  data: Record<string, string | undefined> = {},
  query: Record<string, string | undefined> = {}
): string => {
  let formattedLink = link;
  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (isSet(value)) {
        formattedLink = formattedLink.replace(`:${key}`, value);
      }
    });
  }

  if (size(query) > 0) {
    formattedLink = `${formattedLink}?`;
    const keys = Object.keys(query);
    keys.forEach((key, index) => {
      formattedLink = `${formattedLink}${key}=${query[key]}${
        index < size(keys) - 1 ? "&" : ""
      }`;
    });
  }
  return formattedLink;
};

export type CompleteLink = {
  absolutePath: string;
  pathname: string;
  query: Record<string, string | undefined>;
  relative: string;
};

const getCompleteLink = (
  link: string,
  data: Record<string, string | undefined> = {},
  query: Record<string, string | undefined> = {}
): CompleteLink => {
  const pathname = formatLink(link, data);
  const relative = pathname[0] === "/" ? pathname.slice(1) : pathname;

  return {
    absolutePath: `${formatLink(link, data, query)}`,
    pathname,
    query,
    relative,
  };
};

const getRouteByType = (type: string): string => {
  switch (type) {
    case "user":
      return routes.users.single.okrs;
    case "company":
      return routes.company.okrs;
    case "group":
      return routes.groups.single.okrs;
    default:
      return "";
  }
};

const getPathByType = (type: string, id?: string): string =>
  formatLink(getRouteByType(type), { id });

const getLinkByType = getPathByType;

export {
  routes,
  redirectPaths,
  formatLink,
  getCompleteLink,
  getLinkByType,
  getPathByType,
};
