import {
  namedOperations,
  useDeleteReactionMutation,
  useGiveReactionMutation,
} from "@graphql";
import { REACTION_SET } from "constants/tracking";
import { useToggle } from "hooks/useToggle/useToggle";
import { track } from "utils/tracker";
import { showGqlErrorToast } from "utils/utils";

type Options = {
  source?: Source;
};

export type GiveReactionInput = {
  kudoId?: string | undefined;
  name: string | undefined;
};

export type RemoveReactionInput = {
  id: string | undefined;
};

type Hook = {
  giveReaction: (input: GiveReactionInput) => Promise<void>;
  loading: boolean;
  removeReaction: (input: RemoveReactionInput) => Promise<void>;
  toggleTooltipOpen: () => void;
  tooltipOpen: boolean;
};

type Source = "profile" | "home";

const SOURCE_TO_EVENT_SOURCE: Record<Source, string> = {
  home: "Home feed",
  profile: "Profile page",
};

export const useReaction = ({ source }: Options): Hook => {
  const [tooltipOpen, toggleTooltipOpen, setTooltipOpen] = useToggle(false);
  const [giveReactionMutation, { loading }] = useGiveReactionMutation({
    refetchQueries: [namedOperations.Query.getKudos],
  });
  const [removeReactionMutation] = useDeleteReactionMutation({
    refetchQueries: [namedOperations.Query.getKudos],
  });

  const giveReaction = async (input: GiveReactionInput) => {
    const response = await giveReactionMutation({
      refetchQueries: [namedOperations.Query.getKudoReaction],
      variables: {
        input: {
          kudo: input.kudoId ? [input.kudoId] : undefined,
          name: input.name,
        },
      },
    });

    if (response.errors) {
      showGqlErrorToast(response.errors);
    } else {
      if (source) {
        track(REACTION_SET, {
          source: SOURCE_TO_EVENT_SOURCE[source],
        });
      }
      setTooltipOpen(false);
    }
  };

  const removeReaction = async (input: RemoveReactionInput) => {
    await removeReactionMutation({
      refetchQueries: [namedOperations.Query.getKudoReaction],
      variables: {
        input: {
          id: input.id,
        },
      },
    });
  };

  return {
    giveReaction,
    loading,
    removeReaction,
    toggleTooltipOpen,
    tooltipOpen,
  };
};
