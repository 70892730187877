import { useGetUserAvatarQuery } from "@graphql";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { routes } from "route-configs";
import { Icon } from "common/icons";
import { ListItem } from "common/misc/ListItem/ListItem";
import { UserDropdown } from "common/user/UserDropdown/UserDropdown";
import { PermissionWrapper } from "common/wrappers";
import { useAddUserAction } from "hooks/useAddUserAction/useAddUserAction";
import { useLoadingState } from "hooks/useLoadingState/useLoadingState";

export const UserDropDown = (): JSX.Element | null => {
  const router = useRouter();
  const openInviteUserModal = useAddUserAction();

  const { data, loading, error } = useGetUserAvatarQuery();

  const state = useLoadingState({ data, error, loading });

  if (state.status !== "resolved" || !state.data) {
    return null;
  }

  const logout = () => {
    router.push(routes.logout);
  };

  return (
    <UserDropdown avatarUrl={state.data?.me.avatar}>
      <ListItem data-cy="user-settings" href={routes.settings.user.profile}>
        <Icon className="mr-2" name="account_box" size="2xl" />
        <FormattedMessage
          defaultMessage="Personal settings"
          id="settings:user:title"
        />
      </ListItem>
      <PermissionWrapper module="user">
        <ListItem data-cy="uHKOCqfSrngBmbpcI5oPS" onClick={openInviteUserModal}>
          <Icon className="mr-2" name="person_add" size="2xl" />
          <FormattedMessage defaultMessage="Add user" id="user:add" />
        </ListItem>
      </PermissionWrapper>
      <ListItem data-cy="1QESE_JA9kxqrqeB9MzCI" onClick={logout}>
        <Icon className="mr-2" name="power_settings_new" size="2xl" />
        <FormattedMessage defaultMessage="Log out" id="navbar:logout" />
      </ListItem>
    </UserDropdown>
  );
};
