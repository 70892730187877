import { useField } from "formik";
import React, { ReactNode } from "react";
import { ObjectiveSelectFilters } from "common/inputs/ObjectiveSelect/hooks/useObjectiveSelect";
import { ObjectiveSelect } from "common/inputs/ObjectiveSelect/ObjectiveSelect";
import { FormControl } from "../FormControl/FormControl";

type Props = {
  autoFocus?: boolean;
  "data-cy"?: string;
  disabled?: boolean;
  filters?: ObjectiveSelectFilters;
  label?: ReactNode;
  name: string;
  onChange?: (id: string) => void;
  placeholder?: ReactNode;
};

export const ObjectiveField = ({
  name,
  filters,
  autoFocus,
  disabled,
  onChange,
  label,
  "data-cy": dataCy,
  placeholder,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<string>(name);
  const handleChange = (id: string) => {
    helpers.setValue(id);
    onChange?.(id);
  };

  return (
    <FormControl label={label} errorText={meta.error}>
      <ObjectiveSelect
        filters={filters}
        onChange={handleChange}
        value={field.value}
        autoFocus={autoFocus}
        disabled={disabled}
        hasError={!!meta.error}
        data-cy={dataCy}
        placeholder={placeholder}
      />
    </FormControl>
  );
};
