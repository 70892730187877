import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Icon } from "common/icons";
import { AllMetrics } from "constants/metric";
import { useCompany } from "hooks/useCompany/useCompany";
import { twClass } from "utils/twClass";
import {
  BillingPeriod,
  PaidPlans,
  Plans,
  SupportedCurrencies,
} from "../UpgradeModal.types";
import { monthlyPricePerUser } from "../utils";
import { FeatureList } from "./FeatureList";

type Props = {
  billingPeriod: BillingPeriod;
  className?: string;
  currency: SupportedCurrencies;
  "data-cy"?: string;
  featuresHeading: ReactNode;
  onChoosePlan?: (plan: PaidPlans) => void;
  plan: Plans;
  subtitle: ReactNode;
  title: ReactNode;
};

export const PricingColumn = ({
  title,
  currency,
  plan,
  subtitle,
  billingPeriod,
  onChoosePlan,
  "data-cy": dataCy,
  className,
  featuresHeading,
}: Props) => {
  const intl = useIntl();
  const company = useCompany();

  return (
    <>
      <div className={twClass("divide-y", className)} data-cy={dataCy}>
        <div className="relative text-center flex flex-grow flex-col items-center py-4 space-y-4">
          <p className="text-xl font-semibold text-slate-800">{title}</p>
          <div className="flex items-center justify-center space-x-3">
            <p className="text-4xl text-slate-800">
              {AllMetrics[currency].symbol}
              {intl.formatNumber(
                monthlyPricePerUser(currency, billingPeriod, plan, company.mpq),
                {
                  minimumFractionDigits: 2,
                }
              )}
            </p>
            <p className="text-left text-sm text-slate-800">
              <FormattedMessage
                defaultMessage="per user{linebreak}per month"
                id="upgrade:modal:perUser:info"
                values={{
                  linebreak: <br />,
                }}
              />
            </p>
          </div>
          {plan === "free" ? (
            <Button className="h-10 hover:bg-blue-500" disabled variant="ghost">
              <div className="flex space-x-2">
                <Icon
                  size="md"
                  className="text-green-500"
                  name="check_circle_outline"
                />
                <div className="text-slate-800">
                  <FormattedMessage defaultMessage="Active plan" id="W2W9lu" />
                </div>
              </div>
            </Button>
          ) : (
            <Button
              className="h-10"
              data-cy="upgradeButton"
              onClick={() => onChoosePlan?.(plan)}
            >
              <FormattedMessage
                defaultMessage="Upgrade to {plan}"
                id="upgrade:modal:upgradeTo"
                values={{
                  plan: title,
                }}
              />
            </Button>
          )}
          <p className="text-sm text-slate-500">{subtitle}</p>
        </div>
        <div className="p-4 space-y-3">
          <p className="text-sm font-semibold text-slate-800">
            {featuresHeading}
          </p>
          <FeatureList plan={plan} />
        </div>
      </div>
    </>
  );
};
