import { PerdooApiIntegrationApplicationChoices } from "@graphql";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons";
import { Anchor } from "common/navigation/Anchor/Anchor";

type Props = {
  integration: PerdooApiIntegrationApplicationChoices | undefined;
};

export const IntegrationLinkWarning = ({
  integration,
}: Props): JSX.Element | null => {
  if (integration === PerdooApiIntegrationApplicationChoices.Asana) {
    return (
      <div className="flex items-center text-slate-500">
        <Icon className="mr-2" name="help" />
        <span>
          <FormattedMessage
            defaultMessage="This result is linked to an Asana project/task. <a>Learn more</a>"
            id="resultForm:asana:disabledFields:helpText"
            values={{
              a: (children: ReactNode) => (
                <Anchor href="https://support.perdoo.com/en/articles/5170427-asana-integration">
                  {children}
                </Anchor>
              ),
            }}
          />
        </span>
      </div>
    );
  }
  return null;
};
