import { ReactionDefaultFragment } from "@graphql";
import { map } from "lodash";
import React from "react";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { isSet } from "utils/isSet";
import { getNativeEmoji } from "./Emoji/Emoji.utils";
import { EmojiButton } from "./Emoji/EmojiButton";
import { useGetSlackEmojis } from "./hook/useGetSlackEmojis/useGetSlackEmojis";
import { useReaction } from "./hook/useReaction/useReaction";
import { GroupedReactions } from "./Reaction.types";

interface Props {
  allReactions: GroupedReactions;
  entityId: string;
}

export const Reactions = ({ entityId, allReactions }: Props): JSX.Element => {
  const me = useCurrentUser();

  const { emojis } = useGetSlackEmojis();
  const { giveReaction, removeReaction } = useReaction({});

  const givenReactions = map(allReactions, (reactions, emojiId) => {
    const userReaction = reactions.find(
      (reaction: ReactionDefaultFragment) => reaction.author.id === me?.id
    );
    const nativeEmoji = getNativeEmoji(emojiId);
    return (
      <EmojiButton
        key={emojiId}
        native={nativeEmoji}
        onDeselect={() => {
          if (userReaction) {
            removeReaction({
              id: userReaction.id,
            });
          }
        }}
        onSelect={() =>
          giveReaction({
            kudoId: entityId,
            name: emojiId,
          })
        }
        reactionCount={reactions.length}
        selected={isSet(userReaction)}
        src={emojis[emojiId]}
      />
    );
  });

  return (
    <div className="my-1 flex flex-wrap items-center">{givenReactions}</div>
  );
};
