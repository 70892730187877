import React from "react";
import { OneOnOneMeetingItemTemplateForm } from "./OneOnOneMeetingItemTemplateForm";

type Props = {
  close: () => void;
};

export const AddMeetingItemTemplateForm = ({ close }: Props): JSX.Element => {
  return (
    <OneOnOneMeetingItemTemplateForm
      close={close}
      initialValues={{
        description: "",
        name: "",
        talkingPoints: [""],
      }}
    />
  );
};
