import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { HELP_CENTER_VIEW } from "constants/tracking";
import { useToggle } from "hooks/useToggle/useToggle";
import { track } from "utils/tracker";
import { DefaultInfoView } from "../DefaultInfoView/DefaultInfoView";

type HelpCenterContext = {
  helpCenterContent: HelpCenterContent;
  isHelpCenterVisible: boolean;
  setHelpCenterContent: (content: HelpCenterContent) => void;
  toggleHelpCenterVisible: () => void;
};

const HelpCenterContext = React.createContext<HelpCenterContext>({
  helpCenterContent: { element: <DefaultInfoView />, type: "default" },
  isHelpCenterVisible: false,
  setHelpCenterContent: () => {},
  toggleHelpCenterVisible: () => {},
});

export type HelpCenterContentType =
  | "default"
  | "cadences"
  | "timeframes"
  | "results"
  | "status_and_progress"
  | "slack_integration";

export type HelpCenterContent = {
  element: ReactElement;
  type: HelpCenterContentType;
};

export const HelpCenterContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [isHelpCenterVisible, toggleHelpCenterVisible] = useToggle(false);
  const [helpCenterContent, setHelpCenterContent] = useState<HelpCenterContent>(
    {
      element: <DefaultInfoView />,
      type: "default",
    }
  );

  useEffect(() => {
    if (isHelpCenterVisible) {
      track(HELP_CENTER_VIEW, { type: helpCenterContent.type });
    }
  }, [isHelpCenterVisible, helpCenterContent.type]);

  return (
    <HelpCenterContext.Provider
      value={{
        helpCenterContent,
        isHelpCenterVisible,
        setHelpCenterContent,
        toggleHelpCenterVisible,
      }}
    >
      {children}
    </HelpCenterContext.Provider>
  );
};

type Hook = {
  helpCenterContent: HelpCenterContent;
  isHelpCenterVisible: boolean;
  setHelpCenter: (content: HelpCenterContent) => void;
  toggleHelpCenter: () => void;
};

export const useHelpCenter = (): Hook => {
  const {
    helpCenterContent,
    setHelpCenterContent,
    isHelpCenterVisible,
    toggleHelpCenterVisible,
  } = useContext(HelpCenterContext);

  return {
    helpCenterContent,
    isHelpCenterVisible,
    setHelpCenter: setHelpCenterContent,
    toggleHelpCenter: toggleHelpCenterVisible,
  };
};
