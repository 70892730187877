import { useGetAllTagsQuery } from "@graphql";
import { size, truncate } from "lodash";
import React from "react";
import { Tag } from "common/tag/Tag/Tag";
import { colorTheme } from "constants/colorTheme";
import { isSet } from "utils/isSet";

type Props = {
  ids?: string[];
};

type Tag = {
  color?: string | null;
  id: string;
  name: string;
};

const NO_OF_TAGS_TO_DISPLAY = 5;

export const ObjectiveTagsNames = ({ ids }: Props): JSX.Element | null => {
  const { data } = useGetAllTagsQuery();

  if (!data?.allTags || !ids) return null;

  const tags = data.allTags.edges.reduce(
    (memo, { node }) => ({
      ...memo,
      [node.id]: node,
    }),
    {} as Record<string, Tag>
  );

  return (
    <div className="ml-4 flex">
      <div className="flex items-center">
        {ids
          .filter((id) => isSet(tags[id]))
          .slice(0, NO_OF_TAGS_TO_DISPLAY - 1)
          .map((id) => (
            <Tag
              key={id}
              className="mr-2"
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              color={tags[id].color || colorTheme.slate[300]}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              text={truncate(tags[id].name, { length: 12 })}
            />
          ))}
      </div>
      {size(ids) > 5 && (
        <div className="ml-1 mr-2 flex h-9 w-9 items-center justify-center self-start rounded-full bg-slate-200">
          +{size(ids) - NO_OF_TAGS_TO_DISPLAY}
        </div>
      )}
    </div>
  );
};
