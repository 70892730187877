type Props = {
  children: JSX.Element;
  condition?: boolean;
  wrapper: (children: JSX.Element) => JSX.Element;
};

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: Props): JSX.Element => (condition ? wrapper(children) : children);
