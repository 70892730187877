import {
  GroupArchivedFragment,
  GroupDefaultFragment,
  GroupLeaderFragment,
} from "@graphql";
import React from "react";
import { useIntl } from "react-intl";
import { getCompleteLink, routes } from "route-configs";
import { Entity } from "common/avatar";
import { Icon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { Modal } from "common/overlay/Modal/Modal";
import { messages } from "./SubGroupsModal.messages";

export type SubGroup = GroupDefaultFragment &
  GroupArchivedFragment &
  GroupLeaderFragment;

type SubGroupsModalProps = {
  open: boolean;
  parentGroup: string;
  subGroups: SubGroup[];
  toggleOpen: () => void;
};

export const SubGroupsModal = ({
  open,
  toggleOpen,
  subGroups,
  parentGroup,
}: SubGroupsModalProps): JSX.Element | null => {
  const intl = useIntl();

  return (
    <Modal
      isOpen={open}
      onClose={toggleOpen}
      size="sm"
      title={intl.formatMessage(messages.title, { title: parentGroup })}
    >
      <div className="mt-3">
        {subGroups.map((subGroup) => (
          <div
            key={subGroup.id}
            className="flex items-center justify-between border-t border-slate-300"
          >
            <AnchorNext
              href={
                getCompleteLink(routes.groups.single.okrs, {
                  id: subGroup.id,
                }).absolutePath
              }
            >
              <div className="min-h-10 p-3 py-4 text-base text-slate-800 hover:text-blue-500">
                <Entity avatar={subGroup.avatar} name={subGroup.name} />
              </div>
            </AnchorNext>
            {subGroup.groupLeader && (
              <div className="flex items-center space-x-0.5 text-sm text-slate-500 hover:text-blue-500">
                <Icon name="person" size="sm" />
                <AnchorNext
                  className="text-slate-500 hover:text-blue-500"
                  href={
                    getCompleteLink(routes.users.single.okrs, {
                      id: subGroup.groupLeader.id,
                    }).absolutePath
                  }
                >
                  {subGroup.groupLeader.fullName}
                </AnchorNext>
              </div>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};
