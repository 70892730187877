import React, { ReactNode } from "react";
import { ItemSize } from "common/misc/types";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twMerge } from "utils/twMerge";

export const badgeSize = {
  extraLarge: "h-6 w-8",
  extraSmall: "h-0",
  fullWidth: "h-6 w-8",
  large: "h-[14px] w-[20px]",
  medium: "h-[14px] w-[20px]",
  small: "h-0",
};
interface IconBadgeProps {
  children: ReactNode;
  className?: string;
  size: keyof typeof ItemSize;
  tooltip?: ReactNode;
}

export const IconBadge = ({
  size,
  className,
  tooltip,
  children,
}: IconBadgeProps): JSX.Element => {
  return (
    <WithTooltip placement="top" tooltip={tooltip}>
      <div
        className={twMerge(
          "flex items-center justify-center",
          className,
          badgeSize[size]
        )}
      >
        {children}
      </div>
    </WithTooltip>
  );
};
