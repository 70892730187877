import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const PanelHeader = ({ className, children }: Props): JSX.Element => (
  <div
    className={twClass(
      "flex items-center justify-between",
      "space-x-2 px-5 py-3",
      "text-base font-semibold",
      className
    )}
  >
    {children}
  </div>
);
