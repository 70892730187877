import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const ReportDrilldown = ({
  children,
  className,
}: Props): JSX.Element => {
  return (
    <div
      className={twClass("flex h-full min-h-half w-full flex-col", className)}
    >
      {children}
    </div>
  );
};
