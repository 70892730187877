import {
  namedOperations,
  useGetGroupQuery,
  useUpsertGroupMutationMutation,
} from "@graphql";
import { FormikHelpers } from "formik";
import { uniq } from "lodash";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { GroupForm, GroupFormValues } from "common/group/GroupForm/GroupForm";
import { ImageUploadInput } from "common/inputs/ImageUploadInput/ImageUploadInput";
import { Modal } from "common/overlay/Modal/Modal";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { objectTypes } from "constants/objects";
import { setFormikGqlErrors } from "utils/forms";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";

const messages = defineMessages({
  editTitle: {
    defaultMessage: "Edit team",
    id: "I8Hyi4",
  },
});

interface Props {
  id: string;
  onRequestClosed: () => void;
  open: boolean;
}

export const EditGroupModal = ({
  id,
  open,
  onRequestClosed,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { data } = useGetGroupQuery({
    variables: { id },
  });
  const [editGroup] = useUpsertGroupMutationMutation({
    refetchQueries: [namedOperations.Query.fetchGroups],
  });
  if (!data?.group) {
    return <Spinner />;
  }
  const { group } = data;
  const onSubmit = (
    values: GroupFormValues,
    actions: FormikHelpers<GroupFormValues>
  ) => {
    editGroup({
      refetchQueries: [
        namedOperations.Query.getCurrentUserInfo,
        namedOperations.Query.UserGroups,
      ],
      variables: {
        input: {
          ...values,
          id,
        },
      },
    }).then((response) => {
      actions.setSubmitting(false);
      const errors = response.data?.upsertGroup?.errors ?? [];
      if (errors.length) {
        setFormikGqlErrors(actions, errors);
        return;
      }

      toast.success("Group has been updated!");
      onRequestClosed();
    });
  };
  const { avatar, name, mission, kpisEnabled, slackChannel, allParents } =
    group;
  const avatarElement = (
    <ImageUploadInput
      avatar={avatar}
      entityId={id}
      entityType={objectTypes.group}
      inline
    />
  );
  const subGroups = group.subGroups.edges.map(({ node }) => node.id);
  const excludeGroups = uniq(
    mapEdges(allParents.edges).map((group) => group.id)
  ).filter((id) => !subGroups.includes(id));

  const initialValues = {
    cadence: group.cadence?.id,
    groupLeader: group.groupLeader?.id,
    kpisEnabled,
    members: group.members.edges.map(({ node }) => node.id),
    mission,
    name,
    slackChannel,
    subGroups,
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage(messages.editTitle)}
    >
      <GroupForm
        avatarUpload={avatarElement}
        groupId={id}
        initialValues={initialValues}
        isEdit
        excludeGroups={excludeGroups}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
