import dayjs from "dayjs";

// Any newly added timezone needs to be supported by Python's pytz library
// `pytz.all_timezones` and by Postgres (`SELECT * FROM pg_timezone_names;`).
const options = [
  { label: "Pago Pago", value: "Pacific/Pago_Pago" },
  { label: "Hawaii Time", value: "Pacific/Honolulu" },
  { label: "Pacific Time", value: "America/Los_Angeles" },
  { label: "Pacific Time - Tijuana", value: "America/Tijuana" },
  { label: "Mountain Time", value: "America/Denver" },
  { label: "Mountain Time - Arizona", value: "America/Phoenix" },
  {
    label: "Mountain Time - Chihuahua, Mazatlan",
    value: "America/Mazatlan",
  },
  { label: "Central Time", value: "America/Chicago" },
  {
    label: "Central Time - Mexico City",
    value: "America/Mexico_City",
  },
  { label: "Central Time - Regina", value: "America/Regina" },
  { label: "Guatemala", value: "America/Guatemala" },
  { label: "Bogota", value: "America/Bogota" },
  { label: "Eastern Time", value: "America/New_York" },
  { label: "Lima", value: "America/Lima" },
  { label: "Caracas", value: "America/Caracas" },
  { label: "Atlantic Time - Halifax", value: "America/Halifax" },
  { label: "Guyana", value: "America/Guyana" },
  { label: "La Paz", value: "America/La_Paz" },
  {
    label: "Buenos Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "Godthab", value: "America/Godthab" },
  { label: "Montevideo", value: "America/Montevideo" },
  {
    label: "Newfoundland Time - St. Johns",
    value: "America/St_Johns",
  },
  { label: "Santiago", value: "America/Santiago" },
  { label: "Sao Paulo", value: "America/Sao_Paulo" },
  { label: "South Georgia", value: "Atlantic/South_Georgia" },
  { label: "Azores", value: "Atlantic/Azores" },
  { label: "Cape Verde", value: "Atlantic/Cape_Verde" },
  { label: "Casablanca", value: "Africa/Casablanca" },
  { label: "UTC", value: "UTC" },
  { label: "Dublin", value: "Europe/Dublin" },
  { label: "Lisbon", value: "Europe/Lisbon" },
  { label: "London", value: "Europe/London" },
  { label: "Monrovia", value: "Africa/Monrovia" },
  { label: "Algiers", value: "Africa/Algiers" },
  { label: "Amsterdam", value: "Europe/Amsterdam" },
  { label: "Berlin", value: "Europe/Berlin" },
  { label: "Brussels", value: "Europe/Brussels" },
  { label: "Budapest", value: "Europe/Budapest" },
  {
    label: "Central European Time - Belgrade",
    value: "Europe/Belgrade",
  },
  {
    label: "Central European Time - Prague",
    value: "Europe/Prague",
  },
  { label: "Copenhagen", value: "Europe/Copenhagen" },
  { label: "Madrid", value: "Europe/Madrid" },
  { label: "Paris", value: "Europe/Paris" },
  { label: "Rome", value: "Europe/Rome" },
  { label: "Stockholm", value: "Europe/Stockholm" },
  { label: "Vienna", value: "Europe/Vienna" },
  { label: "Warsaw", value: "Europe/Warsaw" },
  { label: "Athens", value: "Europe/Athens" },
  { label: "Bucharest", value: "Europe/Bucharest" },
  { label: "Cairo", value: "Africa/Cairo" },
  { label: "Jerusalem", value: "Asia/Jerusalem" },
  { label: "Johannesburg", value: "Africa/Johannesburg" },
  { label: "Helsinki", value: "Europe/Helsinki" },
  { label: "Kyiv", value: "Europe/Kiev" },
  { label: "Moscow-01 - Kaliningrad", value: "Europe/Kaliningrad" },
  { label: "Riga", value: "Europe/Riga" },
  { label: "Sofia", value: "Europe/Sofia" },
  { label: "Tallinn", value: "Europe/Tallinn" },
  { label: "Vilnius", value: "Europe/Vilnius" },
  { label: "Istanbul", value: "Europe/Istanbul" },
  { label: "Baghdad", value: "Asia/Baghdad" },
  { label: "Nairobi", value: "Africa/Nairobi" },
  { label: "Minsk", value: "Europe/Minsk" },
  { label: "Riyadh", value: "Asia/Riyadh" },
  { label: "Moscow+00 - Moscow", value: "Europe/Moscow" },
  { label: "Tehran", value: "Asia/Tehran" },
  { label: "Baku", value: "Asia/Baku" },
  { label: "Moscow+01 - Samara", value: "Europe/Samara" },
  { label: "Tbilisi", value: "Asia/Tbilisi" },
  { label: "Yerevan", value: "Asia/Yerevan" },
  { label: "Kabul", value: "Asia/Kabul" },
  { label: "Karachi", value: "Asia/Karachi" },
  {
    label: "Moscow+02 - Yekaterinburg",
    value: "Asia/Yekaterinburg",
  },
  { label: "Tashkent", value: "Asia/Tashkent" },
  { label: "Colombo", value: "Asia/Colombo" },
  { label: "Almaty", value: "Asia/Almaty" },
  { label: "Dhaka", value: "Asia/Dhaka" },
  { label: "Rangoon", value: "Asia/Rangoon" },
  { label: "Bangkok", value: "Asia/Bangkok" },
  { label: "Jakarta", value: "Asia/Jakarta" },
  { label: "Moscow+04 - Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { label: "China Time - Beijing", value: "Asia/Shanghai" },
  { label: "Hong Kong", value: "Asia/Hong_Kong" },
  { label: "Kuala Lumpur", value: "Asia/Kuala_Lumpur" },
  { label: "Moscow+05 - Irkutsk", value: "Asia/Irkutsk" },
  { label: "Singapore", value: "Asia/Singapore" },
  { label: "Taipei", value: "Asia/Taipei" },
  { label: "Ulaanbaatar", value: "Asia/Ulaanbaatar" },
  { label: "Western Time - Perth", value: "Australia/Perth" },
  { label: "Moscow+06 - Yakutsk", value: "Asia/Yakutsk" },
  { label: "Seoul", value: "Asia/Seoul" },
  { label: "Tokyo", value: "Asia/Tokyo" },
  { label: "Central Time - Darwin", value: "Australia/Darwin" },
  { label: "Eastern Time - Brisbane", value: "Australia/Brisbane" },
  { label: "Guam", value: "Pacific/Guam" },
  { label: "Moscow+07 - Magadan", value: "Asia/Magadan" },
  {
    label: "Moscow+07 - Yuzhno-Sakhalinsk",
    value: "Asia/Vladivostok",
  },
  { label: "Port Moresby", value: "Pacific/Port_Moresby" },
  { label: "Central Time - Adelaide", value: "Australia/Adelaide" },
  { label: "Eastern Time - Hobart", value: "Australia/Hobart" },
  {
    label: "Eastern Time - Melbourne, Sydney",
    value: "Australia/Sydney",
  },
  { label: "Guadalcanal", value: "Pacific/Guadalcanal" },
  { label: "Noumea", value: "Pacific/Noumea" },
  { label: "Majuro", value: "Pacific/Majuro" },
  {
    label: "Moscow+09 - Petropavlovsk-Kamchatskiy",
    value: "Asia/Kamchatka",
  },
  { label: "Auckland", value: "Pacific/Auckland" },
  { label: "Fakaofo", value: "Pacific/Fakaofo" },
  { label: "Fiji", value: "Pacific/Fiji" },
  { label: "Tongatapu", value: "Pacific/Tongatapu" },
  { label: "Apia", value: "Pacific/Apia" },
];

export type TimezoneOption = {
  label: string;
  value: string;
};

export const getTimezoneOptions = (): TimezoneOption[] => {
  const timezones = options.map(({ label, value }) => {
    const offset = dayjs().tz(value).format("Z");
    return {
      label: `${label} (GMT${offset})`,
      value,
    };
  });
  return timezones;
};
