import React from "react";
import { FormattedMessage } from "react-intl";
import { StackedAvatar } from "common/avatar";
import { useObjectiveContext } from "common/context/objectiveContext";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { mapEdges } from "utils/mapEdges";

export const ObjectivePrivacy = () => {
  const objective = useObjectiveContext();
  const privateToUsers = mapEdges(objective.privateToUsers?.edges);
  return (
    <div className="flex space-x-3 items-center text-slate-800">
      <WithTooltip
        tooltip={privateToUsers.map((user) => user.fullName).join(", ")}
      >
        <StackedAvatar
          maxCount={2}
          size="small"
          urls={privateToUsers.map((user) => user.avatar)}
        />
      </WithTooltip>
      <div>
        <FormattedMessage
          defaultMessage="{contributors} users"
          id="+o7pXc"
          values={{
            contributors: privateToUsers.length,
          }}
        />
      </div>
    </div>
  );
};
