import { useField } from "formik";
import React from "react";
import {
  RadioSelect,
  RadioSelectProps,
} from "../../inputs/RadioSelect/RadioSelect";

type Props = Omit<RadioSelectProps, "selected"> & {
  name: string;
  selectValue: string;
};
export const RadioSelectField = ({
  selectValue,
  disabled,
  onClick,
  name,
  ...other
}: Props): JSX.Element => {
  const [field, , helpers] = useField({ name });
  const handleChange = (selected: boolean) => {
    if (!disabled) {
      helpers.setValue(selectValue);
      onClick?.(selected);
    }
  };

  return (
    <RadioSelect
      data-cy="EyEVP7cVqsdFEw_jXb1VT"
      {...other}
      disabled={disabled}
      onClick={handleChange}
      selected={field.value === selectValue}
    />
  );
};
