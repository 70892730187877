import { useGetStrategicPillarsQuery } from "@graphql";
import { FieldInputProps, FormikProps } from "formik";
import { camelCase, get } from "lodash";
import React from "react";
import { objectTypes } from "constants/objects";
import { Select } from "legacy/components/Select/Select";

type Option = {
  id: string;
  label: string;
};

type Props = {
  autoFocus?: boolean;
  "data-cy"?: string;
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  isGql: boolean;
  label?: string;
  optional?: boolean;
  placeholder?: string;
  styles?: any;
};

export const FormStrategicPillarSelect = ({
  field,
  form: { errors, submitCount },
  optional = false,
  label = "",
  isGql,
  autoFocus,
  "data-cy": dataCy,
  placeholder,
  styles,
}: Props): JSX.Element => {
  const { data, loading } = useGetStrategicPillarsQuery();
  const options: Option[] =
    data?.allStrategicPillars.edges.map(({ node }) => ({
      id: node.id,
      label: node.name,
    })) || [];

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selected' implicitly has an 'any' type.
  const handleChange = (selected) => {
    const value = isGql
      ? selected?.id
      : {
          ...selected,
          type: objectTypes.strategicPillar,
        };

    field.onChange({
      target: {
        name: field.name,
        value: selected ? value : null,
      },
    });
  };
  const errorText = submitCount > 0 && get(errors, field.name);
  const fieldValue = isGql ? field.value : field.value.id;
  const value = options.find((option) => option.id === fieldValue);

  return (
    <Select
      autoFocus={autoFocus}
      data-cy={dataCy || camelCase(label)}
      errorText={errorText}
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
      getNewOptionData={(val) => val}
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
      getOptionValue={({ id }) => id}
      id={field.name}
      isClearable={optional}
      isLoading={loading}
      label={label}
      name={field.name}
      onBlur={field.onBlur}
      onChange={handleChange}
      optional={optional}
      options={options}
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
      promptTextCreator={(val) => val}
      value={value}
      placeholder={placeholder}
      styles={styles}
    />
  );
};
