import React, { ReactNode, useState } from "react";
import { createNonNullableCtx } from "common/context/utils";
import { RefetchQueries } from "utils/graphql/types";
import { View } from "./constants";

export type ViewToggleContextType = NonNullable<{
  currentView: View;
  page: string;
  selectedTfId?: string;
  setCurrentView: (view: View) => void;
  setSelectedTfId: (tf?: string) => void;
}>;

export const [useViewToggleContext, ViewToggleContextProvider] =
  createNonNullableCtx<ViewToggleContextType>();

interface Props {
  children: ReactNode;
  page?: "home" | "profile";
  view?: View;
}

export const ViewToggleProvider = ({
  children,
  view = "folded",
  page = "profile",
}: Props): JSX.Element => {
  const [selectedTfId, setSelectedTfId] = useState<string | undefined>();
  const [currentView, setCurrentView] = useState<View>(view);

  return (
    <ViewToggleContextProvider
      value={{
        currentView,
        page,
        selectedTfId,
        setCurrentView,
        setSelectedTfId,
      }}
    >
      {children}
    </ViewToggleContextProvider>
  );
};

type Context = {
  reFetchQueries: RefetchQueries;
  showGroups?: boolean;
};

export const ProfileOkrListContext = React.createContext<Context>({
  reFetchQueries: [],
  showGroups: false,
});
