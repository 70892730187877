import React from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { twClass } from "utils/twClass";
import { useHelpCenter } from "../hooks/useHelpCenter";

export const Header = (): JSX.Element => {
  const { toggleHelpCenter } = useHelpCenter();

  return (
    <div
      className={twClass(
        "sticky top-0 z-tooltip-prio bg-white",
        "flex items-center justify-between",
        "border-b px-5 py-4"
      )}
      data-cy="helpCenterHeader"
    >
      <h5 className="my-0">
        <FormattedMessage defaultMessage="Help & Support" id="navbar:help" />
      </h5>
      <IconButton
        data-cy="haVw_-imPrGct9xDUlVM6"
        className="text-slate-400"
        name="close"
        onClick={toggleHelpCenter}
      />
    </div>
  );
};
