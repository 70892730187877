import { useField } from "formik";
import { isNil } from "lodash";
import React from "react";
import { ValueType } from "react-select";
import { useJiraFormContext } from "common/integration/JiraFormLogic/JiraFormLogic";
import { Select } from "legacy/components/Select/Select";
import { OptionType } from "legacy/components/Select/Select.types";
import { useJiraProgressTypeOptions } from "./utils/useJiraProgressTypeOptions/useJiraProgressTypeOptions";

type Props = {
  disabled?: boolean;
  includeEpicOptions?: boolean;
  label?: string;
  name?: string;
};

export const JiraProgressTypeField = ({
  includeEpicOptions = true,
  name = "jiraProgressType",
  disabled = false,
  label,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const { issue } = useJiraFormContext();

  const shouldIncludeEpicOptions = includeEpicOptions || !!issue?.isEpic;
  const options = useJiraProgressTypeOptions({
    includeEpicOptions: shouldIncludeEpicOptions,
  });

  const forwardOnChange = (opt: ValueType<OptionType, false>) => {
    helpers.setValue(opt?.value);
  };

  const errorText = meta.error;
  const selectedOption = options.find((x) => x.value === field.value) ?? null;
  return (
    <Select
      disabled={disabled || isNil(issue) || !issue.isEpic}
      errorText={errorText}
      isClearable
      isInForm
      label={label}
      onChange={forwardOnChange}
      options={options}
      value={selectedOption}
    />
  );
};
