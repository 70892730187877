import React from "react";
import { defineMessages, useIntl } from "react-intl";
import asanaTaskIcon from "../../../../public/images/asana_task_icon.svg";

const messages = defineMessages({
  icon: {
    defaultMessage: "Asana Task Icon",
    id: "asana:task:icon:alt",
  },
});

export const AsanaTaskIcon = (): JSX.Element => {
  const intl = useIntl();
  const alt = intl.formatMessage(messages.icon);
  return <img alt={alt} className="h-4 w-4" src={asanaTaskIcon.src} />;
};
