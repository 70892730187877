import React from "react";
import { useIntl } from "react-intl";
import { Button } from "common/buttons";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { ReportEntityAction } from "common/reports/utils/types";

type Props<Entity> = {
  actions?: ReportEntityAction<Entity>[];
  entity: Entity;
};

/**
 * renders buttons with actions related to an individual report entity (user / group).
 */
export const ReportActions = <Entity extends { id: string }>({
  actions,
  entity,
}: Props<Entity>): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      {actions?.map((action) => (
        <WithTooltip key={action.id} tooltip={action.tooltip}>
          <Button
            data-cy="XGb7A7jN-1IHQKV_yyUDk"
            key={action.id}
            onClick={action.handler(entity)}
            size="small"
            variant="ghost"
          >
            {intl.formatMessage(action.title)}
          </Button>
        </WithTooltip>
      ))}
    </>
  );
};
