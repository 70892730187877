import { ReactElement } from "react";
import { createPortal } from "react-dom";

type Props = {
  children: ReactElement;
  container: string;
};

export const Portal = ({ children, container }: Props): JSX.Element => {
  const portalTarget = document.querySelector<HTMLElement>(container);
  if (!portalTarget) {
    console.warn(
      `unable to portal to ${container} because the element doesn't exist, rendering children in-place.`
    );
    return children;
  }

  return createPortal(children, portalTarget);
};
