import {
  PerformanceReviewQuestionAndAnswersDefaultFragment,
  PerformanceReviewRepeatFrequency,
} from "@graphql";
import dayjs from "dayjs";
import { UseCurrentUserHook } from "hooks/useCurrentUser/useCurrentUser";
import { PerformanceReviewCardType } from "./PerformanceReviewCard/PerformanceReviewCardContext/PerformanceReviewCardContext";

type PerformanceReviewStatusesType = {
  otherUserCompleted: boolean;
  otherUserDrafted: boolean;
  otherUserSubmitted: boolean;
  reviewComplete: boolean;
  userCompleted: boolean;
  userDrafted: boolean;
  userIsReviewee: boolean;
  userSubmitted: boolean;
};

export const performanceReviewStatuses = (
  me: UseCurrentUserHook,
  review: PerformanceReviewCardType
): PerformanceReviewStatusesType => {
  const { reviewee, reviewer, isComplete } = review;
  const userIsReviewee = me?.id === reviewee?.user?.id;

  const userDrafted = userIsReviewee ? reviewee?.isDraft : reviewer?.isDraft;
  const userSubmitted = userIsReviewee
    ? reviewee?.isSubmitted
    : reviewer?.isSubmitted;
  const userCompleted = !userDrafted && userSubmitted;

  const otherUserSubmitted = !userIsReviewee
    ? reviewee?.isSubmitted
    : reviewer?.isSubmitted;
  const otherDrafted = !userIsReviewee ? reviewee?.isDraft : reviewer?.isDraft;
  const otherCompleted = !otherDrafted && otherUserSubmitted;

  return {
    otherUserCompleted: otherCompleted ?? false,
    otherUserDrafted: otherDrafted ?? false,
    otherUserSubmitted: otherUserSubmitted ?? false,
    reviewComplete: isComplete,
    userCompleted: userCompleted ?? false,
    userDrafted: userDrafted ?? false,
    userIsReviewee,
    userSubmitted: userSubmitted ?? false,
  };
};

export const getNextPerformanceReviewDate = (
  dueDate: string | null,
  repeatFrequency: PerformanceReviewRepeatFrequency
): string | undefined => {
  const date = dueDate ? dayjs(dueDate) : dayjs();
  switch (repeatFrequency) {
    case PerformanceReviewRepeatFrequency.Quarterly:
      return date.add(3, "months").toISOString();
    case PerformanceReviewRepeatFrequency.Every_6Months:
      return date.add(6, "months").toISOString();
    case PerformanceReviewRepeatFrequency.Yearly:
      return date.add(12, "months").toISOString();
    default:
      return undefined;
  }
};

export const getOtherUsersQuestionsAndAnswers = (
  isReviewee: boolean,
  questionsAndAnswers: PerformanceReviewQuestionAndAnswersDefaultFragment[]
) => {
  return isReviewee
    ? questionsAndAnswers.map(
        ({ id, reviewerParagraphAnswer, reviewerStarRatingAnswer }) => ({
          id,
          paragraphAnswer: reviewerParagraphAnswer,
          starRatingAnswer: reviewerStarRatingAnswer,
        })
      )
    : questionsAndAnswers.map(
        ({ id, revieweeParagraphAnswer, revieweeStarRatingAnswer }) => ({
          id,
          paragraphAnswer: revieweeParagraphAnswer,
          starRatingAnswer: revieweeStarRatingAnswer,
        })
      );
};
