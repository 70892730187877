import { defineMessages } from "react-intl";

export const sidebarTitles = defineMessages({
  allTeamsListTitle: {
    defaultMessage: "All teams",
    id: "DtvBtA",
  },
  companyAndGroups: {
    defaultMessage: "Company & Groups",
    id: "group:list:company_and_title",
  },
  configureTitle: {
    defaultMessage: "Configure",
    id: "settings:company:title",
  },
  customReports: {
    defaultMessage: "Custom Reports",
    id: "reports:custom:title",
  },
  engagementReports: {
    defaultMessage: "Engagement Reports",
    id: "reports:engagementReports",
  },
  goals: {
    defaultMessage: "Goals",
    id: "goals:title",
  },
  groupsAndPeople: {
    defaultMessage: "Groups & People",
    id: "sidebar:groupsAndPeople",
  },
  growthBoard: {
    defaultMessage: "Growth Board",
    id: "kpiBoard:title",
  },
  home: {
    defaultMessage: "Home",
    id: "home:title",
  },
  kpiBoards: {
    defaultMessage: "KPI Boards",
    id: "kpiBoards:title",
  },
  mapsTitle: {
    defaultMessage: "Maps",
    id: "maps:title",
  },
  myProfile: {
    defaultMessage: "My profile",
    id: "navbar:myProfile",
  },
  okrsListTitle: {
    defaultMessage: "OKRs",
    id: "okrs:list:title",
  },
  peopleListTitle: {
    defaultMessage: "People",
    id: "people:list:title",
  },
  peopleManagement: {
    defaultMessage: "People Management",
    id: "sidebar:peopleManagement",
  },
  quickStart: {
    defaultMessage: "Quick start guide",
    id: "sidebar:quickStartGuide",
  },
  reportsTitle: {
    defaultMessage: "Reports",
    id: "reports:all:title",
  },
  roadmapTitle: {
    defaultMessage: "Roadmap",
    id: "roadmap:title",
  },
  strategy: {
    defaultMessage: "Strategy",
    id: "strategy:title",
  },
  timelineTitle: {
    defaultMessage: "Strategic Timeline",
    id: "timeline:title",
  },
  userListTitle: {
    defaultMessage: "Users",
    id: "user:list:title",
  },
});
