import { PerdooApiIntegrationApplicationChoices } from "@graphql";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import invariant from "tiny-invariant";
import { Show } from "common/controlFlow";
import { Dropdown } from "common/overlay/Dropdown";
import { NoIntegrationMessage } from "common/result/ResultForm/IntegrationField/NoIntegrationMessage";
import { twClass } from "utils/twClass";
import { useIntegrationOptions } from "../hooks/useIntegrations";
import { IntegrationLogo } from "../IntegrationLogo/IntegrationLogo";

type Value = {
  application: PerdooApiIntegrationApplicationChoices;
  id: string;
};
export type IntegrationDropdownValue = Value;

type Props = {
  children: ReactElement;
  onSelect: (value: Value) => void;
};

export const IntegrationDropdown = ({ onSelect, children }: Props) => {
  const { options, anyIntegrationEnabled } = useIntegrationOptions();
  return (
    <Dropdown>
      <Dropdown.Trigger>{children}</Dropdown.Trigger>
      <Dropdown.Content placement="bottom-left">
        <Show
          when={anyIntegrationEnabled}
          fallback={
            <div className="p-2 text-center">
              <NoIntegrationMessage />
            </div>
          }
        >
          {options.map((option) => (
            <Dropdown.Item
              key={option.value}
              data-cy={`integrationDropdown${option.label}`}
              onClick={() => {
                invariant(!!option.value); // otherwise it's disabled
                onSelect({ application: option.application, id: option.value });
              }}
              className={twClass("flex items-center space-x-2", {
                "text-slate-500": !option.value,
              })}
              disabled={!option.value}
            >
              <IntegrationLogo application={option.application} />
              <div>
                {option.label}{" "}
                {!option.value && (
                  <FormattedMessage
                    defaultMessage="(Inactive)"
                    id="integrationSelect:inactive"
                  />
                )}
              </div>
            </Dropdown.Item>
          ))}
        </Show>
      </Dropdown.Content>
    </Dropdown>
  );
};
