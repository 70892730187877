import { PerformanceReviewQuestionAndAnswersDefaultFragment } from "@graphql";
import { compact } from "lodash";
import React from "react";
import { defineMessages, FormattedDate, useIntl } from "react-intl";
import { Subtext } from "common/misc/Subtext/Subtext";
import { ModalHeader } from "common/overlay/Modal/ModalHeader/ModalHeader";
import { PerformanceReviewCardType } from "common/performanceReview/PerformanceReviewCard/PerformanceReviewCardContext/PerformanceReviewCardContext";
import { titleMessages } from "common/performanceReview/PerformanceReviewCard/PerformanceReviewCardTitle/PerformanceReviewCardTitle";
import { messages } from "common/performanceReview/PerformanceReviewForm/usePerformanceReviewFrequencyOptions/usePerformanceReviewFrequencyOptions";
import { performanceReviewStatuses } from "common/performanceReview/performanceReviews.utils";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";

const subtitleMessages = defineMessages({
  reviewee: {
    defaultMessage: "Performance Review of {reviewee}",
    id: "performanceReview:complete:form:reviewee:subtitle",
  },
});

export type ReviewCompleteType = PerformanceReviewCardType & {
  questionAndAnswers: NodeConnection<PerformanceReviewQuestionAndAnswersDefaultFragment>;
};

type Props = {
  review: ReviewCompleteType;
};

export const PerformanceReviewCompleteModalHeader = ({
  review,
}: Props): JSX.Element => {
  const intl = useIntl();
  const me = useCurrentUser();
  const { userCompleted, otherUserCompleted } = performanceReviewStatuses(
    me,
    review
  );
  const sharedCount = compact([userCompleted, otherUserCompleted]).length;

  const subtext = [
    <Subtext
      key="review-repeat-frequency"
      className="hover:text-slate-500"
      icon="repeat"
      text={intl.formatMessage(messages[review.repeatFrequency])}
    />,
    <Subtext
      key="review-shared-count"
      className="hover:text-slate-500"
      icon="send"
      text={intl.formatMessage(titleMessages.reviewSharedSubtitle, {
        count: sharedCount,
      })}
    />,
    review.dueDate && (
      <Subtext
        key="review-due-date"
        className="hover:text-slate-500"
        icon="timer"
        iconType="outlined"
        text={
          <FormattedDate
            day="numeric"
            month="short"
            value={review.dueDate}
            year="numeric"
          />
        }
      />
    ),
  ];

  const renderSubtext = () => (
    <div className="flex flex-wrap gap-x-5 pb-3">{subtext}</div>
  );

  const reviewee = review.reviewee?.user?.fullName ?? "";

  return (
    <ModalHeader
      editVisible={false}
      entityId={review.id}
      subtext={renderSubtext()}
      title={intl.formatMessage(subtitleMessages.reviewee, { reviewee })}
    />
  );
};
