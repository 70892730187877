import Fuse, { FuseOptionKeyObject } from "fuse.js";

export const searchBy = <Item>(
  collection: Item[],
  paths: (FuseOptionKeyObject<Item> | (keyof Item & string))[],
  query: string
): Item[] => {
  // Fuse returns empty array with empty query, but we want to use it for filtering, so we actually want the entire array.
  // can remove this line if this issue gets resolved: https://github.com/krisk/Fuse/issues/229
  if (!query) return collection;

  return new Fuse(collection, {
    findAllMatches: true,
    isCaseSensitive: false,
    keys: paths,
    // 0 = only perfect match, 1 = matches anything
    threshold: 0.3,
  })
    .search(query)
    .map((r) => r.item);
};
