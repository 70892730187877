import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  count?: number;
};

/**
 * shows a bubble with a number on top-right of the icon. used for global notifications button.
 */
export const Bubble = ({ count }: Props): JSX.Element | null => {
  if (!count) return null;

  return (
    <div
      className={twClass(
        "absolute right-0 top-0",
        "flex items-center justify-center",
        "rounded-full",
        "text-xxs font-semibold",
        "h-3.5 w-3.5",
        "text-white bg-red-500"
      )}
    >
      <span>{count > 9 ? "9+" : count}</span>
    </div>
  );
};
