import { IconName } from "constants/icons";
import { objectTypes } from "constants/objects";
import { progressTypes } from "constants/progressTypes";
import { resultTypes } from "constants/resultTypes";
import { IconType } from "../Icon/Icon.types";

export type EntityIconKey =
  | ValueOf<typeof objectTypes>
  | ValueOf<typeof resultTypes>
  | ValueOf<typeof progressTypes>
  | "strategy";

type EntityIcon = {
  className: string;
  name: keyof typeof IconName;
  tooltip: string;
  tooltipDefaultMessage: string;
  type?: IconType;
};

export const ENTITY_ICONS: Record<EntityIconKey, EntityIcon> = {
  [objectTypes.initiative]: {
    className: "text-green-500",
    name: "playlist_add_check",
    tooltip: "global:initiative",
    tooltipDefaultMessage: "global:initiative",
  },
  [resultTypes.initiative]: {
    className: "text-green-500",
    name: "playlist_add_check",
    tooltip: "global:initiative",
    tooltipDefaultMessage: "global:initiative",
  },
  [objectTypes.keyresult]: {
    className: "text-blue-500",
    name: "trending_up",
    tooltip: "global:keyResult",
    tooltipDefaultMessage: "global:keyResult",
  },
  [resultTypes.keyResult]: {
    className: "text-blue-500",
    name: "trending_up",
    tooltip: "global:keyResult",
    tooltipDefaultMessage: "global:keyResult",
  },
  [objectTypes.objective]: {
    className: "text-slate-500",
    name: "outlined_flag",
    tooltip: "global:objective",
    tooltipDefaultMessage: "global:objective",
  },
  [objectTypes.strategicPillar]: {
    className: "text-green-500",
    name: "account_balance",
    tooltip: "global:strategicPillar",
    tooltipDefaultMessage: "global:strategicPillar",
    type: "outlined",
  },
  strategy: {
    className: "text-green-500",
    name: "alignedMap",
    tooltip: "global:strategy",
    tooltipDefaultMessage: "global:strategy",
  },
  [objectTypes.kpi]: {
    className: "text-indigo-500",
    name: "monitor_heart",
    tooltip: "global:kpi",
    tooltipDefaultMessage: "global:kpi",
    type: "outlined",
  },
  [progressTypes.alignedObjective]: {
    className: "text-slate-500",
    name: "alignedObjective",
    tooltip: "objective:form:progressCalculation:alignedObjective",
    tooltipDefaultMessage:
      "objective:form:progressCalculation:alignedObjective",
  },
  [progressTypes.keyResults]: {
    className: "text-blue-500",
    name: "trending_up",
    tooltip: "global:keyResult",
    tooltipDefaultMessage: "global:keyResult",
  },
};
