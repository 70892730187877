import { isEmpty } from "lodash";
import includes from "lodash/includes";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { isSet } from "utils/isSet";
import { hasUserAccess } from "utils/permissions";

type PermissionWrapperProps = {
  children: JSX.Element;
  module?: string;
  redirectTo?: string;
  role?: string;
};

export const PermissionWrapper = ({
  children,
  role = "",
  module = "",
  redirectTo,
}: PermissionWrapperProps): JSX.Element | null => {
  const user = useCurrentUser();
  const { push } = useRouter();

  let hasAccess = user?.canCreate ? includes(user.canCreate, module) : false;

  hasAccess = !isEmpty(role) ? hasUserAccess(user, role) : hasAccess;

  useEffect(() => {
    if (!hasAccess && redirectTo && isSet(user)) {
      push(redirectTo);
    }
  }, [user, hasAccess]);

  if (!user) return null;

  return hasAccess ? children : null;
};
