import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { articles as staticArticles } from "common/layout/HelpCenter/DefaultInfoView/BlogPosts/BlogPosts.constants";
import { parseJsonArticles } from "common/layout/HelpCenter/DefaultInfoView/BlogPosts/BlogPosts.utils";
import { blogPosts } from "common/layout/HelpCenter/HelpCenter.messages";
import { useHelpCenterTrack } from "common/layout/HelpCenter/hooks/useHelpCenterTrack";
import { AnchorNextTrack } from "common/navigation";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { logToSentry } from "utils/tracker";
import { stripHTML } from "utils/utils";

const ARTICLES_TO_SHOW = 4;

export const BlogPosts = (): JSX.Element => {
  const intl = useIntl();
  const { trackContentHandler } = useHelpCenterTrack();
  const [articles, setArticles] = useState(staticArticles);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      setLoading(true);
      const response = await fetch(
        "https://www.perdoo.com/wp-json/wp/v2/posts?per_page=4"
      );
      const data = await response.json();
      const parsedArticles = parseJsonArticles(data);

      if (parsedArticles.length) {
        setArticles(parsedArticles);
      }
    };

    fetchBlogPosts()
      .catch(() => {
        logToSentry("Error fetching blog posts");
      })
      .finally(() => setLoading(false));
  }, []);

  const visibleArticles = articles.slice(0, ARTICLES_TO_SHOW);
  return (
    <div className="border-b px-5 py-4 border-slate-300">
      <div className="flex justify-between pb-2.5">
        <h6 className="my-0 text-sm">{intl.formatMessage(blogPosts.title)}</h6>
        <AnchorNextTrack
          data-cy="tmPobb4YoGOyljr3qXkuI"
          href="https://www.perdoo.com/resources"
          onClick={trackContentHandler("https://www.perdoo.com/resources")}
          target="blank"
        >
          <FormattedMessage defaultMessage="See more" id="global:seeMore" />
        </AnchorNextTrack>
      </div>
      {loading && <SpinnerFit />}
      {!loading &&
        visibleArticles.map(({ href, id, timeToRead, title }) => (
          <div key={id} className="py-2.5">
            <p className="text-xs leading-relaxed text-slate-500">
              {intl.formatMessage(blogPosts.timeToRead, { timeToRead })}
            </p>
            <AnchorNextTrack
              data-cy="L0Kg1xC-gj69ygIMxJPeH"
              className="leading-relaxed text-slate-800 hover:text-blue-500"
              href={href}
              onClick={trackContentHandler(href)}
              target="blank"
            >
              {stripHTML(title)}
            </AnchorNextTrack>
          </div>
        ))}
    </div>
  );
};
