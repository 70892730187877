import {
  Company,
  useCompanyPlansQuery,
  useStartCompanyTrialMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import { ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { PREMIUM_UPGRADE_MODAL, START_TRIAL } from "constants/tracking";
import { useSalesModals } from "hooks/useSalesModals/useSalesModals";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { showGqlErrorToast } from "utils/utils";

export const messages = defineMessages({
  ambassadorReportDescription: {
    defaultMessage:
      "Your toolkit to drive the adoption of OKRs. Identify which goals haven’t been updated, and how that’s trending over time. See who’s not logging in, or which groups still need to add OKRs. The Ambassador report takes the pain out of running an OKR program.",
    id: "ambassadorReport:go:premium:description",
  },
  errorOnDismiss: {
    defaultMessage: "There was an error while trying to dismiss banner.",
    id: "goTrial:banner:dismiss",
  },
  errorOnTrial: {
    defaultMessage: "There was an error while upgrading your account to Trial.",
    id: "goTrial:banner:errorOnTrial",
  },
  planFetchError: {
    defaultMessage: "Error fetching plan",
    id: "plan:fetch:error:msg",
  },
  reportsDescription: {
    defaultMessage:
      "A holistic overview of the performance of your entire organization across different timeframes. Track goal progression over time, visualize performance across different groups, and check on the status of all your goals. They’re available to all employees, so you can build a shared understanding, boost transparency, and collaborate to overcome any issues.",
    id: "reports:performance:go:premium:description",
  },
  startTrialMsg: {
    defaultMessage: "Start free trial",
    id: "trialBanner:startFreeTrial",
  },
  successOnTrial: {
    defaultMessage: "Trial started! Time to check out Premium 🚀 ",
    id: "goTrial:banner:trialStarted",
  },
  teaserExpiredTrial: {
    defaultMessage:
      "Upgrade to Premium or Supreme for robust reports, actionable insights, and much more",
    id: "teaser:page:trialExpired:upgradeToPremiumOrSupreme",
  },
  teaserStartTrial: {
    defaultMessage:
      "Start a free 14-day trial of the Supreme plan. No credit card required.",
    id: "teaser:page:trial:descriptionToSupreme",
  },
  upgradeMsg: {
    defaultMessage: "Upgrade",
    id: "premium:upgrade",
  },
});

type CompanyPlans = Derive<
  Company,
  {
    asanaIntegrationAvailable: true;
    companySize: true;
    daysLeftInTrial: true;
    featureTier: true;
    growthBoardAvailable: true;
    id: true;
    jiraIntegrationAvailable: true;
    performanceReviewsAvailable: true;
    privateGoalsAvailable: true;
  }
>;

const useGoTrial = (company: CompanyPlans | undefined): (() => void) => {
  const intl = useIntl();
  const [startCompanyTrial] = useStartCompanyTrialMutation();

  if (!company) return () => {};

  return () => {
    return startCompanyTrial()
      .then((response) => {
        if (response.errors) {
          showGqlErrorToast(response.errors);
        } else {
          toast.success(intl.formatMessage(messages.successOnTrial));
          track(START_TRIAL, { type: "in-app" });
        }
      })
      .catch(() => toast.failure(intl.formatMessage(messages.errorOnTrial)));
  };
};

export const useUpgradeAction = (
  tab?: string,
  title?: string
): (() => void) | undefined => {
  const intl = useIntl();
  const { openUpgradeNow } = useSalesModals();

  const { error, data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  const goTrial = useGoTrial(data?.currentCompany);

  if (error) {
    toast.failure(intl.formatMessage(messages.planFetchError));
  }

  if (isNil(data)) {
    return undefined;
  }

  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  switch (data?.currentCompany.featureTier) {
    case "FREE":
      return goTrial;
    case "TRIAL":
    case "EXPIRED_TRIAL":
      return () => {
        track(PREMIUM_UPGRADE_MODAL, {
          highlight_feature: tab,
          trigger_feature: title,
        });
        openUpgradeNow();
      };
    default:
      return () =>
        window // TODO: [no-unnecessary-condition] remove and fix
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          ?.open("https://www.perdoo.com/pricing/#contact-form", "_blank")
          ?.focus();
  }
};

type UpgradeTextOptions = {
  startTrialText?: ReactNode;
  upgradeText?: ReactNode;
};

export const useUpgradeText = (options: UpgradeTextOptions = {}): ReactNode => {
  const intl = useIntl();
  const { error, data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });
  if (error) {
    toast.failure(intl.formatMessage(messages.planFetchError));
  }

  if (isNil(data)) {
    return undefined;
  }

  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data?.currentCompany.featureTier === "FREE") {
    return options.startTrialText ?? intl.formatMessage(messages.startTrialMsg);
  }
  return options.upgradeText ?? intl.formatMessage(messages.upgradeMsg);
};

type TeaserUpgradeTextOptions = {
  startTrialText?: ReactNode;
  upgradeText?: ReactNode;
};

export const useTeaserUpgradeText = (
  options: TeaserUpgradeTextOptions = {}
): ReactNode => {
  const intl = useIntl();
  const { error, data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });
  if (error) {
    toast.failure(intl.formatMessage(messages.planFetchError));
  }

  if (isNil(data)) {
    return undefined;
  }

  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data?.currentCompany.featureTier === "FREE") {
    return (
      options.startTrialText ?? intl.formatMessage(messages.teaserStartTrial)
    );
  }
  return options.upgradeText ?? intl.formatMessage(messages.teaserExpiredTrial);
};
