import { gql } from "@apollo/client";

/**
 * @deprecated use composable fragments instead. see {@link common/timeframe/fragments.graphql}.
 */
export const TIMEFRAME_DEFAULT_FRAGMENT = gql`
  fragment TimeframeDefaultFields on timeframe {
    id
    name
    objectiveCount
    duration
    startDate
    endDate
    isActive
    isCompanyLevel
    isPast
    isGroupLevel
    cadence {
      id
      name
      intervalLength
      intervalType
      nextTimeframeStartDate
      goalUpdateCycle
    }
  }
`;
