import classNames from "clsx";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons/Button/Button";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { useShowSalesModals } from "common/company/hooks/useShowSalesModals";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import {
  COACHING_PRICES,
  ONBOARDING_PRICING,
} from "common/inAppUpgrade/constants";
import { Checkbox } from "common/inputs/Checkbox/Checkbox";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { AllMetrics } from "constants/metric";
import { PREMIUM_CHECKOUT } from "constants/tracking";
import { useChargebee } from "hooks/useChargebee/useChargebee";
import { useCompany } from "hooks/useCompany/useCompany";
import { usePageTrack } from "hooks/usePageTrack/usePageTrack";
import { BillingPeriodSelector } from "../BillingPeriodSelector";
import { CurrencySelector } from "../CurrencySelector";
import {
  BillingPeriod,
  CoachingTypes,
  PaidPlans,
  SupportedCurrencies,
} from "../UpgradeModal.types";
import {
  calculateTotalPrice,
  getBillingPeriodDuration,
  getPlanName,
  monthlyPricePerUser,
  monthlyPricePerViewOnlyLicense,
} from "../utils";
import { CoachingPlanSelect } from "./CoachingPlanSelect/CoachingPlanSelect";

export interface PlanOptionValues {
  coachingPackage: CoachingTypes;
  licenses: number;
  terms: boolean;
  viewOnlyLicenses: number;
}

interface Props {
  billingPeriod: BillingPeriod;
  currency: SupportedCurrencies;
  onBack: () => void;
  onChatToSales: () => void;
  onSubmit: (
    values: PlanOptionValues,
    formikHelpers: FormikHelpers<PlanOptionValues>
  ) => void;
  plan: PaidPlans;
  setBillingPeriod: (val: BillingPeriod) => void;
  setCurrency: (val: SupportedCurrencies) => void;
}

const getSchema = (minLicenses: number) => {
  return Yup.object().shape({
    licenses: Yup.number()
      .integer("Must be an integer")
      .min(minLicenses, `Minimum of ${minLicenses} required`)
      .required("Required"),
    terms: Yup.mixed()
      .oneOf([true], "Please accept the Terms")
      .required("Required"),
  });
};

export const PlanOptionsForm: React.FC<Props> = ({
  billingPeriod,
  currency,
  plan,
  onSubmit,
  onBack,
  onChatToSales,
  setCurrency,
  setBillingPeriod,
}) => {
  const intl = useIntl();
  usePageTrack(PREMIUM_CHECKOUT);
  useChargebee();
  const company = useCompany();

  const { showSalesModals } = useShowSalesModals();
  const minLicenses = company.mpq;
  const schema = getSchema(minLicenses);
  const planName = getPlanName(plan);
  const duration = getBillingPeriodDuration(billingPeriod);
  const viewOnlyEnabled = plan === "supreme";

  return (
    <Formik
      initialValues={{
        coachingPackage: "no_coaching" as CoachingTypes,
        licenses: minLicenses,
        terms: false,
        viewOnlyLicenses: 0,
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({
        isSubmitting,
        values,
        errors,
        setFieldValue,
        submitCount,
        submitForm,
      }) => (
        <Form className="h-full">
          <div className="flex h-full flex-col overflow-y-auto lg:flex-row">
            <div className="flex-1 space-y-10 p-10 lg:overflow-y-auto">
              <div className="full-width flex h-10 items-center justify-between">
                <div className="flex items-center justify-between">
                  <IconButton
                    data-cy="4aSTyt-jHb6F8eYExMeNB"
                    className="mr-4"
                    name="chevron_left"
                    onClick={onBack}
                    size="3xl"
                  />
                  <h1 className="m-0 text-2xl text-slate-800">
                    <FormattedMessage
                      defaultMessage="Plan options"
                      id="upgrade:modal:label:plan:options"
                    />
                  </h1>
                </div>
              </div>
              <div className="space-y-8">
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h2 className="m-0 text-lg font-semibold text-slate-900">
                      <FormattedMessage
                        defaultMessage="Plan selected"
                        id="upgrade:modal:label:plan:selected"
                      />
                    </h2>
                    {showSalesModals && (
                      <p className="text-xs text-slate-500 lg:text-sm">
                        <FormattedMessage
                          defaultMessage="Need 500+ users?"
                          id="fp/UWS"
                        />
                        <Anchor
                          data-cy="UHeM4osVkIzb9C5KOuu8N"
                          className="ml-1 cursor-pointer text-xs text-blue-500 hover:underline lg:text-sm"
                          onClick={onChatToSales}
                        >
                          <FormattedMessage
                            defaultMessage="Talk to sales"
                            id="WEityN"
                          />
                        </Anchor>
                      </p>
                    )}
                  </div>

                  <div className="flex h-20 items-center justify-between rounded border p-6 bg-white border-slate-300">
                    <div>
                      <h3 className="m-0 text-base font-semibold text-slate-900">
                        {planName}
                      </h3>
                      <div className="text-xs text-slate-500 lg:text-sm">
                        <FormattedMessage
                          defaultMessage="Price decreases as you add more licenses."
                          id="IPGQJO"
                        />
                      </div>
                    </div>
                    <div>
                      <Field
                        className={classNames({
                          "border-red-600": errors.licenses,
                          "border-slate-300": !errors.licenses,
                          "h-10 w-16 rounded border  p-2 outline-none lg:w-24":
                            true,
                        })}
                        data-cy="licenses"
                        id="licenses"
                        name="licenses"
                        type="number"
                      />
                      <span className="pl-2 lowercase text-slate-900 md:pl-4">
                        <FormattedMessage
                          defaultMessage="Users"
                          id="user:list:title"
                        />
                      </span>
                    </div>
                  </div>
                  {errors.licenses && (
                    <div className="flex justify-end">
                      <div className="text-sm text-red-500">
                        {errors.licenses}
                      </div>
                    </div>
                  )}
                </div>

                <Show when={viewOnlyEnabled}>
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <h2 className="m-0 text-lg font-semibold text-slate-900">
                        <FormattedMessage
                          defaultMessage="View-only"
                          id="GXLtIh"
                        />
                      </h2>
                      {errors.viewOnlyLicenses && (
                        <div className="text-sm text-red-500">
                          {errors.viewOnlyLicenses}
                        </div>
                      )}
                    </div>

                    <div className="flex h-20 items-center justify-between rounded border p-6 bg-white border-slate-300">
                      <div>
                        <h3 className="m-0 text-base font-semibold text-slate-900">
                          <FormattedMessage
                            defaultMessage="View-only"
                            id="GXLtIh"
                          />
                        </h3>
                        <div className="text-xs text-slate-500 lg:text-sm">
                          <FormattedMessage
                            defaultMessage="Price decreases as you add more licenses."
                            id="IPGQJO"
                          />
                        </div>
                      </div>
                      <div>
                        <Field
                          className={classNames({
                            "border-red-600": errors.viewOnlyLicenses,
                            "border-slate-300": !errors.viewOnlyLicenses,
                            "h-10 w-16 rounded border  p-2 outline-none lg:w-24":
                              true,
                          })}
                          data-cy="viewOnlyLicenses"
                          id="viewOnlyLicenses"
                          name="viewOnlyLicenses"
                          type="number"
                        />
                        <span className="pl-2 lowercase text-slate-900 md:pl-4">
                          <FormattedMessage
                            defaultMessage="licenses"
                            id="oibZZl"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </Show>

                <div className="space-y-4">
                  <div>
                    <h2 className="m-0 text-lg font-semibold text-slate-900">
                      <FormattedMessage
                        defaultMessage="Coaching options"
                        id="upgrade:modal:label:coaching:options"
                      />
                    </h2>
                    <p className="text-xs text-slate-500 lg:text-sm">
                      <FormattedMessage
                        defaultMessage="Find success faster with our in-house coaches."
                        id="upgrade:modal:label:coaching:options:subtitle"
                      />{" "}
                      <Anchor
                        href="https://www.perdoo.com/coaching/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedMessage
                          defaultMessage="Learn more."
                          id="premium:indicator:learnMore"
                        />
                        <Icon
                          className="ml-1 align-text-bottom"
                          name="open_in_new"
                        />
                      </Anchor>
                    </p>
                  </div>
                  <CoachingPlanSelect
                    currency={currency}
                    onClear={() =>
                      setFieldValue("coachingPackage", "no_coaching")
                    }
                    onSelect={(selected) =>
                      setFieldValue("coachingPackage", selected)
                    }
                    selected={values.coachingPackage}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between rounded-r-md border-t bg-white border-slate-200 lg:relative lg:border-l">
              <div className="space-y-10 p-10 md:overflow-y-auto">
                <div className="flex h-10 items-center justify-between space-x-2">
                  <div>
                    <BillingPeriodSelector
                      onChange={setBillingPeriod}
                      value={billingPeriod}
                    />
                  </div>
                  <div>
                    <CurrencySelector onChange={setCurrency} value={currency} />
                  </div>
                </div>
                <div className="space-y-6">
                  <div className="flex justify-between items-center">
                    <h2 className="m-0 text-lg font-semibold text-slate-900">
                      <FormattedMessage
                        defaultMessage="Summary"
                        id="upgrade:modal:summary"
                      />
                    </h2>
                    <div className="mt-1 rounded bg-gradient-to-r from-yellow-400 to-yellow-500 px-1 text-xxs font-semibold text-yellow-900 lg:px-2 lg:py-1 lg:text-xs">
                      <FormattedMessage
                        defaultMessage="More licenses = Lower price"
                        id="RRAazX"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-slate-500">
                      <FormattedMessage
                        defaultMessage="Monthly price per user"
                        id="upgrade:modal:label:monthly:user:price"
                      />
                    </p>
                    <p className="text-right text-slate-900">
                      {AllMetrics[currency].symbol}
                      {intl.formatNumber(
                        monthlyPricePerUser(
                          currency,
                          billingPeriod,
                          plan,
                          values.licenses
                        ),
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-slate-500">
                      <FormattedMessage
                        defaultMessage="Users"
                        id="user:list:title"
                      />
                    </p>
                    <p className="text-right text-slate-900">
                      {intl.formatNumber(values.licenses)}
                    </p>
                  </div>
                  <Show when={viewOnlyEnabled}>
                    <div className="flex justify-between">
                      <p className="text-slate-500">
                        <FormattedMessage
                          defaultMessage="Monthly price per view-only license"
                          id="vevm+r"
                        />
                      </p>
                      <p className="text-right text-slate-900">
                        {AllMetrics[currency].symbol}
                        {intl.formatNumber(
                          monthlyPricePerViewOnlyLicense(
                            currency,
                            billingPeriod,
                            values.viewOnlyLicenses
                          ),
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-slate-500">
                        <FormattedMessage
                          defaultMessage="View-only licenses"
                          id="vkjDE6"
                        />
                      </p>
                      <p className="text-right text-slate-900">
                        {intl.formatNumber(values.viewOnlyLicenses)}
                      </p>
                    </div>
                  </Show>
                  <hr className="border-slate-300" />
                  <div className="flex justify-between">
                    <p className="mr-1 text-slate-500">
                      {planName}{" "}
                      <FormattedMessage defaultMessage="licenses" id="oibZZl" />
                    </p>
                    <div className="flex items-center">
                      <p className="text-right text-slate-900">
                        {AllMetrics[currency].symbol}
                        {intl.formatNumber(
                          monthlyPricePerUser(
                            currency,
                            billingPeriod,
                            plan,
                            values.licenses
                          ) *
                            values.licenses *
                            getBillingPeriodDuration(billingPeriod).asMonths(),
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <Show when={viewOnlyEnabled}>
                    <div className="flex justify-between">
                      <p className="text-slate-500">
                        <FormattedMessage
                          defaultMessage="View-only licenses"
                          id="vkjDE6"
                        />
                      </p>
                      <p className="text-right text-slate-900">
                        {AllMetrics[currency].symbol}
                        {intl.formatNumber(
                          monthlyPricePerViewOnlyLicense(
                            currency,
                            billingPeriod,
                            values.viewOnlyLicenses
                          ) *
                            values.viewOnlyLicenses *
                            getBillingPeriodDuration(billingPeriod).asMonths(),
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </Show>
                  <div className="flex justify-between">
                    <p className="mr-1 text-slate-500">
                      <FormattedMessage
                        defaultMessage="Onboarding"
                        id="GjrgLY"
                      />
                    </p>
                    <div className="flex items-center space-x-2">
                      <p className="text-right text-slate-900 line-through">
                        {AllMetrics[currency].symbol}{" "}
                        {intl.formatNumber(250, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                      <p className="text-right text-slate-900">
                        {AllMetrics[currency].symbol}{" "}
                        {intl.formatNumber(ONBOARDING_PRICING, {
                          minimumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-slate-500">
                      <FormattedMessage
                        defaultMessage="Coaching"
                        id="upgrade:modal:label:coaching"
                      />
                    </p>
                    <p className="text-right text-slate-900">
                      {AllMetrics[currency].symbol}
                      {intl.formatNumber(
                        COACHING_PRICES[values.coachingPackage][currency],
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                  <hr className="border-slate-300" />
                  <div className="flex justify-between">
                    <p className="mr-4 text-slate-500">
                      <FormattedMessage
                        defaultMessage="Total service fees"
                        id="upgrade:modal:label:serviceFees"
                      />
                    </p>
                    <p
                      className="text-right text-slate-900"
                      data-cy="totalPrice"
                    >
                      {AllMetrics[currency].symbol}
                      {intl.formatNumber(
                        calculateTotalPrice({
                          coachingPackage: values.coachingPackage,
                          currency,
                          licenses: values.licenses,
                          period: billingPeriod,
                          plan,
                          viewOnlyLicenses: values.viewOnlyLicenses,
                        }),
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-between text-sm">
                      <p className="mr-4 leading-loose text-slate-500">
                        <FormattedMessage
                          defaultMessage="Initial service term"
                          id="upgrade:modal:label:initialServiceTerm"
                        />
                      </p>
                      <p
                        className="text-right leading-loose text-slate-900"
                        data-cy="totalPrice"
                      >
                        <FormattedMessage
                          defaultMessage="{months} months"
                          id="upgrade:modal:label:months"
                          values={{
                            months: duration.format("M"),
                          }}
                        />
                      </p>
                    </div>
                    <div className="flex justify-between text-sm">
                      <p className="mr-4 leading-loose text-slate-500">
                        <FormattedMessage
                          defaultMessage="Renewal service term"
                          id="upgrade:modal:label:renewalServiceTerm"
                        />
                      </p>
                      <p
                        className="text-right leading-loose text-slate-900"
                        data-cy="totalPrice"
                      >
                        <FormattedMessage
                          defaultMessage="{months} months"
                          id="upgrade:modal:label:months"
                          values={{
                            months: duration.format("M"),
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-10">
                <div className="mb-4">
                  {errors.terms && submitCount > 0 && (
                    <div className="text-sm text-red-500">
                      <FormattedMessage
                        defaultMessage="Please accept our terms of service"
                        id="welcome:termsError"
                      />
                    </div>
                  )}
                  <Field
                    id="terms"
                    name="terms"
                    render={({ field }: FieldProps) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        data-cy="terms"
                        id="invite-form-terms"
                        label={
                          <FormattedMessage
                            defaultMessage="I accept the{linkToTerms}"
                            id="upgrade:modal:acceptTerms"
                            values={{
                              linkToTerms: (
                                <Anchor
                                  href="https://www.perdoo.com/terms/"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  &nbsp;Terms of Service&nbsp;
                                </Anchor>
                              ),
                            }}
                          />
                        }
                      />
                    )}
                  />
                </div>
                <Button
                  className="w-full"
                  data-cy="upgradeNow"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  <FormattedMessage
                    defaultMessage="Continue"
                    id="upgrade:modal:label:continue"
                  />
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
