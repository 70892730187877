import clsx from "clsx";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useLongPress } from "use-long-press";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { TOGGLE_DEV_MODE } from "constants/tracking";
import { useDevMode } from "hooks/useDevMode/useDevMode";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import logo from "../../../../../public/images/perdoo_logo_white_retina.png";
import { messages } from "../Sidebar.messsages";

export const SidebarHeader = (): JSX.Element => {
  const intl = useIntl();
  const { isCollapsed, toggleSidebarCollapse } = useContext(CollapsibleContext);
  const { devMode, setDevMode } = useDevMode();
  const [longPresses, setLongPresses] = useState(0);

  const toggleDevMode = () => {
    const newLongPresses = longPresses + 1;
    if (newLongPresses >= 3) {
      setDevMode(devMode ? 0 : 1);
      track(TOGGLE_DEV_MODE, { state: devMode ? "off" : "on" });
      setLongPresses(0);
      return;
    }
    setLongPresses(newLongPresses);
  };

  const switchBetweenDevModes = () => {
    if (devMode) {
      setDevMode((prev) => prev + 1);
    }
  };

  const toggleDevModeWhenLongPressedThreeTimes = useLongPress(toggleDevMode, {
    onCancel: switchBetweenDevModes,
  });

  return (
    <WithTooltip
      className="group w-full"
      placement="right"
      tooltip={isCollapsed ? intl.formatMessage(messages.expand) : null}
    >
      <div
        className={twClass("flex h-14 w-full items-center px-3.5 py-2", {
          "justify-between": !isCollapsed,
          "justify-center": isCollapsed,
        })}
      >
        <Show when={!isCollapsed}>
          <img
            alt="Perdoo logo"
            className="inline h-8"
            src={logo.src}
            {...toggleDevModeWhenLongPressedThreeTimes()}
          />
        </Show>
        <div className="flex justify-center">
          <Show when={isCollapsed}>
            <img
              alt="Perdoo logo"
              className={clsx("inline h-6", {
                "group-hover:hidden": isCollapsed,
              })}
              src="/images/perdoo_symbol.svg"
            />
          </Show>
          <IconButton
            className={clsx("text-slate-400 hover:text-white", {
              "hidden rotate-180 group-hover:block group-hover:animate-slow-fade-in":
                isCollapsed,
            })}
            data-cy="Xys8iPqMcWzANsy2cL4GS"
            name="menu_open"
            onClick={toggleSidebarCollapse}
            size="2xl"
          />
        </div>
      </div>
    </WithTooltip>
  );
};
