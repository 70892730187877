import {
  useGetSlackChannelLazyQuery,
  useGetSlackChannelsLazyQuery,
} from "@graphql";
import debounce from "debounce-promise";
import { isNil, isSet } from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Props as ReactSelectProps } from "react-select";
import { Icon } from "common/icons";
import { Select } from "legacy/components/Select/Select";
import { usePrimarySlackIntegration } from "modules/settings/company/integrations/slack/Slack/hooks/usePrimarySlackIntegration";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";

type Option = {
  id: string;
  isPrivate: boolean;
  name: string;
};
export type SlackChannelOption = Option;

type SelectProps = Omit<ReactSelectProps<Option, false>, "onChange" | "value">;
type Props = SelectProps & {
  onChange: (newChannel: string | null) => void;
  value?: string | null;
};

export const SlackChannelSelect = ({
  value,
  onChange,
  ...other
}: Props): JSX.Element => {
  const { slackConnected, slackInitialized } = usePrimarySlackIntegration();
  const [currentValue, setCurrentValue] = useState<Option>();
  const [getSlackChannel] = useGetSlackChannelLazyQuery({
    fetchPolicy: "network-only",
  });
  const [searchSlackChannel, { error }] = useGetSlackChannelsLazyQuery({
    fetchPolicy: "cache-first",
  });

  const getOptionLabel = (option: Option): ReactNode => (
    <span className="flex items-center space-x-1">
      {option.isPrivate ? <Icon name="lock" type="outlined" /> : <span>#</span>}
      <span>{option.name}</span>
    </span>
  );
  const getOptionValue = (option: Option) => option.id;

  const loadOptions = async (query: string) => {
    const result = await searchSlackChannel({ variables: { query } });
    const options = mapEdges(result.data?.allSlackChannels.edges);
    return options;
  };
  const debouncedLoadOptions = debounce(loadOptions, 500);

  const fetchOption = async (id: string | undefined | null) => {
    if (isNil(id)) {
      setCurrentValue(undefined);
      return;
    }

    const result = await getSlackChannel({ variables: { slackId: id } });
    const channel = result.data?.slackChannelBySlackId;
    if (isNil(channel)) {
      // eslint-disable-next-line no-console
      console.warn("Couldn't set Slack channel because we couldn't find it");
      return;
    }

    setCurrentValue(channel);
  };

  useEffect(() => {
    if (slackConnected && slackInitialized) {
      fetchOption(value);
    } else {
      setCurrentValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, slackConnected, slackInitialized]);

  const forwardOnChange = (option: Option | null) => {
    onChange(option?.id ?? null);
  };

  if (isSet(error)) {
    toast.failure("Error fetching slack channels");
    return (
      <Select
        disabled
        options={[]}
        placeholder="Error fetching channels"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    );
  }

  return (
    <Select
      async
      // This is a terribly named prop: it means fetch options with
      // `loadOptions` when the user opens the dropdown (not just after typing).
      defaultOptions
      disabled={!slackConnected || !slackInitialized}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      infoElement={
        !slackConnected && (
          <FormattedMessage
            defaultMessage="Admins need to connect a Slack account to send updates from this group to a Slack channel of your choice"
            id="global:forms:slackChannel:notConnected"
          />
        )
      }
      isClearable
      loadOptions={debouncedLoadOptions}
      onChange={forwardOnChange}
      placeholder="No channel"
      value={currentValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};
