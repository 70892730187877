const EventTypes = {
  // Comments
  CommentCreated: "COMMENT_CREATED",

  // Commits
  CommitCreated: "COMMIT_CREATED",

  // Company
  CompanyChanged: "COMPANY_CHANGED",

  // Groups
  GroupChanged: "GROUP_CHANGED",

  GroupCreated: "GROUP_CREATED",

  InitiativeChanged: "INITIATIVE_CHANGED",

  InitiativeCreated: "INITIATIVE_CREATED",

  InitiativeDeleted: "INITIATIVE_DELETED",

  InitiativeRelationAdded: "INITIATIVE_RELATION_ADDED",

  InitiativeRelationRemoved: "INITIATIVE_RELATION_REMOVED",

  InvitationCreated: "INVITATION_CREATED",

  KeyResultChanged: "KEY_RESULT_CHANGED",

  // Key Results
  KeyResultCreated: "KEY_RESULT_CREATED",

  KeyResultDeleted: "KEY_RESULT_DELETED",

  KeyResultRelationAdded: "KEY_RESULT_RELATION_ADDED",

  KeyResultRelationRemoved: "KEY_RESULT_RELATION_REMOVED",

  KpiChanged: "KPI_CHANGED",

  // KPI
  KpiCreated: "KPI_CREATED",

  KpiRelationAdded: "KPI_RELATION_ADDED",

  KpiRelationRemoved: "KPI_RELATION_REMOVED",

  LegacyInitiativeChanged: "ACTION_CHANGED",

  // Initiatives
  LegacyInitiativeCreated: "ACTION_CREATED",

  LegacyInitiativeDeleted: "ACTION_DELETED",

  LegacyInitiativeRelationAdded: "ACTION_RELATION_ADDED",

  LegacyUserAddedToAction: "USER_ADDED_TO_ACTION",

  LegacyUserAddedToInitiative: "USER_ADDED_TO_INITIATIVE",

  LegacyUserAddedToObjective: "USER_ADDED_TO_OBJECTIVE",

  LegacyUserRemovedFromAction: "USER_REMOVED_FROM_ACTION",

  LegacyUserRemovedFromInitiative: "USER_REMOVED_FROM_INITIATIVE",

  LegacyUserRemovedFromObjective: "USER_REMOVED_FROM_OBJECTIVE",

  ObjectiveChanged: "OBJECTIVE_CHANGED",

  ObjectiveCompleted: "OBJECTIVE_COMPLETED",

  // Objectives
  ObjectiveCreated: "OBJECTIVE_CREATED",

  ObjectiveRelationAdded: "OBJECTIVE_RELATION_ADDED",

  ObjectiveRelationRemoved: "OBJECTIVE_RELATION_REMOVED",

  TagAddedToObjective: "TAG_ADDED_TO_OBJECTIVE",

  TagRemovedFromObjective: "TAG_REMOVED_FROM_OBJECTIVE",

  TimeframeCreated: "TIMEFRAME_CREATED",

  // Timefames
  TimeframeSwitched: "TIMEFRAME_SWITCHED",

  UserAddedToInitiativeAsContributor: "USER_ADDED_TO_INITIATIVE_AS_CONTRIBUTOR",

  UserAddedToInitiativeAsLead: "USER_ADDED_TO_INITIATIVE_AS_LEAD",

  UserAddedToKeyResultAsContributor: "USER_ADDED_TO_KEY_RESULT_AS_CONTRIBUTOR",

  UserAddedToKeyResultAsLead: "USER_ADDED_TO_KEY_RESULT_AS_LEAD",

  UserAddedToKpiAsContributor: "USER_ADDED_TO_KPI_AS_CONTRIBUTOR",

  UserAddedToKpiAsLead: "USER_ADDED_TO_KPI_AS_LEAD",

  UserAddedToObjectiveAsContributor: "USER_ADDED_TO_OBJECTIVE_AS_CONTRIBUTOR",

  UserAddedToObjectiveAsLead: "USER_ADDED_TO_OBJECTIVE_AS_LEAD",

  UserChanged: "USER_CHANGED",

  // Users
  UserCreated: "USER_CREATED",

  UserRemovedFromInitiativeAsContributor:
    "USER_REMOVED_FROM_INITIATIVE_AS_CONTRIBUTOR",

  UserRemovedFromInitiativeAsLead: "USER_REMOVED_FROM_INITIATIVE_AS_LEAD",

  UserRemovedFromKeyResultAsContributor:
    "USER_REMOVED_FROM_OBJECTIVE_AS_CONTRIBUTOR",

  UserRemovedFromKeyResultAsLead: "USER_REMOVED_FROM_OBJECTIVE_AS_LEAD",

  UserRemovedFromKpiAsContributor: "USER_REMOVED_FROM_KPI_AS_CONTRIBUTOR",

  UserRemovedFromKpiAsLead: "USER_REMOVED_FROM_KPI_AS_LEAD",

  UserRemovedFromObjectiveAsContributor:
    "USER_REMOVED_FROM_OBJECTIVE_AS_CONTRIBUTOR",

  UserRemovedFromObjectiveAsLead: "USER_REMOVED_FROM_OBJECTIVE_AS_LEAD",
};

const legacyEventTypes = {
  [EventTypes.LegacyInitiativeCreated]: EventTypes.InitiativeCreated,
  [EventTypes.LegacyInitiativeChanged]: EventTypes.InitiativeChanged,
  [EventTypes.LegacyInitiativeRelationAdded]:
    EventTypes.InitiativeRelationAdded,
  [EventTypes.LegacyInitiativeDeleted]: EventTypes.InitiativeDeleted,
  [EventTypes.LegacyUserAddedToAction]: EventTypes.LegacyUserAddedToInitiative,
  [EventTypes.LegacyUserRemovedFromAction]:
    EventTypes.LegacyUserRemovedFromInitiative,
};

export { EventTypes, legacyEventTypes };
