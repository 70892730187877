import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import { ResultsFilterContext } from "common/result/ResultsFilterContext/ResultsFilterContext";
import { isSet } from "utils/isSet";
import { AddResultDropdown } from "./AddResultDropdown/AddResultDropdown";

export const ObjectiveResultsHeader = () => {
  const objective = useObjectiveContext();
  const {
    archived,
    excludeAccomplished,
    toggleHideCompleted,
    toggleHideArchived,
  } = useContext(ResultsFilterContext);

  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center space-x-2">
        <div>
          <FormattedMessage
            defaultMessage="Key Results and Initatives"
            id="vQxYEh"
          />
        </div>
        <div
          className="py-0.25 rounded border px-1 text-xs bg-white"
          data-testid="resultCount"
        >
          {objective.results?.edgeCount ?? 0}
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex space-x-2 items-center mr-3">
          <IconButton
            iconType="outlined"
            size="lg"
            data-testid="hideArchived"
            data-cy="hideArchived"
            className="text-sm font-normal"
            name={isSet(archived) ? "visibility" : "visibility_off"}
            onClick={(e) => {
              e.stopPropagation();
              toggleHideArchived?.();
            }}
          >
            <Show
              when={isSet(archived)}
              fallback={
                <FormattedMessage defaultMessage="Hide archived" id="rUwXQJ" />
              }
            >
              <FormattedMessage defaultMessage="Show archived" id="SrqTtZ" />
            </Show>
          </IconButton>

          <IconButton
            iconType="outlined"
            size="lg"
            data-testid="hideCompleted"
            data-cy="hideCompleted"
            className="text-sm font-normal"
            name={excludeAccomplished ? "visibility" : "visibility_off"}
            onClick={(e) => {
              e.stopPropagation();
              toggleHideCompleted?.();
            }}
          >
            <Show
              when={excludeAccomplished}
              fallback={
                <FormattedMessage
                  defaultMessage="Hide accomplished"
                  id="JtMcdl"
                />
              }
            >
              <FormattedMessage
                defaultMessage="Show accomplished"
                id="oU8wji"
              />
            </Show>
          </IconButton>
        </div>
        <Show when={objective.canPatch}>
          <AddResultDropdown objectiveId={objective.id} />
        </Show>
      </div>
    </div>
  );
};
