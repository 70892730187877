import { Menu } from "@headlessui/react";
import React, { Fragment, ReactElement } from "react";

type DropdownTriggerProps = {
  children: ReactElement;
};

export const DropdownTrigger = ({
  children,
}: DropdownTriggerProps): JSX.Element => {
  // when Dropdown is places inside a clickable element, this prevents propagation when opening dropdown
  const button = React.cloneElement(children, {
    onClick: (e: MouseEvent) => e.stopPropagation(),
  });

  return <Menu.Button as={Fragment}>{button}</Menu.Button>;
};
