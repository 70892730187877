import {
  namedOperations,
  PerdooApiObjectiveStageChoices,
  useGetAddObjectiveDataQuery,
  useGetTimeframeCadenceQuery,
  useUpsertObjectiveMutationMutation,
} from "@graphql";
import { FormikHelpers } from "formik";
import { isNil, isNull } from "lodash";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ObjectiveForm } from "common/objective/ObjectiveForm/ObjectiveForm";
import { ObjectiveFormValues } from "common/objective/ObjectiveForm/ObjectiveForm.types";
import { getAlignedToField } from "common/objective/utils/utils";
import { Modal } from "common/overlay/Modal/Modal";
import { progressTypes } from "constants/progressTypes";
import {
  ADD_OBJECTIVE,
  CHANGE_DEFAULT_GOAL_UPDATE_CYCLE,
  OPEN_ADD_MODAL,
} from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useDefaultOwners } from "hooks/useDefaultOwners/useDefaultOwners";
import { useGlobalTimeframe } from "hooks/useGlobalTf/useGlobalTimeframe";
import {
  ObjectiveModalType,
  useModalRouter,
} from "hooks/useModalRouter/useModalRouter";
import { useOnboardingSteps } from "hooks/useOnboardingSteps/useOnboardingSteps";
import { useRouteGroupId } from "hooks/useRouteGroupId/useRouteGroupId";
import { useRouteUserId } from "hooks/useRouteUserId/useRouteUserId";
import { handleErrors } from "utils/graphql/handleErrors";
import { RefetchQueries } from "utils/graphql/types";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

const messages = defineMessages({
  noTimeframe: {
    defaultMessage:
      "You don't have any active timeframes. Admins can create timeframes in Configure.",
    id: "addObjectiveModel:noTimeframe",
  },
});

type Props = {
  callback?: (objective: { id: string }) => void;
  changeTabView?: (view: unknown) => void;
  groupPrefilledId?: string;
  kpiId?: string;
  name?: string;
  onRequestClosed?: () => void;
  open?: boolean;
  parent?: string;
  redirectAfter?: boolean;
  refetchQueries?: RefetchQueries;
  source?: string;
  strategicPillar?: string;
  tfId?: string;
};

export const AddObjectiveModal = ({
  callback,
  changeTabView,
  groupPrefilledId,
  kpiId,
  name = "",
  onRequestClosed,
  open = false,
  parent,
  refetchQueries = [],
  source,
  strategicPillar,
  tfId,
  redirectAfter,
}: Props): JSX.Element | null => {
  const router = useRouter();
  const { query } = router;
  const { id: companyId } = useCompany();
  const user = useCurrentUser();
  const order = source === "map" ? "max" : "min";
  const globalTimeframe = useGlobalTimeframe(order);
  const intl = useIntl();
  const { refetchSteps } = useOnboardingSteps();
  const { close, goToObjective } = useModalRouter();
  const pageUserId = useRouteUserId();
  const pageGroupId = useRouteGroupId();
  const defaultOwners = useDefaultOwners(
    groupPrefilledId ?? pageGroupId ?? undefined
  );
  const usesModalRouter = query["type"] === ObjectiveModalType.ADD;
  const redirectAfterFromURL = !!query["redirectAfter"]
    ? query["redirectAfter"] === "true"
    : undefined;
  const withRedirect = redirectAfter ?? redirectAfterFromURL ?? true;

  const { data, error } = useGetAddObjectiveDataQuery();

  const [addObjective] = useUpsertObjectiveMutationMutation({
    refetchQueries: [
      ...refetchQueries,
      namedOperations.Query.fetchSelectObjectives,
      namedOperations.Query.SubObjectives,
      namedOperations.Query.getProfileObjectives,
      namedOperations.Query.TotalUnalignedObjectivesCount,
      namedOperations.Query.getUnalignedObjectives,
    ],
  });

  const timeframeId = tfId || globalTimeframe?.id;
  const { data: getTimeframeCadenceData } = useGetTimeframeCadenceQuery({
    skip: isNil(timeframeId),
    variables: { timeframeId: timeframeId ?? "" },
  });

  const onClose = () => {
    if (usesModalRouter) {
      close();
    }
    onRequestClosed?.();
  };

  useEffect(() => {
    if (open && isNull(globalTimeframe)) {
      toast.failure(intl.formatMessage(messages.noTimeframe));
      onClose();
    }
  }, [open, globalTimeframe]);

  useEffect(() => {
    if (open) {
      track(OPEN_ADD_MODAL, { goalType: "objective" });
    }
  }, [open]);

  if (error) return null;

  const loading = isNil(data) || isNil(companyId) || isNil(timeframeId);
  if (loading) return null;

  const onSubmit = async (
    values: ObjectiveFormValues,
    actions: FormikHelpers<ObjectiveFormValues>,
    selectedSampleObjective?: { name: ReactNode }
  ) => {
    const response = await addObjective({
      variables: {
        input: { ...values },
      },
    });
    actions.setSubmitting(false);

    const { hasError } = handleErrors(
      response,
      response.data?.upsertObjective?.errors
    );

    if (hasError) {
      toast.failure(
        intl.$t({ defaultMessage: "Error Creating objective", id: "VyO9Is" })
      );
      actions.setSubmitting(false);
      return;
    }

    const objective = response.data?.upsertObjective?.objective;

    const createdFromSample = selectedSampleObjective?.name === values.name;

    if (objective) {
      track(ADD_OBJECTIVE, {
        alignedTo: getAlignedToField(objective),
        description_added: !!objective.description,
        end_date: objective.dueDate,
        from_example: createdFromSample,
        multiple_owners_enabled: objective.groups.edges.length > 1,
        owner_count: objective.groups.edges.length,
        progressDriver: objective.progressDriver,
        scope: objective.isCompanyGoal ? "company" : "group",
        start_date: objective.startDate,
        status: objective.status,
        tags: objective.tags.edges.map(({ node }) => node.name),

        uiElementId: source,
      });
      const defaultGoalUpdateCycle =
        getTimeframeCadenceData?.timeframe?.cadence?.goalUpdateCycle;

      if (values.goalUpdateCycle !== defaultGoalUpdateCycle) {
        track(CHANGE_DEFAULT_GOAL_UPDATE_CYCLE, {
          cadenceId: objective.id,
          frequency: values.goalUpdateCycle,
          objectiveId: objective.id,
          userId: user?.id,
        });
      }
    }

    actions.setSubmitting(false);
    refetchSteps();

    if (callback && objective) {
      callback(objective);
    }

    if (changeTabView) {
      changeTabView(values.stage);
    }
    onClose();

    if (withRedirect && objective) {
      await goToObjective({ oId: objective.id });
    }

    toast.success(
      intl.formatMessage({ defaultMessage: "Objective added", id: "M1tgq4" })
    );
    actions.setSubmitting(false);
  };

  const getInitialValues = (): ObjectiveFormValues => {
    return {
      contributors: [],
      description: data.currentCompany.objDescriptionTemplate,
      goalUpdateCycle:
        getTimeframeCadenceData?.timeframe?.cadence?.goalUpdateCycle,
      groups: defaultOwners,
      kpi: kpiId,
      lead: pageUserId ?? data.me?.id ?? "",
      name,
      parent,
      progressDriver: progressTypes.keyResults,
      stage: PerdooApiObjectiveStageChoices.Active,
      strategicPillar,
      tags: [],
      timeframe: timeframeId,
    };
  };

  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio"
      contentClassName="min-h-[390px] pb-0"
      isOpen={open}
      onClose={onClose}
      size="md"
      title={intl.formatMessage({
        defaultMessage: "Add Objective",
        id: "global:addObjective",
      })}
    >
      <ObjectiveForm
        initialValues={getInitialValues()}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
