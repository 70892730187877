import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Button, CancelButton } from "common/buttons";
import { ButtonVariant } from "common/buttons/Button/Button";
import { Modal, ModalProps } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";

type Props = Pick<ModalProps, "titleClassName" | "titleWrapperClassName"> & {
  action?: (() => void) | (() => Promise<void>);
  buttonDisabled?: boolean;
  cancelText?: ReactNode;
  children?: React.ReactNode;
  close: () => void;
  confirmText: ReactNode;
  confirmVariant?: ButtonVariant;
  isLoading?: boolean;
  onCancel?: () => void;
  title?: ReactNode;
};
export type ConfirmationModalProps = Props;

/** unlike {@link Confirm}, this component doesn't handle its render state, so you can just render it conditionally
 * in a parent component and pass a `close()` action to close it. */
export const ConfirmationModal = ({
  action,
  children,
  close,
  confirmText,
  onCancel,
  title,
  titleClassName,
  titleWrapperClassName,
  confirmVariant,
  cancelText,
  buttonDisabled,
  isLoading,
}: Props): JSX.Element => {
  const fallbackTitle = (
    <FormattedMessage
      defaultMessage="Confirmation needed"
      id="global:confirm:title"
    />
  );

  const handleConfirm = async () => {
    await action?.();
    close();
  };

  const handleCancel = async () => {
    onCancel?.();
    close();
  };

  return (
    <Modal
      backdropClass="!z-modal-top-prio-backdrop"
      backdropClose={false}
      className="!z-modal-top-prio mt-[15%]"
      id="modal-confirm"
      isOpen
      onClose={close}
      scrollable={false}
      size="sm"
      title={title ?? fallbackTitle}
      titleClassName={titleClassName}
      titleWrapperClassName={titleWrapperClassName}
    >
      <div className="p-6">{children}</div>
      <ModalFooter showDivider>
        <Button
          className="bg-red-500"
          data-cy="P63CdUXwmN071VdM9v-jE"
          disabled={buttonDisabled}
          loading={isLoading}
          onClick={handleConfirm}
          variant={confirmVariant}
        >
          {confirmText}
        </Button>
        {cancelText ? (
          <CancelButton
            data-cy="vroUBhNjzrXNl3MQNSlt7"
            onClick={handleCancel}
            disabled={isLoading}
          >
            {cancelText}
          </CancelButton>
        ) : (
          <CancelButton
            disabled={isLoading}
            data-cy="vroUBhNjzrXNl3MQNSlt7"
            onClick={handleCancel}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
