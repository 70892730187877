import { defineMessages } from "react-intl";

export const messages = defineMessages({
  descriptionHelperText: {
    defaultMessage:
      "You can add context to share why this is a your Ultimate Goal. Explain your choices so that everyone's on the same page.",
    id: "ultimateGoal:forms:helper:text:description",
  },
  descriptionLabel: {
    defaultMessage: "Description",
    id: "ultimateGoal:forms:description:label",
  },
  descriptionPlaceholder: {
    defaultMessage: "Add a description..",
    id: "ultimateGoal:forms:description:placeholder",
  },
  howTo: {
    defaultMessage: "Here's how",
    id: "wizard:howTo",
  },
  nameHelperSubtext: {
    defaultMessage:
      "Curious to learn how to set a good Ultimate Goal? {examples}",
    id: "ultimateGoal:forms:helper:subtext",
  },
  nameHelperText: {
    defaultMessage:
      "Your Ultimate Goal define's your business's ultimate winning aspirations — describing what your organization’s purpose is and when you’ll consider your venture a success.",
    id: "ultimateGoal:forms:helper:text:title",
  },
  namePlaceholder: {
    defaultMessage:
      "Example: Offer the best and most popular resources and support",
    id: "ultimateGoal:forms:namePlaceholder",
  },
});
