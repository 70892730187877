import React, { ReactNode } from "react";
import { createPortal } from "react-dom";
import { twClass } from "utils/twClass";
import { ModalContext } from "../ModalContext";

interface ModalFooterProps {
  children: ReactNode;
  className?: string;
  showDivider?: boolean;
}

// TODO: the portal is unnecessary and leads to issues with not being able to just use a button type="submit". remove it.
export const ModalFooter = ({
  className,
  children,
  showDivider = true,
}: ModalFooterProps): JSX.Element => (
  <ModalContext.Consumer>
    {({ footerRef }) => {
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (footerRef && showDivider) {
        return createPortal(
          <div
            className={twClass(
              "modalfooter relative box-border flex w-full flex-row-reverse gap-3 rounded-b-md border-t p-5 bg-slate-50 border-slate-300 md:h-20",
              className
            )}
          >
            {children}
          </div>,
          footerRef
        );
      }
      return (
        <div className="modalfooter flex flex-row-reverse pt-2">{children}</div>
      );
    }}
  </ModalContext.Consumer>
);
