import { KeyResult } from "@graphql";
import { Derive } from "@shoooe/derive";
import { mapEdges } from "utils/mapEdges";

type ResultType = Derive<
  KeyResult,
  {
    children: {
      edges: {
        node: {
          id: true;
          index: true;
        };
      };
    };
    id: true;
    index: true;
    parent: {
      id: true;
    };
  }
>;

export const useOrderedResults = (allResults: ResultType[]) => {
  const resultSequence = allResults
    .filter((r) => !r.parent)
    .flatMap((r) => [r, ...mapEdges(r.children.edges)]);
  return resultSequence;
};
