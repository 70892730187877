import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dueDate: {
    defaultMessage: "Meeting day",
    id: "performanceReports:dueDate:label",
  },
  remainingUsers: {
    defaultMessage: "Remaining Users",
    id: "performanceReports:addForm:remainingUsers",
  },
  repeatFrequency: {
    defaultMessage: "Repeat",
    id: "performanceReports:cadence:label",
  },
  reviewee: {
    defaultMessage: "Reviewee",
    id: "performanceReports:reviewee:label",
  },
  selectAllDirectReports: {
    defaultMessage: "Select all your direct reports",
    id: "performanceReports:add:reviewee:subtitle",
  },
});
