import { modalActionTypes } from "../actionTypes";

const initialState = {
  language: "en",
  loading: true,
  modals: {
    data: {},
    status: {},
  },
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function setModalShow(state, action) {
  return {
    ...state,
    modals: {
      ...state.modals,
      [action.payload.modalType]: {
        open: true,
        ...action.payload.modalProps,
      },
    },
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
function setModalHide(state, action) {
  return {
    ...state,
    modals: {
      ...state.modals,
      [action.payload.modalType]: {
        open: false,
      },
    },
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export function globals(state = initialState, action) {
  switch (action.type) {
    case modalActionTypes.SHOW_MODAL:
      return setModalShow(state, action);
    case modalActionTypes.HIDE_MODAL:
      return setModalHide(state, action);
    default: {
      return state;
    }
  }
}
