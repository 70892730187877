import {
  PerformanceReviewQuestionAndAnswersDefaultFragment,
  PerformanceReviewQuestionVisibility,
  UserAvatarFragment,
  UserFullNameFragment,
} from "@graphql";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Avatar } from "common/avatar";
import { Show } from "common/controlFlow";
import { RatePicker } from "common/inputs/RatePicker/RatePicker";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { twClass } from "utils/twClass";

const messages = defineMessages({
  noResponse: {
    defaultMessage: "No response",
    id: "performanceReview:answer:noResponse",
  },
});

type UserDetails = (UserFullNameFragment & UserAvatarFragment) | null;

type UserAnswerProps = {
  paragraphAnswer: string | null;
  starRatingAnswer: number | null;
  user?: UserDetails;
};

export const UserAnswer = ({
  paragraphAnswer,
  starRatingAnswer,
  user,
}: UserAnswerProps): JSX.Element => {
  const intl = useIntl();
  return (
    <div className="flex w-full space-x-3">
      {user?.avatar && <Avatar className="mt-1" url={user.avatar} />}
      <div className="flex grow flex-col space-y-1">
        <span className="text-sm font-normal text-slate-800">
          {user?.fullName}
        </span>
        <div
          className={twClass(
            "rounded border bg-slate-100 border-slate-300",
            "w-full px-2 py-1",
            "text-base font-normal"
          )}
        >
          {!isEmpty(paragraphAnswer) && !isNil(paragraphAnswer) ? (
            <DangerousHTML html={paragraphAnswer} />
          ) : !isNil(starRatingAnswer) ? (
            <RatePicker disabled value={starRatingAnswer} />
          ) : (
            <span>{intl.formatMessage(messages.noResponse)}</span>
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  questionAndAnswers: PerformanceReviewQuestionAndAnswersDefaultFragment;
  reviewee?: UserDetails;
  reviewer?: UserDetails;
};

export const PerformanceReviewAnswer = ({
  questionAndAnswers,
  reviewee,
  reviewer,
}: Props): JSX.Element => {
  return (
    <div key={questionAndAnswers.id} className="w-full space-y-2">
      <span className="text-base font-medium text-slate-800">
        {questionAndAnswers.question}
      </span>
      <Show
        when={[
          PerformanceReviewQuestionVisibility.Both,
          PerformanceReviewQuestionVisibility.Manager,
        ].includes(questionAndAnswers.visibility)}
      >
        <UserAnswer
          paragraphAnswer={questionAndAnswers.reviewerParagraphAnswer}
          starRatingAnswer={questionAndAnswers.reviewerStarRatingAnswer}
          user={reviewer}
        />
      </Show>
      <Show
        when={[
          PerformanceReviewQuestionVisibility.Both,
          PerformanceReviewQuestionVisibility.DirectReport,
        ].includes(questionAndAnswers.visibility)}
      >
        <UserAnswer
          paragraphAnswer={questionAndAnswers.revieweeParagraphAnswer}
          starRatingAnswer={questionAndAnswers.revieweeStarRatingAnswer}
          user={reviewee}
        />
      </Show>
    </div>
  );
};
