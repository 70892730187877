import {
  GetActiveUsersQueryVariables,
  UserAvatarFragment,
  UserFullNameFragment,
} from "@graphql";
import { useField } from "formik";
import React from "react";
import { FormControl } from "common/form/FormControl/FormControl";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { UserMultiSelect } from "common/user/UserMultiSelect/UserMultiSelect";
import {
  ActiveUsersOptions,
  useActiveUsers,
} from "hooks/useActiveUsers/useActiveUsers";
import { isSet } from "utils/isSet";

type MultiSelectProps = React.ComponentProps<typeof UserMultiSelect>;

type User = UserFullNameFragment & UserAvatarFragment;

type Props = Omit<
  MultiSelectProps,
  "name" | "onChange" | "selectedUsers" | "users"
> & {
  label?: string;
  name: string;
  optional?: boolean;
  options?: Omit<ActiveUsersOptions, "variables">;
  variables?: GetActiveUsersQueryVariables;
};

export const UserMultiField = ({
  label,
  name,
  optional,
  options,
  variables,
  ...multiSelectProps
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<string[] | undefined>(name);
  const value = field.value ?? [];

  const users = useActiveUsers({
    ...options,
    variables,
  });

  if (!users) return <SpinnerFit />;

  const setSelectedUsers = (selected: User[]) => {
    helpers.setValue(selected.map(({ id }) => id));
  };

  const selectedUsers = value
    .map((userId) => users.find(({ id }) => id === userId))
    .filter(isSet);

  return (
    <FormControl
      errorText={meta.error}
      id={name}
      label={label}
      optional={optional}
    >
      <UserMultiSelect
        {...multiSelectProps}
        creatable
        onChange={setSelectedUsers}
        selectedUsers={selectedUsers}
        users={users}
      />
    </FormControl>
  );
};
