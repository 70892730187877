import { PerdooApiObjectiveStageChoices } from "@graphql";
import { useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { useResultContext } from "common/context/resultContext";
import { Show } from "common/controlFlow";
import { EntityIcon } from "common/icons";
import { InlineEditable } from "common/inputs/InlineEditable/InlineEditable";
import { PlainTextArea } from "common/inputs/PlainTextArea/PlainTextArea";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { resultTypes } from "constants/resultTypes";
import { twClass } from "utils/twClass";
import { useResultTitle } from "./hooks/useResultTitle";

const validationSchema = Yup.object().shape({
  title: Yup.string().max(250).required(),
});

export const ResultHeader = () => {
  const result = useResultContext();

  const { onSubmit } = useResultTitle(result.id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: result.name,
    },
    onSubmit,
    validationSchema,
  });

  const handleTitleSubmit = () => {
    if (formik.values.title === result.name) {
      return;
    }

    if (!formik.errors.title) {
      formik.submitForm();
    }
  };

  const closed =
    result.objective.stage === PerdooApiObjectiveStageChoices.Closed;
  const isEditable = !result.archived && !closed;

  const titleInput = (
    <PlainTextArea
      autoFocus
      data-cy="modalTitle"
      className={twClass(
        "block text-xl font-semibold text-slate-900",
        "w-full overflow-hidden border-2 px-3 py-1"
      )}
      minRows={1}
      name="title"
      onChange={(value) => formik.setFieldValue("title", value)}
      value={formik.values.title}
    />
  );
 
  return (
    <div
      data-testid="resultDetailsHeader"
      className="flex justify-between p-4 border-b border-slate-300 space-x-6"
    >
      <div className="flex space-x-2 items-center text-xl font-medium grow">
        <WithTooltip
          tooltip={
            result.type === resultTypes.keyResult ? (
              <FormattedMessage defaultMessage="Key Result" id="b+726N" />
            ) : (
              <FormattedMessage defaultMessage="Initiative" id="jpmEhT" />
            )
          }
        >
          <EntityIcon entity={result.type} />
        </WithTooltip>
        <div className="grow">
          <Show
            when={isEditable}
            fallback={<div className="text-xl">{result.name}</div>}
          >
            <InlineEditable
              className={twClass(
                "flex shrink-0 border-2 rounded px-3 py-1 text-xl border-transparent",
                { "hover:border-slate-300": result.canPatch }
              )}
              editComponent={titleInput}
              onCancel={() => formik.setFieldValue("title", result.name)}
              onEdit={handleTitleSubmit}
              disabled={!result.canPatch}
            >
              {formik.values.title}
            </InlineEditable>
          </Show>
        </div>
      </div>
    </div>
  );
};
