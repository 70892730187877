import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { twClass } from "utils/twClass";
import { usePanelContext } from "../hooks/usePanelContext/usePanelContext";

type Props = {
  className?: string;
  onClick?: () => void;
};

export const PanelCloseButton = ({
  className,
  onClick: onClickProp,
}: Props): JSX.Element => {
  const { onClose } = usePanelContext();
  const onClick = onClickProp ?? onClose;

  return (
    <IconButton
      data-cy="kguohTZevmDaot7c3UqhL"
      className={twClass("text-slate-500", className)}
      name="close"
      onClick={onClick}
      size="xl"
    />
  );
};
