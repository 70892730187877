import {
  OneOnOneMeeting,
  OneOnOneMeetingItem,
  OneOnOneMeetingItemTemplate,
  OneOnOneMeetingItemType,
  OneOnOneRepeatFrequency,
  OneOnOneSeries,
} from "@graphql";
import { v4 as uuidv4 } from "uuid";
import { mockPageInfo } from "mocks/common/pageInfo";
import { mockCompany_deprecated } from "mocks/entities/company";
import { mockCurrentUser, mockUsers } from "mocks/entities/user";
import { unmapEdges } from "utils/mapEdges";

export const aOneOnOneSeries = (
  overrides?: Partial<OneOnOneSeries>
): OneOnOneSeries => ({
  __typename: "oneOnOneSeries",
  attendee: overrides?.attendee ?? (mockUsers[0] as any), // mockUsers types need to be fixed
  canDelete: overrides?.canDelete ?? true,
  canPatch: overrides?.canPatch ?? true,
  company: overrides?.company ?? (mockCompany_deprecated as any), // mockCompany types need to be fixed
  createdDate: overrides?.createdDate ?? "2023-03-29T00:00:00Z",
  description: overrides?.description ?? null,
  gCalEventSeries: null,
  gCalIntegration: null,
  id: overrides?.id ?? uuidv4(),
  isActive: overrides?.isActive ?? true,
  isOrganizer: true,
  meetings: overrides?.meetings ?? {
    __typename: "oneOnOneMeetingConnection",
    edges: unmapEdges([], "oneOnOneMeetingEdge", "OneOnOneMeetingCursor"),
    pageInfo: mockPageInfo,
  },
  nextMeeting: overrides?.nextMeeting ?? null,
  organizer: overrides?.organizer ?? (mockCurrentUser as any), // mockCurrentUser types need to be fixed
  recurringEventId: null,
  repeatFrequency:
    overrides?.repeatFrequency ?? OneOnOneRepeatFrequency.NoRepeat,
});
export const aOneOnOneMeeting = (
  overrides?: Partial<OneOnOneMeeting>
): OneOnOneMeeting => ({
  __typename: "oneOnOneMeeting",
  attendeeNote: overrides?.attendeeNote ?? "attendee note",
  attendeePrivateNote: overrides?.attendeePrivateNote ?? "",
  canDelete: overrides?.canDelete ?? true,
  canPatch: overrides?.canPatch ?? true,
  completedDate: overrides?.completedDate ?? "2023-03-29T00:00:00Z",
  createdDate: overrides?.createdDate ?? "2023-03-29T00:00:00Z",
  dueDate: overrides?.dueDate ?? "3023-02-26T12:00:00Z",
  eventId: overrides?.eventId ?? null,
  gCalEvent: overrides?.gCalEvent ?? null,
  gCalIntegration: overrides?.gCalIntegration ?? null,
  id: overrides?.id ?? uuidv4(),
  isCancelled: overrides?.isCancelled ?? false,
  isComplete: overrides?.isComplete ?? false,
  isOrganizer: overrides?.isOrganizer ?? true,
  lastEditedDate: overrides?.lastEditedDate ?? "2023-03-29T00:00:00Z",
  meetingItems: overrides?.meetingItems ?? {
    __typename: "oneOnOneMeetingItemConnection",
    edges: unmapEdges([], "oneOnOneMeetingItemEdge", "meetingItemCursor"),
    pageInfo: mockPageInfo,
  },
  oneOnOneSeries: overrides?.oneOnOneSeries ?? aOneOnOneSeries(),
  organizerNote: overrides?.organizerNote ?? "organizer note",
  organizerPrivateNote: overrides?.organizerPrivateNote ?? "",
  scheduleDate: overrides?.scheduleDate ?? "3023-02-26T12:00:00Z",
});
export const aOneOnOneMeetingItem = (
  overrides?: Partial<OneOnOneMeetingItem>
): OneOnOneMeetingItem => ({
  __typename: "oneOnOneMeetingItem",
  assignee: overrides?.assignee ?? null,
  canDelete: overrides?.canDelete ?? true,
  canPatch: overrides?.canPatch ?? true,
  createdDate: overrides?.createdDate ?? "2023-04-01T16:00:00Z",
  dueDate: overrides?.dueDate ?? null,
  id: overrides?.id ?? uuidv4(),
  index: 0,
  isComplete: overrides?.isComplete ?? false,
  isRecurring: overrides?.isRecurring ?? false,
  lastEditedDate: overrides?.lastEditedDate ?? "2023-04-01T16:00:00Z",
  oneOnOneMeeting: overrides?.oneOnOneMeeting ?? aOneOnOneMeeting(),
  text: overrides?.text ?? "dummy meeting item text",
  type: overrides?.type ?? OneOnOneMeetingItemType.TalkingPoint,
});

export const aOneonOneMeetingItemsTemplate = (
  overrides?: Partial<OneOnOneMeetingItemTemplate>
): OneOnOneMeetingItemTemplate => ({
  __typename: "oneOnOneMeetingItemTemplate",
  canDelete: overrides?.canDelete ?? true,
  canPatch: overrides?.canPatch ?? true,
  createdBy: overrides?.createdBy ?? (mockCurrentUser as any), // mockCurrentUser types need to be fixed
  createdDate: overrides?.createdDate ?? "2023-04-01T16:00:00Z",
  description: overrides?.description ?? "Sample description",
  id: uuidv4(),
  name: "Sample meeting items template",
  talkingPoints: ["sample talking point 1", "sample talking point 2"],
});
