import { sortBy } from "lodash";
import moment from "moment";

export const cadencePresetIds = {
  ANNUAL: "ANNUAL",
  FIVE_YEAR: "5YEAR",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  SEMIANNUAL: "SEMIANNUAL",
  THREE_YEAR: "3YEAR",
};

export const cadenceIntervalTypes = {
  MONTH: "Month",
  WEEK: "Week",
  YEAR: "Year",
};

export type CadencePreset = {
  description: string;
  id: string;
  intervalLength: number;
  intervalType: string;
  name: string;
  startDate: string;
  tfFormatter: string;
};

const year = new Date().getFullYear();

export const cadencePresets: Record<string, CadencePreset> = {
  [cadencePresetIds.FIVE_YEAR]: {
    description: `Eg. ${year} - ${year + 5}`,
    id: cadencePresetIds.FIVE_YEAR,
    intervalLength: 5,
    intervalType: cadenceIntervalTypes.YEAR,
    name: "5 year",
    startDate: moment().startOf("year").format("YYYY-MM-DD"),
    tfFormatter: "{start[year]} - {end[year]}",
  },
  [cadencePresetIds.THREE_YEAR]: {
    description: `Eg. ${year} - ${year + 3}`,
    id: cadencePresetIds.THREE_YEAR,
    intervalLength: 3,
    intervalType: cadenceIntervalTypes.YEAR,
    name: "3 year",
    startDate: moment().startOf("year").format("YYYY-MM-DD"),
    tfFormatter: "{start[year]} - {end[year]}",
  },
  [cadencePresetIds.ANNUAL]: {
    description: `Eg. ${year}`,
    id: cadencePresetIds.ANNUAL,
    intervalLength: 1,
    intervalType: cadenceIntervalTypes.YEAR,
    name: "Annual",
    startDate: moment().startOf("year").format("YYYY-MM-DD"),
    tfFormatter: "{start[year]}",
  },
  [cadencePresetIds.SEMIANNUAL]: {
    description: `Eg. H1 ${year}, H2 ${year}`,
    id: cadencePresetIds.SEMIANNUAL,
    intervalLength: 6,
    intervalType: cadenceIntervalTypes.MONTH,
    name: "Semi-Annual",
    startDate: moment().startOf("year").format("YYYY-MM-DD"),
    tfFormatter: "{start[month]} - {end[month]} {start[year]}",
  },
  [cadencePresetIds.QUARTERLY]: {
    description: `Eg. Q1 ${year}, Q2 ${year}`,
    id: cadencePresetIds.QUARTERLY,
    intervalLength: 3,
    intervalType: cadenceIntervalTypes.MONTH,
    name: "Quarterly",
    startDate: moment().startOf("quarter").format("YYYY-MM-DD"),
    tfFormatter: "Q{start[quarter]} {start[year]}",
  },
  [cadencePresetIds.MONTHLY]: {
    description: `Eg. Jan ${year}`,
    id: cadencePresetIds.MONTHLY,
    intervalLength: 1,
    intervalType: cadenceIntervalTypes.MONTH,
    name: "Monthly",
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    tfFormatter: "{start[month]}",
  },
};

export const sortedCadencePresets = (): CadencePreset[] =>
  sortCadencePresets(cadencePresets);

const sortCadencePresets = (
  cadences: { [key: string]: CadencePreset },
  order: "ASC" | "DESC" | undefined = "DESC"
): CadencePreset[] =>
  sortBy(cadences, (cadence) => {
    const intervalInWeeks = {
      [cadenceIntervalTypes.WEEK]: 1,
      [cadenceIntervalTypes.MONTH]: 4,
      [cadenceIntervalTypes.YEAR]: 51,
    };
    const { intervalType, intervalLength } = cadence;
    if (order === "ASC") {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return intervalInWeeks[intervalType] * intervalLength;
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return -intervalInWeeks[intervalType] * intervalLength;
  });
