import {
  PerdooApiKpiGoalOperatorChoices,
  TargetsKpiFragment,
  useUpsertKpiTargetMutation,
} from "@graphql";
import dayjs from "dayjs";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { FormInput } from "common/form/FormInput/FormInput";
import { Margin } from "common/misc/Margin/Margin.styles";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { getMetricSymbol } from "constants/metric";
import { handleGqlServerErrors, setFormikGqlErrors } from "utils/forms";
import { OperatorSwitch } from "../Targets/OperatorSwitch/OperatorSwitch";

type FormData = {
  operator: PerdooApiKpiGoalOperatorChoices;
  threshold: number;
};

const schema = Yup.object().shape({
  goalThreshold: Yup.number().typeError("The target value must be a number"),
});

type Props = {
  callback: () => void;
  kpi: TargetsKpiFragment;
  onRequestClosed: () => void;
  open: boolean;
};

/**
 * opened when trying to add an OKR from a kpi dropdown but the kpi has no target yet. currently only used in subgroups?
 */
export const KpiSetTargetAddObjectiveModal = ({
  kpi,
  open,
  onRequestClosed,
  callback,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [upsertTarget] = useUpsertKpiTargetMutation();

  const onSubmit = async (
    { threshold, operator }: FormData,
    actions: FormikHelpers<FormData>
  ) => {
    try {
      const { data, errors } = await upsertTarget({
        variables: {
          input: {
            kpi: kpi.id,
            operator,
            startDate: dayjs.utc().startOf("month").format("YYYY-MM-DD"),
            threshold,
          },
        },
      });

      const mutationErrors = data?.upsertKpiTarget?.errors ?? [];
      if (mutationErrors.length > 0) {
        setFormikGqlErrors(actions, mutationErrors);
        return;
      }

      if (errors) {
        handleGqlServerErrors(errors);
        return;
      }
      actions.setSubmitting(false);
      callback();
      onRequestClosed();
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      backdropClose={false}
      data-cy="improveKpiOkrSetTargetModal"
      isOpen={open}
      onClose={onRequestClosed}
      title={
        <FormattedMessage
          defaultMessage="KPI target"
          id="kpi:add:okr:target:title"
        />
      }
    >
      <>
        <Margin bottom={5}>
          <FormattedMessage
            defaultMessage="Set a target for the KPI <strong>{name}</strong> before you create an OKR to support it."
            id="kpi:add:okr:target:line1"
            values={{
              name: kpi.name,
              strong: (str: ReactNode) => <strong>{str}</strong>,
            }}
          />
        </Margin>
        <Margin bottom={5}>
          <strong>
            <FormattedMessage
              defaultMessage="Target value"
              id="kpi:add:okr:target:line2"
            />
          </strong>
        </Margin>
        <div>
          <Formik
            initialValues={{
              operator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
              threshold: 0,
            }}
            onSubmit={onSubmit}
            render={({
              isSubmitting,
              setFieldValue,
              submitForm,
              errors,
              touched,
            }) => (
              <Form>
                <div className="flex space-x-2">
                  <OperatorSwitch
                    name="operator"
                    setFieldValue={setFieldValue}
                  />
                  <div
                    // Undoing `<Input />`'s natural margin
                    style={{ marginBottom: "-11px", marginTop: "-11px" }}
                  >
                    <Field
                      addOn={getMetricSymbol(kpi.metricUnit)}
                      component={FormInput}
                      data-cy="improveKpiOkrSetTargetModalThreshold"
                      errorText={touched.threshold && errors.threshold}
                      name="threshold"
                      type="number"
                    />
                  </div>
                </div>
                <ModalFooter>
                  <Button
                    data-cy="improveKpiOkrSetTargetModalSave"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={submitForm}
                    type="submit"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Save Change & Create",
                      id: "kpi:setTarget:form:submit",
                    })}
                  </Button>
                  <Button
                    data-cy="ihojSpZU4wxun2HRydOnc"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={onRequestClosed}
                    type="button"
                    variant="ghost"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Cancel",
                      id: "global:cancel",
                    })}
                  </Button>
                </ModalFooter>
              </Form>
            )}
            validationSchema={schema}
          />
        </div>
      </>
    </Modal>
  );
};
