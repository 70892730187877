import { ErrorMessage, useField, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { TextEditor } from "common/inputs/TextEditor/TextEditor";
import { useCompleteOneOnOneContext } from "common/oneOnOnes/context/completeOneOnOneContext";
import { useBeforeWindowUnload } from "hooks/useBeforeWindowUnload/useBeforeWindowUnload";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type Props = {
  name: string;
  onBlur: (value: string) => Promise<void>;
};

export const OneOnOneCompletePublicNoteField = ({
  onBlur,
  name,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const { dirty } = useFormikContext();
  const intl = useIntl();

  useBeforeWindowUnload(() => {
    if (dirty) {
      onBlur(field.value);
    }
  });

  const { refetchMeeting, startPolling, stopPolling } =
    useCompleteOneOnOneContext();

  const handleBlur = async () => {
    await onBlur(field.value);
    await refetchMeeting();
    startPolling();
  };

  const handleChange = (value: string) => {
    helpers.setValue(value);
  };

  const handleFocus = async () => {
    stopPolling();
  };

  const saveDataInBackground = (value: string) => onBlur(value);

  return (
    <>
      <TextEditor
        className={twClass("flex min-h-[5rem]", {
          "border-red-500": isSet(meta.error),
        })}
        containerClassName="grow"
        data-testid="public-note"
        onChange={handleChange}
        label={intl.$t({
          defaultMessage: "My notes",
          id: "UzyWYd",
        })}
        onBackgroundSave={saveDataInBackground}
        onEditorBlur={handleBlur}
        onEditorFocus={handleFocus}
        placeholder={intl.$t({
          defaultMessage: "Add notes",
          id: "CbpVeK",
        })}
        text={field.value}
      />
      <ErrorMessage
        className="text-sm text-red-500"
        component="div"
        name="publicNote"
      />
    </>
  );
};
