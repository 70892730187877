import { useGetActiveUsersQuery } from "@graphql";
import { size } from "lodash";
import React from "react";
import { Entity } from "common/avatar";
import { isSet } from "utils/isSet";
import defaultGravatar from "../../../../../../public/images/default_user.png";

type Props = {
  ids?: string[];
};

type User = {
  avatar?: string | null;
  email: string;
  fullName?: string | null;
  id: string;
};

const NO_OF_AVATARS_TO_DISPLAY = 10;

export const ObjectiveContributorsAvatars = ({
  ids,
}: Props): JSX.Element | null => {
  const { data } = useGetActiveUsersQuery({ fetchPolicy: "cache-and-network" });

  if (!data?.allUsers || !ids) return null;

  const users = data.allUsers.edges.reduce(
    (memo, { node }) => ({
      ...memo,
      [node.id]: node,
    }),
    {} as Record<string, User>
  );

  return (
    <div className="ml-3 flex">
      {ids
        .filter((id) => isSet(users[id]))
        .slice(0, NO_OF_AVATARS_TO_DISPLAY - 1)
        .map((id) => (
          <Entity
            key={id}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            avatar={users[id].avatar || defaultGravatar.src}
            avatarClass="mr-1"
            avatarSize="medium"
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            tooltip={users[id].fullName}
          />
        ))}
      {size(ids) > 9 && (
        <div className="ml-1 mr-2 flex h-9 w-9 items-center justify-center rounded-full bg-slate-200">
          +{size(ids) - NO_OF_AVATARS_TO_DISPLAY}
        </div>
      )}
    </div>
  );
};
