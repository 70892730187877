import { ReactNode, useEffect, useState } from "react";
import { twClass } from "utils/twClass";
import { InlineEditable } from "../InlineEditable/InlineEditable";
import { Input, InputSize } from "../Input/Input";

type Props = {
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  /** Value that is displayed when not editing. Defaults to `value`. */
  displayValue?: string;
  onCancel?: () => void;
  onChange: (value: string) => void;
  prefix?: ReactNode;
  size?: InputSize;
  suffix?: ReactNode;
  /** The value passed in to the input. */
  value: string;
};

export function InlineInput({
  value,
  onChange,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  prefix,
  suffix,
  size = "base",
  disabled,
  displayValue = value,
}: Props) {
  const [tempValue, setTempValue] = useState<string>(value);
  useEffect(() => setTempValue(value), [value]);

  const resetAndForward = () => {
    onChange(tempValue);

    // Here we reset the temp value to the value because we are not in edit
    // mode anymore and the edit might have been discarded by the `onChange`
    // (e.g. due to a validation error) and therefore we reset to the initial
    // state.
    setTempValue(value);
  };

  return (
    <div
      className={twClass("relative -ml-2.5", {
        "-ml-1.5": size === "sm",
        "-ml-2.5": size === "base",
      })}
    >
      <InlineEditable
        data-testid={dataTestId}
        onEdit={resetAndForward}
        editComponent={() => (
          <Input
            autoFocus
            value={tempValue}
            data-testid={dataTestId}
            onChange={(e) => {
              setTempValue(e.target.value);
            }}
            data-cy={dataCy}
            prefix={prefix}
            suffix={suffix}
            formControl={false}
            size={size}
          />
        )}
        disabled={disabled}
        className={twClass("flex items-center", {
          "cursor-not-allowed": disabled,
          "h-10 px-2.5 text-base space-x-2.5": size === "base",
          "h-8 px-1.5 text-sm space-x-1.5": size === "sm",
        })}
      >
        {prefix && <div className="flex items-center">{prefix}</div>}
        <div>{displayValue}</div>
        {suffix && <div className="flex items-center">{suffix}</div>}
      </InlineEditable>
    </div>
  );
}
