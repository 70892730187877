import { namedOperations, useGetObjectiveQuery } from "@graphql";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ListItem } from "common/misc/ListItem/ListItem";
import { DeleteObjectiveAction } from "common/objective/ObjectiveWrapper/ObjectiveActions/DeleteObjective/DeleteObjective";
import { EDIT_OBJECTIVE } from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { track } from "utils/tracker";

interface Props {
  id: string;
  name: string;
}

const refetchQueries = [
  namedOperations.Query.getRoadmap,
  namedOperations.Query.getUnalignedObjectives,
  namedOperations.Query.TotalUnalignedObjectivesCount,
];

export const MapsObjectiveDropdown = ({
  id,
  name,
}: Props): JSX.Element | null => {
  const permissions = usePermissions();
  const { goToObjective } = useModalRouter();
  const me = useCurrentUser();
  const { data } = useGetObjectiveQuery({
    variables: { objectiveId: id },
  });

  const handleEditObjective = () => {
    goToObjective({ oId: id });
    track(EDIT_OBJECTIVE);
  };
  const cannotUpdateOkr =
    data?.objective?.lead?.id !== me?.id && !permissions.canUpdateOkrs;
  if (cannotUpdateOkr || isNil(data?.objective)) {
    return null;
  }

  return (
    <Dropdown
      button={<IconButton data-cy="sp-dropdown" name="more_vert" size="xl" />}
      keepOpen
    >
      <ListItem data-cy="R0VcPdcqFuzIns1UHbjR-" onClick={handleEditObjective}>
        <FormattedMessage
          defaultMessage="Edit objective"
          id="objective:edit:title"
        />
      </ListItem>
      <DeleteObjectiveAction
        key="delete"
        id={id}
        name={name}
        onDeleteQueries={refetchQueries}
      />
    </Dropdown>
  );
};
