import dayjs from "dayjs";
import { useField } from "formik";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { For, Show } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { CurrentTimezone } from "common/timezone/CurrentTimezone";
import { FormControl } from "../FormControl/FormControl";

const Select = makeSelect<Option>();

type Option = {
  id: string;
  label: string;
};

type Props = {
  "data-cy"?: string;
  disabled?: boolean;
  /** Increment in minutes. */
  increment?: number;
  label?: string;
  name: string;
  showTimezone?: boolean;
};

const generateOptions = (increment?: number) => {
  const defaultIncrement = 30;
  const beginTime = "12:00AM";
  const endTime = dayjs("11:59PM", "HH:mmA");
  const values = [];

  for (
    let currentTime = dayjs(beginTime, "HH:mmA");
    currentTime.isBefore(endTime);
    currentTime = currentTime.add(increment ?? defaultIncrement, "minutes")
  ) {
    values.push({
      id: currentTime.format("HH:mm:ss"),
      label: currentTime.format("hh:mmA"),
    });
  }

  return values;
};

export const TimePickerField = ({
  name,
  disabled,
  label,
  showTimezone = true,
  increment,
  "data-cy": dataCy,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<string | null>(name);
  const options = useMemo(() => generateOptions(increment), [increment]);
  const selected = options.find((x) => x.id === field.value) ?? null;

  return (
    <div className="flex items-center gap-3">
      <FormControl
        className="w-40 my-0"
        errorText={meta.touched ? meta.error : undefined}
        id={name}
        label={label}
      >
        <Select.Root
          data-cy={dataCy}
          disabled={disabled}
          onChange={(opt) => helpers.setValue(opt?.id ?? null)}
          value={selected}
        >
          <Select.Trigger>
            <Select.Value innerClass="w-full">
              {selected?.label ?? (
                <FormattedMessage defaultMessage="Time" id="ug01Mk" />
              )}
            </Select.Value>
          </Select.Trigger>
          <Select.Dropdown className="divide-y divide-slate-300">
            <Select.Options>
              <For each={options} fallback={<SelectFallback />}>
                {(option) => (
                  <Select.Option key={option.id} value={option}>
                    {option.label}
                  </Select.Option>
                )}
              </For>
            </Select.Options>
          </Select.Dropdown>
        </Select.Root>
      </FormControl>
      <Show when={showTimezone}>
        <div className="flex items-center">
          <FormattedMessage
            defaultMessage="in {timezone}"
            values={{ timezone: <CurrentTimezone className="ml-2" /> }}
            id="Hd3AWC"
          />
        </div>
      </Show>
    </div>
  );
};
