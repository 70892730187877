import classNames from "clsx";
import React from "react";

type Props = {
  className?: string;
};

export const AvatarSkeleton: React.FC<Props> = ({ className }) => (
  <div
    className={classNames([
      "animate-pulse rounded-full bg-slate-200",
      className,
    ])}
  />
);
