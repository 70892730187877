import React from "react";
import { Icon } from "common/icons";
import { Tooltip } from "common/overlay";

type Props = {
  content: string;
};

/**
 * tooltip shown in reports.
 */
export const ReportCardTooltip = ({ content }: Props): JSX.Element => {
  return (
    <Tooltip content={content}>
      <Icon name="help_outline" size="base" />
    </Tooltip>
  );
};
