import { OneOnOneMeetingItemTemplate } from "@graphql";
import { Derive } from "@shoooe/derive";
import { Tuple } from "utils/Tuple";

type SAMPLE_TEMPLATE = Derive<
  OneOnOneMeetingItemTemplate,
  {
    canDelete: true;
    canPatch: true;
    createdBy: {
      fullName: true;
      id: true;
    };
    description: true;
    id: true;
    name: true;
    talkingPoints: true;
  }
> & { isSample: boolean };

export const PERDOO_MEETING_ITEM_TEMPLATES: Tuple<SAMPLE_TEMPLATE, 4> = [
  {
    canDelete: false,
    canPatch: false,
    createdBy: {
      fullName: "Perdoo",
      id: "PERDOO_SAMPLE",
    },
    description:
      "A new hire on board? Just getting started with 1:1 meetings? " +
      "Discuss topics that will help build strong work relations.",
    id: "PERDOO_SAMPLE_MEETING_ITEM_TEMPLATE_1",
    isSample: true,
    name: "First 1:1",
    talkingPoints: [
      "What do you like to do outside of work?",
      "What is one of the most impressive books that you've read recently?",
      "How do you like to communicate? (Video call, in-person, email, Slack, etc.)",
      "What time of day do you do your best work?",
      "What kind of projects are you most excited to work on?",
      "What do you hope to achieve this year?",
      "Are there any tools you need to do your job well?",
    ],
  },
  {
    canDelete: false,
    canPatch: false,
    createdBy: {
      fullName: "Perdoo",
      id: "PERDOO_SAMPLE",
    },
    description:
      "Questions to ask during every 1:1 meeting to ensure " +
      "your direct report feels and does well at work.",
    id: "PERDOO_SAMPLE_MEETING_ITEM_TEMPLATE_2",
    isSample: true,
    name: "Recurring 1:1s",
    talkingPoints: [
      "What has gone well, and what has gone not so well, since our last 1:1?",
      "How are you and your team progressing toward your goals?",
      "Do you feel confident you can achieve all your goals this quarter?",
      "Is there anything you need support with (eg, any blockers I can help you with)?",
      "What are your plans and priorities for this week?",
      "Is there anything we didn't cover that you'd like to discuss next time?",
    ],
  },
  {
    canDelete: false,
    canPatch: false,
    createdBy: {
      fullName: "Perdoo",
      id: "PERDOO_SAMPLE",
    },
    description:
      "Being a great manager requires an open line of communication. " +
      "Gather feedback to understand your direct report's needs and work on areas of improvement.",
    id: "PERDOO_SAMPLE_MEETING_ITEM_TEMPLATE_3",
    isSample: true,
    name: "Collect feedback",
    talkingPoints: [
      "On a scale of 1-10, how has my level of support been over the past month?",
      "Where do you need more support right now?",
      "Where do you think I should be focusing more of my attention?",
      "Are you getting enough feedback on your work?",
      "What “soft skills” do you think I excel at most? And what can I improve?",
    ],
  },
  {
    canDelete: false,
    canPatch: false,
    createdBy: {
      fullName: "Perdoo",
      id: "PERDOO_SAMPLE",
    },
    description:
      "Performance Reviews provide next steps required for advancement. " +
      "Help ensure your direct reports are working and progressing toward these growth " +
      "and career aspirations in between these formal assessments.",
    id: "PERDOO_SAMPLE_MEETING_ITEM_TEMPLATE_4",
    isSample: true,
    name: "Personal growth and career aspirations",
    talkingPoints: [
      "What progress have you made on your career goals since our last meeting?",
      "What would you like to achieve in the next few years?",
      "If you could choose any skill to develop, what would it be? ",
      "Are there any projects you'd really like to work on if you were given the opportunity?",
      "How are you progressing toward your career and life goals?",
      "Do you feel like you have enough opportunities to do what you do best?",
      "Is there someone you'd like to learn from at the company (or outside)?",
      "Which areas of your work would you like more feedback on?",
    ],
  },
];
