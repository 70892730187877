import { NotificationTypes } from "../../../../../notification/constants";

const {
  TimeframeChanged,
  KeyResultChanged,
  InitiativeChanged,
  CommentCreated,
  UserAddedToGroup,
  LegacyUserAddedToObjective,
  ObjectiveCompleted,
  LegacyUserAddedToInitiative,
  UserChanged,
  LegacyUserAddedToKeyResult,
  UserAddedToObjectiveAsLead,
  UserAddedToObjectiveAsContributor,
  UserAddedToInitiativeAsLead,
  UserAddedToInitiativeAsContributor,
  UserAddedToKeyResultAsLead,
  UserAddedToKeyResultAsContributor,
  UserAddedToKpiAsLead,
  UserAddedToKpiAsContributor,
} = NotificationTypes;

export const NotificationMessages = {
  [CommentCreated]: "notifications:commentCreated",
  [InitiativeChanged]: "notifications:initUpdated",
  [KeyResultChanged]: "notifications:krUpdated",
  [LegacyUserAddedToInitiative]: "notifications:madeOwner",
  [LegacyUserAddedToKeyResult]: "notifications:userAddedToKr",
  [LegacyUserAddedToObjective]: "notifications:madeContributor",
  [ObjectiveCompleted]: "notifications:oCompleted",
  [TimeframeChanged]: "notifications:tfChanged",
  [UserAddedToGroup]: "notifications:addedTo",
  [UserAddedToInitiativeAsContributor]: "notifications:madeContributor",
  [UserAddedToInitiativeAsLead]: "notifications:madeOwner",
  [UserAddedToKeyResultAsContributor]: "notifications:madeContributor",
  [UserAddedToKeyResultAsLead]: "notifications:madeOwner",
  [UserAddedToKpiAsContributor]: "notifications:madeContributor",
  [UserAddedToKpiAsLead]: "notifications:madeOwner",
  [UserAddedToObjectiveAsContributor]: "notifications:madeContributor",
  [UserAddedToObjectiveAsLead]: "notifications:madeOwner",
  [UserChanged]: "notifications:userChanged",
};

export const NotificationTargetGetter = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [CommentCreated]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [InitiativeChanged]: (extra) => extra.name,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [KeyResultChanged]: (extra) => extra.name,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [LegacyUserAddedToInitiative]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [LegacyUserAddedToKeyResult]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [LegacyUserAddedToObjective]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [ObjectiveCompleted]: (extra) => extra.name,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [TimeframeChanged]: (extra) => extra.change.newValue,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToGroup]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToInitiativeAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToInitiativeAsLead]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKeyResultAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKeyResultAsLead]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKpiAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKpiAsLead]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToObjectiveAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToObjectiveAsLead]: (extra) => extra.targetName,
};

// To check if the API has supplied all the necessary props for formatting
export const NotificationCompleteGetter = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [CommentCreated]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [InitiativeChanged]: (extra) => extra.change.attribute === "current_value",

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [KeyResultChanged]: (extra) => extra.change.attribute === "current_value",

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [LegacyUserAddedToInitiative]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [LegacyUserAddedToKeyResult]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [LegacyUserAddedToObjective]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [ObjectiveCompleted]: (extra) => extra.name,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [TimeframeChanged]: (extra) => extra.change.attribute === "default_tf",

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToGroup]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToInitiativeAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToInitiativeAsLead]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKeyResultAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKeyResultAsLead]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKpiAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToKpiAsLead]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToObjectiveAsContributor]: (extra) => extra.targetName,

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extra' implicitly has an 'any' type.
  [UserAddedToObjectiveAsLead]: (extra) => extra.targetName,
};
