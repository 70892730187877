import { CommitStatus } from "@graphql";
import { useField } from "formik";
import React from "react";
import { Status } from "common/charts/Status/Status";
import { For } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { FormControl } from "../FormControl/FormControl";

const Select = makeSelect<Option>();

type Props = {
  className?: string;
  "data-cy"?: string;
  label: string;
  name: string;
};

type Option = {
  id: CommitStatus;
};

export const options = [
  { id: CommitStatus.Accomplished },
  { id: CommitStatus.OnTrack },
  { id: CommitStatus.NeedsAttention },
  { id: CommitStatus.OffTrack },
  { id: CommitStatus.NoStatus },
];

export const CommitStatusField = ({
  name,
  label,
  "data-cy": dataCy,
  className,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<CommitStatus | null>(name);
  const selected = options.find((opt) => opt.id === field.value) ?? null;

  return (
    <div className={className}>
      <FormControl errorText={meta.error} id={name} label={label}>
        <Select.Root
          data-cy={dataCy}
          onChange={(opt) => helpers.setValue(opt?.id ?? null)}
          value={selected}
        >
          <Select.Trigger>
            <Select.Value>
              <Status status={field.value} />
            </Select.Value>
          </Select.Trigger>
          <Select.Dropdown>
            <Select.Options>
              <For each={options}>
                {(option) => (
                  <Select.Option value={option}>
                    <Status status={option.id} />
                  </Select.Option>
                )}
              </For>
            </Select.Options>
          </Select.Dropdown>
        </Select.Root>
      </FormControl>
    </div>
  );
};
