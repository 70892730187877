import { defineMessages } from "react-intl";

export const messages = defineMessages({
  expand: {
    defaultMessage: "Expand",
    id: "home:sidebar:expand",
  },
  new: {
    defaultMessage: "New",
    id: "global:new",
  },
  revertToOld: {
    defaultMessage: "Revert to old sidebar",
    id: "home:sidebar:revert",
  },
  tryNewSidebar: {
    defaultMessage: "Try out our new sidebar!",
    id: "home:sidebar:tryNewSidebar",
  },
});
