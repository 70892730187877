import { TimelineEventFragmentFragment } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EventTypes } from "common/event/constants";
import { Target } from "common/event/Target/Target";
import { logToSentry } from "utils/tracker";
import { EventFormatter } from "./types";

const { UserChanged, UserCreated, InvitationCreated } = EventTypes;

export const UserEventFormatters: Record<string, EventFormatter> = {
  [InvitationCreated]: {
    getComment: (): null => null,
    getString: ({ extra }: TimelineEventFragmentFragment): JSX.Element => {
      return (
        <FormattedMessage
          defaultMessage="invited {userEmail}"
          id="event:formatter:user:invited"
          values={{
            userEmail: <Target element={extra.name} />,
          }}
        />
      );
    },
  },
  [UserChanged]: {
    getComment: (): null => null,
    getString: ({
      extra,
      description,
    }: TimelineEventFragmentFragment): JSX.Element => {
      let formattedEvent;
      switch (extra.change.attribute) {
        case "language": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed their language from {oldVal} to {newVal}"
              id="event:formatter:user:changedLanguage"
              values={{
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "first_name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed their first name from {oldVal} to {newVal}"
              id="event:formatter:user:changedFirstName"
              values={{
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "last_name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed their last name from {oldVal} to {newVal}"
              id="event:formatter:user:changedLastName"
              values={{
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "avatar": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="updated their avatar"
              id="event:formatter:user:changedAvatar"
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: UserChanged,
            extra,
          });
          formattedEvent = <span>{description}</span>;
        }
      }

      return formattedEvent;
    },
  },
  [UserCreated]: {
    getComment: (): null => null,
    getString: (attr: { extra: { name: string } }): JSX.Element => {
      const { extra } = attr;
      return (
        <FormattedMessage
          defaultMessage="created the user {userEmail}"
          id="event:formatter:user:created"
          values={{
            userEmail: <Target element={extra.name} />,
          }}
        />
      );
    },
  },
};
