import React, { useState } from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ScrollDirection } from "hooks/useScrollElement/useScrollElement";
import { twClass } from "utils/twClass";

type Props = {
  buttonNext?: React.ReactElement;
  buttonPrev?: React.ReactElement;
  children: React.ReactElement[];
  className?: string;
  /** this class will be added to the container of an item which is not currently in view. */
  hiddenChildClass?: string;
  /** percentage of carousel width that each item should take (default = 100%) */
  itemMinWidth?: number;
};

/**
 * a generic Carousel component for showing a list of items as a vertical slide show.
 */
export const Carousel = ({
  buttonNext: buttonNextProp,
  buttonPrev: buttonPrevProp,
  children,
  className,
  hiddenChildClass = "",
  itemMinWidth = 100,
}: Props): JSX.Element => {
  const [visibleChild, setVisibleChild] = useState(0);
  const canScrollLeft = visibleChild > 0;
  const canScrollRight = visibleChild < children.length - 1;

  const scrollHandler = (direction: ScrollDirection) => () => {
    const newVisibleChild = visibleChild + direction;
    if (newVisibleChild < 0 || newVisibleChild > children.length - 1) return;

    setVisibleChild(newVisibleChild);
  };

  let buttonPrev = (
    <IconButton
      className={twClass(
        "bg-white text-blue-500 hover:bg-slate-300",
        "rounded-full border shadow border-slate-300",
        "p-0.5"
      )}
      data-cy="carouselButtonPrev"
      disabled={!canScrollLeft}
      name="arrow_back"
      onClick={scrollHandler(ScrollDirection.LEFT)}
    />
  );

  if (buttonPrevProp) {
    buttonPrev = React.cloneElement(buttonPrevProp, {
      disabled: !canScrollLeft,
      onClick: scrollHandler(ScrollDirection.LEFT),
    });
  }

  let buttonNext = (
    <IconButton
      className={twClass(
        "bg-white text-blue-500 hover:bg-slate-300",
        "rounded-full border shadow border-slate-300",
        "p-0.5"
      )}
      data-cy="carouselButtonNext"
      disabled={!canScrollRight}
      name="arrow_forward"
      onClick={scrollHandler(ScrollDirection.RIGHT)}
    />
  );

  if (buttonNextProp) {
    buttonNext = React.cloneElement(buttonNextProp, {
      disabled: !canScrollRight,
      onClick: scrollHandler(ScrollDirection.RIGHT),
    });
  }

  const paddingSize = itemMinWidth === 100 ? 0 : 15;
  let scrollOffset = itemMinWidth * visibleChild;
  if (visibleChild > 0 && itemMinWidth < 100) {
    // we scroll items a bit (5%) to the right, so the previous item can be seen.
    // we don't do it for the first one to avoid showing empty space.
    scrollOffset -= 5;
  }

  return (
    <div
      className={twClass(
        "relative box-content touch-none overflow-x-hidden py-2",
        className
      )}
      data-cy="carousel"
    >
      <div
        className={twClass(
          "flex h-full items-center",
          "absolute left-5 top-0",
          "z-higher transition-opacity",
          { hidden: !canScrollLeft }
        )}
        data-cy="carouselButtonPrevContainer"
      >
        {buttonPrev}
      </div>
      <div
        className="relative flex h-full w-full transition-transform"
        data-cy="carouselInner"
        style={{
          transform: `translateX(${-scrollOffset}%)`,
        }}
      >
        {children.map((child, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={twClass("relative inline-block h-full", {
              [hiddenChildClass]: visibleChild !== index,
            })}
            data-cy="carouselItem"
            style={{
              minWidth: `${itemMinWidth}%`,
              paddingLeft: paddingSize,
            }}
          >
            {child}
          </div>
        ))}
      </div>
      <div
        className={twClass(
          "flex h-full items-center",
          "absolute right-5 top-0",
          "z-higher transition-opacity",
          { hidden: !canScrollRight }
        )}
        data-cy="carouselButtonNextContainer"
      >
        {buttonNext}
      </div>
    </div>
  );
};
