import { OneOnOneRepeatFrequency, OneOnOneSeries } from "@graphql";
import { Derive } from "@shoooe/derive";
import dayjs from "dayjs";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import * as Yup from "yup";
import { Modal } from "common/overlay/Modal/Modal";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useHandleOneOnOneMutations } from "../hooks/useHandleOneOnOneMutations";
import {
  AddOneOnOneMeetingForm,
  AddOneOnOneMeetingValues,
} from "../OneOnOneMeetingForm/AddOneOnOneMeetingForm";
import { oneOnOneDefaults } from "../utils";

const validationSchema = Yup.object().shape({
  attendee: Yup.string().typeError("Required").required("Required"),
  dueDate: Yup.date()
    .nullable()
    .when("googleCalendarEventAction", {
      is: "LINK_EXISTING_EVENT",
      otherwise: (schema) =>
        schema
          .required("Required")
          .typeError("Required")
          .min(dayjs().add(-1, "day"), "Meeting date can't be in the past"),
      then: (schema) => schema,
    }),
  googleCalendarEvent: Yup.string()
    .nullable()
    .when("googleCalendarEventAction", {
      is: "LINK_EXISTING_EVENT",
      otherwise: (schema) => schema,
      then: (schema) => schema.required("Required"),
    }),
  googleCalendarEventAction: Yup.string().nullable(),
  meetingTime: Yup.string()
    .nullable()
    .when("googleCalendarEventAction", {
      is: "LINK_EXISTING_EVENT",
      otherwise: (schema) => schema.required("Required"),
      then: (schema) => schema,
    }),
  repeatFrequency: Yup.string()
    .nullable()
    .when("googleCalendarEventAction", {
      is: "LINK_EXISTING_EVENT",
      otherwise: (schema) =>
        schema
          .oneOf(Object.values(OneOnOneRepeatFrequency))
          .required("Please select a valid repeat frequency."),
      then: (schema) => schema,
    }),
});

type OneOnOneCreatedCallbackPayload = Derive<
  OneOnOneSeries,
  {
    id: true;
    nextMeeting: {
      id: true;
    };
  }
>;

export type OneOnOneCreatedCallback = (
  oneOnOneSeries: OneOnOneCreatedCallbackPayload
) => void;

type Props = {
  attendee?: string;
  onOneOnOneCreated?: OneOnOneCreatedCallback;
};

export const AddOneOnOneMeetingModal = ({
  attendee,
  onOneOnOneCreated,
}: Props): JSX.Element | null => {
  const intl = useIntl();

  const { createNewSeriesAndMeeting } = useHandleOneOnOneMutations();
  const { close } = useModalRouter();

  const initialValues = (): AddOneOnOneMeetingValues => {
    const initial = {
      attendee: attendee ?? null,
      description: null,
      dueDate: oneOnOneDefaults.defaultDueDate(),
      googleCalendarEvent: null,
      googleCalendarEventAction: null,
      isActive: true,
      meetingTime: oneOnOneDefaults.defaultMeetingTime(),
      repeatFrequency: oneOnOneDefaults.defaultRepeatFrequency,
    };
    return initial;
  };

  const handleSubmit = async (
    values: AddOneOnOneMeetingValues,
    actions: FormikHelpers<AddOneOnOneMeetingValues>
  ) => {
    const { hasError, oneOnOneSeries } =
      await createNewSeriesAndMeeting(values);
    actions.setSubmitting(false);
    if (hasError || !oneOnOneSeries) return;
    onOneOnOneCreated?.(oneOnOneSeries);
    close();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen
      onClose={close}
      size="sm"
      title={intl.formatMessage({
        defaultMessage: "Plan 1:1 meeting",
        id: "d92ZDt",
      })}
    >
      <Formik<AddOneOnOneMeetingValues>
        initialValues={initialValues()}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <AddOneOnOneMeetingForm onCancel={close} />
      </Formik>
    </Modal>
  );
};
