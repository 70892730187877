import {
  FetchSelectObjectivesQueryVariables,
  ObjectiveDefaultFragment,
  ObjectiveGroupsFragment,
  ObjectiveLeadFragment,
  ObjectiveTimeframeFragment,
  useFetchSelectObjectivesQuery,
} from "@graphql";
import { isNil } from "lodash";
import { useMemo } from "react";
import { mapEdges } from "utils/mapEdges";

export type AlignedObjective = ObjectiveDefaultFragment &
  ObjectiveGroupsFragment &
  ObjectiveLeadFragment &
  ObjectiveTimeframeFragment;

type Props = {
  excludedIds?: string[];
  variables?: FetchSelectObjectivesQueryVariables;
};

type Hook = {
  loading: boolean;
  objectives?: AlignedObjective[];
};

export const useGetAlignedObjectives = ({
  variables,
  excludedIds,
}: Props = {}): Hook => {
  const { data } = useFetchSelectObjectivesQuery({ fetchPolicy:"cache-and-network", variables });

  const objectives = useMemo(
    () =>
      mapEdges(data?.objectives.edges)
        .filter(({ id }) => !excludedIds?.includes(id))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [data, excludedIds]
  );

  return {
    loading: isNil(data),
    objectives,
  };
};
