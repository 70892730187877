import { gql } from "@apollo/client";
import { apolloClient } from "apolloClient";
import crossFetch from "cross-fetch";
import { defineMessages } from "react-intl";
import { auth } from "common/authHelper";
import { objectTypes } from "constants/objects";
import { CSV_EXPORT, UPLOAD_PROFILE_PICTURE } from "constants/tracking";
import {
  UPLOAD_AVATAR_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
} from "modules/company/Company/Company.constants";
import { UPSERT_COMPANY_MUTATION } from "modules/company/Company/Company.graphql";
import { UPSERT_GROUP_MUTATION } from "modules/groups/[id]/Group/LegacyGroup.graphql";
import { callApi } from "utils/api";
import { defaultErrorHandler } from "utils/errorHandler";
import { handleNonFormErrors } from "utils/forms";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import {
  ADD_ANALYTICS_EVENT_FAILURE,
  ADD_ANALYTICS_EVENT_REQUEST,
  ADD_ANALYTICS_EVENT_SUCCESS,
  modalActionTypes,
} from "../actionTypes";

const messages = defineMessages({
  csvExportNotification: {
    defaultMessage: "You will receive an email when your export is ready!",
    id: "csv:email:notification",
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
export const trackBackendLogin = () => (dispatch) =>
  dispatch({
    CALL_API: {
      endpoint: "/analytics_event",
      method: "POST",
      payload: {
        data: {
          attributes: {
            name: "Login",
            payload: {
              client: "WEB",
            },
          },
          type: "analytics_event",
        },
      },
      types: [
        ADD_ANALYTICS_EVENT_REQUEST,
        ADD_ANALYTICS_EVENT_SUCCESS,
        ADD_ANALYTICS_EVENT_FAILURE,
      ],
    },
    type: "trackBackendLogin",
  });

const endpoints = {
  [objectTypes.objective]: `/objective`,
  [objectTypes.keyresult]: `/key_result`,
  [objectTypes.kpi]: `/kpi`,
  [objectTypes.group]: `/group`,
  [objectTypes.user]: `/user`,
};

export type CSVExportEntityType = keyof typeof endpoints;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'queryParams' implicitly has an 'any' ty... Remove this comment to see the full error message
export const getCsvExport = (type: CSVExportEntityType, queryParams, intl) => {
  track(CSV_EXPORT, { type });
  toast.success(intl.formatMessage(messages.csvExportNotification));

  return callApi(`${endpoints[type]}`, {
    headers: {
      Authorization: auth.getAccessToken() ?? undefined,
    },
    params: {
      ...queryParams,
      csv: true,
    },
    responseType: "blob",
  });
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'modalType' implicitly has an 'any' type... Remove this comment to see the full error message
export function showModal(modalType, modalProps = {}) {
  return {
    payload: { modalProps, modalType },
    type: modalActionTypes.SHOW_MODAL,
  };
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'modalType' implicitly has an 'any' type... Remove this comment to see the full error message
export function hideModal(modalType) {
  return {
    payload: { modalType },
    type: modalActionTypes.HIDE_MODAL,
  };
}

export const UPSERT_USER_MUTATION = gql`
  mutation upsertUser($input: UpsertUserMutationInput!) {
    upsertUser(input: $input) {
      user {
        id
        email
        firstName
        lastName
        fullName
        jobPosition
        avatar
        role
        accountStatus
        manualSlackChannel
        isActive
        language
        notificationsEnabled
        notificationsSettings
        manager {
          id
        }
        groups {
          edges {
            node {
              id
            }
          }
        }
        slackEnabled
      }
      errors {
        field
        messages
      }
    }
  }
`;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
export const uploadAvatar = (id, type, file) => (dispatch) => {
  return (
    dispatch({
      CALL_API: {
        endpoint: `/${type}/${id}/upload/avatar`,
        queryParams: {
          file_name: file.name,
          file_type: file.type,
        },
        types: [
          UPLOAD_AVATAR_REQUEST,
          UPLOAD_AVATAR_SUCCESS,
          UPLOAD_AVATAR_FAILURE,
        ],
      },
      type: "uploadUserPicture",
    })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) => {
        // Upload the image to the signed URL, and resolve with the intended URL for
        // the avatar
        const { url, signedURL } = response.data;
        return crossFetch(signedURL, {
          body: file,
          headers: {
            "Content-Type": file.type,
          },
          method: "PUT",
        }).then(() => url);
      })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'avatarUrl' implicitly has an 'any' type... Remove this comment to see the full error message
      .then((avatarUrl) => {
        switch (type) {
          case objectTypes.user:
            apolloClient
              .mutate({
                mutation: UPSERT_USER_MUTATION,
                variables: {
                  input: {
                    avatar: avatarUrl,
                    id,
                  },
                },
              })
              .then((response) => {
                const { errors } = response.data.upsertUser;
                if (errors.length) {
                  handleNonFormErrors(errors);
                }
              });

            break;
          case objectTypes.group:
            apolloClient
              .mutate({
                mutation: UPSERT_GROUP_MUTATION,
                variables: {
                  input: {
                    avatar: avatarUrl,
                    id,
                  },
                },
              })
              .then((response) => {
                const { errors } = response.data.upsertGroup;
                if (errors.length) {
                  handleNonFormErrors(errors);
                  return;
                }
                toast.success("Group updated");
              });
            break;
          case objectTypes.company:
            apolloClient.mutate({
              mutation: UPSERT_COMPANY_MUTATION,
              variables: {
                input: {
                  avatar: avatarUrl,
                  id,
                },
              },
            });
            break;
          default:
            break;
        }
      })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) => {
        track(UPLOAD_PROFILE_PICTURE, {
          type,
        });
        return response;
      })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'err' implicitly has an 'any' type.
      .catch((err) => {
        defaultErrorHandler(err);
      })
  );
};
