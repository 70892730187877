import React from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { twClass } from "utils/twClass";

interface Props {
  autoFocus?: boolean;
  className?: string;
  cols?: number;
  id?: string;
  minRows?: number;
  name?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  value: string;
  wrap?: string;
}

type TextProps = Omit<TextareaAutosizeProps, "onChange"> & Props;

export const PlainTextArea = React.forwardRef<HTMLTextAreaElement, TextProps>(
  (
    {
      autoFocus = false,
      className,
      minRows = 3,
      value,
      name,
      onBlur,
      onChange,
      ...props
    },
    ref
  ) => {
    const handleChange = ({
      target,
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(target.value);
    };

    return (
      <TextareaAutosize
        ref={ref}
        autoFocus={autoFocus}
        className={twClass(
          "w-full resize-none overflow-y-hidden p-3",
          "rounded border bg-white focus:outline-blue-500",
          className
        )}
        minRows={minRows}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value}
        {...props}
      />
    );
  }
);
