import React from "react";
import { useIntl } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";

interface Props {
  archivedComment?: string;
  archivedDate?: string;
}

export const ResultArchivedIndicator = ({
  archivedComment,
  archivedDate,
}: Props) => {
  const intl = useIntl();
  const sanitizedComment = archivedComment
    ? archivedComment.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    : "";

  return (
    <Tooltip
      content={
        <div className="space-y-2 p-2">
          <h5 className="my-0">
            {archivedComment
              ? intl.formatMessage({
                  defaultMessage: "Archiving note:",
                  id: "result:archiving:note",
                })
              : intl.formatMessage({
                  defaultMessage: "No archiving note added",
                  id: "result:archiving:note:empty",
                })}
          </h5>
          <div className="line-clamp-6">
            <DangerousHTML html={sanitizedComment} />
          </div>
        </div>
      }
    >
      <div className="flex items-center gap-x-1.5 text-sm text-slate-500">
        <Icon name="archive" size="md" />
        <span data-cy="archivedMessage">
          {intl.formatMessage(
            {
              defaultMessage: "Archived on {archivedDate}",
              id: "result:archived:date",
            },
            {
              archivedDate: intl.formatDate(archivedDate),
            }
          )}
        </span>
      </div>
    </Tooltip>
  );
};
