import SplitPane from "react-split-pane";
import { useKpiContext } from "common/context/kpiContext";
import { Show } from "common/controlFlow";
import { useModalSplitter } from "hooks/useModalSplitter/useModalSplitter";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { KpiSidebar } from "../KpiSidebar/KpiSidebar";
import { KpiSummary } from "../KpiSummary/KpiSummary";

export const KpiDetails = () => {
  const kpi = useKpiContext();
  const { handleSplitterResize, isDragging, setIsDragging, splitPos } =
    useModalSplitter({ defaultSize: 400 });

  return (
    <Show when={isSet(kpi)}>
      {/* @ts-expect-error FIXME: TS2322: Type '{ children: Element[]; ... }' is not assignable to type ... */}
      <SplitPane
        primary="second"
        data-cy="kpiSplitter"
        className="!relative flex-reverse"
        defaultSize={splitPos}
        minSize={400}
        maxSize={800}
        onChange={handleSplitterResize}
        onDragFinished={() => setIsDragging(false)}
        onDragStarted={() => setIsDragging(true)}
        resizerClassName={twClass(
          "bg-white border-r !border-r-slate-300 hover:!border-r-blue-500",
          {
            "!border-r-blue-500": isDragging,
          }
        )}
        split="vertical"
      >
        <div className="col-span-3 h-full modal-scrollbar modal-scrollbar-thumb overflow-y-scroll overflow-x-hidden">
          <KpiSummary />
        </div>
        <div className="modal-scrollbar modal-scrollbar-thumb h-full overflow-y-auto bg-slate-50 overflow-x-hidden">
          <KpiSidebar />
        </div>
      </SplitPane>
    </Show>
  );
};
