import React from "react";
import { twClass } from "utils/twClass";

interface FilterSetItemProps {
  children: React.ReactNode;
  "data-cy"?: string;
  onClick?: React.MouseEventHandler<HTMLLIElement> | undefined;
}
export const FilterSetItem = ({
  children,
  "data-cy": dataCy,
  onClick,
}: FilterSetItemProps): JSX.Element => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      className={twClass(
        "flex min-h-[30px] items-center whitespace-nowrap px-3 py-1 font-normal",
        "hover:cursor-pointer hover:bg-slate-300",
        "i-child:mr-2 i-child:fill-slate-800 i-child:text-slate-800",
        "svg-child:mr-2 svg-child:fill-slate-800 svg-child:text-slate-800"
      )}
      data-cy={dataCy}
      onClick={onClick}
    >
      {children}
    </li>
  );
};
