import { findIndex, lowerCase } from "lodash";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { arrayMoveImmutable } from "utils/array-move";
import { onboardingData } from "utils/sampleData.constants";
import { SampleGoal, SampleObjective } from "utils/sampleData.types";
import {
  getSampleKeyResults,
  getSampleKpis,
  getSampleObjectives,
} from "utils/sampleData.utils";

const messages = defineMessages({
  suggested: {
    defaultMessage: "Suggested",
    id: "sample:suggested",
  },
});

const resolveItemGetter = (
  type: "kpi" | "objective" | "result"
): ((ownerList: string[], intl: IntlShape) => SampleGoal[]) => {
  switch (type) {
    case "kpi":
      return getSampleKpis;
    case "objective":
      return getSampleObjectives;
    case "result":
      return getSampleKeyResults;
    default:
      throw new Error(`invalid goal type: ${type}`);
  }
};

/**
 * gets sample goals of a given type for all sample groups, with the goals for given group highlighted first if found.
 *
 * @param type
 * @param highlightedGroup
 * @param sampleObjective
 */
export const useSampleGoals = (
  type: "kpi" | "objective" | "result",
  highlightedGroup = "",
  sampleObjective?: SampleObjective
): [{ items: SampleGoal[]; name: string }[], boolean] => {
  const intl = useIntl();
  const getItems = resolveItemGetter(type);

  let groups = Object.keys(onboardingData.objectives);

  const currentGroupIndex = findIndex(groups, (groupName) =>
    lowerCase(highlightedGroup).includes(groupName)
  );

  const sampleGoalsFound = currentGroupIndex > 0;
  if (sampleGoalsFound) {
    groups = arrayMoveImmutable(groups, currentGroupIndex, 0);
  }

  const sampleGoalsByGroup = groups.map((group) => ({
    items: getItems([group], intl),
    name: group,
  }));

  if (sampleObjective) {
    sampleGoalsByGroup.unshift({
      items: sampleObjective.keyResults,
      name: intl.formatMessage(messages.suggested),
    });
  }

  return [sampleGoalsByGroup, sampleGoalsFound];
};
