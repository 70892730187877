import React from "react";
import { Clickable } from "common/navigation/Clickable";
import { twClass } from "utils/twClass";

export type RadioSelectProps = {
  addOn?: React.ReactElement;
  children: React.ReactElement;
  disabled?: boolean;
  onClick?: (select: boolean) => boolean | void;
  selected?: boolean;
};

export const RadioSelect = ({
  onClick,
  selected,
  disabled = false,
  addOn,
  children,
  ...other
}: RadioSelectProps): JSX.Element => {
  return (
    <Clickable
      data-cy="h_u3BEM2scNUhla-pgdBx"
      className={twClass(
        "flex min-h-14 w-full items-center space-x-6 rounded border p-3 border-slate-300",
        {
          "bg-slate-200": disabled,
          "cursor-pointer hover:border-blue-500 active:border-blue-500":
            !disabled,
        }
      )}
      onClick={() => onClick?.(!selected)}
      {...other}
    >
      <div className="flex items-center space-x-4 grow min-w-0">
        <div className="flex h-5 w-5 items-center justify-center rounded-full border border-slate-300">
          {selected && <div className="h-3 w-3 rounded-full bg-blue-500" />}
        </div>
        <div className="grow min-w-0">{children}</div>
      </div>
      {addOn && <div className="flex items-center">{addOn}</div>}
    </Clickable>
  );
};
