import { defineMessages } from "react-intl";

export const messages = defineMessages({
  issueCountLabel: {
    defaultMessage: "Epic progress by issues completed",
    id: "jira:progressType:issueCount:label",
  },
  statusLabel: {
    defaultMessage: "Issue status",
    id: "jira:progressType:status:label",
  },
  storyPointsLabel: {
    defaultMessage: "Epic progress by story points completed",
    id: "jira:progressType:storyPoints:label",
  },
});
