import React, { forwardRef, ReactNode } from "react";
import { Placement } from "tippy.js";
import { Tooltip, TooltipTheme } from "common/overlay/Tooltip/Tooltip";

type Props = {
  children: ReactNode;
  className?: string;
  delay?: number;
  interactive?: boolean;
  maxWidth?: string;
  /** default = "top" */
  placement?: Placement;
  theme?: TooltipTheme;
  tooltip?: ReactNode;
  visible?: boolean;
};
export type WithTooltipProps = Props;

/**
 * wraps the element with a tooltip if provided and not empty.
 * TODO: just implement the conditional functionality in Tooltip and remove this component.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {ReactNode} props.children
 * @param {number} [props.delay]
 * @param {ReactNode} [props.tooltip]
 * @param {Placement} [props.placement]
 */
export const WithTooltip = forwardRef<Element, Props>(
  (
    {
      children,
      className,
      delay,
      interactive,
      maxWidth,
      placement,
      theme,
      tooltip,
      visible,
    }: Props,
    ref
  ) => {
    if (!tooltip) {
      return <>{children}</>;
    }

    return (
      <Tooltip
        ref={ref}
        content={tooltip}
        spanClass={className}
        {...{ delay, interactive, maxWidth, placement, theme, visible }}
      >
        <>{children}</>
      </Tooltip>
    );
  }
);

WithTooltip.displayName = "WithTooltip";
