import { FetchResult } from "@apollo/client";
import { isEmpty, merge } from "lodash";
import { toast } from "utils/toastr";

type ErrorType = {
  field: string;
  messages: string[];
};

type Options = {
  /**
   * disable to suppress toast messages for top-level errors.
   */
  handleTopLevel?: boolean;
  /** override to show the same generic message for top-level errors. */
  topLevelMessage?: string;
};

const defaultOptions: Options = {
  handleTopLevel: false,
  topLevelMessage: undefined,
};

type Util = {
  hasError: boolean;
};

/**
 * shows a toast failure for each graphql error. handles both top-level errors (`response.errors`) and query-specific
 * errors (`response.data.{queryName}.errors`).
 *
 * @returns {boolean} hasError
 * @example
 * const response = doSomeMutation();
 * const { hasError } = handleErrors(response, response.data.someQuery.errors);
 * if (hasError) return;
 */
export const handleErrors = <Query>(
  response: FetchResult<Query>,
  querySpecificErrors?: Nullable<Nullable<ErrorType>[]>,
  optionsProp?: Options
): Util => {
  const { errors } = response;

  const options = merge({}, defaultOptions, optionsProp);

  let hasError = false;

  if (options.handleTopLevel) {
    errors?.forEach((error) =>
      toast.failure(options.topLevelMessage ?? error.message)
    );

    hasError = !isEmpty(errors);
  }

  querySpecificErrors?.forEach((errorMessages) => {
    errorMessages?.messages.forEach(toast.failure);
    hasError = hasError || !isEmpty(errorMessages?.messages);
  });

  return {
    hasError,
  };
};
