import { defineMessages } from "react-intl";

export const messages = defineMessages({
  asanaProject: {
    defaultMessage: "Asana Project",
    id: "result:form:asanaProject:label",
  },
  noResults: {
    defaultMessage: "No results",
    id: "asanaProjectSelect:noResults",
  },
  search: {
    defaultMessage: "Search...",
    id: "asanaProjectSelect:search",
  },
  searchProjects: {
    defaultMessage: "Search Asana projects",
    id: "asanaProjectSelect:searchProjects",
  },
});
