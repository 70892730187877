import { partial } from "lodash";
import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TextButton } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Tooltip } from "common/overlay";
import { KpiNavigation } from "hooks/useKpiStartDate/useKpiStartDate";
import { twClass } from "utils/twClass";

const messages = defineMessages({
  nextQuarter: {
    defaultMessage: "Next quarter",
    id: "9kmzSo",
  },
  nextYear: {
    defaultMessage: "Next year",
    id: "NbzvTY",
  },
  prevQuarter: {
    defaultMessage: "Previous quarter",
    id: "hQS3l9",
  },
  prevYear: {
    defaultMessage: "Previous year",
    id: "smN0U9",
  },
});

type Size = "xl" | "2xl";

type Props = {
  disabled?: boolean;
  kpiNavigation: KpiNavigation;
  size: Size;
};

/**
 * navigation for kpi boards, moves through time.
 */
export const YearlyNavigation = ({
  size,
  disabled = false,
  kpiNavigation,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const iconClass = twClass({
    "p-1": size === "2xl",
    "py-0.5 px-1.5": size === "xl",
  });
  return (
    <div className="flex items-center gap-3">
      <div className="flex divide-x divide-slate-200 rounded border bg-white border-slate-200">
        <IconButton
          className={iconClass}
          data-cy="goPrevious"
          size={size}
          disabled={disabled}
          name="first_page"
          onClick={partial(kpiNavigation.navigateQuarters, -4)}
          tooltip={formatMessage(messages.prevYear)}
        />
        <IconButton
          className={iconClass}
          data-cy="goPreviousQuarter"
          disabled={disabled}
          size={size}
          name="keyboard_arrow_left"
          onClick={partial(kpiNavigation.navigateQuarters, -1)}
          tooltip={formatMessage(messages.prevQuarter)}
        />
        <IconButton
          className={iconClass}
          size={size}
          data-cy="goNextQuarter"
          disabled={disabled}
          name="keyboard_arrow_right"
          onClick={partial(kpiNavigation.navigateQuarters, +1)}
          tooltip={formatMessage(messages.nextQuarter)}
        />
        <IconButton
          className={iconClass}
          data-cy="goNext"
          disabled={disabled}
          name="last_page"
          size={size}
          onClick={partial(kpiNavigation.navigateQuarters, +4)}
          tooltip={formatMessage(messages.nextYear)}
        />
      </div>
      <Tooltip
        content={
          <FormattedMessage
            defaultMessage="Return to the current year"
            id="growthBoard:navigation:goToday"
          />
        }
      >
        <TextButton
          data-cy="goToday"
          disabled={disabled || kpiNavigation.isDefaultDate}
          onClick={kpiNavigation.reset}
          size="small"
          className="p-0"
        >
          <FormattedMessage defaultMessage="Today" id="timeline:today" />
        </TextButton>
      </Tooltip>
    </div>
  );
};
