import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { useObjectiveFormContext } from "common/objective/utils/useObjectiveFormContext/useObjectiveFormContext";
import { Dropdown } from "common/overlay/Dropdown";
import { useCompany } from "hooks/useCompany/useCompany";
import { twClass } from "utils/twClass";
import { ObjectiveFormValues } from "../ObjectiveForm.types";

export const ObjectivePrivacy = (): JSX.Element => {
  const company = useCompany();
  const {
    values: { private: privateObjective },
    setFieldValue,
  } = useFormikContext<ObjectiveFormValues>();
  const { setAlignedToRadio } = useObjectiveFormContext();
  return (
    <Dropdown>
      <Dropdown.Trigger>
        <div
          className="ml-6 flex cursor-pointer items-center"
          data-cy="privateOKRToggle"
        >
          <Icon name={privateObjective ? "lock_outline" : "public"} size="xl" />
          <div className="ml-2">
            <Show
              fallback={
                <FormattedMessage
                  defaultMessage="Public"
                  id="objective:public"
                />
              }
              when={privateObjective}
            >
              <FormattedMessage
                defaultMessage="Private"
                id="objective:Private"
              />
            </Show>
          </div>
          <Icon name="keyboard_arrow_down" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content className="space-y-1" placement="bottom-left">
        <Dropdown.Item
          data-cy="oephUN9lIS5Ys1ffNlNYg"
          className={twClass({
            "fill-current text-blue-500 hover:text-blue-500": !privateObjective,
          })}
          onClick={() => setFieldValue("private", false)}
        >
          <div className="flex items-center px-3 py-2">
            <Icon name="public" size="2xl" />
            <div className="ml-4 text-base">
              <FormattedMessage defaultMessage="Public" id="objective:public" />
              <div className="whitespace-nowrap text-sm	">
                <FormattedMessage
                  defaultMessage="Anyone in {company} can see this OKR"
                  id="public:objective:description"
                  values={{ company: company.name }}
                />
              </div>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          className={twClass({
            "fill-current text-blue-500 hover:text-blue-500": privateObjective,
          })}
          data-cy="privateOkr"
          onClick={() => {
            setFieldValue("private", true);
            setAlignedToRadio("none");
          }}
        >
          <div className="flex items-center px-3 py-2">
            <Icon name="lock_outline" size="2xl" />
            <div className="ml-4 text-base">
              <FormattedMessage
                defaultMessage="Private"
                id="objective:Private"
              />
              <div className="whitespace-nowrap text-sm	">
                <FormattedMessage
                  defaultMessage="Visible only to Objective & Results Leads, and Contributors"
                  id="private:objective:description"
                />
              </div>
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown>
  );
};
