import { PerdooApiKpiTargetOperatorChoices } from "@graphql";
import { exhaustiveCheck } from "utils/exhaustiveCheck";

export const formatGoalOperator = (
  operator: PerdooApiKpiTargetOperatorChoices
): string => {
  switch (operator) {
    case PerdooApiKpiTargetOperatorChoices.GreaterThanOrEqual:
      return "≥";
    case PerdooApiKpiTargetOperatorChoices.LessThanOrEqual:
      return "≤";
    default:
      return exhaustiveCheck(operator);
  }
};
