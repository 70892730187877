import { Disclosure } from "@headlessui/react";
import { isNil } from "lodash";
import React, { ReactNode } from "react";
import { defineMessage, MessageDescriptor, useIntl } from "react-intl";
import { PulseValues } from "common/inputs/Pulse/Pulse";
import { Tuple } from "utils/Tuple";

export const pulsePrompts: Tuple<MessageDescriptor | null, 6> = [
  null, // for index 0, to make live easier
  defineMessage({
    defaultMessage: "Sorry you feel that way! How can we help you feel better?",
    id: "pulse:prompt:1",
  }),
  defineMessage({
    defaultMessage: "What will it take to make you feel better?",
    id: "pulse:prompt:2",
  }),
  defineMessage({
    defaultMessage: "Anything to add?",
    id: "pulse:prompt:3",
  }),
  defineMessage({
    defaultMessage: "Fantastic! What are you missing to feel at a 5?",
    id: "pulse:prompt:4",
  }),
  defineMessage({
    defaultMessage: "Awesome! What made you feel so good?",
    id: "pulse:prompt:5",
  }),
];

type Props = {
  children: ReactNode;
  defaultOpen?: boolean;
  pulse: PulseValues["pulse"];
};

export const PulseTextAnswer = ({
  children,
  pulse,
  defaultOpen,
}: Props): JSX.Element | null => {
  const intl = useIntl();

  const pulsePrompt = pulsePrompts[pulse];
  if (isNil(pulsePrompt)) return null;

  return (
    <Disclosure defaultOpen={defaultOpen}>
      <Disclosure.Button>
        <p className="text-blue-500 hover:text-indigo-800">
          {intl.formatMessage(pulsePrompt)}
        </p>
      </Disclosure.Button>
      <Disclosure.Panel>{children}</Disclosure.Panel>
    </Disclosure>
  );
};
