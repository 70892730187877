import { gql } from "@apollo/client";
import {
  ResultDefaultFragmentDoc,
  ResultsFieldsWithChildrenFragmentDoc,
} from "@graphql";

export const RESULT_MUTATION = gql`
  mutation UpsertResult($input: UpsertResultMutationInput!) {
    upsertResult(input: $input) {
      keyResult {
        ...ResultDefault
        objective {
          progress
          status
        }
      }
      errors {
        field
        messages
      }
    }
  }
  ${ResultDefaultFragmentDoc}
`;

export const RESULTS_QUERY = gql`
  query getObjectiveResults(
    $objectiveId: UUID!
    $resultsType: String
    $parentIsnull: Boolean
    $userProfile: UUID
    $isOutdated: Boolean
    $archived: Boolean
    $excludeAccomplished: Boolean
  ) {
    results(
      objective: $objectiveId
      parent_Isnull: $parentIsnull
      type: $resultsType
      userProfile: $userProfile
      isOutdated: $isOutdated
      archived: $archived
      excludeAccomplished: $excludeAccomplished
    ) {
      edges {
        node {
          ...resultsFieldsWithChildren
        }
      }
    }
  }
  ${ResultsFieldsWithChildrenFragmentDoc}
`;

export const RESULTS_WITH_CHILDREN_FRAGMENT = gql`
  fragment resultsFieldsWithChildren on keyResult {
    id
    ...ResultDefault
    children(type: $resultsType) {
      edges {
        node {
          id
          ...ResultDefault
        }
      }
    }
  }
  ${ResultDefaultFragmentDoc}
`;

export const RESULT_QUERY = gql`
  query getResult($resultId: UUID!, $resultsType: String) {
    result(id: $resultId) {
      ...ResultDefault
      children(type: $resultsType) {
        edges {
          node {
            id
            ...ResultDefault
          }
        }
      }
    }
  }
  ${ResultDefaultFragmentDoc}
`;

export const DELETE_RESULT_MUTATION = gql`
  mutation DeleteResultMutation($input: DeleteResultMutationInput!) {
    deleteResult(input: $input) {
      keyResult {
        id
      }
    }
  }
`;

export const RESULTS_SEARCH_QUERY = gql`
  query searchResults(
    $search: String
    $resultsObjectiveIn: String
    $resultsType: String
  ) {
    results(
      name_Icontains: $search
      objective_In: $resultsObjectiveIn
      type: $resultsType
    ) {
      edges {
        node {
          id
          name
          type
        }
      }
    }
  }
`;

export const FORM_RESULT_QUERY = gql`
  query getFormResult($id: UUID!) {
    result(id: $id) {
      id
      name
    }
  }
`;
