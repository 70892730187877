import React from "react";
import { Show } from "common/controlFlow";
import { AccessFeature } from "./types";
import { useFeatureAccess } from "./useFeatureAccess";

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  feature: AccessFeature;
};
/**
 * generic component to conditionally show feature if available. each feature defines its own permission logic.
 */
export const ShowFeature: React.FC<Props> = ({
  children,
  fallback,
  feature,
}) => {
  const { show: showFeature } = useFeatureAccess(feature);

  return (
    <Show when={showFeature} fallback={fallback ?? null}>
      {children}
    </Show>
  );
};
