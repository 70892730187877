import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Clickable } from "common/navigation/Clickable";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { useSalesModals } from "hooks/useSalesModals/useSalesModals";

type Size = "small" | "medium" | "large" | "extraLarge";

const sizes: TMap<Size, number> = {
  extraLarge: 60,
  large: 28,
  medium: 24,
  small: 18,
};

interface Props {
  feature?: string;
  showHover?: boolean;
  size: Size;
}

export const GoPremiumTooltip = ({
  feature,
}: {
  feature: string | undefined;
}) => {
  const { push, query } = useRouter();

  const showUpgrade = () => push({ query: { ...query, upgradeNow: true } });

  return (
    <div className="space-y-2 p-2">
      <div className="font-semibold">
        <FormattedMessage
          defaultMessage="{feature} is a premium feature"
          id="Ljn7Uc"
          values={{ feature }}
        />
      </div>
      <div className="text-sm">
        <FormattedMessage
          defaultMessage="{goPremium} to unlock its benefits, and many more."
          id="kfNK3b"
          values={{
            goPremium: (
              <Clickable
                className="text-blue-500 hover:text-indigo-800"
                data-cy="linkUpgradeNow"
                onClick={showUpgrade}
              >
                <FormattedMessage defaultMessage="Go Premium" id="i/qpb5" />
              </Clickable>
            ),
          }}
        />
      </div>
    </div>
  );
};

export const PremiumBadge = ({
  size,
  showHover,
  feature,
}: Props): JSX.Element => {
  const { upgradeNowHref } = useSalesModals();
  const button = (
    <svg
      data-cy="premiumBadge"
      fill="none"
      height={sizes[size]}
      viewBox="0 0 29 40"
      width={sizes[size]}
    >
      <path
        clipRule="evenodd"
        d="M16 0.599977L5.81786 4.36316L0.400031 13.8919L5.81785 4.36316L16 0.599977ZM6.5225 28.3409L10.5822 31.8L15.6105 31.8L11.0617 39.6789C10.9806 39.8193 10.7748 39.8099 10.7069 39.6627L8.40586 34.6789L2.9392 35.1781C2.77771 35.1928 2.66673 35.0193 2.74781 34.8789L6.5225 28.3409ZM16.0634 31.8L21.4179 31.8L25.259 28.5272L28.9577 34.9336C29.0388 35.074 28.9278 35.2475 28.7663 35.2327L23.2996 34.7335L20.9986 39.7174C20.9306 39.8646 20.7249 39.874 20.6438 39.7336L16.0634 31.8ZM24.2121 6.74728L16 3.71222L7.78798 6.74728L3.41843 14.4323L4.93596 23.1714L11.6305 28.8754L20.3696 28.8754L27.0641 23.1714L28.5816 14.4323L24.2121 6.74728Z"
        fill="url(#paint0_linear)"
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear"
          x1="0.400018"
          x2="29.2867"
          y1="19.3355"
          y2="19.3355"
        >
          <stop stopColor="#FFC533" />
          <stop offset="1" stopColor="#FFD686" />
        </linearGradient>
      </defs>
    </svg>
  );

  if (showHover) {
    return (
      <Tooltip content={<GoPremiumTooltip feature={feature} />} interactive>
        {button}
      </Tooltip>
    );
  }

  return button;
};
