import { isNil } from "lodash";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Avatar } from "common/avatar";
import { For, Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { useCompleteOneOnOneContext } from "common/oneOnOnes/context/completeOneOnOneContext";
import { OneOnOneMeetingItem } from "common/oneOnOnes/OneOnOneCompleteForm/OneOnOneMeetingItemsField/OneOnOneMeetingItemsField";
import { Tooltip } from "common/overlay";
import { twClass } from "utils/twClass";

type Props = {
  meetingItems: OneOnOneMeetingItem[] | null;
  title: ReactNode;
};

export const OneOnOneCompleteMeetingItems = ({
  meetingItems,
  title,
}: Props): JSX.Element | null => {
  const { organizer, attendee } = useCompleteOneOnOneContext();

  if (isNil(meetingItems)) return null;

  const avatarUrl = (assignee: string | null) => {
    if (!isNil(assignee)) {
      return assignee === organizer.id ? organizer.avatar : attendee.avatar;
    }
    return null;
  };

  return (
    <div className="space-y-2">
      <span className="text-base font-medium text-slate-800">{title}</span>
      <For
        each={meetingItems}
        fallback={
          <div className="py-4 text-gray-500">
            <FormattedMessage defaultMessage="No items recorded." id="TnOral" />
          </div>
        }
      >
        {({ assignee, text, isComplete, isRecurring }: OneOnOneMeetingItem) => (
          <div className="flex min-h-11 w-full grow items-start space-x-1 py-2">
            <div>
              <Show
                fallback={
                  <Icon
                    className="rounded-full p-1.5 bg-slate-100"
                    name="person_off"
                    size="md"
                  />
                }
                when={!isNil(assignee)}
              >
                <Avatar
                  className="border-2 border-transparent"
                  url={avatarUrl(assignee)}
                />
              </Show>
            </div>
            <div>
              <Show when={isRecurring}>
                <Tooltip
                  className="text-xs"
                  content={
                    <FormattedMessage defaultMessage="Recurring" id="v84fNv" />
                  }
                  data-placement="top"
                >
                  <Icon
                    className={twClass("px-1", {
                      "text-slate-500": isComplete,
                    })}
                    data-cy="zmPMC7cuYk0tQkCzEdX-c"
                    data-testid="recurring-icon"
                    name="repeat"
                    size="xl"
                    type="outlined"
                  />
                </Tooltip>
              </Show>
            </div>
            <div
              className={twClass(
                "flex flex-wrap",
                "min-w-[16rem] max-w-xs focus:outline-0 lg:max-w-lg xl:max-w-xl",
                "ml-0 mt-1 border-none",
                {
                  "line-through text-slate-500": isComplete,
                }
              )}
            >
              {text}
            </div>
          </div>
        )}
      </For>
    </div>
  );
};
