import React from "react";
import { FormattedMessage } from "react-intl";

export const linkToTerms = (
  <a
    href="https://www.perdoo.com/terms/"
    rel="noopener noreferrer"
    target="_blank"
  >
    <FormattedMessage defaultMessage="Terms of Service" id="global:terms" />
  </a>
);

export const linkToPrivacy = (
  <a
    href="https://www.perdoo.com/privacy/"
    rel="noopener noreferrer"
    target="_blank"
  >
    <FormattedMessage defaultMessage="Privacy Policy" id="global:privacy" />
  </a>
);

export const linkToDPA = (
  <a
    href="https://www.perdoo.com/dpa/"
    rel="noopener noreferrer"
    target="_blank"
  >
    <FormattedMessage defaultMessage="Data Protection Agreement" id="vQg5V+" />
  </a>
);
