import { ProgressDriver } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { EntityIcon } from "common/icons";
import { objectTypes } from "constants/objects";
import { progressTypes } from "constants/progressTypes";

interface Props {
  progressDriver?: ProgressDriver;
}

export const ObjectiveProgressDriver = ({ progressDriver }: Props) => {
  if (!progressDriver) {
    return (
      <div className="rounded-md w-2/3 h-full bg-slate-300 animate-pulse" />
    );
  }

  return (
    <div className="flex space-x-2 items-center">
      <Show
        when={progressDriver === ProgressDriver.KeyResults}
        fallback={
          <>
            <div className="rounded-full border border-solid h-5 flex items-center justify-center w-8 bg-white">
              <EntityIcon entity={progressTypes.alignedObjective} size="base" />
            </div>
            <div>
              <FormattedMessage
                defaultMessage="Aligned Objectives"
                id="wkczZ1"
              />
            </div>
          </>
        }
      >
        <div className="rounded-full border border-solid h-5 flex items-center justify-center w-8 bg-white">
          <EntityIcon entity={objectTypes.keyresult} size="base" />
        </div>
        <div>
          <FormattedMessage defaultMessage="Key Results" id="RVWEg5" />
        </div>
      </Show>
    </div>
  );
};
