export const goalOperators = {
  GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
  LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
};

export const MODAL_TYPES = {
  ADD_CADENCE_MODAL: "ADD_CADENCE_MODAL",
  ADD_GROUP: "ADD_GROUP",
  ADD_KPI: "ADD_KPI",
  ADD_KPI_BOARD: "ADD_KPI_BOARD",
  ADD_OBJECTIVE: "ADD_OBJECTIVE",
  ADD_RESULT: "ADD_RESULT",
  ADD_TAG: "ADD_TAG",
  ADD_TIMEFRAME_MODAL: "ADD_TIMEFRAME_MODAL",
  ADD_USER: "ADD_USER",
  ARCHIVE_RESULT: "ARCHIVE_RESULT",
  CLONE_OBJECTIVE: "CLONE_OBJECTIVE",
  CLOSE_OBJECTIVE: "CLOSE_OBJECTIVE",
  EDIT_CADENCE_MODAL: "EDIT_CADENCE_MODAL",
  EDIT_COMPANY: "EDIT_COMPANY",
  EDIT_GROUP: "EDIT_GROUP",
  EDIT_KPI: "EDIT_KPI",
  EDIT_OBJECTIVE: "EDIT_OBJECTIVE",
  EDIT_RESULT: "EDIT_RESULT",
  EDIT_TAG: "EDIT_TAG",
  EDIT_USER: "EDIT_USER",
  EXPLORE_VIEW_MODAL: "EXPLORE_VIEW_MODAL",
  ITEMS_LIST: "ITEMS_LIST",
  KPI_SET_TARGET_ADD_OBJECTIVE: "KPI_SET_TARGET_ADD_OBJECTIVE",
  MOVE_RESULT: "MOVE_RESULT",
  RELOAD_APP: "RELOAD_APP",
  ULTIMATE_GOAL: "ULTIMATE_GOAL",
  USER_LIST: "USER_LIST",
} as const;

export const KPI_LIMIT = 6;
