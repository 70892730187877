import React, { FunctionComponent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons/Button/Button";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Icon } from "common/icons";
import { Anchor } from "common/navigation/Anchor/Anchor";

/**
 * displays an alert that prompts user to update their browser. uses browser-update package.
 * allows to dismiss the alert via cookie.
 *
 * @constructor
 */
export const BrowserUpdateAlert: FunctionComponent = () => {
  const cookieName = `browser-update=pause`;
  const [alertPaused, setAlertPaused] = useState(
    document.cookie.indexOf(cookieName) > -1
  );
  const [displayAlert, setDisplayAlert] = useState(!alertPaused);
  const [dialogTimeoutRef, setDialogTimeoutRef] = useState<NodeJS.Timeout>();

  /**
   * sets a cookie that the user has already seen the notification, closed it or permanently wants to hide it. no information on the user is stored.
   */
  const setCookie = (days = 0) => {
    const milliseconds = days * 24 * 60 * 60 * 1000;
    const cookieDuration = new Date(
      new Date().getTime() + milliseconds
    ).toUTCString();
    document.cookie = `${cookieName}; expires=${cookieDuration}; path=/; SameSite=Lax${/https:/.test(
      location.href
    )}?'; Secure':''`;
  };

  /**
   * dismisses the alert for the given number of days by setting a cookie.
   *
   * @param days: number
   */
  const dismissAlert = (days: number) => {
    setCookie(days);

    if (alertPaused) {
      setDisplayAlert(false);

      if (dialogTimeoutRef) {
        clearTimeout(dialogTimeoutRef);
      }
      return;
    }

    setAlertPaused(true);
    const timeoutRef = setTimeout(() => {
      setDisplayAlert(false);
    }, 5000);

    setDialogTimeoutRef(timeoutRef);
  };

  const dismissArea = alertPaused ? (
    <span className="flex flex-1 justify-end">
      <FormattedMessage
        defaultMessage="You will be reminded in 7 days &nbsp; {dismiss}"
        id="home:global:browser-update:dismiss"
        values={{
          dismiss: (
            <Anchor
              data-cy="M93R8CbjIc4DYBCWAtiZk"
              className="text-sm"
              // TODO: intl
              onClick={dismissAlert.bind(null, 3650)}
              text="Never show again"
            />
          ),
        }}
      />
    </span>
  ) : (
    <IconButton
      data-cy="closeAlertButton"
      name="close"
      onClick={dismissAlert.bind(null, 7)}
    />
  );

  return displayAlert ? (
    <div className="flex min-h-[60px] w-full flex-nowrap items-center justify-between p-9 py-3 text-sm bg-slate-300">
      {!alertPaused && (
        <div className="flex items-center gap-3">
          <Icon name="warning" size="3xl" />
          <FormattedMessage
            defaultMessage="Update your browser to ensure a secure and smooth experience using Perdoo."
            id="home:global:browser-update:outdated"
          />
          <Button
            href="https://browser-update.org/update-browser.html"
            hrefTarget="_blank"
            size="small"
          >
            Update now
          </Button>
        </div>
      )}
      {dismissArea}
    </div>
  ) : null;
};
