import React, { ReactNode } from "react";
import { Show } from "common/controlFlow";
import { twMerge } from "utils/twMerge";
import { HealthScoreIndicator } from "../HealthScoreIndicator/HealthScoreIndicator";

interface Props {
  children?: ReactNode;
  className?: string;
  healthScoreIndicator?: boolean;
  title?: string;
  tooltip?: ReactNode;
}

/**
 * a generic container containing a title, body and tooltip. used in performance reports.
 */
export const ReportCard = ({
  children,
  className,
  healthScoreIndicator,
  title,
}: Props): JSX.Element => {
  return (
    <article
      aria-label={title}
      className={twMerge(
        "relative flex h-84 flex-col",
        "bg-white text-slate-500",
        "rounded border border-slate-300",
        "m-2.5 gap-2 px-2.5 py-3",
        "overflow-auto",
        className
      )}
    >
      {children}
      <Show when={healthScoreIndicator}>
        <HealthScoreIndicator className="absolute right-0 top-0" />
      </Show>
    </article>
  );
};
