import { PerdooApiExploreViewTabNameChoices } from "@graphql";
import classNames from "clsx";
import { isEmpty } from "lodash";
import React from "react";
import { useFiltersContext } from "legacy/components/filters/filtersContext";
import { FilterSelected } from "legacy/components/filters/FilterSelected/FilterSelected";
import { handleFilterRemove } from "legacy/components/filters/FilterSet.utils";
import { Filter, FilterItem } from "legacy/components/filters/types";

type FilterListProps = {
  activeFilter: Filter | null;
  afterFilterRemoved?: () => void;
  noWrap?: boolean;
  scrollable?: boolean;
  tabName?: PerdooApiExploreViewTabNameChoices;
};

/**
 * @deprecated use entity-specific filter components instead and hook up action to {@link useFilters} (wip).
 */
export const FilterList = ({
  activeFilter,
  scrollable,
  noWrap,
  afterFilterRemoved,
  tabName,
}: FilterListProps): JSX.Element => {
  const { filtersDispatch, filterState } = useFiltersContext();
  const exploreView = filterState.filters;

  return (
    <div
      className={classNames("flex items-center", {
        "flex-nowrap": noWrap,
        "flex-wrap": !noWrap,
        "h-full overflow-y-auto": scrollable,
      })}
    >
      {Object.entries(exploreView).map(([columnName, columnValue]) => (
        <div
          key={columnName}
          className="mr-2.5 flex flex-wrap items-center"
          data-cy={`filter-${columnName}`}
        >
          <div className="text-sm text-slate-500">
            {columnValue.name === "Stage" &&
            tabName !== PerdooApiExploreViewTabNameChoices.Objectives
              ? "Objective Stage"
              : columnValue.name}
            :
          </div>

          {!isEmpty(columnValue.selected) && (
            <FilterSelected
              columnValue={columnValue}
              onRemove={(filter: FilterItem) =>
                handleFilterRemove({
                  activeFilter,
                  afterFilterRemoved,
                  filter,
                  filterName: columnName,
                  filtersDispatch,
                })
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};
