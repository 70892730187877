import { defineMessages } from "react-intl";

export const namePlaceholders = defineMessages({
  text1: {
    defaultMessage: "Revenue",
    id: "kpi:forms:placeholder:name:text1",
  },
  text10: {
    defaultMessage: "Monthly Recurring Revenue (MRR)",
    id: "kpi:forms:placeholder:name:text10",
  },
  text11: {
    defaultMessage: "Churn",
    id: "kpi:forms:placeholder:name:text11",
  },
  text12: {
    defaultMessage: "Close 500K new business",
    id: "kpi:forms:placeholder:name:text12",
  },
  text13: {
    defaultMessage: "CSAT",
    id: "kpi:forms:placeholder:name:text13",
  },
  text2: {
    defaultMessage: "Conversion rate",
    id: "kpi:forms:placeholder:name:text2",
  },
  text3: {
    defaultMessage: "Page response time",
    id: "kpi:forms:placeholder:name:text3",
  },
  text4: {
    defaultMessage: "Customer response time",
    id: "kpi:forms:placeholder:name:text4",
  },
  text5: {
    defaultMessage: "EBIT",
    id: "kpi:forms:placeholder:name:text5",
  },
  text6: {
    defaultMessage: "Gross profit margin ≥ 20%",
    id: "kpi:forms:placeholder:name:text6",
  },
  text7: {
    defaultMessage: "Employees NPS",
    id: "kpi:forms:placeholder:name:text7",
  },
  text8: {
    defaultMessage: "NPS",
    id: "kpi:forms:placeholder:name:text8",
  },
  text9: {
    defaultMessage: "Customer Acquisition Cost (CAC)",
    id: "kpi:forms:placeholder:name:text9",
  },
});
