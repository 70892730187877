import {
  useKpisByUserQuery,
  UserAvatarFragment,
  UserFullNameFragment,
} from "@graphql";
import { isNil } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { For, Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { Kpi } from "common/kpi/Kpi/Kpi";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { mapEdges } from "utils/mapEdges";
import { apolloQueryMerger } from "utils/utils";
import { PerformanceReviewSidebarEmptyMessage } from "../PerformanceReviewSidebarEmptyMessage";

const FIRST = 5;

type Props = {
  user?: (UserFullNameFragment & UserAvatarFragment) | null;
};

export const PerformanceReviewSidebarKpis = ({ user }: Props): JSX.Element => {
  const { goToKpi } = useModalRouter();
  const [privateMode] = usePrivateMode();
  const { data, fetchMore } = useKpisByUserQuery({
    skip: !user?.id,
    variables: { entityId: user?.id ?? "", first: FIRST },
  });
  if (isNil(data)) {
    return (
      <div className="mt-4 flex justify-center">
        <Spinner />
      </div>
    );
  }

  let kpis = mapEdges(data.allKpis.edges);
  if (privateMode) {
    kpis = kpis.filter((kpi) => !kpi.private);
  }

  const { hasNextPage, endCursor } = data.allKpis.pageInfo;

  const handleFetchMore = () => {
    fetchMore({
      updateQuery: apolloQueryMerger,
      variables: {
        after: endCursor,
      },
    });
  };
  const emptyMessage = (
    <PerformanceReviewSidebarEmptyMessage>
      <FormattedMessage
        defaultMessage="{name} has no KPIs yet."
        id="kpi:list:empty"
        values={{ name: user?.fullName ?? "User" }}
      />
    </PerformanceReviewSidebarEmptyMessage>
  );

  const handleClick = async (id: string) => {
    goToKpi({ kpiId: id }, { stack: true });
  };
  return (
    <div className="px-5 pt-4">
      <div className="flex items-center space-x-1 border-b pb-1 text-sm font-normal text-slate-800">
        <Icon name="monitor_heart" type="outlined" />
        <span>
          <FormattedMessage defaultMessage="KPIs" id="home:global:kpis" />
        </span>
      </div>
      <div className="mt-3 grid grid-cols-1 gap-2">
        <For each={kpis} fallback={emptyMessage}>
          {(kpi) => (
            <Kpi
              key={kpi.id}
              disableUpdate
              kpi={kpi}
              onClick={() => handleClick(kpi.id)}
              showGroups
            />
          )}
        </For>
        <Show when={hasNextPage}>
          <div className="flex justify-center p-2.5">
            <Button data-cy="aKMqzIrEFkV1OdMgXwEai" onClick={handleFetchMore}>
              <FormattedMessage
                defaultMessage="Load more..."
                id="global:loadMore"
              />
            </Button>
          </div>
        </Show>
      </div>
    </div>
  );
};
