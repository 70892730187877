import { Menu } from "@headlessui/react";
import { isFunction } from "lodash";
import React, { ReactElement, ReactNode } from "react";
import { twClass } from "utils/twClass";
import { DropdownContent } from "./DropdownContent/DropdownContent";
import { DropdownItem } from "./DropdownItem/DropdownItem";
import { DropdownTrigger } from "./DropdownTrigger/DropdownTrigger";

type DropdownChildrenRenderProps = {
  close: () => void;
  open: boolean;
};

type DropdownProps = {
  children: ReactNode | ((props: DropdownChildrenRenderProps) => ReactElement);
  className?: string;
  "data-testid"?: string;
};

export const Dropdown = ({
  className,
  children,
  "data-testid": dataTestId,
}: DropdownProps): JSX.Element => (
  <Menu
    as="div"
    className={twClass("relative", className)}
    data-testid={dataTestId}
    // this allows placing Dropdown inside another clickable element
    onClick={(e: React.MouseEvent) => e.stopPropagation()}
  >
    {({ close, open }) => {
      return isFunction(children) ? children({ close, open }) : <>{children}</>;
    }}
  </Menu>
);

Dropdown.Trigger = DropdownTrigger;
Dropdown.Content = DropdownContent;
Dropdown.Item = DropdownItem;
