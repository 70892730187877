import React, { ReactNode, useRef } from "react";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { twMerge } from "utils/twMerge";
import {
  PanelBag,
  PanelContextProvider,
} from "./hooks/usePanelContext/usePanelContext";

type Props = {
  children?: ReactNode;
  className?: string;
  id?: string;
  onClose?: () => void;
};

/**
 * Panel used for overlay dialog boxes (e.g. confirmation modals).
 * Usually used in conjunction with {@link WithTooltip}.
 * @example
 *  <Panel onClose={onClose}>
 *    <PanelHeader>Title</PanelHeader>
 *    <PanelBody>Body content</PanelBody>
 *    <PanelFooter>Some buttons</PanelFooter>
 *  </Panel>
 */
export const Panel = ({
  className,
  onClose = () => {},
  children,
  id,
}: Props): JSX.Element => {
  const panelRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(panelRef, onClose, [
    // Mentions list
    "[data-mentions-list]",
    "#dropdown-root",
    "#color-picker",
    "em-emoji-picker",
    "[data-testid=emojiList]",
    "#modal-confirm",
    "#overlay-root",
    "#select-dropdown-portal",
    // Not sure why, but clicking on the status in Select registers as an outside click. so whitelisting explicitly here.
    "#statusElement",
    "#text-editor-tools-root",
    "#gif-picker",
    "#image-picker",
  ]);

  const panelBag: PanelBag = { onClose };

  return (
    <PanelContextProvider value={panelBag}>
      {/* Reason: the onClick is just for stopping event propagation */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <article
        ref={panelRef}
        aria-label="panel"
        className={twMerge(
          "flex flex-col",
          "divide-y divide-slate-300",
          "rounded bg-white",
          className
        )}
        data-cy="panel"
        data-testid="panel"
        // Prevent triggering fold / unfold events on roadmap
        onClick={(e) => e.stopPropagation()}
        id={id}
      >
        {children}
      </article>
    </PanelContextProvider>
  );
};
