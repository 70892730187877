import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import React, { FocusEventHandler, ReactNode } from "react";

type Props = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  name: string;
  onBlur?: FocusEventHandler;
  onChange?: (newValue: string) => void;
  value: string;
};

export const RadioGroup = ({
  value,
  disabled = false,
  name,
  className,
  children,
  onChange = () => {},
  onBlur,
}: Props): JSX.Element => {
  return (
    <HeadlessRadioGroup
      className={className}
      disabled={disabled}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    >
      {children}
    </HeadlessRadioGroup>
  );
};
