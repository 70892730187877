import React, { useState } from "react";
import { Range } from "common/inputs/Range/Range";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'defaultValue' implicitly has an '... Remove this comment to see the full error message
export const FilterRange = ({ defaultValue, ...props }): JSX.Element => {
  const [state, setState] = useState(defaultValue || []);

  return (
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'range' implicitly has an 'any' type.
    <Range {...props} onChange={(range) => setState(range)} value={state} />
  );
};
