export enum IconSize {
  "2xl" = "text-2xl",
  "3xl" = "text-3xl",
  "4xl" = "text-4xl",
  "5xl" = "text-5xl",
  "6xl" = "text-6xl",
  "7xl" = "text-7xl",
  "8xl" = "text-8xl",
  "9xl" = "text-9xl",
  "base" = "text-base",
  "lg" = "text-lg",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  "md" = "text-base",
  "sm" = "text-sm",
  "xl" = "text-xl",
  "xs" = "text-xs",
  "xxs" = "text-xxs",
}

export enum ItemSize {
  "extraSmall",
  "small",
  "medium",
  "large",
  "extraLarge",
}

// TODO: a way to automatically get this from tailwind somehow?
export enum FontSize {
  "xxs" = 9.375,
  "xs" = 11.25,
  "sm" = 13.125,
  "base" = 15,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  "md" = 15,
  "lg" = 16.875,
  "xl" = 18.75,
  "2xl" = 22.5,
  "3xl" = 33.75,
  "4xl" = 37.5,
  "5xl" = 45,
  "6xl" = 56.25,
  "7xl" = 67.5,
  "8xl" = 90,
  "9xl" = 120,
}
