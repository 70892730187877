import {
  AsanaWorkspace,
  MakeOptional,
  useGetAsanaWorkspacesQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useField } from "formik";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Props as ReactSelectProps, ValueType } from "react-select";
import { AllowedFieldProps } from "common/form/types";
import { useAsanaFormContext } from "common/integration/AsanaFormLogic/AsanaFormLogic";
import { messages } from "common/result/ResultForm/ResultForm.messages";
import { Select } from "legacy/components/Select/Select";
import { OptionType } from "legacy/components/Select/Select.types";
import { isSet } from "utils/isSet";

export type AsanaWorkspaceFieldWorkspace = Derive<
  AsanaWorkspace,
  {
    gid: true;
    name: true;
  }
>;
type Workspace = AsanaWorkspaceFieldWorkspace;

const workspaceToOption = (workspace: Workspace): OptionType => ({
  label: workspace.name,
  value: workspace.gid,
});

type Props = ReactSelectProps & MakeOptional<AllowedFieldProps, "name">;

export const AsanaWorkspaceField = ({
  name = "asanaWorkspace",
  "data-cy": dataCy = "asanaWorkspace",
  ...reactSelectProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);

  const { onWorkspaceSelected } = useAsanaFormContext();

  const [currentValue, setCurrentValue] = useState<OptionType>();

  const { data, loading } = useGetAsanaWorkspacesQuery({
    fetchPolicy: "cache-and-network",
  });

  const workspaces = data?.asanaWorkspaces;
  const options = workspaces?.map(workspaceToOption);

  const forwardOnChange = (option: ValueType<OptionType, false>) => {
    helpers.setValue(option?.value);
  };

  useEffect(() => {
    if (!workspaces) return;

    if (isNil(field.value)) {
      onWorkspaceSelected?.(null);
      setCurrentValue(undefined);
      return;
    }

    const foundWorkspace = workspaces.find((w) => w.gid === field.value);
    if (isSet(foundWorkspace)) {
      const option = workspaceToOption(foundWorkspace);
      setCurrentValue(option);
      onWorkspaceSelected?.(foundWorkspace);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        "Couldn't set Asana workspace because it's not part of the available options"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, workspaces]);

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...reactSelectProps}
      errorText={meta.error}
      isInForm
      label={intl.formatMessage(messages.asanaWorkspaceLabel)}
      loading={loading}
      noOptionsMessage={() => "No workspaces"}
      onChange={forwardOnChange}
      options={options}
      value={currentValue}
    />
  );
};
