type Options = Partial<{
  caseSensitive: boolean;
}>;

const defaultOptions: Options = {
  caseSensitive: false,
};

/**
 * normal sorting breaks with special characters like emojis:
 * ```
 * ["✅ Zahra but emoji first", "Andy"] → ["✅ Zahra but emoji first", "Andy"]
 * ```
 *
 * this function performs sort considering only regular characters (matches `\w`):
 *
 * ```
 * ["✅ Zahra but emoji first", "Andy"] → ["Andy", "✅ Zahra but emoji first"]
 * ```
 */
export const sortExcludeSpecialCharacters = (
  a: string,
  b: string,
  options?: Options
) => {
  const resolvedOptions = { ...defaultOptions, ...options };
  const excludeSpecial = (s: string) => {
    const stringWithoutSpecialChars = [...s]
      .filter((c) => /\w/.test(c))
      .join("");

    if (!resolvedOptions.caseSensitive) {
      return stringWithoutSpecialChars.toLowerCase();
    }

    return stringWithoutSpecialChars;
  };

  return excludeSpecial(a).localeCompare(excludeSpecial(b));
};
