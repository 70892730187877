import { PerdooApiObjectiveStageChoices } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { ListItem } from "common/misc/ListItem/ListItem";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";

type DropdownProps = {
  setStage: (value: PerdooApiObjectiveStageChoices) => void;
  stage: PerdooApiObjectiveStageChoices;
};

export const ObjectivesFilterDropdown = ({
  stage,
  setStage,
}: DropdownProps): JSX.Element => {
  const text =
    stage === PerdooApiObjectiveStageChoices.Active ? (
      <FormattedMessage defaultMessage="Active" id="objective:stage:active" />
    ) : (
      <FormattedMessage defaultMessage="Closed" id="objective:stage:closed" />
    );

  const defaultButton = (
    <TextButton
      className="px-0 text-sm text-slate-800"
      data-cy="tfDropdownButton"
      isDropdown
      size="small"
      text={text}
    />
  );
  return (
    <Dropdown
      button={defaultButton}
      id="performance-review-objectives-dropdown"
      limitHeight
      menuClass="space-y-1"
    >
      <ListItem
        data-cy="PJQnUXYsigVA0d23BgXwj"
        key={PerdooApiObjectiveStageChoices.Active}
        className="pt-1"
        onClick={() => setStage(PerdooApiObjectiveStageChoices.Active)}
        selected={stage === PerdooApiObjectiveStageChoices.Active}
      >
        <FormattedMessage defaultMessage="Active" id="objective:stage:active" />
      </ListItem>
      <ListItem
        data-cy="KEWwvkC5avhxrszUfPxcg"
        key={PerdooApiObjectiveStageChoices.Closed}
        className="pt-1"
        onClick={() => setStage(PerdooApiObjectiveStageChoices.Closed)}
        selected={stage === PerdooApiObjectiveStageChoices.Closed}
      >
        <FormattedMessage defaultMessage="Closed" id="objective:stage:closed" />
      </ListItem>
    </Dropdown>
  );
};
