import React, { ReactNode, useContext } from "react";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { twClass } from "utils/twClass";

type Props = {
  children: ReactNode;
  label: ReactNode;
};

export const SidebarSection = ({ label, children }: Props): JSX.Element => {
  const { isCollapsed } = useContext(CollapsibleContext);
  return (
    <div>
      <div
        className={twClass(
          "flex h-10 items-center space-x-2 text-sm text-slate-400",
          {
            "justify-center": isCollapsed,
            "px-3.5": !isCollapsed,
          }
        )}
      >
        {label}
      </div>
      {children}
    </div>
  );
};
