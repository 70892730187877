type Item = {
  duration?: number | null | undefined;
};

/**
 * can be used to sort timeframes / cadences to get the quarterly before annual etc.
 * @example
 * timeframes.sort(orderByDuration)
 */
export const orderByDuration = (a: Item, b: Item): number => {
  const durationA = a.duration ?? 0;
  const durationB = b.duration ?? 0;

  return durationA - durationB;
};
