import classNames from "clsx";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Avatar } from "common/avatar";
import { ImageUploadInputFilePicker } from "common/inputs/ImageUploadInput/ImageUploadInputFilePicker";
import { ImageUploadModal } from "common/overlay/ImageUploadModal/ImageUploadModal";
import { toast } from "utils/toastr";
import { twClass } from "utils/twClass";
import { Button } from "../../buttons";

const messages = defineMessages({
  edit: {
    defaultMessage: "Edit",
    id: "upload:image:edit",
  },
  error: {
    defaultMessage: "Please upload a JPEG, JPG or PNG file",
    id: "upload:image:error",
  },
  newPic: {
    defaultMessage: "Upload new picture",
    id: "settings:user:profile:newPic",
  },
});

const fileTypes = ["image/jpeg", "image/jpg", "image/png"];

type Props = {
  avatar?: string | null;
  buttonLabel?: string;
  entityId?: string;
  entityType: string;
  inline?: boolean;
};

export const ImageUploadInput = ({
  avatar,
  inline,
  entityId,
  entityType,
  buttonLabel,
}: Props): JSX.Element => {
  const intl = useIntl();

  const [imageLoading, setImageLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [canvasSrc, setCanvasSrc] = useState<string>();

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;

      if (fileTypes.indexOf(fileType) < 0) {
        toast.failure(intl.formatMessage(messages.error));
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        toast.failure(
          intl.formatMessage({
            defaultMessage: "Please upload image less than 10 MB",
            id: "9Ao392",
          })
        );
        return;
      }

      setImageLoading(true);

      reader.onloadend = () => {
        setCanvasSrc(reader.result?.toString());
        setImageLoading(false);
        setModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const openCropper = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'imageBlobForTesting' does not exist on t... Remove this comment to see the full error message
    setCanvasSrc(window.imageBlobForTesting);
    setImageLoading(false);
    setModalOpen(true);
  };

  return (
    <div data-cy="settingsAvatarWidget">
      <div
        className={twClass("flex flex-col justify-center", {
          "flex-row justify-start": inline,
        })}
      >
        <Avatar
          alt="Avatar"
          className={classNames({
            "mr-2 inline-block ": inline,
            "mx-auto mb-3": !inline,
          })}
          customSize={inline ? 40 : 100}
          data-cy="avatar"
          url={avatar}
        />

        <ImageUploadInputFilePicker
          buttonLabel={buttonLabel || intl.formatMessage(messages.newPic)}
          editMessage={intl.formatMessage(messages.edit)}
          imageLoading={imageLoading}
          inline={inline}
          onChange={handleImageChange}
        />
      </div>
      <Button
        className=" hidden"
        data-cy="userAvatarUpload"
        onClick={openCropper}
      />
      {modalOpen && canvasSrc && (
        <ImageUploadModal
          canvasSrc={canvasSrc}
          entityId={entityId}
          entityType={entityType}
          onRequestClosed={() => setModalOpen(false)}
          open={modalOpen}
        />
      )}
    </div>
  );
};
