import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clearDraft: {
    defaultMessage: "Clear draft",
    id: "global:clearDraft",
  },
  commentLabel: {
    defaultMessage: "Comment",
    id: "global:update:progress:comment:label",
  },
  commentPlaceholder: {
    defaultMessage: "Add comment",
    id: "global:update:progress:comment:placeholder",
  },
  confirmButtonLabel: {
    defaultMessage: "Discard",
    id: "global:discard",
  },
  confirmDescription: {
    defaultMessage: "The new value, status or comment will be lost.",
    id: "progressUpdate:discard:description",
  },
  confirmTitle: {
    defaultMessage: "Discard changes?",
    id: "progressUpdate:discard:title:",
  },
  dateLabel: {
    defaultMessage: "Date",
    id: "global:update:date:label",
  },
  done: {
    defaultMessage: "Done",
    id: "global:done",
  },
  statusLabel: {
    defaultMessage: "New status",
    id: "global:update:status:label",
  },
  toastSuccess: {
    defaultMessage: "Progress updated",
    id: "global:progress:updated",
  },
  validationErrorValue: {
    defaultMessage: "Please enter a number",
    id: "progressUpdate:validationError:value",
  },
  valueLabel: {
    defaultMessage: "New value",
    id: "global:update:value:label",
  },
});
