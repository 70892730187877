import { useUpsertResultMutation } from "@graphql";
import { useIntl } from "react-intl";
import { toast } from "utils/toastr";
import { showGqlErrorToast } from "utils/utils";

type Values = {
  title: string;
};

export const useResultTitle = (resultId: string) => {
  const intl = useIntl();
  const [updateResult] = useUpsertResultMutation();

  const onSubmit = async (values: Values) => {
    const response = await updateResult({
      variables: {
        input: {
          id: resultId,
          name: values.title,
        },
      },
    });
    if (response.errors) {
      showGqlErrorToast(response.errors);
    } else {
      toast.success(
        intl.formatMessage({
          defaultMessage: "Result Updated!",
          id: "Ihy484",
        })
      );
    }
  };

  return {
    onSubmit,
  };
};
