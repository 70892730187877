import {
  GetGroupHeaderQueryData,
  GetGroupQueryData,
  GroupArchivedFragment,
  GroupDefaultFragment,
  GroupLeaderFragment,
  GroupPulseFragment,
} from "@graphql";
import { aKpiConnection } from "mocks/factories/kpiConnection";
import { unmapEdges } from "utils/mapEdges";
import { Tuple } from "utils/Tuple";

export const mockGroup: ApolloMock<
  GroupDefaultFragment & GroupArchivedFragment
> = {
  __typename: "group",
  archived: false,
  avatar:
    "https://cdn.pixabay.com/photo/2016/06/06/17/05/woman-1439909_960_720.jpg",
  id: "mock-group-001",
  name: "Sample group",
};

type ReportGroup = GroupDefaultFragment &
  GroupArchivedFragment &
  GroupLeaderFragment &
  GroupPulseFragment;

const mockDefaultReportGroup: WithTypename<Omit<ReportGroup, "id">> = {
  __typename: "group",
  archived: false,
  avatar: null,
  groupLeader: {
    __typename: "user",
    fullName: "Adam Smith",
    id: "mock-current-user-001",
  },
  name: "Sample group",
  pulse: 3,
};

/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockReportGroups: Tuple<ReportGroup, 6> = [
  {
    ...mockDefaultReportGroup,
    id: "mock-report-group-001",
    name: "Engineering",
    pulse: 4.75,
  },
  {
    ...mockDefaultReportGroup,
    id: "mock-report-group-002",
    name: "Product",
    pulse: 3.5,
  },
  {
    ...mockDefaultReportGroup,
    id: "mock-report-group-003",
    name: "Customer success",
    pulse: 2.25,
  },
  {
    ...mockDefaultReportGroup,
    id: "mock-report-group-004",
    pulse: 1.5,
  },
  {
    ...mockDefaultReportGroup,
    id: "mock-report-group-005",
    pulse: 1.0,
  },
  {
    ...mockDefaultReportGroup,
    id: "mock-report-group-006",
    name: "Sixth Group",
    pulse: 0.0,
  },
];

// TODO: use composable fragments / infer fragments from Group type
type MockGroup = NonNullable<
  GetGroupQueryData["group"] & GetGroupHeaderQueryData["group"]
>;

/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockGetGroupGroup: MockGroup = {
  __typename: "group",
  allParents: {
    __typename: "groupConnection",
    edges: [],
  },
  archived: false,
  archivedDate: null,
  avatar: null,
  cadence: {
    __typename: "cadence",
    id: "mock-cadence-001",
  },
  canPatch: true,
  groupLeader: null,
  id: "mock-group-001",
  kpis: aKpiConnection({
    healthyCount: 2,
    totalCount: 3,
  }),
  kpisEnabled: true,
  members: {
    __typename: "userConnection",
    edges: [],
  },
  mission: null,
  name: "Engineering",
  objectives: {
    __typename: "objectiveConnection",
    averageProgress: 50,
    statusBreakdown: {
      __typename: "StatusBreakdownType",
      ACCOMPLISHED: null,
      NEEDS_ATTENTION: null,
      NO_STATUS: null,
      OFF_TRACK: null,
      ON_TRACK: null,
    },
    totalCount: 5,
  },
  parentGroups: {
    __typename: "groupConnection",
    edges: [],
  },
  progress: 50,
  slackChannel: null,
  subGroups: {
    __typename: "groupConnection",
    edges: [],
    totalCount: 0,
  },
};

export const mockSubgroup: MockGroup = {
  ...mockGetGroupGroup,
  parentGroups: {
    __typename: "groupConnection",
    edges: unmapEdges(
      [
        {
          __typename: "group",
          avatar: null,
          id: "mock-parent-group-001",
          name: "Mock parent group",
        },
      ],
      "groupEdge"
    ),
  },
};
