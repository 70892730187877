import React, { useEffect, useRef, useState } from "react";
import SplitPane from "react-split-pane";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { twClass } from "utils/twClass";

type Props = {
  children: React.ReactNode;
  layoutKey: string;
};

export const PerformanceReviewCompleteFormLayout = ({
  layoutKey,
  children,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [maxSize, setMaxSize] = useState(() => {
    if (ref.current) return ref.current.offsetWidth * 0.7;
    return 1280;
  });
  const [minSize, setMinSize] = useState(() => {
    if (ref.current) return ref.current.offsetWidth * 0.5;
    return 400;
  });
  const [isDragging, setIsDragging] = useState(false);

  const [size, setSize] = useLocalStorage<string>(
    `${layoutKey}SplitPos`,
    "70%"
  );

  useEffect(() => {
    if (ref.current) {
      setMaxSize(ref.current.offsetWidth * 0.7);
      setMinSize(ref.current.offsetWidth * 0.5);
    }
  }, [windowSize]);

  const handleSplitterResize = (newSize: number) => {
    if (ref.current) {
      setSize(`${Math.round((newSize / ref.current.offsetWidth) * 100)}%`);
    }
  };
  return (
    <div ref={ref} className="h-full w-full">
      {/* @ts-expect-error FIXME: TS2322: Type '{ children: Element[]; ... }' is not assignable to type ... */}
      <SplitPane
        pane2Style={{
          overflow: "hidden",
        }}
        className="!relative"
        defaultSize={size}
        maxSize={maxSize}
        minSize={minSize}
        onChange={handleSplitterResize}
        onDragFinished={() => setIsDragging(false)}
        onDragStarted={() => setIsDragging(true)}
        resizerClassName={twClass(
          "bg-white !border-r hover:!border-r-2 !border-r-slate-300 hover:!border-r-blue-500",
          {
            "!border-r-blue-500": isDragging,
          }
        )}
        split="vertical"
      >
        {children}
      </SplitPane>
    </div>
  );
};
