import { OneOnOneMeetingItem } from "@graphql";
import { Derive } from "@shoooe/derive";
import dayjs from "dayjs";
import React from "react";
import { Icon } from "common/icons";
import { twClass } from "utils/twClass";

type DueDateActionItem = Derive<
  OneOnOneMeetingItem,
  {
    dueDate: true;
  }
>;

type Props = {
  className?: string;
  dueDate: DueDateActionItem["dueDate"];
};

export const ActionItemDueDate = ({
  className,
  dueDate,
}: Props): JSX.Element => (
  <span className={twClass("mt-1 flex items-center space-x-1", className)}>
    <Icon name="timer" size="md" type="outlined" />
    <span className="whitespace-nowrap text-sm font-normal">
      {dayjs(dueDate).format("MMM D YYYY")}
    </span>
  </span>
);
