import { Field, FieldProps, Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { TextEditorVariant } from "common/inputs/TextEditor/TextEditor";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { twClass } from "utils/twClass";
import { stripHTML } from "utils/utils";
import { FormTextArea } from "../FormTextArea/FormTextArea";

export const EMPTY_TEXTAREA_VALUE = "<p></p>";

type Props = {
  archived?: boolean;
  closed?: boolean;
  "data-testid"?: string;
  description: string;
  editVisible: boolean;
  entityId: string;
  onUpdate: (input: { description: string; id: string }) => void;
  template?: string;
  variant?: TextEditorVariant;
};

export const DescriptionForm = ({
  archived = false,
  closed = false,
  "data-testid": dataTestId,
  description,
  editVisible,
  entityId,
  onUpdate,
  template,
  variant = "normal",
}: Props): JSX.Element => {
  const intl = useIntl();

  const handleSubmit = (values: { description: string }) => {
    if (values.description !== description) {
      onUpdate({
        description: values.description,
        id: entityId,
      });
    }
  };

  if (archived || closed || !editVisible) {
    const isDescriptionEmpty = isEmpty(stripHTML(description));
    return (
      <DangerousHTML
        className="modal-scrollbar modal-scrollbar-thumb max-h-96 overflow-y-auto overflow-x-hidden pl-2 text-slate-800"
        html={
          isDescriptionEmpty
            ? intl.formatMessage({
                defaultMessage: "No description added",
                id: "objective:details:noDescription",
              })
            : description
        }
      />
    );
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{ description }}
      onSubmit={handleSubmit}
    >
      {({ submitForm, resetForm, setFieldValue }) => (
        <Field name="description">
          {({ field, form }: FieldProps) => (
            <FormTextArea
              data-testid={dataTestId}
              variant={variant}
              className={twClass({
                "border border-solid bg-transparent border-transparent hover:border-slate-300":
                  variant === "normal",
              })}
              clearOnCancel={false}
              editorClassName="max-h-96"
              enableTasks
              field={field}
              form={form}
              minimizedView
              onCancel={resetForm}
              onEditorBlur={(value) => {
                const newValue = value === EMPTY_TEXTAREA_VALUE ? null : value;
                setFieldValue("description", newValue);
                submitForm();
              }}
              onSubmit={submitForm}
              placeholder={intl.formatMessage({
                defaultMessage: "No description added",
                id: "objective:details:noDescription",
              })}
              plain
              template={template}
            />
          )}
        </Field>
      )}
    </Formik>
  );
};
