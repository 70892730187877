import classNames from "clsx";
import { isNil } from "lodash";
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Avatar } from "common/avatar";
import { ItemSize } from "common/misc/types";
import { AnchorNext } from "common/navigation";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";

type Props = {
  avatar?: string | null;
  avatarClass?: string;
  /** use this to overwrite the default Avatar component */
  avatarElement?: ReactNode;
  avatarSize?: keyof typeof ItemSize;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  forceTooltip?: boolean;
  href?: string;
  /** small grey text shown below the name */
  name?: ReactNode;
  showStreak?: boolean;
  streak?: number | null;
  subtext?: ReactNode;
  tooltip?: ReactNode;
};

/**
 * an entity element is composed of an avatar and a user / group / goal name.
 */
export const Entity: FunctionComponent<Props> = ({
  avatar,
  avatarClass,
  avatarElement,
  avatarSize = "small",
  children,
  className,
  disabled,
  href,
  name,
  forceTooltip,
  subtext,
  tooltip: passedTooltip,
}) => {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [tooltipNeeded, setTooltipNeeded] = useState(true);

  useEffect(() => {
    if (containerRef) {
      setTooltipNeeded(containerRef.scrollWidth > containerRef.offsetWidth);
    }
  }, [containerRef]);

  const defaultTooltip = (
    <div className="flex items-center">
      <span>{name}</span>
    </div>
  );

  const tooltip = isNil(passedTooltip) ? defaultTooltip : passedTooltip;
  const showTooltip = forceTooltip || tooltipNeeded;

  return (
    <div className={twClass("flex w-full items-center", className)}>
      {avatarElement ?? (
        <Avatar
          disabled={disabled}
          className={classNames(avatarClass, "mr-2")}
          size={avatarSize}
          url={avatar}
        />
      )}
      <div className="flex grow flex-col overflow-hidden">
        <WithTooltip
          className="flex grow overflow-hidden"
          tooltip={showTooltip && tooltip}
        >
          {href && (
            <AnchorNext
              ref={(newRef) => {
                setContainerRef(newRef);
              }}
              className={twClass(
                "w-full truncate text-slate-800 hover:text-blue-500",
                {
                  "text-slate-500 pointer-events-none": disabled,
                }
              )}
              data-fs="hide"
              href={href}
            >
              {name}
            </AnchorNext>
          )}
          {!href && (
            <span
              ref={(newRef) => {
                setContainerRef(newRef);
              }}
              className={twClass("w-full truncate", {
                "text-slate-500 pointer-events-none": disabled,
              })}
              data-fs="hide"
            >
              {name}
            </span>
          )}
        </WithTooltip>
        {subtext && (
          <div
            className="flex grow gap-5 truncate text-sm text-slate-500"
            title={typeof subtext === "string" ? subtext : undefined}
          >
            {subtext}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
