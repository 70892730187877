import { FetchResult } from "@apollo/client";
import { CommitStatus, UpsertCommitMutationData } from "@graphql";
import { isNil } from "lodash";
import { ProgressUpdatePanelValues } from "common/goal/ProgressUpdatePanel/ProgressUpdatePanel";
import { objectTypes } from "constants/objects";
import { useCommitActions } from "hooks/useCommitActions/useCommitActions";
import { toast } from "utils/toastr";

type Goal = {
  __typename: "kpi" | "keyResult";
  id: string;
};

export type ProgressUpdateHook = {
  handleSubmit: (
    values: ProgressUpdatePanelValues
  ) => Promise<FetchResult<UpsertCommitMutationData>>;
};

/**
 * returns a `handleSubmit` function that can be passed to a progress update form.
 * this function will submit the progress update for a kpi or result.
 */
export const useProgressUpdate = (
  item: Goal | undefined
): ProgressUpdateHook => {
  const { addCommit } = useCommitActions();

  if (!item) {
    return { handleSubmit: () => Promise.reject(new Error("No result")) };
  }

  const type = item.__typename === "keyResult" ? "keyresult" : item.__typename;

  const handleSubmit = async ({
    commitDate,
    description,
    status,
    value,
  }: ProgressUpdatePanelValues) => {
    if (isNil(value)) {
      toast.failure("Unable to update progress");
      return Promise.reject(new Error("Unable to update progress"));
    }

    const commitStatus = status as CommitStatus;

    const data = {
      commitDate,
      description,
      keyResult: type === "keyresult" ? item.id : undefined,
      kpi: type === "kpi" ? item.id : undefined,
      status: commitStatus,
      value,
    };

    return addCommit(data, objectTypes[type]);
  };

  return { handleSubmit };
};
