import { Transition } from "@headlessui/react";
import classNames from "clsx";
import { isFunction } from "lodash";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { HELP_CENTER_WIDTH } from "common/layout/HelpCenter/HelpCenter";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { useToggle } from "hooks/useToggle/useToggle";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { getPlatform } from "utils/getPlatform";
import { twClass } from "utils/twClass";
import { ModalContext } from "./ModalContext";

export const MODAL_ROOT_ID = "modal-root";

export type ModalSize = "sm" | "md" | "lg" | "xl";

type ChildrenPrams = {
  handleClose: () => void;
  showConfirm: boolean;
  toggleConfirm: () => void;
};

export interface ModalProps {
  backdropClass?: string;
  backdropClose?: boolean;
  buttonAreaStyles?: React.CSSProperties;
  children?:
    | ReactNode
    | (({
        handleClose,
        showConfirm,
        toggleConfirm,
      }: ChildrenPrams) => ReactNode);
  className?: string;
  contentClassName?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disableEsc?: boolean;
  extraIconButtons?: ReactNode[];
  formTouched?: () => boolean;
  headerIndicators?: ReactNode;
  id?: string;
  isOpen: boolean;
  loading?: boolean;
  onClose?: (e?: any) => void;
  renderActions?: () => ReactNode;
  scrollable?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
  showHeaderBodyDivider?: boolean;
  size?: ModalSize;
  title: ReactNode;
  titleClassName?: string;
  titleWrapperClassName?: string;
  verticalScrollable?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  "data-cy": dataCy,
  "data-testid": dataTestId,
  backdropClass,
  backdropClose = true,
  buttonAreaStyles = {},
  children = null,
  className,
  disableEsc = false,
  extraIconButtons = [],
  formTouched,
  headerIndicators = null,
  id,
  isOpen,
  loading = false,
  onClose,
  renderActions,
  scrollable = true,
  verticalScrollable = true,
  showFooter = true,
  showHeader = true,
  showHeaderBodyDivider = false,
  size = "md",
  title = "",
  titleClassName,
  contentClassName,
  titleWrapperClassName,
  ...other
}) => {
  const { isHelpCenterVisible } = useHelpCenter();
  const rightOffset = isHelpCenterVisible ? HELP_CENTER_WIDTH : "0px";
  const [footerRef, setFooterRef] = useState<any>();
  const [showModal, toggleModal, setShowModal] = useToggle(true);
  const [showConfirm, toggleConfirm] = useToggle(false);

  const handleClose = () => {
    if ((formTouched?.() ?? false) && !showConfirm) {
      toggleConfirm();
      return;
    }
    toggleModal();
  };

  const handleBackgroundClick = () => {
    if (!backdropClose) {
      return;
    }
    handleClose();
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    const platform = getPlatform();
    if (e.key === "Escape" && !disableEsc) {
      handleBackgroundClick();
    } else if (
      (platform === "osx" && e.metaKey && e.key === "Enter") ||
      (platform === "windows" && e.ctrlKey && e.key === "Enter") ||
      (platform === "linux" && e.ctrlKey && e.key === "Enter")
    ) {
      e.preventDefault();
    }
  };

  const attachListeners = () => {
    document.addEventListener("keydown", handleKeyPress, true);
  };

  const detachListeners = () => {
    document.removeEventListener("keydown", handleKeyPress, true);
  };
  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen, setShowModal]);

  useEffect(() => {
    if (isOpen) {
      attachListeners();
    }
    return () => {
      detachListeners();
    };
  }, []);

  const actions = renderActions?.();

  const modalRoot = document.getElementById(MODAL_ROOT_ID);
  const content = (
    <Show
      fallback={
        <div className="flex h-64 content-center items-center justify-center">
          <Spinner />
        </div>
      }
      when={!loading}
    >
      {isFunction(children)
        ? children({ handleClose, showConfirm, toggleConfirm })
        : children}
    </Show>
  );

  if (!isOpen) {
    return null;
  }

  const modal = (
    <ModalContext.Provider value={{ footerRef }}>
      <Transition afterLeave={onClose} appear show={showModal}>
        <div
          className="absolute inset-0 flex items-start justify-center p-8"
          data-cy={dataCy}
          data-testid={dataTestId}
          id={id}
        >
          <Transition.Child
            as={Fragment}
            leave="transition-all duration-300"
            leaveFrom="transform opacity-100 translate-y-0"
            leaveTo="transform opacity-0 -translate-y-16"
          >
            <div
              className={twClass(
                "z-modal flex flex-col",
                "rounded shadow-xl bg-white",
                "animate-modal-slide-in",
                {
                  "mt-16 max-h-[85vh] w-[1200px]": size === "lg",
                  "mt-16 max-h-[85vh] w-[600px]": size === "sm",
                  "mt-16 max-h-[85vh] w-[950px]": size === "md",
                  "w-full h-full": size === "xl",
                },
                className
              )}
              data-cy="modalContent"
              data-testid="modalContent"
              style={{
                marginRight: rightOffset,
                maxWidth: `calc(100% - ${rightOffset})`,
              }}
            >
              {showHeader && (
                <div
                  className="flex w-full items-center pl-6 pr-5 pt-4"
                  data-testid="modalHeader"
                >
                  <div
                    className={twClass(
                      "grid flex-grow grid-cols-4 justify-between text-xl font-semibold text-slate-800",
                      titleWrapperClassName
                    )}
                    data-cy="modalTitle"
                  >
                    <h2 className={twClass("col-span-3", titleClassName)}>
                      {title}
                    </h2>
                    <div className="flex flex-col">
                      <div
                        className="flex justify-end fill-current text-slate-500 space-x-2"
                        data-cy="modalButtons"
                        style={{ ...buttonAreaStyles }}
                      >
                        {extraIconButtons}
                        {actions && (
                          <Dropdown
                            button={
                              <IconButton
                                className="hover:bg-transparent hover:text-blue-500"
                                data-cy="actionsModalButton"
                                iconClass="hover:text-blue-500"
                                name="more_vert"
                                tooltip={
                                  <FormattedMessage
                                    defaultMessage="More options"
                                    id="IzCVhG"
                                  />
                                }
                              />
                            }
                          >
                            {actions}
                          </Dropdown>
                        )}
                        <IconButton
                          className="hover:bg-transparent"
                          data-cy="closeModalButton"
                          iconClass="hover:text-blue-500"
                          name="close"
                          onClick={handleClose}
                        />
                      </div>
                      {headerIndicators}
                    </div>
                  </div>
                </div>
              )}
              {showHeaderBodyDivider && (
                <div className="w-full border-b border-solid border-slate-300" />
              )}
              <div
                className={twClass(
                  "grow modal-scrollbar modal-scrollbar-thumb overflow-x-hidden overflow-y-hidden",
                  {
                    "overflow-y-auto px-6 pb-5": scrollable,
                    "overflow-y-hidden": !verticalScrollable,
                  },
                  contentClassName
                )}
                data-cy="modalBody"
                {...other}
              >
                {content}
              </div>
              {showFooter && <div ref={(newRef) => setFooterRef(newRef)} />}
            </div>
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              data-cy="D5KfINARuAiwnrBMG5CFJ"
              className={classNames(
                "absolute inset-0 z-modal-backdrop bg-opacity-50 bg-black",
                backdropClass
              )}
              id="modalBackdrop"
              onClick={handleBackgroundClick}
            />
          </Transition.Child>
        </div>
      </Transition>
    </ModalContext.Provider>
  );
  return modalRoot && ReactDOM.createPortal(modal, modalRoot);
};
