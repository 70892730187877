import {
  GCalIntegration,
  namedOperations,
  useCreateGoogleCalendarIntegrationMutation,
  useDeleteGoogleCalendarIntegrationMutation,
  useGetGoogleCalendarHealthQuery,
  useGetGoogleCalendarInfoQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { GOOGLE_CLIENT_ID } from "config";
import { useIntl } from "react-intl";
import { routes } from "route-configs";
import { GCAL_CONNECTED, GCAL_DISCONNECTED } from "constants/tracking";
import { usePersistentRandomOAuthState } from "hooks/usePersistentRandomOAuthState/usePersistentRandomOAuthState";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

const REQUIRED_SCOPES = [
  "https://www.googleapis.com/auth/calendar.events",
  "https://www.googleapis.com/auth/userinfo.email",
];

type GoogleCalendarIntegration = Derive<
  GCalIntegration,
  { email: true; id: true }
>;

type Hook = {
  deleteIntegration: () => void;
  integration: GoogleCalendarIntegration | undefined | null;
  isCreating: boolean;
  isHealthy: boolean | undefined;
  startFlow: () => void;
};

export const useGoogleCalendar = (): Hook => {
  const intl = useIntl();

  const { data: integrationData } = useGetGoogleCalendarInfoQuery({
    fetchPolicy: "cache-and-network",
  });
  const integration = integrationData?.me?.gCalIntegration;

  // Fetched separately because it's expensive (e.g. we want to use the cache
  // when possible and fetch is separately so that it doesn't block the rest of
  // the UI).
  const { data: healthData } = useGetGoogleCalendarHealthQuery({
    fetchPolicy: "cache-first",
  });
  const isHealthy = healthData?.me?.gCalIntegration?.isHealthy;

  const [deleteMutation] = useDeleteGoogleCalendarIntegrationMutation({
    refetchQueries: [
      namedOperations.Query.GetGoogleCalendarHealth,
      namedOperations.Query.GetGoogleCalendarInfo,
    ],
  });

  const { oAuthState, regenerateOAuthState } = usePersistentRandomOAuthState();

  const deleteIntegration = async () => {
    if (!integration) return;
    const response = await deleteMutation({
      variables: { input: { id: integration.id } },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.deleteGCalIntegration?.errors
    );
    if (hasError) return;

    track(GCAL_DISCONNECTED);
    toast.success(
      intl.formatMessage({
        defaultMessage: "Integration disabled",
        id: "uXNeFW",
      })
    );
  };

  const [createMutation, { loading: isCreating }] =
    useCreateGoogleCalendarIntegrationMutation({
      refetchQueries: [
        namedOperations.Query.GetGoogleCalendarHealth,
        namedOperations.Query.GetGoogleCalendarInfo,
      ],
    });
  const createIntegration = async ({
    state,
    code,
    scope,
  }: google.accounts.oauth2.CodeResponse) => {
    // Google API may return more scopes than requested that's why we are
    // checking that the returned scopes here are a superset of (and not
    // strictly equal to) the requested scopes.
    const grantedScopes = scope.split(" ");
    const anyRequiredScopesNotGranted = REQUIRED_SCOPES.some(
      (requiredScope) =>
        !grantedScopes.some((grantedScope) => grantedScope === requiredScope)
    );
    if (anyRequiredScopesNotGranted) {
      toast.failure(
        intl.formatMessage({
          defaultMessage:
            "We are unable to enable the integration without the appropriate permissions. Please check all the boxes in the consent screen.",
          id: "sxwjIC",
        })
      );
      return;
    }

    if (oAuthState !== state) {
      toast.failure(
        intl.formatMessage({
          defaultMessage:
            "There was an error enabling the integration. Please try again.",
          id: "jFVUCr",
        })
      );
      return;
    }

    const response = await createMutation({ variables: { code } });
    regenerateOAuthState();

    const { hasError } = handleErrors(response, null, { handleTopLevel: true });
    if (hasError) return;

    track(GCAL_CONNECTED);
    toast.success(
      intl.formatMessage({
        defaultMessage: "Integration enabled",
        id: "PgSIQw",
      })
    );
  };

  const startFlow = () => {
    const { google } = window;

    // Can be undefined e.g. if there are tracking blockers
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (google) {
      const client = google.accounts.oauth2.initCodeClient({
        callback: createIntegration,
        client_id: GOOGLE_CLIENT_ID,
        redirect_uri: `${window.location.origin}${routes.settings.user.integrations}`,
        scope: REQUIRED_SCOPES.join(" "),
        state: oAuthState,
        ux_mode: "popup",
      });
      client.requestCode();
    }
  };

  return {
    deleteIntegration,
    integration,
    isCreating,
    isHealthy,
    startFlow,
  };
};
