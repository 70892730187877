import { SuggestionProps } from "@tiptap/suggestion";
import Image from "next/image";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { For } from "common/controlFlow";
import { SlackEmoji } from "common/inputs/TextEditor/ToolBarItem/SlackEmojis/suggestion";
import { ListItem } from "common/misc/ListItem/ListItem";
import { Clickable } from "common/navigation/Clickable";
import { twClass } from "utils/twClass";

const messages = defineMessages({
  noResultsFound: {
    defaultMessage: "No Results",
    id: "global:no:results",
  },
});

type Props = SuggestionProps<SlackEmoji>;

export const EmojiList = forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command(item);
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div data-testid="emojiList">
      <For
        each={props.items}
        fallback={
          <ListItem>{intl.formatMessage(messages.noResultsFound)}</ListItem>
        }
      >
        {(item: SlackEmoji, index: number) => (
          <Clickable
            className={twClass(
              "flex p-1 w-full gap-2 items-center text-black hover:bg-blue-100",
              {
                "bg-blue-100": index === selectedIndex,
              }
            )}
            data-cy="emojiSuggestion"
            data-testid="emojiSuggestion"
            onClick={() => selectItem(index)}
          >
            {item.src ? (
              <Image alt={item.id} height={32} src={item.src} width={32} />
            ) : (
              <span className="text-xl flex items-center w-8 h-8">
                {item.content}
              </span>
            )}
            {item.name}
          </Clickable>
        )}
      </For>
    </div>
  );
});
