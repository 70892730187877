import { gql } from "@apollo/client";
import {
  ClosingNoteDefaultFragmentDoc,
  ClosingNoteQuestionsFragmentDoc,
  ClosingNoteRatingFragmentDoc,
  ClosingNoteStatusFragmentDoc,
  EditableTimeframeTimeframeFragmentDoc,
  GroupDefaultFragmentDoc,
  ObjectiveDefaultLegacyFragmentDoc,
} from "@graphql";
import { CommentTooltip } from "common/comment/CommentTooltip/CommentTooltip";

const EditableTimeframeFragment = gql`
  fragment EditableTimeframeTimeframe on timeframe {
    ...TimeframeDefault
    ...TimeframeDates
    ...TimeframeStatusFields
  }
`;

/**
 * @deprecated use composable fragments from {@link common/objective/fragments.graphql} instead.
 */
export const OBJECTIVE_FRAGMENT = gql`
  fragment ObjectiveFragment on objective {
    id
    private
    name
    stage
    dueDate
    progress
    progressDriver
    canPatch
    startDate
    canDelete
    description
    completed
    progressDelta
    mapIndex
    isCompanyGoal
    goalUpdateCycle
    status
    company {
      id
      name
      avatar
    }
    lastComment {
      ...CommentTooltip
    }
    initiatives {
      totalCount
      achievedCount
    }
    keyResults {
      edgeCount
    }
    results {
      edgeCount
      edges {
        node {
          id
          index
          children {
            edges {
              node {
                id
                index
              }
            }
          }
          parent {
            id
          }
        }
      }
    }
    strategicPillar {
      id
      name
    }
    parent {
      id
      name
    }
    kpi {
      id
      name
    }
    groups {
      edges {
        node {
          ...GroupDefault
        }
      }
    }
    contributors {
      edges {
        node {
          id
          fullName
          avatar
        }
      }
    }
    privateToUsers {
      edgeCount
      edges {
        node {
          id
          fullName
          avatar
        }
      }
    }
    timeframe {
      ...EditableTimeframeTimeframe
    }
    lead {
      id
      avatar
      fullName
    }
    tags {
      edges {
        node {
          id
          color
          name
          description
        }
      }
    }
    children {
      edgeCount
    }
  }
  ${CommentTooltip.fragment}
  ${EditableTimeframeTimeframeFragmentDoc}
  ${GroupDefaultFragmentDoc}
`;

export const OBJECTIVE_QUERY = gql`
  query getObjective($objectiveId: UUID!) {
    objective(id: $objectiveId) {
      ...ObjectiveFragment
      closingNote {
        ...ClosingNoteDefault
        ...ClosingNoteQuestions
        ...ClosingNoteRating
        ...ClosingNoteStatus
      }
    }
  }
  ${ClosingNoteDefaultFragmentDoc}
  ${ClosingNoteQuestionsFragmentDoc}
  ${ClosingNoteRatingFragmentDoc}
  ${ClosingNoteStatusFragmentDoc}
  ${OBJECTIVE_FRAGMENT}
`;

export const UPSERT_OBJECTIVE_MUTATION = gql`
  mutation upsertObjectiveMutation($input: UpsertObjectiveMutationInput!) {
    upsertObjective(input: $input) {
      objective {
        ...ObjectiveFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OBJECTIVE_FRAGMENT}
`;

export const DELETE_OBJECTIVE_MUTATION = gql`
  mutation deleteObjectiveMutation($input: DeleteObjectiveMutationInput!) {
    deleteObjective(input: $input) {
      objective {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const OBJECTIVES_SEARCH_QUERY = gql`
  query searchObjectives($search: String) {
    objectives(name_Icontains: $search) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const FORM_OBJECTIVE_QUERY = gql`
  query getFormObjective($id: UUID!) {
    objective(id: $id) {
      id
      name
    }
  }
`;

export const OBJECTIVE_TEMPLATE_QUERY = gql`
  query getCompanyClosingTemplate {
    currentCompany {
      initArchivingTemplate
      id
    }
  }
`;

export const GET_INITIAL_FORM_DATA = gql`
  query getAddObjectiveData {
    currentCompany {
      id
      objDescriptionTemplate
    }
    me {
      id
    }
  }
`;

export const GET_FORM_ACTIVE_OBJECTIVES = gql`
  query getFormActiveObjectives($objectivesStages: String) {
    objectives(
      stage_In: $objectivesStages
      includePastTimeframes: false
      private: false
    ) {
      edges {
        node {
          ...ObjectiveDefaultLegacy
        }
      }
    }
  }
  ${ObjectiveDefaultLegacyFragmentDoc}
`;

export const GET_FORM_CLOSED_OBJECTIVE = gql`
  query getFormClosedObjective($objectiveId: UUID!) {
    objective(id: $objectiveId) {
      ...ObjectiveDefaultLegacy
    }
  }
  ${ObjectiveDefaultLegacyFragmentDoc}
`;

export const GET_PRIVATE_OBJECTIVE = gql`
  query getPrivateObjective($objectiveId: UUID!) {
    objective(id: $objectiveId) {
      id
      lead {
        id
        fullName
        avatar
      }
      privateToUsers {
        edgeCount
        edges {
          node {
            id
            fullName
            avatar
          }
        }
      }
    }
  }
`;
