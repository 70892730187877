import { Group, useGroupNameAndAvatarQuery } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import { ReactNode } from "react";
import { useIntl } from "react-intl";
import { toast } from "utils/toastr";

type GroupProp = Derive<
  Group,
  {
    avatar: true;
    id: true;
    name: true;
  }
>;

type Props = {
  children: (group: GroupProp) => ReactNode;
  id: string;
};

/**
 * fetches group's name and avatar based on id
 */
export const GroupName = ({ id, children }: Props) => {
  const intl = useIntl();
  const { data, error } = useGroupNameAndAvatarQuery({
    variables: {
      groupId: id,
    },
  });

  if (isNil(data)) return null;

  if (error || isNil(data.group)) {
    toast.failure(
      intl.formatMessage({
        defaultMessage:
          "Something went wrong. Please contact our Support Team if this happens again.",
        id: "global:something:wrong:extended",
      })
    );
    return null;
  }

  return children(data.group);
};
