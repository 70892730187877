import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { twClass } from "utils/twClass";

type Props = {
  description: string | null;
};

export const GoalUpdateNoteSubtext = ({ description }: Props): JSX.Element => {
  return (
    <div className="flex items-center space-x-2 text-sm text-slate-800">
      <Icon
        className="text-slate-800 -mb-0.5"
        name="mode_comment"
        size="lg"
        type="outlined"
      />
      <span
        className={twClass({
          "text-slate-500": !description,
          "text-slate-800": !!description,
        })}
      >
        {!!description ? (
          <DangerousHTML html={description} />
        ) : (
          <FormattedMessage defaultMessage="No comment added" id="0v5vIH" />
        )}
      </span>
    </div>
  );
};
