import React from "react";
import { useFormatDate } from "./hooks/useFormatDate";

type Props = {
  includeYear?: boolean;
  timeZone?: string;
  value: string;
};

export const FormattedDate = ({
  includeYear = true,
  timeZone,
  value,
}: Props): JSX.Element => {
  const { formatDate } = useFormatDate();

  return <>{formatDate(value, { includeYear, timeZone })}</>;
};
