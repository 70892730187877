import classNames from "clsx";
import React from "react";
import { FormattedMessage } from "react-intl";
import { EntityIcon } from "common/icons/EntityIcon/EntityIcon";
import { progressTypes } from "constants/progressTypes";

interface Props {
  className?: string;
  progressDriver: string;
}

export const ProgressDriverStatus = ({ progressDriver, className }: Props) => {
  return (
    <div className={classNames("flex px-2 pb-1 pt-2 text-xs", className)}>
      <EntityIcon entity={progressDriver} size="xl" />
      <span className="ml-2">
        {progressDriver === progressTypes.keyResults ? (
          <FormattedMessage
            defaultMessage="Progress & Status based on Key Results"
            id="objective:card:tooltip:progress:KR"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Progress & Status based on Aligned Objectives"
            id="objective:card:tooltip:progress:AO"
          />
        )}
      </span>
    </div>
  );
};
