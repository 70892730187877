import { isNil } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Avatar } from "common/avatar";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import {
  SeriesUser,
  useCompleteOneOnOneContext,
} from "common/oneOnOnes/context/completeOneOnOneContext";
import { Dropdown } from "common/overlay/Dropdown";
import { twClass } from "utils/twClass";

type OptionProp = {
  isActive: boolean;
  onChange: (userId: string | null) => void;
  user?: SeriesUser;
};

type Props = {
  onChange: (userId: string | null) => void;
  value: string | null;
};

export const OneOnOneSelectUserOption = ({
  user,
  onChange,
  isActive,
}: OptionProp): JSX.Element => {
  return (
    <Show when={!isNil(user)}>
      <Dropdown.Item
        className={twClass(
          "flex min-w-0 items-center space-x-2",
          "text-base font-normal",
          {
            "text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700":
              isActive,
          }
        )}
        data-cy="lBtlcdabt6J_Yznf1gn_9"
        onClick={() => onChange(user?.id as string)}
      >
        <Show when={!isNil(user?.avatar)}>
          <Avatar size="extraSmall" url={user?.avatar} />
        </Show>
        <span className="flex-1 truncate">{user?.fullName}</span>
      </Dropdown.Item>
    </Show>
  );
};

export const OneOnOneMeetingItemsUserSelect = ({
  onChange,
  value,
}: Props): JSX.Element => {
  const { organizer, attendee } = useCompleteOneOnOneContext();

  const userAvatarUrl = () => {
    if (!isNil(value)) {
      if (value === organizer.id) return organizer.avatar;
      if (value === attendee.id) return attendee.avatar;
    }
    return null;
  };

  const url = userAvatarUrl();
  return (
    <Dropdown>
      <Dropdown.Trigger>
        {isNil(value) ? (
          <IconButton
            className={twClass(
              "rounded-full p-1.5",
              "border-2 border-transparent hover:border-blue-500",
              "bg-slate-100 text-blue-500"
            )}
            name="person_add"
            size="md"
          />
        ) : (
          <div>
            <Show when={!isNil(url)}>
              <Avatar
                className="border-2 border-transparent hover:cursor-pointer hover:border-blue-500"
                size="medium"
                url={url}
              />
            </Show>
          </div>
        )}
      </Dropdown.Trigger>
      <Dropdown.Content className="w-full space-y-1" placement="bottom-left">
        <OneOnOneSelectUserOption
          isActive={value === organizer.id}
          onChange={onChange}
          user={organizer}
        />
        <OneOnOneSelectUserOption
          isActive={value === attendee.id}
          onChange={onChange}
          user={attendee}
        />
        <Dropdown.Item
          className={twClass(
            "flex min-w-0 items-center space-x-2 hover:bg-blue-500",
            {
              "text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700":
                isNil(value),
            }
          )}
          data-cy="W35OoBjRa9MTj4FXJ6tuy"
          onClick={() => onChange(null)}
        >
          <span>
            <FormattedMessage defaultMessage="Unassigned" id="a1eoKk" />
          </span>
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown>
  );
};
