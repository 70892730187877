import React, { CSSProperties, ReactNode } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { HelpRollover } from "common/overlay/HelpRollover/HelpRollover";
import { twClass } from "utils/twClass";

export interface WidgetProps {
  children?: ReactNode;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  helpElement?: ReactNode;
  id?: string;
  noPadding?: boolean;
  rightElement?: ReactNode;
  style?: CSSProperties;
  title?: ReactNode;
}

export const Widget = React.forwardRef<HTMLDivElement, WidgetProps>(
  (
    {
      children,
      className,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      helpElement,
      id,
      noPadding = false,
      rightElement,
      style,
      title = "",
    }: WidgetProps,
    ref
  ): JSX.Element => (
    <div
      ref={ref}
      className={twClass(
        "bg-white",
        "px-8 py-4",
        noPadding && "p-0",
        "rounded border border-slate-300",
        className
      )}
      data-cy={dataCy}
      data-testid={dataTestId}
      id={id}
      style={style}
    >
      {title && (
        <div
          className={twClass(
            "px-0 py-1.5",
            noPadding && "px-5 py-1.5" // sic
          )}
        >
          <Row between="xs" middle="xs">
            <Col className="col">
              <div className="flex items-center">
                <h3 className="pb-4 font-semibold leading-tight">
                  {title}
                  &nbsp;
                </h3>
                {helpElement && <HelpRollover content={helpElement} />}
              </div>
            </Col>
            <Col>{rightElement}</Col>
          </Row>
        </div>
      )}
      {children}
    </div>
  )
);

Widget.displayName = "Widget";
