import {
  ObjectiveFragmentFragment,
  useUpsertObjectiveMutationMutation,
} from "@graphql";
import React from "react";
import { useIntl } from "react-intl";
import { ModalHeader } from "common/overlay/Modal/NewModalHeader/ModalHeader";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

interface HeaderProps {
  objective: ObjectiveFragmentFragment;
}

export const ObjectiveModalHeader = ({
  objective,
}: HeaderProps): JSX.Element => {
  const intl = useIntl();
  const [updateObjective] = useUpsertObjectiveMutationMutation();

  const saveObjective = async (obj: { id: string; name: string }) => {
    const response = await updateObjective({
      variables: {
        input: {
          ...obj,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertObjective?.errors
    );

    if (hasError) {
      toast.failure(
        intl.formatMessage({
          defaultMessage: "Error updating objective",
          id: "objective:details:error:updating",
        })
      );
    }

    toast.success(
      intl.formatMessage({
        defaultMessage: "Objective Updated",
        id: "N5Ya1o",
      })
    );
  };

  return (
    <ModalHeader
      closed={objective.completed}
      editVisible={objective.canPatch}
      entityId={objective.id}
      onUpdate={saveObjective}
      title={objective.name}
    />
  );
};
