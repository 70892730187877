import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { InputField } from "common/fields/InputField/InputField";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { WithHelper } from "common/form/WithHelper/WithHelper";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { messages } from "./UltimateGoalForm.messages";

const schema = Yup.object().shape({
  description: Yup.string().nullable(),
  name: Yup.string().nullable(),
});

export type InitialValues = {
  description: string;
  name: string;
};

type Props = {
  initialValues: InitialValues;
  isEdit?: boolean;
  onCancel: () => void;
  onSubmit: (values: InitialValues) => void;
};

export const UltimateGoalForm: React.FC<Props> = ({
  initialValues,
  isEdit = false,
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <WithHelper
              helper={
                <div>
                  <p className="mb-2.5">
                    {intl.formatMessage(messages.nameHelperText)}
                  </p>
                  <p className="mt-2.5">
                    {intl.formatMessage(messages.nameHelperSubtext, {
                      examples: (
                        <a
                          href="https://www.perdoo.com/resources/get-aligned-strategy-first-okr-second/"
                          rel="noopener noreferrer"
                          tabIndex={-1}
                          target="_blank"
                        >
                          {intl.formatMessage(messages.howTo)}
                        </a>
                      ),
                    })}
                  </p>
                </div>
              }
            >
              <InputField
                autoFocus
                label={intl.formatMessage({
                  defaultMessage: "Ultimate Goal",
                  id: "roadmap:ultimate",
                })}
                name="name"
                optional
                placeholder={intl.formatMessage(messages.namePlaceholder)}
              />
            </WithHelper>
            <WithHelper
              helper={<FormattedMessage {...messages.descriptionHelperText} />}
            >
              <div className="space-y-1">
                <Field name="description">
                  {({ field, form }: FieldProps) => (
                    <FormTextArea
                      className="h-full min-h-36"
                      data-testid="ultimateGoalDescription"
                      field={field}
                      form={form}
                      label={intl.formatMessage(messages.descriptionLabel)}
                      optional
                      placeholder={intl.formatMessage(
                        messages.descriptionPlaceholder
                      )}
                    />
                  )}
                </Field>
              </div>
            </WithHelper>
            <ModalFooter>
              <Button
                data-cy="Poy0nbfXQ7a2iNVnPspjE"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={submitForm}
                type="submit"
              >
                {intl.formatMessage({
                  defaultMessage: "Save",
                  id: "global:save",
                })}
              </Button>
              {!isEdit && (
                <Button
                  data-cy="3nOSzqIPYdpgIDTtCS67D"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={onCancel}
                  variant="ghost"
                >
                  {intl.formatMessage({
                    defaultMessage: "Cancel",
                    id: "global:cancel",
                  })}
                </Button>
              )}
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};
