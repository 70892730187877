import { isObject } from "lodash";

/**
 * special ID to denote a selectable empty state. this is useful if we want to show an item that should be clickable,
 * and which should clear the selection / set the value to null.
 * we can probably remove this if we implement clearing selection in a different way.
 */
export const SELECT_EMPTY_ID = "SELECT_EMPTY_ID";

/**
 * Special ID to denote a new option. This is useful when we want to have an
 * option that creates a new entity when clicked.
 */
export const SELECT_NEW_ID = "SELECT_NEW_ID";

/**
 * checks if a select option is the selectable empty state, based on id. useful if you want to put that item as first.
 */
export const isSelectableEmptyState = <SelectOption>(
  a: SelectOption
): boolean => isObject(a) && "id" in a && a.id === SELECT_EMPTY_ID;
