import { namedOperations, useDeleteClosingNoteMutation } from "@graphql";
import { defineMessages, useIntl } from "react-intl";
import { REOPEN_OBJECTIVE } from "constants/tracking";
import { handleNonFormErrors } from "utils/forms";
import { RefetchQueries } from "utils/graphql/types";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

const messages = defineMessages({
  reOpenMsg: {
    defaultMessage: "Objective Reopened!",
    id: "global:reOpened:message",
  },
});

type Options = {
  refetchQueries?: RefetchQueries;
};

type Hook = {
  deleteClosingNote: (closingNoteId: string) => Promise<void>;
};

export const useDeleteClosingNote = (options?: Options): Hook => {
  const intl = useIntl();
  const additionalRefetchQueries = options?.refetchQueries ?? [];
  const [deleteClosingNoteMutation] = useDeleteClosingNoteMutation({
    refetchQueries: [
      namedOperations.Query.getObjective,
      ...additionalRefetchQueries,
    ],
  });

  const deleteClosingNote = async (closingNoteId: string) => {
    const response = await deleteClosingNoteMutation({
      variables: { input: { id: closingNoteId } },
    });
    const errors = response.data?.deleteClosingNote?.errors;
    if (errors?.length) {
      handleNonFormErrors(errors);
      return;
    }
    toast.success(intl.formatMessage(messages.reOpenMsg));
    track(REOPEN_OBJECTIVE);
  };

  return {
    deleteClosingNote,
  };
};
