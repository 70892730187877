import { ClosingNoteStatus, CommitStatus, ProgressDriver } from "@graphql";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { ObjectiveStatusObjective } from "common/objective/ObjectiveStatus/ObjectiveStatus";
import { isSet } from "utils/isSet";

const messagedClosed = defineMessages<ClosingNoteStatus, MessageDescriptor>({
  [ClosingNoteStatus.Achieved]: {
    defaultMessage: "Congratulations! The Objective was achieved.",
    id: "LvjYZm",
  },
  [ClosingNoteStatus.Missed]: {
    defaultMessage: "The Objective missed the desired outcome.",
    id: "objective:status:tooltip:closed:missed",
  },
  [ClosingNoteStatus.Postponed]: {
    defaultMessage: "The Objective was postponed to a later timeframe.",
    id: "objective:status:tooltip:closed:postponed",
  },
  [ClosingNoteStatus.PartiallyAchieved]: {
    defaultMessage: "The Objective partially fulfilled the desired outcome.",
    id: "fVvrdO",
  },
  [ClosingNoteStatus.Cancelled]: {
    defaultMessage: "The Objective was cancelled.",
    id: "5mEUyt",
  },
});

type OkrCommitStatus = Exclude<
  CommitStatus,
  CommitStatus.Healthy | CommitStatus.Unhealthy
>;

const messagesAO = defineMessages<OkrCommitStatus, MessageDescriptor>({
  ACCOMPLISHED: {
    defaultMessage:
      "All the Aligned Objectives are accomplished; that means your Objective is accomplished too.",
    id: "objective:status:tooltip:ao:Accomplished",
  },
  NEEDS_ATTENTION: {
    defaultMessage:
      "If one of your Aligned Objectives need attention, the status for the entire Objective will be “needs attention” as well.",
    id: "objective:status:tooltip:ao:NeedsAttention",
  },
  NO_STATUS: {
    defaultMessage:
      "Since none of your Aligned Objectives currently have been started, your Objective’s status is set to “no status”.",
    id: "objective:status:tooltip:ao:NoStatus",
  },
  OFF_TRACK: {
    defaultMessage:
      "If one of your Aligned Objectives are off track, the status for the entire Objective will be “off track” as well.",
    id: "objective:status:tooltip:ao:OffTrack",
  },
  ON_TRACK: {
    defaultMessage:
      "All the Aligned Objectives are on track; that means your Objective is on track too.",
    id: "objective:status:tooltip:ao:OnTrack",
  },
});

const messagesKR = defineMessages<OkrCommitStatus, MessageDescriptor>({
  ACCOMPLISHED: {
    defaultMessage:
      "All your Key Results are accomplished; that means your Objective is accomplished too.",
    id: "objective:status:tooltip:kr:Accomplished",
  },
  NEEDS_ATTENTION: {
    defaultMessage:
      "If one of your Key Results need attention, the status for the entire Objective will be “needs attention” as well.",
    id: "objective:status:tooltip:kr:NeedsAttention",
  },
  NO_STATUS: {
    defaultMessage:
      "Since none of your Key Results currently have been started, your Objective’s status is set to “no status.",
    id: "objective:status:tooltip:kr:NoStatus",
  },
  OFF_TRACK: {
    defaultMessage:
      "If one of your Key Results are off track, the status for the entire Objective will be “off track” as well.",
    id: "objective:status:tooltip:kr:OffTrack",
  },
  ON_TRACK: {
    defaultMessage:
      "All your Key Results are on track; that means your Objective is on track too.",
    id: "objective:status:tooltip:kr:OnTrack",
  },
});

export const useObjectiveStatusTooltip = ({
  closingNote,
  progressDriver,
  status,
}: ObjectiveStatusObjective): string | undefined => {
  const intl = useIntl();

  if (isSet(closingNote)) {
    return intl.formatMessage(messagedClosed[closingNote.status]);
  }

  const progressAO = progressDriver === ProgressDriver.AlignedObjectives;

  const messages = progressAO ? messagesAO : messagesKR;
  return intl.formatMessage(messages[status as OkrCommitStatus]);
};
