import { singletonHook } from "react-singleton-hook";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";

export const isDevEnvironment = () =>
  process.env.NODE_ENV === "development" ||
  // can be undefined server-side
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  location?.href.startsWith("https://deploy-preview");

type UseDevModeHook = {
  devMode: number;
  disableDevMode: () => void;
  setDevMode: (value: number | ((val: number) => number)) => void;
};

const initialState: UseDevModeHook = {
  devMode: 0,
  disableDevMode: () => undefined,
  setDevMode: () => undefined,
};

const useDevModeHook = (): UseDevModeHook => {
  const [devMode, setDevMode] = useLocalStorage(
    "devMode",
    isDevEnvironment() ? 1 : 0
  );
  const disableDevMode = () => setDevMode(0);

  return { devMode, disableDevMode, setDevMode };
};

/**
 * singleton hook for private mode via local storage.
 * returns boolean state, toggle function and setter function.
 * @see {@link useToggle}
 */
export const useDevMode = singletonHook(initialState, useDevModeHook);
