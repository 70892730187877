import { StrategicPillar, useGetStrategicPillarsQuery } from "@graphql";
import { useMemo } from "react";
import { mapEdges } from "utils/mapEdges";

type Pillars = Pick<StrategicPillar, "name" | "id" | "mapIndex">;
export type UseStrategicPillarsHook = Pillars[] | undefined;

export const useStrategicPillars = (): UseStrategicPillarsHook => {
  const { data } = useGetStrategicPillarsQuery({
    fetchPolicy: "cache-and-network",
  });
  return useMemo(() => {
    const strategicPillars = mapEdges(data?.allStrategicPillars.edges);
    return strategicPillars;
  }, [data]);
};
