import classNames from "clsx";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import {
  ScrollDirection,
  useScrollElement,
} from "hooks/useScrollElement/useScrollElement";
import { CategorySelectItem } from "../CategorySelect";

type Props = {
  categories: {
    items: CategorySelectItem[];
    name: string;
  }[];
  selectedCategory: string;
  toggleSelectedCategory: (category: string) => void;
};

export const CategorySlider = ({
  categories,
  selectedCategory,
  toggleSelectedCategory,
}: Props): JSX.Element => {
  const categoryContainer = useRef<HTMLDivElement>(null);
  const [scrollCategories, canScrollLeft, canScrollRight] =
    useScrollElement(categoryContainer);

  return (
    <div className="py-2" id="category-select-categories">
      <span className="pl-4 text-xs">
        <FormattedMessage
          defaultMessage="Filter by category"
          id="categorySelect:filterBy:title"
        />
      </span>
      <div className="grid grid-cols-12 border-b px-2 border-slate-300">
        <IconButton
          data-cy="M4Icy0MXewpxL0K3ZBmMf"
          className="col-span-1 hover:bg-transparent disabled:text-slate-300"
          disabled={!canScrollLeft}
          name="chevron_left"
          onClick={() => scrollCategories(ScrollDirection.LEFT, 50)}
        />
        <div
          ref={categoryContainer}
          className="w- col-span-10 flex overflow-x-hidden py-2"
        >
          {categories.map((category) => (
            <button
              data-cy="zRPPhwl4rpTh4kkwD4ezj"
              key={category.name}
              className={classNames(
                "ml-2 rounded px-2 py-1",
                "text-xs text-slate-600 bg-slate-200 hover:bg-blue-200",
                "cursor-pointer whitespace-nowrap focus:outline-none",
                { "bg-blue-200": selectedCategory === category.name }
              )}
              onClick={() => toggleSelectedCategory(category.name)}
              type="button"
            >
              {category.name}
            </button>
          ))}
        </div>
        <IconButton
          data-cy="YPq3GZKs0RPlO4SOlQteZ"
          className="col-span-1 hover:bg-transparent disabled:text-slate-300"
          disabled={!canScrollRight}
          name="chevron_right"
          onClick={() => scrollCategories(ScrollDirection.RIGHT, 50)}
        />
      </div>
    </div>
  );
};
