import { useField } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { useLanguageOptions } from "hooks/useLanguageOptions/useLanguageOptions";

const Select = makeSelect<Option>();

type Option = {
  id: string;
  label: string;
};

type Props = {
  className?: string;
  "data-cy"?: string;
  name: string;
};

export const UserLanguageField = ({
  name,
  "data-cy": dataCy,
  className,
}: Props): JSX.Element => {
  const languageOptions = useLanguageOptions();
  const options = languageOptions.map(({ value, ...rest }) => ({
    id: value,
    ...rest,
  }));
  const [field, , helpers] = useField<string | null>(name);
  const selected = options.find((x) => x.id === field.value) ?? null;

  return (
    <Select.Root
      className={className}
      data-cy={dataCy}
      onChange={(opt) => helpers.setValue(opt?.id ?? null)}
      value={selected}
    >
      <Select.Trigger>
        <Select.Value innerClass="w-full">
          {selected?.label ?? (
            <FormattedMessage defaultMessage="Select..." id="724CrE" />
          )}
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown className="divide-y divide-slate-300">
        <Select.Options>
          <For each={options} fallback={<SelectFallback />}>
            {(option) => (
              <Select.Option key={option.id} value={option}>
                {option.label}
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
