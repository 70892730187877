import { each, filter, keyBy } from "lodash";
import { ReportKpi } from "common/progressReports/Kpi";
import { Filter, FilterState } from "./types";

export const selectedIncludingSubgroups = (selected: any) =>
  selected.flatMap((selected: any) => {
    const subgroupIds = selected.subGroups?.map(({ id }: any) => id) ?? [];
    return [selected.id, ...subgroupIds];
  });

export const filterKpis = (
  filterState: FilterState,
  filteredGoals: ReportKpi[],
  filters: Filter[]
) => {
  each(filterState.filters, ({ filterName, selected }) => {
    const filtersByName = keyBy(filters, "filterName");

    const applyFilter = filtersByName[filterName]?.applyFilter;
    if (!applyFilter || !selected.length) return;
    filteredGoals = filter(filteredGoals, (goal) => {
      return applyFilter(goal, selected);
    });
  });
  return filteredGoals;
};

export const getSubGoalFilter = (includeSubGoals: boolean) => ({
  data: includeSubGoals ? [{ id: "YES", name: "Yes" }] : [],
  filterName: "includeSubGoals",
  multiple: false,
  name: "Include Sub-Goals",
  selected: includeSubGoals ? [{ id: "YES", name: "Yes" }] : [],
  type: "checkbox",
});
