import React, { ComponentPropsWithRef } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { twClass } from "utils/twClass";

type InheritedButtonProps = Omit<
  ComponentPropsWithRef<typeof Button>,
  "variant" | "icon"
>;
type Props = InheritedButtonProps & {
  updateDue?: boolean;
};

export const UpdateButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ updateDue = false, className, ...rest }, ref) => {
    if (updateDue) {
      return (
        <Button
          ref={ref}
          variant="normal-outline"
          icon="sync"
          iconSize="md"
          className={twClass("whitespace-nowrap", className)}
          {...rest}
        >
          <FormattedMessage defaultMessage="Update due" id="b/QBNs" />
        </Button>
      );
    }

    return (
      <Button ref={ref} variant="ghost" {...rest}>
        <FormattedMessage defaultMessage="Update" id="BWpuKl" />
      </Button>
    );
  }
);

UpdateButton.displayName = "UpdateButton";
