import {
  UserAvatarFragment,
  UserDefaultFragment,
  UserJobFragment,
} from "@graphql";
import { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { Avatar } from "common/avatar";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { Dropdown } from "common/overlay/Dropdown";
import {
  PeopleManagementSidebarDropdown,
  PeopleManagementSidebarTabBar,
  SidebarCheckIns,
} from "common/peopleManagement";
import { PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS } from "common/peopleManagement/Sidebar/Navigation/PeopleManagementSidebarTabBar";
import {
  PeopleManagementTabOption,
  SidebarUserOptions,
  useContextualSidebarTabs,
} from "hooks/useContextualSidebarTabs/useContextualSidebarTabs";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";
import { PerformanceReviewSidebarKpis } from "modules/performanceReview/PerformanceReviewCompleteSidebar/PerformanceReviewSidebarKpis/PerformanceReviewSidebarKpis";
import { PerformanceReviewSidebarKudos } from "modules/performanceReview/PerformanceReviewCompleteSidebar/PerformanceReviewSidebarKudos/PerformanceReviewSidebarKudos";
import { PerformanceReviewSidebarObjectives } from "modules/performanceReview/PerformanceReviewCompleteSidebar/PerformanceReviewSidebarObjectives/PerformanceReviewSidebarObjectives";
import { OneOnOneSelectUserOption } from "./OneOnOneMeetingItemsField/OneOnOneMeetingItemsUserSelect";

type OneOnOneMeetingUser = UserDefaultFragment &
  UserAvatarFragment &
  UserJobFragment;
type Props = {
  attendee: OneOnOneMeetingUser;
  organizer: OneOnOneMeetingUser;
  seriesId: string;
};

export const OneOnOneCompleteSidebar = ({
  attendee,
  organizer,
  seriesId,
}: Props): JSX.Element => {
  const { tab, handleTabChange } = useContextualSidebarTabs(seriesId);
  const { show: showKudos } = useFeatureAccess(Feature.kudos);
  const [activeUser, setActiveUser] = useLocalStorage<SidebarUserOptions>(
    `contextualSidebarUser-${seriesId}`,
    SidebarUserOptions.ATTENDEE
  );
  const sidebarDiv = useRef<HTMLDivElement>(null);

  const [sidebarWidth, setSidebarWidth] = useState<null | number>(null);

  useEffect(() => {
    if (!sidebarDiv.current) return;
    const resizeObs = new ResizeObserver(() => {
      setSidebarWidth(
        sidebarDiv.current?.getBoundingClientRect().width ?? null
      );
    });
    resizeObs.observe(sidebarDiv.current);
    return () => resizeObs.disconnect();
  }, []);

  const selectedUser =
    activeUser === SidebarUserOptions.ATTENDEE ? attendee : organizer;

  const userText = (
    <div className="flex items-center space-x-1.5">
      <Show when={!isNil(selectedUser.avatar)}>
        <Avatar size="extraSmall" url={selectedUser.avatar} />
      </Show>
      <span>{selectedUser.fullName}</span>
    </div>
  );

  const button = (
    <IconButton
      className="flex-row-reverse gap-0 px-0 text-base font-semibold text-slate-800"
      name="keyboard_arrow_down"
      size="xl"
    >
      {userText}
    </IconButton>
  );

  const sidebarPages = [
    PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS.GOALS,
    PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS.CHECK_INS,
    ...(showKudos ? [PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS.KUDOS] : []),
  ];

  // show tab bar if greater than 300px
  const showTabBar = sidebarWidth ? sidebarWidth > 300 : true;

  return (
    <div ref={sidebarDiv} className="flex flex-col">
      <div className="sticky top-0 z-modal-prio bg-white">
        <Dropdown>
          <div className="w-full border-b px-5 py-2.5 bg-white border-slate-300">
            <Dropdown.Trigger>{button}</Dropdown.Trigger>
            <Dropdown.Content className="space-y-1" placement="bottom-left">
              <OneOnOneSelectUserOption
                isActive={attendee.id === selectedUser.id}
                onChange={() => setActiveUser(SidebarUserOptions.ATTENDEE)}
                user={attendee}
              />
              <OneOnOneSelectUserOption
                isActive={organizer.id === selectedUser.id}
                onChange={() => setActiveUser(SidebarUserOptions.ORGANIZER)}
                user={organizer}
              />
            </Dropdown.Content>
          </div>
        </Dropdown>
      </div>
      <div className="py-2">
        <Show
          fallback={
            <PeopleManagementSidebarDropdown
              handleTabChange={handleTabChange}
              options={sidebarPages}
              tab={tab}
            />
          }
          when={showTabBar}
        >
          <PeopleManagementSidebarTabBar
            handleTabChange={handleTabChange}
            options={sidebarPages}
            tab={tab}
          />
        </Show>
        <div>
          <Show when={tab === PeopleManagementTabOption.GOALS}>
            <PerformanceReviewSidebarKpis user={selectedUser} />
            <PerformanceReviewSidebarObjectives userId={selectedUser.id} />
          </Show>
          <Show when={tab === PeopleManagementTabOption.KUDOS}>
            <PerformanceReviewSidebarKudos userId={selectedUser.id} />
          </Show>
          <Show when={tab === PeopleManagementTabOption.CHECK_INS}>
            <SidebarCheckIns user={selectedUser} />
          </Show>
        </div>
      </div>
    </div>
  );
};
