import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";

export const TextOverflowTooltip = ({
  children,
  tooltip,
}: PropsWithChildren & { tooltip?: ReactNode }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current;
    if (element) {
      setIsOverflown(element.scrollWidth > element.clientWidth);
    }
  }, [children, ref]);

  return (
    <WithTooltip
      tooltip={isOverflown ? tooltip ?? children : null}
      className="grow min-w-0"
    >
      <div ref={ref} className="truncate">
        {children}
      </div>
    </WithTooltip>
  );
};
