import PropTypes from "prop-types";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'rc-s... Remove this comment to see the full error message
import ReactSlider, { createSliderWithTooltip } from "rc-slider";
import React from "react";
import styled from "styled-components";
import { colorTheme } from "constants/colorTheme";
import { CustomHandle } from "../Range/CustomHandle/CustomHandle";

export const StyledRange = styled(createSliderWithTooltip(ReactSlider.Range))`
  padding: 8px 0 !important;
  height: 22px !important;
  position: relative;

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    height: 5px;
    border-radius: 20px;
  }

  .rc-slider-step {
    position: absolute;
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: ${colorTheme.slate[300]};
  }

  .rc-slider-track {
    position: absolute;
    left: 0;
    background-color: ${({ color }) => color};
  }

  .rc-slider-handle {
    position: absolute;
    background-color: ${colorTheme.white};
    width: 16px;
    height: 16px;
    cursor: grab;
    border-radius: 50%;
    margin-top: -6px;
    border: 1px solid ${colorTheme.slate[200]};
    box-shadow: 0 1px 2px ${colorTheme.slate[500]};

    &:hover,
    &:active {
      box-shadow: 0 1px 2px ${colorTheme.blue[500]};
      border: none;
    }

    &:active {
      cursor: grabbing;
    }
  }
`;

export class Slider extends React.Component {
  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onAfterChange' does not exist on type 'R... Remove this comment to see the full error message
      onAfterChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      onChange,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      value,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'delta' does not exist on type 'Readonly<... Remove this comment to see the full error message
      delta,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'color' does not exist on type 'Readonly<... Remove this comment to see the full error message
      color,
      ...other
    } = this.props;
    const fullBarDelta = delta >= value;
    const oldProgress = delta > 0 ? value - delta : value;
    return (
      <StyledRange
        color={color}
        handle={CustomHandle}
        handleStyle={[
          { display: "none" },
          { display: "none" },
          { display: "block" },
        ]}
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
        onAfterChange={(val) => onAfterChange(val[2])}
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
        onChange={(val) => onChange(val[2])}
        pushable={0}
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
        tipFormatter={(v) => `${v}%`}
        tipProps={{
          overlayStyle: { zIndex: 10 },
        }}
        trackStyle={[
          {
            backgroundColor: color,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
          {
            backgroundColor: colorTheme.green[500],
            ...(!fullBarDelta && { borderTopLeftRadius: 0 }),
            ...(!fullBarDelta && { borderBottomLeftRadius: 0 }),
          },
        ]}
        value={[0, fullBarDelta ? 0 : oldProgress, value]}
        {...other}
      />
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Slider.propTypes = {
  color: PropTypes.string,
  delta: PropTypes.number,
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.number.isRequired,
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Slider.defaultProps = {
  color: colorTheme.green[200],
  delta: 0,
  onAfterChange: () => {},
  onChange: () => {},
};
