import { ClosingNoteStatus } from "@graphql";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import React, { RefObject, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons/Button/Button";
import { Label } from "common/inputs/Label/Label";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { FormClosingNoteStatusSelect } from "../FormClosingNoteStatusSelect/FormClosingNoteStatusSelect";
import { FormTextArea } from "../FormTextArea/FormTextArea";
import { RatePickerField } from "../RatePickerField/RatePickerField";

type QuestionValues = {
  answer: string;
  isRequired: boolean;
  text: string;
};

export type CloseObjFormValues = {
  executionRating: number | null;
  id: string | null;
  objective: string;
  questions: QuestionValues[];
  status: ClosingNoteStatus;
};

type Props = {
  className?: string;
  formRef: RefObject<FormikProps<CloseObjFormValues>>;
  initialValues: CloseObjFormValues;
  onCancel: () => void;
  onSubmit: (
    values: CloseObjFormValues,
    actions: FormikHelpers<CloseObjFormValues>
  ) => void;
};

export const CloseObjForm = ({
  className,
  formRef,
  initialValues,
  onSubmit,
  onCancel,
}: Props): JSX.Element => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        executionRating: Yup.number().nullable().min(1).max(5),
        questions: Yup.array().of(
          Yup.object().shape({
            answer: Yup.string().when("isRequired", {
              is: true,
              then: (schema) =>
                schema.required(
                  intl.formatMessage({
                    defaultMessage: "Required",
                    id: "global:required",
                  })
                ),
            }),
          })
        ),
      }),
    []
  );

  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm, values }) => (
          <Form>
            <div className="space-y-5">
              <div className="space-y-1">
                <Label>
                  <FormattedMessage
                    defaultMessage="How would you rate your execution?"
                    id="closeObjectiveForm:executionRating:label"
                  />
                </Label>
                <RatePickerField name="executionRating" />
              </div>
              <div className="space-y-1">
                <Label>
                  <FormattedMessage
                    defaultMessage="Closing status"
                    id="closeObjectiveForm:closingStatus:label"
                  />
                </Label>
                <Field component={FormClosingNoteStatusSelect} name="status" />
              </div>
              {values.questions.map((question, i) => (
                // Reason: when this list is first loaded the number of questions won't change.
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="space-y-1">
                  <Field
                    component={FormTextArea}
                    label={question.text}
                    name={`questions.${i}.answer`}
                    optional={!question.isRequired}
                  />
                  <ErrorMessage
                    className="text-sm text-red-500"
                    component="div"
                    name={`questions.${i}.answer`}
                  />
                </div>
              ))}
            </div>
            <ModalFooter>
              <div className="flex justify-end space-x-4">
                <Button
                  data-cy="4tBX2DaDVOdIqzQGuOCT_"
                  disabled={isSubmitting}
                  onClick={onCancel}
                  variant="ghost"
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="global:cancel"
                  />
                </Button>
                <Button
                  data-cy="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  <FormattedMessage defaultMessage="Save" id="global:save" />
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};
