import { defineMessages } from "react-intl";

export const messages = defineMessages({
  name: {
    defaultMessage: "Name",
    id: "timeframe:form:name:label",
  },
  startDate: {
    defaultMessage: "Start Date",
    id: "timeframe:form:startDate:label",
  },
  submit: {
    defaultMessage: "Submit",
    id: "timeframe:form:submit",
  },
  timeframeCount: {
    defaultMessage: "Create more timeframes",
    id: "timeframe:form:timeframeCount:label",
  },
});
