import { map } from "lodash";
import moment from "moment";
import React, { RefObject } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from "react-datepicker";
import { IconButton } from "common/buttons/IconButton/IconButton";
import {
  Container,
  Header,
  HeaderMonth,
  HeaderMonthName,
  HeaderYearName,
  MonthItem,
  Months,
  SelectText,
  Spacer,
  YearItem,
  Years,
} from "common/inputs/DatePicker/DatePicker.styles";
import { onClickOutside } from "common/misc/onClickOutside/onClickOutside";
import { twClass } from "utils/twClass";

type ForwardingProps = ReactDatePickerProps;

type Props = {
  className?: string;
  date: Date;
  datePickerRef?: RefObject<HTMLDivElement> | null;
  id?: string;
  months?: string[];
  onChange: (date: Date) => void;
  onClickOutside?: () => void;
} & ForwardingProps;

type Mode = "date" | "month" | "year";

type State = {
  mode: Mode;
  month: number;
  year: number;
};

class DatePickerDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: "date",
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      month: props.date ? props.date.getMonth() : new Date().getMonth(),
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      year: props.date ? props.date.getFullYear() : new Date().getFullYear(),
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleChangeMode = this.handleChangeMode.bind(this);

    this.handleChangeMonth = this.handleChangeMonth.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);

    this.handleBack = this.handleBack.bind(this);

    this.renderHeader = this.renderHeader.bind(this);
  }

  handleChange(date: Date) {
    const { onChange } = this.props;
    this.setState({ month: date.getMonth(), year: date.getFullYear() });
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    onChange?.(date);
  }

  handleChangeMode(mode: Mode) {
    this.setState({ mode });
  }

  handleChangeMonth(month: number) {
    this.setState({ mode: "date", month });
  }

  handleChangeYear(year: number) {
    this.setState({ mode: "date", year });
  }

  handleBack() {
    this.setState({ mode: "date" });
  }

  renderHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) {
    const { months = moment.months() } = this.props;
    const { mode, month, year } = this.state;
    let years = [-7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7];
    years = map(years, (y) => y + year);

    return (
      <Header mode={mode}>
        {mode === "date" && (
          <>
            <IconButton
              data-cy="LEmYm3H01P6h47CrThUMu"
              disabled={prevMonthButtonDisabled}
              name="keyboard_arrow_left"
              onClick={decreaseMonth}
            />
            <HeaderMonth>
              <HeaderMonthName
                data-cy="lD0RI-nTRXnsOVRmwZQLM"
                onClick={() => this.handleChangeMode("month")}
              >
                {moment(date).format("MMMM")}
              </HeaderMonthName>
              <HeaderYearName
                data-cy="ujt4TGNKE0aNHzE5hHIXd"
                onClick={() => this.handleChangeMode("year")}
              >
                {moment(date).format("YYYY")}
              </HeaderYearName>
            </HeaderMonth>
            <IconButton
              data-cy="mf17zGubdgeVaitwciT1M"
              disabled={nextMonthButtonDisabled}
              name="keyboard_arrow_right"
              onClick={increaseMonth}
            />
          </>
        )}

        {mode === "month" && (
          <>
            <SelectText>
              <IconButton
                data-cy="yA6RWg3lfyAjt0Kcur_OH"
                name="keyboard_arrow_left"
                onClick={this.handleBack}
              />
              Select Month
              <Spacer />
            </SelectText>
            <Months>
              {map(months, (m, idx) => (
                <MonthItem
                  key={m}
                  data-cy="R9ErZk4gwZI7A-fa9wnrS"
                  onClick={() => {
                    this.handleChangeMonth(idx);
                    changeMonth(idx);
                  }}
                  selected={month === idx}
                >
                  {m}
                </MonthItem>
              ))}
            </Months>
          </>
        )}

        {mode === "year" && (
          <>
            <SelectText>
              <IconButton
                data-cy="3yvTM_KhGPp07mVyW_T9G"
                name="keyboard_arrow_left"
                onClick={this.handleBack}
              />
              Select Year
              <Spacer />
            </SelectText>
            <Years>
              {map(years, (y) => (
                <YearItem
                  key={y}
                  data-cy="kf47i_iEg62FL37w5okAe"
                  onClick={() => {
                    this.handleChangeMonth(y);
                    changeYear(y);
                  }}
                  selected={date.getFullYear() === y}
                >
                  {y}
                </YearItem>
              ))}
            </Years>
          </>
        )}
      </Header>
    );
  }

  render() {
    const {
      date,
      className = "",
      datePickerRef = null,
      onBlur,
      ...rest
    } = this.props;
    const { mode } = this.state;

    return (
      <Container
        ref={datePickerRef}
        // The 380px min height here is to account for months where we
        // display 6 weeks instead of 5 so that it doesn't stutter when
        // moving between months.
        className={twClass("min-h-[380px]", className)}
        data-testid="date-picker"
        hideDates={mode !== "date"}
      >
        <ReactDatePicker
          inline
          selected={date}
          shouldCloseOnSelect={false}
          {...rest}
          onBlur={onBlur}
          onChange={this.handleChange}
          renderCustomHeader={this.renderHeader}
        />
      </Container>
    );
  }
}

export const DatePicker = onClickOutside(DatePickerDetails);
