import { StrategicPillar, useGetStrategicPillarsQuery } from "@graphql";
import { Derive } from "@shoooe/derive";
import { useField } from "formik";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { FormControl } from "common/form/FormControl/FormControl";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectRoot } from "common/inputs/Select/SelectRoot/SelectRoot";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { mapEdges } from "utils/mapEdges";

const Select = makeSelect<SelectStrategicPillar>();

type SelectStrategicPillar = Derive<
  StrategicPillar,
  {
    id: true;
    name: true;
  }
>;

type SelectProps = React.ComponentProps<typeof SelectRoot>;

type Props = Omit<SelectProps, "children" | "onChange" | "value"> & {
  label?: ReactNode;
  name: string;
  onAfterChange?: (c: SelectStrategicPillar | null) => void;
  optional?: boolean;
  tooltip?: ReactNode;
};

export const StrategicPillarField = ({
  label,
  name,
  onAfterChange,
  optional,
  tooltip,
  ...selectProps
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField(name);

  const { data } = useGetStrategicPillarsQuery();
  const strategicPillars = mapEdges(data?.allStrategicPillars.edges);
  const selectedPillar =
    strategicPillars.find((sp) => sp.id === field.value) ?? null;

  const handleChange = (strategicPillar: SelectStrategicPillar | null) => {
    helpers.setValue(strategicPillar?.id ?? null);
    onAfterChange?.(strategicPillar);
  };

  return (
    <FormControl
      errorText={meta.error}
      id={name}
      label={label}
      optional={optional}
    >
      <Select.Root
        onChange={handleChange}
        value={selectedPillar}
        {...selectProps}
      >
        <Select.Trigger>
          <Select.Value clearable={optional}>
            <WithTooltip tooltip={tooltip}>
              <span>
                {selectedPillar?.name ?? (
                  <FormattedMessage defaultMessage="Select..." id="724CrE" />
                )}
              </span>
            </WithTooltip>
          </Select.Value>
        </Select.Trigger>
        <Select.Dropdown>
          <Select.Options>
            <For each={strategicPillars}>
              {(pillar) => (
                <Select.Option value={pillar}>{pillar.name}</Select.Option>
              )}
            </For>
          </Select.Options>
        </Select.Dropdown>
      </Select.Root>
    </FormControl>
  );
};
