import React from "react";
import { defineMessage, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { IconSize } from "common/misc/types";

const tooltip = defineMessage({
  defaultMessage: "Help & Support",
  id: "navbar:help",
});

interface Props {
  size?: keyof typeof IconSize;
}

export const HelpCenterButton = ({ size }: Props): JSX.Element => {
  const intl = useIntl();
  const { toggleHelpCenter } = useHelpCenter();

  return (
    <IconButton
      size={size}
      data-cy="supportCenterDropdown"
      name="help_outline"
      onClick={toggleHelpCenter}
      tooltip={intl.formatMessage(tooltip)}
    />
  );
};
