import {
  namedOperations,
  useGetCadenceTimeframesQuery,
  useUpsertCadenceMutationMutation,
} from "@graphql";
import { FormikHelpers } from "formik";
import moment from "moment";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  CadenceForm,
  Values as CadenceFormValues,
} from "common/form/CadenceForm/CadenceForm";
import { Modal } from "common/overlay/Modal/Modal";
import { WidgetLoading } from "common/placeholders/WidgetLoading/WidgetLoading";
import { CHANGE_DEFAULT_GOAL_UPDATE_CYCLE } from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { Branch } from "utils/utils";

type Cadence = {
  goalUpdateCycle: string;
  id: string;
  name: string;
  startDate: string;
};

type Props = {
  cadence: Cadence;
  onRequestClosed: () => void;
  open: boolean;
};

const messages = defineMessages({
  success: {
    defaultMessage: "Cadence was edited.",
    id: "cadence:edit:success",
  },
});

export const EditCadenceModal = ({
  onRequestClosed,
  cadence,
  open,
}: Props): JSX.Element => {
  const intl = useIntl();
  const user = useCurrentUser();
  const [editCadence] = useUpsertCadenceMutationMutation({
    refetchQueries: [namedOperations.Query.getCadenceList],
  });

  const { loading, data } = useGetCadenceTimeframesQuery({
    variables: {
      cadence: cadence.id,
    },
  });

  const timeframes = (data?.timeframes.edges ?? []).map((edge) => edge.node);

  const initialValues = {
    goalUpdateCycle: cadence.goalUpdateCycle,
    name: cadence.name,
    startDate: timeframes[0]?.startDate ?? cadence.startDate,
  };

  const onSubmit = (
    values: CadenceFormValues,
    actions: FormikHelpers<CadenceFormValues>
  ) => {
    actions.setSubmitting(true);
    const { name, startDate, goalUpdateCycle } = values;
    editCadence({
      variables: {
        input: {
          goalUpdateCycle,
          id: cadence.id,
          name,
          startDate: moment(startDate).format("YYYY-MM-DD"),
        },
      },
    }).then((response) => {
      actions.setSubmitting(false);
      const { hasError } = handleErrors(
        response,
        response.data?.upsertCadence?.errors
      );
      if (hasError) return;
      toast.success(intl.formatMessage(messages.success));

      if (initialValues.goalUpdateCycle !== goalUpdateCycle) {
        track(CHANGE_DEFAULT_GOAL_UPDATE_CYCLE, {
          cadenceId: cadence.id,
          frequency: goalUpdateCycle,
          userId: user?.id,
        });
      }

      onRequestClosed();
    });
  };

  return (
    <Modal
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title={intl.formatMessage({
        defaultMessage: "Edit cadence",
        id: "cadence:edit",
      })}
    >
      <Branch
        left={<WidgetLoading />}
        right={
          <CadenceForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            timeframes={timeframes}
          />
        }
        test={loading}
      />
    </Modal>
  );
};
