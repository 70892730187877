import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons/Button/Button";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { SupportTip } from "common/support";
import {
  Timeframe as TimeframeTableTimeframe,
  TimeframeTable,
} from "common/timeframe/TimeframeTable/TimeframeTable";
import { useGoalUpdateFrequencyOptions } from "hooks/useGoalUpdateFrequencyOptions/useGoalUpdateFrequencyOptions";
import { messages } from "../../cadence/messages";
import { FormDatePicker } from "../FormDatePicker/FormDatePicker";
import { FormInput } from "../FormInput/FormInput";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().required("Required"),
});

type Timeframe = TimeframeTableTimeframe;

export type Values = {
  goalUpdateCycle: string;
  name: string;
  startDate: string;
};

interface Props {
  initialValues: Values;
  onSubmit: (values: Values, actions: FormikHelpers<Values>) => void;
  timeframes: Timeframe[];
}

export const CadenceForm: React.FC<Props> = ({
  onSubmit,
  initialValues,
  timeframes,
}) => {
  const intl = useIntl();
  const goalUpdateFrequencyOptions = useGoalUpdateFrequencyOptions();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ isSubmitting, submitForm }) => (
        <Form>
          <Field
            autoFocus
            component={FormInput}
            data-cy="cadenceName"
            label={intl.formatMessage(messages.name)}
            name="name"
            placeholder={intl.formatMessage(messages.cadencePlaceholder)}
          />
          {timeframes.length > 0 && (
            <Field
              component={FormDatePicker}
              data-cy="startDate"
              label={intl.formatMessage(messages.startDate)}
              name="startDate"
            />
          )}
          <Field
            component={FormSelect}
            label={
              <span className="flex gap-1">
                {intl.formatMessage(messages.goalUpdateCycleLabel)}
                <SupportTip
                  description={intl.formatMessage({
                    defaultMessage:
                      "How often is the lead prompted to update goals in this cadence?",
                    id: "wRka4U",
                  })}
                />
              </span>
            }
            name="goalUpdateCycle"
            options={goalUpdateFrequencyOptions}
            placeholder={intl.formatMessage(messages.goalUpdateCycleLabel)}
          />
          {timeframes.length > 0 && (
            <>
              <hr className="my-4 border-slate-300" />
              <TimeframeTable
                showObjectiveCount
                timeframes={timeframes}
                title={intl.formatMessage(messages.timeframeTableTitle)}
              />
            </>
          )}
          <ModalFooter>
            <Button
              data-cy="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              {intl.formatMessage({
                defaultMessage: "Save",
                id: "global:save",
              })}
            </Button>
          </ModalFooter>
        </Form>
      )}
      validationSchema={schema}
    />
  );
};
