import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { ShowFeature } from "common/access/ShowFeature";
import { Show } from "common/controlFlow";
import { AdvancedFields } from "common/form/AdvancedFields/AdvancedFields";
import { FieldFocusWrapper } from "common/form/FieldFocusWrapper/FieldFocusWrapper";
import { FormObjectiveProgressDriver } from "common/form/FormObjectiveProgressDriver/FormObjectiveProgressDriver";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { UserMultiField } from "common/form/UserMultiField/UserMultiField";
import { ShowObjectiveTags } from "common/objective/ShowObjectiveTags/ShowObjectiveTags";
import { useObjectiveFormContext } from "common/objective/utils/useObjectiveFormContext/useObjectiveFormContext";
import { TagField } from "common/select/TagMultiSelect/TagField";
import { SupportTip } from "common/support";
import { useCompanySettings } from "hooks/useCompany/useCompany";
import { useGoalUpdateFrequencyOptions } from "hooks/useGoalUpdateFrequencyOptions/useGoalUpdateFrequencyOptions";
import { messages } from "../ObjectiveForm.messages";
import { ObjectiveFormValues } from "../ObjectiveForm.types";
import { ShowSelectObjectiveContributors } from "../ShowSelectObjectiveContributors/ShowSelectObjectiveContributors";

export const AdvancedSection = (): JSX.Element => {
  const intl = useIntl();
  const { setFocusedField } = useObjectiveFormContext();
  const { values } = useFormikContext<ObjectiveFormValues>();
  const goalUpdateFrequencyOptions = useGoalUpdateFrequencyOptions();
  const { flexibleObjectiveProgressDriver: progressDriveEnabled } =
    useCompanySettings();

  return (
    <AdvancedFields className="my-4 flex flex-col space-y-4">
      <ShowSelectObjectiveContributors
        name="contributors"
        setFocusedField={setFocusedField}
      >
        <UserMultiField
          autoFocus
          options={{ excludeViewOnly: true }}
          data-cy="objectiveContributors"
          data-testid="objectiveContributors"
          name="contributors"
          optional
        />
      </ShowSelectObjectiveContributors>

      <FieldFocusWrapper name="description" setFocusedField={setFocusedField}>
        <Field name="description">
          {({ field, form }: FieldProps) => (
            <FormTextArea
              data-cy="objectiveDescription"
              data-testid="objectiveDescription"
              field={field}
              form={form}
              label={intl.formatMessage(messages.objDescriptionLabel)}
              optional
              placeholder={intl.formatMessage(
                messages.objDescriptionPlaceholder
              )}
            />
          )}
        </Field>
      </FieldFocusWrapper>

      <FieldFocusWrapper
        name="goalUpdateCycle"
        setFocusedField={setFocusedField}
      >
        <Field name="goalUpdateCycle">
          {({ field, form }: FieldProps) => (
            <FormSelect
              field={field}
              form={form}
              label={
                <span className="flex gap-1">
                  {intl.formatMessage(messages.goalUpdateCycleLabel)}
                  <SupportTip
                    description={intl.formatMessage({
                      defaultMessage:
                        "Select a custom update frequency for this goal. " +
                        "Note: your default update frequency is based on timeframe settings.",
                      id: "objective:form:goalUpdateFrequency:tooltip",
                    })}
                  />
                </span>
              }
              options={goalUpdateFrequencyOptions}
            />
          )}
        </Field>
      </FieldFocusWrapper>

      <Show when={progressDriveEnabled}>
        <FormObjectiveProgressDriver disabled={values.private}>
          {({ ProgressDriverObjective, ProgressDriverResults }) => (
            <>
              <ProgressDriverResults name="progressDriver" />
              <ProgressDriverObjective name="progressDriver" />
            </>
          )}
        </FormObjectiveProgressDriver>
      </Show>

      <ShowObjectiveTags name="tags" setFocusedField={setFocusedField}>
        <TagField
          data-testid="objectiveTags"
          data-cy="objectiveTags"
          name="tags"
        />
      </ShowObjectiveTags>
    </AdvancedFields>
  );
};
