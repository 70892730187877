import * as Sentry from "@sentry/nextjs";
import { Scope } from "@sentry/nextjs";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { MODAL_TYPES } from "constants/index";
import { UNHANDLED_ERROR } from "constants/tracking";
import { showModal } from "legacy/actions/actions";
import { intercom, track } from "utils/tracker";
import { twClass } from "utils/twClass";

type Props = {
  children?: React.ReactNode;
};

export const ErrorBoundary = ({ children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const fallback = () => (
    <div className="p-8">
      <div
        className={twClass(
          "flex flex-col items-center justify-center",
          "absolute inset-0 p-20 text-center"
        )}
      >
        <h1>
          <FormattedMessage
            defaultMessage="Something went wrong."
            id="global:crash"
          />
        </h1>
        <div className="max-w-md">
          <FormattedMessage
            defaultMessage="Our team has already been notified. If you have a moment, please use the chat box to tell us what you did leading up to this to speed up our investigation. Thanks!"
            id="global:error:supportChat"
          />
        </div>
      </div>
    </div>
  );

  const onError = (error: Error, componentStack: string, eventId: string) => {
    if (error.message.includes("ChunkLoadError")) {
      dispatch(showModal(MODAL_TYPES.RELOAD_APP));
      return;
    }

    intercom()?.(
      "showNewMessage",
      `Link for internal purposes: https://sentry.io/organizations/perdoo/issues/?project=211416&query=+id%3A${eventId}\n\nHi there,\n\nI just ran into an unexpected issue in Perdoo. Please help!`
    );
    track(UNHANDLED_ERROR);
  };

  const beforeCapture = (scope: Scope) => {
    scope.setTag("source", "react");
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={fallback}
      onError={onError}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
