import { defineMessages } from "react-intl";

export const messages = defineMessages({
  favorites: {
    defaultMessage: "Favorites",
    id: "global:favorites",
  },
  myCompany: {
    defaultMessage: "My company",
    id: "global:myCompany",
  },
  myProfile: {
    defaultMessage: "My profile",
    id: "global:myProfile",
  },
});
