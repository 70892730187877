import {
  useGetCompanyForHookQuery,
  useGetCurrentUserInfoQuery,
} from "@graphql";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "common/authHelper";
import { useI18nContext } from "common/context/i18nContext";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { GlobalLoadingState } from "common/placeholders/GlobalLoadingState/GlobalLoadingState";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { useIsProtectedRoute } from "common/topLevel/useIsProtectedRoute/useIsProtectedRoute";
import { Lang } from "hooks/useLanguageOptions/useLanguageOptions";
import { trackBackendLogin } from "legacy/actions/actions";
import { identifyUser } from "utils/tracker";

type Props = {
  children: React.ReactNode;
};

/**
 * handles page-access based on authentication.
 */
export const AuthWrapper = ({ children }: Props): JSX.Element | null => {
  const { isCollapsed } = useContext(CollapsibleContext);
  const dispatch = useDispatch<any>();
  const { setLang } = useI18nContext();
  const isPublicRoute = !useIsProtectedRoute();
  const posthog = usePostHog();
  const router = useRouter();

  /**
   * when we refresh the token and retry the request inside apolloClient's onError,
   * the useQuery hook will modify the `data` field, but it will not cause AuthWrapper to rerender.
   * therefore, we cannot rely on `companyData` & `userData` to determine loading state and need to use our own state.
   */
  const [dataMissing, setDataMissing] = useState(false);

  const { data: companyData, error: companyError } = useGetCompanyForHookQuery({
    // we don't need authentication check on non-auth routes
    skip: isPublicRoute,
  });
  const { data: userData, error: userError } = useGetCurrentUserInfoQuery({
    // we don't want to persist cache after logging out
    fetchPolicy: "no-cache",
    // we don't need authentication check on non-auth routes
    skip: isPublicRoute,
  });

  const error = !!companyError || !!userError;

  /**
   * track login event.
   */
  useEffect(() => {
    const noData = !companyData || !userData;
    setDataMissing(noData);

    if (isPublicRoute || noData || !userData.me) {
      return;
    }

    dispatch(trackBackendLogin());
    identifyUser(userData.me, companyData.currentCompany);
    setLang(userData.me.language as Lang);

    if (posthog.__loaded) {
      if (posthog.isFeatureEnabled("session-recording")) {
        posthog.startSessionRecording();
      }
    }
  }, [companyData, userData]);

  // we don't need authentication check on non-auth routes
  if (isPublicRoute) return <>{children}</>;

  if (dataMissing) {
    return (
      <GlobalLoadingState isCollapsed={isCollapsed}>
        <SpinnerFit />
      </GlobalLoadingState>
    );
  }

  if (error) {
    auth.logout({ reason: "error in AuthWrapper", router });
    return null;
  }

  return <>{children}</>;
};
