import { Comment as GQLComment, useDeleteCommentMutation } from "@graphql";
import { Derive } from "@shoooe/derive";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getLinkByType } from "route-configs";
import { Avatar } from "common/avatar";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { Confirm } from "common/overlay/Confirm/Confirm";
import { objectTypes } from "constants/objects";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import defaultGravatar from "../../../../../public/images/default_user.png";
import { messages } from "../TimelineEvent.messages";

type DataProp = Derive<
  GQLComment,
  {
    content: true;
    id: true;
    user: { avatar: true; fullName: true; id: true };
  }
>;

type Props = {
  data: DataProp;
  fetchTimeline: () => void;
  getUserUrl: (id?: string) => string;
};

export const Comment = ({ getUserUrl, data, fetchTimeline }: Props) => {
  const currentUser = useCurrentUser();
  const { user, id } = data;
  const intl = useIntl();
  const canDelete = user && user.id === currentUser?.id;
  const [deleteComment] = useDeleteCommentMutation();

  const removeComment = () => {
    return deleteComment({
      variables: {
        input: {
          id,
        },
      },
    }).then(fetchTimeline);
  };

  return (
    <div className="flex flex-row px-0 py-3">
      <div className="w-8">
        <Avatar
          href={user ? getLinkByType(objectTypes.user, user.id) : undefined}
          url={user ? user.avatar : defaultGravatar.src}
        />
      </div>
      <div className="group flex-grow pl-2">
        <div className="flex justify-between">
          <div className="flex">
            <Anchor
              href={getUserUrl(user?.id)}
              text={
                user
                  ? user.fullName
                  : intl.formatMessage({
                      defaultMessage: "Unknown User",
                      id: "global:unknownUser",
                    })
              }
            />
            <div className="ml-2 text-sm font-normal text-slate-500">
              {moment(get(data, "createdDate")).fromNow()}
            </div>
            {canDelete && (
              <Confirm
                body={
                  <div className="mt-4 flex flex-col">
                    <FormattedMessage
                      defaultMessage="Are you sure you want to delete this? This cannot be undone."
                      id="global:deleteUpdate"
                    />
                    <div className="pointer-events-none mt-3 rounded-lg border border-solid p-3">
                      <div className="mx-0 my-2 flex justify-between">
                        <DangerousHTML html={get(data, "content")} />
                      </div>
                    </div>
                  </div>
                }
                button={
                  <span className="ml-2 hidden cursor-pointer text-sm group-hover:block">
                    <FormattedMessage
                      defaultMessage="Delete"
                      id="global:delete"
                    />
                  </span>
                }
                confirmText={intl.formatMessage({
                  defaultMessage: "Confirm",
                  id: "global:confirm",
                })}
                onConfirm={removeComment}
                size="sm"
                title={intl.formatMessage(messages.deleteComment)}
              />
            )}
          </div>
        </div>
        <div className="mx-0 my-2 flex justify-between">
          <DangerousHTML html={get(data, "content")} />
        </div>
      </div>
    </div>
  );
};
