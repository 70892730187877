import { omit } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { useObjectiveContext } from "common/context/objectiveContext";
import { mapEdges } from "utils/mapEdges";
import { useOrderedResults } from "../../hook/useOrderedResults";

interface Props {
  resultId: string;
}

export const ResultNavigation = ({ resultId }: Props) => {
  const router = useRouter();
  const objective = useObjectiveContext();
  const totalResults = objective.results?.edgeCount;
  const sequencedResults = useOrderedResults(
    mapEdges(objective.results?.edges)
  );
  const index = sequencedResults.map((result) => result.id).indexOf(resultId);

  const handleResultChange = (resultId?: string) => {
    router.replace({
      query: {
        ...router.query,
        rId: resultId,
      },
    });
  };

  const handleNextResult = () => {
    const result = sequencedResults[index + 1];
    handleResultChange(result?.id);
  };

  const handlePreviousResult = () => {
    const result = sequencedResults[index - 1];
    handleResultChange(result?.id);
  };

  return (
    <div className="flex h-16 p-5 w-full items-center divide-x">
      <div className="pr-4">
        <Button
          icon="arrow_back"
          data-cy="backKeyResult"
          variant="ghost"
          onClick={() => router.replace({ query: omit(router.query, "rId") })}
          size="small"
        >
          <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
        </Button>
      </div>

      <div className="pl-4 flex items-center space-x-2">
        <div className="flex items-center">
          <IconButton
            disabled={index == 0}
            onClick={handlePreviousResult}
            data-cy="previousResult"
            name="arrow_back_ios_new"
            ghost
            size="md"
            className="rotate-90"
          />
          <IconButton
            disabled={index + 1 === totalResults}
            data-cy="nextResult"
            name="arrow_back_ios_new"
            ghost
            size="md"
            className="-rotate-90"
            onClick={handleNextResult}
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="{index}/{total} results"
            id="UCOrJ3"
            values={{
              index: index + 1,
              total: totalResults,
            }}
          />
        </div>
      </div>
    </div>
  );
};
