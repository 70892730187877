import { mergeAttributes } from "@tiptap/core";
import TipTapMention from "@tiptap/extension-mention";
import { PluginKey } from "prosemirror-state";

export const SlackEmojiPluginKey = new PluginKey("inlineEmoji");

/**
 * inline emojis that can be inserted using ":". supports native and custom slack emojis.
 */
export const InlineEmoji = TipTapMention.extend({
  name: "inlineEmoji",
  renderHTML({ node, HTMLAttributes }) {
    // for some reason plugin only passes the `id` field,
    // so we need to figure out which type of emoji to render based on value.
    const emojiContent = node.attrs.id;
    const isImage = emojiContent.startsWith("http");

    // custom slack emojis
    if (isImage)
      return [
        "span",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          "data-type": this.name,
        }),
        [
          "img",
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
            class: "w-5 h-5",
            "data-type": this.name,
            src: emojiContent,
          }),
        ],
      ];

    // native emojis, rendered as text
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": this.name,
      }),
      `${emojiContent}`,
    ];
  },
});
