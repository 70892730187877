import React, { ChangeEvent, ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { twClass } from "utils/twClass";
import { Input } from "../../inputs/Input/Input";

type ConfirmSafeProps = {
  body?: React.ReactNode;
  entity?: string;
  loading?: boolean;
  onConfirm: () => void;
  topPrio?: boolean;
  trigger: ReactElement;
};

export const ConfirmDeletePermanently = ({
  body,
  loading = false,
  topPrio = false,
  onConfirm,
  trigger,
  entity,
}: ConfirmSafeProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [inputWord, setInputWord] = useState("");
  const intl = useIntl();

  const safeWord = intl.formatMessage({
    defaultMessage: "delete forever",
    id: "8rq2vR",
  });

  const open = () => {
    setModalOpen(true);
  };
  const close = () => {
    setModalOpen(false);
  };
  const handleChangeSafeWord = (e: ChangeEvent<HTMLInputElement>) => {
    setInputWord(e.target.value);
  };
  const confirm = () => {
    close();
    onConfirm();
  };

  const renderModal = () => {
    return (
      <Modal
        backdropClass={twClass({
          "!z-modal-top-prio-backdrop": topPrio,
        })}
        className={twClass({
          "!z-modal-top-prio mt-[10%]": topPrio,
        })}
        isOpen={modalOpen}
        onClose={close}
        size="sm"
        title={
          <FormattedMessage
            defaultMessage="Permanently delete this {entity}?"
            id="qq/35c"
            values={{
              entity,
            }}
          />
        }
      >
        <div className="mt-4">
          {body && <div className="mb-2">{body}</div>}
          <FormattedMessage
            defaultMessage="This action cannot be undone. To confirm, type {safeWord} below."
            id="WJUPeH"
            values={{
              safeWord: <strong>{safeWord}</strong>,
            }}
          />
          <Input
            autoFocus
            className="mt-2"
            data-cy="confirm-delete"
            data-testid="confirm-delete"
            disableOnEnter
            label=""
            onChange={handleChangeSafeWord}
            onSubmit={confirm}
            placeholder={safeWord}
            value={inputWord}
          />
        </div>
        <ModalFooter>
          <Button
            key={1}
            data-cy="confirm"
            data-testid="confirm"
            disabled={inputWord !== safeWord}
            loading={loading}
            onClick={confirm}
            variant="danger"
          >
            <FormattedMessage defaultMessage="Delete forever" id="8aSo9o" />
          </Button>
          <Button
            data-cy="wOoDnrrjCgDRnwvD7GZZE"
            key={0}
            onClick={close}
            variant="ghost"
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const ClonedTrigger = React.cloneElement(trigger, {
    onClick: open,
  });

  return (
    <div>
      {ClonedTrigger}
      {renderModal()}
    </div>
  );
};
