import React, { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Show } from "common/controlFlow";
import { SELECT_NEW_ID } from "common/inputs/Select/Select.utils";
import { SelectOption } from "common/inputs/Select/SelectOption/SelectOption";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
  creatable?: boolean;
  query?: string;
};

/**
 * default fallback for {@link Select} when no options found.
 */
export const SelectFallback = ({
  children,
  className,
  creatable,
  query,
}: Props): JSX.Element => {
  const { $t } = useIntl();
  return (
    <div
      className={twClass("px-2.5 py-2 text-center text-slate-500", className)}
    >
      <Show
        when={creatable}
        fallback={
          children ?? (
            <FormattedMessage defaultMessage="No options" id="pfrU/9" />
          )
        }
      >
        <SelectOption value={{ id: SELECT_NEW_ID, name: query }}>
          {query} ({$t({ defaultMessage: "add new", id: "0JChV5" })})
        </SelectOption>
      </Show>
    </div>
  );
};
