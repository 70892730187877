import { useRouter } from "next/router";

type Hook = {
  hashUrl: string;
  redirect: () => null;
  shouldRedirect: boolean;
};

/**
 * redirects legacy hash-based routes to regular urls.
 *
 * @example
 * "/#/mapsv3/s/objective/[id]/summary" → "/mapsv3/s/objective/[id]/summary"
 */
export const useRedirectLegacyHashRoute = (): Hook => {
  const router = useRouter();

  let hashUrl = "";
  if (typeof window !== "undefined") hashUrl = window.location.hash.slice(1);
  // make sure hash contains a route
  if (hashUrl[0] !== "/") hashUrl = "";
  // remove trailing slash "/foo/bar" → "foo/bar"
  hashUrl = hashUrl.slice(1);

  if (!hashUrl) {
    return { hashUrl: "", redirect: () => null, shouldRedirect: false };
  }

  const redirect = (): null => {
    router.replace(hashUrl);
    return null;
  };

  return { hashUrl, redirect, shouldRedirect: true };
};
