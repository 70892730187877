import { defineMessages } from "react-intl";

export const messages = defineMessages({
  active: {
    defaultMessage: "Active",
    id: "3a5wL8",
  },
  attendee: {
    defaultMessage: "Meeting with",
    id: "vKIsuX",
  },
  description: {
    defaultMessage: "Description",
    id: "Q8Qw5B",
  },
  meetingTime: {
    defaultMessage: "Time",
    id: "ug01Mk",
  },
  repeatFrequency: {
    defaultMessage: "Frequency",
    id: "vAW30j",
  },
});
