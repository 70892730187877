import { QueryOptions } from "@apollo/client";
import {
  SubObjectivesDocument,
  useDoCloneObjectiveMutation,
  useGetClonedObjectiveQuery,
} from "@graphql";
import { FormikHelpers } from "formik";
import { findIndex, isNil, last, orderBy } from "lodash";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  CloneObjectiveForm,
  CloneObjectiveFormValues,
} from "common/form/CloneObjectiveForm/CloneObjectiveForm";
import { FlexContainer } from "common/misc/FlexContainer/FlexContainer";
import { Modal } from "common/overlay/Modal/Modal";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { ADD_OBJECTIVE, CLONE_OBJECTIVE } from "constants/tracking";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { showServerErrorToast } from "utils/utils";

const messages = defineMessages({
  error: {
    defaultMessage: "An error occurred cloning objective",
    id: "objective:clone:error",
  },
  noTimeframe: {
    defaultMessage: "You must provide a timeframe!",
    id: "form:error:noTimeframe",
  },
});

type Props = {
  onRequestClosed?: () => void;
  open?: boolean;
  passedObjectiveId: string;
  refetchQueries: (string | QueryOptions)[];
};

export const CloneObjectiveModal = ({
  onRequestClosed,
  open = false,
  passedObjectiveId,
  refetchQueries = [],
}: Props): JSX.Element => {
  const intl = useIntl();
  const [cloneObjective] = useDoCloneObjectiveMutation();
  const { data } = useGetClonedObjectiveQuery({
    onError: (err) => showServerErrorToast(err),
    variables: {
      id: passedObjectiveId,
    },
  });
  const timeframes = mapEdges(
    data?.objective?.timeframe.cadence?.timeframes.edges
  );
  const sortedTimeframes = orderBy(timeframes, [(tf) => tf.endDate], ["asc"]);

  const onSubmit = (
    values: CloneObjectiveFormValues,
    actions: FormikHelpers<CloneObjectiveFormValues>
  ) => {
    actions.setSubmitting(true);
    const parentId = data?.objective?.parent?.id;

    if (parentId) {
      refetchQueries.push({
        query: SubObjectivesDocument,
        variables: {
          parentId,
        },
      });
    }

    if (isNil(values.timeframe)) {
      toast.failure(intl.formatMessage(messages.noTimeframe));
      actions.setSubmitting(false);
      return;
    }

    cloneObjective({
      refetchQueries,
      variables: {
        includeInits: values.includeInits,
        includeKrs: values.includeKrs,
        objectiveId: passedObjectiveId,
        timeframeId: values.timeframe,
      },
    })
      .then((res) => {
        const createdObjective = res.data?.cloneObjective?.clonedObjective;

        if (isNil(createdObjective)) {
          throw new Error(intl.formatMessage(messages.error));
        }

        track(CLONE_OBJECTIVE, {
          cloneInits: values.includeInits,
          cloneKr: values.includeKrs,
          status: createdObjective.status,
        });

        track(ADD_OBJECTIVE, {
          aligned: !isNil(parentId),
          description_added: !!createdObjective.description,
          end_date: createdObjective.dueDate,
          // scope: createdObjective.group ? "group" : "company",
          start_date: createdObjective.startDate,
          status: createdObjective.status,
          tags: mapEdges(createdObjective.tags.edges).map(({ name }) => name),
        });
        toast.success("Objective cloned");
        actions.setSubmitting(false);
        onRequestClosed?.();
      })
      .catch(() => {
        toast.failure(intl.formatMessage(messages.error));
        actions.setSubmitting(false);
      });
  };

  const prevTFIndex = findIndex(
    timeframes,
    (tf) => tf.id === data?.objective?.timeframe.id
  );

  const nextTf = sortedTimeframes[prevTFIndex + 1] ?? last(sortedTimeframes);

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title={intl.formatMessage({
        defaultMessage: "Clone Objective",
        id: "objective:clone",
      })}
    >
      {isNil(data) && (
        <FlexContainer fullWidth justifyCenter>
          <Spinner />
        </FlexContainer>
      )}
      {isSet(data) && (
        <CloneObjectiveForm
          initialValues={{
            includeInits: false,
            includeKrs: false,
            timeframe: nextTf?.id ?? data.objective?.id,
          }}
          onRequestClosed={onRequestClosed}
          onSubmit={onSubmit}
          sizeInits={data.objective?.initiatives?.edgeCount ?? 0}
          sizeKrs={data.objective?.keyResultsCount ?? 0}
        />
      )}
    </Modal>
  );
};
