import React from "react";
import { useDevMode } from "hooks/useDevMode/useDevMode";
import { twClass } from "utils/twClass";

interface Props {
  children: React.ReactNode;
  className?: string;
}

/**
 * custom colours for the sidebar in dev mode, to distinguish between environments.
 */
const devModeStyles = [
  "border-gray-600",
  "border-purple-800",
  "border-red-800",
  "border-yellow-800",
  "border-green-800",
  "border-blue-800",
  "border-indigo-800",
  "border-pink-800",
];

export const SidebarWrapper = ({ children, className }: Props): JSX.Element => {
  const { devMode } = useDevMode();

  return (
    <div
      className={twClass(
        "flex h-full flex-col font-semibold text-white bg-slate-800 dark:text-white dark:bg-gray-800",
        {
          "border-l-2": devMode,
        },
        devModeStyles[devMode % devModeStyles.length],
        className
      )}
    >
      {children}
    </div>
  );
};
