import { Transition } from "@headlessui/react";
import classNames from "clsx";
import { get } from "lodash";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Clickable } from "common/navigation/Clickable";
import { getPlatform } from "utils/getPlatform";
import { twClass } from "utils/twClass";

export const MODAL_ROOT_ID = "modal-root";

const modalWidthSizes = {
  lg: "1200px",
  md: "950px",
  sm: "600px",
  xl: "90vw",
};

type CloseButtonPosition = "external" | "internal";

type Props = {
  backdropClose?: boolean;
  children?: React.ReactNode;
  className?: string;
  closeButtonPosition?: CloseButtonPosition;
  "data-cy"?: string;
  disableEsc?: boolean;
  fullHeight?: boolean;
  id?: string;
  isOpen: boolean;
  onClose?: () => void;
  preRender?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
};

export const BaseModal = ({
  "data-cy": dataCy,
  backdropClose = true,
  children = null,
  className,
  closeButtonPosition = "internal",
  disableEsc = false,
  id,
  isOpen,
  onClose,
  size = "md",
  fullHeight = false,
  preRender = false,
}: Props): JSX.Element | null => {
  const container = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const handleBackgroundClick = () => {
    if (!backdropClose) {
      return;
    }
    onClose?.();
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    const platform = getPlatform();
    if (e.key === "Escape" && !disableEsc) {
      handleBackgroundClick();
    } else if (
      (platform === "osx" && e.metaKey && e.key === "Enter") ||
      (platform === "windows" && e.ctrlKey && e.key === "Enter") ||
      (platform === "linux" && e.ctrlKey && e.key === "Enter")
    ) {
      e.preventDefault();
    }
  };

  const attachListeners = () => {
    document.addEventListener("keydown", handleKeyPress, true);
  };

  const detachListeners = () => {
    document.removeEventListener("keydown", handleKeyPress, true);
  };

  useEffect(() => {
    if (isOpen) {
      attachListeners();
    }
    return () => {
      detachListeners();
    };
  }, []);

  useEffect(() => {
    if (isOpen && container.current) {
      attachListeners();
    } else if (isOpen) {
      detachListeners();
    }
  }, [isOpen, container]);


  const modalRoot = document.getElementById(MODAL_ROOT_ID);

  if (!isOpen && !preRender) {
    return null;
  }
  const modal = (
    <Transition afterLeave={onClose} appear show={isOpen}>
      <div
        ref={container}
        className={classNames(
          "absolute inset-0 flex items-start justify-center",
          { hidden: preRender && !isOpen }
        )}
        data-cy={dataCy}
        id={id}
      >
        <div
          ref={content}
          className={twClass(
            "relative mt-16 h-full animate-modal-slide-in overflow-hidden rounded-md shadow-[rgb(0_0_0_/_50%)_0px_5px_15px] bg-white",
            {
              "!z-modal": !className?.match(/\b(z-)/),
            },
            className
          )}
          data-cy="modalContent"
          data-testid="modalContent"
          style={{
            height: fullHeight ? "85vh" : undefined,
            maxHeight: "85vh",
            maxWidth: "90vw",
            width: get(modalWidthSizes, size, "750px"),
          }}
        >
          <IconButton
            className={classNames("absolute hover:bg-transparent", {
              "-right-8 top-0 text-black": closeButtonPosition === "external",
              "right-2 top-2 text-slate-500":
                closeButtonPosition === "internal",
            })}
            data-cy="closeModalButton"
            iconClass={classNames({
              "hover:text-blue-500": closeButtonPosition === "internal",
              "hover:text-white": closeButtonPosition === "external",
            })}
            name="close"
            onClick={onClose}
          />
          {children}
        </div>
        <Clickable
          data-cy="mLu6FK_P6Pz4col8RwRTD"
          className={classNames(
            "absolute inset-0 w-full bg-opacity-50 transition-opacity bg-black",
            "duration-2000 !z-modal-backdrop animate-modal-fade-in",
          )}
          id="modalBackdrop"
          onClick={handleBackgroundClick}
        />
      </div>
    </Transition>
  );

  return modalRoot && ReactDOM.createPortal(modal, modalRoot);
};
