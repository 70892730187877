import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  text: string;
};

export const Badge = ({ className, text }: Props): JSX.Element => {
  const badge = (
    <div
      className={twClass(
        "rounded-full border px-1 text-xs opacity-100",
        className
      )}
    >
      {text}
    </div>
  );

  return badge;
};
