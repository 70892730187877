import { useRouter } from "next/router";
import { routes } from "route-configs";

export type UseInTeamsHook = boolean;

export const useInTeams = (): UseInTeamsHook => {
  const router = useRouter();
  const inTeams =
    router.asPath.startsWith(routes.msTeams.base) ||
    router.asPath.includes(routes.login.msTeams);

  return inTeams;
};
