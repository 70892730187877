import {
  KeyResult,
  MetricUnit,
  namedOperations,
  PerdooApiKeyResultTypeChoices,
  UpsertResultMutationInput,
  useUpsertResultMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useIntl } from "react-intl";
import { resultTypes } from "constants/resultTypes";
import { useConvertToInitiativeConfirmationModal } from "hooks/useConvertToInitiativeConfirmationModal/useConvertToInitiativeConfirmationModal";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

type ResultParam = Derive<
  KeyResult,
  {
    childrenCount: true;
    dueDate: true;
    endValue: true;
    id: true;
    lead: {
      id: true;
    };
    metricUnit: true;
    parent: {
      id: true;
    };
    startDate: true;
    startValue: true;
    type: true;
  }
>;

type BaseUpsertInput = {
  input: UpsertResultMutationInput;
  successMessage: string;
};

export const useInlineResultActions = (result: ResultParam) => {
  const intl = useIntl();
  const [upsertResult] = useUpsertResultMutation({
    refetchQueries: [
      namedOperations.Query.searchResults,
      namedOperations.Query.getResultProgressGraph,
      namedOperations.Query.getProfileObjectives,
      namedOperations.Query.getResultTimeline,
    ],
  });
  const { openModal: openConfirmationModal } =
    useConvertToInitiativeConfirmationModal();

  const baseUpsert = async (input: BaseUpsertInput) => {
    const response = await upsertResult({
      variables: {
        input: {
          ...input.input,
          id: result.id,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertResult?.errors
    );
    if (hasError) return false;

    toast.success(input.successMessage);
    return true;
  };

  const setLead = async (value: string | null) => {
    if (!value || value === result.lead?.id) return false;
    return await baseUpsert({
      input: { lead: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result lead updated!",
        id: "iPMfnm",
      }),
    });
  };

  const setType = async (
    value: PerdooApiKeyResultTypeChoices,
    undo: () => void
  ) => {
    if (value === result.type) return;

    const submit = async () =>
      await baseUpsert({
        input: { type: value },
        successMessage: intl.formatMessage({
          defaultMessage: "Result type updated!",
          id: "tDYNVz",
        }),
      });

    const isChangingToInitiative =
      result.type === resultTypes.keyResult && value === resultTypes.initiative;
    const childrensCount = result.childrenCount;
    if (isChangingToInitiative && childrensCount > 0) {
      return openConfirmationModal({
        onCancel: undo,
        onClose: undo,
        onSave: submit,
        result,
      });
    }

    await submit();
  };

  const setParent = async (value: string | null) => {
    if (value === result.parent?.id) return;
    await baseUpsert({
      input: { parent: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result parent updated!",
        id: "TkgnLX",
      }),
    });
  };

  const setStartValue = async (value: number) => {
    if (value === result.startValue) return;
    await baseUpsert({
      input: { startValue: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result start value updated!",
        id: "dt0lLz",
      }),
    });
  };

  const setEndValue = async (value: number) => {
    if (value === result.endValue) return;
    await baseUpsert({
      input: { endValue: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result end value updated!",
        id: "67wFjW",
      }),
    });
  };

  const setMetricUnit = async (value: MetricUnit) => {
    if (value === result.metricUnit) return false;
    return await baseUpsert({
      input: { metricUnit: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result end value updated!",
        id: "67wFjW",
      }),
    });
  };

  const setContributors = (value: string[]) =>
    baseUpsert({
      input: { contributors: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result contributors updated!",
        id: "8dISZw",
      }),
    });

  const setTags = (value: string[]) =>
    baseUpsert({
      input: { tags: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result tags updated!",
        id: "suNZa4",
      }),
    });

  const setStartDate = async (value: string | null) => {
    if (value === result.startDate) return;
    await baseUpsert({
      input: { startDate: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result start date updated!",
        id: "20SFRw",
      }),
    });
  };

  const setDueDate = async (value: string | null) => {
    if (value === result.dueDate) return;
    await baseUpsert({
      input: { dueDate: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Result due date updated!",
        id: "n8+eqM",
      }),
    });
  };

  const setIntegration = async (
    value: Derive<
      UpsertResultMutationInput,
      { integration: true; integrationField: true }
    >
  ) => {
    if (value === result.dueDate) return;
    await baseUpsert({
      input: value,
      successMessage: intl.formatMessage({
        defaultMessage: "Integration updated!",
        id: "3aTZvW",
      }),
    });
  };

  return {
    setContributors,
    setDueDate,
    setEndValue,
    setIntegration,
    setLead,
    setMetricUnit,
    setParent,
    setStartDate,
    setStartValue,
    setTags,
    setType,
  };
};
