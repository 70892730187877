import React from "react";
import { objectiveStages } from "common/objective/constants";
import { Result } from "common/result";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";

type Props = {
  // TODO: add typing. good luck (you'll need it) 🙏
  result: any;
  showEdges?: boolean;
};
export type ResultWrapperProps = Props;

export const ResultWrapper = ({ result, showEdges }: Props): JSX.Element => {
  const { resultHref } = useModalRouter();
  const { objective } = result;
  const getResultLink = () => resultHref(result.id);

  return (
    <Result
      canDrag={objective.canPatch}
      // TODO: just added this last-child border for backwards compatibility, but i don't think these ever have any borders?
      closed={objective.stage === objectiveStages.closed}
      data-cy-id={result.id}
      showEdges={showEdges}
      result={result}
      resultLink={getResultLink()}
    />
  );
};
