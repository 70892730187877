import React from "react";
import styled, { keyframes } from "styled-components";
import { Icon } from "common/icons";
import { IconProps } from "common/icons/Icon/Icon";
import { colorTheme } from "constants/colorTheme";
import { twClass } from "utils/twClass";

type ContainerProps = {
  children?: React.ReactNode;
  className?: string;
  containerSize?: string;
};

const Container = ({ containerSize, className, children }: ContainerProps) => (
  <div
    className={className}
    style={{
      width: containerSize,
    }}
  >
    {children}
  </div>
);

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

type SpinnerProps = {
  color?: string;
  size?: string;
  speed?: string;
};

const Spinner = styled.div<SpinnerProps>`
  border: 2px solid ${({ color }) => color};
  border-left-color: transparent;
  border-radius: 11px;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  animation: ${spin} ${({ speed }) => speed} linear infinite;
`;

type State = "loading" | "success" | "failure" | "idle";
export type RequestIndicatorState = State;

type IndicatorIconProps = IconProps & { state: State };

// TODO: need to discuss how to handle animation delay props
// example: "animate-[wiggle_1s_ease-in-out_infinite]" (https://tailwindcss.com/docs/animation#arbitrary-values)
// original: https://github.com/perdoo/perdoo-api-v2/blob/stage/frontend/src/common/misc/RequestIndicator/RequestIndicator.tsx
const IndicatorIcon = ({
  state,
  className,
  ...rest
}: IndicatorIconProps): JSX.Element => (
  <Icon
    className={twClass(
      "animate-request-indicator",
      {
        "text-green-500": state === "success",
        "text-red-500": state === "failure",
      },
      className
    )}
    {...rest}
  />
);

type Props = {
  className?: string;
  color?: string;
  containerSize?: string;
  size?: string;
  speed?: string;
  state: State;
};

export const RequestIndicator = ({
  state = "idle",
  className = "",
  containerSize = "20px",
  color = colorTheme.slate[800],
  size = "20px",
  speed = "0.3s",
}: Props): JSX.Element => (
  <Container className={className} containerSize={containerSize}>
    {state === "loading" && <Spinner color={color} size={size} speed={speed} />}
    {state === "success" && <IndicatorIcon name="done" state={state} />}
    {state === "failure" && <IndicatorIcon name="clear" state={state} />}
  </Container>
);
