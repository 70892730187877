import { useField } from "formik";
import React from "react";
import { PlainTextArea } from "common/inputs/PlainTextArea/PlainTextArea";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  "data-cy"?: string;
  name: string;
  placeholder?: string;
};

export const PlainTextField = ({
  name,
  "data-cy": dataCy,
  className,
  placeholder,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const { error } = meta;
  return (
    <div>
      <PlainTextArea
        className={twClass({ "border-red-500": error }, className)}
        data-cy={dataCy}
        name={field.name}
        onChange={helpers.setValue}
        placeholder={placeholder}
        value={field.value}
      />
      {error && <div className="text-sm text-red-500">{error}</div>}
    </div>
  );
};
