import { defineMessages } from "react-intl";

export const messages = defineMessages({
  contributorsLabel: {
    defaultMessage: "Contributors",
    id: "objective:forms:contributors:label",
  },
  contributorsPlaceholder: {
    defaultMessage: "Search...",
    id: "objective:forms:contributors:placeholder",
  },
  draft: {
    defaultMessage: "Draft",
    id: "objective:forms:draft",
  },
  goalUpdateCycleLabel: {
    defaultMessage: "Update frequency",
    id: "objective:forms:goalUpdate:frequency",
  },
  leadLabel: {
    defaultMessage: "Lead",
    id: "objective:forms:lead:label",
  },
  leadLabelPlaceholder: {
    defaultMessage: "Select lead...",
    id: "objective:form:lead:placeholder",
  },
  learnMore: {
    defaultMessage: "Learn more",
    id: "objective:forms:private:learnMore",
  },
  lessOptions: {
    defaultMessage: "Less options",
    id: "global:less:options",
  },
  moreOptions: {
    defaultMessage: "More options",
    id: "global:more:options",
  },
  objDescriptionLabel: {
    defaultMessage: "Description",
    id: "objective:forms:description:label",
  },
  objDescriptionPlaceholder: {
    defaultMessage: "Write a description...",
    id: "objective:forms:description:placeholder",
  },
  objNameLabel: {
    defaultMessage: "Name",
    id: "objective:forms:name:label",
  },
  objNameValidationLabel: {
    defaultMessage: "Make sure your Objective name:",
    id: "objective:forms:name:validation:label",
  },
  objOwnerLabel: {
    defaultMessage: "Owner",
    id: "objective:forms:owner:label",
  },
  objOwnerPlaceholder: {
    defaultMessage: "Select owner...",
    id: "objective:form:owner:placeholder",
  },
  privateObjectiveDescription: {
    defaultMessage:
      "Hide OKR from everyone except Objective Lead, Result Leads, and Contributors. {link}",
    id: "objective:forms:privateObjectiveDescription",
  },
  privateObjectiveLabel: {
    defaultMessage: "Private Objective",
    id: "objective:forms:privateObjectiveLabel",
  },
  sharedOwnership: {
    defaultMessage: "Shared ownership",
    id: "objective:forms:sharedOwnership",
  },
  sharedOwnershipTooltip: {
    defaultMessage:
      "Multiple teams can own an Objective. Add the different owners here.",
    id: "objective:forms:sharedOwnership:tooltip",
  },
  tagsLabel: {
    defaultMessage: "Tags",
    id: "objective:forms:tags:label",
  },
  tagsPlaceholder: {
    defaultMessage: "Select tags...",
    id: "objective:forms:tag:placeholder",
  },
  timeFrameInfo: {
    defaultMessage:
      "You've selected a future timeframe, so we'd suggest saving this OKR as a draft. {link} about drafts.",
    id: "objective:forms:timeframe:info",
  },
  timeframeLabel: {
    defaultMessage: "Timeframe",
    id: "objective:forms:timeframe:label",
  },
});

// TODO: can't we get these from sample goals?
export const namePlaceholders = defineMessages({
  text1: {
    defaultMessage: "Create a more goal-oriented culture",
    id: "objective:forms:placeholder:text1",
  },
  text2: {
    defaultMessage: "Spread the word about our new community event",
    id: "objective:forms:placeholder:text2",
  },
  text3: {
    defaultMessage: "Optimize our funnel to close deals in less time",
    id: "objective:forms:placeholder:text3",
  },
  text4: {
    defaultMessage: "Release an amazing Recommendations Engine",
    id: "objective:forms:placeholder:text4",
  },
  text5: {
    defaultMessage: "Our onboarding process is smooth and fast",
    id: "objective:forms:placeholder:text5",
  },
  text6: {
    defaultMessage: "Budgets are cost effective across all departments",
    id: "objective:forms:placeholder:text7",
  },
  text7: {
    defaultMessage: "Employee happiness is off the charts",
    id: "objective:forms:placeholder:text8",
  },
  text8: {
    defaultMessage: "No one can refuse our product offer",
    id: "objective:forms:placeholder:text9",
  },
});
