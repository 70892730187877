import { PerdooApiExploreViewTabNameChoices } from "@graphql";
import { defer } from "lodash";
import React, { useEffect, useState } from "react";
import { useToggle } from "hooks/useToggle/useToggle";
import {
  cleanUpFilters,
  filterColumnAdd,
  updateFilter,
} from "legacy/components/filters/actions";
import { ActiveFilter } from "legacy/components/filters/ActiveFilter/ActiveFilter";
import { FilterButton } from "legacy/components/filters/FilterButton/FilterButton";
import { FilterList } from "legacy/components/filters/FilterList/FilterList";
import { useFiltersContext } from "legacy/components/filters/filtersContext";
import { handleFilterRemove } from "legacy/components/filters/FilterSet.utils";
import { FilterSetItem } from "legacy/components/filters/FilterSetItem";
import { FilterSubgroupsToggle } from "legacy/components/filters/FilterSubgroupsToggle/FilterSubgroupsToggle";
import {
  Filter,
  FilterBtnProps,
  FilterItem,
} from "legacy/components/filters/types";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { FilterSubGoalsToggle } from "./FilterSubGoalsToggle/FilterSubGoalsToggle";
import { getSubGoalFilter } from "./utils";

type FilterSetProps = {
  afterFilterRemoved?: () => void;
  afterFilterSelected?: () => void;
  buttonFirst?: boolean;
  customRender?: boolean;
  filterCopy?: string;
  noWrap?: boolean;
  onFilterSelect?: (filter: Filter | null) => void;
  renderButton?: (props: FilterBtnProps) => React.ReactElement;
  scrollable?: boolean;
  tabName?: PerdooApiExploreViewTabNameChoices;
};

/**
 * @deprecated use entity-specific filter components instead and hook up action to {@link useFilters} (wip).
 */
export const FilterSet = ({
  afterFilterRemoved,
  afterFilterSelected,
  buttonFirst = false,
  customRender,
  noWrap = false,
  onFilterSelect = undefined,
  renderButton = undefined,
  scrollable = false,
  tabName,
  filterCopy,
}: FilterSetProps): JSX.Element => {
  const { filters, filtersDispatch, filterState } = useFiltersContext();
  const [activeFilter, setActiveFilter] = useState<Filter | null>(null);
  const [includeSubgroups, toggleIncludeSubgroups] = useToggle(false);
  const [includeSubGoals, toggleIncludeSubGoals] = useToggle(false);

  useEffect(() => {
    if (
      activeFilter?.filterName === "alignedTo" ||
      activeFilter?.filterName.includes("objective_AlignedTo")
    ) {
      const subGoalFilter = getSubGoalFilter(includeSubGoals);
      filtersDispatch(updateFilter(subGoalFilter));
    }
  }, [includeSubGoals]);

  const handleFilterPick = (pickedFilter: Filter) => {
    const { name, type, min, max, multiple, filterName } = pickedFilter;

    const filterAlreadyApplied = isSet(filterState.filters[filterName]);

    if (!filterAlreadyApplied) {
      filtersDispatch(
        filterColumnAdd({
          filterName,
          multiple,
          name,
          selected: multiple ? [] : [min, max],
          type,
        })
      );
    }
    setActiveFilter(pickedFilter);
  };

  const handleSelectFilterValue = (filterValues: FilterItem) => {
    if (onFilterSelect) onFilterSelect(activeFilter);
    const filter = {
      filterName: activeFilter?.filterName,
      name: activeFilter?.name,
    };
    const archivedOrOutdated = ["isOutdated", "isArchived"].some(
      (v) => v === activeFilter?.filterName
    );
    if (archivedOrOutdated) {
      filtersDispatch(
        updateFilter({
          ...filter,
          data: filterValues,
        })
      );
      afterFilterSelected?.();
      return;
    }

    if (
      activeFilter?.filterName === "alignedTo" ||
      activeFilter?.filterName.includes("objective_AlignedTo")
    ) {
      const subGoalFilter = getSubGoalFilter(includeSubGoals);
      filtersDispatch(updateFilter(subGoalFilter));
    }

    if (activeFilter?.multiple) {
      const { name, avatar, id, color, fullName, subGroups, __typename } =
        filterValues;

      const useSubgroups = includeSubgroups && __typename === "group";

      const data = useSubgroups
        ? { avatar, color, fullName, id, name, subGroups }
        : { avatar, color, fullName, id, name };

      filtersDispatch(
        updateFilter({
          ...filter,
          data,
        })
      );
    } else {
      filtersDispatch(
        updateFilter({
          ...filter,
          data: filterValues,
        })
      );
    }

    afterFilterSelected?.();
  };

  const handleFilterDropdownToggle = (isOpen: boolean) => {
    if (!isOpen) {
      setActiveFilter(() => {
        filtersDispatch(cleanUpFilters(null));
        return null;
      });
    }
  };

  const findActiveFilterItems = () => {
    return filters.find(({ name }) => name === activeFilter?.name)?.items ?? [];
  };

  const renderToggle = () => {
    if (activeFilter?.filterName.includes("owner")) {
      return (
        <FilterSubgroupsToggle
          activeFilterItems={findActiveFilterItems()}
          includeSubgroups={includeSubgroups}
          onToggle={toggleIncludeSubgroups}
        />
      );
    }
    if (
      activeFilter?.filterName.includes("alignedTo") ||
      activeFilter?.filterName.includes("objective_AlignedTo")
    ) {
      return (
        <FilterSubGoalsToggle
          activeFilterItems={findActiveFilterItems()}
          includeSubGoals={includeSubGoals}
          onToggle={toggleIncludeSubGoals}
        />
      );
    }
    return null;
  };

  const filterButton = (
    <FilterButton
      filterCopy={filterCopy}
      data-cy="filterButton"
      onToggle={handleFilterDropdownToggle}
      renderButton={renderButton}
      renderFilters={() => (
        <>
          {!activeFilter &&
            filters.map((filter) => (
              <FilterSetItem
                key={filter.name}
                data-cy="filter-menu-item"
                onClick={(e) => {
                  e.stopPropagation();
                  defer(() => handleFilterPick(filter));
                }}
              >
                {filter.name}
              </FilterSetItem>
            ))}
          {activeFilter && (
            <ActiveFilter
              goalType={tabName}
              activeFilter={activeFilter}
              findActiveFilterItems={findActiveFilterItems}
              handleFilterRemove={handleFilterRemove}
              handleSelectFilterValue={handleSelectFilterValue}
              renderToggle={renderToggle}
            />
          )}
        </>
      )}
    />
  );

  return (
    <div
      className={twClass("flex items-center", {
        "flex-nowrap": noWrap,
        "flex-wrap": !noWrap,
      })}
    >
      {buttonFirst && filterButton}
      {!customRender && (
        <FilterList
          activeFilter={activeFilter}
          afterFilterRemoved={afterFilterRemoved ?? afterFilterSelected}
          noWrap={noWrap}
          scrollable={scrollable}
          tabName={tabName}
        />
      )}
      {!buttonFirst && filterButton}
    </div>
  );
};
