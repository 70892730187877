import {
  OneOnOneRepeatFrequency,
  UserAvatarFragment,
  UserDefaultFragment,
} from "@graphql";
import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Avatar } from "common/avatar";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { FormInput } from "common/form/FormInput/FormInput";
import { FormToggle } from "common/form/FormToggle/FormToggle";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import {
  CalendarIntegrationFormSection,
  CalendarIntegrationOneOnOneProp,
  CalendarIntegrationValues,
} from "./CalendarIntegrationFormSection/CalendarIntegrationFormSection";
import { OneOnOneMeetingDateInfo } from "./OneOnOneMeetingDateInfo/OneOnOneMeetingDateInfo";
import { messages } from "./oneOnOneMeetingForm.messages";
import { RecurrenceFormSection } from "./RecurrenceFormSection/RecurrenceFormSection";

export type EditOneOnOneMeetingFormValues = {
  description?: string | null;
  dueDate: string | null;
  id: string;
  isActive: boolean;
  meetingTime?: string | null;
  repeatFrequency: OneOnOneRepeatFrequency | null;
} & CalendarIntegrationValues;

type OneOnOneProp = CalendarIntegrationOneOnOneProp;

type EditOneOnOneMeetingFormProps = {
  attendee: (UserDefaultFragment & UserAvatarFragment) | null;
  onCancel: () => void;
  oneOnOne: OneOnOneProp;
};

export const EditOneOnOneMeetingForm = ({
  attendee,
  onCancel,
  oneOnOne,
}: EditOneOnOneMeetingFormProps): JSX.Element => {
  const intl = useIntl();

  const { isSubmitting, submitForm, values } =
    useFormikContext<EditOneOnOneMeetingFormValues>();

  return (
    <>
      <div className="pb-3 pt-4">
        <span className="flex flex-grow items-center pb-2 text-sm">
          {intl.formatMessage(messages.attendee)}
        </span>
        <div className="flex items-center space-x-2">
          <Avatar url={attendee?.avatar} />
          <span className="text-base font-normal text-slate-800">
            {attendee?.fullName}
          </span>
        </div>
      </div>
      <Field name="description">
        {({ field, form }: FieldProps) => (
          <FormInput
            autoFocus
            field={field}
            form={form}
            id="description"
            label={intl.formatMessage(messages.description)}
            optional
            placeholder={intl.formatMessage({
              defaultMessage: "What are these meetings are about?",
              id: "J0GvDw",
            })}
          />
        )}
      </Field>

      <CalendarIntegrationFormSection<EditOneOnOneMeetingFormValues>
        attendee={attendee}
        oneOnOne={oneOnOne}
      />

      <Show when={!values.googleCalendarEventAction}>
        <RecurrenceFormSection<EditOneOnOneMeetingFormValues> />
      </Show>

      <OneOnOneMeetingDateInfo values={values} />
      <ModalFooter className="justify-between">
        <div className="flex flex-row-reverse gap-3">
          <Button
            data-cy="saveOneOnOneMeeting"
            data-testid="submitForm"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={submitForm}
            type="submit"
          >
            <FormattedMessage defaultMessage="Save" id="global:save" />
          </Button>
          <Button
            data-cy="qTCqmVbYiEXvZMbon1QlU"
            onClick={onCancel}
            type="button"
            variant="ghost"
          >
            <FormattedMessage defaultMessage="Cancel" id="global:cancel" />
          </Button>
        </div>
        <Field name="isActive">
          {({ field }: FieldProps) => (
            <FormToggle
              data-cy="isActive"
              field={field}
              label={intl.formatMessage(messages.active)}
            />
          )}
        </Field>
      </ModalFooter>
    </>
  );
};
