import { namedOperations, useUpsertCompanyMutationMutation } from "@graphql";
import { FormikValues } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { Modal } from "common/overlay/Modal/Modal";
import { UPDATE_ULTIMATE_GOAL } from "constants/tracking";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { UltimateGoalForm } from "../UltimateGoalForm/UltimateGoalForm";
import { messages } from "./UltimateGoalModal.messages";

const refetchQueries = [namedOperations.Query.getRoadmap];

type Props = {
  companyId: string;
  onRequestClosed: () => void;
  open: boolean;
  ultimateGoal?: string;
  ultimateGoalDescription?: string;
};

export const UltimateGoalModal = ({
  companyId,
  open = false,
  onRequestClosed: onModalClose,
  ultimateGoal,
  ultimateGoalDescription,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [editCompany] = useUpsertCompanyMutationMutation({ refetchQueries });

  const onSubmit = async (values: FormikValues) => {
    const { errors } = await editCompany({
      variables: {
        input: {
          id: companyId,
          ultimateGoal: values.name,
          ultimateGoalDescription: values.description,
        },
      },
    });
    if (errors) {
      toast.failure("Error updating ultimate goal");
    }
    toast.success("Ultimate Goal Updated");
    track(UPDATE_ULTIMATE_GOAL, { uiElementId: "roadmap" });
    onModalClose();
  };
  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onModalClose}
      title={intl.formatMessage(
        ultimateGoal ? messages.editUltimateGoal : messages.addUltimateGoal
      )}
    >
      <UltimateGoalForm
        initialValues={{
          description: ultimateGoalDescription || "",
          name: ultimateGoal || "",
        }}
        onCancel={onModalClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
