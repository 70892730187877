import { AsanaProject as GqlAsanaProject } from "@graphql";
import { Derive } from "@shoooe/derive";
import React from "react";
import { AsanaProjectIcon } from "../AsanaProjectIcon/AsanaProjectIcon";

type ProjectProp = Derive<
  GqlAsanaProject,
  {
    color: true;
    gid: true;
    name: true;
    permalinkUrl: true;
  }
>;

type Props = {
  project: ProjectProp;
};

export const AsanaProject = ({ project }: Props): JSX.Element => (
  <div className="flex items-center gap-2">
    <AsanaProjectIcon color={project.color} />
    <a href={project.permalinkUrl} rel="noreferrer" target="_blank">
      {project.name}
    </a>
  </div>
);
