import React from "react";
import { FormattedMessage } from "react-intl";

export const getSelectionPrompt = (option: string) => {
  switch (option) {
    case "kpi":
      return (
        <FormattedMessage
          defaultMessage="Select the KPI that this OKR should improve."
          id="objective:form:kpi:validation:msg"
        />
      );
    case "parent":
      return (
        <FormattedMessage
          defaultMessage="Select the OKR that this OKR should support."
          id="objective:form:parent:validation:msg"
        />
      );
    case "strategicPillar":
      return (
        <FormattedMessage
          defaultMessage="Select the Strategic Pillar that this OKR should support."
          id="objective:form:strategicPillar:validation:msg"
        />
      );
    default:
      return null;
  }
};
