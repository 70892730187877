import React, { useRef } from "react";
import { components } from "react-select";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Anchor } from "common/navigation/Anchor/Anchor";

export const MenuList = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const onScroll = (x: number) => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ref && ref.current) {
      ref.current.scrollLeft += x;
    }
  };

  const { clearAll, timeframesSelect } = props.selectProps;

  return (
    <>
      <div className="mt-2 flex w-full items-center">
        <IconButton
          data-cy="o7oJtEhaBrNTvmSoDarUv"
          className="hover:text-slate-400"
          name="chevron_left"
          onClick={() => onScroll(-50)}
          size="3xl"
        />
        <div className="flex flex-grow flex-col">
          <div className="flex flex-grow justify-between text-sm">
            Filter by timeframe:
            <Anchor
              data-cy="ve4ZMaK8AcOKAmxijI8-u"
              className="text-sm"
              onClick={clearAll}
              text="Clear all"
            />
          </div>
          <div
            ref={ref}
            className="mt-2.5 h-10 w-80 overflow-x-auto whitespace-nowrap"
          >
            {timeframesSelect}
          </div>
        </div>
        <IconButton
          data-cy="RW_LKR89bClKq6bPbYUnS"
          className="hover:text-slate-400"
          name="chevron_right"
          onClick={() => onScroll(50)}
          size="3xl"
        />
      </div>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </>
  );
};
