import {
  KpiTargetSelectorKpiFragment,
  PerdooApiKpiTargetOperatorChoices,
} from "@graphql";
import { Form, Formik, FormikHelpers } from "formik";
import { isNil } from "lodash";
import React, { ChangeEvent, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";
import type { Placement } from "tippy.js";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { TextButton } from "common/buttons/TextButton/TextButton";
import { InputField } from "common/fields/InputField/InputField";
import { OperatorSwitch } from "common/kpi/Targets/OperatorSwitch/OperatorSwitch";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { getMetricSymbol } from "constants/metric";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { stringToCommitNumber } from "utils/stringToCommitNumber";
import { isValidCommitNumber } from "utils/utils";

export type Kpi = KpiTargetSelectorKpiFragment;
type FormData = {
  operator: PerdooApiKpiTargetOperatorChoices;
  target: string;
};

type ValidatedFormData = {
  operator: PerdooApiKpiTargetOperatorChoices;
  target: number;
};

type Props = {
  children: JSX.Element;
  initialValues: FormData;
  kpi: Kpi;
  onChange?: (e: ChangeEvent<HTMLFormElement>) => void;
  onClose?: () => void;
  onRemoveTarget?: () => void;
  onSubmit: (values: ValidatedFormData) => Promise<void>;
  placement: Placement;
  setNewOperator: (op: PerdooApiKpiTargetOperatorChoices) => void;
  visible: boolean;
};

const messages = defineMessages({
  invalidTarget: {
    defaultMessage: "Enter a valid target value",
    id: "kpiTargetSelector:invalidTarget",
  },
  noTargetYet: {
    defaultMessage: "No target value set for this month yet",
    id: "kpiTargetSelector:noTargetYet",
  },
  removeTarget: {
    defaultMessage: "Remove target",
    id: "kpiTargetSelector:removeTarget",
  },
  submit: {
    defaultMessage: "Done",
    id: "kpiTargetSelector:submit",
  },
  subtitle: {
    defaultMessage: "This KPI should be",
    id: "kpiTargetSelector:subtitle",
  },
  targetRequired: {
    defaultMessage: "Enter a target value",
    id: "kpiTargetSelector:targetRequired",
  },
  title: {
    defaultMessage: "Target",
    id: "kpiTargetSelector:title",
  },
});

export const KpiTargetSelector = ({
  children,
  initialValues,
  kpi,
  onChange,
  onClose,
  onRemoveTarget,
  onSubmit,
  placement,
  setNewOperator,
  visible,
}: Props): JSX.Element => {
  const intl = useIntl();
  const tooltipRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(tooltipRef, () => {
    onClose?.();
  });

  const validationSchema = () => {
    return Yup.object().shape({
      target: Yup.string()
        .test(
          "is-number",
          intl.formatMessage(messages.invalidTarget),
          isValidCommitNumber
        )
        .required(intl.formatMessage(messages.targetRequired)),
    });
  };

  const handleSubmit = (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    onSubmit({
      ...values,
      target: stringToCommitNumber(values.target),
    } as ValidatedFormData).finally(() => setSubmitting(false));
  };

  if (!visible) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      content={
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form onChange={onChange}>
              <div ref={tooltipRef} className="w-64 space-y-3 p-3">
                <div className="flex">
                  <div className="flex-grow font-semibold">
                    {intl.formatMessage(messages.title)}
                  </div>
                  <div>
                    <IconButton
                      data-cy="LCBsUVr5Z0AcCttM9galT"
                      className="text-slate-500"
                      name="close"
                      onClick={onClose}
                      size="xl"
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <div>{intl.formatMessage(messages.subtitle)}</div>
                  <div className="flex space-x-2">
                    <OperatorSwitch
                      name="operator"
                      onChange={setNewOperator}
                      setFieldValue={setFieldValue}
                    />
                    <div className="grow min-w-0">
                      <InputField
                        suffix={getMetricSymbol(kpi.metricUnit)}
                        data-cy="kpi-target-input"
                        data-testid="kpiTargetInput"
                        formControl={false}
                        name="target"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    {!isNil(onRemoveTarget) && (
                      <TextButton
                        className="p-0"
                        data-cy="kpi-target-remove"
                        onClick={onRemoveTarget}
                        size="small"
                      >
                        {intl.formatMessage(messages.removeTarget)}
                      </TextButton>
                    )}
                  </div>
                  <div className="text-right">
                    <Button
                      data-cy="kpi-target-submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="submit"
                    >
                      {intl.formatMessage(messages.submit)}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
      interactive
      placement={placement}
      spanClass="flex items-center h-full"
      theme="perdoo-white"
      visible={visible}
    >
      {children}
    </Tooltip>
  );
};
