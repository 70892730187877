import { User } from "@graphql";
import { Derive } from "@shoooe/derive";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ProTipBanner } from "common/overlay/ProTipBanner/ProTipBanner";
import { MODAL_TYPES } from "constants/index";
import { showModal } from "legacy/actions/actions";

type PrivateUser = Derive<
  User,
  {
    avatar: true;
    fullName: true;
    id: true;
  }
>;

interface Props {
  users: PrivateUser[];
}

export const PrivateObjectiveLockButton = ({
  users,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const showContributingUsers = async () => {
    dispatch(
      showModal(MODAL_TYPES.USER_LIST, {
        title: intl.formatMessage({
          defaultMessage: "Who can see this?",
          id: "objective:private:contributingUsers:whoCanSeeThis",
        }),
        topArea: () => (
          <ProTipBanner className="border text-slate-800 text-sm border-yellow-400">
            <FormattedMessage
              defaultMessage="Only Objective lead, Result leads, and contributors can see private OKRs."
              id="objective:private:contributingUsers:notice"
            />
          </ProTipBanner>
        ),
        users,
      })
    );
  };

  return (
    <IconButton
      data-cy="SPiX_DgLcPQYBQ-rRxAsv"
      className="text-slate-500 hover:text-blue-500 hover:border-blue-500 border border-slate-300"
      ghost
      iconClass="text-[inherit]"
      name="lock_outline"
      onClick={showContributingUsers}
      size="sm"
      tooltip={intl.formatMessage({
        defaultMessage: "Private Objective",
        id: "g9EX6q",
      })}
      tooltipPlacement="top"
    />
  );
};
