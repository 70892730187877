import { range } from "lodash";
import React, { useState } from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type Props = {
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (value: number | null) => void;
  total?: number;
  value?: number | null;
};

export const RatePicker = ({
  total = 5,
  value = null,
  onChange,
  disabled = false,
  "data-testid": dataTestid,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState(value);

  const handleClick = (newValue: number | null) => {
    if (disabled) return;
    const valueToSet = newValue === selected ? null : newValue;
    setSelected(valueToSet);
    onChange?.(valueToSet);
  };

  return (
    <div className="flex items-center space-x-2" data-testid={dataTestid}>
      <div className="flex items-center space-x-0.5">
        {range(total).map((i) => {
          const starValue = i + 1;
          const isSelected = isSet(selected) && starValue <= selected;
          return (
            <div key={i}>
              <IconButton
                data-cy="c_eS6WcAIh0_zlGffyrgi"
                data-on={isSelected}
                data-testid="rate-picker-star"
                iconClass={twClass("hover:text-yellow-300", {
                  "text-slate-300": !isSelected,
                  "text-yellow-500": isSelected,
                })}
                name="star"
                onClick={() => handleClick(starValue)}
                size="4xl"
              />
            </div>
          );
        })}
      </div>
      {isSet(selected) && (
        <div className="space-x-1 text-lg">
          <span className="font-semibold">{selected}</span>
          <span>/</span>
          <span>{total}</span>
        </div>
      )}
    </div>
  );
};
