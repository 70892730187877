import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { DatePickerDropdown } from "common/overlay/DatePickerDropdown/DatePickerDropdown";
import { twClass } from "utils/twClass";

type Props = {
  "data-cy"?: string;
  defaultMessage?: ReactNode;
  disabled?: boolean;
  hasError?: boolean;
  onChange?: (value: string | null) => void;
  value: string | null;
};

export const InlineDatePicker = ({
  value,
  "data-cy": dataCy,
  onChange,
  hasError,
  defaultMessage,
  disabled,
}: Props) => {
  return (
    <DatePickerDropdown value={value} onChange={onChange} disabled={disabled}>
      <TextButton
        className={twClass(
          "-ml-2 px-2 py-0 rounded h-10 border border-transparent",
          {
            "hover:bg-white hover:border-slate-300": !disabled,
            "text-red-500": hasError,
          }
        )}
        size="small"
        data-cy={dataCy}
        disabled={disabled}
      >
        {value ? (
          <FormattedDate value={value} />
        ) : (
          defaultMessage ?? (
            <FormattedMessage defaultMessage="No date" id="065UXh" />
          )
        )}
      </TextButton>
    </DatePickerDropdown>
  );
};
