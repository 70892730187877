import { useIntl } from "react-intl";
import { useCompany } from "hooks/useCompany/useCompany";

type Options = {
  includeYear?: boolean;
  timeZone?: string;
};

export const useFormatDate = () => {
  const intl = useIntl();
  const company = useCompany();

  const formatDate = (value: string, options?: Options): string => {
    return intl.formatDate(value, {
      day: "numeric",
      month: "short",
      timeZone: options?.timeZone ?? company.timezone,
      year: options?.includeYear ? "numeric" : undefined,
    });
  };

  return { formatDate };
};
