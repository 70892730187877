import { MetricUnit, PerdooApiIntegrationApplicationChoices } from "@graphql";
import classNames from "clsx";
import { useField, useFormikContext } from "formik";
import { isNil, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Props as ReactSelectProps, ValueType } from "react-select";
import { AllowedFieldProps } from "common/form/types";
import {
  getLabelForApplication,
  IntegrationOption,
  useIntegrationOptions,
} from "common/integration/hooks/useIntegrations";
import { IntegrationLogo } from "common/integration/IntegrationLogo/IntegrationLogo";
import {
  ResultFormIntegration,
  useResultFormContext,
} from "common/result/utils/useResultFormContext/useResultFormContext";
import { Select } from "legacy/components/Select/Select";
import { OptionType } from "legacy/components/Select/Select.types";
import { isSet } from "utils/isSet";
import { ResultFormValues } from "../ResultForm.types";
import { messages } from "./IntegrationField.messages";
import { NoIntegrationMessage } from "./NoIntegrationMessage";

// TODO: move to separate file in ResultForm/utils and reuse in ResultForm
const shouldBeFixedPercentage = (
  application: PerdooApiIntegrationApplicationChoices | undefined
) => {
  const isAsanaOrJira =
    isSet(application) &&
    [
      PerdooApiIntegrationApplicationChoices.Asana,
      PerdooApiIntegrationApplicationChoices.Jira,
    ].includes(application);
  return isAsanaOrJira;
};

const integrationToOption = (integration: ResultFormIntegration): Option => {
  return {
    application: integration.application,
    label: getLabelForApplication(integration.application),
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    value: integration?.id,
  };
};

type Option = IntegrationOption;

type Props = ReactSelectProps & AllowedFieldProps;

export const IntegrationField = ({
  name,
  ...reactSelectProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, submitCount } = useFormikContext<ResultFormValues>();

  const { integration, setIntegration } = useResultFormContext();
  const [currentValue, setCurrentValue] = useState<Option>();

  const { integrations, options, anyIntegrationEnabled } =
    useIntegrationOptions();

  const handleIntegrationSelected = (
    newIntegration: ResultFormIntegration | null
  ) => {
    const previousSelectedIntegration = integration;
    setIntegration(newIntegration);

    // Ignore initial values being set
    if (isUndefined(previousSelectedIntegration)) return;

    const shouldSetToPercentage = shouldBeFixedPercentage(
      newIntegration?.application
    );

    if (shouldSetToPercentage) {
      setFieldValue("metricUnit", MetricUnit.Percentage);
      setFieldValue("startValue", 0);
      setFieldValue("endValue", 100);
    }

    if (
      isNil(newIntegration) ||
      (isSet(previousSelectedIntegration) &&
        newIntegration.application !== previousSelectedIntegration.application)
    ) {
      setFieldValue("asanaTask", undefined);
      setFieldValue("asanaProject", undefined);
      setFieldValue("asanaWorkspace", undefined);
      setFieldValue("jiraIssue", undefined);
      setFieldValue("jiraProgressType", undefined);
      setFieldValue("jiraJql", undefined);
    }
  };

  useEffect(() => {
    if (isNil(field.value)) {
      handleIntegrationSelected(null);
      setCurrentValue(undefined);
      return;
    }

    const selectedIntegration = integrations.find((i) => i.id === field.value);
    if (isSet(selectedIntegration)) {
      const option = integrationToOption(selectedIntegration);
      setCurrentValue(option);
      handleIntegrationSelected(selectedIntegration);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        "Couldn't set integration because it's not part of the available options"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, JSON.stringify(integrations)]);

  const forwardOnChange = (opt: ValueType<OptionType, false>) => {
    helpers.setValue(opt?.value);
  };

  const formatLabel = ({ label: optionLabel, value, application }: Option) => {
    return (
      <div className="flex space-x-2">
        <IntegrationLogo application={application} />
        <div className={classNames({ "text-slate-500": isNil(value) })}>
          {optionLabel}{" "}
          {isNil(value) && (
            <FormattedMessage
              defaultMessage="(Inactive)"
              id="integrationSelect:inactive"
            />
          )}
        </div>
      </div>
    );
  };

  const errorText = submitCount > 0 ? meta.error : undefined;
  const isOptionDisabled = (opt: Option) => isNil(opt.value);

  return (
    <Select
      {...reactSelectProps}
      errorText={errorText}
      formatOptionLabel={formatLabel}
      isInForm
      isOptionDisabled={isOptionDisabled}
      label={intl.formatMessage(messages.integrationLabel)}
      noOptionsMessage={() => <NoIntegrationMessage />}
      onChange={forwardOnChange}
      optional
      options={anyIntegrationEnabled ? options : []}
      value={currentValue}
    />
  );
};
