import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { useCompany } from "hooks/useCompany/useCompany";
import { twClass } from "utils/twClass";

interface Props {
  alwaysShowHelper?: boolean;
  className?: string;
  localTime: dayjs.Dayjs;
  prefix?: string;
}

export const LocalTimeHelper = ({
  localTime,
  className,
  prefix,
  alwaysShowHelper,
}: Props) => {
  const { timezone } = useCompany();
  const { $t } = useIntl();

  const inCompanyTimezone =
    dayjs().utcOffset() === dayjs().tz(timezone).utcOffset();
  if (!alwaysShowHelper && inCompanyTimezone) return null;

  return (
    <div className={twClass("text-slate-500 text-sm", className)}>
      <FormattedMessage
        defaultMessage="{prefix} {time} your time (GMT{offset})."
        id="wg8wrV"
        values={{
          offset: localTime.format("Z"),
          prefix: prefix || $t({ defaultMessage: "This is", id: "+YlgHO" }),
          time: (
            <span className="font-semibold">
              {localTime.format("dddd, MMM DD[ at] hh:mmA")}
            </span>
          ),
        }}
      />
    </div>
  );
};
