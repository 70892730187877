import {
  GroupDefaultFragment,
  GroupGoalsUpToDateFragment,
  GroupLeaderFragment,
  GroupOkrsNotClosedFragment,
  GroupPulseFragment,
} from "@graphql";
import { isNil } from "lodash";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import { getLinkByType } from "route-configs";
import { Entity } from "common/avatar";
import { Show } from "common/controlFlow";
import { getPulseColorClass } from "common/inputs/Pulse/Pulse.constants";
import { Subtext } from "common/misc/Subtext/Subtext";
import { Pagination } from "common/navigation";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import {
  ReportActions,
  ReportDrilldown,
  ReportDrilldownEntities,
  useReport,
} from "common/reports/index";
import { ReportEntityAction } from "common/reports/utils/types";
import { Cursor } from "common/table/types";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type ReportGroup = Omit<
  GroupDefaultFragment &
    Partial<
      GroupGoalsUpToDateFragment &
        GroupLeaderFragment &
        GroupOkrsNotClosedFragment &
        GroupPulseFragment
    >,
  "__typename"
> & {
  __typename: "company" | "group";
};

type Props = {
  actions?: ReportEntityAction<ReportGroup>[];
  children?: ReactNode;
  groups: ReportGroup[];
  pageInfo?: PageInfo;
  setPage: (page: Cursor) => void;
  totalCount?: number;
};

/**
 * report drilldown, shows group list as Entity + pulse.
 */
export const ReportGroupDrilldown = ({
  actions,
  children,
  groups,
  pageInfo,
  setPage,
  totalCount,
}: Props): JSX.Element => {
  const { $t, formatNumber } = useIntl();

  const { drillDownPageSize, modalIsPaginating, setModalIsPaginating } =
    useReport();

  if (isNil(pageInfo) || isNil(totalCount)) {
    return (
      <ReportDrilldown className="items-center justify-center">
        <Spinner />
      </ReportDrilldown>
    );
  }

  return (
    <ReportDrilldown>
      <Pagination
        entityName={$t(
          {
            defaultMessage:
              "{count} {count, plural, one {group} other {groups}}",
            id: "ZiLZoe",
          },
          {
            count: totalCount,
          }
        )}
        loadingNext={modalIsPaginating === "forward"}
        loadingPrev={modalIsPaginating === "backward"}
        onAfterPaginate={(direction) => setModalIsPaginating(direction)}
        pageInfo={pageInfo}
        pageSize={drillDownPageSize}
        setPage={setPage}
        totalCount={totalCount}
      />
      <ReportDrilldownEntities entities={groups}>
        {(group) => (
          <>
            <div className="flex max-w-sm items-center gap-4">
              <Show when={group.pulse}>
                {(pulse) => (
                  <span
                    className={twClass(
                      "shrink-0 basis-7 font-semibold",
                      getPulseColorClass(pulse)
                    )}
                  >
                    {formatNumber(pulse, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </span>
                )}
              </Show>
              <Show when={group.goalsUpToDate}>
                {(goalsUpToDate) => (
                  <span className="shrink-0 basis-7 font-semibold">
                    {Math.floor(goalsUpToDate * 100)}%
                  </span>
                )}
              </Show>
              <Show when={group.okrsNotClosed}>
                {(okrsNotClosed) => (
                  <span className="shrink-0 basis-7 font-semibold">
                    {okrsNotClosed}
                  </span>
                )}
              </Show>
              <Entity
                avatar={group.avatar}
                avatarSize="medium"
                className="w-auto"
                href={getLinkByType(group.__typename, group.id)}
                name={group.name}
              />
            </div>
            <div className="flex items-center gap-2 text-sm">
              {isSet(group.groupLeader) && (
                <Subtext
                  href={getLinkByType("user", group.groupLeader.id)}
                  icon="person"
                  text={group.groupLeader.fullName}
                />
              )}
              <ReportActions actions={actions} entity={group} />
            </div>
          </>
        )}
      </ReportDrilldownEntities>
      {children}
    </ReportDrilldown>
  );
};
