import { isNil } from "lodash";
import React from "react";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { Show } from "common/controlFlow";
import {
  PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS,
  PeopleManagementSidebarTabBar,
} from "common/peopleManagement";
import { usePerformanceReviewCompleteContext } from "common/performanceReview/context/performanceReviewCompleteContext";
import {
  PeopleManagementTabOption,
  useContextualSidebarTabs,
} from "hooks/useContextualSidebarTabs/useContextualSidebarTabs";
import { PerformanceReviewSidebarKpis } from "./PerformanceReviewSidebarKpis/PerformanceReviewSidebarKpis";
import { PerformanceReviewSidebarKudos } from "./PerformanceReviewSidebarKudos/PerformanceReviewSidebarKudos";
import { PerformanceReviewSidebarObjectives } from "./PerformanceReviewSidebarObjectives/PerformanceReviewSidebarObjectives";

type Props = {
  meetingId: string;
};

export const PerformanceReviewCompleteSidebar = ({
  meetingId,
}: Props): JSX.Element => {
  const { tab, handleTabChange } = useContextualSidebarTabs(meetingId);
  const { show: showKudos } = useFeatureAccess(Feature.kudos);
  const { reviewee } = usePerformanceReviewCompleteContext();

  const revieweeUser = reviewee?.user;
  const revieweeUserId = reviewee?.user?.id;

  const sidebarPages = [
    PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS.GOALS,
    ...(showKudos ? [PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS.KUDOS] : []),
  ];

  return (
    <div className="flex flex-col py-2">
      <PeopleManagementSidebarTabBar
        handleTabChange={handleTabChange}
        options={sidebarPages}
        tab={tab}
      />
      <div>
        <Show when={tab === PeopleManagementTabOption.GOALS}>
          <Show when={!isNil(revieweeUser)}>
            <PerformanceReviewSidebarKpis user={reviewee?.user} />
            <PerformanceReviewSidebarObjectives
              userId={revieweeUserId as string}
            />
          </Show>
        </Show>
        <Show when={tab === PeopleManagementTabOption.KUDOS}>
          <Show when={Boolean(revieweeUserId)}>
            <PerformanceReviewSidebarKudos userId={revieweeUserId as string} />
          </Show>
        </Show>
      </div>
    </div>
  );
};
