import React from "react";
import { twClass } from "utils/twClass";
import empty_okrs from "../../../../public/images/empty_okrs.svg";

type Props = {
  className?: string;
  src?: string;
};

export const PagePlaceholderImage = ({
  className,
  src = empty_okrs.src,
}: Props): JSX.Element => {
  return (
    <img
      alt="empty page"
      className={twClass("h-40 w-40", className)}
      src={src}
    />
  );
};
