import { FieldInputProps } from "formik";
import React, { ReactNode } from "react";
import { RadioGroup } from "common/inputs/RadioGroup";

type Props = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  field: FieldInputProps<string>;
  onChange?: (value: string) => void;
};

export const FormRadioGroup = ({
  className,
  disabled,
  field,
  children,
  onChange,
}: Props): JSX.Element => {
  const handleChange = (value: string) => {
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
    onChange?.(value);
  };
  return (
    <RadioGroup
      className={className}
      disabled={disabled}
      name={field.name}
      onBlur={field.onBlur}
      onChange={handleChange}
      value={field.value}
    >
      {children}
    </RadioGroup>
  );
};
