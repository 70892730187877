import { MetricUnit } from "@graphql";
import classNames from "clsx";
import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ShowFeature } from "common/access/ShowFeature";
import { Show } from "common/controlFlow";
import { AdvancedFields } from "common/form/AdvancedFields/AdvancedFields";
import { AsanaProgressTypeField } from "common/form/AsanaProgressTypeField";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { JiraProgressTypeField } from "common/form/JiraProgressTypeField/JiraProgressTypeField";
import { PlainTextField } from "common/form/PlainTextField/PlainTextField";
import { UserMultiField } from "common/form/UserMultiField/UserMultiField";
import { WithHelper } from "common/form/WithHelper/WithHelper";
import { Label } from "common/inputs/Label/Label";
import { AsanaFormLogic } from "common/integration/AsanaFormLogic/AsanaFormLogic";
import { JiraFormLogic } from "common/integration/JiraFormLogic/JiraFormLogic";
import { useResultFormContext } from "common/result/utils/useResultFormContext/useResultFormContext";
import { TagField } from "common/select/TagMultiSelect/TagField";
import { ShowTagsWrapper } from "common/tag/ShowTagsWrapper/ShowTagsWrapper";
import { useCompany } from "hooks/useCompany/useCompany";
import { isSet } from "utils/isSet";
import { AsanaProjectField } from "../AsanaProjectField/AsanaProjectField";
import { AsanaTaskField } from "../AsanaTaskField/AsanaTaskField";
import { AsanaUpgradeBanner } from "../AsanaUpgradeBanner/AsanaUpgradeBanner";
import { AsanaWorkspaceField } from "../AsanaWorkspaceField/AsanaWorkspaceField";
import { IntegrationField } from "../IntegrationField/IntegrationField";
import { JiraIssueField } from "../JiraIssueField/JiraIssueField";
import { JiraJqlPreview } from "../JiraJqlPreview/JiraJqlPreview";
import {
  JiraSourceType,
  JiraSourceTypeField,
} from "../JiraSourceTypeField/JiraSourceTypeField";
import { helper } from "../ResultForm.helper";
import { messages } from "../ResultForm.messages";
import { ResultFormValues } from "../ResultForm.types";

export const AdvancedSection = (): JSX.Element => {
  const intl = useIntl();
  const company = useCompany();
  const { setFieldValue, values } = useFormikContext<ResultFormValues>();
  const { isAsanaSelected, isJiraSelected } = useResultFormContext();

  const setMetricUnitOnJiraSourceTypeChange = (sourceType: JiraSourceType) => {
    if (sourceType === "issue") {
      setFieldValue("metricUnit", MetricUnit.Percentage);
      setFieldValue("startValue", 0);
      setFieldValue("endValue", 100);
    } else {
      setFieldValue("metricUnit", MetricUnit.Numerical);
    }
  };

  return (
    <AdvancedFields expanded={isSet(values.integration)}>
      <WithHelper data-testid="contributors" helper={helper.contributors}>
        <UserMultiField
          options={{ excludeViewOnly: true }}
          label={intl.formatMessage(messages.contributorsLabel)}
          name="contributors"
          optional
        />
      </WithHelper>

      <WithHelper data-testid="integration">
        <IntegrationField
          data-cy="integration"
          isClearable
          name="integration"
        />
      </WithHelper>

      <Show when={isAsanaSelected}>
        <AsanaFormLogic<ResultFormValues>>
          <WithHelper data-testid="asanaWorkspace">
            <div className="-mt-3 border-x px-4 py-2">
              <AsanaWorkspaceField isClearable />
            </div>
          </WithHelper>

          <WithHelper data-testid="asanaProject">
            <div className="-mt-3 border-x px-4 py-2">
              <AsanaProjectField isClearable />
            </div>
          </WithHelper>

          <WithHelper data-testid="asanaProgressType">
            <div
              className={classNames(
                "-mt-3 space-y-1.5 border-x px-4 py-2 pb-3",
                {
                  "rounded-b border-b":
                    !company.isOnTrialPlan &&
                    values.asanaProgressType !== "task",
                }
              )}
            >
              <Label htmlFor="asanaProgressType">
                {intl.formatMessage(messages.asanaProgressType)}
              </Label>
              <AsanaProgressTypeField />
            </div>
          </WithHelper>

          {values.asanaProgressType === "task" && (
            <WithHelper data-testid="asanaTask">
              <div
                className={classNames("-mt-3 border-x px-4 py-2", {
                  "rounded-b border-b": !company.isOnTrialPlan,
                })}
              >
                <AsanaTaskField isClearable />
              </div>
              <AsanaUpgradeBanner />
            </WithHelper>
          )}
        </AsanaFormLogic>
      </Show>

      <Show when={isJiraSelected}>
        <JiraFormLogic<ResultFormValues>>
          <WithHelper data-testid="jiraProgressType">
            <div className="-mt-3 border-x px-4 pb-2 pt-5">
              <JiraSourceTypeField
                onChange={setMetricUnitOnJiraSourceTypeChange}
              />
            </div>
          </WithHelper>

          <Show when={values.jiraSourceType === "jql"}>
            <WithHelper>
              <div className="space-y-2 rounded-b border-x border-b px-4 py-2">
                <div className="text-sm">
                  <FormattedMessage
                    defaultMessage="Enter query to fetch value"
                    id="X966Hj"
                  />
                </div>
                <PlainTextField
                  name="jiraJql"
                  placeholder="e.g. status = done and project = DEV"
                />
                <JiraJqlPreview value={values.jiraJql ?? ""} />
              </div>
            </WithHelper>
          </Show>

          <Show when={values.jiraSourceType === "issue"}>
            <WithHelper
              data-testid="jiraIssue"
              helper={intl.formatMessage(messages.jiraHelperText)}
            >
              <div className="-mt-3 border-x px-4 py-2">
                <JiraIssueField data-cy="jiraIssue" />
              </div>
            </WithHelper>

            <WithHelper data-testid="jiraProgressType">
              <div className="-mt-3 rounded-b border-x border-b px-4 py-2">
                <JiraProgressTypeField
                  data-cy="jiraProgressType"
                  label={intl.formatMessage(messages.jiraProgressTypeLabel)}
                />
              </div>
            </WithHelper>
          </Show>
        </JiraFormLogic>
      </Show>

      <WithHelper data-testid="startDate">
        <Field name="startDate">
          {({ field, form }: FieldProps) => (
            <FormDatePicker
              field={field}
              form={form}
              label={intl.formatMessage(messages.startDateLabel)}
              optional
            />
          )}
        </Field>
      </WithHelper>

      <ShowFeature feature="INPUT_RESULT_DUE_DATE">
        <WithHelper data-testid="dueDate" helper={helper.dueDate}>
          <Field name="dueDate">
            {({ field, form }: FieldProps) => (
              <FormDatePicker
                field={field}
                form={form}
                label={intl.formatMessage(messages.dueDateLabel)}
                optional
              />
            )}
          </Field>
        </WithHelper>
      </ShowFeature>
      <WithHelper
        helper={
          <FormattedMessage
            defaultMessage="Add tags to easily find related result via filters and search fields"
            id="crWA1t"
          />
        }
      >
        <ShowTagsWrapper name="tags">
          <TagField data-cy="resultTags" name="tags" />
        </ShowTagsWrapper>
      </WithHelper>
    </AdvancedFields>
  );
};
