import React, { MouseEventHandler, ReactNode } from "react";
import { UrlObject } from "url";
import { Icon, toIconSize } from "common/icons/Icon/Icon";
import { IconNameKey, IconType } from "common/icons/Icon/Icon.types";
import { IconSize } from "common/misc/types";
import { AnchorNext } from "common/navigation";
import { TailSpinner } from "common/placeholders/TailSpinner/TailSpinner";
import { twClass } from "utils/twClass";

export type ButtonVariant =
  | "normal"
  | "normal-outline"
  | "ghost"
  | "danger"
  | "negative"
  | "danger-outline";
type Props = {
  active?: boolean;
  children?: ReactNode;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  href?: string | UrlObject;
  hrefTarget?: string;
  icon?: IconNameKey;
  iconSize?: keyof typeof IconSize;
  iconType?: IconType;
  id?: string;
  innerClass?: string;
  isDropdown?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: "large" | "regular" | "small" | "extraSmall";
  type?: "button" | "submit";
  variant?: ButtonVariant;
};
export type ButtonProps = Props;

/**
 * The base button component.
 * set @param href to render inner anchor, else button use @param onClick
 */
export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      "data-cy": dataCy,
      "data-testid": dataTestId,
      active,
      children = "",
      className,
      disabled = false,
      href = "",
      hrefTarget,
      icon,
      iconSize,
      iconType,
      id,
      innerClass,
      isDropdown = false,
      loading = false,
      onClick,
      size = "regular",
      type = "button",
      variant = "normal",
    },
    ref
  ): JSX.Element => {
    const Inner = () => (
      <div
        className={twClass(
          {
            "h-12 px-3 py-2 text-xl font-medium": size === "large",
            "h-5 px-1.5 text-sm font-medium": size === "extraSmall",
            "h-6 px-2 py-0.5 text-sm font-medium": size === "small",
            "px-3 py-2 text-base font-medium": size === "regular",
            // order is important here
            // eslint-disable-next-line sort-keys-fix/sort-keys-fix
            "px-0": variant === "negative",
          },
          "pointer-events-none leading-none",
          "flex items-center",
          innerClass
        )}
      >
        {loading && (
          <TailSpinner
            className={twClass("absolute left-0 right-0 mx-auto", {
              "h-4 w-4": size === "small",
              "h-6 w-6": size === "regular",
              "h-8 w-8": size === "large",
            })}
            disabled={disabled}
            variant={variant}
          />
        )}
        <div
          className={twClass("flex items-center space-x-1", {
            invisible: loading,
          })}
        >
          {icon && (
            <Icon
              name={icon}
              size={iconSize || toIconSize(size)}
              type={iconType}
            />
          )}
          {children && <div>{children}</div>}
          {isDropdown && (
            <Icon
              className="dd-icon"
              name="keyboard_arrow_down"
              size={toIconSize(size)}
            />
          )}
        </div>
      </div>
    );

    return (
      <button
        ref={ref}
        className={twClass(
          "relative flex items-center justify-center rounded disabled:bg-slate-400 disabled:border-slate-400",
          (loading || disabled) && "cursor-not-allowed",
          {
            "bg-blue-700 border-blue-700":
              variant === "normal" && active && !loading,
            "bg-red-700 border-red-700":
              variant === "danger" && active && !loading,
            "bg-transparent text-blue-500 disabled:bg-transparent disabled:text-slate-500":
              variant === "negative",
            "border bg-white text-blue-500 border-blue-500 hover:bg-slate-100 disabled:text-slate-500 disabled:bg-slate-50 disabled:border-slate-300":
              variant === "normal-outline",
            "border bg-white text-red-500 border-red-500 hover:bg-slate-100":
              variant === "danger-outline",
            "border bg-white text-slate-800 border-slate-300 disabled:text-slate-500 disabled:bg-slate-50 disabled:border-slate-300":
              variant === "ghost",
            "border text-white bg-red-500 border-red-500": variant === "danger",
            "border-slate-300 bg-gray-200":
              variant === "ghost" && active && !loading,
            "hover:bg-blue-600 hover:border-blue-600 active:bg-blue-700 active:border-blue-700":
              variant === "normal" && !loading,
            "hover:bg-red-600 hover:border-red-600 active:bg-red-700 active:border-red-700":
              variant === "danger" && !loading,
            "hover:border-bg-gray-100 hover:bg-gray-100 active:bg-slate-300 active:border-slate-300":
              variant === "ghost" && !loading,
            "hover:text-blue-600 active:text-blue-700":
              variant === "negative" && !loading,
            "text-blue-700": variant === "negative" && active && !loading,
            "text-white bg-blue-500": variant === "normal",
          },
          className
        )}
        data-cy={dataCy}
        data-testid={dataTestId}
        disabled={disabled}
        id={id}
        onClick={onClick}
        type={type}
      >
        {href && !disabled && !loading ? (
          <AnchorNext
            className="!text-current hover:!text-current"
            data-testid={`anchor-${dataTestId}`}
            href={href}
            target={hrefTarget}
          >
            <Inner />
          </AnchorNext>
        ) : (
          <Inner />
        )}
      </button>
    );
  }
);

Button.displayName = "Button";
