import dayjs from "dayjs";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { isEmpty } from "lodash";
import Router, { NextRouter } from "next/router";
import * as qs from "qs";
import { routes } from "route-configs";
import invariant from "tiny-invariant";
import { callApi } from "utils/api";
import { logToSentry } from "utils/tracker";

const auth = {
  clearTokens(): void {
    window.localStorage.removeItem("access");
    window.localStorage.removeItem("refresh");
  },
  decodeAccessToken(): JwtPayload | null {
    const access = this.getAccessToken();
    if (!access) return null;
    try {
      return jwtDecode<JwtPayload>(access);
    } catch (e) {
      return null;
    }
  },
  async fetchNewToken(): Promise<string> {
    const refresh = this.getRefreshToken()?.replace("JWT ", "");
    invariant(!!refresh, "Refresh token not found");
    const response = await callApi(
      "/api-token-refresh",
      {
        data: qs.stringify({
          refresh,
        }),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        method: "POST",
      },
      { noRetryOn401: true }
    );
    const token = response?.data?.access as string | undefined;
    if (!token) {
      throw new Error("Unable to refresh token.");
    }
    const jwtToken = `JWT ${token}`;
    this.setAccessToken(jwtToken);
    return jwtToken;
  },
  getAccessToken(): string | null {
    return window.localStorage.getItem("access");
  },
  getRefreshToken(): string | null {
    return window.localStorage.getItem("refresh");
  },
  getTimeUntilExpiry(expiry: Date | null): number {
    return dayjs(expiry).diff(dayjs(), "milliseconds");
  },
  getTokenExpiryDatetime(): Date | null {
    const decoded = this.decodeAccessToken();
    return !decoded?.exp ? null : new Date(decoded.exp * 1000);
  },
  /**
   * Determines if the user was logged in via admin panel.
   */
  isLoggedInAs(): boolean {
    const decoded = this.decodeAccessToken();
    return (
      !!decoded &&
      "originalUserId" in decoded &&
      !isEmpty(decoded.originalUserId)
    );
  },
  isTokenExpired(): boolean {
    const now = Date.now().valueOf() / 1000;
    const decoded = this.decodeAccessToken();
    if (!decoded || !decoded.exp) return true;
    return decoded.exp < now;
  },
  loggedIn(): boolean {
    return !!this.getAccessToken();
  },
  logout(props?: {
    msTeams?: boolean;
    reason?: string;
    router?: NextRouter;
  }): void {
    const router = props?.router ?? Router;
    const query = {
      ...router.query,
    };

    if (router.pathname !== "/") query.redirectTo = router.pathname;

    router.push({
      pathname: props?.msTeams ? routes.msTeams.authenticate : routes.logout,
      query,
    });

    logToSentry("User was logged out", { reason: props?.reason });
  },
  setAccessToken(access: string): void {
    window.localStorage.setItem("access", access);
  },

  setTokens(access: string, refresh: string): void {
    this.setAccessToken(access);
    window.localStorage.setItem("refresh", refresh);
  },
};

export { auth };
