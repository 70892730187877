import {
  GetActiveUsersDocument,
  UserAvatarFragment,
  UserDefaultFragment,
} from "@graphql";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionProps } from "@tiptap/suggestion";
import { apolloClient } from "apolloClient";
import tippy from "tippy.js";
import { mapEdges } from "utils/mapEdges";
import { MentionList } from "./MentionList";

type User = UserDefaultFragment & UserAvatarFragment;

export const suggestion = {
  items: async ({ query }: { query: string }) => {
    try {
      const { data, loading, error } = await apolloClient.query({
        fetchPolicy: "network-only",
        query: GetActiveUsersDocument,
      });

      if (loading || error) {
        return [];
      }

      const users = mapEdges<User>(data?.allUsers?.edges);
      return users
        .map((user) => ({
          avatar: user.avatar,
          id: user.id,
          name: user.fullName,
        }))
        .filter((item) =>
          item.name.toLowerCase().startsWith(query.toLowerCase())
        );
    } catch (e) {
      return [];
    }
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onExit() {
        popup?.[0].destroy();
        // TODO: destroying a component is causing an error with null docView, investigate
        // https://discuss.prosemirror.net/t/what-could-create-a-null-docview/1830
        // component.destroy();
      },

      onKeyDown(props: any) {
        if (props.event.key === "Escape") {
          popup?.[0].hide();

          return true;
        }

        return component?.ref?.onKeyDown(props);
      },

      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(MentionList, {
          editor: props.editor,
          props,
        });

        if (!props.clientRect) {
          return;
        }
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        popup = tippy("body", {
          appendTo: () => document.body,
          content: component.element,
          getReferenceClientRect: props.clientRect,
          interactive: true,
          placement: "bottom-start",
          showOnCreate: true,
          theme: "perdoo-light",
          trigger: "manual",
        });
      },

      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }
        popup?.[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },
    };
  },
};
