import { MetricValue } from "constants/metric";
import { useFormatMetric } from "./hooks/useFormatMetric";

type Props = {
  unit?: MetricValue;
  value: number;
};

export const FormattedMetric = ({ unit, value }: Props): JSX.Element => {
  const { formatMetric } = useFormatMetric();
  return <>{formatMetric(value, unit)}</>;
};
