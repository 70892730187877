import { useIntl } from "react-intl";
import { OptionType } from "legacy/components/Select/Select.types";
import { messages } from "./useJiraProgressTypeOptions.messages";

type Configuration = {
  includeEpicOptions: boolean;
};

export const useJiraProgressTypeOptions = ({
  includeEpicOptions,
}: Configuration): OptionType[] => {
  const intl = useIntl();

  const commonOptions = [
    { label: intl.formatMessage(messages.statusLabel), value: "status" },
  ];

  const epicOptions = [
    {
      label: intl.formatMessage(messages.issueCountLabel),
      value: "issue_count",
    },
    {
      label: intl.formatMessage(messages.storyPointsLabel),
      value: "story_points",
    },
  ];

  let options = commonOptions;

  if (includeEpicOptions) {
    options = options.concat(epicOptions);
  }

  return options;
};
