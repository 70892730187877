import { colorTheme } from "./colorTheme";

export const tagColors = [
  colorTheme.slate[900],
  colorTheme.indigo[800],
  colorTheme.blue[200],
  colorTheme.blue[500],
  colorTheme.green[900],
  colorTheme.green[800],
  colorTheme.green[500],
  colorTheme.green[200],
  colorTheme.red[900],
  colorTheme.red[800],
  colorTheme.red[500],
  colorTheme.red[200],
  colorTheme.yellow[900],
  colorTheme.yellow[800],
  colorTheme.yellow[500],
  colorTheme.yellow[200],
  colorTheme.slate[800],
  colorTheme.slate[300],
  colorTheme.slate[100],
  colorTheme.slate[50],
];
