import { isEmpty, orderBy } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Entity, StackedAvatar } from "common/avatar";
import { TextButton } from "common/buttons";
import { Show } from "common/controlFlow";
import { Clickable } from "common/navigation/Clickable";
import { useOwners } from "hooks/useOwners/useOwners";
import { useToggle } from "hooks/useToggle/useToggle";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { twClass } from "utils/twClass";
import { CloseFn, MultiSelect } from "../MultiSelect/MultiSelect";

type Value = string[];

type Option = {
  images: string[];
  label: string;
  value: string;
};

type Props = {
  "data-cy"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => void;
  value: Value;
};

export const InlineOwnerMultiSelect = ({
  "data-cy": dataCy,
  onChange,
  value,
  disabled,
}: Props) => {
  const [open, toggleOpen, setOpen] = useToggle(false);
  const { owners } = useOwners({ selected: value });
  const options: Option[] = useMemo(() => {
    const options = owners.map((owner) => ({
      images: owner.avatar ? [owner.avatar] : [],
      label: owner.name,
      selected: value.includes(owner.id),
      value: owner.id,
    }));
    return orderBy(options, ["selected"], ["desc"]);
  }, [owners, value]);
  const selected = options.filter((user) => value.includes(user.value));

  const handleClose: CloseFn<Option> = (selectedOptions) => {
    toggleOpen();
    if (!selectedOptions) return;
    onChange?.(selectedOptions.map((opt) => opt.value));
  };

  const avatars = selected.flatMap((option) => option.images);

  return (
    <Dropdown
      open={open}
      onToggle={setOpen}
      disabled={disabled}
      button={
        !isEmpty(selected) ? (
          <Clickable
            data-cy={dataCy}
            className={twClass(
              "w-full space-y-2 border border-transparent  data-[state=open]:bg-white data-[state=open]:border-slate-300 rounded p-2 -ml-2",
              {
                "cursor-not-allowed": disabled,
                "hover:bg-white hover:border-slate-300": !disabled,
              }
            )}
          >
            <Show when={selected.length === 1}>
              <Entity
                key={selected[0]!.value}
                name={selected[0]!.label}
                avatar={selected[0]!.images[0]}
              />
            </Show>
            <Show when={selected.length > 1}>
              <div className="flex items-center">
                <div>
                  <StackedAvatar size="small" urls={avatars} />
                </div>
                <div className="ml-4">
                  <FormattedMessage
                    defaultMessage="{count} owners"
                    id="nbvLbM"
                    values={{
                      count: selected.length,
                    }}
                  />
                </div>
              </div>
            </Show>
          </Clickable>
        ) : (
          <TextButton className="p-0" size="small" data-cy={dataCy}>
            +&nbsp;
            <FormattedMessage defaultMessage="Add owner" id="bLmnuX" />
          </TextButton>
        )
      }
      className="block"
    >
      {open && (
        <MultiSelect
          onClose={handleClose}
          options={options}
          initialValue={value}
          required={false}
          saveText={<FormattedMessage defaultMessage="Done" id="JXdbo8" />}
        />
      )}
    </Dropdown>
  );
};
