import Fuse from "fuse.js";
import { orderBy, take } from "lodash";
import { useEffect, useMemo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { makeSelect } from "./Select";
import { SelectBaseItem } from "./Select.types";
import { isSelectableEmptyState } from "./Select.utils";

type Hook<SelectOption extends SelectBaseItem> = {
  handleSearch: (query: string) => void;
  handleSelect: (option: SelectOption | null) => void;
  query: string;
  Select: ReturnType<typeof makeSelect<SelectOption>>;
  selected: SelectOption | null;
  visibleOptions: SelectOption[];
};

type Props<SelectOption extends SelectBaseItem> = {
  defaultItem?: SelectOption;
  /**
   * pass to customize how options are mapped, e.g. add custom sorting or limit.
   * by default, it orders by "fullName" and "name", and returns first 10 items.
   */
  mapFn?: (options: SelectOption[]) => SelectOption[];
  // pass all options to select from
  options: SelectOption[];
  /**
   * pass to customize how we find items. by default uses fuzzy search with Fuse library with 0.3 threshold.
   */
  searchFn?: (options: SelectOption[], query: string) => SelectOption[];
};

export const useSelect = <SelectOption extends SelectBaseItem>({
  defaultItem,
  mapFn,
  options,
  searchFn,
}: Props<SelectOption>): Hook<SelectOption> => {
  const defaultMap = (originalOptions: SelectOption[]) => {
    const ordered = orderBy(originalOptions, [
      (a) => !isSelectableEmptyState(a),
      "fullName",
      "name",
      "email",
    ]);
    return ordered;
  };

  const defaultSearch = (searchOptions: SelectOption[], query: string) => {
    // Fuse returns empty array with empty query, but we want to use it for filtering, so we actually want the entire array.
    // can remove this line if this issue gets resolved: https://github.com/krisk/Fuse/issues/229
    if (!query) return searchOptions;

    return new Fuse(searchOptions, {
      findAllMatches: true,
      isCaseSensitive: false,
      keys: ["fullName", "name", "email"],
      // 0 = only perfect match, 1 = matches anything
      threshold: 0.3,
    })
      .search(query)
      .map((r) => r.item);
  };

  const map = mapFn ?? defaultMap;
  const search = searchFn ?? defaultSearch;

  const defaultOptions = useMemo(() => map(options), [options]);
  const [visibleOptions, setVisibleOptions] = useState(defaultOptions);
  const [selected, setSelected] = useState<SelectOption | null>(
    defaultItem ?? null
  );
  const [query, setQuery] = useState("");

  useDeepCompareEffect(() => {
    setVisibleOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    if (defaultItem?.id !== selected?.id) {
      setSelected(defaultItem ?? null);
    }
  }, [defaultItem]);

  const handleSearch = (newQuery: string) => {
    setQuery(newQuery);
    if (!newQuery) {
      setVisibleOptions(defaultOptions);
      return;
    }

    const matches = search(options, newQuery);
    const mappedMatches = map(matches);

    setVisibleOptions(mappedMatches);
  };
  const resetSearch = handleSearch.bind(null, "");

  const handleSelect = (option: SelectOption | null) => {
    setSelected(option);
    setVisibleOptions(defaultOptions);
    resetSearch();
  };

  const Select = makeSelect<SelectOption>();

  return {
    handleSearch,
    handleSelect,
    query,
    Select,
    selected,
    visibleOptions,
  };
};
