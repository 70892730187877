import { ReportUserFragment } from "@graphql";
import { isFunction, isNil } from "lodash";
import { useIntl } from "react-intl";
import { getNudgeMailtoMessage } from "common/reports";
import { ReportAction, ReportEntityAction } from "common/reports/utils/types";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { goalUpdateCycleLabel } from "modules/company/Company/Company.constants";
import {
  messages,
  tooltips,
} from "modules/reports/engagement/EngagementReport/EngagementReport.messages";
import { toast } from "utils/toastr";

type Hook = {
  handleNudgeAll: () => Promise<void>;
  nudgeAction: ReportEntityAction;
  nudgeAllAction: ReportAction;
};

/**
 * provides nudge actions. nudging = opens a mailto link with a predefined message.<br />
 * `nudgeAction` is a {@link ReportEntityAction} that describes nudging an individual user. <br />
 * `handleNudgeAll` is a handler for nudging all users from the passed `emails` array.
 */
export const useNudge = (
  emails?: string[] | (() => Promise<string[]>)
): Hook => {
  const intl = useIntl();
  const { checkInFrequency } = useCompany();
  const me = useCurrentUser();

  const getNudgeHandler =
    ({ email, firstName, fullName }: ReportUserFragment) =>
    () => {
      const recipient = firstName ?? fullName;
      const sender = me?.firstName ?? me?.fullName ?? "";
      const checkInCycle = checkInFrequency;
      const frequency = goalUpdateCycleLabel[checkInCycle];
      const nudge = getNudgeMailtoMessage({ frequency, recipient, sender });
      window.open(
        `mailto:${email}?subject=${nudge.noCheckInThisWeek.subject}&body=${nudge.noCheckInThisWeek.body}`,
        "blank",
        "noreferrer"
      );
    };

  const handleNudgeAll = async () => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const userEmails = isFunction(emails) ? await emails?.() : emails;
    if (isNil(me) || isNil(userEmails)) {
      toast.failure(
        intl.formatMessage({
          defaultMessage:
            "Something went wrong. Please contact our Support Team if this happens again.",
          id: "global:something:wrong:extended",
        })
      );
      return;
    }

    const sender = me.firstName ?? me.fullName;
    const frequency = goalUpdateCycleLabel[checkInFrequency];
    const nudge = getNudgeMailtoMessage({ frequency, sender });

    window.open(
      `mailto:${me.email}?subject=${nudge.noCheckInThisWeek.subject}&body=${
        nudge.noCheckInThisWeek.body
      }&bcc=${userEmails.join(",")}`,
      "blank",
      "noreferrer"
    );
  };

  const nudgeAction = {
    handler: getNudgeHandler,
    id: "nudge",
    title: messages.nudge,
    tooltip: intl.formatMessage(tooltips.nudge),
  };

  const nudgeAllAction: ReportAction = {
    handler: handleNudgeAll,
    id: "nudge",
    title: intl.formatMessage(messages.nudgeAll),
  };
  return { handleNudgeAll, nudgeAction, nudgeAllAction };
};
