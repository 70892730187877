import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { Icon } from "common/icons";
import { AnchorNextTrack } from "common/navigation";
import { SupportLinksProps } from "common/support/SupportLinks/SupportLinks.types";
import { SUPPORT_VIEWED } from "constants/tracking";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { SupportVideo } from "../SupportVideo/SupportVideo";

export const SupportLinks: React.FC<SupportLinksProps> = ({
  getStartedLink,
  videoUrl,
  page,
  supportText,
  className,
}) => {
  return (
    <div
      className={twClass("hidden items-center md:flex print:hidden", className)}
    >
      {getStartedLink && (
        <AnchorNextTrack
          data-cy="WRWEY1P9NFxWsAI7C4vxc"
          className="mr-5"
          href={getStartedLink}
          onClick={() => track(SUPPORT_VIEWED, { page, type: "Article" })}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="flex items-center">
            {/* The `-ml-1` is to remove the extra left-spacing this icon has */}
            <Icon className="-ml-1 mr-1" name="description" size="lg" />
            {supportText || (
              <FormattedMessage
                defaultMessage="How to get started"
                id="supportLinks:getStarted"
              />
            )}
          </div>
        </AnchorNextTrack>
      )}
      {videoUrl && (
        <SupportVideo videoUrl={videoUrl}>
          {(openVideo) => (
            <Button
              data-cy="yYfzzj9w61LC_ZVnKzclL"
              className="text-blue-500 hover:text-indigo-800"
              icon="ondemand_video"
              variant="negative"
              onClick={() => {
                openVideo();
                track(SUPPORT_VIEWED, { page, type: "Video" });
              }}
              size="small"
            >
              <FormattedMessage
                defaultMessage="Watch a video"
                id="supportLinks:watchVideo"
              />
            </Button>
          )}
        </SupportVideo>
      )}
    </div>
  );
};
