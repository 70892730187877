import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  ButtonGroup,
  ButtonGroupOption,
} from "common/buttons/ButtonGroup/ButtonGroup";
import { BillingPeriod } from "../UpgradeModal.types";

type Props = {
  onChange: (value: BillingPeriod) => void;
  value: BillingPeriod;
};

const messages = defineMessages({
  annuallyLabel: {
    defaultMessage: "Billed annually",
    id: "global:billingPeriod:year:label",
  },
  quarterlyLabel: {
    defaultMessage: "Billed quarterly",
    id: "global:billingPeriod:quarter:label",
  },
});

export const BillingPeriodSelector = ({
  value,
  onChange,
}: Props): JSX.Element => {
  const intl = useIntl();
  const options: ButtonGroupOption<BillingPeriod>[] = [
    {
      label: intl.formatMessage(messages.annuallyLabel),
      pill: "-10%",
      value: "ANNUAL",
    },
    { label: intl.formatMessage(messages.quarterlyLabel), value: "QUARTERLY" },
  ];
  return (
    <ButtonGroup
      onChange={onChange}
      options={options}
      size="lg"
      value={value}
    />
  );
};
