import {
  UserAvatarFragment,
  UserEmailFragment,
  UserFullNameFragment,
  UserStatusFragment,
} from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { useAddUser } from "common/select/UserSelect/useAddUser";
import { Select } from "legacy/components/Select/Select";
import {
  isNewSelectItem,
  SelectNewItem,
} from "legacy/components/Select/Select.types";
import { isSet } from "utils/isSet";
import { isValidEmail } from "utils/isValidEmail";

type User = UserFullNameFragment &
  UserAvatarFragment &
  UserEmailFragment &
  UserStatusFragment;
export type UserMultiSelectUser = User;

type Props = {
  autoFocus?: boolean;
  creatable?: boolean;
  "data-cy"?: string;
  /** for using inside form */
  name?: string;
  onChange: (u: User[]) => void;
  selectedUsers: User[];
  users: User[];
};

export const UserMultiSelect = ({
  autoFocus,
  creatable,
  "data-cy": dataCy,
  name,
  onChange,
  selectedUsers,
  users,
}: Props): JSX.Element => {
  const { loading, inviteUser } = useAddUser();

  const handleChange = (newUsers: User[] | null) => {
    newUsers ??= [];
    onChange(newUsers);
  };

  const handleCreate = async (email: string) => {
    const newUser = await inviteUser(email);
    if (!newUser) return;
    handleChange([...selectedUsers, newUser]);
  };

  const getEntity = (user: User | SelectNewItem) => {
    if (isNewSelectItem(user)) {
      if (!creatable) return null;
      return (
        <FormattedMessage
          defaultMessage="Invite {email} to Perdoo"
          id="Lr87n4"
          values={{ email: user.value }}
        />
      );
    }

    return (
      <Entity
        avatar={user.avatar}
        avatarSize="extraSmall"
        name={user.fullName}
      />
    );
  };

  const search = (
    { data }: { data: User | SelectNewItem },
    query: string
  ): boolean => {
    if (isNewSelectItem(data)) {
      if (!creatable) return false;
      return data.value.toLowerCase().includes(query.toLowerCase());
    }
    return data.fullName.toLowerCase().includes(query.toLowerCase());
  };

  const emptyState = () => {
    return creatable ? (
      <FormattedMessage
        defaultMessage="This person hasn't been invited yet. Enter their email address to invite them now."
        id="forms:invite:user"
      />
    ) : (
      <FormattedMessage defaultMessage="No options" id="pfrU/9" />
    );
  };

  return (
    <Select
      autoFocus={autoFocus}
      className="userMultiSelect"
      closeMenuOnSelect={false}
      creatable={creatable}
      data-cy={dataCy ?? "userMultiSelect"}
      filterOption={search}
      formatCreateLabel={(query: string) => (
        <FormattedMessage
          defaultMessage="Invite {email} to Perdoo"
          id="Lr87n4"
          values={{ email: query }}
        />
      )}
      getOptionLabel={getEntity}
      getOptionValue={({ id }: User) => id}
      isInForm={isSet(name)}
      isLoading={loading}
      isMulti
      isValidNewOption={isValidEmail}
      name={name}
      noOptionsMessage={emptyState}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={users}
      value={selectedUsers}
    />
  );
};
