import { Event as GqlEvent } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isEmpty } from "lodash";
import * as React from "react";
import { NotificationEmptyList } from "./NotificationEmptyList/NotificationEmptyList";
import { NotificationInfiniteList } from "./NotificationInfiniteList/NotificationInfiniteList";
import { NotificationItemWrapper } from "./NotificationItemWrapper/NotificationItemWrapper";

type NotificationProp = Derive<GqlEvent, { eventDate: true; id: true }>;

type Props = {
  fetchMore: () => void;
  hasNextPage: boolean | undefined;
  lastNotificationRead: string | null | undefined;
  loading: boolean;
  notifications: NotificationProp[];
};

export const NotificationList = ({
  notifications,
  fetchMore,
  loading,
  hasNextPage,
  lastNotificationRead,
}: Props) => {
  const data = notifications.map((notification) => {
    const isUnread = !!lastNotificationRead
      ? notification.eventDate > lastNotificationRead
      : false;
    return (
      <NotificationItemWrapper
        key={notification.id}
        isUnread={isUnread}
        notification={notification}
      />
    );
  });

  return isEmpty(notifications) ? (
    <NotificationEmptyList />
  ) : (
    <NotificationInfiniteList
      data={data}
      fetchMore={fetchMore}
      hasNextPage={hasNextPage}
      loading={loading}
    />
  );
};
