import hotToast from "react-hot-toast";

export const toast = {
  failure(title: string): void {
    hotToast.error(title);
  },
  info(title: string): void {
    hotToast(title, {
      icon: "💬",
    });
  },
  success(title: string): void {
    hotToast.success(title);
  },
};
