import { FetchResult } from "@apollo/client";
import {
  CommitDefaultFieldsFragment,
  CommitDescriptionFragment,
  CommitUserFragment,
  CommitValuesFragment,
  Maybe,
  MetricUnit,
  PerdooApiIntegrationApplicationChoices,
  PushIntegrationApplication,
  UpsertCommitMutationData,
} from "@graphql";
import React, {
  forwardRef,
  MouseEventHandler,
  useImperativeHandle,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import {
  ProgressUpdatePanel,
  ProgressUpdatePanelTypes,
  ProgressUpdatePanelValues,
} from "common/goal/ProgressUpdatePanel/ProgressUpdatePanel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useToggle } from "hooks/useToggle/useToggle";
import { twMerge } from "utils/twMerge";

const titles = defineMessages<ProgressUpdatePanelTypes>({
  edit: {
    defaultMessage: "Edit",
    id: "modal:update:edit",
  },
  INITIATIVE: {
    defaultMessage: "Update progress",
    id: "modal:update:progress",
  },
  KEY_RESULT: {
    defaultMessage: "Update progress",
    id: "modal:update:progress",
  },
  kpi: {
    defaultMessage: "Update KPI",
    id: "kpi:update:title",
  },
  result: {
    defaultMessage: "Update progress",
    id: "modal:update:progress",
  },
});

export type ProgressUpdateButtonRef = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
};

type Props = {
  apiIntegration?: PushIntegrationApplication | null;
  application?: PerdooApiIntegrationApplicationChoices | null;
  backdating?: boolean;
  button?: React.ReactElement;
  buttonClass?: string;
  buttonOpenClass?: string;
  /** passed to panel, use {@link buttonClass} to style button. */
  className?: string;
  disabled?: boolean;
  initialValues: ProgressUpdatePanelValues;
  lastCommit?: Maybe<
    CommitDefaultFieldsFragment &
      CommitDescriptionFragment &
      CommitUserFragment &
      CommitValuesFragment
  >;
  metricUnit: MetricUnit;
  onSubmit: (
    values: ProgressUpdatePanelValues
  ) => Promise<FetchResult<UpsertCommitMutationData>> | void;
  positiveMetric?: boolean;
  resetGoal?: () => void;
  type: ProgressUpdatePanelTypes;
};

/**
 * progress update button for KPI / result which brings up the {@link ProgressUpdatePanel} on click.
 */
export const ProgressUpdateButton = forwardRef<ProgressUpdateButtonRef, Props>(
  (
    {
      apiIntegration,
      application,
      backdating,
      button,
      buttonClass,
      buttonOpenClass,
      className,
      disabled,
      initialValues,
      lastCommit,
      metricUnit,
      onSubmit,
      positiveMetric,
      resetGoal,
      type = "edit",
    },
    ref
  ): JSX.Element => {
    const intl = useIntl();
    const [showPanel, togglePanel, setShowPanel] = useToggle(false);

    useImperativeHandle(ref, () => ({
      isOpen: showPanel,
      setIsOpen: setShowPanel,
    }));
    const title = intl.formatMessage(titles[type]);

    const handleToggle: MouseEventHandler<HTMLButtonElement> = (e) => {
      // prevent triggering fold / unfold events on roadmap
      e.stopPropagation();
      togglePanel();
    };

    const progressUpdatePanel = (
      <ProgressUpdatePanel
        className={twMerge("w-[27rem]", className)}
        onClose={() => setShowPanel(false)}
        resetGoal={resetGoal}
        type={type}
        {...{
          apiIntegration,
          application,
          backdating,
          initialValues,
          lastCommit,
          metricUnit,
          onSubmit,
          positiveMetric,
          title,
        }}
      />
    );

    const defaultOpenClass = "visible";
    const buttonClassMerged = showPanel
      ? twMerge(buttonClass, defaultOpenClass, buttonOpenClass)
      : buttonClass;

    let updateButton = (
      <IconButton
        className={buttonClassMerged}
        data-cy="updateProgressButton"
        data-testid="progressUpdateButton"
        disabled={disabled}
        name="update_progress"
        onClick={handleToggle}
      />
    );

    if (button) {
      updateButton = React.cloneElement(button, {
        className: buttonClassMerged,
        "data-cy": "updateProgressButton",
        "data-testid": "progressUpdateButton",
        disabled,
        onClick: handleToggle,
      });
    }

    return (
      <WithTooltip
        interactive
        maxWidth=""
        placement="left"
        theme="perdoo-white"
        tooltip={showPanel && progressUpdatePanel}
        visible={showPanel}
      >
        {updateButton}
      </WithTooltip>
    );
  }
);

ProgressUpdateButton.displayName = "ProgressUpdateButton";
