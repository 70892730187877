import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { logToSentry } from "../../../utils/tracker";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const {
  KeyResultChanged,
  KeyResultCreated,
  KeyResultDeleted,
  KeyResultRelationRemoved,
  KeyResultRelationAdded,
} = EventTypes;

export const KeyResultEventFormatters: Record<string, EventFormatter> = {
  [KeyResultChanged]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.change.attribute) {
        case "name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="renamed {oldName} to {newName}"
              id="event:formatter:keyResult:renamed"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "lead": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the lead from {oldName} to {newName}"
              id="event:formatter:keyResult:lead"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "description": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="updated the description of {krName}"
              id="event:formatter:keyResult:changedDescription"
              values={{
                krName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "start_value": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the start value of {krName} from {oldVal} to {newVal}"
              id="event:formatter:keyResult:changedStart"
              values={{
                krName: <Target element={extra.name} />,
                newVal: (
                  <Target>
                    <FormattedNumber value={extra.change.new_value} />
                  </Target>
                ),
                oldVal: (
                  <Target>
                    <FormattedNumber value={extra.change.old_value} />
                  </Target>
                ),
              }}
            />
          );
          break;
        }
        case "end_value": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the end value of {krName} from {oldVal} to {newVal}"
              id="event:formatter:keyResult:changedEnd"
              values={{
                krName: <Target element={extra.name} />,
                newVal: (
                  <Target>
                    <FormattedNumber value={extra.change.new_value} />
                  </Target>
                ),
                oldVal: (
                  <Target>
                    <FormattedNumber value={extra.change.old_value} />
                  </Target>
                ),
              }}
            />
          );
          break;
        }
        case "metric_unit": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the metric unit of {krName} from {oldVal} to {newVal}"
              id="event:formatter:keyResult:changedUnit"
              values={{
                krName: <Target element={extra.name} />,
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "metric_type": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the metric type of {krName} from {oldVal} to {newVal}"
              id="event:formatter:keyResult:changedType"
              values={{
                krName: <Target element={extra.name} />,
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: KeyResultChanged,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
        }
      }
      return formattedEvent;
    },
  },
  [KeyResultCreated]: {
    getComment: () => null,
    getString: (attr) => (
      <FormattedMessage
        defaultMessage="created the Key Result {krName}"
        id="event:formatter:keyResult:created"
        values={{
          krName: <Target element={attr.extra.name} />,
        }}
      />
    ),
  },
  [KeyResultDeleted]: {
    getComment: () => null,
    getString: (attr) => (
      <FormattedMessage
        defaultMessage="deleted the Key Result {krName}"
        id="event:formatter:keyResult:deleted"
        values={{
          krName: <Target element={attr.extra.name} />,
        }}
      />
    ),
  },
  [KeyResultRelationAdded]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "contributors": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="added {contrCount} contributor(s) to the Key Result {oName}"
              id="event:formatter:keyResult:contributorsAdded"
              values={{
                contrCount: <Target element={extra.relation.pk_set.length} />,
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: KeyResultRelationAdded,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
  [KeyResultRelationRemoved]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "contributors": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="removed {contrCount} contributor(s) from the Key Result {oName}"
              id="event:formatter:keyResult:contributorsRemoved"
              values={{
                contrCount: <Target element={extra.relation.pk_set.length} />,
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: KeyResultRelationRemoved,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
};
