import { useRouter } from "next/router";
import React from "react";
import { getCompleteLink } from "route-configs";
import { AnchorNext } from "common/navigation";
import { twClass } from "utils/twClass";
import { Tab, TabSize } from "../Tab/Tab";

interface TabLinkProp {
  active?: boolean;
  className?: string;
  data?: Record<string, string>;
  "data-cy"?: string;
  "data-testid"?: string;
  link: string;
  linkActiveList?: string[];
  query?: Record<string, string>;
  size?: keyof typeof TabSize;
  title: string | React.ReactNode;
}

export const TabLink: React.FC<TabLinkProp> = ({
  className,
  data,
  query,
  link,
  title,
  linkActiveList,
  size = "base",
  active,
  "data-cy": dataCy,
  "data-testid": dataTestId,
}) => {
  const { pathname } = useRouter();
  const isLinkActive = (url: string) => pathname.startsWith(url);

  const completelink = getCompleteLink(link, data, query);
  const isActive = linkActiveList
    ? [...linkActiveList, link].some(isLinkActive)
    : isLinkActive(link);

  return (
    <AnchorNext
      data-cy={dataCy}
      data-testid={dataTestId}
      href={completelink.absolutePath}
    >
      <Tab
        active={active ?? isActive}
        className={twClass(className)}
        size={size}
        title={title}
      />
    </AnchorNext>
  );
};
