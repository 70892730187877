import {
  GetOneOnOneSeriesDocument,
  namedOperations,
  OneOnOneMeetingDefaultFragment,
  OneOnOneRepeatFrequency,
  useGetOneOnOneMeetingQuery,
  useGetOneOnOneSeriesQuery,
} from "@graphql";
import dayjs from "dayjs";
import { Formik, FormikHelpers } from "formik";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Show } from "common/controlFlow";
import { Modal } from "common/overlay/Modal/Modal";
import { NotFoundModal } from "common/overlay/NotFoundModal/NotFoundModal";
import { ONE_ON_ONE_MEETING_COMPLETED } from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useToggle } from "hooks/useToggle/useToggle";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { handleError } from "utils/utils";
import {
  CompleteOneOnOneContext,
  CompleteOneOnOneProvider,
  OneOnOneFormSavingStatus,
} from "../context/completeOneOnOneContext";
import { useHandleOneOnOneMutations } from "../hooks/useHandleOneOnOneMutations";
import { CompleteMeetingInput } from "../OneOnOneCompleteForm/OneOnOneCompleteDatePicker";
import {
  CompleteOneOnOneValues,
  OneOnOneCompleteForm,
} from "../OneOnOneCompleteForm/OneOnOneCompleteForm";
import { OneOnOneCompleteFormFooter } from "../OneOnOneCompleteForm/OneOnOneCompleteFormFooter";
import { OneOnOneCompleteFormHeader } from "../OneOnOneCompleteForm/OneOnOneCompleteFormHeader";
import { OneOnOneModalLayout } from "../OneOnOneCompleteForm/OneOnOneCompleteFormLayout";
import { OneOnOneCompleteSidebar } from "../OneOnOneCompleteForm/OneOnOneCompleteSidebar";
import { OneOnOneCompleteSkeleton } from "../OneOnOneCompleteForm/OneOnOneCompleteSkeleton";
import { OneOnOneMeetingItem } from "../OneOnOneCompleteForm/OneOnOneMeetingItemsField/OneOnOneMeetingItemsField";
import {
  InactiveMeetingMessage,
  MeetingCompleteMessage,
} from "../OneOnOneCompleteForm/OneOnOneMessages";
import { OneOnOneCompleteView } from "../OneOnOneCompleteView/OneOnOneCompleteView";
import {
  getMeetingItems,
  removeEmptyAndCheckCompletedMeetingItems,
} from "../oneOnOnemeeting.utils";
import { OneOnOneMeetingItemTemplatesView } from "../OneOnOneMeetingItemTemplatesView/OneOnOneMeetingItemTemplatesView";
import { oneOneOneMeetingPlaceholder } from "./OneOnOneModal.utils";
import { ScheduleNextOneOnOneMeetingModal } from "./ScheduleNextOneOnOneMeetingModal";

const POLLING_INTERVAL = 10_000;

type Props = {
  meetingId: string;
  seriesId: string;
};

export const OneOnOneModal = ({
  meetingId,
  seriesId,
}: Props): JSX.Element | null => {
  const me = useCurrentUser();
  const intl = useIntl();
  const { $t } = intl;
  const { close } = useModalRouter();

  // used to display status in the footer + we briefly disable CRUD actions while saving to prevent conflicts
  const [savingStatus, setSavingStatus] =
    useState<OneOnOneFormSavingStatus>("idle");
  const [showTemplateView, setShowTemplateView] = useState(false);
  const [showScheduleNextMeeting, toggleShowScheduleNextMeeting] =
    useToggle(false);

  const userDirectReportsIds = mapEdges(me?.directReports.edges).map(
    ({ id }) => id
  );
  const userManagerId = me?.manager?.id;

  const { completeOneOnOneMeeting, upsertOneOnOneMeeting } =
    useHandleOneOnOneMutations({
      refetchQueries: [
        namedOperations.Query.GetOneOnOneMeeting,
        namedOperations.Query.GetAllUserOneOnOneSeries,
        {
          query: GetOneOnOneSeriesDocument,
          variables: {
            id: seriesId,
          },
        },
      ],
    });

  const {
    data,
    error: meetingError,
    refetch: refetchMeeting,
    startPolling: _startPolling,
    stopPolling,
  } = useGetOneOnOneMeetingQuery({
    onError: (err) => {
      handleError(err);
      close();
    },
    variables: { id: meetingId },
  });
  const startPolling = _startPolling.bind(null, POLLING_INTERVAL);

  const {
    deleteOneOnOneMeetingItem,
    upsertMeetingItems,
    upsertOneOnOneMeetingItem,
  } = useHandleOneOnOneMutations();

  const {
    data: seriesData,
    error: seriesError,
    refetch: refetchSeries,
  } = useGetOneOnOneSeriesQuery({
    onError: (err) => {
      handleError(err);
      close();
    },
    variables: { id: seriesId },
  });

  const isMeetingComplete = data?.oneOnOneMeeting?.isComplete ?? false;

  // disable sync while browsing templates
  useEffect(() => {
    if (isMeetingComplete) return;
    if (showTemplateView) {
      stopPolling();
      return;
    }
    refetchMeeting();
    startPolling();
  }, [showTemplateView]);

  // enable sync for active meetings
  useEffect(() => {
    if (showTemplateView) return;
    if (!isMeetingComplete) {
      startPolling();
    }
    return () => {
      stopPolling();
    };
  }, [meetingId]);

  // disable sync when meeting completed
  useEffect(() => {
    if (isMeetingComplete) {
      stopPolling();
      refetchSeries();
    }
    return () => {
      stopPolling();
    };
  }, [isMeetingComplete]);

  const error = meetingError || seriesError;
  const loading = isNil(me) || isNil(data) || isNil(seriesData);

  if (isNil(seriesData) || error) {
    return null;
  }

  const oneOnOneSeries = seriesData.oneOnOne;
  const oneOnOneMeeting = data?.oneOnOneMeeting ?? oneOneOneMeetingPlaceholder;

  if (isNil(oneOnOneSeries)) {
    return <NotFoundModal onClose={close} />;
  }

  if (oneOnOneMeeting.isComplete) stopPolling();
  const {
    attendeeNote,
    attendeePrivateNote,
    completedDate,
    dueDate,
    isCancelled,
    isComplete,
    lastEditedDate,
    organizerNote,
    organizerPrivateNote,
    scheduleDate,
  } = oneOnOneMeeting;
  const { isActive } = oneOnOneSeries;

  const today = dayjs();
  const isOverdue =
    dayjs(dueDate).isSameOrBefore(today) && !oneOnOneMeeting.isComplete;

  const isCurrentMeeting =
    oneOnOneSeries.isActive && isSet(dueDate) && !isComplete;

  const { description, attendee, organizer, repeatFrequency, nextMeeting } =
    oneOnOneSeries;
  const isOrganizer = oneOnOneSeries.organizer.id === me?.id;
  const otherUser = isOrganizer ? attendee : organizer;
  const otherUserPublicNote = isOrganizer ? attendeeNote : organizerNote;

  const allMeetings = mapEdges(oneOnOneSeries.meetings.edges);

  const meetingItems = getMeetingItems(
    mapEdges(oneOnOneMeeting.meetingItems.edges)
  );

  const [privateNote, publicNote] = isOrganizer
    ? [organizerPrivateNote, organizerNote]
    : [attendeePrivateNote, attendeeNote];

  const initialValues: CompleteOneOnOneValues = {
    ...meetingItems,
    meetingId: oneOnOneMeeting.id,
    privateNote,
    publicNote,
  };

  const updateMeetingPublicNote = async (note: string) => {
    setSavingStatus("saving");
    try {
      const { hasError } = await upsertOneOnOneMeeting({
        id: oneOnOneMeeting.id,
        ...(isOrganizer ? { organizerNote: note } : { attendeeNote: note }),
      });
      if (hasError) {
        toast.failure(
          intl.$t({
            defaultMessage: "Error saving meeting public note",
            id: "JiU971",
          })
        );
        setSavingStatus("idle");
        return;
      }
      if (oneOnOneMeeting.isComplete) {
        toast.success(
          intl.$t({
            defaultMessage: "Successfully saved meeting public note",
            id: "p+qHYg",
          })
        );
      }
      setSavingStatus("saved");
    } catch (e) {
      setSavingStatus("idle");
    }
  };

  const updateMeetingPrivateNote = async (note: string) => {
    setSavingStatus("saving");
    try {
      const { hasError } = await upsertOneOnOneMeeting({
        id: meetingId,
        ...(isOrganizer
          ? { organizerPrivateNote: note }
          : { attendeePrivateNote: note }),
      });
      if (hasError) {
        toast.failure(
          intl.$t({
            defaultMessage: "Error saving meeting private note",
            id: "uM0iWp",
          })
        );
        setSavingStatus("idle");
        return;
      }
      if (oneOnOneMeeting.isComplete) {
        toast.success(
          intl.$t({
            defaultMessage: "Successfully saved private note",
            id: "UpxsYb",
          })
        );
      }
      setSavingStatus("saved");
    } catch (e) {
      setSavingStatus("idle");
    }
  };

  const deleteMeetingItem = async (id: string) => {
    setSavingStatus("saving");

    const { hasError } = await deleteOneOnOneMeetingItem(id);
    try {
      if (hasError) {
        toast.failure(
          $t({
            defaultMessage: "Error deleting meeting item.",
            id: "xxE2VL",
          })
        );
      } else {
        await refetchMeeting();
      }
      setSavingStatus(hasError ? "idle" : "saved");
    } catch {
      setSavingStatus("idle");
    }
  };

  const updateItem = async (
    item: OneOnOneMeetingItem
  ): Promise<{ hasError: boolean }> => {
    setSavingStatus("saving");
    try {
      const { hasError } = await upsertOneOnOneMeetingItem({
        ...item,
        oneOnOneMeetingId: oneOnOneMeeting.id,
      });
      if (hasError) {
        toast.failure(
          intl.$t({
            defaultMessage: "Error saving meeting item.",
            id: "MBfKcD",
          })
        );
      }
      setSavingStatus(hasError ? "idle" : "saved");
      return { hasError };
    } catch (e) {
      setSavingStatus("idle");
      return { hasError: true };
    }
  };

  const updateItemsOnBlur = async (
    items: OneOnOneMeetingItem[]
  ): Promise<{ hasError: boolean }> => {
    if (isEmpty(items)) {
      return { hasError: false };
    }

    setSavingStatus("saving");
    try {
      const { hasError } = await upsertMeetingItems(oneOnOneMeeting.id, items);
      if (hasError) {
        toast.failure(
          intl.$t({
            defaultMessage: "Error saving meeting item.",
            id: "MBfKcD",
          })
        );
      } else {
      }
      setSavingStatus(hasError ? "idle" : "saved");
      return { hasError };
    } catch (e) {
      setSavingStatus("idle");
      return { hasError: true };
    }
  };

  const handleCompleteMeeting = async ({
    isCancelled = false,
    values,
  }: CompleteMeetingInput) => {
    const { hasError: updateItemsHasError } =
      await handleUpdateMeetingItems(values);
    if (updateItemsHasError) return { hasError: true };

    const { hasError: upsertHasError } = await upsertOneOnOneMeeting({
      id: meetingId,
      ...(isOrganizer && values.privateNote
        ? { organizerPrivateNote: values.privateNote }
        : { attendeePrivateNote: values.privateNote }),
      ...(isOrganizer
        ? { organizerNote: values.publicNote }
        : {
            attendeeNote: values.publicNote,
          }),
    });
    if (upsertHasError) return { hasError: true };

    const { hasError: completeHasError } = await completeOneOnOneMeeting({
      id: meetingId,
      isCancelled,
    });
    if (completeHasError) return { hasError: completeHasError };
    if (isSet(seriesData.oneOnOne?.gCalEventSeries?.id)) {
      refetchSeries();
    }

    return {
      hasError: false,
    };
  };

  const handleUpdateMeetingItems = async (values: CompleteOneOnOneValues) => {
    const formItems = [
      ...(values.actionItems ?? []),
      ...(values.talkingPoints ?? []),
    ];

    const itemsToUpsert = removeEmptyAndCheckCompletedMeetingItems(formItems);
    const { hasError } = await updateItemsOnBlur(itemsToUpsert);

    return {
      hasError,
    };
  };

  const handleCompleteCheck = async () => {
    await refetchSeries();
    const { data } = await refetchMeeting();
    return data.oneOnOneMeeting?.isComplete;
  };

  const onSubmit = async (
    values: CompleteOneOnOneValues,
    actions: FormikHelpers<CompleteOneOnOneValues>
  ) => {
    actions.setSubmitting(true);
    const { hasError: completeHasError } = await handleCompleteMeeting({
      values,
    });
    actions.setSubmitting(false);
    if (completeHasError) {
      toast.failure(
        intl.$t({
          defaultMessage: "Error ending 1:1 meeting",
          id: "rRDNt7",
        })
      );
      return;
    }
    toast.success(
      intl.formatMessage({
        defaultMessage: "1:1 meeting ended",
        id: "oLUjft",
      })
    );
    refetchSeries();
    const meetingUserIds = [attendee.id, organizer.id];
    const managerAndDirectReport =
      userDirectReportsIds.some((id) => meetingUserIds.includes(id)) ||
      meetingUserIds.includes(userManagerId ?? "");
    track(ONE_ON_ONE_MEETING_COMPLETED, {
      actionItemsAdded: values.actionItems?.length ?? 0,
      attendee: attendee.id,
      attendeeSharedNotesEmpty: !isOrganizer
        ? Boolean(values.publicNote)
        : Boolean(attendeeNote),
      managerAndDirectReport,
      organizer: organizer.id,
      organizerSharedNotesEmpty: isOrganizer
        ? Boolean(values.publicNote)
        : Boolean(attendeeNote),
      repeatFrequency,
      seriesId: oneOnOneSeries.id,
      talkingPointsAdded: values.talkingPoints?.length ?? 0,
      userPrivateNoteEmpty: Boolean(values.privateNote),
    });
  };

  const handleScheduleNextMeetingResponse = async (
    values: CompleteOneOnOneValues,
    nextOneOnOneMeeting?: OneOnOneMeetingDefaultFragment | null
  ) => {
    if (!isNil(nextOneOnOneMeeting)) {
      const { hasError: completeHasError } = await handleCompleteMeeting({
        isCancelled: oneOnOneMeeting.isCancelled,
        values,
      });
      if (completeHasError) {
        toast.failure(
          intl.formatMessage({
            defaultMessage: "Error ending 1:1, please try again",
            id: "pZ8i6N",
          })
        );
        return;
      }
      toast.success(
        intl.formatMessage({
          defaultMessage: "1:1 meeting ended",
          id: "oLUjft",
        })
      );
    }
  };

  const handleSubmitForm = async (submitForm: () => Promise<void>) => {
    const hasBeenCompleted = await handleCompleteCheck();
    if (hasBeenCompleted) return;
    if (repeatFrequency === OneOnOneRepeatFrequency.NoRepeat) {
      toggleShowScheduleNextMeeting();
      return;
    }
    submitForm();
  };

  const completeOneOnOneBag: CompleteOneOnOneContext = {
    attendee,
    deleteMeetingItem,
    dueDate,
    handleCompleteCheck,
    meetingId,
    meetings: allMeetings,
    nextMeeting,
    organizer,
    refetchMeeting,
    repeatFrequency,
    savingStatus,
    scheduleDate,
    setShowTemplateView,
    startPolling,
    stopPolling,
  };

  const noUpcomingMeeting =
    isComplete && allMeetings[0]?.id === oneOnOneMeeting.id;

  const toggleScheduleNextMeeting = noUpcomingMeeting
    ? toggleShowScheduleNextMeeting
    : undefined;

  return (
    <CompleteOneOnOneProvider value={completeOneOnOneBag}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {({ submitForm, values, isSubmitting }) => (
          <Modal
            backdropClose={false}
            isOpen
            onClose={close}
            scrollable={false}
            showHeaderBodyDivider
            size="xl"
            title={
              <OneOnOneCompleteFormHeader
                description={description}
                handleCompleteMeeting={handleCompleteMeeting}
                otherUser={otherUser}
                seriesId={oneOnOneMeeting.id}
              />
            }
            verticalScrollable={false}
          >
            <>
              <Show when={showScheduleNextMeeting}>
                <ScheduleNextOneOnOneMeetingModal
                  close={toggleShowScheduleNextMeeting}
                  handleScheduleNextMeetingResponse={(val) =>
                    handleScheduleNextMeetingResponse(values, val)
                  }
                  handleSkipAndSave={() => {
                    toggleShowScheduleNextMeeting();
                    submitForm();
                  }}
                  isComplete={oneOnOneMeeting.isComplete}
                  seriesId={seriesId}
                />
              </Show>
              {showTemplateView ? (
                <OneOnOneMeetingItemTemplatesView />
              ) : (
                <>
                  <OneOnOneModalLayout>
                    <OneOnOneModalLayout.Content>
                      <Show
                        when={!loading}
                        fallback={
                          <OneOnOneCompleteSkeleton
                            otherUserName={otherUser.fullName}
                          />
                        }
                      >
                        <Show when={isCurrentMeeting}>
                          <OneOnOneCompleteForm
                            otherUser={otherUser}
                            otherUserPublicNote={otherUserPublicNote}
                            saveMeetingItem={updateItem}
                            saveMeetingItems={updateItemsOnBlur}
                            savePrivateNote={updateMeetingPrivateNote}
                            savePublicNote={updateMeetingPublicNote}
                          />
                        </Show>
                        <Show when={!isCurrentMeeting}>
                          <Show
                            fallback={
                              <InactiveMeetingMessage isActive={isActive} />
                            }
                            when={isComplete}
                          >
                            <OneOnOneCompleteView
                              meetingBag={{
                                actionItems: meetingItems.actionItems,
                                otherUserNote: otherUserPublicNote,
                                talkingPoints: meetingItems.talkingPoints,
                              }}
                              otherUser={otherUser}
                              savePrivateNote={updateMeetingPrivateNote}
                              savePublicNote={updateMeetingPublicNote}
                            />
                          </Show>
                        </Show>
                      </Show>
                    </OneOnOneModalLayout.Content>
                    <OneOnOneModalLayout.Sidebar>
                      <OneOnOneCompleteSidebar
                        attendee={attendee}
                        organizer={organizer}
                        seriesId={seriesId}
                      />
                    </OneOnOneModalLayout.Sidebar>
                  </OneOnOneModalLayout>
                  <OneOnOneCompleteFormFooter
                    completeMessage={
                      <MeetingCompleteMessage
                        completedDate={completedDate}
                        lastEditedDate={lastEditedDate}
                        meetingCancelled={oneOnOneMeeting.isCancelled}
                        meetingComplete={oneOnOneMeeting.isComplete}
                      />
                    }
                    loading={loading}
                    oneOnOneBag={{
                      isActive,
                      isCancelled,
                      isComplete: oneOnOneMeeting.isComplete,
                      isOverdue,
                      seriesId,
                    }}
                    savingStatus={savingStatus}
                    showEndMeeting={isCurrentMeeting}
                    submitForm={() => handleSubmitForm(submitForm)}
                    toggleShowScheduleMeeting={
                      !isSubmitting ? toggleScheduleNextMeeting : undefined
                    }
                  />
                </>
              )}
            </>
          </Modal>
        )}
      </Formik>
    </CompleteOneOnOneProvider>
  );
};
