import {
  FILTER_CLEAN_UP,
  FILTER_COLUMN_ADD,
  FILTER_REMOVE,
  FILTER_UPDATE,
  ROLLBACK_FILTER,
  SET_EXPLORE_VIEW,
  SET_EXPLORE_VIEW_ATTR,
} from "legacy/actionTypes";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
const createAction = (type) => (payload) => ({
  payload,
  type,
});

export const updateFilter = createAction(FILTER_UPDATE);
export const removeFilter = createAction(FILTER_REMOVE);
export const filterColumnAdd = createAction(FILTER_COLUMN_ADD);
export const cleanUpFilters = createAction(FILTER_CLEAN_UP);
export const setExploreView = createAction(SET_EXPLORE_VIEW);
export const setExploreViewAttribute = createAction(SET_EXPLORE_VIEW_ATTR);
export const rollbackFilter = createAction(ROLLBACK_FILTER);
