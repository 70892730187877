import { namedOperations, useUpsertUserMutation } from "@graphql";
import { FormikHelpers } from "formik";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { Modal } from "common/overlay/Modal/Modal";
import { roles } from "constants/roles";
import { ADD_USER } from "constants/tracking";
import { useOnboardingSteps } from "hooks/useOnboardingSteps/useOnboardingSteps";
import { UserStatuses } from "modules/users/[id]/UserProfile/UserProfile.constants";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { UserForm, UserFormValues } from "../UserForm/UserForm";

const messages = defineMessages({
  error: {
    defaultMessage:
      "Something went wrong. Please contact our Support Team if this happens again.",
    id: "global:something:wrong:extended",
  },
  userCreated: {
    defaultMessage: "User created",
    id: "settings:company:users:created",
  },
});

export type UserAddedCallback = (user: { id: string }) => void;

type Props = {
  onRequestClosed?: () => void;
  onUserAdded?: UserAddedCallback;
  open?: boolean;
  prefillGroupId?: string;
  refetchQueries?: string[];
};

export const InviteUserModal = ({
  onRequestClosed,
  open = false,
  prefillGroupId,
  refetchQueries = [],
  onUserAdded,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { show: showRole } = useFeatureAccess("DROPDOWN_USER_ROLE");
  const { refetchSteps } = useOnboardingSteps();

  const [addAnother, setAddAnother] = useState(false);
  const [upsertUser] = useUpsertUserMutation({
    refetchQueries: [
      namedOperations.Query.getAdminUserList,
      namedOperations.Query.GetAllPerformanceReviews,
      namedOperations.Query.getCurrentUserInfo,
      namedOperations.Query.getUserDirectReports,
      namedOperations.Query.getActiveUsers,
      namedOperations.Query.getSupervisees,
      namedOperations.Query.getUserList,
      namedOperations.Query.BillableUsersCount,
      ...refetchQueries,
    ],
  });

  const initialGroup = prefillGroupId ? [prefillGroupId] : [];
  const initialValues = {
    directReports: [],
    email: "",
    expireInvite: true,
    firstName: "",
    groups: initialGroup,
    invite: true,
    jobPosition: "",
    lastName: "",
    manager: null,
    role: showRole ? "" : roles.superAdmin,
  };

  const onSubmit = async (
    values: UserFormValues,
    actions: FormikHelpers<UserFormValues>
  ) => {
    const response = await upsertUser({ variables: { input: values } });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertUser?.errors
    );
    actions.setSubmitting(false);

    if (hasError) return;
    const dataResponse = response.data?.upsertUser;
    const user = dataResponse?.user;

    refetchSteps();

    toast.success(intl.formatMessage(messages.userCreated));

    if (user) {
      track(ADD_USER, {
        email: user.email,
        expireInvite: values.expireInvite,
        invited: user.accountStatus === UserStatuses.Invited,
        role: user.role,
      });
      onUserAdded?.(user);
    }

    if (addAnother) {
      actions.resetForm({
        values: { ...initialValues, invite: values.invite },
      });
      setAddAnother(false);
    } else {
      onRequestClosed?.();
    }
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      showFooter
      title={intl.formatMessage({ defaultMessage: "Add user", id: "user:add" })}
    >
      <UserForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        setAddAnother={setAddAnother}
      />
    </Modal>
  );
};
