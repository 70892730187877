import { FieldInputProps, FormikProps } from "formik";
import { get } from "lodash";
import camelCase from "lodash/camelCase";
import React from "react";
import { Option } from "react-select/src/filters";
import { Select } from "legacy/components/Select/Select";
import { OnChangeVariable } from "../FormCategorizedUsersSelect/FormCategorizedUsersSelect";

// TODO: Add full types for this component
type Props = {
  [key: string]: any;
  "data-testid"?: string;
  featureTitle?: string | JSX.Element;
  field: FieldInputProps<any>;
  filterOption?: (option: Option, query: string) => boolean;
  form: FormikProps<any>;
  label?: any;
  onChange?: (option: OnChangeVariable) => void;
  options: any[];
};
export class FormSelect extends React.Component<Props> {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  onChange = (option) => {
    const { field, onChange } = this.props;
    const handler = onChange || field.onChange;
    handler({
      target: {
        name: field.name,
        ...option,
      },
    });
  };

  render() {
    const {
      label,
      field,
      options,
      featureTitle,
      form: { errors, submitCount },
      "data-testid": dataTestId,
      ...other
    } = this.props;

    const errorText =
      submitCount > 0
        ? (get(errors, field.name) as string | undefined)
        : undefined;

    const dataCy = () => {
      if (other["data-cy"]) {
        return other["data-cy"];
      }
      if (typeof label === "string") {
        return camelCase(label);
      }
      return null;
    };
    return (
      <Select
        data-cy={dataCy()}
        data-testid={dataTestId}
        defaultValue={options[0]}
        errorText={errorText}
        featureTitle={featureTitle}
        isSearchable={false}
        label={label}
        name={field.name}
        onChange={this.onChange}
        options={options}
        value={options.find((option) => option.value === field.value)}
        {...other}
      />
    );
  }
}
