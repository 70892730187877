import React from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { Tab } from "common/navigation/Tab/Tab";
import { TabBar } from "common/navigation/TabBar/TabBar";
import { CONTEXTUAL_SIDEBAR_SWITCH } from "constants/tracking";
import { PeopleManagementTabOption } from "hooks/useContextualSidebarTabs/useContextualSidebarTabs";
import { track } from "utils/tracker";

type Option = {
  id: PeopleManagementTabOption;
  label: React.ReactNode;
};

export const PEOPLE_MGMT_SIDEBAR_DROPDOWN_OPTIONS: Record<
  PeopleManagementTabOption,
  Option
> = {
  [PeopleManagementTabOption.CHECK_INS]: {
    id: PeopleManagementTabOption.CHECK_INS,
    label: <FormattedMessage defaultMessage="Progress reports" id="Z15+K/" />,
  },
  [PeopleManagementTabOption.GOALS]: {
    id: PeopleManagementTabOption.GOALS,
    label: <FormattedMessage defaultMessage="Goals" id="goals:title" />,
  },
  [PeopleManagementTabOption.KUDOS]: {
    id: PeopleManagementTabOption.KUDOS,
    label: (
      <FormattedMessage
        defaultMessage="Kudos"
        id="settings:company:general:kudos:title"
      />
    ),
  },
};

type Props = {
  handleTabChange: (newTab: PeopleManagementTabOption) => void;
  options: Option[];
  tab: PeopleManagementTabOption;
};

export const PeopleManagementSidebarTabBar = ({
  options,
  tab,
  handleTabChange,
}: Props): JSX.Element => {
  const changeTabHandler = (newTab: PeopleManagementTabOption) => {
    handleTabChange(newTab);
    track(CONTEXTUAL_SIDEBAR_SWITCH, {
      view: newTab,
    });
  };

  return (
    <TabBar
      className="flex w-full items-center border-b px-5"
      data-testid="sidebar-user-goals-kudos-dropdown"
    >
      <div className="flex space-x-4">
        <For each={options}>
          {({ id, label }) => (
            <Tab
              active={tab === id}
              data-cy={`${id}-tab-button`}
              onClick={() => changeTabHandler(id)}
              size="sm"
            >
              {label}
            </Tab>
          )}
        </For>
      </div>
    </TabBar>
  );
};
