import React, { ReactNode } from "react";
import { WithTooltip } from "../WithTooltip/WithTooltip";
import { PermissionErrorMessage } from "./PermissionErrorMessage/PermissionErrorMessage";

type Props = {
  children: ReactNode;
  className?: string;
  show: boolean;
};

export const PermissionErrorTooltip = ({
  show,
  children,
  className,
}: Props): JSX.Element => (
  <WithTooltip
    className={className}
    interactive
    placement="top"
    tooltip={show && <PermissionErrorMessage />}
  >
    {children}
  </WithTooltip>
);
