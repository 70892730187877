import { useGetSlackEmojisQuery } from "@graphql";
import { isNil } from "lodash";
import { CustomEmojis } from "common/emojiPicker/EmojiPicker";

export type GetSlackEmojiHook = {
  customEmojis: CustomEmojis | null;
  emojis: Record<string, string>;
};

export const useGetSlackEmojis = (): GetSlackEmojiHook => {
  const { data } = useGetSlackEmojisQuery({
    fetchPolicy: "cache-and-network",
  });

  const isLoading = isNil(data);
  if (isLoading) {
    return { customEmojis: null, emojis: {} };
  }

  const emojis = JSON.parse(data.currentCompany.slackEmojis) || {};
  const customEmojis: CustomEmojis = {
    emojis: [],
    id: "slack",
    name: "Slack",
  };

  for (const [key, value] of Object.entries(emojis)) {
    customEmojis.emojis.push({
      id: key,
      keywords: [key],
      name: key,
      skins: [{ src: value as string }],
    });
  }

  return { customEmojis, emojis };
};
