import { isNil } from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { getLinkByType } from "route-configs";
import { Entity } from "common/avatar";
import { useGoalOwners } from "common/goal/GoalOwners/useGoalOwners";
import { Pagination } from "common/navigation";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { useReportPagination } from "common/reports";
import { GoalType } from "constants/goal.types";
import { DrilldownGroups } from "./DrilldownGroups";

const DEFAULT_PAGE = { first: 5 };

type Props = {
  children?: ReactNode;
  goalId: string;
  goalType: GoalType;
};

export const OwnersDrilldown = ({
  children,
  goalId,
  goalType,
}: Props): JSX.Element | null => {
  const [modalIsPaginating, setModalIsPaginating] = useState<
    false | "backward" | "forward"
  >(false);

  const { page, setPage } = useReportPagination(DEFAULT_PAGE);
  const { owners, pageInfo, totalCount } = useGoalOwners(
    goalType,
    goalId,
    page
  );

  useEffect(() => {
    setModalIsPaginating(false);
  }, [owners]);

  if (isNil(pageInfo) || isNil(totalCount)) {
    return (
      <div className="flex h-full w-full flex-col justify-center pt-2 ">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col pt-2">
      <Pagination
        loadingNext={modalIsPaginating === "forward"}
        loadingPrev={modalIsPaginating === "backward"}
        onAfterPaginate={(direction) => setModalIsPaginating(direction)}
        pageInfo={pageInfo}
        pageSize={DEFAULT_PAGE.first}
        setPage={setPage}
        totalCount={totalCount}
      />
      <DrilldownGroups groups={owners}>
        {(owner) => (
          <>
            <Entity
              avatar={owner.avatar}
              avatarSize="medium"
              className="w-auto max-w-sm"
              href={getLinkByType(
                owner.isCompany ? "company" : "group",
                owner.id
              )}
              name={owner.name}
            />
          </>
        )}
      </DrilldownGroups>
      {children}
    </div>
  );
};
