import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";

type ReportDrilldownEntity = { id: string };

type Props<Entity> = {
  children: (user: Entity) => ReactNode;
  entities: Entity[];
};

/**
 * customisable wrapper for report drilldowns entities (users / groups). use like:
 * @example
 * <ReportDrilldownUsers users={users}>
 *   {(user) => (
 *     <>
 *       <img src={user.avatar} />
 *       <span>{user.fullName}</span>
 *     </>
 *   )}
 * </ReportDrilldownUsers>
 */
export const ReportDrilldownEntities = <Entity extends ReportDrilldownEntity>({
  children,
  entities,
}: Props<Entity>): JSX.Element => {
  return (
    <ul>
      {entities.map((entity) => (
        <li
          key={entity.id}
          className={twClass(
            "flex items-center justify-between",
            "h-16",
            "border-t border-slate-300"
          )}
        >
          {children(entity)}
        </li>
      ))}
    </ul>
  );
};
