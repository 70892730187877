import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";

type Hook = {
  oAuthState: string;
  regenerateOAuthState: () => void;
};

const generateRandomString = () => {
  return Math.random().toString(16).substring(2);
};

export const usePersistentRandomOAuthState = (): Hook => {
  const [oAuthState, setOAuthState] = useLocalStorage(
    "oAuthState",
    generateRandomString
  );

  const regenerateOAuthState = () => setOAuthState(generateRandomString());

  return {
    oAuthState,
    regenerateOAuthState,
  };
};
