import React from "react";
import { FormattedMessage } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";

export const ObjectiveTimeframeHeader = () => {
  const { timeframe } = useObjectiveContext();

  return (
    <div
      className={twClass("flex space-x-1 items-center", {
        "text-red-500": timeframe.isPast,
      })}
    >
      <div>
        <FormattedMessage
          defaultMessage="Timeframe"
          id="keyResult:list:timeframe"
        />
      </div>
      <Show when={timeframe.isPast}>
        <WithTooltip
          tooltip={
            <div>
              <FormattedMessage
                defaultMessage="Timeframe has ended"
                id="29ETcC"
              />
            </div>
          }
        >
          <Icon name="error_outline" className="text-red-500" size="lg" />
        </WithTooltip>
      </Show>
    </div>
  );
};
