import axios, { AxiosRequestConfig, Method } from "axios";
import { API_URL } from "config";
import Router from "next/router";
import { auth } from "common/authHelper";
import { isProtectedRoute } from "common/topLevel/useIsProtectedRoute/useIsProtectedRoute";
import { toast } from "./toastr";
import { logToSentry } from "./tracker";

export const callApi = (
  endpoint: string,
  params: AxiosRequestConfig = {},
  options: {
    // pass true to prevent retrying the request after refreshing the access token
    noRetryOn401?: boolean;
    showFailureToast?: boolean;
    /** won't pass authorization token. use for things like password reset. */
    skipAuth?: boolean;
    skipDefaulHandlerFor?: number;
  } = { showFailureToast: true }
): Promise<any> => {
  const url = endpoint.includes(API_URL) ? endpoint : API_URL + endpoint;
  const token = auth.getAccessToken();

  const defaultParams = {
    data: null,
    headers: {
      Accept: "application/vnd.api+json",
      Authorization: options.skipAuth ? undefined : token,
      "Content-Type": "application/vnd.api+json",
    },
    method: "get" as Method,
    params: {},
    url,
  };

  return axios({ ...defaultParams, ...params })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        if (error.response.status === options.skipDefaulHandlerFor) {
          // TODO: Simply return error.reponse in the rejection. Requires updating some error handling in components
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ ...error.response, ...error.response.data });
        }

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 404) {
          toast.failure("Oops! That object doesn't seem to exist anymore.");
          if (error.response.config.url.includes("undefined")) {
            logToSentry("404", error.response.data);
          }
        } else if (
          error.response.status === 401 &&
          isProtectedRoute(Router.asPath) &&
          endpoint.includes("api-token-refresh")
        ) {
          auth.logout({
            reason: "unable to refresh token (api.ts)",
          });
          return Promise.reject(error);
        } else if (
          error.response.status === 401 &&
          isProtectedRoute(Router.asPath)
        ) {
          if (options.noRetryOn401) {
            auth.logout({
              reason: `${endpoint}: unauthorized response in API call (api.ts)`,
            });
            return Promise.reject(error);
          }
          return auth
            .fetchNewToken()
            .then(() =>
              callApi(endpoint, params, {
                ...options,
                noRetryOn401: true,
              })
            )
            .catch(() => {
              auth.logout({
                reason: "unable to refresh token (api.ts)",
              });
            });
        } else if (error.response.status === 403 && options.showFailureToast) {
          toast.failure("You do not have permission to perform this action");
        } else if (error.response.status === 500) {
          toast.info("Server error. Something went wrong!");
        }
        // TODO: Simply return error.reponse in the rejection. Requires updating some error handling in components
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...error.response, ...error.response.data });
      }

      let errorMsg;
      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorMsg = `No response received: ${error.message}`;
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMsg = `Error setting up request: ${error.message}`;
      }

      logToSentry(errorMsg, { error }, "error");
      return Promise.reject(error);
    });
};
