import { useRouter } from "next/router";
import React, { ReactNode } from "react";
import { routes } from "route-configs";
import { Show } from "common/controlFlow";
import { SidebarSkeleton } from "common/placeholders/SidebarSkeleton/SidebarSkeleton";

type Props = {
  children?: ReactNode;
  isCollapsed?: boolean;
};

export const GlobalLoadingState = ({
  children,
  isCollapsed,
}: Props): JSX.Element => {
  const router = useRouter();
  const inTeams = router.asPath.includes(routes.msTeams.base);
  return (
    <div className="flex h-screen w-screen">
      <Show when={!inTeams}>
        <SidebarSkeleton isCollapsed={isCollapsed} />
      </Show>
      <div className="flex w-full flex-col">
        <div className="relative flex flex-shrink-0 flex-grow flex-col items-center justify-center overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};
