import {
  KeyResult,
  PerdooApiKeyResultTypeChoices,
  useSearchResultsQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { EntityIcon } from "common/icons";
import { useSelect } from "common/inputs/Select/useSelect";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";
import { SELECT_EMPTY_ID } from "../Select/Select.utils";

type Value = string | null;

type Option = Derive<
  KeyResult,
  {
    id: true;
    name: true;
    type: true;
  }
>;

type Props = {
  className?: string;
  "data-cy"?: string;
  disabled?: boolean;
  objective?: string;
  onChange?: (value: Value) => void;
  type?: PerdooApiKeyResultTypeChoices;
  value: Value;
};

const EMPTY_OPTION: Option = {
  id: SELECT_EMPTY_ID,
  name: "",
  type: PerdooApiKeyResultTypeChoices.KeyResult,
};

export const InlineResultSelect = ({
  className,
  "data-cy": dataCy,
  onChange,
  value,
  type,
  objective,
  disabled,
}: Props) => {
  const { data } = useSearchResultsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      resultsObjectiveIn: objective,
      resultsType: type,
    },
  });
  const options = useMemo(() => mapEdges(data?.results.edges), [data]);
  const defaultItem = options.find((opt) => opt.id === value);
  const { selected, handleSelect, handleSearch, Select, visibleOptions } =
    useSelect<Option>({
      defaultItem,
      options,
    });

  const handleChange = (option: Option | null) => {
    if (!option) return;
    if (option.id === SELECT_EMPTY_ID) {
      handleSelect(null);
      onChange?.(null);
      return;
    }
    handleSelect(option);
    onChange?.(option.id);
  };

  return (
    <Select.Root
      data-cy={dataCy}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          {selected ? (
            <div className="flex space-x-1 items-center">
              <EntityIcon entity={selected.type} />
              <div className="truncate">{selected.name}</div>
            </div>
          ) : (
            <div>
              <FormattedMessage defaultMessage="Not selected" id="b7wHG1" />
            </div>
          )}
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20 divide-y">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <Select.Option value={EMPTY_OPTION}>
            <div className="truncate">
              <FormattedMessage defaultMessage="Not selected" id="b7wHG1" />
            </div>
          </Select.Option>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <div className="flex space-x-1 items-center">
                  <EntityIcon
                    className={twClass({
                      "text-white": selected?.id === option.id,
                    })}
                    entity={option.type}
                  />
                  <div className="truncate">{option.name}</div>
                </div>
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
