import {
  AsanaTask,
  MakeOptional,
  useGetAsanaTaskForSelectLazyQuery,
  useGetAsanaTasksForSelectLazyQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useField } from "formik";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Props as ReactSelectProps, ValueType } from "react-select";
import { AsanaTaskIcon } from "common/asana/AsanaTaskIcon/AsanaTaskIcon";
import { AllowedFieldProps } from "common/form/types";
import { useAsanaFormContext } from "common/integration/AsanaFormLogic/AsanaFormLogic";
import { Select } from "legacy/components/Select/Select";
import { messages } from "./AsanaTaskField.messages";

export type AsanaTaskFieldTask = Derive<
  AsanaTask,
  {
    gid: true;
    name: true;
    projects: {
      gid: true;
    };
    workspace: {
      gid: true;
      name: true;
    };
  }
>;

export type AsanaTaskFieldProject = {
  gid: string;
};

type Option = {
  icon: JSX.Element;
  label: string;
  value: string;
};

const taskToOption = (task: AsanaTaskFieldTask): Option => ({
  icon: <AsanaTaskIcon />,
  label: task.name,
  value: task.gid,
});

type Props = ReactSelectProps & MakeOptional<AllowedFieldProps, "name">;

export const AsanaTaskField = ({
  name = "asanaTask",
  "data-cy": dataCy = "asanaTask",
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);

  const [currentValue, setCurrentValue] = useState<Option>();

  const { project, onTaskSelected } = useAsanaFormContext();

  const [searchAsana, { loading }] = useGetAsanaTasksForSelectLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getAsanaTask, { loading: lazyLoading }] =
    useGetAsanaTaskForSelectLazyQuery({
      fetchPolicy: "cache-and-network",
    });
  const loadOptions = async (query: string) => {
    if (isNil(project)) return [];
    const result = await searchAsana({
      variables: { projectGid: project.gid, query },
    });
    const options = result.data?.asanaTasks.map(taskToOption) ?? [];
    return options;
  };

  const formatOptionLabel = (option: Option) => {
    return (
      <div className="flex items-center space-x-2">
        {option.icon}
        <div>{option.label}</div>
      </div>
    );
  };

  const fetchOptionFromServer = async (gid: string | undefined) => {
    if (isNil(gid)) {
      setCurrentValue(undefined);
      onTaskSelected?.(null);
      return;
    }

    const result = await getAsanaTask({ variables: { gid } });
    const task = result.data?.asanaTask;
    if (isNil(task)) {
      // eslint-disable-next-line no-console
      console.warn("Couldn't set Asana task because the server didn't find it");
      return;
    }

    const option = taskToOption(task);
    setCurrentValue(option);
    onTaskSelected?.(task);
  };

  useEffect(() => {
    fetchOptionFromServer(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const forwardOnChange = (option: ValueType<Option, false>) => {
    helpers.setValue(option?.value);
  };

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      async
      disabled={isNil(project)}
      errorText={meta.error}
      formatOptionLabel={formatOptionLabel}
      isClearable
      isInForm
      isLoading={loading || lazyLoading}
      label={intl.formatMessage(messages.asanaTask)}
      loadOptions={loadOptions}
      noOptionsMessage={({ inputValue }) =>
        inputValue
          ? intl.formatMessage(messages.noResults)
          : intl.formatMessage(messages.searchTasks)
      }
      onChange={forwardOnChange}
      placeholder={intl.formatMessage(messages.search)}
      value={currentValue ?? null}
    />
  );
};
