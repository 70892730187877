import { useUpsertTagMutation } from "@graphql";
import { pick } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "common/overlay/Modal/Modal";
import { tagColors } from "constants/tag-colors";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { TagForm } from "../../TagForm/TagForm";

const getRandomColor = () => {
  const idx = Math.floor(Math.random() * tagColors.length);
  return tagColors[idx];
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'open' implicitly has an 'any' typ... Remove this comment to see the full error message
export const AddTagModal = ({ open, onRequestClosed, refetchQueries = [] }) => {
  const [mutation] = useUpsertTagMutation();
  const intl = useIntl();

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  const onSubmit = async (values, actions) => {
    const response = await mutation({
      refetchQueries,
      variables: { input: pick(values, ["name", "description", "color"]) },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertTag?.errors,
      { handleTopLevel: true }
    );
    if (hasError) return null;


    toast.success(
      intl.formatMessage({
        defaultMessage: "Tag updated",
        id: "company:settings:tags:updateConfirmationMessage",
      })
    );

    actions.setSubmitting(false);
    onRequestClosed();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      size="sm"
      onClose={onRequestClosed}
      title={<FormattedMessage defaultMessage="Add Tag" id="GUW//c" />}
    >
      <TagForm
        initialValues={{
          color: getRandomColor(),
        }}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
