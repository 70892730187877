import React from "react";
import { Icon } from "common/icons";
import { IconNameKey } from "common/icons/Icon/Icon.types";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  name?: IconNameKey;
  open?: boolean;
};

export const ComboIcon = ({
  className,
  name = "keyboard_arrow_down",
  open,
}: Props): JSX.Element => {
  return (
    <Icon
      aria-hidden="true"
      className={twClass(
        "text-slate-400",
        {
          "text-slate-900": open,
        },
        className
      )}
      name={name}
      size="2xl"
    />
  );
};
