import {
  UpsertObjectiveMutationInput,
  useGetCompanyOkriTemplateQuery,
  useUpsertObjectiveMutationMutation,
} from "@graphql";
import React from "react";
import { useIntl } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { DescriptionForm } from "common/form/DescriptionForm/DescriptionForm";
import { UPDATE_OBJECTIVE } from "constants/tracking";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

export const ObjectiveDescription = () => {
  const intl = useIntl();
  const { id, completed, description, canPatch } = useObjectiveContext();

  const { data } = useGetCompanyOkriTemplateQuery();
  const [upsertObjective] = useUpsertObjectiveMutationMutation();

  const descriptionTemplate = data?.currentCompany.objDescriptionTemplate;

  const updateObjective = async (
    input: Partial<UpsertObjectiveMutationInput>
  ) => {
    const response = await upsertObjective({
      variables: {
        input: {
          id: id,
          ...input,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertObjective?.errors
    );

    if (!hasError) {
      toast.success(
        intl.formatMessage({
          defaultMessage: "Objective Updated",
          id: "N5Ya1o",
        })
      );
      track(UPDATE_OBJECTIVE, { fields: Object.keys(input) });
    }
  };

  const updateDescription = (input: { description: string }) => {
    if (input.description === description) return;

    updateObjective({
      description: input.description,
    });
  };

  return (
    <div className="my-5">
      <DescriptionForm
        data-testid="objectiveDescription"
        variant="borderless"
        closed={completed}
        description={description ?? ""}
        editVisible={canPatch}
        entityId={id}
        onUpdate={updateDescription}
        template={descriptionTemplate}
      />
    </div>
  );
};
