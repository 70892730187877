/* eslint-disable typescript-sort-keys/string-enum */
import React from "react";

type SvgIcons = SMap<string>;

export const svgIcons: SvgIcons = {
  alignedMap:
    "M8,5.5c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v3c0,0.6-0.4,1-1,1h-2.5v2h3.1c1.7,0,3.2,1.3,3.4,3h1c0.6,0,1,0.4,1,1 v3c0,0.6-0.4,1-1,1h-6c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h4c-0.2-1.1-1.2-2-2.4-2h-7c-1.2,0-2.2,0.9-2.4,2H10c0.6,0,1,0.4,1,1v3 c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1h1.3c0.2-1.7,1.6-3,3.4-3h2.9v-2H9c-0.6,0-1-0.4-1-1V5.5z",
  alignedObjective:
    "M5 1.5C5 0.9 5.4 0.5 6 0.5H12C12.6 0.5 13 0.9 13 1.5V4.5C13 5.1 12.6 5.5 12 5.5H9.5V7.5H12.6C14.3 7.5 15.8 8.8 16 10.5H17C17.6 10.5 18 10.9 18 11.5V14.5C18 15.1 17.6 15.5 17 15.5H11C10.4 15.5 10 15.1 10 14.5V11.5C10 10.9 10.4 10.5 11 10.5H15C14.8 9.4 13.8 8.5 12.6 8.5H5.6C4.4 8.5 3.4 9.4 3.2 10.5H7C7.6 10.5 8 10.9 8 11.5V14.5C8 15.1 7.6 15.5 7 15.5H1C0.4 15.5 0 15.1 0 14.5V11.5C0 10.9 0.4 10.5 1 10.5H2.3C2.5 8.8 3.9 7.5 5.7 7.5H8.6V5.5H6C5.4 5.5 5 5.1 5 4.5V1.5Z",
  cadence:
    "m8.65955,7.22301c0,-0.55228 0.44771,-0.99999 1,-0.99999l4.99995,0c0.5523,0 1,0.44771 1,0.99999l0,2c0,0.55229 -0.4477,1 -1,1l-4.99995,0c-0.55229,0 -1,-0.44771 -1,-1l0,-2zm-5.15955,7.55396c0,-0.55229 0.44772,-1 1,-1l5,0c0.55228,0 1,0.44771 1,1l0,2c0,0.5523 -0.44772,1 -1,1l-5,0c-0.55228,0 -1,-0.4477 -1,-1l0,-2zm11,-1c-0.5523,0 -1,0.44771 -1,1l0,2c0,0.5523 0.4477,1 1,1l5,0c0.5523,0 1,-0.4477 1,-1l0,-2c0,-0.55229 -0.4477,-1 -1,-1l-5,0z",
  gif: "M23.15 30.25v-12.5h2.9v12.5Zm-11.95 0q-.8 0-1.375-.55t-.575-1.4v-8.6q0-.9.575-1.425t1.375-.525h6.2q.85 0 1.375.525T19.3 19.7v.95h-7.2v6.7h4.35V24h2.85v4.35q0 .85-.525 1.375t-1.375.525Zm18.5 0v-12.5h9.05v2.9h-6.2v2.8h4.05v2.95h-4.05v3.85Z",
  growCards:
    "M15.5,8.5v7c0,0.6-0.4,1-1,1h-5c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h5C15.1,7.5,15.5,7.9,15.5,8.5z M5.3,8.5 L1.5,12l3.8,3.5l1.2-1.1L3.9,12l2.6-2.4L5.3,8.5z M18.7,8.5l-1.2,1.1l2.6,2.4l-2.6,2.4l1.2,1.1l3.8-3.5L18.7,8.5z",
  kpis: "M 12 2.667 C 5.373 2.667 0 8.039 0 14.667 C 0 16.867 0.594 18.928 1.628 20.7 C 1.861 21.101 2.307 21.333 2.771 21.333 L 21.229 21.333 C 21.693 21.333 22.139 21.101 22.373 20.7 C 23.406 18.928 24 16.867 24 14.667 C 24 8.039 18.627 2.667 12 2.667 Z  M 12 5.333 C 12.613 5.333 13.107 5.755 13.263 6.319 C 13.217 6.413 13.153 6.495 13.12 6.597 L 12.735 7.75 C 12.522 7.895 12.278 8 12 8 C 11.264 8 10.667 7.403 10.667 6.667 C 10.667 5.93 11.264 5.333 12 5.333 Z  M 4 17.333 C 3.264 17.333 2.667 16.736 2.667 16 C 2.667 15.264 3.264 14.667 4 14.667 C 4.736 14.667 5.333 15.264 5.333 16 C 5.333 16.736 4.736 17.333 4 17.333 Z  M 6 10.667 C 5.264 10.667 4.667 10.07 4.667 9.333 C 4.667 8.597 5.264 8 6 8 C 6.736 8 7.333 8.597 7.333 9.333 C 7.333 10.07 6.736 10.667 6 10.667 Z  M 16.282 7.65 L 13.727 15.316 C 14.297 15.805 14.667 16.522 14.667 17.333 C 14.667 17.822 14.526 18.273 14.297 18.667 L 9.703 18.667 C 9.474 18.273 9.333 17.822 9.333 17.333 C 9.333 15.919 10.438 14.774 11.829 14.684 L 14.385 7.017 C 14.559 6.493 15.124 6.206 15.65 6.385 C 16.174 6.559 16.456 7.126 16.282 7.65 L 16.282 7.65 Z  M 16.893 10.033 L 17.54 8.093 C 17.684 8.04 17.837 8 18 8 C 18.736 8 19.333 8.598 19.333 9.334 C 19.333 10.07 18.736 10.667 18 10.667 C 17.526 10.667 17.13 10.405 16.893 10.033 Z  M 20 17.333 C 19.264 17.333 18.667 16.736 18.667 16 C 18.667 15.264 19.264 14.667 20 14.667 C 20.736 14.667 21.333 15.264 21.333 16 C 21.333 16.736 20.736 17.333 20 17.333 Z",
  listAltAdd:
    "M10.6667 11.6667H8.91667V10.5H10.6667V8.75H11.8333V10.5H13.5833V11.6667H11.8333V13.4167H10.6667V11.6667ZM2.5 12.25V1.75H13V8.06458C12.825 7.95764 12.6403 7.87014 12.4458 7.80208C12.2514 7.73403 12.0472 7.68056 11.8333 7.64167V2.91667H3.66667V11.0833H7.75C7.75 11.2875 7.76458 11.4868 7.79375 11.6812C7.82292 11.8757 7.87153 12.0653 7.93958 12.25H2.5ZM5.41667 9.91667C5.58194 9.91667 5.72049 9.86076 5.83229 9.74896C5.9441 9.63715 6 9.49861 6 9.33333C6 9.16806 5.9441 9.02951 5.83229 8.91771C5.72049 8.8059 5.58194 8.75 5.41667 8.75C5.25139 8.75 5.11285 8.8059 5.00104 8.91771C4.88924 9.02951 4.83333 9.16806 4.83333 9.33333C4.83333 9.49861 4.88924 9.63715 5.00104 9.74896C5.11285 9.86076 5.25139 9.91667 5.41667 9.91667ZM5.41667 7.58333C5.58194 7.58333 5.72049 7.52743 5.83229 7.41563C5.9441 7.30382 6 7.16528 6 7C6 6.83472 5.9441 6.69618 5.83229 6.58437C5.72049 6.47257 5.58194 6.41667 5.41667 6.41667C5.25139 6.41667 5.11285 6.47257 5.00104 6.58437C4.88924 6.69618 4.83333 6.83472 4.83333 7C4.83333 7.16528 4.88924 7.30382 5.00104 7.41563C5.11285 7.52743 5.25139 7.58333 5.41667 7.58333ZM5.41667 5.25C5.58194 5.25 5.72049 5.1941 5.83229 5.08229C5.9441 4.97049 6 4.83194 6 4.66667C6 4.50139 5.9441 4.36285 5.83229 4.25104C5.72049 4.13924 5.58194 4.08333 5.41667 4.08333C5.25139 4.08333 5.11285 4.13924 5.00104 4.25104C4.88924 4.36285 4.83333 4.50139 4.83333 4.66667C4.83333 4.83194 4.88924 4.97049 5.00104 5.08229C5.11285 5.1941 5.25139 5.25 5.41667 5.25ZM7.16667 7.58333H10.6667V6.41667H7.16667V7.58333ZM7.16667 5.25H10.6667V4.08333H7.16667V5.25ZM7.16667 9.91667H7.95417C8.03194 9.69306 8.12917 9.48403 8.24583 9.28958C8.3625 9.09514 8.49861 8.91528 8.65417 8.75H7.16667V9.91667Z",
  maps: "m8.605,6.24065c0,-0.63616 0.51572,-1.15186 1.15187,-1.15186l4.48626,0c0.63618,0 1.15187,0.51571 1.15187,1.15186l0,1.64556c0,0.63616 -0.51569,1.15187 -1.15187,1.15187l-4.48626,0c-0.63616,0 -1.15187,-0.51571 -1.15187,-1.15187l0,-1.64556zm2.71524,2.7975l0,2.10398l-1.89616,0c-2.1097,0 -3.81998,1.71028 -3.81994,3.81998l-1.66724,-0.00014c-0.63616,0 -1.15187,0.51571 -1.15187,1.15192l0,1.64545c0,0.63618 0.51572,1.15187 1.15187,1.15187l4.48625,0c0.63616,0 1.15187,-0.51569 1.15187,-1.15187l0,-1.64545c0,-0.63621 -0.51572,-1.15192 -1.15187,-1.15192l-1.66713,0.00012c-0.00005,-1.47353 1.1945,-2.66808 2.66806,-2.66808l2.47209,0l2.67985,0c1.47359,0 2.66808,1.19455 2.66808,2.6681l-1.66722,-0.00013c-0.63618,0 -1.15187,0.51571 -1.15187,1.15192l0,1.64545c0,0.63618 0.51569,1.15187 1.15187,1.15187l4.4862,0c0.63618,0 1.15187,-0.51569 1.15187,-1.15187l0,-1.64545c0,-0.63621 -0.51569,-1.15192 -1.15187,-1.15192l-1.66711,0.00013c0,-2.1097 -1.71019,-3.81997 -3.81996,-3.81997l-2.10391,0l0,-2.10398l-1.15187,0z",
  pillars:
    "M4.5 9H2.5V16H4.5V9ZM10.5 9H8.5V16H10.5V9ZM19 18H0V20H19V18ZM16.5 9H14.5V16H16.5V9ZM9.5 2.26L14.71 5H4.29L9.5 2.26ZM9.5 0L0 5V7H19V5L9.5 0Z",
  pulse:
    "M0 9.29778H2.48L6.31111 2L7.36 9.96445L10.2222 6.32889L13.1822 9.29778H16V11.0756H12.4444L10.3733 9.00445L6.15111 14.3911L5.28 7.79556L3.55556 11.0756H0V9.29778Z",
  shrinkCards:
    "M5,12l-3.8,3.5L0,14.4L2.6,12L0,9.6l1.2-1.1L5,12z M24,9.6l-1.2-1.1L19,12l3.8,3.5l1.2-1.1L21.4,12L24,9.6z M19.5,9.5v-2c0-0.6-0.5-1-1-1H5.5c-0.6,0-1,0.5-1,1v2l3,2.5l-3,2.5v2c0,0.6,0.5,1,1,1h12.9c0.6,0,1-0.5,1-1v-2l-3-2.5L19.5,9.5z",
  sparkle:
    "M12.6666 6.00002L13.5 4.16669L15.3333 3.33335L13.5 2.50002L12.6666 0.666687L11.8333 2.50002L9.99996 3.33335L11.8333 4.16669L12.6666 6.00002ZM7.66663 6.33335L5.99996 2.66669L4.33329 6.33335L0.666626 8.00002L4.33329 9.66669L5.99996 13.3334L7.66663 9.66669L11.3333 8.00002L7.66663 6.33335ZM12.6666 10L11.8333 11.8334L9.99996 12.6667L11.8333 13.5L12.6666 15.3334L13.5 13.5L15.3333 12.6667L13.5 11.8334L12.6666 10Z",
  standardMap:
    "M9,4.5c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-3c0-0.6-0.4-1-1-1H9z M21,12.5H3v-1h18V12.5z M4,14.5c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-3c0-0.6-0.4-1-1-1H4z M14,14.5c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1 h6c0.6,0,1-0.4,1-1v-3c0-0.6-0.4-1-1-1H14z",
  streak:
    "M18.0825 10.8613C17.5617 9.68919 16.8047 8.63718 15.8587 7.77108L15.0781 7.05486C15.0516 7.0312 15.0197 7.01442 14.9852 7.00598C14.9507 6.99753 14.9147 6.99769 14.8803 7.00643C14.8458 7.01517 14.8141 7.03222 14.7878 7.0561C14.7615 7.07998 14.7414 7.10995 14.7294 7.14338L14.3807 8.14394C14.1634 8.77164 13.7637 9.41275 13.1977 10.0431C13.1602 10.0834 13.1173 10.0941 13.0878 10.0968C13.0583 10.0995 13.0126 10.0941 12.9724 10.0565C12.9349 10.0243 12.9161 9.97606 12.9188 9.92778C13.018 8.31293 12.5352 6.49154 11.4783 4.50919C10.6038 2.86216 9.38863 1.57726 7.87036 0.681315L6.7625 0.0294753C6.61765 -0.0563636 6.43256 0.0562999 6.4406 0.225295L6.49962 1.51288C6.53985 2.39273 6.43792 3.17064 6.1965 3.81712C5.90143 4.60845 5.4776 5.34344 4.93574 6.00333C4.55865 6.46193 4.13124 6.87674 3.66157 7.23995C2.53039 8.10951 1.61059 9.22372 0.971053 10.4991C0.333092 11.7857 0.000778347 13.2022 0 14.6382C0 15.9043 0.249469 17.1302 0.743043 18.2863C1.21963 19.3995 1.90739 20.4097 2.76831 21.2612C3.63743 22.1196 4.64603 22.7956 5.76999 23.265C6.93418 23.7532 8.16811 24 9.44228 24C10.7165 24 11.9504 23.7532 13.1146 23.2677C14.2358 22.801 15.2553 22.1204 16.1163 21.2639C16.9854 20.4055 17.6667 19.4023 18.1415 18.289C18.6344 17.136 18.8872 15.8948 18.8846 14.6409C18.8846 13.3318 18.6163 12.0603 18.0825 10.8613Z",
  treeInputIcon:
    "M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z",
  update_progress:
    "M 18.056 4.923 L 14.377 8.819 L 9.957 4.695 L 3.68 10.972 L 3 11.652 L 4.361 13.013 L 5.041 12.333 L 10.005 7.369 L 14.46 11.528 L 19.077 6.639 L 19.077 7.807 L 19.077 8.768 L 21 8.768 L 21 7.807 L 21 3 L 15.897 3 L 14.936 3 L 14.936 4.923 L 15.897 4.923 L 18.056 4.923 L 18.056 4.923 L 18.056 4.923 Z  M 9.389 9.286 L 9.389 20.003 L 5.84 20.003 L 5.84 13.131 L 9.389 9.286 L 9.389 9.286 L 9.389 9.286 Z  M 13.827 12.993 L 13.827 20.028 L 10.573 20.028 L 10.573 9.878 L 13.827 12.993 L 13.827 12.993 Z  M 18.56 8.99 L 18.56 20.028 L 15.01 20.028 L 15.01 13.131 L 18.56 8.99 Z",
};

export const RoadmapAddIcon = ({
  className,
}: {
  className: string;
}): JSX.Element => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 45 20"
    width="45"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.12934 14.9893L0.5 0H44.5L35.8707 14.9893C34.086 18.0893 30.7813 20 27.2042 20H17.7958C14.2187 20 10.914 18.0893 9.12934 14.9893Z" />
    <path
      d="M27.5418 10.2918H22.7918V15.0418H21.2085V10.2918H16.4585V8.7085H21.2085V3.9585H22.7918V8.7085H27.5418V10.2918Z"
      fill="white"
    />
  </svg>
);

export const OneOnOneAddItemIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => (
  <svg
    className={className}
    height="14"
    viewBox="0 0 15 14"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 11.6667H8.91667V10.5H10.6667V8.75H11.8333V10.5H13.5833V11.6667H11.8333V13.4167H10.6667V11.6667ZM2.5 12.25V1.75H13V8.06458C12.825 7.95764 12.6403 7.87014 12.4458 7.80208C12.2514 7.73403 12.0472 7.68056 11.8333 7.64167V2.91667H3.66667V11.0833H7.75C7.75 11.2875 7.76458 11.4868 7.79375 11.6812C7.82292 11.8757 7.87153 12.0653 7.93958 12.25H2.5ZM5.41667 9.91667C5.58194 9.91667 5.72049 9.86076 5.83229 9.74896C5.9441 9.63715 6 9.49861 6 9.33333C6 9.16806 5.9441 9.02951 5.83229 8.91771C5.72049 8.8059 5.58194 8.75 5.41667 8.75C5.25139 8.75 5.11285 8.8059 5.00104 8.91771C4.88924 9.02951 4.83333 9.16806 4.83333 9.33333C4.83333 9.49861 4.88924 9.63715 5.00104 9.74896C5.11285 9.86076 5.25139 9.91667 5.41667 9.91667ZM5.41667 7.58333C5.58194 7.58333 5.72049 7.52743 5.83229 7.41563C5.9441 7.30382 6 7.16528 6 7C6 6.83472 5.9441 6.69618 5.83229 6.58437C5.72049 6.47257 5.58194 6.41667 5.41667 6.41667C5.25139 6.41667 5.11285 6.47257 5.00104 6.58437C4.88924 6.69618 4.83333 6.83472 4.83333 7C4.83333 7.16528 4.88924 7.30382 5.00104 7.41563C5.11285 7.52743 5.25139 7.58333 5.41667 7.58333ZM5.41667 5.25C5.58194 5.25 5.72049 5.1941 5.83229 5.08229C5.9441 4.97049 6 4.83194 6 4.66667C6 4.50139 5.9441 4.36285 5.83229 4.25104C5.72049 4.13924 5.58194 4.08333 5.41667 4.08333C5.25139 4.08333 5.11285 4.13924 5.00104 4.25104C4.88924 4.36285 4.83333 4.50139 4.83333 4.66667C4.83333 4.83194 4.88924 4.97049 5.00104 5.08229C5.11285 5.1941 5.25139 5.25 5.41667 5.25ZM7.16667 7.58333H10.6667V6.41667H7.16667V7.58333ZM7.16667 5.25H10.6667V4.08333H7.16667V5.25ZM7.16667 9.91667H7.95417C8.03194 9.69306 8.12917 9.48403 8.24583 9.28958C8.3625 9.09514 8.49861 8.91528 8.65417 8.75H7.16667V9.91667Z"
      id="Vector"
    />
  </svg>
);

export const clearIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
  >
    <path d={svgIcons.clearIcon} />
  </svg>
);

export const treeInputIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    height="20"
    viewBox="0 0 20 20"
    width="20"
  >
    <path d={svgIcons.treeInputIcon} />
  </svg>
);

export const filterIcon = (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 19 13"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0 0V1.5H13.5V0H0ZM5.24991 9.00002H8.24991V7.50002H5.24991V9.00002ZM11.2501 5.25001H2.25008V3.75001H11.2501V5.25001Z"
      fill="black"
      fillOpacity="0.54"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10 8.5C10 6.016 12.016 4 14.5 4C16.984 4 19 6.016 19 8.5C19 10.984 16.984 13 14.5 13C12.016 13 10 10.984 10 8.5ZM14.95 8.95H16.75V8.05H14.95V6.25H14.05V8.05H12.25V8.95H14.05V10.75H14.95V8.95Z"
      fill="#059DDF"
      fillRule="evenodd"
    />
  </svg>
);

export const SortIcon: React.FC<{ className: string }> = (props) => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 11 14"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 0L0 2.9925H2.25V8.25H3.75V2.9925H6L3 0Z" fill="#6A707A" />
    <path
      d="M8.25 10.5075V5.25H6.75V10.5075H4.5L7.5 13.5L10.5 10.5075H8.25Z"
      fill="#05A9F4"
    />
  </svg>
);

export type HealthScoreIconProps = {
  backgroundClass: string;
  className?: string;
  innerClass: string;
  outlineClass: string;
};
export const HealthScoreIcon = ({
  backgroundClass,
  className,
  innerClass,
  outlineClass,
}: HealthScoreIconProps) => (
  <svg
    className={className}
    fill="none"
    height="88"
    viewBox="0 0 100 88"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={backgroundClass}
      d="M41 72L38.9971 70.1177C31.2395 63.0493 24.8427 56.9881 19.8068 51.934C14.7708 46.88 10.7802 42.451 7.83477 38.6471C4.88937 34.8431 2.84722 31.4246 1.70833 28.3915C0.569445 25.3585 0 22.3653 0 19.4118C0 13.9437 1.86542 9.34137 5.59626 5.60482C9.32711 1.86827 13.9416 0 19.4397 0C23.681 0 27.6279 1.17647 31.2802 3.52941C34.9325 5.88235 38.1724 9.33333 41 13.8824C43.9847 9.2549 47.2582 5.78431 50.8207 3.47059C54.3831 1.15686 58.2963 0 62.5603 0C68.0584 0 72.6729 1.86827 76.4037 5.60482C80.1346 9.34137 82 13.9437 82 19.4118C82 22.3653 81.4306 25.3585 80.2917 28.3915C79.1528 31.4246 77.1151 34.8347 74.1787 38.6218C71.2423 42.4088 67.2516 46.8378 62.2067 51.9087C57.1618 56.9797 50.7605 63.0493 43.0029 70.1177L41 72Z"
    />
    <path
      className={outlineClass}
      d="M41.059 72L39.4072 70.4706C31.4628 63.098 24.9343 56.8823 19.8216 51.8235C14.7089 46.7647 10.6777 42.3333 7.72806 38.5294C4.77842 34.7255 2.753 31.3795 1.6518 28.4914C0.5506 25.6033 0 22.6552 0 19.6471C0 14.1569 1.86811 9.5098 5.60432 5.70588C9.34053 1.90196 13.9616 0 19.4676 0C23.7151 0 27.6676 1.17647 31.3252 3.52941C34.9827 5.88235 38.2273 9.33333 41.059 13.8824C44.048 9.2549 47.3271 5.78431 50.8965 3.47059C54.4659 1.15686 58.3866 0 62.6586 0C67.7325 0 72.1262 1.76471 75.8397 5.29412C79.5533 8.82353 81.6067 13.1765 82 18.3529H79.6403C79.3257 13.9608 77.5952 10.2353 74.4489 7.17647C71.3026 4.11765 67.3698 2.58824 62.6504 2.58824C58.7962 2.58824 55.2173 3.70588 51.9137 5.94118C48.6101 8.17647 45.5424 11.7647 42.7108 16.7059H39.2892C36.4576 11.7647 33.4026 8.17647 30.1242 5.94118C26.8459 3.70588 23.2937 2.58824 19.4676 2.58824C14.6695 2.58824 10.658 4.21569 7.43309 7.47059C4.20815 10.7255 2.59568 14.7843 2.59568 19.6471C2.59568 22.4706 3.16595 25.2745 4.30647 28.0588C5.447 30.8431 7.44835 34.0615 10.3105 37.7138C13.1727 41.3662 17.1056 45.6407 22.1091 50.5373C27.1126 55.434 33.4293 61.4118 41.059 68.4706C43.6547 66.0392 45.9947 63.8823 48.0791 62C50.1635 60.1176 52.2283 58.1961 54.2734 56.2353L54.5839 56.5264C54.7909 56.7203 55.002 56.937 55.2173 57.1765C55.4325 57.4159 55.6437 57.6326 55.8506 57.8266L56.1612 58.1176C54.1161 60 52.071 61.8627 50.0259 63.7059C47.9808 65.549 45.6604 67.6863 43.0647 70.1176L41.059 72Z"
    />
    <path
      className={innerClass}
      d="M9 37.5H25.5644L30.0468 46H31.541L39.0117 29H42"
    />
  </svg>
);

export type GoogleIconProps = {
  className?: string;
};

export const GoogleIcon = ({ className }: GoogleIconProps) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    style={{ display: "block" }}
  >
    <path
      fill="#EA4335"
      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
    ></path>
    <path
      fill="#4285F4"
      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
    ></path>
    <path
      fill="#FBBC05"
      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
    ></path>
    <path
      fill="#34A853"
      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
    ></path>
    <path fill="none" d="M0 0h48v48H0z"></path>
  </svg>
);

export enum IconName {
  // Custom IconNames
  alignedMap = "alignedMap",
  alignedObjective = "alignedObjective",
  gif = "gif",
  growCards = "growCards",
  listAltAdd = "listAltAdd",
  maps = "maps",
  pulse = "pulse",
  shrinkCards = "shrinkCards",
  sparkle = "sparkle",
  standardMap = "standardMap",
  streak = "streak",
  update_progress = "update_progress",
  // Google IconNames
  "3d_rotation" = "3d_rotation",
  accessibility = "accessibility",
  accessibility_new = "accessibility_new",
  accessible = "accessible",
  accessible_forward = "accessible_forward",
  account_balance = "account_balance",
  account_balance_wallet = "account_balance_wallet",
  account_box = "account_box",
  account_circle = "account_circle",
  add_shopping_cart = "add_shopping_cart",
  add_task = "add_task",
  add_to_drive = "add_to_drive",
  addchart = "addchart",
  admin_panel_settings = "admin_panel_settings",
  ads_click = "ads_click",
  alarm = "alarm",
  alarm_add = "alarm_add",
  alarm_off = "alarm_off",
  alarm_on = "alarm_on",
  all_inbox = "all_inbox",
  all_out = "all_out",
  analytics = "analytics",
  anchor = "anchor",
  android = "android",
  announcement = "announcement",
  api = "api",
  app_blocking = "app_blocking",
  arrow_circle_down = "arrow_circle_down",
  arrow_circle_up = "arrow_circle_up",
  arrow_right_alt = "arrow_right_alt",
  article = "article",
  aspect_ratio = "aspect_ratio",
  assessment = "assessment",
  assignment = "assignment",
  assignment_ind = "assignment_ind",
  assignment_late = "assignment_late",
  assignment_return = "assignment_return",
  assignment_returned = "assignment_returned",
  assignment_turned_in = "assignment_turned_in",
  autorenew = "autorenew",
  backup = "backup",
  backup_table = "backup_table",
  batch_prediction = "batch_prediction",
  book = "book",
  book_online = "book_online",
  bookmark = "bookmark",
  bookmark_add = "bookmark_add",
  bookmark_added = "bookmark_added",
  bookmark_border = "bookmark_border",
  bookmark_remove = "bookmark_remove",
  bookmarks = "bookmarks",
  bug_report = "bug_report",
  build = "build",
  build_circle = "build_circle",
  cached = "cached",
  calendar_today = "calendar_today",
  calendar_view_day = "calendar_view_day",
  calendar_view_month = "calendar_view_month",
  calendar_view_week = "calendar_view_week",
  camera_enhance = "camera_enhance",
  cancel_schedule_send = "cancel_schedule_send",
  card_giftcard = "card_giftcard",
  card_membership = "card_membership",
  card_travel = "card_travel",
  change_history = "change_history",
  check_circle = "check_circle",
  check_circle_outline = "check_circle_outline",
  chrome_reader_mode = "chrome_reader_mode",
  circle_notifications = "circle_notifications",
  class = "class",
  close_fullscreen = "close_fullscreen",
  code = "code",
  code_off = "code_off",
  comment_bank = "comment_bank",
  commute = "commute",
  compare_arrows = "compare_arrows",
  compress = "compress",
  contact_page = "contact_page",
  contact_support = "contact_support",
  contactless = "contactless",
  copyright = "copyright",
  credit_card = "credit_card",
  credit_card_off = "credit_card_off",
  dangerous = "dangerous",
  dashboard = "dashboard",
  dashboard_customize = "dashboard_customize",
  data_exploration = "data_exploration",
  date_range = "date_range",
  delete = "delete",
  delete_forever = "delete_forever",
  delete_outline = "delete_outline",
  description = "description",
  disabled_by_default = "disabled_by_default",
  disabled_visible = "disabled_visible",
  dns = "dns",
  done = "done",
  done_all = "done_all",
  done_outline = "done_outline",
  donut_large = "donut_large",
  donut_small = "donut_small",
  drag_indicator = "drag_indicator",
  dynamic_form = "dynamic_form",
  eco = "eco",
  edit_calendar = "edit_calendar",
  edit_off = "edit_off",
  eject = "eject",
  euro_symbol = "euro_symbol",
  event = "event",
  event_seat = "event_seat",
  exit_to_app = "exit_to_app",
  expand = "expand",
  explore = "explore",
  explore_off = "explore_off",
  extension = "extension",
  extension_off = "extension_off",
  face = "face",
  fact_check = "fact_check",
  favorite = "favorite",
  favorite_border = "favorite_border",
  feedback = "feedback",
  file_present = "file_present",
  filter_alt = "filter_alt",
  filter_list_alt = "filter_list_alt",
  find_in_page = "find_in_page",
  find_replace = "find_replace",
  fingerprint = "fingerprint",
  fit_screen = "fit_screen",
  flaky = "flaky",
  flight_land = "flight_land",
  flight_takeoff = "flight_takeoff",
  flip_to_back = "flip_to_back",
  flip_to_front = "flip_to_front",
  flutter_dash = "flutter_dash",
  free_cancellation = "free_cancellation",
  g_translate = "g_translate",
  gavel = "gavel",
  generating_tokens = "generating_tokens",
  get_app = "get_app",
  grade = "grade",
  grading = "grading",
  group_work = "group_work",
  help = "help",
  help_center = "help_center",
  help_outline = "help_outline",
  hide_source = "hide_source",
  highlight_alt = "highlight_alt",
  highlight_off = "highlight_off",
  history = "history",
  history_toggle_off = "history_toggle_off",
  home = "home",
  home_filled = "home_filled",
  horizontal_split = "horizontal_split",
  hotel_class = "hotel_class",
  hourglass_disabled = "hourglass_disabled",
  hourglass_empty = "hourglass_empty",
  hourglass_full = "hourglass_full",
  http = "http",
  https = "https",
  important_devices = "important_devices",
  info = "info",
  info_outline = "info_outline",
  input = "input",
  integration_instructions = "integration_instructions",
  invert_colors = "invert_colors",
  label = "label",
  label_important = "label_important",
  label_important_outline = "label_important_outline",
  label_off = "label_off",
  label_outline = "label_outline",
  language = "language",
  launch = "launch",
  leaderboard = "leaderboard",
  lightbulb = "lightbulb",
  lightbulb_outline = "lightbulb_outline",
  line_style = "line_style",
  line_weight = "line_weight",
  list = "list",
  lock = "lock",
  lock_clock = "lock_clock",
  lock_open = "lock_open",
  lock_outline = "lock_outline",
  login = "login",
  logout = "logout",
  loyalty = "loyalty",
  manage_accounts = "manage_accounts",
  mark_as_unread = "mark_as_unread",
  markunread_mailbox = "markunread_mailbox",
  maximize = "maximize",
  mediation = "mediation",
  minimize = "minimize",
  model_training = "model_training",
  monitor_heart = "monitor_heart",
  new_label = "new_label",
  next_plan = "next_plan",
  nightlight_round = "nightlight_round",
  no_accounts = "no_accounts",
  not_accessible = "not_accessible",
  not_started = "not_started",
  note_add = "note_add",
  offline_bolt = "offline_bolt",
  offline_pin = "offline_pin",
  online_prediction = "online_prediction",
  opacity = "opacity",
  open_in_browser = "open_in_browser",
  open_in_full = "open_in_full",
  open_in_new = "open_in_new",
  open_in_new_off = "open_in_new_off",
  open_with = "open_with",
  outbond = "outbond",
  outbound = "outbound",
  outbox = "outbox",
  outgoing_mail = "outgoing_mail",
  outlet = "outlet",
  pageview = "pageview",
  paid = "paid",
  pan_tool = "pan_tool",
  payment = "payment",
  pending = "pending",
  pending_actions = "pending_actions",
  perm_camera_mic = "perm_camera_mic",
  perm_contact_calendar = "perm_contact_calendar",
  perm_data_setting = "perm_data_setting",
  perm_device_information = "perm_device_information",
  perm_identity = "perm_identity",
  perm_media = "perm_media",
  perm_phone_msg = "perm_phone_msg",
  perm_scan_wifi = "perm_scan_wifi",
  pets = "pets",
  picture_in_picture = "picture_in_picture",
  picture_in_picture_alt = "picture_in_picture_alt",
  pillars = "pillars",
  pin_end = "pin_end",
  pin_invoke = "pin_invoke",
  plagiarism = "plagiarism",
  play_for_work = "play_for_work",
  polymer = "polymer",
  power_settings_new = "power_settings_new",
  pregnant_woman = "pregnant_woman",
  preview = "preview",
  print = "print",
  privacy_tip = "privacy_tip",
  private_connectivity = "private_connectivity",
  production_quantity_limits = "production_quantity_limits",
  published_with_changes = "published_with_changes",
  query_builder = "query_builder",
  question_answer = "question_answer",
  quickreply = "quickreply",
  receipt = "receipt",
  record_voice_over = "record_voice_over",
  redeem = "redeem",
  remove_done = "remove_done",
  remove_shopping_cart = "remove_shopping_cart",
  reorder = "reorder",
  report_problem = "report_problem",
  request_page = "request_page",
  restore = "restore",
  restore_from_trash = "restore_from_trash",
  restore_page = "restore_page",
  room = "room",
  rounded_corner = "rounded_corner",
  rowing = "rowing",
  rule = "rule",
  saved_search = "saved_search",
  savings = "savings",
  schedule = "schedule",
  schedule_send = "schedule_send",
  search = "search",
  search_off = "search_off",
  segment = "segment",
  send_and_archive = "send_and_archive",
  sensors = "sensors",
  sensors_off = "sensors_off",
  settings = "settings",
  settings_accessibility = "settings_accessibility",
  settings_applications = "settings_applications",
  settings_backup_restore = "settings_backup_restore",
  settings_bluetooth = "settings_bluetooth",
  settings_brightness = "settings_brightness",
  settings_cell = "settings_cell",
  settings_ethernet = "settings_ethernet",
  settings_input_antenna = "settings_input_antenna",
  settings_input_component = "settings_input_component",
  settings_input_composite = "settings_input_composite",
  settings_input_hdmi = "settings_input_hdmi",
  settings_input_svideo = "settings_input_svideo",
  settings_overscan = "settings_overscan",
  settings_phone = "settings_phone",
  settings_power = "settings_power",
  settings_remote = "settings_remote",
  settings_voice = "settings_voice",
  shop = "shop",
  shop_2 = "shop_2",
  shop_two = "shop_two",
  shopping_bag = "shopping_bag",
  shopping_basket = "shopping_basket",
  shopping_cart = "shopping_cart",
  smart_button = "smart_button",
  source = "source",
  space_dashboard = "space_dashboard",
  speaker_notes = "speaker_notes",
  speaker_notes_off = "speaker_notes_off",
  spellcheck = "spellcheck",
  star_rate = "star_rate",
  stars = "stars",
  sticky_note_2 = "sticky_note_2",
  store = "store",
  subject = "subject",
  subtitles_off = "subtitles_off",
  supervised_user_circle = "supervised_user_circle",
  supervisor_account = "supervisor_account",
  support = "support",
  swap_horiz = "swap_horiz",
  swap_horizontal_circle = "swap_horizontal_circle",
  swap_vert = "swap_vert",
  swap_vertical_circle = "swap_vertical_circle",
  swipe = "swipe",
  sync_alt = "sync_alt",
  system_update_alt = "system_update_alt",
  tab = "tab",
  tab_unselected = "tab_unselected",
  table_view = "table_view",
  task_alt = "task_alt",
  text_rotate_up = "text_rotate_up",
  text_rotate_vertical = "text_rotate_vertical",
  text_rotation_angledown = "text_rotation_angledown",
  text_rotation_angleup = "text_rotation_angleup",
  text_rotation_down = "text_rotation_down",
  text_rotation_none = "text_rotation_none",
  theaters = "theaters",
  thumb_down = "thumb_down",
  thumb_down_off_alt = "thumb_down_off_alt",
  thumb_up = "thumb_up",
  thumb_up_off_alt = "thumb_up_off_alt",
  thumbs_up_down = "thumbs_up_down",
  timeline = "timeline",
  tips_and_updates = "tips_and_updates",
  toc = "toc",
  today = "today",
  toll = "toll",
  touch_app = "touch_app",
  tour = "tour",
  track_changes = "track_changes",
  translate = "translate",
  trending_down = "trending_down",
  trending_flat = "trending_flat",
  trending_up = "trending_up",
  try = "try",
  turned_in = "turned_in",
  turned_in_not = "turned_in_not",
  unpublished = "unpublished",
  update = "update",
  update_disabled = "update_disabled",
  upgrade = "upgrade",
  verified = "verified",
  verified_user = "verified_user",
  vertical_split = "vertical_split",
  view_agenda = "view_agenda",
  view_array = "view_array",
  view_carousel = "view_carousel",
  view_column = "view_column",
  view_day = "view_day",
  view_headline = "view_headline",
  view_in_ar = "view_in_ar",
  view_list = "view_list",
  view_module = "view_module",
  view_quilt = "view_quilt",
  view_sidebar = "view_sidebar",
  view_stream = "view_stream",
  view_week = "view_week",
  view_timeline = "view_timeline",
  visibility = "visibility",
  visibility_off = "visibility_off",
  voice_over_off = "voice_over_off",
  watch_later = "watch_later",
  wifi_protected_setup = "wifi_protected_setup",
  work = "work",
  work_off = "work_off",
  work_outline = "work_outline",
  wysiwyg = "wysiwyg",
  youtube_searched_for = "youtube_searched_for",
  zoom_in = "zoom_in",
  zoom_out = "zoom_out",
  add_alert = "add_alert",
  auto_delete = "auto_delete",
  error = "error",
  error_outline = "error_outline",
  notification_important = "notification_important",
  warning = "warning",
  warning_amber = "warning_amber",
  "10k" = "10k",
  "1k" = "1k",
  "1k_plus" = "1k_plus",
  "2k" = "2k",
  "2k_plus" = "2k_plus",
  "3k" = "3k",
  "3k_plus" = "3k_plus",
  "4k" = "4k",
  "4k_plus" = "4k_plus",
  "5g" = "5g",
  "5k" = "5k",
  "5k_plus" = "5k_plus",
  "6k" = "6k",
  "6k_plus" = "6k_plus",
  "7k" = "7k",
  "7k_plus" = "7k_plus",
  "8k" = "8k",
  "8k_plus" = "8k_plus",
  "9k" = "9k",
  "9k_plus" = "9k_plus",
  add_to_queue = "add_to_queue",
  airplay = "airplay",
  album = "album",
  art_track = "art_track",
  av_timer = "av_timer",
  branding_watermark = "branding_watermark",
  call_to_action = "call_to_action",
  closed_caption = "closed_caption",
  closed_caption_disabled = "closed_caption_disabled",
  closed_caption_off = "closed_caption_off",
  control_camera = "control_camera",
  equalizer = "equalizer",
  explicit = "explicit",
  fast_forward = "fast_forward",
  fast_rewind = "fast_rewind",
  featured_play_list = "featured_play_list",
  featured_video = "featured_video",
  fiber_dvr = "fiber_dvr",
  fiber_manual_record = "fiber_manual_record",
  fiber_new = "fiber_new",
  fiber_pin = "fiber_pin",
  fiber_smart_record = "fiber_smart_record",
  forward_10 = "forward_10",
  forward_30 = "forward_30",
  forward_5 = "forward_5",
  games = "games",
  hd = "hd",
  hearing = "hearing",
  hearing_disabled = "hearing_disabled",
  high_quality = "high_quality",
  library_add = "library_add",
  library_add_check = "library_add_check",
  library_books = "library_books",
  library_music = "library_music",
  loop = "loop",
  mic = "mic",
  mic_none = "mic_none",
  mic_off = "mic_off",
  missed_video_call = "missed_video_call",
  movie = "movie",
  music_video = "music_video",
  new_releases = "new_releases",
  not_interested = "not_interested",
  note = "note",
  pause = "pause",
  pause_circle = "pause_circle",
  pause_circle_filled = "pause_circle_filled",
  pause_circle_outline = "pause_circle_outline",
  play_arrow = "play_arrow",
  play_circle = "play_circle",
  play_circle_filled = "play_circle_filled",
  play_circle_outline = "play_circle_outline",
  play_disabled = "play_disabled",
  playlist_add = "playlist_add",
  playlist_add_check = "playlist_add_check",
  playlist_play = "playlist_play",
  queue = "queue",
  queue_music = "queue_music",
  queue_play_next = "queue_play_next",
  radio = "radio",
  recent_actors = "recent_actors",
  remove_from_queue = "remove_from_queue",
  repeat = "repeat",
  repeat_on = "repeat_on",
  repeat_one = "repeat_one",
  repeat_one_on = "repeat_one_on",
  replay = "replay",
  replay_10 = "replay_10",
  replay_30 = "replay_30",
  replay_5 = "replay_5",
  replay_circle_filled = "replay_circle_filled",
  sd = "sd",
  shuffle = "shuffle",
  shuffle_on = "shuffle_on",
  skip_next = "skip_next",
  skip_previous = "skip_previous",
  slow_motion_video = "slow_motion_video",
  snooze = "snooze",
  sort_by_alpha = "sort_by_alpha",
  speed = "speed",
  stop = "stop",
  stop_circle = "stop_circle",
  subscriptions = "subscriptions",
  subtitles = "subtitles",
  surround_sound = "surround_sound",
  video_call = "video_call",
  video_label = "video_label",
  video_library = "video_library",
  video_settings = "video_settings",
  videocam = "videocam",
  videocam_off = "videocam_off",
  volume_down = "volume_down",
  volume_down_alt = "volume_down_alt",
  volume_mute = "volume_mute",
  volume_off = "volume_off",
  volume_up = "volume_up",
  web = "web",
  web_asset = "web_asset",
  web_asset_off = "web_asset_off",
  "3p" = "3p",
  add_ic_call = "add_ic_call",
  alternate_email = "alternate_email",
  app_registration = "app_registration",
  business = "business",
  call = "call",
  call_end = "call_end",
  call_made = "call_made",
  call_merge = "call_merge",
  call_missed = "call_missed",
  call_missed_outgoing = "call_missed_outgoing",
  call_received = "call_received",
  call_split = "call_split",
  cancel_presentation = "cancel_presentation",
  cell_wifi = "cell_wifi",
  chat = "chat",
  chat_bubble = "chat_bubble",
  chat_bubble_outline = "chat_bubble_outline",
  clear_all = "clear_all",
  comment = "comment",
  contact_mail = "contact_mail",
  contact_phone = "contact_phone",
  contacts = "contacts",
  desktop_access_disabled = "desktop_access_disabled",
  dialer_sip = "dialer_sip",
  dialpad = "dialpad",
  document_scanner = "document_scanner",
  domain_disabled = "domain_disabled",
  domain_verification = "domain_verification",
  duo = "duo",
  email = "email",
  forum = "forum",
  forward_to_inbox = "forward_to_inbox",
  hourglass_bottom = "hourglass_bottom",
  hourglass_top = "hourglass_top",
  import_contacts = "import_contacts",
  import_export = "import_export",
  invert_colors_off = "invert_colors_off",
  list_alt = "list_alt",
  live_help = "live_help",
  location_off = "location_off",
  location_on = "location_on",
  mail_outline = "mail_outline",
  mark_chat_read = "mark_chat_read",
  mark_chat_unread = "mark_chat_unread",
  mark_email_read = "mark_email_read",
  mark_email_unread = "mark_email_unread",
  message = "message",
  mobile_screen_share = "mobile_screen_share",
  more_time = "more_time",
  nat = "nat",
  no_sim = "no_sim",
  pause_presentation = "pause_presentation",
  person_add_disabled = "person_add_disabled",
  person_search = "person_search",
  phone = "phone",
  phone_disabled = "phone_disabled",
  phone_enabled = "phone_enabled",
  phonelink_erase = "phonelink_erase",
  phonelink_lock = "phonelink_lock",
  phonelink_ring = "phonelink_ring",
  phonelink_setup = "phonelink_setup",
  portable_wifi_off = "portable_wifi_off",
  present_to_all = "present_to_all",
  print_disabled = "print_disabled",
  qr_code = "qr_code",
  qr_code_2 = "qr_code_2",
  qr_code_scanner = "qr_code_scanner",
  read_more = "read_more",
  ring_volume = "ring_volume",
  rss_feed = "rss_feed",
  rtt = "rtt",
  screen_share = "screen_share",
  sentiment_satisfied_alt = "sentiment_satisfied_alt",
  sip = "sip",
  speaker_phone = "speaker_phone",
  stay_current_landscape = "stay_current_landscape",
  stay_current_portrait = "stay_current_portrait",
  stay_primary_landscape = "stay_primary_landscape",
  stay_primary_portrait = "stay_primary_portrait",
  stop_screen_share = "stop_screen_share",
  swap_calls = "swap_calls",
  textsms = "textsms",
  unsubscribe = "unsubscribe",
  voicemail = "voicemail",
  vpn_key = "vpn_key",
  add = "add",
  add_box = "add_box",
  add_circle = "add_circle",
  add_circle_outline = "add_circle_outline",
  add_link = "add_link",
  amp_stories = "amp_stories",
  archive = "archive",
  attribution = "attribution",
  backspace = "backspace",
  ballot = "ballot",
  biotech = "biotech",
  block = "block",
  block_flipped = "block_flipped",
  bolt = "bolt",
  calculate = "calculate",
  change_circle = "change_circle",
  clear = "clear",
  content_copy = "content_copy",
  content_cut = "content_cut",
  content_paste = "content_paste",
  content_paste_off = "content_paste_off",
  copy_all = "copy_all",
  create = "create",
  delete_sweep = "delete_sweep",
  drafts = "drafts",
  dynamic_feed = "dynamic_feed",
  file_copy = "file_copy",
  filter_list = "filter_list",
  flag = "flag",
  font_download = "font_download",
  font_download_off = "font_download_off",
  forward = "forward",
  gesture = "gesture",
  how_to_reg = "how_to_reg",
  how_to_vote = "how_to_vote",
  inbox = "inbox",
  insights = "insights",
  inventory = "inventory",
  inventory_2 = "inventory_2",
  link = "link",
  link_off = "link_off",
  low_priority = "low_priority",
  mail = "mail",
  markunread = "markunread",
  move_to_inbo = "move_to_inbox",
  next_week = "next_week",
  outlined_flag = "outlined_flag",
  policy = "policy",
  push_pin = "push_pin",
  redo = "redo",
  remove = "remove",
  remove_circle = "remove_circle",
  remove_circle_outline = "remove_circle_outline",
  reply = "reply",
  reply_all = "reply_all",
  report = "report",
  report_gmailerrorred = "report_gmailerrorred",
  report_off = "report_off",
  save = "save",
  save_alt = "save_alt",
  select_all = "select_all",
  send = "send",
  shield = "shield",
  sort = "sort",
  square_foot = "square_foot",
  stacked_bar_chart = "stacked_bar_chart",
  stream = "stream",
  tag = "tag",
  text_format = "text_format",
  unarchive = "unarchive",
  undo = "undo",
  upcoming = "upcoming",
  waves = "waves",
  web_stories = "web_stories",
  weekend = "weekend",
  where_to_vote = "where_to_vote",
  "1x_mobiledata" = "1x_mobiledata",
  "30fps" = "30fps",
  "3g_mobiledata" = "3g_mobiledata",
  "4g_mobiledata" = "4g_mobiledata",
  "4g_plus_mobiledata" = "4g_plus_mobiledata",
  "60fps" = "60fps",
  access_alarm = "access_alarm",
  access_alarms = "access_alarms",
  access_time = "access_time",
  access_time_filled = "access_time_filled",
  ad_units = "ad_units",
  add_alarm = "add_alarm",
  add_to_home_screen = "add_to_home_screen",
  air = "air",
  airplane_ticket = "airplane_ticket",
  airplanemode_active = "airplanemode_active",
  airplanemode_inactive = "airplanemode_inactive",
  aod = "aod",
  battery_alert = "battery_alert",
  battery_charging_full = "battery_charging_full",
  battery_full = "battery_full",
  battery_saver = "battery_saver",
  battery_std = "battery_std",
  battery_unknown = "battery_unknown",
  bloodtype = "bloodtype",
  bluetooth = "bluetooth",
  bluetooth_connected = "bluetooth_connected",
  bluetooth_disabled = "bluetooth_disabled",
  bluetooth_drive = "bluetooth_drive",
  bluetooth_searching = "bluetooth_searching",
  brightness_auto = "brightness_auto",
  brightness_high = "brightness_high",
  brightness_low = "brightness_low",
  brightness_medium = "brightness_medium",
  cable = "cable",
  cameraswitch = "cameraswitch",
  credit_score = "credit_score",
  dark_mode = "dark_mode",
  data_saver_off = "data_saver_off",
  data_saver_on = "data_saver_on",
  data_usage = "data_usage",
  developer_mode = "developer_mode",
  device_thermostat = "device_thermostat",
  devices = "devices",
  do_not_disturb_on_total_silence = "do_not_disturb_on_total_silence",
  dvr = "dvr",
  e_mobiledata = "e_mobiledata",
  edgesensor_high = "edgesensor_high",
  edgesensor_low = "edgesensor_low",
  flashlight_off = "flashlight_off",
  flashlight_on = "flashlight_on",
  flourescent = "flourescent",
  fmd_bad = "fmd_bad",
  fmd_good = "fmd_good",
  g_mobiledata = "g_mobiledata",
  gpp_bad = "gpp_bad",
  gpp_good = "gpp_good",
  gpp_maybe = "gpp_maybe",
  gps_fixed = "gps_fixed",
  gps_not_fixed = "gps_not_fixed",
  gps_off = "gps_off",
  graphic_eq = "graphic_eq",
  grid_3x = "grid_3x3",
  grid_4x = "grid_4x4",
  grid_goldenratio = "grid_goldenratio",
  h_mobiledata = "h_mobiledata",
  h_plus_mobiledata = "h_plus_mobiledata",
  hdr_auto = "hdr_auto",
  hdr_auto_select = "hdr_auto_select",
  hdr_off_select = "hdr_off_select",
  hdr_on_select = "hdr_on_select",
  lens_blur = "lens_blur",
  light_mode = "light_mode",
  location_disabled = "location_disabled",
  location_searching = "location_searching",
  lte_mobiledata = "lte_mobiledata",
  lte_plus_mobiledata = "lte_plus_mobiledata",
  media_bluetooth_off = "media_bluetooth_off",
  media_bluetooth_on = "media_bluetooth_on",
  medication = "medication",
  mobile_friendly = "mobile_friendly",
  mobile_off = "mobile_off",
  mobiledata_off = "mobiledata_off",
  mode_night = "mode_night",
  mode_standby = "mode_standby",
  monitor_weight = "monitor_weight",
  nearby_error = "nearby_error",
  nearby_off = "nearby_off",
  network_cell = "network_cell",
  network_wifi = "network_wifi",
  nfc = "nfc",
  nightlight = "nightlight",
  note_alt = "note_alt",
  password = "password",
  pattern = "pattern",
  pin = "pin",
  play_lesson = "play_lesson",
  price_change = "price_change",
  price_check = "price_check",
  quiz = "quiz",
  r_mobiledata = "r_mobiledata",
  radar = "radar",
  remember_me = "remember_me",
  reset_tv = "reset_tv",
  restart_alt = "restart_alt",
  reviews = "reviews",
  rsvp = "rsvp",
  screen_lock_landscape = "screen_lock_landscape",
  screen_lock_portrait = "screen_lock_portrait",
  screen_lock_rotation = "screen_lock_rotation",
  screen_rotation = "screen_rotation",
  screen_search_desktop = "screen_search_desktop",
  screenshot = "screenshot",
  sd_storage = "sd_storage",
  security_update = "security_update",
  security_update_good = "security_update_good",
  security_update_warning = "security_update_warning",
  sell = "sell",
  send_to_mobile = "send_to_mobile",
  settings_suggest = "settings_suggest",
  settings_system_daydream = "settings_system_daydream",
  share_location = "share_location",
  shortcut = "shortcut",
  signal_cellular_4_bar = "signal_cellular_4_bar",
  signal_cellular_alt = "signal_cellular_alt",
  signal_cellular_connected_no_internet_4_bar = "signal_cellular_connected_no_internet_4_bar",
  signal_cellular_no_sim = "signal_cellular_no_sim",
  signal_cellular_nodata = "signal_cellular_nodata",
  signal_cellular_null = "signal_cellular_null",
  signal_cellular_off = "signal_cellular_off",
  signal_wifi_0_bar = "signal_wifi_0_bar",
  signal_wifi_4_bar = "signal_wifi_4_bar",
  signal_wifi_bad = "signal_wifi_bad",
  signal_wifi_off = "signal_wifi_off",
  signal_wifi_statusbar_null = "signal_wifi_statusbar_null",
  splitscreen = "splitscreen",
  sports_score = "sports_score",
  storage = "storage",
  storm = "storm",
  summarize = "summarize",
  system_security_update = "system_security_update",
  system_security_update_good = "system_security_update_good",
  system_security_update_warning = "system_security_update_warning",
  task = "task",
  thermostat = "thermostat",
  timer_10_select = "timer_10_select",
  timer_3_select = "timer_3_select",
  tungsten = "tungsten",
  usb = "usb",
  usb_off = "usb_off",
  wallpaper = "wallpaper",
  water = "water",
  widgets = "widgets",
  wifi_calling = "wifi_calling",
  wifi_lock = "wifi_lock",
  wifi_tethering = "wifi_tethering",
  wifi_tethering_error_rounded = "wifi_tethering_error_rounded",
  wifi_tethering_off = "wifi_tethering_off",
  add_chart = "add_chart",
  add_comment = "add_comment",
  align_horizontal_center = "align_horizontal_center",
  align_horizontal_left = "align_horizontal_left",
  align_horizontal_right = "align_horizontal_right",
  align_vertical_bottom = "align_vertical_bottom",
  align_vertical_center = "align_vertical_center",
  align_vertical_top = "align_vertical_top",
  area_chart = "area_chart",
  attach_file = "attach_file",
  attach_money = "attach_money",
  auto_graph = "auto_graph",
  bar_chart = "bar_chart",
  border_all = "border_all",
  border_bottom = "border_bottom",
  border_clear = "border_clear",
  border_color = "border_color",
  border_horizontal = "border_horizontal",
  border_inner = "border_inner",
  border_left = "border_left",
  border_outer = "border_outer",
  border_right = "border_right",
  border_style = "border_style",
  border_top = "border_top",
  border_vertical = "border_vertical",
  bubble_chart = "bubble_chart",
  checklist = "checklist",
  checklist_rtl = "checklist_rtl",
  drag_handle = "drag_handle",
  draw = "draw",
  edit_note = "edit_note",
  format_align_center = "format_align_center",
  format_align_justify = "format_align_justify",
  format_align_left = "format_align_left",
  format_align_right = "format_align_right",
  format_bold = "format_bold",
  format_clear = "format_clear",
  format_color_fill = "format_color_fill",
  format_color_reset = "format_color_reset",
  format_color_text = "format_color_text",
  format_indent_decrease = "format_indent_decrease",
  format_indent_increase = "format_indent_increase",
  format_italic = "format_italic",
  format_line_spacing = "format_line_spacing",
  format_list_bulleted = "format_list_bulleted",
  format_list_numbered = "format_list_numbered",
  format_list_numbered_rtl = "format_list_numbered_rtl",
  format_paint = "format_paint",
  format_quote = "format_quote",
  format_shapes = "format_shapes",
  format_size = "format_size",
  format_strikethrough = "format_strikethrough",
  format_textdirection_l_to_r = "format_textdirection_l_to_r",
  format_textdirection_r_to_l = "format_textdirection_r_to_l",
  format_underlined = "format_underlined",
  functions = "functions",
  height = "height",
  highlight = "highlight",
  horizontal_distribute = "horizontal_distribute",
  horizontal_rule = "horizontal_rule",
  insert_chart = "insert_chart",
  insert_chart_outlined = "insert_chart_outlined",
  insert_comment = "insert_comment",
  insert_drive_file = "insert_drive_file",
  insert_emoticon = "insert_emoticon",
  insert_invitation = "insert_invitation",
  insert_link = "insert_link",
  insert_photo = "insert_photo",
  linear_scale = "linear_scale",
  margin = "margin",
  merge_type = "merge_type",
  mode = "mode",
  mode_comment = "mode_comment",
  mode_edit = "mode_edit",
  mode_edit_outline = "mode_edit_outline",
  monetization_on = "monetization_on",
  money_off = "money_off",
  money_off_csred = "money_off_csred",
  multiline_chart = "multiline_chart",
  notes = "notes",
  padding = "padding",
  pie_chart = "pie_chart",
  pie_chart_outline = "pie_chart_outline",
  pie_chart_outlined = "pie_chart_outlined",
  post_add = "post_add",
  publish = "publish",
  query_stats = "query_stats",
  scatter_plot = "scatter_plot",
  schema = "schema",
  score = "score",
  short_text = "short_text",
  show_chart = "show_chart",
  space_bar = "space_bar",
  stacked_line_chart = "stacked_line_chart",
  strikethrough_s = "strikethrough_s",
  subscript = "subscript",
  superscript = "superscript",
  table_chart = "table_chart",
  table_rows = "table_rows",
  text_fields = "text_fields",
  title = "title",
  vertical_align_bottom = "vertical_align_bottom",
  vertical_align_center = "vertical_align_center",
  vertical_align_top = "vertical_align_top",
  vertical_distribute = "vertical_distribute",
  wrap_text = "wrap_text",
  approval = "approval",
  attach_email = "attach_email",
  attachment = "attachment",
  cloud = "cloud",
  cloud_circle = "cloud_circle",
  cloud_done = "cloud_done",
  cloud_download = "cloud_download",
  cloud_off = "cloud_off",
  cloud_queue = "cloud_queue",
  cloud_upload = "cloud_upload",
  create_new_folder = "create_new_folder",
  download = "download",
  download_done = "download_done",
  download_for_offline = "download_for_offline",
  downloading = "downloading",
  drive_file_move = "drive_file_move",
  drive_file_move_outline = "drive_file_move_outline",
  drive_file_move_rtl = "drive_file_move_rtl",
  drive_file_rename_outline = "drive_file_rename_outline",
  drive_folder_upload = "drive_folder_upload",
  file_download = "file_download",
  file_download_done = "file_download_done",
  file_download_off = "file_download_off",
  file_upload = "file_upload",
  folder = "folder",
  folder_open = "folder_open",
  folder_shared = "folder_shared",
  grid_view = "grid_view",
  request_quote = "request_quote",
  rule_folder = "rule_folder",
  snippet_folder = "snippet_folder",
  text_snippet = "text_snippet",
  topic = "topic",
  upload = "upload",
  upload_file = "upload_file",
  workspaces = "workspaces",
  workspaces_filled = "workspaces_filled",
  workspaces_outline = "workspaces_outline",
  browser_not_supported = "browser_not_supported",
  cast = "cast",
  cast_connected = "cast_connected",
  cast_for_education = "cast_for_education",
  computer = "computer",
  connected_tv = "connected_tv",
  desktop_mac = "desktop_mac",
  desktop_windows = "desktop_windows",
  developer_board = "developer_board",
  developer_board_off = "developer_board_off",
  device_hub = "device_hub",
  device_unknown = "device_unknown",
  devices_other = "devices_other",
  dock = "dock",
  earbuds = "earbuds",
  earbuds_battery = "earbuds_battery",
  gamepad = "gamepad",
  headphones = "headphones",
  headphones_battery = "headphones_battery",
  headset = "headset",
  headset_mic = "headset_mic",
  headset_off = "headset_off",
  home_max = "home_max",
  home_mini = "home_mini",
  keyboard = "keyboard",
  keyboard_alt = "keyboard_alt",
  keyboard_arrow_down = "keyboard_arrow_down",
  keyboard_arrow_left = "keyboard_arrow_left",
  keyboard_arrow_right = "keyboard_arrow_right",
  keyboard_arrow_up = "keyboard_arrow_up",
  keyboard_backspace = "keyboard_backspace",
  keyboard_capslock = "keyboard_capslock",
  keyboard_hide = "keyboard_hide",
  keyboard_return = "keyboard_return",
  keyboard_tab = "keyboard_tab",
  keyboard_voice = "keyboard_voice",
  laptop = "laptop",
  laptop_chromebook = "laptop_chromebook",
  laptop_mac = "laptop_mac",
  laptop_windows = "laptop_windows",
  memory = "memory",
  monitor = "monitor",
  mouse = "mouse",
  phone_android = "phone_android",
  phone_iphone = "phone_iphone",
  phonelink = "phonelink",
  phonelink_off = "phonelink_off",
  point_of_sale = "point_of_sale",
  power_input = "power_input",
  router = "router",
  scanner = "scanner",
  security = "security",
  sim_card = "sim_card",
  smart_display = "smart_display",
  smart_screen = "smart_screen",
  smart_toy = "smart_toy",
  smartphone = "smartphone",
  speaker = "speaker",
  speaker_group = "speaker_group",
  tablet = "tablet",
  tablet_android = "tablet_android",
  tablet_mac = "tablet_mac",
  toys = "toys",
  tv = "tv",
  videogame_asset = "videogame_asset",
  videogame_asset_off = "videogame_asset_off",
  watch = "watch",
  sensor_door = "sensor_door",
  sensor_window = "sensor_window",
  "10mp" = "10mp",
  "11mp" = "11mp",
  "12mp" = "12mp",
  "13mp" = "13mp",
  "14mp" = "14mp",
  "15mp" = "15mp",
  "16mp" = "16mp",
  "17mp" = "17mp",
  "18mp" = "18mp",
  "19mp" = "19mp",
  "20mp" = "20mp",
  "21mp" = "21mp",
  "22mp" = "22mp",
  "23mp" = "23mp",
  "24mp" = "24mp",
  "2mp" = "2mp",
  "30fps_select" = "30fps_select",
  "3mp" = "3mp",
  "4mp" = "4mp",
  "5mp" = "5mp",
  "60fps_s" = "60fps_select",
  "6mp" = "6mp",
  "7mp" = "7mp",
  "8mp" = "8mp",
  "9mp" = "9mp",
  add_a_photo = "add_a_photo",
  add_photo_alternate = "add_photo_alternate",
  add_to_photos = "add_to_photos",
  adjust = "adjust",
  animation = "animation",
  assistant = "assistant",
  assistant_photo = "assistant_photo",
  audiotrack = "audiotrack",
  auto_awesome = "auto_awesome",
  auto_awesome_mosaic = "auto_awesome_mosaic",
  auto_awesome_motion = "auto_awesome_motion",
  auto_fix_high = "auto_fix_high",
  auto_fix_normal = "auto_fix_normal",
  auto_fix_off = "auto_fix_off",
  auto_stories = "auto_stories",
  autofps_select = "autofps_select",
  bedtime = "bedtime",
  blur_circular = "blur_circular",
  blur_linear = "blur_linear",
  blur_off = "blur_off",
  blur_on = "blur_on",
  brightness_1 = "brightness_1",
  brightness_2 = "brightness_2",
  brightness_3 = "brightness_3",
  brightness_4 = "brightness_4",
  brightness_5 = "brightness_5",
  brightness_6 = "brightness_6",
  brightness_7 = "brightness_7",
  broken_image = "broken_image",
  brush = "brush",
  burst_mode = "burst_mode",
  camera = "camera",
  camera_alt = "camera_alt",
  camera_front = "camera_front",
  camera_rear = "camera_rear",
  camera_roll = "camera_roll",
  cases = "cases",
  center_focus_strong = "center_focus_strong",
  center_focus_weak = "center_focus_weak",
  circle = "circle",
  collections = "collections",
  collections_bookmark = "collections_bookmark",
  color_lens = "color_lens",
  colorize = "colorize",
  compare = "compare",
  control_point = "control_point",
  control_point_duplicate = "control_point_duplacate",
  crop = "crop",
  crop_16_9 = "crop_16_9",
  crop_3_2 = "crop_3_2",
  crop_5_4 = "crop_5_4",
  crop_7_5 = "crop_7_5",
  crop_din = "crop_din",
  crop_free = "crop_free",
  crop_landscape = "crop_landscape",
  crop_original = "crop_original",
  crop_portrait = "crop_portrait",
  crop_rotate = "crop_rotate",
  crop_square = "crop_square",
  dehaze = "dehaze",
  details = "details",
  dirty_lens = "dirty_lens",
  edit = "edit",
  euro = "euro",
  exposure = "exposure",
  exposure_neg_1 = "exposure_neg_1",
  exposure_neg_2 = "exposure_neg_2",
  exposure_plus_1 = "exposure_plus_1",
  exposure_plus_2 = "exposure_plus_2",
  exposure_zero = "exposure_zero",
  face_retouching_natural = "face_retouching_naural",
  face_retouching_off = "face_retouching_off",
  filter = "filter",
  filter_1 = "filter_1",
  filter_2 = "filter_2",
  filter_3 = "filter_3",
  filter_4 = "filter_4",
  filter_5 = "filter_5",
  filter_6 = "filter_6",
  filter_7 = "filter_7",
  filter_8 = "filter_8",
  filter_9 = "filter_9",
  filter_9_plus = "filter_9_plus",
  filter_b_and_w = "filter_b_and_w",
  filter_center_focus = "filter_center_focus",
  filter_drama = "filter_drama",
  filter_frames = "filter_frames",
  filter_hdr = "filter_hdr",
  filter_none = "filter_none",
  filter_tilt_shift = "filter_tilt_shift",
  filter_vintage = "filter_vintage",
  flare = "flare",
  flash_auto = "flash_auto",
  flash_off = "flash_off",
  flash_on = "flash_on",
  flip = "flip",
  flip_camera_android = "flip_camera_android",
  flip_camera_ios = "flip_camera_ios",
  gradient = "gradient",
  grain = "grain",
  grid_off = "grid_off",
  grid_on = "grid_on",
  hdr_enhanced_select = "hdr_enhanced_select",
  hdr_off = "hdr_off",
  hdr_on = "hdr_on",
  hdr_plus = "hdr_plus",
  hdr_strong = "hdr_strong",
  hdr_weak = "hdr_weak",
  healing = "healing",
  hevc = "hevc",
  hide_image = "hide_image",
  image = "image",
  image_aspect_ratio = "image_aspect_ratio",
  image_not_supported = "image_not_supported",
  image_search = "image_search",
  incomplete_circle = "incomplete_circle",
  iso = "iso",
  landscape = "landscape",
  leak_add = "leak_add",
  leak_remove = "leak_remove",
  lens = "lens",
  linked_camera = "linked_camera",
  looks = "looks",
  looks_3 = "looks_3",
  looks_4 = "looks_4",
  looks_5 = "looks_5",
  looks_6 = "looks_6",
  looks_one = "looks_one",
  looks_two = "looks_two",
  loupe = "loupe",
  mic_external_off = "mic_external_off",
  mic_external_on = "mic_external_on",
  monochrome_photos = "monochrome_photos",
  motion_photos_auto = "motion_photos_auto",
  motion_photos_off = "motion_photos_off",
  motion_photos_on = "motion_photos_on",
  motion_photos_pause = "motion_photos_pause",
  motion_photos_paused = "motion_photos_paused",
  movie_creation = "movie_creation",
  movie_filter = "movie_filter",
  mp = "mp",
  music_note = "music_note",
  music_off = "music_off",
  nature = "nature",
  nature_people = "nature_people",
  navigate_before = "navigate_before",
  navigate_next = "navigate_next",
  palette = "palette",
  panorama = "panorama",
  panorama_fish_eye = "panorama_fish_eye",
  panorama_horizontal = "panorama_horizontal",
  panorama_horizontal_select = "panorama_horizontal_select",
  panorama_photosphere = "panorama_photosphere",
  panorama_photosphere_select = "panorama_photosphere_select",
  panorama_vertical = "panorama_vertical",
  panorama_vertical_select = "panorama_vertical_select",
  panorama_wide_angle = "panorama_wide_angle",
  panorama_wide_angle_select = "panorama_wide_angle_select",
  photo = "photo",
  photo_album = "photo_album",
  photo_camera = "photo_camera",
  photo_camera_back = "photo_camera_back",
  photo_camera_front = "photo_camera_front",
  photo_filter = "photo_filter",
  photo_library = "photo_library",
  photo_size_select_actual = "photo_size_select_actual",
  photo_size_select_large = "photo_size_select_large",
  photo_size_select_small = "photo_size_select_small",
  picture_as_pdf = "picture_as_pdf",
  portrait = "portrait",
  raw_off = "raw_off",
  raw_on = "raw_on",
  receipt_long = "receipt_long",
  remove_red_eye = "remove_red_eye",
  rotate_90_degrees_ccw = "rotate_90_degrees_ccw",
  rotate_left = "rotate_left",
  rotate_right = "rotate_right",
  shutter_speed = "shutter_speed",
  slideshow = "slideshow",
  straighten = "straighten",
  style = "style",
  switch_camera = "switch_camera",
  switch_video = "switch_video",
  tag_faces = "tag_faces",
  texture = "texture",
  thermostat_auto = "thermostat_auto",
  timelapse = "timelapse",
  timer = "timer",
  timer_10 = "timer_10",
  timer_3 = "timer_3",
  timer_off = "timer_off",
  tonality = "tonality",
  transform = "transform",
  tune = "tune",
  video_camera_back = "video_camera_back",
  video_camera_front = "video_camera_front",
  video_stable = "video_stable",
  view_comfy = "view_comfy",
  view_compact = "view_compact",
  vignette = "vignette",
  vrpano = "vrpano",
  wb_auto = "wb_auto",
  wb_cloudy = "wb_cloudy",
  wb_incandescent = "wb_incandescent",
  wb_iridescent = "wb_iridescent",
  wb_shade = "wb_shade",
  wb_sunny = "wb_sunny",
  wb_twighlight = "wb_twighlight",
  wb_twilight = "wb_twilight",
  add_business = "add_business",
  add_location = "add_location",
  add_location_alt = "add_location_alt",
  add_road = "add_road",
  agriculture = "agriculture",
  alt_route = "alt_route",
  atm = "atm",
  attractions = "attractions",
  badge = "badge",
  bakery_dining = "bakery_dining",
  beenhere = "beenhere",
  bike_scooter = "bike_scooter",
  breakfast_dining = "breakfast_dining",
  brunch_dining = "brunch_dining",
  bus_alert = "bus_alert",
  car_rental = "car_rental",
  car_repair = "car_repair",
  category = "category",
  celebration = "celebration",
  cleaning_services = "cleaning_services",
  compass_calibration = "compass_calibration",
  delivery_diniing = "delivery_dining",
  departure_board = "departure_board",
  design_services = "design_services",
  dinner_dining = "dinner_dining",
  directions = "directions",
  directions_bike = "directions_bike",
  directions_boat = "directions_boat",
  directions_boat_filled = "directions_boat_filled",
  directions_bus = "directions_bus",
  directions_bus_filled = "directions_bus_filled",
  directions_car = "directions_car",
  directions_car_filled = "directions_car_filled",
  directions_railway = "directions_railway",
  directions_railway_filled = "directions_railway_filled",
  directions_run = "directions_run",
  directions_subway = "directions_subway",
  directions_subway_filled = "directions_subway_filled",
  directions_transit = "directions_transit",
  directions_transit_filled = "directions_transit_filled",
  directions_walk = "directions_walk",
  dry_cleaning = "dry_cleaning",
  edit_attributes = "edit_attributes",
  edit_location = "edit_location",
  edit_location_alt = "edit_location_alt",
  edit_road = "edit_road",
  electric_bike = "electric_bike",
  electric_car = "electric_car",
  electric_moped = "electric_moped",
  electric_rickshaw = "electric_rickshaw",
  electric_scooter = "electric_scooter",
  electrical_services = "electrical_services",
  emergency = "emergency",
  ev_station = "ev_station",
  fastfood = "fastfood",
  festival = "festival",
  flight = "flight",
  hail = "hail",
  handyman = "handyman",
  hardware = "hardware",
  home_repair_service = "home_repair_service",
  hotel = "hotel",
  hvac = "hvac",
  icecream = "icecream",
  layers = "layers",
  layers_clear = "layers_clear",
  liquor = "liquor",
  local_activity = "local_activity",
  local_airport = "local_airport",
  local_atm = "local_atm",
  local_bar = "local_bar",
  local_cafe = "local_cafe",
  local_car_wash = "local_car_wash",
  local_convenience_store = "local_convenience_store",
  local_dining = "local_dining",
  local_drink = "local_drink",
  local_fire_department = "local_fire_department",
  local_florist = "local_florist",
  local_gas_station = "local_gas_station",
  local_grocery_store = "local_grocery_store",
  local_hospital = "local_hospital",
  local_hotel = "local_hotel",
  local_laundry_service = "local_laundry_service",
  local_library = "local_library",
  local_mall = "local_mall",
  local_movies = "local_movies",
  local_offer = "local_offer",
  local_parking = "local_parking",
  local_pharmacy = "local_pharmacy",
  local_phone = "local_phone",
  local_pizza = "local_pizza",
  local_play = "local_play",
  local_police = "local_police",
  local_post_office = "local_post_office",
  local_printshop = "local_printshop",
  local_see = "local_see",
  local_shipping = "local_shipping",
  local_taxi = "local_taxi",
  location_pin = "location_pin",
  lunch_dining = "lunch_dining",
  map = "map",
  maps_ugc = "maps_ugc",
  medical_services = "medical_services",
  menu_book = "menu_book",
  miscellaneous_services = "miscellaneous_services",
  money = "money",
  moped = "moped",
  moving = "moving",
  multiple_stop = "multiple_stop",
  museum = "museum",
  my_location = "my_location",
  navigation = "navigation",
  near_me = "near_me",
  near_me_disabled = "near_me_disabled",
  nightlife = "nightlife",
  no_meals = "no_meals",
  no_meals_ouline = "no_meals_ouline",
  no_transfer = "no_transfer",
  not_listed_location = "not_listed_location",
  park = "park",
  pedal_bike = "pedal_bike",
  person_pin = "person_pin",
  person_pin_circle = "person_pin_circle",
  pest_control = "pest_control",
  pest_control_rodent = "pest_control_rodent",
  pin_drop = "pin_drop",
  place = "place",
  plumbing = "plumbing",
  railway_alert = "railway_alert",
  ramen_dining = "ramen_dining",
  rate_review = "rate_review",
  restaurant = "restaurant",
  restaurant_menu = "restaurant_menu",
  run_circle = "run_circle",
  sailing = "sailing",
  satellite = "satellite",
  set_meal = "set_meal",
  snowmobile = "snowmobile",
  store_mall_directory = "store_mall_directory",
  streetview = "streetview",
  subway = "subway",
  takeout_dining = "takeout_dining",
  taxi_alert = "taxi_alert",
  terrain = "terrain",
  theater_comedy = "theater_comedy",
  traffic = "traffic",
  train = "train",
  tram = "tram",
  transfer_within_a_station = "transfer_within_a_station",
  transit_enterexit = "transit_enterexit",
  trip_origin = "trip_origin",
  two_wheeler = "two_wheeler",
  volunteer_activism = "volunteer_activism",
  wine_bar = "wine_bar",
  wrong_location = "wrong_location",
  zoom_out_map = "zoom_out_map",
  app_settings_alt = "app_settings_alt",
  apps = "apps",
  arrow_back = "arrow_back",
  arrow_back_ios = "arrow_back_ios",
  arrow_back_ios_new = "arrow_back_ios_new",
  arrow_downward = "arrow_downward",
  arrow_drop_down = "arrow_drop_down",
  arrow_drop_down_circle = "arrow_drop_down_circle",
  arrow_drop_up = "arrow_drop_up",
  arrow_forward = "arrow_forward",
  arrow_forward_ios = "arrow_forward_ios",
  arrow_left = "arrow_left",
  arrow_right = "arrow_right",
  arrow_upward = "arrow_upward",
  assistant_direction = "assistant_direction",
  assistant_navigation = "assistant_navigation",
  campaign = "campaign",
  cancel = "cancel",
  check = "check",
  chevron_left = "chevron_left",
  chevron_right = "chevron_right",
  close = "close",
  double_arrow = "double_arrow",
  east = "east",
  expand_less = "expand_less",
  expand_more = "expand_more",
  first_page = "first_page",
  fullscreen = "fullscreen",
  fullscreen_exit = "fullscreen_exit",
  home_work = "home_work",
  last_page = "last_page",
  legend_toggle = "legend_toggle",
  maps_home_work = "maps_home_work",
  menu = "menu",
  menu_open = "menu_open",
  more_horiz = "more_horiz",
  more_vert = "more_vert",
  north = "north",
  north_east = "north_east",
  north_west = "north_west",
  offline_share = "offline_share",
  payments = "payments",
  pivot_table_chart = "pivot_table_chart",
  refresh = "refresh",
  south = "south",
  south_east = "south_east",
  south_west = "south_west",
  subdirectory_arrow_left = "subdirectory_arrow_left",
  subdirectory_arrow_right = "subdirectory_arrow_right",
  switch_left = "switch_left",
  switch_right = "switch_right",
  unfold_less = "unfold_less",
  unfold_more = "unfold_more",
  waterfall_chart = "waterfall_chart",
  west = "west",
  account_tree = "account_tree",
  adb = "adb",
  add_call = "add_call",
  airline_seat_flat = "airline_seat_flat",
  airline_seat_flat_angled = "airline_seat_flat_angled",
  airline_seat_individual_suite = "airline_seat_individual_suite",
  airline_seat_legroom_extra = "airline_seat_legroom_extra",
  airline_seat_legroom_normal = "airline_seat_legroom_normal",
  airline_seat_legroom_reduced = "airline_seat_legroom_reduced",
  airline_seat_recline_extra = "airline_seat_recline_extra",
  airline_seat_recline_normal = "airline_seat_recline_normal",
  bluetooth_audio = "bluetooth_audio",
  confirmation_number = "confirmation_number",
  directions_off = "directions_off",
  disc_full = "disc_full",
  do_disturb = "do_disturb",
  do_disturb_alt = "do_disturb_alt",
  do_disturb_off = "do_disturb_off",
  do_disturb_on = "do_disturb_on",
  do_not_disturb = "do_not_disturb",
  do_not_disturb_alt = "do_not_disturb_alt",
  do_not_disturb_off = "do_not_disturb_off",
  do_not_disturb_on = "do_not_disturb_on",
  drive_eta = "drive_eta",
  enhanced_encryption = "enhanced_encryption",
  event_available = "event_available",
  event_busy = "event_busy",
  event_note = "event_note",
  folder_special = "folder_special",
  imagesearch_roller = "imagesearch_roller",
  live_tv = "live_tv",
  mms = "mms",
  more = "more",
  network_check = "network_check",
  network_locked = "network_locked",
  no_encryption = "no_encryption",
  no_encryption_gmailerrorred = "no_encryption_gmailerrorred",
  ondemand_video = "ondemand_video",
  personal_video = "personal_video",
  phone_bluetooth_speaker = "phone_bluetooth_speaker",
  phone_callback = "phone_callback",
  phone_forwarded = "phone_forwarded",
  phone_in_talk = "phone_in_talk",
  phone_locked = "phone_locked",
  phone_missed = "phone_missed",
  phone_paused = "phone_paused",
  power = "power",
  power_off = "power_off",
  priority_high = "priority_high",
  running_with_errors = "running_with_errors",
  sd_card = "sd_card",
  sd_card_alert = "sd_card_alert",
  sim_card_alert = "sim_card_alert",
  sms = "sms",
  sms_failed = "sms_failed",
  support_agent = "support_agent",
  sync = "sync",
  sync_disabled = "sync_disabled",
  sync_problem = "sync_problem",
  system_update = "system_update",
  tap_and_play = "tap_and_play",
  time_to_leave = "time_to_leave",
  tv_off = "tv_off",
  vibration = "vibration",
  voice_chat = "voice_chat",
  vpn_lock = "vpn_lock",
  wc = "wc",
  wifi = "wifi",
  wifi_off = "wifi_off",
  ac_unit = "ac_unit",
  airport_shuttle = "airport_shuttle",
  all_inclusive = "all_inclusive",
  apartment = "apartment",
  baby_changing_station = "baby_changing_station",
  backpack = "backpack",
  balcony = "balcony",
  bathtub = "bathtub",
  beach_access = "beach_access",
  bento = "bento",
  bungalow = "bungalow",
  business_center = "business_center",
  cabin = "cabin",
  carpenter = "carpenter",
  casino = "casino",
  chalet = "chalet",
  charging_station = "charging_station",
  checkroom = "checkroom",
  child_care = "child_care",
  child_friendly = "child_friendly",
  corporate_fare = "corporate_fare",
  cottage = "cottage",
  countertops = "countertops",
  crib = "crib",
  do_not_step = "do_not_step",
  do_not_touch = "do_not_touch",
  dry = "dry",
  elevator = "elevator",
  escalator = "escalator",
  escalator_warning = "escalator_warning",
  family_restroom = "family_restroom",
  fence = "fence",
  fire_extinguisher = "fire_extinguisher",
  fitness_center = "fitness_center",
  food_bank = "food_bank",
  foundation = "foundation",
  free_breakfast = "free_breakfast",
  gite = "gite",
  golf_course = "golf_course",
  grass = "grass",
  holiday_village = "holiday_village",
  hot_tub = "hot_tub",
  house = "house",
  house_siding = "house_siding",
  houseboat = "houseboat",
  iron = "iron",
  kitchen = "kitchen",
  meeting_room = "meeting_room",
  microwave = "microwave",
  night_shelter = "night_shelter",
  no_backpack = "no_backpack",
  no_cell = "no_cell",
  no_drinks = "no_drinks",
  no_flash = "no_flash",
  no_food = "no_food",
  no_meeting_room = "no_meeting_room",
  no_photography = "no_photography",
  no_stroller = "no_stroller",
  other_houses = "other_houses",
  pool = "pool",
  rice_bowl = "rice_bowl",
  roofing = "roofing",
  room_preferences = "room_preferences",
  room_service = "room_service",
  rv_hookup = "rv_hookup",
  smoke_free = "smoke_free",
  smoking_rooms = "smoking_rooms",
  soap = "soap",
  spa = "spa",
  sports_bar = "sports_bar",
  stairs = "stairs",
  storefront = "storefront",
  stroller = "stroller",
  tapas = "tapas",
  tty = "tty",
  umbrella = "umbrella",
  villa = "villa",
  wash = "wash",
  water_damage = "water_damage",
  wheelchair_pickup = "wheelchair_pickup",
  bathroom = "bathroom",
  bed = "bed",
  bedroom_baby = "bedroom_baby",
  bedroom_child = "bedroom_child",
  bedroom_parent = "bedroom_parent",
  blender = "blender",
  camera_indoor = "camera_indoor",
  camera_outdoor = "camera_outdoor",
  chair = "chair",
  chair_alt = "chair_alt",
  coffee = "coffee",
  coffee_maker = "coffee_maker",
  dining = "dining",
  door_back = "door_back",
  door_front = "door_front",
  door_sliding = "door_sliding",
  doorbell = "doorbell",
  feed = "feed",
  flatware = "flatware",
  garage = "garage",
  light = "light",
  living = "living",
  manage_search = "manage_search",
  podcasts = "podcasts",
  shower = "shower",
  window = "window",
  yard = "yard",
  "6_ft_apart" = "6_ft_apart",
  add_moderator = "add_moderator",
  add_reaction = "add_reaction",
  architecture = "architecture",
  back_hand = "back_hand",
  cake = "cake",
  catching_pokemon = "catching_pokemon",
  clean_hands = "clean_hands",
  compost = "compost",
  connect_without_contact = "connect_without_contact",
  construction = "construction",
  coronavirus = "coronavirus",
  cruelty_free = "cruelty_free",
  deck = "deck",
  domain = "domain",
  downhill_skiing = "downhill_skiing",
  edit_notifications = "edit_notifications",
  elderly = "elderly",
  emoji_emotions = "emoji_emotions",
  emoji_events = "emoji_events",
  emoji_flags = "emoji_flags",
  emoji_food_beverage = "emoji_food_beverage",
  emoji_nature = "emoji_nature",
  emoji_objects = "emoji_objects",
  emoji_people = "emoji_people",
  emoji_symbols = "emoji_symbols",
  emoji_transportation = "emoji_transportation",
  engineering = "engineering",
  facebook = "facebook",
  female = "female",
  fireplace = "fireplace",
  follow_the_signs = "follow_the_signs",
  front_hand = "front_hand",
  group = "group",
  group_add = "group_add",
  group_off = "group_off",
  groups = "groups",
  health_and_safety = "health_and_safety",
  hiking = "hiking",
  history_edu = "history_edu",
  ice_skating = "ice_skating",
  ios_share = "ios_share",
  kayaking = "kayaking",
  king_bed = "king_bed",
  kitesurfing = "kitesurfing",
  location_city = "location_city",
  luggage = "luggage",
  male = "male",
  masks = "masks",
  military_tech = "military_tech",
  mood = "mood",
  mood_bad = "mood_bad",
  nights_stay = "nights_stay",
  no_luggage = "no_luggage",
  nordic_walking = "nordic_walking",
  notification_add = "notification_add",
  notifications = "notifications",
  notifications_active = "notifications_active",
  notifications_none = "notifications_none",
  notifications_off = "notifications_off",
  notifications_paused = "notifications_paused",
  outdoor_grill = "outdoor_grill",
  pages = "pages",
  paragliding = "paragliding",
  party_mode = "party_mode",
  people = "people",
  people_alt = "people_alt",
  people_outline = "people_outline",
  person = "person",
  person_add = "person_add",
  person_add_alt = "person_add_alt",
  person_add_alt_1 = "person_add_alt_1",
  person_off = "person_off",
  person_outline = "person_outline",
  person_remove = "person_remove",
  person_remove_alt_1 = "person_remove_alt_1",
  personal_injury = "personal_injury",
  piano = "piano",
  piano_off = "piano_off",
  plus_one = "plus_one",
  poll = "poll",
  precision_manufacturing = "precision_manufacturing",
  psychology = "psychology",
  public = "public",
  public_off = "public_off",
  real_estate_agent = "real_estate_agent",
  recommend = "recommend",
  recycling = "recycling",
  reduce_capacity = "reduce_capacity",
  remove_moderator = "remove_moderator",
  safety_divider = "safety_divider",
  sanitizer = "sanitizer",
  school = "school",
  science = "science",
  self_improvement = "self_improvement",
  sentiment_dissatisfied = "sentiment_dissatisfied",
  sentiment_neutral = "sentiment_neutral",
  sentiment_satisfied = "sentiment_satisfied",
  sentiment_very_dissatisfied = "sentiment_very_dissatisfied",
  sentiment_very_satisfied = "sentiment_very_satisfied",
  share = "share",
  sick = "sick",
  single_bed = "single_bed",
  skateboarding = "skateboarding",
  sledding = "sledding",
  snowboarding = "snowboarding",
  snowshoeing = "snowshoeing",
  social_distance = "social_distance",
  sports = "sports",
  sports_baseball = "sports_baseball",
  sports_basketball = "sports_basketball",
  sports_cricket = "sports_cricket",
  sports_esports = "sports_esports",
  sports_football = "sports_football",
  sports_golf = "sports_golf",
  sports_handball = "sports_handball",
  sports_hockey = "sports_hockey",
  sports_kabaddi = "sports_kabaddi",
  sports_mma = "sports_mma",
  sports_motorsports = "sports_motorsports",
  sports_rugby = "sports_rugby",
  sports_soccer = "sports_soccer",
  sports_tennis = "sports_tennis",
  sports_volleyball = "sports_volleyball",
  surfing = "surfing",
  switch_account = "switch_account",
  thumb_down_alt = "thumb_down_alt",
  thumb_up_alt = "thumb_up_alt",
  transgender = "transgender",
  travel_explore = "travel_explore",
  water_drop = "water_drop",
  waving_hand = "waving_hand",
  whatshot = "whatshot",
  check_box = "check_box",
  check_box_outline_blank = "check_box_outline_blank",
  indeterminate_check_box = "indeterminate_check_box",
  radio_button_checked = "radio_button_checked",
  radio_button_unchecked = "radio_button_unchecked",
  star = "star",
  star_border = "star_border",
  star_border_purple500 = "star_border_purple500",
  star_half = "star_half",
  star_outline = "star_outline",
  star_purple500 = "star_purple500",
  toggle_off = "toggle_off",
  toggle_on = "toggle_on",
}

export const CustomIconNames = [
  IconName.update_progress,
  IconName.maps,
  IconName.standardMap,
  IconName.alignedMap,
  IconName.growCards,
  IconName.shrinkCards,
];
