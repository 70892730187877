import { Dispatch, SetStateAction, useState } from "react";
import {
  objectiveStages,
  SORTING_VALUES,
  SortValue,
  Stage,
} from "common/objective/constants";

type UseObjectiveStageFiltersHook = {
  resultFilter: string;
  setResultFilter: Dispatch<SetStateAction<string>>;
  setSorting: (value: SortValue) => void;
  setStageFilter: Dispatch<SetStateAction<Stage>>;
  setTouched: Dispatch<SetStateAction<boolean>>;
  sorting: SortValue;
  stageFilter: Stage;
  touched: boolean;
};

export const useObjectiveStageFilters = ({
  resultFilterDefault = "",
} = {}): UseObjectiveStageFiltersHook => {
  const [sorting, setSorting] = useState<SortValue>(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SortValue | undefined' is not as... Remove this comment to see the full error message
    SORTING_VALUES.TIMEFRAME_ASC
  );
  const [stageFilter, setStageFilter] = useState<Stage>(objectiveStages.active);
  const [resultFilter, setResultFilter] = useState<string>(resultFilterDefault);
  const [touched, setTouched] = useState<boolean>(false);

  return {
    resultFilter,
    setResultFilter,
    setSorting,
    setStageFilter,
    setTouched,
    sorting,
    stageFilter,
    touched,
  };
};
