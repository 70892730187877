import { useGetObjectiveQuery } from "@graphql";
import { routes } from "route-configs";
import { ObjectiveType } from "common/context/objectiveContext";
import { Icon } from "common/icons";
import { KpiInfoTooltip } from "common/kpi/KpiInfoTooltip/KpiInfoTooltip";
import { Subtext } from "common/misc/Subtext/Subtext";
import { MapsObjectiveTooltip } from "common/objective/MapsObjective/MapsObjectiveTooltip/MapsObjectiveTooltip";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";

interface Props {
  objective: ObjectiveType;
}

export const ObjectiveAlignedToSubText = ({
  objective,
}: Props): JSX.Element | null => {
  const { objectiveHref, kpiHref } = useModalRouter();
  const { parent, kpi, strategicPillar } = objective;
  const alignedTo = parent || kpi || strategicPillar;
  const parentObjectiveId = parent?.id;
  const { data } = useGetObjectiveQuery({
    skip: !parent,
    variables: { objectiveId: parentObjectiveId! },
  });
  const alignedObjective = data?.objective;

  if (!alignedTo) {
    return null;
  }

  const alignments = {
    kpi: {
      link: kpiHref({ kpiId: alignedTo.id }).toString(),
      tooltip: kpi && <KpiInfoTooltip id={kpi.id} />,
    },
    objective: {
      link: objectiveHref({ oId: alignedTo.id }).toString(),
      tooltip: (
        <>
          {alignedObjective && (
            <MapsObjectiveTooltip
              className="m-1.5"
              {...alignedObjective}
              goalId={alignedObjective.id}
            />
          )}
        </>
      ),
    },
    strategicPillar: {
      link: routes.mapsv3.main,
      tooltip: (
        <div className="flex items-center space-x-1">
          <Icon name="account_balance" className="text-green-600" />
          <div>{strategicPillar?.name}</div>
        </div>
      ),
    },
  };

  const alignmentInfo =
    alignments[alignedTo.__typename as "objective" | "kpi" | "strategicPillar"];

  return (
    <WithTooltip tooltip={alignmentInfo.tooltip}>
      <Subtext
        key="account_tree"
        className="!max-w-[16rem] md:max-w-full"
        data-cy="objectiveAlignedTo"
        href={alignmentInfo.link}
        icon="account_tree"
        text={alignedTo.name}
      />
    </WithTooltip>
  );
};
