import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Avatar } from "common/avatar";
import { ListItem } from "common/misc/ListItem/ListItem";
import { colorTheme } from "constants/colorTheme";
import * as Typography from "constants/typography";

const Container = styled(ListItem)`
  color: ${colorTheme.slate[800]};
  background-color: ${(props) =>
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'unread' does not exist on type 'ThemedSt... Remove this comment to see the full error message
    props.unread ? colorTheme.slate[100] : colorTheme.white};
  width: 440px;
  padding: 12px 21px;
  border-bottom: 1px solid ${colorTheme.slate[200]};
  font-weight: initial;
  white-space: initial;

  &:hover {
    background-color: ${colorTheme.slate[300]};
    cursor: pointer;
  }
`;

const LeftCol = styled.div`
  width: 30px;
`;

const RightCol = styled.div`
  flex: 1;
  padding-left: 10px;
`;

const Timestamp = styled.div`
  font-size: ${Typography.fontSizeSmall}; /* used to be larger */
  color: ${colorTheme.slate[500]};
  margin-top: 2px;
`;

export class NotificationItem extends React.Component {
  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'unread' does not exist on type 'Readonly... Remove this comment to see the full error message
      unread,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'avatarUrl' does not exist on type 'Reado... Remove this comment to see the full error message
      avatarUrl,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'notification' does not exist on type 'Re... Remove this comment to see the full error message
      notification,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
      onClick,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'timeAgo' does not exist on type 'Readonl... Remove this comment to see the full error message
      timeAgo,
      ...other
    } = this.props;

    return (
      <Container
        data-cy="yheX13vTPBiBn2B18jb_T"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        unread={unread}
        {...other}
        onClick={onClick}
      >
        <LeftCol>
          <Avatar url={avatarUrl} />
        </LeftCol>
        <RightCol>
          <div>{notification}</div>
          <Timestamp>{timeAgo}</Timestamp>
        </RightCol>
      </Container>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
NotificationItem.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  notification: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  timeAgo: PropTypes.string.isRequired,
  unread: PropTypes.bool,
};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
NotificationItem.defaultProps = {
  onClick: null,
  unread: false,
};
