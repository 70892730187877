import { useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { PRIVATE_MODE_ON } from "constants/tracking";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";
import { track } from "utils/tracker";

type UsePrivateModeHook = [boolean, () => void, (value: boolean) => void];

const initialState: UsePrivateModeHook = [
  false,
  () => undefined,
  () => undefined,
];

const usePrivateModeHook = (): UsePrivateModeHook => {
  const [privateMode, setPrivateMode] = useLocalStorage("privateMode", false);
  const togglePrivateMode = () => setPrivateMode(!privateMode);

  useEffect(() => {
    if (privateMode) {
      track(PRIVATE_MODE_ON);
    }
  }, [privateMode]);

  return [privateMode, togglePrivateMode, setPrivateMode];
};

/**
 * singleton hook for private mode via local storage.
 * returns boolean state, toggle function and setter function.
 * @see {@link useToggle}
 */
export const usePrivateMode = singletonHook(initialState, usePrivateModeHook);
