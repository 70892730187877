import {
  ClosingNoteStatusFragment,
  Maybe,
  ObjectiveProgressDriverFragment,
  ObjectiveStatusFragment,
} from "@graphql";
import React from "react";
import { Status, StatusProps } from "common/charts/Status/Status";
import { useObjectiveStatusTooltip } from "common/objective/ObjectiveStatus/ObjectiveStatus.utils";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { isSet } from "utils/isSet";

export type ObjectiveStatusObjective = ObjectiveProgressDriverFragment &
  ObjectiveStatusFragment & { closingNote?: Maybe<ClosingNoteStatusFragment> };

type Props = Omit<StatusProps, "status"> & {
  objective: ObjectiveStatusObjective;
  showTooltip?: boolean;
};

/**
 * shows objective status with a dynamic tooltip based on progress driver and status type.
 */
export const ObjectiveStatus = ({
  className,
  objective,
  onlyIcon,
  showTooltip,
}: Props): JSX.Element => {
  const tooltip = useObjectiveStatusTooltip(objective);

  const { closingNote, status: objectiveStatus } = objective;

  const objectiveClosed = isSet(closingNote);
  const status = objectiveClosed ? closingNote.status : objectiveStatus;

  return (
    <WithTooltip
      className="inline-flex"
      tooltip={showTooltip ? tooltip : undefined}
    >
      <Status className={className} onlyIcon={onlyIcon} status={status} />
    </WithTooltip>
  );
};
