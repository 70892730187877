import {
  KpiGroupsFragment,
  KpiLeadFragment,
  ObjectiveGroupsFragment,
  ObjectiveLeadFragment,
  ObjectiveTimeframeFragment,
  StrategicPillarDefaultFragment,
  useGetAlignedToOptionsQuery,
} from "@graphql";
import { compact, isEmpty, isNil } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { StackedAvatar } from "common/avatar";
import { EntityIcon, Icon } from "common/icons";
import { ComboIcon } from "common/inputs/ComboIcon/ComboIcon";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { messages } from "common/select/AlignedToSelect/AlignedToSelect.messages";
import { objectTypesIntl } from "constants/objects";
import { useCompany } from "hooks/useCompany/useCompany";
import { Combo, ComboProps } from "legacy/components/Combo/Combo";
import { COMBO_EMPTY_ID } from "legacy/components/Combo/Combo.constants";
import { ComboItem } from "legacy/components/Combo/Combo.types";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { sortExcludeSpecialCharacters } from "utils/stringManipulation";
import { twClass } from "utils/twClass";

type AlignedToObjective = ComboItem &
  ObjectiveGroupsFragment &
  ObjectiveLeadFragment &
  ObjectiveTimeframeFragment;

type AlignedToKpi = ComboItem & KpiGroupsFragment & KpiLeadFragment;
type AlignedToPillar = Omit<StrategicPillarDefaultFragment, "description">;

export type AlignedToItem = AlignedToObjective | AlignedToKpi | AlignedToPillar;

const isKpi = (item: AlignedToItem): item is AlignedToObjective => {
  return item.__typename === "kpi";
};
const isObjective = (item: AlignedToItem): item is AlignedToObjective => {
  return item.__typename === "objective";
};
const isGoal = (
  item: AlignedToItem
): item is AlignedToObjective | AlignedToKpi =>
  isKpi(item) || isObjective(item);

type Props = Pick<ComboProps, "inputClass" | "menuClass"> & {
  excludedIds?: string[];
  excludeKpis?: boolean;
  excludeObjectives?: boolean;
  excludeStrategicPillar?: boolean;
  onSelect?: (item: AlignedToItem) => void;
  renderToggle?: () => React.ReactNode;
};

/**
 * select item to align an objective to. objectives can be aligned to other objectives, strategic pillars, or KPIs.
 */
export const AlignedToSelect = ({
  excludedIds = [],
  inputClass = "w-[28rem]",
  menuClass = "max-w-[28rem] py-1.5", // = max-w-md,
  onSelect,
  renderToggle,
  excludeKpis,
  excludeObjectives,
  excludeStrategicPillar,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const company = useCompany();
  const { data } = useGetAlignedToOptionsQuery();

  if (isNil(data)) {
    return <SpinnerFit className="relative h-10 w-24" />;
  }

  const objectives = excludeObjectives ? [] : mapEdges(data.objectives.edges);
  const strategicPillars = excludeStrategicPillar
    ? []
    : mapEdges(data.allStrategicPillars.edges);
  const kpis = excludeKpis ? [] : mapEdges(data.allKpis.edges);

  const options = [...objectives, ...strategicPillars, ...kpis]
    .filter(({ id }) => !excludedIds.includes(id))
    .sort((a, b) => sortExcludeSpecialCharacters(a.name, b.name));

  const renderItem = (item: AlignedToItem) => {
    if (item.id === COMBO_EMPTY_ID) {
      return (
        <span className="flex w-full justify-center">
          {intl.formatMessage(messages.empty)}
        </span>
      );
    }

    const groupAvatars = isGoal(item)
      ? mapEdges(item.groups.edges).map(({ avatar }) => avatar)
      : [];
    const avatars = compact(groupAvatars);

    if (isGoal(item) && isEmpty(avatars) && item.isCompanyGoal) {
      avatars.push(company.avatar);
    }
    const showAvatar = !isEmpty(avatars);

    const avatarIconType =
      item.__typename === "strategicPillar" ? "strategy" : item.__typename;

    return (
      <>
        {showAvatar ? (
          <StackedAvatar maxCount={2} size="medium" urls={avatars} />
        ) : (
          <div className="flex h-8 w-8 items-center justify-center">
            <EntityIcon className="text-slate-800" entity={avatarIconType} />
          </div>
        )}
        <div className="flex grow flex-col truncate">
          <span className="truncate text-left">{item.name}</span>
          <span className="flex items-center gap-4 text-sm text-slate-500">
            <span className="flex items-center gap-1">
              <EntityIcon
                className="text-slate-500"
                entity={item.__typename}
                size="md"
              />
              {objectTypesIntl(intl)[item.__typename]}
            </span>
            {isGoal(item) && isSet(item.lead) && (
              <span className="flex gap-1">
                <Icon name="person" size="xl" />
                {item.lead.fullName}
              </span>
            )}
            {isObjective(item) && isSet(item.timeframe) && (
              <span className="flex gap-1">
                <Icon name="calendar_today" size="xl" />
                {item.timeframe.name}
              </span>
            )}
          </span>
        </div>
      </>
    );
  };

  return (
    <Combo
      defaultItem={null}
      icon={<ComboIcon name="search" />}
      inputClass={inputClass}
      insideComponent={renderToggle}
      items={options}
      keepOpen
      limitItems={10}
      menuClass={menuClass}
      onChange={(selected) => onSelect?.(selected)}
    >
      {({ disabled, item }) => (
        <button
          className={twClass(
            "flex items-center gap-2.5",
            "w-full overflow-hidden px-2.5 py-1.5",
            "bg-white hover:bg-slate-300"
          )}
          disabled={disabled}
          type="button"
        >
          {renderItem(item)}
        </button>
      )}
    </Combo>
  );
};
