import { useUseSampleOneOnOneMeetingItemTemplateMutation } from "@graphql";
import { useIntl } from "react-intl";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

type ReturnFunc = (
  sampleTalkingPoints: string[],
  meetingId?: string
) => Promise<{ hasError: boolean }>;

export const useSampleMeetingItemsTemplate = (): ReturnFunc => {
  const { $t } = useIntl();

  const [callUseSampleTemplate] =
    useUseSampleOneOnOneMeetingItemTemplateMutation();

  return async (sampleTalkingPoints: string[], meetingId?: string) => {
    const response = await callUseSampleTemplate({
      variables: {
        input: {
          meetingId,
          sampleTalkingPoints,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.useSampleOneOnOneMeetingItemTemplate?.errors
    );
    if (hasError) {
      toast.failure(
        $t({ defaultMessage: "Error using sample template.", id: "UqFg/9" })
      );
      return { hasError };
    }
    toast.success(
      $t({ defaultMessage: "Sample template used.", id: "50XUkr" })
    );
    return { hasError };
  };
};
