import React from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { useShowSalesModals } from "common/company/hooks/useShowSalesModals";
import { Icon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { useCompany } from "hooks/useCompany/useCompany";
import { useValidateUserLimit } from "hooks/useValidateUserLimit/useValidateUserLimit";
import { BillingPeriodSelector } from "../BillingPeriodSelector";
import { CurrencySelector } from "../CurrencySelector";
import {
  BillingPeriod,
  PaidPlans,
  SupportedCurrencies,
} from "../UpgradeModal.types";
import { PricingColumn } from "./PricingColumn";

interface Props {
  billingPeriod: BillingPeriod;
  currency: SupportedCurrencies;
  onChatToSales: () => void;
  onChoosePlan: (plan: PaidPlans) => void;
  setBillingPeriod: (val: BillingPeriod) => void;
  setCurrency: (val: SupportedCurrencies) => void;
}

export const Pricing: React.FC<Props> = ({
  onChoosePlan,
  currency,
  setCurrency,
  onChatToSales,
  billingPeriod,
  setBillingPeriod,
}) => {
  const company = useCompany();
  const { showSalesModals } = useShowSalesModals();
  const { freeUserLimit } = useValidateUserLimit();
  return (
    <div className="modal-scrollbar modal-scrollbar-thumb h-full space-y-16 overflow-y-auto p-10">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="m-0 text-2xl text-slate-800">
            <FormattedMessage
              defaultMessage="Upgrade your plan"
              id="upgrade:modal:label:plan:upgrade"
            />
          </h1>
          <div className="text-smd text-slate-600">
            <FormattedMessage
              defaultMessage="Prices are subject to volume discounts (see next step)"
              id="T2pQ+L"
            />
          </div>
        </div>
        <div className="ml-auto flex items-center space-x-6">
          <div>
            <BillingPeriodSelector
              onChange={setBillingPeriod}
              value={billingPeriod}
            />
          </div>
          <div>
            <CurrencySelector onChange={setCurrency} value={currency} />
          </div>
        </div>
      </div>
      <div className="bg-slate-50 space-y-4">
        <div className="bg-white border rounded mt-6 divide-y">
          <div className="flex justify-stretch divide-x">
            <PricingColumn
              title={<FormattedMessage defaultMessage="Free" id="tf1lIh" />}
              currency={currency}
              plan="free"
              subtitle={
                <FormattedMessage
                  defaultMessage="Up to {count} users"
                  id="R7Jf5/"
                  values={{ count: freeUserLimit }}
                />
              }
              billingPeriod={billingPeriod}
              featuresHeading={
                <FormattedMessage
                  defaultMessage="Free plan includes:"
                  id="SCviwM"
                />
              }
              className="flex-1"
              onChoosePlan={onChoosePlan}
              data-cy="freeColumn"
            />

            <div className="relative flex-1">
              <div className="absolute h-6 -top-6 outline-1 bg-yellow-400 text-yellow-900 w-full rounded-t text-center text-xs font-semibold uppercase leading-6">
                <FormattedMessage
                  defaultMessage="Most popular"
                  id="upgrade:modal:most:popular"
                />
              </div>
              <PricingColumn
                title={
                  <FormattedMessage
                    defaultMessage="Premium"
                    id="upgrade:modal:premium"
                  />
                }
                currency={currency}
                plan="premium"
                subtitle={
                  <FormattedMessage
                    defaultMessage="Minimum {limit} users"
                    id="LeTcvv"
                    values={{
                      limit: company.mpq,
                    }}
                  />
                }
                billingPeriod={billingPeriod}
                featuresHeading={
                  <FormattedMessage
                    defaultMessage="Everything in Free, plus..."
                    id="MyfHEo"
                  />
                }
                onChoosePlan={onChoosePlan}
                data-cy="premiumColumn"
              />
            </div>

            <PricingColumn
              title={
                <FormattedMessage
                  defaultMessage="Supreme"
                  id="upgrade:modal:supreme"
                />
              }
              currency={currency}
              className="flex-1"
              plan="supreme"
              subtitle={
                <FormattedMessage
                  defaultMessage="Minimum {limit} users"
                  id="LeTcvv"
                  values={{
                    limit: company.mpq,
                  }}
                />
              }
              billingPeriod={billingPeriod}
              featuresHeading={
                <FormattedMessage
                  defaultMessage="Everything in Premium, plus..."
                  id="lYDbTC"
                />
              }
              onChoosePlan={onChoosePlan}
              data-cy="supremeColumn"
            />
          </div>
          <div className="p-4 text-center">
            <AnchorNext
              className="font-medium"
              href="https://www.perdoo.com/pricing/"
              target="_blank"
              external
            >
              <FormattedMessage
                defaultMessage="See full plan comparison"
                id="o7twZe"
              />
            </AnchorNext>
          </div>
        </div>

        {showSalesModals && (
          <div className="text-center font-medium text-slate-800">
            <FormattedMessage
              defaultMessage="Special pricing available for volume purchases. Interested?"
              id="xJgqbn"
            />{" "}
            <TextButton
              className="inline text-blue-500 space-x-1"
              onClick={onChatToSales}
              size="small"
            >
              <div className="flex items-center space-x-1">
                <div>
                  <FormattedMessage
                    defaultMessage="Chat to sales"
                    id="tmaZgh"
                  />
                </div>
                <Icon name="chat" type="outlined" />
              </div>
            </TextButton>
          </div>
        )}
      </div>
    </div>
  );
};
