import {
  namedOperations,
  useActivateNewSubscriptionMutation,
  useCheckoutNewSubscriptionMutation,
  useGetCompanyForHookQuery,
  useUserCompanyInfoQuery,
} from "@graphql";
import { FormikHelpers } from "formik";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { WidgetLoading } from "common/index";
import { BaseModal } from "common/overlay/BaseModal/BaseModal";
import {
  CHAT_TO_SALES,
  CHECKOUT_COMPLETE,
  PREMIUM_CALL_SCHEDULED,
} from "constants/tracking";
import { useChargebee } from "hooks/useChargebee/useChargebee";
import { useCompany } from "hooks/useCompany/useCompany";
import { useSalesModals } from "hooks/useSalesModals/useSalesModals";
import { toast } from "utils/toastr";
import { logToSentry, track } from "utils/tracker";
import { showGqlErrorToast } from "utils/utils";
import { ChatSales } from "./ChatSales/ChatSales";
import {
  PlanOptionsForm,
  PlanOptionValues,
} from "./PlanOptionsForm/PlanOptionsForm";
import { Pricing } from "./Pricing/Pricing";
import {
  BillingPeriod,
  PaidPlans,
  SupportedCurrencies,
} from "./UpgradeModal.types";
import { getChargebeeCoachingPlan, getChargebeePlanName } from "./utils";

const upgradePages = {
  chatSales: "chatSales",
  planOptions: "planOptions",
  pricing: "pricing",
};

export const UpgradeModal = (): JSX.Element | null => {
  const { refetch: refetchCompany } = useGetCompanyForHookQuery();
  const { close } = useSalesModals();
  const [progress, setProgress] = useState(upgradePages.pricing);
  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("ANNUAL");
  const [currency, setCurrency] = useState<SupportedCurrencies>("EUR");
  const [plan, setPlan] = useState<PaidPlans>("premium");

  const [activateNewSubscription] = useActivateNewSubscriptionMutation({
    refetchQueries: [namedOperations.Query.getCompany],
  });

  const [checkoutNewSubscription] = useCheckoutNewSubscriptionMutation();
  const { isOnFreeOrTrialPlan } = useCompany();

  const cbInstance = useChargebee();

  const { loading, data, error } = useUserCompanyInfoQuery();

  const onChoosePlan = (selected: PaidPlans): void => {
    setPlan(selected);
    setProgress(upgradePages.planOptions);
  };

  const alreadyUpgraded = !isOnFreeOrTrialPlan;
  if (alreadyUpgraded) {
    return null;
  }

  if (!data?.me || error) {
    return (
      <FormattedMessage
        defaultMessage="There was an error."
        id="premium:modal:error"
      />
    );
  }

  const onSubmit = (
    values: PlanOptionValues,
    actions: FormikHelpers<PlanOptionValues>
  ) => {
    const variables = {
      coachingPackage: getChargebeeCoachingPlan(
        values.coachingPackage,
        currency
      ),
      plan: getChargebeePlanName(currency, plan, billingPeriod),
      quantity: values.licenses,
      viewOnlyLicenses: values.viewOnlyLicenses,
    };
    const chargebeeInstance = cbInstance ?? window.Chargebee?.getInstance();
    chargebeeInstance?.openCheckout({
      close: () => {
        actions.setSubmitting(false);
      },
      hostedPage: () => {
        return checkoutNewSubscription({
          variables,
        })
          .then((response) => {
            if (response.errors) {
              showGqlErrorToast(response);
              throw new Error();
            }

            return JSON.parse(
              response.data?.checkoutNewSubscription?.hostedPage
            );
          })
          .catch((error: string) => {
            logToSentry(error);
            toast.failure("Error connecting to chargebee");
            chargebeeInstance.closeAll();
            actions.setSubmitting(false);
            return null;
          });
      },
      success: (hostedPageId: string) => {
        activateNewSubscription({
          variables: {
            hostedPageId,
          },
        })
          .then((response) => {
            if (response.errors) {
              showGqlErrorToast(response);
              throw new Error();
            } else {
              refetchCompany();
              track(CHECKOUT_COMPLETE, {
                planPurchased: plan,
              });
              close();
            }
          })
          .catch((error) => {
            logToSentry(error);
            toast.failure("Error activating subscription");
          })
          .finally(() => {
            chargebeeInstance.closeAll();
            actions.setSubmitting(false);
          });
      },
    });
  };

  const onChatToSales = () => {
    track(CHAT_TO_SALES);
    setProgress(upgradePages.chatSales);
  };

  const upgradeFlowBody = {
    [upgradePages.pricing]: (
      <Pricing
        billingPeriod={billingPeriod}
        currency={currency}
        onChatToSales={onChatToSales}
        onChoosePlan={onChoosePlan}
        setBillingPeriod={setBillingPeriod}
        setCurrency={setCurrency}
      />
    ),
    [upgradePages.planOptions]: (
      <PlanOptionsForm
        billingPeriod={billingPeriod}
        currency={currency}
        onBack={() => setProgress(upgradePages.pricing)}
        onChatToSales={onChatToSales}
        onSubmit={onSubmit}
        plan={plan}
        setBillingPeriod={setBillingPeriod}
        setCurrency={setCurrency}
      />
    ),
    [upgradePages.chatSales]: (
      <div className="h-full space-y-10 overflow-y-auto p-10">
        <div className="full-width flex items-center justify-between">
          <div className="flex content-center items-center">
            <IconButton
              data-cy="gj6SoBUeiltAEc7e3J1ol"
              className="mr-4"
              name="chevron_left"
              onClick={() => setProgress(upgradePages.pricing)}
              size="3xl"
            />
            <h1 className="m-0 text-2xl text-slate-800">
              <FormattedMessage
                defaultMessage="Chat to sales"
                id="upgrade:modal:label:chat:sales"
              />
            </h1>
          </div>
        </div>
        <ChatSales
          className="p-0 bg-slate-50"
          email={data.me.email}
          onEventScheduled={() => track(PREMIUM_CALL_SCHEDULED)}
          userFullName={data.me.fullName}
        />
      </div>
    ),
  };

  const body = upgradeFlowBody[progress];

  return (
    <BaseModal className="bg-slate-50" isOpen onClose={close} size="lg">
      {loading ? <WidgetLoading /> : body}
    </BaseModal>
  );
};
