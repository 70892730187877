import { MessageDescriptor } from "@formatjs/intl/src/types";
import { useGetCompanyFeaturesQuery } from "@graphql";
import { ReactElement } from "react";
import { GROUPS_TYPE_CHOICES } from "common/group/GroupSelector/GroupSelector.constants";
import { useReportQueryParams } from "common/reports/utils/useReportQueryParams";

type Element = {
  className?: string;
  /** if true, will be filtered out when showing group data. */
  companyOnly?: boolean;
  component: ReactElement;
  /** if true, metric is part of health score = shows a heart icon in top-right */
  healthScore?: boolean;
  id: string;
  /** number of columns the element should take up in the grid. default = 1. */
  size?: number;
  /** use to customize on which timeframes the widget will be displayed. by default it shows on all. */
  timeframe?: ("past" | "active" | "future")[];
};

/**
 * each item in the layout array represents a section.
 * each element in a section represents a widget.
 */
export type ReportLayout = {
  elements: Element[];
  id: string;
  title?: MessageDescriptor;
}[];

/**
 * parses a report layout and returns a filtered layout based on available features and selected filters.
 */
export const useReportLayout = (widgets: ReportLayout): ReportLayout => {
  const { groupsType, timeframe } = useReportQueryParams();
  const { data: featuresData } = useGetCompanyFeaturesQuery();

  let layout = widgets;

  const isPulseEnabled = featuresData?.currentCompany.pulse;
  if (!isPulseEnabled) {
    layout = layout.filter(({ id }) => id !== "pulse");
  }

  if (groupsType === GROUPS_TYPE_CHOICES.GROUP) {
    layout = layout.map((section) => ({
      ...section,
      elements: section.elements.filter((element) => !element.companyOnly),
    }));
  }

  // TODO FIXME type should be fixed in the hook
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!timeframe) return layout;

  if (timeframe.isPast) {
    layout = layout.map((section) => ({
      ...section,
      elements: section.elements.filter(
        (element) => element.timeframe?.includes("past") ?? true
      ),
    }));
  } else if (timeframe.isActive) {
    layout = layout.map((section) => ({
      ...section,
      elements: section.elements.filter(
        (element) => element.timeframe?.includes("active") ?? true
      ),
    }));
  } else {
    layout = layout.map((section) => ({
      ...section,
      elements: section.elements.filter(
        (element) => element.timeframe?.includes("future") ?? true
      ),
    }));
  }

  return layout;
};
