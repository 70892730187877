import { OneOnOneRepeatFrequency } from "@graphql";
import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { UserField } from "common/form/UserField";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import {
  CalendarIntegrationFormSection,
  CalendarIntegrationValues,
} from "./CalendarIntegrationFormSection/CalendarIntegrationFormSection";
import { messages } from "./oneOnOneMeetingForm.messages";
import { RecurrenceFormSection } from "./RecurrenceFormSection/RecurrenceFormSection";

export type AddOneOnOneMeetingValues = {
  attendee: string | null;
  description: string | null;
  dueDate: string | null;
  isActive: boolean;
  meetingTime: string | null;
  repeatFrequency: OneOnOneRepeatFrequency | null;
} & CalendarIntegrationValues;

type AddOneOnOneMeetingProps = {
  onCancel: () => void;
};

export const AddOneOnOneMeetingForm = ({
  onCancel,
}: AddOneOnOneMeetingProps): JSX.Element => {
  const intl = useIntl();
  const { isSubmitting, submitForm, values } =
    useFormikContext<AddOneOnOneMeetingValues>();

  return (
    <>
      <UserField
        data-cy="fieldAttendee"
        data-testid="attendee-selector"
        label={intl.formatMessage(messages.attendee)}
        name="attendee"
        options={{
          excludeViewOnly: true,
        }}
      />
      <InputField
        label={intl.formatMessage(messages.description)}
        name="description"
        placeholder={intl.formatMessage({
          defaultMessage: "What are these meetings are about?",
          id: "J0GvDw",
        })}
      />
      <CalendarIntegrationFormSection<AddOneOnOneMeetingValues> />
      <Show when={!values.googleCalendarEventAction}>
        <RecurrenceFormSection<AddOneOnOneMeetingValues> />
      </Show>
      <ModalFooter>
        <Button
          data-cy="saveOneOnOneMeeting"
          data-testid="submitForm"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={submitForm}
          type="submit"
        >
          <FormattedMessage defaultMessage="Save" id="global:save" />
        </Button>
        <Button
          data-cy="rrOifvEQWWvaY7z0qiz2Z"
          onClick={onCancel}
          type="button"
          variant="ghost"
        >
          <FormattedMessage defaultMessage="Cancel" id="global:cancel" />
        </Button>
      </ModalFooter>
    </>
  );
};
