import { KpiConnection } from "@graphql";
import { mockPageInfo } from "mocks/common/pageInfo";
import { unmapEdges } from "utils/mapEdges";

export const aKpiConnection = (
  overrides?: Partial<KpiConnection> & {
    nodes?: KpiConnection["edges"][number]["node"][];
  }
): KpiConnection => {
  const edgeCount = overrides?.edges?.length ?? overrides?.nodes?.length ?? 0;
  return {
    __typename: "kpiConnection",
    commitCount: overrides?.commitCount ?? 0,
    edgeCount: overrides?.edgeCount ?? edgeCount,
    edges:
      overrides?.edges ??
      unmapEdges(overrides?.nodes ?? [], "kpiEdge", "kpiCursor"),
    healthyCount: overrides?.healthyCount ?? 0,
    healthyCountAtDatetime: overrides?.healthyCountAtDatetime ?? 0,
    pageInfo: overrides?.pageInfo ?? mockPageInfo,
    totalCount: overrides?.totalCount ?? edgeCount,
  };
};
