import { FormikProps, useField } from "formik";
import { omit } from "lodash";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Show } from "common/controlFlow";
import { AllowedFieldProps } from "common/form/types";
import { ResultFormValues } from "common/result/ResultForm/ResultForm.types";
import { resultTypes } from "constants/resultTypes";
import { messages } from "./ResultTypeField.messages";
import { ResultTypeGuideToggle } from "./ResultTypeGuideToggle/ResultTypeGuideToggle";
import {
  InitiativeRadioSelect,
  KeyResultRadioSelect,
} from "./ResultTypeRadioSelect/ResultTypeRadioSelect";

type Props = AllowedFieldProps & {
  // TODO: refactor state logic to parent
  form: FormikProps<ResultFormValues>;
};

export const ResultTypeField = ({
  form,
  name,
  ...fieldProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, submitCount, values } = form;
  const props = omit(fieldProps, ["onChange", "disabled"]);

  const [tempParent, setTempParent] = useState<string | undefined>(undefined);

  const handleTypeChange = (value: string) => {
    if (value === resultTypes.keyResult) {
      setTempParent(values.parent);
      setFieldValue("parent", null);
    } else {
      setFieldValue("parent", tempParent);
      setTempParent(undefined);
    }
    helpers.setValue(value);
  };

  const errorText = submitCount > 0 && meta.error;

  return (
    <div className="my-4 space-y-2">
      <div>
        <div className="flex items-center justify-between space-x-2">
          <div className="text-sm">
            {intl.formatMessage(messages.resultTypeLabel)}
          </div>
          <ResultTypeGuideToggle />
        </div>
      </div>
      <input hidden {...field} {...props} data-cy="result-type-input" />
      <Show when={errorText}>
        <div className="text-sm text-red-500">{errorText}</div>
      </Show>
      <KeyResultRadioSelect onClick={handleTypeChange} selected={field.value} />
      <InitiativeRadioSelect
        onClick={handleTypeChange}
        selected={field.value}
      />
    </div>
  );
};
