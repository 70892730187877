import React from "react";
import { FormattedMessage } from "react-intl";
import { MetricUnits } from "constants/metric";
import { resultTypes } from "constants/resultTypes";
import { actionWords } from "../ResultForm.messages";
import { ResultFormValues } from "../ResultForm.types";

const warningTypes = {
  actionWords: {
    element: (
      <FormattedMessage
        defaultMessage="The words in your Result’s name are typically used for Initiatives. Are you sure you want to track this as a Key Result? "
        id="result:form:actionWord:warning"
      />
    ),
    id: 1,
    link: "https://www.perdoo.com/resources/how-to-use-okrs-to-measure-what-matters/",
  },
  percentage: {
    element: (
      <FormattedMessage
        defaultMessage="Results with start value 0% and target value 100% are typically projects and tasks. Since these are a means to an end, they’re better tracked as Initiatives."
        id="result:form:percentage:warning"
      />
    ),
    id: 0,
    link: "https://www.perdoo.com/resources/how-to-use-okrs-to-measure-what-matters/",
  },
};

const containsActionWord = (name: string, language: string) => {
  const actionWordList: string[] = actionWords[language] ?? [];
  return actionWordList.some((word) =>
    name.toLowerCase().split(" ").includes(word)
  );
};

/**
 * checks if the result being added should actually be an initiative.
 *
 * @param values
 * @param language
 */
export const getResultFormWarnings = (
  values: ResultFormValues,
  language: string
) => {
  const warnings = [];
  const { name, type, startValue, endValue, metricUnit } = values;

  if (type === resultTypes.keyResult) {
    if (
      metricUnit === MetricUnits.PERCENTAGE &&
      startValue === 0 &&
      endValue === 100
    ) {
      warnings.push(warningTypes.percentage);
    }

    if (containsActionWord(name, language)) {
      warnings.push(warningTypes.actionWords);
    }
  }
  return warnings;
};
