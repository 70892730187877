import { Identifier, XYCoord } from "dnd-core";
import React, { ReactElement, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ConnectDragSource } from "react-dnd/src/types";

type DragAndDrop = {
  drag: ConnectDragSource;
  drop: React.RefObject<HTMLDivElement>;
  handlerId: Identifier | null;
  isDragging: boolean;
};

export interface DraggableItemProps {
  children: (props: DragAndDrop) => ReactElement;
  id: any;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDragEnd: (dragItem: DragItem) => void;
  /** if true, you can only drag using the handler. else, you can drag the entire element. */
  separateHandler?: boolean;
  type: string;
}

export type DragItem = {
  id: string;
  index: number;
  type: string;
};

/**
 * The DraggableItem is used to create sortable lists. The wrapped component is both a drag source and a drop target,
 * and reorder is handled the data in the hoverhandler.
 * Inspired by ReactDnD:
 * @see https://react-dnd.github.io/react-dnd/examples/sortable/simple
 */
export const DraggableItem = ({
  children,
  id,
  index,
  moveCard,
  onDragEnd,
  separateHandler,
  type,
}: DraggableItemProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      onDragEnd(item);
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => {
      return { id, index };
    },
    type,
  });

  const opacity = isDragging ? 0.5 : 1;
  if (separateHandler) {
    preview(drop(ref));
  } else {
    drag(drop(ref));
  }

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{
        opacity,
        position: "relative",
      }}
    >
      {children({ drag, drop: ref, handlerId, isDragging })}
    </div>
  );
};
