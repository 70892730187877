import { isNil } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { AnchorNext } from "common/navigation/AnchorNext/AnchorNext";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { roleNames } from "hooks/useRoleOptions/useRoleOptions";
import { supportLinks } from "modules/company/Company/Company.constants";
import { messages } from "./PermissionErrorMessage.messages";

interface Props {
  variant?: "default" | "addGroup" | "addLead";
}

export const PermissionErrorMessage = ({
  variant = "default",
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const user = useCurrentUser();
  const invalidRole = !user || !Object.keys(roleNames).includes(user.role);
  if (isNil(user) || isNil(user.role) || invalidRole) return null;

  return (
    <>
      {intl.formatMessage(messages[variant], {
        role: (
          <AnchorNext
            href={supportLinks.rolesRights.getStartedLink}
            target="_blank"
          >
            <span className="hover:text-blue-200">
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              {intl.formatMessage(roleNames[user.role])}
            </span>
          </AnchorNext>
        ),
      })}
    </>
  );
};
