import { gql } from "@apollo/client";
import { KpiTagsFragmentDoc } from "@graphql";

export const DELETE_KPI_MUTATION = gql`
  mutation DeleteKPI($input: DeleteKPIMutationInput!) {
    deleteKpi(input: $input) {
      kpi {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPSERT_KPI_MUTATION = gql`
  mutation addKpi($input: UpsertKPIMutationInput!) {
    upsertKpi(input: $input) {
      kpi {
        id
        name
        description
        isCompanyGoal
        metricUnit
        startValue
        currentValue
        goalThreshold
        goalOperator
        goalUpdateCycle
        createdDate
        archivedDate
        archived
        private
        visibleTo {
          edges {
            node {
              id
              fullName
              avatar
            }
          }
        }
        strategicPillar {
          id
          name
        }
        lead {
          id
          name
        }
        ...KpiTags
      }
      errors {
        field
        messages
      }
    }
  }
  ${KpiTagsFragmentDoc}
`;

export const ALL_KPIS_QUERY = gql`
  query getAllKpis {
    allKpis {
      edges {
        node {
          id
          name
          goalThreshold
        }
      }
    }
  }
`;

export const UPSERT_KPI_TARGET_MUTATION = gql`
  mutation upsertKpiTarget($input: UpsertKPITargetMutationInput!) {
    upsertKpiTarget(input: $input) {
      kpiTarget {
        id
        operator
        startDate
        threshold
        kpi {
          id
          targets {
            edges {
              node {
                ...TargetsKpiTarget
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DELETE_KPI_TARGET_MUTATION = gql`
  mutation deleteKpiTarget($input: DeleteKPITargetMutationInput!) {
    deleteKpiTarget(input: $input) {
      kpiTarget {
        id
        kpi {
          id
          targets {
            edges {
              node {
                ...TargetsKpiTarget
              }
            }
          }
        }
      }
    }
  }
`;
