import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ListItem } from "common/misc/ListItem/ListItem";
import { ReportAction } from "common/reports/utils/types";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";

type Props = {
  actions: ReportAction[];
  button?: React.ReactElement;
};

/**
 * kebab menu shown in report cards.
 */
export const ReportCardMenu = ({
  actions,
  button: buttonProp,
}: Props): JSX.Element => {
  const button = buttonProp ?? (
    <IconButton data-cy="kpiKebabButton" name="more_vert" />
  );

  return (
    <Dropdown button={button} menuClass="min-w-0">
      {actions.map((action) => (
        <ListItem key={action.id}>
          <button
            data-cy="DACFNkEQLoKNGoqGhVLqC"
            className="w-full px-2 py-1.5"
            onClick={action.handler}
            type="button"
          >
            {action.title}
          </button>
        </ListItem>
      ))}
    </Dropdown>
  );
};
