import { useMutation } from "@apollo/client";
import { defineMessages, useIntl } from "react-intl";
import { UPSERT_OBJECTIVE_MUTATION } from "common/objective/queries";
import { handleNonFormErrors } from "utils/forms";
import { toast } from "utils/toastr";

const messages = defineMessages({
  errorMsg: {
    defaultMessage: "Error activating objective",
    id: "objective:error:toast",
  },
  successMsg: {
    defaultMessage: "Objective activated!",
    id: "objective:activated:toast",
  },
});

const refetchQueries = ["getProfileObjectives"];

type Props = {
  objective: {
    id: string;
  };
};

type Hook = {
  activateObjective: () => void;
};

export const useObjectiveActions = ({ objective }: Props): Hook => {
  const intl = useIntl();

  const [updateObjective] = useMutation(UPSERT_OBJECTIVE_MUTATION, {
    refetchQueries,
  });

  const activateObjective = () => {
    updateObjective({
      variables: {
        input: {
          id: objective.id,
          stage: "ACTIVE",
        },
      },
    })
      .then((response) => {
        const { errors } = response.data.upsertObjective;

        if (errors.length) {
          handleNonFormErrors(errors);
          return null;
        }

        return toast.success(intl.formatMessage(messages.successMsg));
      })
      .catch(() => {
        toast.failure(intl.formatMessage(messages.errorMsg));
      });
  };

  return {
    activateObjective,
  };
};
