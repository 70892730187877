import React from "react";
import {
  ENTITY_ICONS,
  EntityIconKey,
} from "common/icons/EntityIcon/EntityIcon.constants";
import { Icon } from "common/icons/Icon/Icon";
import { IconSize } from "common/misc/types";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";

interface Props {
  className?: string;
  entity: EntityIconKey;
  size?: keyof typeof IconSize;
  tooltip?: string | null;
}

export const EntityIcon = ({
  className,
  entity,
  tooltip,
  size = "2xl",
}: Props): JSX.Element | null => {
  const entityIcon = ENTITY_ICONS[entity];

  if (!entityIcon) {
    return null;
  }

  return (
    <WithTooltip tooltip={tooltip}>
      <Icon
        className={twClass(entityIcon.className, className)}
        name={entityIcon.name}
        size={size}
        type={entityIcon.type}
      />
    </WithTooltip>
  );
};
