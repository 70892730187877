import { PerdooApiKpiTargetOperatorChoices } from "@graphql";
import classNames from "clsx";
import { isNil } from "lodash";
import React, { ReactNode } from "react";
import { formatGoalOperator } from "common/kpi/utils";
import { RequestIndicator } from "common/placeholders/RequestIndicator/RequestIndicator";
import { colorTheme } from "constants/colorTheme";
import { Month } from "../TargetList.types";

type Props = {
  editing: boolean;
  isSelected: boolean;
  month: Month;
  newOperator: PerdooApiKpiTargetOperatorChoices;
  newTarget: string | null;
  updating: boolean;
};

/**
 * displays kpi target (explicit or implicit), or the currently edited one, or a loading spinner
 */
export const KpiTarget = ({
  editing,
  isSelected,
  month,
  newOperator,
  newTarget,
  updating,
}: Props): JSX.Element => {
  const renderTarget = (): ReactNode => {
    const operator = formatGoalOperator(
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      newOperator || PerdooApiKpiTargetOperatorChoices.GreaterThanOrEqual
    );

    if (updating) {
      return (
        <RequestIndicator
          className="grid place-items-center"
          color={colorTheme.slate[500]}
          containerSize="100%"
          size="15px"
          speed="0.6s"
          state="loading"
        />
      );
    }

    if (editing) {
      return `${operator} ${newTarget}`;
    }

    return month.formattedValue || month.value;
  };

  return (
    <div
      className={classNames("grid h-5 place-items-center", {
        "text-slate-500": editing && !isSelected,
        "text-slate-500 ": !month.isExplicit && !editing,
        "text-slate-800 group-hover:text-slate-800": isSelected,
      })}
    >
      {renderTarget()}
    </div>
  );
};
