import { isUndefined, uniqueId } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Icon } from "common/icons/Icon/Icon";

const QualityWrapper = styled.div`
  margin-bottom: 25px;
  i {
    display: block;
  }
`;

type Props = {
  name?: string;
  title: string;
};

export const ObjectiveNameValidator = ({
  name = "",
  title,
}: Props): JSX.Element => {
  const trimmed = name.trim();
  let charValid;
  let metricValid;

  if (trimmed.length > 0) {
    charValid = !!(trimmed.length > 0 && trimmed.length <= 70);
    const isWithNumber = /\d/.test(trimmed);
    metricValid = !isWithNumber;
  }

  const criteria = [
    {
      success: charValid,
      text: (
        <FormattedMessage
          defaultMessage="Is clear and concise"
          id="objective:wizard:objective:charLimit"
        />
      ),
    },
    {
      success: metricValid,
      text: (
        <FormattedMessage
          defaultMessage="Doesn't contain a metric"
          id="objective:wizard:objective:noMetric"
        />
      ),
    },
  ];

  return (
    <QualityWrapper>
      <p className="mb-2.5">
        <FormattedMessage
          defaultMessage="Be inspirational and provide a clear direction."
          id="objective:form:name"
        />
      </p>
      <p className="mb-2.5">{title}</p>
      {criteria.map((c) => (
        <div key={uniqueId()} className="mb-2 flex items-center">
          <div className="-mb-1.5 mr-1">
            {c.success && (
              <Icon className="text-green-500" name="check_circle" size="xl" />
            )}
            {c.success === false && (
              <Icon className="text-red-500" name="cancel" size="xl" />
            )}
            {isUndefined(c.success) && (
              <Icon
                className="text-slate-300"
                name="panorama_fish_eye"
                size="xl"
              />
            )}
          </div>

          <div>{c.text}</div>
        </div>
      ))}
      <p className="mt-2.5">
        <FormattedMessage
          defaultMessage="Need inspiration ? Check out {examples}"
          id="examples:learnAboutOkr"
          values={{
            examples: (
              <a
                href="https://support.perdoo.com/en/collections/2091196-learn-about-okr#okr-kpi-and-strategic-pillars-example-library"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="examples"
                  id="wizard:examples"
                />
              </a>
            ),
          }}
        />
      </p>
    </QualityWrapper>
  );
};
