import { Field, Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { routes } from "route-configs";
import * as Yup from "yup";
import { TextButton } from "common/buttons";
import { Button } from "common/buttons/Button/Button";
import { CheckboxField } from "common/form/CheckboxField/CheckboxField";
import { FieldCol } from "common/form/FieldWithHelper/FieldWithHelper";
import { FormInput } from "common/form/FormInput/FormInput";
import { FormSlackChannelSelect } from "common/form/FormSlackChannelSelect/FormSlackChannelSelect";
import { FormikSubmitHandler } from "common/form/types";
import { messages } from "common/group/GroupForm/GroupForm.messages";
import { Icon } from "common/icons/Icon/Icon";
import { ImageUploadInput } from "common/inputs/ImageUploadInput/ImageUploadInput";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { useHelpCenterContent } from "common/layout/HelpCenter/hooks/useHelpCenterContent";
import { SlackIntegrationInfoView } from "common/layout/HelpCenter/SlackIntegrationInfoView/SlackIntegrationInfoView";
import { Margin } from "common/misc/Margin/Margin.styles";
import { AnchorNextTrack } from "common/navigation";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { objectTypes } from "constants/objects";
import { useInTeams } from "hooks/useInTeams/useInTeams";

const schema = Yup.object().shape({
  kpisEnabled: Yup.boolean().required("Required"),
  name: Yup.string().required("Required"),
  slackChannel: Yup.string().nullable(),
  vision: Yup.string(),
});

export type CompanyFormValues = {
  avatar: string;
  id?: string;
  kpisEnabled: boolean;
  name: string;
  slackChannel?: string | null;
  vision?: string | null;
};

type Props = {
  initialValues: CompanyFormValues;
  onCancel: () => void;
  onSubmit: FormikSubmitHandler<CompanyFormValues>;
};

export const CompanyForm = ({
  initialValues,
  onCancel,
  onSubmit,
}: Props): JSX.Element => {
  const inTeams = useInTeams();
  const intl = useIntl();
  useHelpCenterContent({
    element: <SlackIntegrationInfoView />,
    type: "slack_integration",
  });

  const { toggleHelpCenter } = useHelpCenter();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={schema}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Row>
            <FieldCol lg={8} md={8} sm={8} xs={8}>
              <Field
                autoFocus
                component={FormInput}
                label={intl.formatMessage({
                  defaultMessage: "Name",
                  id: "settings:company:general:nameLabel",
                })}
                name="name"
                placeholder={intl.formatMessage({
                  defaultMessage: "Name",
                  id: "settings:company:general:namePlaceholder",
                })}
              />
              <Field
                component={FormInput}
                label={intl.formatMessage({
                  defaultMessage: "Slogan",
                  id: "settings:company:general:slogalLabel",
                })}
                name="vision"
                placeholder={intl.formatMessage({
                  defaultMessage: "Slogan",
                  id: "settings:company:general:sloganPlaceholder",
                })}
                type="text"
              />
              <CheckboxField
                label="Enable KPIs on Company page"
                name="kpisEnabled"
              />
              <Field
                component={FormSlackChannelSelect}
                label={intl.formatMessage(messages.slackChannelLabel)}
                name="slackChannel"
                optional
                placeholder={intl.formatMessage(
                  messages.slackChannelPlaceholder
                )}
              />
              <TextButton
                data-cy="rDYb-0pRmGxbMTidAJEAK"
                className="px-0"
                onClick={toggleHelpCenter}
                size="small"
                text={
                  <span className="flex items-center space-x-1 text-blue-500">
                    <span>
                      <FormattedMessage
                        defaultMessage="Can’t see your private channels?"
                        id="slack:privateChannelsHelp"
                      />
                    </span>
                    <Icon name="help_outline" size="xl" />
                  </span>
                }
              />
            </FieldCol>
            <Col lg={4} md={4} sm={4} style={{ position: "relative" }} xs={4}>
              <ImageUploadInput
                avatar={initialValues.avatar}
                entityId={initialValues.id}
                entityType={objectTypes.company}
              />
            </Col>
          </Row>
          <ModalFooter>
            <div className="flex w-full items-center justify-between">
              {!inTeams && (
                <AnchorNextTrack
                  data-cy="0BhWDio3zoQoVPrlYwNmj"
                  href={`${routes.settings.company.general.info}`}
                  onClick={onCancel}
                >
                  <div className="flex items-center justify-between">
                    <Icon className="mr-2 mt-1" name="launch" size="lg" />
                    <FormattedMessage
                      defaultMessage="More company settings"
                      id="company:form:more:settings"
                    />
                  </div>
                </AnchorNextTrack>
              )}
              <div className="flex items-center justify-between">
                <Button
                  data-cy="hlB4wKs-wBIwc-knkliDF"
                  disabled={isSubmitting}
                  onClick={onCancel}
                  variant="ghost"
                >
                  {intl.formatMessage({
                    defaultMessage: "Cancel",
                    id: "global:cancel",
                  })}
                </Button>
                <Margin left={10}>
                  <Button
                    data-cy="lxrCtcdQigOdUjW-Vq9vv"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={submitForm}
                    type="submit"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Save",
                      id: "global:save",
                    })}
                  </Button>
                </Margin>
              </div>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
