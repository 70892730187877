import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Modal } from "../Modal/Modal";
import { ModalFooter } from "../Modal/ModalFooter/ModalFooter";

interface Props {
  onRequestClosed: () => void;
  open: boolean;
}

export const ReloadModal = ({ open, onRequestClosed }: Props): JSX.Element => {
  const { $t } = useIntl();
  const router = useRouter();

  const onClick = () => {
    router.reload();
  };
  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title={$t({ defaultMessage: "New version available", id: "dAOq4D" })}
    >
      <div className="mt-3">
        <FormattedMessage
          defaultMessage="A new version of Perdoo is available. Please reload the app to get the
        latest changes."
          id="LJkc53"
        />
      </div>
      <ModalFooter>
        <Button data-cy="iqUoLXyqHC5ZB7gVq37Rx" onClick={onClick} type="submit">
          <FormattedMessage defaultMessage="Reload" id="fdCS5/" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
