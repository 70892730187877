import { FieldInputProps } from "formik";
import { camelCase, lowerCase } from "lodash";
import React from "react";
import { components } from "react-select";
import {
  AllMetrics,
  FrequentCurrencies,
  FrequentMetrics,
  OtherCurrencies,
} from "constants/metric";
import { Select } from "legacy/components/Select/Select";
import { OnChangeVariable } from "../FormCategorizedUsersSelect/FormCategorizedUsersSelect";

type Props = {
  [key: string]: any;
  "data-cy"?: string;
  field: FieldInputProps<any>;
  label: string;
  onChange?: (option: OnChangeVariable) => void;
};

export const FormMetricSelect = ({
  label,
  field,
  onChange,
  "data-cy": dataCy,
  ...other
}: Props): JSX.Element => {
  const options = Object.values(AllMetrics);

  const groupedOptions = [
    {
      options: [...Object.values(FrequentMetrics)],
    },
    {
      options: [...Object.values(FrequentCurrencies)],
    },
    {
      options: [...Object.values(OtherCurrencies)],
    },
  ];

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  const Group = (props) => (
    <div className="group-container border-b border-slate-300 last:border-b-0">
      <components.Group {...props} />
    </div>
  );

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const handleChange = (option) => {
    const handler = onChange || field.onChange;
    handler({
      target: {
        name: field.name,
        value: option.abbreviation,
      },
    });
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const getLabel = (option) => (
    <div className="flex">
      <div className="w-10">{option.symbol}</div> {option.name}
    </div>
  );

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  const filterOptions = ({ data }, query) => {
    const allSymbols = options.map(({ symbol }) => symbol);

    if (allSymbols.includes(query)) {
      return data.symbol.indexOf(query) > -1;
    }

    return (
      lowerCase(data.name).indexOf(lowerCase(query)) > -1 ||
      lowerCase(data.abbreviation).indexOf(lowerCase(query)) > -1
    );
  };

  return (
    <Select
      {...other}
      components={{ Group }}
      data-cy={dataCy || camelCase(label)}
      defaultValue={options[0]}
      filterOption={filterOptions}
      getOptionLabel={getLabel}
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'abbreviation' implicitly has an '... Remove this comment to see the full error message
      getOptionValue={({ abbreviation }) => abbreviation}
      id={field.name}
      label={label}
      name={field.name}
      onChange={handleChange}
      options={groupedOptions}
      value={options.find((option) => option.abbreviation === field.value)}
    />
  );
};
