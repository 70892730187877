import Head from "next/head";
import React, { useContext } from "react";
import { HelpCenter } from "common/layout/HelpCenter/HelpCenter";
import { NavBar } from "common/layout/NavBar/NavBar";
import { Sidebar } from "common/layout/Sidebar/Sidebar";
import { ErrorBoundary } from "common/misc/ErrorBoundary/ErrorBoundary";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { twClass } from "utils/twClass";
import { useHelpCenter } from "../HelpCenter/hooks/useHelpCenter";

type Props = {
  children: React.ReactNode;
  className?: string;
  // TODO: make this a variant like LayoutNoSidebar
  hideSidebar?: boolean;
  title?: string;
};

/**
 * generic layout wrapper.
 * if a page does not specify its own layout, _app renders `<Layout>{page}</Layout>`.
 *
 * @example
 * const HomeLayout = (page) => (
 *   <Layout>
 *     <LayoutHeader title="Home" />
 *     <div>some custom layout element</div>
 *
 *     {page}
 *   </Layout>
 * );
 * Home.getLayout = HomeLayout;
 */
export const Layout = ({
  children,
  className,
  hideSidebar,
  title = "Perdoo",
}: Props): JSX.Element | null => {
  const showNavigation = !hideSidebar;
  const { isCollapsed } = useContext(CollapsibleContext);
  const { isHelpCenterVisible, helpCenterContent } = useHelpCenter();
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <div className="flex h-screen w-full overflow-x-hidden print:overflow-visible">
        {showNavigation && (
          <div
            className={twClass(
              "z-higher h-full print:hidden",
              !isCollapsed && "flex-[0_0_250px]",
              isCollapsed && "flex-[0_0_80px]"
            )}
          >
            <Sidebar />
          </div>
        )}

        <div
          className="h-full grow overflow-x-auto print:h-auto print:overflow-visible"
          id="main-view-container"
        >
          <div
            className={twClass(
              "h-full flex-auto print:h-auto",
              "relative overflow-y-auto print:overflow-y-visible",
              "bg-slate-50",
              "flex flex-col",
              className
            )}
            id="main-view"
          >
            {showNavigation && <NavBar />}
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </div>
        {showNavigation && isHelpCenterVisible && (
          <HelpCenter>{helpCenterContent.element}</HelpCenter>
        )}
      </div>
    </>
  );
};
