import Tippy from "@tippyjs/react";
import React from "react";
import { Placement } from "tippy.js";
import { twClass } from "utils/twClass";

export type TooltipTheme = "perdoo-light" | "perdoo-white" | "perdoo-blue";

const ARROW_DISTANCE_DEFAULT = 5;

export type TooltipProps = React.ComponentProps<typeof Tippy> & {
  arrowDistance?: number;
  children: React.ReactNode;
  className?: string;
  /** what will be shown in the tooltip */
  content?: React.ReactNode;
  delay?: number;
  maxWidth?: string;
  placement?: Placement;
  ref?: React.Ref<Element>;
  spanClass?: string;
  theme?: TooltipTheme;
};

/**
 * @param {object} props
 * @param props.children the element that will display a tooltip on hover
 * @param props.content what will be shown in the tooltip
 */
export const Tooltip = ({
  arrowDistance,
  children,
  content,
  delay = 0,
  maxWidth = "350px", // tippy's default
  placement = "top",
  ref,
  spanClass,
  theme,
  ...other
}: TooltipProps): JSX.Element => {
  const arrowSize = theme === "perdoo-white" ? 16 : 8;
  const arrowOffset = arrowSize + (arrowDistance ?? ARROW_DISTANCE_DEFAULT);
  const offset: [number, number] = [0, arrowOffset];

  return (
    <Tippy
      {...other}
      ref={ref}
      // For some reason Tippy.js changes the default from appendTo=document.body
      // to appendTo="parent" when in interactive mode. This makes no sense.
      // The following reverts to the correct default in that case.
      appendTo={document.body}
      className="dont-break-out"
      content={content}
      delay={delay}
      maxWidth={maxWidth}
      offset={offset}
      placement={placement}
      theme={theme}
    >
      {/* TODO: span is needed by tippy, but this messes up styling in some cases. find solution. */}
      <span
        className={twClass("flex items-center", spanClass)}
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        tabIndex={0}
      >
        {children}
      </span>
    </Tippy>
  );
};
