import React from "react";
import { useIntl } from "react-intl";
import { useCompany } from "hooks/useCompany/useCompany";

type Props = {
  timeZone?: string;
  value: string;
};

export const FormattedTime = ({ value, timeZone }: Props): JSX.Element => {
  const intl = useIntl();
  const company = useCompany();
  return (
    <>
      {intl.formatTime(value, {
        timeZone: timeZone ?? company.timezone,
      })}
    </>
  );
};
