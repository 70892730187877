import {
  GetKudoReactionQueryVariables,
  useGetKudoReactionQuery,
} from "@graphql";
import { groupBy, isNil } from "lodash";
import { GroupedReactions } from "common/reaction/Reaction.types";
import { mapEdges } from "utils/mapEdges";

type Variables = GetKudoReactionQueryVariables;

export type KudoReactionsHook = {
  isLoading: boolean;
  kudoReactions: GroupedReactions;
};

export const useKudoReactions = ({ id }: Variables): KudoReactionsHook => {
  const { data } = useGetKudoReactionQuery({
    fetchPolicy: "cache-and-network",
    variables: { id },
  });

  const isLoading = isNil(data);
  if (isLoading) {
    return {
      isLoading: true,
      kudoReactions: {},
    };
  }

  const kudoReactions = groupBy(mapEdges(data.kudo?.reactions.edges), "name");

  return {
    isLoading,
    kudoReactions,
  };
};
