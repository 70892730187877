import {
  TimeframeDefaultFragment,
  TimeframeGoalUpdateCycleFragment,
  TimeframeStartDateFragment,
} from "@graphql";
import Fuse from "fuse.js";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { For } from "common/controlFlow";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { SelectRoot } from "common/inputs/Select/SelectRoot/SelectRoot";
import { useSelect } from "common/inputs/Select/useSelect";

type SelectTimeframe = TimeframeDefaultFragment &
  TimeframeStartDateFragment &
  TimeframeGoalUpdateCycleFragment;

type SelectRootProps = React.ComponentProps<typeof SelectRoot>;

type Props = Omit<SelectRootProps, "children" | "onChange" | "value"> & {
  defaultItem?: SelectTimeframe;
  onChange?: (u: SelectTimeframe | null) => void;
  timeframes: SelectTimeframe[];
};

export type TimeframeSelectProps = Props;

export const TimeframeSelect = ({
  defaultItem,
  onChange,
  timeframes,
  ...selectRootProps
}: Props): JSX.Element => {
  const { selected, handleSelect, handleSearch, Select, visibleOptions } =
    useSelect({
      defaultItem,
      mapFn: (tfs) => tfs,
      options: timeframes,
      searchFn: (searchOptions, query) => {
        if (!query) return searchOptions;
        return new Fuse(searchOptions, {
          findAllMatches: true,
          isCaseSensitive: false,
          keys: ["name"],
          threshold: 0.2,
        })
          .search(query)
          .map((r) => r.item);
      },
    });

  const handleChange = async (newTimeframe: SelectTimeframe | null) => {
    handleSelect(newTimeframe);
    onChange?.(newTimeframe);
  };

  return (
    <Select.Root {...selectRootProps} onChange={handleChange} value={selected}>
      <Select.Trigger>
        <Select.Value>
          {selected ? (
            <Entity name={selected.name} />
          ) : (
            <FormattedMessage defaultMessage="Select..." id="724CrE" />
          )}
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown className="divide-y divide-slate-300">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <For each={visibleOptions} fallback={<SelectFallback />}>
            {(option) => (
              <Select.Option key={option.id} value={option}>
                <Entity name={option.name} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
