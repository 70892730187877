import { useField } from "formik";
import { size } from "lodash";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextButton } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { ObjectiveTagsNames } from "common/objective/ObjectiveForm/ObjectiveTags/ObjectiveTagsNames/ObjectiveTagsNames";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { twClass } from "utils/twClass";

type Props = {
  children?: React.ReactNode;
  name: string;
};

export const ShowTagsWrapper = ({ name, children }: Props): JSX.Element => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [field] = useField(name);
  const [tagsDisplayed, setTagsDisplayed] = useState(false);
  useOutsideAlerter(ref, () => setTagsDisplayed(false));

  return (
    <div ref={ref} className="relative mt-2">
      <div className="flex items-center">
        <div className="mr-4 text-sm">
          {intl.formatMessage({ defaultMessage: "Tags", id: "1EYCdR" })}
        </div>
        <Show when={!tagsDisplayed && size(field.value) > 0}>
          <ObjectiveTagsNames ids={field.value} />
        </Show>

        <div
          className={twClass("flex h-8 items-center", {
            invisible: tagsDisplayed,
          })}
        >
          <Show
            fallback={
              <IconButton
                data-cy="FzBH6Dd8uZpuz1CSUZJUp"
                className="rounded-full border border-dashed p-1 text-blue-500"
                name="add"
                onClick={(evt) => {
                  evt.stopPropagation();
                  setTagsDisplayed(true);
                }}
                size="2xl"
              />
            }
            when={size(field.value) > 0}
          >
            <TextButton
              data-cy="PMH6cm6cMm9Zj6PGJ-g2C"
              onClick={() => {
                setTagsDisplayed(true);
              }}
              size="small"
              text={<FormattedMessage defaultMessage="Edit" id="wEQDC6" />}
            />
          </Show>
        </div>
      </div>
      <Show when={tagsDisplayed}>
        <div className="absolute -top-4 right-0 z-modal-prio w-11/12">
          {children}
        </div>
      </Show>
    </div>
  );
};
