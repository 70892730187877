import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addCadence: {
    defaultMessage: "Add cadence",
    id: "cadence:add",
  },
  cadenceNameLabel: {
    defaultMessage: "Name",
    id: "cadence:new:cadence:name:label",
  },
  cadencePlaceholder: {
    defaultMessage: "Enter Name",
    id: "cadence:form:name:placeholder",
  },
  cadenceResetEveryLabel: {
    defaultMessage: "Reset every",
    id: "cadence:new:cadence:reset_every:label",
  },
  cadenceStartDateLabel: {
    defaultMessage: "Start date",
    id: "cadence:new:cadence:start_date:label",
  },
  cadenceTimeframeFormulaLabel: {
    defaultMessage: "Timeframe formula",
    id: "cadence:new:cadence:timeframe_formula:label",
  },
  checkboxDescription: {
    defaultMessage: `Before unchecking this, please ensure you don't already have Timeframes
      that match this cadence to prevent duplicates. If unchecked, new Timeframes will be created.`,
    id: "cadence:new:cadence:checkbox:description",
  },
  checkboxLabel: {
    defaultMessage: "Use existing Timeframes for this cadence",
    id: "cadence:new:cadence:checkbox:label",
  },
  existingCadence: {
    defaultMessage: "{cadenceName} (existing cadence)",
    id: "cadence:existing:cadence",
  },
  goalUpdateCycleLabel: {
    defaultMessage: "Default update frequency",
    id: "cadence:form:goalUpdateCycle:label",
  },
  here: {
    defaultMessage: "here",
    id: "cadence:new:cadence:here",
  },
  name: {
    defaultMessage: "Name",
    id: "cadence:form:name:label",
  },
  nameLabel: {
    defaultMessage: "Type",
    id: "cadence:preset:label",
  },
  startDate: {
    defaultMessage: "Start Date",
    id: "cadence:form:startDate:label",
  },
  timeframesLabel: {
    defaultMessage: "Timeframes",
    id: "cadence:new:cadence:timeframes:label",
  },
  timeframeTableTitle: {
    defaultMessage:
      "The following Timeframe(s) and their Objectives will be changed",
    id: "cadence:form:timeframeTable:title",
  },
});
