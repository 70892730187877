import dayjs from "dayjs";
import { useField } from "formik";
import { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import { Show } from "common/controlFlow";
import { DragNDropItemTypes } from "common/dnd/ItemTypes";
import { EMPTY_TEXTAREA_VALUE } from "common/form/DescriptionForm/DescriptionForm";
import { Icon } from "common/icons";
import { Checkbox } from "common/inputs/Checkbox/Checkbox";
import { PlainTextArea } from "common/inputs/PlainTextArea/PlainTextArea";
import { ActionItemDueDate } from "common/oneOnOnes/ActionItemDueDate/ActionItemDueDate";
import { useCompleteOneOnOneContext } from "common/oneOnOnes/context/completeOneOnOneContext";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import {
  ACTION_ITEM_CHECKED,
  DUE_DATE_ADDED_TO_ACTION_ITEM,
  TALKING_POINT_TOGGLED_RECURRING,
} from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { isSet } from "utils/isSet";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { DatePickerDropdown } from "../../../../overlay/DatePickerDropdown/DatePickerDropdown";
import { OneOnOneMeetingItemActions } from "../OneOnOneMeetingItemActions/OneOnOneMeetingItemActions";
import { OneOnOneMeetingItem } from "../OneOnOneMeetingItemsField";
import { OneOnOneMeetingItemsUserSelect } from "../OneOnOneMeetingItemsUserSelect";

export const newEmptyMeetingItem = ({
  assignee,
  index,
  type,
}: Pick<
  OneOnOneMeetingItem,
  "assignee" | "index" | "type"
>): OneOnOneMeetingItem => ({
  assignee,
  dueDate: null,
  id: uuidv4(),
  index,
  isComplete: false,
  isRecurring: false,
  text: "",
  type,
});

type Props = {
  addNewItem: (previousItem: OneOnOneMeetingItem) => Promise<void>;
  focusedField: string | null;
  meetingItem: OneOnOneMeetingItem;
  name: keyof DragNDropItemTypes;
  saveChanges: (item: OneOnOneMeetingItem) => Promise<{ hasError: boolean }>;
  setFocusedField: (f: string | null) => void;
};

export const MeetingItem = ({
  addNewItem,
  focusedField,
  meetingItem,
  name,
  saveChanges,
  setFocusedField,
}: Props): JSX.Element => {
  const {
    deleteMeetingItem,
    organizer,
    attendee,
    savingStatus,
    startPolling,
    stopPolling,
  } = useCompleteOneOnOneContext();
  const me = useCurrentUser();
  const [field, , helpers] = useField<OneOnOneMeetingItem[]>(name);
  const intl = useIntl();
  const meetingItemRef = useRef<HTMLTextAreaElement>(null);

  // when we add a new item using Enter, we need to manually call the blur event.
  // then when we focus the new item, we need to prevent the real blur event, so it doesn't fire twice.
  const [preventBlur, setPreventBlur] = useState(false);

  useEffect(() => {
    if (focusedField && focusedField === meetingItem.id) {
      meetingItemRef.current?.focus();
    }
  }, [focusedField]);

  const isOrganizer = organizer.id === me?.id;

  const updateFormItem = async (itemToChange: OneOnOneMeetingItem) => {
    const newItems = field.value.map((item) =>
      item.id === itemToChange.id ? itemToChange : item
    );
    await helpers.setValue(newItems);
  };

  const handleDateChange = (meetingItemId: string, date: string | null) => {
    const modifiedItem = field.value.find(
      (existing) => existing.id === meetingItemId
    );

    if (!modifiedItem) {
      return;
    }

    const newItem = {
      ...modifiedItem,
      dueDate: date,
    };

    updateFormItem(newItem);
    saveChanges(newItem);

    toast.success(
      intl.formatMessage(
        date
          ? {
              defaultMessage: "Due date set",
              id: "GcBNdl",
            }
          : {
              defaultMessage: "Due date removed",
              id: "GcBNdk",
            }
      )
    );
  };

  const handleCheckboxChange = () => {
    const newItem = {
      ...meetingItem,
      isComplete: !meetingItem.isComplete,
    };

    if (newItem.isComplete) {
      track(ACTION_ITEM_CHECKED, {
        hasDueDate: isSet(newItem.dueDate),
        source: "1:1s modal",
      });
    }

    updateFormItem(newItem);
    saveChanges(newItem);
  };

  const handleTextChange = (value: string) => {
    const oldTextVal =
      meetingItem.text === EMPTY_TEXTAREA_VALUE ? "" : meetingItem.text;
    const val = value === EMPTY_TEXTAREA_VALUE ? "" : value;

    if (!oldTextVal && isNil(meetingItem.assignee)) {
      const assignee = isOrganizer ? organizer.id : attendee.id;
      updateFormItem({
        ...meetingItem,
        assignee,
        text: val as string,
      });
      return;
    }

    updateFormItem({
      ...meetingItem,
      text: val as string,
    });
  };

  const handleUserChange = (userId: string | null) => {
    const newItem = {
      ...meetingItem,
      assignee: userId,
    };

    updateFormItem(newItem);
    saveChanges(newItem);
  };

  const handleToggleIsRecurring = () => {
    const newItem = {
      ...meetingItem,
      isRecurring: !meetingItem.isRecurring,
    };
    if (newItem.isRecurring) {
      track(TALKING_POINT_TOGGLED_RECURRING, {
        source: "1:1s modal",
      });
    }
    updateFormItem(newItem);
    saveChanges(newItem);
  };

  const handleRemoveItem = async () => {
    const removedItemIndex = field.value.findIndex(
      (i) => i.id === meetingItem.id
    );
    const itemToFocusIndex = removedItemIndex - 1;
    const newItems = field.value.filter(
      (existing) => existing.id !== meetingItem.id
    );
    await helpers.setValue(newItems);
    await deleteMeetingItem(meetingItem.id);
    setFocusedField(newItems.at(itemToFocusIndex)?.id ?? null);
    startPolling();
  };

  const handleFocus = () => {
    stopPolling();
    setFocusedField(meetingItem.id);
  };

  const handleBlur = async () => {
    if (preventBlur) {
      setPreventBlur(false);
      return;
    }
    if (focusedField === meetingItem.id) setFocusedField(null);
    try {
      await saveChanges(meetingItem);
    } finally {
      startPolling();
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // enter saves the current item and creates a new item below.
      // we want the current item to be saved first, and not when it detects a blur.
      // so we prevent the blur event from triggering one time.
      setPreventBlur(true);

      await addNewItem(meetingItem);
    }

    if (e.key === "Backspace") {
      const meetingItem = field.value.find((item) => item.id === focusedField);
      if (meetingItem?.text === "") {
        handleRemoveItem();
      }
    }
  };

  return (
    <div
      className="group/close flex min-h-11 w-full grow items-start py-2"
      data-testid="meetingItemContainer"
    >
      <div className="mr-4 h-full">
        <OneOnOneMeetingItemsUserSelect
          data-cy="meetingItemAssignee"
          onChange={handleUserChange}
          value={meetingItem.assignee}
        />
      </div>
      <Checkbox
        checkboxClassName="hover:border-blue-500"
        checked={meetingItem.isComplete}
        className="mt-1"
        data-cy="meetingItemCheckbox"
        data-testid="meetingItemCheckbox"
        id={meetingItem.id}
        onChange={handleCheckboxChange}
      />
      <Show when={meetingItem.isRecurring}>
        <Tooltip
          className="text-xs"
          content={<FormattedMessage defaultMessage="Recurring" id="v84fNv" />}
          data-placement="top"
        >
          <Icon
            className={twClass("mt-1.5", {
              "text-slate-500": meetingItem.isComplete,
            })}
            data-cy="zmPMC7cuYk0tQkCzEdX-c"
            data-testid="recurring-icon"
            name="repeat"
            size="xl"
            type="outlined"
          />
        </Tooltip>
      </Show>
      <div className="mt-1 flex grow items-center">
        <PlainTextArea
          ref={meetingItemRef}
          autoFocus={meetingItem.id === focusedField}
          className={twClass(
            "mr-1 w-full self-start outline-none focus-visible:outline-none group-hover:bg-slate-50",
            "ml-0 border-none !py-0 pl-0.5",
            {
              "line-through text-slate-500": meetingItem.isComplete,
            }
          )}
          data-cy="meetingItemText"
          data-testid="meetingItem"
          minRows={1}
          onBlur={handleBlur}
          onChange={handleTextChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          value={meetingItem.text}
        />

        <Show when={isSet(meetingItem.dueDate)}>
          <div className="mr-1.5 self-start">
            <DatePickerDropdown
              onChange={(date) => {
                track(DUE_DATE_ADDED_TO_ACTION_ITEM);
                handleDateChange(meetingItem.id, date);
              }}
              value={meetingItem.dueDate}
              range="future"
            >
              <span
                className={twClass(
                  "px-0 py-0 text-slate-800 hover:cursor-pointer hover:text-blue-500",
                  {
                    "text-red-500 hover:text-red-700": dayjs(
                      meetingItem.dueDate
                    ).isBefore(dayjs()),
                  }
                )}
                data-cy="meetingItemDueDate"
              >
                <ActionItemDueDate dueDate={meetingItem.dueDate} />
              </span>
            </DatePickerDropdown>
          </div>
        </Show>
      </div>
      <OneOnOneMeetingItemActions
        loading={savingStatus === "saving"}
        handleDateChange={handleDateChange}
        handleRemoveItem={handleRemoveItem}
        handleToggleIsRecurring={handleToggleIsRecurring}
        meetingItem={meetingItem}
      />
    </div>
  );
};
