import { createNonNullableCtx } from "common/context/utils";

type ProfileContextType = NonNullable<{
  canPatch?: boolean | null;
  id: string;
  kpisEnabled: boolean;
  name: string;
}>;

export const [useProfileContext, ProfileContext, useOptionalProfileContext] =
  createNonNullableCtx<ProfileContextType>();
