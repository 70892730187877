import React, { useContext } from "react";
import { ProfileOkrListContext } from "common/objective/context";
import { Objective } from "common/objective/Objective/Objective";
import { ObjectiveActions } from "./ObjectiveActions/ObjectiveActions";

type ObjectiveWrapperType = {
  canUnalign?: boolean;
  objective?: any;
};

export const ObjectiveWrapper = ({
  objective,
  canUnalign = false,
}: ObjectiveWrapperType) => {
  const { reFetchQueries, showGroups } = useContext(ProfileOkrListContext);
  const actions = (
    <ObjectiveActions
      canUnalign={canUnalign}
      objective={objective}
      onChangeQueries={reFetchQueries}
    />
  );

  return (
    <Objective
      actions={actions}
      data-cy="objective"
      data-cy-id={objective.id}
      objective={objective}
      showGroups={showGroups}
    />
  );
};
