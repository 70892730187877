import { OneOnOneRepeatFrequency } from "@graphql";
import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  [OneOnOneRepeatFrequency.Every_3Months]: {
    defaultMessage: "Every 3 Months",
    id: "qD+HNZ",
  },
  [OneOnOneRepeatFrequency.NoRepeat]: {
    defaultMessage: "Don't Repeat",
    id: "Dqu6be",
  },
  [OneOnOneRepeatFrequency.Monthly]: {
    defaultMessage: "Every month",
    id: "YaEHga",
  },
  [OneOnOneRepeatFrequency.BiWeekly]: {
    defaultMessage: "Every 2 weeks",
    id: "SfkaNB",
  },
  [OneOnOneRepeatFrequency.Weekly]: {
    defaultMessage: "Every week",
    id: "g7bjQR",
  },
  [OneOnOneRepeatFrequency.Integration]: {
    defaultMessage: "See Integration",
    id: "8NgAnk",
  },
});

type Hook = { label: string; value: string }[];

export const useOneOnOneMeetingFrequencyOptions = (): Hook => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(messages[OneOnOneRepeatFrequency.NoRepeat]),
      value: OneOnOneRepeatFrequency.NoRepeat,
    },

    {
      label: intl.formatMessage(messages[OneOnOneRepeatFrequency.Weekly]),
      value: OneOnOneRepeatFrequency.Weekly,
    },
    {
      label: intl.formatMessage(messages[OneOnOneRepeatFrequency.BiWeekly]),
      value: OneOnOneRepeatFrequency.BiWeekly,
    },
    {
      label: intl.formatMessage(messages[OneOnOneRepeatFrequency.Monthly]),
      value: OneOnOneRepeatFrequency.Monthly,
    },
    {
      label: intl.formatMessage(
        messages[OneOnOneRepeatFrequency.Every_3Months]
      ),
      value: OneOnOneRepeatFrequency.Every_3Months,
    },
  ];
};
