import { Combobox, Transition } from "@headlessui/react";
import { isFunction, isNil } from "lodash";
import React, {
  Fragment,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { ComboIcon } from "common/inputs/ComboIcon/ComboIcon";
import { ComboOptionProps } from "common/inputs/ComboOption/ComboOption";
import { isDefined } from "utils/isDefined";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { messages } from "./Combo.messages";
import { ComboItem } from "./Combo.types";
import { useComboItems } from "./useComboItems/useComboItems";

// TODO: 🤮 this component needs to be refactored to use composition, because it's heading for an apropcalypse. 🤮
export type ComboProps<Item extends ComboItem = ComboItem> = {
  buttonProps?: Record<string, unknown>;
  children: ((props: ComboOptionProps<Item>) => ReactElement) | ReactElement;
  className?: string;
  defaultItem?: Item | null;
  /** shows "{search text} (add new)" in the list if no items found */
  emptyAddNew?: boolean;
  icon?: ReactElement;
  inputClass?: string;
  insideComponent?: () => React.ReactNode;
  items: Item[];
  keepOpen?: boolean;
  label?: string;
  /** max number of items to show in the selector. unset for no limit. */
  limitItems?: number;
  menuClass?: string;
  /** pass to use inside form - hidden input elements will be rendered and kept in sync with your selected value. */
  name?: string;
  onChange?: (i: Item) => void;
  optionProps?: Record<string, unknown>;
  /** shows "no results". can be used in conjunction with {@link emptyAddNew} to show both. */
  showEmptyState?: boolean;
};

/**
 * Combobox (search + selector)
 * @see https://headlessui.com/react/combobox
 * @deprecated Use `inputs/Select` instead.
 */
export const Combo = <Item extends ComboItem>({
  buttonProps,
  children,
  className,
  defaultItem: defaultItemProp,
  emptyAddNew,
  icon,
  inputClass,
  items: allItems,
  keepOpen,
  label,
  limitItems,
  menuClass,
  name,
  onChange,
  optionProps,
  showEmptyState = true,
  insideComponent,
}: ComboProps<Item>): JSX.Element => {
  const intl = useIntl();

  const [query, setQuery] = useState("");
  const defaultItem = isDefined(defaultItemProp)
    ? defaultItemProp
    : allItems[0];
  const [selectedItem, setSelectedItem] = useState(defaultItem);
  const buttonRef = useRef<HTMLButtonElement>(null);
  /**
   * whenever items change, we check if the selected item is still in the list, and select the default item otherwise.
   * TODO: check if this can be removed and handled by remounting Combo using key.
   */
  useEffect(() => {
    if (!selectedItem) return;

    if (!allItems.find((x) => x.id === selectedItem.id))
      setSelectedItem(defaultItem);
    else if (!allItems.find((x) => x.name === selectedItem.name))
      setSelectedItem(allItems.find((x) => x.id === selectedItem.id));
  }, [allItems, defaultItem, selectedItem]);

  const items = useComboItems<Item>({
    allItems,
    emptyAddNew,
    limitItems,
    query,
    showEmptyState,
  });

  /**
   * opens selector on focus if no already open.
   */
  const getOnFocusHandler = (isOpen: boolean) => {
    if (isOpen) {
      return undefined;
    }
    return () => buttonRef.current?.click();
  };

  const handleChange = (item: Item) => {
    setSelectedItem(item);
    onChange?.(item);
  };

  return (
    <div className={className} role="searchbox">
      <Combobox name={name} onChange={handleChange} value={selectedItem}>
        {({ open }) => (
          <div className="relative w-full">
            {isSet(label) && <Combobox.Label>{label}</Combobox.Label>}
            <div
              className={twClass(
                "relative w-full",
                "cursor-default overflow-hidden text-left",
                "rounded ring-1 ring-slate-300",
                {
                  "ring-2 ring-blue-500": open,
                }
              )}
            >
              <Combobox.Input
                data-cy="MyX3JcHVuUhEuu2ogjuMT"
                className={twClass(
                  "w-full truncate px-2 py-2 focus:outline-none",
                  inputClass
                )}
                displayValue={(item: Item | null) => item?.name ?? ""}
                onChange={(e) => setQuery(e.currentTarget.value)}
                onClick={getOnFocusHandler(open)}
                placeholder={intl.formatMessage(messages.placeholder)}
              />
              <Show when={open}>
                <div
                  className={twClass("absolute inset-y-0 flex items-center", {
                    "right-10": isSet(icon),
                    "right-2": !isSet(icon),
                  })}
                >
                  <IconButton
                    data-cy="3isqP6gQQ7Sx_So9g-cxI"
                    className="text-slate-400 hover:text-blue-500 active:text-blue-600"
                    name="close"
                    onClick={() => setQuery("")}
                    size="lg"
                  />
                </div>
              </Show>
              <Show when={isSet(icon)}>
                <div className="absolute inset-y-0 right-9 my-2.5 border-l border-slate-300" />
              </Show>
              <Combobox.Button
                ref={buttonRef}
                className="absolute inset-y-0 right-2 flex items-center"
                {...buttonProps}
              >
                {isSet(icon) ? (
                  React.cloneElement(icon, { open })
                ) : (
                  <ComboIcon open={open} />
                )}
              </Combobox.Button>
            </div>
            {!isNil(insideComponent) && insideComponent()}
            <Transition
              afterLeave={() => setQuery("")}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open || keepOpen}
            >
              <Combobox.Options
                className={twClass(
                  "absolute z-modal-prio",
                  "max-h-60 min-w-full max-w-2xl",
                  "-ml-px mt-1.5 py-1",
                  "focus:outline-none",
                  "rounded-md shadow-lg ring-1 ring-black ring-opacity-5",
                  "overflow-auto bg-white",
                  "z-modal",
                  menuClass
                )}
                static={keepOpen}
              >
                {items.map((item) => (
                  <Combobox.Option
                    key={item.id}
                    disabled={item.disabled ?? false}
                    value={item}
                    {...optionProps}
                  >
                    {({ active, selected }) =>
                      isFunction(children)
                        ? children({
                            active,
                            disabled: item.disabled ?? false,
                            item,
                            selected,
                          })
                        : children
                    }
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};
