import classNames from "clsx";
import * as React from "react";

interface Props {
  className?: string;
  Component?: React.ElementType;
  html: string;
}

export const DangerousHTML: React.FC<Props> = ({
  html,
  className,
  Component = (props) => <div {...props} />,
}) => (
  <Component
    className={classNames(className, "dangerous-html", "ProseMirror")}
    dangerouslySetInnerHTML={{ __html: html }}
    data-cy="descriptionText"
  />
);
