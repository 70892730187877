import { FormattedMessage } from "react-intl";
import { ShowFeature } from "common/access/ShowFeature";
import { useResultContext } from "common/context/resultContext";
import { Show } from "common/controlFlow";
import { usePOTFrequency } from "common/goal/hooks/usePOTFrequency";
import { GoalActivity } from "common/goalModal/GoalActivity/GoalActivity";
import { GoalActivityHeader } from "common/goalModal/GoalActivity/GoalActivityHeader/GoalActivityHeader";
import { GoalDisclosure } from "common/goalModal/GoalDisclosure/GoalDisclosure";
import { FrequencyDropdown } from "common/overlay/FrequencyDropdown/FrequencyDropdown";
import { LoadingManager } from "common/placeholders/LoadingManager/LoadingManager";
import { WidgetLoading } from "common/placeholders/WidgetLoading/WidgetLoading";
import { objectTypes } from "constants/objects";
import { resultTypes } from "constants/resultTypes";
import { ResultDescription } from "./ResultDescription/ResultDescription";
import { ResultHeader } from "./ResultHeader/ResultHeader";
import { ResultProgressGraphContainer } from "./ResultPOT/ResultProgressGraphContainer";
import { SupportingInitiatives } from "./SupportingInitiatives/SupportingInitiatives";
import { SupportingInitiativesHeader } from "./SupportingInitiatives/SupportingInitiativesHeader/SupportingInitiativesHeader";

export const ResultSummary = () => {
  const result = useResultContext();
  const [freq, setFreq] = usePOTFrequency(result.objective.goalUpdateCycle);

  return (
    <div className="flex flex-col bg-white">
      <ResultHeader />
      <GoalDisclosure
        data-testid="resultDescription"
        title={
          <FormattedMessage
            defaultMessage="Description"
            id="objective:forms:description:label"
          />
        }
      >
        <ResultDescription />
      </GoalDisclosure>
      <div>
        <ShowFeature feature="GRAPH_POT">
          <GoalDisclosure
            data-testid="resultDescription"
            title={
              <div className="flex justify-between w-full">
                <div>
                  <FormattedMessage
                    defaultMessage="Progress over time"
                    id="keyResult:modal:progressOverTime"
                  />
                </div>
                <FrequencyDropdown freq={freq} setFreq={setFreq} />
              </div>
            }
          >
            <div className="mt-5">
              <LoadingManager fallback={<WidgetLoading />}>
                <ResultProgressGraphContainer freq={freq} result={result} />
              </LoadingManager>
            </div>
          </GoalDisclosure>
        </ShowFeature>
      </div>
      <Show when={resultTypes.keyResult === result.type}>
        <GoalDisclosure
          data-testid="supportingInitiative"
          title={<SupportingInitiativesHeader />}
        >
          <SupportingInitiatives />
        </GoalDisclosure>
      </Show>

      <GoalDisclosure
        data-testid="resultActivity"
        title={<GoalActivityHeader />}
      >
        <GoalActivity entityId={result.id} entityType={objectTypes.keyresult} />
      </GoalDisclosure>
    </div>
  );
};
