import { useGetAllTagsQuery } from "@graphql";
import { isEmpty, orderBy } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { Clickable } from "common/navigation/Clickable";
import { TextSkeleton } from "common/placeholders/TextSkeleton/TextSkeleton";
import { Tag } from "common/tag/Tag/Tag";
import { useToggle } from "hooks/useToggle/useToggle";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";
import { CloseFn, MultiSelect } from "../MultiSelect/MultiSelect";

type Value = string[];

type Option = {
  color: string | null;
  label: string;
  value: string;
};

type Props = {
  "data-cy"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => void;
  value: Value;
};

export const InlineTagsSelect = ({
  "data-cy": dataCy,
  onChange,
  value,
  disabled,
}: Props) => {
  const [open, toggleOpen, setOpen] = useToggle(false);
  const { data } = useGetAllTagsQuery();
  const tags = mapEdges(data?.allTags.edges);

  const orderedOptions: Option[] = useMemo(() => {
    const options = tags.map((tag) => ({
      color: tag.color,
      label: tag.name,
      selected: value.includes(tag.id),
      value: tag.id,
    }));
    return orderBy(options, ["selected"], ["desc"]);
  }, [value, tags]);

  const handleClose: CloseFn<Option> = (selectedOptions) => {
    toggleOpen();
    if (!selectedOptions) return;
    onChange?.(selectedOptions.map((opt) => opt.value));
  };

  const selected = tags.filter((user) => value.includes(user.id));
  if (!data) return <TextSkeleton />;

  return (
    <Dropdown
      open={open}
      onToggle={setOpen}
      disabled={disabled}
      button={
        !isEmpty(selected) ? (
          <Clickable
            data-cy={dataCy}
            className={twClass(
              "flex flex-wrap w-full border border-transparent data-[state=open]:bg-white data-[state=open]:border-slate-300 rounded px-2 pt-2 -ml-2 -mt-2",
              {
                "cursor-not-allowed": disabled,
                "hover:bg-white hover:border-slate-300": !disabled,
              }
            )}
          >
            {selected.map((tag) => (
              <div key={tag.id} className="flex items-center mr-2 mb-2">
                <Tag text={tag.name} color={tag.color} />
              </div>
            ))}
          </Clickable>
        ) : (
          <TextButton className="p-0" size="small" data-cy={dataCy}>
            +&nbsp;
            <FormattedMessage defaultMessage="Add tag" id="Un1mxZ" />
          </TextButton>
        )
      }
      className="block"
    >
      {open && (
        <MultiSelect
          onClose={handleClose}
          options={orderedOptions}
          initialValue={value}
          required={false}
          saveText={<FormattedMessage defaultMessage="Done" id="JXdbo8" />}
        />
      )}
    </Dropdown>
  );
};
