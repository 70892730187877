import { GroupDefaultFragment } from "@graphql";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { getLinkByType } from "route-configs";
import { OwnersDrilldown } from "common/group/modals/OwnersDrilldown/OwnersDrilldown";
import { Icon } from "common/icons/Icon/Icon";
import { IconSize } from "common/misc/types";
import { Modal } from "common/overlay/Modal/Modal";
import { GoalType } from "constants/goal.types";
import { objectTypes } from "constants/objects";
import { useCompany } from "hooks/useCompany/useCompany";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";
import { messages } from "./GoalOwners.messages";

interface Props {
  className?: string;
  goalId: string;
  goalType: GoalType;
  iconSize?: keyof typeof IconSize;
  isCompanyGoal?: boolean;
  maxCount?: number;
  owners: NodeConnection<GroupDefaultFragment>;
  textClassName?: string;
}

export const GoalOwners = ({
  className,
  isCompanyGoal = false,
  owners,
  maxCount = 2,
  textClassName,
  iconSize = "xl",
  goalId,
  goalType,
}: Props): JSX.Element => {
  const company = useCompany();
  const inTeams = useInTeams();
  const intl = useIntl();
  const router = useRouter();

  const [modalState, setModalState] = useState(false);

  const allOwners = mapEdges<GroupDefaultFragment>(owners.edges);
  if (isEmpty(allOwners) || isCompanyGoal) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ avatar: string; id: string; na... Remove this comment to see the full error message
    allOwners.push({
      avatar: company.avatar,
      id: company.id || "",
      name: company.name,
    });
  }

  const hiddenChildrens = allOwners.slice(maxCount, allOwners.length);

  const handleClick = () => {
    if (allOwners.length === 1) {
      router.push(
        getLinkByType(
          isCompanyGoal ? objectTypes.company : objectTypes.group,
          allOwners[0]?.id ?? ""
        )
      );
      return;
    }
    setModalState(!modalState);
  };

  const ownersToShow = (
    <div className="whitespace-no-wrap flex">
      <div className={twClass("max-w-[30ch] truncate", textClassName)}>
        {allOwners
          .slice(0, maxCount)
          .map((owner) => owner.name)
          .join(", ")}
      </div>
      {!isEmpty(hiddenChildrens) && (
        <>
          ,
          {intl.formatMessage(
            {
              defaultMessage: "+{number} more",
              id: "global:plus:more",
            },
            { number: hiddenChildrens.length }
          )}
        </>
      )}
    </div>
  );

  if (inTeams) {
    return (
      <div className="flex items-center">
        <div className="whitespace-no-wrap flex p-2 text-center text-sm text-slate-500">
          <Icon className="mr-1" name="group" size={iconSize} />
          {ownersToShow}
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <button
        className={twClass(
          "whitespace-no-wrap flex rounded p-2 text-center text-sm text-slate-500 hover:bg-slate-100 hover:text-blue-500",
          className
        )}
        data-cy="modal-subtext-group"
        onClick={handleClick}
        type="button"
      >
        <Icon className="mr-1" name="group" size={iconSize} />
        {ownersToShow}
      </button>
      <Modal
        backdropClass="z-40"
        className="z-50"
        isOpen={modalState}
        onClose={setModalState}
        size="sm"
        title={intl.formatMessage(messages.owners)}
      >
        <OwnersDrilldown goalId={goalId} goalType={goalType} />
      </Modal>
    </div>
  );
};
