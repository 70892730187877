import { MetricUnit, PerdooApiKeyResultTypeChoices } from "@graphql";
import dayjs from "dayjs";
import { toNumber } from "lodash";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { getCompleteLink, routes } from "route-configs";
import { Status } from "common/charts/Status/Status";
import { useResultContext } from "common/context/resultContext";
import { Show } from "common/controlFlow";
import { parseCommaSeparatedValues } from "common/form/utils/parseCommaSeparatedValues";
import { Icon } from "common/icons";
import { InlineDatePicker } from "common/inputs/InlineDatePicker/InlineDatePicker";
import { InlineInput } from "common/inputs/InlineInput/InlineInput";
import { InlineIntegrationPicker } from "common/inputs/InlineIntegrationPicker/InlineIntegrationPicker";
import { InlineMetricUnitSelect } from "common/inputs/InlineMetricUnitSelect/InlineMetricUnitSelect";
import { InlineResultSelect } from "common/inputs/InlineResultSelect/InlineResultSelect";
import { InlineResultTypeSelect } from "common/inputs/InlineResultTypeSelect/InlineResultTypeSelect";
import { InlineTagsSelect } from "common/inputs/InlineTagsSelect/InlineTagsSelect";
import { InlineUserMultiSelect } from "common/inputs/InlineUserMultiSelect/InlineUserMultiSelect";
import { InlineUserSelect } from "common/inputs/InlineUserSelect/InlineUserSelect";
import { IntegrationLogo } from "common/integration/IntegrationLogo/IntegrationLogo";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { useFormatMetric } from "common/misc/FormattedMetric/hooks/useFormatMetric";
import { SelectLinkButton } from "common/misc/SelectLinkButton/SelectLinkButton";
import { AnchorNext } from "common/navigation";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { ProgressBar } from "common/progress/ProgressBar/ProgressBar";
import { useInlineResultActions } from "common/result/hooks/useInlineResultActions";
import { SupportTip } from "common/support";
import { getMetricSymbol } from "constants/metric";
import { resultTypes } from "constants/resultTypes";
import { allRolesButViewonly } from "constants/roles";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { roleNames } from "hooks/useRoleOptions/useRoleOptions";
import { isSet } from "utils/isSet";
import { mapFn } from "utils/mapFn/mapFn";
import { twClass } from "utils/twClass";
import { GoalSidebarItem } from "../../../../../../goalModal/GoalSidebarItem/GoalSidebarItem";
import { ResultIntegrationDetail } from "./ResultIntegrationDetail";
import { ResultProgressUpdateButton } from "./ResultProgressUpdateButton/ResultProgressUpdateButton";

export const ResultSidebar = () => {
  const result = useResultContext();
  const currentUser = useCurrentUser();
  const {
    setStartValue,
    setMetricUnit,
    setEndValue,
    setLead,
    setType,
    setParent,
    setTags,
    setContributors,
    setStartDate,
    setDueDate,
    setIntegration,
  } = useInlineResultActions(result);
  const { formatMetric } = useFormatMetric();
  const isOverDue = dayjs().isAfter(result.dueDate);
  const resultType =
    result.type === resultTypes.keyResult ? (
      <FormattedMessage defaultMessage="Key Result" id="global:keyResult" />
    ) : (
      <FormattedMessage defaultMessage="Initiative" id="global:initiative" />
    );

  const shouldBeInitiative =
    result.type === PerdooApiKeyResultTypeChoices.KeyResult &&
    result.startValue === 0 &&
    result.endValue === 100 &&
    result.metricUnit === MetricUnit.Percentage;

  const contributors = useMemo(
    () => result.contributors.edges.map((edge) => edge.node.id),
    [result]
  );
  const tags = useMemo(
    () => result.tags.edges.map((edge) => edge.node.id),
    [result]
  );

  return (
    <div className="h-full" data-testid="resultSidebar">
      <div className="flex flex-col space-y-4 pb-4 border-b border-slate-300 font-medium">
        <FormattedMessage
          defaultMessage="{resultType} details"
          id="LVYfUE"
          values={{
            resultType: resultType,
          }}
        />
      </div>
      <div className="py-4 text-slate-500 text-sm border-b border-slate-300">
        <div className="flex flex-col space-y-1">
          <ResultProgressUpdateButton />
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage
                defaultMessage="Progress"
                id="global:progress"
              />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content className="w-3/4">
              {isSet(result.normalizedValue) && (
                <div className="flex items-center space-x-2">
                  <ProgressBar
                    className="w-32"
                    progress={result.normalizedValue}
                  />
                  <div className="text-slate-800">
                    <div className="flex w-full gap-1 text-sm">
                      <Show when={isSet(result.integration)}>
                        <IntegrationLogo
                          application={result.integration?.application}
                        />
                      </Show>
                      <strong data-testid="currentProgress">
                        <FormattedMetric
                          value={result.currentValue}
                          unit={result.metricUnit}
                        />
                      </strong>
                      /
                      <FormattedMetric
                        value={result.endValue}
                        unit={result.metricUnit}
                      />
                    </div>
                  </div>
                </div>
              )}
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Status" id="global:status" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <Status
                className="text-slate-800"
                data-testid="currentStatus"
                status={result.status}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div
                  className={twClass({ "text-yellow-500": shouldBeInitiative })}
                >
                  <FormattedMessage
                    defaultMessage="Result Type"
                    id="result:form:resultType:label"
                  />
                </div>
                <Show when={shouldBeInitiative}>
                  <SupportTip
                    iconSize="lg"
                    description={
                      <FormattedMessage
                        defaultMessage="Results with start value 0% and target value 100% are typically projects and tasks. Since these are a means to an end, they’re better tracked as Initiatives."
                        id="Oxi5qX"
                      />
                    }
                    iconClassName="text-yellow-500"
                  />
                </Show>
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineResultTypeSelect
                value={result.type}
                onChange={setType}
                disabled={!result.canPatch}
                data-cy="typeSelect"
                data-testid="typeSelect"
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={result.type === PerdooApiKeyResultTypeChoices.Initiative}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage
                  defaultMessage="Parent Key Result"
                  id="uSTXsz"
                />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <InlineResultSelect
                  value={result.parent?.id ?? null}
                  onChange={setParent}
                  objective={result.objective.id}
                  type={PerdooApiKeyResultTypeChoices.KeyResult}
                  data-cy="parentSelect"
                  disabled={!result.canPatch}
                />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Lead" id="global:lead" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <SelectLinkButton
                href={
                  getCompleteLink(routes.users.single.okrs, {
                    id: result.lead?.id,
                  }).absolutePath
                }
                tooltip={
                  <FormattedMessage defaultMessage="Open profile" id="z6rKH9" />
                }
              >
                <InlineUserSelect
                  value={result.lead?.id ?? null}
                  variables={{
                    roleIn: allRolesButViewonly.join(),
                  }}
                  onChange={setLead}
                  data-cy="leadSelect"
                  data-testid="leadSelect"
                  disabled={!result.canPatch}
                />
              </SelectLinkButton>
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
        </div>
      </div>

      <div className="py-4 text-slate-500 text-sm border-b border-slate-300">
        <div className="flex flex-col space-y-1">
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage
                defaultMessage="Start value"
                id="global:startValue"
              />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineInput
                value={result.startValue.toString()}
                displayValue={formatMetric(result.startValue)}
                prefix={mapFn(result.metricUnit, getMetricSymbol)}
                size="sm"
                onChange={(strValue) =>
                  setStartValue(toNumber(parseCommaSeparatedValues(strValue)))
                }
                data-cy="startValueInput"
                data-testid="startValueInput"
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div
                  className={twClass({ "text-yellow-500": shouldBeInitiative })}
                >
                  <FormattedMessage
                    defaultMessage="Target value"
                    id="result:form:endValue:label"
                  />
                </div>
                <Show when={shouldBeInitiative}>
                  <SupportTip
                    iconSize="lg"
                    description={
                      <FormattedMessage
                        defaultMessage="Results with start value 0% and target value 100% are typically projects and tasks. Since these are a means to an end, they’re better tracked as Initiatives."
                        id="Oxi5qX"
                      />
                    }
                    iconClassName="text-yellow-500"
                  />
                </Show>
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineInput
                value={result.endValue.toString()}
                displayValue={formatMetric(result.endValue)}
                prefix={mapFn(result.metricUnit, getMetricSymbol)}
                size="sm"
                onChange={(strValue) =>
                  setEndValue(toNumber(parseCommaSeparatedValues(strValue)))
                }
                data-cy="endValueInput"
                data-testid="endValueInput"
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Measure as" id="uzzR4W" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineMetricUnitSelect
                value={result.metricUnit}
                onChange={setMetricUnit}
                data-testid="metricUnitSelect"
                data-cy="metricUnitSelect"
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
        </div>
      </div>

      <div className="py-4 text-slate-500 text-sm">
        <div className="flex flex-col space-y-1">
          <GoalSidebarItem className="items-start">
            <GoalSidebarItem.Title>
              <div className="h-6 flex items-center">
                <FormattedMessage
                  defaultMessage="Tags"
                  id="company:settings:tags:title"
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineTagsSelect
                value={tags}
                onChange={setTags}
                data-cy="tagsSelect"
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem className="items-start">
            <GoalSidebarItem.Title>
              <div className="h-6 flex items-center">
                <FormattedMessage
                  defaultMessage="Contributors"
                  id="global:contributors"
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineUserMultiSelect
                value={contributors}
                onChange={setContributors}
                data-cy="contributorsSelect"
                addText={
                  <FormattedMessage
                    defaultMessage="Add contributor"
                    id="pkW5hu"
                  />
                }
                options={{ excludeViewOnly: true }}
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage
                defaultMessage="Integration"
                id="result:form:integration:label/"
              />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineIntegrationPicker
                data-cy="openIntegrationDropdown"
                goal={result}
                onChange={setIntegration}
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={isSet(result.integration)}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage defaultMessage="Updated from" id="9CDG6w" />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <ResultIntegrationDetail />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage
                defaultMessage="Start date"
                id="result:form:startDate:label"
              />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineDatePicker
                value={result.startDate}
                onChange={setStartDate}
                data-cy="startDateSelect"
                defaultMessage={
                  <FormattedMessage
                    defaultMessage="No start date"
                    id="mYnwmQ"
                  />
                }
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div
                className={twClass("flex space-x-1 items-center", {
                  "text-red-500": isOverDue,
                })}
              >
                <div>
                  <FormattedMessage
                    defaultMessage="Due date"
                    id="result:form:dueDate:label"
                  />
                </div>
                <Show when={isOverDue}>
                  <WithTooltip
                    tooltip={
                      <div>
                        <FormattedMessage
                          defaultMessage="Overdue"
                          id="M0vCGv"
                        />
                      </div>
                    }
                  >
                    <Icon
                      name="error_outline"
                      className="text-red-500"
                      size="lg"
                    />
                  </WithTooltip>
                </Show>
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineDatePicker
                value={result.dueDate}
                onChange={setDueDate}
                data-cy="dueDateSelect"
                hasError={isOverDue}
                defaultMessage={
                  <FormattedMessage defaultMessage="No due date" id="mLrh+0" />
                }
                disabled={!result.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={!result.canPatch}>
            <div className="flex space-x-2 items-center py-4 text-slate-500 text-sm border-t border-slate-300">
              <Icon name="error_outline" />
              <div>
                {currentUser?.role && (
                  <FormattedMessage
                    defaultMessage="As a {role} user editing possibilities are restricted. {learnMore}"
                    id="wslZHv"
                    values={{
                      learnMore: (
                        <AnchorNext
                          href="https://support.perdoo.com/en/articles/1588568-roles-rights"
                          data-cy="rolesLink"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage
                            defaultMessage="Learn more"
                            id="TdTXXf"
                          />
                        </AnchorNext>
                      ),
                      role: (
                        <FormattedMessage {...roleNames[currentUser.role]} />
                      ),
                    }}
                  />
                )}
              </div>
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
};
