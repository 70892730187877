/**
 * Checks whether a variable is set = is non-nullable = is neither `null` nor `undefined`. Can be used as type guard.
 * @example
 * [1, null, 2, undefined].filter(isSet);
 * // => [1, 2]
 *
 * @example
 * if (isSet(data)) {
 *   // process data
 * }
 *
 * @example
 * const { data } = useQuery(...);
 * const item = data?.item; // Item | null | undefined
 * if (isSet(item)) {
 *   doSomething(item); // Item
 * }
 */
export const isSet = <T>(value: T | null | undefined): value is T => {
  return value != null;
};
