import { PerformanceReviewQuestionVisibility } from "@graphql";

export const isQuestionVisible = (
  userIsReviewee: boolean,
  visibility: PerformanceReviewQuestionVisibility
): boolean => {
  const compatibleVisibilities = userIsReviewee
    ? [
        PerformanceReviewQuestionVisibility.Both,
        PerformanceReviewQuestionVisibility.DirectReport,
      ]
    : [
        PerformanceReviewQuestionVisibility.Both,
        PerformanceReviewQuestionVisibility.Manager,
      ];
  return compatibleVisibilities.includes(visibility);
};
