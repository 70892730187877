import { Kudo } from "@graphql";
import { Derive } from "@shoooe/derive";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { ReactionButton } from "common/reaction/ReactionButton";
import {
  DeleteKudoModal,
  DeleteKudoModalKudo,
} from "../DeleteKudoModal/DeleteKudoModal";
import { useDeleteKudo } from "../hooks/useDeleteKudo/useDeleteKudo";

type KudoActionsKudo = Derive<
  Kudo,
  {
    canDelete: true;
    id: true;
  }
> &
  DeleteKudoModalKudo;

type Props = {
  kudo: KudoActionsKudo;
};

export const KudoActions = ({ kudo }: Props): JSX.Element | null => {
  const {
    deleteKudo,
    modalOpen: deleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useDeleteKudo();

  return (
    <>
      <ReactionButton entityId={kudo.id} />
      {kudo.canDelete && (
        <>
          <DeleteKudoModal
            kudo={kudo}
            onClose={closeDeleteModal}
            onDelete={() => deleteKudo(kudo.id)}
            visible={deleteModalOpen}
          >
            <TextButton
              data-cy="xXIrKg8P4KPur4YOcDeae"
              className="cursor-pointer p-0 font-semibold hover:text-blue-500"
              onClick={openDeleteModal}
              size="small"
              text={
                <FormattedMessage defaultMessage="Delete" id="global:delete" />
              }
            />
          </DeleteKudoModal>
        </>
      )}
    </>
  );
};
