import { map } from "lodash";
import { objectTypes } from "../../../constants/objects";
import {
  SEARCH_GLOBAL_FAILURE,
  SEARCH_GLOBAL_REQUEST,
  SEARCH_GLOBAL_SUCCESS,
} from "../../../legacy/actionTypes";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'query' implicitly has an 'any' type.
const searchGlobal = (query) => (dispatch) => {
  if (!query) {
    return Promise.resolve([]);
  }
  return dispatch({
    CALL_API: {
      endpoint: "/search",
      queryParams: { q: query },
      types: [
        SEARCH_GLOBAL_REQUEST,
        SEARCH_GLOBAL_SUCCESS,
        SEARCH_GLOBAL_FAILURE,
      ],
    },
    type: "searchGlobal",
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
  }).then((response) =>
    map(response.data, (item) => ({
      ...item,
      label: item.attributes.name,
      type:
        item.attributes.type === "keyresult" // camelcase kr type
          ? objectTypes.keyresult
          : item.attributes.type,
      value: item.id,
    }))
  );
};

export { searchGlobal };
