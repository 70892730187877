import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cadence: {
    defaultMessage: "Cadence",
    id: "group:forms:cadence:label",
  },
  cadencePlaceholder: {
    defaultMessage: "Pick a cadence",
    id: "group:forms:cadence:cadencePlaceholder",
  },
  kpisEnabledLabel: {
    defaultMessage: "Enable KPIs for this group",
    id: "group:profile:kpisEnabledLabel",
  },
  kpisHelpLabel: {
    defaultMessage:
      "KPIs are the core indicators of performance for your team. These can be set up on your Group page if you enable them.",
    id: "group:profile:kpis:helpRollover",
  },
  leaderLabel: {
    defaultMessage: "Group leader",
    id: "group:forms:leader:label",
  },
  leaderPlaceholder: {
    defaultMessage: "Search...",
    id: "group:forms:leader:placeholder",
  },
  membersPlaceholder: {
    defaultMessage: "Search...",
    id: "group:forms:members:placeholder",
  },
  missionLabel: {
    defaultMessage: "Mission",
    id: "group:forms:mission:label",
  },
  missionPlaceholder: {
    defaultMessage: "Mission",
    id: "group:forms:mission:placeholder",
  },
  nameLabel: {
    defaultMessage: "Name",
    id: "group:forms:name:formLabel",
  },
  namePlaceholder: {
    defaultMessage: "Name",
    id: "group:forms:name:placeholder",
  },
  profilePicture: {
    defaultMessage: "Profile Picture",
    id: "group:profile:avatarTitle",
  },
  slackChannelLabel: {
    defaultMessage: "Slack channel",
    id: "group:forms:slackChannel:label",
  },
  slackChannelPlaceholder: {
    defaultMessage: "Pick a channel",
    id: "group:forms:slackChannel:placeholder",
  },
  subGroupsLabel: {
    defaultMessage: "Subteams",
    id: "ETq3+E",
  },
  subGroupsPlaceholder: {
    defaultMessage: "Search...",
    id: "group:forms:subGroups:placeholder",
  },
});
