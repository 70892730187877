import {
  ObjectiveClosingNoteFragment,
  ObjectiveDefaultFragment,
  ObjectiveGroupsFragment,
  ObjectivePermissionsFragment,
  ObjectiveProgressFragment,
  ObjectiveStageFragment,
  ObjectiveStatusFragment,
  TimeframeIsPastFragment,
} from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { objectiveStages } from "common/objective/constants";
import { useCloseObjectiveModal } from "common/objective/modals/CloseObjectiveModal/hooks/useCloseObjectiveModal/useCloseObjectiveModal";
import { useReopenObjectiveConfirmationModal } from "common/objective/ReopenObjectiveConfirmationModal";
import { Dropdown } from "common/overlay/Dropdown";
import { MODAL_TYPES } from "constants/index";
import { useObjectiveActions } from "hooks/useObjectiveActions/useObjectiveActions";
import { showModal } from "legacy/actions/actions";

interface Props {
  objective: ObjectiveDefaultFragment &
    ObjectiveGroupsFragment &
    ObjectiveStatusFragment &
    ObjectiveClosingNoteFragment &
    ObjectiveStageFragment &
    ObjectiveProgressFragment &
    ObjectivePermissionsFragment & {
      timeframe: TimeframeIsPastFragment;
    };
}

export const ObjectiveStageActions = ({
  objective,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { openCloseObjectiveModal } = useCloseObjectiveModal();
  const { openModal: openReopenModal } = useReopenObjectiveConfirmationModal();
  const { activateObjective } = useObjectiveActions({ objective });

  const openCloneObjectiveModal = () => {
    dispatch(
      showModal(MODAL_TYPES.CLONE_OBJECTIVE, {
        passedObjectiveId: objective.id,
      })
    );
  };

  const handleClose = () => openCloseObjectiveModal({ objective });

  if (!objective.canPatch) return null;

  const showClosingNoteActions = objective.stage === objectiveStages.closed;
  const showCloseAction =
    objective.timeframe.isPast && objective.stage === objectiveStages.active;
  const showActivateAction = objective.stage === objectiveStages.draft;
  if (!showClosingNoteActions && !showCloseAction && !showActivateAction)
    return null;

  return (
    <div className="space-y-2">
      <div>
        <Show when={showClosingNoteActions}>
          <div className="flex space-x-2">
            <Button
              data-cy="puc-7xnioTenkw1YKyVei"
              className="w-full"
              icon="edit"
              onClick={handleClose}
              variant="ghost"
            >
              <FormattedMessage
                defaultMessage="Edit Closing Note"
                id="objective:edit:close"
              />
            </Button>

            <Dropdown>
              <Dropdown.Trigger>
                <Button icon="more_vert" variant="ghost" />
              </Dropdown.Trigger>
              <Dropdown.Content placement="bottom-right">
                <Dropdown.Item
                  data-cy="yqBj4MVZFJ8amLMN8W8T6"
                  className="flex items-center space-x-2"
                  onClick={() => openReopenModal({ objective })}
                >
                  <Icon name="archive" />
                  <div className="whitespace-nowrap">
                    <FormattedMessage
                      defaultMessage="Reopen Objective"
                      id="objective:reopen"
                    />
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  data-cy="KkRrpEJ2eReFvzNjP2KzV"
                  className="flex items-center space-x-2"
                  onClick={openCloneObjectiveModal}
                >
                  <Icon name="file_copy" />
                  <div className="whitespace-nowrap">
                    <FormattedMessage
                      defaultMessage="Clone Objective"
                      id="objective:clone"
                    />
                  </div>
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </div>
        </Show>
        <Show when={showCloseAction}>
          <div>
            <Button
              data-cy="rX0A4wSwuSPKA7kzAJTau"
              className="w-full"
              icon="archive"
              onClick={() => openCloseObjectiveModal({ objective })}
              variant="danger-outline"
            >
              <FormattedMessage
                defaultMessage="Close Objective"
                id="objective:close"
              />
            </Button>
          </div>
        </Show>

        <Show when={showActivateAction}>
          <div>
            <Button
              data-cy="rX0A4wSwuSPKA7kzAJTau"
              className="w-full"
              icon="archive"
              onClick={activateObjective}
              variant="normal-outline"
            >
              <FormattedMessage defaultMessage="Activate Draft" id="b04AhQ" />
            </Button>
          </div>
        </Show>
      </div>
    </div>
  );
};
