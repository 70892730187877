import { isNil, isNull } from "lodash";
import { useMemo } from "react";
import { useSelect } from "common/inputs/Select/useSelect";
import { useGetAlignedObjectives } from "hooks/useGetAlignedObjectives/useGetAlignedObjectives";
import { ObjectiveSelectType } from "../ObjectiveSelect";

export type ObjectiveSelectFilters = {
  strategicPillar?: null;
  timeframeCadence?: string;
  timeframes?: string[];
};

type Options = {
  excludedIds?: string[];
  filters?: ObjectiveSelectFilters;
  onChange?: (id: string) => void;
  value?: string;
};

export const useObjectiveSelect = ({
  value,
  onChange,
  filters,
  excludedIds,
}: Options) => {
  const strategicPillar_Isnull =
    !!filters && isNull(filters.strategicPillar) ? true : undefined;
  const timeframes = filters?.timeframes?.join(",");
  const timeframe_Cadence_Id = filters?.timeframeCadence;
  const { objectives } = useGetAlignedObjectives({
    excludedIds,
    variables: {
      strategicPillar_Isnull,
      timeframe_Cadence_Id,
      timeframes,
    },
  });

  const loading = isNil(objectives);

  const options = useMemo(() => objectives ?? [], [objectives]);
  const defaultItem = options.find((opt) => opt.id === value);
  const { selected, handleSearch, handleSelect, Select, visibleOptions } =
    useSelect({
      defaultItem,
      options,
    });

  const handleChange = async (objective: ObjectiveSelectType | null) => {
    if (!objective) return;
    handleSelect(objective);
    onChange?.(objective.id);
  };

  return {
    handleChange,
    handleSearch,
    loading,
    Select,
    selected,
    visibleOptions,
  };
};
