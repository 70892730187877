import { isNil } from "lodash";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { Button } from "common/buttons";
import { TimelineContext } from "common/event/TimelineContext/TimelineContext";
import { TimelineEvent } from "common/event/TimelineEvent/TimelineEvent";
import { NoteForm } from "common/form/NoteForm/NoteForm";
import { WidgetLoading } from "common/placeholders/WidgetLoading/WidgetLoading";
import { apolloQueryMerger } from "utils/utils";

interface Props {
  disableCommenting?: boolean;
  entityId: string;
  entityType: string;
}

export const GoalActivity = ({
  entityId,
  disableCommenting,
  entityType,
}: Props) => {
  const intl = useIntl();

  const { timelineFilter, timelineData, updateTimeline, fetchMore } =
    useContext(TimelineContext);

  if (isNil(timelineData)) {
    return <WidgetLoading />;
  }

  return (
    <div className="space-y-4 bg-[inherit] py-5">
      <div>
        {!disableCommenting && (
          <NoteForm
            afterSubmit={updateTimeline}
            entityId={entityId}
            entityType={entityType}
            filter={timelineFilter?.value}
          />
        )}
        {timelineData.edges.map(({ node }) => (
          <TimelineEvent
            key={node.id}
            entityId={entityId}
            entityType={entityType}
            event={node}
            fetchTimeline={updateTimeline}
            filter={timelineFilter?.value}
            showTargetNames
          />
        ))}

        {timelineData.pageInfo.hasNextPage && (
          <div className="flex justify-center p-2.5">
            <Button
              data-cy="Vaay64mclJ7V3hzhQGQ4M"
              onClick={() =>
                fetchMore({
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ updateQuery: (prev: any, { fet... Remove this comment to see the full error message
                  updateQuery: apolloQueryMerger,
                  variables: {
                    after: timelineData.pageInfo.endCursor,
                  },
                })
              }
            >
              {intl.formatMessage({
                defaultMessage: "Load more...",
                id: "global:loadMore",
              })}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
