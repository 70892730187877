import React, { ReactNode } from "react";
import ReactPlayer from "react-player";
import { Show } from "common/controlFlow";
import { BaseModal } from "common/overlay/BaseModal/BaseModal";
import { useToggle } from "hooks/useToggle/useToggle";
import { track } from "utils/tracker";

type OpenVideoAction = () => void;

type Props = {
  children?: (openVideo: OpenVideoAction) => ReactNode;
  videoUrl: string;
};

export const SupportVideo = ({ videoUrl, children }: Props): JSX.Element => {
  const [isOpen, toggleOpen] = useToggle(false);
  return (
    <>
      {children?.(toggleOpen)}
      <Show when={isOpen}>
        <BaseModal
          backdropClose
          className="aspect-video h-auto overflow-visible rounded-none"
          closeButtonPosition="external"
          fullHeight={false}
          isOpen={isOpen}
          onClose={toggleOpen}
          preRender
        >
          <ReactPlayer
            config={{}}
            controls
            height="100%"
            onPlay={() => {
              track("User played video", {
                video: "growth board",
              });
            }}
            playing={isOpen}
            url={videoUrl}
            width="100%"
          />
        </BaseModal>
      </Show>
    </>
  );
};
