import { mergeAttributes } from "@tiptap/core";
import TipTapMention from "@tiptap/extension-mention";
import { getLinkByType } from "route-configs";

export const Mention = TipTapMention.extend({
  renderHTML({ node, HTMLAttributes }) {
    const userId = node.attrs.id;
    const userLink = getLinkByType("user", userId.split(":")[1]);

    return [
      "span",
      mergeAttributes(
        { "data-type": this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      [
        "a",
        mergeAttributes({ href: userLink }),
        this.options.renderLabel({
          node,
          options: this.options,
        }),
      ],
    ];
  },
});
