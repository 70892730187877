import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  menu?: ReactNode;
};

/**
 * header for report widgets, with optional menu ({@link ReportCardMenu}).
 */
export const ReportCardHeader = ({ children, menu }: Props): JSX.Element => {
  return (
    <div className="flex items-center justify-between font-semibold text-slate-800">
      <div className="flex gap-1">{children}</div>
      {menu}
    </div>
  );
};
