import { QueryOptions } from "@apollo/client";
import { namedOperations, useUpsertObjectiveMutationMutation } from "@graphql";
import { Menu } from "@headlessui/react";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { EntityIcon, Icon } from "common/icons";
import { ObjectivePicker } from "common/inputs/ObjectivePicker/ObjectivePicker";
import { messages } from "common/objective/modals/ObjectiveModal/ObjectiveDetailsModal/ObjectiveDetailsModal.messages";
import { getAlignedObjectivesTabsQueries } from "common/objective/modals/ObjectiveModal/ObjectiveDetailsModal/ObjectiveSummary/AlignedObjectives/AlignedObjective.utils";
import { Dropdown } from "common/overlay/Dropdown";
import { MODAL_TYPES } from "constants/index";
import { progressTypes } from "constants/progressTypes";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { showModal } from "legacy/actions/actions";
import { DropdownContext } from "legacy/components/Dropdown/DropdownContext";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

interface Props {
  disabled?: boolean;
  kpiId: string;
}

export const AlignedObjectiveDropdown = ({
  kpiId,
  disabled,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [addingOkr, setAddingOkr] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);

  const dropdownRef = useRef<(HTMLDivElement & DropdownContext) | null>(null);

  useOutsideAlerter(dropdownRef, () => {
    if (keepOpen) setKeepOpen(false);
    if (addingOkr) setAddingOkr(false);
  });

  const dispatch = useDispatch();
  const refetchQueries: (string | QueryOptions)[] = [
    namedOperations.Query.getKpiModalBaseKpi,
    namedOperations.Query.SubObjectives,
    ...getAlignedObjectivesTabsQueries(kpiId),
  ];

  const [updateObjective] = useUpsertObjectiveMutationMutation({
    refetchQueries,
  });

  const handleAlignNewObjective = () => {
    dispatch(
      showModal(MODAL_TYPES.ADD_OBJECTIVE, {
        kpiId,
        refetchQueries,
        source: "kpiModal",
      })
    );
  };

  const handleAddExistingObjective = async (childId: string) => {
    const response = await updateObjective({
      refetchQueries,
      variables: {
        input: {
          id: childId,
          kpi: kpiId,
          parent: null,
          strategicPillar: null,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertObjective?.errors
    );
    if (hasError) return;

    toast.success(intl.formatMessage(messages.objectiveAligned));
    setAddingOkr(false);
    setKeepOpen(false);
  };

  return (
    <Menu
      ref={dropdownRef}
      data-cy="jr-6izolKsk0SbEAn6_xQ"
      as="div"
      className="relative"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <Dropdown.Trigger>
        <Button
          onClick={(event) => {
            event.stopPropagation();
          }}
          data-cy="addAlignedObjective"
          disabled={disabled}
          icon="add"
          iconSize="base"
        >
          <FormattedMessage defaultMessage="Add" id="2/2yg+" />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content static={keepOpen} placement="bottom-right">
        <Show
          when={addingOkr}
          fallback={
            <>
              <Dropdown.Item
                key="addAlignNewObjective"
                data-cy="add-aligned-objective"
                onClick={handleAlignNewObjective}
              >
                <div className="flex items-center space-x-2">
                  <div>
                    <Icon name="flag" type="outlined" size="lg" />
                  </div>
                  <div>
                    <FormattedMessage
                      defaultMessage="New Aligned Objective"
                      id="XVv483"
                    />
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                key="alignExistingObjective"
                data-cy="align-existing-objective"
                onClick={() => {
                  setKeepOpen(true);
                  setAddingOkr(true);
                }}
              >
                <div className="flex items-center space-x-2">
                  <EntityIcon
                    className="text-slate-800"
                    entity={progressTypes.alignedObjective}
                  />

                  <div>
                    <FormattedMessage
                      defaultMessage="Align existing Objective"
                      id="objectives:modal:summary:alignExisting"
                    />
                  </div>
                </div>
              </Dropdown.Item>
            </>
          }
        >
          <ObjectivePicker onChange={handleAddExistingObjective} />
        </Show>
      </Dropdown.Content>
    </Menu>
  );
};
