import { useEffect } from "react";
import { trackPage } from "../../utils/tracker";

export type UsePageTrackHook = void;

export const usePageTrack = (
  title: string,
  props?: { [key: string]: string | undefined }
): UsePageTrackHook => {
  useEffect(() => {
    trackPage(title, props);
  }, [title, props]);
};
