import { useGetCompanyQuery } from "@graphql";
import classNames from "clsx";
import { isNil } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons/Button/Button";
import { useUpgradeAction, useUpgradeText } from "common/inAppUpgrade/hooks";
import { PremiumBadge } from "common/inAppUpgrade/PremiumBadge/PremiumBadge";
import { useCompany } from "hooks/useCompany/useCompany";
import { featureTiers } from "modules/company/Company/Company.constants";

export const UpgradeIndicator: React.FC = () => {
  const onUpgrade = useUpgradeAction();
  const upgradeText = useUpgradeText();
  const { data } = useGetCompanyQuery();
  const { isOnFreeOrTrialPlan } = useCompany();

  const showTrialBanner =
    data?.currentCompany.featureTier === featureTiers.TRIAL;
  const daysLeftInTrial = data?.currentCompany.daysLeftInTrial ?? 0;

  if (!isOnFreeOrTrialPlan) {
    return null;
  }

  // 2022-05-17: having this as a separate sub-component fixes
  // rendering blank badge in Safari, which is an issue on its own
  const BadgeWithText = () => (
    <div className="flex items-center gap-1 text-sm">
      <PremiumBadge size="small" />
      {upgradeText}
    </div>
  );

  return (
    <div
      className={classNames({
        "bg-blue-50": showTrialBanner && daysLeftInTrial > 3,
        "bg-yellow-100": showTrialBanner && daysLeftInTrial <= 3,
        "flex items-center": true,
        "h-11 rounded p-2": showTrialBanner,
      })}
    >
      {showTrialBanner && (
        <div className="mx-2 text-blue-800" data-cy="daysInTrial">
          <FormattedMessage
            defaultMessage="{daysLeftInTrial} trial days left"
            id="premium:trial:indicator"
            tagName="span"
            values={{
              daysLeftInTrial,
            }}
          />
        </div>
      )}

      <Button
        data-cy="goPremiumButton"
        disabled={isNil(onUpgrade)}
        loading={isNil(onUpgrade)}
        onClick={onUpgrade}
      >
        <BadgeWithText />
      </Button>
    </div>
  );
};
