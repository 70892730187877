import { usePasswordConfirmationMutation } from "@graphql";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useToggle } from "hooks/useToggle/useToggle";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

export const usePasswordConfirmation = () => {
  const intl = useIntl();
  const [
    showConfirmPassword,
    toggleShowConfirmPassword,
    setShowConfirmPassword,
  ] = useToggle(false);
  const [loading, setLoading] = useState(false);
  const [checkUserPassword] = usePasswordConfirmationMutation();

  const checkPassword = async (password: string) => {
    setLoading(true);
    const response = await checkUserPassword({
      variables: { password: password },
    });

    setLoading(false);

    if (handleErrors(response).hasError) return false;

    if (!response.data?.userPasswordConfirmation?.ok) {
      toast.failure(
        intl.formatMessage({
          defaultMessage: "Incorrect Password",
          id: "+oqz0O",
        })
      );
      return false;
    }

    return true;
  };

  return {
    checkPassword,
    loading,
    setShowConfirmPassword,
    showConfirmPassword,
    toggleShowConfirmPassword,
  };
};
