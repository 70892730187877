import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { GROWTH_BOARD_NAVIGATION } from "constants/tracking";
import { useKpiStartingMonth } from "hooks/useKpiStartingMonth/useKpiStartingMonth";
import { track } from "utils/tracker";

export type KpiNavigation = ReturnType<typeof useKpiNavigation>;

export const useKpiNavigation = () => {
  const startingMonth = useKpiStartingMonth();
  const defaultStartDate = useMemo(() => {
    let date = dayjs
      .utc()
      .month(startingMonth - 1)
      .startOf("month");
    if (date.isAfter(dayjs.utc())) date = date.subtract(1, "year");
    return date;
  }, [startingMonth]);

  const [startDate, setStartDate] = useState(defaultStartDate);
  useEffect(() => {
    setStartDate(defaultStartDate);
  }, [setStartDate, defaultStartDate]);

  const navigateQuarters = (offset: number) => {
    const newStartDate = startDate.add(offset, "quarter");
    setStartDate(newStartDate);
    const trackingType = Math.abs(offset) === 4 ? "year" : "quarter";
    track(GROWTH_BOARD_NAVIGATION, {
      type: trackingType,
    });
  };

  const reset = () => {
    setStartDate(defaultStartDate);
    track(GROWTH_BOARD_NAVIGATION, {
      type: "today",
    });
  };

  const isDefaultDate = startDate.isSame(defaultStartDate);

  return { isDefaultDate, navigateQuarters, reset, startDate };
};
