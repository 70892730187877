import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { logToSentry } from "../../../utils/tracker";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const {
  InitiativeCreated,
  InitiativeChanged,
  InitiativeRelationAdded,
  InitiativeRelationRemoved,
  InitiativeDeleted,
} = EventTypes;

export const InitiativeEventFormatters: Record<string, EventFormatter> = {
  [InitiativeChanged]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      // const id = `${InitiativeChanged}_FORMAT_${extra.change.attribute}`;
      let formattedEvent;

      switch (extra.change.attribute) {
        case "name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="renamed {oldName} to {newName}"
              id="event:formatter:initiative:renamed"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "lead": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the lead from {oldName} to {newName}"
              id="event:formatter:keyResult:lead"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "objective": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the Objective from {oldName} to {newName}"
              id="event:formatter:initiative:objchanged"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "key_result": {
          if (extra.change.new_value === "None") {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="removed alignment of Initiative {iName}"
                id="event:formatter:initiative:krremoved"
                values={{
                  iName: <Target element={extra.name} />,
                }}
              />
            );
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="aligned the initiative {iName} to the Key Result {krName}"
                id="event:formatter:initiative:kradded"
                values={{
                  iName: <Target element={extra.name} />,
                  krName: <Target element={extra.change.new_value} />,
                }}
              />
            );
          }

          break;
        }
        case "archived_date": {
          const newVal = extra.change.new_value;

          if (newVal !== "None") {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="archived the initiative {initName}"
                id="event:formatter:initiative:archived"
                values={{
                  initName: <Target element={extra.name} />,
                }}
              />
            );
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="restored the initiative {initName}"
                id="event:formatter:initiative:unarchived"
                values={{
                  initName: <Target element={extra.name} />,
                }}
              />
            );
          }

          break;
        }
        case "end_value": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the end value of {krName} from {oldVal} to {newVal}"
              id="event:formatter:initiative:changedEnd"
              values={{
                krName: <Target element={extra.name} />,
                newVal: (
                  <Target>
                    <FormattedNumber value={extra.change.new_value} />
                  </Target>
                ),
                oldVal: (
                  <Target>
                    <FormattedNumber value={extra.change.old_value} />
                  </Target>
                ),
              }}
            />
          );
          break;
        }
        default: {
          formattedEvent = null;
          break;
        }
      }

      return formattedEvent;
    },
  },
  [InitiativeCreated]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      return (
        <FormattedMessage
          defaultMessage="created the Initiative {name}"
          id="event:formatter:initiative:created"
          values={{
            name: <Target element={extra.name} />,
          }}
        />
      );
    },
  },
  [InitiativeDeleted]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      return (
        <FormattedMessage
          defaultMessage="deleted the Initiative {name}"
          id="event:formatter:initiative:deleted"
          values={{
            name: <Target element={extra.name} />,
          }}
        />
      );
    },
  },
  [InitiativeRelationAdded]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "contributors": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="added {contrCount} contributor(s) to the Initiative {iName}"
              id="event:formatter:initiative:contributorsAdded"
              values={{
                contrCount: <Target element={extra.relation.pk_set.length} />,
                iName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: InitiativeRelationAdded,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
  [InitiativeRelationRemoved]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "contributors": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="removed {contrCount} contributor(s) from the Initiative {iName}"
              id="event:formatter:initiative:contributorsRemoved"
              values={{
                contrCount: <Target element={extra.relation.pk_set.length} />,
                iName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: InitiativeRelationRemoved,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
};
