import { autoPlacement, autoUpdate, useFloating } from "@floating-ui/react-dom";
import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, ReactNode, useEffect } from "react";
import { Portal } from "common/layout/Portal/Portal";
import { twClass } from "utils/twClass";
import { useSelectContext } from "../Select.context";

export type Props = {
  children: ReactNode;
  className?: string;
};

export const SelectDropdown = ({
  children,
  className,
}: Props): JSX.Element | null => {
  const { isOpen, searchRef, valueRef } = useSelectContext();

  // headless UI doesn't support portal positioning, so we are using floating UI, which works by passing callback refs.
  // we are using a portal so the menu pops out correctly regardless of container.
  const { floatingStyles, refs } = useFloating({
    elements: {
      reference: valueRef,
    },
    middleware: [autoPlacement({ allowedPlacements: ["bottom", "top"] })],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    if (isOpen) searchRef?.focus();
  }, [isOpen, searchRef]);

  if (!isOpen) return null;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    // Select would lose focus when pressing escape, so we simulate clicking the value to close + maintain focus
    if (e.key === "Escape") {
      valueRef?.click();
    }

    // workaround for https://github.com/tailwindlabs/headlessui/issues/2475
    if (e.key === "Tab") {
      searchRef?.focus();
    }
  };

  return (
    <Portal container="#select-dropdown-portal">
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          ref={refs.setFloating}
          className={twClass(
            // can be used inside interactive tooltips so should have higher z-index
            "absolute z-tooltip-prio",
            "min-w-full max-w-2xl",
            "mt-1.5",
            "focus:outline-none",
            "rounded-md shadow-lg ring-1 ring-black ring-opacity-5",
            "flex flex-col bg-white",
            className
          )}
          data-cy="selectDropdown"
          data-testid="selectDropdown"
          onKeyDown={handleKeyDown}
          role="listbox"
          static
          style={{
            ...floatingStyles,
            maxWidth: valueRef?.scrollWidth,
            minWidth: valueRef?.scrollWidth,
          }}
        >
          {children}
        </Listbox.Options>
      </Transition>
    </Portal>
  );
};
