import React from "react";
import { TextButton } from "common/buttons";
import { ListItem } from "common/misc/ListItem/ListItem";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { SupportedCurrencies } from "../UpgradeModal.types";

type Props = {
  onChange: (value: SupportedCurrencies) => void;
  value: SupportedCurrencies;
};

const pricingOptions: SupportedCurrencies[] = ["EUR", "USD"];

export const CurrencySelector = ({ value, onChange }: Props): JSX.Element => {
  return (
    <Dropdown
      button={
        <TextButton
          className="pr-0 text-sm"
          isDropdown
          size="small"
          text={value}
        />
      }
    >
      {pricingOptions.map((option) => (
        <ListItem
          data-cy="UtYd0h7smAh13N7ycoLgg"
          key={option}
          onClick={() => onChange(option)}
          selected={value === option}
        >
          {option}
        </ListItem>
      ))}
    </Dropdown>
  );
};
