import React, { forwardRef, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  name: string;
  setFocusedField: (fieldName: string) => void;
}

export const FieldFocusWrapper = forwardRef(
  (
    { setFocusedField, children, name, className }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const onFieldFocus = (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation();
      setFocusedField(name);
    };

    return (
      <div
        data-cy="KarUBoV47LT0vq9woep8a"
        ref={ref}
        className={className}
        onClick={onFieldFocus}
        onMouseEnter={onFieldFocus}
      >
        {children}
      </div>
    );
  }
);
