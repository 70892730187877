import React from "react";
import styled from "styled-components";
import { Avatar } from "common/avatar";
import { Icon } from "common/icons/Icon/Icon";
import { colorTheme } from "constants/colorTheme";
import * as Typography from "constants/typography";
import { FilterItem, FilterValue } from "legacy/components/filters/types";
import { SubGroupsIndicator } from "modules/mapsv3/Roadmap/SubGroups/SubGroupsIndicator/SubGroupsIndicator";

const RemoveButton = styled("button")`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(
    -90deg,
    ${colorTheme.slate[300]} 75%,
    transparent 100%
  );
  max-width: 30px;
  line-height: 1.2;
  border: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
`;

const Container = styled("div")`
  background: ${colorTheme.slate[300]};
  font-size: ${Typography.fontSizeSmall};
  padding: 2px 8px;
  border-radius: 11px;
  margin: 0 4px;
  position: relative;
  overflow: hidden;
  &:hover ${RemoveButton} {
    opacity: 1;
  }
  display: flex;
  align-items: center;
  max-width: 200px;
  span {
    margin-right: 2px;
    line-height: 1;
  }
`;

type FilterSearchProps = {
  filter: FilterItem;
};
const FilterSearch = ({ filter }: FilterSearchProps) => {
  const { avatar, fullName, name, subGroups } = filter;
  return (
    <div className="flex max-w-full items-center space-x-1">
      {avatar && <Avatar size="extraSmall" tooltip={fullName} url={avatar} />}
      <div className="truncate">{name ?? fullName}</div>
      <SubGroupsIndicator parentGroup={name ?? ""} subGroups={subGroups} />
    </div>
  );
};

const FilterRange = styled.div`
  min-width: 78px;
  text-align: center;
`;

type SelectedFilterProps = {
  children: React.ReactNode;
  className?: string;
  onRemove?: () => void;
};

export const SelectedFilter = ({
  className,
  onRemove,
  children,
}: SelectedFilterProps): JSX.Element => {
  return (
    <Container className={className}>
      {children}
      {onRemove && (
        <RemoveButton
          data-cy="buttonFilterRemove"
          onClick={onRemove}
          type="button"
        >
          <Icon name="close" size="base" />
        </RemoveButton>
      )}
    </Container>
  );
};

type FilterSelectedProps = {
  className?: string;
  columnValue: FilterValue;
  onRemove: (filter: FilterItem) => void;
};
export const FilterSelected = ({
  className,
  onRemove,
  columnValue: { type, selected, ...rest },
}: FilterSelectedProps): JSX.Element => {
  if (
    type === "search" ||
    type === "searchTags" ||
    type === "alignedToSelect"
  ) {
    return (
      <>
        {selected.map((filter) => (
          <SelectedFilter
            key={filter.id}
            className={className}
            onRemove={() => onRemove(filter)}
          >
            <FilterSearch filter={filter} />
          </SelectedFilter>
        ))}
      </>
    );
  }
  if (type === "range") {
    return (
      <SelectedFilter onRemove={() => onRemove({ selected, type, ...rest })}>
        <FilterRange>
          <>
            {selected[0]}% -{selected[1]}%
          </>
        </FilterRange>
      </SelectedFilter>
    );
  }

  return (
    <>
      {selected.map((filter) => (
        <SelectedFilter key={filter.id} onRemove={() => onRemove(filter)}>
          {filter.name}
        </SelectedFilter>
      ))}
    </>
  );
};
