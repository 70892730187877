import { Menu } from "@headlessui/react";
import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";

export type DropdownContentPlacement = "bottom-left" | "bottom-right";

type DropdownContentProps = {
  children: ReactNode;
  className?: string;
  "data-testid"?: string;
  placement?: DropdownContentPlacement;
  static?: boolean;
};

export const DropdownContent = ({
  children,
  className,
  placement = "bottom-left",
  static: isStatic,
  "data-testid": dataTestId,
}: DropdownContentProps): JSX.Element => (
  <Menu.Items
    className={twClass(
      "absolute z-tooltip mt-2 min-w-48 rounded border py-1 drop-shadow bg-white focus:outline-none",
      {
        "left-0": placement === "bottom-left",
        "right-0": placement === "bottom-right",
      },
      className
    )}
    static={isStatic}
    data-testid={dataTestId}
  >
    {children}
  </Menu.Items>
);
