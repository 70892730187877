import React from "react";
import { FormattedMessage } from "react-intl";
import { logToSentry } from "../../../utils/tracker";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const { GroupChanged, GroupCreated } = EventTypes;

export const GroupEventFormatters: Record<string, EventFormatter> = {
  [GroupChanged]: {
    getComment: () => null,
    getString: (attr): JSX.Element => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.change.attribute) {
        case "avatar": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the avatar of {groupName}"
              id="event:formatter:group:changedAvatar"
              values={{
                groupName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="renamed {oldName} to {newName}"
              id="event:formatter:group:renamed"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: GroupChanged,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
        }
      }

      return formattedEvent;
    },
  },
  [GroupCreated]: {
    getComment: () => null,
    getString: (attr): JSX.Element => (
      <FormattedMessage
        defaultMessage="created {groupName}"
        id="event:formatter:group:created"
        values={{
          groupName: <Target element={attr.extra.name} />,
        }}
      />
    ),
  },
};
