import { IconSize } from "common/misc/types";
import { twClass } from "utils/twClass";
import { Icon } from "./Icon/Icon";

type ExpandIconProps = {
  "aria-label"?: string;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  expanded: boolean;
  size?: keyof typeof IconSize;
};

export const ExpandIcon = ({
  expanded,
  size,
  className,
  "data-testid": dataTestid,
  "data-cy": dataCy,
  "aria-label": ariaLabel,
}: ExpandIconProps) => {
  return (
    <Icon
      name="expand_more"
      className={twClass(
        "transition-transform",
        { "-rotate-90": !expanded },
        className
      )}
      aria-label={ariaLabel}
      size={size}
      data-testid={dataTestid}
      data-cy={dataCy}
    />
  );
};
