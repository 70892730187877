import {
  createHttpLink,
  FetchResult,
  fromPromise,
  InMemoryCache,
  Observable,
  Operation,
} from "@apollo/client";
import { ApolloClient, ApolloLink } from "@apollo/client/core";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import fetch from "cross-fetch";
import Router from "next/router";
import { routes } from "route-configs";
import { ReleaseContextRef } from "common/alerts/ReleaseAlert/ReleaseContextRef/ReleaseContextRef";
import { isProtectedRoute } from "common/topLevel/useIsProtectedRoute/useIsProtectedRoute";
import { logToSentry } from "utils/tracker";
import { auth } from "./common/authHelper";
import { API_URL, IS_DEVELOPMENT, RELEASE } from "./config";
import { toast } from "./utils/toastr";

const GRAPHQL_URL = `${API_URL}/graphql/`;

if (IS_DEVELOPMENT) {
  loadDevMessages();
  loadErrorMessages();
}

const refreshTokenAndRetry = (
  operation: Operation,
  forward: (operation: Operation) => Observable<FetchResult>
) =>
  fromPromise(
    auth
      .fetchNewToken()
      .then((token) => token)
      .catch(() => {
        auth.logout({
          reason:
            "unable to refresh token (apolloClient - refreshTokenAndRetry)",
        });
        return undefined;
      })
  )
    .filter((value) => !!value)
    .flatMap((token) => {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          authorization: token,
        },
      });

      return forward(operation);
    });

const errorLink = onError(
  ({ forward, graphQLErrors, networkError, operation }) => {
    const isUnauthorized = graphQLErrors?.some(
      ({ message }) => message === "Unauthorized."
    );
    if (isUnauthorized && isProtectedRoute(Router.asPath)) {
      return refreshTokenAndRetry(operation, forward);
    }

    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        logToSentry(`${message} - Location: ${locations}, Path: ${path}`);
        // eslint-disable-next-line no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });

    if (networkError != null && "statusCode" in networkError) {
      // Global error handling
      if (
        networkError.statusCode === 401 &&
        !Router.asPath.startsWith(routes.login.main)
      ) {
        auth.logout({
          reason: "unauthorized graphql response (apolloClient - errorLink)",
        });
      } else if (networkError.statusCode === 403) {
        toast.failure("You do not have permission to perform this action");
      } else if (networkError.statusCode === 500) {
        toast.info("Server error. Something went wrong!");
      }
    }
  }
);

const releaseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    if (headers) {
      const incomingVersion = headers.get("x-version");
      if (incomingVersion !== RELEASE) {
        ReleaseContextRef.current?.setShowBanner(true);
      } else if (ReleaseContextRef.current?.showBanner) {
        ReleaseContextRef.current.setShowBanner(false);
      }
    }
    return response;
  });
});

const httpLink = createHttpLink({
  fetch,
  uri: GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = auth.getAccessToken();

  return {
    headers: {
      ...headers,
      ...(token && { authorization: token }),
    },
  };
});

// allows single entities to be cached after been fetched by lists
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'typename' implicitly has an 'any' type.
const cacheSingleEntity = (typename) => {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter '_' implicitly has an 'any' type.
  return (_, { args, toReference }) =>
    toReference({
      __typename: typename,
      id: args.id,
    });
};

const link = ApolloLink.from([authLink, errorLink, releaseLink, httpLink]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          group: cacheSingleEntity("group"),
          kpi: cacheSingleEntity("kpi"),
          objective: cacheSingleEntity("objective"),
          result: cacheSingleEntity("keyResult"),
          timeframe: cacheSingleEntity("timeframe"),
        },
      },
    },
  }),
  connectToDevTools: true,

  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
  },
  link,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'string | stri... Remove this comment to see the full error message
  typeDefs: {},
});
