import { FetchMoreQueryOptions } from "@apollo/client";
import { Maybe, TimelineEventFragmentFragment } from "@graphql";
import { createContext } from "react";
import { defineMessages } from "react-intl";

export const timelineContextMessages = defineMessages({
  everything: {
    defaultMessage: "Everything",
    description: "Describes an option in the activity feed filter dropdown",
    id: "activity:everything",
  },
  notesOnly: {
    defaultMessage: "Notes only",
    id: "activity:notesOnly",
  },
  updateAndNotes: {
    defaultMessage: "Progress updates & notes",
    id: "activity:progressUpdatesAndNotes",
  },
  updatesOnly: {
    defaultMessage: "Progress Updates only",
    id: "activity:progressUpdatesOnly",
  },
});

export type TimelineFilter = {
  id: number;
  name: string;
  value?: string;
};

export type TimelineBag = {
  fetchMore: (options: FetchMoreQueryOptions<unknown>) => void;
  filterOptions?: TimelineFilter[];
  setTimelineFilter: (filter: TimelineFilter) => void;
  timelineData:
    | Maybe<
        NodeConnection<TimelineEventFragmentFragment> & {
          pageInfo: Partial<PageInfo>;
        }
      >
    | undefined;
  timelineFilter?: TimelineFilter;
  timelineLoading: boolean;
  updateTimeline: (props?: any) => void;
};

export const TimelineContext = createContext<TimelineBag>({
  fetchMore: () => undefined,
  filterOptions: undefined,
  setTimelineFilter: () => undefined,
  timelineData: undefined,
  timelineFilter: undefined,
  timelineLoading: false,
  updateTimeline: () => undefined,
});
