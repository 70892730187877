import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import React from "react";

type Emoji = {
  id: string;
  keywords: string[];
  name: string;
  skins: Record<string, string>[];
};

export type CustomEmojis = {
  emojis: Emoji[];
  id: string;
  name: string;
} | null;

interface Props {
  customEmojis?: CustomEmojis;
  onSelect: (emoji: any) => void;
}

export const EmojiPicker = ({ customEmojis, onSelect }: Props): JSX.Element => {
  return (
    <Picker
      autoFocus
      custom={[customEmojis]}
      data={data}
      onEmojiSelect={onSelect}
    />
  );
};
