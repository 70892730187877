import { isFunction, isUndefined } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useLocalStorage<T>(
  key: string
): readonly [T | undefined, (value: T | undefined) => void];

export function useLocalStorage<T>(
  key: string,
  initialValue: T | (() => T)
): readonly [T, Dispatch<SetStateAction<T>>];

export function useLocalStorage<T>(
  key: string,
  initialValue?: T | (() => T)
): readonly [T | undefined, Dispatch<SetStateAction<T | undefined>>] {
  const readLocalStorageValue = (): T | undefined => {
    const getInitialValue = () =>
      isFunction(initialValue) ? initialValue() : initialValue;

    if (typeof window === "undefined") {
      return getInitialValue();
    }

    try {
      const item = window.localStorage.getItem(key);

      if (!item) {
        const valueToStore = getInitialValue();
        if (!isUndefined(valueToStore)) {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
        return valueToStore;
      }

      return JSON.parse(item);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading localStorage key "${key}":`, error);
      return getInitialValue();
    }
  };

  const [storedValue, setStoredValue] = useState<T | undefined>(
    readLocalStorageValue
  );
  useEffect(() => {
    const newStoredValue = readLocalStorageValue();
    setStoredValue(newStoredValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const setValue: Dispatch<SetStateAction<T | undefined>> = (value) => {
    setStoredValue((currentStoredValue) => {
      const valueToStore = isFunction(value)
        ? value(currentStoredValue)
        : value;

      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        window.dispatchEvent(new Event(`${key}-local-storage-updated`));
      }

      return valueToStore;
    });
  };

  return [storedValue, setValue] as const;
}
