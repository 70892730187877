import { extendTailwindMerge } from "tailwind-merge";

/**
 * note: this shouldn't be used directly, instead use {@link twClass} which combines tailwind-merge and clsx.
 * @see https://github.com/dcastil/tailwind-merge/blob/v1.9.1/docs/configuration.md#extending-the-tailwind-merge-config
 * @see https://github.com/dcastil/tailwind-merge/blob/v1.14.0/src/lib/default-config.ts#L619
 */
export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [{ text: ["xxs"] }],
      z: [
        {
          z: [
            "lower",
            "base",
            "higher",
            "modal-backdrop",
            "modal",
            "modal-prio-backdrop",
            "modal-prio",
            "tooltip",
            "modal-top-prio-backdrop",
            "modal-top-prio",
            "tooltip-prio",
            "top-prio",
            "maximum",
          ],
        },
      ],
    },
  },
});
