import { useGetCompanyFeaturesQuery } from "@graphql";
import { isEmpty } from "lodash";
import { StackedAvatar } from "common/avatar";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { CommentTooltip } from "common/comment/CommentTooltip/CommentTooltip";
import { ObjectiveType } from "common/context/objectiveContext";
import { For, Show } from "common/controlFlow";
import { GoalOwners } from "common/goal/GoalOwners/GoalOwners";
import { useSharedOwners } from "common/goal/GoalOwners/GoalOwners.utils";
import { SemiCircleProgress } from "common/misc/SemiCircleProgress/SemiCircleProgress";
import { AnchorNext } from "common/navigation";
import { ObjectiveStatus } from "common/objective/ObjectiveStatus/ObjectiveStatus";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { ProgressDelta } from "common/progress/ProgressDelta/ProgressDelta";
import { Tag } from "common/tag/Tag/Tag";
import { MetricUnits } from "constants/metric";
import { objectTypes } from "constants/objects";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";
import { objectiveStages } from "../constants";
import { OKROverload } from "../OKROverload/OKROverload";
import { ObjectiveSubtext } from "./ObjectiveSubtext/ObjectiveSubtext";
import { PrivateObjectiveLockButton } from "./PrivateObjectiveLockButton/PrivateObjectiveLockButton";

export interface ObjectiveProps {
  actions?: JSX.Element[] | JSX.Element | null;
  objective: ObjectiveType;
  showGroups?: boolean;
}

export const Objective = ({
  objective,
  actions = [],
  showGroups,
  ...other
}: ObjectiveProps): JSX.Element => {
  const inTeams = useInTeams();
  const [privateMode] = usePrivateMode();
  const { objectiveHref } = useModalRouter();
  const objectiveLink = objectiveHref({ oId: objective.id });

  const { data } = useGetCompanyFeaturesQuery();
  const {
    groups,
    isCompanyGoal,
    lastComment,
    name,
    progress,
    progressDriver,
    progressDelta,
    stage,
    tags,
  } = objective;

  const displayProgressDriver = data?.currentCompany
    .flexibleObjectiveProgressDriver
    ? progressDriver
    : undefined;

  const sharedGroups = useSharedOwners(isCompanyGoal, groups);
  const closed = stage === objectiveStages.closed;
  const krCount = objective.keyResults?.edgeCount || 0;

  return (
    <div
      data-testid="objective"
      className={twClass("group relative", {
        // this is just a fallback, should be actually removed from the DOM wherever it's rendered
        "!hidden": privateMode && objective.private,
      })}
    >
      <Show when={objective.private && objective.privateToUsers}>
        <div className="absolute z-10 -left-2 -top-1">
          <PrivateObjectiveLockButton
            users={mapEdges(objective.privateToUsers?.edges)}
          />
        </div>
      </Show>
      <div className="pl-6 flex w-full items-center" {...other}>
        <div className="mt-4 w-full">
          <div
            className={twClass(
              "block sm:flex",
              "mb-3.5 items-center leading-5",
              "justify-center",
              "gap-x-2 gap-y-5"
            )}
          >
            <div
              className="flex grow items-center"
              data-testid="objectiveHeader"
            >
              <div>
                <div className="flex">
                  <AnchorNext
                    className={twClass(
                      "pointer-events-none cursor-pointer sm:pointer-events-auto",
                      "mr-1.5",
                      "font-semibold text-slate-800 hover:text-blue-500",
                      { "text-slate-500": closed }
                    )}
                    data-cy="objectiveTitle"
                    data-fs="hide"
                    data-testid="objectiveTitle"
                    href={objectiveLink}
                  >
                    {name}
                  </AnchorNext>
                  <div className="flex flex-wrap">
                    <For each={mapEdges(tags.edges)}>
                      {(tag) => (
                        <div key={tag.id} className="relative ml-2.5 inline">
                          <Tag key={tag.id} color={tag.color} text={tag.name} />
                        </div>
                      )}
                    </For>
                  </div>
                </div>
                {krCount > 5 && <OKROverload type={objectTypes.objective} />}
                <div
                  className={twClass(
                    "hidden md:flex md:flex-grow md:flex-wrap",
                    { "md:!hidden": inTeams },
                    "mt-1.5 gap-x-4 gap-y-2",
                    "text-sm text-slate-500"
                  )}
                >
                  <ObjectiveSubtext objective={objective} />
                </div>
              </div>
            </div>
            <div className="w-fit">
              {(sharedGroups.length > 1 || showGroups) && (
                <Tooltip
                  content={
                    <GoalOwners
                      className="p-0 text-white hover:bg-transparent"
                      goalId={objective.id}
                      goalType="objective"
                      isCompanyGoal={objective.isCompanyGoal}
                      maxCount={2}
                      owners={groups}
                    />
                  }
                  interactive
                  maxWidth="450px"
                >
                  <StackedAvatar
                    maxCount={2}
                    size="medium"
                    urls={sharedGroups.map((group) => group.avatar as string)}
                  />
                </Tooltip>
              )}
            </div>
            <div className="flex items-center justify-between space-x-4">
              <div className="hidden md:flex md:justify-end">
                <ObjectiveStatus
                  className="whitespace-nowrap text-sm text-slate-800"
                  objective={objective}
                />
              </div>
              <div className="hidden justify-items-start md:flex md:justify-end">
                <Tooltip
                  content={
                    isSet(lastComment) ? (
                      <CommentTooltip
                        comment={lastComment}
                        progressDriver={displayProgressDriver}
                      />
                    ) : undefined
                  }
                  theme="perdoo-light"
                >
                  <SemiCircleProgress className="mx-auto" progress={progress} />
                </Tooltip>

                {!closed && !!progressDelta && (
                  <ProgressDelta
                    metric={MetricUnits.PERCENTAGE}
                    small
                    value={progressDelta}
                  />
                )}
              </div>
              <div className="my-4 flex md:hidden">
                <ObjectiveStatus
                  className="text-sm text-slate-500"
                  objective={objective}
                />
                <div
                  className="ml-5 flex justify-items-start"
                  style={{ width: 110 }}
                >
                  <SemiCircleProgress progress={progress} />
                  {!closed && !!progressDelta && (
                    <ProgressDelta
                      metric={MetricUnits.PERCENTAGE}
                      small
                      value={progressDelta}
                    />
                  )}
                </div>
              </div>
              <Show when={!isEmpty(actions) && !inTeams}>
                <div className="hidden md:block">
                  <Dropdown
                    button={
                      <IconButton
                        className="invisible hover:text-blue-500 group-hover:visible"
                        data-cy="objectiveKebabButton"
                        name="more_vert"
                      />
                    }
                  >
                    {actions}
                  </Dropdown>
                </div>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
