import React from "react";
import { Toaster } from "react-hot-toast";
import { colorTheme } from "constants/colorTheme";

/**
 * container for the toast messages provided by react-hot-toast. used for notifications about success / error state.
 */
export const ToasterContainer = (): JSX.Element => {
  return (
    <Toaster
      containerClassName="toaster-container"
      containerStyle={{
        zIndex: 99999999, // For the container
      }}
      position="bottom-left"
      toastOptions={{
        error: {
          iconTheme: {
            primary: colorTheme.red[500],
            secondary: "#fff",
          },
        },
        style: {
          zIndex: 99999999, // For toasts
        },
        success: {
          iconTheme: {
            primary: colorTheme.green[500],
            secondary: "#fff",
          },
        },
      }}
    />
  );
};
