import { FormattedMessage } from "react-intl";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { Subtext } from "common/misc/Subtext/Subtext";
import { isSet } from "utils/isSet";

interface Props {
  lastCommitDate?: string | null;
}

export const GoalUpdatedDate = ({ lastCommitDate }: Props): JSX.Element => {
  const text = isSet(lastCommitDate) ? (
    <FormattedMessage
      defaultMessage="Updated on {date}"
      values={{
        date: <FormattedDate value={lastCommitDate} />,
      }}
      id="N3Jd7a"
    />
  ) : (
    <FormattedMessage
      defaultMessage="Never updated"
      id="global:commit:neverUpdated"
    />
  );

  return <Subtext text={text} />;
};
