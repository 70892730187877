import { aOneOnOneMeeting } from "mocks/factories/oneOnOne";

export const oneOneOneMeetingPlaceholder = aOneOnOneMeeting({
  attendeeNote: undefined,
  attendeePrivateNote: undefined,
  completedDate: undefined,
  dueDate: undefined,
  id: "oneOnOneMeetingPlaceholder",
  isCancelled: undefined,
  isComplete: undefined,
  lastEditedDate: undefined,
  organizerNote: undefined,
  organizerPrivateNote: undefined,
  scheduleDate: undefined,
});
