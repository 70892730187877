import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons";
import { RadioSelect } from "common/inputs/RadioSelect/RadioSelect";
import { resultTypes } from "constants/resultTypes";

type SelectProps = {
  onClick: (krId: string) => void;
  selected?: string;
};

export const KeyResultRadioSelect = ({
  selected,
  onClick,
}: SelectProps): JSX.Element => {
  const content = (
    <div data-testid="keyResultType">
      <div>
        <FormattedMessage defaultMessage="Key Result" id="global:keyResult" />
      </div>
      <div className="text-sm text-slate-500">
        <FormattedMessage
          defaultMessage="An outcome that measures the objective's progress"
          id="result:radio:keyresult:details"
        />
      </div>
    </div>
  );

  const addOn = (
    <Icon className="text-blue-500" name="trending_up" size="2xl" />
  );

  return (
    <RadioSelect
      addOn={addOn}
      data-cy="type-keyResult"
      onClick={() => onClick(resultTypes.keyResult)}
      selected={selected === resultTypes.keyResult}
    >
      {content}
    </RadioSelect>
  );
};

export const InitiativeRadioSelect = ({
  selected,
  onClick,
}: SelectProps): JSX.Element => {
  const content = (
    <div data-testid="initiativeType">
      <div>
        <FormattedMessage defaultMessage="Initiative" id="global:initiative" />
      </div>
      <div className="text-sm text-slate-500">
        <FormattedMessage
          defaultMessage="Work to be done to achieve your Key Results, but won’t update their progress"
          id="result:radio:initiative:details"
        />
      </div>
    </div>
  );

  const addOn = (
    <Icon className="text-green-500" name="playlist_add_check" size="2xl" />
  );

  return (
    <RadioSelect
      addOn={addOn}
      data-cy="type-initiative"
      onClick={() => onClick(resultTypes.initiative)}
      selected={selected === resultTypes.initiative}
    >
      {content}
    </RadioSelect>
  );
};
