import { HELP_CENTER_CONTENT } from "constants/tracking";
import { track } from "utils/tracker";

type Hook = {
  trackContent: (href: string) => void;
  trackContentHandler: (href: string) => () => void;
};

export const useHelpCenterTrack = (): Hook => {
  const trackContent = (href: string) => {
    track(HELP_CENTER_CONTENT, { href });
  };
  const trackContentHandler = (href: string) => () => trackContent(href);

  return { trackContent, trackContentHandler };
};
