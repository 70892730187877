import React, { ReactElement } from "react";
import { Placement } from "tippy.js";
import { Icon } from "common/icons/Icon/Icon";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";

interface Props {
  className?: string;
  placement?: Placement;
  warnings?: ReactElement[];
}

export const WarningIcon: React.FC<Props> = ({
  className,
  placement = "top",
  warnings = [],
}) => {
  if (!warnings.length) {
    return null;
  }
  return (
    <div className={className}>
      <WithTooltip
        interactive
        placement={placement}
        tooltip={<div data-cy="warningTipContainer">{warnings}</div>}
      >
        <Icon
          className="text-red-500"
          data-cy="WarningIcon"
          name="error_outline"
          size="sm"
        />
      </WithTooltip>
    </div>
  );
};
