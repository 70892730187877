import React from "react";
import styled from "styled-components";
import { colorTheme } from "constants/colorTheme";

const StyledSpinner = styled.div<Props>`
  width: 70px;
  text-align: center;

  > div {
    width: ${({ size }) => (size === "small" ? "6px" : "13px")};
    height: ${({ size }) => (size === "small" ? "6px" : "13px")};
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    background-color: ${colorTheme.blue[500]};
  }

  .blue > div {
    background-color: ${colorTheme.blue[500]};
  }

  .grey > div {
    background-color: ${colorTheme.slate[800]};
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    margin: 0 5px;
    animation-delay: -0.16s;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

export type Props = {
  className?: string;
  size?: "large" | "medium" | "regular" | "small";
};

export const Spinner = ({ className, size = "medium" }: Props): JSX.Element => (
  <StyledSpinner className={className} data-testid="loadingSpinner" size={size}>
    <div className="bounce1" />
    <div className="bounce2" />
    <div />
  </StyledSpinner>
);
