import { defineMessages } from "react-intl";

export const messages = defineMessages({
  targetAdded: {
    defaultMessage: "KPI target added",
    id: "kpi:target:added",
  },
  targetRemoved: {
    defaultMessage: "KPI target removed",
    id: "kpi:target:removed",
  },
  targetUpdated: {
    defaultMessage: "KPI target updated",
    id: "kpi:target:updated",
  },
  valueUpdated: {
    defaultMessage: "KPI value updated",
    id: "kpi:value:updated",
  },
});
