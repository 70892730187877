import { useGetOneOnOneMeetingQuery } from "@graphql";
import dayjs from "dayjs";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { CancelButton, SaveButton } from "common/buttons";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { LocalTimeHelper } from "common/form/LocalTimeHelper";
import { TimePickerField } from "common/form/TimePickerField/TimePickerField";
import { Label } from "common/inputs";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { ONE_ON_ONE_MEETING_RESCHEDULED } from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { handleError } from "utils/utils";
import { ONE_ON_ONE_TIME_INCREMENT } from "../constants";
import { useDisableOneOnOneMeeting } from "../hooks/useDisableOneOnOneMeeting";
import { useHandleOneOnOneMutations } from "../hooks/useHandleOneOnOneMutations";
import { formatOneOnOneMeetingDueDate } from "../oneOnOnemeeting.utils";
import { getMeetingLocalTime } from "../utils";

export const rescheduleMeetingValidationSchema = Yup.object().shape({
  dueDate: Yup.date()
    .min(dayjs().add(-1, "day"), "Meeting date must be today or later.")
    .typeError("Required")
    .required("Required"),
  meetingTime: Yup.string()
    .required("Must set a meeting time")
    .typeError("Must set a meeting time"),
});

export type RescheduleValues = {
  dueDate: string | null;
  meetingTime: string | null;
};

type Props = {
  meetingId: string;
  seriesId: string;
};

export const RescheduleOneOnOneMeetingModal = ({
  meetingId,
  seriesId,
}: Props): JSX.Element | null => {
  const { close } = useModalRouter();
  const { setDisableStatus } = useDisableOneOnOneMeeting(seriesId);
  const intl = useIntl();
  const { upsertOneOnOneMeeting } = useHandleOneOnOneMutations();
  const {
    data,
    loading: meetingLoading,
    error,
  } = useGetOneOnOneMeetingQuery({
    onError: (err) => {
      handleError(err);
      close();
    },
    variables: {
      id: meetingId,
    },
  });
  const { timezone } = useCompany();

  const loading = meetingLoading;

  if (!data?.oneOnOneMeeting || error) return null;

  const {
    dueDate,
    oneOnOneSeries: { isActive },
  } = data.oneOnOneMeeting;
  const initialValues: RescheduleValues = {
    dueDate: dueDate ?? null,
    meetingTime: dueDate
      ? dayjs(dueDate).tz(timezone).format("HH:mm:ss")
      : null,
  };

  const handleSubmit = async (
    values: RescheduleValues,
    actions: FormikHelpers<RescheduleValues>
  ) => {
    actions.setSubmitting(true);
    const formattedDueDate = formatOneOnOneMeetingDueDate(
      values.dueDate,
      values.meetingTime
    );
    const { hasError } = await upsertOneOnOneMeeting({
      dueDate: formattedDueDate,
      id: meetingId,
      ...(!isActive && { scheduleDate: formattedDueDate }),
    });
    actions.setSubmitting(false);
    if (hasError) return;
    track(ONE_ON_ONE_MEETING_RESCHEDULED, {
      meetingId,
    });
    if (!isActive) {
      await setDisableStatus(true);
      close();
      return;
    }
    toast.success(
      intl.formatMessage({
        defaultMessage: "Successfully rescheduled 1:1",
        id: "GrmRKo",
      })
    );
    close();
  };

  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio mt-[15%]"
      isOpen
      loading={loading}
      onClose={close}
      size="sm"
      title={
        isActive ? (
          <FormattedMessage defaultMessage="Reschedule" id="RH5nkn" />
        ) : (
          <FormattedMessage defaultMessage="Plan a meeting" id="Ady4p4" />
        )
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={rescheduleMeetingValidationSchema}
      >
        {({ values, handleBlur, isSubmitting, submitForm }) => {
          const meetingLocalTime = getMeetingLocalTime(
            values.dueDate,
            values.meetingTime,
            timezone
          );

          return (
            <>
              <div className="mb-1 mt-2">
                <Label>
                  <FormattedMessage
                    defaultMessage="Next meeting date & time"
                    id="G0PPdQ"
                  />
                </Label>
              </div>
              <div className="flex items-baseline gap-2">
                <Field
                  label="Due Date"
                  name="dueDate"
                  onBlur={handleBlur("dueDate")}
                >
                  {({ field, form }: FieldProps) => (
                    <FormDatePicker
                      className="w-36"
                      field={field}
                      formControlClassName="my-0"
                      form={form}
                      id="nextMeetingDate"
                      // label={intl.formatMessage(messages.nextMeetingDate)}
                      onBlur={handleBlur("dueDate")}
                    />
                  )}
                </Field>
                <TimePickerField
                  increment={ONE_ON_ONE_TIME_INCREMENT}
                  name="meetingTime"
                />
              </div>
              {meetingLocalTime && (
                <LocalTimeHelper
                  localTime={meetingLocalTime}
                  className="my-2"
                />
              )}
              <ModalFooter>
                <SaveButton
                  data-cy="saveOneOnOneMeeting"
                  data-testid="submitForm"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                />
                <CancelButton data-cy="a7b-R-XBDg-O5oBNsfKmr" onClick={close} />
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
