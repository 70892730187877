import {
  namedOperations,
  useDeleteCommentMutation,
  useDeleteCommitMutation,
  useGetResultLazyQuery,
  useGetResultTimelineLazyQuery,
} from "@graphql";
import { EventTypes } from "common/event/constants";
import { fetchObjectiveTimeline } from "common/objective/actions";
import { objectTypes } from "constants/objects";
import { toast } from "utils/toastr";

export const useDeleteEvent = () => {
  const [deleteComment] = useDeleteCommentMutation({
    refetchQueries: [namedOperations.Query.getResultTimeline],
  });
  const [getResultTimeline] = useGetResultTimelineLazyQuery();
  const [deleteCommit] = useDeleteCommitMutation({
    refetchQueries: [
      namedOperations.Query.getKpiProgressGraph,
      namedOperations.Query.getKpiModalBaseKpi,
      namedOperations.Query.getKpisByGroup,
      namedOperations.Query.KpisByUser,
      namedOperations.Query.getKpiBoardKpis,
      namedOperations.Query.getResultProgressGraph,
      namedOperations.Query.getResultTimeline,
    ],
  });
  const [getResult] = useGetResultLazyQuery();

  return (event: any) => {
    const {
      eventType,
      extra: { targetType, targetId },
      targetId: id,
    } = event;

    const isCommit = eventType === EventTypes.CommitCreated;
    const deleteActionCreator = isCommit ? deleteCommit : deleteComment;
    return deleteActionCreator({
      variables: {
        input: {
          id,
        },
      },
    }).then(() => {
      let objectiveId;
      if (targetType === objectTypes.keyresult) {
        objectiveId = event.commit?.keyResult?.objective.id;
        getResultTimeline({ variables: { id: targetId } });
        if (isCommit) {
          getResult({
            variables: { resultId: targetId },
          });
        }
      } else if (targetType === objectTypes.objective) {
        objectiveId = targetId;
      }

      if (objectiveId) {
        fetchObjectiveTimeline(objectiveId);
      }
      toast.success("Event deleted");
    });
  };
};
