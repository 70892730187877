import { useField } from "formik";
import { FieldValidator } from "formik/dist/types";
import { camelCase } from "lodash";
import React from "react";
import { Input } from "common/inputs/Input/Input";

interface Props extends React.ComponentProps<typeof Input> {
  name: string;
  validate?: FieldValidator;
}

export const InputField = ({
  name,
  validate,
  ...props
}: Props): JSX.Element => {
  const [field, meta] = useField({ name, validate });
  return (
    <>
      <Input
        data-cy={props["data-cy"] || camelCase(props.label)}
        data-testid={props["data-testid"] ?? camelCase(props.label)}
        errorText={meta.touched && meta.error}
        field={field}
        id={name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
        // It's okay to spread here, since the component is type safe. Not doing so here would cause a lot of duplication.
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
};
