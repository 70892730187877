/**
 * ordered from newest to oldest. app only displays top 4 articles.
 * note that `id` must be unique!
 * note: we fetch blog posts dynamically using wordpress json api. this is only used as fallback.
 */
export const articles = [
  {
    href: "https://www.perdoo.com/resources/podcasts/navigate-uncertainty-with-okr",
    id: "navigate-uncertainty-with-okr",
    timeToRead: 13,
    title: "Navigate uncertainty with OKR",
  },
  {
    href: "https://www.perdoo.com/resources/whats-new-in-perdoo-june-2022/",
    id: "whats-new-in-perdoo-june-2022",
    timeToRead: 2,
    title:
      "New Engagement Report, shared goals, private slack channels, Jira, and more",
  },
  {
    href: "https://www.perdoo.com/resources/okr-framework-and-flexibility/",
    id: "okr-framework-and-flexibility",
    timeToRead: 2,
    title: "How flexible is the OKR framework?",
  },
  {
    href: "https://www.perdoo.com/resources/whats-new-in-perdoo-may-22/",
    id: "whats-new-in-perdoo-may-22",
    timeToRead: 3,
    title:
      "New Roadmap updates, actionable goal update emails, Check-in fixes, and more",
  },
];
