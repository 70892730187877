import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { Modal, ModalSize } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";

interface Props {
  body: React.ReactNode;
  button: React.ReactElement;
  cancelProps?: any;
  className?: string;
  confirmProps?: any;
  confirmText: string;
  disabled?: boolean;
  onConfirm?: () => void;
  size?: ModalSize;
  title: string;
}

/**
 * @deprecated Use `useConfirmationModal` or `ConfirmationModal`.
 */
export const Confirm = ({
  body,
  button,
  cancelProps = {},
  confirmProps = {},
  confirmText,
  disabled,
  onConfirm,
  size = "md",
  title,
  className,
}: Props): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);

  const open = () => {
    setModalOpen(true);
  };

  const close = (e: any) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setModalOpen(false);
  };

  const confirm = (e: any) => {
    close(e);
    if (onConfirm) onConfirm();
  };

  const ClonedButton = React.cloneElement(button, {
    onClick: disabled ? undefined : open,
  });
  return (
    <div className={className}>
      {ClonedButton}
      <Modal
        backdropClass="!z-modal-top-prio-backdrop"
        className="!z-modal-top-prio mt-[15%]"
        isOpen={modalOpen}
        onClose={close}
        showFooter
        size={size}
        title={title}
      >
        {body}
        <ModalFooter showDivider>
          <Button
            key={1}
            data-cy="submit"
            data-testid="submit"
            onClick={confirm}
            type="submit"
            {...confirmProps}
          >
            {confirmText}
          </Button>
          <Button
            data-cy="B1Rbck7T27UH4Ct5t2lpQ"
            key={0}
            onClick={close}
            variant="ghost"
            {...cancelProps}
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
