import { find } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { routes } from "route-configs";
import { Icon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { Tooltip } from "common/overlay";
import { useCompany } from "hooks/useCompany/useCompany";
import { getTimezoneOptions } from "utils/timezoneOptions/timezoneOptions";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
};
export const CurrentTimezone = ({ className }: Props): JSX.Element => {
  const company = useCompany();
  const { timezone } = company;
  const timezoneOptions = getTimezoneOptions();
  const currentTimezone = find(timezoneOptions, (tz) => tz.value === timezone)!;

  return (
    <div className={twClass("flex items-center space-x-2", className)}>
      <div>{currentTimezone.label}</div>
      <Tooltip
        content={
          <div className="px-2">
            <FormattedMessage
              defaultMessage="Timezone can be edited in the {companyDetailsLink} tab."
              id="vOCFO2"
              values={{
                companyDetailsLink: (
                  <AnchorNext href={routes.settings.company.general.info}>
                    <FormattedMessage
                      defaultMessage="Company details"
                      id="company:settings:info:title"
                    />
                  </AnchorNext>
                ),
              }}
            />
          </div>
        }
        interactive
      >
        <Icon className="text-blue-500" name="info_outline" size="xl" />
      </Tooltip>
    </div>
  );
};
