import { useUpsertCompanyMutationMutation } from "@graphql";
import { Form, Formik, FormikHelpers } from "formik";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { UserField } from "common/form/UserField";
import { UserMultiField } from "common/form/UserMultiField/UserMultiField";
import { Modal } from "common/overlay/Modal/Modal";
import { EDIT_COMPANY } from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useUserCreation } from "legacy/utils/useUserCreation";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

const validationSchema = Yup.object().shape({
  ambassadors: Yup.array().of(Yup.string()).min(1, "Required"),
  owner: Yup.string().required("Required"),
});

type Values = {
  ambassadors: string[];
  owner: string | null;
};

type Props = {
  description?: ReactNode;
  excludeUserIds?: string[];
  initialValues: Values;
  onClose: () => void;
  onSave?: () => void;
  showAmbassadors?: boolean;
  showOwner?: boolean;
  title?: ReactNode;
};

export type UpdateOwnerAndAmbassadorsModalProps = Props;

export const UpdateOwnerAndAmbassadorsModal = ({
  initialValues,
  showOwner,
  showAmbassadors,
  title,
  description,
  excludeUserIds,
  onClose,
  onSave,
}: Props): JSX.Element | null => {
  const intl = useIntl();

  const { formErrors } = useUserCreation();
  const company = useCompany();
  const [editCompany] = useUpsertCompanyMutationMutation();

  const handleSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    const response = await editCompany({
      variables: {
        input: {
          ambassadors: values.ambassadors,
          id: company.id,
          owner: values.owner,
        },
      },
    });
    actions.setSubmitting(false);
    const { hasError } = handleErrors(
      response,
      response.data?.upsertCompany?.errors
    );
    if (hasError) return;
    toast.success(
      intl.formatMessage({
        defaultMessage: "Configurations updated",
        id: "g3+lAV",
      })
    );
    track(EDIT_COMPANY);
    onClose();
    onSave?.();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen
      onClose={onClose}
      size="sm"
      title={title}
    >
      <div className="space-y-2">
        {!!description && <div>{description}</div>}
        <Formik<Values>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <Show when={showOwner ?? true}>
                <UserField
                  data-cy="fieldOwner"
                  label={intl.formatMessage({
                    defaultMessage: "Owner",
                    id: "zINlao",
                  })}
                  name="owner"
                />
                {formErrors.owner && (
                  <div className="text-red-500">{formErrors.owner}</div>
                )}
              </Show>
              <Show when={showAmbassadors ?? true}>
                <UserMultiField
                  label={intl.formatMessage({
                    defaultMessage: "Ambassadors",
                    id: "2e8kOc",
                  })}
                  name="ambassadors"
                  variables={{
                    idsExclude: excludeUserIds?.join(","),
                  }}
                />
              </Show>
              <Button
                className="mt-5"
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                {intl.formatMessage({
                  defaultMessage: "Save",
                  id: "global:save",
                })}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
