import { PageInfo } from "@graphql";
import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { usePage } from "common/table/hooks/usePage/usePage";
import { Cursor } from "common/table/types";
import { CONTEXTUAL_SIDEBAR_CHECK_IN_NAVIGATION } from "constants/tracking";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";

type Props = {
  loading: boolean;
  pageInfo: PageInfo;
  paginator: Cursor;
  setPaginator: (cursor: Cursor) => void;
  totalCount: number;
};

export const Navigation = ({
  loading,
  pageInfo,
  paginator,
  setPaginator,
  totalCount,
}: Props): JSX.Element => {
  const { itemFirst, itemLast } = usePage(totalCount, 1, pageInfo);

  // pageInfo.hasPreviousPage etc. actually only holds one-way info for this.
  // this is a limitation in apollo.
  const hasPreviousPage = itemFirst > 1;
  const hasNextPage = itemLast < totalCount;

  const handleNext = () => {
    setPaginator({
      after: pageInfo.endCursor ?? undefined,
      first: 1,
    });
    track(CONTEXTUAL_SIDEBAR_CHECK_IN_NAVIGATION, {
      direction: "previous", // sic, see below
    });
  };

  const handlePrev = () => {
    setPaginator({
      before: pageInfo.startCursor ?? undefined,
      last: 1,
    });
    track(CONTEXTUAL_SIDEBAR_CHECK_IN_NAVIGATION, {
      direction: "next", // sic, see below
    });
  };

  const loadingNext = loading && "first" in paginator;
  const loadingPrev = loading && "last" in paginator;

  // sic: response shows latest item first, so fetching next items = fetching older items.
  return (
    <div className="flex bg-white">
      <IconButton
        className={twClass(
          "rounded-l border border-r-0 px-1.5 py-1 border-slate-300",
          {
            "text-slate-400": !hasNextPage,
          }
        )}
        data-cy="paginate-prev"
        disabled={!hasNextPage || loading}
        loading={loadingNext}
        name="chevron_left"
        onClick={handleNext}
        size="2xl"
      />
      <IconButton
        className={twClass("rounded-r border px-1.5 py-1 border-slate-300", {
          "text-slate-400": !hasPreviousPage,
        })}
        data-cy="paginate-next"
        disabled={!hasPreviousPage || loading}
        loading={loadingPrev}
        name="chevron_right"
        onClick={handlePrev}
        size="2xl"
      />
    </div>
  );
};
