import { defineMessages } from "react-intl";

export const blogPosts = defineMessages({
  timeToRead: {
    defaultMessage:
      "{timeToRead, plural, one {# min read} other {# mins read}}",
    id: "sidebarGuide:blogPosts:timeToRead",
  },
  title: {
    defaultMessage: "What’s new?",
    id: "sidebarGuide:blogPosts:title ",
  },
});

export const links = defineMessages({
  faq: {
    defaultMessage: "FAQ",
    id: "sidebarGuide:links:faq",
  },
  okrsConfigure: {
    defaultMessage: "Configure and implement OKRs",
    id: "sidebarGuide:links:okrsConfigure",
  },
  okrsLearn: {
    defaultMessage: "Learn about OKRs",
    id: "sidebarGuide:links:okrsLearn",
  },
});

export const resources = defineMessages({
  goals: {
    defaultMessage: "Create, update and achieve goals",
    id: "sidebarGuide:resources:goals",
  },
  integrations: {
    defaultMessage: "Integrations",
    id: "sidebarGuide:resources:integrations",
  },
  keyFeatures: {
    defaultMessage: "Key features explained",
    id: "sidebarGuide:resources:keyFeatures",
  },
  quickStartGuide: {
    defaultMessage: "Quick start guide",
    id: "sidebarGuide:resources:quickStartGuide",
  },
});

export const videos = defineMessages({
  title: {
    defaultMessage: "Video tutorials",
    id: "sidebarGuide:videos:title ",
  },
});
