import React, { ReactElement } from "react";
import { IconSize } from "common/misc/types";
import { HelpRollover } from "common/overlay/HelpRollover/HelpRollover";
import { SupportLinks } from "common/support";

type Props = {
  description: ReactElement | string;
  iconClassName?: string;
  iconSize?: keyof typeof IconSize;
  links?: {
    getStartedLink?: string;
    page?: string;
    supportText?: string;
    takeTourLink?: string;
  };
  title?: string;
};

export const SupportTip: React.FC<Props> = ({
  description,
  links,
  title,
  iconSize,
  iconClassName,
}) => {
  const content = (
    <div className="space-y-2 p-1">
      {title && <div className="font-semibold">{title}</div>}
      <div>{description}</div>
      {links && <SupportLinks {...links} />}
    </div>
  );

  return (
    <HelpRollover
      content={content}
      iconSize={iconSize}
      iconClassName={iconClassName}
    />
  );
};
