import { useUpsertStrategicPillarMutation } from "@graphql";
import { isEmpty, isNil } from "lodash";
import { defineMessages, useIntl } from "react-intl";
import { handleNonFormErrors, handleUpsertErrors } from "utils/forms";
import { toast } from "utils/toastr";
import { stripHTML } from "utils/utils";

const messages = defineMessages({
  newStrategicPillar: {
    defaultMessage: "Strategic pillar created",
    id: "timeline:newStrategicPillar",
  },
  updatedStrategicPillar: {
    defaultMessage: "Strategic pillar updated",
    id: "timeline:updatedStrategicPillar",
  },
});

type Input = {
  description?: string;
  id?: string;
  name?: string;
};

type UpsertFn = (input: Input) => Promise<string | null>;

type Hook = {
  upsertStrategicPillar: UpsertFn | undefined;
};

export const useUpsertStrategicPillar = (refetchQueries: string[]): Hook => {
  const intl = useIntl();
  const [mutation] = useUpsertStrategicPillarMutation({
    refetchQueries: [...refetchQueries],
  });
  const upsertStrategicPillar = async ({ id, name, description }: Input) => {
    if (!isNil(name)) name = stripHTML(name);
    const { data, errors } = await mutation({
      variables: { input: { description, id, name } },
    });

    if (!isNil(errors) && errors.length) {
      handleNonFormErrors(errors);
      return null;
    }

    if (!isEmpty(data?.upsertStrategicPillar?.errors)) {
      handleUpsertErrors(data?.upsertStrategicPillar?.errors);

      return null;
    }

    const successMessage = !isNil(id)
      ? intl.formatMessage(messages.updatedStrategicPillar)
      : intl.formatMessage(messages.newStrategicPillar);
    if (!isNil(successMessage)) toast.success(successMessage);

    return data?.upsertStrategicPillar?.strategicPillar?.id ?? null;
  };

  return { upsertStrategicPillar };
};
