import {
  PerdooApiIntegrationApplicationChoices,
  useCompanyPlansQuery,
} from "@graphql";
import { isNil } from "lodash";
import { useIntl } from "react-intl";
import { featureAvailable, PlanFeatures } from "common/inAppUpgrade/constants";
import { messages } from "common/inAppUpgrade/hooks";
import { roles } from "constants/roles";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { featureTiers } from "modules/company/Company/Company.constants";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { isRoleSuperior } from "utils/permissions";
import { toast } from "utils/toastr";
import { useFeatureAccessHook } from "./types";

/** used for features that depend only on company tier */
export const useStandardPaidFeatureAvailable = (
  feature: PlanFeatures
): useFeatureAccessHook => {
  const intl = useIntl();
  const { data, error } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  if (error) {
    toast.failure(intl.formatMessage(messages.planFetchError));
  }

  const tier = data?.currentCompany.featureTier;

  if (!tier) return { badge: false, show: undefined };

  const showFeature = featureAvailable[feature]?.[tier as FeatureTier];
  const isPaidFeature = !featureAvailable[feature]?.["FREE"];
  const isTrial = isPaidFeature && tier === "TRIAL";

  return { badge: !showFeature || isTrial, show: showFeature };
};

export const useCanToggleAsanaIntegration = (): useFeatureAccessHook => {
  const intl = useIntl();
  const { data, error } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  if (error) {
    toast.failure(intl.formatMessage(messages.planFetchError));
  }

  if (isSet(data?.currentCompany.asanaIntegrationAvailable))
    return {
      badge: false,
      show: data.currentCompany.asanaIntegrationAvailable,
    };

  const tier = data?.currentCompany.featureTier;
  if (!tier) return { badge: false, show: undefined };

  const showFeature =
    featureAvailable["INTEGRATION_ASANA"]?.[tier as FeatureTier];
  const isPaidFeature = !featureAvailable["INTEGRATION_ASANA"]?.["FREE"];
  const isTrial = isPaidFeature && tier === "TRIAL";
  return { badge: !showFeature || isTrial, show: showFeature };
};

export const useAsanaIntegrationAvailable = (): useFeatureAccessHook => {
  const { badge, show: hasAccess } = useCanToggleAsanaIntegration();
  const company = useCompany();

  const isEnabled = mapEdges(company.integrations?.edges).some(
    (i) => i.application === PerdooApiIntegrationApplicationChoices.Asana
  );
  return { badge: isEnabled && badge, show: hasAccess && isEnabled };
};

export const useCanToggleJiraIntegration = (): useFeatureAccessHook => {
  const intl = useIntl();
  const { data, error } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  if (error) {
    toast.failure(intl.formatMessage(messages.planFetchError));
  }

  if (isSet(data?.currentCompany.jiraIntegrationAvailable))
    return { badge: false, show: data.currentCompany.jiraIntegrationAvailable };

  const tier = data?.currentCompany.featureTier;
  if (!tier) return { badge: false, show: undefined };

  const showFeature =
    featureAvailable["INTEGRATION_JIRA"]?.[tier as FeatureTier];
  const isPaidFeature = !featureAvailable["INTEGRATION_JIRA"]?.["FREE"];
  const isTrial = isPaidFeature && tier === "TRIAL";

  return { badge: !showFeature || isTrial, show: showFeature };
};

export const useJiraIntegrationAvailable = (): useFeatureAccessHook => {
  const { badge, show: hasAccess } = useCanToggleJiraIntegration();
  const company = useCompany();

  const isEnabled = mapEdges(company.integrations?.edges).some(
    (i) => i.application === PerdooApiIntegrationApplicationChoices.Jira
  );
  return { badge: isEnabled && badge, show: hasAccess && isEnabled };
};

export const useEngagementReportAvailable = (): useFeatureAccessHook => {
  const company = useCompany();
  const currentUser = useCurrentUser();
  const { badge, show: showFeature } =
    useStandardPaidFeatureAvailable("PAGE_ENGAGEMENT");

  if (isNil(currentUser) || isNil(company.id))
    return { badge: false, show: undefined };

  const isAmbassador = mapEdges(company.ambassadors?.edges).some(
    (a) => a.id === currentUser.id
  );
  const userHasAccess =
    isAmbassador || isRoleSuperior(currentUser, roles.standard);

  return {
    badge: userHasAccess && badge,
    show: showFeature && userHasAccess,
  };
};

const useCanUpgradeKpiBoards = (): boolean | undefined => {
  const company = useCompany();
  const currentUser = useCurrentUser();
  if (!currentUser) return undefined;

  const isAmbassador = mapEdges(company.ambassadors?.edges).some(
    (a) => a.id === currentUser.id
  );
  const isAccountOwner = company.owner?.id === currentUser.id;
  const isAdmin = isRoleSuperior(currentUser, roles.standard);

  return isAmbassador || isAccountOwner || isAdmin;
};

/**
 * kpi boards are available on supreme pricing plans, or if manually enabled in django superadmin.
 * for lower plans, we show the teaser page, but only for admin+ users, account owner and ambassadors.
 */
export const useKpiBoardsAvailable = (): useFeatureAccessHook => {
  const { data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  const hasAccessSetInSuperadminPanel =
    data?.currentCompany.growthBoardAvailable;

  const tier = data?.currentCompany.featureTier;
  const showFeature = featureAvailable["PAGE_KPI_BOARD"]?.[tier as FeatureTier];
  const companyHasAccess = hasAccessSetInSuperadminPanel || showFeature;
  const userCanAccess = useCanUpgradeKpiBoards();

  const isPaidFeature = !featureAvailable["PAGE_KPI_BOARD"]?.["FREE"];
  const isTrial = isPaidFeature && tier === "TRIAL";

  return {
    badge: userCanAccess && (!companyHasAccess || isTrial),
    show: companyHasAccess,
  };
};

export const useKudosVisibility = (): useFeatureAccessHook => {
  const company = useCompany();
  const showKudos = company.kudosEnabled ?? false;

  return { badge: false, show: showKudos };
};

export const useTeamReflectionsEnabled = (): useFeatureAccessHook => {
  const company = useCompany();
  const showTeamReflections = company.teamReflectionsEnabled ?? false;

  return { badge: false, show: showTeamReflections };
};

export const useOneOnOneVisibility = (): useFeatureAccessHook => {
  const { oneOnOnesEnabled, loading } = useCompany();
  const me = useCurrentUser();

  if (loading || !me) {
    return { badge: false, show: undefined };
  }
  const isStandardOrHigher = isRoleSuperior(me, roles.viewOnly);

  return { badge: false, show: oneOnOnesEnabled && isStandardOrHigher };
};

export const usePerformanceReviewsVisibility = (): useFeatureAccessHook => {
  const { performanceReviewsEnabled, loading } = useCompany();
  const me = useCurrentUser();
  const { data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  const hasAccessSetInSuperadminPanel =
    data?.currentCompany.performanceReviewsAvailable;

  const tier = data?.currentCompany.featureTier;
  const showFeature =
    featureAvailable["PERFORMANCE_REVIEWS"]?.[tier as FeatureTier];

  // required because performanceReviewsEnabled returns false while fetching
  if (loading || !me) {
    return { badge: false, show: undefined };
  }

  const companyHasAccess = hasAccessSetInSuperadminPanel || showFeature;

  const isPaidFeature = !featureAvailable["PERFORMANCE_REVIEWS"]?.["FREE"];
  const isTrial = isPaidFeature && tier === "TRIAL";

  const isStandardOrHigher = isRoleSuperior(me, roles.viewOnly);
  return {
    badge:
      performanceReviewsEnabled &&
      isStandardOrHigher &&
      (!companyHasAccess || isTrial),
    show: companyHasAccess && performanceReviewsEnabled && isStandardOrHigher,
  };
};

export const useCanTogglePrivateGoals = (): useFeatureAccessHook => {
  const company = useCompany();
  const { data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  const hasAccessSetInSuperadminPanel =
    data?.currentCompany.privateGoalsAvailable;

  const tier = data?.currentCompany.featureTier;
  const showFeature = featureAvailable["PRIVATE_GOALS"]?.[tier as FeatureTier];

  const hasAccess = company.accountStatus === featureTiers.TRIAL || showFeature;
  const isPaidFeature = !featureAvailable["PRIVATE_GOALS"]?.["FREE"];
  const isTrial = isPaidFeature && tier === "TRIAL";

  return {
    badge: !hasAccessSetInSuperadminPanel && (!hasAccess || isTrial),
    show: hasAccessSetInSuperadminPanel || hasAccess,
  };
};

export const usePrivateGoalsAvailable = (): useFeatureAccessHook => {
  const { badge, show: hasAccess } = useCanTogglePrivateGoals();
  const company = useCompany();

  return {
    badge: company.privateOkrs && badge,
    show: hasAccess && company.privateOkrs,
  };
};

export const useCanToggleSharedGoals = (): useFeatureAccessHook => {
  const { data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });

  const tier = data?.currentCompany.featureTier;
  const showFeature = featureAvailable["SHARED_GOALS"]?.[tier as FeatureTier];
  const isTrial = tier === "TRIAL";

  return {
    badge: !showFeature || isTrial,
    show: showFeature,
  };
};

export const useSharedGoalsEnabled = (): useFeatureAccessHook => {
  const { badge, show: hasAccess } = useCanToggleSharedGoals();
  const company = useCompany();

  return {
    badge: company.sharedGoals && badge,
    show: hasAccess && company.sharedGoals,
  };
};

export const useGoalTagsAvailable = (): useFeatureAccessHook => {
  const { data } = useCompanyPlansQuery({
    fetchPolicy: "cache-first",
  });
  const { badge: showBadge, show: hasAccess } =
    useStandardPaidFeatureAvailable("GOAL_TAGS");
  const hasAccessSetInSuperadminPanel = data?.currentCompany.goalTagsAvailable;
  return {
    badge: showBadge && !hasAccessSetInSuperadminPanel,
    show: hasAccessSetInSuperadminPanel || hasAccess,
  };
};
