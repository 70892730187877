import { useQuery } from "@apollo/client";
import {
  BookmarkTargetType,
  namedOperations,
  useDoToggleBookmarkMutation,
} from "@graphql";
import gql from "graphql-tag";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ADD_BOOKMARK, DELETE_BOOKMARK } from "constants/tracking";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";

const messages = defineMessages({
  favourite: {
    defaultMessage: "Add Favourite",
    id: "group:favourite",
  },
  unfavourite: {
    defaultMessage: "Remove Favourite",
    id: "group:unfavourite",
  },
});

const bookmarkedQueryByType = {
  [BookmarkTargetType.Group]: gql`
    query groupBookmarkedQuery($targetId: UUID!) {
      group(id: $targetId) {
        id
        bookmarked
      }
    }
  `,
  [BookmarkTargetType.Kpi]: gql`
    query kpiBookmarkedQuery($targetId: UUID!) {
      kpi(id: $targetId) {
        id
        bookmarked
      }
    }
  `,
  [BookmarkTargetType.Objective]: gql`
    query objectiveBookmarkedQuery($targetId: UUID!) {
      objective(id: $targetId) {
        id
        bookmarked
      }
    }
  `,
  [BookmarkTargetType.User]: gql`
    query userBookmarkedQuery($targetId: UUID!) {
      user(id: $targetId) {
        id
        bookmarked
      }
    }
  `,
  [BookmarkTargetType.Company]: gql`
    query companyBookmarkedQuery {
      currentCompany {
        id
        bookmarked
      }
    }
  `,
};

interface Props {
  className?: string;
  targetId: string;
  targetType: BookmarkTargetType;
}

export const BookmarkButton = ({
  targetType,
  targetId,
  className,
}: Props): JSX.Element => {
  // Pass "bookmarked" down as prop once everything is refactored
  const { data, loading: queryLoading } = useQuery(
    bookmarkedQueryByType[targetType],
    {
      variables: {
        targetId,
      },
    }
  );
  const [toggleBookmark, { loading }] = useDoToggleBookmarkMutation({
    refetchQueries: [
      namedOperations.Query.groupBookmarkedQuery,
      namedOperations.Query.objectiveBookmarkedQuery,
      namedOperations.Query.userBookmarkedQuery,
      namedOperations.Query.kpiBookmarkedQuery,
      namedOperations.Query.getUserBookmarks,
      namedOperations.Query.companyBookmarkedQuery,
    ],
  });
  const intl = useIntl();

  const target = data?.user || data?.group || data?.objective || data?.kpi || data?.currentCompany;

  const bookmarked = Boolean(target?.bookmarked);

  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    toggleBookmark({
      optimisticResponse: {
        toggleBookmark: {
          // @ts-expect-error ts-migrate(2740)
          bookmark: {
            [targetType]: {
              __typename: targetType,
              bookmarked: !bookmarked,
              id: targetId,
            },
          },
        },
      },
      variables: {
        targetId,
        targetType,
      },
    });

    track(!bookmarked ? ADD_BOOKMARK : DELETE_BOOKMARK, {
      target_type: targetType,
    });
  };

  return (
    <IconButton
      className={twClass(
        "ml-3",
        {
          "text-blue-500": bookmarked,
        },
        className
      )}
      data-cy="bookmarkButton"
      disabled={loading || queryLoading}
      name={bookmarked ? "star" : "star_border"}
      onClick={onClick}
      tooltip={
        bookmarked
          ? intl.formatMessage(messages.unfavourite)
          : intl.formatMessage(messages.favourite)
      }
    />
  );
};
