import { PerdooApiIntegrationApplicationChoices } from "@graphql";
import { createNonNullableCtx } from "common/context/utils";

export type ResultFormIntegration = {
  application: PerdooApiIntegrationApplicationChoices;
  id: string;
};

export type ResultFormContext = {
  integration: ResultFormIntegration | null;
  isAsanaSelected: boolean;
  isJiraSelected: boolean;
  setIntegration: (integration: ResultFormIntegration | null) => void;
};

export const [useResultFormContext, ResultFormContextProvider] =
  createNonNullableCtx<ResultFormContext>();
