import { Timeframe, useOrderedTimeframesQuery } from "@graphql";
import { Derive } from "@shoooe/derive";
import Fuse from "fuse.js";
import React, { useMemo } from "react";
import { For } from "common/controlFlow";
import { useSelect } from "common/inputs/Select/useSelect";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";

type Value = string;

type Option = Derive<
  Timeframe,
  {
    id: true;
    name: true;
  }
>;

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  hasError?: boolean;
  onChange?: (value: Value) => void;
  value: Value;
};

export const InlineTimeframeSelect = ({
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  hasError,
  onChange,
  value,
  disabled,
}: Props) => {
  const { data } = useOrderedTimeframesQuery();
  const timeframes = useMemo(() => mapEdges(data?.timeframes.edges), [data]);
  const defaultItem = timeframes.find((tf) => tf.id === value);
  const { selected, handleSelect, handleSearch, Select, visibleOptions } =
    useSelect<Option>({
      defaultItem,
      mapFn: (tfs) => tfs,
      options: timeframes,
      searchFn: (searchOptions, query) => {
        if (!query) return searchOptions;
        return new Fuse(searchOptions, {
          findAllMatches: true,
          isCaseSensitive: false,
          keys: ["name"],
          threshold: 0.2,
        })
          .search(query)
          .map((r) => r.item);
      },
    });

  const handleChange = (option: Option | null) => {
    if (!option) return;
    handleSelect(option);
    onChange?.(option.id);
  };

  return (
    <Select.Root
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue className={twClass({ "text-red-500": hasError })}>
          <div>{selected?.name}</div>
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20 divide-y">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <div>{option.name}</div>
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
