import { defineMessages } from "react-intl";

export const messages = defineMessages({
  objectiveAligned: {
    defaultMessage: "Objective aligned",
    id: "objective:aligned:success",
  },
  tabKrInit: {
    defaultMessage: "Results",
    id: "result:modal:tabKrInit",
  },
});
