import { isNil } from "lodash";
import React from "react";
import { Status } from "common/charts/Status/Status";
import { getNormalizedCommitDeltaColor } from "common/commit/utils";
import { Show } from "common/controlFlow";
import { IntegrationIcon } from "common/goal/IntegrationIcon/IntegrationIcon";
import { IntegrationLogo } from "common/integration/IntegrationLogo/IntegrationLogo";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { objectiveStages } from "common/objective/constants";
import { ProgressBar } from "common/progress/ProgressBar/ProgressBar";
import { ProgressDelta } from "common/progress/ProgressDelta/ProgressDelta";
import { ResultProps } from "common/result/Result/Result";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

interface ResultProgressProps {
  result: ResultProps;
}

export const ResultProgress = ({
  result,
}: ResultProgressProps): JSX.Element => {
  const {
    archivedDate,
    currentValue,
    endValue,
    lastCommit,
    metricUnit,
    normalizedValue,
    objective,
    pushIntegrationApplication,
    status,
    integration,
  } = result;
  const closed = objective.stage === objectiveStages.closed;
  const delta = lastCommit?.delta ?? 0;

  const getMetric = () => {
    return (
      <div className="flex w-full gap-1 text-sm">
        <Show when={isSet(integration)}>
          <IntegrationLogo application={integration?.application} />
        </Show>
        <strong data-testid="resultValue">
          <FormattedMetric value={currentValue} unit={metricUnit} />
        </strong>
        /
        <FormattedMetric value={endValue} unit={metricUnit} />
      </div>
    );
  };

  const normalizedDeltaColor = !isNil(lastCommit)
    ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ResultProps' is not assignable t... Remove this comment to see the full error message
      getNormalizedCommitDeltaColor(lastCommit, result)
    : undefined;

  return (
    <div className="flex flex-col w-32 space-y-1 mx-2">
      <div className="flex justify-between max-w-[250px]">
        <div className="col flex items-center">
          <Show when={pushIntegrationApplication}>
            <IntegrationIcon integration={pushIntegrationApplication!} />
          </Show>
          {getMetric()}
        </div>

        <Show when={!archivedDate}>
          <ProgressDelta
            className="ml-1 text-xs"
            color={normalizedDeltaColor}
            small
            value={delta}
          />
        </Show>
      </div>
      <div className="h-2">
        {isSet(normalizedValue) && (
          <ProgressBar
            className="h-1.5"
            barClassName={twClass({
              "bg-slate-500": closed || isSet(archivedDate),
            })}
            progress={normalizedValue}
          />
        )}
      </div>
      <Show when={!archivedDate}>
        <div className="flex jutify-between text-sm">
          <Status status={status} />
        </div>
      </Show>
    </div>
  );
};
