import { GetResultQueryData, useGetResultProgressGraphQuery } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ProgressChart } from "common/dynamic";
import { Frequency } from "common/overlay/FrequencyDropdown/FrequencyDropdown";

interface Props {
  freq?: Frequency;
  result: NonNullable<GetResultQueryData["result"]>;
}

export const ResultProgressGraphContainer = ({ result, freq }: Props) => {
  const { data, loading, error } = useGetResultProgressGraphQuery({
    fetchPolicy: "cache-and-network", // cache doesn't work reliably since there's no ID on the POT nodes
    variables: {
      freq,
      id: result.id,
    },
  });

  if (error) {
    return (
      <div className="text-red-500">
        <FormattedMessage
          defaultMessage="Error fetching graph"
          id="kpi:progressGraph:error"
        />
      </div>
    );
  }

  if (loading || !data) {
    return (
      <div className="my-10 h-96 flex items-center justify-center rounded-md text-slate-500 bg-slate-100 animate-pulse">
        <div>
          <FormattedMessage defaultMessage="Loading..." id="global:loading" />
        </div>
      </div>
    );
  }

  const idealLineData = [
    {
      date:
        result.startDate ?? data.result?.objective.timeframe.startDate ?? "",
      value: result.startValue,
    },
    {
      date: result.dueDate ?? data.result?.objective.timeframe.endDate ?? "",
      value: result.endValue,
    },
  ];

  return (
    <ProgressChart
      data={data.result?.progressGraph?.edges ?? []}
      idealLineData={idealLineData}
      showStatus
    />
  );
};
