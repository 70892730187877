import React from "react";
import { IconNameKey, IconType } from "common/icons/Icon/Icon.types";
import { FontSize, IconSize } from "common/misc/types";
import { svgIcons } from "constants/icons";
import { exhaustiveCheck } from "utils/exhaustiveCheck";
import { twClass } from "utils/twClass";

export type IconProps = {
  className?: string;
  "data-testid"?: string;
  id?: string;
  /** material icon name */
  name: IconNameKey;
  size?: keyof typeof IconSize;
  /** STOP: don't pass onClick to Icon, use {@link IconButton} instead. */
  // onClick?: MouseEventHandler<SVGSVGElement | HTMLSpanElement>;
  /** tooltip */
  title?: string;
  type?: IconType;
} & Pick<React.HTMLAttributes<HTMLSpanElement>, "aria-label">;

const viewboxes: Partial<Record<IconNameKey, string>> = {
  alignedObjective: "-3 -2 24 20",
  gif: "20 10 6 32",
  pulse: "0 0 16 16",
  sparkle: "0.67 0.67 14.67 14.67",
  streak: "0 0 20 24",
  warning_amber: "0.67 0.67 14.67 14.67",
};
const DEFAULT_VIEWBOX = "0 0 24 24";

/**
 * a tailwind-based Icon.
 *
 * @param {object} props
 * @param {string} props.size same as the font-size suffix in tailwind, i.e. "sm" corresponds to "text-sm"
 * @param {string} props.viewbox only for svg icons
 *
 */
export const Icon = ({
  className = "",
  "data-testid": dataTestId,
  id,
  name,
  title,
  type = null,
  size = "base",
  ...other
}: IconProps): JSX.Element => {
  const iconType = type
    ? `material-icons material-icons-${type}`
    : "material-icons";

  if (svgIcons[name]) {
    return (
      <svg
        className={twClass("fill-current", className)}
        clipRule="evenodd"
        data-testid={dataTestId ?? `icon-${name}`}
        fillRule="evenodd"
        height={FontSize[size]}
        id={id}
        viewBox={viewboxes[name] ?? DEFAULT_VIEWBOX}
        width={FontSize[size]}
      >
        <path d={svgIcons[name]} />
      </svg>
    );
  }

  return (
    <span
      className={twClass(iconType, IconSize[size], "leading-none", className)}
      data-testid={dataTestId ?? `icon-${name}`}
      id={id}
      title={title}
      {...other}
    >
      {name}
    </span>
  );
};

export const toIconSize = (
  from: "large" | "medium" | "regular" | "small" | "extraSmall"
): keyof typeof IconSize => {
  switch (from) {
    case "small":
      return "xl";
    case "medium":
      return "2xl";
    case "regular":
      return "2xl";
    case "large":
      return "3xl";
    case "extraSmall":
      return "sm";
    default:
      return exhaustiveCheck(from);
  }
};
