import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addAnother: {
    defaultMessage: "Add another",
    id: "settings:user:invite:addanother:label",
  },
  btnLabel: {
    defaultMessage: "Create user",
    id: "settings:company:users:buttonLabel",
  },
  emailChangeInfo: {
    defaultMessage:
      "Warning: You'll need to log in again if you change your email",
    id: "settings:user:email:info",
  },
  emailLabel: {
    defaultMessage: "Email",
    id: "settings:company:users:emailLabel",
  },
  emailPlaceholder: {
    defaultMessage: "Email",
    id: "settings:company:users:emailPlaceholder",
  },
  expireInvite: {
    defaultMessage: "Expire Invite after 30 days",
    id: "settings:company:users:expireInvite",
  },
  firstNameLabel: {
    defaultMessage: "First name",
    id: "settings:user:firstNameLabel",
  },
  firstNamePlaceholder: {
    defaultMessage: "First name",
    id: "settings:user:firstNamePlaceholder",
  },
  groupsPlaceholder: {
    defaultMessage: "Search...",
    id: "settings:user:invite:group:placeholder",
  },
  jobPositionLabel: {
    defaultMessage: "Job title",
    id: "settings:user:jobPositionLabel",
  },
  jobPositionPlaceholder: {
    defaultMessage: "Job title",
    id: "settings:user:jobPositionPlaceholder",
  },
  languageLabel: {
    defaultMessage: "Language",
    id: "settings:user:language:label",
  },
  lastNameLabel: {
    defaultMessage: "Last name",
    id: "settings:user:lastNameLabel",
  },
  lastNamePlaceholder: {
    defaultMessage: "Last name",
    id: "settings:user:lastNamePlaceholder",
  },
  managerLabel: {
    defaultMessage: "Manager",
    id: "settings:user:managerLabel",
  },
  no: {
    defaultMessage: "No",
    id: "global:no",
  },
  roleLabel: {
    defaultMessage: "Role",
    id: "settings:user:roleLabel",
  },
  sendInvites: {
    defaultMessage: "Send invite",
    id: "settings:company:users:sendInvites",
  },
  teamsLabel: {
    defaultMessage: "Teams",
    id: "DNeuzb",
  },
  yes: {
    defaultMessage: "Yes",
    id: "global:yes",
  },
});
