import React, { ReactNode } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { FormattedMessage } from "react-intl";
import { colorTheme } from "constants/colorTheme";
import { openIntercomChat } from "utils/openIntercomChat/openIntercomChat";
import { twClass } from "utils/twClass";

interface Props {
  className?: string;
  email: string;
  onEventScheduled: () => void;
  userFullName: string;
}

export const ChatSales: React.FC<Props> = ({
  userFullName,
  email,
  onEventScheduled,
  className,
}) => {
  const stripHash = (str: string) => str.replace("#", "");
  useCalendlyEventListener({
    onEventScheduled,
  });

  const title = (
    <FormattedMessage
      defaultMessage="Schedule a call with one of our representatives"
      id="upgrade:modal:chatSales:scheduleInfo"
    />
  );
  const subtitle = (
    <FormattedMessage
      defaultMessage="Got a question about {ourPaidPlans} or {coachingServices}? Find a time that suits you for a quick chat with one of the team."
      id="upgrade:modal:chatSales:gotQuestion"
      values={{
        coachingServices: (
          <a
            data-cy="linkUpgradePaidPlans"
            href="https://www.perdoo.com/coaching/"
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="coaching services"
              description='as part of "Got a question about ... ?"'
              id="yZS678"
            />
          </a>
        ),
        ourPaidPlans: (
          <a
            data-cy="linkUpgradeCoaching"
            href="https://www.perdoo.com/pricing"
            rel="noreferrer"
            target="_blank"
          >
            <FormattedMessage
              defaultMessage="our paid plans"
              description='as part of "Got a question about ... ?"'
              id="vcZoSo"
            />
          </a>
        ),
      }}
    />
  );
  const calendlyUrl =
    "https://calendly.com/perdoo/talk-to-sales?hide_event_type_details=1&hide_landing_page_details=1";

  return (
    <div className={twClass("py-8", className)}>
      <div className="flex flex-col justify-center rounded border bg-white md:flex-row">
        <div className="w-full p-10 text-center md:text-left">
          <div className="mx-auto max-w-sm">
            <img
              alt="Perdoo Team"
              className="mx-auto w-48 pb-4 md:mx-0"
              src="/images/sales-avatars.svg"
            />
            <h2 className="pb-6 text-2xl text-slate-800">{title}</h2>
            <p className="text-lg text-slate-800">{subtitle}</p>
          </div>
        </div>

        <div className="flex w-full flex-col items-center justify-center border-t pt-8 md:border-l md:border-t-0 md:pt-0">
          <InlineWidget
            pageSettings={{
              // Calendly can't handle hashtags in the hex colors:
              // https://github.com/tcampb/react-calendly/issues/108#issuecomment-1090454433
              backgroundColor: stripHash(colorTheme.white),
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: stripHash(colorTheme.blue[500]),
              textColor: stripHash(colorTheme.slate[800]),
            }}
            prefill={{ email, name: userFullName }}
            styles={{
              height: "600px",
              width: "400px",
            }}
            url={calendlyUrl}
          />
        </div>
      </div>

      <div className="mx-auto w-3/4 pt-6 text-center">
        <p className="text-base text-slate-800">
          <FormattedMessage
            defaultMessage="Need immediate assistance? <a>Start a chat!</a>"
            id="upgrade:modal:chatSales:needImmediateAssistance"
            values={{
              a: (children: ReactNode) => (
                <button
                  className="cursor-pointer text-blue-500 hover:text-indigo-800"
                  data-cy="chatToSalesButton"
                  onClick={() => openIntercomChat("start a chat!")}
                  type="button"
                >
                  {children}
                </button>
              ),
            }}
          />
        </p>
      </div>
    </div>
  );
};
