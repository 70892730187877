import { stripHTML } from "utils/utils";

/** https://www.sciencedirect.com/science/article/abs/pii/S0749596X19300786 */
const AVERAGE_WPM = 238;
/** if all fails, show "5 minutes" as time to read */
const TIME_TO_READ_FALLBACK = 5;

/**
 * "time to read" is set manually by the blog post author.
 * we parse it by looking for an element with a class ".rt-time" in the article excerpt.
 * if that fails, we calculate it based on the word count.
 */
const parseTimeToRead = (article: any): number => {
  const timeToReadHtml = new DOMParser().parseFromString(
    article.excerpt?.rendered,
    "text/html"
  );
  const timeToReadNode = timeToReadHtml.querySelector(".rt-time");

  if (timeToReadNode && timeToReadNode.textContent) {
    const parsedTimeToRead = parseInt(
      // replace non-word chars to handle "<1 min"
      timeToReadNode.textContent.replace(/\W/, "")
    );

    if (Number.isFinite(parsedTimeToRead)) {
      return parsedTimeToRead;
    }
  }

  const content = stripHTML(article.content?.rendered);
  const wordCount = content.split(" ").length;
  const timeToReadCalculated = Math.round(wordCount / AVERAGE_WPM);

  return timeToReadCalculated || TIME_TO_READ_FALLBACK;
};

export const parseJsonArticles = (articles: any[]) => {
  return articles
    .filter((a) => a.link)
    .map((article, index) => {
      const href = article.link;
      const id = article.id ?? `blog-post-${index}`;
      const timeToRead = parseTimeToRead(article);
      const title = article.title?.rendered ?? "Untitled";

      return {
        href,
        id,
        timeToRead,
        title,
      };
    });
};
