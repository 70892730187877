import { useQuery } from "@apollo/client";
import { camelCase, get } from "lodash";
import React from "react";
import { Props } from "react-select";
import { Show } from "common/controlFlow";
import { ALL_KPIS_QUERY } from "common/kpi/queries";
import { Select } from "legacy/components/Select/Select";

interface KpiSelectProps {
  filter?: (node: KpiNode) => boolean;
  renderSubtitle?: () => React.ReactNode;
}

interface KpisQuery {
  allKpis: NodeConnection<KpiNode>;
}

type Option = {
  id: string;
  label: string;
};

export const FormKpiSelect: React.FC<Props & KpiSelectProps> = ({
  field,
  form: { errors, submitCount },
  optional,
  label,
  placeholder,
  filter = () => true,
  renderSubtitle,
  styles,
  infoElement,
  ...other
}) => {
  const { data, loading } = useQuery<KpisQuery>(ALL_KPIS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const optionsFilter = ({ node }: { node: KpiNode }) =>
    node.id === field.value || filter(node);

  const options: Option[] =
    data?.allKpis.edges.filter(optionsFilter).map(({ node }) => ({
      id: node.id,
      label: node.name,
    })) || [];

  const handleChange = (selected: Option | undefined) => {
    field.onChange({
      target: {
        name: field.name,
        value: selected?.id,
      },
    });
  };

  const errorText = submitCount > 0 && get(errors, field.name);

  const value = options.find((option: Option) => option.id === field.value);

  return (
    <div className="mb-2.5">
      <Select
        autoFocus
        data-cy={other["data-cy"] || camelCase(label)}
        errorText={errorText}
        getNewOptionData={(val: string) => val}
        getOptionValue={(option: { id: string }) => option.id}
        id={field.name}
        infoElement={infoElement}
        isClearable={optional}
        isInForm
        isLoading={loading}
        label={label}
        name={field.name}
        onBlur={field.onBlur}
        onChange={handleChange}
        optional={optional}
        options={options}
        placeholder={placeholder}
        promptTextCreator={(val: string) => val}
        styles={styles}
        value={value}
      />
      <Show when={renderSubtitle}>
        <div>{renderSubtitle?.()}</div>
      </Show>
    </div>
  );
};
