import { isFunction } from "lodash";
import React, { ReactElement } from "react";
import { useKeyTrigger } from "hooks/useKeyTrigger/useKeyTrigger";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { twClass } from "utils/twClass";

interface Props {
  children: React.ReactNode;
  className?: string;
  "data-testid"?: string;
  disabled?: boolean;
  editComponent: React.ReactNode | (() => ReactElement);
  exceptFor?: string[];
  hasInitialFocus?: boolean;
  onCancel?: () => void;
  onEdit: () => void;
  onStart?: () => void;
  submitOnEnter?: boolean;
}

export const InlineEditable: React.FC<Props> = ({
  children,
  editComponent,
  "data-testid": dataTestId,
  hasInitialFocus = false,
  onEdit,
  onStart,
  onCancel,
  className,
  exceptFor,
  submitOnEnter = true,
  disabled = false,
}) => {
  const wrapperRef = React.useRef(null);
  const [editOn, setEditOn] = React.useState(hasInitialFocus);

  const handleSaveChanges = () => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (onEdit && editOn) {
      setEditOn(false);

      onEdit();
    }
  };

  const handleCancel = () => {
    setEditOn(false);
    if (onCancel) {
      onCancel();
    }
  };

  useOutsideAlerter(wrapperRef, handleSaveChanges, exceptFor);
  useKeyTrigger("Escape", handleCancel);
  const onPressEnter = submitOnEnter ? handleSaveChanges : undefined;
  useKeyTrigger("Enter", onPressEnter);

  const handleEditOn = () => {
    if (disabled) return;
    setEditOn(true);
    if (onStart) {
      onStart();
    }
  };

  return (
    <div ref={wrapperRef}>
      {editOn ? (
        isFunction(editComponent) ? (
          editComponent()
        ) : (
          editComponent
        )
      ) : (
        <div
          data-testid={dataTestId}
          data-cy="c5jxqE8eNcLxHWSvffqMQ"
          className={twClass(
            "rounded border border-solid p-1 border-transparent",
            {
              "border border-solid hover:cursor-pointer hover:bg-white hover:border-slate-300":
                !disabled,
            },
            className
          )}
          onClick={handleEditOn}
        >
          {children}
        </div>
      )}
    </div>
  );
};
