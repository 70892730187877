import { isEmpty } from "lodash";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { GroupName } from "common/group/GroupName/GroupName";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { objectiveStages } from "common/objective/constants";
import { useCompany } from "hooks/useCompany/useCompany";
import { intersperse } from "utils/intersperse";
import { logToSentry } from "../../../utils/tracker";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const {
  ObjectiveCreated,
  ObjectiveChanged,
  ObjectiveRelationAdded,
  ObjectiveRelationRemoved,
  ObjectiveCompleted,
} = EventTypes;

const OwnerfFormatter = ({ newName }: { newName: string }) => {
  const company = useCompany();
  const owner = newName === "None" ? company.name : newName;

  return (
    <FormattedMessage
      defaultMessage="changed the owner to {newName}"
      id="event:formatter:objective:changedOwner"
      values={{
        newName: <Target element={owner} />,
      }}
    />
  );
};

export const ObjectiveEventFormatters: Record<string, EventFormatter> = {
  [ObjectiveChanged]: {
    getComment: () => null,
    getString: (attr, { company }) => {
      const { extra } = attr;
      let formattedEvent;
      switch (extra.change.attribute) {
        case "name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="renamed {oldName} to {newName}"
              id="event:formatter:objective:renamed"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "description": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed description from {oldDesc} to {newDesc}"
              id="event:formatter:objective:description:updated"
              values={{
                newDesc: (
                  <Target>
                    <DangerousHTML html={extra.change.new_value} />
                  </Target>
                ),
                oldDesc: (
                  <Target>
                    <DangerousHTML html={extra.change.old_value} />
                  </Target>
                ),
              }}
            />
          );
          break;
        }
        case "completed_comment": {
          if (
            !isEmpty(extra.change.old_value) &&
            extra.change.old_value !== "None"
          ) {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="changed the completed comment from {old_value} to {new_value}"
                id="event:formatter:objective:completed_comment:updated"
                values={{
                  new_value: (
                    <Target>
                      <DangerousHTML html={extra.change.new_value} />
                    </Target>
                  ),
                  old_value: (
                    <Target>
                      <DangerousHTML html={extra.change.old_value} />
                    </Target>
                  ),
                }}
              />
            );
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="set the completed comment to {new_value}"
                id="event:formatter:objective:completed_comment:created"
                values={{
                  new_value: (
                    <Target>
                      <DangerousHTML html={extra.change.new_value} />
                    </Target>
                  ),
                }}
              />
            );
          }
          break;
        }
        case "timeframe": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="updated timeframe from {oldName} to {newName}"
              id="event:formatter:objective:timeframe:updated"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "group": {
          formattedEvent = <OwnerfFormatter newName={extra.change.new_value} />;

          break;
        }
        case "start_date": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the start date of {oName} from {oldVal} to {newVal}"
              id="event:formatter:objective:changedStart"
              values={{
                newVal: (
                  <Target>
                    <FormattedDate value={extra.change.new_value} />
                  </Target>
                ),
                oldVal: (
                  <Target>
                    <FormattedDate value={extra.change.old_value} />
                  </Target>
                ),
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "due_date": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the end date of {oName} from {oldVal} to {newVal}"
              id="event:formatter:objective:changedEnd"
              values={{
                newVal: (
                  <Target>
                    <FormattedDate value={extra.change.new_value} />
                  </Target>
                ),
                oldVal: (
                  <Target>
                    <FormattedDate value={extra.change.old_value} />
                  </Target>
                ),
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "manager": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the lead of {oName}"
              id="event:formatter:objective:changedLead"
              values={{
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "lead": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the lead from {oldName} to {newName}"
              id="T2YvPV"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "completed_date": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="re-opened {oName}"
              id="event:formatter:objective:reOpen"
              values={{
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "parent": {
          const { change } = extra;
          if (change.new_value === "None") {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="removed the alignment of {oName}"
                id="event:formatter:objective:unaligned"
                values={{
                  oName: <Target element={extra.name} />,
                }}
              />
            );
          } else if (change.old_value === "None") {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="aligned {oName} to {newVal}"
                id="event:formatter:objective:aligned"
                values={{
                  newVal: <Target element={extra.change.new_value} />,
                  oName: <Target element={extra.name} />,
                }}
              />
            );
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="changed the alignment of {oName} from {oldVal} to {newVal}"
                id="event:formatter:objective:changedAlignment"
                values={{
                  newVal: <Target element={extra.change.new_value} />,
                  oldVal: <Target element={extra.change.old_value} />,
                  oName: <Target element={extra.name} />,
                }}
              />
            );
          }
          break;
        }
        case "progress": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="updated {objective}'s progress from {oldValue} to {newValue}"
              id="event:formatter:objective:progress:updated"
              values={{
                newValue: <Target element={extra.change.new_value} />,
                objective: <Target element={extra.name} />,
                oldValue: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "stage": {
          if (extra.change.new_value === objectiveStages.closed) {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="closed the Objective {oName}"
                id="event:formatter:objective:closed"
                values={{ oName: <Target element={extra.name} /> }}
              />
            );
          } else if (extra.change.new_value === objectiveStages.active) {
            if (extra.change.old_value === objectiveStages.closed) {
              formattedEvent = (
                <FormattedMessage
                  defaultMessage="re-opened {oName}"
                  id="event:formatter:objective:reOpen"
                  values={{ oName: <Target element={extra.name} /> }}
                />
              );
            } else {
              formattedEvent = (
                <FormattedMessage
                  defaultMessage="activated the Objective {name}"
                  id="event:formatter:objective:activated"
                  values={{ name: <Target element={extra.name} /> }}
                />
              );
            }
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="updated {objective}'s stage from {oldValue} to {newValue}"
                id="event:formatter:objective:stage:updated"
                values={{
                  newValue: <Target element={extra.change.new_value} />,
                  objective: <Target element={extra.name} />,
                  oldValue: <Target element={extra.change.old_value} />,
                }}
              />
            );
          }

          break;
        }
        case "is_company_goal": {
          if (
            extra.change.new_value === "True" &&
            extra.change.old_value === "False"
          ) {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="added {company} as owner"
                id="zBpogU"
                values={{
                  company: <Target element={company.name} />,
                }}
              />
            );
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="removed {company} as owner"
                id="VhOfLx"
                values={{
                  company: <Target element={company.name} />,
                }}
              />
            );
          }
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: ObjectiveChanged,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
  [ObjectiveCompleted]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      return (
        <FormattedMessage
          defaultMessage="closed the Objective {oName}"
          id="event:formatter:objective:closed"
          values={{
            oName: <Target element={extra.name} />,
          }}
        />
      );
    },
  },
  [ObjectiveCreated]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      return (
        <FormattedMessage
          defaultMessage="created {oName}"
          id="event:formatter:objective:created"
          values={{
            oName: <Target element={extra.name} />,
          }}
        />
      );
    },
  },
  [ObjectiveRelationAdded]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "contributors": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="added {contrCount} contributor(s) to the Objective {oName}"
              id="event:formatter:objective:contributorsAdded"
              values={{
                contrCount: <Target element={extra.relation.pk_set.length} />,
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "groups": {
          const groupIds = extra.relation.pk_set;
          formattedEvent = (
            <FormattedMessage
              defaultMessage="added {teams} as {count, plural, one {owner} other {owners}}"
              id="lBfwim"
              values={{
                count: groupIds.length,
                teams: intersperse(
                  groupIds.map((id: string) => (
                    <GroupName key={id} id={id}>
                      {(group) => <strong>{group.name}</strong>}
                    </GroupName>
                  )),
                  ","
                ),
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: ObjectiveRelationAdded,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
  [ObjectiveRelationRemoved]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "contributors": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="removed {contrCount} contributor(s) from the Objective {oName}"
              id="event:formatter:objective:contributorsRemoved"
              values={{
                contrCount: <Target element={extra.relation.pk_set.length} />,
                oName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "groups": {
          const groupIds = extra.relation.pk_set;
          formattedEvent = (
            <FormattedMessage
              defaultMessage="removed {teams} as {count, plural, one {owner} other {owners}}"
              id="x+vj+J"
              values={{
                count: groupIds.length,
                teams: intersperse(
                  groupIds.map((id: string) => (
                    <GroupName key={id} id={id}>
                      {(group) => <strong>{group.name}</strong>}
                    </GroupName>
                  )),
                  ","
                ),
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: ObjectiveRelationRemoved,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
};
