import { useField } from "formik";
import React, { ReactNode } from "react";
import { Checkbox } from "common/inputs/Checkbox/Checkbox";

type Props = {
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  id?: string;
  label?: ReactNode;
  name: string;
};

export const CheckboxField: React.FC<Props> = ({
  "data-cy": dataCy,
  "data-testid": dataTestId,
  disabled,
  label,
  id,
  name,
  ...other
}) => {
  const [field, meta] = useField<boolean>(name);

  return (
    <Checkbox
      checked={Boolean(field.value)}
      data-cy={dataCy}
      data-testid={dataTestId}
      errorText={meta.touched && meta.error}
      disabled={disabled}
      id={id ?? field.name}
      label={label}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      {...other}
    />
  );
};
