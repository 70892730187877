import React from "react";
import { FormattedMessage } from "react-intl";
import { RadioSelectField } from "common/form/RadioSelectField/RadioSelectField";
import { EntityIcon } from "common/icons/EntityIcon/EntityIcon";
import { Icon } from "common/icons/Icon/Icon";
import { useObjectiveFormContext } from "common/objective/utils/useObjectiveFormContext/useObjectiveFormContext";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { progressTypes } from "constants/progressTypes";
import { FieldFocusWrapper } from "../FieldFocusWrapper/FieldFocusWrapper";
import { messages } from "./FormObjectiveProgressDriver.messages";

type RadioProps = {
  name: string;
  value?: string;
};

type ProgressDriverBag = {
  ProgressDriverObjective: ({ name }: RadioProps) => JSX.Element;
  ProgressDriverResults: ({ name }: RadioProps) => JSX.Element;
};
interface Props {
  children: (props: ProgressDriverBag) => React.ReactNode;
  disabled?: boolean;
}

export const FormObjectiveProgressDriver = ({
  children,
  disabled = false,
}: Props): JSX.Element => {
  const { setFocusedField } = useObjectiveFormContext();
  const ProgressDriverResults = ({ name }: RadioProps) => (
    <FieldFocusWrapper
      className="w-full"
      name="progressKeyResults"
      setFocusedField={setFocusedField}
    >
      <RadioSelectField
        addOn={<EntityIcon entity={progressTypes.keyResults} />}
        data-cy="progressKeyResults"
        disabled={disabled}
        name={name}
        selectValue={progressTypes.keyResults}
      >
        {<FormattedMessage {...messages.keyResults} />}
      </RadioSelectField>
    </FieldFocusWrapper>
  );

  const ProgressDriverObjective = ({ name }: RadioProps) => (
    <FieldFocusWrapper
      className="w-full"
      name="progressAlignedObjectives"
      setFocusedField={setFocusedField}
    >
      <RadioSelectField
        addOn={<EntityIcon entity={progressTypes.alignedObjective} />}
        data-cy="progressAlignedObjectives"
        disabled={disabled}
        name={name}
        selectValue={progressTypes.alignedObjective}
      >
        {<FormattedMessage {...messages.alignedObjective} />}
      </RadioSelectField>
    </FieldFocusWrapper>
  );
  return (
    <>
      <div className="my-2 flex flex-row text-sm font-normal">
        <FormattedMessage {...messages.progressCalulation} />
        <WithTooltip
          tooltip={
            <div className="max-w-xs">
              <FormattedMessage
                defaultMessage="Choose how progress for this Objective should be calculated."
                id="objective:forms:progressDriver:help:tooltip"
              />
            </div>
          }
        >
          <Icon
            className="ml-2 text-slate-500"
            name="help_outline"
            size="lg"
            type="outlined"
          />
        </WithTooltip>
      </div>
      <div className="flex w-full space-x-4">
        {children({ ProgressDriverObjective, ProgressDriverResults })}
      </div>
    </>
  );
};
