import React from "react";
import { FormattedMessage } from "react-intl";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const { TimeframeCreated, TimeframeSwitched } = EventTypes;

export const TimeframeEventFormatters: Record<string, EventFormatter> = {
  [TimeframeCreated]: {
    getComment: () => null,
    getString: (attr) => (
      <FormattedMessage
        defaultMessage="created the Timeframe {tfName}"
        id="event:formatter:timeframe:created"
        values={{
          tfName: <Target element={attr.extra.name} />,
        }}
      />
    ),
  },
  [TimeframeSwitched]: {
    getComment: () => null,
    getString: (attr) => (
      <FormattedMessage
        defaultMessage="changed the default Timeframe to {tfName}"
        id="event:formatter:timeframe:switched"
        values={{
          tfName: <Target element={attr.extra.change.new_value} />,
        }}
      />
    ),
  },
};
