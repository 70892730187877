import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { intercom } from "utils/tracker";

export const useIntercom = (): void => {
  const router = useRouter();
  const user = useCurrentUser();

  useEffect(() => {
    const handleRouteChange = () => {
      intercom()?.("update", {
        email: user?.email,
        user_hash: user?.intercomHash || undefined,
        user_id: user?.id,
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);
};
