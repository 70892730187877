import { Disclosure, Transition } from "@headlessui/react";
import { Fragment } from "react";
import React, { ReactNode } from "react";
import { ExpandIcon } from "common/icons/ExpandIcon";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";
import { twClass } from "utils/twClass";

interface Props {
  children: ReactNode;
  "data-testid"?: string;
  defaultOpen?: boolean;
  title: ReactNode;
}

export const GoalDisclosure = ({
  title,
  children,
  "data-testid": dataTestId,
  defaultOpen = true,
}: Props) => {
  const [disclosureOpen, setDisclosureOpen] = useLocalStorage<boolean>(
    `disclosure-${dataTestId}`,
    defaultOpen
  );
  return (
    <div className="pt-5 px-5" data-testid={dataTestId}>
      <Disclosure defaultOpen={disclosureOpen}>
        {({ open }) => (
          <section
            aria-label="goalDisclosure"
            className={twClass({ "w-full pb-5": !open })}
          >
            <div className="w-full flex items-center gap-2">
              <Disclosure.Button data-cy="VRVagwCUinySLgbaVNFj4" as={Fragment}>
                <div
                  className="w-full flex pb-3 border-b border-slate-300 space-x-2 items-center hover:cursor-pointer"
                  data-cy="goalDisclosureButton"
                  data-testid="goalDisclosureButton"
                  onClick={() => {
                    setDisclosureOpen(!open);
                  }}
                >
                  <ExpandIcon
                    size="xl"
                    className="border border-transparent hover:bg-slate-50 hover:border-slate-300 rounded"
                    data-testid="goalDisclosureToggle"
                    data-cy="goalDisclosureToggle"
                    expanded={open}
                  />
                  <div className="w-full flex justify-start items-center font-semibold">
                    {title}
                  </div>
                </div>
              </Disclosure.Button>
            </div>
            <Transition
              enter="transition-opacity duration-100 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition-opacity duration-75 ease-out"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Disclosure.Panel>
                <div data-testid="goalDisclosure-children">{children}</div>
              </Disclosure.Panel>
            </Transition>
          </section>
        )}
      </Disclosure>
    </div>
  );
};
