import { useField } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";

const Select = makeSelect<Option>();

type Option = {
  id: string;
  label: JSX.Element;
};

const options = [
  {
    id: "true",
    label: <FormattedMessage defaultMessage="Yes" id="global:yes" />,
  },
  {
    id: "false",
    label: <FormattedMessage defaultMessage="No" id="global:no" />,
  },
];

type Props = {
  className?: string;
  "data-cy"?: string;
  name: string;
};

export const YesNoField = ({
  name,
  "data-cy": dataCy,
  className,
}: Props): JSX.Element => {
  const [field, , helpers] = useField<boolean | null>(name);
  const selected =
    options.find((x) => (x.id === "true") === field.value) ?? null;

  return (
    <Select.Root
      className={className}
      data-cy={dataCy}
      onChange={(opt) => helpers.setValue(opt?.id === "true")}
      value={selected}
    >
      <Select.Trigger>
        <Select.Value innerClass="w-full">
          {selected?.label ?? (
            <FormattedMessage defaultMessage="Select..." id="724CrE" />
          )}
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown className="divide-y divide-slate-300">
        <Select.Options>
          <For each={options} fallback={<SelectFallback />}>
            {(option) => (
              <Select.Option key={option.id} value={option}>
                {option.label}
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
