import {
  camelCase,
  each,
  get,
  isArray,
  keyBy,
  mapKeys,
  mapValues,
  set,
} from "lodash";
import { toast } from "./toastr";
import { camelizeKeys } from "./utils";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'res' implicitly has an 'any' type.
export const getFormikErrors = (res) => {
  if (isArray(get(res, "errors.non_field_errors"))) {
    each(get(res, "errors.non_field_errors"), (e) => toast.failure(e));
  }

  if (isArray(res.errors)) {
    each(res.errors, (e) => toast.failure(e.detail));
    return {};
  }

  const errObj = {};
  const errors = get(res, "data.errors");
  if (res.status === 400 && errors) {
    Object.keys(errors).forEach((key) => {
      set(errObj, ["attributes", camelCase(key)], errors[key][0]);
      set(errObj, ["relationships", camelCase(key), "data"], errors[key][0]);
    });
  }

  return errObj;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'actions' implicitly has an 'any' type.
export const setFormikGqlErrors = (actions, errors) => {
  const parsedErrors = mapValues(
    camelizeKeys(keyBy(errors, "field")),
    ({ messages }) => messages[0] // Only show one error at a time per field
  );
  const { __all__: globalError, ...formErrors } = parsedErrors;
  if (globalError) {
    toast.failure(globalError);
  }

  actions.setErrors({
    ...formErrors,
    // This sets more errors than necessary, but supports legacy forms using JSON API shape
    ...mapKeys(formErrors, (val, key) => `attributes.${key}`),
    ...mapKeys(formErrors, (val, key) => `relationships.${key}`),
  });
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'errors' implicitly has an 'any' type.
export const handleNonFormErrors = (errors) => {
  const parsedErrors = mapValues(
    keyBy(errors, "field"),
    ({ messages }) => messages
  );

  each(parsedErrors.__all__, toast.failure);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'errors' implicitly has an 'any' type.
export const getPrimaryGqlError = (errors) => errors[0].messages[0];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'errors' implicitly has an 'any' type.
export const handleGqlServerErrors = (errors) => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  errors.forEach(({ message }) => {
    toast.failure(message);
  });
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'errors' implicitly has an 'any' type.
export const notifyServerErrors = (errors) => {
  each(errors, ({ detail, status }) => {
    if (status === "400" || status === "409") {
      toast.failure(detail);
    }
  });
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'errors' implicitly has an 'any' type.
export const handleUpsertErrors = (errors) => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'messages' implicitly has an 'any'... Remove this comment to see the full error message
  errors.forEach(({ messages }) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
    messages.forEach((message) => toast.failure(message));
  });
};
