import {
  OneOnOneMeetingItemDefaultFragment,
  OneOnOneMeetingItemType,
  useGetUserAvatarQuery,
} from "@graphql";
import { useField } from "formik";
import update from "immutability-helper";
import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableItem } from "common/dnd/DraggableItem";
import { DragNDropItemTypes, ItemTypes } from "common/dnd/ItemTypes";
import { Icon } from "common/icons";
import { Clickable } from "common/navigation/Clickable";
import { useCompleteOneOnOneContext } from "common/oneOnOnes/context/completeOneOnOneContext";
import { ONE_ON_ONE_MEETING_ITEM_ADDED } from "constants/tracking";
import { OmitTypename } from "utils/graphql/types";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { MeetingItem, newEmptyMeetingItem } from "./MeetingItem/MeetingItem";

export type OneOnOneMeetingItem =
  OmitTypename<OneOnOneMeetingItemDefaultFragment> & {
    assignee: string | null;
  };

type Props = {
  emptyMessage?: JSX.Element | string;
  name: keyof DragNDropItemTypes;
  saveItem: (item: OneOnOneMeetingItem) => Promise<{ hasError: boolean }>;
  saveItems: (items: OneOnOneMeetingItem[]) => Promise<{ hasError: boolean }>;
  type: OneOnOneMeetingItemType;
};

export const OneOnOneMeetingItemsField = ({
  emptyMessage,
  name,
  saveItem,
  saveItems,
  type,
}: Props): JSX.Element | null => {
  const { data } = useGetUserAvatarQuery();
  const me = data?.me;
  const { organizer, attendee, savingStatus } = useCompleteOneOnOneContext();
  const [field, meta, helpers] = useField<OneOnOneMeetingItem[]>(name);
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const prevItems = field.value;
    const newList = update(prevItems, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevItems[dragIndex] as OneOnOneMeetingItem],
      ],
    }).map((line, idx) => ({
      ...line,
      index: idx,
    }));
    helpers.setValue(newList);
  };

  const addNewItem = async (previousItem?: OneOnOneMeetingItem) => {
    const isOrganizer = organizer.id === me?.id;
    const lastIndex =
      // we cannot push the new item after the current one, because it would have the same index as the next item
      // we would need to update all subsequent items' indexes on the frontend and
      // previousItem?.index ??
      field.value.at(-1)?.index ?? field.value.length - 1;
    const newItem = newEmptyMeetingItem({
      assignee: isOrganizer ? organizer.id : attendee.id,
      index: lastIndex + 1,
      type,
    });
    track(ONE_ON_ONE_MEETING_ITEM_ADDED, {
      source: "1:1 meeting",
    });

    const itemsToUpdate: OneOnOneMeetingItem[] = [];
    let newFormItems = [...field.value];
    // if added using enter, we save both the current and new item together
    if (previousItem) {
      itemsToUpdate.push(previousItem);
      newFormItems = newFormItems.map((item) =>
        item.id === previousItem.id ? previousItem : item
      );
      itemsToUpdate.push(newItem);
    }
    newFormItems.push(newItem);

    // save in form state so the changes are visible instantly
    await helpers.setValue(newFormItems);
    // upsert items so they're saved in the db
    if (previousItem) {
      await saveItems(itemsToUpdate);
    } else await saveItem(newItem);

    setFocusedField(newItem.id);
  };

  const sortableMeetingItem = useCallback(
    (item: OneOnOneMeetingItem, index: number) => {
      return (
        <DraggableItem
          key={item.id}
          id={item.id}
          index={index}
          moveCard={moveCard}
          onDragEnd={() => saveItems(field.value)}
          separateHandler
          type={ItemTypes[name]}
        >
          {({ drag, drop, handlerId, isDragging }) => (
            <div
              ref={drop}
              className={twClass(
                "group relative flex w-full items-start hover:bg-slate-50",
                {
                  "opacity-50": isDragging,
                }
              )}
              data-handler-id={handlerId}
            >
              <div
                ref={drag}
                className="mt-3.5 flex h-4 items-center px-1.5 pt-1"
              >
                <Icon
                  className={twClass(
                    "pointer-events-none cursor-grab opacity-0 text-slate-500 hover:text-blue-500",
                    "pointer-events-auto group-hover:opacity-100"
                  )}
                  name="drag_indicator"
                  size="2xl"
                />
              </div>
              <MeetingItem
                addNewItem={addNewItem}
                focusedField={focusedField}
                meetingItem={item}
                name={name}
                saveChanges={saveItem}
                setFocusedField={setFocusedField}
              />
            </div>
          )}
        </DraggableItem>
      );
    },
    [field.value]
  );

  if (!me) return null;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col space-y-2">
        {field.value.map((meetingItem, index) =>
          sortableMeetingItem(meetingItem, index)
        )}
      </div>
      <Clickable
        className={twClass("w-full px-9 text-blue-500", {
          "cursor-wait": savingStatus === "saving",
        })}
        data-cy="addNewMeetingItem"
        data-testid="addNewMeetingItem"
        disabled={savingStatus === "saving"}
        onMouseDown={() => addNewItem()}
      >
        <span>+ {emptyMessage}</span>
      </Clickable>
      <div className="flex space-x-2 text-sm text-slate-500">
        <div className="w-10" />
        <div className="grow text-red-500">{meta.error}</div>
      </div>
    </DndProvider>
  );
};
