import React from "react";
import { FormattedMessage } from "react-intl";
import { SELECT_NEW_ID } from "common/inputs/Select/Select.utils";
import { SelectOption } from "common/inputs/Select/SelectOption/SelectOption";
import { OwnerSelectOption } from "./OwnerSelect.types";

type Props = {
  query: string;
};

export const OwnerSelectEmptyState = ({ query }: Props): JSX.Element => {
  const newOption: OwnerSelectOption = {
    avatar: null,
    id: SELECT_NEW_ID,
    name: query,
  };

  return (
    <SelectOption value={newOption}>
      <FormattedMessage
        defaultMessage="{query} (add new)"
        id="CyA/1A"
        values={{ query }}
      />
    </SelectOption>
  );
};
