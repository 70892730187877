import { GetActiveUsersQueryVariables } from "@graphql";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { For } from "common/controlFlow";
import { useSelect } from "common/inputs/Select/useSelect";
import { TextSkeleton } from "common/placeholders/TextSkeleton/TextSkeleton";
import { NEW_USER_ID, useAddUser } from "common/select/UserSelect/useAddUser";
import { SelectUser } from "common/select/UserSelect/UserSelect";
import { UserSelectFallback } from "common/select/UserSelect/UserSelectFallback";
import { useUserSelect } from "./hook/useUserSelect";

type Value = string | null;

type Option = SelectUser;

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => Promise<boolean>;
  value: Value;
  variables?: GetActiveUsersQueryVariables;
};

const DEFAULT_USERS: Option[] = [];

export const InlineUserSelect = ({
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  onChange,
  value,
  variables,
  disabled,
}: Props) => {
  const users = useUserSelect({
    defaultUserId: value,
    options: variables,
  });

  const { inviteUser } = useAddUser();
  const loading = isNil(users);
  const defaultItem = users?.find((tf) => tf.id === value);
  const {
    selected,
    query,
    handleSelect,
    handleSearch,
    Select,
    visibleOptions,
  } = useSelect<Option>({
    defaultItem,
    options: users ?? DEFAULT_USERS,
  });

  const handleChange = async (option: SelectUser | null) => {
    const newUser =
      option?.id === NEW_USER_ID ? await inviteUser(option.fullName) : option;

    const success = await onChange?.(newUser?.id ?? null);
    if (success) handleSelect(newUser);
  };

  return (
    <Select.Root
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          {loading && <TextSkeleton className="bg-slate-200" />}
          {selected && !loading && (
            <Entity
              disabled={!selected.isActive}
              avatar={selected.avatar}
              name={selected.fullName}
            />
          )}
          {!selected && !loading && (
            <FormattedMessage defaultMessage="No user selected" id="HLpTpV" />
          )}
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20 divide-y">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <UserSelectFallback query={query} visibleUsers={visibleOptions} />
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <Entity
                  disabled={!option.isActive}
                  avatar={option.avatar}
                  name={option.fullName}
                />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
