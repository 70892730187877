import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import { ExpandIcon } from "common/icons/ExpandIcon";
import { twClass } from "utils/twClass";
import { twMerge } from "utils/twMerge";

interface Props {
  /** content which folds when button clicked */
  children: React.ReactNode;
  className?: string;
  defaultOpen?: boolean;
  /** shown before the button, does not trigger folding. */
  header?: React.ReactNode;
}

/**
 * collapsible panel that shows and hides content. used e.g. in check-in for grouping results under foldable parent.
 * @see https://headlessui.com/react/disclosure
 */
export const Panel = ({
  className,
  children,
  defaultOpen = true,
  header,
}: Props): JSX.Element => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <section aria-label="panel" className={twClass({ "pb-5": !open })}>
          <div className={twMerge("flex items-center gap-2", className)}>
            <Disclosure.Button
              data-cy="panelButton"
              data-testid="panelButton"
              className="flex items-center"
            >
              <ExpandIcon
                size="2xl"
                className="text-blue-500 hover:text-indigo-800"
                expanded={open}
              />
            </Disclosure.Button>
            {header}
          </div>
          <Transition
            enter="transition-opacity duration-100 ease-out"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition-opacity duration-75 ease-out"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Disclosure.Panel>
              <div className={twClass("py-5")} data-testid="panel-children">
                {children}
              </div>
            </Disclosure.Panel>
          </Transition>
        </section>
      )}
    </Disclosure>
  );
};
