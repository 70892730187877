import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { FilterBtnProps } from "legacy/components/filters/types";

interface Props extends FilterBtnProps {
  filterCopy?: string;
  renderButton?: (props: FilterBtnProps) => React.ReactElement;
  renderFilters: () => JSX.Element;
}

export const FilterButton = ({
  renderFilters,
  onToggle,
  renderButton,
  filterCopy,
}: Props): JSX.Element => {
  const button = renderButton ? (
    renderButton({ onToggle })
  ) : (
    <button
      className="flex items-center border-0 text-sm bg-transparent hover:text-blue-500 focus:outline-none"
      data-cy="filter-toggle"
      type="button"
    >
      <Icon name="add" size="lg" />
      {filterCopy ? (
        filterCopy
      ) : (
        <FormattedMessage defaultMessage="Filter OKRs" id="nvuo/A" />
      )}
    </button>
  );
  return (
    <div className="filter-add-button relative">
      <Dropdown button={button} limitHeight={false} onToggle={onToggle}>
        {renderFilters()}
      </Dropdown>
    </div>
  );
};
