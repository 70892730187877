import { FieldInputProps } from "formik";
import React from "react";
import { Label } from "common/inputs/Label/Label";
import { Toggle } from "common/inputs/Toggle/Toggle";
import { FlexContainer } from "common/misc/FlexContainer/FlexContainer";
import { Widget } from "common/misc/Widget/Widget";

type Props = {
  afterChange?: (value: boolean) => void;
  description: React.ReactNode;
  field: FieldInputProps<any>;
  label: string;
  testLabel: string;
};
export const FormTabbedToggle = ({
  label,
  description,
  afterChange,
  field,
  testLabel,
}: Props): JSX.Element => {
  const handleChange = (value: boolean) => {
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
    if (afterChange) {
      afterChange(value);
    }
  };

  return (
    <div className="my-3">
      <Label className="mt-2">{label}</Label>
      <Widget>
        <FlexContainer className="pt-3" contentCenter itemsCenter>
          <span>{description}</span>
          <Toggle
            data-cy={testLabel}
            on={field.value}
            onToggle={handleChange}
          />
        </FlexContainer>
      </Widget>
    </div>
  );
};
