import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  barClassName?: string;
  className?: string;
  progress: number;
};

export const ProgressBar = ({ className, progress, barClassName }: Props) => {
  const roundedProgress = Math.round(progress);
  return (
    <div
      className={twClass(
        "h-2 rounded-full w-full",
        "flex flex-row",
        "bg-slate-300",
        className
      )}
    >
      {roundedProgress > 0 && (
        <div
          className={twClass(
            "bg-blue-500 rounded-l",
            "transition-all duration-200 ease-out",
            {
              "rounded-full": roundedProgress >= 100,
              "rounded-l": roundedProgress < 100,
            },
            barClassName
          )}
          style={{
            width: `${roundedProgress}%`,
          }}
        />
      )}
    </div>
  );
};
