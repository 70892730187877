import { useGetObjectiveQuery } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { routes } from "route-configs";
import { useObjectiveContext } from "common/context/objectiveContext";
import { KpiInfoTooltip } from "common/kpi/KpiInfoTooltip/KpiInfoTooltip";
import { SelectLinkButton } from "common/misc/SelectLinkButton/SelectLinkButton";
import { objectiveAlignToFields } from "common/objective/constants";
import { MapsObjectiveTooltip } from "common/objective/MapsObjective/MapsObjectiveTooltip/MapsObjectiveTooltip";
import { getAlignedToField } from "common/objective/utils/utils";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { AlignmentOption } from "./AlignmentOption";

export const ObjectiveAlignment = () => {
  const objective = useObjectiveContext();

  const parentObjectiveId = objective.parent?.id;
  const { data } = useGetObjectiveQuery({
    skip: !objective.parent,
    variables: { objectiveId: parentObjectiveId! },
  });
  const { kpiHref, objectiveHref } = useModalRouter();
  const alignedTo = getAlignedToField(objective, true);

  switch (alignedTo) {
    case objectiveAlignToFields.kpi:
      const kpiId = objective.kpi?.id;
      const kpiLink = kpiHref({ kpiId: kpiId! });

      return (
        <SelectLinkButton
          href={kpiLink}
          tooltip={<FormattedMessage defaultMessage="Open kpi" id="Kzpl2j" />}
        >
          <WithTooltip
            tooltip={
              objective.kpi && (
                <KpiInfoTooltip className="m-1.5" id={objective.kpi.id} />
              )
            }
          >
            <AlignmentOption
              showOverflowTooltip={false}
              text={objective.kpi?.name}
              iconClassName="text-indigo-500"
              iconName="monitor_heart"
              iconType="outlined"
            />
          </WithTooltip>
        </SelectLinkButton>
      );
    case objectiveAlignToFields.parent:
      const parentObjective = data?.objective;
      const parentLink = objectiveHref({ oId: parentObjectiveId });
      return (
        <SelectLinkButton
          href={parentLink}
          tooltip={
            <FormattedMessage
              defaultMessage="Open parent objective"
              id="sKqmAc"
            />
          }
        >
          <WithTooltip
            tooltip={
              parentObjective && (
                <MapsObjectiveTooltip
                  className="m-1.5"
                  {...parentObjective}
                  goalId={parentObjective.id}
                />
              )
            }
          >
            <AlignmentOption
              showOverflowTooltip={false}
              text={objective.parent?.name}
              iconClassName="text-slate-600"
              iconName="outlined_flag"
            />
          </WithTooltip>
        </SelectLinkButton>
      );
    case objectiveAlignToFields.strategicPillar:
      return (
        <SelectLinkButton
          href={routes.mapsv3.main}
          tooltip={<FormattedMessage defaultMessage="Open maps" id="dSUuSP" />}
        >
          <AlignmentOption
            text={objective.strategicPillar?.name}
            iconClassName="text-green-600"
            iconName="account_balance"
          />
        </SelectLinkButton>
      );
    default:
      return (
        <AlignmentOption
          text={<FormattedMessage defaultMessage="No alignment" id="+j7cGb" />}
          iconClassName="text-red-500"
          iconName="close"
        />
      );
  }
};
