import { Listbox } from "@headlessui/react";
import React, { Fragment, ReactElement } from "react";
import { useSelectContext } from "../Select.context";

export type Props = {
  children: ReactElement;
};

export const SelectTrigger = ({ children }: Props): JSX.Element => {
  const { setValueRef } = useSelectContext();
  // the context ref is for positioning the menu next to the value.
  // we are using a portal so the menu pops out correctly regardless of container.
  // headless UI doesn't support portal positioning, so we are using floating UI,
  // which works by passing callback refs.
  const refChildren = React.cloneElement(children, {
    ref: setValueRef,
  });
  return <Listbox.Button as={Fragment}>{refChildren}</Listbox.Button>;
};
