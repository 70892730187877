import { GCalEventSeries, useGetUserEventSeriesQuery } from "@graphql";
import { Derive } from "@shoooe/derive";
import clsx from "clsx";
import { ErrorMessage, useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { FormattedRRule } from "common/misc/FormattedRRule/FormattedRRule";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { searchBy } from "utils/searchBy/searchBy";

const Select = makeSelect<Option>();

type Option = Derive<
  GCalEventSeries,
  {
    id: true;
    recurrence: true;
    start: true;
    summary: true;
  }
>;
export type GoogleCalendarEventFieldOption = Option;

type Props = {
  attendee: string;
  className?: string;
  name: string;
  onChange?: (newVal: Option | null) => void;
};

export const GoogleCalendarEventField = ({
  name,
  attendee,
  className,
  onChange,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState<Option | null>(null);
  const setSelectedAndForward = (value: Option | null) => {
    setSelected(value);
    onChange?.(value);
  };
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField<string | null>(name);

  const { data } = useGetUserEventSeriesQuery({
    variables: { attendee },
  });

  const options = mapEdges(data?.me?.gCalIntegration?.eventSeries?.edges);
  const [visibleOptions, setVisibleOptions] = useState(options);
  const handleSearch = (query: string) => {
    const filteredItems = searchBy(options, ["summary"], query);
    setVisibleOptions(filteredItems);
  };

  useEffect(() => {
    const selectedFromValue = options.find((o) => o.id === field.value);
    setSelectedAndForward(selectedFromValue ?? null);

    if (isSet(data) && !!field.value && !selectedFromValue) {
      helpers.setValue(null);
    }

    if (isSet(data)) {
      setVisibleOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, attendee, isSet(data)]);

  if (!isSet(data)) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className={clsx("space-y-1", className)}>
        <Select.Root
          onChange={(val) => {
            setSelectedAndForward(val);
            helpers.setValue(val?.id ?? null);
          }}
          value={selected}
        >
          <Select.Trigger>
            <Select.Value hasError={!!meta.error && submitCount > 0}>
              {selected?.summary ?? (
                <FormattedMessage
                  defaultMessage="Select event..."
                  id="XE6WyF"
                />
              )}
            </Select.Value>
          </Select.Trigger>
          <Select.Dropdown className="divide-y divide-slate-300">
            <Select.Searchbox onChange={handleSearch} />
            <Select.Options>
              <For each={visibleOptions} fallback={<SelectFallback />}>
                {(option) => (
                  <Select.Option key={option.id} value={option}>
                    <div>{option.summary}</div>
                    <div className="text-sm text-slate-500">
                      <FormattedRRule
                        start={option.start}
                        value={option.recurrence}
                      />
                    </div>
                  </Select.Option>
                )}
              </For>
            </Select.Options>
          </Select.Dropdown>
        </Select.Root>
        <div className="text-sm text-red-500">
          <ErrorMessage name={name} />
        </div>
      </div>
    </>
  );
};
