import React from "react";
import { Avatar, avatarSizes } from "common/avatar";
import { ItemSize } from "common/misc/types";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";

const badgeStyles = {
  extraLarge: "h-[40px] w-[40px] -left-8 text-[23px]",
  extraSmall: "h-[14px] w-[14px] -left-2 text-[9px]",
  fullWidth: "h-full",
  large: "h-[22px] w-[22px] -left-4 text-sm",
  medium: "h-[18px] w-[18px] -left-3 text-[11px]",
  small: "h-[14px] w-[14px] -left-2 text-[9px]",
};

const stackedAvatarStyles = {
  extraLarge: "-ml-20",
  extraSmall: "-ml-2",
  fullWidth: "h-full",
  large: "-ml-7",
  medium: "-ml-5",
  small: "-ml-3",
};

interface Props {
  className?: string;
  "data-cy"?: string;
  maxCount?: number;
  noOverlap?: boolean;
  onClick?: () => void;
  showTooltip?: boolean;
  size?: keyof typeof ItemSize;
  urls: (string | null)[];
}

export const StackedAvatar = ({
  urls,
  onClick,
  maxCount = 2,
  className,
  size = "medium",
  noOverlap = false,
  showTooltip,
  "data-cy": dataCy,
}: Props) => {
  let childrenToShow = urls;
  let childrenHidden: (string | null)[] = [];

  if (maxCount && maxCount < urls.length) {
    childrenToShow = urls.slice(0, maxCount);
    childrenHidden = urls.slice(maxCount, urls.length);
  }
  const hiddenAvatars = showTooltip ? (
    <div className="flex ">
      {childrenHidden.map((avatarUrl) => {
        return (
          <div key={avatarUrl} className="mr-2">
            <Avatar size={size} url={avatarUrl} />
          </div>
        );
      })}
    </div>
  ) : null;

  return (
    <div
      className={twClass(
        "relative flex cursor-default items-baseline",
        { "items-center": noOverlap },
        className
      )}
      data-cy={dataCy}
      onClick={onClick}
    >
      {childrenToShow.map((avatarUrl, idx) => (
        <Avatar
          key={avatarUrl ?? idx}
          className={twClass(
            idx > 0 && stackedAvatarStyles[size],
            { "ml-0.5": noOverlap },
            "border border-white bg-white"
          )}
          size={size}
          url={avatarUrl}
        />
      ))}
      {childrenHidden.length > 0 && (
        <div
          className={twClass({
            "relative ": !noOverlap,
          })}
        >
          <WithTooltip interactive tooltip={hiddenAvatars}>
            <div
              className={twClass(
                "absolute bottom-0 rounded-full bg-blue-500",
                badgeStyles[size],
                { "relative left-0 ml-0.5 text-xs": noOverlap }
              )}
              style={{
                height: noOverlap ? avatarSizes[size] : "",
                width: noOverlap ? avatarSizes[size] : "",
              }}
            >
              <div
                className={twClass(
                  "flex h-full items-center justify-center text-white",
                  {
                    "rounded-full border border-white": noOverlap,
                  }
                )}
              >
                +{childrenHidden.length}
              </div>
            </div>
          </WithTooltip>
        </div>
      )}
    </div>
  );
};
