export const roles = {
  admin: "admin",
  standard: "standard",
  superAdmin: "superadmin",
  viewOnly: "viewonly",
};

export const allRolesButViewonly = Object.values(roles)
.filter((role) => role !== roles.viewOnly);

export type UserRole = ValueOf<typeof roles>;
