import Link from "next/link";
import React, { MouseEventHandler } from "react";
import { Icon } from "common/icons";
import { AnchorNextProps } from "./AnchorNext";

type Props = {
  "data-cy"?: string;
  external?: boolean;
  href: string;
  onClick?: MouseEventHandler<HTMLElement>;
} & Omit<AnchorNextProps, "onClick">;

/**
 * same as {@link AnchorNext} but supports onClick handler. this is a separate component on purpose, to discourage
 * the common anti-pattern of using onClick handlers for overriding link actions. <br />
 * <br />
 * this is useful for passing events such as tracking, which should happen onClick, without preventing the link navigation. <br />
 * <br />
 * if you want to trigger an action instead of navigation, use a button.
 */
export const AnchorNextTrack = ({
  children,
  href,
  onClick,
  external = false,
  "data-cy": dataCy,
  ...rest
}: Props): JSX.Element => {
  return (
    <Link href={href} legacyBehavior>
      <a data-cy={dataCy} onClick={onClick} {...rest}>
        {children}
        {external && (
          <Icon
            className="ml-1 inline-block align-text-bottom"
            name="open_in_new"
          />
        )}
      </a>
    </Link>
  );
};
