import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import {
  OneOnOneFormSavingStatus,
  useCompleteOneOnOneContext,
} from "common/oneOnOnes/context/completeOneOnOneContext";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useToggle } from "hooks/useToggle/useToggle";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { ConfirmEndOneOnOneMeetingModal } from "../modals/ConfirmEndOneOnOneMeetingModal";

type Props = {
  completeMessage: JSX.Element | null;
  loading?: boolean;
  oneOnOneBag: {
    isActive: boolean;
    isCancelled: boolean;
    isComplete: boolean;
    isOverdue: boolean;
    seriesId: string;
  };
  savingStatus: OneOnOneFormSavingStatus;
  showEndMeeting: boolean;
  submitForm: () => void;
  toggleShowScheduleMeeting?: () => void;
};

export const OneOnOneCompleteFormFooter = ({
  completeMessage,
  loading,
  oneOnOneBag: { isOverdue, seriesId, isActive, isComplete, isCancelled },
  savingStatus,
  showEndMeeting,
  submitForm,
  toggleShowScheduleMeeting,
}: Props): JSX.Element => {
  const { close, goToOneOnOneMeeting } = useModalRouter();
  const [now, setNow] = useState(dayjs());
  const [showConfirmEndMeeting, toggleConfirmEndMeeting] = useToggle(false);
  const { isSubmitting } = useFormikContext();
  const showPlanNextMeeting = isSet(toggleShowScheduleMeeting);
  const { query } = useRouter();
  const { meetings } = useCompleteOneOnOneContext();
  const { mId } = query;
  const activeMeeting = meetings.find(({ id }) => id === mId);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs());
    }, 10000); // refresh every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const dueDateInFuture = dayjs(activeMeeting?.dueDate) > now;

  const handlePlanNextMeeting = async () => {
    if (showPlanNextMeeting) {
      toggleShowScheduleMeeting();
      return;
    }
    goToOneOnOneMeeting({
      reschedule: true,
      seriesId,
    });
  };

  const noUpcomingMeetings = (
    <div className="flex items-center space-x-1 text-gray-500">
      <Icon
        className="text-red-500"
        name="error_outline"
        size="lg"
        type="outlined"
      />
      <span>
        <FormattedMessage
          defaultMessage="No upcoming meetings planned"
          id="AjLmxI"
        />
      </span>
    </div>
  );

  const meetingNotEndedMessage = (
    <div className="flex items-center space-x-1 text-gray-500">
      <Icon
        className="text-red-500"
        name="error_outline"
        size="lg"
        type="outlined"
      />
      <span>
        <FormattedMessage defaultMessage="Meeting not ended" id="q9FMbk" />
      </span>
    </div>
  );

  const showMessage = () => {
    if (isComplete) {
      return completeMessage;
    }
    if (savingStatus === "saving")
      return <FormattedMessage defaultMessage="Saving updates" id="JwVXC9" />;

    if (savingStatus === "saved") {
      return <FormattedMessage defaultMessage="Updates saved" id="F8P+7M" />;
    }
    return (
      <FormattedMessage
        defaultMessage="All unchecked entries will be carried over to the next meeting."
        id="uD9xIE"
      />
    );
  };
  const completeMessageIcon = () => {
    if (isComplete) {
      return isCancelled ? "cancel" : "check_circle";
    }
    return "info";
  };
  const ShowMeetingSaveStatus = (
    <Show
      fallback={
        <div className="flex items-center space-x-1 text-gray-500">
          <Show when={isActive}>
            <Icon
              className={twClass({
                "text-green-500": isComplete && !isCancelled,
                "text-red-500": isCancelled,
              })}
              name={completeMessageIcon()}
              size="lg"
              type="outlined"
            />
            <span className="text-base font-normal">{showMessage()}</span>
          </Show>
        </div>
      }
      when={isOverdue && isActive}
    >
      {meetingNotEndedMessage}
    </Show>
  );

  return (
    <>
      <ConfirmEndOneOnOneMeetingModal
        close={toggleConfirmEndMeeting}
        onConfirmAction={submitForm}
        open={showConfirmEndMeeting}
      />
      <ModalFooter className="flex-row items-center justify-between">
        <Show fallback={<SpinnerFit />} when={!loading}>
          <Show fallback={ShowMeetingSaveStatus} when={showPlanNextMeeting}>
            {noUpcomingMeetings}
          </Show>
          <div className="flex items-center space-x-2">
            <Button
              data-cy="jXGJ4Phyi_awSsMQYPCt8"
              onClick={close}
              variant="ghost"
              disabled={isSubmitting}
            >
              <FormattedMessage defaultMessage="Close" id="rbrahO" />
            </Button>
            <Show when={showPlanNextMeeting || !isActive}>
              <Button
                data-cy="9y6f9ihZd8nF7_zA1Tk6E"
                onClick={handlePlanNextMeeting}
                variant="normal"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {isActive ? (
                  <FormattedMessage
                    data-cy="planNextOneOnOneMeeting"
                    defaultMessage="Plan next meeting"
                    id="tbPXCm"
                  />
                ) : (
                  <FormattedMessage
                    data-cy="planNextOneOnOneMeeting"
                    defaultMessage="Activate meeting"
                    id="wRwtue"
                  />
                )}
              </Button>
            </Show>
            <Show when={showEndMeeting}>
              <WithTooltip
                tooltip={
                  dueDateInFuture ? "Meeting is scheduled in the future" : ""
                }
              >
                <Button
                  className="bg-green-500 hover:bg-green-600 active:bg-green-700"
                  data-cy="completeOneOnOneMeeting"
                  data-testid="completeOneOnOneMeeting"
                  icon="check_circle_outline"
                  iconSize="lg"
                  disabled={isSubmitting || dueDateInFuture}
                  loading={isSubmitting}
                  onClick={toggleConfirmEndMeeting}
                >
                  <FormattedMessage
                    defaultMessage="End 1:1 meeting"
                    id="SQTpZN"
                  />
                </Button>
              </WithTooltip>
            </Show>
          </div>
        </Show>
      </ModalFooter>
    </>
  );
};
