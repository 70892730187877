import React from "react";
import { FormattedMessage } from "react-intl";
import { messages as alignmentMessages } from "../../form/FormObjectiveAlignToSelect/FormObjectiveAlignToSelect.messages";

export const helpers = {
  alignToNone: (
    <>
      <div className="mb-2 font-semibold">
        <FormattedMessage defaultMessage="Alignment" id="global:alignment" />
      </div>
      <FormattedMessage {...alignmentMessages.radioNoneHelper} />
    </>
  ),
  kpi: (
    <>
      <div className="mb-2 font-semibold">
        <FormattedMessage defaultMessage="Alignment" id="global:alignment" />
      </div>
      <FormattedMessage {...alignmentMessages.radioKpiHelper} />
    </>
  ),
  kpiHelper: (
    <div className="text-sm text-slate-500">
      <FormattedMessage {...alignmentMessages.alignTokpiInfoTitle} />
    </div>
  ),
  parent: (
    <>
      <div className="mb-2 font-semibold">
        <FormattedMessage defaultMessage="Alignment" id="global:alignment" />
      </div>
      <FormattedMessage {...alignmentMessages.radioOkrHelper} />
    </>
  ),
  strategicPillar: (
    <>
      <div className="mb-2 font-semibold">
        <FormattedMessage defaultMessage="Alignment" id="global:alignment" />
      </div>
      <FormattedMessage {...alignmentMessages.radioPillarHelper} />
    </>
  ),
};
