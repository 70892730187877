import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deleteComment: {
    defaultMessage: "Delete comment",
    id: "global:comment:deleteTitle",
  },
  deleteUpdate: {
    defaultMessage: "Delete update",
    id: "global:update:deleteTitle",
  },
  unknownUser: {
    defaultMessage: "Unknown User",
    id: "global:unknownUser",
  },
});
