export type AsanaIntegrationFieldData = {
  projectGid: string;
  taskGid: string | null;
  workspaceGid: string;
};

export const asanaDataToIntegrationField = (
  data: AsanaIntegrationFieldData
): string => JSON.stringify(data);

export const integrationFieldToAsanaData = (
  integrationField: string
): AsanaIntegrationFieldData => {
  return JSON.parse(integrationField);
};
