import { Disclosure, Transition } from "@headlessui/react";
import React, { Fragment, ReactNode } from "react";
import { ExpandIcon } from "common/icons/ExpandIcon";
import { ConditionalWrapper } from "common/wrappers";
import { twMerge } from "utils/twMerge";

type Behaviour = "hide" | "unmount";

interface Props {
  behaviour?: Behaviour;
  children: ReactNode;
  className?: string;
  "data-testid"?: string;
  defaultOpen?: boolean;
  header: ReactNode;
}

export const Accordion = ({
  className,
  children,
  defaultOpen = false,
  "data-testid": dataTestId,
  header,
  behaviour = "unmount",
}: Props): JSX.Element => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <section className="divide-y rounded border bg-white">
          <Disclosure.Button
            as={Fragment}
            data-cy="accordionButton"
            data-testid={dataTestId ?? "accordionButton"}
          >
            <div className="group flex cursor-pointer items-center space-x-2 p-5">
              <ExpandIcon
                size="2xl"
                className="text-blue-500 hover:text-indigo-800"
                expanded={open}
              />
              {header}
            </div>
          </Disclosure.Button>
          <ConditionalWrapper
            condition={behaviour === "unmount"}
            wrapper={(inner) => (
              <Transition
                enter="transition-opacity duration-100 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-75 ease-out"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                {inner}
              </Transition>
            )}
          >
            <Disclosure.Panel
              className={twMerge("p-5", className)}
              data-testid="accordionBody"
              unmount={behaviour === "unmount"}
            >
              {children}
            </Disclosure.Panel>
          </ConditionalWrapper>
        </section>
      )}
    </Disclosure>
  );
};
