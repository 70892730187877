import React from "react";
import { Spinner } from "common/placeholders/Spinner/Spinner";

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactElement;
  loading?: boolean;
};

/**
 * like Suspense but with an explicit `loading` state. if loading, shows `fallback` (or loading spinner, if not provided).
 */
export const LoadingManager = ({
  children,
  loading,
  fallback,
}: Props): JSX.Element => {
  if (loading) {
    return fallback ?? <Spinner size="small" />;
  }

  return <>{children}</>;
};
