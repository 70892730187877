import { PlanFeatures } from "common/inAppUpgrade/constants";

/**
 * features with custom logic.
 */
export enum Feature {
  engagementReport = "engagementReport",
  goalTags = "goalTags",
  kpiBoards = "kpiBoards",
  kudos = "kudos",
  oneOnOnes = "1:1s",
  performanceReviews = "Performance Reviews",
  privateGoals = "privateGoals",
  sharedGoals = "sharedGoals",
  teamReviews = "teamReviews",
}

/**
 * feature flags - used to enable a feature for a subset of customers using the superadmin panel.
 */
type SupportedFeatureFlags = never;
// to add support for feature flags, do:
// type SupportedFeatureFlags = (typeof FeatureFlags)["TIMELINE"];
// type SupportedFeatureFlags = (typeof FeatureFlags)["TIMELINE"] | ...;

/**
 * plan features depend on company tier, i.e. free, premium, supreme.
 */
const SupportedPlanFeatures = [
  "BUTTON_BOOKMARK_STAR",
  "BUTTON_EXPLORE_GRAPHS",
  "BUTTON_BOOKMARK_STAR",
  "BUTTON_EXPLORE_GRAPHS",
  "BUTTON_EXPORTS",
  "DROPDOWN_SSO_PROVIDER",
  "DROPDOWN_USER_ROLE",
  "GRAPH_POT",
  "INPUT_KPI_TARGET",
  "INPUT_RESULT_DUE_DATE",
  "INTEGRATION_API",
  "INTEGRATION_ASANA",
  "INTEGRATION_HRIS",
  "INTEGRATION_JIRA",
  "INTEGRATION_SCIM",
  "MENU_CLONE_OBJECTIVE",
  "PAGE_EXPLORE",
  "PAGE_PERFORMANCE",
  "PAGE_ROADMAP",
  "PAGE_TIMELINE",
  "PRIVATE_MODE",
  "GOAL_TAGS",
] satisfies PlanFeatures[];

export type AccessFeature =
  | Feature
  | SupportedFeatureFlags
  | (typeof SupportedPlanFeatures)[number];

export type useFeatureAccessHook = {
  badge: boolean | undefined;
  show: boolean | undefined;
};
