import { ProgressDriver } from "@graphql";
import { FormattedMessage } from "react-intl";

export const messages = {
  progressDriverToolip: {
    [ProgressDriver.KeyResults]: (
      <FormattedMessage
        defaultMessage="Progress and status is based on the Objective's Key Results, which are all weighted equally. Initiatives and Aligned OKRs don't affect its progress or status."
        id="upFboN"
      />
    ),
    [ProgressDriver.AlignedObjectives]: (
      <FormattedMessage
        defaultMessage="Progress and status is based on the Objective's Aligned OKRs, which are all weighted equally. Key Results and Initiatives don't affect its progress or status."
        id="dx1Xed"
      />
    ),
  },
  progressTooltip: {
    [ProgressDriver.KeyResults]: (
      <FormattedMessage
        defaultMessage="Progress is based on the Objective's Key Results, which are all weighted equally. Initiatives and Aligned OKRs don't affect its progress. You can change this setting under Progress driver."
        id="khdxMe"
      />
    ),
    [ProgressDriver.AlignedObjectives]: (
      <FormattedMessage
        defaultMessage="Progress is based on the Objective's Aligned OKRs. Key Results and Initiatives don't affect its progress. You can change this setting under Progress driver."
        id="AtDPgQ"
      />
    ),
  },
  statusTooltip: {
    [ProgressDriver.KeyResults]: (
      <FormattedMessage
        defaultMessage="Status is based on the Objective's Key Results. Initiatives and Aligned OKRs don't affect the status. You can change this setting under Progress driver."
        id="LMk0cL"
      />
    ),
    [ProgressDriver.AlignedObjectives]: (
      <FormattedMessage
        defaultMessage="Status is based on the Objective's Aligned OKRs. Key Results and Initiatives don't affect the status. You can change this setting under Progress driver."
        id="wWZyFP"
      />
    ),
  },
};
