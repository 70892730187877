import { GetActiveUsersQueryVariables, StrategicPillar } from "@graphql";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { For } from "common/controlFlow";
import { Icon } from "common/icons";
import { useSelect } from "common/inputs/Select/useSelect";
import { TextOverflowTooltip } from "common/overlay/Tooltip/TextOverflowTooltip/TextOverflowTooltip";
import { TextSkeleton } from "common/placeholders/TextSkeleton/TextSkeleton";
import { useStrategicPillars } from "hooks/useStrategicPillars/useStrategicPillars";

type Value = string | null;
type Option = Pick<StrategicPillar, "name" | "id" | "mapIndex">;

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => Promise<boolean>;
  value?: Value;
  variables?: GetActiveUsersQueryVariables;
};

const DEFAULT_OPTIONS: Option[] = [];

export const KpiAlignment = ({
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  onChange,
  value,
  disabled,
}: Props) => {
  const strategicPillars = useStrategicPillars();
  const loading = isNil(strategicPillars);
  const defaultItem = strategicPillars?.find((item) => item.id === value);
  const { selected, handleSelect, handleSearch, Select, visibleOptions } =
    useSelect<Option>({
      defaultItem,
      options: strategicPillars ?? DEFAULT_OPTIONS,
    });
  const handleChange = async (option: Option | null) => {
    if (selected?.id === option?.id) {
      handleSelect(null);
      onChange?.(null);
    } else {
      const success = await onChange?.(option?.id ?? null);
      if (success) handleSelect(option);
    }
  };

  return (
    <Select.Root
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          {loading && <TextSkeleton className="bg-slate-200" />}
          {selected && !loading && (
            <div className="flex space-x-1 items-center">
              <Icon name="account_balance" className="text-green-600" />
              <TextOverflowTooltip tooltip={selected.name}>
                <Entity name={selected.name} />
              </TextOverflowTooltip>
            </div>
          )}

          {!selected && !loading && (
            <FormattedMessage
              defaultMessage="No strategic pillar selected"
              id="XHDdrw"
            />
          )}
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20 divide-y">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <Entity name={option.name} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
