import { useGetAsanaTaskQuery } from "@graphql";
import { AsanaTask } from "./AsanaTask";

type Props = {
  taskGid: string;
};

export const AsanaTaskByGid = ({ taskGid }: Props): JSX.Element | null => {
  const { data: asanaTaskData } = useGetAsanaTaskQuery({
    variables: { gid: taskGid },
  });

  const task = asanaTaskData?.asanaTask ?? null;
  if (!task) {
    return null;
  }
  return <AsanaTask task={task} />;
};
