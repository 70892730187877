import {
  CommitDefaultFieldsFragment,
  CommitDescriptionFragment,
  CommitValuesFragment,
} from "@graphql";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { ProgressUpdate } from "common/progress/ProgressUpdate/ProgressUpdate";
import {
  ShortProgressUpdateKpi,
  ShortProgressUpdateResult,
} from "common/progress/ShortProgressUpdate/ShortProgressUpdate";

type Commit = CommitDefaultFieldsFragment &
  CommitDescriptionFragment &
  Pick<CommitValuesFragment, "delta" | "valueBefore"> & {
    keyResult?: ShortProgressUpdateResult | null;
    kpi?: ShortProgressUpdateKpi | null;
  };

export type CommitProps = {
  commit: Commit;
  showDetails: boolean;
};

export const Commit = ({
  commit,
  showDetails,
}: CommitProps): JSX.Element | null => {
  const target = commit.keyResult ?? commit.kpi;
  if (!target) return null;

  const { commitDate, description, createdDate } = commit;

  return (
    <>
      {showDetails && (
        <div className="inline-block py-1">
          <ProgressUpdate commit={commit} entity={target} />{" "}
          {moment(commitDate).format("DD/MM/YYYY") !==
            moment(createdDate).format("DD/MM/YYYY") && (
            <FormattedMessage
              defaultMessage="Backdated to {date}."
              id="commit:backdate"
              values={{ date: <FormattedDate value={commitDate} /> }}
            />
          )}
        </div>
      )}
      {description && <DangerousHTML html={description} />}
    </>
  );
};
