import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "common/icons";
import { Input } from "common/inputs/Input/Input";
import { twClass } from "utils/twClass";

export type Props = {
  className?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  onChange?: (value: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
};

export const Searchbox = ({
  onChange,
  placeholder,
  onKeyDown,
  inputRef,
  className,
}: Props): JSX.Element => {
  const intl = useIntl();

  const [value, setValue] = useState("");
  const setValueAndOnChange = (val: string) => {
    setValue(val);
    onChange?.(val);
  };

  useEffect(() => {
    const resetSearch = () => onChange?.("");
    return () => {
      resetSearch();
    };
  }, []);

  const defaultPlaceholder = intl.formatMessage({
    defaultMessage: "Search...",
    id: "0BUTMv",
  });

  return (
    <div
      className={twClass(
        "space-x flex items-center px-2.5 py-0.5 space-x-2",
        className
      )}
    >
      <Icon className="text-slate-800" name="search" size="xl" />
      <Input
        ref={inputRef}
        // TODO: find out why autofocus doesn't work here.
        // Possible reason: https://github.com/tailwindlabs/headlessui/discussions/741
        autoFocus
        variant="borderless"
        formControl={false}
        onChange={(e) => setValueAndOnChange(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeholder ?? defaultPlaceholder}
        tabIndex={0}
        type="text"
        value={value}
      />
    </div>
  );
};
