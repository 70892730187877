import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emptyStateCta: {
    defaultMessage: "<a>Configure</a>",
    id: "integrations:select:emptyStateCta",
  },
  emptyStateMessage: {
    defaultMessage:
      "No integrations active. Enable Jira, Asana or Google Sheets for automatic updates.",
    id: "integrations:select:emptyStateMessage",
  },
  integrationLabel: {
    defaultMessage: "Integration",
    id: "result:form:integration:label",
  },
});
