import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alignmentLabel: {
    defaultMessage:
      "Pick which goal this Objective supports, or choose to skip alignment.",
    id: "objective:pick:alignment",
  },
  alignSelectReason: {
    defaultMessage: "What will this OKR do?",
    id: "objective:forms:alignment:selectReason",
  },
  alignSelectReasonTooltip: {
    defaultMessage:
      "It's not possible to align a private OKR. Make this Objective public to align it.",
    id: "objective:forms:alignment:alignSelectReasonTooltip",
  },
  alignTokpiInfoTitle: {
    defaultMessage: "You can only choose from KPIs that have a target value",
    id: "objective:forms:kpi:info:title",
  },
  emptySelection: {
    defaultMessage: "Selection is empty.",
    id: "objective:forms:alignment:empty:warning",
  },
  kpiAlignmentPlaceholder: {
    defaultMessage: "Select the KPI you’ll improve",
    id: "objective:form:kpi:alignment:placeholder",
  },
  objAlignmentPlaceholder: {
    defaultMessage: "Select the OKR that this one supports",
    id: "objective:form:alignment:placeholder",
  },
  pillarAlignmentPlaceholder: {
    defaultMessage: "Select the Strategic Pillar this supports",
    id: "objective:form:pillar:alignment:placeholder",
  },
  pleaseSelectOneOption: {
    defaultMessage: "Please select what this OKR will do",
    id: "objective:forms:alignment:info:warning",
  },
  radioKpi: {
    defaultMessage: "Improve a KPI",
    id: "objective:form:radio:kpi",
  },
  radioKpiHelper: {
    defaultMessage:
      "Align this OKR to a KPI to fix the KPI or take it to the next level.",
    id: "objective:form:radio:kpi:helper",
  },
  radioNone: {
    defaultMessage: "Skip",
    id: "objective:form:radio:none",
  },
  radioNoneHelper: {
    defaultMessage:
      "Choose to not align this OKR. You can add alignment later.",
    id: "objective:form:radio:none:helper",
  },
  radioOkr: {
    defaultMessage: "Support another OKR",
    id: "objective:form:radio:okr",
  },
  radioOkrHelper: {
    defaultMessage:
      "Align to another Objective, e.g. quarterly OKR supports annual OKR.",
    id: "objective:form:radio:okr:helper",
  },
  radioPillar: {
    defaultMessage: "Support a Strategic Pillar",
    id: "objective:form:radio:pillar",
  },
  radioPillarHelper: {
    defaultMessage:
      "Align this OKR to company strategy, ideal for long-term OKRs.",
    id: "objective:form:radio:pillar:helper",
  },
});
