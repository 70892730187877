import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Editor } from "@tiptap/react";
import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Panel } from "common/overlay/Panel/Panel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useToggle } from "hooks/useToggle/useToggle";

interface Props {
  editor: Editor | null;
}

export const ToolBarEmoji = ({ editor }: Props): JSX.Element => {
  const [overlayOpen, togglePanel] = useToggle(false);

  // @ts-expect-error'emoji' implicitly has an 'any' type.
  const addEmoji = (emoji) => {
    if (editor) {
      const cursorPosition = editor.state.selection.anchor;
      // eslint-disable-next-line no-irregular-whitespace
      const emojiAsText = ` ${emoji.native} `;
      editor.commands.insertContentAt(cursorPosition, emojiAsText, {
        parseOptions: {
          preserveWhitespace: "full",
        },
        updateSelection: true,
      });
      editor
        .chain()
        .focus()
        .setTextSelection(cursorPosition + emojiAsText.length)
        .run();
    }
  };

  // @ts-expect-error'emoji' implicitly has an 'any' type.
  const handleEmojiSelect = (emoji) => {
    togglePanel();
    addEmoji(emoji);
  };

  return (
    <WithTooltip
      interactive
      theme="perdoo-white"
      tooltip={
        overlayOpen && (
          <Panel onClose={togglePanel} id="emoji-picker">
            <Picker
              autoFocus
              data={data}
              theme="light"
              onEmojiSelect={handleEmojiSelect}
            />
          </Panel>
        )
      }
      visible
    >
      <IconButton
        data-cy="7MBZaZh3jz8eNFoK0Ei-t"
        name="tag_faces"
        onClick={() => togglePanel()}
        size="2xl"
      />
    </WithTooltip>
  );
};
