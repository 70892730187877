import { QueryOptions } from "@apollo/client";
import {
  PerdooApiObjectiveStageChoices,
  SubObjectivesDocument,
} from "@graphql";

/**
 * for displaying aligned objectives, we show 4 tabs for each objectvie stage. to be able to refetch these, we need to
 * provide all the variables. this helper returns all 4 as an array.
 */
export const getAlignedObjectivesTabsQueries = (
  alignedTo: string
): QueryOptions[] => [
  {
    query: SubObjectivesDocument,
    variables: {
      alignedTo,
      first: 10,
      orderBy: "cadence_duration,timeframe__start_date,name",
      stage: PerdooApiObjectiveStageChoices.Active,
    },
  },
  {
    query: SubObjectivesDocument,
    variables: {
      alignedTo,
      first: 10,
      orderBy: "cadence_duration,timeframe__start_date,name",
      stage: PerdooApiObjectiveStageChoices.Draft,
    },
  },
  {
    query: SubObjectivesDocument,
    variables: {
      alignedTo,
      first: 10,
      orderBy: "cadence_duration,timeframe__start_date,name",
      stage: PerdooApiObjectiveStageChoices.Closed,
    },
  },
  {
    query: SubObjectivesDocument,
    variables: {
      alignedTo,
      first: 10,
      orderBy: "cadence_duration,timeframe__start_date,name",
      stage: "",
    },
  },
];
