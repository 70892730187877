import { FieldInputProps } from "formik";
import React from "react";
import { Label } from "common/inputs/Label/Label";
import { Toggle } from "common/inputs/Toggle/Toggle";

type Props = {
  afterChange?: (value: boolean) => void;
  "data-cy": string;
  "data-testid"?: string;
  field: FieldInputProps<any>;
  label: string;
};
export const FormToggle = ({
  label,
  afterChange,
  field,
  "data-cy": dataCy,
  "data-testid": dataTestId,
}: Props): JSX.Element => {
  const handleChange = (value: boolean) => {
    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
    if (afterChange) {
      afterChange(value);
    }
  };

  return (
    <div className="flex items-center gap-3">
      <Toggle
        data-cy={dataCy}
        data-testid={dataTestId}
        on={field.value}
        onToggle={handleChange}
      />
      <Label className="text-md">{label}</Label>
    </div>
  );
};
