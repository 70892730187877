import { useField } from "formik";
import React from "react";
import { TwitterPicker } from "react-color";
import { Button } from "common/buttons";
import { Icon } from "common/icons";
import { Panel } from "common/overlay/Panel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useToggle } from "hooks/useToggle/useToggle";

interface Props {
  name: string;
}
export const ColorPickerField = ({ name }: Props): JSX.Element => {
  const [field, , helpers] = useField(name);
  const [tooltipOpen, toggleTooltipOpen] = useToggle(false);
  return (
    <div className="flex">
      <WithTooltip
        className="overflow-hidden"
        interactive
        maxWidth=""
        placement="right"
        theme="perdoo-white"
        tooltip={
          tooltipOpen && (
            <Panel onClose={toggleTooltipOpen}>
              <TwitterPicker
                color={field.value}
                onChange={(value) => {
                  helpers.setValue(value.hex);
                  toggleTooltipOpen();
                }}
                triangle="hide"
              />
            </Panel>
          )
        }
        visible={tooltipOpen}
      >
        <Button
          data-cy="KT9ySJbcyOrfueQ3clHAM"
          onClick={toggleTooltipOpen}
          variant="ghost"
        >
          <div className="flex items-center">
            <div
              className="mr-2 h-5 w-5 rounded"
              style={{ backgroundColor: field.value }}
            />
            <Icon name="keyboard_arrow_down" size="lg" />
          </div>
        </Button>
      </WithTooltip>
    </div>
  );
};
