import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const EmptyWrapper = styled.div`
  padding: 12px 21px;
  text-align: center;
  width: 400px;
`;

export const NotificationEmptyList = (): JSX.Element => (
  <EmptyWrapper>
    <FormattedMessage
      defaultMessage="You haven't received any notifications yet"
      id="navbar:notifications:empty"
    />
  </EmptyWrapper>
);
