import { every, includes, mapKeys, snakeCase } from "lodash";
import { callApi } from "../../../utils/api";
import { beforeUnloadHandler } from "../../../utils/utils";

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "CALL_API";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export const api = () => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  const {
    endpoint,
    entityType,
    entityId,
    types,
    method = "GET",
    payload,
    filters,
    queryParams,
  } = callAPI;

  if (typeof endpoint !== "string" && !entityType && !entityId) {
    throw new Error("Specify a string endpoint URL or an entity.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }

  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const finalEndpoint =
    endpoint || `/${snakeCase(entityType)}${entityId ? `/${entityId}` : ""}`;

  const finalQueryParams = {
    ...mapKeys(filters, (val, key) => `filter[${key}]`),
    ...queryParams,
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  const actionWith = (data) => {
    const finalAction = {
      endpoint: finalEndpoint,
      entityId,
      entityType,
      filters,
      ...action,
      ...data,
    };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  if (includes(["POST", "PATCH"], method)) {
    window.addEventListener("beforeunload", beforeUnloadHandler);
  }

  return callApi(finalEndpoint, {
    data: payload,
    method,
    params: finalQueryParams,
  }).then(
    (response) => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);

      next(
        actionWith({
          // If only specific fields are being fetched (using fields[]) , don't cache the response
          cache: every(
            Object.keys(finalQueryParams),
            (param) => !param.includes("fields")
          ),

          payload: response,

          type: successType,
        })
      );
      return Promise.resolve(response);
    },
    (err) => {
      next(
        actionWith({
          error: err,
          type: failureType,
        })
      );

      return Promise.reject(err);
    }
  );
};
