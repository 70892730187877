import { useFormikContext } from "formik";
import { isNil, isUndefined } from "lodash";
import React, { useState } from "react";
import { ReactNode } from "react";
import { AsanaProgressType } from "common/form/AsanaProgressTypeField";
import { AsanaProjectFieldProject } from "common/result/ResultForm/AsanaProjectField/AsanaProjectField";
import { AsanaTaskFieldTask } from "common/result/ResultForm/AsanaTaskField/AsanaTaskField";
import { AsanaWorkspaceFieldWorkspace } from "common/result/ResultForm/AsanaWorkspaceField/AsanaWorkspaceField";
import { isSet } from "utils/isSet";

type AsanaFormContext = {
  onProgressTypeSelected?: (type: AsanaProgressType) => void;
  onProjectSelected?: (project: AsanaProjectFieldProject | null) => void;
  onTaskSelected?: (task: AsanaTaskFieldTask | null) => void;
  onWorkspaceSelected?: (
    workspace: AsanaWorkspaceFieldWorkspace | null
  ) => void;
  project?: AsanaProjectFieldProject | null;
  task?: AsanaTaskFieldTask | null;
  workspace?: AsanaWorkspaceFieldWorkspace | null;
};

const AsanaFormContext = React.createContext<AsanaFormContext>({});

/**
 * The interface expected by the set of Asana fields.
 */
export type AsanaFormValues = {
  asanaProgressType?: string;
  asanaProject?: string;
  asanaTask?: string;
  asanaWorkspace?: string;
};

type Props = {
  children?: ReactNode;
};

export const useAsanaFormContext = () => React.useContext(AsanaFormContext);

export const AsanaFormLogic = <Values extends AsanaFormValues>({
  children,
}: Props) => {
  const [workspace, setWorkspace] =
    useState<AsanaWorkspaceFieldWorkspace | null>();

  const [project, setProject] = useState<AsanaProjectFieldProject | null>();
  const [progressType, setProgressType] = useState<AsanaProgressType | null>();
  const [task, setTask] = useState<AsanaTaskFieldTask | null>();

  const { setFieldValue } = useFormikContext<Values>();

  const onWorkspaceSelected = (
    newWorkspace: AsanaWorkspaceFieldWorkspace | null
  ) => {
    const previousWorkspace = workspace;
    setWorkspace(newWorkspace);

    // Ignore initial values being set
    if (isUndefined(previousWorkspace)) return;

    if (isNil(newWorkspace)) {
      setFieldValue("asanaProject", undefined);
    }

    const isDifferentWorkspace = newWorkspace?.gid !== previousWorkspace?.gid;
    if (isDifferentWorkspace) {
      setFieldValue("asanaProject", undefined);
      setFieldValue("asanaProgressType", "project");
      setFieldValue("asanaTask", undefined);
    }
  };

  const onProjectSelected = (newProject: AsanaProjectFieldProject | null) => {
    const previousProject = project;
    setProject(newProject);

    // Ignore initial values being set
    if (isUndefined(previousProject)) return;

    const isDifferentProject = newProject?.gid !== previousProject?.gid;
    if (isDifferentProject) {
      setFieldValue("asanaProgressType", "project");
      setFieldValue("asanaTask", undefined);
    }
  };

  const onTaskSelected = (newTask: AsanaTaskFieldTask | null) => {
    const previousTask = task;
    setTask(newTask);

    // Ignore initial values being set
    if (isUndefined(previousTask)) return;

    const newWorkspace = newTask?.workspace;
    const currentWorkspaceDoesntMatch =
      isSet(newWorkspace) && newWorkspace.gid !== workspace?.gid;
    if (currentWorkspaceDoesntMatch) {
      setFieldValue("asanaWorkspace", newWorkspace.gid);
    }
  };

  const onProgressTypeSelected = (newProgressType: AsanaProgressType) => {
    const previousProgressType = progressType;
    setProgressType(newProgressType);

    // Ignore initial values being set
    if (isUndefined(previousProgressType)) return;

    setFieldValue("asanaTask", undefined);
  };

  return (
    <AsanaFormContext.Provider
      value={{
        onProgressTypeSelected,
        onProjectSelected,
        onTaskSelected,
        onWorkspaceSelected,
        project,
        task,
        workspace,
      }}
    >
      {children}
    </AsanaFormContext.Provider>
  );
};
