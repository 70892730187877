import dayjs from "dayjs";
import { FieldProps } from "formik";
import { camelCase, get, isNil } from "lodash";
import React from "react";
import { PdDatePicker } from "common/inputs/PdDatePicker/PdDatePicker";
import { isSet } from "utils/isSet";

type Props = React.ComponentProps<typeof PdDatePicker> &
  Pick<FieldProps<Date>, "field" | "form"> & {
    afterChange?: (date: Date | null) => void;
    key?: string;
  };

export const FormDatePicker: React.FC<Props> = ({
  afterChange,
  className,
  field,
  form,
  key,
  label,
  placeholder,
  ...other
}) => {
  const { touched, errors, submitCount } = form;

  let errorText;
  if (submitCount > 0) {
    errorText = get(touched, field.name) && get(errors, field.name);
  }
  return (
    <PdDatePicker
      key={key}
      {...other}
      className={className}
      data-cy={other["data-cy"] || camelCase(label)}
      date={
        !isNil(field.value) && dayjs(field.value).isValid()
          ? new Date(field.value)
          : null
      }
      // @ts-expect-error FIXME: TS2322: Type 'string | false | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined' is not assignable to type 'ReactNode'.
      errorText={errorText}
      label={label}
      onChange={(value) => {
        const momentValue = isSet(value) ? dayjs(value) : null;
        field.onChange({
          target: {
            name: field.name,
            value: momentValue?.isValid() ? momentValue.toISOString() : null,
          },
        });
        if (afterChange) {
          afterChange(value);
        }
      }}
      placeholder={placeholder ?? "Date"}
    />
  );
};
