import { CommitStatus, GroupDefaultFragment } from "@graphql";
import { intersection, isEmpty, some } from "lodash";
import { Filter } from "legacy/components/filters/types";
import { selectedIncludingSubgroups } from "legacy/components/filters/utils";
import { mapEdges } from "utils/mapEdges";

export const objectiveStage: Filter = {
  applyFilter: (goal, selected) => {
    return some(selected, { id: goal.stage });
  },
  filterName: "stage",
  items: [
    { id: "ACTIVE", name: "Active" },
    { id: "CLOSED", name: "Closed" },
    { id: "DRAFT", name: "Draft" },
  ],
  multiple: true,
  name: "Stage",
  type: "checkbox",
};

export const progress: Filter = {
  applyFilter: (goal, selected) => {
    const [startValue, endValue] = selected;
    const progress = goal.delta?.progress ?? goal.progress;
    return startValue <= progress && progress <= endValue;
  },
  filterName: "normalized_value",
  max: 100,
  min: 0,
  name: "Progress",
  type: "range",
};

export const owner: Filter = {
  filterName: "contributors__id__in",
  multiple: true,
  name: "Owner",
  type: "search",
};

export const outdated: Filter = {
  filterName: "isOutdated",
  items: [
    { id: "YES", name: "Yes" },
    { id: "NO", name: "No" },
  ],
  multiple: true,
  name: "Outdated",
  type: "checkbox",
};

export const archived: Filter = {
  filterName: "isArchived",
  items: [
    { id: "YES", name: "Yes" },
    { id: "NO", name: "No" },
  ],
  multiple: true,
  name: "Archived",
  type: "checkbox",
};

export const timeframe: Filter = {
  filterName: "objective__timeframe__id__in",
  multiple: true,
  name: "Timeframe",
  type: "search",
};
export const contributor: Filter = {
  filterName: "contributors__id__in",
  multiple: true,
  name: "Contributor",
  type: "search",
};

export const status: Filter = {
  applyFilter: (goal, selected) =>
    some(selected, { id: goal.status }) ||
    some(selected, { id: goal.delta?.status }),
  filterName: "status__in",
  multiple: true,
  name: "Status",
  type: "checkbox",
};

export const objective: Filter = {
  filterName: "objective__in",
  multiple: true,
  name: "Objective",
  type: "search",
};

export const keyResults: Filter = {
  filterName: "key_result__id__in",
  multiple: true,
  name: "Key results",
  type: "search",
};

export const dueDate: Filter = {
  filterName: "overdue__in",
  items: [
    { id: "overdue", name: "Initiatives overdue" },
    { id: "not_overdue", name: "Initiatives not overdue" },
  ],
  multiple: true,
  name: "Due date",
  type: "checkbox",
};

export const lead: Filter = {
  applyFilter: (goal, selected) => some(selected, { id: goal.lead?.id }),
  filterName: "lead__id__in",
  multiple: true,
  name: "Lead",
  type: "search",
};

export const tags: Filter = {
  applyFilter: (goal, selected) =>
    some(goal.tags?.edges, (tag) => some(selected, { id: tag.node.id })),
  filterName: "tags__id__in",
  multiple: true,
  name: "Tag",
  type: "searchTags",
};

export const objectiveTags: Filter = {
  applyFilter: (goal, selected) =>
    some(goal.tags?.edges, (tag) => some(selected, { id: tag.node.id })),
  filterName: "tags__id__in",
  multiple: true,
  name: "Tags",
  type: "searchTags",
};

export const alignedTo: Filter = {
  filterName: "aligned_to",
  multiple: true,
  name: "Aligned to",
  type: "alignedToSelect",
};

export const group: Filter = {
  applyFilter: (goal, selected) => {
    let groupIds: any = [];
    if (goal?.groups) {
      groupIds = mapEdges<GroupDefaultFragment & { __typename: string }>(
        goal.groups.edges
      ).map((node) => node.id);
    }
    const selectedIds = selectedIncludingSubgroups(selected);
    return (
      !isEmpty(intersection(selectedIds, groupIds)) ||
      (some(selected, { id: goal.company?.id }) && goal.isCompanyGoal)
    );
  },
  filterName: "owner",
  multiple: true,
  name: "Group",
  type: "search",
};

export const kpiStatus: Filter = {
  applyFilter: (goal, selected) =>
    some(selected, { id: goal.lastCommitStatus }) ||
    some(selected, { id: goal.delta?.status }),
  filterName: "last_commit_status__in",
  items: [
    { id: CommitStatus.Healthy, name: "healthy" },
    { id: CommitStatus.Unhealthy, name: "unhealthy" },
  ],
  multiple: true,
  name: "Status",
  type: "checkbox",
};

export const resultType: Filter = {
  filterName: "type",
  multiple: true,
  name: "Result type",
  type: "checkbox",
};

export const alignment: Filter = {
  filterName: "unaligned",
  multiple: false,
  name: "Alignment",
  type: "search",
};
