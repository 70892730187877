import React from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { Label } from "common/inputs";
import { PermissionErrorMessage, Tooltip } from "common/overlay";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { twClass } from "utils/twClass";

type Props = {
  children: string;
  className?: string;
  htmlFor: string;
  optional?: boolean;
};

export const UserFieldLabel = ({
  children,
  className,
  htmlFor,
  optional,
}: Props): JSX.Element => {
  const { canCreateGoalsInAnyGroup } = usePermissions();
  return (
    <Label
      className={twClass("flex items-center justify-between", className)}
      htmlFor={htmlFor}
    >
      <div className="flex items-center gap-2">
        <span>{children}</span>
        <Show when={!canCreateGoalsInAnyGroup}>
          <Tooltip
            content={
              <div className="max-w-xs">
                <PermissionErrorMessage variant="addLead" />
              </div>
            }
            interactive
          >
            <Icon
              className="text-slate-500"
              name="info"
              size="md"
              type="outlined"
            />
          </Tooltip>
        </Show>
      </div>
      <Show when={optional}>
        <div className="text-sm text-slate-500">
          <FormattedMessage
            defaultMessage="Optional"
            id="global:form:optional"
          />
        </div>
      </Show>
    </Label>
  );
};
