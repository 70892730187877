import { KeyResultDefaultFragment } from "@graphql";
import { useContext } from "react";
import { TimelineContext } from "common/event/TimelineContext/TimelineContext";
import {
  ProgressUpdateHook,
  useProgressUpdate,
} from "common/goal/hooks/useProgressUpdate";
import { ProgressUpdatePanelValues } from "common/goal/ProgressUpdatePanel/ProgressUpdatePanel";

/**
 * a wrapper around {@link useProgressUpdate} that also updates objective timeline from context after submit.
 */
export const useResultProgressUpdate = (
  result?: KeyResultDefaultFragment
): ProgressUpdateHook => {
  const objectiveTimelineBag = useContext(TimelineContext);
  const { handleSubmit: submitProgressUpdate } = useProgressUpdate(result);

  const handleSubmit = async (values: ProgressUpdatePanelValues) => {
    const response = await submitProgressUpdate(values);
    objectiveTimelineBag.updateTimeline();
    return response;
  };

  return { handleSubmit };
};
