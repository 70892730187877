import { gql } from "@apollo/client";
import { GroupDefaultFragmentDoc } from "@graphql";

const USER_DIRECT_REPORTS = gql`
  fragment UserDirectReports on user {
    directReports(isActive: true) {
      edges {
        node {
          id
          fullName
          lastCheckInDate
          checkInDue
          avatar
          email
          isActive
        }
      }
    }
  }
`;

export const USER_DEFAULT_FRAGMENT = gql`
  fragment UserDefaultFields on user {
    accountStatus
    avatar
    canCreate
    canDelete
    canPatch
    company {
      id
      avatar
    }
    companyId
    createdDate
    email
    firstName
    fullName
    groups {
      edges {
        node {
          ...GroupDefault
          archived
        }
      }
    }
    groupLeaderOf {
      edges {
        node {
          id
          name
          archived
          avatar
        }
      }
    }
    id
    intercomHash
    isActive
    jobPosition
    language
    lastCheckInDate
    lastLoginDate
    lastName
    lastNotificationRead
    manager {
      id
      avatar
      fullName
      email
      isActive
    }
    ...UserDirectReports
    notificationsEnabled
    persona
    role
    status
  }
  ${USER_DIRECT_REPORTS}
  ${GroupDefaultFragmentDoc}
`;
