import { QueryOptions } from "@apollo/client";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { MODAL_TYPES } from "constants/index";
import { showModal } from "legacy/actions/actions";
import { toast } from "utils/toastr";
import { useValidateUserLimit } from "../useValidateUserLimit/useValidateUserLimit";

type Props = {
  prefillGroupId?: string;
  refetchQueries?: (string | QueryOptions)[];
};

export const useAddUserAction = ({
  prefillGroupId,
  refetchQueries,
}: Props = {}): (() => void) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { userLimitReached, freeUserLimit } = useValidateUserLimit();

  const openInviteUserModal = () => {
    if (userLimitReached) {
      toast.failure(
        intl.formatMessage(
          {
            defaultMessage:
              "You’ve reached your {count} user limit. Archive users or upgrade to add more users.",
            id: "BFi/+Z",
          },
          {
            count: freeUserLimit,
          }
        )
      );
      return;
    }

    dispatch(
      showModal(MODAL_TYPES.ADD_USER, {
        prefillGroupId,
        refetchQueries,
      })
    );
  };

  return openInviteUserModal;
};
