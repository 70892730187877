import dayjs from "dayjs";
import { capitalize } from "lodash";
import React from "react";
import { rrulestr } from "rrule";
import { useCompany } from "hooks/useCompany/useCompany";

type Props = {
  start: string;
  value: string;
};

export const FormattedRRule = ({ value, start }: Props): JSX.Element => {
  const { timezone } = useCompany();
  const rrule = rrulestr(value, {
    dtstart: dayjs(start).toDate(),
    tzid: timezone,
  });
  const text = capitalize(rrule.toText());
  return <>{text}</>;
};
