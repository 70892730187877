import React from "react";
import { twClass } from "utils/twClass";
import { toFixed } from "utils/utils";

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 180) * Math.PI) / 180;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  return `
        M ${start.x} ${start.y}
        A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x}, ${end.y}`;
}

function progressAngle(progress: number) {
  return (180 * progress) / 100;
}

type Size = "sm" | "md" | "lg" | "xl";

const sizeConfig = {
  sm: { fontClass: "text-xxs", radius: 21, stroke: 4 },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  md: { fontClass: "text-xs", radius: 25, stroke: 4 },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  lg: { fontClass: "text-sm", radius: 31, stroke: 4 },
  xl: { fontClass: "text-xl", radius: 40, stroke: 8 },
};

export type SemiCircleProgressProps = {
  className?: string;
  progress?: number | null;
  size?: Size;
};

// radius = 21, stroke = 4, font-size = 13
// radius = 25, stroke = 4, font-size = 13
// radius = 31, stroke = 4, font-size = 16
// radius = 40, stroke = 8, font-size = 23

export function SemiCircleProgress({
  progress = 0,
  className = "",
  size = "sm",
}: SemiCircleProgressProps): JSX.Element {
  const { radius, stroke, fontClass } = sizeConfig[size];
  const x = radius + stroke;
  const y = radius + stroke;
  const roundedProgress = Math.round(progress ?? 0);
  return (
    <svg
      className={className}
      data-cy="semiCircleProgress"
      height={y}
      width={x * 2}
    >
      <path
        d={describeArc(x, y, radius, 0, progressAngle(100))}
        fill="none"
        stroke="#D6D6D6"
        strokeWidth={stroke}
      />

      <path
        d={describeArc(x, y, radius, 0, progressAngle(roundedProgress))}
        fill="none"
        stroke="#05A9F4"
        strokeWidth={stroke}
      />

      <text
        className={twClass("fill-current text-slate-800", fontClass)}
        data-cy="progressPercentage"
        dominantBaseline="auto"
        textAnchor="middle"
        x="50%"
        y={y - 2}
      >
        {toFixed(roundedProgress.toString(), 0)}%
      </text>
    </svg>
  );
}
