import { BookmarkTargetType, useGetUserBookmarksQuery } from "@graphql";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { useIntl } from "react-intl";
import { routes } from "route-configs";
import { Avatar } from "common/avatar";
import { BookmarkButton } from "common/buttons/BookmarkButton/BookmarkButton";
import { Icon } from "common/icons";
import { useIsActive } from "hooks/routes/useIsActive";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { mapEdges } from "utils/mapEdges";
import { isGroupActive } from "utils/routes/isGroupActive";
import { isProfileActive } from "utils/routes/isProfileActive";
import { SidebarLink } from "../SidebarLink/SidebarLink";
import { SidebarSection } from "../SidebarSection/SidebarSection";
import { messages } from "./Favorites.messages";
import { getBookmarkLink, getBookmarksByType } from "./Favorites.utils";

export const Favorites = (): JSX.Element | null => {
  const intl = useIntl();
  const currentUser = useCurrentUser();
  const { id, name } = useCompany();
  const isActive = useIsActive();
  const { data } = useGetUserBookmarksQuery();
  const { asPath: pathname } = useRouter();
  const { objectiveHref, kpiHref } = useModalRouter();

  const allBookmarks = mapEdges(data?.me?.bookmarks?.edges);

  if (isEmpty(allBookmarks)) {
    return null;
  }

  const { groups, objectives, users, kpis, company } =
    getBookmarksByType(allBookmarks);

  return (
    <SidebarSection label={intl.formatMessage(messages.favorites)}>
      <div className="overflow-x-hidden">
        <div>
          {company && (
            <SidebarLink
              active={isActive.companyGroup}
              icon={
                <Avatar customSize={20} url={currentUser?.company.avatar} />
              }
              className="group/sidebarlink"
              label={
                <div className="max-w-[190px]">
                  <div className="w-full truncate">{name}</div>
                </div>
              }
              link={routes.company.okrs}
              rhs={
                <BookmarkButton
                  className="invisible group-hover/sidebarlink:visible"
                  targetId={id ?? ""}
                  data-cy="unbookmark-company"
                  targetType={BookmarkTargetType.Company}
                />
              }
            />
          )}
          {groups.map((group) => {
            return (
              <SidebarLink
                key={group.id}
                active={isGroupActive(pathname, group.id)}
                className="group/sidebarlink"
                icon={<Avatar customSize={20} url={group.avatar} />}
                label={group.name}
                link={getBookmarkLink(group.id, "group")}
                rhs={
                  <BookmarkButton
                    data-cy={`unbookmark-group-${group.id}`}
                    className="invisible group-hover/sidebarlink:visible"
                    targetId={group.id}
                    targetType={BookmarkTargetType.Group}
                  />
                }
              />
            );
          })}
          {users.map((user) => {
            return (
              <SidebarLink
                key={user.id}
                active={isProfileActive(pathname, user.id)}
                className="group/sidebarlink"
                icon={<Avatar customSize={20} url={user.avatar} />}
                label={user.name}
                link={getBookmarkLink(user.id, "user")}
                rhs={
                  <BookmarkButton
                    data-cy={`unbookmark-user-${user.id}`}
                    className="invisible group-hover/sidebarlink:visible"
                    targetId={user.id}
                    targetType={BookmarkTargetType.User}
                  />
                }
              />
            );
          })}
          {objectives.map((objective) => {
            return (
              <SidebarLink
                key={objective.id}
                className="group/sidebarlink"
                icon={<Icon name="outlined_flag" size="lg" />}
                label={objective.name}
                link={objectiveHref({ oId: objective.id }).toString()}
                rhs={
                  <BookmarkButton
                    data-cy={`unbookmark-objective-${objective.id}`}
                    className="invisible group-hover/sidebarlink:visible"
                    targetId={objective.id}
                    targetType={BookmarkTargetType.Objective}
                  />
                }
              />
            );
          })}
          {kpis.map((kpi) => {
            return (
              <SidebarLink
                key={kpi.id}
                className="group/sidebarlink"
                icon={<Icon name="monitor_heart" size="lg" type="outlined" />}
                label={kpi.name}
                link={kpiHref({ kpiId: kpi.id })}
                rhs={
                  <BookmarkButton
                    data-cy={`unbookmark-kpi-${kpi.id}`}
                    className="invisible group-hover/sidebarlink:visible"
                    targetId={kpi.id}
                    targetType={BookmarkTargetType.Kpi}
                  />
                }
              />
            );
          })}
        </div>
      </div>
    </SidebarSection>
  );
};
