import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { Icon } from "common/icons";
import { useSelect } from "common/inputs/Select/useSelect";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";

type Value = boolean;

type Option = {
  description: ReactNode;
  icon: ReactNode;
  id: string;
  label: ReactNode;
};

const options: Option[] = [
  {
    description: (
      <FormattedMessage
        defaultMessage="Anyone in the company can see this Objective"
        id="wdd0+C"
      />
    ),
    icon: <Icon name="public" />,
    id: "public",
    label: <FormattedMessage defaultMessage="Public" id="uhu5aG" />,
  },
  {
    description: (
      <FormattedMessage
        defaultMessage="Visible only to Leads & Contributors"
        id="08dyk+"
      />
    ),
    icon: <Icon name="lock" type="outlined" />,
    id: "private",
    label: <FormattedMessage defaultMessage="Private" id="viXE32" />,
  },
];

type Props = {
  className?: string;
  "data-cy"?: string;
  disabled?: boolean;
  isPrivate: Value;
  onChange?: (value: Value) => Promise<boolean>;
  tooltip?: ReactNode;
};

export const InlineObjectiveVisibilitySelect = ({
  className,
  "data-cy": dataCy,
  onChange,
  isPrivate,
  disabled,
  tooltip,
}: Props) => {
  const value = isPrivate ? "private" : "public";
  const idToValue = (str: string) => str === "private";
  const defaultItem = options.find((option) => option.id === value);
  const { Select, selected, visibleOptions, handleSelect } = useSelect<Option>({
    defaultItem,
    options,
  });

  const handleChange = async (option: Option | null) => {
    if (!option) return;
    const successful = await onChange?.(idToValue(option.id));
    if (!successful) return;
    handleSelect(option);
  };

  return (
    <Select.Root
      data-cy={dataCy}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          <WithTooltip tooltip={tooltip} className="inline-block">
            <div className="flex items-center space-x-2">
              {selected?.icon}
              <div>{selected?.label}</div>
            </div>
          </WithTooltip>
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20">
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <div className="flex items-center space-x-2">
                  {option.icon}
                  <div>
                    <div>{option.label}</div>
                    <div className="text-sm">{option.description}</div>
                  </div>
                </div>
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
