import React from "react";
import { PerformanceReviewCompleteFormLayout } from "common/performanceReview/PerformanceReviewForm/PerformanceReviewCompleteFormLayout";
import { twClass } from "utils/twClass";

type Props = {
  children: React.ReactNode;
};
type LayoutProps = {
  children: React.ReactNode;
};

export const OneOnOneFormContent = ({ children }: Props): JSX.Element => (
  <div
    className={twClass(
      "col-span-3 flex h-full overflow-y-hidden",
      "flex min-h-0 grow",
      "h-full w-full overflow-y-auto",
      "modal-scrollbar modal-scrollbar-thumb"
    )}
  >
    {children}
  </div>
);

export const OneOnOneFormSidebar = ({ children }: Props): JSX.Element => (
  <div
    className="modal-scrollbar modal-scrollbar-thumb h-full overflow-y-auto bg-slate-50"
    data-testid="oneOnOneSidebar"
  >
    {children}
  </div>
);

export const OneOnOneModalLayout = ({ children }: LayoutProps): JSX.Element => (
  <PerformanceReviewCompleteFormLayout layoutKey="oneOnOneModal">
    {children}
  </PerformanceReviewCompleteFormLayout>
);

OneOnOneModalLayout.Sidebar = OneOnOneFormSidebar;
OneOnOneModalLayout.Content = OneOnOneFormContent;
