import { GoalUpdateCycle } from "@graphql";
import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  every_4_months: {
    defaultMessage: "Every 4 Months",
    id: "goalUpdateFrequency:label:every_4_months",
  },
  every_6_months: {
    defaultMessage: "Every 6 Months",
    id: "goalUpdateFrequency:label:every_6_months",
  },
  every_two_weeks: {
    defaultMessage: "Every two weeks",
    id: "goalUpdateFrequency:label:every_two_weeks",
  },
  monthly: {
    defaultMessage: "Monthly",
    id: "goalUpdateFrequency:label:monthly",
  },
  quarterly: {
    defaultMessage: "Quarterly",
    id: "goalUpdateFrequency:label:quarterly",
  },
  weekly: {
    defaultMessage: "Weekly",
    id: "goalUpdateFrequency:label:weekly",
  },
  yearly: {
    defaultMessage: "Yearly",
    id: "goalUpdateFrequency:label:yearly",
  },
});

const updateFrequencyOptions = [
  {
    message: messages.weekly,
    value: GoalUpdateCycle.Weekly,
  },
  {
    message: messages.every_two_weeks,
    value: GoalUpdateCycle.Biweekly,
  },
  {
    message: messages.monthly,
    value: GoalUpdateCycle.Monthly,
  },
  {
    message: messages.quarterly,
    value: GoalUpdateCycle.Quarterly,
  },
  {
    message: messages.every_4_months,
    value: GoalUpdateCycle.Every_4Months,
  },
  {
    message: messages.every_6_months,
    value: GoalUpdateCycle.Every_6Months,
  },
  {
    message: messages.yearly,
    value: GoalUpdateCycle.Yearly,
  },
];

export type GoalUpdateFrequencyOption = {
  id: GoalUpdateCycle;
  label: string;
  value: GoalUpdateCycle;
};

export const useGoalUpdateFrequencyOptions =
  (): GoalUpdateFrequencyOption[] => {
    const intl = useIntl();

    const localizedOptions = useMemo(
      () =>
        updateFrequencyOptions.map(({ message, value }) => ({
          id: value,
          label: intl.formatMessage(message),
          message,
          value,
        })),
      [intl]
    );

    if (!localizedOptions.length || !localizedOptions[0])
      throw new Error(
        "At least one GoalUpdateFrequencyOption must be configured"
      );

    return localizedOptions;
  };
