import classNames from "clsx";
import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const PanelFooter = ({ className, children }: Props): JSX.Element => (
  <div
    className={classNames(
      "flex items-center justify-end",
      "space-x-4 px-5 py-4",
      "rounded-b border-t bg-slate-50",
      className
    )}
  >
    {children}
  </div>
);
