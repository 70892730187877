import { ApolloError } from "@apollo/client";
import {
  camelCase,
  filter,
  get,
  includes,
  isArray,
  isNil,
  isNumber,
  isPlainObject,
  isString,
  mapKeys,
  mapValues,
  mergeWith,
} from "lodash";
import React from "react";
import { IntlShape } from "react-intl";
import { AllMetrics, MetricUnits, MetricValue } from "constants/metric";
import { isSet } from "./isSet";
import { toast } from "./toastr";

// @ts-expect-error ts-migrate(7023) FIXME: 'mapKeysDeep' implicitly has return type 'any' bec... Remove this comment to see the full error message
const mapKeysDeep = (obj, cb) => {
  if (isString(obj) || isNumber(obj)) {
    return obj;
  }
  return mapValues(mapKeys(obj, cb), (val) => {
    if (isPlainObject(val)) {
      return mapKeysDeep(val, cb);
    }
    if (isArray(val)) {
      return val.map((item) => mapKeysDeep(item, cb));
    }
    return val;
  });
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
export const camelizeKeys = (obj) =>
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  mapKeysDeep(obj, (val, key) => camelCase(key));

export const detectBrowser = () => {
  // TODO: [no-unnecessary-condition] remove and fix
  // @ts-expect-error ts-migrate(2551) FIXME: Property 'documentMode' does not exist on type 'Do... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isIE = false || !!document.documentMode;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'StyleMedia' does not exist on type 'Wind... Remove this comment to see the full error message
  const isEdge = !isIE && !!window.StyleMedia;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'chrome' does not exist on type 'Window &... Remove this comment to see the full error message
  const isChrome = !!window.chrome && !!window.chrome.webstore;
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'InstallTrigger'.
  const isFirefox = typeof InstallTrigger !== "undefined";
  return { isChrome, isEdge, isFirefox, isIE };
};

/**
 * Utility to attach suitable suffixes to a large positive number, such as "200K", and "20M"
 *
 * @deprecated Use `useFormatMetric` or `FormattedMetric` instead
 */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
// eslint-disable-next-line
export const abbreviateNumber = (number, intl) => {
  // First convert the number to a positive number, since this function only works for positive numbers
  const positiveNumber = Math.abs(Number(number));
  const suffixes = ["", "K", "M", "B", "T"];
  let power = 0;
  let formattedNumber = Math.floor(positiveNumber);

  // Reduce the number to get the highest power of 1000 it is divisible by.
  while (formattedNumber >= 1000) {
    formattedNumber = Math.floor(formattedNumber / 1000);
    power += 1;
  }

  // Calculate the digits supposed to appear after the decimal
  // Since we know the highest power of 1000 this number is divisible by, we get the remainder
  // obtained by dividing the positive number by this power.
  const significantDigits = positiveNumber % 1000 ** power;

  // If significantDigits is non-zero
  if (significantDigits) {
    // Adjust significantDigits powers and add to the formattedNumber to get the final number
    formattedNumber += significantDigits / 1000 ** power;
    // Fix the numbers after the decimal point to 3
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
    formattedNumber = formattedNumber.toFixed(1);
    // Convert it back to a JS Number to trim any unnecessary zeroes
    formattedNumber = Number(formattedNumber);
  }

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
  formattedNumber = formattedNumber.toLocaleString(
    intl ? intl.locale : undefined
  );

  // Finally, attach the right suffix, and return
  return `${formattedNumber}${suffixes[power]}`;
};

const separateNumber = (number: number, intl: IntlShape): string => {
  const positiveNumber = Math.abs(Number(number));

  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return positiveNumber.toLocaleString(intl ? intl.locale : undefined);
};

/**
 * Helper function to format keyresult metrics
 *
 * @deprecated Use `useFormatMetric` or `FormattedMetric` instead
 */
export const formatPdMetric = (
  intl: IntlShape,
  value: number,
  metricUnit: MetricValue | null,
  { abbreviate = false, separators = false } = {}
): string => {
  let style: "decimal" | "percent" | "currency" = "decimal";
  let currency = "EUR";
  let val = value;

  if (metricUnit === MetricUnits.PERCENTAGE) {
    style = "percent";
    val = value / 100;
  }

  const nonCurrenciesMetricValues: MetricValue[] = [
    MetricUnits.PERCENTAGE,
    MetricUnits.NUMERICAL,
  ];
  if (isSet(metricUnit) && !nonCurrenciesMetricValues.includes(metricUnit)) {
    style = "currency";
    currency = get(AllMetrics, [metricUnit, "abbreviation"]);
  }

  let formattedVal = intl.formatNumber(val, {
    currency,
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
    style,
    useGrouping: false,
  });

  // remove weird space before unit that is added in german
  formattedVal = formattedVal.replace(/\s/g, "");
  if (abbreviate) {
    formattedVal = formattedVal.replace(
      /[0-9|.|,]+/,
      abbreviateNumber(value, intl)
    ); // Abbreviate number
  } else if (separators) {
    formattedVal = formattedVal.replace(
      /[0-9|.|,]+/,
      separateNumber(value, intl)
    ); // Add digits separators
  }

  return formattedVal;
};

// helper function to format dates
/** @deprecated Use `FormattedDate` instead */
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'intl' implicitly has an 'any' type.
export const formatPdDate = (intl, val) =>
  intl.formatDate(val, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dataUrl' implicitly has an 'any' type.
export const dataUrlToBlob = (dataUrl) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUrl.split(",")[1]);

  // separate out the mime component
  const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
};

export const toFixed = (
  numberOrString: string | number,
  fractionDigits = 2
): string => {
  let number = numberOrString;
  if (isString(number)) number = parseFloat(number);
  return number.toFixed(fractionDigits);
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'test' implicitly has an 'any' typ... Remove this comment to see the full error message
export const Branch = ({ test, right, left }) => {
  return <>{test ? left : right}</>;
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'test' implicitly has an 'any' typ... Remove this comment to see the full error message
export const BranchLazy = ({ test, right, left }) => {
  return <>{test ? left() : right()}</>;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
export const beforeUnloadHandler = (e) => {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = "";
};

export const setMobileViewport = () => {
  const viewportMeta = document.getElementById("viewport-meta");
  viewportMeta?.setAttribute("content", "width=device-width");
};

export const setDesktopViewport = () => {
  const viewportMeta = document.getElementById("viewport-meta");
  viewportMeta?.setAttribute("content", "width=1280");
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'entities' implicitly has an 'any' type.
export const filterEntities = (entities, query, filterColumn = "name") => {
  let data = [];
  if (query === "") {
    data = entities;
  } else {
    data = filter(entities, (entity) =>
      includes(get(entity, filterColumn, "").toLowerCase(), query.toLowerCase())
    );
  }

  return data;
};

// apolloQueryMerger works only on a single nested level queries
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prev' implicitly has an 'any' type.
export const apolloQueryMerger = (prev, { fetchMoreResult }) => {
  return mergeWith({}, prev, fetchMoreResult, (objValue, srcValue) =>
    isArray(objValue) ? objValue.concat(srcValue) : undefined
  );
};

export const showServerErrorToast = (err?: any): void => {
  if (get(err, "networkError.result.errors[0].status") === "400") {
    toast.failure(
      get(err, "networkError.result.errors[0].detail", "Unknown error")
    );
  }
};

export const handleError = (err: ApolloError) => {
  if (!err.networkError) {
    toast.failure(`${err.name}: ${err.message}`);
    return;
  }
  showServerErrorToast(err);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
export const showGqlErrorToast = (response) => {
  const { errors } = response;
  if (Array.isArray(errors)) {
    errors.forEach((error) =>
      toast.failure(get(error, "message", "Unknown error"))
    );
  }
};

export const isValidCommitNumber = (val?: string | null): boolean => {
  if (isNil(val)) {
    return false;
  }

  // allow both dot and comma as decimal separator
  const normalizedValue = val.replace(",", ".");

  // do not allow trailing decimal separator
  if (normalizedValue.slice(-1) === ".") return false;

  // see: https://stackoverflow.com/a/175787
  // isNaN can do type coercion
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  return !isNaN(normalizedValue) && !isNaN(parseFloat(normalizedValue));
};

// We can replace this with https://github.com/apostrophecms/sanitize-html at some point, but overkill for now
// https://github.com/lovasoa/react-contenteditable/issues/35#issuecomment-271121903
export const stripHTML = (htmlString: string): string => {
  const tmp = document.createElement("div");
  tmp.innerHTML = htmlString;
  return tmp.textContent ?? "";
};

type ObjEntry<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

export function filterObject<T extends object>(
  obj: T,
  fn: (entry: ObjEntry<T>, i: number, arr: ObjEntry<T>[]) => boolean
) {
  return Object.fromEntries(
    (Object.entries(obj) as ObjEntry<T>[]).filter(fn)
  ) as Partial<T>;
}

// https://stackoverflow.com/a/16861050
export const openPopupCenter = ({
  url,
  title,
  w,
  h,
}: {
  h: number;
  title: string;
  url: string;
  w: number;
}) => {
  // Fixes dual-screen position
  const dualScreenLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop ? window.screenTop : window.screenY;

  const width =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;
  const height =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.innerHeight ??
    document.documentElement.clientHeight ??
    screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  newWindow?.focus();
};

export const compressImage = (
  canvas: HTMLCanvasElement,
  quality: number
): Promise<Blob> => {
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob!);
      },
      "image/jpeg",
      quality
    );
  });
};
