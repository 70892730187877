import { RadioGroup as HeadlessRadioGroup } from "@headlessui/react";
import clsx from "clsx";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  value: string;
};

export const RadioButton = ({ children, value }: Props): JSX.Element => {
  return (
    <HeadlessRadioGroup.Option key={value} value={value}>
      {({ disabled, checked }) => (
        <div className="flex cursor-pointer items-center space-x-2">
          <div
            className={clsx(
              "flex h-5 w-5 items-center justify-center rounded-full border border-slate-300",
              { "bg-slate-300": disabled }
            )}
          >
            {checked && (
              <div
                className={clsx("h-2 w-2 rounded-full", {
                  "bg-blue-500": !disabled,
                  "bg-slate-400": disabled,
                })}
              />
            )}
          </div>
          <div>{children}</div>
        </div>
      )}
    </HeadlessRadioGroup.Option>
  );
};
