import {
  ClosingNoteStatusFragment,
  CommitStatus,
  Company,
  GroupDefaultFragment,
  Maybe,
  Objective,
  ProgressDriver,
  TagColorFragment,
  TagDefaultFragment,
  Timeframe,
  User,
} from "@graphql";
import { isNil, round } from "lodash";
import React, { ReactNode } from "react";
import { DragElementWrapper, DragSourceOptions } from "react-dnd";
import { FormattedMessage } from "react-intl";
import { Avatar, StackedAvatar } from "common/avatar";
import { Show } from "common/controlFlow";
import { useSharedOwners } from "common/goal/GoalOwners/GoalOwners.utils";
import { Icon } from "common/icons";
import { ExpandIcon } from "common/icons/ExpandIcon";
import { Ghost } from "common/objective/MapsObjective/Ghost/Ghost";
import { MapsObjectiveDropdown } from "common/objective/MapsObjective/MapsObjectiveDropdown/MapsObjectiveDropdown";
import { MapsObjectiveTooltip } from "common/objective/MapsObjective/MapsObjectiveTooltip/MapsObjectiveTooltip";
import { ObjectiveStatus } from "common/objective/ObjectiveStatus/ObjectiveStatus";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { ProgressBar } from "common/progress/ProgressBar/ProgressBar";
import { useIsFlexibleObjectiveProgressEnabled } from "hooks/useCompany/useCompany";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { isDefined } from "utils/isDefined";
import { twClass } from "utils/twClass";

type ObjectiveProps = Pick<
  Objective,
  "id" | "name" | "progress" | "stage" | "private"
> & {
  closingNote?: Maybe<ClosingNoteStatusFragment>;
  company: Pick<Company, "id" | "name" | "avatar">;
  groups?: NodeConnection<GroupDefaultFragment>;
  kpi?: Pick<Objective, "id" | "name">;
  lead: Maybe<Pick<User, "id" | "fullName" | "avatar">>;
  parent?: Pick<Objective, "id" | "name">;
  progressDriver: ProgressDriver;
  strategicPillar?: Pick<Objective, "id" | "name">;
  tags: NodeConnection<TagDefaultFragment & TagColorFragment>;
  timeframe: Maybe<Pick<Timeframe, "id" | "isPast" | "name">>;
};

type Props = ObjectiveProps & {
  addButton?: ReactNode;
  childCount?: number;
  childProgress?: number;
  className?: string;
  dndDisabled?: boolean;
  dndRef?: DragElementWrapper<DragSourceOptions>;
  enableWrap?: boolean;
  folded?: boolean;
  ghost?: boolean;
  hideLines?: boolean;
  id: string;
  isCompanyGoal: boolean;
  onClick?: () => void;
  onClickLink: () => void;
  shadows?: boolean;
  showStatus?: boolean;
  size?: "regular" | "small" | "full";
  status: CommitStatus;
};

export const MapsObjective = ({
  addButton,
  childCount = 0,
  childProgress,
  className = "",
  closingNote,
  company,
  dndDisabled,
  dndRef,
  folded,
  ghost,
  groups,
  hideLines,
  id,
  isCompanyGoal,
  kpi,
  lead,
  name,
  onClick,
  onClickLink,
  private: isPrivate,
  parent,
  progress,
  progressDriver,
  showStatus = true,
  size = "regular",
  stage,
  status,
  strategicPillar,
  tags,
  timeframe,
  shadows = true,
  enableWrap = false,
}: Props) => {
  const [privateMode] = usePrivateMode();
  const isSmall = size === "small";
  const flexibleObjectiveProgressEnabled =
    useIsFlexibleObjectiveProgressEnabled();
  const showProgressDriver = flexibleObjectiveProgressEnabled
    ? progressDriver
    : undefined;

  const sizeClasses = twClass("h-28 my-10 mx-4", {
    "w-24": size === "small",
    "w-[285px]": size === "regular",
    "w-full": size === "full",
  });

  const sharedOwners = useSharedOwners(isCompanyGoal, groups);

  const renderChildCount = () =>
    isSmall ? (
      <div data-cy="o-child-count">{`+${childCount}`}</div>
    ) : (
      <div data-cy="o-child-count">{`+${childCount} OKRs`}</div>
    );

  const handleClickLink = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    onClickLink();
  };

  const contentClasses = twClass(
    "flex flex-col group relative justify-between -mt-2.5",
    "min-h-[88px] prevent-drag-scroll border rounded-md hover:border-blue-500",
    {
      "bg-slate-100 border-slate-300": showStatus && stage === "CLOSED",
      "bg-slate-50 border-slate-400 border-dashed":
        showStatus && stage === "DRAFT",
      "bg-white border-red-500":
        showStatus && stage === "ACTIVE" && timeframe?.isPast,
      "bg-white border-slate-300":
        !showStatus || (stage === "ACTIVE" && !timeframe?.isPast),
      "cursor-default": dndDisabled && isNil(onClick),
      "cursor-pointer": !(dndDisabled && isNil(onClick)),
      [id]: true,

      "shadow-lg": shadows,
    }
  );

  const mainContent = (
    // TODO: change div to button, remove "role"
    <div
      ref={dndRef}
      className={contentClasses}
      data-cy="okrCard"
      data-cy-size={size}
      data-cy-stage={stage}
      role="button"
    >
      {/* Main content */}
      <div className="px-3 py-2 w-full">
        <div
          className="flex flex-grow flex-col justify-between"
          data-cy={`objective-modal-opener-${id}`}
          onClick={handleClickLink}
        >
          <div className="flex gap-2 mb-1 items-start">
            <StackedAvatar
              data-cy="o-avatar"
              maxCount={2}
              size="small"
              urls={sharedOwners.map((avatar) => avatar.avatar as string)}
            />
            <Show when={!isSmall}>
              <div
                data-cy="o-card"
                className={twClass(
                  "line-clamp-2 flex-grow text-sm font-semibold hover:text-blue-500",
                  {
                    "text-slate-600": showStatus && stage === "CLOSED",
                  }
                )}
              >
                {name}
              </div>
            </Show>
          </div>

          <div
            className="flex space-x-2 items-center text-sm font-semibold"
            data-cy="objectiveProgress"
            data-testid="objectiveProgress"
          >
            <ProgressBar progress={progress ?? 0} />
            <div>{round(progress ?? 0)}%</div>
          </div>

          <Show when={!isSmall}>
            <div
              data-testid="objectiveDetails"
              className={twClass(
                "flex whitespace-nowrap items-center *:mr-1.5 text-xs *:mt-1",
                {
                  "flex-wrap": enableWrap,
                }
              )}
            >
              {lead && (
                <div className="flex items-center space-x-1 border px-1 py-0.5 rounded-md max-w-24">
                  <Avatar
                    data-cy="o-lead"
                    size="extraSmall"
                    url={lead.avatar}
                  />
                  <div className="truncate">{lead.fullName}</div>
                </div>
              )}
              {stage === "DRAFT" ? (
                <div className="border border-dashed border-slate-400 flex items-center py-0.5 text-xs text-black px-2 rounded-md gap-1">
                  <Icon name="flip" size="sm" />
                  <FormattedMessage defaultMessage="Draft" id="W6nwjo" />
                </div>
              ) : (
                <div className="border px-1 py-0.5 rounded-md max-w-20">
                  <ObjectiveStatus
                    objective={{
                      __typename: "objective",
                      closingNote,
                      progressDriver,
                      status,
                    }}
                    onlyIcon={isSmall}
                  />
                </div>
              )}
              {timeframe && (
                <div
                  className={twClass(
                    "flex items-center space-x-1 border px-1 py-0.5 rounded-md",
                    "max-w-20 px-1 py-0.5 border rounded-md",
                    {
                      "text-red-500": timeframe.isPast,
                    }
                  )}
                >
                  <Show when={timeframe.isPast}>
                    <Icon name="error_outline" size="sm" />
                  </Show>
                  <div className="truncate">{timeframe.name}</div>
                </div>
              )}
            </div>
          </Show>
        </div>
        <div
          className={twClass(
            "absolute right-1.5 top-1.5 opacity-0",
            "hover:text-blue-500 group-hover:opacity-100",
            {
              "bg-slate-50": showStatus && stage === "DRAFT",
              "bg-white": !showStatus || stage === "ACTIVE",
            }
          )}
          role="button"
        >
          <MapsObjectiveDropdown id={id} name={name} />
        </div>
      </div>
      {/* Aligned OKRs section */}
      {childCount > 0 && isDefined(childProgress) && (
        <div
          className="flex h-7 items-center justify-center rounded-b-md border-t px-3 text-sm bg-slate-50"
          onClick={onClick}
        >
          <div className="flex items-center">
            {renderChildCount()}
            <ExpandIcon expanded={!folded} size="xl" />
          </div>
        </div>
      )}
    </div>
  );

  if (ghost)
    return <Ghost className={sizeClasses} hideLines={hideLines} id={id} />;

  return (
    <div
      className={twClass(sizeClasses, className, {
        "!hidden": privateMode && isPrivate,
      })}
    >
      <Tooltip
        className="bg-slate-800"
        content={
          <MapsObjectiveTooltip
            company={company}
            goalId={id}
            groups={groups}
            isCompanyGoal={isCompanyGoal}
            lead={lead}
            name={name}
            progress={progress}
            progressDriver={showProgressDriver}
            tags={tags}
          />
        }
        delay={300}
        interactive
        placement="top"
        spanClass="block h-full"
        theme="perdoo-blue"
      >
        <div>
          {mainContent}
          {addButton}
        </div>
      </Tooltip>
    </div>
  );
};
