import { ApolloError } from "@apollo/client";
import { Company, Group, useGetOrderedGroupsQuery } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isArray, isNil, sortBy } from "lodash";
import { useMemo } from "react";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { mapEdges } from "utils/mapEdges";

type OptionData = {
  isCompany: boolean;
  isSelectable: boolean;
  isSelected: boolean;
};
type GroupOwner = Derive<Group, { avatar: true; id: true; name: true }> &
  OptionData;
type CompanyOwner = Derive<Company, { avatar: true; id: true; name: true }> &
  OptionData;

type Options = {
  excludedIds?: string[];
  includeArchived?: boolean;
  /** if not specified, company will be included if the user has permission to create company goals */
  includeCompany?: boolean;
};

export type Owner = GroupOwner | CompanyOwner;

type Hook = {
  error: ApolloError | undefined;
  loading: boolean;
  owners: Owner[];
};

interface UseOwnersProps {
  options?: Options;
  selected: string | string[] | null;
}

export const useOwners = ({
  selected,
  options = {
    excludedIds: [],
    includeArchived: false,
    includeCompany: undefined,
  },
}: UseOwnersProps): Hook => {
  const permissions = usePermissions();
  const includeCompany =
    options.includeCompany ?? permissions.canCreateCompanyGoals;
  const { data, error } = useGetOrderedGroupsQuery({
    variables: {
      idsExclude: options.excludedIds?.join(","),
      includeCompany: true,
    },
  });

  const value = useMemo(() => {
    if (isArray(selected)) {
      return selected;
    }

    if (!selected) {
      return selected;
    }

    return [selected];
  }, [selected]);

  const allOwners = useMemo<Owner[] | undefined>(() => {
    if (!data) {
      return undefined;
    }

    let optionsData: Owner[] = sortBy(
      mapEdges(data.allGroups.edges).map((group) => ({
        ...group,
        isCompany: false,
        isSelectable:
          group.canCreateKpis &&
          (options.includeArchived ? true : !group.archived),
        isSelected: value?.includes(group.id) ?? false,
      })),
      ["name"]
    );

    if (data.currentCompany) {
      const companyOption = {
        ...data.currentCompany,
        isCompany: true,
        isSelectable: includeCompany,
        isSelected: value?.includes(data.currentCompany.id) ?? false,
      };
      optionsData = [companyOption, ...optionsData];
    }
    return optionsData;
  }, [data, includeCompany, value, options.includeArchived]);

  const selectableOwners = useMemo<Owner[]>(
    () => allOwners?.filter((opt) => opt.isSelectable || opt.isSelected) ?? [],
    [allOwners]
  );

  return { error, loading: isNil(data), owners: selectableOwners };
};
