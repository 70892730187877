import React from "react";
import { Icon } from "common/icons/Icon/Icon";
import { Combo } from "common/inputs";
import { ComboIcon } from "common/inputs/ComboIcon/ComboIcon";
import { FilterItem, FilterValue } from "legacy/components/filters/types";
import { selectedIncludingSubgroups } from "legacy/components/filters/utils";
import { twClass } from "utils/twClass";

export const DropdownIndicator = (): JSX.Element => {
  return <Icon className="mr-1 text-slate-500" name="search" size="2xl" />;
};

type FilterSearchProps = {
  filterColumn: FilterValue;
  onSelect: (selected: FilterItem, filterName: string) => void;
  renderToggle?: () => React.ReactNode;
};

export const FilterSearch = ({
  filterColumn,
  onSelect,
  renderToggle,
}: FilterSearchProps): JSX.Element => {
  const selectedIds = selectedIncludingSubgroups(filterColumn.selected);
  const options =
    filterColumn.items?.filter(
      (item) => !selectedIds.some((id: string) => id === item.id)
    ) ?? [];
  const comboOptions = options.map((opt) => ({
    ...opt,
    id: opt.id ?? "",
    name: opt.name ?? opt.fullName ?? "",
  }));
  const onSelectHandler = (selected: FilterItem) => {
    onSelect(selected, filterColumn.name);
  };

  const renderItem = (item: FilterItem) => {
    return (
      <button
        className={twClass(
          "flex w-full px-3 py-2 text-slate-800",
          "bg-white hover:bg-slate-100"
        )}
        type="button"
      >
        <span className="truncate">{item.fullName ?? item.name}</span>
      </button>
    );
  };

  return (
    <>
      <Combo
        className="min-w-[26rem] max-w-[28rem]"
        defaultItem={null}
        icon={<ComboIcon name="search" />}
        insideComponent={renderToggle}
        items={comboOptions}
        keepOpen
        menuClass="w-full mt-1 rounded-none"
        onChange={onSelectHandler}
      >
        {({ item }) => <> {renderItem(item)}</>}
      </Combo>
    </>
  );
};
