import { Kpi } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import { IconName } from "constants/icons";
import { goalOperators } from "constants/index";
import { isSet } from "utils/isSet";

type Hook = {
  delta: number;
  deltaColorClass: string;
  deltaIcon: keyof typeof IconName;
};

type Props = {
  currentValue?: number;
  newValue?: number;
  positiveMetric?: boolean;
};

export const getDelta = ({
  currentValue,
  newValue,
  positiveMetric,
}: Props): Hook => {
  const delta =
    isSet(newValue) && isSet(currentValue) ? newValue - currentValue : 0;
  const deltaIcon = delta >= 0 ? "arrow_upward" : "arrow_downward";

  let deltaColorClass = delta ? "text-blue-500" : "transparent";
  if (positiveMetric === true) {
    deltaColorClass = delta > 0 ? "text-green-500" : "text-red-500";
  } else if (positiveMetric === false) {
    deltaColorClass = delta < 0 ? "text-green-500" : "text-red-500";
  }

  return { delta, deltaColorClass, deltaIcon };
};

export const getKpiDelta = ({
  goalOperator,
  goalThreshold,
  ...props
}: Props & Derive<Kpi, { goalOperator: true; goalThreshold: true }>): Hook => {
  const positiveMetric =
    !isNil(goalThreshold) && goalOperator
      ? goalOperator === goalOperators.GREATER_THAN_OR_EQUAL
      : undefined;
  return getDelta({ ...props, positiveMetric });
};
