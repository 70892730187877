import React from "react";
import { useIntl } from "react-intl";
import { links } from "common/layout/HelpCenter/HelpCenter.messages";
import { useHelpCenterTrack } from "common/layout/HelpCenter/hooks/useHelpCenterTrack";
import { AnchorNextTrack } from "common/navigation";

export const Links = (): JSX.Element => {
  const intl = useIntl();
  const { trackContentHandler } = useHelpCenterTrack();

  return (
    <div className="flex flex-col px-5 py-4">
      <AnchorNextTrack
        data-cy="8Ldrr1LaAu3XQPAvOtnXb"
        className="py-2"
        href="https://support.perdoo.com/en/collections/2091196-learn-about-okr"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/2091196-learn-about-okr"
        )}
        target="_blank"
      >
        {intl.formatMessage(links.okrsLearn)}
      </AnchorNextTrack>
      <AnchorNextTrack
        data-cy="Ii21T6s5Ouhww2vsCkrZ-"
        className="py-2"
        href="https://support.perdoo.com/en/collections/2512004-configure-and-implement-perdoo"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/2512004-configure-and-implement-perdoo"
        )}
        target="_blank"
      >
        {intl.formatMessage(links.okrsConfigure)}
      </AnchorNextTrack>
      <AnchorNextTrack
        data-cy="bB5hIdkMcAGUaNBrfOwoA"
        className="py-2"
        href="https://support.perdoo.com/en/collections/2512083-faq"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/2512083-faq"
        )}
        target="_blank"
      >
        {intl.formatMessage(links.faq)}
      </AnchorNextTrack>
    </div>
  );
};
