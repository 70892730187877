import { gql } from "@apollo/client";

export const UPSERT_TIMEFRAMES_BULK_MUTATION = gql`
  mutation UpsertTimeframesBulkMutation($timeframes: [TimeframesBulkInput]) {
    upsertTimeframesBulk(timeframes: $timeframes) {
      timeframes {
        id
        name
      }
    }
  }
`;
