import { useFormikContext } from "formik";
import React, { ReactNode } from "react";
import { FormObjectiveAlignToSelect } from "common/form/FormObjectiveAlignToSelect/FormObjectiveAlignToSelect";
import { getSelectionPrompt } from "common/form/FormObjectiveAlignToSelect/FormObjectiveAlignToSelect.utils";
import { useObjectiveFormContext } from "common/objective/utils/useObjectiveFormContext/useObjectiveFormContext";
import { useCompanySettings } from "hooks/useCompany/useCompany";
import { ObjectiveFormValues } from "../ObjectiveForm/ObjectiveForm.types";

type ChildrenPrams = {
  pillarsEnabled: boolean;
  validateObjectiveAlignmentSelection: (option: string) => void;
};
interface Props {
  children: ({
    pillarsEnabled,

    validateObjectiveAlignmentSelection,
  }: ChildrenPrams) => ReactNode;
}

export const ObjectiveAlignment = ({ children }: Props): JSX.Element => {
  const { pillarsEnabled } = useCompanySettings();
  const { values, errors, submitCount, setFieldValue } =
    useFormikContext<ObjectiveFormValues>();
  const { setFocusedField, alignedToRadio, setAlignedToRadio } =
    useObjectiveFormContext();

  const handleSetAlignedToRadio = (alignedTo: string) => {
    if (!values.private) {
      setAlignedToRadio(alignedTo);
    }
  };

  const validateObjectiveAlignmentSelection = (option: string) => {
    return () => {
      if (values[option as keyof ObjectiveFormValues]) return null;
      return getSelectionPrompt(option);
    };
  };

  return (
    <FormObjectiveAlignToSelect
      alignedToRadio={alignedToRadio ?? ""}
      errors={errors}
      privateObjective={values.private ?? false}
      setAlignedToRadio={handleSetAlignedToRadio}
      setFieldValue={setFieldValue}
      setFocusedField={setFocusedField}
      submitCount={submitCount}
    >
      {children({
        pillarsEnabled: pillarsEnabled,
        validateObjectiveAlignmentSelection,
      })}
    </FormObjectiveAlignToSelect>
  );
};
