import { gql } from "@apollo/client";
import { TIMEFRAME_DEFAULT_FRAGMENT } from "./fragments";

export const GET_ALL_TIMEFRAMES = gql`
  query allTimeframes {
    timeframes {
      edges {
        node {
          ...TimeframeDefaultFields
        }
      }
    }
  }
  ${TIMEFRAME_DEFAULT_FRAGMENT}
`;

export const GET_TIMEFRAME_CADENCE = gql`
  query getTimeframeCadence($timeframeId: UUID!) {
    timeframe(id: $timeframeId) {
      id
      name
      cadence {
        id
        goalUpdateCycle
      }
    }
  }
`;

export const GET_TIMEFRAME = gql`
  query getTimeframe($timeframeId: UUID!) {
    timeframe(id: $timeframeId) {
      id
      name
    }
  }
`;
