import { orderBy, take } from "lodash";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { For, Show } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SELECT_NEW_ID } from "common/inputs/Select/Select.utils";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { useGroupCreation } from "hooks/useGroupCreation/useGroupCreation";
import { useOwners } from "hooks/useOwners/useOwners";
import { searchBy } from "utils/searchBy/searchBy";
import { OwnerSelectOption } from "./OwnerSelect.types";
import { OwnerSelectEmptyState } from "./OwnerSelectEmptyState";

const Select = makeSelect<Option>();

type Option = OwnerSelectOption;

type Props = {
  className?: string;
  creatable?: boolean;
  hasError?: boolean;
  onChange?: (val: string | null) => void;
  value: string | null;
};

export const OwnerSelect = ({
  creatable,
  hasError = false,
  onChange,
  value,
  className,
}: Props): JSX.Element => {
  const { owners: options } = useOwners({ selected: value });

  const [query, setQuery] = useState<string>("");
  const orderedOptions = orderBy(options, "name");
  const visibleOptions = take(searchBy(orderedOptions, ["name"], query), 10);

  const selected = options.find((group) => group.id === value) ?? null;

  const { createGroup, isCreating } = useGroupCreation();

  const handleChange = async (option: Option | null) => {
    if (option?.id === SELECT_NEW_ID) {
      const group = await createGroup(option.name);
      if (!group) return;
      onChange?.(group.id);
      return;
    }
    onChange?.(option?.id ?? null);
  };

  return (
    <Select.Root
      className={className}
      data-cy="fieldOwner"
      onChange={handleChange}
      value={selected}
    >
      <Select.Trigger>
        <Select.Value hasError={hasError}>
          <Show fallback={<SpinnerFit />} when={!isCreating}>
            {selected ? (
              <Entity avatar={selected.avatar} name={selected.name} />
            ) : (
              <FormattedMessage
                defaultMessage="No owner selected"
                id="PtN4rM"
              />
            )}
          </Show>
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown className="divide-y divide-slate-300">
        <Select.Searchbox onChange={setQuery} />
        <Select.Options>
          <For
            each={visibleOptions}
            fallback={
              creatable ? (
                <OwnerSelectEmptyState query={query} />
              ) : (
                <SelectFallback />
              )
            }
          >
            {(option) => (
              <Select.Option key={option.id} value={option}>
                <Entity avatar={option.avatar} name={option.name} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
