import classNames from "clsx";
import { isNil } from "lodash";
import React, { useState } from "react";
import { IconNameKey, IconType } from "common/icons/Icon/Icon.types";
import { CategorySelectItems } from "common/inputs/CategorySelect/CategorySelectItems/CategorySelectItems";
import { CategorySlider } from "./CategorySlider/CategorySlider";

// item can be anything. never or unknown doesn't work.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CategorySelectItem = Record<string, any> & {
  id: string;
  name: React.ReactNode;
};

export type CategorySelectGroup = {
  items: CategorySelectItem[];
  name?: string;
};

type Props = {
  categories: {
    items: CategorySelectItem[];
    name: string;
  }[];
  /** class name for the container */
  className?: string;
  icon?: {
    name: IconNameKey;
    type?: IconType;
  };
  /** what happens when you select an item (not category) */
  onSelect?: (item: CategorySelectItem) => void;
  /** if true, first category will be selected by default. otherwise it will show all items. */
  selectFirst?: boolean;
  /** used to perform string transformation on the category names, e.g. convert to Proper Case */
  transform?: (name: string) => string;
};

/**
 * displays a list of categories as a slider. select one to show items belonging to this category below.
 * unselect category to show all items, grouped by category.
 *
 * @param categories
 * @param className
 * @param icon
 * @param onSelect
 * @param selectFirst
 * @param transform
 * @constructor
 */
export const CategorySelect = ({
  categories,
  className = "",
  icon,
  onSelect,
  selectFirst = true,
  transform,
}: Props): JSX.Element => {
  const transformedCategories = !isNil(transform)
    ? categories.map(({ name, items }) => ({ items, name: transform(name) }))
    : categories;
  const defaultSelection = selectFirst
    ? transformedCategories[0]?.name ?? ""
    : "";
  const [selectedCategory, setSelectedCategory] =
    useState<string>(defaultSelection);

  const selectedCategories = selectedCategory
    ? transformedCategories.filter(
        (category) => category.name === selectedCategory
      )
    : transformedCategories;

  const toggleSelectedCategory = (category: string) =>
    setSelectedCategory(selectedCategory === category ? "" : category);

  return (
    <div
      className={classNames(className, {
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        "flex flex-col": !className?.includes("flex"),
      })}
      data-testid="category-select"
      id="category-select"
    >
      <CategorySlider
        categories={transformedCategories}
        selectedCategory={selectedCategory}
        toggleSelectedCategory={toggleSelectedCategory}
      />
      <CategorySelectItems
        icon={icon}
        onSelect={onSelect}
        selectedCategories={selectedCategories}
      />
    </div>
  );
};
