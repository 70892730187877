import { ClosingNoteStatus, CommitStatus } from "@graphql";
import classNames from "clsx";
import { omit } from "lodash";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { IconNameKey } from "common/icons/Icon/Icon.types";
import { colorTheme } from "constants/colorTheme";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

const messages = defineMessages({
  accomplished: {
    defaultMessage: "accomplished",
    id: "kpis:list:accomplished",
  },
  achieved: {
    defaultMessage: "achieved",
    id: "z5hMDa",
  },
  cancelled: {
    defaultMessage: "cancelled",
    id: "8XMLza",
  },
  healthy: {
    defaultMessage: "healthy",
    id: "kpis:list:healthy",
  },
  missed: {
    defaultMessage: "missed",
    id: "global:update:status:missed",
  },
  needsAttention: {
    defaultMessage: "needs attention",
    id: "global:update:status:needsAttention",
  },
  NoStatus: {
    defaultMessage: "no status",
    id: "kpis:list:noStatus",
  },
  notFinalized: {
    defaultMessage: "not finalized",
    id: "global:update:status:notFinalized",
  },
  offTrack: {
    defaultMessage: "off track",
    id: "global:update:status:offTrack",
  },
  onTrack: {
    defaultMessage: "on track",
    id: "global:update:status:onTrack",
  },
  partiallyAchieved: {
    defaultMessage: "partially achieved",
    id: "Z7MLrJ",
  },
  postponed: {
    defaultMessage: "postponed",
    id: "global:update:status:postponed",
  },
  unhealthy: {
    defaultMessage: "unhealthy",
    id: "kpis:list:unhealthy",
  },
});

type ObjectiveStatus = ClosingNoteStatus | CommitStatus;

const statusColors: Record<string, string> = {
  Accomplished: colorTheme.green[800],
  Cancelled: colorTheme.red[600],
  Missed: colorTheme.red[500],
  NeedsAttention: colorTheme.yellow[500],
  NoStatus: colorTheme.slate[300],
  NotFinalized: colorTheme.slate[500],
  OffTrack: colorTheme.red[500],
  OnTrack: colorTheme.green[500],
  PartiallyAchieved: colorTheme.yellow[500],
  Postponed: colorTheme.blue[500],
};

export type StatusPair = { className: string; color?: string; title: string };
export type StatusMapFn = (intl: IntlShape) => Record<string, StatusPair>;

export const getGoalStatuses: StatusMapFn = (intl) => ({
  ACCOMPLISHED: {
    className: "text-green-800",
    color: statusColors.Accomplished,
    title: intl.formatMessage(messages.accomplished),
  },
  ACHIEVED: {
    className: "text-green-800",
    color: statusColors.Accomplished,
    title: intl.formatMessage(messages.achieved),
  },
  CANCELLED: {
    className: "text-red-600",
    color: statusColors.Cancelled,
    title: intl.formatMessage(messages.cancelled),
  },
  HEALTHY: {
    className: "bg-green-500",
    color: statusColors.OnTrack,
    title: intl.formatMessage(messages.healthy),
  },
  MISSED: {
    className: "text-red-500",
    color: statusColors.Missed,
    title: intl.formatMessage(messages.missed),
  },
  NEEDS_ATTENTION: {
    className: "bg-yellow-500",
    color: statusColors.NeedsAttention,
    title: intl.formatMessage(messages.needsAttention),
  },
  NO_STATUS: {
    className: "bg-slate-300",
    color: statusColors.NoStatus,
    title: intl.formatMessage(messages.NoStatus),
  },
  OFF_TRACK: {
    className: "bg-red-500",
    color: statusColors.OffTrack,
    title: intl.formatMessage(messages.offTrack),
  },
  ON_TRACK: {
    className: "bg-green-500",
    color: statusColors.OnTrack,
    title: intl.formatMessage(messages.onTrack),
  },
  PARTIALLY_ACHIEVED: {
    className: "text-yellow-500",
    color: statusColors.PartiallyAchieved,
    title: intl.formatMessage(messages.partiallyAchieved),
  },
  POSTPONED: {
    className: "text-blue-500",
    color: statusColors.Postponed,
    title: intl.formatMessage(messages.postponed),
  },
  UNHEALTHY: {
    className: "bg-yellow-500",
    color: statusColors.NeedsAttention,
    title: intl.formatMessage(messages.unhealthy),
  },
});

export const getOkrStatuses = (intl: IntlShape) => {
  const goalStatuses = getGoalStatuses(intl);
  return omit(goalStatuses, "UNHEALTHY", "HEALTHY");
};

export const getStatuses: StatusMapFn = (intl) => ({
  ...getGoalStatuses(intl),
  healthy: {
    className: "bg-green-500",
    title: intl.formatMessage(messages.healthy),
  },
  // watch out when thinking of converting this, the colours are used in a lot of places
  unhealthy: {
    className: "bg-yellow-500",
    title: intl.formatMessage(messages.unhealthy),
  },
});

const statusIcons: Record<string, IconNameKey> = {
  [ClosingNoteStatus.Achieved]: "emoji_events",
  [CommitStatus.Accomplished]: "emoji_events",
  [ClosingNoteStatus.PartiallyAchieved]: "star_half",
  [ClosingNoteStatus.Missed]: "call_missed_outgoing",
  [ClosingNoteStatus.Postponed]: "access_time",
  [ClosingNoteStatus.Cancelled]: "block",
};

export type HealthStatus = "healthy" | "unhealthy";

export interface StatusProps<S = CommitStatus> {
  className?: string;
  "data-testid"?: string;
  onlyIcon?: boolean;
  // TODO: does this need to be generic?
  status: S | HealthStatus | ObjectiveStatus;
  statusesResolver?: StatusMapFn;
}

export const Status = <S,>({
  className,
  onlyIcon,
  status,
  statusesResolver = getStatuses,
  "data-testid": dataTestId,
}: StatusProps<S>): JSX.Element | null => {
  const intl = useIntl();
  const statuses = statusesResolver(intl);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Statu... Remove this comment to see the full error message
  const { className: statusClass, title } =
    statuses[status as string] ?? statuses[CommitStatus.NoStatus];

  return (
    <div
      className={twClass("flex items-center space-x-1", className)}
      id="statusElement"
    >
      {Object.keys(statusIcons).includes(status as string) &&
      isSet(statusIcons[status as string]) ? (
        <div className="flex items-center">
          <Icon
            className={statusClass}
            data-testid="statusIcon"
            // we check this above
            // @ts-expect-error FIXME: TS2322 Type 'undefined' is not assignable to type { ... }
            name={statusIcons[status as string]}
            size="lg"
          />
        </div>
      ) : (
        <div
          className={classNames(
            "rounded-full border border-transparent",
            statusClass
          )}
          data-cy="statusColor"
          data-status={status}
          data-testid="statusIcon"
          style={{
            // using classes like w-2.5 h-2.5 causes the circles to become oval sometimes, no idea why
            height: "10px",
            width: "10px",
          }}
        />
      )}
      {!onlyIcon && (
        <div
          className="truncate"
          data-cy="statusTitle"
          data-testid={dataTestId ?? "statusTitle"}
        >
          {title}
        </div>
      )}
    </div>
  );
};
