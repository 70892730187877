import React from "react";
import { FormattedMessage } from "react-intl";
import { PermissionErrorMessage } from "common/overlay/PermissionErrorTooltip/PermissionErrorMessage/PermissionErrorMessage";
import { messages } from "./KpiForm.messages";

export const helper = {
  cannotCreateGroup: <PermissionErrorMessage variant="addGroup" />,
  description: <FormattedMessage {...messages.descriptionHelperText} />,
  name: (
    <div>
      <p className="mb-2.5">
        Key Performance Indicators or KPIs are measurements that show how your
        team or company is performing on its core responsibilities.
      </p>
      When defining KPIs consider:
      <ul className="ml-0 mt-2 list-inside list-disc pl-4">
        <li>These metrics will influence daily behavior and decision-making</li>
        <li>Their status indicates if performance is healthy or unhealthy</li>
      </ul>
      <p className="mt-2.5">
        <FormattedMessage
          defaultMessage="Need inspiration ? Check out {examples}"
          id="examples:learnAboutOkr"
          values={{
            examples: (
              <a
                href="https://support.perdoo.com/en/collections/2091196-learn-about-okr#okr-kpi-and-strategic-pillars-example-library"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FormattedMessage
                  defaultMessage="examples"
                  id="wizard:examples"
                />
              </a>
            ),
          }}
        />
      </p>
    </div>
  ),
};
