import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { ObjectiveAlignmentModalProps } from "../ObjectiveAlignmentModal";

type HookModalProps = Omit<ObjectiveAlignmentModalProps, "onClose"> &
  Partial<Pick<ObjectiveAlignmentModalProps, "onClose">>;

type Hook = {
  closeModal: () => void;
  modalProps: ObjectiveAlignmentModalProps | null;
  openModal: (props: HookModalProps) => void;
};

const initialState: Hook = {
  closeModal: () => {},
  modalProps: null,
  openModal: () => {},
};

export const useObjectiveAlignmentModal = singletonHook(
  initialState,
  (): Hook => {
    const [modalProps, setModalProps] = useState<HookModalProps | null>(null);
    const openModal = (props: HookModalProps) => setModalProps(props);
    const closeModal = () => setModalProps(null);

    const overwrittenModalProps: ObjectiveAlignmentModalProps | null =
      modalProps
        ? {
            ...modalProps,
            onClose: () => {
              modalProps.onClose?.();
              closeModal();
            },
          }
        : null;

    return { closeModal, modalProps: overwrittenModalProps, openModal };
  }
);
