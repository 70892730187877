import React from "react";
import { defineMessages, FormattedDate, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { usePerformanceReviewCompleteContext } from "../../context/performanceReviewCompleteContext";
import { PerformanceReviewFormRootBanner } from "../PerformanceReviewFormRootBanner";

const messages = defineMessages({
  reminderDirectReport: {
    defaultMessage: "The answers will be visible to you and your manager only.",
    id: "performanceReview:form:directReportManagerOnly",
  },
  saved: {
    defaultMessage: "Review saved! It will be shared with {user} on {dueDate}",
    id: "performanceReview:form:savedBanner",
  },
  saveReminder: {
    defaultMessage: "Don't forget to save your answers when you are finished",
    id: "performanceReview:form:saveReminder",
  },
});

type Props = {
  isReviewee: boolean;
  otherUser?: string | null;
  showSavedMsg: boolean;
};

export const PerformanceReviewFormBanner = ({
  showSavedMsg,
  otherUser,
  isReviewee,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { dueDate } = usePerformanceReviewCompleteContext();
  const message = () => {
    if (showSavedMsg) {
      return intl.formatMessage(messages.saved, {
        dueDate: (
          <FormattedDate
            day="numeric"
            month="short"
            value={dueDate}
            year="numeric"
          />
        ),
        user: otherUser,
      });
    }
    if (isReviewee) {
      return intl.formatMessage(messages.reminderDirectReport);
    }
    return intl.formatMessage(messages.saveReminder);
  };

  const icon = () => {
    if (showSavedMsg) {
      return "check";
    }
    if (isReviewee) {
      return "lock";
    }
    return "info";
  };
  return (
    <PerformanceReviewFormRootBanner
      data-testid="performance-review-form-banner"
      type={showSavedMsg ? "success" : "reminder"}
    >
      <Icon name={icon()} size="lg" type="outlined" />
      <span>{message()}</span>
    </PerformanceReviewFormRootBanner>
  );
};
