/* eslint-disable react/jsx-key */
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { Plans } from "../UpgradeModal.types";

type Props = {
  plan: Plans;
};

export const FeatureList = ({ plan }: Props): JSX.Element => (
  <ul>
    {FEATURES_BY_PLAN[plan].map((feature, i) => (
      <li key={i} className="flex py-1.5 space-x-1">
        <Icon className="text-green-500" name="check_circle" size="xl" />
        <span className="text-sm">{feature}</span>
      </li>
    ))}
  </ul>
);

const FEATURES_BY_PLAN: Record<Plans, JSX.Element[]> = {
  free: [
    <FormattedMessage
      defaultMessage="Strategy management with Map and Strategic Pillars"
      id="7igpvs"
    />,
    <FormattedMessage
      defaultMessage="Company, Team, and IndividualOKR & KPI management"
      id="wxdp2h"
    />,
    <FormattedMessage
      defaultMessage="Custom and weekly progress reports"
      id="M72mPH"
    />,
    <FormattedMessage
      defaultMessage="Reflections, 1:1s, Pulse & Kudos"
      id="ckXY9z"
    />,
    <FormattedMessage
      defaultMessage="Slack, Teams, GSheet and many more integrations"
      id="syeIXP"
    />,
  ],
  premium: [
    <FormattedMessage defaultMessage="Shared OKRs & KPIs" id="98hlXB" />,
    <FormattedMessage defaultMessage="Clone OKRs" id="CC/gfM" />,
    <FormattedMessage
      defaultMessage="Health & Performance reports"
      id="5OkYrB"
    />,
    <FormattedMessage defaultMessage="SSO via SAML" id="tguTce" />,
    <FormattedMessage defaultMessage="SCIM integration" id="PxoPye" />,
    <FormattedMessage defaultMessage="Priority support" id="3sSrKz" />,
    <FormattedMessage defaultMessage="Export data" id="SX126d" />,
  ],
  supreme: [
    <FormattedMessage defaultMessage="KPI Boards" id="T/B5ll" />,
    <FormattedMessage defaultMessage="Private OKRs & KPIs" id="tZHzzf" />,
    <FormattedMessage
      defaultMessage="Custom tags & report graphs"
      id="eRaKu6"
    />,
    <FormattedMessage defaultMessage="Jira & Asana integrations" id="bBYe2R" />,
    <FormattedMessage defaultMessage="API access" id="Z77U9s" />,
    <FormattedMessage defaultMessage="Private Mode" id="fMbuFD" />,
    <FormattedMessage defaultMessage="Performance Reviews" id="sDvo06" />,
    <FormattedMessage
      defaultMessage="View-only licenses (purchased separately)"
      id="Vi4doH"
    />,
  ],
};
