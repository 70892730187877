import classNames from "clsx";
import React from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string;
  hideLines?: boolean;
  id: string;
};

export const Ghost = ({ id, className, hideLines, ...other }: Props) => {
  const lineClass = classNames(
    "absolute bg-slate-400 h-36 top-0 transform-gpu -translate-x-1/2 left-1/2",
    {
      "opacity-0": hideLines,
    }
  );

  return (
    <div
      className={`${id} ${className} relative`}
      data-cy="okrCard"
      id={id}
      {...other}
    >
      <div className={lineClass} style={{ width: 1 }} />
    </div>
  );
};
