import {
  Company,
  GroupDefaultFragment,
  Maybe,
  Objective,
  TagColorFragment,
  TagDefaultFragment,
  User,
} from "@graphql";
import { round } from "lodash";
import { GoalOwners } from "common/goal/GoalOwners/GoalOwners";
import { Icon } from "common/icons";
import { ProgressDriverStatus } from "common/progress/ProgressDriverStatus/ProgressDriverStatus";
import { Tag } from "common/tag/Tag/Tag";

type ObjectiveProps = Pick<Objective, "name" | "progress"> & {
  company: Pick<Company, "id" | "name" | "avatar">;
  goalId: string;
  groups?: NodeConnection<GroupDefaultFragment>;
  isCompanyGoal: boolean;
  lead: Maybe<Pick<User, "id" | "fullName" | "avatar">>;
  progressDriver?: string;
  tags: NodeConnection<TagDefaultFragment & TagColorFragment>;
};

type Props = ObjectiveProps & {
  className?: string;
};

export const MapsObjectiveTooltip = ({
  className = "",
  company,
  goalId,
  groups,
  isCompanyGoal,
  lead,
  name,
  progress,
  progressDriver,
  tags,
}: Props) => {
  return (
    <div className={className}>
      <div className="mb-1 flex flex-wrap items-center space-x-2">
        <div className="flex items-center space-x-1 text-base font-semibold">
          <Icon name="flag" type="outlined" size="lg" />
          <div>{name}</div>
        </div>
        {tags.edges.map((edge) => (
          <Tag
            key={edge.node.id}
            className="mr-0.5 bg-transparent text-xs px-1 h-auto"
            color={edge.node.color}
            text={edge.node.name}
          />
        ))}
      </div>
      <div className="flex flex-wrap">
        <div className="mr-2 flex space-x-1 text-slate-300">
          {groups?.edges.length ? (
            <GoalOwners
              className="truncate p-1 text-slate-300 hover:bg-transparent"
              goalId={goalId}
              goalType="objective"
              isCompanyGoal={isCompanyGoal}
              maxCount={2}
              owners={groups}
              textClassName="max-w-[20ch]"
            />
          ) : (
            <div>{company.name}</div>
          )}
        </div>
        <div className="mr-2 flex items-center space-x-1 text-slate-300">
          <Icon name="person" size="xl" />
          <div>{lead?.fullName}</div>
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <div className="relative mr-2 h-2 flex-grow rounded bg-gray-300">
          <div
            className="absolute h-2 rounded-l bg-blue-500"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="font-semibold leading-none">{`${round(
          progress ?? 0
        )}%`}</div>
      </div>
      {progressDriver && (
        <ProgressDriverStatus progressDriver={progressDriver} />
      )}
    </div>
  );
};
