import React, { ReactNode, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, TextButton } from "common/buttons";
import { Icon } from "common/icons/Icon/Icon";
import { twClass } from "utils/twClass";

interface Props {
  children: ReactNode;
  className?: string;
  expanded?: boolean;
  expandedText?: string;
  minimizedText?: string;
}

export const AdvancedFields = ({
  children,
  expandedText,
  minimizedText,
  className,
  expanded = false,
}: Props): JSX.Element => {
  const [showAdvanced, setAdvancedState] = useState(expanded);
  const bottom = useRef<HTMLDivElement>(null);

  const toggleAdvancedView = () => {
    setAdvancedState((state) => !state);
  };

  useEffect(() => {
    if (!expanded && showAdvanced && bottom.current) {
      bottom.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showAdvanced]);

  const expandedLabel = expandedText || (
    <FormattedMessage
      defaultMessage="Advanced Options"
      id="advanced:fields:link"
    />
  );

  const minimizedLabel = minimizedText || (
    <FormattedMessage
      defaultMessage="Advanced Options"
      id="advanced:fields:link"
    />
  );

  return (
    <>
      <div className="mt-4">
        <Button
          variant="negative"
          className="pl-0 text-base text-blue-500 hover:text-indigo-800"
          data-testid="advancedOptions"
          data-cy="advanceField"
          onClick={toggleAdvancedView}
        >
          <div className="flex items-center">
            {showAdvanced ? expandedLabel : minimizedLabel}
            <Icon
              name={showAdvanced ? "keyboard_arrow_up" : "keyboard_arrow_down"}
              size="2xl"
            />
          </div>
        </Button>
      </div>
      <div
        className={twClass(
          { hidden: !showAdvanced },
          `${showAdvanced && className}`
        )}
        id="advancedSection"
      >
        {children}
      </div>
      <div ref={bottom} />
    </>
  );
};
