import { useFormikContext } from "formik";
import { isNil, isUndefined } from "lodash";
import React, { ReactNode, useState } from "react";
import { JiraIssueFieldIssue } from "common/result/ResultForm/JiraIssueField/JiraIssueField";
import { JiraSourceType } from "common/result/ResultForm/JiraSourceTypeField/JiraSourceTypeField";

type JiraFormContext = {
  issue?: JiraIssueFieldIssue | null;
  onIssueSelected?: (issue: JiraIssueFieldIssue | null) => void;
  onSourceTypeSelected?: (source: JiraSourceType) => void;
};

const JiraFormContext = React.createContext<JiraFormContext>({});

/**
 * The interface expected by the set of Asana fields.
 */
export type JiraFormValues = {
  jiraIssue?: string;
  jiraJql?: string;
  jiraProgressType?: string;
  jiraSourceType: string;
};

type Props = {
  children?: ReactNode;
};

export const useJiraFormContext = () => React.useContext(JiraFormContext);

export const JiraFormLogic = <Values extends JiraFormValues>({
  children,
}: Props) => {
  const [issue, setIssue] = useState<JiraIssueFieldIssue | null>();
  const [sourceType, setSourceType] = useState<JiraSourceType>();

  const { setFieldValue } = useFormikContext<Values>();

  const onIssueSelected = (newJiraIssue: JiraIssueFieldIssue | null) => {
    const previousJiraIssue = issue;
    setIssue(newJiraIssue);

    // TODO: how to improve this pattern?
    // Ignore initial values being set
    if (isUndefined(previousJiraIssue)) return;

    if (isNil(newJiraIssue) || newJiraIssue.isEpic) {
      setFieldValue("jiraProgressType", undefined);
    } else {
      setFieldValue("jiraProgressType", "status");
    }
  };

  const onSourceTypeSelected = (newSourceType: JiraSourceType) => {
    const previousSourceType = sourceType;
    setSourceType(newSourceType);

    // Ignore initial values being set
    if (isUndefined(previousSourceType)) return;

    setFieldValue("jiraJql", undefined);
    setFieldValue("jiraProgressType", "status");
    setFieldValue("jiraIssue", undefined);
  };

  return (
    <JiraFormContext.Provider
      value={{
        issue,
        onIssueSelected,
        onSourceTypeSelected,
      }}
    >
      {children}
    </JiraFormContext.Provider>
  );
};
