import { PerformanceReviewQuestionType } from "@graphql";
import dayjs from "dayjs";
import { ErrorMessage, Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Avatar } from "common/avatar";
import { For, Show } from "common/controlFlow";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { RatePickerField } from "common/form/RatePickerField/RatePickerField";
import { usePerformanceReviewCompleteContext } from "common/performanceReview/context/performanceReviewCompleteContext";
import { PerformanceReviewCompleteFormValues } from "common/performanceReview/modals/PerformanceReviewCompleteFormModal";
import { isQuestionVisible } from "common/performanceReview/modals/PerformanceReviewCompleteFormModal.utils";
import { getOtherUsersQuestionsAndAnswers } from "common/performanceReview/performanceReviews.utils";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { UserAnswer } from "modules/performanceReview/PerformanceReviewCompleteList/PerformanceReviewAnswer";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";

const messages = defineMessages({
  placeholder: {
    defaultMessage: "Reply",
    id: "performanceReview:form:textArea:placeholder",
  },
});

type Props = {
  userIsReviewee: boolean;
};

export const PerformanceReviewQuestionsForm = ({
  userIsReviewee,
}: Props): JSX.Element => {
  const intl = useIntl();
  const me = useCurrentUser();
  const {
    values: { questionsAndAnswers },
  } = useFormikContext<PerformanceReviewCompleteFormValues>();

  const {
    dueDate,
    questionAndAnswers: performanceReviewQnA,
    reviewer,
    reviewee,
  } = usePerformanceReviewCompleteContext();

  const showOtherAnswers = dayjs(dueDate).isSameOrBefore(dayjs());
  const otherAnswers = getOtherUsersQuestionsAndAnswers(
    userIsReviewee,
    mapEdges(performanceReviewQnA.edges)
  );

  const otherUser = userIsReviewee ? reviewer?.user : reviewee?.user;

  return (
    <div className="space-y-8">
      <For each={questionsAndAnswers}>
        {(item, index) => {
          const shouldShowQuestionForm = isQuestionVisible(
            userIsReviewee,
            item.visibility
          );
          const shouldShowOthersAnswer =
            showOtherAnswers &&
            isQuestionVisible(!userIsReviewee, item.visibility);

          if (!shouldShowQuestionForm && !shouldShowOthersAnswer) {
            return <></>;
          }

          const otherAnswer = otherAnswers.find(
            (answer) => answer.id === item.id
          )!;

          return (
            <div
              className="flex flex-col space-y-1"
              data-testid="performanceReviewQuestion"
            >
              <span
                className={twClass("text-slate-800", {
                  "font-medium": showOtherAnswers,
                  "text-sm": !showOtherAnswers,
                })}
              >
                {item.question}
              </span>
              <Show when={shouldShowQuestionForm}>
                <div className="flex w-full space-x-3">
                  <Show when={showOtherAnswers && me?.avatar}>
                    <Avatar className="mt-1" url={me?.avatar} />
                  </Show>
                  <div className="grow">
                    <Show when={showOtherAnswers}>
                      <span className="text-sm text-slate-800">
                        {me?.fullName}
                      </span>
                    </Show>
                    <Show
                      when={
                        item.type === PerformanceReviewQuestionType.StarRating
                      }
                    >
                      <RatePickerField
                        data-testid={`answer-${item.id}-ratepicker`}
                        name={`questionsAndAnswers.${index}.starRatingAnswer`}
                      />
                    </Show>
                    <Show
                      when={
                        item.type === PerformanceReviewQuestionType.Paragraph
                      }
                    >
                      <Field
                        name={`questionsAndAnswers.${index}.paragraphAnswer`}
                        optional={false}
                      >
                        {({ field, form }: FieldProps) => (
                          <>
                            <FormTextArea
                              className="flex min-h-[5rem]"
                              containerClassName="grow"
                              data-testid={`answer-${item.id}-textarea`}
                              field={field}
                              form={form}
                              placeholder={intl.formatMessage(
                                messages.placeholder
                              )}
                            />
                            <ErrorMessage
                              className="text-sm text-red-500"
                              component="div"
                              name={`questionsAndAnswers.${index}.paragraphAnswer`}
                            />
                          </>
                        )}
                      </Field>
                    </Show>
                  </div>
                </div>
              </Show>
              <Show when={shouldShowOthersAnswer}>
                <UserAnswer
                  paragraphAnswer={otherAnswer.paragraphAnswer}
                  starRatingAnswer={otherAnswer.starRatingAnswer}
                  user={otherUser}
                />
              </Show>
            </div>
          );
        }}
      </For>
    </div>
  );
};
