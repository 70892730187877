import { GroupDefaultFragment } from "@graphql";
import React from "react";
import { getLinkByType } from "route-configs";
import { AnchorNext } from "common/navigation";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  group: GroupDefaultFragment;
};

/**
 * individual group displayed inside a report widget. renders name with link to profile.
 */
export const ReportCardGroupName = ({
  className,
  group,
}: Props): JSX.Element => {
  const profileLink = getLinkByType("group", group.id);

  return (
    <AnchorNext
      className={twClass(
        "grow truncate text-slate-800 hover:text-blue-500",
        className
      )}
      href={profileLink}
    >
      {group.name}
    </AnchorNext>
  );
};
