import { PerdooApiExploreViewTabNameChoices } from "@graphql";
import { isEmpty } from "lodash";
import {
  FILTER_CLEAN_UP,
  FILTER_COLUMN_ADD,
  FILTER_REMOVE,
  FILTER_UPDATE,
  ROLLBACK_FILTER,
  SET_EXPLORE_VIEW,
  SET_EXPLORE_VIEW_ATTR,
} from "legacy/actionTypes";
import { objectiveStage as objectiveStageFilterSpec } from "legacy/components/filters/constants";

export const defaultObjectiveStage = {
  multiple: objectiveStageFilterSpec.multiple,
  name: objectiveStageFilterSpec.name,
  type: objectiveStageFilterSpec.type,
};

export const objectiveStageFilterParam = {
  [PerdooApiExploreViewTabNameChoices.Initiatives]: "objective_stage",
  [PerdooApiExploreViewTabNameChoices.KeyResults]: "objective_stage",
  [PerdooApiExploreViewTabNameChoices.Objectives]: "stage",
};

export const initialState = {
  filters: {},
  touched: false,
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'filters' implicitly has an 'any' type.
const cleanUpFilterState = (filters) =>
  Object.entries(filters).reduce((accu, [name, value]) => {
    // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
    return !isEmpty(value.selected) ? { ...accu, [name]: value } : accu;
  }, {});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const updateFilter = (state, payload) => {
  const currentFilter = state.filters[payload.filterName];
  if (currentFilter) {
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.filterName]: {
          ...currentFilter,
          selected: currentFilter.multiple
            ? [...currentFilter.selected, payload.data]
            : payload.data,
        },
      },
      touched: true,
    };
  }

  if (payload.filterName) {
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.filterName]: {
          ...payload,
        },
      },
      touched: true,
    };
  }
  return state;
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const removeFilter = (state, payload) => {
  const currentFilter = state.filters[payload.filterName];

  if (currentFilter.multiple) {
    const newSelectedList = currentFilter.selected.filter(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
      (filter) => filter.id !== payload.id
    );

    if (newSelectedList.length > 0) {
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filterName]: {
            ...currentFilter,
            selected: newSelectedList,
          },
        },
        touched: true,
      };
    }
  }

  const newFiltersState = {
    ...state,
    filters: {
      ...state.filters,
      [payload.filterName]: {
        ...currentFilter,
        selected: [],
      },
    },
    touched: true,
  };

  if (payload.cleanUp) {
    return {
      ...newFiltersState,
      filters: cleanUpFilterState(newFiltersState.filters),
    };
  }

  return {
    ...newFiltersState,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const filterColumnsAdd = (state, payload) => ({
  ...state,
  filters: {
    ...state.filters,
    [payload.filterName]: payload,
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const cleanUpFilters = (state) => ({
  ...state,
  filters: cleanUpFilterState(state.filters),
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter '_' implicitly has an 'any' type.
const setExploreView = (_, { exploreView, id, name }) => {
  return {
    ...exploreView,
    id,
    name,
    touched: false,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rollbackToInitial = (_state, _payload) => initialState;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const setExploreViewAttribute = (state, { attribute }) => {
  return {
    ...state,
    ...attribute,
    touched: true,
  };
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'payload' implicitly has an 'any' ... Remove this comment to see the full error message
export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case FILTER_COLUMN_ADD:
      return filterColumnsAdd(state, payload);
    case FILTER_UPDATE:
      return updateFilter(state, payload);
    case FILTER_REMOVE:
      return removeFilter(state, payload);
    case FILTER_CLEAN_UP:
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      return cleanUpFilters(state, payload);
    case SET_EXPLORE_VIEW:
      return setExploreView(state, payload);
    case SET_EXPLORE_VIEW_ATTR:
      return setExploreViewAttribute(state, payload);
    case ROLLBACK_FILTER:
      return rollbackToInitial(state, payload);
    default:
      return state;
  }
};
