import React from "react";

type Props = {
  children: React.ReactNode;
};
export const PerformanceReviewSidebarEmptyMessage = ({
  children,
}: Props): JSX.Element => {
  return (
    <div className="space-y-3 rounded border p-8 text-center bg-white border-slate-300">
      <div>{children}</div>
    </div>
  );
};
