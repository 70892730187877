import {
  CommitDefaultFieldsFragment,
  CommitDescriptionFragment,
  CommitStatus,
  CommitUserFragment,
  CommitValuesFragment,
  Maybe,
} from "@graphql";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProgressChartTooltip } from "common/charts/ProgressChart/ProgressChartTooltip/ProgressChartTooltip";
import { useFormatDate } from "common/misc/FormattedDate/hooks/useFormatDate";
import { useFormatMetric } from "common/misc/FormattedMetric/hooks/useFormatMetric";
import { colorTheme } from "constants/colorTheme";
import { MetricValue } from "constants/metric";

type Commit = CommitDefaultFieldsFragment &
  CommitDescriptionFragment &
  CommitUserFragment &
  CommitValuesFragment & {
    status?: CommitStatus;
  };

type Props = {
  commit?: Maybe<Commit>;
  metricUnit: MetricValue;
  positiveMetric?: boolean;
  warning?: string;
};

export const CommitTooltip = ({
  commit,
  positiveMetric,
  warning,
  metricUnit,
}: Props): JSX.Element => {
  const intl = useIntl();

  const { formatDate } = useFormatDate();
  const { formatMetric } = useFormatMetric();

  if (!commit) {
    return (
      <FormattedMessage
        defaultMessage="Never updated"
        id="global:commit:neverUpdated"
      />
    );
  }

  const delta = commit.delta || 0;
  const formattedDelta = formatMetric(delta, metricUnit);

  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const progress = (commit?.valueBefore ?? 0) + commit.delta;
  const formattedProgress = formatMetric(progress, metricUnit);

  let deltaColor = commit.delta ? colorTheme.blue[500] : "transparent";

  if (positiveMetric === true) {
    deltaColor = commit.delta > 0 ? colorTheme.green[500] : colorTheme.red[500];
  } else if (positiveMetric === false) {
    deltaColor = commit.delta < 0 ? colorTheme.green[500] : colorTheme.red[500];
  }

  const lastUpdatedString = !!commit.user
    ? intl.formatMessage(
        {
          defaultMessage: "{date} by {user}",
          id: "ZHR9j5",
        },
        {
          date: formatDate(commit.commitDate, { includeYear: true }),
          user: commit.user.fullName,
        }
      )
    : intl.formatMessage(
        {
          defaultMessage: "{date}",
          id: "W9j0FJ",
        },
        {
          date: formatDate(commit.commitDate, { includeYear: true }),
        }
      );

  return (
    <ProgressChartTooltip
      className="p-1"
      formattedProgress={formattedProgress}
      formattedDelta={formattedDelta}
      deltaColor={deltaColor}
      deltaIcon={commit.delta >= 0 ? "arrow_upward" : "arrow_downward"}
      description={
        commit.description ||
        intl.formatMessage({ defaultMessage: "No comment added", id: "0v5vIH" })
      }
      descriptionClass={commit.description ? "text-black" : "text-slate-500"}
      lastUpdatedString={lastUpdatedString}
      statusColor="green"
      statusTitle={commit.status as string}
      valueClass="hidden"
      warning={warning}
      warningClass={warning ? "" : "hidden"}
    />
  );
};
