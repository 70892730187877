import { Kudo } from "@graphql";
import { Derive } from "@shoooe/derive";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { getLinkByType } from "route-configs";
import { Avatar } from "common/avatar";
import { Show } from "common/controlFlow";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { AnchorNext } from "common/navigation";
import { Reactions } from "common/reaction/Reactions";
import { objectTypes } from "constants/objects";
import { useKudoReactions } from "../hooks/useKudoReactions/useKudoReactions";

export type KudoSummaryKudo = Derive<
  Kudo,
  {
    author: {
      avatar: true;
      fullName: true;
      id: true;
    };
    createdDate: true;
    id: true;
    text: true;
  }
>;

type Props = {
  actions?: ReactNode;
  kudo: KudoSummaryKudo;
};

export const KudoSummary = ({ kudo, actions }: Props): JSX.Element | null => {
  const userLink = getLinkByType(objectTypes.user, kudo.author.id);
  const { isLoading: reactionsLoading, kudoReactions } = useKudoReactions({
    id: kudo.id,
  });

  return (
    <div data-testid="userKudo" className="flex space-x-3">
      <div>
        <Avatar
          href={userLink}
          size="medium"
          url={kudo.author.avatar ?? undefined}
        />
      </div>
      <div>
        <div className="space-x-1">
          <span>
            <FormattedMessage
              defaultMessage="{name} gave kudos"
              id="kudosList:gaveKudos"
              values={{
                name: (
                  <AnchorNext href={userLink}>
                    {kudo.author.fullName}
                  </AnchorNext>
                ),
              }}
            />
          </span>
          <span>🙌</span>
        </div>
        <div>
          <DangerousHTML html={kudo.text} />
        </div>
        <Show when={!reactionsLoading}>
          <div className="flex">
            <Reactions allReactions={kudoReactions} entityId={kudo.id} />
          </div>
        </Show>
        <div className="flex items-center space-x-3 text-sm text-slate-500">
          <div className="font-normal ">
            <FormattedDate value={kudo.createdDate} />
          </div>
          {actions}
        </div>
      </div>
    </div>
  );
};
