/**
 * this just reimplements https://github.com/sindresorhus/array-move, because it only export ES modules which don't
 * play well with jest at the moment.
 */
export function arrayMoveImmutable<ValueType>(
  array: readonly ValueType[],
  fromIndex: number,
  toIndex: number
): ValueType[] {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

/**
 * this just reimplements https://github.com/sindresorhus/array-move, because it only export ES modules which don't
 * play well with jest at the moment.
 */
export function arrayMoveMutable(
  array: unknown[],
  fromIndex: number,
  toIndex: number
): void {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}
