import classNames from "clsx";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { InlineEditable } from "common/inputs/InlineEditable/InlineEditable";
import { PlainTextArea } from "common/inputs/PlainTextArea/PlainTextArea";
import { twClass } from "utils/twClass";
import { Branch } from "utils/utils";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(250, "The name must be 250 or less characters long.")
    .required(),
});

interface ModalHeaderProps {
  archived?: boolean;
  className?: string;
  closed?: boolean;
  editVisible: boolean;
  entityId?: string;
  onUpdate?: any;
  title: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  onUpdate,
  entityId,
  title,
  className,
  editVisible,
  archived,
  closed,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title,
    },
    onSubmit: (values) =>
      onUpdate(
        /* tslint:disable-next-line */
        {
          id: entityId,
          name: values.title,
        },
        {
          inline: true,
        }
      ),
    validationSchema,
  });

  const handleSubmit = () => {
    if (formik.values.title === title) {
      return;
    }

    if (formik.errors.title) {
      formik.setFieldValue("title", title);
    } else {
      formik.submitForm();
    }
  };

  const isEditable = editVisible && !archived && !closed;

  const titleInput = (
    <PlainTextArea
      autoFocus
      data-cy="modalTitle"
      data-testid="modalTitle"
      className={classNames(
        "-ml-3 block text-2xl font-semibold text-slate-900",
        "w-full overflow-hidden border-2 px-3 py-1"
      )}
      minRows={1}
      name="title"
      onChange={(value) => formik.setFieldValue("title", value)}
      value={formik.values.title}
    />
  );

  return (
    <>
      <div
        className="flex-no-wrap m-1 flex flex-col items-start justify-start"
        data-cy="modalHeader"
      >
        <form className="w-full" onSubmit={formik.handleSubmit}>
          <div
            className={twClass(
              "relative mr-1 items-center leading-5",
              className
            )}
          >
            <Branch
              left={
                <>
                  <InlineEditable
                    className="-ml-3 flex shrink-0 rounded border-2 px-3 py-1 text-2xl border-transparent hover:border-slate-300"
                    editComponent={titleInput}
                    onCancel={() => formik.setFieldValue("title", title)}
                    onEdit={handleSubmit}
                  >
                    {formik.values.title}
                  </InlineEditable>
                  {formik.errors.title && (
                    <div className="my-1 text-base text-red-500">
                      {formik.errors.title}
                    </div>
                  )}
                </>
              }
              right={
                <div className="-ml-3 flex shrink-0 rounded border-2 px-3 py-1 text-2xl border-transparent">
                  {formik.values.title}
                </div>
              }
              test={isEditable}
            />
          </div>
        </form>
      </div>
    </>
  );
};
