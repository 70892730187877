import React from "react";
import { twClass } from "utils/twClass";

interface Props {
  native?: string;
  onDeselect: () => void;
  onSelect: () => void;
  reactionCount: number;
  selected: boolean;
  src?: string;
}

export const EmojiButton = ({
  native,
  src,
  selected = false,
  reactionCount,
  onSelect,
  onDeselect,
}: Props): JSX.Element => {
  const handleEmojiSelect = () => {
    if (selected) {
      onDeselect();
    } else {
      onSelect();
    }
  };
  return (
    <button
      data-cy="IIrFGv4zXWfDpr_yzuOt2"
      className={twClass(
        "my-0.5 mr-1 flex items-center rounded-xl border px-1 bg-slate-100 hover:border-blue-300",
        {
          "bg-blue-50 border-blue-400": selected,
        }
      )}
      onClick={handleEmojiSelect}
      type="button"
    >
      <>
        {native || (
          <img alt="" className="mr-1" height="16px" src={src} width="16px" />
        )}

        <span className="mx-0.5 text-blue-700">{reactionCount}</span>
      </>
    </button>
  );
};
