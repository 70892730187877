import { defineMessages } from "react-intl";

export const messages = defineMessages({
  keyResult1: {
    defaultMessage: "Increase gross profit from 53% to 65%",
    id: "sample:keyResult:company:1",
  },
  keyResult10: {
    defaultMessage: "5% click through rate",
    id: "sample:keyResult:marketing:2",
  },
  keyResult11: {
    defaultMessage: "Increase on-time releases by 50%",
    id: "sample:keyResult:product:1",
  },
  keyResult12: {
    defaultMessage: "Reduce time to release from 12 to 8 weeks",
    id: "sample:keyResult:product:2",
  },
  keyResult13: {
    defaultMessage: "Average resolution time for IT tix <24 hours",
    id: "sample:keyResult:operations:1",
  },
  keyResult14: {
    defaultMessage: "Reduce unnecessary IT costs by 30%",
    id: "sample:keyResult:operations:2",
  },
  keyResult15: {
    defaultMessage: "3 acquisitions",
    id: "sample:keyResult:company:3",
  },
  keyResult16: {
    defaultMessage: "Increase labor productivity from 40 to 52",
    id: "sample:keyResult:company:4",
  },
  keyResult17: {
    defaultMessage: "Increase gross margin from 56 to 65",
    id: "sample:keyResult:company:5",
  },
  keyResult18: {
    defaultMessage: "Up 21 places to be top 10 in F-100 best places to work",
    id: "sample:keyResult:company:6",
  },
  keyResult19: {
    defaultMessage: "eNPS >45",
    id: "sample:keyResult:company:7",
  },
  keyResult2: {
    defaultMessage: "Generate revenue of €20MM",
    id: "sample:keyResult:company:2",
  },
  keyResult20: {
    defaultMessage: "Close $4m in Series A funding",
    id: "sample:keyResult:company:8",
  },
  keyResult21: {
    defaultMessage: "1,200 transactions per day",
    id: "sample:keyResult:company:9",
  },
  keyResult22: {
    defaultMessage: "Global revenue of $550K MRR",
    id: "sample:keyResult:company:10",
  },
  keyResult23: {
    defaultMessage: "Revenue in UK of $450K MRR",
    id: "sample:keyResult:company:11",
  },
  keyResult24: {
    defaultMessage: "Market share of 22%",
    id: "sample:keyResult:company:12",
  },
  keyResult25: {
    defaultMessage: "Monthly churn <6%",
    id: "sample:keyResult:company:13",
  },
  keyResult26: {
    defaultMessage: "Customer NPS >15",
    id: "sample:keyResult:company:14",
  },
  keyResult27: {
    defaultMessage: "95% of KRs are up to date every 2 weeks",
    id: "sample:keyResult:company:15",
  },
  keyResult28: {
    defaultMessage: "All teams have OKRs and KPIs in Perdoo",
    id: "nQAwYn",
  },
  keyResult29: {
    defaultMessage: "8 industry publication recognitions",
    id: "sample:keyResult:company:17",
  },
  keyResult3: {
    defaultMessage: "Increase net profit by 10%",
    id: "sample:keyResult:finance:1",
  },
  keyResult30: {
    defaultMessage: "Speaker at the top 3 industry events",
    id: "sample:keyResult:company:18",
  },
  keyResult31: {
    defaultMessage: "Increase UV-to-Lead CR from 2% to 4.5%",
    id: "sample:keyResult:marketing:3",
  },
  keyResult32: {
    defaultMessage: "55% leads meet our ICP",
    id: "sample:keyResult:marketing:4",
  },
  keyResult33: {
    defaultMessage: "Improve domain ranking from 12 to 5",
    id: "sample:keyResult:marketing:5",
  },
  keyResult34: {
    defaultMessage: "Improve our YSlow Score from 72% to 90%",
    id: "sample:keyResult:marketing:6",
  },
  keyResult35: {
    defaultMessage: "Improve our PageSpeed Score from 66% to 87%",
    id: "sample:keyResult:marketing:7",
  },
  keyResult36: {
    defaultMessage: "Viral coefficient >1.3 for our surveys product",
    id: "sample:keyResult:marketing:8",
  },
  keyResult37: {
    defaultMessage: "Increase Lead to MQL conversion to 70%",
    id: "sample:keyResult:marketing:9",
  },
  keyResult38: {
    defaultMessage: "Increase avg time on page from 34 to 55 seconds",
    id: "sample:keyResult:marketing:10",
  },
  keyResult39: {
    defaultMessage: "30 interview requests from industry leading publications",
    id: "sample:keyResult:marketing:11",
  },
  keyResult4: {
    defaultMessage: "Reduce operating costs by 30%",
    id: "sample:keyResult:finance:2",
  },
  keyResult40: {
    defaultMessage: "75% team knows how to create video content",
    id: "sample:keyResult:marketing:12",
  },
  keyResult41: {
    defaultMessage: "25 reshares",
    id: "sample:keyResult:marketing:13",
  },
  keyResult42: {
    defaultMessage: "4 publication features",
    id: "sample:keyResult:marketing:14",
  },
  keyResult43: {
    defaultMessage: "90 invitation requests from publication features",
    id: "sample:keyResult:marketing:15",
  },
  keyResult44: {
    defaultMessage: "Generate 220 leads from Facebook",
    id: "sample:keyResult:marketing:16",
  },
  keyResult45: {
    defaultMessage: "Achieve 200000 followers",
    id: "sample:keyResult:marketing:17",
  },
  keyResult46: {
    defaultMessage: "Generate 350 leads from influencer posts",
    id: "sample:keyResult:marketing:18",
  },
  keyResult47: {
    defaultMessage: "Increase Youtube CR from 0.7% to 2%",
    id: "sample:keyResult:marketing:19",
  },
  keyResult48: {
    defaultMessage: "Triple traffic to the website from Youtube",
    id: "sample:keyResult:marketing:20",
  },
  keyResult49: {
    defaultMessage: "Increase Trial-to-Customer conversion rate from 6 to 11%",
    id: "sample:keyResult:sales:3",
  },
  keyResult5: {
    defaultMessage: "Reduce time to fix from 6 to 2 days",
    id: "sample:keyResult:engineering:1",
  },
  keyResult50: {
    defaultMessage: "Increase trial satisfaction score from 3.6 to 4.5",
    id: "sample:keyResult:sales:4",
  },
  keyResult51: {
    defaultMessage: "Generate $15k in partner revenue",
    id: "sample:keyResult:sales:5",
  },
  keyResult52: {
    defaultMessage: "20 new customers from partner referrals",
    id: "sample:keyResult:sales:6",
  },
  keyResult53: {
    defaultMessage:
      "Increase Sales Qualified Lead to Customer conversion rate to 35%",
    id: "sample:keyResult:sales:7",
  },
  keyResult54: {
    defaultMessage: "Generate 1000 new prospects",
    id: "sample:keyResult:sales:8",
  },
  keyResult55: {
    defaultMessage: "Increase average deal size to $10k",
    id: "sample:keyResult:sales:9",
  },
  keyResult56: {
    defaultMessage: "Have a 8% close rate",
    id: "sample:keyResult:sales:10",
  },
  keyResult57: {
    defaultMessage: "33% increase in ICP deals",
    id: "sample:keyResult:sales:11",
  },
  keyResult58: {
    defaultMessage: "Increase in # deals closed per week to 10",
    id: "sample:keyResult:sales:12",
  },
  keyResult59: {
    defaultMessage: "Reduction in time spent on paperwork by 30%",
    id: "sample:keyResult:sales:13",
  },
  keyResult6: {
    defaultMessage: "Reduce client complaints by 70%",
    id: "sample:keyResult:engineering:2",
  },
  keyResult60: {
    defaultMessage: "Increase demo satisfaction score from 65 to 85",
    id: "sample:keyResult:sales:14",
  },
  keyResult61: {
    defaultMessage: "Hire a new Sales Development Representative",
    id: "sample:keyResult:sales:15",
  },
  keyResult62: {
    defaultMessage: "Increase Sales Qualified Leads by 25%",
    id: "sample:keyResult:sales:16",
  },
  keyResult63: {
    defaultMessage: "Reduce contract negotiation time from 60 to 45 days",
    id: "sample:keyResult:sales:17",
  },
  keyResult64: {
    defaultMessage: "75% positive client feedback",
    id: "sample:keyResult:engineering:6",
  },
  keyResult65: {
    defaultMessage: "9 out of 10 accurate recommendations",
    id: "sample:keyResult:engineering:7",
  },
  keyResult66: {
    defaultMessage: "35% clicks on recommendations",
    id: "sample:keyResult:engineering:8",
  },
  keyResult67: {
    defaultMessage: "Reduce critical bugs by 20%",
    id: "sample:keyResult:engineering:9",
  },
  keyResult68: {
    defaultMessage: "Improve code quality by 25%",
    id: "sample:keyResult:engineering:10",
  },
  keyResult69: {
    defaultMessage: "Time to recover reduced from 1 hour to 25 minutes",
    id: "sample:keyResult:engineering:11",
  },
  keyResult7: {
    defaultMessage: "Reduce time to close from 90 to 60 days",
    id: "sample:keyResult:sales:1",
  },
  keyResult70: {
    defaultMessage: "Reduce Pull Request review time from 72 to 36 hours",
    id: "sample:keyResult:engineering:12",
  },
  keyResult71: {
    defaultMessage: "Increase in new releases/per week from 1 to 3",
    id: "sample:keyResult:engineering:13",
  },
  keyResult72: {
    defaultMessage: "Reduce response time to 200ms",
    id: "sample:keyResult:engineering:14",
  },
  keyResult73: {
    defaultMessage: "Reduce time to onboard to 8 minutes",
    id: "sample:keyResult:product:15",
  },
  keyResult74: {
    defaultMessage: "Improve onboarding NPS to 75",
    id: "sample:keyResult:product:16",
  },
  keyResult75: {
    defaultMessage: "Test team prototype adoption of 30%",
    id: "8x9ObT",
  },
  keyResult76: {
    defaultMessage: "Test team prototype acceptance of 75%",
    id: "Ql+60H",
  },
  keyResult77: {
    defaultMessage: "New features have 60% utilization",
    id: "sample:keyResult:product:6",
  },
  keyResult78: {
    defaultMessage: "Reduce overspend by 15%",
    id: "sample:keyResult:finance:3",
  },
  keyResult79: {
    defaultMessage: "Increase in positive shareholder feedback by 30%",
    id: "sample:keyResult:finance:4",
  },
  keyResult8: {
    defaultMessage: "Close 25 deals per month",
    id: "sample:keyResult:sales:2",
  },
  keyResult80: {
    defaultMessage: "Net profit increase of 10%",
    id: "sample:keyResult:finance:5",
  },
  keyResult81: {
    defaultMessage: "10% reduction in operating costs",
    id: "sample:keyResult:finance:6",
  },
  keyResult82: {
    defaultMessage: "Reduce production delays from 4 weeks to 2 week",
    id: "sample:keyResult:operations:3",
  },
  keyResult83: {
    defaultMessage: "Reduce cost by $30000",
    id: "sample:keyResult:operations:4",
  },
  keyResult84: {
    defaultMessage: "Reduce time for initial RFP to 75 days",
    id: "sample:keyResult:operations:6",
  },
  keyResult9: {
    defaultMessage: "50% open rate",
    id: "sample:keyResult:marketing:1",
  },
  kpi1: { defaultMessage: "Growth Rate ≥ 20% ", id: "sample:kpi:company:1" },
  kpi2: {
    defaultMessage: "Gross Profit Margin ≥ 30%",
    id: "sample:kpi:finance:1",
  },
  kpi3: {
    defaultMessage: "Number of open P1&2 bugs ≤ 8",
    id: "sample:kpi:engineering:1",
  },
  kpi4: {
    defaultMessage: "Average Deal Value ≥ $8k",
    id: "sample:kpi:sales:1",
  },
  kpi5: {
    defaultMessage: "Unique Visitor to Lead Conversion Rate ≥ 5%",
    id: "sample:kpi:marketing:1",
  },
  kpi6: {
    defaultMessage: "New Feature usage after 14 days ≥ 50%  ",
    id: "sample:kpi:product:1",
  },
  kpi7: {
    defaultMessage: "Order Fulfillment Time ≤ 7 days",
    id: "sample:kpi:operations:1",
  },
  objective1: {
    defaultMessage:
      "Solidify finances so we’re ready to take our product to the next level",
    id: "sample:objective:company:1",
  },
  objective10: {
    defaultMessage: "Be a top place to work in the US",
    id: "sample:objective:company:4",
  },
  objective11: {
    defaultMessage: "Prove that our great idea will make a great company",
    id: "sample:objective:company:5",
  },
  objective12: {
    defaultMessage: "Build a marketplace that Amazon would envy",
    id: "sample:objective:company:6",
  },
  objective13: {
    defaultMessage: "Become the UK small biz banking leader",
    id: "sample:objective:company:7",
  },
  objective14: {
    defaultMessage: "Gain a strong foothold in affordable beauty",
    id: "sample:objective:company:8",
  },
  objective15: {
    defaultMessage: "Become the most trusted brand in German fitness",
    id: "sample:objective:company:9",
  },
  objective16: {
    defaultMessage: "Create a more goal-oriented culture",
    id: "sample:objective:company:10",
  },
  objective17: {
    defaultMessage: "We're thought leaders in eco-friendly home cleaning",
    id: "sample:objective:company:11",
  },
  objective18: {
    defaultMessage: "Launch a higher converting site for working moms",
    id: "sample:objective:marketing:2",
  },
  objective19: {
    defaultMessage: "Strengthen our SEO ranking for 'affordable beauty'",
    id: "sample:objective:marketing:3",
  },
  objective2: {
    defaultMessage: "Our budgets are cost-effective across all departments",
    id: "sample:objective:finance:1",
  },
  objective20: {
    defaultMessage: "Optimize SEO through technical upgrades",
    id: "sample:objective:marketing:4",
  },
  objective21: {
    defaultMessage: "Double-down on our growth loops",
    id: "sample:objective:marketing:5",
  },
  objective22: {
    defaultMessage: "Optimize content production to increase MQLs",
    id: "sample:objective:marketing:6",
  },
  objective23: {
    defaultMessage: "Be a rental law subject matter expert",
    id: "sample:objective:marketing:7",
  },
  objective24: {
    defaultMessage: "Develop processes to become a video creation engine",
    id: "sample:objective:marketing:8",
  },
  objective25: {
    defaultMessage: "Spread the word about our new community event",
    id: "sample:objective:marketing:9",
  },
  objective26: {
    defaultMessage: "Diversify by turning FB into an acquisition channel",
    id: "sample:objective:marketing:10",
  },
  objective27: {
    defaultMessage:
      "Expand our Instagram footprint with influencer partnerships",
    id: "sample:objective:marketing:11",
  },
  objective28: {
    defaultMessage: "Turn YouTube into our best lead generation channel",
    id: "sample:objective:marketing:12",
  },
  objective29: {
    defaultMessage:
      "Our trial experience is so good that people want to marry us",
    id: "sample:objective:sales:2",
  },
  objective3: {
    defaultMessage: "Speed up deployments of important bug fixes",
    id: "sample:objective:engineering:1",
  },
  objective30: {
    defaultMessage: "Turn the partner network into a revenue stream",
    id: "sample:objective:sales:3",
  },
  objective31: {
    defaultMessage: "Our sales team has the best closers",
    id: "sample:objective:sales:4",
  },
  objective32: {
    defaultMessage: "Fill the top of the funnel through outbound efforts",
    id: "sample:objective:sales:5",
  },
  objective33: {
    defaultMessage: "No one can refuse our product offer",
    id: "sample:objective:sales:6",
  },
  objective34: {
    defaultMessage: "Boost our sales by targeting the right customers",
    id: "sample:objective:sales:7",
  },
  objective35: {
    defaultMessage: "Keep the sales team focused on closing, not admin work",
    id: "sample:objective:sales:8",
  },
  objective36: {
    defaultMessage: "Improve the client demo experience",
    id: "sample:objective:sales:9",
  },
  objective37: {
    defaultMessage: "Grow our sales team to support Sales Qualified Leads",
    id: "sample:objective:sales:10",
  },
  objective38: {
    defaultMessage: "Increase trust during contract negotiation",
    id: "sample:objective:sales:11",
  },
  objective39: {
    defaultMessage: "Release an amazing Recommendations Engine",
    id: "sample:objective:engineering:2",
  },
  objective4: {
    defaultMessage:
      "Optimize the sales funnel to close more deals in less time",
    id: "sample:objective:sales:1",
  },
  objective40: {
    defaultMessage: "Make quality the top priority in our engineering process",
    id: "sample:objective:engineering:3",
  },
  objective41: {
    defaultMessage: "Improve stability of the site",
    id: "sample:objective:engineering:4",
  },
  objective42: {
    defaultMessage:
      "Establish a more efficient team workflow with a simpler architecture",
    id: "sample:objective:engineering:5",
  },
  objective43: {
    defaultMessage:
      "Give our customers a speedy, uninterrupted experience with our tool",
    id: "sample:objective:engineering:6",
  },
  objective44: {
    defaultMessage: "Product setup is easy and fast",
    id: "sample:objective:product:2",
  },
  objective45: {
    defaultMessage: "Validate our product hypothesis by testing our prototype",
    id: "sample:objective:product:3",
  },
  objective46: {
    defaultMessage:
      "One step effortlessly leads to the next in our product user journey",
    id: "sample:objective:product:4",
  },
  objective47: {
    defaultMessage: "Tighten financial controls",
    id: "sample:objective:finance:2",
  },
  objective48: {
    defaultMessage: "Our shareholders find our reporting insightful",
    id: "sample:objective:finance:3",
  },
  objective49: {
    defaultMessage: "Our budgets are cost effective across all departments",
    id: "sample:objective:finance:4",
  },
  objective5: {
    defaultMessage: "Launch an engaging weekly newsletter",
    id: "sample:objective:marketing:1",
  },
  objective50: {
    defaultMessage:
      "Significantly improve our tracking system to prevent production delays",
    id: "sample:objective:operations:2",
  },
  objective51: {
    defaultMessage: "Bring production costs to an all time low",
    id: "sample:objective:operations:3",
  },
  objective52: {
    defaultMessage:
      "Advance the request for proposal process faster without sacrificing quality",
    id: "sample:objective:operations:4",
  },
  objective6: {
    defaultMessage:
      "Reimagine the product development process to make it more predictable",
    id: "sample:objective:product:1",
  },
  objective7: {
    defaultMessage: "Improve our IT infrastructure to increase efficiency",
    id: "sample:objective:operations:1",
  },
  objective8: {
    defaultMessage: "Crush the competition through acquisitions",
    id: "sample:objective:company:2",
  },
  objective9: {
    defaultMessage: "We're as seamless as a McDonald's franchise",
    id: "sample:objective:company:3",
  },
});
