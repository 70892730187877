import React, { MouseEventHandler, ReactNode } from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ComboStateProps } from "legacy/components/Combo/Combo.types";
import { twClass } from "utils/twClass";

type Props = ComboStateProps & {
  children: ReactNode;
  className?: string;
};

/**
 * container for combo actions like delete, edit.
 */
export const ComboActions = ({ className, children }: Props): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex items-center justify-center gap-2",
        "text-slate-900",
        className
      )}
    >
      {children}
    </div>
  );
};

type ComboActionProps = ComboStateProps & {
  className?: string;
  onClick?: () => void;
};

const ComboAction = ({
  className,
  onClick,
  selected,
  type,
}: ComboActionProps & {
  type: "delete" | "edit";
}): JSX.Element => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick?.();
  };
  return (
    <IconButton
      data-cy="uxMwv6PyniDufbgunaRN6"
      className={twClass(
        selected && "text-white hover:text-slate-200",
        className
      )}
      name={type}
      onClick={handleClick}
    />
  );
};

export const ComboActionDelete = (props: ComboActionProps): JSX.Element => (
  <ComboAction type="delete" {...props} />
);

export const ComboActionEdit = (props: ComboActionProps): JSX.Element => (
  <ComboAction type="edit" {...props} />
);
