import isEmpty from "lodash/isEmpty";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { defineMessages, injectIntl, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { components } from "react-select";
import { Icon } from "common/icons/Icon/Icon";
import { colorTheme } from "constants/colorTheme";
import { Select } from "legacy/components/Select/Select";
import { objectTypesIntl } from "../../../../constants/objects";
import { useGetEntityUrl } from "../hooks/useGetEntityUrl";
import { searchGlobal } from "../NavBar.actions";

const customStyles = {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
  control: (provided, state) => ({
    ...provided,
    backgroundColor: colorTheme.slate[100],
    borderWidth: 0,
    color: colorTheme.slate[500],
    width: state.isFocused ? 450 : 120,
  }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'base' implicitly has an 'any' type.
  menuPortal: (base) => ({
    ...base,
    zIndex: 50, // equivalent to `z-50`
  }),
};

const Option = injectIntl(({ data, intl, ...props }) => {
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  if (!data.label) return null;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const { leadFullName, timeframeName, objectiveName } = data.attributes;

  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <components.Option {...props}>
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
      <div className="truncate">{data.label}</div>
      <div className="mr-2 mt-px flex text-xs text-slate-500">
        <div className="mr-2 flex min-w-0 items-center">
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {objectTypesIntl(intl)[data.type]}
        </div>
        {timeframeName && (
          <div className="mr-2 flex min-w-0 items-center">
            <Icon className="mr-1" name="calendar_today" size="base" />
            <div className="truncate">{timeframeName}</div>
          </div>
        )}
        {leadFullName && (
          <div className="mr-2 flex min-w-0 items-center">
            <Icon className="mr-1" name="person" size="base" />
            <div className="truncate">{leadFullName}</div>
          </div>
        )}
        {objectiveName && (
          <div className="mr-2 flex min-w-0 max-w-[25%] items-center">
            <Icon className="mr-1" name="flag" size="base" />
            <div className="truncate">{objectiveName}</div>
          </div>
        )}
      </div>
    </components.Option>
  );
});

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const ValueContainer = ({ children, ...props }) => (
  <>
    <Icon className="ml-2" name="search" size="2xl" />
    {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </>
);

const messages = defineMessages({
  noResults: {
    defaultMessage: "No results",
    id: "navbar:search:noResults",
  },
  placeholder: {
    defaultMessage: "Search for OKRs, Users or Groups",
    id: "navbar:search:placeholder",
  },
});

export const GlobalNavbarSearch = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState();
  const dispatch = useDispatch();
  const router = useRouter();
  const intl = useIntl();
  const getEntityUrl = useGetEntityUrl();

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'query' implicitly has an 'any' type.
  const onSearch = (query, callback) => {
    if (isEmpty(query)) {
      return callback([]);
    }

    // @ts-expect-error FIXME: TS2345: Argument of type '(dispatch: any) => any' is not assignable to parameter of type 'AnyAction'.
    return dispatch(searchGlobal(query));
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const onChange = (value) => {
    if (value && !Array.isArray(value)) {
      router.push(getEntityUrl(value.type, value.id));
    }
  };

  return (
    <div className="pd-navbar-search" data-cy="globalSearchText">
      <Select
        async
        components={{
          IndicatorsContainer: () => null,
          Option,
          ValueContainer,
        }}
        loadOptions={onSearch}
        name="form-field-global-search"
        noOptionsMessage={() => null}
        noResultsText={intl.formatMessage(messages.noResults)}
        onChange={onChange}
        onInputChange={setSearchValue}
        placeholder={intl.formatMessage(messages.placeholder)}
        styles={customStyles}
        value={searchValue}
      />
    </div>
  );
};
