import { CommitValuesFragment } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Target } from "common/event/Target/Target";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { ProgressUpdateTarget } from "common/progress/ShortProgressUpdate/ShortProgressUpdate";

export type ProgressUpdateCommit = Pick<
  CommitValuesFragment,
  "delta" | "valueBefore"
>;

export type ProgressUpdateProps = {
  commit: ProgressUpdateCommit;
  entity: ProgressUpdateTarget;
};

export const ProgressUpdate = ({
  commit,
  entity,
}: ProgressUpdateProps): JSX.Element => {
  const { delta, valueBefore } = commit;
  const { metricUnit } = entity;

  return (
    <FormattedMessage
      defaultMessage="Update from {before} to {after}"
      id="event:formatter:commit:progressUpdate"
      values={{
        after: (
          <Target
            element={
              <FormattedMetric value={valueBefore + delta} unit={metricUnit} />
            }
          />
        ),
        before: (
          <Target
            element={<FormattedMetric value={valueBefore} unit={metricUnit} />}
          />
        ),
      }}
    />
  );
};
