import {
  useDeleteOneOnOneMeetingItemTemplateMutation,
  useGetOneOnOneMeetingItemTemplateQuery,
} from "@graphql";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useConfirmationModal } from "common/overlay/ConfirmationModal/hooks/useConfirmationModal";
import { Modal } from "common/overlay/Modal/Modal";
import { MEETING_ITEM_TEMPLATE_DELETED } from "constants/tracking";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { OneOnOneMeetingItemTemplateForm } from "./OneOnOneMeetingItemTemplateForm";

type Props = {
  close: () => void;
  id: string;
};

export const EditMeetingItemTemplateForm = ({
  close,
  id,
}: Props): JSX.Element => {
  const { $t } = useIntl();

  const { data } = useGetOneOnOneMeetingItemTemplateQuery({
    variables: { id },
  });
  const [deleteTemplate] = useDeleteOneOnOneMeetingItemTemplateMutation({
    update: (cache, { data: mutationData }) => {
      // if there was an error with the mutation, cancel cache evict
      if (!isEmpty(mutationData?.deleteOneOnOneMeetingItemTemplate?.errors))
        return;
      const normalisedId = cache.identify({
        __typename: "oneOnOneMeetingItemTemplate",
        id,
      });
      cache.evict({ id: normalisedId });
      cache.gc();
    },
    variables: {
      input: {
        id,
      },
    },
  });
  const { openModal: openConfirmationModal } = useConfirmationModal();
  const loading = isNil(data);

  if (loading) {
    return (
      <Modal
        backdropClass="!z-modal-prio-backdrop"
        backdropClose={false}
        className="!z-modal-prio"
        isOpen
        loading
        onClose={close}
        title={<FormattedMessage defaultMessage="Loading..." id="gjBiyj" />}
      />
    );
  }

  const initialValues = {
    description: data.oneOnOneMeetingItemTemplate?.description ?? "",
    id,
    name: data.oneOnOneMeetingItemTemplate?.name ?? "",
    talkingPoints: data.oneOnOneMeetingItemTemplate?.talkingPoints ?? [""],
  };

  const handleDeleteTemplate = async () => {
    const response = await deleteTemplate();
    const { hasError } = handleErrors(
      response,
      response.data?.deleteOneOnOneMeetingItemTemplate?.errors
    );
    if (hasError) {
      toast.failure(
        $t({ defaultMessage: "Error deleting template", id: "V8RNCE" })
      );
      close();
      return;
    }
    toast.success($t({ defaultMessage: "template deleted", id: "ZThqqb" }));
    track(MEETING_ITEM_TEMPLATE_DELETED, {
      templateId: id,
    });
    close();
  };

  const confirmDeleteTemplate = () => {
    openConfirmationModal({
      action: async () => handleDeleteTemplate(),
      children: (
        <FormattedMessage
          defaultMessage="Are you sure you want to delete {name} template?"
          id="5CXdmn"
          values={{
            name: data.oneOnOneMeetingItemTemplate?.name,
          }}
        />
      ),
      confirmText: (
        <FormattedMessage defaultMessage="Confirm" id="global:confirm" />
      ),
      title: <FormattedMessage defaultMessage="Delete template" id="AghqfS" />,
    });
  };
  return (
    <>
      <OneOnOneMeetingItemTemplateForm
        close={close}
        initialValues={initialValues}
        isEdit
        onDelete={confirmDeleteTemplate}
      />
    </>
  );
};
