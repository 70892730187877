import { Form, Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import invariant from "tiny-invariant";
import * as Yup from "yup";
import { CancelButton, SaveButton, TextButton } from "common/buttons";
import { AsanaProgressTypeField } from "common/form/AsanaProgressTypeField";
import { Label } from "common/inputs";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { AsanaProjectField } from "common/result/ResultForm/AsanaProjectField/AsanaProjectField";
import { AsanaTaskField } from "common/result/ResultForm/AsanaTaskField/AsanaTaskField";
import { AsanaUpgradeBanner } from "common/result/ResultForm/AsanaUpgradeBanner/AsanaUpgradeBanner";
import { AsanaWorkspaceField } from "common/result/ResultForm/AsanaWorkspaceField/AsanaWorkspaceField";
import {
  asanaDataToIntegrationField,
  integrationFieldToAsanaData,
} from "common/result/utils/asana";
import { AsanaFormLogic } from "../AsanaFormLogic/AsanaFormLogic";

type Values = {
  asanaProgressType?: string;
  asanaProject?: string;
  asanaTask?: string;
  asanaWorkspace?: string;
};

type Props = {
  onClose?: () => void;
  onDisconnect?: () => void;
  onSubmit?: (value: string) => void;
  /** The goal's integration field */
  value: string | null;
};

const schema = Yup.object().shape({
  asanaProgressType: Yup.string().required("Required"),
  asanaProject: Yup.string().required("Required"),
  asanaTask: Yup.string().when("asanaProgressType", {
    is: "task",
    then: (s) => s.required("Required"),
  }),
  asanaWorkspace: Yup.string().required("Required"),
});

const initialValuesFromIntegrationField = (
  integrationField: string | null
): Values => {
  if (!integrationField) {
    return {
      asanaProgressType: "project",
    };
  }

  const data = integrationFieldToAsanaData(integrationField);
  return {
    asanaProgressType: !!data.taskGid ? "task" : "project",
    asanaProject: data.projectGid,
    asanaTask: data.taskGid ?? undefined,
    asanaWorkspace: data.workspaceGid,
  };
};

export const AsanaModal = ({
  onClose,
  onSubmit,
  value,
  onDisconnect,
}: Props): JSX.Element => {
  const initialValues = initialValuesFromIntegrationField(value);

  const submitAndClose = async (values: Values) => {
    // Invariants coming from formik's validation
    invariant(!!values.asanaProject);
    invariant(!!values.asanaWorkspace);
    const integrationField = asanaDataToIntegrationField({
      projectGid: values.asanaProject,
      taskGid: values.asanaTask ?? null,
      workspaceGid: values.asanaWorkspace,
    });
    onSubmit?.(integrationField);
    onClose?.();
  };

  const disconnectAndClose = () => {
    onDisconnect?.();
    onClose?.();
  };

  const close = () => {
    onClose?.();
  };

  return (
    <Modal
      backdropClass="!z-modal-top-prio-backdrop"
      className="!z-modal-top-prio mt-[15%]"
      id="modal-confirm"
      isOpen
      onClose={onClose}
      scrollable={false}
      size="sm"
      title={
        <FormattedMessage defaultMessage="Asana integration" id="HmfarO" />
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitAndClose}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
      >
        {({ values, submitForm }) => {
          return (
            <Form>
              <AsanaFormLogic<Values>>
                <div className="p-6 space-y-2">
                  <AsanaWorkspaceField isClearable />

                  <AsanaProjectField isClearable />

                  <Label htmlFor="asanaProgressType">
                    <FormattedMessage
                      defaultMessage="Calculate progress based on"
                      id="result:form:asanaProgressType:label"
                    />
                  </Label>
                  <AsanaProgressTypeField />

                  {values.asanaProgressType === "task" && (
                    <>
                      <AsanaTaskField isClearable />
                      <AsanaUpgradeBanner />
                    </>
                  )}
                </div>
                <ModalFooter
                  showDivider
                  className="flex flex-row items-center justify-between"
                >
                  {!!onDisconnect && (
                    <div>
                      <TextButton
                        className="text-red-500"
                        size="small"
                        onClick={disconnectAndClose}
                      >
                        <FormattedMessage
                          defaultMessage="Disconnect"
                          id="qj1uhz"
                        />
                      </TextButton>
                    </div>
                  )}
                  <div className="space-x-3 flex items-center">
                    <CancelButton onClick={close} />
                    <SaveButton onClick={submitForm} />
                  </div>
                </ModalFooter>
              </AsanaFormLogic>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
