import classNames from "clsx";
import React from "react";
import { twMerge } from "utils/twMerge";

interface Props {
  circleSize?: string;
  className?: string;
  status?: string | null;
  value?: string;
}

/**
 * displays a status circle next to the kpi value
 */
export const KpiStatus = ({
  circleSize,
  className,
  status,
  value,
}: Props): JSX.Element => {
  const displayValue = value ? <span>{value}</span> : <span>&nbsp;</span>;

  const cyHealthIndicator =
    // eslint-disable-next-line no-nested-ternary
    status === "healthy"
      ? "kpiHealthy"
      : status === "unhealthy"
      ? "kpiUnhealthy"
      : undefined;

  return (
    <div
      className={twMerge("flex items-center justify-center gap-1", className)}
      data-cy="kpiStatusHealth"
    >
      {status && (
        <div
          className={classNames("rounded-full", {
            "bg-green-500": status === "healthy",
            "bg-yellow-500": status === "unhealthy",
          })}
          data-cy={cyHealthIndicator}
          style={{
            // circles smaller than this become oval sometimes, no idea why
            height: circleSize ?? "8px",
            width: circleSize ?? "8px",
          }}
        />
      )}
      {displayValue}
    </div>
  );
};
