import { useMutation } from "@apollo/client";
import {
  Kpi,
  namedOperations,
  useAddKpiMutation,
  useCreateOkrFromKpiMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNull, partial } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { DELETE_KPI_MUTATION } from "common/kpi/queries";
import { ListItem } from "common/misc/ListItem/ListItem";
import { getAlignedToField } from "common/objective/utils/utils";
import { Confirm } from "common/overlay/Confirm/Confirm";
import { ConfirmDeletePermanently } from "common/overlay/ConfirmDeletePermanently/ConfirmDeletePermanently";
import { MODAL_TYPES } from "constants/index";
import { ADD_OBJECTIVE, DELETE_KPI, EDIT_KPI } from "constants/tracking";
import { useGlobalTimeframe } from "hooks/useGlobalTf/useGlobalTimeframe";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { showModal } from "legacy/actions/actions";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { showGqlErrorToast } from "utils/utils";
import { useArchiveKpi } from "../hooks/useArchiveKpi";
import { messages } from "./KpiDropdown.messages";

export type KpiDropdownOption =
  | "addOkr"
  | "archive"
  | "delete"
  | "edit"
  | "growth-board-remove"
  | "unalign"
  | "view";

type KpiDropdown = Derive<
  Kpi,
  {
    archived: true;
    canDelete: true;
    canPatch: true;
    goalThreshold: true;
    id: true;
    name: true;
  }
>;

interface Props {
  afterDelete?: () => void;
  // TODO: probably best to pass an array of object, with an onClick event per option
  dropdownOptions?: KpiDropdownOption[];
  kpi: KpiDropdown;
  onClickView?: (id: any) => void;
  onRemoveKpi?: () => void;
}

export const KpiDropdown = ({
  afterDelete,
  dropdownOptions = ["view", "edit", "archive", "delete", "addOkr"],
  kpi,
  onClickView,
  onRemoveKpi,
}: Props): JSX.Element => {
  const { id, name } = kpi;

  const globalTimeframe = useGlobalTimeframe();
  const { goToObjective, goToKpi } = useModalRouter();

  const { archiveKpi, unarchiveKpi } = useArchiveKpi();
  const [editKpi] = useAddKpiMutation({
    refetchQueries: [
      namedOperations.Query.getRoadmap,
      namedOperations.Query.getGrowthBoardKpis,
      namedOperations.Query.getKpiBoardKpis,
    ],
  });
  const [deleteKpi] = useMutation(DELETE_KPI_MUTATION, {
    refetchQueries: [
      namedOperations.Query.GetCompanyKpis,
      namedOperations.Query.getGroupKPICount,
      namedOperations.Query.getGrowthBoardKpis,
      namedOperations.Query.getKpiBoardKpis,
      namedOperations.Query.getKpisByGroup,
      namedOperations.Query.getRoadmap,
      namedOperations.Query.getTimelineKpiSnapshots,
      namedOperations.Query.getTimelineKpiStats,
      namedOperations.Query.getTimelineStrategicPillars,
      namedOperations.Query.getUserOkrs,
      namedOperations.Query.KpisByUser,
    ],
  });
  const [createOkrFromKpi] = useCreateOkrFromKpiMutation({
    refetchQueries: [
      namedOperations.Query.getProfileObjectives,
      namedOperations.Query.getGroupProgress,
      namedOperations.Query.getCompanyHeader,
      namedOperations.Query.getGroupHeader,
      namedOperations.Query.getGrowthBoardKpis,
      namedOperations.Query.getKpiBoardKpis,
    ],
  });
  const dispatch = useDispatch();
  const intl = useIntl();
  const permissions = usePermissions();

  const handleEdit = () => {
    goToKpi({ kpiId: id });
    track(EDIT_KPI);
  };

  const handleUnalignKpiFromStrategicPillar = async () => {
    await editKpi({ variables: { input: { id, strategicPillar: null } } });
    toast.success(intl.formatMessage(messages.unalignKpiToast));
  };

  const handleDeleteKpi = async () => {
    await deleteKpi({ variables: { input: { id } } });
    track(DELETE_KPI);
    if (afterDelete) afterDelete();
    toast.success(
      intl.formatMessage({ defaultMessage: "KPI removed", id: "KTAOtL" })
    );
  };

  const createOkr = () => {
    createOkrFromKpi({ variables: { id } }).then((response) => {
      if (response.errors) {
        return showGqlErrorToast(response);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const objective = response.data!.kpiCreateOkr!.objective!;

      track(ADD_OBJECTIVE, {
        alignedTo: getAlignedToField(objective),
        description_added: !!objective.description,
        end_date: objective.dueDate,
        start_date: objective.startDate,
        status: objective.status,
        uiElementId: "kpiDropdown",
      });

      goToObjective({ oId: objective.id });
    });
  };

  const handleAddOkr = () => {
    if (isNull(globalTimeframe)) {
      toast.failure(
        intl.formatMessage({
          defaultMessage:
            "You don't have any active timeframes. Admins can create timeframes in Configure.",
          id: "addObjectiveModel:noTimeframe",
        })
      );
      return;
    }
    if (kpi.goalThreshold === null) {
      dispatch(
        showModal(MODAL_TYPES.KPI_SET_TARGET_ADD_OBJECTIVE, {
          callback: createOkr,
          kpi,
        })
      );
    } else {
      createOkr();
    }
  };

  return (
    <>
      {dropdownOptions.includes("view") && (
        <ListItem data-cy="view-details-kpi" onClick={() => onClickView?.(id)}>
          <FormattedMessage
            defaultMessage="View Details"
            id="kpi:details:title"
          />
        </ListItem>
      )}
      {dropdownOptions.includes("edit") && kpi.canPatch && (
        <ListItem data-cy="edit-kpi" onClick={handleEdit}>
          <FormattedMessage defaultMessage="Edit KPI" id="kpi:edit:title" />
        </ListItem>
      )}
      {dropdownOptions.includes("growth-board-remove") &&
        permissions.canUpdateGrowthBoard && (
          <ListItem data-cy="growth-board-remove" onClick={onRemoveKpi}>
            <FormattedMessage
              defaultMessage="Remove from board"
              id="growth-board:remove:title"
            />
          </ListItem>
        )}
      {dropdownOptions.includes("archive") &&
        (!kpi.archived ? (
          <Confirm
            size="sm"
            body={
              <div className="space-y-3 mt-3">
                <FormattedMessage
                  defaultMessage="Are you sure you want to archive this KPI?"
                  id="Tp82WM"
                  tagName="p"
                />
                <FormattedMessage
                  defaultMessage="Archived KPIs are:"
                  id="4eK23m"
                  tagName="p"
                />
                <p className="space-x-2">
                  <span>❌</span>
                  <FormattedMessage
                    defaultMessage="No longer possible to update progress, unless unarchived"
                    id="44uvuV"
                    tagName="span"
                  />
                </p>
                <p className="space-x-2">
                  <span>❌</span>
                  <FormattedMessage
                    defaultMessage="Removed from any KPI board"
                    id="HrNosC"
                    tagName="span"
                  />
                </p>
                <p className="space-x-2">
                  <span>❌</span>
                  <FormattedMessage
                    defaultMessage="Not possible to align"
                    id="ykNFlD"
                    tagName="span"
                  />
                </p>
                <p className="space-x-2">
                  <span>❌</span>
                  <FormattedMessage
                    defaultMessage="Hidden from profile pages"
                    id="5F3YgM"
                    tagName="span"
                  />
                </p>
              </div>
            }
            button={
              <ListItem>
                <FormattedMessage defaultMessage="Archive KPI" id="QcF4IM" />
              </ListItem>
            }
            confirmText={intl.formatMessage({
              defaultMessage: "Confirm",
              id: "global:confirm",
            })}
            onConfirm={partial(archiveKpi, id)}
            title={intl.formatMessage({
              defaultMessage: "Archive KPI",
              id: "QcF4IM",
            })}
          />
        ) : (
          <ListItem onClick={partial(unarchiveKpi, id)}>
            <FormattedMessage defaultMessage="Unarchive KPI" id="QEjlbp" />
          </ListItem>
        ))}

      {dropdownOptions.includes("delete") && kpi.canDelete && (
        <ConfirmDeletePermanently
          body={
            <FormattedMessage
              defaultMessage="You are about to delete the {kpiName} KPI."
              id="0BQLnS"
              values={{
                kpiName: <strong>{name}</strong>,
              }}
            />
          }
          trigger={
            <ListItem data-cy="confirm-delete-kpi" onClick={handleDeleteKpi}>
              <FormattedMessage
                defaultMessage="Delete KPI"
                id="kpi:delete:title"
              />
            </ListItem>
          }
          data-cy="delete-kpi"
          onConfirm={handleDeleteKpi}
          entity={intl.formatMessage({
            defaultMessage: "KPI",
            id: "QVgyk9",
          })}
        />
      )}
      {dropdownOptions.includes("unalign") && kpi.canPatch && (
        <ListItem
          data-cy="qlpI7V3ANx9nI3rea608t"
          onClick={handleUnalignKpiFromStrategicPillar}
        >
          <FormattedMessage
            defaultMessage="Unalign KPI"
            id="kpi:unalign:title"
          />
        </ListItem>
      )}
      {dropdownOptions.includes("addOkr") &&
        !kpi.archived &&
        permissions.canCreateOkrs && (
          <ListItem data-cy="kpi-create-okr" onClick={handleAddOkr}>
            <FormattedMessage
              defaultMessage="Add OKR to improve KPI"
              id="kpi:createOkr"
            />
          </ListItem>
        )}
    </>
  );
};
