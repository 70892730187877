import { useRouter } from "next/router";
import { routes } from "route-configs";

export const useIsActive = () => {
  const { asPath: pathname } = useRouter();
  return {
    companyGroup: pathname.startsWith(routes.company.main),
    goals: pathname.startsWith(routes.explore.main),
    groupList: pathname.startsWith(routes.groups.list),
    groups:
      pathname.startsWith(routes.groups.list) ||
      pathname.startsWith(routes.groups.main),
    groupsAndPeople:
      pathname.startsWith(routes.groups.list) ||
      pathname.startsWith(routes.groups.main) ||
      pathname.startsWith(routes.users.main) ||
      pathname.startsWith(routes.users.list),
    growthBoard: pathname.startsWith(routes.growthBoard.main),
    home: pathname.startsWith(routes.home.main),
    kpiBoard: pathname.startsWith(routes.kpiBoard.main),
    oneOnOnes: pathname.startsWith(routes.peopleManagement.oneOnOne),
    performanceReviews: pathname.startsWith(
      routes.peopleManagement.performanceReviews
    ),
    quickStart: pathname.startsWith(routes.quickStart.main),
    reports:
      pathname.startsWith(routes.performanceReports.main) ||
      pathname.startsWith(routes.reports.main),
    roadmap: pathname.startsWith(routes.mapsv3.main),
    settings: pathname.startsWith(routes.settings.company.main),
    timeline: pathname.startsWith(routes.timeline.main),
    userList: pathname.startsWith(routes.users.list),
    users:
      pathname.startsWith(routes.users.main) ||
      pathname.startsWith(routes.users.list),
  };
};
