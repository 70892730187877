import React, { ReactNode } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { NotificationTypes } from "../../../../../notification/constants";

const Strong = (children: ReactNode) => <strong>{children}</strong>;

export const titleMessages = defineMessages({
  groupTfNoOkrs: {
    defaultMessage:
      "Your group <strong>{groupName}</strong> doesn’t have any OKRs for <strong>{timeframeName}</strong> yet",
    id: "notifications:title:groupTfNoOkrs",
  },
  objectiveMissingContribution: {
    defaultMessage:
      "Contribute to <strong>{objectiveName}</strong> by leading one of its Key Results or Initiatives",
    id: "notifications:title:objectiveMissingContribution",
  },
  tfEndCloseObjective: {
    defaultMessage:
      "It’s time to close your Objective <strong>{objectiveName}</strong>",
    id: "notifications:title:tfEndCloseObjective",
  },
});

type NotificationSpec = {
  titleRenderer: (props: {
    targetName: string;
    timeframeName?: string;
  }) => ReactNode;
};

export const notificationsConfig: Record<string, NotificationSpec> = {
  [NotificationTypes.GroupTfNoOKRS]: {
    titleRenderer: ({ targetName, timeframeName }) => (
      <FormattedMessage
        {...titleMessages.groupTfNoOkrs}
        values={{
          groupName: targetName,
          strong: Strong,
          timeframeName,
        }}
      />
    ),
  },
  [NotificationTypes.TfEndCloseObjective]: {
    titleRenderer: ({ targetName }) => (
      <FormattedMessage
        {...titleMessages.tfEndCloseObjective}
        values={{
          objectiveName: targetName,
          strong: Strong,
        }}
      />
    ),
  },
  [NotificationTypes.ObjectiveMissingContribution]: {
    titleRenderer: ({ targetName }) => (
      <FormattedMessage
        {...titleMessages.objectiveMissingContribution}
        values={{
          objectiveName: targetName,
          strong: Strong,
        }}
      />
    ),
  },
};
