import { isNil } from "lodash";
import { useRef, useState } from "react";
import Cropper from "react-cropper";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Button } from "common/buttons";
import { uploadAvatar } from "legacy/actions/actions";
import { toast } from "utils/toastr";
import { compressImage, detectBrowser } from "utils/utils";
import { Modal } from "../Modal/Modal";
import { ModalFooter } from "../Modal/ModalFooter/ModalFooter";

type Props = {
  canvasSrc?: string;
  entityId?: string;
  entityType: string;
  onRequestClosed: () => void;
  open?: boolean;
};

export const ImageUploadModal = ({
  canvasSrc,
  entityId = "",
  entityType,
  onRequestClosed,
  open = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isSaving, setIsSaving] = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);

  const cropImage = async () => {
    const imageElement: any = cropperRef.current;
    const cropper: any = imageElement?.cropper;
    if (isNil(cropper.getCroppedCanvas())) {
      return Promise.resolve();
    }
    setIsSaving(true);
    const canvas: HTMLCanvasElement = cropper.getCroppedCanvas();
    const quality = 0.8;
    let file: Blob = await compressImage(canvas, quality);
    const name = `${uuidv4()}.jpeg`;

    const browsers = detectBrowser();

    if (!browsers.isIE && !browsers.isEdge) {
      file = new File([file], name, {
        type: "image/jpeg",
      });
    }

    setIsSaving(false);

    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'then' does not exist on type '(dispatch:... Remove this comment to see the full error message
      dispatch(uploadAvatar(entityId, entityType, file))
        .then(() => {
          setIsSaving(false);
          onRequestClosed();
        })
        .catch(() => setIsSaving(false))
    );
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage({
        defaultMessage: "Upload new picture",
        id: "settings:user:profile:newPic",
      })}
    >
      <Cropper
        ref={cropperRef}
        aspectRatio={16 / 16}
        guides={false}
        preview=".img-preview"
        src={canvasSrc}
        style={{ height: 400, width: "100%" }}
      />
      <br />
      <ModalFooter>
        <Button
          data-cy="cropImageButton"
          loading={isSaving}
          onClick={cropImage}
        >
          {intl.formatMessage({
            defaultMessage: "Crop and Upload",
            id: "cropper:title",
          })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
