import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { objectTypes } from "../../../../constants/objects";
import { formatLink, routes } from "../../../../route-configs";

export const useGetEntityUrl = () => {
  const { objectiveHref, resultHref, kpiHref } = useModalRouter();

  return (type: string, id: string) => {
    switch (type) {
      case objectTypes.objective:
        return objectiveHref({ oId: id });
      case objectTypes.keyresult:
        return resultHref(id);
      case objectTypes.initiative:
        return resultHref(id);
      case objectTypes.kpi:
        return kpiHref({ kpiId: id });
      case objectTypes.company:
        return routes.company.okrs;
      case objectTypes.group:
        return formatLink(routes.groups.single.okrs, { id });
      case objectTypes.user:
        return formatLink(routes.users.single.okrs, { id });
      default:
        return "/home";
    }
  };
};
