import { PerformanceReviewRepeatFrequency } from "@graphql";
import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  [PerformanceReviewRepeatFrequency.Every_6Months]: {
    defaultMessage: "Every 6 Months",
    id: "performanceReviews:cadence:sixMonths",
  },
  [PerformanceReviewRepeatFrequency.NoRepeat]: {
    defaultMessage: "Don't Repeat",
    id: "performanceReviews:cadence:noRepeat",
  },
  [PerformanceReviewRepeatFrequency.Quarterly]: {
    defaultMessage: "Quarterly",
    id: "performanceReviews:cadence:quarterly",
  },
  [PerformanceReviewRepeatFrequency.Yearly]: {
    defaultMessage: "Yearly",
    id: "performanceReviews:cadence:yearly",
  },
});

type Hook = { label: string; value: string }[];

export const usePerformanceReviewFrequencyOptions = (): Hook => {
  const intl = useIntl();
  return [
    {
      label: intl.formatMessage(
        messages[PerformanceReviewRepeatFrequency.NoRepeat]
      ),
      value: PerformanceReviewRepeatFrequency.NoRepeat,
    },
    {
      label: intl.formatMessage(
        messages[PerformanceReviewRepeatFrequency.Quarterly]
      ),
      value: PerformanceReviewRepeatFrequency.Quarterly,
    },
    {
      label: intl.formatMessage(
        messages[PerformanceReviewRepeatFrequency.Every_6Months]
      ),
      value: PerformanceReviewRepeatFrequency.Every_6Months,
    },
    {
      label: intl.formatMessage(
        messages[PerformanceReviewRepeatFrequency.Yearly]
      ),
      value: PerformanceReviewRepeatFrequency.Yearly,
    },
  ];
};
