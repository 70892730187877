import {
  PerformanceReviewQuestionType,
  PerformanceReviewQuestionVisibility,
  useGetPerformanceReviewQuery,
} from "@graphql";
import dayjs from "dayjs";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { ReactInstance, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useReactToPrint } from "react-to-print";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { Modal } from "common/overlay/Modal/Modal";
import { NotFoundModal } from "common/overlay/NotFoundModal/NotFoundModal";
import { usePerformanceReviewFormSubmission } from "common/performanceReview/hooks/usePerformanceReviewFormSubmission";
import { PerformanceReviewConfirmCancel } from "common/performanceReview/modals/PerformanceReviewConfirmCancel";
import { PerformanceReviewCompleteModalHeader } from "common/performanceReview/PerformanceReviewForm/PerformanceReviewCompleteModalHeader/PerformanceReviewCompleteModalHeader";
import {
  PERFORMANCE_REVIEW_OPENED,
  PERFORMANCE_REVIEW_SAVED,
} from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { PerformanceReviewCompletedQnA } from "modules/performanceReview/PerformanceReviewCompleteList/PerformanceReviewCompletedQnA";
import { PerformanceReviewCompleteSidebar } from "modules/performanceReview/PerformanceReviewCompleteSidebar/PerformanceReviewCompleteSidebar";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { handleError } from "utils/utils";
import { PerformanceReviewCompleteProvider } from "../context/performanceReviewCompleteContext";
import { PerformanceReviewCompletedBanner } from "../PerformanceReviewBanners/PerformanceReviewCompletedBanner/PerformanceReviewCompletedBanner";
import { PerformanceReviewFormBanner } from "../PerformanceReviewBanners/PerformanceReviewFormBanner/PerformanceReviewFormBanner";
import { PerformanceReviewCompleteFormFooter } from "../PerformanceReviewForm/PerformanceReviewCompleteFormFooter/PerformanceReviewCompleteFormFooter";
import { PerformanceReviewCompleteFormLayout } from "../PerformanceReviewForm/PerformanceReviewCompleteFormLayout";
import { PerformanceReviewQuestionsForm } from "../PerformanceReviewForm/PerformanceReviewQuestionsForm/PerformanceReviewQuestionsForm";
import { isQuestionVisible } from "./PerformanceReviewCompleteFormModal.utils";

export type PerformanceReviewCompleteFormValues = {
  questionsAndAnswers: {
    id: string;
    paragraphAnswer: string;
    question: string;
    starRatingAnswer: number;
    type: PerformanceReviewQuestionType;
    visibility: PerformanceReviewQuestionVisibility;
  }[];
};

const messages = defineMessages({
  success: {
    defaultMessage: "Performance review saved!",
    id: "performanceReview:saved",
  },
});

export const performanceReviewSuccessMessages = messages;

type Props = {
  performanceReviewId: string;
};

export const PerformanceReviewCompleteFormModal = ({
  performanceReviewId,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const formRef =
    useRef<FormikProps<PerformanceReviewCompleteFormValues>>(null);
  const printRef = useRef<HTMLDivElement | null>(null);
  const me = useCurrentUser();

  const handleExport = useReactToPrint({
    content: () => printRef.current,
  });

  const { handleUpsertPerformanceReviewActor, handleUpsertAnswers } =
    usePerformanceReviewFormSubmission();

  const [showSuccess, setShowSuccess] = useState(false);
  const { close } = useModalRouter();

  const { data, loading } = useGetPerformanceReviewQuery({
    onError: (err) => {
      handleError(err);
      close();
    },
    variables: {
      id: performanceReviewId,
    },
  });

  track(PERFORMANCE_REVIEW_OPENED);

  if (!data) {
    return null;
  }

  const performanceReview = data.performanceReview;
  const reviewee = performanceReview?.reviewee;
  const reviewer = performanceReview?.reviewer;

  if (!performanceReview || !reviewer || !reviewee) {
    return <NotFoundModal onClose={close} />;
  }

  const userIsReviewee = reviewee.user?.id === me?.id;

  const [user, otherUser] = userIsReviewee
    ? [reviewee, reviewer]
    : [reviewer, reviewee];

  const { createdDate, isDraft, isSubmitted, lastEditedDate } = user;

  const wasJustCreated =
    dayjs(createdDate).isSame(dayjs(lastEditedDate), "date") &&
    !isDraft &&
    !isSubmitted;
  const lastEdited = wasJustCreated ? undefined : lastEditedDate;

  const getInitialValues = (): PerformanceReviewCompleteFormValues => {
    const questionsAndAnswers = mapEdges(
      performanceReview.questionAndAnswers.edges
    );
    const usersQuestionsAndAnswers = questionsAndAnswers.map(
      ({
        id,
        revieweeParagraphAnswer,
        revieweeStarRatingAnswer,
        reviewerParagraphAnswer,
        reviewerStarRatingAnswer,
        question,
        type,
        visibility,
      }) => ({
        id,
        question,
        type,
        visibility,
        ...(userIsReviewee
          ? {
              paragraphAnswer: revieweeParagraphAnswer ?? "",
              starRatingAnswer: revieweeStarRatingAnswer ?? 0,
            }
          : {
              paragraphAnswer: reviewerParagraphAnswer ?? "",
              starRatingAnswer: reviewerStarRatingAnswer ?? 0,
            }),
      })
    );
    return {
      questionsAndAnswers: usersQuestionsAndAnswers,
    };
  };

  const onSubmit = async (
    values: PerformanceReviewCompleteFormValues,
    actions: FormikHelpers<PerformanceReviewCompleteFormValues>
  ) => {
    const [responseAnswers, responseActor] = await Promise.allSettled([
      handleUpsertAnswers(values, userIsReviewee),
      handleUpsertPerformanceReviewActor({
        performanceReview,
        userIsReviewee,
      }),
    ]);

    actions.setSubmitting(false);
    if (
      responseAnswers.status === "rejected" ||
      responseActor.status === "rejected"
    )
      return;
    const hasError =
      responseAnswers.value === true || responseActor.value === true;

    if (hasError) return;
    toast.success(intl.formatMessage(messages.success));
    track(PERFORMANCE_REVIEW_SAVED, {
      date: dayjs(),
      reviewee: userIsReviewee,
      reviewer: !userIsReviewee,
    });
    setShowSuccess(true);
  };

  const showCompletePerformanceReview = performanceReview.isComplete;

  const validationSchema = Yup.object().shape({
    questionsAndAnswers: Yup.array().of(
      Yup.object().shape({
        starRatingAnswer: Yup.number().when(["type", "visibility"], {
          is: (
            type: PerformanceReviewQuestionType,
            visibility: PerformanceReviewQuestionVisibility
          ) =>
            type === PerformanceReviewQuestionType.StarRating &&
            isQuestionVisible(userIsReviewee, visibility),
          then: (schema) => schema.required("Required"),
        }),
      })
    ),
  });

  return (
    <Modal
      backdropClose={false}
      formTouched={() => formRef.current?.dirty ?? false}
      isOpen
      loading={loading}
      onClose={close}
      scrollable={false}
      showHeaderBodyDivider
      size="xl"
      title={
        <PerformanceReviewCompleteModalHeader review={performanceReview} />
      }
      verticalScrollable={false}
    >
      {({ handleClose, showConfirm, toggleConfirm }) => (
        <PerformanceReviewCompleteProvider value={performanceReview}>
          <Formik
            enableReinitialize
            initialValues={getInitialValues()}
            innerRef={formRef}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validationSchema={validationSchema}
          >
            <>
              <PerformanceReviewCompleteFormLayout layoutKey="performanceReviewModal">
                <div className="col-span-3 flex h-full overflow-y-hidden">
                  <div className="flex min-h-0 grow">
                    <div
                      ref={printRef}
                      className={twClass(
                        "h-full w-full overflow-y-auto",
                        "px-3 py-4 xl:px-9 xl:py-4 print:px-20",
                        "modal-scrollbar modal-scrollbar-thumb"
                      )}
                    >
                      <Show
                        fallback={
                          <div className="pb-2">
                            <PerformanceReviewFormBanner
                              isReviewee={userIsReviewee}
                              otherUser={otherUser.user?.fullName}
                              showSavedMsg={showSuccess}
                            />
                            <PerformanceReviewQuestionsForm
                              userIsReviewee={userIsReviewee}
                            />
                          </div>
                        }
                        when={showCompletePerformanceReview}
                      >
                        <div className="my-4 hidden print:block border-b-2">
                          <PerformanceReviewCompleteModalHeader
                            review={performanceReview}
                          />
                        </div>
                        <PerformanceReviewCompletedBanner
                          handleExport={handleExport}
                        />
                        <PerformanceReviewCompletedQnA />
                      </Show>
                    </div>
                  </div>
                </div>
                <div className="modal-scrollbar modal-scrollbar-thumb h-full overflow-y-auto bg-slate-50">
                  <PerformanceReviewCompleteSidebar
                    meetingId={performanceReview.id}
                  />
                </div>
              </PerformanceReviewCompleteFormLayout>
              <Show when={!performanceReview.isComplete}>
                <PerformanceReviewCompleteFormFooter
                  lastEditedDate={lastEdited}
                  toggleConfirm={handleClose}
                />
              </Show>
              <Show when={showConfirm}>
                <PerformanceReviewConfirmCancel
                  onClose={toggleConfirm}
                  onConfirm={handleClose}
                />
              </Show>
            </>
          </Formik>
        </PerformanceReviewCompleteProvider>
      )}
    </Modal>
  );
};
