import {
  GetObjectiveDocument,
  GetObjectiveResultsDocument,
  KeyResult,
  UpsertResultMutationInput,
  useUpsertResultMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { CancelButton, SaveButton } from "common/buttons";
import { ObjectiveField } from "common/form/ObjectiveField/ObjectiveField";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { handleErrors } from "utils/graphql/handleErrors";

const schema = Yup.object().shape({
  objective: Yup.string().required("Required"),
});

type Props = {
  callback?: () => void;
  objectiveId?: string;
  onRequestClosed: () => void;
  open: boolean;
  refetchQueries?: string[];
  result: Derive<
    KeyResult,
    {
      id: true;
    }
  >;
};

type Values = Pick<UpsertResultMutationInput, "objective">;
export const MoveResultModal = ({
  callback,
  objectiveId,
  onRequestClosed: closeModal,
  open,
  refetchQueries = [],
  result,
}: Props): JSX.Element | null => {
  const { $t } = useIntl();
  const [upsertResult] = useUpsertResultMutation();

  const handleSubmit = async (values: Values) => {
    const response = await upsertResult({
      refetchQueries: [
        {
          query: GetObjectiveDocument,
          variables: { objectiveId: values.objective },
        },
        {
          query: GetObjectiveResultsDocument,
          variables: { objectiveId: values.objective, parentIsnull: true },
        },
        ...refetchQueries,
      ],
      variables: {
        input: {
          id: result.id,
          objective: values.objective,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertResult?.errors
    );

    if (hasError) return;

    toast.success(
      $t({
        defaultMessage: "Result moved",
        id: "Ps9H7z",
      })
    );
    closeModal();
    callback?.();
  };

  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio"
      isOpen={open}
      onClose={closeModal}
      size="sm"
      title={<FormattedMessage defaultMessage="Move result" id="73goXy" />}
    >
      <Formik
        initialValues={{
          objective: objectiveId,
        }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={schema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <div className="w-full mt-2">
              <ObjectiveField
                label={
                  <FormattedMessage defaultMessage="Objective" id="khNSj1" />
                }
                autoFocus
                name="objective"
              />
            </div>
            <ModalFooter>
              <SaveButton
                data-testid="submitForm"
                disabled={isSubmitting}
                onClick={submitForm}
              />
              <CancelButton disabled={isSubmitting} onClick={closeModal} />
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
