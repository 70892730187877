import { get } from "lodash";
import camelCase from "lodash/camelCase";
import React from "react";
import { SlackChannelSelect } from "common/inputs/SlackChannelSelect/SlackChannelSelect";

export const FormSlackChannelSelect = ({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'field' implicitly has an 'any' ty... Remove this comment to see the full error message
  field,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'touched' implicitly has an 'any' ... Remove this comment to see the full error message
  form: { touched, errors },
  ...other
}): JSX.Element => {
  const handleChange = (newChannel: string | null) => {
    field.onChange({
      target: {
        name: field.name,
        value: newChannel,
      },
    });
  };

  const errorText = get(touched, field.name) && get(errors, field.name);

  return (
    <SlackChannelSelect
      data-cy={other["data-cy"] || camelCase(label)}
      errorText={errorText}
      id={field.name}
      label={label}
      name={field.name}
      onBlur={field.onBlur}
      onChange={handleChange}
      value={field.value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};
