import {
  namedOperations,
  PerformanceReviewDefaultFragment,
  PerformanceReviewQuestionType,
  PerformanceReviewUsersFragment,
  UpsertPerformanceReviewQuestionAndAnswersMutationInput,
  useUpsertPerformanceReviewActorMutation,
  useUpsertPerformanceReviewMutation,
  useUpsertPerformanceReviewQuestionAndAnswersMutation,
} from "@graphql";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { useIntl } from "react-intl";
import { PerformanceReviewCompleteType } from "common/performanceReview/context/performanceReviewCompleteContext";
import { getNextPerformanceReviewDate } from "common/performanceReview/performanceReviews.utils";
import { PERFORMANCE_REVIEW_SAVED } from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import {
  PerformanceReviewCompleteFormValues,
  performanceReviewSuccessMessages,
} from "../modals/PerformanceReviewCompleteFormModal";
import { isQuestionVisible } from "../modals/PerformanceReviewCompleteFormModal.utils";

type UpsertActorProps = {
  performanceReview?:
    | (PerformanceReviewDefaultFragment & PerformanceReviewUsersFragment)
    | null;
  userIsReviewee: boolean;
};

type CompleteInput = {
  formValues: PerformanceReviewCompleteFormValues;
  review: PerformanceReviewCompleteType;
};

type Hook = {
  handleCompletePerformanceReview: (input: CompleteInput) => Promise<{
    hasError: boolean;
    nextPerformanceReview?:
      | (PerformanceReviewDefaultFragment & PerformanceReviewUsersFragment)
      | null;
    repeatHasError?: boolean;
  }>;
  handleUpsertAnswers: (
    values: PerformanceReviewCompleteFormValues,
    userIsReviewee: boolean
  ) => Promise<boolean>;
  handleUpsertPerformanceReviewActor: ({
    performanceReview,
    userIsReviewee,
  }: UpsertActorProps) => Promise<boolean>;
};

export const usePerformanceReviewFormSubmission = (): Hook => {
  const intl = useIntl();
  const me = useCurrentUser();

  const [upsertPerformanceReview] = useUpsertPerformanceReviewMutation({
    refetchQueries: [namedOperations.Query.GetAllPerformanceReviews],
  });
  const [upsertPerformanceReviewActor] =
    useUpsertPerformanceReviewActorMutation({
      refetchQueries: [namedOperations.Query.GetAllPerformanceReviews],
    });
  const [upsertQuestionAndAnswers] =
    useUpsertPerformanceReviewQuestionAndAnswersMutation({
      refetchQueries: [
        namedOperations.Query.GetAllPerformanceReviews,
        namedOperations.Query.GetPerformanceReview,
      ],
    });
  const handleUpsertPerformanceReviewActor = async ({
    performanceReview,
    userIsReviewee,
  }: UpsertActorProps) => {
    const response = await upsertPerformanceReviewActor({
      variables: {
        input: {
          id: userIsReviewee
            ? performanceReview?.reviewee?.id
            : performanceReview?.reviewer?.id,
          isDraft: false,
          isSubmitted: true,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertPerformanceReviewActor?.errors
    );
    return hasError;
  };
  const handleUpsertAnswers = async (
    values: PerformanceReviewCompleteFormValues,
    userIsReviewee: boolean
  ) => {
    const responses = values.questionsAndAnswers
      .filter(({ visibility }) => isQuestionVisible(userIsReviewee, visibility))
      .map(({ id, type, paragraphAnswer, starRatingAnswer }) => {
        const input: UpsertPerformanceReviewQuestionAndAnswersMutationInput = {
          id,
        };
        if (userIsReviewee) {
          if (type === PerformanceReviewQuestionType.Paragraph) {
            input.revieweeParagraphAnswer = paragraphAnswer;
          }
          if (type === PerformanceReviewQuestionType.StarRating) {
            input.revieweeStarRatingAnswer = starRatingAnswer;
          }
        } else {
          if (type === PerformanceReviewQuestionType.Paragraph) {
            input.reviewerParagraphAnswer = paragraphAnswer;
          }
          if (type === PerformanceReviewQuestionType.StarRating) {
            input.reviewerStarRatingAnswer = starRatingAnswer;
          }
        }

        return upsertQuestionAndAnswers({ variables: { input } });
      });

    const resolvedResponses = await Promise.all(responses);
    const errors = resolvedResponses.map((resp) => {
      const { hasError } = handleErrors(
        resp,
        resp.data?.upsertPerformanceReviewQuestionAndAnswers?.errors
      );
      return hasError;
    });
    return !errors.every((err) => !err);
  };

  const handleSaveUserFormValues = async (
    values: PerformanceReviewCompleteFormValues,
    performanceReview: PerformanceReviewCompleteType,
    userIsReviewee: boolean
  ) => {
    const [responseAnswers, responseActor] = await Promise.allSettled([
      handleUpsertAnswers(values, userIsReviewee),
      handleUpsertPerformanceReviewActor({
        performanceReview,
        userIsReviewee,
      }),
    ]);

    const hasError =
      responseAnswers.status === "rejected" ||
      responseActor.status === "rejected";

    if (hasError)
      return {
        hasError,
      };
    toast.success(intl.formatMessage(performanceReviewSuccessMessages.success));

    track(PERFORMANCE_REVIEW_SAVED, {
      date: dayjs(),
      reviewee: userIsReviewee,
      reviewer: !userIsReviewee,
    });
    return {
      hasError,
    };
  };

  const handleCompletePerformanceReview = async ({
    review,
    formValues,
  }: CompleteInput) => {
    const userIsReviewee = me?.id === review.reviewee?.id || false;
    const { hasError: formSubmissionHasError } = await handleSaveUserFormValues(
      formValues,
      review,
      userIsReviewee
    );

    if (formSubmissionHasError) {
      return {
        hasError: formSubmissionHasError,
      };
    }
    const { dueDate, id, repeatFrequency, reviewee, reviewer } = review;

    const response = await upsertPerformanceReview({
      refetchQueries: [namedOperations.Query.GetUserPerformanceReviewsDue],
      variables: {
        input: {
          id,
          isComplete: true,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertPerformanceReview?.errors
    );
    const nextDueDate = getNextPerformanceReviewDate(dueDate, repeatFrequency);
    if (!isNil(nextDueDate)) {
      const newReviewResponse = await upsertPerformanceReview({
        refetchQueries: [
          namedOperations.Query.GetUserPerformanceReviewsDue,
          namedOperations.Query.GetAllPerformanceReviews,
        ],
        variables: {
          input: {
            dueDate: dayjs(nextDueDate).format("YYYY-MM-DD"),
            repeatFrequency,
            revieweeId: reviewee?.user?.id,
            reviewerId: reviewer?.user?.id,
          },
        },
      });
      const { hasError: repeatHasError } = handleErrors(
        newReviewResponse,
        newReviewResponse.data?.upsertPerformanceReview?.errors
      );
      return {
        hasError,
        nextPerformanceReview:
          newReviewResponse.data?.upsertPerformanceReview?.performanceReview,
        repeatHasError,
      };
    }
    return { hasError };
  };

  return {
    handleCompletePerformanceReview,
    handleUpsertAnswers,
    handleUpsertPerformanceReviewActor,
  };
};
