import { Menu } from "@headlessui/react";
import React, { Fragment, MouseEventHandler, ReactNode } from "react";
import { ClickableDiv } from "common/navigation/Clickable";
import { twClass } from "utils/twClass";

type DropdownItemProps = React.ComponentProps<typeof ClickableDiv> & {
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
};

export const DropdownItem = React.forwardRef<HTMLDivElement, DropdownItemProps>(
  (
    { className, children, onClick, selected = false, ...rest },
    ref
  ): JSX.Element => {
    const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation();
      onClick?.(e);
    };

    return (
      <Menu.Item as={Fragment}>
        {({ active }) => (
          <ClickableDiv
            data-cy="dropdownItem"
            ref={ref}
            className={twClass(
              "w-full cursor-default px-2 py-1 text-slate-800 hover:cursor-pointer",
              {
                "bg-blue-100 focus:bg-blue-100": active && !selected,
                "text-white bg-blue-500": selected,
              },
              className
            )}
            onClick={handleClick}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          >
            {children}
          </ClickableDiv>
        )}
      </Menu.Item>
    );
  }
);

DropdownItem.displayName = "DropdownItem";
