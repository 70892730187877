import { Company, CompanyDefaultFieldsFragment } from "@graphql";
import { Derive } from "@shoooe/derive";
import { compact, isNil, split } from "lodash";
import { featureTiers } from "modules/company/Company/Company.constants";

export const parseCommaSeparatedDomains = (domain: string): string[] => {
  const trimmed = domain.replace(/ /g, "");
  const splitVal = split(trimmed, ",");
  return compact(splitVal);
};

export const getIsOnFreePlan = (company: CompanyDefaultFieldsFragment) =>
  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  company?.featureTier === featureTiers.FREE;

export const getIsOnFreeOrTrialPlan = (
  company: Derive<
    Company,
    {
      featureTier: true;
    }
  >
): boolean => {
  const featureTier = company.featureTier;

  return [
    featureTiers.TRIAL,
    featureTiers.FREE,
    featureTiers.EXPIRED_TRIAL,
  ].includes(featureTier);
};

export const getIsOnTrialPlan = (company: CompanyDefaultFieldsFragment) =>
  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  company?.featureTier === featureTiers.TRIAL;

export const getCompanySizeFormatted = (
  companySize: string | null | undefined
): string | undefined =>
  companySize?.replace(/^A_(\d+)_(\d+)$/, "$1-$2").replace(/^A_(\d+)_$/, "$1+");

export const getCompanySizeRangeUpper = (
  companySize: string | null | undefined
): number | null => {
  if (isNil(companySize)) return null;

  const format1Match = companySize.match(/^[0-9]+-([0-9]+)$/);
  if (!isNil(format1Match)) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return parseInt(format1Match[1]);
  }

  const format2Match = companySize.match(/^[0-9]+\+$/);
  if (!isNil(format2Match)) {
    return Number.POSITIVE_INFINITY;
  }

  return null;
};
