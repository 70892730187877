export const NotificationTypes = {
  AccountSummary: "ACCOUNT_SUMMARY",
  CheckInComments: "CHECK_IN_COMMENTS",
  CheckInManagerRoundUp: "CHECK_IN_MANAGER_ROUNDUP",
  CheckInReminder: "CHECK_IN_REMINDER",
  CloseOpen: "CLOSE_OPEN",
  CommentCreated: "COMMENT_CREATED",
  EmailWeeklyRoundUp: "EMAIL_WEEKLY_ROUNDUP",
  ExecutivesWeekly: "EXECUTIVES_WEEKLY",
  GroupMemberships: "GROUP_MEMBERSHIPS",
  GroupTfNoOKRS: "GROUP_TF_NO_OKRS",
  InitiativeChanged: "INITIATIVE_CHANGED",
  KeyResultChanged: "KEY_RESULT_CHANGED",
  LegacyUserAddedToAction: "USER_ADDED_TO_ACTION",
  LegacyUserAddedToInitiative: "USER_ADDED_TO_INITIATIVE",
  LegacyUserAddedToKeyResult: "USER_ADDED_TO_KEY_RESULT",
  LegacyUserAddedToObjective: "USER_ADDED_TO_OBJECTIVE",
  MadeResponsible: "MADE_RESPONSIBLE",
  NewComments: "NEW_COMMENTS",
  NewUpdates: "NEW_UPDATES",
  ObjectiveCompleted: "OBJECTIVE_COMPLETED",
  ObjectiveMissingContribution: "OBJECTIVE_MISSING_CONTRIBUTION",
  OneOnOneMeetingDueItems: "ONE_ON_ONE_MEETING_DUE_ITEMS",
  OneOnOneMeetingNotEnded: "ONE_ON_ONE_MEETING_NOT_ENDED",
  OneOnOneMeetingUpcoming: "ONE_ON_ONE_MEETING_UPCOMING",
  OneOnOneSeriesCreated: "ONE_ON_ONE_SERIES_CREATED",
  PerformanceReviewsReminders: "PERFORMANCE_REVIEWS_REMINDERS",
  SlackAtMentions: "SLACK_AT_MENTIONS",
  SlackCheckIn: "SLACK_CHECK_IN",
  SlackCommentCreate: "SLACK_COMMENT_CREATE",
  SlackGoalsCreate: "SLACK_GOALS_CREATE",
  SlackGoalsUpdate: "SLACK_GOALS_UPDATE",
  SlackKPIsUpdate: "SLACK_KPIS_UPDATE",
  SlackKudos: "SLACK_KUDOS",
  SlackWeeklyRoundup: "SLACK_WEEKLY_ROUNDUP",
  TfEndCloseObjective: "TF_END_CLOSE_OBJECTIVE",
  TimeframeChanged: "TIMEFRAME_SWITCHED",
  UserAddedToGroup: "USER_ADDED_TO_GROUP",
  UserAddedToInitiativeAsContributor: "USER_ADDED_TO_INITIATIVE_AS_CONTRIBUTOR",
  UserAddedToInitiativeAsLead: "USER_ADDED_TO_INITIATIVE_AS_LEAD",
  UserAddedToKeyResultAsContributor: "USER_ADDED_TO_KEY_RESULT_AS_CONTRIBUTOR",
  UserAddedToKeyResultAsLead: "USER_ADDED_TO_KEY_RESULT_AS_LEAD",
  UserAddedToKpiAsContributor: "USER_ADDED_TO_KPI_AS_CONTRIBUTOR",
  UserAddedToKpiAsLead: "USER_ADDED_TO_KPI_AS_LEAD",
  UserAddedToObjectiveAsContributor: "USER_ADDED_TO_OBJECTIVE_AS_CONTRIBUTOR",
  UserAddedToObjectiveAsLead: "USER_ADDED_TO_OBJECTIVE_AS_LEAD",
  UserChanged: "USER_CHANGED",
  WeeklyGroupReports: "WEEKLY_GROUP_REPORTS",
};

export const legacyNotificationTypes = {
  [NotificationTypes.LegacyUserAddedToAction]:
    NotificationTypes.LegacyUserAddedToInitiative,
};
