import { CommitStatus, PerdooApiKpiGoalOperatorChoices } from "@graphql";
import isNil from "lodash/isNil";
import { defineMessages, IntlShape } from "react-intl";
import { colorTheme } from "constants/colorTheme";
import { goalOperators } from "constants/index";
import { isSet } from "./isSet";

export const messages = defineMessages({
  greaterThanOrEqualTo: {
    defaultMessage: "Greater than or Equal to {goalThreshold}",
    id: "kpi:modal:greaterThanOrEqualTo",
  },
  greaterTooltip: {
    defaultMessage:
      "This KPI is healthy when it is greater than or equal to {goalThreshold}",
    id: "kpi:health:greaterorequal:tooltip",
  },
  lesserThan: {
    defaultMessage: "Lesser than or Equal to {goalThreshold}",
    id: "kpi:modal:lesserThanOrEqual",
  },
  lessTooltip: {
    defaultMessage:
      "This KPI is healthy when it is less than or equal to {goalThreshold}",
    id: "kpi:health:lessorequal:tooltip",
  },
});

export type KpiData = {
  currentValue: number | null;
  delta?: number | null;
  goalOperator?: PerdooApiKpiGoalOperatorChoices | null;
  goalThreshold?: number | null;
  lastCommit?: { delta: number } | null;
  lastCommitStatus?: string;
};

type GetKpiDataProps = {
  intl: IntlShape;
  isDraft?: boolean;
  kpi: KpiData;
};

/**
 * parses a KPI to get commonly computed secondary data.
 */
export const getKpiData = ({ intl, kpi, isDraft = false }: GetKpiDataProps) => {
  const {
    delta,
    goalThreshold,
    goalOperator,
    lastCommit,
    currentValue,
    lastCommitStatus,
  } = kpi;
  const kpiDelta = delta ?? lastCommit?.delta ?? 0;

  const positiveMetric =
    !isNil(goalThreshold) && goalOperator
      ? goalOperator === goalOperators.GREATER_THAN_OR_EQUAL
      : undefined;
  let deltaColorClass = delta ? "text-blue-500" : "transparent";
  let deltaColor = delta ? colorTheme.blue[500] : "transparent";
  let isHealthy = true;
  let healthTooltip;
  if (positiveMetric === true) {
    deltaColor = kpiDelta > 0 ? colorTheme.green[500] : colorTheme.red[500];
    deltaColorClass = kpiDelta > 0 ? "text-green-500" : "text-red-500";
    if (isSet(goalThreshold) && currentValue) {
      isHealthy = currentValue >= goalThreshold;
      healthTooltip = intl.formatMessage(messages.greaterTooltip, {
        goalThreshold,
      });
    }
  } else if (positiveMetric === false) {
    deltaColor = kpiDelta < 0 ? colorTheme.green[500] : colorTheme.red[500];
    deltaColorClass = kpiDelta < 0 ? "text-green-500" : "text-red-500";
    if (isSet(goalThreshold) && currentValue) {
      isHealthy = currentValue <= goalThreshold;
      healthTooltip = intl.formatMessage(messages.lessTooltip, {
        goalThreshold,
      });
    }
  }

  if (!isDraft && lastCommitStatus) {
    isHealthy = lastCommitStatus === CommitStatus.Healthy;
    healthTooltip = undefined;
  }

  const deltaIcon: "arrow_upward" | "arrow_downward" =
    kpiDelta >= 0 ? "arrow_upward" : "arrow_downward";

  return {
    delta: kpiDelta,
    deltaColor,
    deltaColorClass,
    deltaIcon,
    healthTooltip,
    isHealthy,
    positiveMetric,
  };
};
