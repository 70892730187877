import { KeyResult } from "@graphql";
import { Derive } from "@shoooe/derive";
import { FormattedMessage } from "react-intl";
import { useConfirmationModal } from "common/overlay/ConfirmationModal/hooks/useConfirmationModal";

type ResultProp = Derive<KeyResult, { childrenCount: true }>;

type Options = {
  onCancel?: () => void;
  onClose?: () => void;
  onSave?: () => void;
  result: ResultProp;
};

export const useConvertToInitiativeConfirmationModal = () => {
  const { openModal: openConfirmationModal } = useConfirmationModal();

  const openModal = (options: Options) =>
    openConfirmationModal({
      action: options.onSave,
      cancelText: (
        <FormattedMessage defaultMessage="Keep as Key Result" id="TZtd5m" />
      ),
      children: (
        <div className="space-y-2">
          <p>
            <FormattedMessage
              defaultMessage="This Key Result is currently supported by {count} {count, plural, one {Initiative} other {Initiatives}}. Changing it into an Initiative will disconnect the {count} supporting {count, plural, one {Initiative} other {Initiatives}}."
              id="5nwVgC"
              values={{ count: options.result.childrenCount }}
            />
          </p>
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you want to change this Key Result into an Initiative?"
              id="u7MkMN"
            />
          </p>
        </div>
      ),
      close: options.onClose,
      confirmText: (
        <FormattedMessage defaultMessage="Save as Initiative" id="/jDTfL" />
      ),
      onCancel: options.onCancel,
      title: (
        <FormattedMessage defaultMessage="Supporting Initiatives" id="FrUIry" />
      ),
    });

  return { openModal };
};
