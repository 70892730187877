/**
 * this type guard differentiates between an HTML Element and a Text node.
 * Element = EventTarget ← Node ← Element
 * Text = EventTarget ← Node ← CharacterData ← Text
 */
export const isElement = (node: Element | Text | null): node is HTMLElement => {
  if (node === null) {
    return false;
  }

  // these exist in Text (via CharacterData) but not in Element
  if ("data" in node && "length" in node) {
    return false;
  }

  return true;
};
