import classNames from "clsx";
import { useField } from "formik";
import { size } from "lodash";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { TextButton } from "common/buttons/TextButton/TextButton";
import { Show } from "common/controlFlow";
import { FieldFocusWrapper } from "common/form/FieldFocusWrapper/FieldFocusWrapper";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { twClass } from "utils/twClass";
import { messages } from "../ObjectiveForm.messages";
import { ObjectiveContributorsAvatars } from "./ObjectiveContributorsAvatars/ObjectiveContributorsAvatars";

type Props = {
  children: React.ReactNode;
  name: string;
  setFocusedField: (fieldName: string) => void;
};

export const ShowSelectObjectiveContributors = ({
  name,
  children,
  setFocusedField,
}: Props): JSX.Element => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [field] = useField(name);
  const [contributorsDisplayed, setContributorsDisplayed] = useState(false);
  useOutsideAlerter(ref, () => setContributorsDisplayed(false));

  return (
    <FieldFocusWrapper
      ref={ref}
      className="relative mb-2 flex items-center"
      name={name}
      setFocusedField={setFocusedField}
    >
      <div
        className={twClass("flex w-full items-start", {
          "justify-between": contributorsDisplayed,
        })}
      >
        <div className="mr-2 mt-2 text-sm font-normal">
          {intl.formatMessage(messages.contributorsLabel)}
        </div>
        <Show
          fallback={<div className="z-modal-prio -mt-8 w-full">{children}</div>}
          when={!contributorsDisplayed}
        >
          <ObjectiveContributorsAvatars ids={field.value} />
          <div
            className={classNames("flex h-12 items-baseline", {
              invisible: contributorsDisplayed,
            })}
          >
            <Show
              fallback={
                <IconButton
                  className="ml-1 rounded-full border border-dashed p-1 text-blue-500"
                  data-cy="editObjectiveContributor"
                  name="person_add"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setContributorsDisplayed(true);
                  }}
                  size="2xl"
                />
              }
              when={size(field.value) > 0}
            >
              <TextButton
                data-cy="addObjectiveContributor"
                onClick={() => {
                  setContributorsDisplayed(true);
                }}
                size="small"
                text={<FormattedMessage defaultMessage="Edit" id="wEQDC6" />}
              />
            </Show>
          </div>
        </Show>
      </div>
    </FieldFocusWrapper>
  );
};
