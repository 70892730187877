import dayjs from "dayjs";
import { Duration } from "dayjs/plugin/duration";
import { ceil } from "lodash";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { exhaustiveCheck } from "utils/exhaustiveCheck";
import {
  CHARGEBEE_COACHING_PLANS,
  COACHING_PRICES,
  ONBOARDING_PRICING,
  PREMIUM_PLAN_NAMES,
  PREMIUM_PLAN_PRICING,
  SUPREME_PLAN_NAMES,
  SUPREME_PLAN_PRICING,
  VIEW_ONLY_PRICING,
} from "../../constants";
import {
  BillingPeriod,
  CoachingTypes,
  DiscountBracket,
  LicenseType,
  PaidPlans,
  Plans,
  SupportedCurrencies,
} from "./UpgradeModal.types";

const planNames = {
  premium: PREMIUM_PLAN_NAMES,
  supreme: SUPREME_PLAN_NAMES,
};

const planPricing = {
  premium: PREMIUM_PLAN_PRICING,
  supreme: SUPREME_PLAN_PRICING,
};

const userDiscountBrackets: DiscountBracket[] = [
  { discountRate: 0, maxUsers: 24, minUsers: 1 },
  { discountRate: 10, maxUsers: 49, minUsers: 25 },
  { discountRate: 15, maxUsers: 99, minUsers: 50 },
  { discountRate: 20, maxUsers: 149, minUsers: 100 },
  { discountRate: 25, maxUsers: 199, minUsers: 150 },
  { discountRate: 30, maxUsers: 249, minUsers: 200 },
  { discountRate: 35, maxUsers: 299, minUsers: 250 },
  { discountRate: 40, maxUsers: 349, minUsers: 300 },
  { discountRate: 45, maxUsers: 399, minUsers: 350 },
  { discountRate: 50, maxUsers: 449, minUsers: 400 },
  { discountRate: 55, maxUsers: 499, minUsers: 450 },
  { discountRate: 60, maxUsers: Infinity, minUsers: 500 },
];

const viewOnlyDiscountBrackets: DiscountBracket[] = [
  { discountRate: 0, maxUsers: 49, minUsers: 1 },
  { discountRate: 10, maxUsers: 99, minUsers: 50 },
  { discountRate: 15, maxUsers: 199, minUsers: 100 },
  { discountRate: 20, maxUsers: 299, minUsers: 200 },
  { discountRate: 25, maxUsers: 399, minUsers: 300 },
  { discountRate: 30, maxUsers: 499, minUsers: 400 },
  { discountRate: 35, maxUsers: 749, minUsers: 500 },
  { discountRate: 40, maxUsers: Infinity, minUsers: 750 },
];

const getDiscountRate = (
  discountScheme: DiscountBracket[],
  numberOfUsers: number
): number => {
  const bracket = discountScheme.find(
    (b) => numberOfUsers >= b.minUsers && numberOfUsers <= b.maxUsers
  );
  return bracket ? bracket.discountRate : 0;
};

const calculateDiscountedPrice = (
  numberOfUsers: number,
  initialPrice: number,
  licenseType: LicenseType
): number => {
  const discountRate = getDiscountRate(
    licenseType === "standard"
      ? userDiscountBrackets
      : viewOnlyDiscountBrackets,
    numberOfUsers
  );
  const discountedPrice = initialPrice * (1 - discountRate / 100);
  return ceil(discountedPrice, 2);
};

export const getBillingPeriodDuration = (
  billingPeriod: BillingPeriod
): Duration => {
  switch (billingPeriod) {
    case "QUARTERLY":
      return dayjs.duration({ months: 3 });
    case "ANNUAL":
      return dayjs.duration({ months: 12 });
    default:
      return exhaustiveCheck(billingPeriod);
  }
};

export const monthlyPricePerUser = (
  currency: SupportedCurrencies,
  period: BillingPeriod,
  plan: Plans,
  licenses: number
): number => {
  if (plan === "free") return 0;
  const price = planPricing[plan][period][currency];
  return calculateDiscountedPrice(licenses, price, "standard");
};

export const monthlyPricePerViewOnlyLicense = (
  currency: SupportedCurrencies,
  period: BillingPeriod,
  licenses: number
): number => {
  const price = VIEW_ONLY_PRICING[period][currency];
  return calculateDiscountedPrice(licenses, price, "viewonly");
};

export const calculateTotalPrice = (input: {
  coachingPackage: CoachingTypes;
  currency: SupportedCurrencies;
  licenses: number;
  period: BillingPeriod;
  plan: PaidPlans;
  viewOnlyLicenses: number;
}): number => {
  const planTotal =
    monthlyPricePerUser(
      input.currency,
      input.period,
      input.plan,
      input.licenses
    ) *
    input.licenses *
    getBillingPeriodDuration(input.period).asMonths();
  const viewOnlyTotal =
    monthlyPricePerViewOnlyLicense(
      input.currency,
      input.period,
      input.viewOnlyLicenses
    ) *
    input.viewOnlyLicenses *
    getBillingPeriodDuration(input.period).asMonths();

  const coachingTotal = COACHING_PRICES[input.coachingPackage][input.currency];
  return planTotal + viewOnlyTotal + coachingTotal + ONBOARDING_PRICING;
};

export const getChargebeePlanName = (
  currency: SupportedCurrencies,
  plan: PaidPlans,
  period: BillingPeriod
): string => {
  return planNames[plan][currency][period];
};

export const getChargebeeCoachingPlan = (
  tier: CoachingTypes,
  currency: SupportedCurrencies
): string => {
  return CHARGEBEE_COACHING_PLANS[tier][currency];
};

export const getPlanName = (plan: PaidPlans): ReactNode => {
  switch (plan) {
    case "premium":
      return (
        <FormattedMessage
          defaultMessage="Premium Plan"
          id="upgrade:modal:premiumText:label"
        />
      );
    case "supreme":
      return (
        <FormattedMessage
          defaultMessage="Supreme Plan"
          id="upgrade:modal:supremeText:label"
        />
      );
    default:
      return exhaustiveCheck(plan);
  }
};
