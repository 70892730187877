import { OneOnOneMeetingItemTemplate } from "@graphql";
import { Derive } from "@shoooe/derive";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Accordion } from "common/collapsible/Accordion";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { Clickable } from "common/navigation/Clickable";
import { useCompleteOneOnOneContext } from "../context/completeOneOnOneContext";
import { useSampleMeetingItemsTemplate } from "../hooks/useSampleMeetingItemsTemplate";
import { useUseMeetingItemsTemplate } from "../hooks/useUseMeetingItemsTemplate";
import { OneOnOneMeetingItemTemplateDetails } from "./OneOnOneMeetingItemTemplateDetails";

type MeetingItemTemplate = Derive<
  OneOnOneMeetingItemTemplate,
  {
    canDelete: true;
    canPatch: true;
    createdBy: {
      fullName: true;
      id: true;
    };
    description: true;
    id: true;
    name: true;
    talkingPoints: true;
  }
> & {
  isSample: boolean;
};

type Props = {
  close: () => void;
  showEditModal: (id: string) => void;
  template: MeetingItemTemplate;
};

export const OneonOneMeetingItemTemplateCard = ({
  template,
  showEditModal,
  close,
}: Props): JSX.Element => {
  const { nextMeeting } = useCompleteOneOnOneContext();

  const handleUseMeetingItemTemplate = useUseMeetingItemsTemplate();
  const handleUseSampleMeetingItemTemplate = useSampleMeetingItemsTemplate();

  const handleUseTemplate = async () => {
    // use perdoo sample template
    if (template.isSample) {
      const { hasError } = await handleUseSampleMeetingItemTemplate(
        template.talkingPoints,
        nextMeeting?.id
      );
      if (hasError) return;
      close();
      return;
    }

    // use customer created template
    const { hasError } = await handleUseMeetingItemTemplate(
      template.id,
      nextMeeting?.id
    );
    if (hasError) return;
    close();
  };

  return (
    <Accordion
      behaviour="hide"
      className="space-y-3"
      header={
        <div
          className="flex grow items-center justify-between space-x-1"
          data-cy="2bkreZOOT_piPrzRzzXAg"
        >
          <div
            className="flex min-w-0 grow items-center space-x-2"
            data-cy="3n_mlb6CVKG_TqajI83Es"
          >
            <span className="truncate text-base font-medium group-hover:text-blue-500">
              {template.name}
            </span>
            <span className="mt-0.5 whitespace-nowrap text-sm text-slate-500">
              <FormattedMessage
                defaultMessage="by {user}"
                id="9MRLau"
                values={{ user: template.createdBy.fullName }}
              />
            </span>
          </div>
          <div className="flex items-center space-x-6 text-base font-normal text-blue-500">
            <Show when={template.canPatch}>
              <Clickable
                className="hover:text-blue-600"
                data-cy="8Kwnmj8iR56bCcOceqo9b"
                name="edit"
                onClick={(e) => {
                  // stops accordion opening
                  e.stopPropagation();
                  showEditModal(template.id);
                }}
              >
                <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
              </Clickable>
              <div className="h-5 border-r border-slate-300" />
            </Show>
            <Clickable
              className="flex items-center space-x-1 hover:text-blue-600"
              data-cy="TCu0tVAGaTsB2UgyUBFDP"
              onClick={(e) => {
                // stops accordion opening
                e.stopPropagation();
                handleUseTemplate();
              }}
            >
              <span className="whitespace-nowrap">
                <FormattedMessage defaultMessage="Use template" id="dQvZ+J" />
              </span>
              <Icon className="mt-0.5" name="arrow_forward" size="lg" />
            </Clickable>
          </div>
        </div>
      }
    >
      <OneOnOneMeetingItemTemplateDetails template={template} />
    </Accordion>
  );
};
