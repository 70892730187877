import {
  BookmarkDefaultFragment,
  BookmarkGroupFragment,
  BookmarkKpiFragment,
  BookmarkObjectiveFragment,
  BookmarkUserFragment,
} from "@graphql";
import { getCompleteLink, routes } from "route-configs";
import { isSet } from "utils/isSet";

type BookmarkRoutesType = {
  group: string;
  user: string;
};

const bookmarkRoutes: BookmarkRoutesType = {
  group: routes.groups.single.okrs,
  user: routes.users.single.okrs,
};

export const getBookmarkLink = (targetId: string, type: "user" | "group") => {
  return getCompleteLink(bookmarkRoutes[type], {
    id: targetId,
  }).absolutePath;
};

export const getBookmarksByType = (
  allBookmarks: BookmarkDefaultFragment[]
): {
  company: boolean;
  groups: BookmarkGroupFragment[];
  kpis: BookmarkKpiFragment[];
  objectives: BookmarkObjectiveFragment[];
  users: BookmarkUserFragment[];
} => {
  const groups = allBookmarks.map(({ group }) => group).filter(isSet);
  const users = allBookmarks.map(({ user }) => user).filter(isSet);
  const objectives = allBookmarks
    .map(({ objective }) => objective)
    .filter(isSet);
  const kpis = allBookmarks.map(({ kpi }) => kpi).filter(isSet);
  const company = allBookmarks.map(({ referencesCompany }) => referencesCompany).some(Boolean);

  return {
    company,
    groups,
    kpis,
    objectives,
    users
  };
};
