import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { usePage } from "common/table/hooks/usePage/usePage";
import { Cursor } from "common/table/types";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  /** @deprecated */
  entityName?: string;
  loadingNext?: boolean;
  loadingPrev?: boolean;
  onAfterPaginate?: (direction: "backward" | "forward") => void;
  pageInfo: PageInfo | undefined;
  pageSize: number;
  setPage: (page: Cursor) => void;
  totalCount: number;
};

/**
 * generic component for cursor-based pagination. shows items count and buttons to go back and forward.
 */
export const Pagination = ({
  className,
  loadingPrev,
  loadingNext,
  onAfterPaginate,
  pageInfo,
  pageSize,
  setPage,
  totalCount,
}: Props): JSX.Element | null => {
  const { $t } = useIntl();
  const { itemFirst, itemLast } = usePage(totalCount, pageSize, pageInfo);

  // pageInfo.hasPreviousPage etc. actually only holds one-way info for this.
  // this is a limitation in apollo.
  const hasPreviousPage = itemFirst > 1;
  const hasNextPage = itemLast < totalCount;

  const handleNext = () => {
    setPage({
      after: pageInfo?.endCursor ?? "",
      first: pageSize,
    });
    onAfterPaginate?.("forward");
  };

  const handlePrev = () => {
    setPage({
      before: pageInfo?.startCursor ?? "",
      last: pageSize,
    });
    onAfterPaginate?.("backward");
  };

  return (
    <div
      className={twClass(
        "flex items-center justify-between py-3 gap-2",
        className
      )}
      data-cy="pagination"
    >
      <span className="text-base">
        <FormattedMessage
          defaultMessage="{itemFirst}-{itemLast} of {totalCount}"
          id="9stAff"
          values={{
            itemFirst,
            itemLast,
            totalCount,
          }}
        />
      </span>
      <div className="flex">
        <IconButton
          className={twClass("border-0", {
            "text-slate-400": !hasPreviousPage,
          })}
          data-cy="paginate-prev"
          disabled={!hasPreviousPage}
          ghost
          loading={loadingPrev}
          name="chevron_left"
          onClick={handlePrev}
          size="2xl"
          tooltip={$t({ defaultMessage: "Previous page", id: "k9hDFZ" })}
        />
        <IconButton
          className={twClass("border-0", {
            "text-slate-400": !hasNextPage,
          })}
          data-cy="paginate-next"
          disabled={!hasNextPage}
          ghost
          loading={loadingNext}
          name="chevron_right"
          onClick={handleNext}
          size="2xl"
          tooltip={$t({ defaultMessage: "Next page", id: "rBj9Ib" })}
        />
      </div>
    </div>
  );
};
