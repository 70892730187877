import { TimeframeDefaultFragment } from "@graphql";
import { isEmpty, isNil } from "lodash";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { TextButton } from "common/buttons";
import { Dropdown } from "common/overlay/Dropdown";
import { useTimeframes } from "hooks/useTimeframes/useTimeframes";
import { messages } from "./TimeframeSelector.messages";

type Props = {
  button?: ReactElement;
  buttonClassName?: string;
  canSelectAll?: boolean;
  onSelect: (tfId: string | undefined) => void;
  selected?: TimeframeDefaultFragment | null;
};
export const TimeframeSelector = ({
  button,
  canSelectAll,
  onSelect,
  selected,
  buttonClassName,
}: Props): JSX.Element => {
  const intl = useIntl();

  const { activeTimeframes, pastTimeframes, futureTimeframes, timeframes } =
    useTimeframes();

  const defaultButton = (
    <TextButton
      className={buttonClassName}
      data-cy="tfDropdownButton"
      isDropdown
      dropdownIconSize="lg"
      size="small"
      text={selected?.name ?? intl.formatMessage(messages.allTimeframes)}
    />
  );

  return (
    <Dropdown>
      <Dropdown.Trigger>{button ?? defaultButton}</Dropdown.Trigger>
      <Dropdown.Content
        className="divide-y divide-slate-300 max-h-64 overflow-auto"
        placement="bottom-left"
      >
        {canSelectAll && !isEmpty(timeframes) && (
          <div className="py-1 first:pt-0">
            <Dropdown.Item
              data-cy="vhAemY1L_pdmzU55_cU2e"
              onClick={() => onSelect(undefined)}
              selected={isNil(selected)}
            >
              {intl.formatMessage(messages.allTimeframes)}
            </Dropdown.Item>
          </div>
        )}
        {!isEmpty(activeTimeframes) && (
          <div className="py-1 first:pt-0">
            <p className="px-2.5 py-0.5 text-sm text-slate-500">
              {intl.formatMessage(messages.activeTimeframes)}
            </p>
            {activeTimeframes.map(({ id, name }) => (
              <Dropdown.Item
                data-cy="ijDcAaanoKpMR2tlfVXC9"
                key={id}
                onClick={() => onSelect(id)}
                selected={selected?.id === id}
              >
                {name}
              </Dropdown.Item>
            ))}
          </div>
        )}
        {!isEmpty(futureTimeframes) && (
          <div className="py-1 first:pt-0">
            <p className="px-2.5 py-0.5 text-sm text-slate-500">
              {intl.formatMessage(messages.futureTimeframes)}
            </p>
            {futureTimeframes.map(({ id, name }) => (
              <Dropdown.Item
                data-cy="wARq8GDV85RnAkEKVIq_5"
                key={id}
                onClick={() => onSelect(id)}
                selected={selected?.id === id}
              >
                {name}
              </Dropdown.Item>
            ))}
          </div>
        )}
        {!isEmpty(pastTimeframes) && (
          <div className="py-1 first:pt-0">
            <p className="px-2.5 py-0.5 text-sm text-slate-500">
              {intl.formatMessage(messages.pastTimeframes)}
            </p>
            {pastTimeframes.map(({ id, name }) => (
              <Dropdown.Item
                data-cy="ZNDP9uuiEGMaDnbM9gc7z"
                key={id}
                onClick={() => onSelect(id)}
                selected={selected?.id === id}
              >
                {name}
              </Dropdown.Item>
            ))}
          </div>
        )}
        {isEmpty(timeframes) && (
          <div className="py-1 first:pt-0">
            <p className="px-2.5 py-0.5 text-slate-500">
              {intl.formatMessage(messages.noTimeframes)}
            </p>
          </div>
        )}
      </Dropdown.Content>
    </Dropdown>
  );
};
