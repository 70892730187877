import { UpsertResultMutationInput, useUpsertResultMutation } from "@graphql";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useResultContext } from "common/context/resultContext";
import { TimelineContext } from "common/event/TimelineContext/TimelineContext";
import { DescriptionForm } from "common/form/DescriptionForm/DescriptionForm";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

export const ResultDescription = () => {
  const intl = useIntl();
  const objectiveTimelineBag = useContext(TimelineContext);
  const { id, description, canPatch } = useResultContext();
  const [editResult] = useUpsertResultMutation();

  const updateResult = async (input: Partial<UpsertResultMutationInput>) => {
    const response = await editResult({
      variables: {
        input: {
          id: id,
          ...input,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertResult?.errors
    );

    if (!hasError) {
      objectiveTimelineBag.updateTimeline();
      toast.success(
        intl.formatMessage({
          defaultMessage: "Result Updated",
          id: "P3LL8y",
        })
      );
    }
  };

  return (
    <div className="my-2">
      <DescriptionForm
        key={id}
        variant="borderless"
        description={description ?? ""}
        editVisible={!!canPatch}
        entityId={id}
        onUpdate={updateResult}
      />
    </div>
  );
};
