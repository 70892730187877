/**
 * pass this as an argument to classNames() to style rounded borders in grid layouts responsively with tailwind.
 * left and right-most elements in each row will have the outer borders rounded.
 * items will be divided with a border in between.
 *
 * default layout is 6 columns on xs / sm and 12 columns on md+, but can be customised.
 * you can use more than 1 breakpoint by combining multiple calls like this:
 *
 * ```
 * classNames(
 *   responsiveRoundBorders(3, 6, "sm"),
 *   responsiveRoundBorders(6, 12, "md"),
 * )
 * ```
 */
export const responsiveRoundBorders =
  (
    columnCountSmall = 6,
    columnCountLarge = 12,
    breakpoint: "sm" | "md" | "lg" | "xl" | "2xl" = "md"
  ) =>
  (column: number): Record<string, boolean> => ({
    "border border-r-0": true,

    // left borders
    [`${breakpoint}:rounded-l`]: column % columnCountLarge === 0,
    [`border-r rounded-r`]:
      (column + 1) % columnCountSmall === 0 &&
      (column + 1) % columnCountLarge === 0,
    [`rounded-l ${breakpoint}:rounded-none`]:
      column % columnCountSmall === 0 && column % columnCountLarge !== 0,

    // right borders
    [`${breakpoint}:border-r ${breakpoint}:rounded-r`]:
      (column + 1) % columnCountLarge === 0,
    [`rounded-l`]:
      column % columnCountSmall === 0 && column % columnCountLarge === 0,
    [`border-r rounded-r ${breakpoint}:border-r-0 ${breakpoint}:rounded-none`]:
      (column + 1) % columnCountSmall === 0 &&
      (column + 1) % columnCountLarge !== 0,
  });

/**
 * hello tailwind parser, i hope you're having a great day. please don't purge these, so we can create them dynamically:
 *
 * sm:rounded-l sm:rounded-none sm:rounded-r sm:border-r sm:border-r-0
 * md:rounded-l md:rounded-none md:rounded-r md:border-r md:border-r-0
 * lg:rounded-l lg:rounded-none lg:rounded-r lg:border-r lg:border-r-0
 * xl:rounded-l xl:rounded-none xl:rounded-r xl:border-r xl:border-r-0
 * 2xl:rounded-l 2xl:rounded-none 2xl:rounded-r 2xl:border-r 2xl:border-r-0
 *
 * thanks :)
 */
