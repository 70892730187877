import { isNil } from "lodash";

/**
 * converts base64 cursor, used for pagination in graphql relay, to a number indicating the current page
 *
 * @param cursor
 * @param pageSize
 * @param connectionType
 */
export const convertCursorToPage = (
  cursor: string,
  pageSize: number,
  connectionType = "arrayconnection"
): number => {
  const itemIndex = parseInt(atob(cursor).replace(`${connectionType}:`, ""));
  return Math.floor(itemIndex / pageSize);
};

/**
 * provides index-based pagination info based on the graphql PageInfo object
 *
 * @param itemsCount
 * @param pageSize
 * @param pageInfo
 */
export const usePage = (
  itemsCount: number,
  pageSize: number,
  pageInfo: PageInfo | undefined
) => {
  const pageCount = Math.ceil(itemsCount / pageSize);
  const pageIndex =
    pageInfo && !isNil(pageInfo.startCursor)
      ? convertCursorToPage(pageInfo.startCursor, pageSize)
      : 0;

  const itemFirst = pageIndex * pageSize + 1;
  let itemLast = itemFirst + pageSize - 1;
  if (itemLast > itemsCount) itemLast = itemsCount;

  const hasPreviousPage = pageIndex > 0;
  const hasNextPage = pageIndex + 1 < pageCount;
  return { hasNextPage, hasPreviousPage, itemFirst, itemLast, pageIndex };
};
