import { compact } from "lodash";
import { IntlShape } from "react-intl";
import { onboardingData, sampleData } from "./sampleData.constants";
import { messages } from "./sampleData.messages";
import { SampleKPI, SampleObjective, SampleResult } from "./sampleData.types";

/**
 * Returns the list of filtered sample/demo objectives that are
 * filtered wrt the owner listed within sampleData.
 * @param ownerList - List of owners; e.g ["sales","product"]
 * @param intl -intl object (of type IntlShape), which is the instance to store a cache of all Intl.
 * @return [object] - List of objects with objectives and their key results.
 */

export const getSampleObjectives = (
  ownerList: string[],
  intl: IntlShape
): SampleObjective[] => {
  return sampleData.objectives
    .filter((objective) => ownerList.includes(objective.owner))
    .map((objective) => {
      const keyResults = objective.keyResults.map((krId) => {
        const kr = sampleData.keyResults.find((el) => el.id === krId);
        if (!kr) return undefined;

        return {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          name: intl.formatMessage(messages[kr.id]),
          ...kr,
        };
      });

      return {
        id: objective.id,
        keyResults: compact(keyResults),
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        name: intl.formatMessage(messages[objective.id]),
        owner: objective.owner,
      };
    });
};

/**
 * Returns the list of filtered sample/demo KRs that are
 * filtered wrt the owner listed within sampleData.
 * @param ownerList - List of owners; e.g ["sales","product"]
 * @param intl -intl object (of type IntlShape), which is the instance to store a cache of all Intl.
 * @return [object] - List of objects with KR info
 */

export const getSampleKeyResults = (
  ownerList: string[],
  intl: IntlShape
): SampleResult[] => {
  return sampleData.keyResults
    .filter((keyResult) => ownerList.includes(keyResult.owner))
    .map((kr) => ({
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      name: intl.formatMessage(messages[kr.id]),
      ...kr,
    }));
};

/**
 * Returns the list of filtered sample/demo KPIs that are
 * filtered wrt the owner listed within sampleData.
 * @param ownerList - List of owners; e.g ["sales","product"]
 * @param intl -intl object (of type IntlShape), which is the instance to store a cache of all Intl.
 * @return [object] - List of objects with KPI info
 */

export const getSampleKpis = (
  ownerList: string[],
  intl: IntlShape
): SampleKPI[] => {
  return sampleData.kpis
    .filter((kpi) => ownerList.includes(kpi.owner))
    .map((kpi) => ({
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      name: intl.formatMessage(messages[kpi.id]),
      ...kpi,
    }));
};
