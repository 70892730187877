import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { routes } from "route-configs";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { ReleaseAlert } from "common/alerts/ReleaseAlert/ReleaseAlert";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons/Icon/Icon";
import { SidebarLink } from "common/layout/Sidebar/SidebarLink/SidebarLink";
import { PermissionWrapper } from "common/wrappers";
import { roles } from "constants/roles";
import { useIsActive } from "hooks/routes/useIsActive";
import { useCompany, useCompanySettings } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { twClass } from "utils/twClass";
import { Favorites } from "../Favorites/Favorites";
import { SidebarSection } from "../SidebarSection/SidebarSection";
import { sidebarTitles } from "./SidebarLinks.messages";

export const SidebarLinks = (): JSX.Element => {
  const me = useCurrentUser();
  const isDue = me?.checkInDue ?? false;
  const streak = me?.checkInStreak ?? 0;
  const company = useCompany();
  const { timelineEnabled, pillarsEnabled } = useCompanySettings();
  const engagementReportFeature = useFeatureAccess(Feature.engagementReport);
  const kpiBoardsFeature = useFeatureAccess(Feature.kpiBoards);
  const oneOnOneFeature = useFeatureAccess(Feature.oneOnOnes);
  const performanceReportFeature = useFeatureAccess("PAGE_PERFORMANCE");
  const performanceReviewsFeature = useFeatureAccess(
    Feature.performanceReviews
  );
  const roadmapFeature = useFeatureAccess("PAGE_ROADMAP");
  const timelineFeature = useFeatureAccess("PAGE_TIMELINE");
  const intl = useIntl();
  const isActive = useIsActive();
  const router = useRouter();

  const [groupsLink, setGroupsLink] = useState(routes.groups.list);
  const [usersLink, setUsersLink] = useState(routes.users.list);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const isGroupsUrl =
        url.startsWith(routes.groups.main) || url == routes.groups.list;

      const isUsersUrl =
        url.startsWith(routes.users.main) || url == routes.users.list;

      if (isGroupsUrl) {
        setGroupsLink(url);
      }

      if (isUsersUrl) {
        setUsersLink(url);
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <div className="flex h-0 flex-shrink-0 flex-grow flex-col overflow-x-hidden">
        <div>
          <Show when={company.onboardingEnabled}>
            <SidebarLink
              active={isActive.quickStart}
              data-cy="sidebar-link-start"
              icon={<span>🚀</span>}
              label={<FormattedMessage {...sidebarTitles.quickStart} />}
              link={routes.quickStart.main}
            />
          </Show>
          <SidebarLink
            active={isActive.home}
            data-cy="sidebar-link-home"
            icon={<Icon name="home" size="xl" />}
            label={<FormattedMessage {...sidebarTitles.home} />}
            link={routes.home.main}
            rhs={
              <Show when={company.reflections}>
                <div
                  className={twClass("flex items-center space-x-1 text-sm", {
                    "text-yellow-500": !isDue,
                  })}
                >
                  <div>{streak}</div>
                  <Icon name="streak" size="sm" />
                </div>
              </Show>
            }
          />
          <SidebarLink
            active={isActive.reports}
            data-cy="sidebar-rollover-reports"
            icon={<Icon name="bar_chart" size="xl" />}
            label={intl.formatMessage(sidebarTitles.reportsTitle)}
            link={
              engagementReportFeature.show
                ? routes.reports.main
                : routes.performanceReports.main
            }
            premium={
              engagementReportFeature.badge || performanceReportFeature.badge
            }
          />
          <SidebarSection
            label={<FormattedMessage defaultMessage="Goals" id="XHLYdJ" />}
          >
            <SidebarLink
              active={isActive.companyGroup}
              data-cy="sidebar-rollover-company"
              icon={<Icon name="business" size="xl" />}
              label={<FormattedMessage defaultMessage="Company" id="9YazHG" />}
              link={routes.company.okrs}
            />
            <SidebarLink
              active={isActive.groups}
              data-cy="sidebar-rollover-groups"
              icon={<Icon name="group" size="xl" />}
              label={<FormattedMessage defaultMessage="Teams" id="DNeuzb" />}
              link={groupsLink}
            />
            <SidebarLink
              active={isActive.users}
              data-cy="sidebar-users"
              icon={<Icon name="person" size="xl" />}
              label={<FormattedMessage defaultMessage="Users" id="YDMrKK" />}
              link={usersLink}
            />
            <SidebarLink
              active={isActive.goals}
              data-cy="sidebar-rollover-reports"
              icon={<Icon name="flag" size="xl" />}
              label={
                <FormattedMessage defaultMessage="All goals" id="5CNApj" />
              }
              link={routes.exploreObjectives.list}
            />
          </SidebarSection>
          <Show
            when={
              oneOnOneFeature.show ||
              performanceReviewsFeature.show ||
              performanceReviewsFeature.badge
            }
          >
            <SidebarSection
              label={<FormattedMessage defaultMessage="People" id="Tpy00S" />}
            >
              <Show when={oneOnOneFeature.show}>
                <SidebarLink
                  active={isActive.oneOnOnes}
                  data-cy="sidebar-rollover-one-on-ones"
                  icon={<Icon name="supervised_user_circle" size="xl" />}
                  label={<FormattedMessage defaultMessage="1:1s" id="dr1w79" />}
                  link={routes.peopleManagement.oneOnOne}
                />
              </Show>
              <Show
                when={
                  performanceReviewsFeature.show ||
                  performanceReviewsFeature.badge
                }
              >
                <SidebarLink
                  active={isActive.performanceReviews}
                  data-cy="sidebar-rollover-reviews"
                  icon={<Icon name="stars" size="xl" />}
                  label={
                    <FormattedMessage defaultMessage="Reviews" id="dUxyza" />
                  }
                  premium={performanceReviewsFeature.badge}
                  link={routes.peopleManagement.performanceReviews}
                />
              </Show>
            </SidebarSection>
          </Show>
          <SidebarSection label={intl.formatMessage(sidebarTitles.strategy)}>
            <SidebarLink
              active={isActive.roadmap}
              data-cy="sidebar-link-roadmap"
              icon={<Icon name="alignedMap" size="xl" />}
              label={<FormattedMessage defaultMessage="Map" id="zuqMfZ" />}
              link={routes.mapsv3.main}
              premium={roadmapFeature.badge}
            />
            <Show
              when={
                pillarsEnabled &&
                timelineEnabled &&
                (timelineFeature.show || timelineFeature.badge)
              }
            >
              <SidebarLink
                active={isActive.timeline}
                data-cy="sidebar-link-timeline"
                icon={<Icon name="view_timeline" size="xl" type="outlined" />}
                label={
                  <FormattedMessage defaultMessage="Timeline" id="zWkvNO" />
                }
                link={routes.timeline.main}
                premium={timelineFeature.badge}
              />
            </Show>
            <Show when={kpiBoardsFeature.show || kpiBoardsFeature.badge}>
              <SidebarLink
                active={isActive.kpiBoard}
                data-cy="sidebar-link-kpi-board"
                icon={<Icon name="speed" size="xl" />}
                label={intl.formatMessage(sidebarTitles.kpiBoards)}
                link={routes.kpiBoard.main}
                premium={kpiBoardsFeature.badge}
              />
            </Show>
          </SidebarSection>
        </div>
        <Favorites />
      </div>
      <ReleaseAlert />
      <PermissionWrapper role={roles.superAdmin}>
        <SidebarLink
          active={isActive.settings}
          data-cy="sidebar-link-configure"
          icon={<Icon name="settings" size="xl" />}
          label={
            <div className="text-sm font-normal">
              {intl.formatMessage(sidebarTitles.configureTitle)}
            </div>
          }
          link={routes.settings.company.general.info}
        />
      </PermissionWrapper>
    </>
  );
};
