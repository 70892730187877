import { InternalRefetchQueriesInclude, QueryOptions } from "@apollo/client";
import {
  GetActionItemsDocument,
  MakeOptional,
  namedOperations,
  OneOnOneMeeting,
  OneOnOneMeetingDefaultFragment,
  OneOnOneMeetingItemInput,
  OneOnOneRepeatFrequency,
  OneOnOneSeries,
  OneOnOneSeriesDefaultFragment,
  UpsertOneOnOneMeetingInput,
  UpsertOneOnOneSeriesMutationInput,
  useBulkUpsertOneOnOneMeetingItemsMutation,
  useCompleteOneOnOneMeetingMutation,
  useDeleteOneOnOneMeetingItemMutation,
  useDeleteOneOnOneSeriesMutation,
  useUpsertOneOnOneMeetingItemMutation,
  useUpsertOneOnOneMeetingMutation,
  useUpsertOneOnOneSeriesMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";
import {
  ADD_ONE_ON_ONE_MEETING,
  GCAL_ONE_ON_ONE_EVENT_CREATED,
  GCAL_ONE_ON_ONE_EVENT_LINKED,
} from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useGoogleCalendar } from "hooks/useGoogleCalendar/useGoogleCalendar";
import { useOnboardingSteps } from "hooks/useOnboardingSteps/useOnboardingSteps";
import { handleErrors } from "utils/graphql/handleErrors";
import { mapEdges } from "utils/mapEdges";
import { track } from "utils/tracker";
import { OneOnOneMeetingItem } from "../OneOnOneCompleteForm/OneOnOneMeetingItemsField/OneOnOneMeetingItemsField";
import { formatOneOnOneMeetingDueDate } from "../oneOnOnemeeting.utils";
import { AddOneOnOneMeetingValues } from "../OneOnOneMeetingForm/AddOneOnOneMeetingForm";

type CreateNewSeriesAndMeetingInput = MakeOptional<
  AddOneOnOneMeetingValues & { organizer?: string },
  "meetingTime"
>;

type ToggleDisableSeriesInput = {
  isActive: boolean;
  meetingId: string;
  seriesId: string;
};

type CompleteMeetingInput = {
  id: string;
  isCancelled?: boolean;
};

type UpsertMeetingInput = UpsertOneOnOneMeetingInput & {
  meetingTime?: string | null;
};

type UpsertMeetingItemsResponse = Promise<{
  hasError: boolean;
}>;

type NewSeries = Derive<
  OneOnOneSeries,
  { id: true; nextMeeting: { id: true } }
>;
type CreateNewSeriesAndMeetingResponse = Promise<{
  hasError: boolean;
  oneOnOneSeries: NewSeries | null;
}>;

type MutationResponse = Promise<{
  hasError: boolean;
  oneOnOneMeeting?: OneOnOneMeetingDefaultFragment | null;
  oneOnOneSeries?: OneOnOneSeriesDefaultFragment | null;
}>;

type CompleteResponse = MutationResponse &
  Promise<{
    nextOneOnOneMeeting?: OneOnOneMeetingDefaultFragment | null;
  }>;

type Hook = {
  completeOneOnOneMeeting: (input: CompleteMeetingInput) => CompleteResponse;
  createNewSeriesAndMeeting: (
    input: CreateNewSeriesAndMeetingInput
  ) => CreateNewSeriesAndMeetingResponse;
  deleteOneOnOneMeetingItem: (id: string) => Promise<{
    hasError: boolean;
  }>;
  deleteOneOnOneSeries: (id: string) => Promise<{
    hasError: boolean;
  }>;
  toggleOneOnOneSeriesStatus: (input: ToggleDisableSeriesInput) => Promise<{
    hasError: boolean;
  }>;
  upsertMeetingItems: (
    meetingId: string,
    meetingItems?: OneOnOneMeetingItem[]
  ) => UpsertMeetingItemsResponse;
  upsertOneOnOneMeeting: (input: UpsertMeetingInput) => MutationResponse;
  upsertOneOnOneMeetingItem: (input: OneOnOneMeetingItemInput) => Promise<{
    hasError: boolean;
    meeting?: Pick<OneOnOneMeeting, "id"> | null;
  }>;
  upsertOneOnOneSeries: (input: UpsertOneOnOneSeriesMutationInput) => Promise<{
    hasError: boolean;
    oneOnOneSeries?: OneOnOneSeriesDefaultFragment | null;
  }>;
};

type HookProps = {
  refetchQueries?: InternalRefetchQueriesInclude;
};

export const useHandleOneOnOneMutations = ({
  refetchQueries = [],
}: HookProps = {}): Hook => {
  const intl = useIntl();
  const { refetchSteps } = useOnboardingSteps();
  const { integration: gcalIntegration } = useGoogleCalendar();
  const { timezone } = useCompany();
  const me = useCurrentUser();
  const refetchActionItemsQuery: QueryOptions = {
    query: GetActionItemsDocument,
    variables: { userId: me?.id },
  };
  const [upsertMeeting] = useUpsertOneOnOneMeetingMutation({
    refetchQueries: [
      namedOperations.Query.GetAllUserOneOnOneSeries,
      namedOperations.Query.GetOneOnOneMeeting,
      namedOperations.Query.GetOneOnOneSeries,
    ],
  });
  const [completeMeeting] = useCompleteOneOnOneMeetingMutation({
    refetchQueries: [refetchActionItemsQuery, ...refetchQueries],
  });
  const [upsertSeries] = useUpsertOneOnOneSeriesMutation({
    refetchQueries: [
      refetchActionItemsQuery,
      namedOperations.Query.getActiveSeries,
      namedOperations.Query.GetAllUserOneOnOneSeries,
      ...refetchQueries,
    ],
  });
  const [deleteSeries] = useDeleteOneOnOneSeriesMutation({
    refetchQueries: [
      refetchActionItemsQuery,
      namedOperations.Query.GetAllUserOneOnOneSeries,
      ...refetchQueries,
    ],
  });
  const [upsertMeetingItem, { data: upsertMeetingItemData }] =
    useUpsertOneOnOneMeetingItemMutation({
      refetchQueries: [refetchActionItemsQuery, ...refetchQueries],
    });
  const [deleteMeetingItem] = useDeleteOneOnOneMeetingItemMutation({
    refetchQueries: [refetchActionItemsQuery, ...refetchQueries],
  });
  const [bulkUpsertMeetingItems] = useBulkUpsertOneOnOneMeetingItemsMutation({
    refetchQueries: [refetchActionItemsQuery, ...refetchQueries],
  });

  const upsertOneOnOneMeeting = async (input: UpsertMeetingInput) => {
    const { dueDate: meetingDueDate, meetingTime, ...rest } = input;

    const dueDate = isNil(meetingTime)
      ? meetingDueDate
      : formatOneOnOneMeetingDueDate(meetingDueDate, meetingTime, timezone);

    const response = await upsertMeeting({
      variables: {
        input: {
          ...rest,
          ...(!isNil(dueDate) && { dueDate }),
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertOneOnOneMeeting?.errors
    );
    const meeting = response.data?.upsertOneOnOneMeeting?.oneOnOneMeeting;
    const series =
      response.data?.upsertOneOnOneMeeting?.oneOnOneMeeting?.oneOnOneSeries;
    return {
      hasError,
      oneOnOneMeeting: meeting,
      oneOnOneSeries: series,
    };
  };

  const upsertOneOnOneSeries = async (
    input: UpsertOneOnOneSeriesMutationInput
  ) => {
    const response = await upsertSeries({
      variables: { input },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertOneOnOneSeries?.errors
    );
    const oneOnOneSeries = response.data?.upsertOneOnOneSeries?.oneOnOneSeries;
    return {
      hasError: hasError || isNil(oneOnOneSeries),
      oneOnOneSeries,
    };
  };

  const deleteOneOnOneSeries = async (id: string) => {
    const response = await deleteSeries({
      variables: { id },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.deleteOneOnOneSeries?.errors
    );
    return {
      hasError,
    };
  };

  const upsertOneOnOneMeetingItem = async (input: OneOnOneMeetingItemInput) => {
    const response = await upsertMeetingItem({
      variables: {
        input,
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertOneOnOneMeetingItem?.errors
    );
    return {
      hasError,
      meeting: response.data?.upsertOneOnOneMeetingItem?.oneOnOneMeeting,
    };
  };

  const upsertMeetingItems = async (
    meetingId: string,
    meetingItems?: OneOnOneMeetingItem[]
  ): UpsertMeetingItemsResponse => {
    if (isNil(meetingItems)) return { hasError: false };

    const items: OneOnOneMeetingItemInput[] = meetingItems.map((item) => ({
      ...item,
      oneOnOneMeetingId: meetingId,
    }));
    const response = await bulkUpsertMeetingItems({
      variables: { input: items },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.bulkUpsertOneOnOneMeetingItems?.errors,
      { handleTopLevel: true }
    );
    return { hasError };
  };

  const handleDeleteMeetingItem = async (id: string) => {
    const response = await deleteMeetingItem({
      variables: { id },
    });

    const errors = response.data?.deleteOneOnOneMeetingItem?.errors;

    // if item has already been deleted, we don't need to raise an error.
    // it's already removed from the form values, and next sync won't bring it back because it doesn't exist.
    if (errors && errors.some((e) => e.messages.includes("Not found"))) {
      return { hasError: false };
    }
    const { hasError } = handleErrors(response, errors);
    return { hasError };
  };

  const createNewSeriesAndMeeting = async (
    input: CreateNewSeriesAndMeetingInput
  ): CreateNewSeriesAndMeetingResponse => {
    const {
      dueDate: meetingDueDate,
      meetingTime,
      googleCalendarEvent,
      googleCalendarEventAction,
      repeatFrequency,
      ...seriesInput
    } = input;
    const dueDate = isNil(meetingTime)
      ? meetingDueDate
      : formatOneOnOneMeetingDueDate(meetingDueDate, meetingTime, timezone);
    const organizer = seriesInput.organizer ?? me?.id;
    const { hasError, oneOnOneSeries } = await upsertOneOnOneSeries({
      ...seriesInput,
      gCalIntegration: googleCalendarEventAction ? gcalIntegration?.id : null,
      nextMeetingDueDate: dueDate,
      organizer,
      recurringEventId: googleCalendarEvent,
      repeatFrequency: googleCalendarEvent
        ? OneOnOneRepeatFrequency.Integration
        : repeatFrequency,
    });
    if (hasError) return { hasError, oneOnOneSeries: null };

    if (googleCalendarEventAction === "CREATE_EVENT") {
      track(GCAL_ONE_ON_ONE_EVENT_CREATED);
    }
    if (googleCalendarEventAction === "LINK_EXISTING_EVENT") {
      track(GCAL_ONE_ON_ONE_EVENT_LINKED);
    }

    toast.success(
      intl.formatMessage({
        defaultMessage: "1:1 meeting planned",
        id: "aLzjpS",
      })
    );

    refetchSteps();

    const userDirectReportsIds = mapEdges(me?.directReports.edges).map(
      ({ id }) => id
    );
    const userManagerId = me?.manager?.id;
    const isWithManagerOrDirectReport =
      userDirectReportsIds.includes(input.attendee ?? "") ||
      userManagerId === input.attendee;
    track(ADD_ONE_ON_ONE_MEETING, {
      attendee: input.attendee,
      managerAndDirectReport: isWithManagerOrDirectReport,
      organizer,
      repeat_frequency: input.repeatFrequency,
      scheduled_date: dueDate,
      seriesId: oneOnOneSeries?.id,
    });

    return { hasError, oneOnOneSeries: oneOnOneSeries ?? null };
  };

  const toggleOneOnOneSeriesStatus = async (
    input: ToggleDisableSeriesInput
  ) => {
    const { isActive, meetingId, seriesId } = input;
    const [upsertSeriesResponse, upsertMeetingResponse] =
      await Promise.allSettled([
        upsertOneOnOneSeries({
          id: seriesId,
          isActive,
        }),
        upsertOneOnOneMeeting({
          deactivateMeeting: !isActive,
          id: meetingId,
        }),
      ]);
    return {
      hasError:
        upsertSeriesResponse.status === "rejected" ||
        upsertMeetingResponse.status === "rejected",
    };
  };

  const completeOneOnOneMeeting = async (input: CompleteMeetingInput) => {
    const response = await completeMeeting({
      variables: { input },
    });
    const oneOnOneMeeting =
      response.data?.completeOneOnOneMeeting?.oneOnOneMeeting;
    const nextOneOnOneMeeting =
      response.data?.completeOneOnOneMeeting?.nextOneOnOneMeeting;
    const { hasError } = handleErrors(response);
    return {
      hasError: hasError || isNil(oneOnOneMeeting),
      nextOneOnOneMeeting,
      oneOnOneMeeting,
    };
  };

  return {
    completeOneOnOneMeeting,
    createNewSeriesAndMeeting,
    deleteOneOnOneMeetingItem: handleDeleteMeetingItem,
    deleteOneOnOneSeries,
    toggleOneOnOneSeriesStatus,
    upsertMeetingItems,
    upsertOneOnOneMeeting,
    upsertOneOnOneMeetingItem,
    upsertOneOnOneSeries,
  };
};
