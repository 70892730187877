import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Anchor } from "common/navigation/Anchor/Anchor";

const messages = defineMessages({
  moreLink: {
    defaultMessage: "more",
    id: "objective:warning:moreLink",
  },
  timeframePastBody: {
    defaultMessage:
      "Close these Objectives or learn {moreLink} about when cloning, moving or keeping them active makes more sense.",
    id: "timeframe:warning:timeframePastBody",
  },
  timeframePastTitle: {
    defaultMessage: "This timeframe has ended",
    id: "timeframe:warning:timeframePastTitle",
  },
});

export const TimeframePast = () => {
  const intl = useIntl();

  const MoreLink = () => (
    <Anchor
      href="http://support.perdoo.com/en/articles/4273650-when-to-clone-or-move-okrs-or-keep-it-active"
      rel="noopener noreferrer"
      target="_blank"
      text={intl.formatMessage(messages.moreLink)}
    />
  );

  return (
    <>
      <div className="mb-4 text-sm font-semibold">
        <FormattedMessage {...messages.timeframePastTitle} />
      </div>
      <div className="mb-4">
        <FormattedMessage
          {...messages.timeframePastBody}
          values={{
            moreLink: <MoreLink />,
          }}
        />
      </div>
    </>
  );
};
