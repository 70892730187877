import { defineMessages } from "react-intl";

export const messages = defineMessages({
  descriptionHelperText: {
    defaultMessage:
      "You can add context to share why this is a Strategic Pillar. Explain your choices so that everyone's on the same page.",
    id: "strategicpillar:forms:helper:text:description",
  },
  descriptionLabel: {
    defaultMessage: "Description",
    id: "strategicpillar:forms:description:label",
  },
  descriptionPlaceholder: {
    defaultMessage: "Add a description..",
    id: "strategicpillar:forms:description:placeholder",
  },
  nameLabel: {
    defaultMessage: "Strategic Pillar Name",
    id: "strategicpillar:forms:name:label",
  },
  namePlaceholder: {
    defaultMessage:
      "Example: Offer the best and most popular resources and support",
    id: "strategicpillar:forms:namePlaceholder",
  },
});
