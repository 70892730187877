import { useQuery } from "@apollo/client";
import { apolloClient } from "apolloClient";
import { FormikProps } from "formik";
import { camelCase, get, includes, lowerCase } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { components } from "react-select";
import { UnknownProps } from "types";
import {
  FORM_OBJECTIVE_QUERY,
  OBJECTIVES_SEARCH_QUERY,
} from "common/objective/queries";
import { FORM_RESULT_QUERY, RESULTS_SEARCH_QUERY } from "common/result/queries";
import { objectTypes } from "constants/objects";
import { Select } from "legacy/components/Select/Select";
import { isDefined } from "utils/isDefined";

const entityQueries = {
  [objectTypes.objective]: FORM_OBJECTIVE_QUERY,
  [objectTypes.keyresult]: FORM_RESULT_QUERY,
};

const entitiesQueries = {
  [objectTypes.objective]: OBJECTIVES_SEARCH_QUERY,
  [objectTypes.keyresult]: RESULTS_SEARCH_QUERY,
};

const gqlField = {
  [objectTypes.objective]: { item: "objective", list: "objectives" },
  [objectTypes.keyresult]: { item: "result", list: "results" },
};

type EntityType = keyof typeof entitiesQueries;

type Props = UnknownProps & {
  autoFocus?: boolean;
  entityType: EntityType;
  error?: string | null;
  /** @deprecated instead, pass the error related to the field as {@link error} */
  form?: FormikProps<unknown>;
};

export const FormAsyncSelectGql = ({
  autoFocus,
  entityType,
  error,
  field,
  filters = {},
  form,
  label,
  onChange,
  optional,
  placeholder,
  ...other
}: Props): JSX.Element => {
  const { data: entityData } = useQuery(entityQueries[entityType], {
    skip: !field.value,
    variables: {
      id: field.value,
    },
  });

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'search' implicitly has an 'any' type.
  const getOptions = (search) => {
    const query = entitiesQueries[entityType];
    const key = gqlField[entityType].list;

    return (
      apolloClient
        .query({
          query,
          variables: {
            search,
            ...filters,
          },
        })
        // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'node' implicitly has an 'any' typ... Remove this comment to see the full error message
        .then(({ data }) => data[key].edges.map(({ node }) => node))
    );
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const getLabel = (value) => value.name || "Unknown";

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  const filterOptions = ({ data }, query) =>
    includes(lowerCase(getLabel(data)), lowerCase(query));

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const handleChange = (value) => {
    const data = {
      target: {
        name: field.name,
        value: value?.id || null,
      },
    };

    if (onChange) {
      onChange(data);
      return;
    }

    field.onChange(data);
  };

  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  const Option = ({ data, ...props }) => {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <components.Option {...props}>
        <div className="truncate">{data.name}</div>
      </components.Option>
    );
  };

  const errorText = isDefined(error) ? error : get(form?.errors, field.name);
  const value = get(entityData, gqlField[entityType].item);

  return (
    <Select
      {...other}
      async
      autoFocus={autoFocus}
      components={{ Option }}
      data-cy={other["data-cy"] || camelCase(label)}
      errorText={errorText ?? undefined}
      filterOption={filterOptions}
      getOptionLabel={getLabel}
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
      getOptionValue={({ id }) => id}
      isClearable={optional}
      label={label}
      loadOptions={getOptions}
      noOptionsMessage={() => (
        <FormattedMessage
          defaultMessage="Type to search..."
          id="type:to:search"
        />
      )}
      onChange={handleChange}
      optional={optional}
      placeholder={placeholder}
      value={value}
    />
  );
};
