import { roles } from "constants/roles";

const rolesWeights = {
  [roles.viewOnly]: 0,
  [roles.standard]: 1,
  [roles.admin]: 2,
  [roles.superAdmin]: 3,
};

/**
 * checks if user has a role equal or superior to the given one.
 */
export const hasUserAccess = (
  user: { role: string } | undefined | null,
  role: string
): boolean => {
  if (!user) return false;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return rolesWeights[user.role] >= rolesWeights[role];
};

export const isRoleEqualOrSuperior = (
  userRole: string | undefined | null,
  targetRole: string
): boolean => {
  if (!userRole) return false;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return rolesWeights[userRole] >= rolesWeights[targetRole];
};

export const isRoleSuperior = (
  user: { role: string } | undefined | null,
  role: string
): boolean => {
  if (!user) return false;
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return rolesWeights[user.role] > rolesWeights[role];
};
