import { Field, Form, Formik, FormikHelpers } from "formik";
import { ReactNode, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons/Button/Button";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { AdvancedFields } from "common/form/AdvancedFields/AdvancedFields";
import { CheckboxField } from "common/form/CheckboxField/CheckboxField";
import { FormSlackChannelSelect } from "common/form/FormSlackChannelSelect/FormSlackChannelSelect";
import { GroupMultiField } from "common/form/GroupMultiField/GroupMultiField";
import { UserField } from "common/form/UserField";
import { UserMultiField } from "common/form/UserMultiField/UserMultiField";
import { Icon } from "common/icons";
import { Label } from "common/inputs/Label/Label";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { useHelpCenterContent } from "common/layout/HelpCenter/hooks/useHelpCenterContent";
import { SlackIntegrationInfoView } from "common/layout/HelpCenter/SlackIntegrationInfoView/SlackIntegrationInfoView";
import { HelpRollover } from "common/overlay/HelpRollover/HelpRollover";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { useUserCreation } from "legacy/utils/useUserCreation";
import { messages } from "./GroupForm.messages";

const schema = Yup.object().shape({
  email: Yup.string(),
  groupLeader: Yup.string().required("Required"),
  mission: Yup.string().nullable(),
  name: Yup.string().required("Required"),
});

export type GroupFormValues = {
  cadence?: string;
  groupLeader?: string;
  kpisEnabled: boolean;
  members: string[];
  mission?: string | null;
  name: string;
  slackChannel?: string | null;
  subGroups?: string[];
};

interface Props {
  avatarUpload?: ReactNode;
  excludeGroups?: string[];
  groupId?: string;
  initialValues: GroupFormValues;
  isEdit?: boolean;
  onSubmit: (
    values: GroupFormValues,
    actions: FormikHelpers<GroupFormValues>
  ) => void;
  setAddAnother?: (val: boolean) => void;
}

export const GroupForm = ({
  initialValues,
  setAddAnother,
  avatarUpload,
  excludeGroups = [],
  isEdit,
  onSubmit,
  groupId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { toggleHelpCenter } = useHelpCenter();
  useHelpCenterContent({
    element: <SlackIntegrationInfoView />,
    type: "slack_integration",
  });

  const { formErrors } = useUserCreation();
  const [whichSaveButtonClicked, setWhichSaveButtonClicked] = useState<
    "save" | "addAnother" | null
  >(null);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <div className="flex justify-between gap-4">
            <div className="grow">
              <InputField
                autoFocus
                data-cy="name"
                label={intl.formatMessage(messages.nameLabel)}
                name="name"
                placeholder={intl.formatMessage({
                  defaultMessage: "Name",
                  id: "HAlOn1",
                })}
              />
            </div>
            <Show when={avatarUpload}>
              <div className="mx-4">
                <Label>{intl.formatMessage(messages.profilePicture)}</Label>
                {avatarUpload}
              </div>
            </Show>
          </div>
          <UserField
            data-cy="fieldGroupLeader"
            label={intl.formatMessage(messages.leaderLabel)}
            name="groupLeader"
          />

          {formErrors.groupLeader && (
            <div className="text-red-500">{formErrors.groupLeader}</div>
          )}

          <UserMultiField
            label={intl.formatMessage({
              defaultMessage: "Team members",
              id: "Cg1T7H",
            })}
            name="members"
            optional
          />

          {formErrors.members && (
            <div className="text-red-500">{formErrors.members}</div>
          )}

          <GroupMultiField
            data-cy="subGroups"
            label={intl.formatMessage(messages.subGroupsLabel)}
            name="subGroups"
            optional
            exclude={groupId ? [groupId, ...excludeGroups] : undefined}
          />

          <div className="flex pt-2">
            <CheckboxField
              label={
                <div className="flex">
                  {intl.formatMessage(messages.kpisEnabledLabel)}
                  <div className="relative ml-1 mr-2 mt-1 block">
                    <HelpRollover
                      content={
                        <div className="w-64">
                          {intl.formatMessage(messages.kpisHelpLabel)}
                        </div>
                      }
                    />
                  </div>
                </div>
              }
              name="kpisEnabled"
            />
          </div>

          <AdvancedFields>
            <Field
              component={FormSlackChannelSelect}
              label={intl.formatMessage(messages.slackChannelLabel)}
              name="slackChannel"
              optional
              placeholder={intl.formatMessage(messages.slackChannelPlaceholder)}
            />
            <Button
              data-cy="ogB9n8Sj9bPhVmSD6M5za"
              className="px-0"
              onClick={toggleHelpCenter}
              size="small"
              variant="negative"
            >
              <span className="flex items-center space-x-1 text-blue-500">
                <span>
                  <FormattedMessage
                    defaultMessage="Can’t see your private channels?"
                    id="slack:privateChannelsHelp"
                  />
                </span>
                <Icon name="help_outline" size="xl" />
              </span>
            </Button>
          </AdvancedFields>

          <ModalFooter>
            <Button
              data-cy="addGroupButton"
              disabled={isSubmitting}
              loading={isSubmitting && whichSaveButtonClicked === "save"}
              onClick={() => {
                setWhichSaveButtonClicked("save");
                submitForm();
              }}
              type="submit"
            >
              {intl.formatMessage({
                defaultMessage: "Save",
                id: "global:save",
              })}
            </Button>
            <Show when={!isEdit}>
              <Button
                data-cy="rjNjxUwHCOmV2JFlCMIon"
                disabled={isSubmitting}
                loading={
                  isSubmitting && whichSaveButtonClicked === "addAnother"
                }
                onClick={() => {
                  setWhichSaveButtonClicked("addAnother");
                  setAddAnother?.(true);
                  submitForm();
                }}
                variant="ghost"
              >
                {intl.formatMessage({
                  defaultMessage: "Add another",
                  id: "global:add:another",
                })}
              </Button>
            </Show>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
