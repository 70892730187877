import {
  GetActiveUsersQueryVariables,
  useGetActiveUsersQuery,
  UserAvatarFragment,
  UserDefaultFragment,
  UserJobFragment,
  UserStatusFragment,
} from "@graphql";
import { isNil } from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { SELECT_EMPTY_ID } from "common/inputs/Select/Select.utils";
import { roles } from "constants/roles";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { mapEdges } from "utils/mapEdges";

export type User = UserDefaultFragment &
  UserAvatarFragment &
  UserJobFragment &
  UserStatusFragment;

export type Options = {
  excludeDirectReports?: boolean;
  excludeManager?: boolean;
  excludeSelf?: boolean;
  excludeViewOnly?: boolean;
  optional?: boolean;
  variables?: GetActiveUsersQueryVariables;
};

export type ActiveUsersOptions = Options;

export type UseActiveUsersHook = User[] | undefined;

export const useActiveUsers = (
  options?: ActiveUsersOptions
): UseActiveUsersHook => {
  const intl = useIntl();
  const currentUser = useCurrentUser();
  const { data } = useGetActiveUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: options?.variables,
  });
  return useMemo(() => {
    if (isNil(currentUser) || isNil(data)) {
      return undefined;
    }

    let users = mapEdges(data.allUsers.edges);

    if (options?.excludeSelf) {
      users = users.filter(({ id }) => id !== currentUser.id);
    }

    if (options?.excludeViewOnly) {
      users = users.filter(({ role }) => role !== roles.viewOnly);
    }

    if (options?.excludeDirectReports) {
      const directReportsIds = mapEdges(currentUser.directReports.edges).map(
        (u) => u.id
      );
      users = users.filter((u) => !directReportsIds.includes(u.id));
    }

    if (options?.excludeManager) {
      users = users.filter((u) => u.id !== currentUser.manager?.id);
    }

    if (options?.optional) {
      const noUser = {
        __typename: "user" as const,
        avatar: null,
        email: "",
        fullName: intl.formatMessage({
          defaultMessage: "No user",
          id: "N09b/z",
        }),
        id: SELECT_EMPTY_ID,
        isActive: false,
        jobPosition: null,
        role: "standard",
      };

      users.unshift(noUser);
    }

    return users;
  }, [
    data,
    options?.excludeSelf,
    options?.excludeViewOnly,
    options?.excludeDirectReports,
    options?.excludeManager,
    options?.optional,
    currentUser,
    intl,
  ]);
};
