import React from "react";
import { For } from "common/controlFlow";
import { usePerformanceReviewCompleteContext } from "common/performanceReview/context/performanceReviewCompleteContext";
import { mapEdges } from "utils/mapEdges";
import { PerformanceReviewAnswer } from "./PerformanceReviewAnswer";

export const PerformanceReviewCompletedQnA = (): JSX.Element => {
  const { questionAndAnswers, reviewee, reviewer } =
    usePerformanceReviewCompleteContext();
  const questionEdges = mapEdges(questionAndAnswers.edges);

  return (
    <div className="w-full space-y-3">
      <For each={questionEdges}>
        {(questionAndUserAnswers) => (
          <PerformanceReviewAnswer
            key={questionAndUserAnswers.id}
            questionAndAnswers={questionAndUserAnswers}
            reviewee={reviewee?.user}
            reviewer={reviewer?.user}
          />
        )}
      </For>
    </div>
  );
};
