import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, ButtonProps } from "common/buttons/Button/Button";

type Props = ButtonProps;

export const DismissButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ onClick, ...rest }: Props, ref) => (
    <Button
      className="px-1 py-0.5"
      data-cy="dismiss"
      onClick={onClick}
      variant="negative"
      ref={ref}
      {...rest}
    >
      <FormattedMessage defaultMessage="Dismiss" id="TDaF6J" />
    </Button>
  )
);
