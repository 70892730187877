import React from "react";
import { Status } from "common/charts/Status/Status";
import { Icon } from "common/icons/Icon/Icon";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { ReportResult } from "common/progressReports/Result";
import { getDelta } from "utils/getDelta/getDelta";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  result: ReportResult;
};

/**
 * goal values to display in the check-in form. value, status, delta.
 *
 * @param {object} props
 * @param {boolean} props.drafted means we have a new update drafted. then we additionally show delta, otherwise the text is greyed out.
 */
export const ResultValues = ({
  className,
  result,
}: Props): JSX.Element | null => {
  const { delta, deltaColorClass, deltaIcon } = getDelta({
    currentValue: result.delta.valueBefore,
    newValue: result.delta.value,
    positiveMetric: result.endValue > result.startValue,
  });

  return (
    <div className={twClass("flex w-36 flex-col text-sm", className)}>
      <div className="value flex items-center gap-1">
        <div className="flex items-center gap-1">
          <span data-testid="valueElement">
            <FormattedMetric
              value={result.delta.value}
              unit={result.metricUnit}
            />
          </span>
        </div>
        <div
          className={`flex items-center ${deltaColorClass}`}
          id="deltaElement"
        >
          <Icon name={deltaIcon} size="xs" />
          <span>
            <FormattedMetric value={delta} />
          </span>
        </div>
      </div>
      <Status status={result.delta.status} />
    </div>
  );
};
