import { PushIntegrationApplication } from "@graphql";
import { isNil } from "lodash";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getCompleteLink, routes } from "route-configs";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { useKpiContext } from "common/context/kpiContext";
import { Show } from "common/controlFlow";
import {
  INTEGRATION_NAMES,
  IntegrationIcon,
} from "common/goal/IntegrationIcon/IntegrationIcon";
import { GoalSidebarItem } from "common/goalModal/GoalSidebarItem/GoalSidebarItem";
import { Icon } from "common/icons";
import { InlineGoalUpdateFrequencySelect } from "common/inputs/InlineGoalUpdateFrequencySelect/InlineGoalUpdateFrequencySelect";
import { InlineMetricUnitSelect } from "common/inputs/InlineMetricUnitSelect/InlineMetricUnitSelect";
import { InlineObjectiveVisibilitySelect } from "common/inputs/InlineObjectiveVisibilitySelect/InlineObjectiveVisibilitySelect";
import { InlineOwnerSelect } from "common/inputs/InlineOwnerSelect/InlineOwnerSelect";
import { InlineTagsSelect } from "common/inputs/InlineTagsSelect/InlineTagsSelect";
import { InlineUserMultiSelect } from "common/inputs/InlineUserMultiSelect/InlineUserMultiSelect";
import { InlineUserSelect } from "common/inputs/InlineUserSelect/InlineUserSelect";
import { useInlineKpiActions } from "common/kpi/hooks/useInlineKpiActions/useInlineKpiActions";
import { useKpiData } from "common/kpi/hooks/useKpiData";
import { KpiStatusHealth } from "common/kpi/KpiStatusHealth/KpiStatusHealth";
import { formatGoalOperator } from "common/kpi/utils";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { SelectLinkButton } from "common/misc/SelectLinkButton/SelectLinkButton";
import { AnchorNext } from "common/navigation/AnchorNext/AnchorNext";
import { SupportTip } from "common/support";
import { allRolesButViewonly } from "constants/roles";
import { useCompany, useCompanySettings } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { roleNames } from "hooks/useRoleOptions/useRoleOptions";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { KpiAlignment } from "./KpiAlignment/KpiAlignment";
import { KpiProgressUpdateButton } from "./KpiProgressUpdateButton/KpiProgressUpdateButton";

export const KpiSidebar = () => {
  const intl = useIntl();
  const kpi = useKpiContext();
  const currentUser = useCurrentUser();
  const company = useCompany();
  const { pillarsEnabled } = useCompanySettings();
  const { show: privateGoalsAvailable } = useFeatureAccess(
    Feature.privateGoals
  );

  const {
    setOwners,
    setLead,
    setTags,
    setUpdateFrequency,
    setVisibility,
    setContributors,
    setAlignment,
    setMetricUnit,
  } = useInlineKpiActions(kpi);

  const { isHealthy } = useKpiData(kpi);

  const isAligned = !!kpi.strategicPillar || !!kpi.strategicPillar;

  const owners = useMemo(() => {
    const groups = mapEdges(kpi.groups.edges).map((node) => node.id);
    if (kpi.isCompanyGoal && !!company.id) {
      groups.push(company.id);
    }
    return groups;
  }, [kpi, company.id]);

  const contributors = useMemo(
    () => kpi.visibleTo.edges.map((edge) => edge.node.id),
    [kpi]
  );

  const tags = useMemo(() => kpi.tags.edges.map((edge) => edge.node.id), [kpi]);

  const getOwnerLink = () => {
    if (owners.length === 1) {
      const ownerId = owners[0];
      if (ownerId === company.id) return routes.company.okrs;
      return getCompleteLink(routes.groups.single.okrs, {
        id: ownerId,
      }).absolutePath;
    }
    return null;
  };

  return (
    <div className="m-6" data-testid="kpiSidebar">
      <div className="flex flex-col space-y-4 pb-4 border-b border-slate-300">
        <div className="font-semibold flex items-center space-x-2">
          <Icon
            size="xl"
            className="text-indigo-500"
            name="monitor_heart"
            type="outlined"
          />
          <div>
            <FormattedMessage defaultMessage="KPI details" id="D523s3" />
          </div>
        </div>
      </div>
      <div className="py-4 text-slate-500 text-sm border-b border-slate-300">
        <div className="flex flex-col space-y-2">
          <KpiProgressUpdateButton />
          <GoalSidebarItem className="h-10">
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Last update" id="oVHn4s" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <div className="flex space-x-1.5">
                <div>{kpi.lastCommit?.value}</div>
                {!!kpi.lastCommitTarget &&
                  !!kpi.lastCommitTarget.operator &&
                  !isNil(kpi.lastCommitTarget.threshold) && (
                    <div>
                      /
                      {` ${formatGoalOperator(kpi.lastCommitTarget.operator)} ${
                        kpi.lastCommitTarget.threshold
                      }`}
                    </div>
                  )}
              </div>
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div>
                  <FormattedMessage
                    defaultMessage="Status"
                    id="global:status"
                  />
                </div>
                <SupportTip
                  iconSize="lg"
                  description={intl.formatMessage({
                    defaultMessage:
                      'Status is calculated based on the last update. If there is no target then it defaults to "healthy".',
                    id: "XYrqBc",
                  })}
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <KpiStatusHealth isHealthy={isHealthy} />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={isSet(kpi.lastCommitDate)}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage defaultMessage="Last Updated" id="EeRwjE" />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <FormattedDate value={kpi.lastCommitDate!} includeYear />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>

          <Show when={isSet(kpi.pushIntegrationApplication)}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage defaultMessage="Updated via" id="UYfL48" />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                {kpi.pushIntegrationApplication && (
                  <div className="flex items-center">
                    <IntegrationIcon
                      className="mr-1.5"
                      integration={kpi.pushIntegrationApplication}
                    />
                    {
                      INTEGRATION_NAMES[
                        kpi.pushIntegrationApplication as PushIntegrationApplication
                      ]
                    }
                  </div>
                )}
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Measure as" id="uzzR4W" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineMetricUnitSelect
                value={kpi.metricUnit}
                onChange={setMetricUnit}
                data-testid="metricUnitSelect"
                data-cy="metricUnitSelect"
                disabled={!kpi.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
        </div>
      </div>

      <div className="py-4 text-slate-500 text-sm border-b border-slate-300">
        <div className="flex flex-col space-y-2">
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Owner" id="global:owner" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <SelectLinkButton
                href={getOwnerLink()}
                tooltip={
                  <FormattedMessage defaultMessage="Open profile" id="z6rKH9" />
                }
              >
                <InlineOwnerSelect
                  disabled={!kpi.canPatch}
                  value={owners}
                  onChange={setOwners}
                  data-cy="ownersSelect"
                />
              </SelectLinkButton>
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <FormattedMessage defaultMessage="Lead" id="global:lead" />
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <SelectLinkButton
                href={
                  kpi.lead?.id
                    ? getCompleteLink(routes.users.single.okrs, {
                        id: kpi.lead.id,
                      }).absolutePath
                    : null
                }
                tooltip={
                  <FormattedMessage defaultMessage="Open profile" id="z6rKH9" />
                }
              >
                <InlineUserSelect
                  value={kpi.lead?.id ?? null}
                  variables={{
                    roleIn: allRolesButViewonly.join(),
                  }}
                  onChange={setLead}
                  data-testid="leadSelect"
                  data-cy="leadSelect"
                  disabled={!kpi.canPatch}
                />
              </SelectLinkButton>
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={pillarsEnabled}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <div className="flex space-x-1 items-center">
                  <div>
                    <FormattedMessage
                      defaultMessage="Alignment"
                      id="global:alignment"
                    />
                  </div>

                  <SupportTip
                    iconSize="lg"
                    description={intl.formatMessage({
                      defaultMessage:
                        "Pick whether this KPI supports a Strategic Pillar",
                      id: "iZKiQ1",
                    })}
                  />
                </div>
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <SelectLinkButton
                  href={
                    isSet(kpi.strategicPillar?.id) ? routes.mapsv3.main : null
                  }
                  tooltip={
                    <FormattedMessage defaultMessage="Open maps" id="dSUuSP" />
                  }
                >
                  <KpiAlignment
                    onChange={(id) => setAlignment({ strategicPillar: id })}
                    value={kpi.strategicPillar?.id}
                    disabled={!kpi.canPatch || kpi.archived}
                  />
                </SelectLinkButton>
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
          </Show>

          <GoalSidebarItem className="items-start">
            <GoalSidebarItem.Title>
              <div className="flex items-center">
                <FormattedMessage
                  defaultMessage="Tags"
                  id="company:settings:tags:title"
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineTagsSelect
                value={tags}
                onChange={setTags}
                data-cy="tagsSelect"
                disabled={!kpi.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>
        </div>
      </div>

      <div className="py-4 text-slate-500 text-sm">
        <div className="flex flex-col space-y-2">
          <GoalSidebarItem>
            <GoalSidebarItem.Title>
              <div className="flex space-x-1 items-center">
                <div data-testid="updateFrequencyLabel">
                  <FormattedMessage
                    defaultMessage="Update. freq."
                    id="AwjGtN"
                  />
                </div>
                <SupportTip
                  iconSize="lg"
                  description={intl.formatMessage({
                    defaultMessage:
                      "Select a custom update frequency for this goal. " +
                      "Note: your default update frequency is based on timeframe settings.",
                    id: "YsAEo2",
                  })}
                />
              </div>
            </GoalSidebarItem.Title>
            <GoalSidebarItem.Content>
              <InlineGoalUpdateFrequencySelect
                data-testid="updateFrequencySelect"
                data-cy="updateFrequencySelect"
                onChange={setUpdateFrequency}
                value={kpi.goalUpdateCycle}
                disabled={!kpi.canPatch}
              />
            </GoalSidebarItem.Content>
          </GoalSidebarItem>

          <Show when={privateGoalsAvailable}>
            <GoalSidebarItem>
              <GoalSidebarItem.Title>
                <FormattedMessage defaultMessage="Visibility " id="1ExHW5" />
              </GoalSidebarItem.Title>
              <GoalSidebarItem.Content>
                <InlineObjectiveVisibilitySelect
                  isPrivate={kpi.private}
                  onChange={setVisibility}
                  disabled={isAligned || !kpi.canPatch}
                  data-cy="visibilitySelect"
                  tooltip={
                    isAligned ? (
                      <FormattedMessage
                        defaultMessage="Aligned Kpi can only be public"
                        id="0uvig2"
                      />
                    ) : null
                  }
                />
              </GoalSidebarItem.Content>
            </GoalSidebarItem>
            <Show when={kpi.private}>
              <GoalSidebarItem className="items-start">
                <GoalSidebarItem.Title>
                  <div className="flex space-x-1 items-center">
                    <div>
                      <FormattedMessage
                        defaultMessage="Private to  "
                        id="smR1Gn"
                      />
                    </div>
                    <SupportTip
                      iconSize="lg"
                      description={intl.formatMessage({
                        defaultMessage:
                          "Only kpi lead and contributors can see private OKRs.",
                        id: "1rO5bt",
                      })}
                    />
                  </div>
                </GoalSidebarItem.Title>
                <GoalSidebarItem.Content>
                  <InlineUserMultiSelect
                    value={contributors}
                    onChange={setContributors}
                    data-cy="contributorsSelect"
                    addText={
                      <FormattedMessage
                        defaultMessage="Add users"
                        id="z42uCZ"
                      />
                    }
                    disabled={!kpi.canPatch}
                    options={{ excludeViewOnly: true }}
                  />
                </GoalSidebarItem.Content>
              </GoalSidebarItem>
            </Show>
          </Show>
        </div>
      </div>

      <Show when={!kpi.canPatch}>
        <div className="flex space-x-2 items-center py-4 text-slate-500 text-sm border-t border-slate-300">
          <Icon name="error_outline" />
          <div>
            {currentUser?.role && (
              <FormattedMessage
                defaultMessage="As a {role} user editing possibilities are restricted. {learnMore}"
                id="wslZHv"
                values={{
                  learnMore: (
                    <AnchorNext
                      href="https://support.perdoo.com/en/articles/1588568-roles-rights"
                      data-cy="rolesLink"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <FormattedMessage
                        defaultMessage="Learn more"
                        id="TdTXXf"
                      />
                    </AnchorNext>
                  ),
                  role: <FormattedMessage {...roleNames[currentUser.role]} />,
                }}
              />
            )}
          </div>
        </div>
      </Show>
    </div>
  );
};
