import Image from "next/legacy/image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { videos as videoMessages } from "common/layout/HelpCenter/HelpCenter.messages";
import { useHelpCenterTrack } from "common/layout/HelpCenter/hooks/useHelpCenterTrack";
import { AnchorNextTrack } from "common/navigation";
import { Carousel } from "common/transitions/Carousel/Carousel";
import { twClass } from "utils/twClass";
import thumbnailKpi from "../../../../../../public/images/thumbnail-add-kpi.png";
import thumbnailOkr from "../../../../../../public/images/thumbnail-add-okr.png";
import thumbnailCloseOkr from "../../../../../../public/images/thumbnail-close-okr.png";
import thumbnailEditDelete from "../../../../../../public/images/thumbnail-edit-delete.png";

export const Videos = (): JSX.Element => {
  const intl = useIntl();
  const { trackContentHandler } = useHelpCenterTrack();

  const videos = [
    {
      href: "https://youtu.be/NPRfqoWnzMs",
      src: thumbnailOkr,
      title: "How to add an OKR in Perdoo",
    },
    {
      href: "https://youtu.be/5ySI9IQUQIk",
      src: thumbnailKpi,
      title: "How to add a KPI in Perdoo",
    },
    {
      href: "https://youtu.be/8ojZO0FPkUo",
      src: thumbnailEditDelete,
      title: "How to edit or delete an update in Perdoo",
    },
    {
      href: "https://youtu.be/6xG5CkkbnKg",
      src: thumbnailCloseOkr,
      title: "How to close an OKR in Perdoo",
    },
  ];
  const playlistLink =
    "https://www.youtube.com/playlist?list=PLmjF3e9n38GTd1oqSQIblhVPlrFzLVYQo";

  return (
    <div className="h-60 border-b py-4 text-sm font-normal border-slate-300">
      <div
        className="flex justify-between px-5 pb-2"
        data-cy="helpCenterVideosHeader"
      >
        <h6 className="my-0 text-sm">
          {intl.formatMessage(videoMessages.title)}
        </h6>
        <AnchorNextTrack
          data-cy="N3-6xvfYDCxNezkqq6riN"
          href={playlistLink}
          onClick={trackContentHandler(playlistLink)}
          target="blank"
        >
          <FormattedMessage defaultMessage="See more" id="global:seeMore" />
        </AnchorNextTrack>
      </div>
      <div className="h-48 w-full pb-5" data-cy="helpCenterVideos">
        <Carousel
          className="h-full pl-1.5"
          hiddenChildClass="opacity-50"
          itemMinWidth={80}
        >
          {videos.map(({ href, src, title }) => (
            <AnchorNextTrack
              data-cy="wWU0uMbfc1l7_tFxp3EBn"
              key={href}
              className={twClass(
                "flex h-full w-full flex-col",
                "group rounded",
                "text-slate-800 hover:text-blue-500"
              )}
              href={href}
              onClick={trackContentHandler(href)}
              target="blank"
            >
              <Image alt={title} className="rounded-t" src={src} />
              <div
                className={twClass(
                  "h-14 p-2 text-sm",
                  "rounded-b border-x border-b border-slate-200",
                  "bg-white group-hover:bg-slate-50"
                )}
              >
                {title}
              </div>
            </AnchorNextTrack>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
