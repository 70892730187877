import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { mapEdges } from "utils/mapEdges";

/**
 * Returns the default list of owners for a goal based on permissions
 * and a contextual default.
 *
 * @note The `contextualOwnerId` maybe be filtered out based on the permissions
 * the user has and is not guaranteed to be used.
 *
 * @note This may return an empty array.
 */
export const useDefaultOwners = (contextualOwnerId?: string): string[] => {
  const currentUser = useCurrentUser();
  const company = useCompany();
  const permissions = usePermissions();

  // If it's a company and we can create company goals, then return it.
  if (
    contextualOwnerId &&
    contextualOwnerId === company.id &&
    permissions.canCreateCompanyGoals
  ) {
    return [contextualOwnerId];
  }

  // If it's a group and we can create goals in any group, return it.
  if (contextualOwnerId && permissions.canCreateGoalsInAnyGroup) {
    return [contextualOwnerId];
  }

  // If it's one of our own groups and we can create goals in that group,
  // return it.
  const userGroups = mapEdges(currentUser?.groups.edges).map(
    (group) => group.id
  );
  if (
    contextualOwnerId &&
    userGroups.includes(contextualOwnerId) &&
    permissions.canCreateGoalsInOwnGroup
  ) {
    return [contextualOwnerId];
  }

  // Return the company only if we can create a company goal.
  return company.id && permissions.canCreateCompanyGoals ? [company.id] : [];
};
