import { isUndefined, some } from "lodash";
import React, { ReactNode, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import ReactSelect from "react-select";
import ReactAsync from "react-select/async";
import ReactCreatable from "react-select/creatable";
import styled from "styled-components";
import { FormControl } from "common/form/FormControl/FormControl";
import { colorTheme } from "constants/colorTheme";

const Container = styled.div`
  .pd-select__menu {
    width: ${(props) =>
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'ThemedSty... Remove this comment to see the full error message
      props.width};
  }
  .pd-select__option--is-disabled {
    color: ${colorTheme.slate[800]};
    opacity: 0.6;
  }
`;

type Props = Record<string, any> & {
  autoFocus?: boolean;
  noOptionsMessage?:
    | (() => ReactNode)
    | ((props: { inputValue: string }) => ReactNode);
};

/**
 * @deprecated use {@link input/Select} instead. for multi-selects we don't have a generic component at this point.
 */
export const Select = ({
  async = false,
  autoFocus,
  color = null,
  containerProps = {},
  creatable = false,
  disabled = false,
  errorText = "",
  helperText = undefined,
  id = "",
  infoElement = null,
  isInForm,
  label = "",
  noOptionsMessage,
  optional = false,
  positionAbsolute = true,
  styles = {},
  width: overrodeWidth = null,
  withMenuPortalTarget = true,
  ...other
}: Props): JSX.Element => {
  const ref = useRef(null);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const formControlProps = {
    errorText,
    fieldAddOn: other.fieldAddOn,
    helperText,
    id,
    infoElement,
    label,
    optional,
  };

  let SelectComponent = ReactSelect;
  if (async) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof Async' is not assignable to type 'typ... Remove this comment to see the full error message
    SelectComponent = ReactAsync;
  } else if (creatable) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof Creatable' is not assignable to type ... Remove this comment to see the full error message
    SelectComponent = ReactCreatable;
  }

  // This would make Select much easier to test, however when I replaced
  // the component like this it always caused the input to unfocus after
  // typing the first character. Probably related to the ref not being passed
  // down correctly, so leaving it for now which makes the tests a bit ugly.
  // const Input = props => {
  //   return <comps.Input {...props} data-cy={other["data-cy"]} />;
  // };

  const handleScroll = () => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ref.current) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
      const { top, left } = ref.current.getBoundingClientRect();
      if (position.top !== top && position.left !== left) {
        setPosition({ left, top });
      }
    }
  };

  const handleMenuOpen = () => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ref.current) {
      window.addEventListener("scroll", handleScroll, true);
    }
  };

  const handleMenuClose = () => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ref.current) {
      window.addEventListener("scroll", handleScroll, true);
    }
  };

  const menuPortalTarget = withMenuPortalTarget
    ? document.querySelector<HTMLElement>("#select-dropdown-portal")
    : null;

  const selectComponent = (
    <SelectComponent
      autoFocus={autoFocus}
      classNamePrefix="pd-select"
      isDisabled={disabled}
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'disabled' does not exist on type '{ labe... Remove this comment to see the full error message
      isOptionDisabled={(option) => option.disabled}
      menuPlacement="auto"
      menuPortalTarget={menuPortalTarget}
      // library has no types or documentation so not sure what it wants, but ReactNode works
      // @ts-expect-error FIXME: TS2769: No overload matches this call.
      noOptionsMessage={
        noOptionsMessage ??
        (() => <FormattedMessage defaultMessage="No options" id="pfrU/9" />)
      }
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      styles={{
        control: (base) => ({
          ...base,
          backgroundColor: disabled
            ? `${colorTheme.slate[100]} !important`
            : colorTheme.white,
          borderColor: errorText ? colorTheme.red[500] : base.borderColor,
        }),
        menuPortal: (base) => ({
          ...base,
          minWidth: 150,
          zIndex: 99999,
        }),
        option: (styles, { isDisabled }) => {
          if (!isDisabled) return styles;
          return {
            ...styles,
            color: "#979BA2",
          };
        },
        ...styles,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          danger: colorTheme.red[500],
          // TODO: [no-unnecessary-condition] remove and fix
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          neutral20: color || colorTheme.slate[300],
          // TODO: [no-unnecessary-condition] remove and fix
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          neutral30: color || colorTheme.slate[300],
          neutral50: colorTheme.slate[400],
          primary: colorTheme.blue[500],
          primary25: colorTheme.blue[100],
          primary50: colorTheme.blue[100],
          primary75: colorTheme.blue[200],
        },
      })}
      {...other}
    />
  );

  const inputContainer = positionAbsolute ? (
    <Container
      data-cy={other["data-cy"]}
      data-testid={other["data-testid"]}
      width={overrodeWidth}
      {...containerProps}
    >
      {selectComponent}
    </Container>
  ) : (
    selectComponent
  );

  const useFormControl = !isUndefined(isInForm)
    ? isInForm
    : some(formControlProps, (val) => !isUndefined(val));

  return useFormControl ? (
    <div ref={ref}>
      <FormControl {...formControlProps}>{inputContainer}</FormControl>
    </div>
  ) : (
    <div ref={ref}>{inputContainer}</div>
  );
};
