import React from "react";
import styled from "styled-components";
import { Icon } from "common/icons/Icon/Icon";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { ProgressDriverStatus } from "common/progress/ProgressDriverStatus/ProgressDriverStatus";
import { colorTheme } from "constants/colorTheme";
import * as Typography from "constants/typography";

const Container = styled.div`
  color: ${colorTheme.slate[800]};
  display: flex;
  flex-wrap: wrap;
  width: 280px;
  flex-direction: column;
  padding: 5px 9px;
  background-color: white;

  .valueDelta {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .value {
      font-size: ${Typography.fontSizeExtraLarge};
      font-weight: 500;
    }

    .delta {
      display: flex;
      align-items: center;
      margin-left: 5px;

      i {
        font-size: 16px;
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid;
      margin-right: 6px;
      margin-left: 4px;
    }
  }

  .comment {
    margin-top: 4px;
  }

  .user {
    display: flex;
    img {
      width: 20px;
      max-height: 20px;
      border-radius: 50px;
      margin-right: 5px;
    }
  }
`;

interface Props {
  className?: string;
  deltaColor?: string;
  deltaIcon?: string;
  description?: string;
  descriptionClass?: string;
  formattedDelta?: string | null;
  formattedProgress?: string | null;
  lastUpdatedString?: string;
  progressDriver?: string;
  renderToString?: boolean;
  showStatus?: boolean;
  statusColor?: string;
  statusTitle?: string;
  valueClass?: string;
  warning?: string;
  warningClass?: string;
}

type PropName =
  | "lastUpdatedString"
  | "deltaColor"
  | "deltaIcon"
  | "formattedDelta"
  | "formattedProgress"
  | "statusColor"
  | "statusTitle"
  | "descriptionClass"
  | "description"
  | "warning"
  | "warningClass"
  | "valueClass";

export const ProgressChartTooltip = ({
  showStatus = false,
  renderToString,
  className = "",
  progressDriver,
  ...props
}: Props): JSX.Element => {
  const renderProp = (name: PropName) => {
    if (renderToString) return `{${name}}`;
    return props[name];
  };

  return (
    <>
      <Container className={className}>
        <div className="date my-1 text-xs font-semibold text-slate-500">
          {renderProp("lastUpdatedString")}
        </div>
        <div className={`valueDelta ${renderProp("valueClass")}`}>
          <span className="value">{renderProp("formattedProgress")}</span>
          <span
            className="delta"
            style={{ color: renderProp("deltaColor") as string }}
          >
            <i className="material-icons">{renderProp("deltaIcon")}</i>
            {renderProp("formattedDelta")}
          </span>
        </div>
        {showStatus && (
          <div className="status">
            <div
              className="circle"
              style={{
                backgroundColor: renderProp("statusColor") as string,
                borderColor: renderProp("statusColor") as string,
              }}
            />
            <div className="text-sm">{renderProp("statusTitle")}</div>
          </div>
        )}
        <div className={`comment text-sm ${renderProp("descriptionClass")}`}>
          <DangerousHTML html={renderProp("description") as string} />
        </div>
        <div
          className={`mt-2 flex items-center text-xs text-slate-500 ${renderProp(
            "warningClass"
          )}`}
        >
          <Icon className="mr-2 text-yellow-500" name="warning" size="sm" />
          {renderProp("warning")}
        </div>
      </Container>
      {progressDriver && (
        <ProgressDriverStatus
          className="border-t bg-slate-50"
          progressDriver={progressDriver}
        />
      )}
    </>
  );
};
