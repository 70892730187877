import { Field, FieldProps, useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { Show } from "common/controlFlow";
import { AdvancedFields } from "common/form/AdvancedFields/AdvancedFields";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { FormTabbedToggle } from "common/form/FormTabbedToggle/FormTabbedToggle";
import { StrategicPillarField } from "common/form/StrategicPillarField/StrategicPillarField";
import { UserMultiField } from "common/form/UserMultiField/UserMultiField";
import { WithHelper } from "common/form/WithHelper/WithHelper";
import { AnchorNext } from "common/navigation/AnchorNext/AnchorNext";
import { TagField } from "common/select/TagMultiSelect/TagField";
import { SupportTip } from "common/support";
import { ShowTagsWrapper } from "common/tag/ShowTagsWrapper/ShowTagsWrapper";
import { useCompanySettings } from "hooks/useCompany/useCompany";
import { useGoalUpdateFrequencyOptions } from "hooks/useGoalUpdateFrequencyOptions/useGoalUpdateFrequencyOptions";
import { KpiFormValues } from "../KpiForm";

export const AdvancedSection = (): JSX.Element => {
  const intl = useIntl();
  const { pillarsEnabled } = useCompanySettings();
  const { show: privateGoalsAvailable } = useFeatureAccess(
    Feature.privateGoals
  );
  const goalUpdateFrequencyOptions = useGoalUpdateFrequencyOptions();
  const { values, setFieldValue } = useFormikContext<KpiFormValues>();
  const isPrivate = values.private;

  const pillarDisabledTooltip = (
    <div className="flex items-center">
      {intl.formatMessage({
        defaultMessage:
          "It’s not possible to align Private KPIs to Strategic Pillars. Make this KPI public to align it.",
        id: "omPaEF",
      })}
    </div>
  );

  return (
    <AdvancedFields>
      <Show when={pillarsEnabled}>
        <WithHelper
          helper={
            <FormattedMessage
              defaultMessage="Strategic Pillars don’t have due dates, so they are measured by KPIs."
              id="Hzqfmj"
            />
          }
        >
          <StrategicPillarField
            data-cy="strategicPillar"
            disabled={isPrivate}
            label={intl.$t({
              defaultMessage: "Align to strategic pillar",
              id: "VYkj+1",
            })}
            name="strategicPillar"
            optional
            tooltip={isPrivate && pillarDisabledTooltip}
          />
        </WithHelper>
      </Show>
      <WithHelper
        helper={
          <FormattedMessage
            defaultMessage="The frequency with which the lead will be prompted to update this KPI"
            id="/zuAMK"
          />
        }
      >
        <Field name="goalUpdateCycle">
          {({ form, field }: FieldProps) => (
            <FormSelect
              field={field}
              form={form}
              label={
                <span className="flex gap-1">
                  <FormattedMessage
                    defaultMessage="Update frequency"
                    id="xcngM0"
                  />
                  <SupportTip
                    description={intl.formatMessage({
                      defaultMessage:
                        "How often is the lead prompted to update this KPI ?",
                      id: "AGXRqa",
                    })}
                    links={{}}
                  />
                </span>
              }
              options={goalUpdateFrequencyOptions}
              placeholder={intl.formatMessage({
                defaultMessage: "Update frequency",
                id: "xcngM0",
              })}
            />
          )}
        </Field>
      </WithHelper>

      <Show when={privateGoalsAvailable}>
        <WithHelper>
          <Field name="private">
            {({ field }: FieldProps) => (
              <FormTabbedToggle
                afterChange={(value: boolean) => {
                  if (value) {
                    setFieldValue("strategicPillar", "");
                  } else {
                    setFieldValue("visibleTo", []);
                  }
                }}
                description={intl.formatMessage(
                  {
                    defaultMessage:
                      "Hide KPI from everyone except the lead and people you select. {link}",
                    id: "UqJrg4",
                  },
                  {
                    link: (
                      <AnchorNext
                        href="http://support.perdoo.com/en/articles/5040665-make-an-okr-private"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Learn more",
                          id: "TdTXXf",
                        })}
                      </AnchorNext>
                    ),
                  }
                )}
                field={field}
                label={intl.formatMessage({
                  defaultMessage: "Private KPI",
                  id: "2r0bsY",
                })}
                testLabel="privateKpi"
              />
            )}
          </Field>
        </WithHelper>
      </Show>
      <Show when={isPrivate}>
        <WithHelper>
          <UserMultiField
            autoFocus
            options={{ excludeViewOnly: true }}
            label={intl.formatMessage({
              defaultMessage: "Visible to the Lead and the following people:",
              id: "fy2z4y",
            })}
            name="visibleTo"
            optional
          />
        </WithHelper>
      </Show>

      <WithHelper
        helper={
          <FormattedMessage
            defaultMessage="Add tags to easily find related KPI via filters and search fields"
            id="MOKGyo"
          />
        }
      >
        <ShowTagsWrapper name="tags">
          <TagField data-cy="kpiTags" name="tags" />
        </ShowTagsWrapper>
      </WithHelper>
    </AdvancedFields>
  );
};
