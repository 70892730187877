import {
  NextOneOnOneMeetingFragment,
  OneOnOneMeeting,
  OneOnOneMeetingDatesFragment,
  OneOnOneRepeatFrequency,
  OneOnOneSeriesUsersFragment,
  UserAvatarFragment,
  UserDefaultFragment,
  UserJobFragment,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { createNonNullableCtx } from "common/context/utils";
import { OmitTypename } from "utils/graphql/types";

export type SeriesUser =
  | (UserDefaultFragment & UserAvatarFragment & UserJobFragment)
  | null;

type Meeting = Derive<
  OneOnOneMeeting,
  {
    dueDate: true;
    gCalEvent: {
      conference: {
        link: true;
      };
      link: true;
    };
    id: true;
    isCancelled: true;
    isComplete: true;
    isOrganizer: true;
    lastEditedDate: true;
    scheduleDate: true;
  }
>;

export type OneOnOneFormSavingStatus = "idle" | "saved" | "saving";

export type CompleteOneOnOneContext =
  OmitTypename<OneOnOneMeetingDatesFragment> &
    OmitTypename<OneOnOneSeriesUsersFragment> &
    OmitTypename<NextOneOnOneMeetingFragment> & {
      deleteMeetingItem: (id: string) => Promise<void>;
      handleCompleteCheck: () => Promise<boolean | undefined>;
      meetingId: string;
      meetings: Meeting[];
      refetchMeeting: () => Promise<unknown>;
      repeatFrequency: OneOnOneRepeatFrequency;
      savingStatus: OneOnOneFormSavingStatus;
      setShowTemplateView: (val: boolean) => void;
      startPolling: () => void;
      stopPolling: () => void;
    };

export const [useCompleteOneOnOneContext, CompleteOneOnOneProvider] =
  createNonNullableCtx<CompleteOneOnOneContext>();
