import {
  MetricUnit,
  TimelineEventFragmentFragment,
  useGetAllTagsQuery,
} from "@graphql";
import { capitalize, get } from "lodash";
import { useState } from "react";
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import { getLinkByType, getPathByType } from "route-configs";
import { Avatar } from "common/avatar";
import { CommentForm } from "common/form/CommentForm/CommentForm";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { ProgressUpdateButton } from "common/goal/ProgressUpdateButton/ProgressUpdateButton";
import { ProgressUpdatePanelValues } from "common/goal/ProgressUpdatePanel/ProgressUpdatePanel";
import { IntegrationLogo } from "common/integration/IntegrationLogo/IntegrationLogo";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { Clickable } from "common/navigation/Clickable";
import { Confirm } from "common/overlay/Confirm/Confirm";
import { objectTypes } from "constants/objects";
import { roles } from "constants/roles";
import { useCommitActions } from "hooks/useCommitActions/useCommitActions";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { logToSentry } from "utils/tracker";
import defaultGravatar from "../../../../public/images/default_user.png";
import { legacyEventTypes } from "../constants";
import { eventFormatters } from "../formatters";
import { Comment } from "./Comment/Comment";
import { useAddComment } from "./hooks/useAddComment/useAddComment";
import { useDeleteEvent } from "./hooks/useDeleteEvent/useDeleteEvent";
import { messages } from "./TimelineEvent.messages";

const integrations = ["JIRA", "SLACK", "ZAPIER", "ASANA", "GSHEETS"];

type Props = {
  entityId: string;
  entityType: string;
  event: TimelineEventFragmentFragment;
  fetchTimeline: () => void;
  filter?: string;
  showTargetNames?: boolean;
};

export const TimelineEvent = ({
  entityId,
  entityType,
  event,
  fetchTimeline,
  filter,
  showTargetNames,
}: Props) => {
  const company = useCompany();
  const addComment = useAddComment();
  const { updateCommit } = useCommitActions();
  const currentUser = useCurrentUser();
  const deleteEvent = useDeleteEvent();
  const inTeams = useInTeams();
  const intl = useIntl();

  const [details, setDetails] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);

  const { data: tagsData } = useGetAllTagsQuery();

  const tags = mapEdges(tagsData?.allTags.edges);
  const { comments, user } = event;
  const replies = mapEdges(comments?.edges);
  const actor_name = event.extra?.actor_name;

  const getUserUrl = (id?: string) => getPathByType(objectTypes.user, id);

  const removeComment = (eventData: unknown) => {
    deleteEvent(eventData).then(fetchTimeline);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  const addReply = (values, actions) => {
    return addComment({
      content: values.content,
      entityId,
      entityType,
      eventId: event.id,
      filter,
    })
      .then(() => {
        actions.setSubmitting(false);
        setShowReplyForm(false);
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  };

  const onEditCommit = (
    commitId: string,
    values: ProgressUpdatePanelValues
  ) => {
    return updateCommit({
      description: values.description,
      id: commitId,
      status: values.status,
      value: values.value,
    });
  };

  const type = legacyEventTypes[event.eventType] || event.eventType;
  const formatter = eventFormatters[type];
  const isCommit = get(event, "eventType") === "COMMIT_CREATED";
  const isComment = get(event, "eventType") === "COMMENT_CREATED";
  const isAuthor = user && user.id === currentUser?.id;
  const isCurrentUserSuperAdmin = currentUser?.role === roles.superAdmin;
  const canDelete =
    (isComment || isCommit) && (isAuthor || isCurrentUserSuperAdmin);

  // TODO: Deprecate use of getString in favour of components
  let message;
  let comment;
  if (formatter) {
    message =
      "component" in formatter ? (
        <formatter.component event={event} showTargetNames={showTargetNames} />
      ) : (
        formatter.getString(event, { company, tags })
      );
    comment = formatter.getComment(event, details);
  }

  if (!formatter || !message) {
    // This filter out any event without formatters, causing the pagination to act strange at times
    logToSentry(`Missing event formatter`, {
      event,
      eventType: type,
    });
    return null;
  }
  const update = (
    <>
      <div className="flex-auto justify-between">
        {integrations.includes(actor_name) ? (
          <div className="inline-block font-semibold">
            {capitalize(actor_name)} Integration
          </div>
        ) : (
          <Anchor href={inTeams ? undefined : getUserUrl(user?.id)}>
            {user ? user.fullName : intl.formatMessage(messages.unknownUser)}
          </Anchor>
        )}
        &nbsp;
        {message}
      </div>
      {comment}
    </>
  );

  const { commit } = event;

  return (
    <div className="flex py-3 text-slate-800">
      <div className="w-8">
        {integrations.includes(actor_name) ? (
          <IntegrationLogo application={actor_name} size="large" />
        ) : (
          <Avatar
            href={
              inTeams || !user
                ? undefined
                : getLinkByType(objectTypes.user, user.id)
            }
            url={user ? user.avatar : defaultGravatar.src}
          />
        )}
      </div>
      <div className="flex-grow pl-2">
        {update}
        <div className="flex items-center space-x-2 text-slate-500">
          <div className="whitespace-nowrap text-sm font-normal text-slate-500">
            <FormattedDate value={event.eventDate} />
          </div>
          <div className="flex items-center space-x-2">
            {isCommit && (
              <div
                className="ml-2 cursor-pointer text-sm font-semibold hover:text-blue-500"
                data-cy="timelineCommitDetails"
                onClick={() => setDetails((state) => !state)}
              >
                {!details && (
                  <FormattedMessage
                    defaultMessage="Details"
                    id="timeline:event:details"
                  />
                )}
                {details && (
                  <FormattedMessage
                    defaultMessage="Hide details"
                    id="timeline:event:hideDetails"
                  />
                )}
              </div>
            )}
            {(isCommit || isComment) && (
              <div
                className="ml-2 cursor-pointer text-sm font-semibold hover:text-blue-500"
                data-cy="timelineCommentReply"
                onClick={() => {
                  setShowReplyForm(true);
                  setShowReplies(true);
                }}
              >
                <FormattedMessage
                  defaultMessage="Reply"
                  id="timeline:event:reply"
                />
              </div>
            )}
            {canDelete && (
              <div className="ml-2 cursor-pointer text-sm font-semibold hover:text-blue-500">
                <Confirm
                  body={
                    <div className="mt-4 flex flex-col">
                      <FormattedMessage
                        defaultMessage="Are you sure you want to delete this? This cannot be undone."
                        id="global:deleteUpdate"
                      />
                      <div className="pointer-events-none mt-3 rounded-lg border border-solid p-3">
                        {update}
                      </div>
                    </div>
                  }
                  button={
                    <span
                      data-testid={
                        isCommit
                          ? "timelineDeleteUpdate"
                          : "timelineDeleteComment"
                      }
                    >
                      <FormattedMessage
                        defaultMessage="Delete"
                        id="global:delete"
                      />
                    </span>
                  }
                  confirmText={intl.formatMessage({
                    defaultMessage: "Confirm",
                    id: "global:confirm",
                  })}
                  onConfirm={() => removeComment(event)}
                  size="sm"
                  title={intl.formatMessage(
                    isCommit ? messages.deleteUpdate : messages.deleteComment
                  )}
                />
              </div>
            )}
          </div>
          {isSet(commit) && (
            <ProgressUpdateButton
              button={
                <Clickable className="w-full">
                  <FormattedMessage
                    defaultMessage="Edit"
                    id="timeline:event:edit"
                  />
                </Clickable>
              }
              buttonClass="font-semibold text-sm ml-2 hover:text-blue-500 cursor-pointer"
              initialValues={{
                description: commit.description ?? "",
                status: entityType === "kpi" ? undefined : commit.status,
                value: commit.valueBefore + commit.delta,
              }}
              metricUnit={
                (commit.kpi?.metricUnit || commit.keyResult?.metricUnit) ??
                MetricUnit.Numerical
              }
              onSubmit={(values) => onEditCommit(commit.id, values)}
              type="edit"
            />
          )}
        </div>
        {replies.length > 0 && !showReplies && (
          <Anchor
            className="block px-0 py-1 font-semibold"
            data-cy="timelineCommentShowReplies"
            onClick={() => setShowReplies(true)}
            text={
              <FormattedPlural
                one={
                  <FormattedMessage
                    defaultMessage="Show reply"
                    id="timeline:event:reply:button"
                  />
                }
                other={
                  <FormattedMessage
                    defaultMessage="Show {count} replies"
                    id="timeline:event:replies:button"
                    values={{ count: replies.length }}
                  />
                }
                value={replies.length}
              />
            }
          />
        )}
        {replies.length > 0 && showReplies && (
          <Anchor
            className="block px-0 py-1 font-semibold"
            data-cy="timelineCommentHideReplies"
            onClick={() => {
              setShowReplies(false);
              setShowReplyForm(false);
            }}
            text={
              <FormattedPlural
                one={
                  <FormattedMessage
                    defaultMessage="Hide reply"
                    id="timeline:event:reply:hide"
                  />
                }
                other={
                  <FormattedMessage
                    defaultMessage="Hide replies"
                    id="timeline:event:replies:hide"
                  />
                }
                value={replies.length}
              />
            }
          />
        )}
        {showReplies && (
          <div>
            {replies.map((reply) => (
              <Comment
                key={reply.id}
                data={reply}
                fetchTimeline={fetchTimeline}
                getUserUrl={getUserUrl}
              />
            ))}
            {showReplyForm && (
              <CommentForm
                clearOnSubmit
                component={(props) => (
                  <>
                    <FormTextArea
                      {...props}
                      autoFocus
                      onCancel={() => {
                        setShowReplies(true);
                        setShowReplyForm(false);
                      }}
                      showActionsButtons
                    />
                  </>
                )}
                onSubmit={addReply}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
