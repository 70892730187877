import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const PanelBody = ({ children, className }: Props): JSX.Element => (
  <div className={twClass("flex flex-col gap-2 p-5", className)}>
    {children}
  </div>
);
