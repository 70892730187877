import { useGetResultTimelineQuery } from "@graphql";
import { TimelineBag } from "common/event/TimelineContext/TimelineContext";
import { useGoalTimelineFilter } from "hooks/useGoalTimelineFilter/useGoalTimelineFilter";

export const useResultTimeline = (resultId: string) => {
  const { filterOptions, setTimelineFilter, timelineFilter } =
    useGoalTimelineFilter();
  const { data: timelineData, fetchMore } = useGetResultTimelineQuery({
    variables: {
      eventType: timelineFilter.value,
      id: resultId,
    },
  });

  const timelineLoading = !timelineData;
  const timelineContext: TimelineBag = {
    fetchMore,
    filterOptions,
    setTimelineFilter,
    timelineData: timelineData?.result?.events,
    timelineFilter,
    timelineLoading,
    updateTimeline: () => {},
  };
  return { timelineContext };
};
