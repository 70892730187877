import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";

type Props = {
  close: () => void;
  onConfirmAction: () => void;
  open: boolean;
};

export const ConfirmEndOneOnOneMeetingModal = ({
  open,
  close,
  onConfirmAction,
}: Props): JSX.Element => {
  const { isSubmitting } = useFormikContext();
  return (
    <Show when={open}>
      <ConfirmationModal
        buttonDisabled={isSubmitting}
        action={onConfirmAction}
        close={close}
        confirmText={
          <FormattedMessage defaultMessage="Confirm" id="global:confirm" />
        }
        title={
          <FormattedMessage
            defaultMessage="Are you sure you want to end this 1:1 meeting?"
            id="epx5no"
          />
        }
        titleClassName="col-span-4"
        titleWrapperClassName="grid-cols-5"
      >
        <FormattedMessage
          defaultMessage={
            "By ending this meeting you will schedule the next meeting " +
            "and will no longer be able to edit the listed items (except the notes) for this meeting."
          }
          id="B0bY0F"
        />
      </ConfirmationModal>
    </Show>
  );
};
