import { isSet } from "lodash";
import { StackedAvatar } from "common/avatar";
import { useSharedOwners } from "common/goal/GoalOwners/GoalOwners.utils";
import { Icon } from "common/icons";
import { ObjectiveSelectType } from "./ObjectiveSelect";

type Props = {
  objective: ObjectiveSelectType;
  size?: "lg" | "base";
};

export const ObjectiveSelectItem = ({ objective, size = "base" }: Props) => {
  const sharedOwners = useSharedOwners(
    objective.isCompanyGoal,
    objective.groups
  );
  return (
    <div className="space-x-2 flex items-center">
      <StackedAvatar
        maxCount={2}
        size="medium"
        urls={sharedOwners.map((avatar) => avatar.avatar)}
      />
      <div className="flex grow flex-col truncate">
        <span className="truncate text-left">{objective.name}</span>
        {size === "lg" && (
          <span className="flex gap-4 text-sm text-slate-500">
            {isSet(objective.lead) && (
              <span className="flex gap-2">
                <Icon name="person" size="xl" />
                {objective.lead.fullName}
              </span>
            )}
            <span className="flex gap-2">
              <Icon name="calendar_today" size="xl" />
              <span>{objective.timeframe.name}</span>
            </span>
          </span>
        )}
      </div>
    </div>
  );
};
