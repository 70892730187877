import dayjs from "dayjs";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { DatePicker } from "common/inputs/DatePicker/DatePicker";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { DropdownContext } from "legacy/components/Dropdown/DropdownContext";

type DateRange = "any" | "future";

interface Props {
  children: ReactElement;
  disabled?: boolean;
  onChange?: (date: string | null) => void;
  range?: DateRange;
  value: string | null;
}

/**
 * ! this doesn't work when put inside our Dropdown component. it can only be used with legacy {@link Dropdown} !
 */
export const DatePickerDropdown = ({
  children,
  onChange,
  value,
  range = "any",
  disabled,
}: Props): JSX.Element => {
  const date = value ? dayjs(value).toDate() : dayjs().toDate();

  const forwardOnChangeAndClose =
    (context: DropdownContext) =>
    (dueDate: Date): void => {
      const dateOnly = dueDate.setUTCHours(0, 0, 0, 0);
      const isoString = dayjs(dateOnly).toISOString();
      onChange?.(isoString);
      context.close();
    };

  const clearAndClose = (context: DropdownContext) => () => {
    onChange?.(null);
    context.close();
  };

  return (
    <Dropdown lazy button={children} disabled={disabled}>
      {(context) => (
        <div>
          <DatePicker
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ inline: true; minDate: Date; onChange: (da... Remove this comment to see the full error message
            date={date}
            onChange={forwardOnChangeAndClose(context)}
            prevMonthButtonDisabled={range === "future"}
          />
          <div className="flex items-center border-t p-1 pl-5 border-slate-300">
            <TextButton
              data-cy="nfFEf2xdSzOcVgTDGpP6m"
              className="text-blue-500 hover:text-blue-700"
              onClick={clearAndClose(context)}
              size="small"
            >
              <FormattedMessage defaultMessage="Remove date" id="//AV6U" />
            </TextButton>
          </div>
        </div>
      )}
    </Dropdown>
  );
};
