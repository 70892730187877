import { PureQueryOptions } from "@apollo/client";
import {
  CommitStatus,
  GoalUpdateCycle,
  namedOperations,
  PerdooApiKpiGoalOperatorChoices,
  useAddKpiMutation,
} from "@graphql";
import { FormikHelpers } from "formik";
import { isNil, pick } from "lodash";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { CategorySelectItem } from "common/inputs/CategorySelect/CategorySelect";
import { KpiForm, KpiFormValues } from "common/kpi/KpiForm/KpiForm";
import { Modal } from "common/overlay/Modal/Modal";
import { MetricUnits } from "constants/metric";
import { objectTypes } from "constants/objects";
import {
  ADD_KPI,
  CHANGE_DEFAULT_GOAL_UPDATE_CYCLE,
  OPEN_ADD_MODAL,
} from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useOnboardingSteps } from "hooks/useOnboardingSteps/useOnboardingSteps";
import { setFormikGqlErrors } from "utils/forms";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

const DEFAULT_KPI_UPDATE_CYCLE = GoalUpdateCycle.Monthly;

const messages = defineMessages({
  addKpi: {
    defaultMessage: "Add KPI",
    id: "kpi:add",
  },
  addKpiError: {
    defaultMessage: "Error adding KPI",
    id: "kpi:add:error",
  },
  kpiAdded: {
    defaultMessage: "KPI created!",
    id: "kpi:added",
  },
});

type Props = {
  afterAdd?: (kpiId: string) => Promise<unknown>;
  entityId?: string;
  leadId?: string;
  onRequestClosed: () => void;
  open: boolean;
  refetchQueries?: (string | PureQueryOptions)[];
  skipRedirectToSummary?: boolean;
  source?: string;
  strategicPillar?: string;
};

export const AddKpiModal = ({
  afterAdd,
  entityId,
  leadId,
  onRequestClosed,
  open,
  refetchQueries = [],
  skipRedirectToSummary = false,
  source,
  strategicPillar,
}: Props): JSX.Element => {
  const company = useCompany();
  const [addAnother, setAddAnother] = useState(false);
  const user = useCurrentUser();
  const [addKpi] = useAddKpiMutation({
    refetchQueries: [namedOperations.Query.getKpiSelectKpis, ...refetchQueries],
  });
  const { refetchSteps } = useOnboardingSteps();
  const { goToKpi } = useModalRouter();
  const intl = useIntl();
  const sessionUser = useCurrentUser();

  useEffect(() => {
    if (open) {
      track(OPEN_ADD_MODAL, { goalType: "kpi" });
    }
  }, [open]);

  const onSubmit = async (
    values: KpiFormValues & { groups: string[]; isCompanyGoal: boolean },
    actions: FormikHelpers<KpiFormValues>,
    selectedSampleKPI?: CategorySelectItem
  ) => {
    const input = {
      ...pick(values, [
        "currentValue",
        "description",
        "goalOperator",
        "goalThreshold",
        "groups",
        "id",
        "isCompanyGoal",
        "metricUnit",
        "name",
        "private",
        "strategicPillar",
        "visibleTo",
      ]),
      goalUpdateCycle: values.goalUpdateCycle?.toString(),
      lead: values.lead,
      tags: values.tags,
    };

    try {
      const response = await addKpi({
        variables: { input },
      });

      const kpiId = response.data?.upsertKpi?.kpi?.id;
      if (!isNil(afterAdd) && !isNil(kpiId)) await afterAdd(kpiId);
      const { hasError } = handleErrors(
        response,
        response.data?.upsertKpi?.errors
      );

      actions.setSubmitting(false);
      if (hasError) return;
      
      const createdFromSample =
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        selectedSampleKPI?.name === values.name ?? false;

      track(ADD_KPI, {
        from_example: createdFromSample,
        goalSet: !isNil(input.goalThreshold),
        multiple_owners_enabled: values.groups.length > 1,
        owner_count: values.groups.length,
        private: values.private,
        scope:
          company.id && values.groups.includes(company.id)
            ? objectTypes.company
            : objectTypes.group,
        status: CommitStatus.NoStatus,
        uiElementId: source,
      });

      if (DEFAULT_KPI_UPDATE_CYCLE !== values.goalUpdateCycle) {
        track(CHANGE_DEFAULT_GOAL_UPDATE_CYCLE, {
          frequency: values.goalUpdateCycle,
          kpiId: values.id,
          userId: user?.id,
        });
      }

      refetchSteps();

      if (isNil(afterAdd)) toast.success(intl.formatMessage(messages.kpiAdded));

      if (addAnother) {
        actions.resetForm();
        setAddAnother(false);
        track(OPEN_ADD_MODAL, { goalType: "kpi" });
      } else {
        onRequestClosed();

        const kpi = response.data?.upsertKpi?.kpi;
        if (kpi && !skipRedirectToSummary) {
          goToKpi({ kpiId: kpi.id });
        }
      }
    } catch (e) {
      toast.failure(intl.formatMessage(messages.addKpiError));
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage(messages.addKpi)}
    >
      <KpiForm
        initialValues={{
          currentValue: 0,
          goalOperator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
          goalThreshold: 0,
          goalUpdateCycle: DEFAULT_KPI_UPDATE_CYCLE,
          groups: entityId ?? "",
          lead: leadId || sessionUser?.id,
          metricUnit: MetricUnits.NUMERICAL,
          name: "",
          private: false,
          strategicPillar: strategicPillar || "",
        }}
        onSubmit={onSubmit}
        setAddAnother={() => setAddAnother(true)}
      />
    </Modal>
  );
};
