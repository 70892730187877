import { OneOnOneMeetingItemType } from "@graphql";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { Dropdown } from "common/overlay/Dropdown";
import { DUE_DATE_ADDED_TO_ACTION_ITEM } from "constants/tracking";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { useToggle } from "hooks/useToggle/useToggle";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import nonRecurringIcon from "../../../../../../public/images/repeat-off.svg";
import { OneOnOneDueDateDropdown } from "../OneOnOneDueDateDropdown/OneOnOneDueDateDropdown";
import { OneOnOneMeetingItem } from "../OneOnOneMeetingItemsField";

interface Props {
  handleDateChange: (meetingItemId: string, date: string | null) => void;
  handleRemoveItem: () => void;
  handleToggleIsRecurring: () => void;
  loading?: boolean;
  meetingItem: OneOnOneMeetingItem;
}

export const OneOnOneMeetingItemActions = ({
  handleDateChange,
  handleRemoveItem,
  handleToggleIsRecurring,
  loading,
  meetingItem,
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  useOutsideAlerter(ref, () => setShowOverlay(false));

  const [showOverlay, toggleOverlay, setShowOverlay] = useToggle(false);

  const dueDateHandler =
    (closeDropdown: () => void) => (date: string | null) => {
      track(DUE_DATE_ADDED_TO_ACTION_ITEM);
      handleDateChange(meetingItem.id, date);
      setShowOverlay(false);
      closeDropdown();
    };

  return (
    <div
      ref={ref}
      className={twClass(
        "relative flex items-center",
        "mr-2 mt-1 space-x-1 p-1",
        "invisible group-hover/close:visible",
        "rounded-md border border-solid bg-white border-slate-300",
        { visible: showOverlay }
      )}
    >
      <Dropdown data-testid="meetingItemMenu">
        {({ close }) => (
          <>
            <Dropdown.Trigger>
              <IconButton
                className={twClass("text-slate-800", {
                  "cursor-wait !text-slate-800 hover:!text-slate-800": loading,
                })}
                data-cy="meetingItemMenuTrigger"
                disabled={loading}
                iconType="outlined"
                name="more_vert"
                onClick={toggleOverlay}
                size="xl"
              />
            </Dropdown.Trigger>
            <Dropdown.Content
              className="space-y-2 visible"
              placement="bottom-right"
            >
              <div className="border-slate-300">
                <Show
                  when={meetingItem.type === OneOnOneMeetingItemType.ActionItem}
                >
                  <Dropdown.Item
                    className="px-1 hover:bg-blue-500"
                    data-cy="meetingItemActionDueDate"
                    onClick={toggleOverlay}
                  >
                    <OneOnOneDueDateDropdown
                      handleDateChange={dueDateHandler(close)}
                      placement="bottom-right"
                      selectedDate={meetingItem.dueDate}
                    >
                      <div className="flex w-full items-center space-x-1 text-slate-800 hover:text-white ">
                        <Icon
                          data-cy="dueDateIcon"
                          name="timer"
                          size="lg"
                          type="outlined"
                        />
                        <span>
                          <FormattedMessage
                            defaultMessage="Due date"
                            id="l3AfOI"
                          />
                        </span>
                      </div>
                    </OneOnOneDueDateDropdown>
                  </Dropdown.Item>
                </Show>
                <Show
                  when={
                    meetingItem.type === OneOnOneMeetingItemType.TalkingPoint
                  }
                >
                  <Dropdown.Item
                    className="flex items-center space-x-1 px-1 hover:text-white hover:bg-blue-500"
                    data-cy="meetingItemActionRecurring"
                    onClick={handleToggleIsRecurring}
                  >
                    <Show
                      fallback={
                        <>
                          <Icon
                            data-cy="recurringIcon"
                            name="repeat"
                            size="lg"
                          />
                          <span>
                            <FormattedMessage
                              defaultMessage="Make recurring"
                              id="Wpp9ax"
                            />
                          </span>
                        </>
                      }
                      when={meetingItem.isRecurring}
                    >
                      <img
                        alt="Non-recurring icon"
                        height={18.75}
                        src={nonRecurringIcon.src}
                        width={18.75}
                      />
                      <span>
                        <FormattedMessage
                          defaultMessage="Make non-recurring"
                          id="YP2m9B"
                        />
                      </span>
                    </Show>
                  </Dropdown.Item>
                </Show>
                <Dropdown.Item
                  className="flex items-center space-x-1 px-1 text-red-600 hover:bg-red-100"
                  data-cy="meetingItemActionDelete"
                  onClick={handleRemoveItem}
                >
                  <Icon
                    className="text-red-600 hover:text-red-600"
                    data-cy="deleteIcon"
                    name="delete"
                    size="lg"
                  />
                  <span>
                    <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
                  </span>
                </Dropdown.Item>
              </div>
            </Dropdown.Content>
          </>
        )}
      </Dropdown>
    </div>
  );
};
