import { namedOperations } from "@graphql";
import { FormikValues } from "formik";
import { defineMessages, useIntl } from "react-intl";
import { Modal } from "common/overlay/Modal/Modal";
import { ADD_STRATEGIC_PILLAR } from "constants/tracking";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useOnboardingSteps } from "hooks/useOnboardingSteps/useOnboardingSteps";
import { useUpsertStrategicPillar } from "hooks/useUpsertStrategicPillar/useUpsertStrategicPillar";
import { track } from "utils/tracker";
import { StrategicPillarForm } from "../StrategicPillarForm/StrategicPillarForm";

const messages = defineMessages({
  addStrategicPillar: {
    defaultMessage: "Add Strategic Pillar",
    id: "strategicpillar:add",
  },
  addStrategicPillarError: {
    defaultMessage: "Error adding Strategic Pillar",
    id: "strategicpillar:add:error",
  },
  strategicPillarAdded: {
    defaultMessage: "Added Strategic Pillar!",
    id: "strategicpillar:added",
  },
});

const refetchQueries = [
  namedOperations.Query.getTimelineStrategicPillars,
  namedOperations.Query.getRoadmap,
];

type Props = {
  open: boolean;
  source?: string;
};

export const AddStrategicPillar = ({
  open = false,
  source,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { close } = useModalRouter();
  const { upsertStrategicPillar } = useUpsertStrategicPillar(refetchQueries);
  const { refetchSteps } = useOnboardingSteps();

  const onSubmit = async (values: FormikValues) => {
    await upsertStrategicPillar!({
      description: values.description,
      name: values.name,
    });
    track(ADD_STRATEGIC_PILLAR, { uiElementId: source });
    refetchSteps();
    close();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={close}
      title={intl.formatMessage(messages.addStrategicPillar)}
    >
      <StrategicPillarForm
        initialValues={{
          description: "",
          name: "",
        }}
        onCancel={close}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
