import React from "react";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { useCompanySettings } from "hooks/useCompany/useCompany";
import { InlineOwnerMultiSelect } from "./InlineOwnerMultiSelect";
import { InlineOwnerSingleSelect } from "./InlineOwnerSingleSelect";

type Value = string[];

type Props = {
  "data-cy"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => void;
  value: Value;
};

export const InlineOwnerSelect = ({
  "data-cy": dataCy,
  onChange,
  value,
  disabled,
}: Props) => {
  const { show: sharedGoals } = useFeatureAccess(Feature.sharedGoals);
  if (value.length <= 1 && !sharedGoals) {
    return (
      <InlineOwnerSingleSelect
        data-cy={dataCy}
        onChange={(val) => onChange?.(val ? [val] : [])}
        value={value[0] ?? null}
        disabled={disabled}
      />
    );
  }

  return (
    <InlineOwnerMultiSelect
      data-cy={dataCy}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
};
