/**
 * Improved type-checked version of `use-browser-language`.
 * Returns an RFC short language tag (e.g. "en", "fr", "es-ES").
 * According to the RFC described grammar, the string starts with a 2 letter ISO 639 code.
 *
 * @see https://www.ietf.org/rfc/rfc5646.txt
 */
export const useBrowserLanguage = (): string | undefined => {
  if (typeof window === "undefined") return undefined;
  const browserLang = navigator.languages[0] ?? navigator.language;

  // browser language is in extended form (e.g. "en-US", "es-ES"), so we're returning just the first part
  return browserLang;
};
