import { ReactNode } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { routes } from "route-configs";
import { AnchorNextTrack } from "common/navigation";
import { MODAL_TYPES } from "constants/index";
import { hideModal } from "legacy/actions/actions";
import { messages } from "./IntegrationField.messages";

export const NoIntegrationMessage = (): JSX.Element => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const closeModal = () => {
    dispatch(hideModal(MODAL_TYPES.ADD_RESULT));
  };

  return (
    <div>
      <div>{intl.formatMessage(messages.emptyStateMessage)}</div>
      <div>
        {intl.formatMessage(messages.emptyStateCta, {
          a: (children: ReactNode) => (
            <AnchorNextTrack
              data-cy="quNpwGYZ9an-jLa4Xw5Jd"
              href={routes.settings.company.integrations.jira}
              onClick={closeModal}
            >
              {children}
            </AnchorNextTrack>
          ),
        })}
      </div>
    </div>
  );
};
