export const modalActionTypes = {
  HIDE_MODAL: "HIDE_MODAL",
  SHOW_MODAL: "SHOW_MODAL",
};

export const SEARCH_GLOBAL_REQUEST = "SEARCH_GLOBAL_REQUEST";
export const SEARCH_GLOBAL_SUCCESS = "SEARCH_GLOBAL_SUCCESS";
export const SEARCH_GLOBAL_FAILURE = "SEARCH_GLOBAL_FAILURE";

export const ADD_ANALYTICS_EVENT_REQUEST = "ADD_ANALYTICS_EVENT_REQUEST";
export const ADD_ANALYTICS_EVENT_SUCCESS = "ADD_ANALYTICS_EVENT_SUCCESS";
export const ADD_ANALYTICS_EVENT_FAILURE = "ADD_ANALYTICS_EVENT_FAILURE";

export const FILTER_UPDATE = "FILTER_UPDATE";
export const FILTER_COLUMN_ADD = "FILTER_COLUMN_ADD";
export const FILTER_REMOVE = "FILTER_REMOVE";
export const FILTER_CLEAN_UP = "FILTER_CLEAN_UP";
export const SET_EXPLORE_VIEW = "SET_EXPLORE_VIEW";
export const SET_EXPLORE_VIEW_ATTR = "SET_EXPLORE_VIEW_ATTR";
export const ROLLBACK_FILTER = "ROLLBACK_FILTER";
