import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { isNil } from "lodash";
import React from "react";
import { defineMessages, FormattedDate, useIntl } from "react-intl";
import { Button,CancelButton, SaveButton } from "common/buttons";
import { Show } from "common/controlFlow";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { usePerformanceReviewCompleteContext } from "common/performanceReview/context/performanceReviewCompleteContext";
import { usePerformanceReviewFormSubmission } from "common/performanceReview/hooks/usePerformanceReviewFormSubmission";
import { PerformanceReviewCompleteFormValues } from "common/performanceReview/modals/PerformanceReviewCompleteFormModal";
import {
  ADD_PERFORMANCE_REVIEW,
  PERFORMANCE_REVIEW_COMPLETED,
} from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

const messages = defineMessages({
  complete: {
    defaultMessage: "Complete",
    id: "performanceReview:footer:complete",
  },
  savedFooterMsg: {
    defaultMessage: "Form saved on {date}",
    id: "performanceReview:footer:savedDate",
  },
  successRepeatToast: {
    defaultMessage: "Successfully scheduled next Performance Review!",
    id: "performanceReview:footer:complete:toast:repeat",
  },
  successToast: {
    defaultMessage: "Performance Review completed",
    id: "performanceReview:footer:complete:toast",
  },
});

type Props = {
  lastEditedDate?: string;
  toggleConfirm: () => void;
};

export const PerformanceReviewCompleteFormFooter = ({
  toggleConfirm,
  lastEditedDate,
}: Props): JSX.Element => {
  const { isSubmitting, submitForm, values } =
    useFormikContext<PerformanceReviewCompleteFormValues>();
  const intl = useIntl();
  const me = useCurrentUser();
  const { handleCompletePerformanceReview } =
    usePerformanceReviewFormSubmission();

  const review = usePerformanceReviewCompleteContext();
  const isManager = review.reviewer?.user?.id === me?.id;

  const today = dayjs();
  const isDueOrAfter = dayjs(review.dueDate).isSameOrBefore(today);
  const showCompleteButton = isManager && isDueOrAfter;

  const handleComplete = async () => {
    const { hasError, repeatHasError, nextPerformanceReview } =
      await handleCompletePerformanceReview({ formValues: values, review });
    if (!hasError) {
      toast.success(intl.formatMessage(messages.successToast));
      track(PERFORMANCE_REVIEW_COMPLETED, {
        date: dayjs(),
        performance_review_id: review.id,
      });
    }
    if (repeatHasError === undefined) return;
    if (!repeatHasError) {
      toast.success(intl.formatMessage(messages.successRepeatToast));
      if (!isNil(nextPerformanceReview))
        track(ADD_PERFORMANCE_REVIEW, {
          first_review_date: nextPerformanceReview.dueDate,
          reviewee: nextPerformanceReview.reviewee?.user?.id,
          reviewee_frequency: nextPerformanceReview.repeatFrequency,
          user_id: nextPerformanceReview.reviewer?.user?.id,
        });
    }
  };
  return (
    <ModalFooter className="flex-row items-center justify-between pr-12">
      <div className="text-base font-normal text-slate-500">
        {lastEditedDate &&
          intl.formatMessage(messages.savedFooterMsg, {
            date: (
              <FormattedDate
                day="numeric"
                month="short"
                value={lastEditedDate}
                year="numeric"
              />
            ),
          })}
      </div>
      <div className="flex items-center space-x-2">
        <CancelButton
          data-cy="dJ91uensTmsOi_zzvpYO1"
          disabled={isSubmitting}
          onClick={toggleConfirm}
        />
        <SaveButton
          data-cy="savePerformanceReview"
          data-testid="submitForm"
          disabled={isSubmitting}
          onClick={submitForm}
        />
        <Show when={showCompleteButton}>
          <Button
            className="bg-green-500 hover:bg-green-600 active:bg-green-700"
            data-cy="completePerformanceReview"
            data-testid="completeForm"
            disabled={isSubmitting}
            icon="check_circle_outline"
            iconSize="lg"
            onClick={handleComplete}
          >
            {intl.formatMessage(messages.complete)}
          </Button>
        </Show>
      </div>
    </ModalFooter>
  );
};
