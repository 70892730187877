import { PerdooApiKeyResultTypeChoices } from "@graphql";
import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { Icon } from "common/icons";
import { useSelect } from "common/inputs/Select/useSelect";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { Clickable } from "common/navigation/Clickable";

type Value = PerdooApiKeyResultTypeChoices;

type Option = {
  "data-cy"?: string;
  icon: (selected: boolean) => ReactNode;
  id: Value;
  label: ReactNode;
};

const options: Option[] = [
  {
    icon: (selected: boolean) => (
      <Icon
        className={selected ? "text-white" : "text-blue-500"}
        name="trending_up"
        size="2xl"
      />
    ),
    id: PerdooApiKeyResultTypeChoices.KeyResult,
    label: (
      <FormattedMessage defaultMessage="Key Result" id="global:keyResult" />
    ),
  },
  {
    icon: (selected: boolean) => (
      <Icon
        className={selected ? "text-white" : "text-green-500"}
        name="playlist_add_check"
        size="2xl"
      />
    ),
    id: PerdooApiKeyResultTypeChoices.Initiative,
    label: (
      <FormattedMessage defaultMessage="Initiative" id="global:initiative" />
    ),
  },
];

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (value: Value, undo: () => void) => void;
  value: Value;
};

export const InlineResultTypeSelect = ({
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  onChange,
  value,
  disabled,
}: Props): JSX.Element | null => {
  const { toggleHelpCenter } = useHelpCenter();

  const defaultItem = options.find((option) => option.id === value);
  const { Select, selected, visibleOptions, handleSelect } = useSelect<Option>({
    defaultItem,
    options,
  });

  const handleChange = async (option: Option | null) => {
    if (!option) return;
    const previous = selected;
    handleSelect(option);
    await onChange?.(option.id, () => handleSelect(previous));
  };

  return (
    <Select.Root
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          <div className="flex items-center space-x-2">
            {selected?.icon(false)}
            <div>{selected?.label}</div>
          </div>
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="divide-y">
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <div className="flex items-center space-x-2">
                  {option.icon(selected?.id === option.id)}
                  <div>{option.label}</div>
                </div>
              </Select.Option>
            )}
          </For>
        </Select.Options>
        <Clickable
          data-cy="oeQcRQlOjHmRGEcq_pR-A"
          onClick={toggleHelpCenter}
          className="px-2.5 py-2 hover:bg-blue-100"
        >
          <FormattedMessage
            defaultMessage="What’s the difference?"
            id="GqKQOP"
          />
        </Clickable>
      </Select.Dropdown>
    </Select.Root>
  );
};
