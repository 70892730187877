import { isNil } from "lodash";
import React from "react";
import { twClass } from "utils/twClass";
import logo from "../../../../public/images/perdoo_logo_white_retina.png";

type Props = {
  isCollapsed?: boolean;
};

export const SidebarSkeleton = ({ isCollapsed }: Props): JSX.Element | null => {
  const links = [...Array(6).keys()];

  if (isNil(isCollapsed)) return null;

  return (
    <div
      className={twClass(
        "h-full",
        !isCollapsed && "flex-[0_0_250px]",
        isCollapsed && "flex-[0_0_80px]"
      )}
    >
      <div className="h-screen" id="SidebarWrapper">
        <div
          className={twClass(
            "text-white bg-slate-800 dark:text-white dark:bg-gray-800",
            "flex h-full flex-col justify-between gap-2",
            !isCollapsed && "w-[250px]",
            isCollapsed && "w-[80px]"
          )}
          id="Sidebar"
        >
          <div id="sidebarUpperSection">
            <div
              className={twClass(
                "flex h-14 items-center justify-between py-2 pl-5",
                {
                  "justify-center": isCollapsed,
                }
              )}
              id="CompanyHead"
            >
              {!isCollapsed && (
                <img alt="Perdoo logo" className="inline h-8 " src={logo.src} />
              )}
              <div className="mr-4 h-6 max-h-full w-6 animate-pulse rounded-full bg-slate-600" />
            </div>
            <div
              className={twClass("mt-4 flex animate-pulse flex-col gap-4", {
                "ml-4": !isCollapsed,
              })}
              id="SidebarLinks"
            >
              {links.map((key) => (
                <div
                  key={key}
                  className="my-1 flex h-6 items-center justify-center gap-4"
                >
                  <div className="h-5 w-5 rounded-full bg-slate-600" />
                  {!isCollapsed && (
                    <div className="grow">
                      <div className="h-3 w-28 rounded-full bg-slate-600" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            className={twClass("mb-4 ml-4 flex items-center space-x-1", {
              "justify-center": isCollapsed,
            })}
          >
            <div className="mr-4 h-5 max-h-full w-5 animate-pulse rounded-full bg-slate-600" />
            {!isCollapsed && (
              <div className="h-3 w-20 rounded-full bg-slate-600" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
