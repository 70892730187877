import { defineMessages } from "react-intl";

export const messages = defineMessages({
  deleteTitle: {
    defaultMessage: "Delete KPI",
    id: "kpi:delete:title",
  },
  growthBoardRemoveToast: {
    defaultMessage: "KPI removed from board",
    id: "growth-board:remove:toast",
  },
  unalignKpiToast: {
    defaultMessage: "KPI unaligned!",
    id: "kpi:delete:toast:success",
  },
  unalignTitle: {
    defaultMessage: "Unalign KPI",
    id: "kpi:unalign:title",
  },
});
