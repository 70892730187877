import { useIntl } from "react-intl";
import { getKpiData, KpiData } from "utils/getKpiData";

type Hook = {
  delta?: number;
  deltaColor?: string;
  deltaColorClass?: string;
  healthTooltip?: string;
  isHealthy?: boolean;
  positiveMetric?: boolean;
};

export const useKpiData = (kpi: KpiData, isDraft = false): Hook => {
  const intl = useIntl();
  const kpiData = getKpiData({ intl, isDraft, kpi });
  return kpiData;
};
