import { FieldInputProps, FormikState } from "formik";
import { get } from "lodash";
import React, { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import {
  components,
  OptionProps,
  SingleValueProps,
  ValueType,
} from "react-select";
import { Icon } from "common/icons";
import { Select } from "legacy/components/Select/Select";
import { OptionType as SelectOptionType } from "legacy/components/Select/Select.types";

type Props = { field: FieldInputProps<string>; form: FormikState<string> };

type OptionType = SelectOptionType & {
  icon: ReactNode;
};

const Option = ({ data, ...props }: OptionProps<OptionType, false>) => {
  return (
    <components.Option data={data} {...props}>
      <div className="flex items-center space-x-3">
        <div className="flex items-center">{data.icon}</div>
        <div>
          <div>{data.label}</div>
        </div>
      </div>
    </components.Option>
  );
};

const SingleValue = ({ data, ...props }: SingleValueProps<OptionType>) => {
  return (
    <components.SingleValue data={data} {...props}>
      <div className="flex items-center space-x-3">
        <div className="flex items-center">{data.icon}</div>
        <div>{data.label}</div>
      </div>
    </components.SingleValue>
  );
};

export const FormClosingNoteStatusSelect = ({
  field,
  form: { errors },
}: Props): JSX.Element => {
  const intl = useIntl();
  const options: OptionType[] = useMemo<OptionType[]>(
    () => [
      {
        icon: <Icon className="text-green-800" name="emoji_events" size="xl" />,
        label: intl.formatMessage({
          defaultMessage: "achieved",
          id: "z5hMDa",
        }),
        value: "ACHIEVED",
      },
      {
        icon: <Icon className="text-yellow-500" name="star_half" size="xl" />,
        label: intl.formatMessage({
          defaultMessage: "partially achieved",
          id: "Z7MLrJ",
        }),
        value: "PARTIALLY_ACHIEVED",
      },
      {
        icon: (
          <Icon
            className="text-red-500"
            name="call_missed_outgoing"
            size="xl"
            type="outlined"
          />
        ),
        label: intl.formatMessage({
          defaultMessage: "missed",
          id: "closingNoteStatusSelect:missed",
        }),
        value: "MISSED",
      },
      {
        icon: (
          <Icon
            className="text-blue-500"
            name="watch_later"
            size="xl"
            type="outlined"
          />
        ),
        label: intl.formatMessage({
          defaultMessage: "postponed",
          id: "closingNoteStatusSelect:postponed",
        }),
        value: "POSTPONED",
      },
      {
        icon: <Icon className="text-red-600" name="block" size="xl" />,
        label: intl.formatMessage({
          defaultMessage: "cancelled",
          id: "8XMLza",
        }),
        value: "CANCELLED",
      },
    ],
    [intl]
  );

  const forwardOnChange = (opt: ValueType<OptionType, false>) => {
    field.onChange({
      target: {
        name: field.name,
        value: opt?.value,
      },
    });
  };

  const errorText = get(errors, field.name) as string | undefined;
  const selectedOption = options.find((x) => x.value === field.value) ?? null;
  return (
    <Select
      components={{ Option, SingleValue }}
      errorText={errorText}
      isInForm
      isSearchable={false}
      onChange={forwardOnChange}
      options={options}
      value={selectedOption}
    />
  );
};
