import { removeFilter } from "legacy/components/filters/actions";
import {
  Filter,
  FilterItem,
  FiltersDispatch,
} from "legacy/components/filters/types";

export type HandleFilterRemoveProps = {
  activeFilter: Filter | null;
  afterFilterRemoved?: (() => void) | undefined;
  filter: FilterItem;
  filterName: string;
  filtersDispatch: FiltersDispatch;
};
export const handleFilterRemove = ({
  filter,
  filterName,
  filtersDispatch,
  activeFilter,
  afterFilterRemoved,
}: HandleFilterRemoveProps): void => {
  filtersDispatch(
    removeFilter({
      cleanUp: !activeFilter,
      filterName,
      id: filter.id,
    })
  );

  afterFilterRemoved?.();
};
