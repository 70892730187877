import deLocaleData from "i18n/locales/de.json";
import enLocaleData from "i18n/locales/en-us.json";
import esLocaleData from "i18n/locales/es.json";
import frLocaleData from "i18n/locales/fr.json";
import nlLocaleData from "i18n/locales/nl.json";
import plLocaleData from "i18n/locales/pl.json";
import ptLocaleData from "i18n/locales/pt.json";

export const intlMessages: Record<string, any> = {
  de: deLocaleData,
  en: enLocaleData,
  es: esLocaleData,
  fr: frLocaleData,
  nl: nlLocaleData,
  pl: plLocaleData,
  pt: ptLocaleData,
};
