import dayjs, { Dayjs } from "dayjs";
import { useCompany } from "hooks/useCompany/useCompany";

/**
 * Hook used to convert from browser datetime to configured datetime without
 * changing the time value.
 *
 * @example
 * Local timezone: Europe/Rome (GMT+2)
 * Configured timezone: UTC
 * Input: 2023-08-14T22:00:00Z (15 Aug 2023 at 12:00 AM in Europe/Rome)
 * Output: 2023-08-15T00:00:00Z (15 Aug 2023 at 12:00 AM in UTC)
 *
 * @deprecated
 * This is a temporary solution to the bigger problem of `PdDatePicker` using the
 * local timezone instead of a the configured one.
 * This ticket will solve the bigger problem: https://linear.app/perdoo/issue/ENG-4273/refactor-pddatepicker
 */

export const useLocalToConfiguredTimezone = () => {
  const { timezone } = useCompany();
  const toConfiguredTimezone = (localDateTime: string | Dayjs) =>
    replaceTimezone(timezone, localDateTime);
  return { toConfiguredTimezone };
};

export const replaceTimezone = (timezone: string, dateTime: string | Dayjs) =>
  dayjs(dateTime).tz(timezone, true).toISOString();
