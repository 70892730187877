import { CheckInFrequency, CompanyFeaturesFragment } from "@graphql";
import { mockPageInfo } from "mocks/common/pageInfo";
import { unmapEdges } from "utils/mapEdges";

export const mockCompanyFeatures: CompanyFeaturesFragment = {
  __typename: "company",
  autoCreateTimeframes: true,
  checkInsEnabled: true,
  flexibleObjectiveProgressDriver: true,
  kpisEnabled: true,
  kpiStartingMonth: 1,
  kudosEnabled: true,
  limitDraftObjectiveVisibility: true,
  onboardingEnabled: true,
  oneOnOnesEnabled: true,
  performanceReviewsEnabled: true,
  pillarsEnabled: true,
  privateOkrs: true,
  pulse: false,
  reflections: true,
  sharedGoals: true,
  stretchGoals: true,
  teamReflectionsEnabled: true,
};

/**
 * @deprecated use a factory from mocks/factories instead.
 */
export const mockCompany_deprecated = {
  __typename: "company" as const,
  accountStatus: "PAYING",
  ambassadors: {
    __typename: "userConnection" as const,
    edgeCount: 0,
    edges: [],
    pageInfo: mockPageInfo,
    totalCount: 0,
  },
  autoCreateTimeframes: true,
  avatar: "https://example.org/company.png",
  cadence: {
    __typename: "cadence" as const,
    id: "mock-cadence-quarterly",
  },
  canPatch: true,
  checkInCycles: {
    __typename: "CheckInCyclesConnection" as const,
    edges: [],
    pageInfo: mockPageInfo,
  },
  checkInDueWeekday: 1,
  checkInFrequency: CheckInFrequency.Weekly,
  checkInReminderDaysBeforeDue: 1,
  checkInReminderTime: "08:00:00",
  checkInReportTime: "08:00:00",
  checkInsEnabled: true,
  companySize: null,
  createdDate: "2016-11-08T10:50:15.901859+00:00",
  currentCycle: {
    __typename: "CycleType" as const,
    end: "",
    id: "",
    name: "",
    start: "",
  },
  customReportGraphsEnabled: true,
  daysLeftInTrial: 0,
  defaultInitArchivingTemplate: "",
  defaultObjDescriptionTemplate: "",
  defaultReflectionsTemplate: "",
  domains: ["perdoo.com"],
  domainSignUp: true,
  executives: {
    __typename: "userConnection" as const,
    edgeCount: 0,
    edges: [],
    pageInfo: mockPageInfo,
    totalCount: 0,
  },
  features: [
    "ENGAGEMENT_REPORT_2022",
    "PRIVATE_KPIS",
    "ROADMAP_FILTERS",
    "WEEKLY_REPORTS",
  ],
  featureTier: "SUPREME",
  flexibleObjectiveProgressDriver: true,
  freeUserLimit: 5,
  groups: {
    __typename: "groupConnection" as const,
    edgeCount: 0,
    edges: [],
    pageInfo: mockPageInfo,
    totalCount: 0,
  },
  id: "mock-company-001",
  initArchivingTemplate:
    "<p><strong>What was the impact of this Result on the OKR?</strong></p> <p><br></p> <p><strong>What did you learn from executing on this Result?</strong></p> <p><br></p>",
  integrations: {
    __typename: "integrationConnection" as const,
    edges: [],
    pageInfo: mockPageInfo,
  },
  isActive: true,
  kpiBoards: {
    __typename: "kpiBoardConnection",
    edgeCount: 0,
    edges: unmapEdges([], "kpiBoardEdge", "kpiBoardCursor"),
    pageInfo: mockPageInfo,
    totalCount: 0,
  },
  kpis: {
    __typename: "kpiConnection" as const,
    edges: unmapEdges(
      [
        {
          __typename: "kpi" as const,
          id: "mock-kpi-001",
          name: "Employee satisfaction",
        },
      ],
      "kpiEdge" as const,
      "kpiCurosr"
    ),
  },
  teamReflectionsEnabled: true,
  kpisEnabled: true,
  teamReportsGracePeriod: 5,
  kpiStartingMonth: 1,
  kudosEnabled: true,
  lastCheckInDate: "2022-02-09T23:59:59Z",
  limitDraftObjectiveVisibility: false,
  managedSubscription: false,
  mpq: 10,
  name: "Company Inc.",
  notificationsConfig: {
    check_in_reminder: {
      enabled: true,
      weekday: 0,
    },
    close_open: {
      enabled: true,
    },
    weekly_reports: {
      enabled: true,
    },
  },
  notificationsSettings: [
    "SLACK_WEEKLY_ROUNDUP",
    "CLOSE_OPEN",
    "SLACK_CHECK_IN",
    "SLACK_AT_MENTIONS",
    "EXECUTIVES_WEEKLY",
    "CHECK_IN_REMINDER",
    "PERFORMANCE_REVIEWS_REMINDERS",
  ],
  objDescriptionTemplate:
    "<p><strong>Why is it important?</strong></p> <p><br></p><p><strong>Why is it urgent?</strong></p> <p><br></p>",
  objectives: {
    __typename: "objectiveConnection" as const,
    achievedCount: 0,
    alignedCount: 0,
    averageProgress: 0,
    edgeCount: 0,
    edges: [],
    initiativeCount: 0,
    keyResultCount: 0,
    pageInfo: mockPageInfo,
    totalCount: 0,
  },
  onboardingEnabled: true,
  oneOnOnesEnabled: true,
  onlySendCheckInRemindersToLeads: false,
  owner: {
    __typename: "user" as const,
    id: "97586ec9-e5d2-4a08-bbda-89fc75ab8115",
  },
  performanceReviewsEnabled: true,
  pillarsEnabled: true,
  privateOkrs: true,
  pulse: true,
  reflections: true,
  reflectionsTemplate:
    "<p><strong>What did you work on last week?</strong></p><ul><li><br></li></ul><p><strong>What will you focus on this week?</strong></p><ul><li><br></li></ul><p><strong>Are you blocked by anything?</strong></p><ul><li><br></li></ul>",
  release: "STABLE",
  sharedGoals: true,
  slackChannel: null,
  ssoconfig: null,
  strategicPillars: {
    __typename: "strategicPillarConnection" as const,
    edges: unmapEdges(
      [
        {
          __typename: "strategicPillar" as const,
          id: "mock-sp-001",
          name: "Be a great place to work",
        },
        {
          __typename: "strategicPillar" as const,
          id: "mock-sp-002",
          name: "Provide the best mock data in the industry",
        },
      ],
      "strategicPillarEdge" as const
    ),
  },
  stretchGoals: false,
  tags: {
    __typename: "tagConnection" as const,
    edges: [],
    pageInfo: mockPageInfo,
  },
  timeframes: {
    __typename: "timeframeConnection" as const,
    edgeCount: 0,
    edges: [],
    pageInfo: mockPageInfo,
    totalCount: 0,
  },
  timelineEnabled: true,
  timezone: "Europe/Berlin",
  ultimateGoal: "Some ultimate goal",
  ultimateGoalDescription: "",
  users: {
    __typename: "userConnection" as const,
    edges: unmapEdges(
      [
        // sic: cannot import mockCurrentUser because of dependency cycle
        {
          __typename: "user" as const,
          avatar: "https://example.org/avatar01.png",
          fullName: "Adam Smith",
          id: "mock-current-user-001",
        },
        {
          __typename: "user" as const,
          avatar: null,
          fullName: "Clarice Robel",
          id: "mock-active-user-001",
        },
        {
          __typename: "user" as const,
          avatar: null,
          fullName: "Otto Graf",
          id: "mock-active-user-002",
        },
      ],
      "userEdge" as const,
      "userCursor"
    ),
  },
  viewOnlyLicenses: 1,
  vision: "",
};
