import { useField } from "formik";
import { isNull } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RadioButton } from "common/inputs/RadioGroup";
import { useAsanaFormContext } from "common/integration/AsanaFormLogic/AsanaFormLogic";
import { FormRadioGroup } from "../FormRadioGroup/FormRadioGroup";

type Props = {
  disabled?: boolean;
  name?: string;
};

export type AsanaProgressType = "project" | "task";

export const AsanaProgressTypeField = ({
  name = "asanaProgressType",
  disabled = false,
}: Props): JSX.Element => {
  const [field] = useField<AsanaProgressType>(name);
  const { project, onProgressTypeSelected } = useAsanaFormContext();
  return (
    <FormRadioGroup
      className="flex space-x-6"
      disabled={disabled || isNull(project)}
      onChange={(type) => onProgressTypeSelected?.(type as AsanaProgressType)}
      field={field}
    >
      <RadioButton value="project">
        <FormattedMessage
          defaultMessage="Overall Project Progress"
          id="asanaProgressType:project"
        />
      </RadioButton>
      <RadioButton value="task">
        <FormattedMessage
          defaultMessage="Single Task"
          id="asanaProgressType:task"
        />
      </RadioButton>
    </FormRadioGroup>
  );
};
