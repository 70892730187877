import React, { Component, ReactNode } from "react";
import styled from "styled-components";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { colorTheme } from "constants/colorTheme";
import { fontWeightRegular } from "constants/typography";
import { DropdownContext } from "legacy/components/Dropdown/DropdownContext";
import { twClass } from "utils/twClass";

type StyledListItemProps = {
  disabled?: boolean;
};

export const StyledListItem = styled.li<StyledListItemProps>`
  display: flex;
  min-height: 30px;
  font-weight: ${fontWeightRegular};
  padding: 2px 10px;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;

  i,
  svg {
    color: ${colorTheme.slate[800]};
    fill: ${colorTheme.slate[800]};
    margin-right: 8px;
  }
`;

const Container = styled(Anchor)`
  padding: 0 !important; /* bootstrap is adding .dropdown-menu>li>a{display:block;padding:3px 20px; */
  color: inherit;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

type Props = {
  children?: ReactNode;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  href?: string;
  keepOpen?: boolean;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  selected?: boolean;
};

export class ListItem extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(e: React.MouseEvent<HTMLLIElement>, close: () => void): void {
    const { onClick, keepOpen, disabled } = this.props;
    e.stopPropagation();
    if (!keepOpen) {
      close();
    }

    if (!disabled && onClick) {
      onClick(e);
    }
  }

  render(): JSX.Element {
    const {
      children,
      className,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      disabled,
      href,
      selected,
    } = this.props;
    return (
      <DropdownContext.Consumer>
        {({ close }) => (
          <StyledListItem
            className={twClass(
              {
                "cursor-pointer hover:bg-slate-300": !disabled,
              },
              className,
              // moving this after className so it has prio. after tailwind v3 upgrade, move above as !text-blue-500
              {
                "fill-current text-blue-500 hover:text-blue-500": selected,
              }
            )}
            data-cy={dataCy}
            data-testid={dataTestId}
            disabled={disabled}
            onClick={(e) => this.handleSelect(e, close)}
            role="button"
            tabIndex={0}
          >
            <Container disabled={disabled} href={href}>
              {children}
            </Container>
          </StyledListItem>
        )}
      </DropdownContext.Consumer>
    );
  }
}
