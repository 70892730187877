import { useJiraJqlSearchLazyQuery } from "@graphql";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  value: string;
};

export const JiraJqlPreview = ({ value }: Props): JSX.Element => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [jiraJqlSearch, { data }] = useJiraJqlSearchLazyQuery();
  const currentValue = data?.jiraJqlSearch?.totalCount;

  const debouncedJiraJqlSearch = useDebouncedCallback(async (jql: string) => {
    if (jql) {
      const response = await jiraJqlSearch({ variables: { jql } });
      const responseError = response.data?.jiraJqlSearch?.error;
      if (responseError) {
        setError(responseError);
      }
    }

    setLoading(false);
  }, 2000);

  useEffect(() => {
    setLoading(true);
    setError(null);
    debouncedJiraJqlSearch(value);
  }, [value]);

  if (loading) {
    return (
      <div>
        <FormattedMessage defaultMessage="Running query" id="tpOGB5" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (currentValue) {
    return (
      <div>
        <FormattedMessage
          defaultMessage="Current value: {currentValue}"
          id="c70TC8"
          values={{ currentValue }}
        />
      </div>
    );
  }

  return (
    <div>
      <FormattedMessage defaultMessage="No current value" id="8E1DAq" />
    </div>
  );
};
