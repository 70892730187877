import { useCompany } from "hooks/useCompany/useCompany";

const DEFAULT_STARTING_MONTH = 1; // January

/**
 * Gets the KPI starting month or defaults to January if KPIs or the feature is
 * disabled.
 * @note Can be used directly and safely whenever you need the starting month.
 * This hook will take care of edges cases (e.g. feature disabled / KPIs
 * disabled) automatically.
 */
export const useKpiStartingMonth = (): number => {
  const company = useCompany();
  return company.kpiStartingMonth ?? DEFAULT_STARTING_MONTH;
};
