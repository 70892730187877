import { Listbox } from "@headlessui/react";
import { isFunction } from "lodash";
import React, { Fragment, ReactNode } from "react";
import { Icon } from "common/icons";
import { twClass } from "utils/twClass";

type ChildrenProps = {
  active: boolean;
  selected: boolean;
};

export type Props<Item> = {
  children: ReactNode | ((props: ChildrenProps) => ReactNode);
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  value: Item;
};

export function SelectOption<Item>({
  children,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  disabled,
  value,
}: Props<Item>): JSX.Element {
  return (
    <Listbox.Option as={Fragment} disabled={disabled ?? false} value={value}>
      {({ active, selected }) => (
        <div
          className={twClass(
            "cursor-default px-2.5 py-2 text-slate-800 flex items-center justify-between",
            {
              "bg-blue-100": selected,
              "bg-slate-100": active && !selected,
              "text-blue-500": active,
            }
          )}
          data-cy={dataCy}
          data-testid={dataTestId}
          // container (SelectOptions) needs tabIndex so we can toggle between search and list.
          // SelectOption needs it too in this case, otherwise we couldn't scroll options with arrow keys.
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <div className="grow min-w-0">
            {isFunction(children) ? children({ active, selected }) : children}
          </div>
          {selected && (
            <div>
              <Icon className="text-blue-500" name="check" size="2xl" />
            </div>
          )}
        </div>
      )}
    </Listbox.Option>
  );
}
