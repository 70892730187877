import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const SelectOptions = ({ children, className }: Props): JSX.Element => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // prevents default headless UI keyboard shortcut (which does nothing?), which allows to toggle between search and list
    if (e.key === "Tab") {
      e.stopPropagation();
    }
  };

  return (
    <div
      className={twClass(
        "max-h-[204px]", // 5 items × 40px + 4px top padding
        "h-full grow overflow-y-auto overflow-x-hidden",
        className
      )}
      onKeyDown={handleKeyDown}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      {children}
    </div>
  );
};
