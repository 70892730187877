import { useUseOneOneOnOneMeetingItemTemplateMutation } from "@graphql";
import { useIntl } from "react-intl";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

type ReturnFunc = (
  templateId: string,
  meetingId?: string
) => Promise<{ hasError: boolean }>;

export const useUseMeetingItemsTemplate = (): ReturnFunc => {
  const { $t } = useIntl();

  const [callUseTemplate] = useUseOneOneOnOneMeetingItemTemplateMutation();

  return async (templateId: string, meetingId?: string) => {
    const response = await callUseTemplate({
      variables: {
        input: {
          meetingId,
          templateId,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.useOneOnOneMeetingItemTemplate?.errors
    );
    if (hasError) {
      toast.failure(
        $t({ defaultMessage: "Error using template.", id: "4pa9iD" })
      );
      return { hasError };
    }
    toast.success($t({ defaultMessage: "Template used.", id: "YC2x8K" }));
    return { hasError };
  };
};
