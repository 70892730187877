import { some } from "lodash";
import { useRouter } from "next/router";

/**
 * routes which should not redirect to the login screen if the user is not authenticated.
 * these are either routes accessible for non-logged-in users, or routes which have their
 * own authentication page (ms-teams).
 */
const publicRoutes = [
  "/forgot-password",
  "/invitations",
  "/login",
  "/logout",
  "/login_as",
  "/ms-teams/login",
  "/ms-teams/authenticate",
  "/reset-password",
  "/welcome",
];

export const isProtectedRoute = (pathname: string): boolean =>
  !some(publicRoutes, (route) => pathname.startsWith(route));

/**
 * checks if the current route requires authentication.
 * TODO: this should be handled by next.js middleware or auth library.
 */
export const useIsProtectedRoute = (): boolean => {
  const { asPath } = useRouter();
  return isProtectedRoute(asPath);
};
