import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";
import { KudoSummary, KudoSummaryKudo } from "../KudoSummary/KudoSummary";

export type DeleteKudoModalKudo = KudoSummaryKudo;

type Props = {
  children?: ReactNode;
  kudo: DeleteKudoModalKudo;
  onClose?: () => void;
  onDelete?: () => void;
  visible?: boolean;
};

export const DeleteKudoModal = ({
  children,
  visible,
  kudo,
  onDelete,
  onClose = () => {},
}: Props): JSX.Element => {
  return (
    <div>
      <div>{children}</div>
      {visible && (
        <ConfirmationModal
          action={onDelete}
          close={onClose}
          confirmText={
            <FormattedMessage defaultMessage="Confirm" id="global:confirm" />
          }
          title={
            <FormattedMessage
              defaultMessage="Delete kudo"
              id="global:deleteKudo"
            />
          }
        >
          <div className="space-y-3">
            <div>
              <FormattedMessage
                defaultMessage="Are you sure you want to delete this? This cannot be undone."
                id="global:deleteUpdate"
              />
            </div>
            <div className="pointer-events-none rounded-lg border p-3">
              <KudoSummary kudo={kudo} />
            </div>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};
