import "dayjs/locale/de";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/nl";
import "dayjs/locale/pt";
import "dayjs/locale/sk";
import dayjs from "dayjs";
import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { I18nContextProvider } from "common/context/i18nContext";
import { intlMessages } from "constants/intl";
import { useBrowserLanguage } from "hooks/useBrowserLanguage/useBrowserLanguage";
import {
  isSupportedLang,
  Lang,
} from "hooks/useLanguageOptions/useLanguageOptions";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";

interface Props {
  children: React.ReactNode;
}

export const I18nProvider = ({ children }: Props): JSX.Element => {
  const browserLang = useBrowserLanguage();
  const [sessionLang, setSessionLang] = useLocalStorage("preferredLanguage");

  let initial: Lang = "en";
  if (
    sessionLang &&
    typeof sessionLang === "string" &&
    isSupportedLang(sessionLang)
  ) {
    initial = sessionLang as Lang;
  } else if (browserLang && isSupportedLang(browserLang)) {
    initial = browserLang as Lang;
  }

  const [lang, setLang] = useState<Lang>(initial);

  const onChangeLang = (newLang: Lang) => {
    if (isSupportedLang(newLang)) {
      setSessionLang(newLang);
      setLang(newLang);
      dayjs.locale(newLang);
    }
  };
  return (
    <I18nContextProvider value={{ lang, setLang: onChangeLang }}>
      <IntlProvider locale={lang} messages={intlMessages[lang]}>
        {children}
      </IntlProvider>
    </I18nContextProvider>
  );
};
