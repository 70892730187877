export const openTeamsDeepLink = ({
  access,
  refresh,
  authId,
}: {
  access: string;
  authId: string;
  refresh: string;
}) => {
  // MS Teams only allows the following chars in the result: a-z, A-Z, 0-9, ., _, +, /, =, ~, and -
  // So we need to base64 encode the JSON
  const result = btoa(JSON.stringify({ access, refresh }));
  window.location.assign(
    `msteams://teams.microsoft.com/l/auth-callback?authId=${authId}&result=${result}`
  );
  window.localStorage.removeItem("oauthRedirectMethod");
  window.localStorage.removeItem("authId");
  // @ts-expect-error Property 'close' does not exist on type 'Location'.ts(2339)
  window.location.close?.();
};
