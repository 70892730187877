/**
 * @deprecated use {@link aUser_WIP} instead.
 */
const users = [
  {
    avatar:
      "https://cdn.pixabay.com/photo/2015/09/09/20/23/baby-933097_960_720.jpg",
    id: 0,
    jobPosition: "Office manager",
    name: "Charlie Steemers",
  },
  {
    avatar:
      "https://cdn.pixabay.com/photo/2012/03/04/01/01/father-22194_960_720.jpg",
    id: 1,
    jobPosition: "Support Manager",
    name: "Aiden Evans",
  },
  {
    avatar:
      "https://cdn.pixabay.com/photo/2017/06/20/22/14/man-2425121_960_720.jpg",
    id: 2,
    jobPosition: "Frontend Engineer",
    name: "Dustin Hall",
  },
  {
    avatar:
      "https://cdn.pixabay.com/photo/2015/01/27/09/58/man-613601_960_720.jpg",
    id: 3,
    jobPosition: "CTO",
    name: "Clarence Franklin",
  },
  {
    avatar:
      "https://cdn.pixabay.com/photo/2016/11/18/19/07/happy-1836445_960_720.jpg",
    id: 4,
    jobPosition: "Stylist",
    name: "Michael Graves",
  },
  {
    avatar:
      "https://cdn.pixabay.com/photo/2012/04/18/23/36/boy-38262_960_720.png",
    id: 5,
    jobPosition: "Fashion expert",
    name: "Maresch Bear",
  },
];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'min' implicitly has an 'any' type.
const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const getRandomUser = () => users[getRandomInt(0, users.length - 1)]!;

export { getRandomUser, getRandomInt, users };
