import { User, UserConnection } from "@graphql";
import { Derive } from "@shoooe/derive";
import { mockPageInfo } from "mocks/common/pageInfo";
import { unmapEdges } from "utils/mapEdges";
import { uuidv4 } from "utils/uuid";

export const aUserConnection = (
  overrides?: Partial<UserConnection> & {
    nodes?: UserConnection["edges"][number]["node"][];
  }
): UserConnection => {
  const edgeCount = overrides?.edges?.length ?? overrides?.nodes?.length ?? 0;
  return {
    __typename: "userConnection",
    edgeCount: overrides?.edgeCount ?? edgeCount,
    edges:
      overrides?.edges ??
      unmapEdges(overrides?.nodes ?? [], "userEdge", "userCursor"),
    pageInfo: overrides?.pageInfo ?? mockPageInfo,
    totalCount: overrides?.totalCount ?? edgeCount,
  };
};

export type PartialUser = Derive<
  User,
  {
    __typename: true;
    avatar: true;
    bookmarked: true;
    checkInStreak: true;
    email: true;
    firstName: true;
    fullName: true;
    goalsCount: true;
    goalsUpToDate: true;
    id: true;
    isActive: true;
    jobPosition: true;
    lastCheckInDate: true;
    lastName: true;
    name: true;
    role: true;
  }
>;

/**
 * this factory is WIP and supports only a handful of props. add new props as needed.
 * <br /> _(full implementation requires too much work to do ad hoc)_
 */
export const aUser_WIP = (overrides?: Partial<PartialUser>): PartialUser => ({
  __typename: "user",
  avatar: overrides?.avatar ?? null,
  bookmarked: overrides?.bookmarked ?? false,
  checkInStreak: overrides?.checkInStreak ?? 0,
  email: overrides?.email ?? "dennis.ziemann@example.org",
  firstName: overrides?.firstName ?? "Dennis",
  fullName: overrides?.fullName ?? "Dennis Ziemann",
  goalsCount: overrides?.goalsCount ?? 0,
  goalsUpToDate: overrides?.goalsUpToDate ?? 0,
  id: overrides?.id ?? uuidv4(),
  isActive: overrides?.isActive ?? true,
  jobPosition: overrides?.jobPosition ?? null,
  lastCheckInDate: overrides?.lastCheckInDate ?? null,
  lastName: overrides?.lastName ?? "Ziemann",
  name: overrides?.name ?? "Dennis Ziemann",
  role: overrides?.role ?? "standard",
});
