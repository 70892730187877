import {
  TimeframeDefaultFragment,
  TimeframeGoalUpdateCycleFragment,
  TimeframeStartDateFragment,
  useOrderedTimeframesQuery,
} from "@graphql";
import { useField } from "formik";
import { isNil } from "lodash";
import React from "react";
import { Show } from "common/controlFlow";
import { Label } from "common/inputs";
import {
  TimeframeSelect,
  TimeframeSelectProps,
} from "common/select/TimeframeSelect/TimeframeSelect";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";

type SelectTimeframe = TimeframeDefaultFragment &
  TimeframeStartDateFragment &
  TimeframeGoalUpdateCycleFragment;

type Props = Omit<TimeframeSelectProps, "defaultItem" | "timeframes"> & {
  label?: string | React.ReactNode;
  name: string;
  wrapperClassName?: string;
};

export const TimeframeField = ({
  name,
  onChange,
  label,
  wrapperClassName,
  ...otherSelectProps
}: Props): JSX.Element | null => {
  const [field, , helpers] = useField(name);

  const { data } = useOrderedTimeframesQuery();
  const loading = isNil(data);
  const timeframes = mapEdges(data?.timeframes.edges);

  const handleChange = (tf: SelectTimeframe | null) => {
    helpers.setValue(tf?.id || null);
    onChange?.(tf);
  };
  const defaultItem = timeframes.find((tf) => tf.id === field.value);
  if (loading) return null;
  return (
    <div className={wrapperClassName}>
      <Show when={isSet(label)}>
        <Label htmlFor="timeframe">{label}</Label>
      </Show>
      <TimeframeSelect
        {...otherSelectProps}
        defaultItem={defaultItem}
        onChange={handleChange}
        timeframes={timeframes}
      />
    </div>
  );
};
