import { createNonNullableCtx } from "common/context/utils";
import { Lang } from "hooks/useLanguageOptions/useLanguageOptions";

type I18nContextType = NonNullable<{
  lang: Lang;
  setLang: (lang: Lang) => void;
}>;

export const [useI18nContext, I18nContextProvider, useOptionalI18nContext] =
  createNonNullableCtx<I18nContextType>();
