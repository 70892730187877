import camelCase from "lodash/camelCase";
import get from "lodash/get";
import React, { useRef } from "react";
import { twClass } from "utils/twClass";
import { FormControl } from "../FormControl/FormControl";
import { AddOnProps, FormProps, StyledInputProps } from "./FormInput.types";

const StyledInput = React.forwardRef<HTMLInputElement, StyledInputProps>(
  (
    {
      disabled,
      readOnly,
      errorText,
      addOnSeparate,
      left,
      metricUnit,
      ...other
    },
    ref
  ) => (
    <input
      ref={ref}
      className={twClass(
        "m-px h-10 w-full px-3 py-0.5 font-sans",
        "appearance-none rounded border placeholder-slate-400 outline-none border-slate-300",
        {
          "bg-slate-100": disabled,
          "bg-slate-300": readOnly,
          "bg-white focus:mx-0 focus:my-px focus:border-2 focus:border-blue-500":
            !readOnly,
          "border-red-500": errorText,
          "cursor-not-allowed": readOnly && disabled,
          "pl-12": left && metricUnit === 3,
          "pl-8": left && metricUnit === 1,
          "pl-9": left && metricUnit === 2,
          "w-[calc(100%_-_50px)]": addOnSeparate,
        }
      )}
      {...other}
    />
  )
);

const AddOn = ({
  children,
  separate,
  isLeft = false,
}: AddOnProps): JSX.Element => (
  <span
    className={twClass(
      "absolute top-2.5 flex h-6 items-center justify-center",
      {
        "left-0": isLeft,
        "mx-0 my-px -mt-[1px]": separate,
        "mx-3 my-0": !separate,
        "right-0": !isLeft,
      }
    )}
  >
    {children}
  </span>
);

/**
 * @deprecated Use {@link common/fields/InputField} instead.
 */
export const FormInput = ({
  className,
  label,
  optional = false,
  addOn = null,
  addOnSeparate,
  infoElement,
  formControl = true,
  field,
  form: { touched, errors, submitCount },
  disabled,
  id,
  isLeft,
  targetValue,
  metricUnit,
  ...other
}: FormProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  let errorText;
  if (submitCount > 0 && get(touched, field.name)) {
    errorText = get(touched, field.name) && get(errors, field.name);
  }
  const formControlProps = {
    errorText: errorText as any,
    id,
    infoElement,
    label,
    optional,
  };
  const metricUnitLength = metricUnit?.length || 0;
  const input = (
    <div
      className={twClass(
        "relative text-base font-normal text-slate-800",
        className
      )}
    >
      <StyledInput
        ref={inputRef}
        addOnSeparate={addOnSeparate}
        data-cy={other["data-cy"] || camelCase(label)}
        disabled={disabled}
        errorText={errorText}
        id={id}
        left={isLeft}
        metricUnit={metricUnitLength}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value ?? ""}
        {...other}
      />
      {addOn && (
        <AddOn isLeft={isLeft} separate={addOnSeparate}>
          {addOn}
        </AddOn>
      )}
      {targetValue && <AddOn separate={false}>{targetValue}</AddOn>}
    </div>
  );

  if (formControl) {
    return <FormControl {...formControlProps}>{input}</FormControl>;
  }

  return input;
};
