import { PerdooApiExploreViewTabNameChoices } from "@graphql";
import { isNil } from "lodash";
import React from "react";
import { Show } from "common/controlFlow";
import {
  AlignedToItem,
  AlignedToSelect,
} from "common/select/AlignedToSelect/AlignedToSelect";
import { FilterCheckbox } from "legacy/components/filters/FilterCheckbox/FilterCheckbox";
import { FilterRange } from "legacy/components/filters/FilterRange/FilterRange";
import { useFiltersContext } from "legacy/components/filters/filtersContext";
import { FilterSearch } from "legacy/components/filters/FilterSearch/FilterSearch";
import { HandleFilterRemoveProps } from "legacy/components/filters/FilterSet.utils";
import {
  Filter,
  FilterItem,
  FilterValue,
} from "legacy/components/filters/types";

type ActiveFilterProps = {
  activeFilter: Filter | null;
  findActiveFilterItems: () => FilterItem[] | [];
  goalType?: PerdooApiExploreViewTabNameChoices;
  handleFilterRemove: ({
    filter,
    filterName,
    filtersDispatch,
    activeFilter,
    afterFilterRemoved,
  }: HandleFilterRemoveProps) => void;
  handleSelectFilterValue: (filterValues: FilterItem) => void;
  renderToggle: () => JSX.Element | null;
};

export const ActiveFilter = ({
  activeFilter,
  findActiveFilterItems,
  handleSelectFilterValue,
  handleFilterRemove,
  renderToggle,
  goalType,
}: ActiveFilterProps): JSX.Element | null => {
  const { filtersDispatch, filterState } = useFiltersContext();
  const exploreView = filterState.filters;

  if (isNil(activeFilter)) {
    return null;
  }

  const handleSelectAlignedTo = ({ id, name }: AlignedToItem) => {
    handleSelectFilterValue({
      ...exploreView.selected,
      id,
      name,
    });
  };

  return (
    <div>
      <Show when={activeFilter.type === "alignedToSelect"}>
        <AlignedToSelect
          excludeKpis={goalType === PerdooApiExploreViewTabNameChoices.Kpis}
          excludeObjectives={
            goalType === PerdooApiExploreViewTabNameChoices.Kpis
          }
          onSelect={handleSelectAlignedTo}
          renderToggle={renderToggle}
        />
      </Show>

      <Show when={activeFilter.type === "search"}>
        <FilterSearch
          filterColumn={
            {
              ...exploreView[activeFilter.filterName],
              items: findActiveFilterItems(),
            } as FilterValue
          }
          onSelect={(selected) =>
            handleSelectFilterValue({ ...selected, id: selected.id })
          }
          renderToggle={renderToggle}
        />
      </Show>

      <Show when={activeFilter.type === "searchTags"}>
        <FilterSearch
          filterColumn={
            {
              ...exploreView[activeFilter.filterName],
              items: findActiveFilterItems(),
            } as FilterValue
          }
          onSelect={(selected) =>
            handleSelectFilterValue({ ...selected, id: selected.id })
          }
        />
      </Show>

      <Show when={activeFilter.type === "range"}>
        <div
          data-cy="j7HiQa8JlMyt1AYRpfg0z"
          className="p-5"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {activeFilter.name}
          <FilterRange
            allowCross={false}
            count={2}
            defaultValue={[activeFilter.min, activeFilter.max]}
            max={activeFilter.max}
            min={activeFilter.min}
            onAfterChange={(range: any) => handleSelectFilterValue(range)}
            pushable
          />
        </div>
      </Show>

      <Show when={activeFilter.type === "checkbox"}>
        <div>
          {activeFilter.items?.map((item) => (
            <FilterCheckbox
              key={item.id}
              filterColumn={exploreView[activeFilter.filterName]}
              item={item}
              onRemove={() =>
                handleFilterRemove({
                  activeFilter,
                  filter: item,
                  filterName: activeFilter.filterName,
                  filtersDispatch,
                })
              }
              onSelect={() => handleSelectFilterValue(item)}
            />
          ))}
        </div>
      </Show>
    </div>
  );
};
