import { defineMessages } from "react-intl";

export const messages = defineMessages({
  closeTitle: {
    defaultMessage: "Close Objective",
    id: "objective:close:title",
  },
  closingNoteUpdated: {
    defaultMessage: "Closing note updated",
    id: "closingNote:updated",
  },
  editClosingNote: {
    defaultMessage: "Edit Closing Note",
    id: "objective:edit:close:title",
  },
  objectiveClosed: {
    defaultMessage: "Objective closed",
    id: "objective:closed",
  },
});
