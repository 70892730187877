import {
  namedOperations,
  PerformanceReviewRepeatFrequency,
  useUpsertPerformanceReviewMutation,
} from "@graphql";
import dayjs from "dayjs";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Modal } from "common/overlay/Modal/Modal";
import { ADD_PERFORMANCE_REVIEW } from "constants/tracking";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import {
  AddPerformanceReviewForm,
  AddPerformanceValues,
} from "../PerformanceReviewForm/AddPerformanceReviewForm";

const validationSchema = Yup.object().shape({
  dueDate: Yup.date()
    .min(dayjs().add(-1, "day"), "Meeting date must be today or later.")
    .typeError("Required")
    .required("Required"),
  repeatFrequency: Yup.string()
    .oneOf(Object.values(PerformanceReviewRepeatFrequency))
    .required("Please select a valid repeat frequency."),
  revieweeIds: Yup.lazy((value) => {
    return Array.isArray(value)
      ? Yup.array().of(Yup.string()).required("Required")
      : Yup.string().typeError("Required").required("Required");
  }),
  reviewerId: Yup.string().required("Required"),
});

type AddPerformanceReviewProps = Omit<AddPerformanceValues, "reviewee">;

type Props = {
  revieweeIds?: string | string[];
  reviewerId: string;
};

export const AddPerformanceReviewModal = ({
  reviewerId,
  revieweeIds,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const [upsertPerformanceReview] = useUpsertPerformanceReviewMutation({
    refetchQueries: [
      namedOperations.Query.GetAllPerformanceReviews,
      namedOperations.Query.GetUserPerformanceReviewsDue,
    ],
  });
  const { close } = useModalRouter();

  const initialValues = (): AddPerformanceValues => {
    const initial = {
      dueDate: null,
      repeatFrequency: PerformanceReviewRepeatFrequency.NoRepeat,
      revieweeIds: null,
      reviewerId,
    };
    if (Array.isArray(revieweeIds)) {
      return { ...initial, revieweeIds };
    }
    if (typeof revieweeIds === "string") {
      return { ...initial, revieweeIds: [revieweeIds] };
    }
    return initial;
  };

  const upsertAllUsers = async ({
    revieweeIds,
    ...values
  }: AddPerformanceReviewProps & {
    revieweeIds: string[];
  }) => {
    const promises = revieweeIds.map((userId) => {
      return upsertPerformanceReview({
        variables: {
          input: { ...values, revieweeId: userId },
        },
      });
    });
    return Promise.all(promises);
  };

  const handleSubmit = async (
    values: AddPerformanceValues,
    actions: FormikHelpers<AddPerformanceValues>
  ) => {
    const dueDate = dayjs(values.dueDate).format("YYYY-MM-DD");
    const responses = await upsertAllUsers({
      ...values,
      dueDate,
      revieweeIds: values.revieweeIds as string[],
    });
    actions.setSubmitting(false);

    const errors = responses.map((response) => {
      const { hasError } = handleErrors(
        response,
        response.data?.upsertPerformanceReview?.errors,
        { handleTopLevel: true }
      );
      return hasError;
    });
    if (errors.every((err) => !err)) {
      toast.success(
        intl.formatMessage({
          defaultMessage: "Successfully scheduled Performance Review!",
          id: "performanceReview:add:success",
        })
      );
      values.revieweeIds?.forEach((reviewee) => {
        track(ADD_PERFORMANCE_REVIEW, {
          first_review_date: values.dueDate,
          reviewee,
          reviewee_frequency: values.repeatFrequency,
          user_id: values.reviewerId,
        });
      });
      await close();
    }
  };
  return (
    <Modal
      backdropClose={false}
      isOpen
      onClose={close}
      size="sm"
      title={intl.formatMessage({
        defaultMessage: "Plan Performance Review",
        id: "performanceReview:add:modal:title",
      })}
    >
      <Formik<AddPerformanceValues>
        initialValues={initialValues()}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <AddPerformanceReviewForm onCancel={close} />
      </Formik>
    </Modal>
  );
};
