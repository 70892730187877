import { useRouter } from "next/router";
import { useEffect } from "react";

const pageview = (url: string) => {
  window.dataLayer?.push({
    event: "pageview",
    page: url,
  });
};

export const useGoogleTagManager = (): void => {
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeComplete", pageview);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router]);
};
