import * as React from "react";
import { useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Spinner } from "common/placeholders/Spinner/Spinner";

export const NotificationInfiniteList = ({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'loading' implicitly has an 'any' ... Remove this comment to see the full error message
  loading,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  data,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'fetchMore' implicitly has an 'any... Remove this comment to see the full error message
  fetchMore,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'hasNextPage' implicitly has an 'a... Remove this comment to see the full error message
  hasNextPage,
}) => {
  const intl = useIntl();
  return (
    <div className="h-128 overflow-y-auto text-blue-500">
      {data}
      <div className="mt-1 flex h-8 items-center justify-center">
        {loading && (
          <div className="infinite-list-item">
            <Spinner />
          </div>
        )}
        {hasNextPage && !loading && (
          <Button
            data-cy="gC7ietDldbm65JQ4V5sfg"
            onClick={fetchMore}
            size="small"
          >
            {intl.formatMessage({
              defaultMessage: "Load more...",
              id: "global:loadMore",
            })}
          </Button>
        )}
      </div>
    </div>
  );
};
