import React, { ReactNode } from "react";
import { colorTheme } from "constants/colorTheme";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  color?: string | null;
  "data-cy"?: string;
  text: ReactNode;
};

export const Tag: React.FC<Props> = ({
  "data-cy": dataCy,
  className,
  text,
  color: maybeColor,
}) => {
  const defaultColor = colorTheme.blue[500];
  const color = maybeColor ?? defaultColor;
  return (
    <div
      className={twClass(
        "rounded-full h-5 px-2 text-sm inline-block select-none max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap bg-white leading-4",
        className
      )}
      style={{
        border: `1px solid ${color}`,
        color: color,
      }}
      data-cy={dataCy}
    >
      {text}
    </div>
  );
};
