import dayjs from "dayjs";
import { camelCase, find } from "lodash";
import { useRouter } from "next/router";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import styled from "styled-components";
import { NotificationItem } from "common/notification/NotificationItem/NotificationItem";
import { colorTheme } from "constants/colorTheme";
import { CLICK_NOTIFICATION } from "constants/tracking";
import { track } from "utils/tracker";
import { camelizeKeys } from "utils/utils";
import { legacyNotificationTypes } from "../../../../../notification/constants";
import { useGetEntityUrl } from "../../../hooks/useGetEntityUrl";
import { notificationsConfig } from "./NotificationItemWrapper.config";
import {
  NotificationCompleteGetter,
  NotificationMessages,
  NotificationTargetGetter,
} from "./NotificationItemWrapper.selectors";

const NotificationSubject = styled.span`
  font-weight: 500;
`;

const messages = defineMessages({
  addedTo: {
    defaultMessage: "added you to",
    id: "notifications:addedTo",
  },
  commentCreated: {
    defaultMessage: "commented on",
    id: "notifications:commentCreated",
  },
  initUpdated: {
    defaultMessage: "updated",
    id: "notifications:initUpdated",
  },
  krUpdated: {
    defaultMessage: "updated",
    id: "notifications:krUpdated",
  },
  madeContributor: {
    defaultMessage: "made you a contributor of",
    id: "notifications:madeContributor",
  },
  madeOwner: {
    defaultMessage: "made you an owner of",
    id: "notifications:madeOwner",
  },
  oCompleted: {
    defaultMessage: "closed",
    id: "notifications:oCompleted",
  },
  tfChanged: {
    defaultMessage: "changed the default Timeframe to",
    id: "notifications:tfChanged",
  },
  userAddedToKr: {
    defaultMessage: "added you to the KR titled",
    id: "notifications:userAddedToKr",
  },
  userChanged: {
    defaultMessage: "made you an ambassador",
    id: "notifications:userChanged",
  },
});

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'notification' implicitly has an '... Remove this comment to see the full error message
export const NotificationItemWrapper = ({ notification, isUnread }) => {
  const intl = useIntl();
  const router = useRouter();
  const { user } = notification;
  const getEntityUrl = useGetEntityUrl();

  const handleClick = () => {
    track(CLICK_NOTIFICATION, {
      event_type: notification.eventType,
    });

    const targetEntity = {
      id: notification.extra.target_id || notification.targetId,
      type: camelCase(
        notification.extra.target_type || notification.targetType
      ),
    };
    router.push(getEntityUrl(targetEntity.type, targetEntity.id));
  };

  const renderNotification = () => {
    const name = user?.fullName || "NO_USER";

    const type =
      legacyNotificationTypes[notification.eventType] || notification.eventType;

    if (NotificationCompleteGetter[type]) {
      const message = find(messages, { id: NotificationMessages[type] });
      return (
        <span data-cy="notificationElement">
          <span style={{ color: colorTheme.blue[500] }}>{name}</span>
          &nbsp;
          {message && intl.formatMessage(message)}
          &nbsp;
          <NotificationSubject>
            {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
            {NotificationTargetGetter[type](camelizeKeys(notification.extra))}
          </NotificationSubject>
        </span>
      );
    }
    if (notificationsConfig[type]) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      return notificationsConfig[type].titleRenderer(
        camelizeKeys(notification.extra)
      );
    }
    return <span>{notification.description}</span>;
  };

  return (
    <NotificationItem
      data-cy="82KTgbgkatfEPIYZHgbI5"
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      avatarUrl={user?.avatar || ""}
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      notification={renderNotification(notification)}
      onClick={handleClick}
      timeAgo={dayjs().to(dayjs(notification.eventDate))}
      unread={isUnread}
    />
  );
};
