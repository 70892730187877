import { exhaustiveCheck } from "utils/exhaustiveCheck";
import { AccessFeature, Feature, useFeatureAccessHook } from "./types";
import {
  useAsanaIntegrationAvailable,
  useEngagementReportAvailable,
  useGoalTagsAvailable,
  useJiraIntegrationAvailable,
  useKpiBoardsAvailable,
  useKudosVisibility,
  useOneOnOneVisibility,
  usePerformanceReviewsVisibility,
  usePrivateGoalsAvailable,
  useSharedGoalsEnabled,
  useStandardPaidFeatureAvailable,
  useTeamReflectionsEnabled,
} from "./useFeatureAccess.hooks";

/**
 * generic hook to check if a feature is available. each feature defines its own permission logic.
 * permission logic can depend on both company and current user.
 */
export const useFeatureAccess = (
  feature: AccessFeature
): useFeatureAccessHook => {
  let hook: () => useFeatureAccessHook;

  switch (feature) {
    case Feature.engagementReport:
      hook = useEngagementReportAvailable;
      break;
    case Feature.kpiBoards:
      hook = useKpiBoardsAvailable;
      break;
    case Feature.kudos:
      hook = useKudosVisibility;
      break;
    case Feature.oneOnOnes:
      hook = useOneOnOneVisibility;
      break;
    case Feature.performanceReviews:
      hook = usePerformanceReviewsVisibility;
      break;
    case Feature.privateGoals:
      hook = usePrivateGoalsAvailable;
      break;
    case Feature.sharedGoals:
      hook = useSharedGoalsEnabled;
      break;
    case "GOAL_TAGS":
    case Feature.goalTags:
      hook = useGoalTagsAvailable;
      break;
    case Feature.teamReviews:
      hook = useTeamReflectionsEnabled;
      break;
    case "BUTTON_BOOKMARK_STAR":
      hook = useStandardPaidFeatureAvailable.bind(null, "BUTTON_BOOKMARK_STAR");
      break;
    case "BUTTON_EXPLORE_GRAPHS":
      hook = useStandardPaidFeatureAvailable.bind(
        null,
        "BUTTON_EXPLORE_GRAPHS"
      );
      break;
    case "BUTTON_EXPORTS":
      hook = useStandardPaidFeatureAvailable.bind(null, "BUTTON_EXPORTS");
      break;
    case "DROPDOWN_SSO_PROVIDER":
      hook = useStandardPaidFeatureAvailable.bind(
        null,
        "DROPDOWN_SSO_PROVIDER"
      );
      break;
    case "DROPDOWN_USER_ROLE":
      hook = useStandardPaidFeatureAvailable.bind(null, "DROPDOWN_USER_ROLE");
      break;
    case "GRAPH_POT":
      hook = useStandardPaidFeatureAvailable.bind(null, "GRAPH_POT");
      break;
    case "INPUT_KPI_TARGET":
      hook = useStandardPaidFeatureAvailable.bind(null, "INPUT_KPI_TARGET");
      break;
    case "INPUT_RESULT_DUE_DATE":
      hook = useStandardPaidFeatureAvailable.bind(
        null,
        "INPUT_RESULT_DUE_DATE"
      );
      break;
    case "INTEGRATION_API":
      hook = useStandardPaidFeatureAvailable.bind(null, "INTEGRATION_API");
      break;
    case "INTEGRATION_ASANA":
      hook = useAsanaIntegrationAvailable;
      break;
    case "INTEGRATION_JIRA":
      hook = useJiraIntegrationAvailable;
      break;
    case "INTEGRATION_HRIS":
      hook = useStandardPaidFeatureAvailable.bind(null, "INTEGRATION_HRIS");
      break;
    case "INTEGRATION_SCIM":
      hook = useStandardPaidFeatureAvailable.bind(null, "INTEGRATION_SCIM");
      break;
    case "MENU_CLONE_OBJECTIVE":
      hook = useStandardPaidFeatureAvailable.bind(null, "MENU_CLONE_OBJECTIVE");
      break;
    case "PAGE_EXPLORE":
      hook = useStandardPaidFeatureAvailable.bind(null, "PAGE_EXPLORE");
      break;
    case "PAGE_PERFORMANCE":
      hook = useStandardPaidFeatureAvailable.bind(null, "PAGE_PERFORMANCE");
      break;
    case "PAGE_ROADMAP":
      hook = useStandardPaidFeatureAvailable.bind(null, "PAGE_ROADMAP");
      break;
    case "PAGE_TIMELINE":
      hook = useStandardPaidFeatureAvailable.bind(null, "PAGE_TIMELINE");
      break;
    case "PRIVATE_MODE":
      hook = useStandardPaidFeatureAvailable.bind(null, "PRIVATE_MODE");
      break;
    default:
      return exhaustiveCheck(feature);
  }

  return hook();
};
