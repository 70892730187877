import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { ColorPickerField } from "common/form/ColorPickerField/ColorPickerField";
import { FormControl } from "common/form/FormControl/FormControl";
import { FormInput } from "common/form/FormInput/FormInput";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { Tag } from "common/tag/Tag/Tag";

const messages = defineMessages({
  colorTitle: {
    defaultMessage: "Color",
    id: "company:settings:tags:edit:colorTitle",
  },
  descriptionTitle: {
    defaultMessage: "Description",
    id: "company:settings:tags:edit:descriptionTitle",
  },
  nameTitle: {
    defaultMessage: "Name",
    id: "company:settings:tags:edit:nameTitle",
  },
  previewTitle: {
    defaultMessage: "Preview",
    id: "company:settings:tags:edit:previewTitle",
  },
});

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

type Values = {
  color?: string;
  description?: string;
  id?: string;
  name?: string;
};
export type TagFormValues = Values;

interface TagFormDetailsProps {
  initialValues: Values;
  onSubmit: (
    values: Values,
    actions: FormikHelpers<Values>
  ) => Promise<null | undefined>;
}

export const TagForm = ({
  onSubmit,
  initialValues,
}: TagFormDetailsProps): JSX.Element => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={schema}
    >
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          <Field
            autoFocus
            component={FormInput}
            label={intl.formatMessage(messages.nameTitle)}
            name="name"
            placeholder={intl.formatMessage(messages.nameTitle)}
          />

          <Field
            component={FormInput}
            label={intl.formatMessage(messages.descriptionTitle)}
            name="description"
            placeholder={intl.formatMessage(messages.descriptionTitle)}
          />

          <div className="flex items-center">
            <div className="flex-1">
              <ColorPickerField name="color" />
            </div>
            {values.name && values.color && (
              <div className="flex-1">
                <FormControl
                  className="block"
                  label={intl.formatMessage(messages.previewTitle)}
                >
                  <Tag color={values.color} text={values.name} />
                </FormControl>
              </div>
            )}
          </div>

          <ModalFooter>
            <Button
              data-cy="lr9pjjmoEe9ppXxHRUffw"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              Submit
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
