import React, { CSSProperties, forwardRef, ReactNode } from "react";
import { Icon } from "common/icons/Icon/Icon";
import { IconSize } from "common/misc/types";
import { IconName } from "constants/icons";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  dropdownIconSize?: keyof typeof IconSize;
  icon?: keyof typeof IconName;
  iconSize?: keyof typeof IconSize;
  isDropdown?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: "small" | "large";
  style?: CSSProperties;
  /** @deprecated Use `children` instead */
  text?: ReactNode;
  type?: "button" | "submit";
};

export const TextButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      text,
      children,
      type = "button",
      disabled = false,
      size = "large",
      isDropdown = false,
      className = "",
      "data-cy": dataCy,
      "data-testid": dataTestId,
      icon,
      iconSize = "2xl",
      style,
      dropdownIconSize,
      onClick,
    },
    ref
  ) => {
    const handleClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (!disabled) {
        onClick?.(e);
      }
    };

    return (
      <button
        ref={ref}
        className={twClass(
          "flex items-center py-1.5 outline-none focus:outline-none",
          {
            "cursor-not-allowed text-slate-500": disabled,
            "cursor-pointer hover:text-blue-500": !disabled,
          },
          className
        )}
        data-cy={dataCy}
        data-testid={dataTestId}
        disabled={disabled}
        onClick={handleClick}
        style={style}
        type={type}
      >
        {icon && <Icon className="mr-2" name={icon} size={iconSize} />}
        {size === "large" && (
          <h3>
            {text}
            {children}
          </h3>
        )}
        {size !== "large" && (
          <span>
            {text}
            {children}
          </span>
        )}
        {isDropdown && (
          <Icon
            name={IconName.keyboard_arrow_down}
            size={dropdownIconSize ?? "2xl"}
          />
        )}
      </button>
    );
  }
);

TextButton.displayName = "TextButton";
