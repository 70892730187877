import React, { LegacyRef, MouseEventHandler } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { twMerge } from "utils/twMerge";

const tooltips = defineMessages({
  keyResult: {
    defaultMessage: "Browse sample results",
    id: "sample:goalSelector:result:tooltip",
  },
  kpi: {
    defaultMessage: "Browse sample KPIs",
    id: "sample:goalSelector:kpi:tooltip",
  },
  objective: {
    defaultMessage: "Browse sample goals",
    id: "sample:goalSelector:objective:tooltip",
  },
});

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type: "keyResult" | "kpi" | "objective";
};

/**
 * just an IconButton with tooltip and some classes. used for sample data selection in goal modals.
 */
export const GoalSelectorButton = React.forwardRef(
  (
    { className, onClick, type }: Props,
    ref: LegacyRef<HTMLButtonElement>
  ): JSX.Element => {
    const intl = useIntl();

    return (
      <Tooltip
        className="text-xs"
        content={intl.formatMessage(tooltips[type])}
        data-placement="top"
        spanClass="w-10 h-10"
      >
        <IconButton
          data-cy="QOwjWfMfbX81qvMeMjLK0"
          className={twMerge(
            "h-full w-full p-2 border-slate-300 text-blue-500",
            className
          )}
          ghost
          iconType="outlined"
          name="folder_open"
          onClick={onClick}
          parentRef={ref}
          size="xl"
        />
      </Tooltip>
    );
  }
);
