import { useRouter } from "next/router";
import React from "react";
import { getCompleteLink } from "route-configs";
import { AnchorNext } from "common/navigation";
import { Tab, TabSize } from "common/navigation/Tab/Tab";

type GeneralTabLinkProps = {
  baseLink: string;
  link: string;
  query?: Record<string, string>;
  size?: keyof typeof TabSize;
  title: string;
};

export const GeneralTabLink = ({
  link,
  query,
  baseLink,
  size,
  title,
}: GeneralTabLinkProps): JSX.Element => {
  const { pathname } = useRouter();

  const completelink = getCompleteLink(link, {}, query);

  return (
    <AnchorNext href={completelink.absolutePath}>
      <Tab
        active={pathname.includes(baseLink)}
        className="pt-0"
        size={size}
        title={title}
      />
    </AnchorNext>
  );
};
