import { useField } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { For, Show } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { useRoleOptions } from "hooks/useRoleOptions/useRoleOptions";
import { twClass } from "utils/twClass";

const Select = makeSelect<Option>();

type Option = {
  id: string;
  label: string;
};

type Props = {
  className?: string;
  "data-cy"?: string;
  disabled?: boolean;
  name: string;
};

export const UserRoleField = ({
  name,
  "data-cy": dataCy,
  className,
  disabled,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<string | null>(name);
  const roleOptions = useRoleOptions(meta.initialValue ?? undefined);
  const options = roleOptions.map(({ value, ...rest }) => ({
    id: value,
    ...rest,
  }));
  const selected = options.find((opt) => opt.id === field.value) ?? null;

  return (
    <div className={twClass(className, "space-y-2")}>
      <Select.Root
        className={className}
        data-cy={dataCy}
        disabled={disabled}
        onChange={(opt) => helpers.setValue(opt?.id ?? null)}
        value={selected}
      >
        <Select.Trigger>
          <Select.Value innerClass="w-full" hasError={!!meta.error}>
            {selected?.label ?? (
              <FormattedMessage defaultMessage="Select..." id="724CrE" />
            )}
          </Select.Value>
        </Select.Trigger>
        <Select.Dropdown className="divide-y divide-slate-300">
          <Select.Options>
            <For each={options} fallback={<SelectFallback />}>
              {(option) => (
                <Select.Option key={option.id} value={option}>
                  {option.label}
                </Select.Option>
              )}
            </For>
          </Select.Options>
        </Select.Dropdown>
      </Select.Root>
      <Show when={!!meta.error}>
        <div
          className="m-1 min-h-[18px] text-sm text-red-500"
          data-cy="errorMessage"
        >
          {meta.error}
        </div>
      </Show>
    </div>
  );
};
