import * as Sentry from "@sentry/react";
import { SeverityLevel } from "@sentry/types";
import { each, isNil, omit, size } from "lodash";
import posthog from "posthog-js";
import { auth } from "common/authHelper";
import { isDevEnvironment } from "hooks/useDevMode/useDevMode";
import { INTERCOM_APP_ID, IS_DEVELOPMENT, IS_PRODUCTION } from "../config";
import { ADD_INITIATIVE, ADD_KEY_RESULT } from "../constants/tracking";
import { getCompanySizeFormatted } from "../modules/company/Company/Company.utils";
import { UserStatuses } from "../modules/users/[id]/UserProfile/UserProfile.constants";

// Default props are sent with every event
const defaultProps = {};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export const identifyUser = (user, company) => {
  if (isNil(user) || isNil(company)) {
    return;
  }
  const identifyData = {
    accountStatus: company.accountStatus,
    activation_status: UserStatuses.Active,
    // this is duplicate to the info in the 'group' call below but required
    // for intercom to count web sessions correctly
    company: {
      accountStatus: company.accountStatus,
      companySize: getCompanySizeFormatted(company.companySize),
      createdAt: company.createdDate,
      featureTier: company.featureTier,
      id: company.id,
      name: company.name,
      release: company.release,
    },
    companySize: getCompanySizeFormatted(company.companySize),
    createdAt: user.createdDate,
    email: user.email,
    featureTier: company.featureTier,
    firstName: user.firstName,
    isDirectReport: user.manager !== null,
    isManager: size(user.directReports.edges) > 0,
    job_position: user.jobPosition,
    language: user.language,
    lastName: user.lastName,
    name: user.fullName,
    perdoo_company_name_v2: company.name,
    perdoo_company_uuid_v2: company.id,
    perdoo_user_uuid_v2: user.id,
    persona: user.persona,
    release: company.release,
    role: user.role,
    userId: user.id,
  };

  Object.assign(defaultProps, {
    companySize: identifyData.companySize,
    context: {
      groupId: company.id,
    },
    featureTier: identifyData.featureTier,
    job_position: identifyData.job_position,
    perdoo_company_name_v2: identifyData.perdoo_company_name_v2,
    persona: identifyData.persona,
    release: identifyData.release,
    role: identifyData.role,
    userId: identifyData.userId,
  }); // partially add to default props

  // intercom
  intercom()?.("boot", {
    ...omit(identifyData, ["createdAt", "release", "userId"]),
    app_id: INTERCOM_APP_ID,
    company: {
      accountStatus: company.accountStatus,
      companySize: getCompanySizeFormatted(company.companySize),
      created_at: company.createdDate,
      featureTier: company.featureTier,
      id: company.id,
      name: company.name,
      perdoo_company_uuid_v2: company.id,
    },
    created_at: user.createdDate,
    email: user.email,
    name: user.fullName,
    user_hash: user.intercomHash,
    user_id: user.id,
  });

  // posthog
  if (posthog.__loaded) {
    posthog.identify(user.id, {
      ...identifyData,
      createdAt: new Date(user.createdDate).toISOString(),
    });
    identifyCompany(company.id, company);
  }

  // sentry identify
  Sentry.configureScope((scope) => {
    scope.setUser({
      ...identifyData,
      email: user.email,
      id: user.id,
      username: user.fullName,
    });
  });
};

export const partialIdentifyUser = (
  id: string,
  traits: Partial<{
    hasCreated1on1s: boolean;
    hasCreatedReviews: boolean;
    isDirectReport: boolean;
    isManager: boolean;
  }> = {}
): void => {
  if (posthog.__loaded) {
    posthog.identify(id, traits);
  }

  // intercom
  intercom()?.("boot", {
    ...traits,
    app_id: INTERCOM_APP_ID,
    user_id: id,
  });
};

export const identifyCompany = (
  id: string,
  traits: Partial<{
    createdDate: string;
    hasSlackIntegration: boolean;
    name: string;
  }>
): void => {
  const { name, createdDate, hasSlackIntegration } = traits;
  if (posthog.__loaded) {
    posthog.group("company", id, {
      ...(createdDate && { createdAt: new Date(createdDate).toISOString() }),
      ...(name && { name }),
      ...(hasSlackIntegration && { hasSlackIntegration }),
    });
  }
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
export const showNpsSurvey = (user, company) => {
  // delighted
  if (IS_PRODUCTION) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delighted' does not exist on type 'Windo... Remove this comment to see the full error message
    window.delighted.survey({
      createdAt: user.createdDate,
      email: user.email,
      name: user.fullName,
      properties: {
        companySize: getCompanySizeFormatted(company.companySize),
        featureTier: company.featureTier,
        job_position: user.jobPosition,
        language: user.language,
        perdoo_company_name_v2: company.name,
        perdoo_company_uuid_v2: company.id,
        perdoo_user_uuid_v2: user.id,
        persona: user.persona,
        role: user.role,
      },
    });
  }
};

export const logToSentry = (
  msg: string,
  extra: Record<string, unknown> = {},
  level: SeverityLevel = "warning"
): void => {
  Sentry.withScope((scope) => {
    each(extra, (val, key) => {
      scope.setExtra(key, val);
    });
    Sentry.captureMessage(msg, level);
  });
};

/**
 * tracks an events for analytics, used for fullstory.
 */
export const track = (event: string, props = {}): void => {
  console.info("track(", event, ", ", props, ")");
  if (IS_DEVELOPMENT) return;

  // intercom
  intercom()?.("trackEvent", event, props);

  // posthog
  if (posthog.__loaded) {
    posthog.capture(event, { ...defaultProps, ...props });
  }

  Sentry.addBreadcrumb({
    category: "track",
    data: props,
    message: event,
  });
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
export const trackAddResult = (result, createdFromSample = false) => {
  const {
    type,
    description,
    dueDate,
    startValue,
    endValue,
    metricTitle,
    metricUnit,
    keyResult,
  } = result;
  track(type === "KEY_RESULT" ? ADD_KEY_RESULT : ADD_INITIATIVE, {
    contributor_count: result.contributors?.edges?.length || 0,
    description_added: !isNil(description),
    dueDateAdded: !isNil(dueDate),
    endValue,
    from_example: createdFromSample,
    linkedKr: !isNil(keyResult),
    metric: metricTitle,
    metric_unit: metricUnit,
    startValue,
  });
};

export const trackPage = (title: string, props = {}): void => {
  Object.assign(defaultProps, { title });
  track(`Viewed Web ${title}`, props);

  Sentry.addBreadcrumb({
    category: "nav",
    data: props,
    message: title,
  });
};

export const intercom = () => {
  // Disable intercom locally and in preview apps.
  if (isDevEnvironment()) {
    return undefined;
  }

  // Disable intercom when a user was "logged in as" (via admin console).
  if (auth.isLoggedInAs()) {
    return undefined;
  }

  // Intercom can be undefined eg if it's blocked.
  return typeof window !== "undefined" ? window.Intercom : undefined;
};
