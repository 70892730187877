import { GetActiveUsersQueryVariables } from "@graphql";
import { useField } from "formik";
import React from "react";
import { Show } from "common/controlFlow";
import { UserFieldLabel } from "common/form/UserField/UserFieldLabel";
import { SELECT_EMPTY_ID } from "common/inputs/Select/Select.utils";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { UserSelect } from "common/select";
import { SelectUser } from "common/select/UserSelect/UserSelect";
import {
  ActiveUsersOptions,
  useActiveUsers,
} from "hooks/useActiveUsers/useActiveUsers";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type UserSelectProps = React.ComponentProps<typeof UserSelect>;
type Props = Omit<UserSelectProps, "onChange" | "users"> & {
  label?: string;
  name: string;
  optional?: boolean;
  options?: ActiveUsersOptions;
  variables?: GetActiveUsersQueryVariables;
};

export const UserField = ({
  className,
  defaultItem,
  label,
  name,
  optional,
  variables,
  options,
  ...userSelectProps
}: Props): JSX.Element => {
  const me = useCurrentUser();
  const { canCreateGoalsInAnyGroup } = usePermissions();
  const permissionVariables = canCreateGoalsInAnyGroup
    ? undefined
    : { groups_Members_Id: me?.id };
  const users: SelectUser[] | undefined = useActiveUsers({
    variables: {
      ...permissionVariables,
      ...variables,
    },
    ...options,
    optional,
  });
  const [field, meta, helpers] = useField<string>(name);

  if (!users) return <SpinnerFit />;

  const handleChange = (user: SelectUser | null) => {
    let userId = user?.id;
    if (!userId || userId === SELECT_EMPTY_ID) userId = "";

    helpers.setValue(userId);
  };

  const defaultUser = users.find((u) => u.id === field.value);

  // margin is added to align with other form fields, see FormControl
  return (
    <div className="my-2">
      <Show when={label}>
        {(label) => (
          <UserFieldLabel className="mb-1" htmlFor={name} optional={optional}>
            {label}
          </UserFieldLabel>
        )}
      </Show>
      <UserSelect
        {...userSelectProps}
        className={twClass("w-full", className)}
        defaultItem={defaultUser ?? defaultItem}
        onChange={handleChange}
        users={users}
        hasError={!!meta.error}
      />
      <Show when={isSet(meta.error)}>
        <div
          className="m-1 min-h-[18px] text-sm text-red-500"
          data-cy="errorMessage"
        >
          {meta.error}
        </div>
      </Show>
    </div>
  );
};
