import React from "react";
import { useIntl } from "react-intl";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { UpgradeIndicator } from "common/inAppUpgrade/UpgradeIndicator/UpgradeIndicator";
import { HelpCenterButton } from "common/layout/NavBar/HelpCenterButton/HelpCenterButton";
import { messages } from "common/layout/NavBar/NavBar.messages";
import { useDevMode } from "hooks/useDevMode/useDevMode";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { twClass } from "utils/twClass";
import { GlobalNavbarSearch } from "./GlobalNavbarSearch/GlobalNavbarSearch";
import { GlobalNotificationButton } from "./GlobalNotificationButton/GlobalNotificationButton";
import { UserDropDown } from "./UserDropDown/UserDropDown";

type ElementProps = {
  children: React.ReactNode;
  className?: string;
};

const Element = ({ children, className }: ElementProps): JSX.Element => (
  <div className={twClass("flex h-7 items-center", className)}>{children}</div>
);

export const NavBar: React.FC = () => {
  const intl = useIntl();

  const { show: privateModeEnabled } = useFeatureAccess("PRIVATE_MODE");
  const { devMode } = useDevMode();
  const [privateMode, togglePrivateMode] = usePrivateMode();

  const privateModeTooltip = (
    <span className="flex items-center gap-1">
      <Icon name="lock" size="md" type="outlined" />
      {intl.formatMessage(messages.privateModeTooltip)}
    </span>
  );

  return (
    <div
      className={twClass(
        "hidden md:flex print:hidden",
        "mr-5 items-center justify-end gap-4",
        "right-0 h-16",
        "z-higher",
        "text-slate-500",
        "md:absolute"
      )}
    >
      <Show when={devMode > 0}>
        <Element>
          <span>screen size:</span>
          <span className="sm:hidden">xs</span>
          <span className="hidden sm:inline-block md:hidden">sm</span>
          <span className="hidden md:inline-block lg:hidden">md</span>
          <span className="hidden lg:inline-block xl:hidden">lg</span>
          <span className="hidden xl:inline-block 2xl:hidden">xl</span>
          <span className="hidden 2xl:inline-block">2xl</span>
        </Element>
      </Show>
      <Element>
        <GlobalNavbarSearch />
      </Element>
      {privateModeEnabled && (
        <Element>
          <IconButton
            data-cy="UFPNbCFPSLxIa10XiOyWI"
            className={twClass(
              privateMode && "text-blue-500 hover:text-indigo-800"
            )}
            name={privateMode ? "lock" : "lock_open"}
            onClick={togglePrivateMode}
            tooltip={privateModeTooltip}
          />
        </Element>
      )}
      <Element>
        <GlobalNotificationButton />
      </Element>
      <Element>
        <HelpCenterButton />
      </Element>
      <Element>
        <UpgradeIndicator />
      </Element>
      <Element className="header-menu-element">
        <UserDropDown />
      </Element>
    </div>
  );
};
