import { createContext } from "react";

export declare type DropdownContext = {
  close: () => void;
  open: () => void;
};

export const DropdownContext = createContext<DropdownContext>({
  close: () => {},
  open: () => {},
});
