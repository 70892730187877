import { useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ObjectiveNameValidator } from "common/objective/ObjectiveNameValidator/ObjectiveNameValidator";
import { useObjectiveFormContext } from "common/objective/utils/useObjectiveFormContext/useObjectiveFormContext";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { messages as alignmentMessages } from "../../../form/FormObjectiveAlignToSelect/FormObjectiveAlignToSelect.messages";
import { messages as progressDriverMessages } from "../../../form/FormObjectiveProgressDriver/FormObjectiveProgressDriver.messages";
import { messages } from "../ObjectiveForm.messages";
import { ObjectiveFormValues } from "../ObjectiveForm.types";

export const ObjectiveFormDynamicHelperTexts = (): JSX.Element | null => {
  const intl = useIntl();
  const {
    values: { private: privateObjective, name },
  } = useFormikContext<ObjectiveFormValues>();
  const { focusedField: fieldName } = useObjectiveFormContext();
  const permissions = usePermissions();

  switch (fieldName) {
    case "name":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.objNameLabel)}
          </div>
          <ObjectiveNameValidator
            name={name}
            title={intl.formatMessage(messages.objNameValidationLabel)}
          />
        </>
      );
    case "description":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.objDescriptionLabel)}
          </div>
          <FormattedMessage
            defaultMessage="Provide context and explain why this Objective is a priority."
            id="objective:form:description:tip"
          />
        </>
      );
    case "groups":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.objOwnerLabel)}
          </div>
          <p className="mb-2.5">
            <FormattedMessage
              defaultMessage="Teams help you organize your goals, just like teams help you organize your people."
              id="jocrcN"
            />
          </p>
          <p className="mb-2.5">
            <FormattedMessage
              defaultMessage="If a team doesn't exist yet, simply type its name to create it."
              id="AIsVbq"
            />
          </p>
          {!permissions.canCreateGoalsInAnyGroup &&
            !permissions.canCreateCompanyGoals &&
            permissions.canCreateGoalsInOwnGroup && (
              <p className="mb-2.5">
                <FormattedMessage
                  defaultMessage="You can add Objectives only to the teams that you are a part of."
                  id="uPdj8e"
                />
              </p>
            )}
          {permissions.canCreateGoalsInAnyGroup &&
            !permissions.canCreateCompanyGoals && (
              <p className="mb-2.5">
                <FormattedMessage
                  defaultMessage="You can’t add Company objectives."
                  id="objective:form:owner:tip:notCompany"
                />
              </p>
            )}
        </>
      );
    case "lead":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.leadLabel)}
          </div>
          <FormattedMessage
            defaultMessage="The person responsible for driving and organizing all the efforts needed to achieve the Objective."
            id="objective:form:lead:tip"
          />
        </>
      );
    case "timeframe":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.timeframeLabel)}
          </div>
          <FormattedMessage
            defaultMessage="Time period by when the Objective needs to be achieved."
            id="objective:form:timeframe:tip"
          />
        </>
      );
    case "contributors":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.contributorsLabel)}
          </div>
          <FormattedMessage
            defaultMessage="Individual(s) responsible for creating and completing Initiatives that support progress of the OKR."
            id="objective:form:contributors:tip"
          />
        </>
      );
    case "tags":
      return (
        <>
          <div className="mb-2 font-semibold">
            {intl.formatMessage(messages.tagsLabel)}
          </div>
          <FormattedMessage
            defaultMessage="Add tags to easily find related Objectives via filters and search fields"
            id="objective:form:tags:tip"
          />
        </>
      );
    case "progressKeyResults":
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage {...progressDriverMessages.progressCalulation} />
          </div>
          <FormattedMessage
            {...progressDriverMessages.keyResultsHelper}
            values={{
              learnMore: (
                <a
                  href="https://support.perdoo.com/en/collections/2091196-learn-about-okr#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage {...messages.learnMore} />
                </a>
              ),
            }}
          />
        </>
      );
    case "progressAlignedObjectives":
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage {...progressDriverMessages.progressCalulation} />
          </div>
          <FormattedMessage
            {...progressDriverMessages.alignedObjectiveHelper}
            values={{
              learnMore: (
                <a
                  href="https://support.perdoo.com/en/collections/2091196-learn-about-okr#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage {...messages.learnMore} />
                </a>
              ),
            }}
          />
        </>
      );
    case "kpi":
      if (privateObjective) return null;
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage
              defaultMessage="Alignment"
              id="global:alignment"
            />
          </div>
          <FormattedMessage {...alignmentMessages.radioKpiHelper} />
        </>
      );
    case "parent":
      if (privateObjective) return null;
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage
              defaultMessage="Alignment"
              id="global:alignment"
            />
          </div>
          <FormattedMessage {...alignmentMessages.radioOkrHelper} />
        </>
      );
    case "strategicPillar":
      if (privateObjective) return null;
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage
              defaultMessage="Alignment"
              id="global:alignment"
            />
          </div>
          <FormattedMessage {...alignmentMessages.radioPillarHelper} />
        </>
      );
    case "alignToNone":
      if (privateObjective) return null;
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage
              defaultMessage="Alignment"
              id="global:alignment"
            />
          </div>
          <FormattedMessage {...alignmentMessages.radioNoneHelper} />
        </>
      );
    case "alignment":
      return (
        <>
          <div className="mb-2 font-semibold">
            <FormattedMessage
              defaultMessage="Alignment"
              id="global:alignment"
            />
          </div>
          <FormattedMessage {...alignmentMessages.alignmentLabel} />
        </>
      );
    default:
      return null;
  }
};
