import { gql } from "@apollo/client";
import {
  CompanyFeaturesFragmentDoc,
  StatusBreakdownDefaultFieldsFragmentDoc,
} from "@graphql";

export const UPSERT_COMPANY_MUTATION = gql`
  mutation UpsertCompanyMutation($input: UpsertCompanyMutationInput!) {
    upsertCompany(input: $input) {
      company {
        id
        name
        avatar
        vision
        ultimateGoal
        ultimateGoalDescription
        reflectionsTemplate
        objDescriptionTemplate
        initArchivingTemplate
        domains
        kpisEnabled
        onboardingEnabled
        stretchGoals
        privateOkrs
        checkInFrequency
        timezone
        notificationsSettings
        checkInDueWeekday
        checkInReminderTime
        checkInReminderDaysBeforeDue
        slackChannel
        owner {
          id
        }
        ambassadors {
          edges {
            node {
              id
            }
          }
        }
        cadence {
          id
        }
        executives {
          edges {
            node {
              id
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const COMPANY_DEFAULT_FRAGMENT = gql`
  fragment CompanyDefaultFields on company {
    id
    name
    vision
    ultimateGoal
    ultimateGoalDescription
    accountStatus
    createdDate
    kpisEnabled
    avatar
    domains
    managedSubscription
    isActive
    objDescriptionTemplate
    initArchivingTemplate
    reflectionsTemplate
    daysLeftInTrial
    companySize
    featureTier
    notificationsConfig
    stretchGoals
    notificationsSettings
    timezone
    checkInFrequency
    checkInDueWeekday
    checkInReminderTime
    checkInReminderDaysBeforeDue
    onlySendCheckInRemindersToLeads
    canPatch
    lastCheckInDate
    domainSignUp
    privateOkrs
    slackChannel
    viewOnlyLicenses
    currentCycle {
      id
      name
      start
      end
    }
    executives {
      edges {
        node {
          id
        }
      }
    }
    ssoconfig {
      id
      allowEmailPwAuth
    }
    ambassadors {
      edges {
        node {
          id
        }
      }
    }
    owner {
      id
    }
    cadence {
      id
    }
    integrations {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const COMPANY_CLOSING_QUESTION_TEMPLATES = gql`
  fragment CompanyClosingQuestionTemplates on company {
    closingQuestionTemplates {
      edges {
        node {
          id
          index
          text
          isRequired
        }
      }
    }
  }
`;

export const COMPANY_OBJECT_DESCRIPTION_TEMPLATE = gql`
  fragment CompanyObjectDescriptionTemplate on company {
    objDescriptionTemplate
  }
`;

export const COMPANY_STRETCH_GOALS = gql`
  fragment CompanyStretchGoals on company {
    stretchGoals
  }
`;

export const COMPANY_INIT_ARCHIVING_TEMPLATE = gql`
  fragment CompanyInitArchivingTemplate on company {
    initArchivingTemplate
  }
`;

export const SSO_DEFAULT_FRAGMENT = gql`
  fragment SsoDefaultFields on ssoConfig {
    __typename
    id
    type
    enabled
    acsUrl
    entityId
    autoProvisioning
    allowEmailPwAuth
    idpEntityId
    idpSsoUrl
    idpSloUrl
    canPatch
    canDelete
    idpCert
    userSignUpDefaultRole
    company {
      __typename
      id
    }
  }
`;

export const INTEGRATION_DEFAULT_FRAGMENT = gql`
  fragment IntegrationDefaultFields on integration {
    id
    __typename
    application
    isActive
    settings
    createdDate
    version
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment CompanyFragment on company {
    id
    featureTier
    daysLeftInTrial
    freeUserLimit
  }
`;

export const GET_COMPANY = gql`
  query getCompany {
    currentCompany {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_FEATURES = gql`
  query getCompanyFeatures {
    currentCompany {
      id
      featureTier
      reflectionsTemplate
      ...CompanyFeatures
    }
  }
  ${CompanyFeaturesFragmentDoc}
`;

export const GET_COMPANY_SETTINGS = gql`
  query getCompanySettings {
    currentCompany {
      id
      ambassadors {
        edges {
          node {
            id
          }
        }
      }
      owner {
        id
      }
      notificationsSettings
    }
  }
`;

export const COMPANY_HEADER_QUERY = gql`
  query getCompanyHeader {
    currentCompany {
      id
      avatar
      name
      vision
      objectives(stage: "ACTIVE", isCompanyGoal: true, private: false) {
        totalCount
        averageProgress
        statusBreakdown {
          ...StatusBreakdownDefaultFields
        }
      }
      groups(archivedDate_Isnull: true, parentGroups_Isnull: true) {
        totalCount
      }
      allKpis(isCompanyGoal: true, private: false, archived: false) {
        healthyCount
        totalCount
      }
    }
  }
  ${StatusBreakdownDefaultFieldsFragmentDoc}
`;

export const COMPANY_PROFILE_QUERY = gql`
  query getCompanyProfile {
    currentCompany {
      id
      name
      kpisEnabled
      canPatch
    }
  }
`;
