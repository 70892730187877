import React from "react";
// @ts-expect-error Could not find a declaration file for module 'react-giphy-searchbox'
import ReactGiphySearchbox from "react-giphy-searchbox";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Panel } from "common/overlay/Panel/Panel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useToggle } from "hooks/useToggle/useToggle";

interface Props {
  onSubmit: (url: string) => void;
}

export const ToolBarGif = ({ onSubmit }: Props) => {
  const [tooltipOpen, toggleTooltipOpen] = useToggle(false);
  return (
    <WithTooltip
      className="overflow-hidden"
      interactive
      maxWidth=""
      placement="bottom"
      theme="perdoo-white"
      tooltip={
        tooltipOpen && (
          <Panel onClose={toggleTooltipOpen} className="p-2" id="gif-picker">
            <ReactGiphySearchbox
              apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
              masonryConfig={[
                { columns: 2, gutter: 5, imageWidth: 110 },
                { columns: 3, gutter: 5, imageWidth: 120, mq: "700px" },
              ]}
              onSelect={(item: any) => {
                onSubmit(item.images.fixed_height.url);
                toggleTooltipOpen();
              }}
            />
          </Panel>
        )
      }
      visible={tooltipOpen}
    >
      <IconButton
        data-cy="SQ0maI2yXzicj1DsSa8dz"
        className="mt-0.5"
        name="gif"
        size="2xl"
        onClick={toggleTooltipOpen}
      />
    </WithTooltip>
  );
};
