module.exports = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000",
  white: "#fff",
  slate: {
    50: "#F9FAFB",
    100: "#F2F5F7",
    200: "#E7EDF1",
    300: "#DDE5EA",
    400: "#9BAFC5",
    500: "#68819D",
    600: "#3B5572",
    700: "#024462",
    800: "#14203C",
    900: "#02162C",
  },
  gray: {
    50: "#F8F8F8",
    100: "#EAEBEE",
    200: "#DEE0E3",
    300: "#C6CAD0",
    400: "#979BA2",
    500: "#6A707A", // nevada = #69707A"
    600: "#555A62",
    700: "#404349",
    800: "#151618",
    900: "#0B0B0C",
  },
  red: {
    50: "#FFEFEF",
    100: "#FFDEDE",
    200: "#FFBDBE",
    300: "#FF9C9D",
    400: "#FF8C8D",
    500: "#FF5A5C",
    600: "#E65153",
    700: "#B33F40",
    800: "#662425",
    900: "#401617",
  },
  yellow: {
    50: "#FFF8E6",
    100: "#FFF0CC",
    200: "#FFE9B3",
    300: "#FFE299",
    400: "#FFC533",
    500: "#FFB600",
    600: "#E6A400",
    700: "#A16207",
    800: "#664900",
    900: "#402D00",
  },
  green: {
    50: "#EBFBF4",
    100: "#D7F7E9",
    200: "#AFEED3",
    300: "#9BEAC9",
    400: "#5FDDA8",
    500: "#37D592",
    600: "#32C083",
    700: "#279566",
    800: "#16553A",
    900: "#10402C",
  },
  blue: {
    50: "#E6F6FE",
    100: "#C6EDFA",
    200: "#A9DCF8",
    300: "#70D3F2",
    400: "#37BAF6",
    500: "#05A9F4",
    600: "#0487C3",
    700: "#036592",
    800: "#2D5461",
    900: "#10303F",
  },
  indigo: {
    50: "#F0F4FC",
    100: "#E1E9F9",
    200: "#C3D2F2",
    300: "#A5BCEC",
    400: "#87A5E5",
    500: "#698FDF",
    600: "#5472B2",
    700: "#1D4C7D",
    800: "#04386E",
    900: "#2A3959",
  },
  purple: {
    50: "#F9F3FF",
    100: "#F2E6FE",
    200: "#E6CEFE",
    300: "#D3A9FD",
    400: "#C084FC",
    500: "#AD77E3",
    600: "#865CB0",
    700: "#734F97",
    800: "#60427E",
    900: "#3A284C",
  },
  pink: {
    50: "#FDF2F7",
    100: "#FAE5F0",
    200: "#F8D8E8",
    300: "#F3BED9",
    400: "#E98ABA",
    500: "#E77DB2",
    600: "#B9648E",
    700: "#8B4B6B",
    800: "#743F59",
    900: "#2E1924",
  },
};
