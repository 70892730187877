import { useField } from "formik";
import { isEmpty } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { PasswordField } from "common/fields/PasswordField/PasswordField";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";

interface Props {
  isLoading?: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

export const ConfirmPasswordModal = ({
  onCancel,
  onSubmit,
  onClose,
  isLoading,
}: Props) => {
  const intl = useIntl();
  const [field] = useField({ name: "password" });
  return (
    <ConfirmationModal
      isLoading={isLoading}
      buttonDisabled={isEmpty(field.value)}
      action={onSubmit}
      onCancel={onCancel}
      close={onClose}
      confirmText={intl.formatMessage({
        defaultMessage: "Confirm Password",
        id: "vfG+nh",
      })}
      title={intl.formatMessage({
        defaultMessage: "Confirm Password",
        id: "vfG+nh",
      })}
    >
      <div className="flex flex-col gap-2">
        <div>
          <FormattedMessage
            defaultMessage="For security purposes, please confirm your current password."
            id="c8PCry"
          />
        </div>
        <PasswordField name="password" />
        <i>
          <FormattedMessage
            defaultMessage="Note: This step helps ensure that your account remains secure."
            id="Esqvz/"
          />
        </i>
      </div>
    </ConfirmationModal>
  );
};
