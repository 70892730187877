import {
  GetAllTagsQueryVariables,
  TagDefaultFragment,
  useGetAllTagsQuery,
} from "@graphql";
import { useField } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Show } from "common/controlFlow";
import { Label } from "common/inputs";
import { Select } from "legacy/components/Select/Select";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";

type Tag = TagDefaultFragment;

type Props = {
  "data-cy"?: string;
  label?: string;
  name: string;
  onAfterChange?: (u: Tag[]) => void;
  optional?: boolean;
  variables?: GetAllTagsQueryVariables;
};

export const TagField = ({
  "data-cy": dataCy,
  label,
  name,
  onAfterChange,
  optional,
  variables,
}: Props): JSX.Element => {
  const [field, , helpers] = useField<string[] | undefined>(name);
  const { $t } = useIntl();

  const { data } = useGetAllTagsQuery({
    variables,
  });
  const tags = mapEdges(data?.allTags.edges);
  const selectedTags = field.value
    ?.map((tagId) => tags.find((tag) => tag.id === tagId))
    .filter(isSet);

  const handleChange = (newTags: Tag[] | null) => {
    newTags ??= [];
    helpers.setValue(newTags.map((t) => t.id));
    onAfterChange?.(newTags);
  };

  const search = ({ data }: { data: Tag }, query: string) =>
    data.name.toLowerCase().includes(query.toLowerCase());

  return (
    <div>
      <Show when={label}>
        <Label className="flex items-center justify-between" htmlFor={name}>
          <div className="flex items-center gap-2">
            <span>{label}</span>

            <Show when={optional}>
              <div className="text-sm text-slate-500">
                <FormattedMessage
                  defaultMessage="Optional"
                  id="global:form:optional"
                />
              </div>
            </Show>
          </div>
        </Label>
      </Show>
      <Select
        autoFocus
        className="tagMultiSelect"
        closeMenuOnSelect={false}
        data-cy={dataCy ?? "tagMultiSelect"}
        filterOption={search}
        getOptionLabel={({ name }: Tag) => name}
        getOptionValue={({ id }: Tag) => id}
        isClearable
        isInForm
        isMulti
        name={name}
        onChange={handleChange}
        options={tags}
        placeholder={$t({ defaultMessage: "Select tags...", id: "BaH+sS" })}
        value={selectedTags}
      />
    </div>
  );
};
