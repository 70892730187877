import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addUltimateGoal: {
    defaultMessage: "Add Ultimate Goal",
    id: "roadmap:ultimateGoal:add",
  },
  addUltimateGoalError: {
    defaultMessage: "Error adding Ultimate Goal",
    id: "roadmap:ultimateGoal:add:error",
  },
  editUltimateGoal: {
    defaultMessage: "Edit Ultimate Goal",
    id: "roadmap:ultimateGoal:edit",
  },
  ultimateGoalUpdated: {
    defaultMessage: "Ultimate Goal Updated!",
    id: "roadmap:ultimateGoal:added",
  },
});
