import inst, { PluginFunc, UnitType } from "dayjs";

declare module "dayjs" {
  interface Dayjs {
    ceil(unit: UnitType, amount: number): inst.Dayjs;
  }
}
const ceil: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.ceil = function (unit, amount) {
    return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
  };
};
export { ceil };
