import { useRouter } from "next/router";
import { ObjectiveModalType } from "hooks/useModalRouter/useModalRouter";
import { AddObjectiveModal } from "./AddObjectiveModal/AddObjectiveModal";

export const ObjectiveModals = (): JSX.Element | null => {
  const { query } = useRouter();

  const { type } = query;

  if (type === ObjectiveModalType.ADD) {
    return <AddObjectiveModal open />;
  }

  // Other modal types for objectives should be added here

  return null;
};
