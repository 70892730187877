/**
 * checks if the current browser is outdated based on the set requirements. resolves to true in that case.
 */
export const browserOutdated = async (): Promise<boolean> => {
  const browserUpdate = (await import("browser-update")).default;
  return new Promise<boolean>((resolve) => {
    browserUpdate({
      // test: true, // uncomment to always show alert
      nomessage: true,
      onshow: () => {
        resolve(true);
      },

      reminder: 0,
      required: {
        // firefox
        c: -5,
        // chrome
        e: -5,
        f: -5, // edge & IE
        o: -5, // opera
        s: -1, // safari
      },
    });
  });
};
