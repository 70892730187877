import { namedOperations, useUpsertUserMutation } from "@graphql";
import { useState } from "react";
import { useIntl } from "react-intl";
import { SelectUser } from "common/select/UserSelect/UserSelect";
import { roles } from "constants/roles";
import { ADD_USER } from "constants/tracking";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

export const NEW_USER_ID = "newUser";

export const useAddUser = () => {
  const { $t } = useIntl();
  const [loading, setLoading] = useState(false);

  const [upsertUser] = useUpsertUserMutation({
    refetchQueries: [
      namedOperations.Query.getActiveUsers,
      namedOperations.Query.getCurrentUserInfo,
      namedOperations.Query.BillableUsersCount,
      namedOperations.Query.getUserDirectReports,
    ],
  });

  const inviteUser = async (email: string): Promise<SelectUser | null> => {
    setLoading(true);
    const response = await upsertUser({
      variables: {
        input: {
          email,
          invite: true,
          role: roles.standard,
        },
      },
    });

    track(ADD_USER, {
      source: "inline creation",
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertUser?.errors
    );

    setLoading(false);

    if (hasError) {
      toast.failure(
        $t({
          defaultMessage: "Something went wrong when inviting the user.",
          id: "0kne9/",
        })
      );
    }

    setLoading(false);
    return response.data?.upsertUser?.user ?? null;
  };
  return { inviteUser, loading };
};
