import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { FieldWithHelper } from "common/form/FieldWithHelper/FieldWithHelper";
import { useUpgradeAction } from "common/inAppUpgrade/hooks";
import { PremiumBanner } from "common/inAppUpgrade/PremiumBanner/PremiumBanner";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { useCompany } from "hooks/useCompany/useCompany";

export const AsanaUpgradeBanner = (): JSX.Element | null => {
  const company = useCompany();
  const upgradeAction = useUpgradeAction();

  if (!company.isOnTrialPlan) return null;

  return (
    <FieldWithHelper
      renderField={() => (
        <div className="-mt-3 rounded-b border-x border-b px-4 py-4">
          <PremiumBanner>
            <FormattedMessage
              defaultMessage="Asana integration is available on Premium & Supreme plans. <a>Upgrade</a> to keep your goals linked."
              id="resultForm:asana:premiumBanner:message"
              values={{
                a: (children: ReactNode) => (
                  <Anchor
                    data-cy="U0sWe4jSztU0v6cNqKfqr"
                    onClick={upgradeAction}
                  >
                    {children}
                  </Anchor>
                ),
              }}
            />
          </PremiumBanner>
        </div>
      )}
    />
  );
};
