import { MouseEventHandler, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { Icon } from "common/icons";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";

type Props = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: ReactNode;
};

export const ProgressCTAButton = ({
  onClick,
  disabled,
  tooltip,
}: Props): JSX.Element => {
  return (
    <WithTooltip interactive tooltip={tooltip}>
      <Button
        size="regular"
        variant="ghost"
        className="flex w-full items-center justify-center font-semibold text-slate-800"
        data-cy="upgrade-progress-CTA"
        disabled={disabled}
        onClick={onClick}
      >
        <div className="flex space-x-2 items-center">
          <Icon size="xl" name="update_progress" />
          <div>
            <FormattedMessage defaultMessage="Update progress" id="a0yZl7" />
          </div>
        </div>
      </Button>
    </WithTooltip>
  );
};
