import { size } from "lodash";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { getCompleteLink, routes } from "route-configs";
import { ObjectiveType } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import { Subtext } from "common/misc/Subtext/Subtext";
import { objectiveStages } from "common/objective/constants";
import { useCloseObjectiveModal } from "common/objective/modals/CloseObjectiveModal/hooks/useCloseObjectiveModal/useCloseObjectiveModal";
import { ObjectiveClosedSubtext } from "common/objective/ObjectiveClosedSubtext/ObjectiveClosedSubtext";
import { PermissionErrorTooltip } from "common/overlay/PermissionErrorTooltip/PermissionErrorTooltip";
import { MODAL_TYPES } from "constants/index";
import { useObjectiveActions } from "hooks/useObjectiveActions/useObjectiveActions";
import { showModal } from "legacy/actions/actions";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";
import { ProfileOkrListContext } from "../../context";
import { ObjectiveAlignedToSubText } from "./ObjectiveAlignedToSubText/ObjectiveAlignedToSubText";

type Props = {
  objective: ObjectiveType;
};

export const ObjectiveSubtext = ({ objective }: Props): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { reFetchQueries: profileOkrListQueries } = useContext(
    ProfileOkrListContext
  );

  const { activateObjective } = useObjectiveActions({
    objective,
  });

  const { lead, contributors, timeframe, stage, canPatch } = objective;

  const leadLink = getCompleteLink(routes.users.single.okrs, {
    id: lead?.id,
  }).absolutePath;

  const { openCloseObjectiveModal } = useCloseObjectiveModal({
    refetchQueries: [...profileOkrListQueries],
  });

  const showContributors = () => {
    dispatch(
      showModal(MODAL_TYPES.USER_LIST, {
        title: intl.formatMessage({
          defaultMessage: "Contributors",
          id: "initiative:list:contributorsColumn",
        }),
        users: mapEdges(contributors.edges),
      })
    );
  };

  return (
    <div className="flex flex-wrap space-x-3">
      <Show when={isSet(objective.closingNote)}>
        <ObjectiveClosedSubtext key="closingNote" objective={objective} />
      </Show>

      <Subtext
        key="person"
        data-cy="objectiveLead"
        href={leadLink}
        icon="person"
        text={lead?.fullName}
      />

      <Show when={size(contributors.edges) > 0}>
        <Subtext
          key="people_alt"
          data-cy="objectiveContributors"
          icon="people_alt"
          onClick={showContributors}
          text={
            <FormattedMessage
              defaultMessage="{count} contributors"
              id="objective:contributors:count"
              values={{
                count: size(contributors.edges),
              }}
            />
          }
        />
      </Show>

      <Subtext
        key="calendar_today"
        data-cy="objectiveTimeframe"
        icon="calendar_today"
        text={timeframe.name}
      />

      <ObjectiveAlignedToSubText key={objective.id} objective={objective} />
      <Show when={stage !== objectiveStages.closed}>
        {timeframe.isPast && (
          <PermissionErrorTooltip key="archive" show={!canPatch}>
            <Subtext
              className="group"
              data-cy="objectiveClose"
              disabled={!canPatch}
              icon="archive"
              iconClass={twClass({
                "text-red-500 group-hover:text-red-700": canPatch,
                "text-slate-400": !canPatch,
              })}
              onClick={() =>
                openCloseObjectiveModal({
                  objective,
                })
              }
              spanClass={twClass({
                "text-red-500 group-hover:text-red-700": canPatch,
                "text-slate-400": !canPatch,
              })}
              text={
                <FormattedMessage
                  defaultMessage="Close objective"
                  id="objective:stage:closeObjective"
                />
              }
            />
          </PermissionErrorTooltip>
        )}

        {stage === objectiveStages.draft && (
          <>
            <Subtext
              key="draft"
              data-cy="objectiveDraft"
              icon="library_books"
              text={
                <FormattedMessage
                  defaultMessage="Draft"
                  id="objective:status:draft"
                />
              }
            />
            <PermissionErrorTooltip key="unarchive" show={!canPatch}>
              <Subtext
                className="group"
                data-cy="objectiveActivate"
                disabled={!canPatch}
                icon="unarchive"
                iconClass={twClass({
                  "text-blue-500 group-hover:text-indigo-800": canPatch,
                  "text-slate-400": !canPatch,
                })}
                onClick={activateObjective}
                spanClass={twClass({
                  "text-blue-500 group-hover:text-indigo-800": canPatch,
                  "text-slate-400": !canPatch,
                })}
                text={
                  <FormattedMessage
                    defaultMessage="Activate"
                    id="objective:status:activate"
                  />
                }
              />
            </PermissionErrorTooltip>
          </>
        )}
      </Show>
    </div>
  );
};
