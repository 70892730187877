import { OneOnOneRepeatFrequency } from "@graphql";
import { lowerCase } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { FormattedTime } from "common/misc/FormattedTime/FormattedTime";
import { getMeetingLocalTime } from "common/oneOnOnes/utils";
import { useCompany } from "hooks/useCompany/useCompany";
import { EditOneOnOneMeetingFormValues } from "../EditOneOnOneMeetingForm";
import { messages as repeatFrequencyMessages } from "../useOneOnOneMeetingFrequencyOptions/useOneOnOneMeetingFrequencyOptions";

type Props = {
  values: EditOneOnOneMeetingFormValues;
};

export const OneOnOneMeetingDateInfo = ({
  values,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const company = useCompany();
  const { repeatFrequency } = values;

  if (
    repeatFrequency === OneOnOneRepeatFrequency.NoRepeat ||
    repeatFrequency === OneOnOneRepeatFrequency.Integration ||
    !repeatFrequency
  ) {
    return null;
  }

  const meetingLocalTime = getMeetingLocalTime(
    values.dueDate,
    values.meetingTime,
    company.timezone
  );
  const date = meetingLocalTime?.toISOString();

  if (!date) {
    return null;
  }

  return (
    <div className="mt-1 flex items-center gap-2 text-sm text-slate-500">
      <Icon name="info_outline" />
      <FormattedMessage
        defaultMessage="Your upcoming 1:1 meetings will be {repeatFrequency} at {meetingTime}"
        id="MLpR3R"
        values={{
          meetingTime: <FormattedTime value={date} />,
          repeatFrequency: lowerCase(
            intl.formatMessage(repeatFrequencyMessages[repeatFrequency])
          ),
        }}
      />
    </div>
  );
};
