import {
  namedOperations,
  PerformanceReviewRepeatFrequency,
  useGetPerformanceReviewQuery,
  useUpsertPerformanceReviewMutation,
} from "@graphql";
import dayjs from "dayjs";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import * as Yup from "yup";
import { Modal } from "common/overlay/Modal/Modal";
import { EDIT_PERFORMANCE_REVIEW } from "constants/tracking";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import {
  EditPerformanceReviewForm,
  EditPerformanceValues,
} from "../PerformanceReviewForm/EditPerformanceReviewForm";

const validationSchema = Yup.object().shape({
  dueDate: Yup.date()
    .min(dayjs().add(-1, "day"), "Meeting date must be today or later.")
    .typeError("Required")
    .required(),
  repeatFrequency: Yup.string()
    .oneOf(Object.values(PerformanceReviewRepeatFrequency))
    .required("Please select a valid repeat frequency."),
});

const messages = defineMessages({
  success: {
    defaultMessage: "Successfully updated Performance Review!",
    id: "performanceReviews:edit:success",
  },
  title: {
    defaultMessage: "Edit Performance Review",
    id: "performanceReviews:edit:title",
  },
});

type Props = {
  performanceReviewId: string;
};

export const EditPerformanceReviewModal = ({
  performanceReviewId,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const { close } = useModalRouter();
  const { data, loading } = useGetPerformanceReviewQuery({
    fetchPolicy: "cache-first",
    variables: {
      id: performanceReviewId,
    },
  });
  const [upsertPerformanceReview] = useUpsertPerformanceReviewMutation({
    refetchQueries: [
      namedOperations.Query.GetAllPerformanceReviews,
      namedOperations.Query.GetUserPerformanceReviewsDue,
    ],
  });

  const handleSubmit = async (
    values: EditPerformanceValues,
    actions: FormikHelpers<EditPerformanceValues>
  ) => {
    const dueDate = dayjs(values.dueDate).format("YYYY-MM-DD");

    const response = await upsertPerformanceReview({
      variables: {
        input: {
          ...values,
          dueDate,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertPerformanceReview?.errors
    );

    actions.setSubmitting(false);
    if (!hasError) {
      toast.success(intl.formatMessage(messages.success));
      track(EDIT_PERFORMANCE_REVIEW, {
        first_review_date: values.dueDate,
        reviewee_frequency: values.repeatFrequency,
        user_id: data?.performanceReview?.reviewer?.id,
      });
      close();
    }
  };
  if (!data?.performanceReview) return null;

  const { id, dueDate, reviewee, repeatFrequency } = data.performanceReview;
  const initialValues = {
    dueDate,
    id,
    repeatFrequency,
  };
  return (
    <Modal
      backdropClose={false}
      isOpen
      loading={loading}
      onClose={close}
      size="sm"
      title={intl.formatMessage(messages.title)}
    >
      <Formik<EditPerformanceValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <EditPerformanceReviewForm onCancel={close} reviewee={reviewee} />
      </Formik>
    </Modal>
  );
};
