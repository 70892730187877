/**
 * possible choices for the groups type filter. detemines which groups' data should be included
 * in the query results.
 * TODO: find a way to expose it on the backend and use a generated type from apollo codegen.
 */
export const GROUPS_TYPE_CHOICES = {
  ALL: "ALL",
  COMPANY: "COMPANY",
  GROUP: "GROUP",
  TOP_LEVEL: "TOP_LEVEL",
};
