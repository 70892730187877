import React, { ReactNode } from "react";
import { PremiumBadge } from "common/inAppUpgrade/PremiumBadge/PremiumBadge";

type Props = {
  children?: ReactNode;
};

export const PremiumBanner = ({ children }: Props): JSX.Element => (
  <div className="flex items-center rounded p-4 text-sm text-slate-800 bg-blue-50">
    <div className="mr-2">
      <PremiumBadge size="small" />
    </div>
    <div>{children}</div>
  </div>
);
