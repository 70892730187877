import {
  GoalUpdateCycle,
  Kpi,
  MetricUnit,
  namedOperations,
  UpsertKpiMutationInput,
  useUpsertKpiMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useIntl } from "react-intl";
import { getOwnershipInfo } from "common/goal/GoalOwners/GoalOwners.utils";
import { useCompany } from "hooks/useCompany/useCompany";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

type KpiParam = Derive<
  Kpi,
  {
    description: true;
    goalUpdateCycle: true;
    id: true;
    lead: {
      id: true;
    };
    metricUnit: true;
    private: true;
  }
>;

export type KpiAlignmentInput = Pick<UpsertKpiMutationInput, "strategicPillar">;

type BaseUpsertInput = {
  input: UpsertKpiMutationInput;
  successMessage: string;
};

export const useInlineKpiActions = (kpi: KpiParam) => {
  const intl = useIntl();
  const company = useCompany();
  const [upsertKpi] = useUpsertKpiMutation({
    refetchQueries: [
      namedOperations.Query.getKpiModalBaseKpi,
      namedOperations.Query.getKpiModalBaseKpiTimeline,
      namedOperations.Query.getKpiBoardKpis,
    ],
  });

  const baseUpsert = async (input: BaseUpsertInput) => {
    const response = await upsertKpi({
      variables: {
        input: {
          ...input.input,
          id: kpi.id,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertKpi?.errors
    );
    if (hasError) return false;

    toast.success(input.successMessage);
    return true;
  };

  const setName = async (value: string) => {
    return await baseUpsert({
      input: { name: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Kpi name updated!",
        id: "jniML+",
      }),
    });
  };

  const setUpdateFrequency = async (value: GoalUpdateCycle | null) => {
    if (!value || value === kpi.goalUpdateCycle) return false;
    return await baseUpsert({
      input: { goalUpdateCycle: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Update frequency updated!",
        id: "Dbcl+C",
      }),
    });
  };

  const setDescription = async (value: string) => {
    return await baseUpsert({
      input: { description: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Description updated!",
        id: "IF0JtV",
      }),
    });
  };

  const setVisibility = async (isPrivate: boolean) => {
    if (isPrivate === kpi.private) return false;
    return await baseUpsert({
      input: { private: isPrivate },
      successMessage: intl.formatMessage({
        defaultMessage: "Visibility updated!",
        id: "ofSvWp",
      }),
    });
  };

  const setLead = async (value: string | null) => {
    if (!value || value === kpi.lead?.id) return false;
    return await baseUpsert({
      input: { lead: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Kpi lead updated!",
        id: "9lYdke",
      }),
    });
  };

  const setAlignment = (value: KpiAlignmentInput) =>
    baseUpsert({
      input: { strategicPillar: value.strategicPillar },
      successMessage: intl.formatMessage({
        defaultMessage: "Alignment updated!",
        id: "Lt6E2E",
      }),
    });

  const setMetricUnit = async (value: MetricUnit) => {
    if (value === kpi.metricUnit) return false;
    return await baseUpsert({
      input: { metricUnit: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Kpi metric updated!",
        id: "+LVPpj",
      }),
    });
  };

  const setTags = (value: string[]) =>
    baseUpsert({
      input: { tags: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Kpi tags updated!",
        id: "GiT2S/",
      }),
    });

  const setOwners = async (value: string[]) => {
    const { groups, isCompanyGoal } = getOwnershipInfo(value, company.id);
    return await baseUpsert({
      input: { groups, isCompanyGoal },
      successMessage: intl.formatMessage({
        defaultMessage: "Kpi owners updated!",
        id: "hfWKDU",
      }),
    });
  };

  const setContributors = (value: string[]) =>
    baseUpsert({
      input: { visibleTo: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Kpi users visibility updated!",
        id: "+Zmaad",
      }),
    });

  return {
    setAlignment,
    setContributors,
    setDescription,
    setLead,
    setMetricUnit,
    setName,
    setOwners,
    setTags,
    setUpdateFrequency,
    setVisibility,
  };
};
