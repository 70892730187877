import React from "react";
import { FormattedMessage } from "react-intl";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const { TagAddedToObjective, TagRemovedFromObjective } = EventTypes;

export const TagEventFormatters: Record<string, EventFormatter> = {
  [TagAddedToObjective]: {
    getComment: (): null => null,
    getString: (attr, { tags }): JSX.Element => {
      const tag = tags.find((item) => item.id === attr.targetId);
      return (
        <FormattedMessage
          defaultMessage="tagged the Objective {tagName}"
          id="event:formatter:tag:added"
          values={{
            tagName: <Target element={tag?.name} />,
          }}
        />
      );
    },
  },
  [TagRemovedFromObjective]: {
    getComment: (): null => null,
    getString: (attr, { tags }): JSX.Element => {
      const tag = tags.find((item) => item.id === attr.targetId);
      return (
        <FormattedMessage
          defaultMessage="removed the tag {tagName} from the Objective"
          id="event:formatter:tag:removed"
          values={{
            tagName: <Target element={tag?.name} />,
          }}
        />
      );
    },
  },
};
