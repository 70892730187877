import React from "react";
import Script from "next/script";
import { useIntercom } from "hooks/useIntercom/useIntercom";
import { GOOGLE_TAG_MANAGER_ID } from "config";
import { useGoogleTagManager } from "hooks/useGoogleTagManager/useGoogleTagManager";

export const Scripts = (): JSX.Element => {
  useIntercom();
  useGoogleTagManager();

  return (
    <>
      <Script
        type="module"
        src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `!function (e, t, r, n, a) { if (!e[a]) { for (var i = e[a] = [], s = 0; s < r.length; s++) { var c = r[s]; i[c] = i[c] || function (e) { return function () { var t = Array.prototype.slice.call(arguments); i.push([e, t]) } }(c) } i.SNIPPET_VERSION = "1.0.1"; var o = t.createElement("script"); o.type = "text/javascript", o.async = !0, o.src = "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" + n + "/" + a + ".js"; var l = t.getElementsByTagName("script")[0]; l.parentNode.insertBefore(o, l) } }(window, document, ["survey", "reset", "config", "init", "set", "get", "event", "identify", "track", "page", "screen", "group", "alias"], "Slb8ghAYs8kGtmU9", "delighted");`,
        }}
        id="delighted-script"
      />
      <Script
        id="chargebee-script"
        src="https://js.chargebee.com/v2/chargebee.js"
        strategy="lazyOnload"
      />
      {GOOGLE_TAG_MANAGER_ID && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
          `,
          }}
        />
      )}
    </>
  );
};
