import React, { useContext, useEffect } from "react";
import { SidebarHeader } from "common/layout/Sidebar/SidebarHeader/SidebarHeader";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { twClass } from "utils/twClass";
import { SidebarLinks } from "./SidebarLinks/SidebarLinks";
import { SidebarWrapper } from "./SidebarWrapper/SidebarWrapper";

export const Sidebar = (): JSX.Element => {
  const { isCollapsed, toggleSidebarCollapse } = useContext(CollapsibleContext);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!windowSize?.width || isCollapsed) {
      return;
    }
    if (windowSize.width < 1280) {
      toggleSidebarCollapse?.();
    }
  }, [windowSize]);

  return (
    <div className="h-screen" data-cy="sideBar">
      <SidebarWrapper
        className={twClass(
          "z-higher h-full print:hidden",
          !isCollapsed && "w-[250px]",
          isCollapsed && "w-[80px]"
        )}
      >
        <SidebarHeader />
        <SidebarLinks />
      </SidebarWrapper>
    </div>
  );
};
