import { useGetResultQuery } from "@graphql";
import SplitPane from "react-split-pane";
import { ResultContextProvider } from "common/context/resultContext";
import { TimelineContext } from "common/event/TimelineContext/TimelineContext";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { useModalSplitter } from "hooks/useModalSplitter/useModalSplitter";
import { twClass } from "utils/twClass";
import { useResultTimeline } from "./hooks/useResultTimeline";
import { ResultNavigation } from "./ResultNavigation/ResultNavigation";
import { ResultSidebar } from "./ResultSidebar/ResultSidebar";
import { ResultSummary } from "./ResultSummary/ResultSummary";

interface Props {
  resultId: string;
}

export const ResultDetails = ({ resultId }: Props) => {
  const { handleSplitterResize, isDragging, setIsDragging, splitPos } =
    useModalSplitter({ defaultSize: 400 });
  const { loading, data } = useGetResultQuery({
    variables: { resultId: resultId },
  });

  const { timelineContext } = useResultTimeline(resultId);

  if (loading) {
    return <SpinnerFit />;
  }

  const result = data?.result;
  return (
    <div className="flex flex-col bg-slate-50 h-full">
      {result && (
        <>
          <ResultNavigation resultId={resultId} />
          <div className="flex flex-auto overflow-hidden mb-5 px-5">
            <TimelineContext.Provider value={timelineContext}>
              <ResultContextProvider value={result}>
                {/* @ts-expect-error FIXME: TS2322: Type '{ children: Element[]; ... }' is not assignable to type ... */}
                <SplitPane
                  primary="second"
                  data-cy="objectiveSplitter"
                  className="!relative flex-reverse"
                  defaultSize={splitPos}
                  minSize={400}
                  maxSize={800}
                  onChange={handleSplitterResize}
                  onDragFinished={() => setIsDragging(false)}
                  onDragStarted={() => setIsDragging(true)}
                  resizerClassName={twClass(
                    "bg-white border-y border-r !border-r-slate-300 hover:!border-r-blue-500",
                    {
                      "!border-r-blue-500": isDragging,
                    }
                  )}
                  split="vertical"
                >
                  <div
                    className={twClass(
                      "h-full modal-scrollbar modal-scrollbar-thumb overflow-y-scroll overflow-x-hidden",
                      "border border-r-0 border-slate-300 rounded-tl-md rounded-bl-md bg-white"
                    )}
                  >
                    <ResultSummary />
                  </div>
                  <div
                    className={twClass(
                      "h-full modal-scrollbar modal-scrollbar-thumb overflow-y-auto bg-slate-50",
                      "border-b p-4 border rounded-tr-md rounded-br-md border-l-0 border-slate-300"
                    )}
                  >
                    <ResultSidebar />
                  </div>
                </SplitPane>
              </ResultContextProvider>
            </TimelineContext.Provider>
          </div>
        </>
      )}
    </div>
  );
};
