import {
  namedOperations,
  useGetResultTimelineLazyQuery,
  useRefetchObjectiveLastCommentLazyQuery,
  useUpsertCommentMutation,
} from "@graphql";
import { camelCase, size } from "lodash";
import { objectTypes } from "constants/objects";
import { ADD_COMMENT } from "constants/tracking";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { fetchObjectiveTimeline } from "../../../../objective/actions";

type AddComment = {
  content: string;
  entityId: string;
  entityType: string;
  eventId?: string;
  filter: any;
};
export const useAddComment = () => {
  const [addComment] = useUpsertCommentMutation({
    refetchQueries: [
      namedOperations.Query.getKpiModalBaseKpiTimeline,
      namedOperations.Query.getResultTimeline,
    ],
  });
  const [refetchObjectiveComment] = useRefetchObjectiveLastCommentLazyQuery();
  const [getResultTimeline] = useGetResultTimelineLazyQuery();

  const upsertComment = async ({
    entityType,
    filter,
    entityId,
    eventId,
    content,
  }: AddComment) => {
    const input = eventId
      ? {
          content,
          [objectTypes.event]: eventId,
        }
      : {
          content,
          [camelCase(entityType)]: entityId,
        };

    const response = await addComment({
      variables: {
        input,
      },
    });
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (size(response?.data?.upsertComment?.errors) > 0) {
      toast.failure("Error adding comment");
      return;
    }

    if (entityType === objectTypes.objective) {
      refetchObjectiveComment({
        variables: { id: entityId },
      });
      fetchObjectiveTimeline(entityId, { filter });
    } else if (entityType === objectTypes.keyresult) {
      getResultTimeline({ variables: { id: entityId, ...filter } });
    }

    track(ADD_COMMENT, {
      okr_type: entityType,
      reply: Boolean(eventId),
    });

    toast.success("Comment added");
  };

  return upsertComment;
};
