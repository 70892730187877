import React from "react";
import { Icon } from "common/icons/Icon/Icon";

type Props = {
  name: string;
};

export const Timeframe = ({ name }: Props): JSX.Element => (
  <div className="flex items-center gap-3 overflow-hidden">
    <Icon name="calendar_today" size="xl" />
    <span className="max-w-3xl truncate">{name}</span>
  </div>
);
