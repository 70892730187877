import { roles } from "constants/roles";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { hasUserAccess } from "utils/permissions";

export type UsePermissionsHook = {
  canAccessIntegrations: boolean;
  canAddDirectReports: boolean;
  canCheckIn: boolean;
  canCreateCompanyGoals: boolean;
  canCreateGoalsInAnyGroup: boolean;
  canCreateGoalsInOwnGroup: boolean;
  canCreateGroups: boolean;
  canCreateKpis: boolean;
  canCreateKudos: boolean;
  canCreateOkrs: boolean;
  canCreatePerformanceReviews: boolean;
  canCreateSavedReports: boolean;
  canCreateStrategicPillars: boolean;
  canCreateUsers: boolean;
  canEditKpiBoards: boolean;
  canExportData: boolean;
  canPrintData: boolean;
  canRemoveStrategicPillars: boolean;
  canSeeQuickStartSteps: boolean;
  canUpdateGrowthBoard: boolean;
  canUpdateKpis: boolean;
  canUpdateOkrs: boolean;
  canUpdateStrategicPillars: boolean;
  canUpdateUltimateGoal: boolean;
  canUpdateUsers: boolean;
};

/**
 * checks if a user has permissions for a given action based on their role.
 */
export const usePermissions = (): UsePermissionsHook => {
  const user = useCurrentUser();

  const hasDirectReports = (user?.directReports.edges.length ?? 0) > 0;

  return {
    canAccessIntegrations: hasUserAccess(user, roles.superAdmin),
    // Reference: https://perdoo.slack.com/archives/C03KAM3MQHX/p1680005292012339
    canAddDirectReports: hasUserAccess(user, roles.admin),
    canCheckIn: hasUserAccess(user, roles.standard),
    canCreateCompanyGoals: hasUserAccess(user, roles.superAdmin),
    canCreateGoalsInAnyGroup: hasUserAccess(user, roles.admin),
    canCreateGoalsInOwnGroup: hasUserAccess(user, roles.standard),
    canCreateGroups: hasUserAccess(user, roles.admin),
    canCreateKpis: hasUserAccess(user, roles.standard),
    canCreateKudos: hasUserAccess(user, roles.standard),
    canCreateOkrs: hasUserAccess(user, roles.standard),
    canCreatePerformanceReviews:
      hasUserAccess(user, roles.admin) || hasDirectReports,
    canCreateSavedReports: hasUserAccess(user, roles.standard),
    canCreateStrategicPillars: hasUserAccess(user, roles.superAdmin),
    canCreateUsers: hasUserAccess(user, roles.admin),
    canEditKpiBoards: hasUserAccess(user, roles.admin),
    canExportData: hasUserAccess(user, roles.admin),
    canPrintData: hasUserAccess(user, roles.admin),
    canRemoveStrategicPillars: hasUserAccess(user, roles.superAdmin),
    canSeeQuickStartSteps: hasUserAccess(user, roles.superAdmin),
    canUpdateGrowthBoard: hasUserAccess(user, roles.admin),
    canUpdateKpis: hasUserAccess(user, roles.admin),
    canUpdateOkrs: hasUserAccess(user, roles.admin),
    canUpdateStrategicPillars: hasUserAccess(user, roles.superAdmin),
    canUpdateUltimateGoal: hasUserAccess(user, roles.superAdmin),
    canUpdateUsers: hasUserAccess(user, roles.admin),
  };
};
