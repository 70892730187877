import { Form, Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import invariant from "tiny-invariant";
import * as Yup from "yup";
import { CancelButton, SaveButton, TextButton } from "common/buttons";
import { Show } from "common/controlFlow";
import { JiraProgressTypeField } from "common/form/JiraProgressTypeField/JiraProgressTypeField";
import { PlainTextField } from "common/form/PlainTextField/PlainTextField";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { JiraIssueField } from "common/result/ResultForm/JiraIssueField/JiraIssueField";
import { JiraJqlPreview } from "common/result/ResultForm/JiraJqlPreview/JiraJqlPreview";
import { JiraSourceTypeField } from "common/result/ResultForm/JiraSourceTypeField/JiraSourceTypeField";
import {
  integrationFieldToJiraData,
  jiraDataToIntegrationField,
} from "common/result/utils/jira";
import { JiraFormLogic } from "../JiraFormLogic/JiraFormLogic";

type Values = {
  jiraIssue?: string;
  jiraJql?: string;
  jiraProgressType?: string;
  jiraSourceType: string;
};

type Props = {
  onClose?: () => void;
  onDisconnect?: () => void;
  onSubmit?: (value: string) => void;
  /** The goal's integration field */
  value: string | null;
};

const schema = Yup.object().shape({
  jiraIssue: Yup.string().when("jiraSourceType", {
    is: "issue",
    then: (s) => s.required("Required"),
  }),
  jiraJql: Yup.string().when("jiraSourceType", {
    is: "jql",
    then: (s) => s.required("Required"),
  }),
  jiraProgressType: Yup.string().when("jiraSourceType", {
    is: "issue",
    then: (s) => s.required("Required"),
  }),
  jiraSourceType: Yup.string().required("Required"),
});

const initialValuesFromIntegrationField = (
  integrationField: string | null
): Values => {
  if (!integrationField) {
    return {
      jiraSourceType: "jql",
    };
  }

  const data = integrationFieldToJiraData(integrationField);
  if ("issueKey" in data) {
    return {
      jiraIssue: data.issueKey,
      jiraProgressType: data.progressType,
      jiraSourceType: "issue",
    };
  } else {
    return {
      jiraJql: data.jql,
      jiraSourceType: "jql",
    };
  }
};

export const JiraModal = ({
  onClose,
  onSubmit,
  onDisconnect,
  value,
}: Props): JSX.Element => {
  const intl = useIntl();

  const initialValues = initialValuesFromIntegrationField(value);

  const submitAndClose = async (values: Values) => {
    let integrationField: string | undefined;

    if (!!values.jiraIssue && !!values.jiraProgressType) {
      integrationField = jiraDataToIntegrationField({
        issueKey: values.jiraIssue,
        progressType: values.jiraProgressType,
      });
    }

    if (!!values.jiraJql) {
      integrationField = jiraDataToIntegrationField({
        jql: values.jiraJql,
      });
    }

    invariant(!!integrationField);

    onSubmit?.(integrationField);
    onClose?.();
  };

  const disconnectAndClose = () => {
    onDisconnect?.();
    onClose?.();
  };

  const close = () => {
    onClose?.();
  };

  return (
    <Modal
      backdropClass="!z-modal-top-prio-backdrop"
      className="!z-modal-top-prio mt-[15%]"
      id="modal-confirm"
      isOpen
      onClose={onClose}
      scrollable={false}
      size="sm"
      title={<FormattedMessage defaultMessage="Jira integration" id="FYyOnA" />}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitAndClose}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
      >
        {({ values, submitForm }) => {
          return (
            <Form>
              <JiraFormLogic<Values>>
                <div className="p-6 space-y-2">
                  <JiraSourceTypeField />

                  <Show when={values.jiraSourceType === "jql"}>
                    <div className="space-y-2">
                      <div className="text-sm">
                        <FormattedMessage
                          defaultMessage="Enter query to fetch value"
                          id="X966Hj"
                        />
                      </div>
                      <PlainTextField
                        name="jiraJql"
                        placeholder="e.g. status = done and project = DEV"
                      />
                      <JiraJqlPreview value={values.jiraJql ?? ""} />
                    </div>
                  </Show>

                  <Show when={values.jiraSourceType === "issue"}>
                    <JiraIssueField data-cy="jiraIssue" />

                    <JiraProgressTypeField
                      data-cy="jiraProgressType"
                      label={intl.formatMessage({
                        defaultMessage: "Track progress by",
                        id: "result:form:jira:progressType:label",
                      })}
                    />
                  </Show>
                </div>
                <ModalFooter
                  showDivider
                  className="flex flex-row items-center justify-between"
                >
                  {!!onDisconnect && (
                    <div>
                      <TextButton
                        className="text-red-500"
                        size="small"
                        onClick={disconnectAndClose}
                      >
                        <FormattedMessage
                          defaultMessage="Disconnect"
                          id="qj1uhz"
                        />
                      </TextButton>
                    </div>
                  )}
                  <div className="space-x-3 flex items-center">
                    <CancelButton onClick={close} />
                    <SaveButton onClick={submitForm} />
                  </div>
                </ModalFooter>
              </JiraFormLogic>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
