export const pulseConstants = [
  {
    emoji: "",
    value: 0,
  },
  {
    emoji: "😭", // :loudly_crying_face:
    value: 1,
  },
  {
    emoji: "😟", // :worried:
    value: 2,
  },
  {
    emoji: "😐", // :neutral_face:
    value: 3,
  },
  {
    emoji: "😀", // :grinning:
    value: 4,
  },
  {
    emoji: "🤩", // :star_struck:
    value: 5,
  },
] as const;

export const getPulseColorClass = (pulse: number): string => {
  if (pulse >= 4) return "text-green-500";
  if (pulse >= 3) return "text-yellow-500";
  if (pulse >= 1) return "text-red-500";

  return "";
};
