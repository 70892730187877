import classNames from "clsx";
import { Field } from "formik";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { Col } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { ModalContext } from "common/overlay/Modal/ModalContext";
import { colorTheme } from "constants/colorTheme";
import { twClass } from "utils/twClass";

export const FieldCol = styled(Col)`
  border-right: 1px solid ${colorTheme.slate[300]};
  padding-right: 15px;
`;

type RenderFieldFunction = {
  onFieldFocus: React.FocusEventHandler;
};

type Props = {
  focusedField?: string;
  name?: string;
  renderField?: ({ onFieldFocus }: RenderFieldFunction) => JSX.Element;
  renderHelper?: () => JSX.Element;
  setFocusedField?: (f: string) => void;
} & Record<string, unknown>;

/**
 * @deprecated Use {@link WithHelper} instead
 */
export const FieldWithHelper = ({
  focusedField,
  name,
  renderField,
  renderHelper,
  setFocusedField,
  ...other
}: Props): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const modalLarge = modalContext.size === "large";

  const onFieldFocus = (evt?: React.FocusEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    evt?.stopPropagation?.();
    if (name) setFocusedField?.(name);
  };

  return (
    <div className="group grid grid-cols-24">
      <div
        className={classNames("border-r pr-4 border-slate-300", {
          "col-span-16": !modalLarge,
          "col-span-16 md:col-span-19": modalLarge,
        })}
      >
        {renderField && renderField({ onFieldFocus })}
        {!renderField && (
          <Field
            data-cy="8axkBRicm_Dh76gstf-ku"
            name={name}
            onClick={onFieldFocus}
            onFocus={onFieldFocus}
            {...other}
          />
        )}
      </div>
      <div
        className={twClass("relative col-span-8 ml-3 mt-4", {
          "col-span-8 md:col-span-5": modalLarge,
          "group-hover:visible": isEmpty(focusedField),
          invisible: focusedField !== name,
        })}
      >
        <div className="absolute">{renderHelper && renderHelper()}</div>
      </div>
    </div>
  );
};
