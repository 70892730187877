import { gql } from "@apollo/client";
import { JiraIssue, MakeOptional } from "@graphql";
import { Derive } from "@shoooe/derive";
import { apolloClient } from "apolloClient";
import { useField } from "formik";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Props as ReactSelectProps } from "react-select/src/Select";
import { AllowedFieldProps } from "common/form/types";
import { useJiraFormContext } from "common/integration/JiraFormLogic/JiraFormLogic";
import { messages } from "common/result/ResultForm/ResultForm.messages";
import { Select } from "legacy/components/Select/Select";

export type JiraIssueFieldIssue = Derive<
  JiraIssue,
  {
    iconUrl: true;
    id: true;
    isEpic: true;
    key: true;
    summary: true;
  }
>;

const SEARCH_JIRA = gql`
  query searchJira($query: String) {
    searchJira(search: $query) {
      iconUrl
      id
      key
      summary
      isEpic
    }
  }
`;

export const GET_JIRA_ISSUE_QUERY = gql`
  query getJiraIssue($jiraIssueKey: String) {
    jiraIssue(key: $jiraIssueKey) {
      iconUrl
      id
      key
      summary
      isEpic
    }
  }
`;

const searchJira = (search: string) =>
  apolloClient
    .query({
      fetchPolicy: "network-only",
      query: SEARCH_JIRA,
      variables: {
        query: search,
      },
    })
    .then((res) => {
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return res?.data?.searchJira;
    })
    .catch(() => []);

const getJiraIssue = (key?: string): Promise<JiraIssue> =>
  apolloClient
    .query({
      query: GET_JIRA_ISSUE_QUERY,
      variables: {
        jiraIssueKey: key,
      },
    }) // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    .then((res) => res?.data?.jiraIssue)
    .catch(() => null);

type Props = ReactSelectProps & MakeOptional<AllowedFieldProps, "name">;

export const JiraIssueField = ({
  name = "jiraIssue",
  ...other
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);
  const { onIssueSelected, issue } = useJiraFormContext();

  const handleChange = (issue: JiraIssueFieldIssue | null) => {
    helpers.setValue(issue?.key);
    onIssueSelected?.(issue);
  };

  const fetchOptionFromServer = async () => {
    const issue = await getJiraIssue(field.value);
    onIssueSelected?.(issue);
  };

  useEffect(() => {
    if (field.value) {
      fetchOptionFromServer();
    }
  }, [field.value]);

  const getLabel = (issue: JiraIssueFieldIssue) => {
    return (
      <div key={issue.id} className="flex items-center">
        {issue.iconUrl && (
          <img alt="jira icon" height={14} src={issue.iconUrl} width={14} />
        )}
        <div className="mx-2 flex-shrink-0 text-xs text-slate-500">
          {issue.key}
        </div>
        <div className="truncate">{issue.summary}</div>
      </div>
    );
  };

  return (
    <Select
      {...other}
      async
      cacheOptions
      errorText={meta.error}
      getOptionLabel={getLabel}
      getOptionValue={({ key }: JiraIssue) => key}
      isClearable
      isInForm
      label={intl.formatMessage(messages.jiraLabel)}
      loadOptions={searchJira}
      name={name}
      noOptionsMessage={({ inputValue }: { inputValue: string }) =>
        inputValue ? "No Options" : "Type to search for jira issue"
      }
      onChange={handleChange}
      placeholder="Search..."
      value={issue}
    />
  );
};
