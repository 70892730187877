import { isEmpty, isNil } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { useToggle } from "hooks/useToggle/useToggle";
import { SubGroup, SubGroupsModal } from "../SubGroupsModal/SubGroupsModal";
import { messages } from "./SubGroupsIndicator.messages";

type SubGroupsWrapperProps = {
  parentGroup: string;
  subGroups?: SubGroup[];
};

export const SubGroupsIndicator = ({
  subGroups,
  parentGroup,
}: SubGroupsWrapperProps): JSX.Element | null => {
  const [open, toggleOpen] = useToggle(false);
  const intl = useIntl();
  if (isNil(subGroups)) {
    return null;
  }

  return (
    <div className="flex flex-none items-center space-x-1">
      <span>+</span>
      {isEmpty(subGroups) ? (
        <span className="pr-2">{intl.formatMessage(messages.noSubgroups)}</span>
      ) : (
        <>
          <span
            data-cy="jiPk2NqaFmRp9FYKlr-Hr"
            className="cursor-pointer pr-2 text-blue-500"
            onClick={toggleOpen}
          >
            {intl.formatMessage(messages.subGroupsIndicatorCount, {
              count: subGroups.length,
            })}
          </span>
          <SubGroupsModal
            open={open}
            parentGroup={parentGroup}
            subGroups={subGroups}
            toggleOpen={toggleOpen}
          />
        </>
      )}
    </div>
  );
};
