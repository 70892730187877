import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addGroup: {
    defaultMessage:
      "As a {role} user you can only select groups that you are a part of.",
    id: "global:permissionError:addgroup",
  },
  addLead: {
    defaultMessage:
      "As a {role} user you can only select members of groups that you are a part of.",
    id: "global:permissionError:addlead",
  },
  default: {
    defaultMessage: "As a {role} user, you can't perform this action.",
    id: "global:permissionError",
  },
});
