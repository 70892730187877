import React, { useRef } from "react";
import { Button } from "common/buttons/Button/Button";
import { isSet } from "utils/isSet";

type Props = {
  /** only rendered if {@link inline} set to false. */
  buttonLabel?: string;
  /** only rendered if {@link inline} set to true. */
  editMessage?: string;
  imageLoading?: boolean;
  inline?: boolean;
  onChange?: (e: unknown) => void;
};

export const ImageUploadInputFilePicker = ({
  inline = false,
  imageLoading = false,
  buttonLabel,
  editMessage,
  onChange,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOpen = () => {
    if (isSet(inputRef.current)) inputRef.current.click();
  };

  return (
    <div>
      <input
        ref={inputRef}
        className="hidden"
        onChange={onChange}
        type="file"
      />
      <span data-testid="openPickerButton">
        {!inline && (
          <Button
            data-cy="JM8i2YpEhBAK-jvbFhZ1w"
            loading={imageLoading}
            onClick={handleOpen}
          >
            {buttonLabel}
          </Button>
        )}
        {inline && (
          <Button
            data-cy="hMKQ9Bxwu9pqTTF0wvPmY"
            loading={imageLoading}
            onClick={handleOpen}
            variant="negative"
          >
            {editMessage}
          </Button>
        )}
      </span>
    </div>
  );
};
