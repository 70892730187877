import { createNonNullableCtx } from "common/context/utils";

type SelectContext = {
  clearValue: () => void;
  hasValue: boolean;
  isOpen: boolean;
  searchRef: HTMLInputElement | null;
  setSearchRef: (valueRef: HTMLInputElement | null) => void;
  setValueRef: (valueRef: HTMLDivElement | null) => void;
  valueRef: HTMLDivElement | null;
};

export const [useSelectContext, SelectContextProvider] =
  createNonNullableCtx<SelectContext>();
