import { useGetOneOnOneSeriesQuery } from "@graphql";
import { isNil } from "lodash";
import { useIntl } from "react-intl";
import {
  ONE_ON_ONE_SERIES_DISABLED,
  ONE_ON_ONE_SERIES_ENABLED,
} from "constants/tracking";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { useHandleOneOnOneMutations } from "./useHandleOneOnOneMutations";

type Hook = {
  setDisableStatus: (isActive: boolean) => void;
};

export const useDisableOneOnOneMeeting = (seriesId: string): Hook => {
  const intl = useIntl();
  const { toggleOneOnOneSeriesStatus } = useHandleOneOnOneMutations();
  const { data } = useGetOneOnOneSeriesQuery({ variables: { id: seriesId } });

  const nextMeeting = data?.oneOnOne?.nextMeeting;
  const repeatFrequency = data?.oneOnOne?.repeatFrequency;
  const setDisableStatus = async (isActive: boolean) => {
    if (!isNil(nextMeeting)) {
      const { hasError } = await toggleOneOnOneSeriesStatus({
        isActive,
        meetingId: nextMeeting.id,
        seriesId,
      });
      if (!hasError) {
        toast.success(
          intl.formatMessage(
            isActive
              ? {
                  defaultMessage: "1:1 meeting activated",
                  id: "XJ4MYm",
                }
              : {
                  defaultMessage: "1:1 meeting deactivated",
                  id: "/dKfaJ",
                }
          )
        );
        track(
          isActive ? ONE_ON_ONE_SERIES_ENABLED : ONE_ON_ONE_SERIES_DISABLED,
          {
            repeatFrequency,
            seriesId,
          }
        );
      }
    }
  };

  return { setDisableStatus };
};
