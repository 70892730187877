import React from "react";
import { FormattedMessage } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { ProgressBar } from "common/progress/ProgressBar/ProgressBar";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

export const ObjectiveInitiativeProgress = () => {
  const objective = useObjectiveContext();

  const totalInitiatives = objective.initiatives?.totalCount ?? 0;
  const achievedInitiatives = objective.initiatives?.achievedCount;

  return (
    <div className="w-1/2">
      {isSet(achievedInitiatives) && isSet(totalInitiatives) && (
        <div className="flex items-center space-x-2">
          <ProgressBar
            className="w-32"
            barClassName={twClass({
              "bg-green-500": achievedInitiatives === totalInitiatives,
            })}
            progress={(achievedInitiatives / totalInitiatives) * 100}
          />
          <div>
            <FormattedMessage
              defaultMessage="{achievedInitiatives}/{totalInitiatives}"
              id="QjaG1X"
              values={{
                achievedInitiatives: achievedInitiatives,
                totalInitiatives: totalInitiatives,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
