import { Field, FormikErrors, FormikValues } from "formik";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { objectiveAlignToFields } from "common/objective/constants";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useCompanySettings } from "hooks/useCompany/useCompany";
import { messages } from "./FormObjectiveAlignToSelect.messages";
import { SelectAlignment } from "./SelectAlignment/SelectAlignment";

interface Props {
  alignedToRadio: string;
  children: React.ReactNode;
  errors: FormikErrors<FormikValues>;
  privateObjective: boolean;
  setAlignedToRadio: (value: string) => void;
  setFieldValue: (name: string, value: string | null) => void;
  setFocusedField: (name: string) => void;
  submitCount: number;
}

export const FormObjectiveAlignToSelect = ({
  alignedToRadio,
  errors,
  privateObjective,
  setAlignedToRadio,
  setFieldValue,
  setFocusedField,
  submitCount,
  children,
}: Props): JSX.Element => {
  const intl = useIntl();

  const { pillarsEnabled } = useCompanySettings();

  const sanitizeAlignTo = (alignedToRadio: string) =>
    Object.keys(objectiveAlignToFields).forEach(
      (key) => key !== alignedToRadio && setFieldValue(key, null)
    );

  useEffect(() => {
    sanitizeAlignTo(alignedToRadio);
  }, [alignedToRadio]);

  const validateAlignment = () =>
    !alignedToRadio && <FormattedMessage {...messages.pleaseSelectOneOption} />;

  const errorText = submitCount > 0 && errors.alignment;

  const disabledAlignmentTooltip = intl.formatMessage(
    messages.alignSelectReasonTooltip
  );

  return (
    <div>
      <div className="text-sm -mb-1.5">
        <FormattedMessage defaultMessage="Alignment" id="Ig/eGK" />
      </div>
      <div className="flex flex-1 items-start">
        <WithTooltip
          className="w-full"
          tooltip={privateObjective && disabledAlignmentTooltip}
        >
          <div className="min-w-0 flex-1">
            <SelectAlignment
              alignedToRadio={alignedToRadio}
              disabled={privateObjective}
              errorText={errorText as string}
              pillarsEnabled={pillarsEnabled}
              setAlignedToRadio={setAlignedToRadio}
              setFocusedField={setFocusedField}
            />
          </div>
        </WithTooltip>
        {children}
      </div>
      <Field name="alignment" type="hidden" validate={validateAlignment} />
    </div>
  );
};
