import {
  FetchPrivateKpiMembersDocument,
  FetchPrivateKpiMembersQueryData,
} from "@graphql";
import { apolloClient } from "apolloClient";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ProTipBanner } from "common/overlay/ProTipBanner/ProTipBanner";
import { MODAL_TYPES } from "constants/index";
import { showModal } from "legacy/actions/actions";

interface Props {
  id: string;
}

export const PrivateKpiLockButton = ({ id }: Props): JSX.Element | null => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const showContributingUsers = async () => {
    const { data } = await apolloClient.query<FetchPrivateKpiMembersQueryData>({
      query: FetchPrivateKpiMembersDocument,
      variables: {
        id,
      },
    });

    // TODO: improve readability
    const users = [
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...(data?.kpi?.lead ? [data.kpi.lead] : []),
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ...(data?.kpi?.visibleTo
        ? data.kpi.visibleTo.edges.map(({ node }) => node)
        : []),
    ];

    dispatch(
      showModal(MODAL_TYPES.USER_LIST, {
        title: intl.formatMessage({
          defaultMessage: "Who can see this?",
          id: "objective:private:contributingUsers:whoCanSeeThis",
        }),
        topArea: () => (
          <ProTipBanner className="border text-slate-800 text-sm border-yellow-400">
            <FormattedMessage
              defaultMessage="Private KPIs are visible only to the Lead and the people they choose."
              id="kpi:private:contributingUsers:notice"
            />
          </ProTipBanner>
        ),
        users,
      })
    );
  };

  return (
    <IconButton
      data-cy="SPiX_DgLcPQYBQ-rRxAsv"
      className="text-slate-500 hover:text-blue-500 hover:border-blue-500 border border-slate-300"
      ghost
      iconClass="text-[inherit]"
      name="lock_outline"
      onClick={showContributingUsers}
      size="sm"
      tooltip={intl.formatMessage({
        defaultMessage: "Private KPI",
        id: "kpi:forms:private:kpis",
      })}
      tooltipPlacement="top"
    />
  );
};
