import {
  GetAllActiveTimeframesTimeframeFragment,
  useGetAllActiveTimeframesQuery,
} from "@graphql";
import { isNil } from "lodash";

export type UseGlobalTimeframeHook = GetAllActiveTimeframesTimeframeFragment;

type DurationSorting = "min" | "max";

export const useGlobalTimeframe = (
  durationSorting: DurationSorting = "min"
): UseGlobalTimeframeHook | undefined | null => {
  const orderBy =
    durationSorting === "min" ? "duration,start_date" : "-duration,-start_date";
  const { data } = useGetAllActiveTimeframesQuery({
    variables: {
      active: true,
      orderBy,
    },
  });
  if (isNil(data)) return undefined;
  return data.timeframes.edges[0]?.node ?? null;
};
