import { defineMessages } from "react-intl";

export const namePlaceholders = defineMessages({
  text1: {
    defaultMessage: "Reduce infrastructure costs by X%",
    id: "keyResult:forms:name:placeholder:text1",
  },
  text2: {
    defaultMessage: "Participate in X% of design sprints",
    id: "keyResult:forms:name:placeholder:text2",
  },
  text3: {
    defaultMessage: "Developer satisfaction survey at 95%",
    id: "keyResult:forms:name:placeholder:text3",
  },
  text4: {
    defaultMessage: "X% bug reduction",
    id: "keyResult:forms:name:placeholder:text4",
  },
  text5: {
    defaultMessage: "X% positive client feedback",
    id: "keyResult:forms:name:placeholder:text5",
  },
  text6: {
    defaultMessage: "Reduce onboarding time from X to Y",
    id: "keyResult:forms:name:placeholder:text6",
  },
  text7: {
    defaultMessage: "New features have X% utilization",
    id: "keyResult:forms:name:placeholder:text7",
  },
  txt10: {
    defaultMessage: "X% reduction in operating costs",
    id: "keyResult:forms:name:placeholder:text10",
  },
  txt11: {
    defaultMessage: "X% Employee Satisfaction Score",
    id: "keyResult:forms:name:placeholder:text11",
  },
  txt12: {
    defaultMessage: "X% Turnover Rate",
    id: "keyResult:forms:name:placeholder:text12",
  },
  txt13: {
    defaultMessage: "Increase average deal size to XK",
    id: "keyResult:forms:name:placeholder:text13",
  },
  txt14: {
    defaultMessage: "Have an X% close rate",
    id: "keyResult:forms:name:placeholder:text14",
  },
  txt15: {
    defaultMessage: "Close X new accounts",
    id: "keyResult:forms:name:placeholder:text15",
  },
  txt16: {
    defaultMessage: "Close X in new bizz MRR",
    id: "keyResult:forms:name:placeholder:text16",
  },
  txt17: {
    defaultMessage: "X new customers from partner referrals",
    id: "keyResult:forms:name:placeholder:text17",
  },
  txt18: {
    defaultMessage: "Increase conversion rate by X%",
    id: "keyResult:forms:name:placeholder:text18",
  },
  txt8: {
    defaultMessage: "X feature increase usage by X%",
    id: "keyResult:forms:name:placeholder:text8",
  },
  txt9: {
    defaultMessage: "Net profit increase of X%",
    id: "keyResult:forms:name:placeholder:text9",
  },
});
