import React from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "common/overlay";

type Props = {
  className?: string;
};

export const HealthScoreIndicator = ({ className }: Props): JSX.Element => {
  const { $t } = useIntl();

  return (
    <Tooltip
      content={$t({
        defaultMessage: "This metric is part of the Health Score",
        id: "tMNLDX",
      })}
      spanClass={className}
    >
      <svg
        data-testid="healthScoreIndicator"
        fill="none"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_350_2211)">
          <path d="M0 0H37C38.6569 0 40 1.34315 40 3V40L0 0Z" fill="#DDE5EA" />
          <path
            d="M23.5824 6C22.5588 6 21.7059 6.3689 21.0235 7.10669C20.3412 7.84448 20 8.71741 20 9.72549C20 9.9963 20.0275 10.2634 20.0824 10.5266C20.1373 10.7899 20.2196 11.0458 20.3294 11.2941H24.8382L25.9912 12.9216L27.4118 8.64706H27.9471L29.8206 11.2941H33.6749C33.7833 11.0401 33.8646 10.7824 33.9187 10.521C33.9729 10.2596 34 9.99439 34 9.72549C34 8.71895 33.6572 7.84641 32.9717 7.10784C32.2862 6.36928 31.4293 6 30.401 6C29.918 6 29.4534 6.09477 29.0074 6.28431C28.5613 6.47386 28.1735 6.73856 27.8441 7.07843L27.2108 7.72549H26.7735L26.1353 7.07843C25.8021 6.74097 25.4135 6.47686 24.9693 6.28612C24.525 6.09537 24.0627 6 23.5824 6ZM27 18L32.7853 12.4706H29.1592L28.0088 10.8431L26.5676 15.098H26.0529L24.1794 12.4706H21.1941L27 18Z"
            fill="#68819D"
          />
        </g>
        <defs>
          <clipPath id="clip0_350_2211">
            <rect fill="white" height="40" width="40" />
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
};
