import {
  GrowthBoardKpiFragment,
  MetricUnit,
  TargetsKpiTargetFragment,
} from "@graphql";
import dayjs from "dayjs";
import { isNil, sortBy } from "lodash";
import moment, { Moment } from "moment";
import { IntlShape } from "react-intl";
import { formatGoalOperator } from "common/kpi/utils";
import { isSet } from "utils/isSet";
import { formatPdMetric } from "utils/utils";
import { Month } from "./TargetList.types";

/**
 * combines targets with operator and converts to short syntax, i.e. "30000" -> ">= 30k".
 */
export const formatTargets = (
  intl: IntlShape,
  metricUnit = MetricUnit.Numerical,
  months: Month[]
): Month[] => {
  return months.map((month) => {
    const formattedValue = isSet(month.value)
      ? formatPdMetric(intl, month.value, metricUnit, {
          abbreviate: true,
        })
      : null;

    const operator = month.operator
      ? formatGoalOperator(month.operator)
      : undefined;

    const value =
      formattedValue && operator ? `${operator} ${formattedValue}` : "+";

    return {
      ...month,
      formattedValue: value,
    };
  });
};

/**
 * resolves explicit and implicit monthly targets.
 */
export const resolveTargets = (
  months: Month[],
  targets: TargetsKpiTargetFragment[]
): Month[] => {
  const sortedTargets = sortBy(targets, "startDate");
  let lastTargetIndex = 0;
  let lastTarget = sortedTargets[lastTargetIndex];

  return months.map((month) => {
    if (
      isNil(lastTarget) ||
      dayjs.utc(lastTarget.startDate).isAfter(month.date, "month")
    ) {
      return {
        ...month,
        value: undefined,
      };
    }

    if (dayjs.utc(lastTarget.startDate).isSame(month.date, "month")) {
      return {
        ...month,
        isExplicit: true,
        operator: lastTarget.operator ?? undefined,
        targetId: lastTarget.id,
        value: lastTarget.threshold ?? undefined,
      };
    }

    let nextTarget = sortedTargets[lastTargetIndex + 1];
    while (
      nextTarget &&
      dayjs.utc(nextTarget.startDate).isSameOrBefore(month.date, "month")
    ) {
      lastTarget = nextTarget;
      lastTargetIndex++;
      nextTarget = sortedTargets[lastTargetIndex + 1];
    }

    const isExplicit = dayjs
      .utc(lastTarget.startDate)
      .isSame(month.date, "month");

    return {
      ...month,
      isExplicit,
      operator: lastTarget.operator ?? undefined,
      targetId: isExplicit ? lastTarget.id : undefined,
      value: lastTarget.threshold ?? undefined,
    };
  });
};

type KpiValueStatus = {
  lastCommitDate?: Moment;
  status?: string | null;
  value?: string;
};

export const getFormattedKpiValues = (
  kpi: GrowthBoardKpiFragment,
  intl: IntlShape
): KpiValueStatus[] | undefined => {
  const now = moment();

  return kpi.valuesByMonth?.map((kpiValue): KpiValueStatus => {
    if (isNil(kpiValue)) {
      return {
        lastCommitDate: undefined,
        status: undefined,
        value: undefined,
      };
    }

    if (isNil(kpiValue.value)) {
      const nextMonthStart = kpiValue.date;
      const monthEnd = moment(nextMonthStart).subtract(1, "day");
      const isFuture = monthEnd.isAfter(now, "month");

      if (isFuture) {
        return {
          lastCommitDate: undefined,
          status: undefined,
          value: undefined,
        };
      }

      return { lastCommitDate: undefined, status: undefined, value: "-" };
    }

    const formattedValue = formatPdMetric(
      intl,
      kpiValue.value,
      kpi.metricUnit,
      {
        abbreviate: true,
      }
    );

    const lastCommitDate = moment(kpiValue.lastCommit?.commitDate);

    return { lastCommitDate, status: kpiValue.status, value: formattedValue };
  });
};
