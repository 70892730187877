import React, { ReactNode } from "react";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons/Icon/Icon";
import { twClass } from "utils/twClass";

type Props = {
  checkboxClassName?: string;
  checked?: boolean;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  errorText?: ReactNode | null;
  id: string;
  label?: ReactNode;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const Checkbox = ({
  "data-cy": dataCy,
  "data-testid": dataTestId,
  checkboxClassName,
  checked,
  className,
  disabled,
  errorText,
  id,
  label,
  name,
  onBlur,
  onChange,
}: Props): JSX.Element => {
  return (
    <label className={className} data-testid="checkboxLabel" htmlFor={id}>
      <input
        checked={checked}
        className="hidden"
        data-cy={dataCy}
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type="checkbox"
      />
      <div className="group flex cursor-pointer items-center space-x-2 font-normal">
        <div
          className={twClass(
            "flex h-6 w-6 items-center justify-center rounded border-2 p-0.5 bg-white border-slate-300",
            {
              "bg-blue-500 text-white border-blue-500": checked,
              "bg-slate-300 border-slate-300": disabled,
            },
            checkboxClassName
          )}
          data-testid={dataTestId}
        >
          <Show when={checked}>
            <Icon className="text-white" name="check" size="xl" />
          </Show>
        </div>
        <div className="text-base">{label}</div>
      </div>
      {errorText && (
        <div
          className="m-1 min-h-[18px] text-sm text-red-500"
          data-cy="errorMessage"
        >
          {errorText}
        </div>
      )}
    </label>
  );
};
