import { ReactNode } from "react";
import Emoji from "react-emoji-render";
import { twClass } from "utils/twClass";

type Props = {
  children: ReactNode;
  className?: string;
  emoji?: string;
  title?: ReactNode;
};

/**
 * a flex banner, displays a pro tip message with an emoji (💡 by default), with an optional header.
 */
export const ProTipBanner = ({
  className = "",
  children,
  emoji = "💡",
  title,
}: Props): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex flex-col items-start justify-around",
        "mb-2 min-h-[3.5rem] p-2",
        "rounded",
        "text-yellow-900 bg-yellow-50",
        className
      )}
    >
      {title && (
        <div className="mb-1 self-center text-lg font-semibold">{title}</div>
      )}
      <div className="mb-0.5">
        {emoji && <Emoji className="mr-2 text-xl" text={emoji} />}
        {children}
      </div>
    </div>
  );
};
