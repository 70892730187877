import {
  Cadence,
  GetCadenceListQueryVariables,
  useGetCadencesListQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useField } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { FormControl } from "common/form/FormControl/FormControl";
import { makeSelect } from "common/inputs/Select/Select";
import { SelectRoot } from "common/inputs/Select/SelectRoot/SelectRoot";
import { mapEdges } from "utils/mapEdges";

const Select = makeSelect<SelectCadence>();

type SelectCadence = Derive<
  Cadence,
  {
    id: true;
    nextTimeframeStartDate: true;
  }
>;

type SelectProps = React.ComponentProps<typeof SelectRoot>;

type Props = Omit<SelectProps, "children" | "onChange" | "value"> & {
  label?: string;
  name: string;
  onAfterChange?: (c: SelectCadence | null) => void;
  optional?: boolean;
  variables?: GetCadenceListQueryVariables;
};

export const CadenceField = ({
  label,
  name,
  onAfterChange,
  optional,
  ...selectProps
}: Props): JSX.Element => {
  const [, meta, helpers] = useField(name);

  const { data } = useGetCadencesListQuery({
    fetchPolicy: "cache-and-network",
  });
  const cadences = mapEdges(data?.currentCompany.cadences.edges);
  const selectedCadence = cadences.find((c) => c.id === meta.value) ?? null;

  const handleChange = async (cadence: SelectCadence | null) => {
    // we need to await so that later calls don't reflect outdated values when validating.
    // see https://perdoo.slack.com/archives/C02HBV92QJV/p1685441230574079
    await helpers.setValue(cadence?.id, true);
    onAfterChange?.(cadence);
  };

  return (
    <FormControl
      errorText={meta.error}
      id={name}
      label={label}
      optional={optional}
    >
      <Select.Root
        onChange={handleChange}
        value={selectedCadence}
        {...selectProps}
      >
        <Select.Trigger>
          <Select.Value>
            <span>
              {selectedCadence?.name ?? (
                <FormattedMessage defaultMessage="Select..." id="724CrE" />
              )}
            </span>
          </Select.Value>
        </Select.Trigger>
        <Select.Dropdown>
          <Select.Options>
            <For each={cadences}>
              {(cadence) => (
                <Select.Option value={cadence}>{cadence.name}</Select.Option>
              )}
            </For>
          </Select.Options>
        </Select.Dropdown>
      </Select.Root>
    </FormControl>
  );
};
