import {
  OneOnOneMeetingItemDefaultFragment,
  OneOnOneMeetingItemType,
  OneOnOneMeetingItemUserFragment,
} from "@graphql";
import dayjs from "dayjs";
import { isEqual, isNil, omit } from "lodash";
import { OmitTypename } from "utils/graphql/types";
import { OneOnOneMeetingItem } from "./OneOnOneCompleteForm/OneOnOneMeetingItemsField/OneOnOneMeetingItemsField";

export const formatOneOnOneMeetingDueDate = (
  dueDate?: string | null,
  meetingTime?: string | null,
  timezone?: string
): string | null => {
  if (isNil(dueDate)) {
    return null;
  }
  const date = dayjs(dueDate).format("YYYY-MM-DD");
  const datetime = `${date} ${meetingTime}`;
  return dayjs.tz(datetime, timezone).toISOString();
};

export const getMeetingTimeFromDueDate = (
  dueDate?: string | null
): string | null => {
  if (isNil(dueDate)) {
    return null;
  }
  return dayjs(dueDate).format("HH:mm:ss");
};

type MeetingItem = OneOnOneMeetingItemDefaultFragment &
  OneOnOneMeetingItemUserFragment;

type InitialValuesMeetingItem =
  OmitTypename<OneOnOneMeetingItemDefaultFragment> & {
    assignee: string | null;
  };

type MeetingItems = {
  actionItems: InitialValuesMeetingItem[];
  talkingPoints: InitialValuesMeetingItem[];
};

export const getMeetingItems = (meetingItems: MeetingItem[]): MeetingItems => {
  const actionItems = meetingItems.filter(
    (item) => item.type === OneOnOneMeetingItemType.ActionItem
  );
  const talkingPoints = meetingItems.filter(
    (item) => item.type === OneOnOneMeetingItemType.TalkingPoint
  );
  const getDetailsForMeetingForm = (items: MeetingItem[]) => {
    return items.map(({ assignee, ...rest }) => {
      const props = omit(rest, "__typename");
      return {
        assignee: assignee?.id ?? null,
        ...props,
      };
    });
  };

  return {
    actionItems: getDetailsForMeetingForm(actionItems),
    talkingPoints: getDetailsForMeetingForm(talkingPoints),
  };
};

const isMeetingItemEmpty = (item: OneOnOneMeetingItem) => {
  const emptyItem = {
    isComplete: false,
    text: "",
  };
  return isEqual(omit(item, ["id", "assignee", "type"]), emptyItem);
};

export const removeEmptyAndCheckCompletedMeetingItems = (
  items: OneOnOneMeetingItem[]
): OneOnOneMeetingItem[] => {
  return items.reduce((accu, currentItem) => {
    const accuIds = accu.map((item) => item.id);
    const isNew = !accuIds.includes(currentItem.id);
    if (!isNew && !isMeetingItemEmpty(currentItem)) {
      const existingItem = accu.find((item) => item.id === currentItem.id);
      const existingIndex = accuIds.findIndex((id) => id === currentItem.id);
      const isComplete = existingItem?.isComplete || currentItem.isComplete;
      const newAccu = accu.splice(existingIndex, 1, {
        ...currentItem,
        isComplete,
      });
      return newAccu;
    }
    return !isMeetingItemEmpty(currentItem) ? [...accu, currentItem] : accu;
  }, [] as OneOnOneMeetingItem[]);
};
