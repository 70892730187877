import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { isString, omit } from "lodash";
import { NextRouter } from "next/router";
import React from "react";
import { auth } from "../common/authHelper";
import { callApi } from "./api";
import { ceil } from "./dayjs/utils";
import { openTeamsDeepLink } from "./msTeams";
import { toast } from "./toastr";

/**
 * Sets unique react id to avoid conflicts with other React apps
 */
// @ts-expect-error ts-migrate(2339) FIXME: Property 'ID_ATTRIBUTE_NAME' does not exist on typ... Remove this comment to see the full error message
React.ID_ATTRIBUTE_NAME = "data-perdoo";

export const loginToTeams = async (
  access: string,
  refresh: string
): Promise<void> => {
  const oauthRedirectMethod = window.localStorage.getItem(
    "oauthRedirectMethod"
  );
  const authId = window.localStorage.getItem("authId");
  if (oauthRedirectMethod === "deeplink" && authId) {
    openTeamsDeepLink({ access, authId, refresh });
    return;
  }

  const { app, authentication } = await import("@microsoft/teams-js");
  try {
    await app.initialize();
  } catch {
    console.log("couldn't init teams");
    return;
  }
  const botClientUserId = JSON.parse(
    window.localStorage.getItem("botClientUserId") ?? ""
  );
  const loginKey = window.JSON.parse(localStorage.getItem("loginKey") ?? "");

  window.localStorage.removeItem("botClientUserId");
  window.localStorage.removeItem("loginKey");

  authentication.notifySuccess(
    btoa(
      JSON.stringify({
        access,
        botClientUserId,
        loginKey,
        refresh,
      })
    )
  );

  if (botClientUserId === "checkIn") {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'close' does not exist on type 'Location'... Remove this comment to see the full error message
    window.location.close?.();
  }
};

export const loginWithGoogleToken = async (
  googleResponse: any,
  router: NextRouter
): Promise<any> => {
  const { query, push } = router;

  const token = googleResponse.credential;

  try {
    const response = await callApi(
      `/sso/google_verify_token?id_token=${token}`,
      {
        method: "POST",
      },
      { showFailureToast: false, skipDefaulHandlerFor: 401 }
    );
    loginToTeams(response.access, response.refresh);
    auth.setTokens(`JWT ${response.access}`, `JWT ${response.refresh}`);
    const redirectTo = isString(query.redirectTo) ? query.redirectTo : null;
    await push({
      pathname: redirectTo ?? "/",
      query: {
        ...omit(query, "redirectTo"),
      },
    });
    return null;
  } catch (err: any) {
    return (
      err.errors?.[0]?.detail ??
      "An error occurred while trying to log in with Google."
    );
  }
};

export const addDayjsPlugins = (): Promise<void> => {
  dayjs.extend(advancedFormat);
  dayjs.extend(calendar);
  dayjs.extend(ceil);
  dayjs.extend(customParseFormat);
  dayjs.extend(duration);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);
  dayjs.extend(isoWeek);
  dayjs.extend(quarterOfYear);
  dayjs.extend(relativeTime);
  dayjs.extend(tz);
  dayjs.extend(utc);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(weekday);

  return Promise.resolve();
};

export const boot = (): Promise<void> => {
  Promise.all([addDayjsPlugins()]);

  return Promise.resolve();
};
