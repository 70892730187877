import React, { ReactNode } from "react";
import { ComboItem } from "legacy/components/Combo/Combo.types";
import { twClass } from "utils/twClass";

export type ComboOptionProps<Item extends ComboItem> = {
  active: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  item: Item;
  selected: boolean;
};

/**
 * element rendered for each item in the combobox. pass as child to {@link Combo} component.
 * @example
 * <Combo {props}>
 *    {({ active, item, selected }) => (
 *      <ComboOption>
 *        {children}  //whatever sub-components to render in each option, e.g. item actions, ...
 *      </ComboOption>
 *    )}
 * </Combo>
 */
export const ComboOption = <Item extends ComboItem>({
  active,
  children,
  className,
  item,
  selected,
}: ComboOptionProps<Item>): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex select-none",
        "px-4 py-3",
        active && "text-blue-500 bg-blue-50",
        selected && "text-white bg-blue-500",
        !item.disabled && "cursor-pointer",
        className
      )}
    >
      {children ?? (
        <span className="grow truncate">{item.label ?? item.name}</span>
      )}
    </div>
  );
};
