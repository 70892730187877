// import for jsdoc reference (otherwise resolves to the lodash method)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isSet } from "utils/isSet";

/**
 * Checks whether a variable is defined = is not `undefined`. Can be used as type guard.
 *
 * note: in most cases you'll want to use {@link isSet} instead.
 *
 * @example
 * ```
 * [1, null, 2, undefined].filter(isDefined);
 * // => [1, null, 2]
 *
 * if (isDefined(data)) {
 *   // process data
 * }
 *
 * const { data } = useQuery(...);
 * const item = data?.item; // Item | undefined
 * if (isDefined(item)) {
 *   doSomething(item); // Item
 * }
 * ```
 *
 * @see {@link isSet}
 */
export const isDefined = <T>(value: T | undefined): value is T => {
  return value !== undefined;
};
