import { isEmpty, orderBy } from "lodash";
import React, { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { TextButton } from "common/buttons";
import { Clickable } from "common/navigation/Clickable";
import { TextSkeleton } from "common/placeholders/TextSkeleton/TextSkeleton";
import {
  ActiveUsersOptions,
  useActiveUsers,
} from "hooks/useActiveUsers/useActiveUsers";
import { useToggle } from "hooks/useToggle/useToggle";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { twClass } from "utils/twClass";
import { CloseFn, MultiSelect } from "../MultiSelect/MultiSelect";

type Value = string[];

type Option = {
  images: string[];
  label: string;
  value: string;
};

type Props = {
  addText?: ReactNode;
  "data-cy"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => void;
  options?: ActiveUsersOptions;
  value: Value;
};

export const InlineUserMultiSelect = ({
  addText,
  "data-cy": dataCy,
  onChange,
  value,
  disabled,
  options,
}: Props) => {
  const [open, toggleOpen, setOpen] = useToggle(false);
  const users = useActiveUsers(options);
  const selectedUsers = users?.filter((user) => value.includes(user.id));
  const orderedOptions: Option[] = useMemo(() => {
    const options =
      users?.map((user) => ({
        images: user.avatar ? [user.avatar] : [],
        label: user.fullName,
        selected: value.includes(user.id),
        value: user.id,
      })) ?? [];
    return orderBy(options, ["selected"], ["desc"]);
  }, [value, users]);

  const handleClose: CloseFn<Option> = (selectedOptions) => {
    toggleOpen();
    if (!selectedOptions) return;
    onChange?.(selectedOptions.map((opt) => opt.value));
  };

  if (!selectedUsers) return <TextSkeleton />;

  return (
    <Dropdown
      open={open}
      onToggle={setOpen}
      disabled={disabled}
      button={
        !isEmpty(selectedUsers) ? (
          <Clickable
            data-cy={dataCy}
            className={twClass(
              "w-full space-y-2 border border-transparent data-[state=open]:bg-white data-[state=open]:border-slate-300 rounded p-2 -ml-2 -mt-2",
              {
                "cursor-not-allowed": disabled,
                "hover:bg-white hover:border-slate-300": !disabled,
              }
            )}
          >
            {selectedUsers.map((user) => (
              <Entity key={user.id} name={user.fullName} avatar={user.avatar} />
            ))}
          </Clickable>
        ) : (
          <TextButton className="p-0" size="small" data-cy={dataCy}>
            +&nbsp;
            {addText ?? (
              <FormattedMessage defaultMessage="Add user" id="Dra4ET" />
            )}
          </TextButton>
        )
      }
      className="block"
    >
      {open && (
        <MultiSelect
          onClose={handleClose}
          options={orderedOptions}
          initialValue={value}
          required={false}
          saveText={<FormattedMessage defaultMessage="Done" id="JXdbo8" />}
        />
      )}
    </Dropdown>
  );
};
