import { CadenceDefaultFieldsFragment } from "@graphql";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { CadenceField } from "common/form/CadenceField/CadenceField";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { isSet } from "utils/isSet";
import { messages } from "./TimeframeForm.messages";

const schema = Yup.object().shape({
  cadence: Yup.string().required("Required"),
  name: Yup.string(),
  startDate: Yup.date(),
  timeframeCount: Yup.number().min(1, "min: 0").max(20, "max: 20"),
});

export type TimeframeFormValues = {
  cadence?: string;
  currentCadence?: CadenceDefaultFieldsFragment;
  endDate?: string;
  name?: string;
  startDate?: string;
  timeframeCount?: number;
};

interface TimeframeFormProps {
  initialValues: TimeframeFormValues;
  onSubmit: (
    values: TimeframeFormValues,
    actions: FormikHelpers<TimeframeFormValues>
  ) => void;
  renderTimeframeTable: (values: TimeframeFormValues) => JSX.Element;
}

export const TimeframeForm: React.FC<TimeframeFormProps> = ({
  onSubmit,
  initialValues,
  renderTimeframeTable,
}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validationSchema={schema}
    >
      {({ isSubmitting, submitForm, values, setFieldValue }) => (
        <Form>
          <CadenceField
            autoFocus
            data-cy="cadence"
            label={intl.$t({
              defaultMessage: "Cadence",
              id: "group:forms:cadence:label",
            })}
            name="cadence"
            onAfterChange={(selected) => {
              setFieldValue("startDate", selected?.nextTimeframeStartDate);
              setFieldValue("currentCadence", selected);
            }}
          />
          <InputField
            label={intl.formatMessage(messages.name)}
            name="name"
            optional={!!renderTimeframeTable}
            placeholder={intl.formatMessage(messages.name)}
          />
          <Field name="startDate">
            {({ field, form }: FieldProps) => (
              <FormDatePicker
                key={values.cadence}
                data-cy="startDate"
                field={field}
                form={form}
                label={intl.formatMessage(messages.startDate)}
              />
            )}
          </Field>
          <Show when={isSet(renderTimeframeTable)}>
            <InputField
              className="w-36"
              data-cy="timeframeCount"
              label={intl.formatMessage(messages.timeframeCount)}
              name="timeframeCount"
              type="number"
            />
          </Show>
          <Show when={values.cadence}>
            <hr className="my-4 border-slate-300" />
            {renderTimeframeTable(values)}
          </Show>
          <ModalFooter>
            <Button
              data-cy="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              {intl.formatMessage(messages.submit)}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
