import dayjs from "dayjs";
import { get, isNil } from "lodash";
import { routes } from "route-configs";
import { UseModalRouterHook } from "hooks/useModalRouter/useModalRouter";
import { ObjectiveNode } from "modules/reports/custom/results/ResultTable/ResultTable.types";
import { noAlignment, objectiveAlignToFields } from "../constants";
import { Timeframe } from "../ObjectiveForm/ObjectiveForm.types";

export const styles = {
  menu: (provided: Record<string, string>) => ({
    ...provided,
    width: 500,
  }),
};

export const checkIfTimeframeIsSetToFuture = (
  timeframe: Timeframe | null
): boolean => {
  if (isNil(timeframe)) {
    return false;
  }
  const timeframeStartDate = dayjs(timeframe.startDate);
  const currentDate = dayjs();
  return currentDate.isBefore(timeframeStartDate);
};

export const getAlignedToField = (
  objective: any,
  isEdit?: boolean
): string | undefined => {
  const alignedValue = Object.keys(objectiveAlignToFields).find((key) =>
    get(objective, key)
  );
  return isNil(alignedValue) && isEdit ? noAlignment : alignedValue;
};

export const getAlignedToWithLink = ({
  modalRouter,
  objective,
}: {
  modalRouter: UseModalRouterHook;
  objective: ObjectiveNode;
}) => {
  const { objectiveHref, kpiHref } = modalRouter;
  const { parent, kpi, strategicPillar } = objective;
  const alignedTo = parent || kpi || strategicPillar;

  if (!alignedTo) {
    return null;
  }

  const linkByType = {
    kpi: kpiHref({ kpiId: alignedTo.id }).toString(),
    objective: objectiveHref({ oId: alignedTo.id }).toString(),
    strategicPillar: routes.mapsv3.main,
  };

  const link =
    linkByType[alignedTo.__typename as "objective" | "kpi" | "strategicPillar"];

  return { ...alignedTo, link };
};
