import { kebabCase } from "lodash";
import find from "lodash/find";
import keys from "lodash/keys";
import startsWith from "lodash/startsWith";
import styled from "styled-components";

// Inspired by https://github.com/snikas/react-styled-flexbox/blob/master/src/Container.jsx

/**
 * React container component using css flexbox
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Using_CSS_flexible_boxes
 * VALID PROPS
 * directionRowReverse
 * directionColumn
 * directionColumnReverse
 * wrapWrap
 * wrapWrapReverse
 * itemsFlexStart
 * itemsFlexEnd
 * itemsCenter
 * itemsBaseline
 * contentFlexStart
 * contentFlexEnd
 * contentCenter
 * contentSpaceBetween
 * contentSpaceAround
 * justifySpaceBetween
 * justifySpaceAround
 * justifyFlexEnd
 * justifyCenter
 */

export interface Props {
  contentCenter?: boolean;
  contentFlexEnd?: boolean;
  contentFlexStart?: boolean;
  contentSpaceAround?: boolean;
  contentSpaceBetween?: boolean;
  directionColumn?: boolean;
  directionColumnReverse?: boolean;
  directionRowReverse?: boolean;
  fullWidth?: boolean;
  itemsBaseline?: boolean;
  itemsCenter?: boolean;
  itemsFlexEnd?: boolean;
  itemsFlexStart?: boolean;
  justifyCenter?: boolean;
  justifyFlexEnd?: boolean;
  justifySpaceAround?: boolean;
  justifySpaceBetween?: boolean;
  ref?: any;
  wrapWrap?: boolean;
  wrapWrapReverse?: boolean;
}

const getRule = (ruleName: string, defaultRule: string) => (props: Props) => {
  const foundRule = find(keys(props), (key) =>
    startsWith(key, ruleName)
  ) as keyof Props;
  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!foundRule || !props[foundRule]) {
    return defaultRule;
  }
  const value = foundRule.replace(ruleName, "");
  return kebabCase(value);
};

/**
 * @deprecated use tailwind instead.
 * @example
 * <div className="flex">
 */
export const FlexContainer = styled("div")<Props>`
  display: flex;
  flex-direction: ${getRule("direction", "row")};
  flex-wrap: ${getRule("wrap", "nowrap")};
  justify-content: ${getRule("justify", "flex-start")};
  align-items: ${getRule("items", "stretch")};
  align-content: ${getRule("content", "stretch")};

  ${(props) =>
    props.fullWidth &&
    `
    width: 100%;
  `};
`;
