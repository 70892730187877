export type JiraIntegrationFieldData =
  | {
      issueKey: string;
      progressType: string;
    }
  | {
      jql: string;
    };

export const jiraDataToIntegrationField = (
  data: JiraIntegrationFieldData
): string => JSON.stringify(data);

export const integrationFieldToJiraData = (
  integrationField: string
): JiraIntegrationFieldData => {
  return JSON.parse(integrationField);
};
