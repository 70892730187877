import { ProgressDriver } from "@graphql";
import { FormattedMessage } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Icon } from "common/icons";

export const ObjectiveChildrenWarning = () => {
  const objective = useObjectiveContext();

  const dependsOnAlignedObjectives =
    objective.progressDriver === ProgressDriver.AlignedObjectives;
  const hasChildren = objective.children.edgeCount > 0;
  if (!dependsOnAlignedObjectives || hasChildren) {
    return null;
  }

  return (
    <div className="flex items-center space-x-1 mt-4 text-slate-500">
      <Icon name="info_outline" className="text-red-500" />
      <FormattedMessage
        defaultMessage="You haven't aligned any OKRs yet, while the Objective's progress and status depend on it"
        id="lLnrYW"
        tagName="div"
      />
    </div>
  );
};
