import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { usePerformanceReviewCardContext } from "common/performanceReview/PerformanceReviewCard/PerformanceReviewCardContext/PerformanceReviewCardContext";
import { messages } from "common/performanceReview/PerformanceReviewForm/usePerformanceReviewFrequencyOptions/usePerformanceReviewFrequencyOptions";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import {
  PerformanceReviewModalType,
  useModalRouter,
} from "hooks/useModalRouter/useModalRouter";
import { twClass } from "utils/twClass";
import { performanceReviewStatuses } from "../../performanceReviews.utils";

export const titleMessages = defineMessages({
  reviewSharedSubtitle: {
    defaultMessage: "{count}/2 saved",
    id: "performanceReview:card:saved:subTitle",
  },
  reviewTitle: {
    defaultMessage: "Performance Review of {name}",
    id: "performanceReview:card:title",
  },
});

type SubtitleItemProps = {
  children: React.ReactNode;
  className?: string;
};

export const SubtitleItem = ({
  children,
  className,
}: SubtitleItemProps): JSX.Element => (
  <div
    className={twClass(
      "flex items-center justify-center space-x-1 text-slate-500",
      className
    )}
  >
    {children}
  </div>
);

export const PerformanceReviewCardTitle = (): JSX.Element => {
  const intl = useIntl();
  const { performanceReviewHref } = useModalRouter();
  const me = useCurrentUser();
  const review = usePerformanceReviewCardContext();
  const { userCompleted, otherUserCompleted } = performanceReviewStatuses(
    me,
    review
  );
  const sharedCount = [userCompleted, otherUserCompleted].filter(
    (x) => x
  ).length;

  const href = performanceReviewHref({
    prId: review.id,
    type: PerformanceReviewModalType.SUBMIT,
  }).toString();
  return (
    <div
      className="flex flex-col items-center pl-4"
      data-testid="performanceReviewCard"
    >
      <AnchorNext
        className="flex min-w-0 max-w-xs flex-wrap text-base font-medium text-slate-800 hover:text-blue-500 xl:max-w-none"
        href={href}
      >
        {intl.formatMessage(titleMessages.reviewTitle, {
          name: review.reviewee?.user?.fullName ?? "",
        })}
      </AnchorNext>
      <div className="flex w-full items-start space-x-4">
        <SubtitleItem>
          <Icon name="repeat" />
          <span className="text-sm">
            {intl.formatMessage(messages[review.repeatFrequency])}
          </span>
        </SubtitleItem>
        <SubtitleItem>
          <Icon name="send" />
          <span className="text-sm">
            {intl.formatMessage(titleMessages.reviewSharedSubtitle, {
              count: sharedCount,
            })}
          </span>
        </SubtitleItem>
      </div>
    </div>
  );
};
