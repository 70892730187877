import { useGetOneOnOneMeetingItemTemplatesQuery } from "@graphql";
import { isNil, orderBy } from "lodash";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { For, Show } from "common/controlFlow";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { roles } from "constants/roles";
import { useHasUserAccess } from "hooks/useHasUserAccess/useHasUserAccess";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { useCompleteOneOnOneContext } from "../context/completeOneOnOneContext";
import { AddMeetingItemTemplateForm } from "../OneOnOneMeetingItemTemplateForm/AddMeetingItemTemplateForm";
import { EditMeetingItemTemplateForm } from "../OneOnOneMeetingItemTemplateForm/EditMeetingItemTemplateForm";
import { TEMPLATE_MODAL_TYPE } from "../OneOnOneMeetingItemTemplateForm/OneOnOneMeetingItemTemplateForm";
import { PERDOO_MEETING_ITEM_TEMPLATES } from "./MeetingItemPerdooTemplates";
import { OneonOneMeetingItemTemplateCard } from "./OneOnOneMeetingItemTemplateCard";

type ActiveModal = {
  id?: string;
  type: TEMPLATE_MODAL_TYPE | null;
};

const initialActiveModalState = {
  type: null,
};

export const OneOnOneMeetingItemTemplatesView = (): JSX.Element => {
  const isAdmin = useHasUserAccess(roles.admin);
  const [activeModal, setActiveModal] = useState<ActiveModal>(
    initialActiveModalState
  );

  const { setShowTemplateView } = useCompleteOneOnOneContext();

  const { data } = useGetOneOnOneMeetingItemTemplatesQuery();
  const loading = isNil(data);

  if (loading)
    return (
      <div className="overflow-y-hidden">
        <SpinnerFit />
      </div>
    );

  const existingMeetingItemTemplates = mapEdges(
    data.allOneOnOneMeetingItemTemplates.edges
  ).map((temp) => ({ ...temp, isSample: false }));

  const meetingItemTemplates = [
    ...orderBy(existingMeetingItemTemplates, "name"),
    ...PERDOO_MEETING_ITEM_TEMPLATES,
  ];

  const closeTemplateForm = () => setActiveModal(initialActiveModalState);

  const showEditModal = (id: string) =>
    setActiveModal({ id, type: TEMPLATE_MODAL_TYPE.EDIT });

  return (
    <div className="modal-scrollbar modal-scrollbar-thumb h-full overflow-y-auto">
      <Show when={activeModal.type === TEMPLATE_MODAL_TYPE.ADD}>
        <AddMeetingItemTemplateForm close={closeTemplateForm} />
      </Show>
      {activeModal.type === TEMPLATE_MODAL_TYPE.EDIT &&
        isSet(activeModal.id) && (
          <EditMeetingItemTemplateForm
            close={closeTemplateForm}
            id={activeModal.id}
          />
        )}
      <div className="pb-4" data-testid="template-view">
        <div className="container mx-auto w-full grow flex-col items-center px-12 pt-8 2xl:px-36">
          <div className="mb-5 flex grow items-end justify-between border-b pb-2.5">
            <div className="flex-col">
              <div className="flex items-center text-sm font-medium text-blue-500">
                <IconButton
                  className="gap-0"
                  data-cy="ZjlTyTfR-rUUcMMJsTuKs"
                  iconClass="-rotate-90"
                  name="expand_less"
                  onClick={() => setShowTemplateView(false)}
                  size="xl"
                >
                  <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
                </IconButton>
              </div>
              <span className="text-lg">
                <FormattedMessage defaultMessage="Use a template" id="fnuKBx" />
              </span>
            </div>
            <Show when={isAdmin}>
              <Button
                data-cy="add-new-template-button"
                onClick={() =>
                  setActiveModal({
                    type: TEMPLATE_MODAL_TYPE.ADD,
                  })
                }
              >
                + <FormattedMessage defaultMessage="Add new" id="i2jZA1" />
              </Button>
            </Show>
          </div>
          <div className="flex flex-col justify-center space-y-2">
            <Show
              fallback={
                <span className="self-center">
                  <FormattedMessage
                    defaultMessage="No templates shared"
                    id="NOGFtn"
                  />
                </span>
              }
              when={Boolean(meetingItemTemplates.length)}
            >
              <For each={meetingItemTemplates}>
                {(template) => (
                  <OneonOneMeetingItemTemplateCard
                    close={() => setShowTemplateView(false)}
                    showEditModal={showEditModal}
                    template={template}
                  />
                )}
              </For>
            </Show>
          </div>
        </div>
      </div>
    </div>
  );
};
