import { FieldInputProps, FormikProps } from "formik";
import { get } from "lodash";
import React from "react";
import {
  TextEditor,
  TextEditorProps,
} from "common/inputs/TextEditor/TextEditor";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { EMPTY_TEXTAREA_VALUE } from "../DescriptionForm/DescriptionForm";

type Props = Omit<TextEditorProps, "onChange" | "text"> & {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
};

export const FormTextArea = ({
  autoFocus = false,
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  field,
  form: { touched, errors },
  minimizedView = true,
  editorClassName,
  ...editorProps
}: Props): JSX.Element => {
  const handleChange = (val: string) => {
    const value = val === EMPTY_TEXTAREA_VALUE ? "" : val;
    field.onChange({ target: { name: field.name, value } });
  };

  const errorText = get(touched, field.name) && get(errors, field.name);
  return (
    <TextEditor
      {...editorProps}
      autoFocus={autoFocus}
      className={twClass({ "border-red-500": isSet(errorText) }, className)}
      data-cy={dataCy}
      data-testid={dataTestId}
      editorClassName={twClass("h-18", editorClassName)}
      minimizedView={minimizedView}
      onChange={handleChange}
      text={field.value}
    />
  );
};
