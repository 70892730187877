import { debounce } from "lodash";
import { useEffect, useState } from "react";

type Hook = { height: number; width: number } | null;

export const useWindowSize = (debounceDelay = 0): Hook => {
  const isClient = typeof window === "object";

  const getSize = () =>
    isClient
      ? {
          height: window.innerHeight,
          width: window.innerWidth,
        }
      : null;

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }

    let handleResize = () => setWindowSize(getSize());
    if (debounceDelay > 0) {
      handleResize = debounce(handleResize, debounceDelay);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // reason: Empty array ensures that effect is only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
};
