import { FieldInputProps } from "formik";
import { get } from "lodash";
import React, { forwardRef, ReactNode } from "react";
import { FormControl } from "common/form/FormControl/FormControl";
import { FormType } from "common/form/FormInput/FormInput.types";
import { twClass } from "utils/twClass";

type NativeProps = Omit<
  React.ComponentProps<"input">,
  "form" | "prefix" | "size"
>;
type OverridenProps = { form?: FormType };
type Props = NativeProps &
  OverridenProps & {
    "data-cy"?: string;
    "data-testid"?: string;
    disableOnEnter?: boolean;
    errorText?: ReactNode | null;
    field?: FieldInputProps<any>; // TODO: remove and replace usages with `InputField`
    formControl?: boolean; // TODO: remove and replace usages with `InputField`
    formControlClassName?: string; // TODO: remove and replace usages with `InputField`
    infoElement?: ReactNode | string; // TODO: remove and replace usages with `InputField`
    label?: string; // TODO: remove and replace usages with `InputField`
    onSubmit?: (value?: any) => void;
    optional?: boolean; // TODO: remove and replace usages with `InputField`
    outsideRef?: any;
    prefix?: ReactNode;
    size?: InputSize;
    suffix?: ReactNode;
    variant?: InputVariant;
  };

export type InputProps = Props;
export type InputSize = "sm" | "base";
export type InputVariant = "borderless" | "normal";

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      "aria-label": ariaLabel,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      autoFocus,
      className,
      formControlClassName,
      disableOnEnter = false,
      disabled = false,
      errorText,
      field,
      form,
      formControl = true,
      id,
      infoElement,
      label,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      onSubmit,
      optional = false,
      outsideRef,
      placeholder,
      prefix,
      suffix,
      readOnly = false,
      tabIndex,
      type = "text",
      value = "",
      variant = "normal",
      size = "base",
    },
    ref
  ): JSX.Element => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === "number" && Number.isNaN(Number(e.target.value))) {
        return;
      }
      onChange?.(e);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!disableOnEnter) {
        if (e.key === "Enter") {
          onSubmit?.();
        }
      }
    };

    const error =
      errorText ||
      (field &&
        get(form?.touched, field.name) &&
        get(form?.errors, field.name));
    const formControlProps = {
      errorText: error as string,
      id,
      infoElement,
      label,
      optional,
      ...(formControlClassName && { className: formControlClassName }),
    };

    const variantClasses: Record<InputVariant, string> = {
      borderless: "",
      normal: twClass("bg-white rounded border border-slate-300", {
        "bg-slate-100 text-slate-400": disabled,
        "border-red-500": errorText,
        "focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500":
          !disabled && !errorText,
        "focus-within:ring-1 focus-within:ring-red-500 focus-within:border-red-500":
          !disabled && errorText,
      }),
    };

    const sizeClasses: Record<InputSize, string> = {
      base: twClass("h-10 text-base space-x-2.5", {
        "px-2.5": variant !== "borderless",
      }),
      sm: twClass("h-8 text-sm space-x-1.5", {
        "px-1.5": variant !== "borderless",
      }),
    };

    const input = (
      <div
        ref={outsideRef}
        className={twClass(
          "w-full flex items-center",
          "font-sans text-slate-800",
          {
            "bg-slate-100": disabled && variant !== "borderless",
            "bg-white rounded border border-slate-300":
              variant !== "borderless",
            "border-red-500": errorText && variant !== "borderless",
            "cursor-not-allowed": disabled,
          },
          variantClasses[variant],
          sizeClasses[size],
          className
        )}
      >
        {prefix && (
          <div className="flex items-center whitespace-nowrap">{prefix}</div>
        )}
        <input
          {...field}
          ref={ref}
          aria-label={ariaLabel}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          className={twClass(
            "grow min-w-0 appearance-none placeholder-slate-400 outline-none bg-transparent"
          )}
          data-cy={dataCy}
          data-testid={dataTestId}
          disabled={disabled}
          id={id}
          onBlur={onBlur}
          onChange={handleChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          readOnly={readOnly}
          tabIndex={tabIndex}
          type={type}
          value={value}
        />
        {suffix && (
          <div className="flex items-center whitespace-nowrap">{suffix}</div>
        )}
      </div>
    );
    if (formControl) {
      return <FormControl {...formControlProps}>{input}</FormControl>;
    }

    return input;
  }
);

Input.displayName = "Input";
