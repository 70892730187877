import React from "react";
import { FormattedMessage } from "react-intl";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const { CommentCreated } = EventTypes;

export const CommentEventFormatters: Record<string, EventFormatter> = {
  [CommentCreated]: {
    getComment: ({ extra }) => <DangerousHTML html={extra.content} />,
    getString: ({ extra }) => (
      <FormattedMessage
        defaultMessage="added a note on {targetName}"
        id="event:formatter:note:updatedBy"
        values={{
          targetName: <Target element={extra.target_name} />,
        }}
      />
    ),
  },
};
