import React from "react";
import { twClass } from "utils/twClass";

type ClickableProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  "data-cy"?: string;
  "data-testid"?: string;
};

export const Clickable = React.forwardRef<HTMLButtonElement, ClickableProps>(
  (
    { onClick, className, "data-cy": dataCy, children, ...rest },
    ref
  ): JSX.Element => (
    <button
      data-cy={dataCy}
      ref={ref}
      className={twClass("text-left", className)}
      onClick={onClick}
      type="button"
      {...rest}
    >
      {children}
    </button>
  )
);

Clickable.displayName = "Clickable";

type ClickableDivProps = React.HTMLAttributes<HTMLDivElement> & {
  "data-cy"?: string;
  disabled?: boolean;
};

/**
 * ❗ warning: in most cases you want to use {@link Clickable} instead, which renders an actual semantic button.
 * use only if that doesn't work, e.g. you need the text to be selectable. ❗
 */
export const ClickableDiv = React.forwardRef<HTMLDivElement, ClickableDivProps>(
  (
    { onClick, className, disabled, children, "data-cy": dataCy, ...rest },
    ref
  ): JSX.Element => (
    <div
      ref={ref}
      className={twClass(
        { "!cursor-default": disabled, "cursor-pointer": !disabled },
        className
      )}
      onClick={disabled ? undefined : onClick}
      role="button"
      tabIndex={0}
      data-cy={dataCy}
      {...rest}
    >
      {children}
    </div>
  )
);

ClickableDiv.displayName = "ClickableDiv";
