import Image from "next/legacy/image";
import React from "react";
import { FormattedMessage } from "react-intl";
import { AnchorNextTrack } from "common/navigation/AnchorNext/AnchorNextTrack";
import slackPrivateChannelImage from "../../../../../public/images/help-center-slack-private-channel-setup.png";
import slackPrivateAppImage from "../../../../../public/images/help-center-slack-private-channel-setup-2.png";
import { useHelpCenterTrack } from "../hooks/useHelpCenterTrack";

export const SlackIntegrationInfoView = (): JSX.Element | null => {
  const { trackContentHandler } = useHelpCenterTrack();
  return (
    <div className="flex flex-col divide-y px-5 text-sm">
      <div className="space-y-3 py-4">
        <h4 className="m-0 text-sm">
          <FormattedMessage
            defaultMessage="Can’t see your private channels?"
            id="slackInfoView:seePrivateChannels"
          />
        </h4>
        <div>
          <FormattedMessage
            defaultMessage="To see the private channels to you need to add the Perdoo app to the desired channel. To do that follow this simple 2 step process:"
            id="slackInfoView:body1"
          />
        </div>
        <div>
          <p className="mb-2">
            <FormattedMessage
              defaultMessage="1. Go to your private channel in your Slack. Navigate to the channel settings by clicking on its name at the top. Go to integrations tab."
              id="slackInfoView:body2"
            />
          </p>
          <Image className="rounded" src={slackPrivateChannelImage} />
        </div>
        <div>
          <p className="mb-2">
            <FormattedMessage
              defaultMessage="2. Use the “Add apps” link and search for Perdoo app. Finalize the process by going back to Perdoo and refreshing this page."
              id="slackInfoView:body3"
            />
          </p>
          <Image className="rounded" src={slackPrivateAppImage} />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="When these steps are completed you should be able to select your private channels from the list."
            id="slackInfoView:body4"
          />
        </div>
      </div>
      <div className="space-y-3 py-4">
        <h4 className="m-0 text-sm">
          <FormattedMessage
            defaultMessage="Need more support?"
            id="helpCenter:needMoreSupport"
          />
        </h4>
        <div className="space-y-3">
          <div>
            <AnchorNextTrack
              data-cy="7bYP1ZzME1w8mR-CLJQOl"
              external
              href="https://support.perdoo.com/en/articles/1588575-slack-integration"
              onClick={trackContentHandler(
                "https://support.perdoo.com/en/articles/1588575-slack-integration"
              )}
              target="_blank"
            >
              <FormattedMessage
                defaultMessage="Slack integration"
                id="slackInfoView:links:slackIntegration"
              />
            </AnchorNextTrack>
          </div>
          <div>
            <AnchorNextTrack
              data-cy="WLraeCfKE3rYNJ9RBB-RA"
              external
              href="http://support.perdoo.com/en/articles/1588575-slack-integration#:~:text=To%20be%20able%20to%20select,settings%20%3E%20Integrations%20%3E%20Add%20apps."
              onClick={trackContentHandler(
                "http://support.perdoo.com/en/articles/1588575-slack-integration#:~:text=To%20be%20able%20to%20select,settings%20%3E%20Integrations%20%3E%20Add%20apps."
              )}
              target="_blank"
            >
              <FormattedMessage
                defaultMessage="Why aren't updates pushed to my Slack channel?"
                id="slackInfoView:links:missingUpdates"
              />
            </AnchorNextTrack>
          </div>
        </div>
      </div>
    </div>
  );
};
