import { TimeframeDefaultFieldsFragment } from "@graphql";
import { toUpper } from "lodash";
import { useRouter } from "next/router";
import { GROUPS_TYPE_CHOICES } from "common/group/GroupSelector/GroupSelector.constants";
import { useTimeframes } from "hooks/useTimeframes/useTimeframes";
import { orderByDuration } from "utils/orderByDuration";

// TODO: fix types, `timeframeId` and `timeframe` can be undefined.
export type UseReportQueryParamsHook = {
  /** only used if groupsType is GROUP */
  groupId?: string;
  /** if missing, shows data for company & all groups */
  groupsType: keyof typeof GROUPS_TYPE_CHOICES;
  /** if missing, shows data for the active timeframe with the lowest duration */
  timeframe: TimeframeDefaultFieldsFragment & {
    objectives: { achievedCount: number | null };
  };
  /** if missing, shows data for the active timeframe with the lowest duration */
  timeframeId: string;
};

/**
 * parses query params from url and returns filter variables used in report modules.
 */
export const useReportQueryParams = (): UseReportQueryParamsHook => {
  const router = useRouter();
  const {
    "timeframe-id": timeframeId,
    "groups-type": groupsTypeCaseInsensitive,
    "group-id": groupId,
  } = router.query;
  const groupsType =
    toUpper(groupsTypeCaseInsensitive as string) || GROUPS_TYPE_CHOICES.ALL;
  const { activeTimeframes, timeframes } = useTimeframes();
  const timeframe =
    timeframes.find((tf) => tf.id === timeframeId) ??
    activeTimeframes.sort(orderByDuration)[0];

  return {
    groupId: groupId as string,
    groupsType: groupsType as keyof typeof GROUPS_TYPE_CHOICES,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "timeframe"; id: string; name:... Remove this comment to see the full error message
    timeframe,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | string[] | undefined' is not assign... Remove this comment to see the full error message
    timeframeId: timeframeId ?? timeframe?.id,
  };
};
