import classNames from "clsx";
import React, { ReactNode } from "react";
import { Owner } from "common/goal/GoalOwners/useGoalOwners";

interface DrilldownGroupsProps {
  children: (user: Owner) => ReactNode;
  groups: Owner[];
}

export const DrilldownGroups = ({
  children,
  groups,
}: DrilldownGroupsProps): JSX.Element => {
  return (
    <ul className="mt-3">
      {groups.map((group) => (
        <li
          key={group.id}
          className={classNames(
            "flex items-center justify-between",
            "h-16",
            "border-t border-slate-300"
          )}
        >
          {children(group)}
        </li>
      ))}
    </ul>
  );
};
