import { isNil } from "lodash";

/**
 * maps edges returned from graphql to a simple array of elements.
 *
 * @example
 * const { data } = useQuery(...); // { edges: { node: Item }[] }
 * const items = mapEdges(data.items.edges); // Item[]
 */
export const mapEdges = <T>(edges: { node: T }[] | undefined): T[] =>
  edges?.map(({ node }) => node) ?? [];

/**
 * @example
 * type UsersQueryEdges = { node: { id: string; fullName: string } }[]
 * const mockUsers: MappedEdges<UsersQueryEdges> = [{
 *   id: "1",
 *   fullName: "John Doe",
 * }];
 * ```
 */
export type MappedEdges<Edges> = Edges extends { node: infer Node }[]
  ? Node[]
  : never[];

/**
 * takes an array of elements and returns an apollo-like object: <br />
 * `{ edges: [ { node: item }, ...]}`. <br />
 * useful for mocks.
 *
 * @example
 * const mockUsers = [user1, user2]; // User[]
 * const queryResponse = unmapEdges(mockUsers); // { edges: { node: User }[] }
 */
export function unmapEdges<Type>(items: Type[]): { node: Type }[];
export function unmapEdges<Type, Edge>(
  items: Type[],
  __typename: Edge
): { __typename: Edge; node: Type }[];
export function unmapEdges<Type, Edge>(
  items: Type[],
  __typename: Edge,
  cursor: string
): { __typename: Edge; cursor: string; node: Type }[];
export function unmapEdges<Type, Edge>(
  items: Type[],
  typenameOrNothing?: Edge,
  cursor?: string
): { __typename?: Edge; cursor?: string; node: Type }[] {
  if (isNil(typenameOrNothing)) {
    return items.map((item) => ({ node: item }));
  }

  return items.map((item) => ({
    __typename: typenameOrNothing,
    cursor,
    node: item,
  }));
}
