import { defineMessages } from "react-intl";

export const emptyState = defineMessages({
  bestUpdaters: {
    defaultMessage: "No best updaters",
    id: "engagementReport:emptyState:bestUpdaters",
  },
  checkInsChart: {
    defaultMessage: "No data to show",
    id: "engagementReport:emptyState:checkInsChart",
  },
  goalsUpToDateChart: {
    defaultMessage: "No data to show",
    id: "engagementReport:emptyState:goalsUpToDateChart",
  },
  inactiveUsers: {
    defaultMessage: "Hooray! No inactive users.",
    id: "engagementReport:emptyState:inactiveUsers",
  },
  worstUpdaters: {
    defaultMessage: "No worst updaters",
    id: "engagementReport:emptyState:worstUpdaters",
  },
});

export const messages = defineMessages({
  didntLogIn: {
    defaultMessage: "Didn’t log in",
    id: "engagementReport:inactiveUsers:didntLogIn",
  },
  neverCheckedIn: {
    defaultMessage: "Never added Reflections",
    id: "engagementReport:reflections:never",
  },
  nudge: {
    defaultMessage: "Nudge",
    id: "engagementReport:nudge",
  },
  nudgeAll: {
    defaultMessage: "Nudge all",
    id: "engagementReport:nudgeAll",
  },
  responses: {
    defaultMessage: "{count} {count, plural, one {response} other {responses}}",
    id: "pulse:report:responses",
  },
  streakEnded: {
    defaultMessage: "Streak ended {checkInDate}",
    id: "engagementReport:checkIn:streakEnded",
  },
});

export const sections = defineMessages({
  checkIns: {
    defaultMessage: "Reflections",
    id: "gRnLO1",
  },
  goalsUpToDate: {
    defaultMessage: "Goals up-to-date",
    id: "engagementReport:section:goalsUpToDate",
  },
  pulse: {
    defaultMessage: "Pulse",
    id: "pulse",
  },
  userEngagement: {
    defaultMessage: "Logins",
    id: "engagementReport:section:userEngagement",
  },
});

export const tooltips = defineMessages({
  bestUpdaters: {
    defaultMessage: "Users with more than 90% of their goals up-to-date",
    id: "engagementReport:tooltip:worstUpdaters",
  },
  inactiveUsers: {
    defaultMessage: "% of users that didn’t log in during {timeframe}",
    id: "engagementReport:tooltip:inactiveUsers",
  },
  longestStreak: {
    defaultMessage: "Users that check in regularly across all timeframes",
    id: "engagementReport:tooltip:longestStreak",
  },
  noStreak: {
    defaultMessage:
      "Users that never added Reflections in or lost their streak",
    id: "engagementReport:tooltip:reflections:noStreak",
  },
  nudge: {
    defaultMessage:
      "Nudge this user(s) to encourage them to engage with the platform. Clicking on ‘Nudge’ will open your email client, " +
      "allowing you to craft a personalized message.",
    id: "engagementReport:tooltip:nudge",
  },
  worstUpdaters: {
    defaultMessage: "Users with less than 90% of their goals up-to-date",
    id: "engagementReport:tooltip:bestUpdaters",
  },
});

export const widgets = defineMessages({
  bestUpdaters: {
    defaultMessage: "Best updaters",
    id: "engagementReport:widget:bestUpdaters",
  },
  checkInsChart: {
    defaultMessage: "Reflections added in {timeframeName}",
    id: "engagementReport:widget:reflectionsChart",
  },
  goalsUpToDateByGroup: {
    defaultMessage: "Goals up-to-date",
    id: "WpVcRe",
  },
  goalsUpToDateChart: {
    defaultMessage: "How many goals are up-to-date?",
    id: "engagementReport:widget:goalsUpToDateChart",
  },
  inactiveUsers: {
    defaultMessage: "Inactive users",
    id: "engagementReport:widget:inactiveUsers",
  },
  inactiveUsersTimeframe: {
    defaultMessage: "Inactive users in {timeframe}",
    id: "engagementReport:widget:inactiveUsersTimeframe",
  },
  longestStreak: {
    defaultMessage: "Longest streak",
    id: "engagementReport:widget:longestStreak",
  },
  noStreak: {
    defaultMessage: "No streak",
    id: "engagementReport:widget:noStreak",
  },
  okrsNotClosedByGroup: {
    defaultMessage: "OKRs not closed",
    id: "tsMHcQ",
  },
  pulseAverage: {
    defaultMessage: "Average pulse",
    id: "engagementReport:widget:pulseAverage",
  },
  pulseBySubteam: {
    defaultMessage: "Subteams",
    id: "ETq3+E",
  },
  pulseByTeam: {
    defaultMessage: "Teams",
    id: "DNeuzb",
  },
  pulseChart: {
    defaultMessage: "Pulse in {timeframeName}",
    id: "engagementReport:widget:pulseChart",
  },
  teamsWithoutKpis: {
    defaultMessage: "Teams without KPIs",
    id: "UVlbx7",
  },
  teamsWithoutOkrs: {
    defaultMessage: "Teams without OKRs",
    id: "isFR2T",
  },
  userLoginChart: {
    defaultMessage: "When did users log in?",
    id: "engagementReport:widget:userLoginChart",
  },
  usersLoggedIn: {
    defaultMessage: "Users logged in",
    id: "engagementReport:widget:usersLoggedIn",
  },
  worstUpdaters: {
    defaultMessage: "Falling behind",
    id: "engagementReport:widget:worstUpdaters",
  },
});
