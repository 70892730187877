import { OneOnOneRepeatFrequency } from "@graphql";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { LocalTimeHelper } from "common/form/LocalTimeHelper";
import { TimePickerField } from "common/form/TimePickerField/TimePickerField";
import { Label } from "common/inputs";
import { ONE_ON_ONE_TIME_INCREMENT } from "common/oneOnOnes/constants";
import { useCompany } from "hooks/useCompany/useCompany";
import { useOneOnOneMeetingFrequencyOptions } from "../useOneOnOneMeetingFrequencyOptions/useOneOnOneMeetingFrequencyOptions";

type Values = {
  dueDate: string | null;
  isActive: boolean;
  meetingTime: string | null;
  repeatFrequency: OneOnOneRepeatFrequency | null;
};

export const RecurrenceFormSection = <
  TValues extends Values,
>(): JSX.Element => {
  const intl = useIntl();
  const { values, handleBlur } = useFormikContext<TValues>();
  const meetingFrequencyOptions = useOneOnOneMeetingFrequencyOptions();
  const { timezone } = useCompany();

  const meetingDate = dayjs(values.dueDate);
  const meetingTime = values.meetingTime || meetingDate.format("HH:mm");
  const meetingDateTime = dayjs.tz(
    `${meetingDate.format("YYYY-MM-DD")} ${meetingTime}`,
    timezone
  );
  const meetingLocalTime = meetingDateTime.local();
  return (
    <div>
      <Field name="repeatFrequency">
        {({ field, form }: FieldProps) => {
          return (
            <FormSelect
              data-cy="repeatFrequency"
              disabled={!values.isActive}
              field={field}
              form={form}
              label={intl.formatMessage({
                defaultMessage: "Frequency",
                id: "vAW30j",
              })}
              options={meetingFrequencyOptions}
            />
          );
        }}
      </Field>
      <div className="mb-1">
        <Label>
          <FormattedMessage defaultMessage="Meeting date & time" id="x0x3mE" />
        </Label>
      </div>
      <div className="flex items-baseline gap-2">
        <Field name="dueDate">
          {({ field, form }: FieldProps) => (
            <FormDatePicker
              className="w-36"
              formControlClassName="my-0"
              disabled={!values.isActive}
              field={field}
              form={form}
              onBlur={handleBlur("dueDate")}
            />
          )}
        </Field>
        <TimePickerField
          disabled={!values.isActive}
          increment={ONE_ON_ONE_TIME_INCREMENT}
          name="meetingTime"
        />
      </div>
      <LocalTimeHelper localTime={meetingLocalTime} className="my-2" />
    </div>
  );
};
