import classNames from "clsx";
import { isFunction, isNil } from "lodash";
import React, { ReactNode, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import Select, {
  components,
  ControlProps,
  MenuListComponentProps,
  OptionProps,
  OptionsType,
} from "react-select";
import { StackedAvatar } from "common/avatar";
import { Button } from "common/buttons";
import { Icon } from "common/icons/Icon/Icon";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { Tag } from "common/tag/Tag/Tag";
import { colorTheme } from "constants/colorTheme";
import { DropdownContext } from "legacy/components/Dropdown/DropdownContext";
import { DropdownIndicator } from "legacy/components/filters/FilterSearch/FilterSearch";

type EntityLikeOption = {
  images: readonly string[];
  label: string;
  value: string;
};

type TagLikeOption = {
  color: string | null;
  label: string;
  value: string;
};

type MultiSelectOptionBase = EntityLikeOption | TagLikeOption;

export type CloseFn<OptionType extends MultiSelectOptionBase> = (
  selectedOptions?: OptionsType<OptionType>
) => void;

export type SaveTextFn = ReactNode | ((count: number) => ReactNode);

type Props<OptionType extends MultiSelectOptionBase> = {
  disabled?: boolean;
  initialValue?: string[];
  onClose?: CloseFn<OptionType>;
  options: OptionsType<OptionType> | undefined;
  required?: boolean;
  saveText?: SaveTextFn;
};

type IsMulti = true;

function Control<OptionType extends MultiSelectOptionBase>(
  props: ControlProps<OptionType, IsMulti>
) {
  return (
    <div className="p-2">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Control {...props} />
    </div>
  );
}

function MenuListFactory<OptionType extends MultiSelectOptionBase>(
  isLoading: boolean,
  onClose: CloseFn<OptionType>,
  saveText: SaveTextFn,
  required: boolean
) {
  const MenuList = (
    props: MenuListComponentProps<OptionType, IsMulti>
  ): JSX.Element => {
    // eslint-disable-next-line react/destructuring-assignment
    const selectedItems = props.getValue();
    const onCloseThenReset: CloseFn<OptionType> = (value) => {
      onClose(value);
      props.setValue([], "select-option");
    };
    const hasSelectedItems = selectedItems.length > 0;
    return (
      <>
        <div className="max-h-64 overflow-y-auto py-2">
          {isLoading ? (
            <div className="mt-2 flex justify-center">
              <Spinner />
            </div>
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <components.MenuList {...props} />
          )}
        </div>
        <div className="flex justify-end space-x-1 border-t p-2">
          <Button
            data-cy="multi-select-cancel"
            onClick={() => onCloseThenReset()}
            size="small"
            variant="ghost"
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <Button
            data-cy="multi-select-save"
            disabled={required && (!hasSelectedItems || isLoading)}
            onClick={() => onCloseThenReset(selectedItems)}
            size="small"
          >
            {isFunction(saveText) ? saveText(selectedItems.length) : saveText}
          </Button>
        </div>
      </>
    );
  };
  return MenuList;
}

function Option<OptionType extends MultiSelectOptionBase>({
  data,
  children,
  innerProps,
  innerRef,
  isSelected,
  isFocused,
}: OptionProps<OptionType, IsMulti>) {
  return (
    <div
      ref={innerRef}
      className={classNames(
        "flex cursor-pointer items-center space-x-2 px-3 py-2",
        {
          "bg-blue-50": isSelected || isFocused,
          "text-blue-500": isFocused,
        }
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
    >
      {"images" in data && (
        <div className={classNames({ "mr-2": data.images.length > 1 })}>
          <StackedAvatar size="medium" urls={data.images} />
        </div>
      )}
      <div className="flex-grow">
        {"color" in data ? (
          <Tag text={children} color={data.color} />
        ) : (
          children
        )}
      </div>
      {isSelected && (
        <div>
          <Icon className="text-blue-500" name="check" size="xl" />
        </div>
      )}
    </div>
  );
}

export function MultiSelect<OptionType extends MultiSelectOptionBase>({
  options,
  disabled = false,
  onClose = () => {},
  initialValue,
  required = true,
  saveText,
}: Props<OptionType>): JSX.Element {
  // We need to keep track of the search box value
  // otherwise react-select will automatically delete the input
  // value when an option is selected.
  const [inputValue, setInputValue] = useState("");
  const isLoading = isNil(options);
  const defaultValue = !!initialValue
    ? options?.filter((opt) => initialValue.includes(opt.value))
    : undefined;
  const dropdownContext = useContext(DropdownContext);

  const closeAndForward: CloseFn<OptionType> = (selected) => {
    dropdownContext.close();
    onClose(selected);
  };

  const defaultSaveText: SaveTextFn = (count) => (
    <FormattedMessage
      defaultMessage="{count, plural, one {Add {count} selected} other {Add {count} selected}}"
      id="u0WAJS"
      values={{ count }}
    />
  );

  return (
    <div data-testid="multiSelect" onClick={(e) => e.stopPropagation()}>
      <Select
        autoFocus
        className="w-80"
        defaultValue={defaultValue}
        components={{
          ClearIndicator: null,
          Control,
          DropdownIndicator,
          IndicatorSeparator: null,
          MenuList: MenuListFactory<OptionType>(
            isLoading,
            closeAndForward,
            saveText ?? defaultSaveText,
            required
          ),
          Option,
        }}
        controlShouldRenderValue={false}
        defaultMenuIsOpen
        hideSelectedOptions={false}
        inputValue={inputValue}
        isDisabled={disabled}
        isMulti
        menuIsOpen
        menuPlacement="auto"
        onInputChange={(a, { action }) => {
          if (action === "input-change") {
            setInputValue(a);
          }
        }}
        options={options}
        styles={{
          menu: () => ({
            boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
          }),
          menuList: () => ({
            paddingBottom: 0,
            paddingTop: 0,
          }),
          menuPortal: (base) => ({
            ...base,
            minWidth: 400,
            zIndex: 99999,
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            danger: colorTheme.red[500],
            neutral20: colorTheme.slate[300],
            neutral30: colorTheme.slate[300],
            primary: colorTheme.blue[500],
            primary25: colorTheme.blue[100],
            primary50: colorTheme.blue[100],
            primary75: colorTheme.blue[200],
          },
        })}
      />
    </div>
  );
}
