import { useAllUsersLazyQuery, useGetDirectReportsLazyQuery } from "@graphql";
import { FieldInputProps, FormikProps } from "formik/dist/types";
import { isArray } from "lodash";
import get from "lodash/get";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  components,
  OptionProps,
  OptionTypeBase,
  SingleValueProps,
} from "react-select";
import { Avatar } from "common/avatar";
import { TextButton } from "common/buttons";
import { Show } from "common/controlFlow";
import { AddOneOnOneMeetingValues } from "common/oneOnOnes/OneOnOneMeetingForm/AddOneOnOneMeetingForm";
import { AddPerformanceValues } from "common/performanceReview/PerformanceReviewForm/AddPerformanceReviewForm";
import { messages } from "common/performanceReview/PerformanceReviewForm/performanceReviewForm.messages";
import { handleListFormatting } from "common/performanceReview/PerformanceReviewForm/performanceReviewUsersSelect/utils";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { allRolesButViewonly } from "constants/roles";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { Select } from "legacy/components/Select/Select";

type Option = { label: string; value: string };

export type OnChangeVariable = {
  target: { name: string; value: string[] | string | null };
};

type Props = {
  "data-testid"?: string;
  field: FieldInputProps<string[] | null>;
  form: FormikProps<AddPerformanceValues | AddOneOnOneMeetingValues>;
  isMulti?: boolean;
  label?: string;
  onChange?: (option: OnChangeVariable) => void;
  options?: {
    label: JSX.Element | string;
    options: {
      label: string;
      value: string;
    }[];
  }[];
};

export const FormCategorizedUsersSelect = ({
  onChange,
  options,
  label,
  field,
  isMulti = true,
  "data-testid": dataTestId,
  form: { errors, submitCount },
}: Props): JSX.Element | null => {
  const me = useCurrentUser();
  const intl = useIntl();

  const [getDirectReports, { data: directReportsData }] =
    useGetDirectReportsLazyQuery({
      variables: {
        roleIn: allRolesButViewonly.join(),
      },
    });
  const [getAllUsers, { data: allUsersData }] = useAllUsersLazyQuery({
    variables: {
      isActive: true,
      orderBy: "last_name",
      roleIn: allRolesButViewonly.join(),
    },
  });

  useEffect(() => {
    if (!options) {
      getDirectReports();
      getAllUsers();
    }
  }, [options]);

  const loading = !directReportsData || !allUsersData;
  if (loading) {
    return <SpinnerFit />;
  }

  const { directReports, remainingUsers } = handleListFormatting(
    me?.id ?? "",
    allUsersData,
    directReportsData
  );
  const combinedList = [...directReports, ...remainingUsers];

  const groupedOptions = options || [
    {
      label: (
        <FormattedMessage
          defaultMessage="Direct reports"
          id="home:widget:directReports"
        />
      ),
      options: directReports,
    },
    {
      label: intl.formatMessage(messages.remainingUsers),
      options: remainingUsers,
    },
  ];

  const findActiveUsers = () => {
    return combinedList.filter((user) => field.value?.includes(user.value));
  };

  const handler = onChange || field.onChange;

  const handleSelectAll = () => {
    handler({
      target: {
        name: field.name,
        value: directReports.map((user) => user.value),
      },
    });
  };

  const handleChange = (option: Option[] | Option | null) => {
    if (option === null) {
      return handler({
        target: {
          name: field.name,
          value: null,
        },
      });
    }
    if (!isMulti && !isArray(option)) {
      return handler({
        target: {
          name: field.name,
          value: option.value,
        },
      });
    }
    if (isArray(option)) {
      return handler({
        target: {
          name: field.name,
          value: option.map((opt) => opt.value),
        },
      });
    }
  };

  const CustomOption = ({
    data,
    ...props
  }: OptionProps<OptionTypeBase, true>) => {
    return (
      <components.Option data={data} {...props}>
        <div className="flex items-center space-x-1">
          {data.avatar && <Avatar size="small" url={data.avatar} />}
          <div>{data.label}</div>
        </div>
      </components.Option>
    );
  };

  const SingleValue = ({
    data,
    ...props
  }: SingleValueProps<OptionTypeBase>) => {
    return (
      <components.SingleValue data={data} {...props}>
        <div className="flex items-center space-x-1">
          {data.avatar && <Avatar size="small" url={data.avatar} />}
          <div>{data.label}</div>
        </div>
      </components.SingleValue>
    );
  };

  const errorText = submitCount > 0 && get(errors, field.name);
  return (
    <>
      <Select
        components={{ Option: CustomOption, SingleValue }}
        data-testid={dataTestId}
        errorText={errorText}
        isInForm
        isMulti={isMulti}
        label={label}
        name={field.name}
        onChange={handleChange}
        options={groupedOptions}
        value={field.value ? findActiveUsers() : null}
      />
      <Show when={isMulti}>
        <TextButton
          data-cy="6mUJtS0uRn6aPScvXPv2_"
          className="p-0 pb-1 text-sm font-normal text-blue-500"
          onClick={handleSelectAll}
          size="small"
          text={intl.formatMessage(messages.selectAllDirectReports)}
        />
      </Show>
    </>
  );
};
