/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  OnboardingSectionId,
  OnboardingStepId,
  useOnboardingStepsQuery,
  useSyncOnboardingMutation,
} from "@graphql";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { IconNameKey } from "common/icons/Icon/Icon.types";
import { useCompleteOnboardingStep } from "hooks/useCompleteOnboardingStep/useCompleteOnboardingStep";

type QSGStep = {
  completed: boolean;
  id: OnboardingStepId;
  onClick: () => void;
  subtitle: JSX.Element;
  title: JSX.Element;
};

type QSGStepSection = {
  icon: IconNameKey;
  id: string;
  progress: number;
  steps: QSGStep[];
  subtitle: JSX.Element;
  title: JSX.Element;
};

export type UseOnboardingStepsHook = {
  isStepCompleted: (stepId: OnboardingStepId) => boolean;
  refetchSteps: () => void;
  stepSections: QSGStepSection[];
};

export const useOnboardingSteps = (): UseOnboardingStepsHook => {
  const { data } = useOnboardingStepsQuery();
  const { actions } = useCompleteOnboardingStep();
  const [syncOnboardingSteps] = useSyncOnboardingMutation();
  useEffect(() => {
    syncOnboardingSteps();
  }, []);

  const sections = data?.currentCompany.onboardingSections ?? [];

  const stepSections = sections.map((section) => ({
    ...section,
    ...SECTION_STATIC_PROPS[section.id],
    steps: section.steps.map((step) => ({
      ...step,
      onClick: actions[step.id],
      ...STEP_STATIC_PROPS[step.id],
    })),
  }));

  const isStepCompleted = (stepId: OnboardingStepId) => {
    return sections.some((section) =>
      section.steps.some((step) => step.id === stepId && step.completed)
    );
  };

  return {
    refetchSteps: syncOnboardingSteps,
    stepSections,
    isStepCompleted,
  };
};

type SectionStaticProps = Pick<QSGStepSection, "icon" | "title" | "subtitle">;
const SECTION_STATIC_PROPS: Record<OnboardingSectionId, SectionStaticProps> = {
  [OnboardingSectionId.SetStrategy]: {
    icon: "directions",
    title: <FormattedMessage defaultMessage="Set the strategy" id="nCC4yL" />,
    subtitle: (
      <FormattedMessage
        defaultMessage="Ensure everyone understands the bigger picture and is pulling in the same direction."
        id="3G4uVX"
      />
    ),
  },
  [OnboardingSectionId.ExecuteStrategy]: {
    icon: "flag",
    title: (
      <FormattedMessage defaultMessage="Execute the strategy" id="AAbfdp" />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Set the goals that will help you realize the strategy."
        id="JzplqQ"
      />
    ),
  },
  [OnboardingSectionId.ReportProgress]: {
    icon: "person_pin",
    title: <FormattedMessage defaultMessage="Report on progress" id="qfiW+k" />,
    subtitle: (
      <FormattedMessage
        defaultMessage="Turn goals into actual results. Create an execution flow that boosts goal attainment."
        id="hma3c3"
      />
    ),
  },
};

type StepStaticProps = Pick<QSGStep, "title" | "subtitle">;
const STEP_STATIC_PROPS: Record<OnboardingStepId, StepStaticProps> = {
  [OnboardingStepId.AddUltimateGoal]: {
    title: (
      <FormattedMessage defaultMessage="Add your Ultimate Goal" id="kk9YM5" />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Set up your company's mission & vision."
        id="/ZvjRt"
      />
    ),
  },
  [OnboardingStepId.AddPillar]: {
    title: (
      <FormattedMessage
        defaultMessage="Set up your Strategic Pillars"
        id="l8zNc7"
      />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Communicate your company's strategy via Strategic Pillars."
        id="lMhZby"
      />
    ),
  },
  [OnboardingStepId.AddCompanyOkr]: {
    title: <FormattedMessage defaultMessage="Add a company OKR" id="IpOlAp" />,
    subtitle: (
      <FormattedMessage
        defaultMessage="Define the top priorities for your company."
        id="n3tvGT"
      />
    ),
  },
  [OnboardingStepId.AddTeamKpi]: {
    title: (
      <FormattedMessage defaultMessage="Add KPIs for your team" id="6qclcA" />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Monitor the performance of your team's business-as-usual."
        id="CVsFxl"
      />
    ),
  },
  [OnboardingStepId.AddTeamOkr]: {
    title: (
      <FormattedMessage
        defaultMessage="Add a quarterly OKR for your team"
        id="iHBl+4"
      />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Define the most important problems to solve this quarter."
        id="KuKBZx"
      />
    ),
  },
  [OnboardingStepId.AddTeamMember]: {
    title: <FormattedMessage defaultMessage="Invite your team" id="6g+RkT" />,
    subtitle: (
      <FormattedMessage
        defaultMessage="Distribute responsibility. Add your team members and assign goals to them."
        id="N0FsNF"
      />
    ),
  },
  [OnboardingStepId.AlignOkrs]: {
    title: (
      <FormattedMessage
        defaultMessage="Align your team's goals to the strategy"
        id="U9YYvz"
      />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Let everyone see how you contribute to the company strategy."
        id="oLgPlr"
      />
    ),
  },
  [OnboardingStepId.ConfigureCheckin]: {
    title: (
      <FormattedMessage
        defaultMessage="Configure Progress Reports "
        id="L+PrMQ"
      />
    ),
    subtitle: (
      <FormattedMessage
        defaultMessage="Ensure everyone regularly reports their wins, challenges, and progress."
        id="eidWz2"
      />
    ),
  },
  [OnboardingStepId.AddOneOnOne]: {
    title: <FormattedMessage defaultMessage="Set up 1:1s" id="g/UdHt" />,
    subtitle: (
      <FormattedMessage
        defaultMessage="Regularly meet with your direct reports or team members to stay aligned."
        id="2v6YgN"
      />
    ),
  },
};
