import { namedOperations, useUpsertGroupMutationMutation } from "@graphql";
import { FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "common/overlay/Modal/Modal";
import { ADD_GROUP } from "constants/tracking";
import { useOnboardingSteps } from "hooks/useOnboardingSteps/useOnboardingSteps";
import { useShortestCadence } from "hooks/useShortestCadence/useShortestCadence";
import { setFormikGqlErrors } from "utils/forms";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { GroupForm, GroupFormValues } from "../../GroupForm/GroupForm";

interface Props {
  onAddedGroup?: (group: { id: string }) => void;
  onRequestClosed: () => void;
  open: boolean;
}

export const AddGroupModal = ({
  open,
  onRequestClosed,
  onAddedGroup,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { refetchSteps } = useOnboardingSteps();
  const shortestCadence = useShortestCadence();
  const [addAnother, setAddAnother] = useState(false);
  const [addGroup] = useUpsertGroupMutationMutation();

  const initialValues = {
    cadence: shortestCadence?.id,
    kpisEnabled: true,
    members: [],
    mission: "",
    name: "",
    subGroups: [],
  };

  const onSubmit = (
    values: GroupFormValues,
    actions: FormikHelpers<GroupFormValues>
  ) => {
    addGroup({
      refetchQueries: [
        namedOperations.Query.getGroupList,
        namedOperations.Query.fetchGroups,
        namedOperations.Query.getCurrentUserInfo,
      ],
      variables: {
        input: {
          ...values,
        },
      },
    })
      .then((response) => {
        actions.setSubmitting(false);

        const errors = response.data?.upsertGroup?.errors;
        const { hasError } = handleErrors(response, errors);

        const group = response.data?.upsertGroup?.group;
        if (!group || hasError) {
          toast.failure(
            intl.formatMessage({
              defaultMessage: "Error creating group",
              id: "OZnxxh",
            })
          );
          actions.setSubmitting(false);

          if (!isEmpty(errors)) {
            setFormikGqlErrors(actions, errors);
          }

          return;
        }

        toast.success(
          intl.formatMessage({
            defaultMessage: "Group has been created!",
            id: "7IGKTg",
          })
        );
        const {
          mission,
          groupLeader,
          members,
          subGroups,
          kpisEnabled,
          slackChannel,
        } = group;
        track(ADD_GROUP, {
          kpiEnabled: kpisEnabled,
          lead_added: !!groupLeader,
          member_count: members.edges.length,
          mission_added: !!mission,
          slackChannelAdded: !!slackChannel,
          sub_group_count: subGroups.edges.length,
        });
        onAddedGroup?.(group);
        refetchSteps();
        if (addAnother) {
          actions.resetForm({ values: initialValues });
          setAddAnother(false);
        } else {
          onRequestClosed();
        }
      })
      .catch(() => {
        toast.failure(
          intl.formatMessage({
            defaultMessage: "Error creating group",
            id: "OZnxxh",
          })
        );
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage({
        defaultMessage: "Add team",
        id: "eabkPg",
      })}
    >
      <GroupForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        setAddAnother={setAddAnother}
      />
    </Modal>
  );
};
