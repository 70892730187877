import React from "react";
import { Spinner } from "common/placeholders/Spinner/Spinner";

export const FullPageSpinner = (): JSX.Element => (
  <div className="absolute inset-0" data-cy="loader">
    <div className="flex h-full flex-auto items-center justify-center">
      <Spinner />
    </div>
  </div>
);
