import Fuse from "fuse.js";
import React from "react";
import { Entity } from "common/avatar";
import { For } from "common/controlFlow";
import { useSelect } from "common/inputs/Select/useSelect";
import { TextSkeleton } from "common/placeholders/TextSkeleton/TextSkeleton";
import { useOwners } from "hooks/useOwners/useOwners";

type Value = string | null;

type Option = {
  avatar: string | null;
  id: string;
  name: string;
};

type Props = {
  "data-cy"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => void;
  value: Value;
};

export const InlineOwnerSingleSelect = ({
  "data-cy": dataCy,
  onChange,
  value,
  disabled,
}: Props) => {
  const { owners } = useOwners({ selected: value });
  const defaultItem = owners.find((owner) => owner.id === value);
  const { selected, handleSelect, handleSearch, Select, visibleOptions } =
    useSelect<Option>({
      defaultItem,
      options: owners,
      searchFn: (searchOptions, query) => {
        if (!query) return searchOptions;
        return new Fuse(searchOptions, {
          findAllMatches: true,
          isCaseSensitive: false,
          keys: ["name"],
          threshold: 0.2,
        })
          .search(query)
          .map((r) => r.item);
      },
    });

  const handleChange = (option: Option | null) => {
    if (!option) return;
    handleSelect(option);
    onChange?.(option.id);
  };

  return (
    <Select.Root
      disabled={disabled}
      data-cy={dataCy}
      onChange={handleChange}
      value={selected}
    >
      <Select.Trigger>
        <Select.InlineValue>
          {selected ? (
            <Entity avatar={selected.avatar} name={selected.name} />
          ) : (
            <TextSkeleton className="w-28" />
          )}
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20 divide-y">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <Entity avatar={option.avatar} name={option.name} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
