import { ProgressDriver } from "@graphql";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { useViewToggleContext } from "common/objective/context";
import { PermissionErrorTooltip } from "common/overlay/PermissionErrorTooltip/PermissionErrorTooltip";
import { MODAL_TYPES } from "constants/index";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { showModal } from "legacy/actions/actions";
import { ResultsFilterContext } from "../ResultsFilterContext/ResultsFilterContext";

interface ResultsEmptyStateProps {
  objectiveId: string;
  progressDriver: ProgressDriver;
}

export const ResultsEmptyState = ({
  objectiveId,
  progressDriver,
}: ResultsEmptyStateProps): JSX.Element => {
  const dispatch = useDispatch();
  const { page } = useViewToggleContext();
  const permissions = usePermissions();
  const filters = useContext(ResultsFilterContext);

  const openKeyResultModal = () => {
    dispatch(
      showModal(MODAL_TYPES.ADD_RESULT, {
        filters,
        objectiveId,
      })
    );
  };

  return (
    <Show
      when={page === "profile"}
      fallback={
        progressDriver === ProgressDriver.KeyResults ? (
          <div className="flex justify-between px-3 py-2 ml-6 mb-4 border rounded border-slate-300">
            <FormattedMessage
              defaultMessage="You don’t lead or contribute to any result in this Objective"
              id="IJ9Gp7"
            />
          </div>
        ) : null
      }
    >
      <div className="flex justify-between px-3 py-2 ml-6 mb-4 border rounded border-slate-300">
        <PermissionErrorTooltip show={!permissions.canUpdateOkrs}>
          <Button
            data-cy="addKeyResult"
            variant="negative"
            disabled={!permissions.canUpdateOkrs}
            onClick={openKeyResultModal}
          >
            <FormattedMessage defaultMessage="Add Result" id="WYY9ug" />
          </Button>
        </PermissionErrorTooltip>
      </div>
    </Show>
  );
};
