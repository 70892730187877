import { PerdooApiObjectiveStageChoices } from "@graphql";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { Button } from "common/buttons/Button/Button";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { FieldFocusWrapper } from "common/form/FieldFocusWrapper/FieldFocusWrapper";
import { FormControl } from "common/form/FormControl/FormControl";
import { FormKpiSelect } from "common/form/FormKpiSelect/FormKpiSelect";
import { messages as alignMessages } from "common/form/FormObjectiveAlignToSelect/FormObjectiveAlignToSelect.messages";
import { FormObjectiveParentSelect } from "common/form/FormObjectiveParentSelect/FormObjectiveParentSelect";
import { FormStrategicPillarSelect } from "common/form/FormStrategicPillarSelect/FormStrategicPillarSelect";
import { SharedGroupField } from "common/form/SharedGroupsField";
import { UnifiedHelper } from "common/form/UnifiedHelper/UnifiedHelper";
import { UserField } from "common/form/UserField";
import { getOwnershipInfo } from "common/goal/GoalOwners/GoalOwners.utils";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { PermissionErrorMessage } from "common/overlay/PermissionErrorTooltip/PermissionErrorMessage/PermissionErrorMessage";
import { TimeframeField } from "common/timeframe/TimeframeField/TimeframeField";
import { allRolesButViewonly, roles } from "constants/roles";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { getRandomMessage } from "utils/getRandomMessage";
import { isSet } from "utils/isSet";
import { isRoleSuperior } from "utils/permissions";
import { twClass } from "utils/twClass";
import { noAlignment, objectiveAlignToFields } from "../constants";
import { ObjectiveAlignment } from "../ObjectiveAlignment/ObjectiveAlignment";
import { useObjectiveForm } from "../utils/useObjectiveForm/useObjectiveForm";
import { ObjectiveFormContextProvider } from "../utils/useObjectiveFormContext/useObjectiveFormContext";
import {
  checkIfTimeframeIsSetToFuture,
  getAlignedToField,
  styles,
} from "../utils/utils";
import { AdvancedSection } from "./AdvancedSection/AdvancedSection";
import { helpers } from "./ObjectiveForm.helpers";
import { messages, namePlaceholders } from "./ObjectiveForm.messages";
import { ObjectiveFormValues } from "./ObjectiveForm.types";
import { ObjectiveFormDynamicHelperTexts } from "./ObjectiveFormDynamicHelperTexts/ObjectiveFormDynamicHelperTexts";
import { ObjectiveGoalSelector } from "./ObjectiveGoalSelector/ObjectiveGoalSelector";
import { ObjectivePrivacy } from "./ObjectivePrivacy/ObjectivePrivacy";
import { ObjectiveStages } from "./ObjectiveStages/ObjectiveStages";

const schema = Yup.object().shape({
  description: Yup.string().nullable(),
  groups: Yup.lazy((val) =>
    (Array.isArray(val)
      ? Yup.array().of(Yup.string()).min(1, "Required")
      : Yup.string()
    )
      .required("Required")
      .typeError("Select Owner")
  ),
  lead: Yup.string().required("Required"),
  name: Yup.string().required("Enter a name for your Objective"),
  timeframe: Yup.string().required("Required"),
});

type Props = {
  groupName?: string;
  initialValues: ObjectiveFormValues;
  isEdit?: boolean;
  onCancel?: () => void;
  onSubmit: (
    values: ObjectiveFormValues & { isCompanyGoal: boolean },
    actions: FormikHelpers<ObjectiveFormValues>,
    selectedSampleObjective?: { name: ReactNode }
  ) => void;
};

export const ObjectiveForm = ({
  groupName,
  initialValues,
  isEdit = false,
  onCancel,
  onSubmit,
}: Props): JSX.Element => {
  const intl = useIntl();
  const company = useCompany();
  const { show: privateGoalsAvailable } = useFeatureAccess(
    Feature.privateGoals
  );
  const currentUser = useCurrentUser();
  const isAdmin = isRoleSuperior(currentUser, roles.standard);
  const formContext = useObjectiveForm({
    alignedField: getAlignedToField(initialValues, isEdit),
  });

  const { setFocusedField, alignedToRadio, setTimeframe } = formContext;

  const placeholders = useMemo(
    () => ({ name: getRandomMessage(namePlaceholders) }),
    []
  );

  const handleSubmit = (
    values: ObjectiveFormValues,
    actions: FormikHelpers<ObjectiveFormValues>
  ) => {
    const { groups, isCompanyGoal } = getOwnershipInfo(
      values.groups,
      company.id
    );
    onSubmit(
      {
        ...values,
        groups,
        isCompanyGoal,
        private: values.private,
        stage: values.stage,
      },
      actions
    );
  };

  return (
    <ObjectiveFormContextProvider value={formContext}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={schema}
      >
        {({ isSubmitting, values, submitForm, setFieldValue }) => {
          return (
            <Form className="mt-2">
              <UnifiedHelper helper={<ObjectiveFormDynamicHelperTexts />}>
                <FieldFocusWrapper
                  name="name"
                  setFocusedField={formContext.setFocusedField}
                >
                  <FormControl
                    label={intl.formatMessage(messages.objNameLabel)}
                    id="name"
                  >
                    <div className="flex items-center space-x-2">
                      <InputField
                        autoFocus
                        data-cy="objectiveName"
                        data-testid="objectiveName"
                        id="name"
                        className="grow"
                        formControl={false}
                        name="name"
                        placeholder={intl.formatMessage(placeholders.name)}
                      />
                      <ObjectiveGoalSelector
                        groupName={groupName}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </FormControl>
                </FieldFocusWrapper>

                <ObjectiveAlignment>
                  {({
                    validateObjectiveAlignmentSelection,
                    pillarsEnabled,
                  }) => (
                    <div
                      className={twClass({
                        "flex-1":
                          alignedToRadio !== noAlignment &&
                          isSet(alignedToRadio),
                      })}
                    >
                      <Show
                        when={alignedToRadio === objectiveAlignToFields.kpi}
                      >
                        <FieldFocusWrapper
                          name="kpi"
                          setFocusedField={setFocusedField}
                        >
                          <Field
                            name="kpi"
                            validate={
                              alignedToRadio === objectiveAlignToFields.kpi &&
                              validateObjectiveAlignmentSelection("kpi")
                            }
                          >
                            {({ form, field }: FieldProps) => (
                              <div className="ml-5 min-w-0 flex-1">
                                <FormKpiSelect
                                  data-cy="kpi"
                                  data-testid="kpi"
                                  disabled={values.private}
                                  field={field}
                                  form={form}
                                  infoElement={helpers.kpiHelper}
                                  isGql
                                  styles={styles}
                                />
                              </div>
                            )}
                          </Field>
                        </FieldFocusWrapper>
                      </Show>
                      <Show
                        when={alignedToRadio === objectiveAlignToFields.parent}
                      >
                        <FieldFocusWrapper
                          name="parent"
                          setFocusedField={setFocusedField}
                        >
                          <Field
                            name="parent"
                            validate={
                              alignedToRadio ===
                                objectiveAlignToFields.parent &&
                              validateObjectiveAlignmentSelection("parent")
                            }
                          >
                            {({ field, form }: FieldProps) => (
                              <div className="ml-5 min-w-0 flex-1">
                                <FormObjectiveParentSelect
                                  data-cy="parent"
                                  data-testid="parent"
                                  field={field}
                                  form={form}
                                  objectiveId={values.id}
                                  placeholder={intl.formatMessage(
                                    alignMessages.objAlignmentPlaceholder
                                  )}
                                  styles={styles}
                                />
                              </div>
                            )}
                          </Field>
                        </FieldFocusWrapper>
                      </Show>
                      <Show
                        when={
                          pillarsEnabled &&
                          alignedToRadio ===
                            objectiveAlignToFields.strategicPillar
                        }
                      >
                        <FieldFocusWrapper
                          name="strategicPillar"
                          setFocusedField={setFocusedField}
                        >
                          <Field
                            name="strategicPillar"
                            validate={
                              pillarsEnabled &&
                              alignedToRadio ===
                                objectiveAlignToFields.strategicPillar &&
                              validateObjectiveAlignmentSelection(
                                "strategicPillar"
                              )
                            }
                          >
                            {({ field, form }: FieldProps) => (
                              <div className="ml-5 flex-1">
                                <FormStrategicPillarSelect
                                  data-cy="strategicPillar"
                                  data-testid="strategicPillar"
                                  field={field}
                                  form={form}
                                  isGql
                                  placeholder={intl.formatMessage(
                                    alignMessages.pillarAlignmentPlaceholder
                                  )}
                                  styles={styles}
                                />
                              </div>
                            )}
                          </Field>
                        </FieldFocusWrapper>
                      </Show>
                    </div>
                  )}
                </ObjectiveAlignment>

                <FieldFocusWrapper
                  name="groups"
                  setFocusedField={setFocusedField}
                >
                  <SharedGroupField
                    helperText={
                      !isAdmin && <PermissionErrorMessage variant="addGroup" />
                    }
                    isShared={
                      !isEmpty(initialValues.groups) &&
                      initialValues.groups.length > 1
                    }
                    label={intl.formatMessage(messages.objOwnerLabel)}
                    name="groups"
                  />
                </FieldFocusWrapper>

                <div className="flex space-x-4">
                  <div className="md:min-w-0 md:flex-1">
                    <FieldFocusWrapper
                      name="lead"
                      setFocusedField={setFocusedField}
                    >
                      <UserField
                        variables={{
                          roleIn: allRolesButViewonly.join(),
                        }}
                        data-cy="fieldLead"
                        data-testid="fieldLead"
                        label={intl.formatMessage(messages.leadLabel)}
                        name="lead"
                      />
                    </FieldFocusWrapper>
                  </div>
                  <div className="md:min-w-0 md:flex-1">
                    <FieldFocusWrapper
                      name="timeframe"
                      setFocusedField={setFocusedField}
                    >
                      <TimeframeField
                        data-cy="fieldTimeframe"
                        data-testid="fieldTimeframe"
                        label={intl.formatMessage(messages.timeframeLabel)}
                        name="timeframe"
                        onChange={(tf) => {
                          setTimeframe(tf);
                          setFieldValue(
                            "goalUpdateCycle",
                            tf?.cadence?.goalUpdateCycle
                          );
                          setFieldValue(
                            "stage",
                            checkIfTimeframeIsSetToFuture(tf)
                              ? PerdooApiObjectiveStageChoices.Draft
                              : PerdooApiObjectiveStageChoices.Active
                          );
                        }}
                        wrapperClassName="my-2"
                      />
                    </FieldFocusWrapper>
                  </div>
                </div>
                <AdvancedSection />
              </UnifiedHelper>

              <ModalFooter>
                <div className="w-full justify-between md:flex">
                  <div className="mx-3 mb-4 flex items-center justify-between md:m-0">
                    <ObjectiveStages />
                    <Show when={privateGoalsAvailable || initialValues.private}>
                      <ObjectivePrivacy />
                    </Show>
                  </div>
                  <div className="gap-3 md:flex">
                    <div className="mb-3 mr-2.5 flex md:m-0">
                      <Button
                        className="w-full"
                        data-cy="cancelButton"
                        data-testid="cancelButton"
                        disabled={isSubmitting}
                        onClick={onCancel}
                        type="button"
                        variant="ghost"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Cancel",
                          id: "global:cancel",
                        })}
                      </Button>
                    </div>
                    <div className="mb-3 mr-2.5 flex md:m-0">
                      <Button
                        className="w-full"
                        data-cy="addObjectiveButton"
                        data-testid="saveObjective"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        onClick={submitForm}
                        type="button"
                        variant={"normal"}
                      >
                        {intl.formatMessage({
                          defaultMessage: "Save",
                          id: "global:save",
                        })}
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </ObjectiveFormContextProvider>
  );
};
