import { partial } from "lodash";
import { FormattedMessage } from "react-intl";
import { Banner } from "common/alerts/Banner/Banner";
import { Button } from "common/buttons";
import { useKpiContext } from "common/context/kpiContext";
import { usePOTFrequency } from "common/goal/hooks/usePOTFrequency";
import { GoalActivity } from "common/goalModal/GoalActivity/GoalActivity";
import { GoalActivityHeader } from "common/goalModal/GoalActivity/GoalActivityHeader/GoalActivityHeader";
import { GoalDisclosure } from "common/goalModal/GoalDisclosure/GoalDisclosure";
import { useArchiveKpi } from "common/kpi/hooks/useArchiveKpi";
import { KpiProgressGraphContainer } from "common/kpi/KpiProgressGraphContainer/KpiProgressGraphContainer";
import { TargetList } from "common/kpi/Targets/TargetList/TargetList";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { YearlyNavigation } from "common/navigation/YearlyNavigation/YearlyNavigation";
import { FrequencyDropdown } from "common/overlay/FrequencyDropdown/FrequencyDropdown";
import { objectTypes } from "constants/objects";
import { useKpiNavigation } from "hooks/useKpiStartDate/useKpiStartDate";
import { AlignedObjectives } from "./KpiAlignedObjectives/AlignedObjectives";
import { KpiDescription } from "./KpiDescription/KpiDescription";

export const KpiSummary = () => {
  const kpi = useKpiContext();
  const [freq, setFreq] = usePOTFrequency(kpi.goalUpdateCycle);
  const { unarchiveKpi } = useArchiveKpi();

  const kpiNavigation = useKpiNavigation();

  return (
    <div className="flex flex-col bg-white">
      {kpi.archivedDate && (
        <div className="pt-5 px-5">
          <Banner>
            <div className="flex items-center justify-between">
              <div>
                <FormattedMessage
                  defaultMessage="This KPI was archived on {archivedDate}"
                  id="g13yDA"
                  values={{
                    archivedDate: <FormattedDate value={kpi.archivedDate} />,
                  }}
                />
              </div>
              <div>
                <Button onClick={partial(unarchiveKpi, kpi.id)}>
                  <FormattedMessage defaultMessage="Unarchive" id="ftDYZo" />
                </Button>
              </div>
            </div>
          </Banner>
        </div>
      )}
      <GoalDisclosure
        data-testid="kpiDescription"
        title={
          <FormattedMessage
            defaultMessage="Description"
            id="kpi:forms:description:label"
          />
        }
      >
        <KpiDescription />
      </GoalDisclosure>
      <GoalDisclosure
        data-testid="kpiPot"
        title={
          <div className="flex justify-between w-full">
            <div>
              <FormattedMessage
                defaultMessage="Progress over time"
                id="kpi:modal:progressOverTime"
              />
            </div>
            <FrequencyDropdown freq={freq} setFreq={setFreq} />
          </div>
        }
      >
        <div className="mt-5 -ml-5">
          <KpiProgressGraphContainer freq={freq} kpiId={kpi.id} />
        </div>
      </GoalDisclosure>

      <GoalDisclosure
        data-testid="kpiTargets"
        title={
          <div className="flex justify-between w-full">
            <div>
              <FormattedMessage defaultMessage="Targets" id="cs8gIq" />
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <YearlyNavigation size="xl" kpiNavigation={kpiNavigation} />
            </div>
          </div>
        }
      >
        <TargetList
          className="w-full my-5"
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'TargetsKpiFragment' is not assignable to typ... Remove this comment to see the full error message
          kpi={kpi}
          source="kpi-modal"
          startDate={kpiNavigation.startDate}
          useBorders
          withLabels
        />
      </GoalDisclosure>

      <AlignedObjectives />
      <GoalDisclosure data-testid="kpiActivity" title={<GoalActivityHeader />}>
        <GoalActivity entityId={kpi.id} entityType={objectTypes.kpi} />
      </GoalDisclosure>
    </div>
  );
};
