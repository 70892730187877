import { useIntl } from "react-intl";
import { AllMetrics, MetricUnits, MetricValue } from "constants/metric";

export const getIntegralDigits = (value: number): number =>
  Math.trunc(Math.abs(value)).toString().length;

export const useFormatMetric = () => {
  const intl = useIntl();

  const formatMetric = (value: number, unit?: MetricValue) => {
    let style: "decimal" | "percent" | "currency" = "decimal";
    let currency: string | undefined = undefined;
    let val = value;
    let maximumFractionDigits: number | undefined = undefined;

    const absValue = Math.abs(value);

    const nonCurrenciesMetricValues: MetricValue[] = [
      MetricUnits.PERCENTAGE,
      MetricUnits.NUMERICAL,
    ];
    if (unit && !nonCurrenciesMetricValues.includes(unit)) {
      style = "currency";
      currency = AllMetrics[unit].abbreviation;
    }

    if (absValue >= 1000) {
      // Uses compact notation
      maximumFractionDigits = 3;
    } else {
      const integralDigits = getIntegralDigits(absValue);
      maximumFractionDigits = Math.max(0, 6 - integralDigits);
    }

    if (unit === MetricUnits.PERCENTAGE) {
      style = "percent";
      val = value / 100;
      // limit the decimal place to 2 for percentage
      maximumFractionDigits = 2;
    }

    let formattedVal = intl.formatNumber(val, {
      currency,
      maximumFractionDigits,
      minimumFractionDigits: 0,
      notation: "compact",
      style,
      useGrouping: true,
    });

    // Remove weird space before unit that is added in german
    formattedVal = formattedVal.replace(/\s/g, "");
    return formattedVal;
  };

  return { formatMetric };
};
