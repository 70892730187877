import { useEffect, useState } from "react";

type Hook = {
  hashUrl: string;
  topLevelModule: string;
};

/**
 * returns the hash portion of the url & extracts the top-level module.
 * used for dealing with hash-based routing in react-router.
 * remove once fully migrated to next.js router.
 */
export const useHashUrl = (): Hook => {
  const [hashUrl, setHashUrl] = useState("");

  useEffect(() => {
    const onHashChanged = () => {
      // remove trailing hash: "#/foo/bar" → "/foo/bar"
      let newHashUrl = window.location.hash.slice(1);
      // make sure it's a URL
      if (newHashUrl[0] !== "/") newHashUrl = "";
      // remove trailing slash "/foo/bar" → "foo/bar"
      newHashUrl = newHashUrl.slice(1);

      setHashUrl(newHashUrl);
    };

    // trigger check on first page render
    onHashChanged();

    window.addEventListener("hashchange", onHashChanged);

    return () => {
      window.removeEventListener("hashchange", onHashChanged);
    };
  }, []);

  const topLevelModule = hashUrl.split("/")[0];

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  return { hashUrl, topLevelModule };
};
