import { gql } from "@apollo/client";
import {
  GetObjectiveTimelineDocument,
  ResultDefaultFragmentDoc,
} from "@graphql";
import { apolloClient } from "apolloClient";
import { OBJECTIVE_FRAGMENT } from "./queries";

export const FETCH_OKRS_BY_OWNER = gql`
  query fetchObjectives(
    $owner: String!
    $timeframe: UUID
    $stage: String!
    $after: String
  ) {
    objectives(
      owner: $owner
      stage: $stage
      timeframe: $timeframe
      first: 10
      after: $after
    ) {
      edges {
        node {
          ...ObjectiveFragment
          closingNote {
            ...ClosingNoteDefault
            ...ClosingNoteRating
            ...ClosingNoteStatus
          }
          keyResults {
            edges {
              node {
                ...ResultDefault
              }
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
  ${ResultDefaultFragmentDoc}
  ${OBJECTIVE_FRAGMENT}
`;

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
export const fetchObjectiveTimeline = (id, { filter } = {}) =>
  apolloClient.query({
    fetchPolicy: "network-only",
    query: GetObjectiveTimelineDocument,
    variables: {
      eventType: filter,
      id,
    },
  });
