type ArrayIterator<T, TResult> = (
  value: T,
  index: number,
  collection: T[]
) => TResult;

/**
 * like `Array.map`, but you can pass a fallback to return if the list is empty.
 */
export const mapOrElse = <Item, Result, FallbackType>(
  items: Item[] | null | undefined,
  mapper: ArrayIterator<Item, Result>,
  fallback: FallbackType
): Result[] | FallbackType => {
  if (!items || !items.length) return fallback;

  return items.map(mapper);
};
