import { GoalUpdateCycle } from "@graphql";
import React from "react";
import { For } from "common/controlFlow";
import { useSelect } from "common/inputs/Select/useSelect";
import {
  GoalUpdateFrequencyOption,
  useGoalUpdateFrequencyOptions,
} from "hooks/useGoalUpdateFrequencyOptions/useGoalUpdateFrequencyOptions";

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (value: GoalUpdateCycle | null) => Promise<boolean>;
  value: GoalUpdateCycle;
};

export const InlineGoalUpdateFrequencySelect = ({
  "data-cy": dataCy,
  "data-testid": dataTestId,
  className,
  value,
  onChange,
  disabled,
}: Props) => {
  const options = useGoalUpdateFrequencyOptions();
  const defaultItem = options.find((option) => option.value === value);
  const { Select, selected, visibleOptions, handleSelect } =
    useSelect<GoalUpdateFrequencyOption>({
      defaultItem,
      options,
    });

  const handleChange = async (option: GoalUpdateFrequencyOption | null) => {
    const successful = await onChange?.(option?.id ?? null);
    if (!successful) return;
    handleSelect(option);
  };

  return (
    <Select.Root
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          <div>{selected?.label}</div>
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown>
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <div>{option.label}</div>
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
