import { isNil } from "lodash";
import { RefObject, useState } from "react";

/**
 * left = -1, right = 1
 */
export enum ScrollDirection {
  LEFT = -1,
  RIGHT = 1,
}

/**
 * allows to scroll an element and returns the current scroll state (whether further scroll is possible).
 * @param elementRef
 */
export const useScrollElement = (
  elementRef: RefObject<HTMLElement>
): [
  (direction: ScrollDirection, offset?: number) => void,
  boolean,
  boolean,
] => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const scroll = (
    direction: ScrollDirection = ScrollDirection.RIGHT,
    offsetProp?: number
  ): void => {
    const element = elementRef.current;
    if (isNil(element)) {
      return;
    }

    // scroll by full width by default
    const offset = offsetProp ?? element.offsetWidth;

    const scrollBy = direction * offset;
    const scrollLeftMax = element.scrollWidth - element.clientWidth;

    setCanScrollLeft(element.scrollLeft + scrollBy > 0);
    setCanScrollRight(element.scrollLeft + scrollBy < scrollLeftMax);

    element.scrollBy({
      behavior: "smooth",
      left: scrollBy,
    });
  };

  return [scroll, canScrollLeft, canScrollRight];
};
