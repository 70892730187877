import { ReactNode } from "react";
import { createNonNullableCtx } from "common/context/utils";
import { ReportLayout } from "common/reports/utils/useReportLayout";

export type ReportBag = {
  drillDownPageSize: number;
  handleClose: () => void;
  layout: ReportLayout;
  modal: ReactNode;
  modalFooter: ReactNode;
  modalIsPaginating: false | "backward" | "forward";
  modalTitle: string;
  setModal: (modal: ReactNode) => void;
  setModalFooter: (footer: ReactNode) => void;
  setModalIsPaginating: (status: false | "backward" | "forward") => void;
  setModalTitle: (title: string) => void;
};

export const [useReport, ReportContextProvider] =
  createNonNullableCtx<ReportBag>();
