import nativeEmojiData, { EmojiMartData } from "@emoji-mart/data";
import { GetSlackEmojisDocument, GetSlackEmojisQueryData } from "@graphql";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionProps } from "@tiptap/suggestion";
import { apolloClient } from "apolloClient";
import { PluginKey } from "prosemirror-state";
import tippy from "tippy.js";
import { EmojiList } from "./EmojiList";

export type SlackEmoji = {
  content?: string;
  id: string;
  name: string;
  src?: string;
};

export const suggestion = {
  char: ":",
  items: async ({ query }: { query: string }) => {
    try {
      const { data } = await apolloClient.query<
        GetSlackEmojisQueryData | undefined
      >({
        fetchPolicy: "network-only",
        query: GetSlackEmojisDocument,
      });

      const emojis: SlackEmoji[] = [];

      if (data?.currentCompany.slackEmojis) {
        // data is stored in json in this format: { "emojiName": "path/to/emoji.gif" }
        const companySlackEmojis = Object.entries(
          JSON.parse(data.currentCompany.slackEmojis)
        ) as [string, string][];

        for (const [key, value] of companySlackEmojis) {
          emojis.push({ id: value, name: key, src: value });
        }
      }

      const nativeEmojis = (nativeEmojiData as EmojiMartData).emojis;

      for (const [id, emoji] of Object.entries(nativeEmojis)) {
        emojis.push({
          content: emoji.skins[0]?.native ?? "",
          id: emoji.skins[0]?.native ?? id,
          name: emoji.id,
        });
      }

      return emojis
        .filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        )
        .slice(0, 5);
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  pluginKey: new PluginKey("colonKey"),

  render: () => {
    let component: any;
    let popup: any;

    return {
      onExit() {
        popup?.[0].destroy();
        // TODO: destroying a component is causing an error with null docView, investigate
        // https://discuss.prosemirror.net/t/what-could-create-a-null-docview/1830
        // component.destroy();
      },

      onKeyDown(props: any) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component?.ref?.onKeyDown(props);
      },

      onStart: (props: SuggestionProps<SlackEmoji>) => {
        component = new ReactRenderer(EmojiList, {
          editor: props.editor,
          props,
        });

        if (!props.clientRect) {
          return;
        }
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        popup = tippy("body", {
          appendTo: () => document.body,
          content: component.element,
          getReferenceClientRect: props.clientRect,
          interactive: true,
          placement: "bottom-start",
          showOnCreate: true,
          theme: "perdoo-light",
          trigger: "manual",
        });
      },

      onUpdate(props: SuggestionProps<SlackEmoji>) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },
    };
  },
};
