import { Listbox } from "@headlessui/react";
import { isFunction } from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { SelectContextProvider } from "../Select.context";
import { SelectBaseItem } from "../Select.types";

type ChildrenProps = {
  isOpen: boolean;
};

export type Props<Item extends SelectBaseItem> = {
  autoFocus?: boolean;
  children: ReactNode | ((props: ChildrenProps) => ReactNode);
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (item: Item | null) => void;
  value: Item | null;
};

/**
 * Our styled generic equivalent of `<select />` regularly used in forms.
 *
 * @note If you need a select component that supports selecting 0-N options use `MultiSelect`.
 * @note If you need a dropdown for a menu use `Dropdown` (e.g. kebab menu).
 */
export function SelectRoot<Item extends SelectBaseItem>({
  autoFocus,
  children,
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  onChange,
  value,
  disabled,
}: Props<Item>): JSX.Element {
  // we need a ref to search so that we can focus it on tab. this is a workaround for https://github.com/tailwindlabs/headlessui/issues/2475
  const [searchRef, setSearchRef] = useState<HTMLInputElement | null>(null);
  // dropdown is rendered in a portal. we need a ref to value so that we can align them.
  const [valueRef, setValueRef] = useState<HTMLDivElement | null>(null);
  const hasValue = isSet(value);
  const clearValue = () => onChange?.(null);

  useEffect(() => {
    if (autoFocus) {
      valueRef?.focus();
    }
  }, [autoFocus, valueRef]);

  return (
    <Listbox
      as="div"
      by={(a, b) => isSet(a?.id) && a.id === b?.id}
      className={twClass(className, "relative")}
      data-cy={dataCy}
      data-testid={dataTestId}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      {({ open }) => (
        <SelectContextProvider
          value={{
            clearValue,
            hasValue,
            isOpen: open,
            searchRef,
            setSearchRef,
            setValueRef,
            valueRef,
          }}
        >
          {isFunction(children) ? children({ isOpen: open }) : children}
        </SelectContextProvider>
      )}
    </Listbox>
  );
}
