import React from "react";
import { Icon } from "common/icons/Icon/Icon";
import { IconNameKey, IconType } from "common/icons/Icon/Icon.types";
import { twMerge } from "utils/twMerge";
import { CategorySelectGroup, CategorySelectItem } from "../CategorySelect";

const defaultIcon: { name: IconNameKey; type?: IconType } = {
  name: "star",
};

type Props = {
  className?: string;
  icon?: {
    name: IconNameKey;
    type?: IconType;
  };
  onSelect?: (item: CategorySelectItem) => void;
  selectedCategories: CategorySelectGroup[];
  showIcon?: boolean;
  style?: Record<string, string>;
};

export const CategorySelectItems = ({
  className,
  icon = defaultIcon,
  onSelect,
  selectedCategories,
  showIcon = true,
  style,
}: Props): JSX.Element => (
  <div
    className={twMerge("overflow-y-auto py-3", className)}
    id="category-select-items"
    style={style}
  >
    {selectedCategories.map((category, index) => (
      <React.Fragment key={category.name ?? index}>
        {category.name && (
          <span className="px-4 text-xs text-slate-500">{category.name}</span>
        )}
        <ul>
          {category.items.map((item) => (
            <li key={item.id}>
              <button
                data-cy="XyXNJuUz2RBxl4kuRNrOQ"
                className={twMerge(
                  "flex w-full items-center px-4 py-2.5",
                  "text-left text-sm hover:bg-blue-100",
                  item.className
                )}
                onClick={() => onSelect?.(item)}
                type="button"
              >
                {showIcon && (
                  <Icon
                    className="mr-3 rounded-full px-1 bg-slate-200"
                    name={icon.name}
                    type={icon.type}
                  />
                )}
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </React.Fragment>
    ))}
  </div>
);
