import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cadence: {
    defaultMessage: "Cadences",
    id: "cadence:new:cadence:info:cadences",
  },
  dpas: {
    defaultMessage: "Data Processing Agreement",
    id: "settings:company:tabs:dpas",
  },
  general: {
    defaultMessage: "General",
    id: "settings:company:tabs:general",
  },
  integrations: {
    defaultMessage: "Integrations",
    id: "settings:company:tabs:integrations",
  },
  teams: {
    defaultMessage: "Teams",
    id: "DNeuzb",
  },
  timeframes: {
    defaultMessage: "Timeframes",
    id: "settings:company:tabs:timeframes",
  },
  users: {
    defaultMessage: "Users",
    id: "settings:company:tabs:users",
  },
});
