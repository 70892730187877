import {
  PerformanceReviewDefaultFragment,
  PerformanceReviewQuestionAndAnswersDefaultFragment,
  PerformanceReviewUsersFragment,
} from "@graphql";
import { createNonNullableCtx } from "common/context/utils";

export type PerformanceReviewCompleteType = PerformanceReviewDefaultFragment &
  PerformanceReviewUsersFragment & {
    questionAndAnswers: NodeConnection<PerformanceReviewQuestionAndAnswersDefaultFragment>;
  };

type PerformanceReviewQueryType = NonNullable<PerformanceReviewCompleteType>;

export const [
  usePerformanceReviewCompleteContext,
  PerformanceReviewCompleteProvider,
] = createNonNullableCtx<PerformanceReviewQueryType>();
