import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  "data-testid"?: string;
  helper?: ReactNode;
};

export const WithHelper = ({
  "data-testid": dataTestId,
  helper,
  children,
}: Props): JSX.Element => {
  return (
    <div className="group flex" data-testid={dataTestId}>
      <div className="grow min-w-0 border-r pr-4 border-slate-300 md:col-span-19">
        {children}
      </div>
      <div className="invisible relative ml-3 mt-4 shrink-0 grow-0 basis-72 group-focus-within:visible group-hover:visible">
        <div className="absolute bg-white">{helper}</div>
      </div>
    </div>
  );
};
