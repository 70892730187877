import { useSubObjectivesQuery } from "@graphql";
import { isEmpty, isNil } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { useKpiContext } from "common/context/kpiContext";
import { Show } from "common/controlFlow";
import { GoalDisclosure } from "common/goalModal/GoalDisclosure/GoalDisclosure";
import { Widget } from "common/misc/Widget/Widget";
import { AnchorNext } from "common/navigation";
import { ViewToggleProvider } from "common/objective/context";
import { ObjectiveWrapper } from "common/objective/ObjectiveWrapper/ObjectiveWrapper";
import { getSortingQueryParam } from "common/objective/utils/sortHelper";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { useObjectiveStageFilters } from "hooks/useObjectiveStageFilters/useObjectiveStageFilters";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { useSelectedTimeframe } from "hooks/useSelectedTimeframe/useSelectedTimeframe";
import { FilterList } from "legacy/components/filters/FilterList/FilterList";
import { FiltersContextProvider } from "legacy/components/filters/filtersContext";
import { useFilters__deprecated } from "legacy/utils/useFilters/useFilters";
import { useFilterTypes__deprecated } from "legacy/utils/useFilterTypes/useFilterTypes";
import { mapEdges } from "utils/mapEdges";
import { mapOrElse } from "utils/mapOrElse/mapOrElse";
import { apolloQueryMerger, showServerErrorToast } from "utils/utils";
import { AlignedObjectivesHeader } from "./AlignedObjectivesHeader/AlignedObjectivesHeader";

export const AlignedObjectives = (): JSX.Element => {
  const intl = useIntl();

  const { query } = useRouter();

  const [privateMode] = usePrivateMode();
  const kpi = useKpiContext();
  const initialTimeframe = query.timeframe ? (query.timeframe as string) : null;
  const childrenCount = kpi.objectives?.edgeCount ?? 0;

  const { setStageFilter, sorting, touched } = useObjectiveStageFilters();

  const filters = useFilterTypes__deprecated([
    "owner",
    "progress",
    "tags",
    "objectiveStage",
  ]);
  const [filterState, filtersDispatch, , filterAsGqlParams] =
    useFilters__deprecated();

  const { selectedTfId, selectTfId, timeframe } =
    useSelectedTimeframe(initialTimeframe);

  const { loading, data, fetchMore } = useSubObjectivesQuery({
    onError: (err) => showServerErrorToast(err),
    variables: {
      alignedTo: kpi.id,
      first: 10,
      orderBy: getSortingQueryParam(sorting),
      timeframe: selectedTfId,
      ...filterAsGqlParams,
    },
  });

  useEffect(() => {
    if (!loading && !data?.objectives.edges.length && !touched) {
      setStageFilter("");
    }
  }, [data, touched, loading, setStageFilter]);

  const onLoadMore = () =>
    fetchMore({
      updateQuery: apolloQueryMerger,
      variables: {
        after: data?.objectives.pageInfo.endCursor,
      },
    });

  let objectives = mapEdges(data?.objectives.edges);
  if (privateMode) {
    objectives = objectives.filter((objective) => !objective.private);
  }

  return (
    <ViewToggleProvider>
      <FiltersContextProvider
        value={{
          filters,
          filtersDispatch,
          filtersLoading: false,
          filterState,
        }}
      >
        <GoalDisclosure
          defaultOpen={childrenCount > 0}
          data-testid="kpiAlignedObjectives"
          title={
            <AlignedObjectivesHeader
              selectTfId={selectTfId}
              timeframe={timeframe}
            />
          }
        >
          <div className="my-5">
            <Show
              when={kpi.private}
              fallback={
                <Show
                  when={childrenCount > 0}
                  fallback={
                    <div className="flex text-slate-500">
                      <FormattedMessage
                        defaultMessage="No aligned objectives"
                        id="teBmKH"
                      />
                    </div>
                  }
                >
                  <div className="flex flex-col">
                    <Show when={!isEmpty(filterState.filters)}>
                      <div className="pb-5">
                        <FilterList activeFilter={null} />
                      </div>
                    </Show>
                  </div>
                  <div className="flex flex-col space-y-4">
                    {mapOrElse(
                      objectives,
                      (objective) => (
                        <Widget key={objective.id} noPadding>
                          <ObjectiveWrapper canUnalign objective={objective} />
                        </Widget>
                      ),
                      isNil(data) ? (
                        <SpinnerFit />
                      ) : (
                        <FormattedMessage
                          defaultMessage="No aligned objectives"
                          id="teBmKH"
                        />
                      )
                    )}
                  </div>
                  <div className="my-5">
                    <div>
                      {data?.objectives.pageInfo.hasNextPage && (
                        <Button
                          data-cy="MmxdURrOL-QO1Q76eZgF8"
                          loading={loading}
                          onClick={onLoadMore}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Load more...",
                            id: "global:loadMore",
                          })}
                        </Button>
                      )}
                    </div>
                  </div>
                </Show>
              }
            >
              <Show when={kpi.private}>
                <div className="flex flex-col items-center justify-center text-slate-500">
                  <div>
                    <FormattedMessage
                      defaultMessage="This KPI is private. To keep it private, alignment is disabled."
                      id="6Q6I8m"
                    />
                  </div>
                  <FormattedMessage
                    defaultMessage="Edit the KPI to make it public and align it. {link}"
                    id="yYvsaa"
                    values={{
                      link: (
                        <AnchorNext
                          data-cy="learnMore"
                          href="http://support.perdoo.com/en/articles/5040665-make-an-okr-private"
                        >
                          {intl.formatMessage({
                            defaultMessage: "Learn more",
                            id: "TdTXXf",
                          })}
                        </AnchorNext>
                      ),
                    }}
                  />
                </div>
              </Show>
            </Show>
          </div>
        </GoalDisclosure>
      </FiltersContextProvider>
    </ViewToggleProvider>
  );
};
