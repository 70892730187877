import { GetActiveUsersQueryVariables, useUserDetailsQuery } from "@graphql";
import { isNil } from "lodash";
import {
  useActiveUsers,
  UseActiveUsersHook} from "hooks/useActiveUsers/useActiveUsers";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { usePermissions } from "hooks/usePermissions/usePermissions";

type UserSelectOptions = {
  defaultUserId?: string | null;
  options?: GetActiveUsersQueryVariables;
};

export const useUserSelect = ({
  options,
  defaultUserId,
}: UserSelectOptions): UseActiveUsersHook => {
  const me = useCurrentUser();
  const { canCreateGoalsInAnyGroup } = usePermissions();
  const permissionVariables = canCreateGoalsInAnyGroup
    ? undefined
    : { groups_Members_Id: me?.id };

  const users =
    useActiveUsers({
      variables: {
        ...permissionVariables,
        ...options,
      },
    }) ?? [];

  const { data } = useUserDetailsQuery({
    skip: isNil(defaultUserId),
    variables: {
      id: defaultUserId ?? "",
    },
  });

  const defaultUser = data?.user;
  if (defaultUserId && defaultUser) {
    if (!users.some((user) => user.id === defaultUser.id)) {
      users.push(defaultUser);
    }
  }
  
  return users;
};
