import Image from "next/legacy/image";
import React, { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import initiativeImage from "../../../../../public/images/help-center-initiative.svg";
import keyResultImage from "../../../../../public/images/help-center-key-result.svg";

export const ResultTypesInfoView = (): JSX.Element | null => {
  const intl = useIntl();
  return (
    <div className="flex flex-col divide-y px-5 text-sm">
      <div className="space-y-3 py-4">
        <h4 className="m-0 text-sm">
          <FormattedMessage
            defaultMessage="Result type examples"
            id="resultTypesInfoView:resultTypeExamples"
          />
        </h4>
        <div className="text-sm font-semibold">
          <div>
            <FormattedMessage
              defaultMessage="Objective:"
              id="resultTypesInfoView:objective:label"
            />
          </div>
          <div className="font-normal italic">
            <FormattedMessage
              defaultMessage="Get back in shape after holidays"
              id="resultTypesInfoView:objective:title"
            />
          </div>
        </div>
        <div>
          <FormattedMessage
            defaultMessage="This is your destination. It answers the question “Where do I want to go?”"
            id="resultTypesInfoView:objective:explanation"
          />
        </div>
      </div>
      <div className="space-y-3 py-4">
        <div className="flex items-center space-x-2">
          <div className="flex shrink-0 items-center">
            <Image height={40} src={keyResultImage} width={40} />
          </div>
          <div className="text-sm font-semibold">
            <div>
              <FormattedMessage
                defaultMessage="Key Result:"
                id="resultTypesInfoView:keyResult:label"
              />
            </div>
            <div className="font-normal italic">
              <FormattedMessage
                defaultMessage="Decrease body fat by 5%"
                id="resultTypesInfoView:keyResult:title"
              />
            </div>
          </div>
        </div>
        <div>
          {`"${intl.formatMessage({
            defaultMessage: "How do I know I'm getting there",
            id: "resultTypesInfoView:keyResult:explanation1",
          })}"}`}
          <br />
          <FormattedMessage
            defaultMessage="Key Results are a set of metrics that measure your progress towards the Objective."
            id="resultTypesInfoView:keyResult:explanation2"
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="They are <strong>outcomes</strong> because decreasing your body fat is within your circle of influence, but you don't have full control over it."
            id="resultTypesInfoView:keyResult:explanation3"
            values={{
              strong: (str: ReactNode) => <strong>{str}</strong>,
            }}
          />
        </div>
      </div>
      <div className="space-y-3 py-4">
        <div className="flex items-center space-x-2">
          <div className="flex shrink-0 items-center">
            <Image src={initiativeImage} />
          </div>
          <div className="text-sm font-semibold">
            <div>
              <FormattedMessage
                defaultMessage="Initiative:"
                id="resultTypesInfoView:initiative:label"
              />
            </div>
            <div className="font-normal italic">
              <FormattedMessage
                defaultMessage="Run 5 miles every week"
                id="resultTypesInfoView:initiative:title"
              />
            </div>
          </div>
        </div>
        <div>
          {`"${intl.formatMessage({
            defaultMessage: "What will I do to get there?",
            id: "resultTypesInfoView:initiative:explanation1",
          })}"}`}
          <br />
          <FormattedMessage
            defaultMessage="Initiatives are the concrete tasks, actions or projects that you do to help achieve your OKR."
            id="resultTypesInfoView:initiative:explanation2"
          />
        </div>
        <div>
          <FormattedMessage
            defaultMessage="They are <strong>outputs</strong> and hence you have full control over it such as running 5 miles every day."
            id="resultTypesInfoView:initiative:explanation3"
            values={{
              strong: (str: ReactNode) => <strong>{str}</strong>,
            }}
          />
        </div>
      </div>
    </div>
  );
};
