import { useState } from "react";
import { useTimeframes } from "../useTimeframes/useTimeframes";

export const useSelectedTimeframe = (tfId?: string | null) => {
  const { timeframes } = useTimeframes();
  const [selectedTfId, selectTfId] = useState<string | null | undefined>(tfId);

  let selectedTimeframe = null;

  if (selectedTfId) {
    selectedTimeframe = timeframes.find((tf) => tf.id === selectedTfId);
  }

  return { selectedTfId, selectTfId, timeframe: selectedTimeframe };
};
