import { CadenceDefaultFieldsFragment } from "@graphql";
import { escapeRegExp, uniqueId } from "lodash";
import moment, { Moment } from "moment";
import { isSet } from "utils/isSet";
import { cadenceIntervalTypes } from "./constants";

const format = (str: string, data: { [s: string]: string }) => {
  return Object.entries(data).reduce(
    (accu, [param, value]) =>
      accu.replace(new RegExp(escapeRegExp(`{${param}}`), "g"), value),
    str
  );
};

const getRelativeDateInterval = (
  cadence: CadenceDefaultFieldsFragment,
  cycles: number
) => {
  return {
    months:
      cadence.intervalType === cadenceIntervalTypes.MONTH
        ? cadence.intervalLength * cycles
        : 0,
    weeks:
      cadence.intervalType === cadenceIntervalTypes.WEEK
        ? cadence.intervalLength * cycles
        : 0,
    years:
      cadence.intervalType === cadenceIntervalTypes.YEAR
        ? cadence.intervalLength * cycles
        : 0,
  };
};

const formatTfName = (
  cadence: CadenceDefaultFieldsFragment,
  startDate: Moment,
  endDate: Moment,
  counter: number
) => {
  const formatterParams = (date: Moment, name: string) => ({
    [`${name}[month]`]: moment().month(date.month()).format("MMMM"),
    [`${name}[year]`]: date.year().toString(),
    [`${name}[quarter]`]: date.quarter().toString(),
    [`${name}[week]`]: date.isoWeek().toString(),
  });

  const counterFormatter = (phrase = "", count: number) => {
    const counters = phrase.match(/(counter)(\d+)/g);

    if (counters) {
      return counters.reduce((accu, curr) => {
        const counterNumber = +curr.replace("counter", "");
        if (counterNumber > 1) {
          return {
            ...accu,
            [curr]: (count % +curr.replace("counter", "")) + 1,
          };
        }
        return { counter1: count + 1 };
      }, {});
    }
    return { counter: count + 1 };
  };
  if (isSet(cadence.tfFormatter)) {
    const formattedStart = format(
      cadence.tfFormatter,
      formatterParams(startDate, "start")
    );

    const formattedEnd = format(
      formattedStart,
      formatterParams(endDate, "end")
    );

    return format(formattedEnd, counterFormatter(cadence.tfFormatter, counter));
  }
};

type Timeframe = {
  endDate: string;
  id: string;
  name: string;
  startDate: string;
};

export const getCadenceTimeframesPreview = (
  cadence: CadenceDefaultFieldsFragment,
  limit = 4,
  name: string,
  startDate: string
): Timeframe[] => {
  const timeframes = [];

  for (let created = 0; created < limit; created += 1) {
    const interval = getRelativeDateInterval(cadence, created);
    const start = moment(startDate || cadence.startDate).add(interval);

    const end = moment(start)
      .add(getRelativeDateInterval(cadence, 1))
      .subtract({ days: 1 });

    const getName = () => (limit > 1 ? `${name} ${created + 1}` : name);

    timeframes.push({
      endDate: end.toString(),
      id: uniqueId(),
      name: name ? getName() : formatTfName(cadence, start, end, created) || "",
      startDate: start.toString(),
    });
  }
  return timeframes;
};
