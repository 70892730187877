import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  children: React.ReactNode;
  className?: string;
  "data-testid"?: string;
  type?: "reminder" | "success";
};
export const PerformanceReviewFormRootBanner = ({
  children,
  className,
  type = "reminder",
  "data-testid": dataTestId,
}: Props): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex w-full items-center",
        "mb-4 space-x-1 p-3",
        "text-sm text-slate-800",
        "rounded",
        {
          "border bg-green-50 border-green-500": type === "success",
          "border bg-yellow-50 border-yellow-500": type === "reminder",
        },
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
