import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons/Button/Button";
import { CheckboxField } from "common/form/CheckboxField/CheckboxField";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { TimeframeField } from "common/timeframe/TimeframeField/TimeframeField";

export type CloneObjectiveFormValues = {
  includeInits?: boolean;
  includeKrs?: boolean;
  timeframe?: string;
};

type Props = {
  initialValues: CloneObjectiveFormValues;
  onRequestClosed?: () => void;
  onSubmit: (
    values: CloneObjectiveFormValues,
    actions: FormikHelpers<CloneObjectiveFormValues>
  ) => void;
  sizeInits: number;
  sizeKrs: number;
};

export const CloneObjectiveForm = ({
  initialValues,
  onRequestClosed,
  onSubmit,
  sizeInits,
  sizeKrs,
}: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <>
      <div className="my-4">
        <FormattedMessage
          defaultMessage="This will create a copy of this Objective in another Timeframe. Please
                          note that history and updates will {notBeCloned}, so progress will reset
                          to zero."
          id="objective:clone:description:info"
          values={{
            notBeCloned: (
              <strong>
                <FormattedMessage
                  defaultMessage="not be cloned"
                  id="notBeCloned"
                />
              </strong>
            ),
          }}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <TimeframeField
              data-cy="fieldTimeframe"
              label={
                <FormattedMessage
                  defaultMessage="Clone to"
                  id="objective:clone:cloneTo"
                />
              }
              name="timeframe"
              wrapperClassName="pb-1"
            />
            <CheckboxField
              label={
                <FormattedMessage
                  defaultMessage="Include Key Results {size}"
                  id="objective:clone:modal:includeKr"
                  values={{
                    size: sizeKrs < 1 ? "" : `(${sizeKrs})`,
                  }}
                />
              }
              name="includeKrs"
            />

            <CheckboxField
              label={
                <FormattedMessage
                  defaultMessage="Include Initiatives {size}"
                  id="objective:clone:modal:includeInits"
                  values={{
                    size: sizeInits < 1 ? "" : `(${sizeInits})`,
                  }}
                />
              }
              name="includeInits"
            />
            <ModalFooter>
              <Button
                data-cy="a2vFJPHoAYDcQl7TmUgmD"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={submitForm}
                type="submit"
              >
                <FormattedMessage defaultMessage="Clone" id="global:clone" />
              </Button>
              <Button
                data-cy="o6LxctPy2J1MbKzx0e9LD"
                disabled={isSubmitting}
                onClick={onRequestClosed}
                type="button"
                variant="ghost"
              >
                {intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "global:cancel",
                })}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};
