/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  useAllUsersQuery,
  useGetAllTagsQuery,
  useGetOrderedGroupsQuery,
} from "@graphql";
import { useIntl } from "react-intl";
import { getOkrStatuses } from "common/charts/Status/Status";
import * as filterConstants from "legacy/components/filters/constants";
import { Filter } from "legacy/components/filters/types";
import { transformStatuses } from "modules/reports/custom/results/ResultTable/ResultTable.utils";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";

type FilterType =
  | "progress"
  | "owner"
  | "status"
  | "objectiveStage"
  | "lead"
  | "tags"
  | "objectiveTags";

/**
 * @deprecated use {@link useFilters} (wip) instead
 * @param filterTypes
 */
export const useFilterTypes__deprecated = (
  filterTypes: FilterType[]
): Filter[] | [] => {
  const intl = useIntl();
  const { data: groups } = useGetOrderedGroupsQuery({
    variables: { includeCompany: true },
  });
  const { data: tags } = useGetAllTagsQuery();
  const { data: users } = useAllUsersQuery();

  const allGroups = mapEdges(groups?.allGroups.edges).map((group) => ({
    ...group,
    subGroups: mapEdges(group.subGroups.edges),
  }));
  const filters = [];

  if (filterTypes.includes("progress")) {
    filters.push({
      ...filterConstants.progress,
      filterName: "progress",
    });
  }

  if (filterTypes.includes("status")) {
    filters.push({
      ...filterConstants.status,
      items: transformStatuses(getOkrStatuses(intl)),
    });
  }

  if (filterTypes.includes("owner")) {
    const ownerCompany = groups?.currentCompany;
    const ownerFilters = isSet(ownerCompany) ? [ownerCompany] : [];
    filters.push({
      ...filterConstants.group,
      filterName: "owner",
      items: [...allGroups, ...ownerFilters],
      name: "Owner",
    });
  }

  if (filterTypes.includes("lead")) {
    filters.push({
      ...filterConstants.lead,
      filterName: "lead__id__in",
      items: users?.allUsers.edges.map(({ node }) => node) || [],
    });
  }

  if (filterTypes.includes("tags")) {
    filters.push({
      ...filterConstants.tags,
      items: tags?.allTags.edges.map(({ node }) => node) || [],
    });
  }

  if (filterTypes.includes("objectiveTags")) {
    filters.push({
      ...filterConstants.objectiveTags,
      items: tags?.allTags.edges.map(({ node }) => node) || [],
    });
  }

  if (filterTypes.includes("objectiveStage")) {
    filters.push({
      ...filterConstants.objectiveStage,
    });
  }

  return filters;
};
