import {
  ResultsFieldsWithChildrenFragment,
  useUpsertResultIndexMutationMutation,
} from "@graphql";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { REORDER } from "constants/tracking";
import { track } from "utils/tracker";
import { SortableResultItem } from "./SortableResultItem/SortableResultItem";

export interface ResultsWidgetProps {
  results: ResultsFieldsWithChildrenFragment[];
}

const SortableResultList = SortableContainer<{
  results: ResultsFieldsWithChildrenFragment[];
}>(({ results }: ResultsWidgetProps) => (
  <div className="flex-col space-y-2.5 my-2.5" data-cy="resultsList">
    {results.map((result, idx) => (
      <SortableResultItem
        key={`item-${result.id}`}
        index={idx}
        result={result}
      />
    ))}
  </div>
));

export const ResultsWidget = ({ results }: ResultsWidgetProps): JSX.Element => {
  const [updateResultIndex] = useUpsertResultIndexMutationMutation();

  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) => {
    const result = results[oldIndex];
    await updateResultIndex({
      variables: {
        input: {
          id: result?.id,
          index: newIndex,
        },
      },
    });

    track(REORDER, {
      okr_type: "result",
    });
  };

  return (
    <SortableResultList
      distance={3}
      helperClass="react-sortable-dragging"
      onSortEnd={onSortEnd}
      results={results}
      useDragHandle
    />
  );
};
