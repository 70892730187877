import { isUndefined } from "lodash";
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Widget } from "common/misc/Widget/Widget";
import { PagePlaceholder } from "common/placeholders/PagePlaceholder/PagePlaceholder";
import { PagePlaceholderImage } from "common/placeholders/PagePlaceholder/PagePlaceholderImage";
import { useGlobalTimeframe } from "hooks/useGlobalTf/useGlobalTimeframe";
import noObjectives from "../../../../public/images/no_objectives.png";

type Props = {
  children: React.ReactNode;
};

export const RequireTimeframe = memo(
  ({ children }: Props): JSX.Element | null => {
    const globalTimeframe = useGlobalTimeframe();

    if (isUndefined(globalTimeframe)) return null; // sic, see hook implementation

    if (!globalTimeframe?.id) {
      return (
        <Widget className="px-8 py-5">
          <PagePlaceholder
            image={
              <PagePlaceholderImage
                className="m-8 h-auto w-80"
                src={noObjectives.src}
              />
            }
          >
            <p className="text-slate-500">
              <FormattedMessage
                defaultMessage="No Timeframes configured."
                id="no:timeframe:configured"
              />
            </p>
          </PagePlaceholder>
        </Widget>
      );
    }

    return <>{children}</>;
  }
);
