import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
};

export const TextSkeleton: React.FC<Props> = ({ className }) => (
  <div
    className={twClass("animate-pulse rounded-xl bg-slate-200 h-4", className)}
  />
);
