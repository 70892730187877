import { defineMessages } from "react-intl";

export const messages = defineMessages({
  commentLabel: {
    defaultMessage: "Save your learnings for this Result",
    id: "result:archive:forms:comment:label",
  },
  commentPlaceholder: {
    defaultMessage: "Jot down your learnings...",
    id: "result:archive:forms:comment:placeholder",
  },
});
