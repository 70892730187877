import dynamic from "next/dynamic";
import { BarChartProps } from "common/charts/BarChart/BarChart";
import { GaugeChartProps } from "common/charts/GaugeChart/GaugeChart";
import { LineChartProps } from "common/charts/LineChart/LineChart";
import { PieChartProps } from "common/charts/PieChart/PieChart";
import { ProgressChartProps } from "common/charts/ProgressChart/ProgressChart";

const BarChart = dynamic<BarChartProps>(
  () =>
    import("common/charts/BarChart/BarChart").then((module) => module.BarChart),
  {
    ssr: false,
  }
);

const GaugeChart = dynamic<GaugeChartProps>(
  () =>
    import("common/charts/GaugeChart/GaugeChart").then(
      (module) => module.GaugeChart
    ),
  {
    ssr: false,
  }
);

const LineChart = dynamic<LineChartProps>(
  () =>
    import("common/charts/LineChart/LineChart").then(
      (module) => module.LineChart
    ),
  {
    ssr: false,
  }
);

const PieChart = dynamic<PieChartProps>(
  () =>
    import("common/charts/PieChart/PieChart").then((module) => module.PieChart),
  {
    ssr: false,
  }
);

const ProgressChart = dynamic<ProgressChartProps>(
  () =>
    import("common/charts/ProgressChart/ProgressChart").then(
      (module) => module.ProgressChart
    ),
  {
    ssr: false,
  }
);

/**
 * this contains components which need to be loaded in the browser, so we're importing them dynamically with no SSR.
 * note: to avoid TS errors, a component must either use default export, or export its props and be loaded like:
 * @example
 *     dynamic<FooProps>(() => import("Foo").then((module) => module.Foo), { ssr: false })
 *
 * @see https://nextjs.org/docs/advanced-features/dynamic-import
 * @see https://nextjs.org/docs/advanced-features/dynamic-import#with-no-ssr
 */
export { BarChart, GaugeChart, LineChart, PieChart, ProgressChart };
