import { Switch } from "@headlessui/react";
import classNames from "clsx";
import React from "react";

const sizes = {
  medium: {
    container: "h-6 w-12",
    toggle: "h-5 w-5",
    translate: "translate-x-6",
  },
  small: {
    container: "h-4 w-7",
    toggle: "h-3 w-3",
    translate: "translate-x-3",
  },
};

type Props = {
  className?: string;
  "data-cy"?: string;
  disabled?: boolean;
  label?: string;
  on?: boolean;
  onToggle: (val: boolean) => void;
  size?: keyof typeof sizes;
};

/**
 * Toggle component without internal state. Pass enabled state as `on` prop.
 */
export const Toggle = ({
  "data-cy": dataCy,
  className,
  disabled,
  label,
  on: enabled = false,
  onToggle,
  size = "medium",
}: Props): JSX.Element => {
  const handleToggle = () => {
    if (disabled) return;

    onToggle(!enabled);
  };

  return (
    <Switch.Group>
      <div className={className} data-cy={dataCy}>
        <Switch
          checked={enabled}
          className={classNames(
            "relative inline-flex shrink-0 cursor-pointer rounded-full ",
            "border-2 transition-colors duration-200 ease-in-out border-transparent",
            "focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
            sizes[size].container,
            {
              "bg-green-500": enabled,
              "bg-slate-300": !enabled,
              "opacity-50": disabled,
            }
          )}
          data-cy="toggle"
          onChange={handleToggle}
        >
          <span
            aria-hidden="true"
            className={classNames(
              "pointer-events-none inline-block transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out bg-white",
              sizes[size].toggle,
              {
                [sizes[size].translate]: enabled,
              }
            )}
          />
        </Switch>
        {label && (
          <Switch.Label className="pl-2 cursor-pointer">{label}</Switch.Label>
        )}
      </div>
    </Switch.Group>
  );
};
