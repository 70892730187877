import React from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { twClass } from "utils/twClass";

type CompleteMessageProps = {
  completedDate: string | null;
  lastEditedDate: string;
  meetingCancelled: boolean;
  meetingComplete: boolean;
};

export const MeetingCompleteMessage = ({
  meetingCancelled,
  meetingComplete,
  lastEditedDate,
  completedDate,
}: CompleteMessageProps): JSX.Element | null => {
  if (meetingCancelled) {
    return (
      <FormattedMessage
        defaultMessage="Meeting cancelled on {lastEditedDate} - {lastEditedTime}"
        id="kYpO/g"
        values={{
          lastEditedDate: (
            <FormattedDate
              day="numeric"
              month="short"
              value={lastEditedDate}
              year="numeric"
            />
          ),
          lastEditedTime: <FormattedTime value={lastEditedDate} />,
        }}
      />
    );
  }
  if (completedDate && meetingComplete) {
    return (
      <FormattedMessage
        defaultMessage="Meeting ended on {completedDate} - {lastEditedTime}"
        id="p4Dg1u"
        values={{
          completedDate: (
            <FormattedDate
              day="numeric"
              month="short"
              value={completedDate}
              year="numeric"
            />
          ),
          lastEditedTime: <FormattedTime value={completedDate} />,
        }}
      />
    );
  }
  return null;
};

type UpcomingProps = {
  isActive: boolean;
};

export const InactiveMeetingMessage = ({
  isActive,
}: UpcomingProps): JSX.Element => (
  <div
    className={twClass(
      "flex grow items-center justify-center",
      "space-y-3 p-8 text-center text-slate-600"
    )}
  >
    {isActive ? (
      <FormattedMessage
        defaultMessage="There’s no upcoming 1:1 meeting. Plan the next meeting to set up your 1:1s."
        id="qqd9FL"
      />
    ) : (
      <FormattedMessage
        defaultMessage="This meeting is deactivated. Please re-activate the meeting to continue this 1:1."
        id="2zmSv/"
      />
    )}
  </div>
);
