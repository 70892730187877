import { useUserCompanyInfoQuery } from "@graphql";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { ChatSales } from "common/inAppUpgrade/modals/UpgradeModal/ChatSales/ChatSales";
import { WidgetLoading } from "common/index";
import { Modal } from "common/overlay/Modal/Modal";
import { ErrorMessage } from "common/placeholders/ErrorMessage/ErrorMessage";
import { PREMIUM_CALL_SCHEDULED } from "constants/tracking";
import { useChargebee } from "hooks/useChargebee/useChargebee";
import { useSalesModals } from "hooks/useSalesModals/useSalesModals";
import { track } from "utils/tracker";

export const ChatSalesModal = (): JSX.Element | null => {
  const { close } = useSalesModals();
  useChargebee();

  const { data, error } = useUserCompanyInfoQuery();

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Modal
      buttonAreaStyles={{ left: 10, position: "relative" }}
      isOpen
      onClose={close}
      showFooter={false}
      size="lg"
      title={
        <div className="full-width flex items-center justify-between">
          <h1 className="text-2xl text-slate-800">
            <FormattedMessage
              defaultMessage="Chat to sales"
              id="upgrade:modal:label:chat:sales"
            />
          </h1>
        </div>
      }
    >
      <Show fallback={<WidgetLoading />} when={data?.me}>
        {({ email, fullName }) => (
          <ChatSales
            email={email}
            onEventScheduled={() => track(PREMIUM_CALL_SCHEDULED)}
            userFullName={fullName}
          />
        )}
      </Show>
    </Modal>
  );
};
