import React, { ReactNode } from "react";
import { Show } from "common/controlFlow";
import { twClass } from "utils/twClass";

interface Props {
  children: ReactNode;
  className?: string;
  loading?: boolean;
}

export const GoalSidebarItemContent = ({
  children,
  loading,
  className,
}: Props) => {
  return (
    <Show
      when={!loading}
      fallback={
        <div className="rounded-md w-2/3 h-full bg-slate-300 animate-pulse" />
      }
    >
      <div className={twClass("grow text-slate-800 min-w-0", className)}>
        {children}
      </div>
    </Show>
  );
};
