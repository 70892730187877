import { PropsWithChildren, ReactNode } from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { AnchorNext } from "common/navigation";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type Props = PropsWithChildren & {
  href: string | null;
  tooltip?: ReactNode;
};

export const SelectLinkButton = ({ children, href, tooltip }: Props) => {
  return (
    <div
      className={twClass("flex w-full items-stretch space-x-2 group", {
        block: !isSet(href),
      })}
    >
      <div className="grow min-w-0">{children}</div>
      {!!href && (
        <WithTooltip tooltip={tooltip}>
          <AnchorNext
            href={href}
            className="text-slate-800 hover:text-blue-400 group-hover:visible invisible"
          >
            <IconButton name="keyboard_arrow_right" ghost size="lg" />
          </AnchorNext>
        </WithTooltip>
      )}
    </div>
  );
};
