import classNames from "clsx";
import React from "react";
import { Entity } from "common/avatar";
import { GoalUpdateNoteSubtext } from "common/checkIn/CheckInGoal/GoalUpdateNoteSubtext/GoalUpdateNoteSubtext";
import { KpiValues } from "common/checkIn/CheckInGoal/GoalValues/KpiValues";
import { ReportKpi } from "common/progressReports/Kpi";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";

type Props = {
  kpi: ReportKpi;
};

export const SidebarCheckInKpi = ({ kpi }: Props): JSX.Element => {
  const { kpiHref } = useModalRouter();

  return (
    <div className="divide-y rounded border bg-white border-slate-300">
      <article
        aria-label={`check-in-goal-${kpi.name}`}
        className={classNames(
          "flex items-center",
          "py-3 pl-5",
          "first:border-t-0",
          "border-t border-slate-300"
        )}
        data-cy={`checkInGoal-${kpi.name}`}
      >
        <div className="flex items-center overflow-hidden">
          <Entity
            className="gap-2 items-start"
            href={kpiHref({ kpiId: kpi.id }, { stack: true }).toString()}
            name={kpi.name}
          >
            <div className="space-y-2 pt-2">
              <KpiValues className="w-auto grow flex-row gap-2" kpi={kpi} />
              {kpi.delta.lastCommit?.description && (
                <GoalUpdateNoteSubtext
                  description={kpi.delta.lastCommit.description}
                />
              )}
            </div>
          </Entity>
        </div>
      </article>
    </div>
  );
};
