import { useGetResultLazyQuery } from "@graphql";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { KpiDetailsModal } from "common/kpi/modals/KpiDetailsModal/KpiDetailsModal";
import { ObjectiveDetailsModal } from "common/objective/modals/ObjectiveModal/ObjectiveDetailsModal/ObjectiveDetailsModal";

export const GoalModal = (): JSX.Element | null => {
  const { query, push } = useRouter();
  const { oId, rId, kpiId } = query;
  const [getResult] = useGetResultLazyQuery();

  const fetchObjectiveAndRedirect = async (resultId: string) => {
    const result = await getResult({ variables: { resultId } });
    const objectiveId = result.data?.result?.objective.id;
    if (objectiveId)
      push({
        query: {
          ...query,
          oId: objectiveId,
        },
      });
  };

  useEffect(() => {
    // If route has rId but not oId, fetch and set the corresponding oId
    // Sometimes we want to add a link to a result, without having to fetch it's oId first.
    // This lets you do that
    if (!oId && rId) {
      fetchObjectiveAndRedirect(rId.toString());
    }
  }, [rId, oId]);

  if (oId) {
    // Modal on by default for now

    return (
      <ObjectiveDetailsModal
        id={oId.toString()}
        resultId={rId && rId.toString()}
      />
    );
  }

  if (kpiId) {
    return <KpiDetailsModal id={kpiId.toString()} />;
  }

  return null;
};
