import { useKpiContext } from "common/context/kpiContext";
import { useInlineKpiActions } from "common/kpi/hooks/useInlineKpiActions/useInlineKpiActions";
import { ModalHeader } from "common/overlay/Modal/ModalHeader/ModalHeader";

export const KpiModalHeader = (): JSX.Element => {
  const kpi = useKpiContext();
  const { setName } = useInlineKpiActions(kpi);

  return (
    <ModalHeader
      editVisible={!!kpi.canPatch}
      entityId={kpi.id}
      onUpdate={(editedKpi: {
        name: string;
      }) => setName(editedKpi.name)}
      title={kpi.name}
    />
  );
};
