import React from "react";
import { Checkbox } from "common/inputs/Checkbox/Checkbox";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
export const FilterCheckbox = ({ item, filterColumn, onSelect, onRemove }) => {
  const checked = filterColumn
    ? // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'selected' implicitly has an 'any' type.
      filterColumn.selected.find((selected) => selected.id === item.id)
    : false;

  return (
    <div className="px-3 py-1">
      <Checkbox
        checked={checked}
        id={item.id}
        label={item.name}
        onChange={() => (checked ? onRemove() : onSelect())}
      />
    </div>
  );
};
