import React from "react";
import { FormattedMessage } from "react-intl";
import { logToSentry } from "../../../utils/tracker";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const { CompanyChanged } = EventTypes;

export const CompanyEventFormatters: Record<string, EventFormatter> = {
  [CompanyChanged]: {
    getComment: () => null,
    getString: (attr): JSX.Element => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.change.attribute) {
        case "vision": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the company slogan from {oldVal} to {newVal}"
              id="event:formatter:company:changedSlogan"
              values={{
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "avatar": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="updated the company avatar"
              id="event:formatter:company:changedAvatar"
            />
          );
          break;
        }
        case "name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the company name from {oldVal} to {newVal}"
              id="event:formatter:company:changedName"
              values={{
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: CompanyChanged,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
        }
      }

      return formattedEvent;
    },
  },
};
