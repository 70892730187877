import { gql } from "@apollo/client";
import { CommentTooltipFragment } from "@graphql";
import { upperFirst } from "lodash";
import moment from "moment";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ProgressChartTooltip } from "common/charts/ProgressChart/ProgressChartTooltip/ProgressChartTooltip";
import { isSet } from "utils/isSet";

const messages = defineMessages({
  empty: {
    defaultMessage: "Add a note to your Objective to surface it here",
    id: "notes:empty",
  },
  none: {
    defaultMessage: "No note added",
    id: "notes:none",
  },
});

interface Props {
  comment: CommentTooltipFragment;
  progressDriver?: string;
}

export const CommentTooltip = ({
  comment,
  progressDriver,
}: Props): JSX.Element => {
  const intl = useIntl();

  let lastUpdatedString = intl.formatMessage(messages.none);
  if (isSet(comment)) {
    if (isSet(comment.user)) {
      lastUpdatedString = `${upperFirst(
        moment(comment.createdDate).fromNow()
      )} by ${comment.user.fullName}`;
    } else {
      lastUpdatedString = upperFirst(moment(comment.createdDate).fromNow());
    }
  }

  return (
    <ProgressChartTooltip
      description={comment.content || intl.formatMessage(messages.empty)}
      descriptionClass={comment.content ? "text-black" : "text-slate-500"}
      lastUpdatedString={lastUpdatedString}
      progressDriver={progressDriver}
      valueClass="hidden"
      warningClass="hidden"
    />
  );
};

CommentTooltip.fragment = gql`
  fragment CommentTooltip on comment {
    id
    user {
      id
      avatar
      fullName
    }
    createdDate
    content
    description
  }
`;
