import classNames from "clsx";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import { Avatar } from "common/avatar";
import { ListItem } from "common/misc/ListItem/ListItem";

type MentionUser = {
  avatar: string;
  id: string;
  link: string;
  name: string;
};

const messages = defineMessages({
  noResultsFound: {
    defaultMessage: "No Results",
    id: "global:no:results",
  },
});

export const MentionList = forwardRef((props: any, ref: any) => {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];
    if (item) {
      props.command({ id: `user:${item.id}`, label: item.name });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="max-h-60 overflow-y-auto" data-mentions-list>
      {props.items.length ? (
        props.items.map((item: MentionUser, index: number) => (
          <ListItem
            data-cy="ax3_1Fzah9qS8wO1uhqr4"
            key={item.id}
            className={classNames("text-black hover:bg-blue-100", {
              "bg-blue-100": index === selectedIndex,
            })}
            data-testid="userMention"
            onClick={() => selectItem(index)}
          >
            <Avatar className="mr-2" size="small" url={item.avatar} />
            {item.name}
          </ListItem>
        ))
      ) : (
        <ListItem>{intl.formatMessage(messages.noResultsFound)}</ListItem>
      )}
    </div>
  );
});
