import { createNonNullableCtx } from "common/context/utils";
import {
  Filter,
  FiltersDispatch,
  FilterState,
} from "legacy/components/filters/types";

type FiltersContext = {
  filters: Filter[];
  filtersDispatch: FiltersDispatch;
  filtersLoading: boolean;
  filterState: FilterState;
};

export const [useFiltersContext, FiltersContextProvider] =
  createNonNullableCtx<FiltersContext>();
