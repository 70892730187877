import {
  namedOperations,
  useUpsertUserMutation,
  useUserNotificationsQuery,
} from "@graphql";
import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { apolloQueryMerger } from "utils/utils";
import { NotificationList } from "./NotificationList/NotificationList";

export const GlobalNotificationButton = (): JSX.Element => {
  const { data, loading, fetchMore } = useUserNotificationsQuery();
  const notificationList = mapEdges(data?.notifications.edges);
  const me = useCurrentUser();
  const unread = notificationList.filter(
    (notification) =>
      isSet(me) &&
      isSet(me.lastNotificationRead) &&
      notification.eventDate > me.lastNotificationRead
  );
  const [updateUser] = useUpsertUserMutation({
    refetchQueries: [
      namedOperations.Query.getCurrentUserInfo,
      namedOperations.Query.UserNotifications,
    ],
  });
  const onClick = () => {
    track("OPENED_IN_APP_NOTIFICATIONS");
    return updateUser({
      variables: {
        input: {
          id: me?.id,
          updateLastNotificationRead: true,
        },
      },
    });
  };

  return (
    <Dropdown
      button={
        <IconButton
          bubbleCount={unread.length}
          className={twClass({ "text-blue-500": unread.length > 0 })}
          data-cy="notificationDropdown"
          name="notifications"
          onClick={onClick}
          tooltip="Notifications"
        />
      }
      id="notification-window-dd"
    >
      {loading && !data ? (
        <SpinnerFit />
      ) : (
        <NotificationList
          fetchMore={() =>
            fetchMore({
              updateQuery: apolloQueryMerger,
              variables: {
                after: data?.notifications.pageInfo.endCursor,
              },
            })
          }
          hasNextPage={data?.notifications.pageInfo.hasNextPage}
          lastNotificationRead={me?.lastNotificationRead}
          loading={loading}
          notifications={notificationList}
        />
      )}
    </Dropdown>
  );
};
