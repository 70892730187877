import {
  AllUsersQueryData,
  GetDirectReportsQueryData,
  UserAvatarFragment,
  UserDefaultFragment,
  UserFullNameFragment,
} from "@graphql";
import { mapEdges } from "utils/mapEdges";

type DirectReport = UserDefaultFragment & UserAvatarFragment;

type AllUser = UserFullNameFragment & UserAvatarFragment;

type UserListMembers = {
  avatar: string | null;
  label: string;
  value: string;
}[];

type UserList = {
  directReports: UserListMembers;
  remainingUsers: UserListMembers;
};

export const handleListFormatting = (
  excludedUserIds: string | string[],
  allUsers?: AllUsersQueryData,
  directReports?: GetDirectReportsQueryData
): UserList => {
  const all = mapEdges(allUsers?.allUsers.edges);
  const directReport = mapEdges(directReports?.me?.directReports.edges);

  const remainingUsers = all.filter((user) => {
    const notDirectReport = !directReport.find((dr) => dr.id === user.id);
    const notExcludedUser = Array.isArray(excludedUserIds)
      ? !excludedUserIds.includes(user.id)
      : excludedUserIds !== user.id;
    return notDirectReport && notExcludedUser;
  });

  const formatUsers = (users: (DirectReport | AllUser)[]) =>
    users.map((user) => ({
      avatar: user.avatar,
      label: user.fullName || "Unknown",
      value: user.id,
    }));

  return {
    directReports: formatUsers(directReport),
    remainingUsers: formatUsers(remainingUsers),
  };
};
