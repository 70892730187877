/*
- if you update styles imports here, please do same in storybook preview.jsx
- order of css imports is important, eg.
import "common/inputs/TextArea/PerdooTheme/PerdooTheme.css";
TODO: remove these imports as part of tailwind migration
*/
import "cropperjs/dist/cropper.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-phone-input-2/lib/bootstrap.css";
import "tippy.js/dist/border.css";
import "tippy.js/dist/tippy.css";
import "assets/css/styles.css";
import "assets/css/index.css";
import "assets/css/fonts.css";
import "assets/css/date-picker.css";
import "assets/css/phone-input.css";
import "common/overlay/Tooltip/Tooltip.theme.default.css";
import "common/overlay/Tooltip/Tooltip.theme.perdooLight.css";
import "common/overlay/Tooltip/Tooltip.theme.perdooWhite.css";
import "common/overlay/Tooltip/Tooltip.theme.perdooBlue.css";
import "common/inputs/TextEditor/TextEditor.css";
import "common/misc/DangerousHTML/DangerousHTML.css";
import "common/overlay/Modal/Modal.css";
import "common/layout/Sidebar/Sidebar.css";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "apolloClient";
import { INTERCOM_APP_ID, POSTHOG_KEY } from "config";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Client, HydrationProvider } from "react-hydration-provider";
import { Provider } from "react-redux";
import { BrowserUpdateAlert } from "common/alerts/BrowserUpdateAlert/BrowserUpdateAlert";
import { HelpCenterContextProvider } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { Layout } from "common/layout/Layout/Layout";
import { NextPageWithLayout } from "common/layout/Layout/Layout.types";
import { ErrorBoundary } from "common/misc/ErrorBoundary/ErrorBoundary";
import { PagePermissions } from "common/misc/PagePermissions/PagePermissions";
import { CollapsibleContext } from "common/navigation/CollapsibleContext/CollapsibleContext";
import { useCollapsibleSidebar } from "common/navigation/useCollapsibleSidebar/useCollapsibleSidebar";
import { GlobalModals } from "common/overlay/GlobalModals/GlobalModals";
import { AuthWrapper } from "common/topLevel/AuthWrapper/AuthWrapper";
import { I18nProvider } from "common/topLevel/I18nProvider/I18nProvider";
import { Scripts } from "common/topLevel/Scripts/Scripts";
import { ToasterContainer } from "common/topLevel/ToasterContainer/ToasterContainer";
import { useIsProtectedRoute } from "common/topLevel/useIsProtectedRoute/useIsProtectedRoute";
import { store } from "legacy/store/store.config";
import { boot } from "utils/boot";
import { browserOutdated } from "utils/browserOutdated";
import { useRedirectLegacyHashRoute } from "utils/navigation";
import { intercom } from "utils/tracker";
import { setDesktopViewport } from "utils/utils";

if (POSTHOG_KEY && typeof window !== "undefined") {
  posthog.init(POSTHOG_KEY, {
    api_host: "https://eu.posthog.com",
    autocapture: false,
    capture_pageview: false,
    disable_session_recording: true,
  });
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

/**
 * custom app to control page initialization.
 * @see https://nextjs.org/docs/basic-features/typescript#custom-app
 */
const MyApp = ({
  Component,
  pageProps,
}: AppPropsWithLayout): JSX.Element | null => {
  const router = useRouter();

  const [isBrowserOutdated, setIsBrowserOutdated] = useState(false);
  const { hideSidebar, isCollapsed, toggleCollapsed } = useCollapsibleSidebar();
  const isProtectedRoute = useIsProtectedRoute();
  const showNavigation = isProtectedRoute && !hideSidebar;

  // TODO: refactor so browserOutdated is just one hook
  useEffect(() => {
    browserOutdated().then((value) => {
      setIsBrowserOutdated(value);
    });

    // This renders the app like a desktop site on mobile, until we make more parts responsive
    setDesktopViewport();
  }, []);

  useEffect(() => {
    boot();
  }, []);

  const { hashUrl, shouldRedirect } = useRedirectLegacyHashRoute();
  if (shouldRedirect) {
    router.replace(hashUrl);
    return null;
  }

  const getLayout =
    Component.getLayout ??
    ((page) => <Layout hideSidebar={!showNavigation}>{page}</Layout>);

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <HelpCenterContextProvider>
          <I18nProvider>
            <ErrorBoundary>
              <HydrationProvider>
                <PostHogProvider client={posthog}>
                  {/* TODO: this will only render client-side. remove the wrapper after auth is rewritten to not use local storage. */}
                  <Client>
                    {/* fixed in newer versions of react-dnd, remove after upgrade */}
                    <DndProvider backend={HTML5Backend}>
                      <PagePermissions>
                        <Head>
                          <meta charSet="utf-8" />
                          <meta
                            content="width=device-width, initial-scale=1"
                            id="viewport-meta"
                            name="viewport"
                          />
                          <meta content="#000000" name="theme-color" />
                          <meta content="notranslate" name="google" />
                          <title>Perdoo</title>
                        </Head>
                        <div className="h-full overflow-hidden print:overflow-visible">
                          {isBrowserOutdated && <BrowserUpdateAlert />}
                          <Scripts />

                          <CollapsibleContext.Provider
                            value={{
                              isCollapsed,
                              toggleSidebarCollapse: toggleCollapsed,
                            }}
                          >
                            <AuthWrapper>
                              {getLayout(<Component {...pageProps} />)}
                            </AuthWrapper>
                            <ToasterContainer />
                          </CollapsibleContext.Provider>
                        </div>
                      </PagePermissions>
                    </DndProvider>
                  </Client>
                </PostHogProvider>
              </HydrationProvider>
              {/* These cannot be moved to <Scripts /> as that would stop them from being loaded before the site is interactive  */}
              {/* https://nextjs.org/docs/basic-features/script#application-scripts */}
              <Script
                src="https://accounts.google.com/gsi/client"
                strategy="beforeInteractive"
              />
              <Script
                dangerouslySetInnerHTML={{
                  __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
                }}
                id="intercom-script"
                onReady={() => {
                  intercom()?.("boot", {
                    app_id: INTERCOM_APP_ID,
                  });
                }}
                strategy="beforeInteractive"
              />
            </ErrorBoundary>
            <GlobalModals />
          </I18nProvider>
        </HelpCenterContextProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default MyApp;
