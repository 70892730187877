import { Disclosure } from "@headlessui/react";
import { isArray, take, takeRight } from "lodash";
import React, { Fragment, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "common/buttons";

type FoldButtonProps = { open: boolean };

const FoldButton = ({ open }: FoldButtonProps) => (
  <Button variant="negative">
    {open ? (
      <FormattedMessage defaultMessage="See less" id="sidebar:widget:seeLess" />
    ) : (
      <FormattedMessage defaultMessage="See all" id="sidebar:widget:seeAll" />
    )}
  </Button>
);

type Props = {
  children: ReactNode;
  initialShowedItemsCount?: number;
  renderFoldButton?: ({ open }: FoldButtonProps) => JSX.Element;
  renderHiddenItems?: (c: ReactNode) => JSX.Element;
  renderItems?: (c: ReactNode) => JSX.Element;
};

/**
 * shows the first {@link initialShowedItemsCount} items and hides the rest under a "see all" button.
 */
export const FoldableContainer = ({
  children,
  renderItems,
  renderHiddenItems,
  renderFoldButton = FoldButton,
  initialShowedItemsCount = 3,
}: Props): JSX.Element => {
  const isChildrenArray = isArray(children);

  if (isChildrenArray && children.length > initialShowedItemsCount) {
    const showedItems = take(children, initialShowedItemsCount);
    const hiddenItems = takeRight(
      children,
      children.length - initialShowedItemsCount
    );

    return (
      <Disclosure>
        {({ open }) => (
          <>
            {renderItems?.(showedItems) ?? showedItems}
            <Disclosure.Panel>
              {renderHiddenItems?.(hiddenItems) ?? hiddenItems}
            </Disclosure.Panel>
            <Disclosure.Button as={Fragment} data-cy="disclosureButton">
              {renderFoldButton({ open })}
            </Disclosure.Button>
          </>
        )}
      </Disclosure>
    );
  }

  return renderItems?.(children) ?? <>{children}</>;
};
