import { useField } from "formik";
import { isString } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { FormControl } from "common/form/FormControl/FormControl";
import { GroupMultiSelect } from "common/group/GroupMultiSelect/GroupMultiSelect";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { OwnerSelectOption } from "common/select/OwnerSelect/OwnerSelect.types";
import { useOwners } from "hooks/useOwners/useOwners";
import { isSet } from "utils/isSet";
import { toast } from "utils/toastr";

type MultiSelectProps = React.ComponentProps<typeof GroupMultiSelect>;
type FormControlProps = Pick<
  React.ComponentProps<typeof FormControl>,
  "fieldAddOn" | "helperText"
>;

type Group = OwnerSelectOption;

type Props = Omit<
  MultiSelectProps,
  "name" | "onChange" | "selectedGroups" | "groups"
> &
  FormControlProps & {
    exclude?: string[];
    includeArchived?: boolean;
    includeCompany?: boolean;
    label?: string;
    name: string;
    optional?: boolean;
  };

export const GroupMultiField = ({
  fieldAddOn,
  helperText,
  includeCompany = false,
  label,
  name,
  optional,
  exclude,
  includeArchived,
  ...multiSelectProps
}: Props): JSX.Element | null => {
  const [field, meta, helpers] = useField<string | string[] | undefined>(name);
  const { $t } = useIntl();

  let value = field.value ?? [];
  // when switching between single and shared ownership, the field value is remembered as string, so we need to convert it to array.
  if (isString(value)) value = [value];

  const {
    error,
    loading,
    owners: groups,
  } = useOwners({
    options: {
      excludedIds: exclude,
      includeArchived,
      includeCompany,
    },
    selected: value,
  });

  if (error) {
    toast.failure(
      $t({ defaultMessage: "Error fetching groups", id: "NIwHEE" })
    );

    return null;
  }

  if (loading) return <SpinnerFit />;

  const setSelectedGroups = (selected: Group[]) => {
    helpers.setValue(selected.map(({ id }) => id));
  };

  const selectedGroups = value
    .map((groupId) => groups.find(({ id }) => id === groupId))
    .filter(isSet);

  return (
    <FormControl
      errorText={meta.error}
      fieldAddOn={fieldAddOn}
      helperText={helperText}
      id={name}
      label={label}
      optional={optional}
    >
      <GroupMultiSelect
        {...multiSelectProps}
        creatable
        groups={groups}
        onChange={setSelectedGroups}
        selectedGroups={selectedGroups}
        errorText={meta.error}
      />
    </FormControl>
  );
};
