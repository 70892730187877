/*
  @sampleData is the object that hold all the sample objectives, kpi and key results that are used
  as dummy data during Onboarding as well as suggestions while creating them.
 */
import { MetricUnit, PerdooApiKpiGoalOperatorChoices } from "@graphql";
import { resultTypes } from "constants/resultTypes";

export const sampleData = {
  keyResults: [
    {
      endValue: 65,
      id: "keyResult1",
      owner: "company",
      startValue: 53,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 20,
      id: "keyResult2",
      owner: "company",
      startValue: 10,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 10,
      id: "keyResult3",
      owner: "finance",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 30,
      id: "keyResult4",
      owner: "finance",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 2,
      id: "keyResult5",
      owner: "engineering",
      startValue: 6,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 70,
      id: "keyResult6",
      owner: "engineering",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 60,
      id: "keyResult7",
      owner: "sales",
      startValue: 90,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 25,
      id: "keyResult8",
      owner: "sales",
      startValue: 15,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 50,
      id: "keyResult9",
      owner: "marketing",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 5,
      id: "keyResult10",
      owner: "marketing",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 50,
      id: "keyResult11",
      owner: "product",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 8,
      id: "keyResult12",
      owner: "product",
      startValue: 12,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 24,
      id: "keyResult13",
      owner: "operations",
      startValue: 39,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 30,
      id: "keyResult14",
      owner: "operations",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 3,
      id: "keyResult15",
      owner: "company",
      startValue: 1,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 52,
      id: "keyResult16",
      owner: "company",
      startValue: 40,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 65,
      id: "keyResult17",
      owner: "company",
      startValue: 56,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 10,
      id: "keyResult18",
      owner: "company",
      startValue: 31,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 45,
      id: "keyResult19",
      owner: "company",
      startValue: 32,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 4000000,
      id: "keyResult20",
      owner: "company",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 1200,
      id: "keyResult21",
      owner: "company",
      startValue: 876,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 550000,
      id: "keyResult22",
      owner: "company",
      startValue: 493871,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 450000,
      id: "keyResult23",
      owner: "company",
      startValue: 402321,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 22,
      id: "keyResult24",
      owner: "company",
      startValue: 17.32,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 6,
      id: "keyResult25",
      owner: "company",
      startValue: 8.2,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 15,
      id: "keyResult26",
      owner: "company",
      startValue: 2,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 95,
      id: "keyResult27",
      owner: "company",
      startValue: 42,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 100,
      id: "keyResult28",
      owner: "company",
      startValue: 12,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 8,
      id: "keyResult29",
      owner: "company",
      startValue: 1,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 3,
      id: "keyResult30",
      owner: "company",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 4.5,
      id: "keyResult31",
      owner: "marketing",
      startValue: 2.02,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 55,
      id: "keyResult32",
      owner: "marketing",
      startValue: 53,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 5,
      id: "keyResult33",
      owner: "marketing",
      startValue: 12,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 90,
      id: "keyResult34",
      owner: "marketing",
      startValue: 72,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 87,
      id: "keyResult35",
      owner: "marketing",
      startValue: 66,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 1.18,
      id: "keyResult36",
      owner: "marketing",
      startValue: 0.97,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 70,
      id: "keyResult37",
      owner: "marketing",
      startValue: 55,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 55,
      id: "keyResult38",
      owner: "marketing",
      startValue: 34,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 30,
      id: "keyResult39",
      owner: "marketing",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 75,
      id: "keyResult40",
      owner: "marketing",
      startValue: 25,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 25,
      id: "keyResult41",
      owner: "marketing",
      startValue: 2,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 4,
      id: "keyResult42",
      owner: "marketing",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 90,
      id: "keyResult43",
      owner: "marketing",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 220,
      id: "keyResult44",
      owner: "marketing",
      startValue: 30,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 220000,
      id: "keyResult45",
      owner: "marketing",
      startValue: 88000,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 350,
      id: "keyResult46",
      owner: "marketing",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 2,
      id: "keyResult47",
      owner: "marketing",
      startValue: 0.7,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 3700,
      id: "keyResult48",
      owner: "marketing",
      startValue: 1267,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 11,
      id: "keyResult49",
      owner: "sales",
      startValue: 6,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 4.5,
      id: "keyResult50",
      owner: "sales",
      startValue: 3.6,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 15000,
      id: "keyResult51",
      owner: "sales",
      startValue: 1280,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 20,
      id: "keyResult52",
      owner: "sales",
      startValue: 2,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 35,
      id: "keyResult53",
      owner: "sales",
      startValue: 23,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 1000,
      id: "keyResult54",
      owner: "sales",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 10000,
      id: "keyResult55",
      owner: "sales",
      startValue: 7500,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 8,
      id: "keyResult56",
      owner: "sales",
      startValue: 5,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 80,
      id: "keyResult57",
      owner: "sales",
      startValue: 60,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 10,
      id: "keyResult58",
      owner: "sales",
      startValue: 4,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 7,
      id: "keyResult59",
      owner: "sales",
      startValue: 10,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 85,
      id: "keyResult60",
      owner: "sales",
      startValue: 65,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 1,
      id: "keyResult61",
      owner: "sales",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 1250,
      id: "keyResult62",
      owner: "sales",
      startValue: 1000,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 45,
      id: "keyResult63",
      owner: "sales",
      startValue: 60,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 75,
      id: "keyResult64",
      owner: "engineering",
      startValue: 60,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 9,
      id: "keyResult65",
      owner: "engineering",
      startValue: 7,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 35,
      id: "keyResult66",
      owner: "engineering",
      startValue: 22,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 20,
      id: "keyResult67",
      owner: "engineering",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 25,
      id: "keyResult68",
      owner: "engineering",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 25,
      id: "keyResult69",
      owner: "engineering",
      startValue: 60,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 36,
      id: "keyResult70",
      owner: "engineering",
      startValue: 72,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 3,
      id: "keyResult71",
      owner: "engineering",
      startValue: 1,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 200,
      id: "keyResult72",
      owner: "engineering",
      startValue: 375,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 8,
      id: "keyResult73",
      owner: "product",
      startValue: 12,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 75,
      id: "keyResult74",
      owner: "product",
      startValue: 55,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 30,
      id: "keyResult75",
      owner: "product",
      startValue: 20,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 75,
      id: "keyResult76",
      owner: "product",
      startValue: 60,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 60,
      id: "keyResult77",
      owner: "product",
      startValue: 40,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 15,
      id: "keyResult78",
      owner: "finance",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 30,
      id: "keyResult79",
      owner: "finance",
      startValue: 0,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 220000,
      id: "keyResult80",
      owner: "finance",
      startValue: 200000,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 90000,
      id: "keyResult81",
      owner: "finance",
      startValue: 100000,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 2,
      id: "keyResult82",
      owner: "operations",
      startValue: 4,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 130000,
      id: "keyResult83",
      owner: "operations",
      startValue: 160000,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 75,
      id: "keyResult84",
      owner: "operations",
      startValue: 90,
      type: resultTypes.keyResult,
      unit: MetricUnit.Numerical,
    },
  ],
  kpis: [
    {
      endValue: 20,
      id: "kpi1",
      operator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
      owner: "company",
      startValue: 15,
      unit: MetricUnit.Percentage,
    },
    {
      endValue: 30,
      id: "kpi2",
      operator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
      owner: "finance",
      startValue: 25,
      unit: MetricUnit.Percentage,
    },
    {
      endValue: 8,
      id: "kpi3",
      operator: PerdooApiKpiGoalOperatorChoices.LessThanOrEqual,
      owner: "engineering",
      startValue: 15,
      unit: MetricUnit.Numerical,
    },
    {
      endValue: 8000,
      id: "kpi4",
      operator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
      owner: "sales",
      startValue: 9300,
      unit: MetricUnit.Usd,
    },
    {
      endValue: 5,
      id: "kpi5",
      operator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
      owner: "marketing",
      startValue: 4.7,
      unit: MetricUnit.Percentage,
    },
    {
      endValue: 50,
      id: "kpi6",
      operator: PerdooApiKpiGoalOperatorChoices.GreaterThanOrEqual,
      owner: "product",
      startValue: 55,
      unit: MetricUnit.Percentage,
    },
    {
      endValue: 7,
      id: "kpi7",
      operator: PerdooApiKpiGoalOperatorChoices.LessThanOrEqual,
      owner: "operations",
      startValue: 12,
      unit: MetricUnit.Numerical,
    },
  ],
  objectives: [
    {
      id: "objective1",
      keyResults: ["keyResult1", "keyResult2"],
      owner: "company",
    },
    {
      id: "objective2",
      keyResults: ["keyResult3", "keyResult4"],
      owner: "finance",
    },
    {
      id: "objective3",
      keyResults: ["keyResult5", "keyResult6"],
      owner: "engineering",
    },
    {
      id: "objective4",
      keyResults: ["keyResult7", "keyResult8"],
      owner: "sales",
    },
    {
      id: "objective5",
      keyResults: ["keyResult9", "keyResult10"],
      owner: "marketing",
    },
    {
      id: "objective6",
      keyResults: ["keyResult11", "keyResult12"],
      owner: "product",
    },
    {
      id: "objective7",
      keyResults: ["keyResult13", "keyResult14"],
      owner: "operations",
    },
    {
      id: "objective8",
      keyResults: ["keyResult15"],
      owner: "company",
    },
    {
      id: "objective9",
      keyResults: ["keyResult17", "keyResult16"],
      owner: "company",
    },
    {
      id: "objective10",
      keyResults: ["keyResult19", "keyResult18"],
      owner: "company",
    },
    {
      id: "objective11",
      keyResults: ["keyResult20"],
      owner: "company",
    },
    {
      id: "objective12",
      keyResults: ["keyResult22", "keyResult21"],
      owner: "company",
    },
    {
      id: "objective13",
      keyResults: ["keyResult23"],
      owner: "company",
    },
    {
      id: "objective14",
      keyResults: ["keyResult24"],
      owner: "company",
    },
    {
      id: "objective15",
      keyResults: ["keyResult26", "keyResult25"],
      owner: "company",
    },
    {
      id: "objective16",
      keyResults: ["keyResult28", "keyResult27"],
      owner: "company",
    },
    {
      id: "objective17",
      keyResults: ["keyResult30", "keyResult29"],
      owner: "company",
    },
    {
      id: "objective18",
      keyResults: ["keyResult32", "keyResult31"],
      owner: "marketing",
    },
    {
      id: "objective19",
      keyResults: ["keyResult33"],
      owner: "marketing",
    },
    {
      id: "objective20",
      keyResults: ["keyResult35", "keyResult34"],
      owner: "marketing",
    },
    {
      id: "objective21",
      keyResults: ["keyResult36"],
      owner: "marketing",
    },
    {
      id: "objective22",
      keyResults: ["keyResult38", "keyResult37"],
      owner: "marketing",
    },
    {
      id: "objective23",
      keyResults: ["keyResult39"],
      owner: "marketing",
    },
    {
      id: "objective24",
      keyResults: ["keyResult40"],
      owner: "marketing",
    },
    {
      id: "objective25",
      keyResults: ["keyResult41", "keyResult43", "keyResult42"],
      owner: "marketing",
    },
    {
      id: "objective26",
      keyResults: ["keyResult44"],
      owner: "marketing",
    },
    {
      id: "objective27",
      keyResults: ["keyResult46", "keyResult45"],
      owner: "marketing",
    },
    {
      id: "objective28",
      keyResults: ["keyResult48", "keyResult47"],
      owner: "marketing",
    },
    {
      id: "objective29",
      keyResults: ["keyResult50", "keyResult49"],
      owner: "sales",
    },
    {
      id: "objective30",
      keyResults: ["keyResult52", "keyResult51"],
      owner: "sales",
    },
    {
      id: "objective31",
      keyResults: ["keyResult53"],
      owner: "sales",
    },
    {
      id: "objective32",
      keyResults: ["keyResult54"],
      owner: "sales",
    },
    {
      id: "objective33",
      keyResults: ["keyResult56", "keyResult55"],
      owner: "sales",
    },
    {
      id: "objective34",
      keyResults: ["keyResult57"],
      owner: "sales",
    },
    {
      id: "objective35",
      keyResults: ["keyResult59", "keyResult58"],
      owner: "sales",
    },
    {
      id: "objective36",
      keyResults: ["keyResult60"],
      owner: "sales",
    },
    {
      id: "objective37",
      keyResults: ["keyResult62", "keyResult61"],
      owner: "sales",
    },
    {
      id: "objective38",
      keyResults: ["keyResult63"],
      owner: "sales",
    },
    {
      id: "objective39",
      keyResults: ["keyResult64", "keyResult66", "keyResult65"],
      owner: "engineering",
    },
    {
      id: "objective40",
      keyResults: ["keyResult68", "keyResult67"],
      owner: "engineering",
    },
    {
      id: "objective41",
      keyResults: ["keyResult69"],
      owner: "engineering",
    },
    {
      id: "objective42",
      keyResults: ["keyResult71", "keyResult70"],
      owner: "engineering",
    },
    {
      id: "objective43",
      keyResults: ["keyResult72"],
      owner: "engineering",
    },
    {
      id: "objective44",
      keyResults: ["keyResult74", "keyResult73"],
      owner: "product",
    },
    {
      id: "objective45",
      keyResults: ["keyResult76", "keyResult75"],
      owner: "product",
    },
    {
      id: "objective46",
      keyResults: ["keyResult77"],
      owner: "product",
    },
    {
      id: "objective47",
      keyResults: ["keyResult78"],
      owner: "finance",
    },
    {
      id: "objective48",
      keyResults: ["keyResult79"],
      owner: "finance",
    },
    {
      id: "objective49",
      keyResults: ["keyResult81", "keyResult80"],
      owner: "finance",
    },
    {
      id: "objective50",
      keyResults: ["keyResult82"],
      owner: "operations",
    },
    {
      id: "objective51",
      keyResults: ["keyResult83"],
      owner: "operations",
    },
  ],
};

export const onboardingData = {
  kpis: {
    company: ["kpi1"],
    engineering: ["kpi3"],
    finance: ["kpi2"],
    marketing: ["kpi5"],
    operations: ["kpi7"],
    product: ["kpi6"],
    sales: ["kpi4"],
  },
  objectives: {
    company: ["objective1"],
    engineering: ["objective3"],
    finance: ["objective2"],
    marketing: ["objective5"],
    operations: ["objective7"],
    product: ["objective6"],
    sales: ["objective4"],
  },
};
