import { isEmpty } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { SelectOption } from "common/inputs/Select/SelectOption/SelectOption";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { isValidEmail } from "utils/isValidEmail";
import { NEW_USER_ID } from "./useAddUser";
import { SelectUser } from "./UserSelect";

type Props = {
  query: string;
  visibleUsers: SelectUser[];
};

export const UserSelectFallback = ({
  query,
  visibleUsers,
}: Props): JSX.Element | null => {
  const permissions = usePermissions();
  const emailAlreadyExists = visibleUsers.some((user) => user.email === query);
  if (
    isValidEmail(query) &&
    !emailAlreadyExists &&
    permissions.canCreateUsers
  ) {
    const newUser = {
      __typename: "user",
      avatar: null,
      fullName: query,
      id: NEW_USER_ID,
    };
    return (
      <SelectOption value={newUser}>
        <FormattedMessage
          defaultMessage="Invite {email} to Perdoo"
          id="Lr87n4"
          values={{ email: query }}
        />
      </SelectOption>
    );
  }

  if (!isEmpty(visibleUsers)) {
    return null;
  }

  if (isEmpty(query) || !permissions.canCreateUsers) {
    return <SelectFallback />;
  }

  return (
    <SelectFallback>
      <FormattedMessage
        defaultMessage="This person hasn't been invited yet. Enter their email address to invite them now."
        id="forms:invite:user"
      />
    </SelectFallback>
  );
};
