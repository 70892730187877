import pluralize from "pluralize";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons";
import { Clickable } from "common/navigation/Clickable";
import { AllMetrics } from "constants/metric";
import { twClass } from "utils/twClass";
import { COACHING_DURATION, COACHING_PRICES } from "../../../../constants";
import { CoachingTypes, SupportedCurrencies } from "../../UpgradeModal.types";

interface Props {
  currency: SupportedCurrencies;
  onClear: () => void;
  onSelect: (selected: CoachingTypes) => void;
  selected?: CoachingTypes;
}

const popularPlan = "small";

export const CoachingPlanSelect: React.FC<Props> = ({
  onSelect,
  onClear,
  selected,
  currency,
}) => {
  const plans: CoachingTypes[] = ["one_hour", "small", "medium", "large"];

  const renderPlan = (plan: CoachingTypes) => {
    if (plan === "no_coaching") return null;

    return (
      <Clickable
        data-cy="TsyEGy1dGOFx27RD4L735"
        key={plan}
        className={twClass(
          "hover:slate-300 group mb-4 mt-0 flex h-20 w-full items-center justify-between rounded p-6 ring-1 ring-slate-300 bg-white hover:ring-2",
          {
            "ring-2 ring-blue-500": selected === plan,
          }
        )}
        onClick={() => (selected === plan ? onClear() : onSelect(plan))}
      >
        <div className="items-center lg:flex lg:space-x-3">
          <h3 className="text-base font-semibold text-slate-900 group-hover:text-blue-500">
            {COACHING_DURATION[plan]}{" "}
            {pluralize("hour", COACHING_DURATION[plan])}
          </h3>
          {plan === popularPlan && (
            <div className="mt-1 rounded bg-gradient-to-r from-yellow-400 to-yellow-500 px-1 text-xxs font-semibold text-yellow-900 lg:px-2 lg:py-1 lg:text-xs">
              <FormattedMessage
                defaultMessage="Most popular"
                id="upgrade:modal:most:popular"
              />
            </div>
          )}
        </div>
        <div className="flex items-center space-x-10">
          <p className="w-10 text-slate-900">
            {AllMetrics[currency].symbol}
            {+COACHING_PRICES[plan][currency] / COACHING_DURATION[plan]}/h
          </p>
          <p className="w-6 text-slate-900">
            {AllMetrics[currency].symbol}
            {COACHING_PRICES[plan][currency]}
          </p>

          {selected === plan ? (
            <button
              data-cy="4ZNQFlRDF71_gNqQoX_EX"
              className="flex w-20 justify-center rounded border-none py-1 text-sm font-normal text-blue-500  bg-blue-50 hover:text-blue-500 hover:bg-blue-100 focus:outline-none"
              onClick={onClear}
              type="button"
            >
              <div className="flex items-center">
                <Icon name="close" size="sm" />
                <FormattedMessage
                  defaultMessage="Remove"
                  id="upgrade:modal:remove"
                />
              </div>
            </button>
          ) : (
            <button
              data-cy="AKiMVIJYtRtrLHkDm8rOX"
              className="w-20 rounded border py-1 text-sm bg-white border-slate-200 text-blue-500 hover:bg-white hover:border-slate-300 hover:text-blue-500 focus:outline-none group-hover:bg-slate-100"
              onClick={() => onSelect(plan)}
              type="button"
            >
              <FormattedMessage defaultMessage="Add" id="upgrade:modal:add" />
            </button>
          )}
        </div>
      </Clickable>
    );
  };

  return <div className="mb-8">{plans.map((plan) => renderPlan(plan))}</div>;
};
