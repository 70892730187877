import { namedOperations } from "@graphql";
import { apolloClient } from "apolloClient";
import omit from "lodash/omit";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { roles } from "constants/roles";
import { UPSERT_USER_MUTATION } from "legacy/actions/actions";
import { getPrimaryGqlError } from "utils/forms";

const messages = defineMessages({
  inviteUser: {
    defaultMessage:
      "This person hasn't been invited yet. Enter their email address to invite them now.",
    id: "forms:invite:user",
  },
});

const handleAsyncError = (
  formErrors: Record<string, string>,
  error: string,
  fieldName: string
) => {
  if (error) {
    return {
      ...formErrors,
      [fieldName]: error,
    };
  }

  return {
    ...formErrors,
    [fieldName]: "Unexpected error has occurred",
  };
};

const handleSuccess = (formErrors: Record<string, string>, fieldName: string) =>
  omit(formErrors, fieldName);

const onCreateOption =
  (
    formErrors: Record<string, string>,
    onSuccess: (fieldName: string) => void,
    onError: (error: string, fieldName: string) => void
  ) =>
  async (email: string, fieldName: string) => {
    if (formErrors[fieldName]) {
      onSuccess(fieldName);
    }

    const response = await apolloClient.mutate({
      mutation: UPSERT_USER_MUTATION,
      refetchQueries: [namedOperations.Query.getCurrentUserInfo],
      variables: {
        input: {
          email,
          invite: true,
          role: roles.standard,
        },
      },
    });

    const { user, errors } = response.data.upsertUser;

    if (errors.length) {
      onError(getPrimaryGqlError(errors), fieldName);
      return {};
    }

    const { id, ...userProps } = user;

    return {
      data: {
        attributes: { ...userProps },
        id,
      },
    };
  };

type Hook = {
  formErrors: Record<string, string>;
  onCreateOption: (
    email: string,
    fieldName: string
  ) => Promise<{
    data?: {
      id: string;
    } & Record<string, unknown>;
  }>;
  onOptionsMessage: () => string;
};

/**
 * @deprecated rewrite component to use {@link UserSelect} with `creatable` prop instead.
 */
export const useUserCreation = (): Hook => {
  const intl = useIntl();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  return {
    formErrors,
    onCreateOption: onCreateOption(
      formErrors,
      (fieldName) => setFormErrors(handleSuccess(formErrors, fieldName)),
      (error, fieldName) =>
        setFormErrors(handleAsyncError(formErrors, error, fieldName))
    ),
    onOptionsMessage: () => intl.formatMessage(messages.inviteUser),
  };
};
