import dayjs from "dayjs";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { twMerge } from "utils/twMerge";
import { formatPdDate } from "../../../utils/utils";
import { Subtext } from "../Subtext/Subtext";

const messages = defineMessages({
  tooltip: {
    defaultMessage: "Overdue",
    id: "goal:overdue",
  },
});

interface Props {
  className?: string;
  date: string;
}

export const DueDate = ({ className, date }: Props): JSX.Element => {
  const isOverdue = dayjs(date).isBefore(new Date());
  const intl = useIntl();

  if (isOverdue) {
    return (
      <Tooltip
        content={intl.formatMessage(messages.tooltip)}
        spanClass={twMerge(
          "text-sm flex items-center gap-1 text-red-500 truncate max-w-xs leading-8 hidden md:flex",
          className
        )}
      >
        <>
          <Icon name="timer" size="lg" type="outlined" />
          {formatPdDate(intl, date)}
        </>
      </Tooltip>
    );
  }

  return <Subtext icon="timer" text={formatPdDate(intl, date)} />;
};
