import { Company, Group } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isEmpty, isString } from "lodash";
import { useCompany } from "hooks/useCompany/useCompany";
import { mapEdges } from "utils/mapEdges";

/**
 * Helper function to convert the selected owner to array of string.
 * Also checks if the company is selected and set the isCompanyGoal to true
 */

export const getOwnershipInfo = (
  group: string | string[],
  companyId = ""
): { groups: string[]; isCompanyGoal: boolean } => {
  const groups = isString(group) ? [group] : group;
  return {
    groups: groups.filter((item: string) => item !== companyId),
    isCompanyGoal: groups.includes(companyId) || isEmpty(groups),
  };
};

type SharedOwnerCompany = Derive<
  Company,
  { __typename: true; avatar: true; id: true; name: true }
>;

type SharedOwnerGroup = Derive<
  Group,
  { __typename: true; avatar: true; id: true; name: true }
>;

type SharedOwner = SharedOwnerCompany | SharedOwnerGroup;

type GetSharedOwnerGoal = {
  company: SharedOwnerCompany;
  groups?: NodeConnection<SharedOwnerGroup>;
  isCompanyGoal: boolean;
};

export const useSharedOwners = (
  isCompanyGoal: boolean,
  groups?: NodeConnection<SharedOwnerGroup>
): SharedOwner[] => {
  const company = useCompany();
  return getSharedOwners({
    company: { ...company, __typename: "company", id: company.id ?? "" },
    groups,
    isCompanyGoal,
  });
};

export const getSharedOwners = ({
  isCompanyGoal,
  company,
  groups,
}: GetSharedOwnerGoal): SharedOwner[] => {
  let sharedOwners: SharedOwner[] = [];
  if (groups) sharedOwners = mapEdges(groups.edges);
  if (isCompanyGoal) sharedOwners.unshift(company);

  return sharedOwners;
};
