import { camelCase, isEmpty, mapKeys } from "lodash";
import { useReducer } from "react";
import { TableSetting } from "common/table/hooks/useStoredTableSettings/useStoredTableSettings";
import {
  initialState as defaultState,
  reducer,
} from "legacy/utils/useFilters/useFilters.reducer";
import { getFiltersPath } from "legacy/utils/useFilters/useFilters.selectors";

// dear developer,
// if you're thinking of fixing the type issues in this hook, be warned. the level of complexity increases
// exponentially. good luck!

/**
 * @deprecated use {@link useFilters} (wip) instead
 * @param type
 * @param onChange
 */
export const useFilters__deprecated = (savedState?: TableSetting) => {
  const initialState =
    savedState && !isEmpty(savedState) ? savedState : defaultState;
  const [state, dispatch] = useReducer(reducer, initialState);
  const filterAsUrlParams = getFiltersPath(state);

  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  const filterAsGqlParams = mapKeys(filterAsUrlParams, (val, key) =>
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    camelCase(key)
  );

  return [state, dispatch, filterAsUrlParams, filterAsGqlParams];
};
