import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { ConfirmationModalProps } from "../ConfirmationModal";

type HookModalProps = Omit<ConfirmationModalProps, "close"> &
  Partial<Pick<ConfirmationModalProps, "close">>;

type Hook = {
  closeModal: () => void;
  modalProps: ConfirmationModalProps | null;
  openModal: (props: HookModalProps) => void;
};

const initialState: Hook = {
  closeModal: () => {},
  modalProps: null,
  openModal: () => {},
};

export const useConfirmationModal = singletonHook(initialState, (): Hook => {
  const [modalProps, setModalProps] = useState<HookModalProps | null>(null);
  const openModal = (props: HookModalProps) => setModalProps(props);
  const closeModal = () => setModalProps(null);

  const overwrittenModalProps: ConfirmationModalProps | null = modalProps
    ? {
        ...modalProps,
        close: () => {
          modalProps.close?.();
          closeModal();
        },
      }
    : null;

  return { closeModal, modalProps: overwrittenModalProps, openModal };
});
