import { PerdooApiIntegrationApplicationChoices } from "@graphql";
import * as Yup from "yup";

export const schema = (
  application: PerdooApiIntegrationApplicationChoices | null
): Yup.Schema =>
  Yup.object().shape({
    asanaProgressType:
      application === PerdooApiIntegrationApplicationChoices.Asana
        ? Yup.string().required("Required")
        : Yup.string(),
    asanaProject:
      application === PerdooApiIntegrationApplicationChoices.Asana
        ? Yup.string().required("Required")
        : Yup.string(),
    asanaTask:
      application === PerdooApiIntegrationApplicationChoices.Asana
        ? Yup.string().when("asanaProgressType", {
            is: "task",
            then: (s) => s.required("Required"),
          })
        : Yup.string(),
    asanaWorkspace:
      application === PerdooApiIntegrationApplicationChoices.Asana
        ? Yup.string().required("Required")
        : Yup.string(),
    endValue: Yup.number()
      .typeError("End Value must be a number")
      .required("Required"),
    integration: Yup.string().nullable(),
    jiraIssue:
      application === PerdooApiIntegrationApplicationChoices.Jira
        ? Yup.string().when("jiraSourceType", {
            is: "issue",
            then: (s) => s.required("Required"),
          })
        : Yup.string(),
    jiraJql:
      application === PerdooApiIntegrationApplicationChoices.Jira
        ? Yup.string().when("jiraSourceType", {
            is: "jql",
            then: (s) => s.required("Required"),
          })
        : Yup.string(),
    jiraProgressType:
      application === PerdooApiIntegrationApplicationChoices.Jira
        ? Yup.string().when("jiraSourceType", {
            is: "issue",
            then: (s) => s.required("Required"),
          })
        : Yup.string(),
    jiraSourceType:
      application === PerdooApiIntegrationApplicationChoices.Jira
        ? Yup.string().required("Required")
        : Yup.string(),
    lead: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    objective: Yup.string().required("Required"),
    startValue: Yup.number()
      .typeError("Start Value must be a number")
      .required("Required"),
    type: Yup.string().required("Required"),
  });
