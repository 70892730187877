import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { Feature } from "common/access/types";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { AddPerformanceReviewModal } from "common/performanceReview/modals/AddPerformanceReviewModal";
import { EditPerformanceReviewModal } from "common/performanceReview/modals/EditPerformanceReviewModal";
import { PerformanceReviewCompleteFormModal } from "common/performanceReview/modals/PerformanceReviewCompleteFormModal";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { PerformanceReviewModalType } from "hooks/useModalRouter/useModalRouter";
import { partialIdentifyUser } from "utils/tracker";

export const PerformanceReviewModalBase = (): JSX.Element | null => {
  const { query } = useRouter();
  const me = useCurrentUser();

  useEffect(() => {
    if (me?.id) partialIdentifyUser(me.id, { hasCreatedReviews: true });
  }, [me?.id]);

  const { show: showPerformanceReviews } = useFeatureAccess(
    Feature.performanceReviews
  );
  // TODO: `type` is too generic, make it connected to PRs only
  const { revieweeIds, type, prId } = query;

  if (!me) {
    return null;
  }
  const reviewerId = query.reviewerId ?? me.id;

  if (showPerformanceReviews === undefined || !showPerformanceReviews) {
    return null;
  }

  if (type === PerformanceReviewModalType.ADD) {
    return (
      <AddPerformanceReviewModal
        revieweeIds={revieweeIds}
        reviewerId={reviewerId as string}
      />
    );
  }
  if (type === PerformanceReviewModalType.EDIT && prId) {
    return <EditPerformanceReviewModal performanceReviewId={prId as string} />;
  }

  if (type === PerformanceReviewModalType.SUBMIT && prId) {
    return (
      <PerformanceReviewCompleteFormModal
        performanceReviewId={prId as string}
      />
    );
  }

  return null;
};
