import { FormikHelpers } from "formik";
import React from "react";
import { useAddComment } from "common/event/TimelineEvent/hooks/useAddComment/useAddComment";
import { CommentForm, CommentFormValues } from "../CommentForm/CommentForm";

type Props = {
  afterSubmit: () => void;
  entityId: string;
  entityType: string;
  filter: unknown;
};

export const NoteForm = ({
  afterSubmit,
  entityId,
  entityType,
  filter,
}: Props): JSX.Element => {
  const addComment = useAddComment();

  const onSubmit = (
    values: CommentFormValues,
    actions: FormikHelpers<CommentFormValues>
  ) => {
    return addComment({
      content: values.content,
      entityId,
      entityType,
      filter,
    })
      .then(() => {
        actions.setSubmitting(false);
        afterSubmit();
      })
      .catch(() => {
        actions.setSubmitting(false);
      });
  };

  return <CommentForm clearOnSubmit onSubmit={onSubmit} />;
};
