import { useRouter } from "next/router";
import React, { ReactElement, ReactNode, useContext } from "react";
import { Badge } from "common/avatar";
import { PremiumBadge } from "common/inAppUpgrade/PremiumBadge/PremiumBadge";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";
import { AnchorNext } from "../../../navigation/AnchorNext/AnchorNext";
import { CollapsibleContext } from "../../../navigation/CollapsibleContext/CollapsibleContext";

type Props = {
  active?: boolean;
  beta?: boolean;
  className?: string;
  "data-cy"?: string;
  icon?: ReactElement;
  label: ReactNode;
  link?: string;
  premium?: boolean;
  rhs?: ReactNode;
};

export const SidebarLink = ({
  label,
  "data-cy": dataCy,
  active = false,
  className,
  icon,
  link,
  beta = false,
  premium = false,
  rhs,
}: Props): JSX.Element => {
  const { asPath } = useRouter();
  const { isCollapsed } = useContext(CollapsibleContext);

  const tooltip = isCollapsed ? (
    <div className="flex items-center space-x-1">
      <div>{label}</div>
      {premium && <PremiumBadge size="small" />}
    </div>
  ) : null;

  return (
    <div>
      <WithTooltip className="block" placement="right" tooltip={tooltip}>
        <AnchorNext href={link ?? asPath}>
          <div
            className={twClass(
              "h-10 border-l-4 border-transparent text-slate-300",
              "pr-1 hover:text-white group",
              {
                "bg-slate-700 border-blue-500 hover:bg-slate-700": active,
                "dark:hover:text-slate-400": !active,
              },
              className
            )}
            data-cy={dataCy}
          >
            <div
              className={twClass(
                "flex h-full w-full items-center justify-between space-x-2 px-2.5",
                {
                  "justify-center": isCollapsed,
                }
              )}
            >
              <div
                className={twClass(
                  "flex items-center space-x-2 text-sm font-medium leading-normal",
                  {
                    "overflow-x-hidden": !isCollapsed,
                  }
                )}
              >
                <div className="relative flex justify-center">
                  {icon}
                  {isCollapsed && beta && (
                    <Badge
                      className="absolute left-2 top-2 px-0.5 text-xxs bg-slate-800"
                      text="Beta"
                    />
                  )}
                </div>
                {!isCollapsed && (
                  <div className="overflow-x-hidden text-ellipsis whitespace-nowrap">
                    {label}
                  </div>
                )}
              </div>
              {!isCollapsed && (beta || premium || rhs) && (
                <div className="flex items-center space-x-2">
                  {rhs && <div>{rhs}</div>}
                  {beta && (
                    <Badge
                      className={
                        "flex bg-slate-800 dark:bg-gray-800 dark:group-hover:text-slate-400"
                      }
                      text="Beta"
                    />
                  )}
                  {premium && <PremiumBadge size="small" />}
                </div>
              )}
            </div>
          </div>
        </AnchorNext>
      </WithTooltip>
    </div>
  );
};
