import {
  PerdooApiIntegrationApplicationChoices,
  UpsertResultMutationInput,
} from "@graphql";
import { assign, isNil, pick } from "lodash";
import { ResultFormValues } from "common/result/ResultForm/ResultForm.types";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { ResultProps } from "../Result/Result";
import {
  asanaDataToIntegrationField,
  integrationFieldToAsanaData,
} from "../utils/asana";
import {
  integrationFieldToJiraData,
  jiraDataToIntegrationField,
} from "../utils/jira";

export const valuesToInput = (
  values: ResultFormValues
): UpsertResultMutationInput => {
  let input = pick(values, [
    "id",
    "name",
    "description",
    "lead",
    "objective",
    "metricUnit",
    "startValue",
    "endValue",
    "type",
    "parent",
    "contributors",
    "startDate",
    "dueDate",
    "integration",
    "status",
    "tags",
  ]);

  if (isSet(values.asanaProject) && isSet(values.asanaWorkspace)) {
    input = assign(input, {
      integrationField: asanaDataToIntegrationField({
        projectGid: values.asanaProject,
        taskGid: values.asanaTask ?? null,
        workspaceGid: values.asanaWorkspace,
      }),
    });
  }

  if (isSet(values.jiraIssue) && isSet(values.jiraProgressType)) {
    input = assign(input, {
      integrationField: jiraDataToIntegrationField({
        issueKey: values.jiraIssue,
        progressType: values.jiraProgressType,
      }),
    });
  }

  if (isSet(values.jiraJql)) {
    input = assign(input, {
      integrationField: jiraDataToIntegrationField({
        jql: values.jiraJql,
      }),
    });
  }

  if (isNil(values.integration)) {
    input = assign(input, { integration: null, integrationField: null });
  }

  return input;
};

export const initialValuesFromResult = (
  result: ResultProps
): ResultFormValues => {
  let initialValues: ResultFormValues = {
    ...pick(result, [
      "id",
      "name",
      "metricUnit",
      "startValue",
      "endValue",
      "type",
      "status",
    ]),
    asanaProgressType: "project",
    contributors: result.contributors.edges.map(({ node }) => node.id),
    description: result.description ?? "",
    dueDate: result.dueDate ?? undefined,
    integration: result.integration?.id,
    jiraSourceType: "jql",
    lead: result.lead?.id,
    objective: result.objective.id,
    parent: result.parent?.id,
    startDate: result.startDate ?? undefined,
    tags: mapEdges(result.tags.edges).map((tag) => tag.id),
  };

  switch (result.integration?.application) {
    case PerdooApiIntegrationApplicationChoices.Asana:
      if (isSet(result.integrationField)) {
        const data = integrationFieldToAsanaData(result.integrationField);
        initialValues = assign(initialValues, {
          asanaProgressType: isSet(data.taskGid) ? "task" : "project",
          asanaProject: data.projectGid,
          asanaTask: data.taskGid,
          asanaWorkspace: data.workspaceGid,
        });
      }
      break;
    case PerdooApiIntegrationApplicationChoices.Jira:
      if (isSet(result.integrationField)) {
        const data = integrationFieldToJiraData(result.integrationField);
        if ("issueKey" in data) {
          initialValues = assign(initialValues, {
            jiraIssue: data.issueKey,
            jiraProgressType: data.progressType,
            jiraSourceType: "issue",
          });
        } else {
          initialValues = assign(initialValues, {
            jiraJql: data.jql,
            jiraSourceType: "jql",
          });
        }
      }
      break;
    default:
      break;
  }

  return initialValues;
};
