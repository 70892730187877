import { namedOperations, useUpsertObjectiveMutationMutation } from "@graphql";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ShowFeature } from "common/access/ShowFeature";
import { ListItem } from "common/misc/ListItem/ListItem";
import { objectiveStages } from "common/objective/constants";
import {
  ProfileOkrListContext
} from "common/objective/context";
import { useCloseObjectiveModal } from "common/objective/modals/CloseObjectiveModal/hooks/useCloseObjectiveModal/useCloseObjectiveModal";
import { messages } from "common/objective/ObjectiveWrapper/ObjectiveActions/ObjectiveActions.messages";
import { useReopenObjectiveConfirmationModal } from "common/objective/ReopenObjectiveConfirmationModal";
import { ResultsFilterContext } from "common/result";
import { MODAL_TYPES } from "constants/index";
import { EDIT_OBJECTIVE } from "constants/tracking";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { useObjectiveActions } from "hooks/useObjectiveActions/useObjectiveActions";
import { showModal } from "legacy/actions/actions";
import { handleErrors } from "utils/graphql/handleErrors";
import { RefetchQueries } from "utils/graphql/types";
import { isSet } from "utils/isSet";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { DeleteObjectiveAction } from "./DeleteObjective/DeleteObjective";

interface Props {
  canUnalign?: boolean;
  objective: any;
  onChangeQueries: RefetchQueries;
  onDelete?: () => void;
}

export const ObjectiveActions = ({
  objective,
  onDelete,
  onChangeQueries,
  canUnalign = false,
}: Props): JSX.Element[] | null => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { objectiveHref } = useModalRouter();
  const { goToObjective } = useModalRouter();

  const objectiveLink = objectiveHref({ oId: objective.id });
  const { openModal: openReopenModal } = useReopenObjectiveConfirmationModal();

  const filters = useContext(ResultsFilterContext);

  const { reFetchQueries: profileOkrListQueries } = useContext(
    ProfileOkrListContext
  );


  const [updateObjective] = useUpsertObjectiveMutationMutation({
    refetchQueries: [namedOperations.Query.getObjective],
  });

  const { activateObjective } = useObjectiveActions({
    objective,
  });

  const { openCloseObjectiveModal } = useCloseObjectiveModal({
    refetchQueries: [...profileOkrListQueries],
  });

  if (!objective) return null;

  const { canPatch, canDelete, id, stage, name } = objective;
  const closed = stage === objectiveStages.closed;

  const openObjCloseModal = () =>
    openCloseObjectiveModal({
      objective,
    });

  const openObjEditModal = () => {
    goToObjective({ oId: objective.id });
    track(EDIT_OBJECTIVE);
  };

  const openObjCloneModal = () => {
    dispatch(
      showModal(MODAL_TYPES.CLONE_OBJECTIVE, {
        passedObjectiveId: id,
        refetchQueries: profileOkrListQueries,
      })
    );
  };

  const openAddResultModal = () =>
    dispatch(
      showModal(MODAL_TYPES.ADD_RESULT, {
        filters,
        objectiveId: id,
      })
    );

  const handleUnalign = async () => {
    const response = await updateObjective({
      variables: {
        input: {
          id: objective.id,
          parent: null,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertObjective?.errors
    );
    if (!hasError) {
      toast.success(intl.formatMessage(messages.objectiveUnaligned));
    }
  };

  const actions = [];

  actions.push(
    <ListItem key="details" href={objectiveLink}>
      <FormattedMessage defaultMessage="View details" id="global:viewDetails" />
    </ListItem>
  );

  if (!canPatch) return actions;

  actions.push(
    <ListItem
      key="edit"
      data-cy="f9ZQ9Or5bsglAsbefgETX"
      onClick={openObjEditModal}
    >
      <FormattedMessage defaultMessage="Edit Objective" id="objective:edit" />
    </ListItem>
  );

  if (closed) {
    actions.push(
      <ListItem
        key="reopen"
        data-cy="reopen-objective"
        onClick={() => openReopenModal({ objective })}
      >
        <FormattedMessage
          defaultMessage="Reopen Objective"
          id="objective:reopen"
        />
      </ListItem>
    );

    actions.push(
      <ListItem
        key="close"
        data-cy="hbHbzh-86qMPrIHHoDAmm"
        onClick={openObjCloseModal}
      >
        <FormattedMessage
          defaultMessage="Edit Closing Note"
          id="objective:edit:close"
        />
      </ListItem>
    );
  }

  if (!closed) {
    actions.push(
      <ListItem
        key="close"
        data-cy="DMT7MsxYuLtChYKj4ynJE"
        onClick={openObjCloseModal}
      >
        <FormattedMessage
          defaultMessage="Close Objective"
          id="objective:close"
        />
      </ListItem>
    );

    if (objective.stage === "DRAFT") {
      actions.push(
        <ListItem
          key="activate"
          data-cy="s7CazNkuYEXoARyU2j_DZ"
          onClick={activateObjective}
        >
          <FormattedMessage
            defaultMessage="Activate Objective"
            id="objective:activate"
          />
        </ListItem>
      );
    }

    actions.push(
      <ListItem
        key="addResult"
        data-cy="B0K0aOIMq7jaGo7rW46Cp"
        onClick={openAddResultModal}
      >
        <FormattedMessage defaultMessage="Add Result" id="result:add" />
      </ListItem>
    );
  }

  actions.push(
    <ShowFeature key="accessFeature" feature="MENU_CLONE_OBJECTIVE">
      <ListItem
        key="clone"
        data-cy="q3CyesSVhHk9mZoHnGZyS"
        onClick={openObjCloneModal}
      >
        <FormattedMessage
          defaultMessage="Clone Objective"
          id="objective:clone"
        />
      </ListItem>
    </ShowFeature>
  );

  if (canDelete) {
    actions.push(
      <DeleteObjectiveAction
        key="delete"
        id={id}
        name={name}
        onDelete={onDelete}
        onDeleteQueries={onChangeQueries}
      />
    );
  }

  if (isSet(objective.parent) && canUnalign) {
    actions.push(
      <ListItem
        key="unalign"
        data-cy="unalignObjective"
        onClick={handleUnalign}
      >
        <FormattedMessage
          defaultMessage="Unalign"
          id="objectives:modal:summary:unalign"
        />
      </ListItem>
    );
  }

  return actions;
};
