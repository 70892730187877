import { useMutation } from "@apollo/client";
import { namedOperations, useGetUserQuery } from "@graphql";
import { isNil, pick } from "lodash";
import React from "react";
import { FullPageSpinner } from "common";
import { Modal } from "common/overlay/Modal/Modal";
import { UserForm } from "common/user/UserForm/UserForm";
import { UPSERT_USER_MUTATION } from "legacy/actions/actions";
import { setFormikGqlErrors } from "utils/forms";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onRequestClosed' implicitly has a... Remove this comment to see the full error message
export const UserProfileEditModal = ({ onRequestClosed, userId, open }) => {
  const { data } = useGetUserQuery({
    // makes sure changes made on related users (direct reports, manager) are reflected.
    fetchPolicy: "cache-and-network",
    variables: { id: userId },
  });
  const user = data?.user;
  const [updateUser] = useMutation(UPSERT_USER_MUTATION);

  if (isNil(user)) return <FullPageSpinner />;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  const onSubmit = async (values, actions) => {
    return updateUser({
      refetchQueries: [
        namedOperations.Query.getSupervisees,
        namedOperations.Query.getCurrentUserInfo,
        namedOperations.Query.GetAllPerformanceReviews,
        namedOperations.Query.UserGroups,
      ],
      variables: {
        input: pick(values, [
          "password",
          "directReports",
          "email",
          "firstName",
          "groups",
          "id",
          "jobPosition",
          "lastName",
          "manager",
          "role",
        ]),
      },
    }).then((response) => {
      actions.setSubmitting(false);
      const { errors } = response.data.upsertUser;

      if (errors.length) {
        setFormikGqlErrors(actions, errors);
        return;
      }

      toast.success("Changes saved");
      onRequestClosed();
    });
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      showFooter={false}
      title="Edit User"
    >
      <UserForm
        initialValues={{
          ...user,
          directReports: mapEdges(user.directReports.edges).map((g) => g.id),
          groups: mapEdges(user.groups.edges).map((g) => g.id),
          manager: user.manager?.id,
        }}
        isEdit
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
