import { Status } from "common/charts/Status/Status";
import { Icon } from "common/icons/Icon/Icon";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { ReportKpi } from "common/progressReports/Kpi";
import { getKpiDelta } from "utils/getDelta/getDelta";
import { twClass } from "utils/twClass";

type Props = {
  className?: string;
  kpi: ReportKpi;
};

/**
 * goal values to display in the check-in form. value, status, delta.
 *
 * @param {object} props
 * @param {boolean} props.drafted means we have a new update drafted. then we additionally show delta, otherwise the text is greyed out.
 */
export const KpiValues = ({ className, kpi }: Props): JSX.Element | null => {
  const { delta, deltaColorClass, deltaIcon } = getKpiDelta({
    currentValue: kpi.delta.valueBefore,
    goalOperator: kpi.goalOperator,
    goalThreshold: kpi.goalThreshold,
    newValue: kpi.delta.value,
  });

  return (
    <div className={twClass("flex w-36 flex-col text-sm", className)}>
      <div className="value flex items-center gap-1">
        <div className="flex items-center gap-1">
          <span data-testid="valueElement">
            <FormattedMetric value={kpi.delta.value} unit={kpi.metricUnit} />
          </span>
        </div>
        <div
          className={`flex items-center ${deltaColorClass}`}
          id="deltaElement"
        >
          <Icon name={deltaIcon} size="xs" />
          <span>
            <FormattedMetric value={delta} unit={kpi.metricUnit} />
          </span>
        </div>
      </div>
      <Status status={kpi.delta.status} />
    </div>
  );
};
