import {
  GoalUpdateCycle,
  PerdooApiKpiGoalOperatorChoices,
  useGetGroupKpiCountQuery,
} from "@graphql";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { isArray, isEmpty, isNil, startCase } from "lodash";
import { useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { useOptionalProfileContext } from "common/context/profileContext";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { FormInput } from "common/form/FormInput/FormInput";
import { FormMetricSelect } from "common/form/FormMetricSelect/FormMetricSelect";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { SharedGroupField } from "common/form/SharedGroupsField";
import { UserField } from "common/form/UserField";
import { WithHelper } from "common/form/WithHelper/WithHelper";
import { getOwnershipInfo } from "common/goal/GoalOwners/GoalOwners.utils";
import { GoalSelectorButton } from "common/goal/GoalSelectorButton/GoalSelectorButton";
import { Label } from "common/inputs";
import {
  CategorySelect,
  CategorySelectItem,
} from "common/inputs/CategorySelect/CategorySelect";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { PermissionErrorMessage } from "common/overlay/PermissionErrorTooltip/PermissionErrorMessage/PermissionErrorMessage";
import { ProTipBanner } from "common/overlay/ProTipBanner/ProTipBanner";
import { KPI_LIMIT } from "constants/index";
import { getMetricSymbol, MetricValue } from "constants/metric";
import { objectTypes } from "constants/objects";
import { allRolesButViewonly, roles } from "constants/roles";
import { SELECT_EXAMPLE_GOAL } from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useSampleGoals } from "hooks/useSampleGoals/useSampleGoals";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { useUserCreation } from "legacy/utils/useUserCreation";
import { getRandomMessage } from "utils/getRandomMessage";
import { hasUserAccess } from "utils/permissions";
import { track } from "utils/tracker";
import { OperatorSwitch } from "../Targets/OperatorSwitch/OperatorSwitch";
import { AdvancedSection } from "./AdvancedSection/AdvancedSection";
import { helper } from "./KpiForm.helper";
import { messages } from "./KpiForm.messages";
import { namePlaceholders } from "./KpiForm.placeholders";

const schema = Yup.object().shape({
  description: Yup.string().nullable(),
  goalThreshold: Yup.number().typeError("The target value must be a number"),
  groups: Yup.lazy((val) =>
    (Array.isArray(val)
      ? Yup.array().of(Yup.string()).min(1, "Required")
      : Yup.string()
    )
      .required("Required")
      .typeError("Select Owner")
  ),
  lead: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
});

export type KpiFormValues = {
  currentValue: number;
  description?: string;
  goalOperator: PerdooApiKpiGoalOperatorChoices;
  goalThreshold: number | null;
  goalUpdateCycle?: GoalUpdateCycle;
  groups: string[] | string;
  id?: string;
  isCompanyGoal?: boolean;
  lastCommitDate?: string;
  lead?: string;
  metricUnit: MetricValue;
  name: string;
  private: boolean;
  strategicPillar?: string;
  tags?: string[];
  visibleTo?: string[];
};

interface Props {
  initialValues: KpiFormValues;
  onSubmit: (
    values: KpiFormValues & { groups: string[]; isCompanyGoal: boolean },
    actions: FormikHelpers<KpiFormValues>,
    selectedSampleResult?: CategorySelectItem
  ) => void;
  setAddAnother?: () => void;
}

export const KpiForm = ({
  initialValues,
  onSubmit,
  setAddAnother = () => {},
}: Props): JSX.Element => {
  const intl = useIntl();
  const company = useCompany();
  const currentUser = useCurrentUser();
  const { formErrors } = useUserCreation();
  const dropdownRef = useRef<Dropdown>(null);
  const isAdmin = hasUserAccess(currentUser, roles.admin);

  const [selectedSampleKPI, setSelectedSampleKPI] = useState<
    CategorySelectItem | undefined
  >(undefined);
  const currentGroup = useOptionalProfileContext();

  const placeholders = useMemo(
    () => ({
      name: getRandomMessage(namePlaceholders),
    }),
    []
  );

  const selectedGroupId = initialValues.groups;
  const { data } = useGetGroupKpiCountQuery({
    fetchPolicy: "cache-and-network",
    skip: isNil(selectedGroupId) || isArray(selectedGroupId),
    variables: {
      id: isArray(selectedGroupId) ? "" : selectedGroupId,
    },
  });

  const kpiCount = data?.group?.kpis.totalCount ?? 0;

  const [sampleGoalsByGroup, sampleGoalsFound] = useSampleGoals(
    "kpi",
    currentGroup?.name
  );

  const handleSubmit = (
    values: KpiFormValues,
    actions: FormikHelpers<KpiFormValues>
  ) => {
    const { groups, isCompanyGoal } = getOwnershipInfo(
      values.groups,
      company.id
    );
    onSubmit({ ...values, groups, isCompanyGoal }, actions, selectedSampleKPI);
  };

  const sampleGoalSelected = (
    setFieldValue: (field: string, value: unknown) => void,
    kpi: CategorySelectItem
  ) => {
    setSelectedSampleKPI(kpi);
    track(SELECT_EXAMPLE_GOAL, {
      title: kpi.name,
      type: objectTypes.kpi,
    });

    setFieldValue("name", kpi.name);
    setFieldValue("metricUnit", kpi.unit);
    setFieldValue("currentValue", kpi.startValue);
    setFieldValue("goalOperator", kpi.operator);
    setFieldValue("goalThreshold", kpi.endValue);

    dropdownRef.current?.close();
  };
  return (
    <>
      <Show when={data && kpiCount >= KPI_LIMIT}>
        <ProTipBanner>
          <FormattedMessage
            defaultMessage="To stay focused, try to not add more than 6 KPIs per group"
            id="banner:protip:tooManyKPIs"
          />
        </ProTipBanner>
      </Show>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(formikProps) => {
          const {
            values: { lastCommitDate, metricUnit },
            isSubmitting,
            setFieldValue,
            submitForm,
          } = formikProps;

          const goalSelector = (
            <Dropdown
              ref={dropdownRef}
              button={<GoalSelectorButton type={objectTypes.kpi} />}
              buttonOpen={
                <GoalSelectorButton
                  className="border border-blue-500"
                  type={objectTypes.kpi}
                />
              }
              forcePosition="bottom-left"
            >
              <CategorySelect
                categories={sampleGoalsByGroup}
                className="max-h-96 w-96"
                icon={{ name: "monitor_heart", type: "outlined" }}
                onSelect={(kpi) => {
                  sampleGoalSelected(setFieldValue, kpi);
                }}
                selectFirst={sampleGoalsFound}
                transform={startCase}
              />
            </Dropdown>
          );

          return (
            <Form data-cy="kpiForm">
              <WithHelper helper={helper.name}>
                <Field name="name">
                  {({ field, form }: FieldProps) => (
                    <FormInput
                      addOn={goalSelector}
                      addOnSeparate
                      autoFocus
                      data-cy="kpiName"
                      data-testid="kpiName"
                      field={field}
                      form={form}
                      label={intl.formatMessage(messages.nameLabel)}
                      placeholder={intl.formatMessage(placeholders.name)}
                    />
                  )}
                </Field>
              </WithHelper>

              <WithHelper helper={helper.description}>
                <Field name="description">
                  {({ field, form }: FieldProps) => (
                    <FormTextArea
                      data-testid="kpiDescription"
                      field={field}
                      form={form}
                      label={intl.formatMessage(messages.descriptionLabel)}
                      optional
                      placeholder={intl.formatMessage(
                        messages.descriptionPlaceholder
                      )}
                    />
                  )}
                </Field>
              </WithHelper>

              <WithHelper>
                <Field name="metricUnit">
                  {({ field }: FieldProps) => (
                    <FormMetricSelect
                      field={field}
                      isLeft
                      label={intl.formatMessage(messages.metricUnitsLabel)}
                      onChange={undefined}
                    />
                  )}
                </Field>
              </WithHelper>

              <WithHelper>
                <Field name="currentValue">
                  {({ field, form }: FieldProps) => (
                    <FormInput
                      addOn={getMetricSymbol(metricUnit)}
                      field={field}
                      form={form}
                      infoElement={
                        !!lastCommitDate && (
                          <div>
                            <FormattedMessage
                              defaultMessage="You can't change the current value of a KPI after creation."
                              id="kpi:forms:noChange"
                            />
                          </div>
                        )
                      }
                      isLeft
                      label={intl.formatMessage(messages.valueLabel)}
                      metricUnit={getMetricSymbol(metricUnit)}
                      placeholder={intl.formatMessage(
                        messages.valuePlaceholder
                      )}
                      readOnly={!!lastCommitDate}
                      type="number"
                    />
                  )}
                </Field>
              </WithHelper>
              <WithHelper>
                <div className="my-2">
                  <Label htmlFor="kpiTargetValue">
                    {intl.formatMessage(messages.targetValue)}
                  </Label>
                  <div className="flex space-x-2">
                    <OperatorSwitch
                      name="goalOperator"
                      setFieldValue={setFieldValue}
                    />
                    <div className="grow min-w-0">
                      <InputField
                        type="number"
                        suffix={getMetricSymbol(metricUnit)}
                        data-cy="kpi-target-input"
                        data-testid="kpiTargetInput"
                        formControl={false}
                        name="goalThreshold"
                      />
                    </div>
                  </div>
                </div>
              </WithHelper>

              <WithHelper data-testid="groups">
                <SharedGroupField
                  helperText={
                    !isAdmin && <PermissionErrorMessage variant="addGroup" />
                  }
                  isShared={
                    !isEmpty(initialValues.groups) &&
                    isArray(initialValues.groups)
                  }
                  label={intl.formatMessage({
                    defaultMessage: "Owner",
                    id: "kpis:list:ownersColumn",
                  })}
                  name="groups"
                />
              </WithHelper>

              <WithHelper data-testid="lead">
                <UserField
                  data-cy="fieldLead"
                  data-testid="fieldLead"
                  label={intl.formatMessage(messages.leadLabel)}
                  variables={{
                    roleIn: allRolesButViewonly.join(),
                  }}
                  name="lead"
                />
              </WithHelper>
              <Show when={formErrors.lead}>
                <div className="text-red-500">{formErrors.lead}</div>
              </Show>

              <AdvancedSection />

              <ModalFooter className="space-x-3">
                <Button
                  data-cy="addKpiButton"
                  data-testid="addKpiButton"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  {intl.formatMessage({
                    defaultMessage: "Save",
                    id: "global:save",
                  })}
                </Button>

                <Button
                  data-cy="submitKpiButton"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={() => {
                    setAddAnother();
                    submitForm();
                  }}
                  type="submit"
                  variant="ghost"
                >
                  {intl.formatMessage({
                    defaultMessage: "Add another",
                    id: "global:add:another",
                  })}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
