import { User } from "@graphql";
import { Derive } from "@shoooe/derive";
import { get } from "lodash";
import { getLinkByType } from "route-configs";
import { Entity } from "common/avatar";
import { For } from "common/controlFlow";
import { Modal } from "common/overlay/Modal/Modal";
import { objectTypes } from "constants/objects";
import { useInTeams } from "hooks/useInTeams/useInTeams";

type PrivateUser = Derive<
  User,
  {
    avatar: true;
    fullName: true;
    id: true;
  }
>;

type Props = {
  onRequestClosed?: () => void;
  open?: boolean;
  title?: string;
  topArea?: () => JSX.Element;
  users: PrivateUser[];
};

export const UserListModal = ({
  onRequestClosed,
  open,
  title,
  topArea,
  users,
}: Props): JSX.Element => {
  const inTeams = useInTeams();

  return (
    <Modal
      size="sm"
      backdropClose={false}
      isOpen={open ?? false}
      onClose={onRequestClosed}
      title={title}
    >
      <div className="my-3">
        {topArea && topArea()}
        <For each={users}>
          {(user) => (
            <Entity
              className="border-b border-slate-300 h-14"
              avatar={get(user, "attributes.avatar", user.avatar)}
              href={
                inTeams ? undefined : getLinkByType(objectTypes.user, user.id)
              }
              name={get(user, "attributes.fullName", user.fullName)}
            />
          )}
        </For>
      </div>
    </Modal>
  );
};
