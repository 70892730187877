import { Form, Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { TextButton } from "common/buttons/TextButton/TextButton";
import { InputField } from "common/fields/InputField/InputField";
import { Panel } from "common/overlay/Panel/Panel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useToggle } from "hooks/useToggle/useToggle";

const validationSchema = yup.object().shape({
  url: yup.string().required().url(),
});

interface Props {
  onSubmit: (url: string) => void;
}

interface FormValues {
  url: string;
}

export const ToolBarImage = ({ onSubmit }: Props) => {
  const intl = useIntl();
  const [tooltipOpen, toggleTooltipOpen] = useToggle(false);
  const handleSubmit = (values: FormValues) => {
    onSubmit(values.url);
    toggleTooltipOpen();
  };

  return (
    <WithTooltip
      className="overflow-hidden"
      interactive
      maxWidth=""
      placement="bottom"
      theme="perdoo-white"
      tooltip={
        tooltipOpen && (
          <Panel onClose={toggleTooltipOpen} className="p-2" id="image-picker">
            <Formik
              initialValues={{ url: "" }}
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={validationSchema}
              validateOnBlur={false}
            >
              {(formik) => (
                <Form
                  className="flex flex-row items-center justify-start"
                  onSubmit={(e) => {
                    e.stopPropagation();
                    formik.handleSubmit(e);
                  }}
                >
                  <InputField
                    autoFocus
                    id="url"
                    name="url"
                    className="w-[200px]"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Image URL",
                      id: "B9mB91",
                    })}
                  />

                  <TextButton
                    data-cy="texteditor-toolbar-submit-image"
                    className="ml-2"
                    text="Add"
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </Panel>
        )
      }
      visible
    >
      <IconButton
        data-cy="vEnyJdHb7mHBdapX0yavE"
        name="image"
        size="2xl"
        onClick={toggleTooltipOpen}
      />
    </WithTooltip>
  );
};
