import styled from "styled-components";

interface MarginProps {
  bottom?: number;
  display?: "string";
  left?: number;
  right?: number;
  top?: number;
}

/**
 * @deprecated use tailwind instead
 * @example
 * <div className="mt-4">
 */
export const Margin = styled.div<MarginProps>`
  margin-top: ${({ top }) => top && `${top}px`};
  margin-left: ${({ left }) => left && `${left}px`};
  margin-right: ${({ right }) => right && `${right}px`};
  margin-bottom: ${({ bottom }) => bottom && `${bottom}px`};
  display: ${({ display }) => display};
`;
