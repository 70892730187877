import { MetricUnit } from "@graphql";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { For } from "common/controlFlow";
import {
  FrequentCurrencies,
  FrequentMetrics,
  OtherCurrencies,
} from "constants/metric";
import { searchBy } from "utils/searchBy/searchBy";
import { makeSelect } from "../Select/Select";

type Value = MetricUnit;

type Option = {
  id: MetricUnit;
  name: string;
  symbol: string;
};

type OptionsGroup = {
  id: string;
  options: Option[];
};

const Select = makeSelect<Option>();

const groupedOptions: OptionsGroup[] = [
  {
    id: "frequentMetrics",
    options: [...Object.values(FrequentMetrics)].map((opt) => ({
      id: opt.abbreviation as MetricUnit,
      name: opt.name,
      symbol: opt.symbol,
    })),
  },
  {
    id: "frequentCurrencies",
    options: [...Object.values(FrequentCurrencies)].map((opt) => ({
      id: opt.abbreviation as MetricUnit,
      name: opt.name,
      symbol: opt.symbol,
    })),
  },
  {
    id: "otherCurrencies",
    options: [...Object.values(OtherCurrencies)].map((opt) => ({
      id: opt.abbreviation as MetricUnit,
      name: opt.name,
      symbol: opt.symbol,
    })),
  },
];

const options = groupedOptions.flatMap((group) => group.options);

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  onChange?: (value: Value) => Promise<boolean>;
  value: Value;
};

export const InlineMetricUnitSelect = ({
  className,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  onChange,
  value,
  disabled,
}: Props) => {
  const defaultItem = options.find((opt) => opt.id === value)!;
  const [selected, setSelected] = useState(defaultItem);
  const [query, setQuery] = useState("");

  const visibleGroups = groupedOptions
    .map((group) => ({
      ...group,
      options: searchBy(group.options, ["name", "symbol"], query),
    }))
    .filter((group) => !isEmpty(group.options));

  const select = (option: Option) => {
    setSelected(option);
    setQuery("");
  };

  const handleChange = async (option: Option | null) => {
    if (!option) return;
    const success = await onChange?.(option.id);
    if (success) select(option);
  };

  return (
    <Select.Root
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          <div className="flex items-center space-x-2">
            <div>{selected.symbol}</div>
            <div>{selected.name}</div>
          </div>
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown className="w-20 divide-y">
        <Select.Searchbox onChange={setQuery} />
        <Select.Options className="divide-y">
          <For each={visibleGroups}>
            {(group) => (
              <div>
                <For each={group.options}>
                  {(option) => (
                    <Select.Option value={option}>
                      <div className="flex items-center space-x-2">
                        <div className="w-10">{option.symbol}</div>
                        <div>{option.name}</div>
                      </div>
                    </Select.Option>
                  )}
                </For>
              </div>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
