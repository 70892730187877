import { isNil } from "lodash";
import React, { CSSProperties, MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import { AnchorNext } from "common/navigation";
import { twClass } from "utils/twClass";

type StyledSpanProps = {
  disabled?: boolean;
};

const StyledSpan = styled.span<StyledSpanProps>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export interface AnchorProps {
  children?: ReactNode;
  className?: string;
  "data-cy"?: string;
  "data-fs"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  href?: string;
  id?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  rel?: string;
  style?: CSSProperties;
  target?: string;
  text?: ReactNode;
  type?: string;
}

/**
 * @deprecated if you need an anchor, use {@link AnchorNext} which is a light wrapper around HTML `<a>` element and can be
 * styled using tailwind. <br />
 * 🎨 if you need a span that looks like an anchor, then use a `<span>` or extract a common component. <br />
 * 🔘 if you are trying to pass onClick, use a button and make it look like a link via styling. <br />
 * ⛓ if you need to link to an external page, use a regular `<a>` element, because we're using next `<Link>` under the hook.
 * <br />
 * note that this one doesn't support forwardRef.
 */
export const Anchor = ({
  children,
  className,
  "data-cy": dataCy,
  "data-fs": dataFs,
  "data-testid": dataTestId,
  href,
  id,
  disabled,
  onClick,
  text = "",
  style,
  type,
  rel,
  target,
}: AnchorProps): JSX.Element => {
  if (href && isNil(onClick)) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <AnchorNext
        className={twClass(
          {
            "cursor-not-allowed text-slate-400": disabled,
            "text-blue-500 hover:text-indigo-800": !disabled,
          },
          className
        )}
        data-cy={dataCy}
        data-fs={dataFs}
        data-testid={dataTestId}
        href={disabled ? undefined : href}
        id={id}
        onClick={disabled ? undefined : onClick}
        rel={rel}
        style={style}
        target={target}
        type={type}
      >
        {text}
        {children}
      </AnchorNext>
    );
  }

  return (
    <StyledSpan
      className={twClass(
        {
          "cursor-not-allowed text-slate-400": disabled,
          "text-blue-500 hover:text-indigo-800": !disabled,
        },
        className
      )}
      data-cy={dataCy}
      data-fs={dataFs}
      data-testid={dataTestId}
      disabled={disabled}
      id={id}
      onClick={disabled ? undefined : onClick}
    >
      {text}
      {children}
    </StyledSpan>
  );
};
