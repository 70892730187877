import { MetricUnits } from "constants/metric";
import { FeatureTier } from "hooks/useCompany/useCompany";
import {
  BillingPeriod,
  CoachingPrice,
  CoachingTypes,
  CoachingValueType,
  SupportedCurrencies,
} from "./modals/UpgradeModal/UpgradeModal.types";

export const COACHING_PRICES = {
  large: { [MetricUnits.EUR]: 7200, [MetricUnits.USD]: 8640 },
  medium: { [MetricUnits.EUR]: 4320, [MetricUnits.USD]: 5280 },
  no_coaching: { [MetricUnits.EUR]: 0, [MetricUnits.USD]: 0 },
  one_hour: { [MetricUnits.EUR]: 250, [MetricUnits.USD]: 300 },
  small: { [MetricUnits.EUR]: 1935, [MetricUnits.USD]: 2385 },
} satisfies CoachingPrice;

export const COACHING_RATE = {
  large: { [MetricUnits.EUR]: 7200, [MetricUnits.USD]: 8640 },
  medium: { [MetricUnits.EUR]: 4320, [MetricUnits.USD]: 5280 },
  no_coaching: { [MetricUnits.EUR]: 0, [MetricUnits.USD]: 0 },
  one_hour: { [MetricUnits.EUR]: 250, [MetricUnits.USD]: 300 },
  small: { [MetricUnits.EUR]: 1935, [MetricUnits.USD]: 2385 },
} satisfies CoachingPrice;

export const COACHING_DURATION: Record<CoachingTypes, number> = {
  large: 48,
  medium: 24,
  no_coaching: 0,
  one_hour: 1,
  small: 9,
};

export const CHARGEBEE_COACHING_PLANS: Record<
  CoachingTypes,
  CoachingValueType
> = {
  large: {
    EUR: "2023-eur-coaching-large-nonrecurring",
    USD: "2023-usd-coaching-Large-nonrecurring",
  },
  medium: {
    EUR: "2023-eur-coaching-medium-nonrecurring",
    USD: "2023-usd-coaching-medium-nonrecurring",
  },
  no_coaching: { EUR: "", USD: "" },
  one_hour: {
    EUR: "coaching-hours-(eur-250)",
    USD: "coaching-hours-(usd-300)",
  },
  small: {
    EUR: "2023-eur-coaching-small-nonrecurring",
    USD: "2023-usd-coaching-small-nonrecurring",
  },
};

type PlanPricing = Record<BillingPeriod, Record<SupportedCurrencies, number>>;

export const PREMIUM_PLAN_PRICING: PlanPricing = {
  ANNUAL: {
    EUR: 8,
    USD: 9,
  },
  QUARTERLY: {
    EUR: 8.8,
    USD: 9.9,
  },
};

export const SUPREME_PLAN_PRICING: PlanPricing = {
  ANNUAL: {
    EUR: 10,
    USD: 11,
  },
  QUARTERLY: {
    EUR: 11,
    USD: 12.1,
  },
};

export const VIEW_ONLY_PRICING: PlanPricing = {
  ANNUAL: {
    EUR: 1.8,
    USD: 2,
  },
  QUARTERLY: {
    EUR: 1.98,
    USD: 2.2,
  },
};

export const ONBOARDING_PRICING = 1;

type PlanCurrencyName = Record<
  SupportedCurrencies,
  Record<BillingPeriod, string>
>;

export const PREMIUM_PLAN_NAMES: PlanCurrencyName = {
  EUR: {
    ANNUAL: "eur-2024-annually-premium-v",
    QUARTERLY: "eur-2024-quarterly-premium-v",
  },
  USD: {
    ANNUAL: "usd-2024-annually-premium-v",
    QUARTERLY: "usd-2024-quarterly-premium-v",
  },
};

export const SUPREME_PLAN_NAMES: PlanCurrencyName = {
  EUR: {
    ANNUAL: "eur-2024-annually-supreme-v",
    QUARTERLY: "eur-2024-quarterly-supreme-v",
  },
  USD: {
    ANNUAL: "usd-2024-annually-supreme-v",
    QUARTERLY: "usd-2024-quarterly-supreme-v",
  },
};

export type PlanFeatures =
  | "BUTTON_BOOKMARK_STAR"
  | "BUTTON_EXPLORE_GRAPHS"
  | "BUTTON_EXPORTS"
  | "DROPDOWN_SSO_PROVIDER"
  | "DROPDOWN_USER_ROLE"
  | "GRAPH_POT"
  | "INPUT_KPI_TARGET"
  | "INPUT_RESULT_DUE_DATE"
  | "INTEGRATION_API"
  | "INTEGRATION_ASANA"
  | "INTEGRATION_HRIS"
  | "INTEGRATION_JIRA"
  | "INTEGRATION_SCIM"
  | "MENU_CLONE_OBJECTIVE"
  | "PAGE_ENGAGEMENT"
  | "PAGE_EXPLORE"
  | "PAGE_GROWTH_BOARD"
  | "PAGE_KPI_BOARD"
  | "PAGE_PERFORMANCE"
  | "PAGE_ROADMAP"
  | "PAGE_TIMELINE"
  | "PRIVATE_GOALS"
  | "PRIVATE_MODE"
  | "SHARED_GOALS"
  | "GOAL_TAGS"
  | "PERFORMANCE_REVIEWS";

type Map = {
  [k in PlanFeatures]?: {
    [key in FeatureTier]: boolean;
  };
};
export const featureAvailable: Map = {
  BUTTON_BOOKMARK_STAR: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  BUTTON_EXPLORE_GRAPHS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  BUTTON_EXPORTS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  DROPDOWN_SSO_PROVIDER: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  DROPDOWN_USER_ROLE: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  GOAL_TAGS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  GRAPH_POT: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  INPUT_KPI_TARGET: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  INPUT_RESULT_DUE_DATE: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  INTEGRATION_API: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  INTEGRATION_ASANA: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  INTEGRATION_HRIS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  INTEGRATION_JIRA: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  INTEGRATION_SCIM: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  MENU_CLONE_OBJECTIVE: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PAGE_ENGAGEMENT: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PAGE_EXPLORE: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  PAGE_KPI_BOARD: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PAGE_PERFORMANCE: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PAGE_ROADMAP: {
    EXPIRED_TRIAL: true,
    FREE: true,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
  PAGE_TIMELINE: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PERFORMANCE_REVIEWS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PRIVATE_GOALS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  PRIVATE_MODE: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: false,
    PROFESSIONAL: false,
    SUPREME: true,
    TRIAL: true,
  },
  SHARED_GOALS: {
    EXPIRED_TRIAL: false,
    FREE: false,
    PREMIUM: true,
    PROFESSIONAL: true,
    SUPREME: true,
    TRIAL: true,
  },
};
