import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

type Kind = "info" | "warning";

type Props = {
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  kind?: Kind;
  onDismiss?: () => void;
};

export const Banner = ({
  icon,
  children,
  onDismiss,
  kind = "info",
  className,
}: Props): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex items-center justify-items-stretch rounded border px-4 py-3 text-sm",
        {
          "bg-blue-50 border-blue-300": kind === "info",
          "bg-yellow-50 border-yellow-300": kind === "warning",
        },
        className
      )}
    >
      {icon && <div className="mr-3 flex items-center">{icon}</div>}
      <div className="grow">{children}</div>
      {isSet(onDismiss) && (
        <div className="ml-auto pl-2">
          <Anchor data-cy="jGkXVZJhBrkV-OfTsXm6z" onClick={onDismiss}>
            <FormattedMessage defaultMessage="Dismiss" id="TDaF6J" />
          </Anchor>
        </div>
      )}
    </div>
  );
};
