import { KeyResult, Kpi } from "@graphql";
import { Derive } from "@shoooe/derive";
import { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { PermissionErrorMessage } from "common/overlay";

type KpiProp = Derive<
  Kpi | KeyResult,
  {
    archived: true;
    canPatch: true;
  }
>;

type Hook =
  | { canUpdateProgress: false; reason: ReactNode }
  | { canUpdateProgress: true; reason: undefined };

export const useCanUpdateProgress = (kpi: KpiProp): Hook => {
  return useMemo<Hook>(() => {
    if (!kpi.canPatch) {
      return { canUpdateProgress: false, reason: <PermissionErrorMessage /> };
    }

    if (kpi.archived) {
      return {
        canUpdateProgress: false,
        reason: (
          <FormattedMessage
            defaultMessage="Unarchive this goal to update progress."
            id="dA5k/d"
          />
        ),
      };
    }

    return { canUpdateProgress: true, reason: undefined };
  }, [kpi.canPatch, kpi.archived]);
};
