import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import * as Yup from "yup";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";

const messages = defineMessages({
  addNote: {
    defaultMessage: "Add note...",
    id: "global:add:note:placeholder",
  },
  save: {
    defaultMessage: "Save",
    id: "global:save",
  },
  submit: {
    defaultMessage: "Submit",
    id: "global:submit",
  },
});

const initialValues: CommentFormValues = { content: "" };

export type CommentFormValues = {
  content: string;
};

type Props = {
  clearOnSubmit?: boolean;
  component?: React.ComponentType<React.ComponentProps<typeof FormTextArea>>;
  onSubmit: (
    values: CommentFormValues,
    actions: FormikHelpers<CommentFormValues>
  ) => void;
};

export const CommentForm = ({
  clearOnSubmit,
  component = FormTextArea,
  onSubmit,
}: Props): JSX.Element => {
  const intl = useIntl();
  const schema = Yup.object().shape({
    content: Yup.string().nullable().required("Required"),
  });

  const handleSubmit = (
    values: CommentFormValues,
    formikBag: FormikHelpers<CommentFormValues>
  ) => {
    if (clearOnSubmit) {
      formikBag.resetForm({ values: initialValues });
      formikBag.setErrors({ content: undefined });
    }
    onSubmit(values, formikBag);
  };

  return (
    <div>
      <Formik
        initialValues={{ content: "" }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ submitForm }) => (
          <Form>
            <Field
              component={component}
              data-cy="addComment"
              minimizedView
              name="content"
              onSubmit={submitForm}
              placeholder={intl.formatMessage(messages.addNote)}
              showActionsButtons
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
