import { namedOperations } from "@graphql";
import { useDispatch } from "react-redux";
import { MODAL_TYPES } from "constants/index";
import { CLOSE_OBJECTIVE_STARTED } from "constants/tracking";
import { showModal } from "legacy/actions/actions";
import { RefetchQueries } from "utils/graphql/types";
import { track } from "utils/tracker";
import { CloseObjectiveModalProps } from "../../CloseObjectiveModal";

type ModalProps = Omit<
  CloseObjectiveModalProps,
  "onRequestClosed" | "open" | "refetchQueries"
>;

type Options = {
  refetchQueries?: RefetchQueries;
};

type Hook = {
  openCloseObjectiveModal: (props: ModalProps) => void;
};

export const useCloseObjectiveModal = (options?: Options): Hook => {
  const dispatch = useDispatch();
  const additionalRefetchQueries = options?.refetchQueries ?? [];
  return {
    openCloseObjectiveModal: (props: ModalProps) => {
      track(CLOSE_OBJECTIVE_STARTED);
      dispatch(
        showModal(MODAL_TYPES.CLOSE_OBJECTIVE, {
          ...props,
          refetchQueries: [
            namedOperations.Query.getObjective,
            ...additionalRefetchQueries,
          ],
        })
      );
    },
  };
};
