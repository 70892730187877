import { GetKudosQueryVariables, Kudo, useGetKudosQuery } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import { mapEdges } from "utils/mapEdges";
import { apolloQueryMerger } from "utils/utils";

type Variables = Omit<GetKudosQueryVariables, "first" | "after">;

type Options = {
  pageSize: number;
  variables?: Variables;
};

export type UseKudosKudo = Derive<
  Kudo,
  {
    author: {
      avatar: true;
      fullName: true;
      id: true;
    };
    canDelete: true;
    canPatch: true;
    createdDate: true;
    id: true;
    text: true;
  }
>;

type Hook = {
  hasNextPage: boolean;
  isLoading: boolean;
  kudos: UseKudosKudo[];
  loadMore: () => void;
};

export const useKudos = ({ pageSize, variables }: Options): Hook => {
  const { data, fetchMore } = useGetKudosQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      ...variables,
      first: pageSize,
    },
  });

  const isLoading = isNil(data);
  if (isLoading) {
    return {
      hasNextPage: false,
      isLoading: true,
      kudos: [],
      loadMore: () => {},
    };
  }

  const { hasNextPage, endCursor } = data.allKudos.pageInfo;

  const loadMore = () =>
    fetchMore({
      updateQuery: apolloQueryMerger,
      variables: { after: endCursor },
    });

  const kudos = mapEdges(data.allKudos.edges);

  return {
    hasNextPage,
    isLoading,
    kudos,
    loadMore,
  };
};
