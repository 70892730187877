import { useState } from "react";

/**
 * a simple useState wrapper for boolean states that allows to quickly toggle the value
 * @param initState
 */
export const useToggle = (
  initState: boolean
): [boolean, () => void, (value: boolean) => void] => {
  const [state, setState] = useState(initState);

  const toggle = () => {
    setState((previous) => !previous);
  };

  return [state, toggle, setState];
};
