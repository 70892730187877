import PropTypes from "prop-types";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'rc-s... Remove this comment to see the full error message
import Handle from "rc-slider/lib/Handle";
import Tooltip from "rc-tooltip";
import React from "react";

// @deprecated. use something else so we can remove rc-slider and rc-tooltip, which have issues.
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const CustomHandle = (props) => {
  const { value, dragging, index, ...other } = props;
  return (
    <Tooltip
      key={index}
      overlay={value}
      placement="top"
      prefixCls="rc-slider-tooltip"
      visible={dragging}
    >
      <Handle value={value} {...other} />
    </Tooltip>
  );
};

CustomHandle.propTypes = {
  dragging: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

CustomHandle.defaultProps = {};
