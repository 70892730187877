import { UserDefaultFragment } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { OneonOneCompletePublicAnswer } from "common/oneOnOnes/OneOnOneCompleteView/OneOnOneCompletePublicAnswer";
import { OneOnOneMeetingSharedNoteWrapper } from "../OneOnOneCompleteForm/OneOnOneCompleteForm";
import { OneOnOneCompletePrivateNoteWrapper } from "../OneOnOneCompleteForm/OneOnOneCompletePrivateNoteWrapper/OneOnOneCompletePrivateNoteWrapper";
import { OneOnOneCompletePublicNoteField } from "../OneOnOneCompleteForm/OneOnOneCompletePublicNoteField";
import { OneOnOneMeetingItem } from "../OneOnOneCompleteForm/OneOnOneMeetingItemsField/OneOnOneMeetingItemsField";
import { OneOnOneMeetingPrivateNoteField } from "../OneOnOneCompleteForm/OneOnOneMeetingPrivateNoteField/OneOnOneMeetingPrivateNoteField";
import { OneOnOneCompleteMeetingItems } from "./OneonOneCompleteMeetingItems/OneOnOneCompleteMeetingItems";

type Props = {
  meetingBag: {
    actionItems: OneOnOneMeetingItem[] | null;
    otherUserNote: string;
    talkingPoints: OneOnOneMeetingItem[] | null;
  };
  otherUser: UserDefaultFragment;
  savePrivateNote: (note: string) => Promise<void>;
  savePublicNote: (note: string) => Promise<void>;
};

export const OneOnOneCompleteView = ({
  meetingBag: { actionItems, otherUserNote, talkingPoints },
  otherUser,
  savePrivateNote,
  savePublicNote,
}: Props): JSX.Element => {
  return (
    <div className="w-full space-y-4 px-3 py-4 md:px-9 xl:py-4">
      <OneOnOneCompleteMeetingItems
        meetingItems={talkingPoints}
        title={<FormattedMessage defaultMessage="Talking points" id="zQ4Fl+" />}
      />
      <OneOnOneCompleteMeetingItems
        meetingItems={actionItems}
        title={<FormattedMessage defaultMessage="Action items" id="Arkkex" />}
      />
      <OneOnOneMeetingSharedNoteWrapper className="px-0">
        <OneOnOneCompletePublicNoteField
          name="publicNote"
          onBlur={savePublicNote}
        />
        <OneonOneCompletePublicAnswer
          note={otherUserNote}
          userName={otherUser.fullName}
        />
      </OneOnOneMeetingSharedNoteWrapper>
      <OneOnOneCompletePrivateNoteWrapper className="px-0 pb-4">
        <OneOnOneMeetingPrivateNoteField
          name="privateNote"
          onBlur={savePrivateNote}
        />
      </OneOnOneCompletePrivateNoteWrapper>
    </div>
  );
};
