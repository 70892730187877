import { startCase } from "lodash";
import React, { ReactNode, useRef } from "react";
import { useOptionalProfileContext } from "common/context/profileContext";
import { GoalSelectorButton } from "common/goal/GoalSelectorButton/GoalSelectorButton";
import {
  CategorySelect,
  CategorySelectItem,
} from "common/inputs/CategorySelect/CategorySelect";
import { objectTypes } from "constants/objects";
import { SELECT_EXAMPLE_GOAL } from "constants/tracking";
import { useSampleGoals } from "hooks/useSampleGoals/useSampleGoals";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { track } from "utils/tracker";

interface Props {
  groupName?: string;
  setFieldValue: (field: string, value: ReactNode) => void;
}

export const ObjectiveGoalSelector = ({
  groupName,
  setFieldValue,
}: Props): JSX.Element => {
  const dropdownRef = useRef<Dropdown>(null);
  const currentGroup = useOptionalProfileContext();

  const [sampleGoalsByGroup, sampleGoalsFound] = useSampleGoals(
    "objective",
    groupName ?? currentGroup?.name
  );

  const sampleGoalSelected = (objective: CategorySelectItem) => {
    track(SELECT_EXAMPLE_GOAL, {
      title: objective.name,
      type: objectTypes.objective,
    });

    setFieldValue("name", objective.name);
    dropdownRef.current?.close();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      button={<GoalSelectorButton type="objective" />}
      buttonOpen={
        <GoalSelectorButton
          className="border border-blue-500"
          type="objective"
        />
      }
      forcePosition="bottom-left"
    >
      <CategorySelect
        categories={sampleGoalsByGroup}
        className="max-h-96 w-96"
        icon={{ name: "flag", type: "outlined" }}
        onSelect={(objective) => {
          sampleGoalSelected(objective);
        }}
        selectFirst={sampleGoalsFound}
        transform={startCase}
      />
    </Dropdown>
  );
};
