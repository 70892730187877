import { isNil } from "lodash";
import moment from "moment";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { colorTheme } from "constants/colorTheme";
import { twClass } from "utils/twClass";
import * as ZIndex from "../../../constants/zIndex";
import { DatePicker } from "../DatePicker/DatePicker";
import { Input } from "../Input/Input";

const DATE_FORMAT = "MMM D, YYYY";
export const OVERLAY_ROOT_ID = "overlay-root";
const DATE_TIME_FORMAT = "MMM D, YYYY LT";

const Container = styled.div`
  position: relative;
`;

const StyledDatePicker = styled(DatePicker)`
  position: absolute;
  top: ${(props) => props.position.top}px;
  left: ${(props) => props.position.left}px;
  box-shadow: 0 2px 10px ${colorTheme.slate[200]};
  z-index: ${ZIndex.level5};
`;

type Props = Omit<React.ComponentProps<typeof Input>, "onChange"> & {
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  date?: Date | null;
  disabled?: boolean;
  errorText?: ReactNode;
  id?: string;
  infoElement?: ReactNode;
  label?: string;
  months?: string[];
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (val: Date | null) => void;
  optional?: boolean;
  placeholder?: string;
  showTimeInput?: boolean;
};

type State = {
  date: Date | null;
  dateFormat: string;
  dateString: string | null;
  isDatePickerVisible: boolean;
};

export class PdDatePicker extends React.Component<Props, State> {
  private container = React.createRef<HTMLDivElement>();

  private datePickerRef = React.createRef<HTMLDivElement>();

  private position = { left: 0, top: 0 };

  constructor(props: Props) {
    super(props);

    const date = props.date || new Date();
    const dateFormat = props.showTimeInput ? DATE_TIME_FORMAT : DATE_FORMAT;
    this.state = {
      date,
      dateFormat,
      dateString: props.date ? moment(date).format(dateFormat) : null,
      isDatePickerVisible: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleToggleDatePicker = this.handleToggleDatePicker.bind(this);
    this.handleDateInput = this.handleDateInput.bind(this);
  }

  componentDidMount(): void {
    document.addEventListener("keydown", this.handleKeyPress, true);
  }

  componentDidUpdate(): void {
    const { date: dateFromProps } = this.props;
    const { date: oldDate } = this.state;

    if (isNil(dateFromProps)) {
      if (!isNil(oldDate)) {
        this.setState({
          date: null,
          dateString: null,
        });
      }
      return;
    }

    if (isNil(oldDate) || !moment(dateFromProps).isSame(moment(oldDate))) {
      const { dateFormat } = this.state;
      const date = moment(dateFromProps);

      const newDate = date.isValid() ? date.toDate() : null;
      const newDateString = date.isValid() ? date.format(dateFormat) : null;
      this.setState({
        date: newDate,
        dateString: newDateString,
      });
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyPress, true);
  }

  handleChange(date: Date): void {
    const momentDate = moment(date);
    if (!momentDate.isValid()) return;

    const { showTimeInput, onChange } = this.props;
    const { dateFormat } = this.state;
    this.setState({
      date,
      dateString: momentDate.format(dateFormat),
      isDatePickerVisible: !!showTimeInput,
    });
    onChange?.(date);
  }

  handleClickOutside(): void {
    const { isDatePickerVisible } = this.state;
    if (isDatePickerVisible) {
      this.handleToggleDatePicker(false);
    }
  }

  handleDateInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { onChange } = this.props;
    const { dateFormat } = this.state;
    const dateString = e.target.value;
    const date = moment(dateString, dateFormat);

    const newDate = date.isValid() ? date.toDate() : null;
    const newDateString = date.isValid() ? e.target.value : null;
    onChange?.(newDate);
    return this.setState({
      date: newDate,
      dateString: newDateString,
    });
  };

  handleKeyPress(e: KeyboardEvent): void {
    if (e.key === "Escape" || e.key === "tab") {
      this.handleToggleDatePicker(false);
    }
  }

  handleToggleDatePicker(isVisible: boolean): void {
    const { onChange } = this.props;
    const { dateFormat, isDatePickerVisible } = this.state;
    if (isDatePickerVisible === isVisible) {
      return;
    }

    if (isVisible && this.container.current) {
      const bounds = this.container.current.getBoundingClientRect();
      this.position = {
        left: bounds.left,
        top: bounds.top + 70,
      };
    }

    this.setState(
      (state) => {
        const { date: propsDate = null } = this.props;
        const date = moment(state.dateString, dateFormat);
        const formatAsStringOrNull = (d: Date | null) =>
          d ? moment(d, dateFormat).format(dateFormat) : null;

        return {
          date: date.isValid() ? date.toDate() : propsDate,
          dateString: date.isValid()
            ? formatAsStringOrNull(date.toDate())
            : formatAsStringOrNull(propsDate),
          isDatePickerVisible: isVisible,
        };
      },
      () => {
        const { date } = this.state;
        if (isVisible && this.isOutOfBottomBoundary()) {
          const bounds = this.container.current?.getBoundingClientRect();
          const height = this.getDatePickerRect()?.height ?? 0;
          const top = (bounds?.top ?? 0) + 20 - height;
          if (top < 0) {
            const right = bounds?.right ?? 0;
            this.position = {
              left: right + 30,
              top: 65,
            };
          } else {
            this.position = {
              left: bounds?.left ?? 0,
              top,
            };
          }
        }
        onChange?.(date);
      }
    );
  }

  getDatePickerRect = (): DOMRect | null => {
    return this.datePickerRef.current?.getBoundingClientRect() ?? null;
  };

  isOutOfBottomBoundary = (): boolean => {
    // TODO: [no-unnecessary-condition] remove and fix
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.datePickerRef.current && document.body) {
      const { height: bodyHeight } = document.body.getBoundingClientRect();

      const height = this.getDatePickerRect()?.height ?? 0;
      const top = this.getDatePickerRect()?.top ?? 0;
      return height + top > bodyHeight;
    }

    return false;
  };

  render(): JSX.Element {
    const {
      className,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      disabled,
      errorText = null,
      id,
      infoElement = null,
      label = "",
      months,
      onBlur,
      optional,
      placeholder = "Date",
      showTimeInput,
      ...other
    } = this.props;
    const { date, dateString, isDatePickerVisible } = this.state;
    const overlayRoot = document.getElementById(OVERLAY_ROOT_ID)!;

    const datepicker = isDatePickerVisible ? (
      <StyledDatePicker
        {...other}
        date={date}
        datePickerRef={this.datePickerRef}
        months={months}
        onBlur={onBlur}
        onChange={this.handleChange}
        onClickOutside={this.handleClickOutside}
        position={this.position}
        showTimeInput={showTimeInput}
      />
    ) : null;

    return (
      <Container
        ref={this.container}
        className={twClass(
          {
            "pointer-events-none": disabled,
          },
          className
        )}
        data-cy={dataCy}
        data-testid={dataTestId}
      >
        <Input
          data-cy="Gf1HkyBEYnKSkJl7uNc4D"
          {...other}
          className={twClass("w-full", {
            "text-slate-400": disabled,
          })}
          errorText={errorText ?? null}
          id={id}
          infoElement={infoElement}
          label={label}
          onChange={this.handleDateInput}
          onClick={() => this.handleToggleDatePicker(true)}
          onFocus={() => this.handleToggleDatePicker(true)}
          optional={optional}
          placeholder={placeholder}
          readOnly={disabled}
          value={dateString || ""}
        />
        {ReactDOM.createPortal(datepicker, overlayRoot)}
      </Container>
    );
  }
}
