import React from "react";
import { BlogPosts } from "./BlogPosts/BlogPosts";
import { Links } from "./Links/Links";
import { Resources } from "./Resources/Resources";
import { Videos } from "./Videos/Videos";

export const DefaultInfoView = (): JSX.Element | null => {
  return (
    <div className="flex flex-col text-sm">
      <Videos />
      <Resources />
      <BlogPosts />
      <Links />
    </div>
  );
};
