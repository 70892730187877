import { Tag, useUpsertTagMutation } from "@graphql";
import { Derive } from "@shoooe/derive";
import { FormikHelpers } from "formik";
import { isNil, pick } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "common/overlay/Modal/Modal";
import { handleNonFormErrors } from "utils/forms";
import { RefetchQueries } from "utils/graphql/types";
import { toast } from "utils/toastr";
import { TagForm, TagFormValues } from "../../TagForm/TagForm";

type TagProp = Derive<Tag, { id: true; name: true }>;

type Props = {
  onRequestClosed: () => void;
  open: boolean;
  refetchQueries: RefetchQueries;
  tag: TagProp;
};

export const EditTagModal = ({
  open,
  onRequestClosed,
  tag,
  refetchQueries = [],
}: Props): JSX.Element => {
  const intl = useIntl();
  const [mutation] = useUpsertTagMutation();
  const onSubmit = async (
    values: TagFormValues,
    actions: FormikHelpers<TagFormValues>
  ) => {
    const { errors } = await mutation({
      refetchQueries,
      variables: {
        input: pick(values, ["name", "description", "color", "id"]),
      },
    });

    if (!isNil(errors) && errors.length) {
      handleNonFormErrors(errors);
      return null;
    }

    toast.success(
      intl.formatMessage({
        defaultMessage: "Tag updated",
        id: "company:settings:tags:updateConfirmationMessage",
      })
    );
    actions.setSubmitting(false);
    onRequestClosed();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={<FormattedMessage defaultMessage="Edit Tag" id="uJkv2X" />}
    >
      <TagForm initialValues={tag} onSubmit={onSubmit} />
    </Modal>
  );
};
