import styled from "styled-components";
import { colorTheme } from "constants/colorTheme";
import * as Typography from "constants/typography";

type ContainerProps = {
  hideDates: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${colorTheme.white};
  border-radius: 4px;
  width: 320px;
  z-index: 1000;

  .react-datepicker__input-time-container {
    display: flex;
    margin-top: 10px;
    align-items: center;

    .react-datepicker-time__input-container {
      flex-grow: 1;
      margin-left: 15px;
    }

    input {
      height: 37px;
      font-family: ${Typography.primaryFontFamily};
      font-weight: normal;
      border-radius: 4px;
      border: 1px solid ${colorTheme.slate[300]};
      padding: 2px 10px;
      margin: 1px;
      width: 100%;
      background-color: ${colorTheme.white};

      :focus {
        border: 2px solid ${colorTheme.blue[500]};
        margin: 1px 0;
        outline: none;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        display: none;
        appearance: none;
        margin: 0;
      }
    }
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      .react-datepicker__day-names {
        display: ${(props) => (props.hideDates ? "none" : "flex")};
        margin-top: 10px;

        .react-datepicker__day-name {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin: 5px;

          &:nth-child(1),
          &:nth-last-child(1) {
            color: ${colorTheme.red[500]};
          }
        }
      }
    }

    .react-datepicker__month {
      display: ${(props) => (props.hideDates ? "none" : "block")};

      .react-datepicker__week {
        display: flex;

        .react-datepicker__day {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin: 5px;
          border-radius: 4px;
          cursor: pointer;
          position: relative;

          &:hover {
            background-color: ${colorTheme.slate[200]};
          }

          &.react-datepicker__day--outside-month {
            color: ${colorTheme.slate[300]};
          }

          &.react-datepicker__day--today {
            font-weight: 500;
            color: ${colorTheme.red[500]};
          }

          &.react-datepicker__day--selected {
            color: ${colorTheme.slate[100]};
            background-color: ${colorTheme.blue[500]};
          }
        }
      }
    }
  }
`;

type HeaderProps = {
  mode: "date" | "month" | "year";
};

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: ${(props) => (props.mode === "date" ? "row" : "column")};
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.mode === "date" ? 50 : 340)}px;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  border-bottom-color: ${(props) =>
    props.mode === "date" ? colorTheme.slate[200] : "transparent"};
`;

export const SelectText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid ${colorTheme.slate[200]};
  user-select: none;
`;

export const HeaderMonth = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const HeaderMonthName = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  user-select: none;

  &:hover {
    background-color: ${colorTheme.slate[200]};
  }
`;

export const HeaderYearName = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  user-select: none;

  &:hover {
    background-color: ${colorTheme.slate[200]};
  }
`;

export const Spacer = styled.div`
  height: 30px;
  width: 30px;
`;

export const Months = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 360px;
  flex: 1;
  align-items: center;
`;

type MonthItemProps = {
  selected: boolean;
};

export const MonthItem = styled.div<MonthItemProps>`
  flex: 0 0 90px;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
  background-color: ${(props) =>
    props.selected ? colorTheme.blue[500] : colorTheme.white};
  color: ${(props) =>
    props.selected ? colorTheme.white : colorTheme.slate[800]};
  cursor: pointer;
  user-select: none;
  height: 30px;

  &:hover {
    background-color: ${colorTheme.slate[200]};
  }
`;

export const Years = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  height: 100px;
`;

type YearItemProps = {
  selected: boolean;
};

export const YearItem = styled.div<YearItemProps>`
  flex: 0 0 30px;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 10px;
  background-color: ${(props) =>
    props.selected ? colorTheme.blue[500] : colorTheme.white};
  color: ${(props) =>
    props.selected ? colorTheme.white : colorTheme.slate[800]};
  cursor: pointer;
  user-select: none;
  height: 30px;

  &:hover {
    background-color: ${colorTheme.slate[200]};
  }
`;
