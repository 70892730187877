import {
  CommitDefaultFieldsFragment,
  CommitDescriptionFragment,
  CommitUserFragment,
  CommitValuesFragment,
  GrowthBoardKpiObjectiveFragment,
  KpiCompanyFragment,
  KpiCurrentValueFragment,
  KpiDefaultFragment,
  KpiDescriptionFragment,
  KpiGroupsFragment,
  KpiIntegrationFragment,
  KpiLeadFragment,
  KpiMetricUnitFragment,
  KpiPermissionsFragment,
  KpiPrivateFragment,
  KpiTargetsFragment,
  KpiValuesFragment,
  namedOperations,
  PerdooApiKpiTargetOperatorChoices,
  useDeleteKpiTargetMutation,
  useUpsertKpiTargetMutation,
} from "@graphql";
import classNames from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty, isNil } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Show } from "common/controlFlow";
import {
  ProgressUpdatePanel,
  ProgressUpdatePanelValues,
} from "common/goal/ProgressUpdatePanel/ProgressUpdatePanel";
import { useKpiData } from "common/kpi/hooks/useKpiData";
import { useKpiTargetMonths } from "common/kpi/hooks/useKpiTargetMonths";
import { KpiStatus } from "common/kpi/KpiStatus/KpiStatus";
import { KpiTargetSelector } from "common/kpi/KpiTargetSelector/KpiTargetSelector";
import { KpiTarget } from "common/kpi/Targets/TargetList/KpiTarget/KpiTarget";
import { PermissionErrorMessage } from "common/overlay/PermissionErrorTooltip/PermissionErrorMessage/PermissionErrorMessage";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { BranchConditionalWrapper } from "common/wrappers";
import { ADD_KPI_TARGET, DELETE_KPI_TARGET } from "constants/tracking";
import { useCommitActions } from "hooks/useCommitActions/useCommitActions";
import { handleNonFormErrors } from "utils/forms";
import { isSet } from "utils/isSet";
import { responsiveRoundBorders } from "utils/responsiveRoundBorders";
import { stringToCommitNumber } from "utils/stringToCommitNumber";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { formatPdMetric } from "utils/utils";
import { messages } from "./TargetList.messages";
import { Month } from "./TargetList.types";
import {
  formatTargets,
  getFormattedKpiValues,
  resolveTargets,
} from "./TargetList.utils";

export const DEFAULT_OPERATOR = PerdooApiKpiTargetOperatorChoices.GreaterThanOrEqual;
const VISIBLE_MONTHS = 12;

const refetchQueries = [
  namedOperations.Query.getGrowthBoardKpis,
  namedOperations.Query.getKpiBoardKpis,
  namedOperations.Query.getKpiModalBaseKpi,
  namedOperations.Query.getKpiModalBaseKpi,
  namedOperations.Query.getKpiProgressGraph,
  namedOperations.Query.getTimelineKpiStats,
  namedOperations.Query.getTimelineStrategicPillars,
];

type Kpi = KpiDefaultFragment &
  KpiCompanyFragment &
  KpiCurrentValueFragment &
  KpiPermissionsFragment &
  KpiGroupsFragment &
  KpiPrivateFragment &
  KpiMetricUnitFragment &
  KpiDescriptionFragment &
  KpiLeadFragment &
  KpiIntegrationFragment &
  KpiTargetsFragment &
  Partial<KpiValuesFragment> & {
    lastCommit: CommitDefaultFieldsFragment &
      CommitDescriptionFragment &
      CommitUserFragment &
      CommitValuesFragment;
  };

export type Props = {
  alignedObjectives?: GrowthBoardKpiObjectiveFragment[];
  className?: string;
  itemClass?: string;
  kpi: Kpi;
  source: "growth-board" | "kpi-modal";
  startDate: Dayjs;
  /** each target cell will have borders and the whole component will render responsive round borders */
  useBorders?: boolean;
  /** show month labels in the target cell (kpi modal) or not (growth board) */
  withLabels?: boolean;
  /** show targets and values (growth board) or just targets (kpi modal) */
  withValues?: boolean;
};

/**
 * shows a row of 12 cells, each indicating the kpi target for one month, optionally displaying also the month name or
 * kpi value. currently used in KPI summary modal and growth board.
 */
export const TargetList = ({
  alignedObjectives,
  className,
  itemClass,
  kpi,
  source,
  startDate,
  useBorders = false,
  withLabels = false,
  withValues = false,
}: Props): JSX.Element => {
  const intl = useIntl();

  const [selectedTargetMonth, setSelectedTargetMonth] = useState<number | null>(
    null
  );
  const [selectedValueMonth, setSelectedValueMonth] = useState<number | null>(
    null
  );
  const [updatingTargetMonth, setUpdatingTargetMonth] = useState<number | null>(
    null
  );
  const [updatingValueMonth, setUpdatingValueMonth] = useState<number | null>(
    null
  );
  const [newOperator, setNewOperator] = useState(DEFAULT_OPERATOR);
  const [newTarget, setNewTarget] = useState<string | null>(null);
  const [newTargetRange, setNewTargetRange] = useState(0);

  const { addCommit } = useCommitActions();
  const [addTarget] = useUpsertKpiTargetMutation({ refetchQueries });
  const [removeTarget] = useDeleteKpiTargetMutation({ refetchQueries });

  const months = useKpiTargetMonths(startDate);
  const targets = kpi.targets?.edges.map(({ node }) => node) ?? [];
  const kpiData = useKpiData(kpi);

  const resetTargetState = () => {
    setSelectedTargetMonth(null);
    setUpdatingTargetMonth(null);
    setNewOperator(DEFAULT_OPERATOR);
    setNewTarget(null);
    setNewTargetRange(0);
  };

  const resetValueState = () => {
    setSelectedValueMonth(null);
    setUpdatingValueMonth(null);
  };

  const resetState = () => {
    resetTargetState();
    resetValueState();
  };

  useEffect(() => {
    // when the page is changed or the data updated, this will stop showing spinners
    resetState();
  }, [kpi, startDate]);

  let targetsByMonth = resolveTargets(months, targets);
  targetsByMonth = formatTargets(intl, kpi.metricUnit, targetsByMonth);

  const toggleSelectedTargetMonth = (id: number) => () => {
    if (!kpi.canPatch) return;
    setNewOperator(DEFAULT_OPERATOR);
    setNewTarget(null);
    setSelectedValueMonth(null);
    setSelectedTargetMonth((prev) => (prev === id ? null : id));
  };

  const toggleSelectedValueMonth = (id: number) => () => {
    if (!kpi.canPatch) return;
    setNewOperator(DEFAULT_OPERATOR);
    setNewTarget(null);
    setSelectedTargetMonth(null);
    setSelectedValueMonth((prev) => (prev === id ? null : id));
  };

  /**
   * calculates until which month the new target will populate
   */
  const getPopulateUntil = () => {
    let populateUntil = selectedTargetMonth;
    if (!populateUntil) {
      return 0;
    }

    while (
      populateUntil < VISIBLE_MONTHS &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      !targetsByMonth[populateUntil].isExplicit
    ) {
      populateUntil++;
    }

    return populateUntil;
  };

  /**
   * when new target is being set, this sets which months should inherit this target so they can be prepopulated.
   */
  const handleTargetChange = (e: ChangeEvent<HTMLFormElement>) => {
    const { value } = e.target;

    let newValue = null;
    const commitNumber = stringToCommitNumber(value);
    if (isSet(commitNumber)) {
      newValue = formatPdMetric(intl, commitNumber, kpi.metricUnit, {
        abbreviate: true,
      });
    }

    setNewTarget(newValue);

    if (!selectedTargetMonth) {
      return;
    }

    setNewTargetRange(getPopulateUntil());
  };

  const handleRemoveTarget = (month: Month) => {
    if (isNil(month.value)) {
      return undefined;
    }

    if (!month.isExplicit) {
      return async () => {
        setNewTargetRange(getPopulateUntil());
        setUpdatingTargetMonth(month.id);
        setSelectedTargetMonth(null);

        const response = await addTarget({
          variables: {
            input: {
              id: month.targetId,
              kpi: kpi.id,
              operator: null,
              startDate: month.date.format("YYYY-MM-DD"),
              threshold: null,
            },
          },
        });

        if (response.errors) {
          resetState();
          return;
        }

        toast.success(intl.formatMessage(messages.targetRemoved));
        track(DELETE_KPI_TARGET, {
          source,
        });
      };
    }

    return async () => {
      setNewTargetRange(getPopulateUntil());
      setUpdatingTargetMonth(month.id);
      setSelectedTargetMonth(null);

      const response = await removeTarget({
        variables: {
          input: {
            id: month.targetId,
          },
        },
      });

      if (response.errors) {
        resetState();
        return;
      }

      toast.success(intl.formatMessage(messages.targetRemoved));
      track(DELETE_KPI_TARGET, {
        source,
      });
    };
  };

  const handleSubmitTarget =
    (month: Month) =>
    async (values: {
      operator: PerdooApiKpiTargetOperatorChoices;
      target: number;
    }) => {
      setSelectedTargetMonth(null);
      const nothingChanged =
        month.value === values.target && month.operator === values.operator;
      if (nothingChanged) {
        resetState();
        return Promise.resolve();
      }

      setUpdatingTargetMonth(month.id);

      const response = await addTarget({
        variables: {
          input: {
            id: month.targetId,
            kpi: kpi.id,
            operator: values.operator,
            startDate: month.date.format("YYYY-MM-DD"),
            threshold: values.target,
          },
        },
      });

      const errors = response.data?.upsertKpiTarget?.errors || [];
      if (errors.length) {
        resetState();
        handleNonFormErrors(errors);
        return;
      }

      if (month.targetId) {
        toast.success(intl.formatMessage(messages.targetUpdated));
      } else {
        toast.success(intl.formatMessage(messages.targetAdded));
        track(ADD_KPI_TARGET, {
          source,
        });
      }

      return Promise.resolve();
    };

  /**
   * do not copy this method outside kpi boards. this sets `from_kpi_board` field on commit as true.
   */
  const getSubmitProgressUpdateForBoardKpiHandler =
    (month: Month) => async (values: ProgressUpdatePanelValues) => {
      setSelectedValueMonth(null);
      setUpdatingValueMonth(month.id);

      const response = await addCommit(
        {
          commitDate: values.commitDate,
          description: values.description,
          fromKpiBoard: true,
          kpi: kpi.id,
          value: values.value ?? 0,
        },
        "kpi"
      );

      const errors = response.data?.upsertCommit?.errors;
      if (!isEmpty(errors)) {
        resetState();
      }
      return response;
    };

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Kpi' is not assignable to parame... Remove this comment to see the full error message
  const values = withValues ? getFormattedKpiValues(kpi, intl) : null;

  return (
    <div
      className={classNames(
        className,
        "text-sm",
        "grid grid-cols-3 sm:grid-cols-6 lg:grid-cols-12 gap-y-4"
      )}
    >
      {targetsByMonth.map((month, index) => {
        const startOfQuarter = month.date.startOf("quarter");
        const isStartOfQuarter = month.date.isSame(startOfQuarter, "month");
        const isCurrentMonth = month.date.isSame(undefined, "month");
        const isFutureMonth = month.date.isAfter(undefined, "month");

        const alignedOkrs = alignedObjectives?.filter((obj) => {
          if (isNil(obj.timeframe.startDate)) return false;
          return dayjs(obj.timeframe.startDate).isSame(startOfQuarter, "month");
        });
        const alignedOkrsCount = alignedOkrs?.length ?? 0;
        const shouldShowAlignedOkrs = isStartOfQuarter && alignedOkrsCount > 0;

        const currentlyEditingTarget =
          !!selectedTargetMonth &&
          !isNil(newTarget) &&
          month.id >= selectedTargetMonth &&
          month.id <= newTargetRange;

        const currentlyUpdatingTarget =
          !!updatingTargetMonth &&
          month.id >= updatingTargetMonth &&
          month.id <= newTargetRange;

        const lastDayOfMonth = month.date
          .endOf("month")
          .hour(23)
          .minute(30)
          .second(0)
          .millisecond(0);
        const lastCommit = kpi.valuesByMonth?.[index]?.lastCommit;
        const lastCommitOfTheMonth =
          kpi.valuesByMonth?.[index]?.lastCommitOfTheMonth;
        const lastCommitOfTheMonthDate = lastCommitOfTheMonth?.commitDate;
        const endMonthDate = isCurrentMonth ? dayjs.utc() : lastDayOfMonth;
        const defaultCommitDate = isNil(lastCommitOfTheMonthDate)
          ? endMonthDate
          : dayjs.utc(lastCommitOfTheMonthDate).add(1, "millisecond");
        const defaultValue =
          kpi.valuesByMonth?.[index]?.value ?? lastCommit?.value ?? 0;

        return (
          <div
            key={month.id}
            className={classNames("flex justify-end flex-col", {
              relative: shouldShowAlignedOkrs,
            })}
          >
            {withLabels && (
              <>
                <Show when={month.id === 1 || month.date.month() === 0}>
                  <div className="font-semibold">
                    {month.date.format("YYYY")}
                  </div>
                </Show>
                <div
                  className={classNames("", {
                    "text-blue-500": isCurrentMonth,
                  })}
                >
                  {month.label}
                </div>
              </>
            )}
            <div
              className={classNames(
                itemClass,
                "group w-full divide-y",
                "flex flex-col place-items-center items-stretch",
                "border-slate-300",
                "truncate focus:outline-none",
                {
                  "border-b lg:border-b-0": !useBorders,
                },
                useBorders && responsiveRoundBorders(3, 6, "sm")(index),
                useBorders && responsiveRoundBorders(6, 12, "lg")(index)
              )}
              data-cy="kpi-cell"
            >
              {isSet(kpi.valuesByMonth) && (
                <BranchConditionalWrapper
                  condition={kpi.canPatch}
                  left={(children) => (
                    <WithTooltip
                      interactive
                      maxWidth=""
                      placement="left"
                      theme="perdoo-white"
                      tooltip={
                        month.id === selectedValueMonth && (
                          <ProgressUpdatePanel
                            apiIntegration={kpi.pushIntegrationApplication}
                            className="w-[27rem]"
                            initialValues={{
                              commitDate: defaultCommitDate.toISOString(),
                              value: defaultValue,
                            }}
                            lastCommit={isCurrentMonth ? kpi.lastCommit : null}
                            metricUnit={kpi.metricUnit}
                            onClose={
                              month.id === selectedValueMonth
                                ? resetValueState
                                : undefined
                            }
                            onSubmit={getSubmitProgressUpdateForBoardKpiHandler(
                              month
                            )}
                            positiveMetric={kpiData.positiveMetric}
                            preserveTimezone
                            title={intl.formatMessage({
                              defaultMessage: "Update KPI",
                              id: "kpi:update:title",
                            })}
                            type="kpi"
                          />
                        )
                      }
                      visible={month.id === selectedValueMonth}
                    >
                      {children}
                    </WithTooltip>
                  )}
                  right={(children) => (
                    <WithTooltip
                      interactive
                      placement="top"
                      tooltip={<PermissionErrorMessage />}
                    >
                      {children}
                    </WithTooltip>
                  )}
                >
                  <button
                    aria-label="kpiValue"
                    className={classNames("w-full py-2", {
                      "bg-slate-200": month.id === selectedValueMonth,
                      "cursor-pointer": !isFutureMonth,
                      "hover:bg-slate-100":
                        month.id !== selectedValueMonth && !isFutureMonth,
                    })}
                    disabled={!!updatingValueMonth || isFutureMonth}
                    onClick={toggleSelectedValueMonth(month.id)}
                    type="button"
                    data-testid="kpiValue"
                  >
                    {values && (
                      <KpiStatus
                        className={classNames({
                          "opacity-25": month.id === selectedValueMonth,
                        })}
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        status={values[index].status}
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        value={values[index].value}
                      />
                    )}
                  </button>
                </BranchConditionalWrapper>
              )}
              <BranchConditionalWrapper
                condition={kpi.canPatch}
                left={(children) => (
                  <KpiTargetSelector
                    initialValues={{
                      operator:
                        month.operator ??
                        PerdooApiKpiTargetOperatorChoices.GreaterThanOrEqual,
                      target: month.value?.toString() ?? "",
                    }}
                    // @ts-expect-error ts-migrate(2741) FIXME: Property 'goalOperator' is missing in type 'Kpi' b... Remove this comment to see the full error message
                    kpi={kpi}
                    onChange={handleTargetChange}
                    onClose={resetTargetState}
                    onRemoveTarget={handleRemoveTarget(month)}
                    onSubmit={handleSubmitTarget(month)}
                    placement="left"
                    setNewOperator={setNewOperator}
                    visible={month.id === selectedTargetMonth}
                  >
                    {children}
                  </KpiTargetSelector>
                )}
                right={(children) => (
                  <WithTooltip
                    interactive
                    placement="top"
                    tooltip={<PermissionErrorMessage />}
                  >
                    {children}
                  </WithTooltip>
                )}
              >
                <button
                  className={classNames("w-full cursor-pointer py-2", {
                    "bg-slate-200": month.id === selectedTargetMonth,
                    "hover:bg-slate-100": month.id !== selectedTargetMonth,
                  })}
                  disabled={!!updatingTargetMonth}
                  onClick={toggleSelectedTargetMonth(month.id)}
                  type="button"
                  data-testid="kpiTarget"
                >
                  <KpiTarget
                    editing={currentlyEditingTarget}
                    isSelected={month.id === selectedTargetMonth}
                    month={month}
                    newOperator={newOperator}
                    newTarget={newTarget}
                    updating={currentlyUpdatingTarget}
                  />
                </button>
              </BranchConditionalWrapper>
            </div>
          </div>
        );
      })}
    </div>
  );
};
