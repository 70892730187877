import { useGetUserCheckInsByCycleQuery, UserDefaultFragment } from "@graphql";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { ErrorMessage } from "common/placeholders/ErrorMessage/ErrorMessage";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { Cursor } from "common/table/types";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { CheckInDetails } from "./CheckInDetails/CheckInDetails";
import { Navigation } from "./Navigation/Navigation";

const DEFAULT_PAGE = { first: 1 };

type Props = {
  user: UserDefaultFragment;
};

export const SidebarCheckIns = ({ user }: Props): JSX.Element => {
  const userId = user.id;
  const [paginator, setPaginator] = useState<Cursor>(DEFAULT_PAGE);

  const { data, error, loading } = useGetUserCheckInsByCycleQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      after: "after" in paginator ? paginator.after : undefined,
      before: "before" in paginator ? paginator.before : undefined,
      first: "first" in paginator ? paginator.first : undefined,
      last: "last" in paginator ? paginator.last : undefined,
      skipFirstNCycles: 1,
      userId,
    },
  });

  useEffect(() => {
    setPaginator(DEFAULT_PAGE);
  }, [userId]);

  if (isNil(data)) return <SpinnerFit />;

  if (isSet(error))
    return (
      <div>
        <ErrorMessage />
      </div>
    );

  const cycles = mapEdges(data.allCycles.edges);
  const cycle = cycles[0];

  const { pageInfo, totalCount } = data.allCycles;

  return (
    <Show
      fallback={
        <div className="flex items-center justify-center py-2">
          <FormattedMessage defaultMessage="No cycles configured" id="8du1wZ" />
        </div>
      }
      when={cycle}
    >
      {(latestCycle) => (
        <>
          <div className="sticky top-[45px] z-modal-prio-backdrop flex border-b px-5 py-4 bg-slate-50 border-slate-300">
            <Navigation
              loading={loading}
              pageInfo={pageInfo}
              paginator={paginator}
              setPaginator={setPaginator}
              totalCount={totalCount}
            />
            <div className="flex items-center divide-x divide-slate-300">
              <span className="px-2">{latestCycle.name}</span>
              <span className="pl-2">
                <FormattedDate value={latestCycle.start} />
                {" - "}
                <FormattedDate value={latestCycle.end} />
              </span>
            </div>
          </div>
          <div className="px-5">
            <CheckInDetails userId={userId} cycleStart={latestCycle.start} />
          </div>
        </>
      )}
    </Show>
  );
};
