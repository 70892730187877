export const primaryFontFamily =
  "'Satoshi', ui-sans-serif, system-ui, -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
export const fontWeightRegular = 450;
export const fontWeightBold = 500;
export const fontWeightBlack = 900;
export const fontSizeExtraSmall = "12px";
export const fontSizeSmall = "14px";
export const fontSizeRegular = "16px";
export const fontSizeLarge = "18px";
export const fontSizeExtraLarge = "20px";
export const fontSizeJumbo = "36px";
