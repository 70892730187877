import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addNew: {
    defaultMessage: "add new",
    id: "globals:tags:addNew",
  },
  emptyState: {
    defaultMessage: "No results",
    id: "global:search:noResults",
  },
  placeholder: {
    defaultMessage: "Search...",
    id: "global:search:placeholder",
  },
});
