import classNames from "clsx";
import { useField } from "formik";
import { size } from "lodash";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextButton } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { FieldFocusWrapper } from "common/form/FieldFocusWrapper/FieldFocusWrapper";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { messages } from "../ObjectiveForm/ObjectiveForm.messages";
import { ObjectiveTagsNames } from "../ObjectiveForm/ObjectiveTags/ObjectiveTagsNames/ObjectiveTagsNames";

type Props = {
  children?: React.ReactNode;
  name: string;
  setFocusedField: (fieldName: string) => void;
};

export const ShowObjectiveTags = ({
  name,
  children,
  setFocusedField,
}: Props): JSX.Element => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [field] = useField(name);
  const [tagsDisplayed, setTagsDisplayed] = useState(false);
  useOutsideAlerter(ref, () => setTagsDisplayed(false));

  return (
    <FieldFocusWrapper name={name} setFocusedField={setFocusedField}>
      <div ref={ref} className="relative">
        <div className="flex items-center">
          <div className="mr-1 text-sm">
            {intl.formatMessage(messages.tagsLabel)}
          </div>
          <div className={classNames({ invisible: tagsDisplayed })}>
            <ObjectiveTagsNames ids={field.value} />
          </div>

          <div
            className={classNames("flex h-8 items-center", {
              invisible: tagsDisplayed,
            })}
          >
            <Show
              fallback={
                <IconButton
                  className="rounded-full border border-dashed p-1 text-blue-500"
                  data-cy="addObjectiveTag"
                  name="add"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setTagsDisplayed(true);
                  }}
                  size="2xl"
                />
              }
              when={size(field.value) > 0}
            >
              <TextButton
                data-cy="editObjectiveTag"
                onClick={() => {
                  setTagsDisplayed(true);
                }}
                size="small"
                text={<FormattedMessage defaultMessage="Edit" id="wEQDC6" />}
              />
            </Show>
          </div>
        </div>
        <Show when={tagsDisplayed}>
          <div className="absolute -top-4 right-0 z-modal-prio w-11/12">
            {children}
          </div>
        </Show>
      </div>
    </FieldFocusWrapper>
  );
};
