import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { isSet } from "utils/isSet";

const StatusCircleHealthy = () => (
  <div className="h-2.5 w-2.5 inline-block mr-[5px] rounded-[50%] bg-green-500" />
);

const StatusCircleUnhealthy = () => (
  <div className="h-2.5 w-2.5 inline-block mr-[5px] rounded-[50%] bg-yellow-500" />
);

type Props = {
  isHealthy?: boolean;
  kpiValue?: ReactNode;
};

export const KpiStatusHealth = ({ isHealthy, kpiValue }: Props) => {
  return (
    <div className="flex items-center" data-cy="kpiStatusHealth">
      <Show when={isSet(kpiValue)}>
        <span className="mr-4 text-[36px]">{kpiValue}</span>
      </Show>
      <Show when={isHealthy} fallback={<StatusCircleUnhealthy />}>
        <StatusCircleHealthy />
      </Show>

      <div className="text-slate-800">
        <Show
          when={isHealthy}
          fallback={
            <FormattedMessage
              defaultMessage="unhealthy"
              id="kpis:list:unhealthy"
            />
          }
        >
          <FormattedMessage defaultMessage="healthy" id="kpis:list:healthy" />
        </Show>
      </div>
    </div>
  );
};
