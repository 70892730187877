import { Form, Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { FieldWithHelper } from "common/form/FieldWithHelper/FieldWithHelper";
import { FormInput } from "common/form/FormInput/FormInput";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { messages } from "./StrategicPillarForm.messages";

const schema = Yup.object().shape({
  description: Yup.string().nullable(),
  name: Yup.string().required("Required"),
});

type InitialValues = {
  description: string;
  name: string;
};

type Props = {
  initialValues: InitialValues;
  isEdit?: boolean;
  onCancel: () => void;
  onSubmit: (values: FormikValues) => void;
};

export const StrategicPillarForm: React.FC<Props> = ({
  initialValues,
  isEdit = false,
  onSubmit,
  onCancel,
}) => {
  const [focusedField, setFocusedField] = useState("name");
  const intl = useIntl();

  const handleSubmit = (values: FormikValues) => {
    onSubmit(values);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, submitForm }) => {
          return (
            <Form
              data-cy="strategicPillarForm"
              onClick={() => setFocusedField("")}
            >
              <FieldWithHelper
                autoFocus
                component={FormInput}
                data-cy="strategicPillarNameField"
                focusedField={focusedField}
                label={intl.formatMessage(messages.nameLabel)}
                name="name"
                placeholder={intl.formatMessage(messages.namePlaceholder)}
                renderHelper={() => (
                  <div>
                    <p className="mb-2.5">
                      <FormattedMessage
                        defaultMessage="Strategy is a set of choices that explain how your organization is different. With Strategic Pillars, you’re able to make sense of those choices and communicate your strategy in a way that everyone understands."
                        id="strategicpillar:forms:helper:text:title"
                      />
                    </p>
                    <p className="mt-2.5">
                      <FormattedMessage
                        defaultMessage="Need inspiration ? View {examples}"
                        id="strategicpillar:examples:learnAboutOkr"
                        values={{
                          examples: (
                            <a
                              href="https://support.perdoo.com/en/articles/4725666-strategic-pillars#h_1a3fba8dd1"
                              rel="noopener noreferrer"
                              tabIndex={-1}
                              target="_blank"
                            >
                              <FormattedMessage
                                defaultMessage="examples"
                                id="wizard:examples"
                              />
                            </a>
                          ),
                        }}
                      />
                    </p>
                  </div>
                )}
                setFocusedField={setFocusedField}
              />
              <FieldWithHelper
                className="h-24 min-h-full"
                component={FormTextArea}
                data-cy="strategicPillarDescriptionField"
                focusedField={focusedField}
                label={intl.formatMessage(messages.descriptionLabel)}
                minimizedView={false}
                name="description"
                optional
                placeholder={intl.formatMessage(
                  messages.descriptionPlaceholder
                )}
                renderHelper={() => (
                  <FormattedMessage {...messages.descriptionHelperText} />
                )}
                setFocusedField={setFocusedField}
              />
              <ModalFooter>
                <Button
                  data-cy="addStrategicPillarButton"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  {intl.formatMessage({
                    defaultMessage: "Save",
                    id: "global:save",
                  })}
                </Button>
                {!isEdit && (
                  <Button
                    data-cy="closeModalButton"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={onCancel}
                    type="button"
                    variant="ghost"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Cancel",
                      id: "global:cancel",
                    })}
                  </Button>
                )}
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
