import React from "react";
import { findDOMNode } from "react-dom";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'Component' implicitly has an 'any' type... Remove this comment to see the full error message
export const onClickOutside = (Component) =>
  class WrappedComponent extends React.Component {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    constructor(props) {
      super(props);

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'WrappedCom... Remove this comment to see the full error message
      this.node = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'WrappedCom... Remove this comment to see the full error message
      if (this.node.current.handleClickOutside) {
        document.addEventListener("click", this.handleClickOutside, true);
      }
    }

    componentWillUnmount() {
      document.removeEventListener("click", this.handleClickOutside, true);
    }

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    handleClickOutside(e) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'WrappedCom... Remove this comment to see the full error message
      const node = findDOMNode(this.node.current); //eslint-disable-line
      if (!node?.contains(e.target)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'WrappedCom... Remove this comment to see the full error message
        this.node.current.handleClickOutside(e);
      }
    }

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'WrappedCom... Remove this comment to see the full error message
      return <Component ref={this.node} {...this.props} />;
    }
  };
