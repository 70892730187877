import { useField } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FormRadioGroup } from "common/form/FormRadioGroup/FormRadioGroup";
import { RadioButton } from "common/inputs/RadioGroup";
import { useJiraFormContext } from "common/integration/JiraFormLogic/JiraFormLogic";

export type JiraSourceType = "jql" | "issue";

type Props = {
  name?: string;
  onChange?: (sourceType: JiraSourceType) => void;
};

export const JiraSourceTypeField = ({
  name = "jiraSourceType",
  onChange,
}: Props): JSX.Element => {
  const [field] = useField(name);

  const { onSourceTypeSelected } = useJiraFormContext();

  const handleChange = (value: string) => {
    onSourceTypeSelected?.(value as JiraSourceType);
    onChange?.(value as JiraSourceType);
  };

  return (
    <FormRadioGroup
      className="flex space-x-6"
      field={field}
      onChange={handleChange}
    >
      <RadioButton value="jql">
        <FormattedMessage defaultMessage="JQL query" id="XaXXhS" />
      </RadioButton>
      <RadioButton value="issue">
        <FormattedMessage defaultMessage="Individual Issue" id="BvFmVo" />
      </RadioButton>
    </FormRadioGroup>
  );
};
