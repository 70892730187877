import { CHARGEBEE_SITE } from "config";
import { useEffect, useState } from "react";

export const useChargebee = () => {
  const [instance, setInstance] = useState<ChargebeeInstance>();
  useEffect(() => {
    const instance = window.Chargebee?.init({
      site: CHARGEBEE_SITE,
    });
    window.Chargebee?.registerAgain();
    setInstance(instance);
  }, []);

  return instance;
};
