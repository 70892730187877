import { Integration, PerdooApiIntegrationApplicationChoices } from "@graphql";
import { Derive } from "@shoooe/derive";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import invariant from "tiny-invariant";
import { TextButton } from "common/buttons";
import { Show } from "common/controlFlow";
import { AsanaModal } from "common/integration/AsanaModal/AsanaModal";
import { getLabelForApplication } from "common/integration/hooks/useIntegrations";
import { IntegrationDropdown } from "common/integration/IntegrationDropdown/IntegrationDropdown";
import { IntegrationLogo } from "common/integration/IntegrationLogo/IntegrationLogo";
import { JiraModal } from "common/integration/JiraModal/JiraModal";

type IntegrationProp = Derive<
  Integration,
  {
    application: true;
    id: true;
  }
>;

type Payload = {
  integration: string | null;
  integrationField: string | null;
};

type GoalProp = {
  integration: IntegrationProp | null;
  integrationField: string | null;
};

type Props = {
  disabled?: boolean;
  goal: GoalProp;
  onChange?: (value: Payload) => void;
};

export const InlineIntegrationPicker = ({
  goal,
  onChange,
  disabled,
}: Props) => {
  const [integration, setIntegration] = useState<IntegrationProp | null>(null);

  const linkIntegration = (integrationField: string) => {
    invariant(!!integration && !!integration.id);
    onChange?.({
      integration: integration.id,
      integrationField,
    });
  };

  const goalHasIntegration = !!goal.integration && !!goal.integrationField;
  const unlinkIntegration = goalHasIntegration
    ? () => {
        onChange?.({
          integration: null,
          integrationField: null,
        });
      }
    : undefined;

  const isAsana =
    integration?.application === PerdooApiIntegrationApplicationChoices.Asana;
  const isJira =
    integration?.application === PerdooApiIntegrationApplicationChoices.Jira;
  return (
    <>
      {!!goal.integration ? (
        <div className="flex items-center space-x-2">
          <IntegrationLogo application={goal.integration.application} />
          <div>{getLabelForApplication(goal.integration.application)}</div>
          <TextButton
            data-cy="3Uj8yb7D--EO6mBujlVRE"
            className="text-blue-500"
            size="small"
            onClick={() => setIntegration(goal.integration)}
          >
            <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
          </TextButton>
        </div>
      ) : (
        <IntegrationDropdown onSelect={setIntegration}>
          <TextButton size="small" disabled={disabled}>
            +&nbsp;
            <FormattedMessage defaultMessage="Add integration" id="e3eOFV" />
          </TextButton>
        </IntegrationDropdown>
      )}
      <Show when={isAsana}>
        <AsanaModal
          value={goal.integrationField}
          onClose={() => setIntegration(null)}
          onSubmit={linkIntegration}
          onDisconnect={unlinkIntegration}
        />
      </Show>
      <Show when={isJira}>
        <JiraModal
          value={goal.integrationField}
          onClose={() => setIntegration(null)}
          onSubmit={linkIntegration}
          onDisconnect={unlinkIntegration}
        />
      </Show>
    </>
  );
};
