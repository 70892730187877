import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { EmojiPicker } from "common/emojiPicker/EmojiPicker";
import { Panel } from "common/overlay/Panel/Panel";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { useGetSlackEmojis } from "./hook/useGetSlackEmojis/useGetSlackEmojis";
import { useReaction } from "./hook/useReaction/useReaction";

interface Props {
  entityId: string;
}

export const ReactionButton = ({ entityId }: Props): JSX.Element => {
  const { customEmojis } = useGetSlackEmojis();
  const { giveReaction, toggleTooltipOpen, tooltipOpen } = useReaction({
    source: "home",
  });

  const handleEmojiSelect = (emoji: any) => {
    giveReaction({ kudoId: entityId, name: emoji.id });
    toggleTooltipOpen();
  };

  return (
    <WithTooltip
      interactive
      theme="perdoo-white"
      tooltip={
        tooltipOpen && (
          <Panel onClose={toggleTooltipOpen}>
            <EmojiPicker
              customEmojis={customEmojis}
              onSelect={handleEmojiSelect}
            />
          </Panel>
        )
      }
      visible={tooltipOpen}
    >
      <IconButton
        data-cy="UKtSG24VmWGaPcnc0hhc5"
        iconClass="mx-auto"
        iconType="outlined"
        name="add_reaction"
        onClick={toggleTooltipOpen}
        size="lg"
      />
    </WithTooltip>
  );
};
