import { get } from "lodash";
import React from "react";
import styled from "styled-components";
import { objectTypes } from "../../../constants/objects";
import { resultTypes } from "../../../constants/resultTypes";
import { ResultsFilterContext } from "../../result/ResultsFilterContext/ResultsFilterContext";
import { ObjectiveWidget } from "../ObjectiveWidget/ObjectiveWidget";

const PageBreak = styled.div`
  margin-bottom: 15px;
  @media print {
    page-break-before: auto;
    page-break-inside: avoid;
  }
`;

export const profileObjectiveMapper =
  ({
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'resultFilter' implicitly has an '... Remove this comment to see the full error message
    resultFilter,
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'entityType' implicitly has an 'an... Remove this comment to see the full error message
    entityType,
    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'entityId' implicitly has an 'any'... Remove this comment to see the full error message
    entityId,
  }) =>
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'objectives' implicitly has an 'any' typ... Remove this comment to see the full error message
  (objectives) => {
    const filters = {
      resultsType: resultFilter || undefined,
      userProfile: entityType === objectTypes.user ? entityId : undefined,
    };

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'objective' implicitly has an 'any' type... Remove this comment to see the full error message
    return objectives.map((objective) => (
      <PageBreak key={objective.id}>
        <ResultsFilterContext.Provider value={filters}>
          <ObjectiveWidget objective={objective} />
        </ResultsFilterContext.Provider>
      </PageBreak>
    ));
  };

export const getProfileFilters = ({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'entityType' implicitly has an 'an... Remove this comment to see the full error message
  entityType,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'entityId' implicitly has an 'any'... Remove this comment to see the full error message
  entityId,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'selectedTfId' implicitly has an '... Remove this comment to see the full error message
  selectedTfId,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'stageFilter' implicitly has an 'a... Remove this comment to see the full error message
  stageFilter,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'resultFilter' implicitly has an '... Remove this comment to see the full error message
  resultFilter,
}) => {
  const userResultFilters = {
    [resultTypes.keyResult]: { user_krs: entityId },
    [resultTypes.initiative]: { user_inits: entityId },
  };

  return {
    ...(selectedTfId ? { timeframe: selectedTfId } : {}),
    ...(stageFilter ? { stage: stageFilter } : {}),
    ...(resultFilter ? { key_results__type: resultFilter } : {}),
    ...(entityType === objectTypes.user
      ? { ...get(userResultFilters, resultFilter, { user_profile: entityId }) }
      : {}),
    ...(entityType === objectTypes.company || entityType === objectTypes.group
      ? { owner: entityId }
      : {}),
  };
};
