import classNames from "clsx";
import React from "react";
import { Entity, StackedAvatar } from "common/avatar";
import { GoalUpdateNoteSubtext } from "common/checkIn/CheckInGoal/GoalUpdateNoteSubtext/GoalUpdateNoteSubtext";
import { ResultValues } from "common/checkIn/CheckInGoal/GoalValues/ResultValues";
import { Panel } from "common/collapsible";
import { For } from "common/controlFlow";
import { EntityIcon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { ReportObjective } from "common/progressReports/Objective";
import { ReportResult } from "common/progressReports/Result";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { mapEdges } from "utils/mapEdges";
import { twClass } from "utils/twClass";

type Props = {
  objective: ReportObjective & { results: ReportResult[] };
};

export const SidebarCheckInObjective = ({ objective }: Props): JSX.Element => {
  const { objectiveHref, resultHref } = useModalRouter();

  return (
    <Panel
      header={
        <div className="flex w-full items-center">
          <StackedAvatar
            maxCount={2}
            size="medium"
            urls={mapEdges(objective.groups.edges).map((group) => group.avatar)}
          />
          <h4 className="ml-4">
            <AnchorNext
              className={twClass(
                "font-semibold text-slate-800 hover:text-blue-500"
              )}
              href={objectiveHref(
                { oId: objective.id },
                { stack: true }
              ).toString()}
            >
              {objective.name}
            </AnchorNext>
          </h4>
        </div>
      }
    >
      <div className="rounded border bg-white border-slate-300">
        <For each={objective.results}>
          {(result) => {
            const hasParent = false; // TODO: add back support for nested inits when we redesign

            return (
              <article
                aria-label={`check-in-goal-${name}`}
                className={classNames(
                  "flex items-center",
                  "py-3 pl-5",
                  "first:border-t-0",
                  // styles below to make indents work for child initiatives, even though each goal (parent or child) is just a CheckInGoal.
                  {
                    "border-t border-slate-300": !hasParent,
                    "last:mb-4": hasParent,
                  }
                )}
                data-cy={`checkInGoal-${name}`}
              >
                <span className="ml-3 h-20 w-6 border-l border-slate-300" />
                <div className="flex items-center overflow-hidden">
                  <Entity
                    avatarElement={<EntityIcon entity={result.type} />}
                    className="gap-2 items-start"
                    href={resultHref(result.id, { stack: true }).toString()}
                    name={result.name}
                  >
                    <div className="space-y-2 pt-2">
                      <ResultValues
                        className="w-auto grow flex-row gap-2"
                        result={result}
                      />
                      {result.delta.lastCommit?.description && (
                        <GoalUpdateNoteSubtext
                          description={result.delta.lastCommit.description}
                        />
                      )}
                    </div>
                  </Entity>
                </div>
              </article>
            );
          }}
        </For>
      </div>
    </Panel>
  );
};
