import { isNil } from "lodash";

/*
  Function used to optionally call free functions.
  `fn` is called only when `obj` is a non-nil value (not null or undefined).
  If `obj` is null or undefined then `obj` is returned and `fn` is not called.

  For methods you can already do `obj?.fn(...)`. This function allows the same
  pattern to be used with free functions (functions not associated with an object).
*/
export function mapFn<T, R>(
  obj: T | undefined,
  fn: (input: T) => R
): R | undefined;

export function mapFn<T, R>(obj: T | null, fn: (input: T) => R): R | null;

export function mapFn<T, R>(
  obj: T | null | undefined,
  fn: (input: T) => R
): R | null | undefined;

export function mapFn<T, R>(
  obj: T | undefined | null,
  fn: (input: T) => R
): R | undefined | null {
  if (isNil(obj)) {
    return obj;
  }
  return fn(obj);
}
