import {
  PerdooApiIntegrationApplicationChoices,
  useGetIntegrationsForSelectQuery,
} from "@graphql";
import { filter, orderBy } from "lodash";
import { useMemo } from "react";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { exhaustiveCheck } from "utils/exhaustiveCheck";
import { mapEdges } from "utils/mapEdges";

export type IntegrationOption = {
  application: PerdooApiIntegrationApplicationChoices;
  label: string;
  value: string | undefined;
};

export const getLabelForApplication = (
  application: PerdooApiIntegrationApplicationChoices
) => {
  switch (application) {
    case PerdooApiIntegrationApplicationChoices.Asana:
      return "Asana";
    case PerdooApiIntegrationApplicationChoices.Jira:
      return "Jira";
    case PerdooApiIntegrationApplicationChoices.Slack:
      return "Slack";
    default:
      return exhaustiveCheck(application);
  }
};

export const useIntegrationOptions = () => {
  const { show: showAsana } = useFeatureAccess("INTEGRATION_ASANA");
  const { show: showJira } = useFeatureAccess("INTEGRATION_JIRA");

  const { data } = useGetIntegrationsForSelectQuery();
  const integrations = useMemo(
    () => mapEdges(data?.integrations.edges),
    [data]
  );

  const getPrimaryIntegrationFor = (
    application: PerdooApiIntegrationApplicationChoices
  ) => {
    const filteredIntegrations = filter(
      integrations,
      (int) => int.application === application
    );

    const sortedIntegrations = orderBy(
      filteredIntegrations,
      ["createdDate"],
      ["desc"]
    );
    return sortedIntegrations[0];
  };

  const applications = [];
  if (showAsana) {
    applications.push(PerdooApiIntegrationApplicationChoices.Asana);
  }
  if (showJira) {
    applications.push(PerdooApiIntegrationApplicationChoices.Jira);
  }

  const options: IntegrationOption[] = applications.map((app) => {
    const primaryIntegration = getPrimaryIntegrationFor(app);
    return {
      application: app,
      label: getLabelForApplication(app),
      value: primaryIntegration?.id,
    };
  });

  const anyIntegrationEnabled = options.some((opt) => !!opt.value);

  return {
    anyIntegrationEnabled,
    integrations,
    options,
  };
};
