/**
 * This function is used to type check exhaustive default cases in switch statements.
 * @example
 * type T = "a" | "b" | "c";
 * const t: T = ...;
 * switch (t) {
 *   case "a": ...;
 *   case "b": ...;
 *   case "c": ...;
 *   default:
 *     exhaustiveCheck(t);
 *     break;
 */
// It's very important that we take an input here. The way this works,
// as shown in the example, is by taking the object that we are switching on.
// That object has to have a type of never (i.e. there are no other options) when we call exchaustiveCheck
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const exhaustiveCheck = (input: never): never => {
  throw new Error("This should never be thrown");
};
