import { showNpsSurvey as show } from "utils/tracker";
import { useCompany } from "../useCompany/useCompany";
import { useCurrentUser } from "../useCurrentUser/useCurrentUser";

export const useNpsSurvey = () => {
  const company = useCompany();
  const user = useCurrentUser();

  return () => {
    if (user && !company.loading) {
      show(user, company);
    }
  };
};
