import Image from "next/image";
import React from "react";
import { routes } from "route-configs";
import { Button } from "common/buttons";

export const GoogleCalendarButton = (): JSX.Element => {
  return (
    <Button
      href={routes.settings.user.integrations}
      hrefTarget="_blank"
      variant="ghost"
    >
      <div className="flex items-center space-x-2">
        <div>
          <Image
            alt="Google Calendar Logo"
            height={20}
            src="/images/google_calendar.svg"
            width={20}
          />
        </div>
        <div className="text-base font-medium">Google Calendar</div>
      </div>
    </Button>
  );
};
