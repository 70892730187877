import { WatchQueryFetchPolicy } from "@apollo/client";
import { CurrentUserFragment, useGetCurrentUserInfoQuery } from "@graphql";

type Options = {
  fetchPolicy?: WatchQueryFetchPolicy;
};

export type UseCurrentUserHook = CurrentUserFragment | undefined | null;

export const useCurrentUser = (options: Options = {}): UseCurrentUserHook => {
  const { data } = useGetCurrentUserInfoQuery({
    fetchPolicy: options.fetchPolicy,
  });
  return data?.me;
};
