import {
  CommitDefaultFieldsFragment,
  CommitStatusFragment,
  CommitValuesFragment,
  KeyResultDefaultFragment,
  KeyResultMetricDetailsFragment,
  KeyResultValuesFragment,
  KpiDefaultFragment,
  KpiGoalFragment,
  KpiMetricUnitFragment,
} from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Status } from "common/charts/Status/Status";
import {
  getCommitDeltaColor,
  getNormalizedResultCommitDelta,
} from "common/commit/utils";
import { Target } from "common/event/Target/Target";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { ProgressDelta } from "common/progress/ProgressDelta/ProgressDelta";
import { colorTheme } from "constants/colorTheme";
import { MetricUnits, MetricValue } from "constants/metric";
import { objectTypes } from "constants/objects";

const InlineWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 23px;
  color: ${colorTheme.slate[500]};
`;

type Commit = CommitDefaultFieldsFragment &
  Pick<CommitValuesFragment, "delta" | "valueBefore"> &
  CommitStatusFragment;

export type ShortProgressUpdateKpi = Omit<KpiDefaultFragment, "name"> &
  KpiGoalFragment &
  KpiMetricUnitFragment;
export type ShortProgressUpdateResult = Omit<KeyResultDefaultFragment, "name"> &
  Pick<KeyResultValuesFragment, "endValue" | "startValue"> &
  Pick<KeyResultMetricDetailsFragment, "metricUnit">;
export type ProgressUpdateTarget =
  | ShortProgressUpdateKpi
  | ShortProgressUpdateResult;

export const isKpi = (
  target: ProgressUpdateTarget
): target is ShortProgressUpdateKpi => target.__typename === objectTypes.kpi;
export const isKR = (
  target: ProgressUpdateTarget
): target is ShortProgressUpdateResult =>
  target.__typename === objectTypes.keyresult;

type Props = {
  commit: Commit;
  entity: ShortProgressUpdateKpi | ShortProgressUpdateResult;
  isCheckIn?: boolean;
};

export const ShortProgressUpdate = ({
  commit,
  entity,
  isCheckIn = false,
}: Props): JSX.Element => {
  const { delta, valueBefore, status } = commit;
  const { metricUnit } = entity;

  let value: number;
  let metric: MetricValue | undefined;
  let deltaColor: string;
  if (isKpi(entity)) {
    value = delta;
    metric = undefined;
    deltaColor = getCommitDeltaColor(delta, entity);
  } else {
    const normalizedDelta = getNormalizedResultCommitDelta(commit, entity);
    value = normalizedDelta;
    metric = MetricUnits.PERCENTAGE;
    deltaColor = getCommitDeltaColor(normalizedDelta, entity);
  }

  const valueAfter = valueBefore ? valueBefore + delta : delta;
  const afterVal = (
    <Target
      element={<FormattedMetric value={valueAfter} unit={metricUnit} />}
    />
  );
  const deltaVal = (
    <InlineWrapper className="text-sm">
      <ProgressDelta color={deltaColor} metric={metric} small value={value} />
    </InlineWrapper>
  );

  const statusVal = (
    <InlineWrapper className="text-sm">
      <Status status={status} />
    </InlineWrapper>
  );

  if (isCheckIn) {
    return (
      <FormattedMessage
        defaultMessage="to {after} {delta} {status}"
        id="event:formatter:commit:progressUpdated:short:checkIn"
        values={{ after: afterVal, delta: deltaVal, status: statusVal }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="to {after} {delta} {status}"
      id="event:formatter:commit:progressUpdated:short"
      values={{ after: afterVal, delta: deltaVal, status: statusVal }}
    />
  );
};
