import { isFunction } from "lodash";
import React, { ReactNode, useState } from "react";
import {
  ReportBag,
  ReportContextProvider,
} from "common/reports/ReportContext/ReportContext";
import {
  ReportLayout,
  useReportLayout,
} from "common/reports/utils/useReportLayout";

type Props = {
  children: ((props: ReportBag) => ReactNode) | ReactNode;
  widgets: ReportLayout;
};

/**
 * main report container providing context for layout and modal.
 */
export const Report = ({ children, widgets }: Props): JSX.Element => {
  const [modal, setModal] = useState<ReactNode>(undefined);
  const [modalFooter, setModalFooter] = useState<ReactNode>(undefined);
  const [modalTitle, setModalTitle] = useState("");
  const [modalIsPaginating, setModalIsPaginating] = useState<
    false | "backward" | "forward"
  >(false);

  const layout = useReportLayout(widgets);

  const handleClose = () => {
    setModal(undefined);
    setModalFooter(undefined);
    setModalTitle("");
  };

  const reportBag: ReportBag = {
    drillDownPageSize: 10,
    handleClose,
    layout,
    modal,
    modalFooter,
    modalIsPaginating,
    modalTitle,
    setModal,
    setModalFooter,
    setModalIsPaginating,
    setModalTitle,
  };

  return (
    <ReportContextProvider value={reportBag}>
      {isFunction(children) ? children(reportBag) : children}
    </ReportContextProvider>
  );
};
