import {
  Group,
  namedOperations,
  useUpsertGroupMutationMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { trim } from "lodash";
import { useIntl } from "react-intl";
import { ADD_GROUP } from "constants/tracking";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";

type CreatedGroup = Derive<Group, { avatar: true; id: true; name: true }>;

type Hook = {
  createGroup: (name: string) => Promise<CreatedGroup | null>;
  isCreating: boolean;
};

export const useGroupCreation = (): Hook => {
  const intl = useIntl();
  const [addGroup, { loading: isCreating }] = useUpsertGroupMutationMutation({
    refetchQueries: [
      namedOperations.Query.getOrderedGroups,
      namedOperations.Query.getGroupsForSelect,
    ],
  });

  const createGroup = async (name: string): Promise<CreatedGroup | null> => {
    const response = await addGroup({
      variables: {
        input: {
          kpisEnabled: true,
          name: trim(name),
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertGroup?.errors
    );

    if (hasError) {
      toast.success(
        intl.formatMessage({
          defaultMessage: "Error creating group",
          id: "OZnxxh",
        })
      );
      return null;
    }

    toast.success(
      intl.formatMessage({
        defaultMessage: "Group has been created!",
        id: "7IGKTg",
      })
    );
    track(ADD_GROUP, {
      kpiEnabled: true,
      source: "inline creation",
    });
    const group = response.data?.upsertGroup?.group ?? null;
    return group;
  };

  return {
    createGroup,
    isCreating,
  };
};
