import React, { Fragment, ReactElement } from "react";
import { useIntl } from "react-intl";
import { TextButton } from "common/buttons";
import { DatePicker } from "common/inputs/DatePicker/DatePicker";
import { Dropdown } from "common/overlay/Dropdown";
import { DropdownContentPlacement } from "common/overlay/Dropdown/DropdownContent/DropdownContent";
import { DUE_DATE_ADDED_TO_ACTION_ITEM } from "constants/tracking";
import { track } from "utils/tracker";

type Props = {
  children: ReactElement;
  handleDateChange: (date: string | null) => void;
  placement: DropdownContentPlacement;
  selectedDate: string | null;
};

export const OneOnOneDueDateDropdown = ({
  children,
  handleDateChange,
  placement,
  selectedDate,
}: Props): JSX.Element => {
  const intl = useIntl();
  const date = selectedDate ? new Date(selectedDate) : new Date();

  return (
    <Dropdown data-testid="actionItem-dueDate">
      {({ close }) => (
        <>
          <Dropdown.Trigger>{children}</Dropdown.Trigger>
          <Dropdown.Content className="space-y-1" placement={placement}>
            <DatePicker
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ inline: true; minDate: Date; onChange: (da... Remove this comment to see the full error message
              date={date}
              onChange={(dueDate: string): void => {
                handleDateChange(dueDate);
                track(DUE_DATE_ADDED_TO_ACTION_ITEM);
                close();
              }}
              prevMonthButtonDisabled
            />
            <div className="flex items-center border-t p-1 pl-5 border-slate-300">
              <TextButton
                className="text-blue-500 hover:text-blue-700"
                onClick={() => handleDateChange(null)}
                size="small"
                text={intl.formatMessage({
                  defaultMessage: "Remove due date",
                  id: "hBLppm",
                })}
              />
            </div>
          </Dropdown.Content>
        </>
      )}
    </Dropdown>
  );
};
