import { TimelineEventFragmentFragment } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ShortProgressUpdate } from "common/progress/ShortProgressUpdate/ShortProgressUpdate";
import { Target } from "../Target/Target";

// the event is coming from TimelineContext
type FormattedCommitEventProps = {
  event?: TimelineEventFragmentFragment;
  showTargetNames?: boolean;
};

export const FormattedCommitEvent = ({
  showTargetNames,
  event,
}: FormattedCommitEventProps): JSX.Element | null => {
  if (!event) return null;
  const { commit, extra } = event;
  const target = commit?.keyResult ?? commit?.kpi;

  return (
    <>
      {showTargetNames && (
        <FormattedMessage
          defaultMessage="updated {name}"
          id="event:formatter:commit:updateGoal"
          values={{ name: <Target element={`${extra?.target_name} `} /> }}
        />
      )}
      {!showTargetNames && (
        <>
          <FormattedMessage
            defaultMessage="added an update"
            id="event:formatter:commit:addedUpdate"
          />
          &nbsp;
        </>
      )}
      {commit && target && (
        <ShortProgressUpdate commit={commit} entity={target} />
      )}
    </>
  );
};
