import clsx from "clsx";
import React from "react";

type Props = {
  color: string | null;
};

// Reference: https://forum.asana.com/t/color-codes-in-api/23716/4
const ASANA_TO_PERDOO_COLOR_MAP: Record<string, string> = {
  "dark-brown": "bg-[#EDC22B]",
  "dark-green": "bg-[#66D173]",
  "dark-orange": "bg-[#FA6137]",
  "dark-pink": "bg-[#E8519C]",
  "dark-purple": "bg-[#7A73EC]",
  "dark-red": "bg-[#E63B52]",
  "dark-teal": "bg-[#2BABE8]",
  "light-blue": "bg-[#4587DD]",
  "light-green": "bg-[#A5CD3E]",
  "light-orange": "bg-[#FA9926]",
  "light-pink": "bg-[#E167E1]",
  "light-purple": "bg-[#A966E0]",
  "light-red": "bg-[#FA92AE]",
  "light-teal": "bg-[#41C3AB]",
  "light-warm-gray": "bg-[#8DA2A5]",
};

const DEFAULT_PERDOO_COLOR = "bg-slate-500";

export const AsanaProjectIcon = ({ color }: Props): JSX.Element => {
  const bgClass =
    (color && ASANA_TO_PERDOO_COLOR_MAP[color]) ?? DEFAULT_PERDOO_COLOR;
  return <div className={clsx("h-4 w-4 rounded", bgClass)} />;
};
