import {
  Commit,
  CommitStatus,
  namedOperations,
  UpsertCommitMutationInput,
  useUpsertCommitMutation,
} from "@graphql";
import { find } from "lodash";
import { NextRouter, useRouter } from "next/router";
import { routes } from "route-configs";
import { UPDATE_PROGRESS } from "constants/tracking";
import { useNpsSurvey } from "hooks/useNpsSurvey/useNpsSurvey";
import { handleErrors } from "utils/graphql/handleErrors";
import { track } from "utils/tracker";

type CommitActionsCommit = {
  commitDate?: Commit["commitDate"];
  delta?: Commit["delta"];
  description?: Commit["description"];
  fromKpiBoard?: Commit["fromKpiBoard"];
  integration?: string;
  keyResult?: string;
  kpi?: string;
  status?: Commit["status"];
  value: Commit["value"];
};

const progressUpdateSources: {
  check: (router: NextRouter) => boolean;
  source: string;
}[] = [
  {
    check: ({ query }) => !!query.oId || !!query.rId || !!query.kpiId,
    source: "detail modal",
  },
  {
    check: ({ asPath }) => asPath.startsWith(routes.checkIn.main),
    source: "check-ins",
  },
  {
    check: ({ asPath }) => asPath.startsWith(routes.home.main),
    source: "home",
  },
  {
    check: ({ asPath }) => asPath.startsWith(routes.groups.main),
    source: "team profile page",
  },
  {
    check: ({ asPath }) => asPath.startsWith(routes.users.main),
    source: "user profile page",
  },
];

export const useCommitActions = () => {
  const router = useRouter();
  const [upsertCommit] = useUpsertCommitMutation({
    refetchQueries: [
      namedOperations.Query.getCurrentUserInfo,
      namedOperations.Query.GetCompanyKpis,
      namedOperations.Query.getGroupHeader,
      namedOperations.Query.getGrowthBoardKpis,
      namedOperations.Query.getKpiBoardKpis,
      namedOperations.Query.getKpiModalBaseKpi,
      namedOperations.Query.getKpiModalBaseKpiTimeline,
      namedOperations.Query.getKpiProgressGraph,
      namedOperations.Query.getKpisByGroup,
      namedOperations.Query.getResult,
      namedOperations.Query.getResultProgressGraph,
      namedOperations.Query.getResultTimeline,
      namedOperations.Query.getRoadmap,
      namedOperations.Query.getTimelineKpiSnapshots,
      namedOperations.Query.getTimelineKpiStats,
      namedOperations.Query.getUnalignedObjectives,
      namedOperations.Query.KpisByUser,
    ],
  });
  const showNpsSurvey = useNpsSurvey();

  const addCommit = async (
    { value, description, commitDate, status, ...values }: CommitActionsCommit,
    type: string
  ) => {
    const commitData = {
      ...values,
      commitDate,
      description,
      status: status ?? CommitStatus.NoStatus,
      value,
    };

    const response = await upsertCommit({
      variables: {
        input: commitData,
      },
    });

    handleErrors(response, response.data?.upsertCommit?.errors);

    const progressUpdateSource = find(progressUpdateSources, ({ check }) =>
      check(router)
    );

    track(UPDATE_PROGRESS, {
      added_comment: !!commitData.description,
      delta: commitData.delta,
      initiativeCompleted: value === 100,
      okr_type: type,
      source: progressUpdateSource?.source || "other",
      statusSet: commitData.status,
    });
    showNpsSurvey();
    return response;
  };

  const updateCommit = async (values: UpsertCommitMutationInput) => {
    const input = {
      ...values,
      status: values.status,
    };
    const response = await upsertCommit({ variables: { input } });

    handleErrors(response, response.data?.upsertCommit?.errors);

    return response;
  };

  return { addCommit, updateCommit };
};
