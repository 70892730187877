import {
  namedOperations,
  useUpsertOneOneOnOneMeetingItemTemplateMutation,
} from "@graphql";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { Icon } from "common/icons";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import {
  MEETING_ITEM_TEMPLATE_ADDED,
  MEETING_ITEM_TEMPLATE_UPDATED,
} from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { useCompleteOneOnOneContext } from "../context/completeOneOnOneContext";
import { useUseMeetingItemsTemplate } from "../hooks/useUseMeetingItemsTemplate";
import { MeetingItemTemplateTalkingPointsField } from "./MeetingItemTemplateTalkingPointsField";

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  name: Yup.string().required("Required"),
  talkingPoints: Yup.lazy((val: string[]) =>
    (val.length === 1
      ? Yup.array().of(
          Yup.string().required(
            "A template has to have at least one talking point"
          )
        )
      : Yup.array().of(
          Yup.string().required(
            "A template has to have at least one talking point"
          )
        )
    ).min(1, "A template has to have at least one talking point")
  ),
});

export enum TEMPLATE_MODAL_TYPE {
  "ADD" = "ADD",
  "EDIT" = "EDIT",
}

type MeetingItemTemplateFormValues = {
  description: string;
  id?: string;
  name: string;
  talkingPoints: string[];
};
type Props = {
  close: () => void;
  initialValues: MeetingItemTemplateFormValues;
  isEdit?: boolean;
  onDelete?: () => void;
};

export const OneOnOneMeetingItemTemplateForm = ({
  isEdit = false,
  close,
  initialValues,
  onDelete,
}: Props): JSX.Element => {
  const { meetingId } = useCompleteOneOnOneContext();

  const me = useCurrentUser();

  const { $t } = useIntl();

  const [shouldUseTemplate, setshouldUseTemplate] = useState(false);

  const handleUseTemplate = useUseMeetingItemsTemplate();

  const [upsertMeetingItemsTemplate] =
    useUpsertOneOneOnOneMeetingItemTemplateMutation();

  const title = isEdit ? (
    <FormattedMessage defaultMessage="Edit a template" id="c7k59S" />
  ) : (
    <FormattedMessage defaultMessage="Add new template" id="3dIiGm" />
  );

  const handleUseNewTemplate = async (templateId: string) => {
    const { hasError } = await handleUseTemplate(templateId, meetingId);
    if (!hasError) {
      close();
    }
  };

  const onSubmit = async (
    input: MeetingItemTemplateFormValues,
    actions: FormikHelpers<MeetingItemTemplateFormValues>
  ) => {
    actions.setSubmitting(true);
    // remove empty talking points
    const talkingPoints = input.talkingPoints.filter((tp) => tp).join(",");
    const response = await upsertMeetingItemsTemplate({
      refetchQueries: [namedOperations.Query.getOneOnOneMeetingItemTemplates],
      variables: {
        input: {
          ...input,
          talkingPoints,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertOneOnOneMeetingItemTemplate?.errors
    );
    actions.setSubmitting(false);
    if (hasError) {
      const errorMsg = isEdit
        ? $t({
            defaultMessage: "Error updating meeting item template",
            id: "Xdr9gn",
          })
        : $t({
            defaultMessage: "Error adding meeting item template",
            id: "yfq+vo",
          });
      toast.failure(errorMsg);
      close();
      return;
    }
    const successMsg = isEdit
      ? $t({
          defaultMessage: "meeting item template updated",
          id: "OHqqiB",
        })
      : $t({
          defaultMessage: "Meeting item template added",
          id: "Eoe0Ia",
        });
    toast.success(successMsg);

    if (isEdit) {
      track(MEETING_ITEM_TEMPLATE_UPDATED, {
        amountOfTalkingPoints: input.talkingPoints.length,
        createdBy: me?.id,
        name: input.name,
      });
      close();
      return;
    }
    track(MEETING_ITEM_TEMPLATE_ADDED, {
      amountOfTalkingPoints: input.talkingPoints.length,
      createdBy: me?.id,
      name: input.name,
    });

    const templateId =
      response.data?.upsertOneOnOneMeetingItemTemplate
        ?.oneOnOneMeetingItemTemplate?.id;
    if (templateId && shouldUseTemplate) handleUseNewTemplate(templateId);
    close();
  };
  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio"
      isOpen
      onClose={close}
      scrollable={false}
      title={title}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className="p-6">
            <InputField
              autoFocus
              label={$t({
                defaultMessage: "Name",
                id: "timeframe:form:name:label",
              })}
              name="name"
              placeholder={$t({
                defaultMessage: "E.g. Onboarding",
                id: "GINYPt",
              })}
            />
            <InputField
              label={$t({
                defaultMessage: "Description",
                id: "kpi:forms:description:label",
              })}
              name="description"
              optional
              placeholder={$t({
                defaultMessage: "What is this template for?",
                id: "Fdh7Cc",
              })}
            />
            <MeetingItemTemplateTalkingPointsField name="talkingPoints" />
            <ModalFooter className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Button
                  data-cy="lUp9Ng4ILWHqnH3ceb7Y5"
                  onClick={close}
                  type="button"
                  variant="ghost"
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="global:cancel"
                  />
                </Button>
                <Button
                  data-cy="saveOneOnOneMeeting"
                  data-testid="submitForm"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                  variant={!isEdit ? "ghost" : "normal"}
                >
                  <FormattedMessage defaultMessage="Save" id="global:save" />
                </Button>
                <Show when={!isEdit}>
                  <Button
                    data-cy="saveOneOnOneMeeting"
                    data-testid="submitForm"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => {
                      setshouldUseTemplate(true);
                      submitForm();
                    }}
                    type="submit"
                  >
                    <FormattedMessage
                      defaultMessage="Save & use template"
                      id="IswG+C"
                    />
                  </Button>
                </Show>
              </div>
              <Show
                fallback={
                  <div className="flex items-center space-x-1 text-slate-500">
                    <Icon name="info_outline" size="lg" />
                    <span>
                      <FormattedMessage
                        defaultMessage="1:1 templates are accessible to everyone"
                        id="+biI+y"
                      />
                    </span>
                  </div>
                }
                when={isEdit}
              >
                <IconButton
                  className="text-red-500"
                  data-cy="deleteTemplateButton"
                  name="delete"
                  onClick={onDelete}
                >
                  <FormattedMessage
                    defaultMessage="Delete template"
                    id="AghqfS"
                  />
                </IconButton>
              </Show>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
