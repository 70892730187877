import { PerformanceReviewRepeatFrequency } from "@graphql";
import { Field, useFormikContext } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { routes } from "route-configs";
import { Button } from "common/buttons";
import { FormCategorizedUsersSelect } from "common/form/FormCategorizedUsersSelect/FormCategorizedUsersSelect";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { FormSelect } from "common/form/FormSelect/FormSelect";
import { AnchorNext } from "common/navigation";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { usePerformanceReviewFrequencyOptions } from "common/performanceReview/PerformanceReviewForm/usePerformanceReviewFrequencyOptions/usePerformanceReviewFrequencyOptions";
import { messages } from "./performanceReviewForm.messages";

export type AddPerformanceValues = {
  dueDate: string | null;
  repeatFrequency: PerformanceReviewRepeatFrequency;
  revieweeIds: string[] | null;
  reviewerId: string;
};

type AddPerformanceReviewFormProps = {
  onCancel: () => void;
};

export const AddPerformanceReviewForm = ({
  onCancel,
}: AddPerformanceReviewFormProps): JSX.Element => {
  const intl = useIntl();
  const reviewFrequencyOptions = usePerformanceReviewFrequencyOptions();
  const { isSubmitting, submitForm, handleBlur } =
    useFormikContext<AddPerformanceValues>();
  return (
    <>
      <Field
        component={FormCategorizedUsersSelect}
        data-testid="revieweeIds"
        label={intl.formatMessage(messages.reviewee)}
        name="revieweeIds"
      />
      <Field
        component={FormDatePicker}
        data-testid="meetingDay"
        label={intl.formatMessage(messages.dueDate)}
        name="dueDate"
        onBlur={handleBlur("dueDate")}
      />
      <Field
        component={FormSelect}
        data-cy="repeatFrequency"
        data-testid="repeatFrequency"
        label={intl.formatMessage(messages.repeatFrequency)}
        name="repeatFrequency"
        options={reviewFrequencyOptions}
      />
      <ModalFooter className="flex flex-row justify-between">
        <div className="flex items-center">
          <AnchorNext external href={routes.settings.company.general.templates}>
            <FormattedMessage defaultMessage="Edit templates" id="IfCCBB" />
          </AnchorNext>
        </div>
        <div className="flex space-x-2">
          <Button
            data-cy="XAp0eQuiX5Ctq7Dj6O5et"
            onClick={onCancel}
            type="button"
            variant="ghost"
          >
            <FormattedMessage defaultMessage="Cancel" id="global:cancel" />
          </Button>
          <Button
            data-cy="savePerformanceReview"
            data-testid="submitForm"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={submitForm}
            type="submit"
          >
            <FormattedMessage defaultMessage="Save" id="global:save" />
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
