import classNames from "clsx";
import { isFunction, isNil } from "lodash";
import React, { Component, ReactElement, ReactNode, RefObject } from "react";
import { findDOMNode } from "react-dom";
import { isElement } from "./Dropdown.utils";
import { DropdownContext } from "./DropdownContext";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";

type Props = {
  button?: ReactElement;
  buttonOpen?: ReactElement;
  children?: ReactNode | ((context: DropdownContext) => ReactNode);
  className?: string;
  disabled?: boolean;
  forcePosition?: string;
  hasShadow?: boolean;
  id?: string;
  keepOpen?: boolean;
  /**
   * Whether to render children only when the dropdown is open.
   * Children will be mounted/unmounted when the dropdown is open/closed.
   */
  lazy?: boolean;
  limitHeight?: boolean;
  menuClass?: string;
  menuZIndex?: number;
  onToggle?: (state: boolean) => void;
  /** Initial dropdown state. */
  open?: boolean;
  renderButton?: (props: {
    id: string;
    isOpen: boolean;
    onClick: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }) => ReactNode;
  zoom?: number;
};

type State = {
  isOpen: boolean;
};

/**
 * @deprecated Use `common/overlay/Dropdown` instead
 */
export class Dropdown extends Component<Props, State> {
  button: RefObject<HTMLDivElement>;

  dropdownContext: {
    close: () => void;
    open: () => void;
  };

  menu: RefObject<Component>;

  resizeObserver?: ResizeObserver;

  constructor(props: Props) {
    super(props);

    this.state = { isOpen: props.open ?? false };

    this.button = React.createRef();
    this.menu = React.createRef();

    this.handleToggle = this.handleToggle.bind(this);
    this.handleDropdownButtonClick = this.handleDropdownButtonClick.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.toggle = this.toggle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.dropdownContext = {
      close: this.close,
      open: this.open,
    };

    // This should be replaced by this: https://www.npmjs.com/package/@react-hook/resize-observer
    // once the component is refactored to a functional component.
    if (!isNil(window.ResizeObserver)) {
      this.resizeObserver = new ResizeObserver(() => {
        this.refresh();
      });
    }
  }

  componentDidMount(): void {
    if (!isNil(this.resizeObserver)) {
      // We need to register a callback on size changes of the menu
      // otherwise when the menu gets populated conditionally its size will
      // be (0, 0) and mess up the calculations below causing the menu to appear
      // outside its bounds.
      const menuDOMNode = findDOMNode(this.menu.current); // eslint-disable-line react/no-find-dom-node
      if (isElement(menuDOMNode)) {
        this.resizeObserver.observe(menuDOMNode);
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  componentWillUnmount(): void {
    if (!isNil(this.resizeObserver)) {
      const menuDOMNode = findDOMNode(this.menu.current); // eslint-disable-line react/no-find-dom-node
      if (isElement(menuDOMNode)) {
        this.resizeObserver.unobserve(menuDOMNode);
      }
    }
  }

  componentDidUpdate(): void {
    this.refresh();
  }

  // eslint-disable-next-line react/sort-comp
  refresh(): void {
    const menuRef = this.menu.current;
    const buttonRef = this.button.current;

    if (!menuRef || !buttonRef) {
      return;
    }

    const menuDOMNode = findDOMNode(menuRef); // eslint-disable-line react/no-find-dom-node
    const buttonDOMNode = findDOMNode(buttonRef); // eslint-disable-line react/no-find-dom-node

    if (!isElement(menuDOMNode) || !isElement(buttonDOMNode)) {
      return;
    }

    const { forcePosition = "", zoom = 100 } = this.props;

    const menuBounds = menuDOMNode.getBoundingClientRect();
    const buttonBounds = buttonDOMNode.getBoundingClientRect();
    const zoomFactor = zoom / 100;

    if (
      !forcePosition.includes("top") &&
      (forcePosition.includes("bottom") ||
        buttonBounds.bottom + menuBounds.height <
          (window.scrollY || 0) + window.innerHeight)
    ) {
      // Button + menu will be vertically visible, open menu towards the bottom
      menuDOMNode.style.top = `${
        buttonBounds.top * zoomFactor + buttonBounds.height
      }px`;
    } else if (
      forcePosition.includes("top") ||
      buttonBounds.top - menuBounds.height > window.scrollY
    ) {
      // Open menu towards the top
      menuDOMNode.style.top = `${
        buttonBounds.top * zoomFactor - menuBounds.height
      }px`;
    } else {
      // If neither direction has enough space, open towards the bottom so it can be scrolled
      menuDOMNode.style.top = `${buttonBounds.bottom * zoomFactor}px`;
    }

    if (
      !forcePosition.includes("left") &&
      (forcePosition.includes("right") ||
        buttonBounds.right + menuBounds.width <
          (window.scrollX || 0) + window.innerWidth)
    ) {
      // Button + menu will be horizontally visible, open menu towards the right
      menuDOMNode.style.left = `${buttonBounds.left * zoomFactor}px`;
    } else {
      const leftPosition = buttonBounds.right * zoomFactor - menuBounds.width;
      const minLeftPosition = 5;
      // Open menu towards the left
      menuDOMNode.style.left = `${
        leftPosition < 0 ? minLeftPosition : leftPosition
      }px`;
    }
  }

  toggle(): void {
    this.handleToggle();
  }

  open(): void {
    const { isOpen } = this.state;
    if (isOpen) {
      return;
    }
    this.toggle();
  }

  close(): void {
    const { isOpen } = this.state;
    if (!isOpen) {
      return;
    }
    this.toggle();
  }

  handleDropdownButtonClick(
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ): void {
    if (e) {
      e.stopPropagation();
    }

    this.handleToggle();

    const { button } = this.props;

    if (button && button.props.onClick) {
      button.props.onClick();
    }
  }

  handleToggle(): void {
    this.setState(
      (state) => ({ isOpen: !state.isOpen }),
      () => {
        this.props.onToggle?.(this.state.isOpen);
      }
    );
  }

  handleItemSelect(): void {
    const { keepOpen } = this.props;
    if (!keepOpen) {
      this.handleToggle();
    }
  }

  renderChildren(): ReactNode {
    const { isOpen } = this.state;
    const { lazy, children } = this.props;

    if (lazy && !isOpen) {
      return null;
    }

    if (isFunction(children)) {
      return children(this.dropdownContext);
    }

    return children;
  }

  render(): JSX.Element {
    const {
      button,
      buttonOpen = null,
      className = "",
      hasShadow = true,
      id = "",
      limitHeight = false,
      menuClass,
      menuZIndex = 86500, // sure, why not
      renderButton,
      disabled,
    } = this.props;
    const { isOpen } = this.state;

    const currentButton = buttonOpen && isOpen ? buttonOpen : button;

    return (
      <div
        className={classNames(
          "flex items-center btn-group dropdown",
          {
            open: isOpen,
          },
          className
        )}
      >
        {button &&
          currentButton &&
          React.cloneElement(currentButton, {
            "data-state": isOpen ? "open" : "closed",
            disabled: disabled,
            id,
            onClick: this.handleDropdownButtonClick,
            open: isOpen,
            ref: this.button,
          })}

        {renderButton && (
          <div ref={this.button}>
            {renderButton({
              id,
              isOpen,
              onClick: this.handleDropdownButtonClick,
            })}
          </div>
        )}
        <DropdownContext.Provider value={this.dropdownContext}>
          <DropdownMenu
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<Component<{}, {}, any>>' is not as... Remove this comment to see the full error message
            ref={this.menu}
            className={menuClass}
            hasShadow={hasShadow}
            id={id}
            isOpen={this.state.isOpen}
            limitHeight={limitHeight}
            onClose={this.close}
            zIndex={menuZIndex}
          >
            {this.renderChildren()}
          </DropdownMenu>
        </DropdownContext.Provider>
      </div>
    );
  }
}
