import { useField } from "formik";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Input } from "common/inputs/Input/Input";
import { Label } from "common/inputs/Label/Label";
import { Clickable } from "common/navigation/Clickable";
import { twClass } from "utils/twClass";

type Props = {
  name: string;
};

export const MeetingItemTemplateTalkingPointsField = ({
  name,
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<string[]>(name);

  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  const { $t } = useIntl();

  const handleDeleteTalkingPoint = (index: number) => {
    const newTalkingPoints = field.value.filter((value, i) => i !== index);
    helpers.setValue(newTalkingPoints);
    setFocusedIndex(index - 1);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newItems = [...field.value, ""];
      helpers.setValue(newItems);
      setFocusedIndex(index + 1);
    }

    if (e.key === "Backspace") {
      const talkingPoint = field.value[index];
      if (talkingPoint === "" && field.value.length > 1) {
        handleDeleteTalkingPoint(index);
      }
    }
  };

  const updateTalkingPoint = (newValue: string, index: number) => {
    const newTalkingPoints = cloneDeep(field.value);
    newTalkingPoints[index] = newValue;
    helpers.setValue(newTalkingPoints);
  };

  return (
    <div className="w-full">
      <Label className="flex grow">
        <FormattedMessage defaultMessage="Talking Points" id="Xup/rQ" />
      </Label>
      {field.value.map((talkingPoint, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="mt-2 flex grow flex-col space-y-1"
        >
          <div className="flex grow items-center space-x-1.5">
            <Input
              autoFocus={index === focusedIndex}
              data-cy={`${index}-talking-point-input-field`}
              formControl={false}
              id={`${index}-${talkingPoint}`}
              className={twClass("grow", {
                "border-red-500": meta.touched && meta.error?.[index],
              })}
              onBlur={() => setFocusedIndex(null)}
              onChange={(e) => updateTalkingPoint(e.target.value, index)}
              onFocus={() => setFocusedIndex(index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              placeholder={$t({
                defaultMessage: "Add a talking point",
                id: "R6P+wv",
              })}
              value={talkingPoint}
            />
            <IconButton
              className="text-red-500"
              data-cy="jWiGW5OOQdyRzf5yKH0C0"
              disabled={field.value.length === 1}
              name="remove_circle_outline"
              onClick={() => handleDeleteTalkingPoint(index)}
            />
          </div>
          <div className="m-1 flex text-sm text-slate-500">
            <div className="grow text-red-500">
              {meta.touched && meta.error?.[index]}
            </div>
          </div>
        </div>
      ))}
      <Clickable
        className="flex items-center space-x-1 text-blue-500"
        data-cy="addNewMeetingItem"
        onClick={() => {
          const newItems = [...field.value, ""];
          helpers.setValue(newItems);
          setFocusedIndex(field.value.length);
        }}
      >
        <span>+</span>
        <span>
          <FormattedMessage defaultMessage="Add another" id="0K9j9i" />
        </span>
      </Clickable>
    </div>
  );
};
