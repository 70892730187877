import { TimeframeDefaultFragment } from "@graphql";
import React from "react";
import { twClass } from "utils/twClass";

type Props = {
  selected: string[];
  timeframes: TimeframeDefaultFragment[];
  toggle: (id: string) => void;
};
export const TimeframesSelect = ({
  selected,
  timeframes,
  toggle,
}: Props): JSX.Element => {
  return (
    <div>
      <div>
        {timeframes.map((timeframe) => (
          <button
            data-cy="Zv2rS9teiiazqkQHSDiz0"
            key={timeframe.id}
            className={twClass(
              "inline-block cursor-pointer rounded text-sm bg-slate-200",
              "mx-1 px-1",
              selected.includes(timeframe.id) && "bg-blue-200"
            )}
            onClick={() => toggle(timeframe.id)}
            type="button"
          >
            {timeframe.name}
          </button>
        ))}
      </div>
    </div>
  );
};
