import { ReactElement } from "react";
import {
  GroupedOptionsType,
  OptionsType as ReactSelectOptionsType,
  Props,
} from "react-select";

export declare type onChangeType = Props["onChange"];
export declare type OptionType = {
  disabled?: boolean;
  label: string;
  value: string;
};
export declare type OptionsType =
  | GroupedOptionsType<OptionType>
  | ReactSelectOptionsType<OptionType>;

/** newly created item coming from react-select */
export type SelectNewItem = {
  __isNew__: true;
  label: ReactElement;
  value: string;
};

export const isNewSelectItem = <T extends Record<string, unknown>>(
  item: T | SelectNewItem
): item is SelectNewItem => {
  return "__isNew__" in item;
};
