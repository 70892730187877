import {
  ObjectiveClosingNoteFragment,
  ObjectiveDefaultFragment,
} from "@graphql";
import { useState } from "react";
import { singletonHook } from "react-singleton-hook";

type ModalData = {
  objective: ObjectiveDefaultFragment & ObjectiveClosingNoteFragment;
};

type Hook = {
  closeModal: () => void;
  modalData: ModalData | null;
  openModal: (data: ModalData) => void;
};

const initialState: Hook = {
  closeModal: () => {},
  modalData: null,
  openModal: () => {},
};

export const useReopenObjectiveConfirmationModal = singletonHook(
  initialState,
  (): Hook => {
    const [modalData, setModalData] = useState<ModalData | null>(null);
    const openModal = (data: ModalData) => setModalData(data);
    const closeModal = () => setModalData(null);
    return { closeModal, modalData, openModal };
  }
);
