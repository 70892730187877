import { CommitStatus, ResultDefaultFragment } from "@graphql";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { SortableHandle } from "react-sortable-hoc";
import { getLinkByType } from "route-configs";
import { Avatar } from "common/avatar";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { CommitTooltip } from "common/commit/CommitTooltip/CommitTooltip";
import { Show } from "common/controlFlow";
import { useResultProgressUpdate } from "common/goal/hooks/useResultProgressUpdate";
import {
  ProgressUpdateButton,
  ProgressUpdateButtonRef,
} from "common/goal/ProgressUpdateButton/ProgressUpdateButton";
import { UpdateButton } from "common/goal/UpdateButton";
import { EntityIcon } from "common/icons/EntityIcon/EntityIcon";
import { Icon } from "common/icons/Icon/Icon";
import { DueDate } from "common/misc/DueDate/DueDate";
import { ResultArchivedIndicator } from "common/misc/ResultArchivedIndicator/ResultArchivedIndicator";
import { AnchorNext } from "common/navigation";
import { Dropdown } from "common/overlay/Dropdown";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { objectTypes } from "constants/objects";
import { resultTypes } from "constants/resultTypes";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { ResultProgress } from "../ResultProgress/ResultProgress";
import { useResultActions } from "../utils/useResultActions";

// TODO: the naming breaks the convention and could be confusing. `ResultProps` suggests props of the Result component, which is not the case here.
export type ResultProps = Pick<
  ResultDefaultFragment,
  | "__typename"
  | "archived"
  | "archivedComment"
  | "archivedDate"
  | "canPatch"
  | "childrenCount"
  | "currentValue"
  | "contributors"
  | "dueDate"
  | "endValue"
  | "id"
  | "index"
  | "integration"
  | "integrationField"
  | "lastCommit"
  | "lastCommitDate"
  | "lead"
  | "objective"
  | "metricUnit"
  | "name"
  | "parent"
  | "description"
  | "normalizedValue"
  | "pushIntegrationApplication"
  | "startDate"
  | "startValue"
  | "type"
  | "isOutdated"
  | "tags"
> & { status: CommitStatus };

interface Props {
  canDrag?: boolean;
  className?: string;
  closed?: boolean;
  result: ResultProps;
  resultLink: string;
  showEdges?: boolean;
}

export const Result = ({
  canDrag,
  className,
  closed,
  result,
  resultLink,
  showEdges = true,
}: Props): JSX.Element => {
  const router = useRouter();
  const selected = router.query.rId === result.id;
  const inTeams = useInTeams();
  const { closeResult } = useModalRouter();

  const updateButtonRef = useRef<ProgressUpdateButtonRef>(null);
  const { handleSubmit } = useResultProgressUpdate(result);

  const {
    archivedComment,
    archivedDate,
    canPatch,
    currentValue,
    dueDate,
    endValue,
    integration,
    isOutdated,
    lastCommit,
    lead,
    metricUnit,
    name: title,
    normalizedValue,
    pushIntegrationApplication,
    startValue,
    status,
    parent,
    index,
    type,
  } = result;

  const onRemove = closeResult;

  const actions = useResultActions({
    onClickUpdate: () => updateButtonRef.current?.setIsOpen(true),
    onEdit: () => router.push(resultLink),
    onRemove,
    result,
  });

  const getSubtext = () => {
    if ((normalizedValue ?? 0) >= 100) {
      return null;
    }

    const subtext = [];

    if (isSet(archivedDate)) {
      subtext.push(
        <ResultArchivedIndicator
          key="archived"
          archivedComment={archivedComment ?? undefined}
          archivedDate={archivedDate}
        />
      );
    } else {
      if (dueDate) {
        subtext.push(
          <DueDate key="due" className="inline-flex" date={dueDate} />
        );
      }
    }

    return subtext;
  };

  const subtext = getSubtext();

  const positiveMetric = endValue >= startValue;

  const tooltip = (
    <CommitTooltip
      commit={lastCommit}
      metricUnit={metricUnit}
      positiveMetric={positiveMetric}
    />
  );

  const DragHandle = SortableHandle(() => (
    <Icon
      className={twClass(
        "pointer-events-none cursor-grab opacity-0 text-slate-500 hover:text-blue-500",
        {
          "pointer-events-auto group-hover/result:opacity-100 select-none":
            canDrag,
        }
      )}
      name="drag_indicator"
      size="xl"
    />
  ));

  return (
    <div data-testid="result" className="flex group/result">
      <div className="m-auto">
        <DragHandle />
      </div>
      <Show
        when={type === resultTypes.initiative && isSet(parent) && showEdges}
      >
        <div className="w-8 flex justify-end">
          <div
            className={twClass("h-3/2 border-l -mt-3", {
              "h-1/2": parent?.childrenCount == index + 1,
            })}
          />
          <div className="h-10">
            <div className="border-l border-b border-slate-300 h-full w-3 -ml-[1px]" />
          </div>
        </div>
      </Show>
      <div
        className={twClass(
          "px-3 py-2.5 min-h-[72px] w-full gap-y-4 bg-white",
          "border border-slate-300 rounded-md group-hover/result:border-blue-500 hover:cursor-pointer",
          "flex flex-col print:flex-row md:flex-row items-center justify-between",
          {
            "border-blue-500": selected,
          },
          className
        )}
        onClick={() => {
          router.push(resultLink);
        }}
        data-testid="resultCard"
        data-cy="keyresult"
      >
        <div className="flex items-center space-x-4">
          <WithTooltip
            tooltip={
              type === resultTypes.keyResult ? (
                <FormattedMessage defaultMessage="Key Result" id="b+726N" />
              ) : (
                <FormattedMessage defaultMessage="Initiative" id="jpmEhT" />
              )
            }
          >
            <EntityIcon entity={type} />
          </WithTooltip>
          <div className="owner">
            {lead && (
              <Avatar
                href={
                  inTeams ? undefined : getLinkByType(objectTypes.user, lead.id)
                }
                size="small"
                tooltip={lead.fullName}
                url={lead.avatar}
              />
            )}
          </div>
          <div>
            <AnchorNext
              data-cy="key-result-title"
              data-testid="resultTitle"
              className={twClass(
                "cursor-pointer text-slate-800 hover:text-blue-500",
                { "text-slate-500": closed || isSet(archivedDate) }
              )}
              data-fs="hide"
              href={resultLink}
            >
              {title}
            </AnchorNext>
            <div
              className={twClass(
                "!hidden items-center md:!flex md:flex-grow md:flex-wrap",
                "gap-x-4 gap-y-2 text-sm"
              )}
            >
              {subtext}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 items-center ml-16">
          <div
            className={twClass("print:hidden", {
              hidden: status === CommitStatus.Accomplished,
            })}
          >
            <ProgressUpdateButton
              button={
                <UpdateButton
                  updateDue={isOutdated}
                  size="small"
                  data-cy="resultUpdate"
                />
              }
              data-cy="updateKeyResultProgress"
              ref={updateButtonRef}
              apiIntegration={pushIntegrationApplication}
              application={integration?.application}
              backdating
              disabled={closed || isSet(archivedDate) || !canPatch}
              initialValues={{
                commitDate: dayjs().toISOString(),
                endValue,
                status,
                value: currentValue,
              }}
              lastCommit={lastCommit}
              metricUnit={metricUnit}
              onSubmit={handleSubmit}
              positiveMetric={positiveMetric}
              type={type}
            />
          </div>

          <Tooltip content={tooltip} theme="perdoo-light">
            <ResultProgress result={result} />
          </Tooltip>

          <Show when={canPatch && !inTeams}>
            <Dropdown data-testid="resultDropdown">
              {({ close }) => (
                <>
                  <Dropdown.Trigger>
                    <IconButton
                      data-cy="keyResult-dropdown"
                      name="more_vert"
                      data-testid="resultCardDropdown"
                    />
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    className="text-sm"
                    placement="bottom-right"
                  >
                    {actions?.map((action) =>
                      React.cloneElement(action, {
                        ...action.props,
                        onClick: () => {
                          close();
                          action.props.onClick?.();
                        },
                        onEdit: () => {
                          close();
                          action.props.onEdit?.();
                        },
                      })
                    )}
                  </Dropdown.Content>
                </>
              )}
            </Dropdown>
          </Show>
        </div>
      </div>
    </div>
  );
};
