import { sample } from "lodash";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Icon } from "common/icons";
import { AvatarSkeleton } from "common/placeholders/AvatarSkeleton/AvatarSkeleton";
import { TextSkeleton } from "common/placeholders/TextSkeleton/TextSkeleton";
import { OneOnOneMeetingSharedNoteWrapper } from "./OneOnOneCompleteForm";
import { OneOnOneCompletePrivateNoteWrapper } from "./OneOnOneCompletePrivateNoteWrapper/OneOnOneCompletePrivateNoteWrapper";

// for tailwind parser, don't remove: w-16 w-20 w-28 w-32 w-48 w-56 w-64 w-72
const POSSIBLE_WIDTHS = [16, 20, 28, 32, 48, 56, 64, 72];
const OneOnOneMeetingItemSkeleton = () => {
  const widths = useRef(sample(POSSIBLE_WIDTHS));
  return (
    <div
      className="flex w-full items-start"
      data-testid="oneOnOneMeetingItemSkeleton"
    >
      <div className="mt-3.5 flex h-4 items-center px-1.5 pt-1">
        <Icon className="opacity-0" name="drag_indicator" size="2xl" />
      </div>
      <div className="flex min-h-11 w-full grow items-center py-2">
        <div className="mr-4 h-full">
          <AvatarSkeleton className="w-8 h-8" />
        </div>

        <TextSkeleton className={`h-6 w-${widths.current}`} />
      </div>
    </div>
  );
};

type Props = {
  otherUserName: string;
};

export const OneOnOneCompleteSkeleton = ({
  otherUserName,
}: Props): JSX.Element => {
  return (
    <div className="flex grow flex-col space-y-4 pb-10 pt-4">
      <div className="space-y-2">
        <div className="flex w-full items-center justify-between pl-9 pr-9">
          <span className="text-base font-medium text-slate-800">
            <FormattedMessage defaultMessage="Talking points" id="zQ4Fl+" />
          </span>
          <IconButton
            className="py-0.5 text-sm font-medium text-slate-800 border-slate-300 hover:text-blue-500"
            disabled
            ghost
            name="folder"
            size="base"
          >
            <FormattedMessage defaultMessage="Use a template" id="fnuKBx" />
          </IconButton>
        </div>

        <div className="flex flex-col space-y-2">
          <OneOnOneMeetingItemSkeleton />
          <OneOnOneMeetingItemSkeleton />
        </div>
      </div>
      <div className="space-y-2">
        <span className="px-9 text-base font-medium text-slate-800">
          <FormattedMessage defaultMessage="Action items" id="Arkkex" />
        </span>

        <div className="flex flex-col space-y-2">
          <OneOnOneMeetingItemSkeleton />
        </div>
      </div>
      <OneOnOneMeetingSharedNoteWrapper>
        <div className="flex grow flex-col">
          <div className="mb-1 flex justify-between">
            <div className="text-sm">
              <FormattedMessage defaultMessage="My notes" id="UzyWYd" />
            </div>
          </div>
          <div className="w-full h-20 rounded border border-solid bg-slate-200 animate-pulse" />
        </div>
        <div className="w-full space-y-1">
          <span className="text-sm text-slate-800">
            <FormattedMessage
              defaultMessage="{userName}'s notes"
              id="VbsTTH"
              values={{ userName: otherUserName }}
            />
          </span>
          <div className="pl-4 h-6 animate-pulse rounded-full bg-slate-200 w-32" />
        </div>
      </OneOnOneMeetingSharedNoteWrapper>
      <OneOnOneCompletePrivateNoteWrapper className="pb-8">
        <div className="w-full h-20 rounded border border-solid bg-slate-200 animate-pulse" />
      </OneOnOneCompletePrivateNoteWrapper>
    </div>
  );
};
