import { PushIntegrationApplication } from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FontSize, IconSize } from "common/misc/types";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import excel from "../../../../public/images/excel.svg";
import googleSheets from "../../../../public/images/google-sheets.svg";

export const INTEGRATION_NAMES: Record<PushIntegrationApplication, string> = {
  EXCEL: "Microsoft Excel",
  GSHEETS: "Google Sheets",
};

type Props = {
  className?: string;
  integration: PushIntegrationApplication;
  size?: keyof typeof IconSize;
};

export const IntegrationIcon = ({
  className,
  integration,
  size = "lg",
}: Props): JSX.Element | null => {
  let icon;
  switch (integration) {
    case "GSHEETS":
      icon = googleSheets;
      break;
    case "EXCEL":
      icon = excel;
      break;
    default:
      icon = null;
  }

  const tooltip = (
    <FormattedMessage
      defaultMessage="Updated via {name} integration"
      id="global:integration:tooltip"
      values={{
        name: INTEGRATION_NAMES[integration],
      }}
    />
  );

  return icon ? (
    <Tooltip content={tooltip}>
      <div
        className={`flex shrink-0 items-center justify-center ${className}`}
        style={{
          height: FontSize[size],
          width: FontSize[size],
        }}
      >
        <img alt="integration icon" className="h-full w-full" src={icon.src} />
      </div>
    </Tooltip>
  ) : null;
};
