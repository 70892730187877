import React, { useEffect } from "react";

/**
 * triggers an effect when user clicks outside the referenced element.
 * @param ref - reference to the element.
 * @param action - the action to be triggered.
 * @param whitelist - array of selectors which should not trigger the action.
 */
export const useOutsideAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  action: () => void,
  whitelist: string[] = []
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation();

      const isWhitelisted = whitelist.some((selectors) =>
        (event.target as Element).closest(selectors)
      );

      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node) &&
        !isWhitelisted &&
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        action
      ) {
        action();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action, whitelist]);
};
