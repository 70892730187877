import {
  CommitDefaultFieldsFragment,
  CommitDescriptionFragment,
  CommitUserFragment,
  CommitValuesFragment,
  Maybe,
} from "@graphql";
import dayjs from "dayjs";
import { isNil, upperFirst } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";
import { IconName } from "constants/icons";

type Commit = Maybe<
  CommitDefaultFieldsFragment &
    CommitDescriptionFragment &
    CommitUserFragment &
    CommitValuesFragment
>;

type Hook = {
  delta: number;
  /** use as className to colour using tailwind */
  deltaColorClass: string;
  /** pass as name to Icon component to display the progress arrow */
  deltaIcon: keyof typeof IconName;
  /** commit description with html stripped. return "no note left" if none. */
  description: React.ReactNode;
  /** whether {@link description} is real, or just a "no note left" fallback. */
  hasDescription: boolean;
  /** e.g. "Last updated 2 days ago by John Doe" */
  lastUpdateString: string;
  /** commits only store deltas, this gives you the current value */
  value: number;
  /** value before the commit (= previous value) */
  valueBefore: number;
};

const FALLBACK_RESPONSE: Hook = {
  delta: 0,
  deltaColorClass: "",
  deltaIcon: "arrow_forward",
  description: "",
  hasDescription: false,
  lastUpdateString: "",
  value: 0,
  valueBefore: 0,
};

/**
 * parses a commit to get commonly computed secondary data.
 *
 * @param commit
 * @param metricUnit
 * @param positiveMetric if true, then delta > 0 shows as green, red otherwise. if false, vice versa.
 */
export const useCommitData = (
  commit: Commit | undefined,
  positiveMetric: boolean | undefined
): Hook => {
  if (isNil(commit)) {
    return FALLBACK_RESPONSE;
  }

  const delta = commit.delta || 0;
  const deltaIcon = delta >= 0 ? "arrow_upward" : "arrow_downward";
  const commitValueBefore = commit.valueBefore;
  const value = commitValueBefore + delta;

  const lastUpdateString = commit.user
    ? `Last updated ${dayjs(commit.commitDate).fromNow()} by ${
        commit.user.fullName
      }`
    : upperFirst(dayjs(commit.commitDate).fromNow());

  let description: JSX.Element;
  let hasDescription: boolean;
  if (commit.description) {
    description = <DangerousHTML html={commit.description} />;
    hasDescription = true;
  } else {
    description = (
      <FormattedMessage defaultMessage="No comment added" id="0v5vIH" />
    );
    hasDescription = false;
  }

  let deltaColorClass = delta ? "text-blue-500" : "transparent";
  if (positiveMetric === true) {
    deltaColorClass = delta > 0 ? "text-green-500" : "text-red-500";
  } else if (positiveMetric === false) {
    deltaColorClass = delta < 0 ? "text-green-500" : "text-red-500";
  }

  return {
    delta,
    deltaColorClass,
    deltaIcon,
    description,
    hasDescription,
    lastUpdateString,
    value,
    valueBefore: commitValueBefore,
  };
};
