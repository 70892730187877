import classNames from "clsx";
import Image from "next/image";
import React, { ReactChild, ReactNode } from "react";
import { IconBadge } from "common/icons/IconBadge/IconBadge";
import { ItemSize } from "common/misc/types";
import { AnchorNext } from "common/navigation";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { ConditionalWrapper } from "common/wrappers";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

export const avatarSizes = {
  extraLarge: 120,
  extraSmall: 16,
  large: 48,
  medium: 32,
  small: 24,
};
interface Props {
  alt?: string;
  badgeTooltip?: ReactNode;
  children?: ReactNode;
  className?: string;
  customSize?: number;
  "data-cy"?: string;
  disabled?: boolean;
  href?: string;
  size?: keyof typeof ItemSize;
  tooltip?: ReactNode;
  url?: string | null;
}

export const Avatar = ({
  "data-cy": dataCy,
  alt = "",
  badgeTooltip,
  children,
  className,
  customSize,
  disabled,
  href,
  size = "medium",
  tooltip,
  url = "",
}: Props): JSX.Element => {
  const imgSize = customSize ?? avatarSizes[size];
  if (!url) {
    return <></>;
  }

  const withLink = (children: ReactChild) => (
    <AnchorNext href={href}>{children}</AnchorNext>
  );

  return (
    <div>
      <WithTooltip tooltip={tooltip}>
        <div
          className={classNames(
            "relative rounded-full",
            { "cursor-pointer": isSet(href) },
            className
          )}
          style={{ height: imgSize, width: imgSize }}
        >
          <ConditionalWrapper condition={isSet(href)} wrapper={withLink}>
            <>
              <Image
                alt={alt}
                className={twClass("rounded-full", {
                  grayscale: disabled,
                })}
                data-cy={dataCy ?? "avatar"}
                width={imgSize}
                height={imgSize}
                src={url}
              />
              {children && (
                <div className="absolute bottom-0 right-0">
                  <IconBadge
                    className="rounded-full border-2 border-white bg-yellow-500"
                    size={size}
                    tooltip={badgeTooltip}
                  >
                    {children}
                  </IconBadge>
                </div>
              )}
            </>
          </ConditionalWrapper>
        </div>
      </WithTooltip>
    </div>
  );
};
