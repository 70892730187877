import { gql } from "@apollo/client";

export const CADENCE_DEFAULT_FRAGMENT = gql`
  fragment CadenceDefaultFields on cadence {
    id
    name
    startDate
    intervalLength
    intervalType
    tfFormatter
    archived
    nextTimeframeStartDate
    goalUpdateCycle
    timeframes {
      edges {
        node {
          id
          startDate
          endDate
        }
      }
    }
    groups {
      edges {
        node {
          id
        }
      }
    }
  }
`;
