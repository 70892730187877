import {
  PerdooApiCommitIntegrationApplicationChoices,
  PerdooApiIntegrationApplicationChoices,
} from "@graphql";
import { capitalize, isNil } from "lodash";
import Image from "next/image";
import React from "react";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import asanaLogo from "../../../../public/images/asana_logo.svg";
import excel from "../../../../public/images/excel.svg";
import googleSheets from "../../../../public/images/google-sheets.svg";
import jiraLogo from "../../../../public/images/jira_logo.svg";

interface Props {
  application?:
    | PerdooApiIntegrationApplicationChoices
    | PerdooApiCommitIntegrationApplicationChoices
    | null;
  className?: string;
  size?: "small" | "medium" | "large";
}

type IntegrationLogos = {
  [K in
    | PerdooApiIntegrationApplicationChoices
    | PerdooApiCommitIntegrationApplicationChoices]: any;
};

const sizes = {
  large: 32,
  medium: 24,
  small: 16,
};

const logos: IntegrationLogos = {
  ASANA: asanaLogo.src,
  EXCEL: excel.src,
  GSHEETS: googleSheets.src,
  JIRA: jiraLogo.src,
  SLACK: "",
};

export const IntegrationLogo: React.FC<Props> = ({
  size = "small",
  application,
  className,
}) => {
  if (isNil(application)) return null;
  const width = sizes[size];

  return (
    <div style={{ height: width, width: width }}>
      <Tooltip
        className={className}
        content={`Updated from ${capitalize(application)} integration`}
      >
        <Image
          alt={`${application} logo`}
          height={width}
          src={logos[application]}
          width={width}
        />
      </Tooltip>
    </div>
  );
};
