import {
  UserAvatarFragment,
  UserDefaultFragment,
  UserStatusFragment,
} from "@graphql";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Entity } from "common/avatar";
import { For, Show } from "common/controlFlow";
import { SelectRoot } from "common/inputs/Select/SelectRoot/SelectRoot";
import { useSelect } from "common/inputs/Select/useSelect";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { twClass } from "utils/twClass";
import { NEW_USER_ID, useAddUser } from "./useAddUser";
import { UserSelectFallback } from "./UserSelectFallback";

export type SelectUser = UserDefaultFragment &
  UserAvatarFragment &
  UserStatusFragment;

type SelectRootProps = React.ComponentProps<typeof SelectRoot>;

type Props = Omit<SelectRootProps, "children" | "onChange" | "value"> & {
  defaultItem?: SelectUser;
  hasError?: boolean;
  onChange?: (u: SelectUser | null) => void;
  users: SelectUser[];
};

export const UserSelect = ({
  className,
  defaultItem,
  onChange,
  users,
  hasError,
  ...selectRootProps
}: Props): JSX.Element => {
  const { loading, inviteUser } = useAddUser();
  const {
    selected,
    handleSearch,
    handleSelect,
    query,
    Select,
    visibleOptions,
  } = useSelect({
    defaultItem,
    options: users,
  });

  const handleChange = async (user: SelectUser | null) => {
    const newUser =
      user?.id === NEW_USER_ID ? await inviteUser(user.fullName) : user;

    handleSelect(newUser);
    onChange?.(newUser);
  };

  return (
    <Select.Root
      {...selectRootProps}
      className={twClass("w-96", className)}
      onChange={handleChange}
      value={selected}
    >
      <Select.Trigger>
        <Select.Value hasError={hasError}>
          <Show fallback={<SpinnerFit />} when={!loading}>
            {selected ? (
              <Entity avatar={selected.avatar} name={selected.fullName} />
            ) : (
              <FormattedMessage defaultMessage="No user selected" id="HLpTpV" />
            )}
          </Show>
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown className="divide-y divide-slate-300">
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          <UserSelectFallback query={query} visibleUsers={visibleOptions} />
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option key={option.id} value={option}>
                <Entity avatar={option.avatar} name={option.fullName} />
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
