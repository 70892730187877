import { PerdooApiIntegrationApplicationChoices } from "@graphql";
import { AsanaProjectByGid } from "common/asana/AsanaProjectByGid";
import { AsanaTaskByGid } from "common/asana/AsanaTask/AsanaTaskByGid";
import { useResultContext } from "common/context/resultContext";
import { JiraIssue } from "common/integration/jira/JiraIssue/JiraIssue";
import { integrationFieldToAsanaData } from "common/result/utils/asana";
import { integrationFieldToJiraData } from "common/result/utils/jira";

export const ResultIntegrationDetail = (): JSX.Element | null => {
  const { integration, integrationField } = useResultContext();

  if (!integrationField || !integration) {
    return null;
  }

  switch (integration.application) {
    case PerdooApiIntegrationApplicationChoices.Jira:
      const integrationFieldJiraData =
        integrationFieldToJiraData(integrationField);

      if ("jql" in integrationFieldJiraData) {
        return <>JQL query</>;
      }

      return (
        <JiraIssue
          integration={integration}
          jiraIssueKey={integrationFieldJiraData.issueKey}
        />
      );
    case PerdooApiIntegrationApplicationChoices.Asana:
      const integrationFieldAsanaData =
        integrationFieldToAsanaData(integrationField);
      if (integrationFieldAsanaData.taskGid) {
        return <AsanaTaskByGid taskGid={integrationFieldAsanaData.taskGid} />;
      }

      return (
        <AsanaProjectByGid projectGid={integrationFieldAsanaData.projectGid} />
      );

    default:
      return null;
  }
};
