import { Objective } from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil } from "lodash";
import React, { ReactNode } from "react";
import { getLinkByType } from "route-configs";
import { For, Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { AnchorNext } from "common/navigation";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { ReportDrilldown, ReportDrilldownEntities } from "common/reports";
import { useCompany } from "hooks/useCompany/useCompany";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { mapEdges } from "utils/mapEdges";

type DrilldownObjective = Derive<
  Objective,
  {
    groups: {
      edges: {
        node: {
          id: true;
          name: true;
        };
      };
    };
    id: true;
    isCompanyGoal: true;
    lead: {
      fullName: true;
      id: true;
    };
    name: true;
  }
>;

type Props = {
  okrs: DrilldownObjective[];
  pagination: ReactNode;
};

/**
 * report drilldown, shows user list as Entity + goals up-to-date + optional actions (nudge).
 */
export const ReportOkrDrilldown = ({
  okrs,
  pagination,
}: Props): JSX.Element => {
  const { name: companyName } = useCompany();
  const { objectiveHref } = useModalRouter();

  if (isNil(pagination)) {
    return (
      <ReportDrilldown className="items-center justify-center">
        <Spinner />
      </ReportDrilldown>
    );
  }

  return (
    <ReportDrilldown>
      {pagination}
      <ReportDrilldownEntities<DrilldownObjective> entities={okrs}>
        {({ groups, id, isCompanyGoal, lead, name }) => (
          <div className="flex w-full flex-col gap-1">
            <AnchorNext
              className="truncate font-semibold text-slate-800 hover:text-blue-500"
              data-cy="objective"
              href={objectiveHref({ oId: id }, { stack: true })}
            >
              {name}
            </AnchorNext>
            <div className="flex gap-4 text-sm">
              <Show when={isCompanyGoal && companyName}>
                <AnchorNext
                  className="flex items-center gap-1 text-slate-500 hover:text-blue-500"
                  data-cy="objectiveSubtextOwner"
                  href={getLinkByType("company")}
                >
                  <Icon name="people_alt" />
                  {companyName}
                </AnchorNext>
              </Show>
              <For each={mapEdges(groups.edges)}>
                {(group) => (
                  <AnchorNext
                    className="flex items-center gap-1 text-slate-500 hover:text-blue-500"
                    data-cy="objectiveSubtextOwner"
                    href={getLinkByType("group", group.id)}
                  >
                    <Icon name="people_alt" />
                    {group.name}
                  </AnchorNext>
                )}
              </For>
              <Show when={lead}>
                {(lead) => (
                  <AnchorNext
                    className="flex items-center gap-1 text-slate-500 hover:text-blue-500"
                    data-cy="objectiveSubtextLead"
                    href={getLinkByType("user", lead.id)}
                  >
                    <Icon name="person" />
                    {lead.fullName}
                  </AnchorNext>
                )}
              </Show>
            </div>
          </div>
        )}
      </ReportDrilldownEntities>
    </ReportDrilldown>
  );
};
