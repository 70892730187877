import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { NOT_FOUND } from "constants/tracking";
import { track } from "utils/tracker";
import { Modal } from "../Modal/Modal";

interface Props {
  onClose: () => void;
}

export const NotFoundModal = ({ onClose }: Props) => {
  useEffect(() => {
    track(NOT_FOUND);
  }, []);

  return (
    <Modal
      backdropClose={false}
      isOpen
      size="sm"
      onClose={onClose}
      title={
        <FormattedMessage
          defaultMessage="Page not found"
          id="global:404:notFoundTitle"
        />
      }
    >
      <div className="flex flex-col items-center justify-center my-16">
        <h1>404</h1>
        <h2>
          <FormattedMessage
            defaultMessage="The page you were looking for could not be found"
            id="global:404:notFound"
          />
        </h2>
      </div>
    </Modal>
  );
};
