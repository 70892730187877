import React, { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "modules/settings/company/CompanySettings/CompanySettings.messages";

const Paragraph = ({ children }: { children: ReactNode }): JSX.Element => (
  <p className="mb-[15px] text-[13px]">{children}</p>
);

const LightParagraph = ({ children }: { children: ReactNode }): JSX.Element => (
  <Paragraph>
    <span className="text-slate-500">{children}</span>
  </Paragraph>
);

export const AddCadenceModalInfo = (): JSX.Element => {
  const intl = useIntl();
  return (
    <div className="w-1/3">
      <Paragraph>
        <strong>{intl.formatMessage(messages.cadence)}</strong>
        <FormattedMessage
          defaultMessage="define how frequently you set goals."
          id="cadence:new:cadence:info:paragraph1"
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="Most organizations work with two cadences:"
          id="cadence:new:cadence:info:paragraph2:part1"
        />
        <br />
        <FormattedMessage
          defaultMessage="Annual and Quarterly, but you're free to use whatever you want."
          id="cadence:new:cadence:info:paragraph2:part2"
        />
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          defaultMessage="Examples of popular cadences:"
          id="cadence:new:cadence:info:paragraph3"
        />
      </Paragraph>
      <LightParagraph>
        <FormattedMessage
          defaultMessage='"5 year": 2019-2023, 2024-2028, etc.'
          id="cadence:new:cadence:info:paragraph4"
        />
      </LightParagraph>
      <LightParagraph>
        <FormattedMessage
          defaultMessage='"Annual": 2019, 2020, etc.'
          id="cadence:new:cadence:info:paragraph5"
        />
      </LightParagraph>
      <LightParagraph>
        <FormattedMessage
          defaultMessage='"Quarterly": Q1-2019, Q2-2019 etc.'
          id="cadence:new:cadence:info:paragraph6"
        />
      </LightParagraph>
    </div>
  );
};
