import { useAlignmentGoalCountQuery } from "@graphql";
import { Field } from "formik";
import { filter, orderBy } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { components, OptionProps, SingleValueProps } from "react-select";
import { FieldFocusWrapper } from "common/form/FieldFocusWrapper/FieldFocusWrapper";
import { objectiveParentSelectStages } from "common/form/FormObjectiveParentSelect/FormObjectiveParentSelect";
import { Icon } from "common/icons/Icon/Icon";
import { objectiveAlignToFields } from "common/objective/constants";
import { colorTheme } from "constants/colorTheme";
import { Select } from "legacy/components/Select/Select";
import { messages } from "../FormObjectiveAlignToSelect.messages";

const Option = ({ data, ...props }: OptionProps<any, any>) => {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <components.Option {...props}>
      <div className="flex" data-cy={data.testLabel}>
        <div className="ml-2 mr-5 flex items-center">
          <Icon
            className={data.iconClass}
            name={data.icon}
            size="2xl"
            type="outlined"
          />
        </div>
        <div>
          <div>{data.label}</div>
          <div className="text-sm">{data.description}</div>
        </div>
      </div>
    </components.Option>
  );
};

const SingleValue = ({ data, ...props }: SingleValueProps<any>) => {
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <components.SingleValue {...props}>
      <div className="flex items-center">
        <Icon
          className={data.iconClass}
          name={data.icon}
          size="2xl"
          type="outlined"
        />
        <div className="ml-3">{data.label}</div>
      </div>
    </components.SingleValue>
  );
};

type Props = {
  alignedToRadio: string;
  disabled?: boolean;
  errorText?: string;
  pillarsEnabled: boolean;
  setAlignedToRadio: (value: string) => void;
  setFocusedField: (name: string) => void;
};

export const SelectAlignment = ({
  alignedToRadio,
  disabled,
  errorText,
  pillarsEnabled,
  setAlignedToRadio,
  setFocusedField,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { data } = useAlignmentGoalCountQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      stageIn: objectiveParentSelectStages,
    },
  });

  const options = [
    ...(pillarsEnabled
      ? [
          {
            description: intl.formatMessage(messages.radioPillarHelper),
            icon: "account_balance",
            iconClass:
              (data?.allStrategicPillars.totalCount || 0) <= 0
                ? "text-slate-400"
                : "text-green-600",
            id: objectiveAlignToFields.strategicPillar,
            label: intl.formatMessage(messages.radioPillar),
            optionIsDisabled: (data?.allStrategicPillars.totalCount || 0) <= 0,
            testLabel: "alignToPillar",
          },
        ]
      : []),
    {
      description: intl.formatMessage(messages.radioOkrHelper),
      icon: "outlined_flag",
      iconClass:
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (data?.objectives?.edgeCount || 0) <= 0
          ? "text-slate-400"
          : "text-slate-600",
      id: objectiveAlignToFields.parent,
      label: intl.formatMessage(messages.radioOkr),
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      optionIsDisabled: (data?.objectives?.edgeCount || 0) <= 0,
      testLabel: "alignToObjective",
    },
    {
      description: intl.formatMessage(messages.radioKpiHelper),
      icon: "monitor_heart",
      iconClass:
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (data?.allKpis?.totalCount || 0) <= 0
          ? "text-slate-400"
          : "text-indigo-500",
      iconType: "outlined",
      id: objectiveAlignToFields.kpi,
      label: intl.formatMessage(messages.radioKpi),
      // TODO: [no-unnecessary-condition] remove and fix
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      optionIsDisabled: (data?.allKpis?.totalCount || 0) <= 0,
      testLabel: "alignToKpi",
    },
    {
      description: intl.formatMessage(messages.radioNoneHelper),
      icon: "close",
      iconClass: "text-red-600",
      id: "none",
      label: intl.formatMessage(messages.radioNone),
      optionIsDisabled: false,
      testLabel: "alignToNone",
    },
  ];
  const disabledOptions = filter(
    options,
    (option) => option.optionIsDisabled === true
  );
  const sortedOptions =
    options.length - disabledOptions.length === 1
      ? orderBy(options, ["optionIsDisabled"])
      : options;

  const value = options.find((val) => val.id === alignedToRadio);
  const extendMenu = [
    objectiveAlignToFields.kpi,
    objectiveAlignToFields.parent,
    objectiveAlignToFields.strategicPillar,
  ].includes(alignedToRadio);

  const styles = {
    menu: (provided: Record<string, string>) => ({
      ...provided,
      ...(extendMenu ? { width: 500 } : {}),
    }),
    option: (
      provided: Record<string, string>,
      { isDisabled }: { isDisabled: boolean }
    ) => ({
      ...provided,
      color: isDisabled ? colorTheme.slate[400] : provided.color,
    }),
  };

  return (
    <FieldFocusWrapper name="alignment" setFocusedField={setFocusedField}>
      <Field name="alignment">
        {() => (
          <Select
            className="ml-px mt-0"
            components={{ Option, SingleValue }}
            data-cy="selectAlign"
            data-testid="selectAlignObjective"
            disabled={disabled}
            errorText={errorText}
            getOptionValue={({ id }: { id: string }) => id}
            isInForm
            isOptionDisabled={(val: { optionIsDisabled: boolean }) =>
              val.optionIsDisabled
            }
            isSearchable={false}
            onChange={(val: { id: string }) => setAlignedToRadio(val.id)}
            options={sortedOptions}
            placeholder={intl.formatMessage(messages.alignSelectReason)}
            styles={styles}
            value={value}
          />
        )}
      </Field>
    </FieldFocusWrapper>
  );
};
