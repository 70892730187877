import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { GoalDisclosure } from "common/goalModal/GoalDisclosure/GoalDisclosure";
import { ResultList, ResultsFilterContext } from "common/result";
import { useToggle } from "hooks/useToggle/useToggle";
import { ObjectiveResultsWarning } from "../../ObjectiveResultsWarning";
import { ObjectiveResultsHeader } from "../ObjectiveResultsHeader/ObjectiveResultsHeader";

export const ObjectiveResults = (): JSX.Element => {
  const helperContainerRef = useRef(null);
  const objective = useObjectiveContext();
  const [hideArchived, toggleHideArchived] = useToggle(false);
  const [hideCompleted, toggleHideCompleted] = useToggle(false);

  const fallback = (
    <div className="px-5 flex text-slate-500 mt-4">
      <FormattedMessage
        defaultMessage="No Key Results or Initiatives added"
        id="CCg5v7"
      />
    </div>
  );

  return (
    <ResultsFilterContext.Provider
      value={{
        archived: hideArchived === true ? null : false,
        excludeAccomplished: hideCompleted,
        parentIsnull: true,
        toggleHideArchived,
        toggleHideCompleted,
      }}
    >
      <GoalDisclosure
        data-testid="objectiveResults"
        defaultOpen={(objective.results?.edgeCount ?? 0) > 0}
        title={<ObjectiveResultsHeader />}
      >
        <ObjectiveResultsWarning />
        <div className="-ml-5">
          <div
            ref={helperContainerRef}
            className="flex h-full flex-col"
            data-cy="objectiveResultsDetails"
            data-testid="objectiveResultsDetails"
          >
            <ResultList objective={objective} fallback={fallback} />
          </div>
        </div>
      </GoalDisclosure>
    </ResultsFilterContext.Provider>
  );
};
