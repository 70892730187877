import React, { LegacyRef, MouseEventHandler, ReactChild } from "react";
import { Placement } from "tippy.js";
import { Bubble } from "common/buttons/IconButton/Bubble/Bubble";
import { Icon } from "common/icons/Icon/Icon";
import { FontSize, IconSize } from "common/misc/types";
import { AnchorNext } from "common/navigation";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { RequestIndicator } from "common/placeholders/RequestIndicator/RequestIndicator";
import { ConditionalWrapper } from "common/wrappers";
import { colorTheme } from "constants/colorTheme";
import { IconName } from "constants/icons";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

export type IconButtonProps = {
  "aria-label"?: string;
  bubbleCount?: number;
  children?: React.ReactNode;
  className?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  iconClass?: string;
  iconType?: "outlined" | "round" | "sharp" | "two-tone";
  id?: string;
  loading?: boolean;
  name?: keyof typeof IconName;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  parentRef?: LegacyRef<HTMLButtonElement>;
  render?: (props: {
    disabled: boolean;
    size: keyof typeof IconSize;
  }) => React.ReactElement;
  size?: keyof typeof IconSize;
  tooltip?: string | JSX.Element;
  tooltipInteractive?: boolean;
  tooltipPlacement?: Placement;
};

/**
 * HTML button wrapped around {@link Icon}. *
 */
export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      "aria-label": ariaLabel,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      bubbleCount,
      children,
      className = "",
      disabled = false,
      ghost = false,
      href,
      iconClass = "",
      iconType,
      id,
      loading,
      name,
      onClick,
      parentRef,
      render,
      size = "2xl",
      tooltip,
      tooltipInteractive,
      tooltipPlacement = "bottom",
      ...other
    }: IconButtonProps,
    ref
  ): JSX.Element => {
    const button = (
      <button
        ref={parentRef ?? ref}
        aria-label={ariaLabel}
        className={twClass(
          "group relative flex items-center justify-center gap-1 focus:outline-none",
          {
            "cursor-not-allowed text-slate-400": disabled,
            "hover:text-blue-500": !disabled,
            "rounded border p-1 bg-white border-slate-200": ghost,
          },
          className
        )}
        data-cy={dataCy}
        data-testid={dataTestId}
        disabled={disabled || loading}
        id={id}
        onClick={onClick}
        type="button"
        {...other}
      >
        {!loading && (
          <>
            {name && (
              <Icon
                className={iconClass}
                name={name}
                size={size}
                type={iconType}
              />
            )}
            {render && render({ disabled, size })}
          </>
        )}
        {loading && (
          <span
            className="grid place-items-center"
            style={{
              height: FontSize[size],
              width: FontSize[size],
            }}
          >
            <RequestIndicator
              className="grid place-items-center"
              color={colorTheme.slate["300"]}
              containerSize="100%"
              // TODO: [no-unnecessary-condition] remove and fix
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              size={`${(FontSize[size] ?? FontSize["2xl"]) - 6}px`}
              speed="0.6s"
              state="loading"
            />
          </span>
        )}
        {children}
        <Bubble count={bubbleCount} />
      </button>
    );

    const withLink = (children: ReactChild) => (
      <AnchorNext className="text-current hover:text-blue-500" href={href}>
        {children}
      </AnchorNext>
    );

    return (
      <ConditionalWrapper condition={isSet(href)} wrapper={withLink}>
        <WithTooltip
          interactive={tooltipInteractive}
          placement={tooltipPlacement}
          tooltip={tooltip}
        >
          {button}
        </WithTooltip>
      </ConditionalWrapper>
    );
  }
);
