import { defineMessages } from "react-intl";

export const objectTypes = {
  bookmark: "bookmark",
  cadence: "cadence",
  checkIn: "checkIn",
  comment: "comment",
  commit: "commit",
  company: "company",
  event: "event",
  group: "group",
  initiative: "initiative",
  integration: "integration",
  keyresult: "keyResult",
  kpi: "kpi",
  kudo: "kudo",
  objective: "objective",
  slackChannel: "slackChannel",
  ssoconfig: "ssoConfig",
  strategicPillar: "strategicPillar",
  tag: "tag",
  timeframe: "timeframe",
  user: "user",
} as const;

const messages = defineMessages({
  company: {
    defaultMessage: "Company",
    id: "global:company",
  },
  group: {
    defaultMessage: "Group",
    id: "global:group",
  },
  initiative: {
    defaultMessage: "Initiative",
    id: "global:initiative",
  },
  keyResult: {
    defaultMessage: "Key Result",
    id: "global:keyResult",
  },
  kpi: {
    defaultMessage: "KPI",
    id: "global:kpi",
  },
  strategicPillar: {
    defaultMessage: "Strategic Pillar",
    id: "global:strategicPillar",
  },
  user: {
    defaultMessage: "User",
    id: "global:user",
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'intl' implicitly has an 'any' type.
export const objectTypesIntl = (intl) => ({
  company: intl.formatMessage(messages.company),
  group: intl.formatMessage(messages.group),
  initiative: intl.formatMessage(messages.initiative),
  keyResult: intl.formatMessage(messages.keyResult),
  kpi: intl.formatMessage(messages.kpi),
  objective: intl.formatMessage({
    defaultMessage: "Objective",
    id: "global:objective",
  }),
  strategicPillar: intl.formatMessage(messages.strategicPillar),
  user: intl.formatMessage(messages.user),
});
