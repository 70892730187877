import React from "react";
import { twClass } from "utils/twClass";

interface Props {
  children: React.ReactNode;
  className?: string;
  vertical?: boolean;
}

export const TabBar = React.forwardRef<HTMLDivElement, Props>(
  ({ vertical = false, children, className }, ref) => {
    return (
      <div
        ref={ref}
        className={twClass(
          "flex",
          {
            "flex-col": vertical,
            "flex-row py-0": !vertical,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);
