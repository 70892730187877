import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, ButtonProps } from "common/buttons/Button/Button";

type Props = ButtonProps;

export const SaveButton = ({ onClick, ...rest }: Props): JSX.Element => (
  <Button data-cy="submit" onClick={onClick} {...rest}>
    <FormattedMessage defaultMessage="Save" id="global:save" />
  </Button>
);
