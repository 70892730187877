import { useState } from "react";
import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";

type Hook = {
  handleSplitterResize: (size: number) => void;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  splitPos: number;
};

type Options = { defaultSize: number; maxSize?: number; minSize?: number };

export const useModalSplitter = (options: Options): Hook => {
  const [isDragging, setIsDragging] = useState(false);
  const [splitPos, setSplitPos] = useLocalStorage(
    "objectiveModalSplitPosition",
    options.defaultSize
  );

  return {
    handleSplitterResize: setSplitPos,
    isDragging,
    setIsDragging,
    splitPos,
  };
};
