import { useGetAllIntegrationSlackQuery } from "@graphql";
import { filter, get, isNil, orderBy, some } from "lodash";
import { isSet } from "utils/isSet";
import { SLACK_SYNC_POLL_INTERVAL } from "../Slack.constants";

export const usePrimarySlackIntegration = () => {
  const { data } = useGetAllIntegrationSlackQuery({
    fetchPolicy: "cache-and-network",
    pollInterval: SLACK_SYNC_POLL_INTERVAL,
  });
  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const integrations = data?.integrations?.edges.map(({ node }) => node) || [];
  const slackIntegrations = filter(
    integrations,
    (int) => int.application === "SLACK"
  );

  const sortedIntegrations = orderBy(
    slackIntegrations,
    ["createdDate"],
    ["desc"]
  );

  const primaryIntegration = sortedIntegrations[0];

  const slackConnected = some(sortedIntegrations, (integration) =>
    get(integration, "settings.scope", "").includes("channels:read")
  );

  const canReadPrivateChannels = get(
    primaryIntegration,
    "settings.scope",
    ""
  ).includes("groups:read");

  const slackInitialized = primaryIntegration?.isSlackInitialized;
  const slackInitializing = isSet(primaryIntegration) && !slackInitialized;

  return {
    canReadPrivateChannels,
    integrations: sortedIntegrations,
    loading: isNil(data),
    primaryIntegration,
    slackConnected,
    slackInitialized,
    slackInitializing,
  };
};
