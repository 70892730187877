import {
  namedOperations,
  ObjectiveClosingNoteFragment,
  ObjectiveDefaultFragment,
} from "@graphql";
import { isNil } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";
import { useDeleteClosingNote } from "hooks/useDeleteClosingNote/useDeleteClosingNote";

type Props = {
  objective: ObjectiveDefaultFragment & ObjectiveClosingNoteFragment;
  onClose: () => void;
};

export const ReopenObjectiveConfirmationModal = ({
  objective,
  onClose,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const { deleteClosingNote } = useDeleteClosingNote({
    refetchQueries: [
      namedOperations.Query.getProfileObjectives,
      namedOperations.Query.getGroupProgress,
      namedOperations.Query.getCompanyHeader,
    ],
  });

  const { closingNote } = objective;
  if (isNil(closingNote)) {
    return null;
  }

  return (
    <ConfirmationModal
      action={() => deleteClosingNote(closingNote.id)}
      close={onClose}
      confirmText={intl.formatMessage({
        defaultMessage: "Confirm",
        id: "global:confirm",
      })}
      title={intl.formatMessage({
        defaultMessage: "Reopen Objective",
        id: "objective:reopen",
      })}
    >
      <div>
        <FormattedMessage
          defaultMessage="Do you want to reopen the Objective {name}?"
          id="objective:close:reopen:confirm"
          values={{
            name: <strong>{objective.name}</strong>,
          }}
        />
      </div>
    </ConfirmationModal>
  );
};
