import { createNonNullableCtx } from "common/context/utils";

export type PanelBag = {
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  open?: boolean;
  toggle?: () => void;
};

export const [usePanelContext, PanelContextProvider] =
  createNonNullableCtx<PanelBag>();
