import { useMutation } from "@apollo/client";
import { namedOperations } from "@graphql";
import { pick } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { FormikSubmitHandler } from "common/form/types";
import { Modal } from "common/overlay/Modal/Modal";
import { EDIT_COMPANY } from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { UPSERT_COMPANY_MUTATION } from "modules/company/Company/Company.graphql";
import { setFormikGqlErrors } from "utils/forms";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { CompanyForm, CompanyFormValues } from "./CompanyForm/CompanyForm";

type Props = {
  callback?: () => void;
  onRequestClosed: () => void;
  open?: boolean;
};

export const EditCompanyModal = ({
  callback,
  onRequestClosed,
  open = false,
}: Props) => {
  const intl = useIntl();
  const company = useCompany();
  const [editCompany] = useMutation(UPSERT_COMPANY_MUTATION, {
    refetchQueries: [
      namedOperations.Query.getCompanyForHook,
      namedOperations.Query.getCompany,
    ],
  });
  const onSubmit: FormikSubmitHandler<CompanyFormValues> = (
    values,
    actions
  ) => {
    return editCompany({
      variables: {
        input: pick(values, [
          "id",
          "name",
          "kpisEnabled",
          "vision",
          "slackChannel",
        ]),
      },
    }).then((response) => {
      actions.setSubmitting(false);
      const { errors } = response.data.upsertCompany;
      if (errors.length) {
        setFormikGqlErrors(actions, errors);
        return;
      }

      toast.success("Company updated");
      track(EDIT_COMPANY);
      onRequestClosed();
      if (callback) {
        callback();
      }
    });
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage({
        defaultMessage: "Edit Company",
        id: "company:edit",
      })}
    >
      <CompanyForm
        initialValues={company}
        onCancel={onRequestClosed}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
