import { GCalEventSeries, GCalIntegration } from "@graphql";
import { Derive } from "@shoooe/derive";
import Image from "next/image";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Avatar } from "common/avatar";
import { Button } from "common/buttons";
import { FormattedRRule } from "common/misc/FormattedRRule/FormattedRRule";

type GCalEventSeriesProp = Derive<
  GCalEventSeries,
  {
    id: true;
    recurrence: true;
    start: true;
    summary: true;
  }
>;

type GCalIntegrationProp = Derive<
  GCalIntegration,
  {
    user: {
      avatar: true;
      fullName: true;
      id: true;
    };
  }
>;

type Props = {
  gCalEventSeries: GCalEventSeriesProp;
  gCalIntegration?: GCalIntegrationProp;
  onUnlink?: () => void;
};

export const GoogleCalendarEventDetails = ({
  gCalEventSeries,
  gCalIntegration,
  onUnlink,
}: Props): JSX.Element => {
  return (
    <div className="divide-y rounded border">
      <div className="flex items-center justify-between space-x-2 p-4">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <div>
              <Image
                alt="Google Calendar logo"
                height={20}
                src="/images/google_calendar.svg"
                width={20}
              />
            </div>
            <div className="font-medium">{gCalEventSeries.summary}</div>
          </div>
          <div className="space-y-0.5 text-sm text-slate-500">
            <FormattedRRule
              start={gCalEventSeries.start}
              value={gCalEventSeries.recurrence}
            />
          </div>
        </div>
        <Button
          data-cy="mna4IRy4jHNYggdROD5IO"
          disabled={!onUnlink}
          onClick={onUnlink}
          variant={onUnlink ? "danger-outline" : "ghost"}
        >
          <FormattedMessage defaultMessage="Unlink event" id="kSMPGh" />
        </Button>
      </div>
      {gCalIntegration && (
        <div className="flex items-center space-x-1 rounded-b px-4 py-2 text-sm text-slate-500 bg-slate-50">
          <div>
            <FormattedMessage defaultMessage="Organizer" id="lu+CdU" />
          </div>
          <Avatar size="small" url={gCalIntegration.user.avatar} />
          <div>{gCalIntegration.user.fullName}</div>
        </div>
      )}
    </div>
  );
};
