import { Form, Formik } from "formik";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import * as Yup from "yup";
import { Button } from "common/buttons/Button/Button";
import { InputField } from "common/fields/InputField/InputField";
import { FormikSubmitHandler } from "common/form/types";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { getFormikErrors } from "utils/forms";

type Props = {
  onRequestClosed: () => void;
  onSave: (values: Values) => void;
  open: boolean;
};

type Values = {
  name: string;
};

const messages = defineMessages({
  nameLabel: {
    defaultMessage: "Name",
    id: "explore:view:form:name",
  },
  saveButton: {
    defaultMessage: "Save",
    id: "explore:view:form:save",
  },
  title: {
    defaultMessage: "Save view",
    id: "explore:view:modal",
  },
});

const formSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export const ExploreViewModal = ({
  open,
  onRequestClosed,
  onSave,
}: Props): JSX.Element => {
  const intl = useIntl();

  const handleSubmit: FormikSubmitHandler<Values> = async (values, actions) => {
    try {
      await onSave(values);
      actions.setSubmitting(false);
      onRequestClosed();
    } catch (ex) {
      getFormikErrors(ex);
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title={intl.formatMessage(messages.title)}
    >
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={handleSubmit}
        render={({ isSubmitting, submitForm }) => (
          <Form>
            <InputField
              autoFocus
              label={intl.formatMessage(messages.nameLabel)}
              name="name"
            />
            <ModalFooter>
              <Button
                data-cy="SzcCSN-V7rF2zu5b8kFcL"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={submitForm}
                type="submit"
              >
                {intl.formatMessage(messages.saveButton)}
              </Button>
              <Button
                data-cy="1Zdsc6ljZNDlG7Y7vjtr7"
                disabled={isSubmitting}
                onClick={onRequestClosed}
                type="button"
                variant="ghost"
              >
                {intl.formatMessage({
                  defaultMessage: "Cancel",
                  id: "global:cancel",
                })}
              </Button>
            </ModalFooter>
          </Form>
        )}
        validationSchema={formSchema}
      />
    </Modal>
  );
};
