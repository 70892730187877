import {
  PerdooApiObjectiveStageChoices,
  useGetProfileObjectivesLazyQuery,
} from "@graphql";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { For, Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { MapsObjective } from "common/objective/MapsObjective/MapsObjective";
import { getProfileFilters } from "common/objective/ProfileOkrStagesList/utils";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { objectTypes } from "constants/objects";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { mapEdges } from "utils/mapEdges";
import { apolloQueryMerger, showServerErrorToast } from "utils/utils";
import { PerformanceReviewSidebarEmptyMessage } from "../PerformanceReviewSidebarEmptyMessage";
import { ObjectivesFilterDropdown } from "./PerformanceReviewSidebarObjectivesDropdown";

const messages = defineMessages({
  emptyList: {
    defaultMessage: "User is not the lead for any {stage} Objectives.",
    id: "performanceReview:form:objectives:empty",
  },
});

const FIRST = 5;

type Props = {
  userId: string;
};

export const PerformanceReviewSidebarObjectives = ({
  userId,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const [privateMode] = usePrivateMode();
  const { goToObjective } = useModalRouter();

  const [stageFilter, setStageFilter] =
    useState<PerdooApiObjectiveStageChoices>(
      PerdooApiObjectiveStageChoices.Active
    );
  const filters = getProfileFilters({
    entityId: userId,
    entityType: objectTypes.user,
    resultFilter: null,
    selectedTfId: null,
    stageFilter,
  });

  const [getUserObjectives, { data, loading, fetchMore }] =
    useGetProfileObjectivesLazyQuery({
      onError: (err) => showServerErrorToast(err),
    });

  useEffect(() => {
    getUserObjectives({
      variables: {
        first: FIRST,
        ...filters,
      },
    });
  }, [userId, stageFilter]);

  if (loading) {
    return (
      <div className="mt-4 flex justify-center">
        <Spinner />
      </div>
    );
  }
  const pageInfo = data?.objectives.pageInfo;

  const handleLoadMore = () => {
    fetchMore({
      updateQuery: apolloQueryMerger,
      variables: {
        after: pageInfo?.endCursor,
      },
    });
  };
  let objectives = mapEdges(data?.objectives.edges);
  if (privateMode) {
    objectives = objectives.filter((objective) => !objective.private);
  }

  const filteredObjectives = objectives.filter(
    (objective) => objective.stage === stageFilter
  );

  const handleClickLink = (oId: string) => {
    goToObjective({ oId: oId }, { stack: true });
  };
  const objectivesStage =
    stageFilter === PerdooApiObjectiveStageChoices.Active ? (
      <FormattedMessage defaultMessage="Active" id="objective:stage:active" />
    ) : (
      <FormattedMessage defaultMessage="Closed" id="objective:stage:closed" />
    );
  const emptyMessage = (
    <PerformanceReviewSidebarEmptyMessage>
      {intl.formatMessage(messages.emptyList, {
        stage: objectivesStage,
      })}
    </PerformanceReviewSidebarEmptyMessage>
  );

  return (
    <div className="my-2 space-y-2 px-5">
      <div className="flex items-center justify-between space-x-1 border-b pb-1 text-sm font-normal text-slate-800">
        <div className="flex items-center">
          <Icon name="flag" type="outlined" />
          <span>
            <FormattedMessage
              defaultMessage="OKRs"
              id="home:global:objectives"
            />
          </span>
        </div>
        <ObjectivesFilterDropdown
          setStage={setStageFilter}
          stage={stageFilter}
        />
      </div>
      <div className="mb-2 space-y-2">
        <For each={filteredObjectives} fallback={emptyMessage}>
          {(objective) => (
            // @ts-expect-error FIXME: types are all old for objs - need to upgrade
            <MapsObjective
              key={objective.id}
              className="mx-0 my-0 w-full"
              onClickLink={() => handleClickLink(objective.id)}
              shadows={false}
              {...objective}
            />
          )}
        </For>
        <Show when={pageInfo?.hasNextPage}>
          <div className="flex justify-center p-2.5">
            <Button data-cy="T368Fb-BbeLDpEqiRo4my" onClick={handleLoadMore}>
              <FormattedMessage
                defaultMessage="Load more..."
                id="global:loadMore"
              />
            </Button>
          </div>
        </Show>
      </div>
    </div>
  );
};
