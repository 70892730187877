import { ErrorMessage, useField } from "formik";
import React from "react";
import { RatePicker } from "common/inputs/RatePicker/RatePicker";

type Props = {
  "data-testid"?: string;
  name: string;
};

export const RatePickerField = ({
  name,
  "data-testid": dataTestid,
}: Props): JSX.Element => {
  const [field, , helpers] = useField(name);
  return (
    <div>
      <RatePicker
        data-testid={dataTestid}
        onChange={helpers.setValue}
        value={field.value}
      />
      <ErrorMessage
        className="text-sm text-red-500"
        component="div"
        name={name}
      />
    </div>
  );
};
