import {
  OneOnOneMeetingItemType,
  useGetUserAvatarQuery,
  UserDefaultFragment,
} from "@graphql";
import { Form } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { OneOnOneCompleteSkeleton } from "common/oneOnOnes/OneOnOneCompleteForm/OneOnOneCompleteSkeleton";
import { twClass } from "utils/twClass";
import { useCompleteOneOnOneContext } from "../context/completeOneOnOneContext";
import { OneonOneCompletePublicAnswer } from "../OneOnOneCompleteView/OneOnOneCompletePublicAnswer";
import { OneOnOneCompletePrivateNoteWrapper } from "./OneOnOneCompletePrivateNoteWrapper/OneOnOneCompletePrivateNoteWrapper";
import { OneOnOneCompletePublicNoteField } from "./OneOnOneCompletePublicNoteField";
import {
  OneOnOneMeetingItem,
  OneOnOneMeetingItemsField,
} from "./OneOnOneMeetingItemsField/OneOnOneMeetingItemsField";
import { OneOnOneMeetingPrivateNoteField } from "./OneOnOneMeetingPrivateNoteField/OneOnOneMeetingPrivateNoteField";

type WrapperProps = {
  children: React.ReactNode;
  className?: string;
};

export const OneOnOneMeetingSharedNoteWrapper = ({
  children,
  className,
}: WrapperProps): JSX.Element => (
  <div
    className={twClass("space-y-2 px-9", className)}
    data-testid="sharedNotes"
  >
    <span className="flex items-center space-x-1 text-base font-medium text-slate-800">
      <span>
        <FormattedMessage defaultMessage="Shared Notes" id="GoxIKu" />
      </span>
    </span>
    {children}
  </div>
);

export type CompleteOneOnOneValues = {
  actionItems: OneOnOneMeetingItem[] | null;
  meetingId: string;
  privateNote: string | null;
  publicNote: string;
  talkingPoints: OneOnOneMeetingItem[] | null;
};

type Props = {
  otherUser: UserDefaultFragment;
  otherUserPublicNote: string;
  saveMeetingItem: (
    item: OneOnOneMeetingItem
  ) => Promise<{ hasError: boolean }>;
  saveMeetingItems: (
    items: OneOnOneMeetingItem[]
  ) => Promise<{ hasError: boolean }>;
  savePrivateNote: (note: string) => Promise<void>;
  savePublicNote: (note: string) => Promise<void>;
};

export const OneOnOneCompleteForm = ({
  otherUser,
  otherUserPublicNote,
  saveMeetingItem,
  saveMeetingItems,
  savePrivateNote,
  savePublicNote,
}: Props): JSX.Element => {
  const { setShowTemplateView } = useCompleteOneOnOneContext();
  const { data } = useGetUserAvatarQuery();
  const me = data?.me;

  if (!me)
    return <OneOnOneCompleteSkeleton otherUserName={otherUser.fullName} />;

  return (
    <Form className="flex grow flex-col space-y-4 pb-10 pt-4">
      <div className="space-y-2" data-testid="talkingPointsSection">
        <div className="flex w-full items-center justify-between pl-9 pr-9">
          <span className="text-base font-medium text-slate-800">
            <FormattedMessage defaultMessage="Talking points" id="zQ4Fl+" />
          </span>
          <IconButton
            className="py-0.5 text-sm font-medium text-slate-800 border-slate-300 hover:text-blue-500"
            data-cy="use-template-icon-button"
            ghost
            name="folder"
            onClick={() => setShowTemplateView(true)}
            size="base"
          >
            <FormattedMessage defaultMessage="Use a template" id="fnuKBx" />
          </IconButton>
        </div>
        <OneOnOneMeetingItemsField
          emptyMessage={
            <FormattedMessage defaultMessage="Add talking point" id="NPmRGk" />
          }
          name="talkingPoints"
          saveItem={saveMeetingItem}
          saveItems={saveMeetingItems}
          type={OneOnOneMeetingItemType.TalkingPoint}
        />
      </div>
      <div className="space-y-2" data-testid="actionItemsSection">
        <span className="px-9 text-base font-medium text-slate-800">
          <FormattedMessage defaultMessage="Action items" id="Arkkex" />
        </span>
        <OneOnOneMeetingItemsField
          emptyMessage={
            <FormattedMessage defaultMessage="Add action item" id="FD7kcH" />
          }
          name="actionItems"
          saveItem={saveMeetingItem}
          saveItems={saveMeetingItems}
          type={OneOnOneMeetingItemType.ActionItem}
        />
      </div>
      <OneOnOneMeetingSharedNoteWrapper>
        <OneOnOneCompletePublicNoteField
          name="publicNote"
          onBlur={savePublicNote}
        />
        <OneonOneCompletePublicAnswer
          note={otherUserPublicNote}
          userName={otherUser.fullName}
        />
      </OneOnOneMeetingSharedNoteWrapper>
      <OneOnOneCompletePrivateNoteWrapper className="pb-8">
        <OneOnOneMeetingPrivateNoteField
          name="privateNote"
          onBlur={savePrivateNote}
        />
      </OneOnOneCompletePrivateNoteWrapper>
    </Form>
  );
};
