import React from "react";

type ModalContext = {
  footerRef: HTMLDivElement;
  size?: string;
};

export const ModalContext = React.createContext<ModalContext>(
  {} as ModalContext
);
