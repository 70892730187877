import { TargetGraphFrequency, useGetKpiProgressGraphQuery } from "@graphql";
import { head, isNil } from "lodash";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { DataNode } from "common/charts/ProgressChart/ProgressChart";
import { ProgressChart } from "common/dynamic";
import { Frequency } from "common/overlay/FrequencyDropdown/FrequencyDropdown";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { getKpiData } from "utils/getKpiData";

interface Props {
  freq?: Frequency;
  kpiId: string;
}

const toTargetGraphFrequency = (
  freq?: Frequency
): TargetGraphFrequency | undefined => {
  switch (freq) {
    case "Y":
      return TargetGraphFrequency.Yearly;
    case "Q":
      return TargetGraphFrequency.Quarterly;
    case "M":
      return TargetGraphFrequency.Monthly;
    case "W":
      return TargetGraphFrequency.Weekly;
    case "D":
      return TargetGraphFrequency.Daily;
    default:
      return undefined;
  }
};

export const KpiProgressGraphContainer = ({
  kpiId,
  freq,
}: Props): JSX.Element => {
  const { data, loading, error } = useGetKpiProgressGraphQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      freq,
      id: kpiId,
      targetFrequency: toTargetGraphFrequency(freq),
    }, // cache doesn't work reliably since there's no ID on the POT nodes
  });

  const intl = useIntl();

  if (error) {
    return (
      <div className="my-10 text-center text-red-500">
        <FormattedMessage
          defaultMessage="Error fetching graph"
          id="kpi:progressGraph:error"
        />
      </div>
    );
  }

  if (loading || !data || !data.kpi) {
    return (
      <div className="grid h-96 place-items-center text-slate-500">
        <Spinner />
      </div>
    );
  }

  const { kpi } = data;
  const targets = kpi.targetGraph?.edges.map((x) => x.node) ?? [];
  const progressNodes = kpi.progressGraph?.edges.map((x) => x.node) ?? [];

  const progressLineData = progressNodes
    .filter(
      (node) => !isNil(node.date) && !isNil(node.delta) && !isNil(node.progress)
    )
    .map((node) => ({
      node: {
        ...node,
        date: node.date,
        delta: node.delta,
        progress: node.progress,
        status: node.status,
      },
    }));

  const idealLineData = targets;

  const parseDataNode = (node: DataNode) => {
    const previousTargets = targets
      .filter((target) => {
        const targetDate = moment(target.date);
        const nodeDate = moment(node.date);
        return targetDate.isSameOrBefore(nodeDate, "day");
      })
      .sort((target1, target2) => {
        const target1Date = moment(target1.date);
        const target2Date = moment(target2.date);
        return target2Date.diff(target1Date, "day");
      });
    const activeTarget = head(previousTargets);
    const { isHealthy, healthTooltip } = getKpiData({
      intl,
      kpi: {
        currentValue: node.progress,
        delta: node.delta,
        goalOperator: activeTarget?.operator ?? null,
        goalThreshold: activeTarget?.value ?? null,
      },
    });

    return {
      warning: !isHealthy ? healthTooltip : undefined,
      warningClass: !isHealthy ? "" : "hidden",
    };
  };

  return (
    <ProgressChart
      showStatus
      data={progressLineData}
      idealLineData={idealLineData}
      idealLineStyle="step"
      parseDataNode={parseDataNode}
    />
  );
};
