import { useField } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { makeSelect } from "common/inputs/Select/Select";

const Select = makeSelect<Option>();

type EventAction = "CREATE_EVENT" | "LINK_EXISTING_EVENT";
export type GoogleCalendarEventAction = EventAction;

type Option = {
  id: EventAction;
  label: ReactNode;
};

const options: Option[] = [
  {
    id: "LINK_EXISTING_EVENT",
    label: (
      <FormattedMessage
        defaultMessage="Link to existing Google event"
        id="45g4We"
      />
    ),
  },
  {
    id: "CREATE_EVENT",
    label: (
      <FormattedMessage defaultMessage="Create new Google event" id="VYCR1s" />
    ),
  },
];

type Props = {
  disabled?: boolean;
  name: string;
  onChange?: (value: EventAction | null) => void;
};

export const GoogleCalendarEventActionField = ({
  name,
  disabled,
  onChange,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState<Option | null>(null);
  const [field, , helpers] = useField<EventAction | null>(name);

  useEffect(() => {
    const selectedFromValue = options.find((o) => o.id === field.value);
    setSelected(selectedFromValue ?? null);
  }, [field.value]);

  return (
    <Select.Root
      disabled={disabled}
      onChange={(val) => {
        setSelected(val);
        helpers.setValue(val?.id ?? null);
        onChange?.(val?.id ?? null);
      }}
      value={selected}
    >
      <Select.Trigger>
        <Select.Value clearable>
          {selected?.label ?? (
            <FormattedMessage defaultMessage="Link to..." id="K30kiH" />
          )}
        </Select.Value>
      </Select.Trigger>
      <Select.Dropdown>
        <Select.Options>
          <For each={options}>
            {(option) => (
              <Select.Option key={option.id} value={option}>
                {option.label}
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
