import React from "react";
import { TimeframePast } from "./TimeframePast/TimeframePast";
import { TimeframeTooManyObjectives } from "./TimeframeTooManyObjectives/TimeframeTooManyObjectives";

interface Props {
  timeframe?: { isPast: boolean };
  timeframeObjectivesCount: number;
}

export const TimeframeWarnings = ({
  timeframeObjectivesCount = 0,
  timeframe,
}: Props): JSX.Element[] => {
  const warnings = [];

  if (timeframeObjectivesCount > 5) {
    warnings.push(
      <TimeframeTooManyObjectives key="timeframe-many-objectives" />
    );
  }
  if (timeframe?.isPast) {
    warnings.push(<TimeframePast key="timeframe-past" />);
  }

  return warnings;
};
