import { size } from "lodash";
import { useEffect } from "react";
import { ZXCVBNResult } from "zxcvbn";
import { twClass } from "utils/twClass";

interface StrengthProperties {
  color: string;
  name: string;
  width: string;
}

const passwordStrengths: { [key: number]: StrengthProperties } = {
  0: { color: "bg-red-500", name: "Weak", width: "40%" },
  1: { color: "bg-yellow-500", name: "Moderate", width: "60%" },
  2: { color: "bg-green-500", name: "Strong", width: "80%" },
  3: { color: "bg-green-600", name: "Very Strong", width: "100%" },
};

interface Props {
  password: ZXCVBNResult;
  setError: (value: string | undefined) => void;
}

export const PasswordStrength = ({ password, setError }: Props) => {
  const strength = Math.min(
    password.score,
    Object.keys(passwordStrengths).length - 1
  );
  const strengthProps = passwordStrengths[strength];
  const color = strengthProps?.color;
  const { warning } = password.feedback;

  useEffect(() => {
    setError(warning);
  }, [warning]);

  return (
    <div className="flex items-center w-full justify-between space-x-3 rounded space-y-1">
      {Array(size(passwordStrengths))
        .fill(null)
        .map((_, id) => {
          return (
            <div
              className={twClass(`bg-slate-300 w-full rounded h-1.5 ${color}`, {
                "bg-slate-300": strength < id,
              })}
              key={id}
            />
          );
        })}
    </div>
  );
};
