import { useMutation } from "@apollo/client";
import { CadenceDefaultFieldsFragment, namedOperations } from "@graphql";
import { apolloClient } from "apolloClient";
import { FormikHelpers } from "formik";
import moment from "moment";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { getCadenceTimeframesPreview } from "common/cadence/utils";
import { Modal } from "common/overlay/Modal/Modal";
import { GET_ALL_TIMEFRAMES } from "common/timeframe/queries";
import { TimeframeTable } from "common/timeframe/TimeframeTable/TimeframeTable";
import { ADD_TIMEFRAMES } from "constants/tracking";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { showGqlErrorToast } from "utils/utils";
import { UPSERT_TIMEFRAMES_BULK_MUTATION } from "../queries";
import {
  TimeframeForm,
  TimeframeFormValues,
} from "./TimeframeForm/TimeframeForm";

const messages = defineMessages({
  success: {
    defaultMessage: "Timeframes have been created",
    id: "timeframe:add:success",
  },
  timeframeTableTitle: {
    defaultMessage:
      "{count, plural, one {The following Timeframe will be created} other {The following Timeframes will be created}}:",
    id: "timeframe:add:timeframeTable:title",
  },
});

interface AddTimeframeModalProps {
  initialCadence?: CadenceDefaultFieldsFragment;
  onRequestClosed: () => void;
  open: boolean;
}

export const AddTimeframeModal = ({
  onRequestClosed,
  open,
  initialCadence,
}: AddTimeframeModalProps): JSX.Element => {
  const intl = useIntl();
  const [addTimeframes] = useMutation(UPSERT_TIMEFRAMES_BULK_MUTATION, {
    refetchQueries: [
      namedOperations.Query.getTimelineTimeframes,
      namedOperations.Query.getTimelineCadences,
      namedOperations.Query.getCadenceList,
      namedOperations.Query.OrderedTimeframes,
    ],
  });

  const generateCadenceTimeframes = (values: TimeframeFormValues) => {
    const { currentCadence, timeframeCount, name = "", startDate } = values;
    const limit = timeframeCount ?? 1;
    const cadence = currentCadence || initialCadence;
    if (cadence) {
      const { intervalType } = cadence;

      const timeframes = getCadenceTimeframesPreview(
        cadence,
        limit,
        name,
        startDate ||
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          moment().startOf(intervalType.toLowerCase()).format("YYYY-MM-DD")
      );
      return timeframes;
    }
    return [];
  };

  const renderTimeframeTable = (values: TimeframeFormValues) => (
    <TimeframeTable
      timeframes={generateCadenceTimeframes(values)}
      title={intl.formatMessage(messages.timeframeTableTitle, {
        count: values.timeframeCount,
      })}
    />
  );

  const onSubmit = async (
    values: TimeframeFormValues,
    actions: FormikHelpers<TimeframeFormValues>
  ) => {
    const timeframes = generateCadenceTimeframes(values).map((tf) => ({
      cadence: values.cadence,
      endDate: moment(new Date(tf.endDate)).format("YYYY-MM-DD"),
      name: tf.name,
      startDate: moment(new Date(tf.startDate)).format("YYYY-MM-DD"),
    }));

    addTimeframes({
      variables: {
        timeframes,
      },
    })
      .then((response) => {
        if (response.errors) {
          showGqlErrorToast(response);
        } else {
          actions.setSubmitting(false);
          apolloClient.query({
            fetchPolicy: "network-only",
            query: GET_ALL_TIMEFRAMES,
          });
          const { cadence, timeframeCount, name = "", startDate } = values;
          track(ADD_TIMEFRAMES, {
            cadence,
            count: timeframeCount,
            name,
            start_date: startDate,
          });
          toast.success(intl.formatMessage(messages.success));
          onRequestClosed();
        }
      })
      .catch(() => {
        toast.failure("error...");
      })
      .finally(() => actions.setSubmitting(false));
  };

  const initialValues: TimeframeFormValues = {
    name: "",
    timeframeCount: 1,
  };

  if (!!initialCadence) {
    initialValues.cadence = initialCadence.id;
    initialValues.startDate =
      initialCadence.nextTimeframeStartDate ?? undefined;
  }

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title="Add Timeframe"
    >
      <TimeframeForm
        key={initialCadence?.id}
        initialValues={initialValues}
        onSubmit={onSubmit}
        renderTimeframeTable={renderTimeframeTable}
      />
    </Modal>
  );
};
