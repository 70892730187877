import PropTypes from "prop-types";
import React from "react";
import { colorTheme } from "constants/colorTheme";
import { StyledRange as Slider } from "../Slider/Slider";
import { CustomHandle } from "./CustomHandle/CustomHandle";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'value' implicitly has an 'any' ty... Remove this comment to see the full error message
export const Range = ({ value, ...other }) => {
  return (
    <Slider
      handle={CustomHandle}
      pushable={0}
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'currentValue' implicitly has an 'any' t... Remove this comment to see the full error message
      tipFormatter={(currentValue) => `${currentValue}%`}
      tipProps={{
        overlayStyle: { zIndex: 3000 },
      }}
      trackStyle={[
        {
          backgroundColor: colorTheme.blue[500],
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },
        {
          backgroundColor: colorTheme.blue[500],
        },
      ]}
      value={value}
      {...other}
    />
  );
};

Range.propTypes = {
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Range.defaultProps = {
  onAfterChange: () => {},
  onChange: () => {},
};
