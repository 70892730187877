import { useCompany } from "hooks/useCompany/useCompany";
import {
  getCompanySizeFormatted,
  getCompanySizeRangeUpper,
} from "modules/company/Company/Company.utils";

/**
 * Wrt to demos and sales we need to focus on bigger companies.
 * If smaller companies need help they can always use Intercom.
 */
export const useShowSalesModals = () => {
  const company = useCompany();
  const formattedCompanySize = getCompanySizeFormatted(company.companySize);
  const companyUpperRange = getCompanySizeRangeUpper(formattedCompanySize) ?? 0;
  const showSalesModals = companyUpperRange >= 50;
  return { showSalesModals };
};
