import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  timeframeTooManyObjectivesBody: {
    defaultMessage:
      "This timeframe has more than 5 Objectives, which may affect your team's ability to focus on what's most important.",
    id: "timeframe:warning:timeframeTooManyObjectivesBody",
  },
  timeframeTooManyObjectivesTitle: {
    defaultMessage: "Too many Objectives",
    id: "timeframe:warning:timeframeTooManyObjectivesTitle",
  },
});

export const TimeframeTooManyObjectives = () => {
  return (
    <>
      <div className="mb-4 text-sm font-semibold">
        <FormattedMessage {...messages.timeframeTooManyObjectivesTitle} />
      </div>
      <div className="mb-4">
        <FormattedMessage {...messages.timeframeTooManyObjectivesBody} />
      </div>
    </>
  );
};
