import React, { ReactNode } from "react";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { twClass } from "utils/twClass";

type Props = {
  children: ReactNode;
  className?: string;
  collapsedHeight?: number;
  showLessLabel?: string;
  showMoreLabel?: string;
};

type State = {
  isOpen: boolean;
  isToggleButtonVisible: boolean;
};

export class TextTruncate extends React.Component<Props, State> {
  private content = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      isToggleButtonVisible: false,
    };

    this.setToggleButtonVisibility = this.setToggleButtonVisibility.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount(): void {
    this.setToggleButtonVisibility();
  }

  componentDidUpdate(): void {
    this.setToggleButtonVisibility();
  }

  setToggleButtonVisibility(): void {
    if (!this.content.current) {
      return;
    }

    const { collapsedHeight = 140 } = this.props;
    const { isToggleButtonVisible } = this.state;

    const dimensions = this.content.current.getBoundingClientRect();
    const maxHeight = collapsedHeight;
    const newValue = dimensions.height >= maxHeight;

    if (newValue !== isToggleButtonVisible)
      this.setState({ isToggleButtonVisible: newValue });
  }

  handleToggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> =
    (evt) => {
      evt.stopPropagation();
      this.setState((state) => ({ isOpen: !state.isOpen }));
    };

  render(): JSX.Element {
    const {
      children,
      className,
      collapsedHeight = 140,
      showLessLabel = "Show Less",
      showMoreLabel = "Show More",
    } = this.props;

    const { isOpen, isToggleButtonVisible } = this.state;

    return (
      <div className={twClass("flex w-full flex-col space-y-1", className)}>
        <div
          ref={this.content}
          className={twClass("relative overflow-hidden", {
            "after:absolute after:bottom-0 after:block after:h-8 after:w-full after:bg-gradient-to-t after:from-white after:to-transparent after:content-['']":
              !isOpen && isToggleButtonVisible,
          })}
          style={{ maxHeight: isOpen ? "initial" : collapsedHeight }}
        >
          <div>{children}</div>
        </div>
        {isToggleButtonVisible && (
          <div>
            <Anchor
              data-cy="RXJPGHq4L8q9VLMOanMfH"
              id="pd-text-truncate-btn"
              onClick={this.handleToggle}
              text={isOpen ? showLessLabel : showMoreLabel}
            />
          </div>
        )}
      </div>
    );
  }
}
