import { OnboardingStepId } from "@graphql";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { routes } from "route-configs";
import { useGetEntityUrl } from "common/layout/NavBar/hooks/useGetEntityUrl";
import { MODAL_TYPES } from "constants/index";
import { objectTypes } from "constants/objects";
import {
  OneOnOneMeetingModalType,
  StrategicPillarModalType,
  useModalRouter,
} from "hooks/useModalRouter/useModalRouter";
import { showModal } from "legacy/actions/actions";
import { mapEdges } from "utils/mapEdges";
import { useCompany } from "../useCompany/useCompany";
import { useCurrentUser } from "../useCurrentUser/useCurrentUser";

export const useCompleteOnboardingStep = () => {
  const company = useCompany();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const getEntityUrl = useGetEntityUrl();
  const { goToOneOnOneMeeting, goToObjective, goToPillar } = useModalRouter();
  const router = useRouter();

  const groups = mapEdges(currentUser?.groups.edges);
  const firstGroup = groups[0];

  const openAddUserModal = () => dispatch(showModal(MODAL_TYPES.ADD_USER));
  const openAddTeamKpiModal = () =>
    dispatch(
      showModal(MODAL_TYPES.ADD_KPI, {
        afterAdd: (kpiId: string) =>
          router.push(getEntityUrl(objectTypes.kpi, kpiId)),
        entityId: firstGroup?.id,
      })
    );
  const openAddCompanyObjectiveModal = () =>
    dispatch(
      showModal(MODAL_TYPES.ADD_OBJECTIVE, {
        callback: (objective: { id: string }) => {
          goToObjective({ oId: objective.id });
        },
        groupPrefilledId: company.id,
      })
    );
  const openAddTeamObjectiveModal = () =>
    dispatch(
      showModal(MODAL_TYPES.ADD_OBJECTIVE, {
        callback: (objective: { id: string }) => {
          goToObjective({ oId: objective.id });
        },
        groupPrefilledId: firstGroup?.id,
      })
    );
  const openAddOneOnOneModal = () =>
    goToOneOnOneMeeting({
      redirectAfter: true,
      type: OneOnOneMeetingModalType.ADD,
    });

  const openAddPillarModal = () => {
    goToPillar({
      type: StrategicPillarModalType.ADD,
    });
  };

  const goToRoadmap = () => {
    router.push(routes.mapsv3.main);
  };

  const goToConfigureCheckin = () => {
    router.push(routes.settings.company.general.checkins.users);
  };

  const actions: Record<OnboardingStepId, () => void> = {
    ADD_COMPANY_OKR: openAddCompanyObjectiveModal,
    ADD_ONE_ON_ONE: openAddOneOnOneModal,
    ADD_PILLAR: openAddPillarModal,
    ADD_TEAM_KPI: openAddTeamKpiModal,
    ADD_TEAM_MEMBER: openAddUserModal,
    ADD_TEAM_OKR: openAddTeamObjectiveModal,
    ADD_ULTIMATE_GOAL: goToRoadmap,
    ALIGN_OKRS: goToRoadmap,
    CONFIGURE_CHECKIN: goToConfigureCheckin,
  };

  return { actions };
};
