import { useGetKpiModalBaseKpiQuery } from "@graphql";
import { GoalOwners } from "common/goal/GoalOwners/GoalOwners";
import { Icon } from "common/icons";
import { Tag } from "common/tag/Tag/Tag";
import { showServerErrorToast } from "utils/utils";

type Props = {
  className?: string;
  id: string;
};

export const KpiInfoTooltip = ({ className = "", id }: Props) => {
  const { data, loading } = useGetKpiModalBaseKpiQuery({
    onError: (err) => showServerErrorToast(err),
    variables: { id },
  });

  if (!data?.kpi || loading) {
    return null;
  }

  const { name, tags, groups, isCompanyGoal, lead, company } = data.kpi;

  return (
    <div className={className}>
      <div className="mb-1 flex flex-wrap items-center space-x-2">
        <div className="flex items-center space-x-1 text-base font-semibold">
          <Icon name="monitor_heart" type="outlined" size="lg" />
          <div>{name}</div>
        </div>
        {tags.edges.map((edge) => (
          <Tag
            key={edge.node.id}
            className="mr-0.5 bg-transparent text-xs px-1 h-auto"
            color={edge.node.color}
            text={edge.node.name}
          />
        ))}
      </div>
      <div className="flex flex-wrap">
        <div className="mr-2 flex space-x-1 text-slate-300">
          {groups.edges.length ? (
            <GoalOwners
              className="truncate p-1 text-slate-300 hover:bg-transparent"
              goalId={id}
              goalType="kpi"
              isCompanyGoal={isCompanyGoal}
              maxCount={2}
              owners={groups}
              textClassName="max-w-[20ch]"
            />
          ) : (
            <div>{company.name}</div>
          )}
        </div>
        <div className="mr-2 flex items-center space-x-1 text-slate-300">
          <Icon name="person" size="xl" />
          <div>{lead?.fullName}</div>
        </div>
      </div>
    </div>
  );
};
