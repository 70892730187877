import { GoalUpdateCycle } from "@graphql";
import { useEffect, useState } from "react";
import { Frequency } from "common/overlay/FrequencyDropdown/FrequencyDropdown";

const updateCycleToFrequencyMap: Record<GoalUpdateCycle, Frequency> = {
  [GoalUpdateCycle.Weekly]: "W",
  [GoalUpdateCycle.Biweekly]: "W",
  [GoalUpdateCycle.Monthly]: "M",
  [GoalUpdateCycle.Quarterly]: "Q",
  [GoalUpdateCycle.Every_4Months]: "M",
  [GoalUpdateCycle.Every_6Months]: "Q",
  [GoalUpdateCycle.Yearly]: "Y",
} as const;

export const usePOTFrequency = (goalUpdateCycle: GoalUpdateCycle) => {
  const defaultFrequency = updateCycleToFrequencyMap[goalUpdateCycle];
  const [frequency, setFrequency] = useState<Frequency | undefined>(
    defaultFrequency
  );

  useEffect(() => {
    setFrequency(defaultFrequency);
  }, [defaultFrequency]);

  return [frequency, setFrequency] as const;
};
