import React from "react";
import {
  defineMessages,
  FormattedDate,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { Button } from "common/buttons";
import { Icon } from "common/icons";
import { usePerformanceReviewCompleteContext } from "common/performanceReview/context/performanceReviewCompleteContext";
import { PerformanceReviewFormRootBanner } from "../PerformanceReviewFormRootBanner";
const messages = defineMessages({
  completedBanner: {
    defaultMessage:
      "Review completed on {dueDate}. See all the questions and answers below.",
    id: "performanceReview:completed:banner",
  },
});

interface Props {
  handleExport: () => void;
}

export const PerformanceReviewCompletedBanner = ({
  handleExport,
}: Props): JSX.Element => {
  const { dueDate } = usePerformanceReviewCompleteContext();
  const intl = useIntl();
  const message = intl.formatMessage(messages.completedBanner, {
    dueDate: (
      <FormattedDate
        day="numeric"
        month="short"
        value={dueDate}
        year="numeric"
      />
    ),
  });

  return (
    <PerformanceReviewFormRootBanner type="success">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center space-x-2">
          <Icon name="check" size="lg" type="outlined" />
          <span className="text-sm">{message}</span>
        </div>
        <div className="print:hidden">
          <Button
            key="export-reviews"
            className="p-1"
            icon="save_alt"
            variant="ghost"
            size="small"
            onClick={handleExport}
          >
            <FormattedMessage defaultMessage="Export" id="SVwJTM" />
          </Button>
        </div>
      </div>
    </PerformanceReviewFormRootBanner>
  );
};
