import {
  Commit,
  KeyResultDefaultFragment,
  KpiGoalFragment,
  PerdooApiKpiGoalOperatorChoices,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { isNil, uniqWith } from "lodash";
import { colorTheme } from "constants/colorTheme";
import { objectTypes } from "constants/objects";
import { goalOperators } from "../../constants";

export const getNormalizedResultCommitDelta = (
  { delta }: { delta: number },
  { startValue, endValue }: { endValue: number; startValue: number }
): number => {
  return endValue !== startValue
    ? Math.round((100 * delta) / (endValue - startValue))
    : 100;
};

export const getNormalizedCommitDeltaColor = (
  commit: { delta: number },
  goal: {
    __typename: string;
    endValue: number;
    goalOperator: PerdooApiKpiGoalOperatorChoices;
    goalThreshold: number;
    startValue: number;
  }
): string => {
  const normalizedDelta = getNormalizedResultCommitDelta(commit, goal);
  return getCommitDeltaColor(normalizedDelta, goal);
};

type KeyResult = Pick<KeyResultDefaultFragment, "__typename">;
type Kpi = Omit<KpiGoalFragment, "__typename"> & {
  __typename: string;
};

export const getCommitDeltaColor = (
  delta: number,
  goal: Kpi | KeyResult | null
): string => {
  if (goal === null) return colorTheme.blue[200];
  if (goal.__typename === objectTypes.kpi) {
    const { goalOperator, goalThreshold } = goal;
    const { GREATER_THAN_OR_EQUAL, LESS_THAN_OR_EQUAL } = goalOperators;

    if (goalOperator === GREATER_THAN_OR_EQUAL && !isNil(goalThreshold)) {
      return delta > 0 ? colorTheme.green[500] : colorTheme.red[500];
    }

    if (goalOperator === LESS_THAN_OR_EQUAL && !isNil(goalThreshold)) {
      return delta < 0 ? colorTheme.green[500] : colorTheme.red[500];
    }

    return colorTheme.blue[200];
  }

  return delta >= 0 ? colorTheme.green[500] : colorTheme.red[500];
};

type GetUniqueGoalsCountCommit = Derive<
  Commit,
  { keyResult: { id: true }; kpi: { id: true } }
>;

export const getUniqueGoalsCount = (commits: GetUniqueGoalsCountCommit[]) =>
  uniqWith(commits, (a, b) => {
    const sameResult = !!a.keyResult && a.keyResult.id === b.keyResult?.id;
    const sameKpi = !!a.kpi && a.kpi.id === b.kpi?.id;
    return sameResult || sameKpi;
  }).length;
