import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  element?: ReactNode;
};

export const Target = ({ element, children }: Props): JSX.Element => {
  return (
    <span className="font-semibold">
      {element}
      {children}
    </span>
  );
};
