import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createRootReducer } from "../reducers";
import { api } from "./middlewares/api";

export const initiateStore = () => {
  const composeEnhancers =
    // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;
  const store = createStore(
    createRootReducer,
    {},
    composeEnhancers(applyMiddleware(thunk, api))
  );
  if (typeof window !== "undefined" && window.Cypress) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'store' does not exist on type 'Window & ... Remove this comment to see the full error message
    window.store = store;
  }
  return store;
};

export const store = initiateStore();
