import React from "react";
import { FormattedMessage } from "react-intl";
import { GroupName } from "common/group/GroupName/GroupName";
import { intersperse } from "utils/intersperse";
import { logToSentry } from "../../../utils/tracker";
import { EventTypes } from "../constants";
import { Target } from "../Target/Target";
import { EventFormatter } from "./types";

const { KpiCreated, KpiChanged, KpiRelationAdded, KpiRelationRemoved } =
  EventTypes;

export const kpiFormatters: Record<string, EventFormatter> = {
  [KpiChanged]: {
    getComment: () => null,
    getString: (attr, { company }) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.change.attribute) {
        case "name": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="renamed {oldName} to {newName}"
              id="event:formatter:kpi:renamed"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "description": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="updated the description of {kpiName}"
              id="event:formatter:kpi:changedDescription"
              values={{
                kpiName: <Target element={extra.name} />,
              }}
            />
          );
          break;
        }
        case "metric_unit": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the metric unit of {kpiName} from {oldVal} to {newVal}"
              id="event:formatter:kpi:changedUnit"
              values={{
                kpiName: <Target element={extra.name} />,
                newVal: <Target element={extra.change.new_value} />,
                oldVal: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "lead": {
          formattedEvent = (
            <FormattedMessage
              defaultMessage="changed the lead from {oldName} to {newName}"
              id="event:formatter:kpi:lead"
              values={{
                newName: <Target element={extra.change.new_value} />,
                oldName: <Target element={extra.change.old_value} />,
              }}
            />
          );
          break;
        }
        case "is_company_goal": {
          if (
            extra.change.new_value === "True" &&
            extra.change.old_value === "False"
          ) {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="added {company} as owner"
                id="zBpogU"
                values={{
                  company: <Target element={company.name} />,
                }}
              />
            );
          } else {
            formattedEvent = (
              <FormattedMessage
                defaultMessage="removed {company} as owner"
                id="VhOfLx"
                values={{
                  company: <Target element={company.name} />,
                }}
              />
            );
          }
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: KpiChanged,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
        }
      }
      return formattedEvent;
    },
  },
  [KpiCreated]: {
    getComment: () => null,
    getString: (attr) => (
      <FormattedMessage
        defaultMessage="created {kpiName}"
        id="event:formatter:kpi:created"
        values={{
          kpiName: <Target element={attr.extra.name} />,
        }}
      />
    ),
  },
  [KpiRelationAdded]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "groups": {
          const groupIds = extra.relation.pk_set;
          formattedEvent = (
            <FormattedMessage
              defaultMessage="added {teams} as as {count, plural, one {owner} other {owners}}"
              id="6m/mfJ"
              values={{
                count: groupIds.length,
                teams: intersperse(
                  groupIds.map((id: string) => (
                    <GroupName key={id} id={id}>
                      {(group) => <strong>{group.name}</strong>}
                    </GroupName>
                  )),
                  ","
                ),
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: KpiRelationAdded,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
  [KpiRelationRemoved]: {
    getComment: () => null,
    getString: (attr) => {
      const { extra } = attr;
      let formattedEvent;

      switch (extra.relation.attribute) {
        case "groups": {
          const groupIds = extra.relation.pk_set;
          formattedEvent = (
            <FormattedMessage
              defaultMessage="removed {teams} as {count, plural, one {owner} other {owners}}"
              id="x+vj+J"
              values={{
                count: groupIds.length,
                teams: intersperse(
                  groupIds.map((id: string) => (
                    <GroupName key={id} id={id}>
                      {(group) => <strong>{group.name}</strong>}
                    </GroupName>
                  )),
                  ","
                ),
              }}
            />
          );
          break;
        }
        default: {
          logToSentry(`Missing event formatter`, {
            eventType: KpiRelationRemoved,
            extra,
          });
          formattedEvent = <span>{attr.description}</span>;
          break;
        }
      }

      return formattedEvent;
    },
  },
};
