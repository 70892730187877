import { Objective } from "@graphql";
import { Derive } from "@shoooe/derive";
import { Show } from "common/controlFlow";
import { Widget } from "common/misc/Widget/Widget";
import { ResultList } from "common/result";
import { twClass } from "utils/twClass";
import { objectiveStages } from "../constants";
import { useViewToggleContext } from "../context";
import { ObjectiveWrapper } from "../ObjectiveWrapper/ObjectiveWrapper";

type WidgetObjective = Derive<
  Objective,
  {
    id: true;
    progressDriver: true;
    stage: true;
    timeframe: {
      isPast: true;
    };
  }
>;

type ObjectiveWidget = {
  objective?: WidgetObjective;
};

export const ObjectiveWidget = ({ objective }: ObjectiveWidget) => {
  const { currentView } = useViewToggleContext();

  if (!objective) return null;

  const active = objective.stage !== objectiveStages.closed;
  const { isPast } = objective.timeframe;

  return (
    <Widget
      className={twClass("mt-2 pr-6", {
        "border-red-500": active && isPast,
      })}
      noPadding
    >
      <ObjectiveWrapper objective={objective} />
      <Show when={currentView === "unfolded"}>
        <ResultList objective={objective} />
      </Show>
    </Widget>
  );
};
