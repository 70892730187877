import { defineMessages } from "react-intl";

export const messages = defineMessages({
  descriptionHelperText: {
    defaultMessage:
      "Your description ideally includes the formula used to calculate the value.",
    id: "kpi:forms:helper:text:description",
  },
  descriptionLabel: {
    defaultMessage: "Description",
    id: "kpi:forms:description:label",
  },
  descriptionPlaceholder: {
    defaultMessage: "Add a description...",
    id: "kpi:forms:description:placeholder",
  },
  globalSearch: {
    defaultMessage: "Search",
    id: "global:search",
  },
  goalUpdateCycleLabel: {
    defaultMessage: "Update frequency",
    id: "kpiform:updateCycle:label",
  },
  greaterThanOrEqualTo: {
    defaultMessage: "Greater than or equal to",
    id: "kpi:forms:greaterThanOrEqualTo",
  },
  kpiTargets: {
    defaultMessage: "Setting KPI targets",
    id: "global:premium:kpiTargets",
  },
  leadHelperText: {
    defaultMessage: "The person responsible for updating the KPI",
    id: "kpi:forms:helper:text:lead",
  },
  leadLabel: {
    defaultMessage: "Lead",
    id: "kpi:forms:lead:label",
  },
  leadPlaceholder: {
    defaultMessage: "Search...",
    id: "kpi:forms:lead:placeholder",
  },
  lessThanOrEqual: {
    defaultMessage: "Less than or equal to",
    id: "kpi:forms:lessThanOrEqualTo",
  },
  metricUnitsLabel: {
    defaultMessage: "Metric Unit",
    id: "keyresult:forms:metricUnits:label",
  },
  nameLabel: {
    defaultMessage: "Metric Name",
    id: "kpi:forms:name:label",
  },
  namePlaceholder: {
    defaultMessage: "Example: Monthly Recurring Revenue",
    id: "kpi:forms:name:placeholder",
  },
  ownerHelperText: {
    defaultMessage: "KPIs are owned by either the company as a whole or groups",
    id: "kpi:forms:helper:text:owner",
  },
  pillarDisabledMessage: {
    defaultMessage:
      "It’s not possible to align Private KPIs to Strategic Pillars. Make this KPI public to align it.",
    id: "kpi:forms:pillar:disabled:message",
  },
  privateKpisDescription: {
    defaultMessage:
      "Hide KPI from everyone except the lead and people you select. {link}",
    id: "kpi:forms:private:kpis:description",
  },
  privateKpisLabel: {
    defaultMessage: "Private KPI",
    id: "kpi:forms:private:kpis",
  },
  sharedOwnership: {
    defaultMessage: "Shared ownership",
    id: "kpi:forms:sharedOwnership",
  },
  sharedOwnershipTooltip: {
    defaultMessage:
      "Multiple teams can own a KPI. Add the different owners here.",
    id: "kpi:forms:sharedOwnership:tooltip",
  },
  strategicPillarText: {
    defaultMessage:
      "Strategic Pillars don’t have due dates, so they are measured by KPIs.",
    id: "kpi:forms:helper:text:strategicPillar",
  },
  targetValue: {
    defaultMessage: "Target value",
    id: "NiAH1z",
  },
  targetValueHelperText: {
    defaultMessage:
      "The minimum or maximum value you want that metric to be. A yellow warning sign will appear when the target isn't met.",
    id: "kpi:forms:helper:text:target",
  },
  valueLabel: {
    defaultMessage: "Current Value",
    id: "kpi:forms:value:label",
  },
  valuePlaceholder: {
    defaultMessage: "What is it right now?",
    id: "kpi:forms:value:placeholder",
  },
  visibleToLabel: {
    defaultMessage: "Visible to the Lead and the following people:",
    id: "kpi:forms:visible:to:label",
  },
});
