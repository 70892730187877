import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useHashUrl } from "utils/navigation";

const getLocalCollapseState = () =>
  localStorage.getItem("isSidebarCollapsed") === "true";
const setLocalCollapseState = (value: boolean) =>
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
  localStorage.setItem("isSidebarCollapsed", value);

type Hook = {
  hideSidebar: boolean;
  isCollapsed?: boolean;
  toggleCollapsed: () => void;
};

export const useCollapsibleSidebar = (): Hook => {
  const [isCollapsed, setCollapsed] = useState<boolean>();
  const toggleCollapsedState = () => setCollapsed((prev) => !prev);
  const { asPath } = useRouter();

  let hideSidebar = false;
  const { topLevelModule } = useHashUrl();
  if (topLevelModule === "ms-teams" || asPath.includes("/ms-teams")) {
    hideSidebar = true;
  }

  useEffect(() => {
    setCollapsed(getLocalCollapseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCollapsed = () => {
    setLocalCollapseState(!isCollapsed);
    toggleCollapsedState();
  };

  return {
    hideSidebar,
    isCollapsed,
    toggleCollapsed,
  };
};
