import { defineMessages } from "react-intl";

export const messages = defineMessages({
  asanaProgressType: {
    defaultMessage: "Calculate progress based on",
    id: "result:form:asanaProgressType:label",
  },
  asanaWorkspaceLabel: {
    defaultMessage: "Workspace",
    id: "result:form:asanaWorkspace:label",
  },
  contributorsLabel: {
    defaultMessage: "Contributors",
    id: "result:form:contributors:label",
  },
  contributorsPlaceholder: {
    defaultMessage: "Search...",
    id: "result:form:contributors:placeholder",
  },
  descriptionLabel: {
    defaultMessage: "Description",
    id: "result:form:description:label",
  },
  descriptionPlaceholder: {
    defaultMessage: "Add a description...",
    id: "result:form:description:placeholder",
  },
  dueDateLabel: {
    defaultMessage: "Due date",
    id: "result:form:dueDate:label",
  },
  endLabel: {
    defaultMessage: "Target value",
    id: "result:form:endValue:label",
  },
  endPlaceholder: {
    defaultMessage: "Target value",
    id: "result:form:endValue:placeholder",
  },
  jiraHelperText: {
    defaultMessage:
      "Connect to Jira issue to automate progress updates on this Result",
    id: "result:form:jira:helper:info",
  },
  jiraLabel: {
    defaultMessage: "Jira issue",
    id: "result:form:jira:label",
  },
  jiraProgressTypeLabel: {
    defaultMessage: "Track progress by",
    id: "result:form:jira:progressType:label",
  },
  keyResultLabel: {
    defaultMessage: "Parent Key Result",
    id: "result:form:parent:key_result",
  },
  leadLabel: {
    defaultMessage: "Lead",
    id: "result:form:lead:label",
  },
  metricUnit: {
    defaultMessage: "Measure as a:",
    id: "result:form:metricUnits:label",
  },
  nameLabel: {
    defaultMessage: "Name",
    id: "result:form:name:label",
  },
  objectiveLabel: {
    defaultMessage: "Objective",
    id: "khNSj1",
  },
  startDateLabel: {
    defaultMessage: "Start date",
    id: "result:form:startDate:label",
  },
  startLabel: {
    defaultMessage: "Start value",
    id: "result:form:startValue:label",
  },
  startPlaceholder: {
    defaultMessage: "Start value",
    id: "result:form:startValue:placeholder",
  },
});

interface Messages {
  [key: string]: string[];
}

export const actionWords: Messages = {
  en: [
    "analyse",
    "analyze",
    "collect",
    "commit",
    "complete",
    "conduct",
    "create",
    "define",
    "design",
    "discuss",
    "distribute",
    "do",
    "document",
    "draft",
    "evaluate",
    "execute",
    "finalize",
    "finish",
    "get",
    "give",
    "hire",
    "identify",
    "implement",
    "kick off",
    "launch",
    "migrate",
    "modify",
    "offer",
    "plan",
    "publish",
    "release",
    "review",
    "roll-out",
    "run",
    "schedule",
    "send",
    "submit",
    "test",
    "transfer",
    "visit",
    "write",
  ],
};
