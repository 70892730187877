import {
  BookmarkTargetType,
  useGetKpiModalBaseKpiQuery,
  useGetKpiModalBaseKpiTimelineQuery,
} from "@graphql";
import { useRouter } from "next/router";
import { BookmarkButton } from "common/buttons/BookmarkButton/BookmarkButton";
import { KpiContextProvider } from "common/context/kpiContext";
import {
  TimelineBag,
  TimelineContext,
} from "common/event/TimelineContext/TimelineContext";
import { KpiDropdown } from "common/kpi/KpiDropdown/KpiDropdown";
import { Modal } from "common/overlay/Modal/Modal";
import { useGoalTimelineFilter } from "hooks/useGoalTimelineFilter/useGoalTimelineFilter";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { twClass } from "utils/twClass";
import { handleError } from "utils/utils";
import { KpiDetails } from "./KpiDetails/KpiDetails";
import { KpiModalHeader } from "./KpiModalHeader/KpiModalHeader";

interface Props {
  id: string;
}

export const KpiDetailsModal = ({ id }: Props) => {
  const { query } = useRouter();
  const { close } = useModalRouter();

  const stack = query.stack ?? false;

  const { filterOptions, setTimelineFilter, timelineFilter } =
    useGoalTimelineFilter();

  const { data, error, loading } = useGetKpiModalBaseKpiQuery({
    onError: (err) => {
      handleError(err);
      close();
    },
    variables: { id },
  });

  const {
    loading: timelineLoading,
    data: timelineData,
    refetch,
    fetchMore,
  } = useGetKpiModalBaseKpiTimelineQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      eventType: timelineFilter.value,
      id,
    },
  });

  if (error) {
    return null;
  }

  const kpi = data?.kpi;

  const timelineBag: TimelineBag = {
    fetchMore,
    filterOptions,
    setTimelineFilter,
    timelineData: timelineData?.kpi?.events,
    timelineFilter,
    timelineLoading,
    updateTimeline: (props) => {
      refetch(props);
    },
  };

  let modalProps = {};

  if (!loading && kpi) {
    modalProps = {
      ...modalProps,
      extraIconButtons: [
        <BookmarkButton
          key="kpi-bookmark"
          targetId={kpi.id}
          targetType={BookmarkTargetType.Kpi}
        />,
      ],
      renderActions: () =>
        kpi.canPatch && (
          <KpiDropdown
            afterDelete={close}
            dropdownOptions={["archive", "delete", "addOkr"]}
            kpi={kpi}
          />
        ),
      title: <KpiModalHeader />,
    };
  }

  return (
    <TimelineContext.Provider value={timelineBag}>
      <KpiContextProvider value={kpi!}>
        <Modal
          backdropClass={twClass({ "!z-modal-prio-backdrop": stack })}
          className={twClass({ "!z-modal-prio": stack })}
          data-cy="modalKpi"
          // we don't want to accidentally close modal while adding a progress update
          disableEsc
          isOpen
          loading={loading}
          onClose={close}
          scrollable={false}
          showFooter={false}
          showHeaderBodyDivider
          size="xl"
          title="Loading..."
          {...modalProps}
        >
          <KpiDetails />
        </Modal>
      </KpiContextProvider>
    </TimelineContext.Provider>
  );
};
