import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { resources } from "common/layout/HelpCenter/HelpCenter.messages";
import { useHelpCenterTrack } from "common/layout/HelpCenter/hooks/useHelpCenterTrack";
import { AnchorNextTrack } from "common/navigation";

export const Resources = (): JSX.Element => {
  const intl = useIntl();
  const { trackContentHandler } = useHelpCenterTrack();

  return (
    <div className="border-b px-5 py-4 border-slate-300">
      <div className="flex justify-between pb-2.5">
        <h6 className="my-0 text-sm">Resources</h6>
        <AnchorNextTrack
          data-cy="hWfWWuv-XGpKb1gYls7Cn"
          href="https://support.perdoo.com/en/"
          onClick={trackContentHandler("https://support.perdoo.com/en/")}
          target="blank"
        >
          <FormattedMessage defaultMessage="See more" id="global:seeMore" />
        </AnchorNextTrack>
      </div>
      <AnchorNextTrack
        data-cy="nzPnwKbgxwnbGzViFnPco"
        className="flex w-full items-center gap-2 py-2.5 text-slate-800 hover:text-blue-500"
        href="https://support.perdoo.com/en/collections/1583516-quick-start-guide"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/1583516-quick-start-guide"
        )}
        target="blank"
      >
        <Icon name="flight_takeoff" size="2xl" />
        {intl.formatMessage(resources.quickStartGuide)}
      </AnchorNextTrack>
      <AnchorNextTrack
        data-cy="jfLfa59JpSUor3Vf7wsXO"
        className="flex w-full items-center gap-2 py-2.5 text-slate-800 hover:text-blue-500"
        href="https://support.perdoo.com/en/collections/2512003-key-features-explained"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/2512003-key-features-explained"
        )}
        target="blank"
      >
        <Icon name="vpn_key" size="2xl" />
        {intl.formatMessage(resources.keyFeatures)}
      </AnchorNextTrack>
      <AnchorNextTrack
        data-cy="4Knzh9mbS3rVz3kWroQBr"
        className="flex w-full items-center gap-2 py-2.5 text-slate-800 hover:text-blue-500"
        href="https://support.perdoo.com/en/collections/2512070-create-update-and-achieve-goals"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/2512070-create-update-and-achieve-goals"
        )}
        target="blank"
      >
        <Icon name="flag" size="2xl" />
        {intl.formatMessage(resources.goals)}
      </AnchorNextTrack>
      <AnchorNextTrack
        data-cy="nPK1-T9jgw5J28LaeOdiC"
        className="flex w-full items-center gap-2 py-2.5 text-slate-800 hover:text-blue-500"
        href="https://support.perdoo.com/en/collections/1431612-integrations"
        onClick={trackContentHandler(
          "https://support.perdoo.com/en/collections/1431612-integrations"
        )}
        target="blank"
      >
        <Icon name="widgets" size="2xl" />
        {intl.formatMessage(resources.integrations)}
      </AnchorNextTrack>
    </div>
  );
};
