import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noSubgroups: {
    defaultMessage: "0 subgroups",
    id: "subGroups:indicator:empty",
  },
  subGroupsIndicatorCount: {
    defaultMessage: "{count} subgroups",
    id: "subGroups:indicator:count",
  },
});
