import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "common/buttons";
import { useResultContext } from "common/context/resultContext";
import { Show } from "common/controlFlow";
import { MODAL_TYPES } from "constants/index";
import { resultTypes } from "constants/resultTypes";
import { showModal } from "legacy/actions/actions";

export const SupportingInitiativesHeader = () => {
  const result = useResultContext();
  const dispatch = useDispatch();

  const addResult = (type: string) => {
    dispatch(
      showModal(MODAL_TYPES.ADD_RESULT, {
        filters: { parentIsnull: true },
        objectiveId: result.objective.id,
        parentId: result.id,
        type,
      })
    );
  };

  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center space-x-2">
        <div>
          <FormattedMessage
            defaultMessage="Supporting Initiatives"
            id="FrUIry"
          />
        </div>
        <div
          className="py-0.25 rounded border px-1 text-xs bg-white"
          data-testid="resultCount"
        >
          {result.childrenCount}
        </div>
      </div>
      <div>
        <Show when={result.canPatch}>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              addResult(resultTypes.initiative);
            }}
            data-cy="addResult"
            icon="add"
            iconSize="base"
          >
            <FormattedMessage defaultMessage="Add" id="2/2yg+" />
          </Button>
        </Show>
      </div>
    </div>
  );
};
