import includes from "lodash/includes";

const WINDOWS = "windows";
const OSX = "osx";
const LINUX = "linux";

type Platform = "windows" | "osx" | "linux";

export const getPlatform = (): Platform => {
  const { platform } = navigator;
  if (includes(platform, "Mac")) return OSX;
  if (includes(platform, "Win")) return WINDOWS;
  return LINUX;
};
