import React from "react";
import { For } from "common/controlFlow";
import { Clickable } from "common/navigation/Clickable";
import { AlignedObjective } from "hooks/useGetAlignedObjectives/useGetAlignedObjectives";
import {
  ObjectiveSelectFilters,
  useObjectiveSelect,
} from "../ObjectiveSelect/hooks/useObjectiveSelect";
import { ObjectiveSelectItem } from "../ObjectiveSelect/ObjectiveSelectItem";
import { Searchbox } from "../Searchbox/Searchbox";

export type ObjectiveSelectType = AlignedObjective;

type Props = {
  excludedIds?: string[];
  filters?: ObjectiveSelectFilters;
  onChange: (id: string) => void;
};

export const ObjectivePicker = ({
  onChange,
  filters,
  excludedIds,
}: Props): JSX.Element | null => {
  const { handleChange, handleSearch, visibleOptions } = useObjectiveSelect({
    excludedIds,
    filters,
    onChange,
  });

  return (
    <div className="divide-y divide-slate-300 w-84">
      <Searchbox onChange={handleSearch} />
      <div className="max-h-60 overflow-y-scroll">
        <For each={visibleOptions}>
          {(option) => (
            <Clickable
              className="block w-full cursor-default px-2.5 py-2 text-slate-800 hover:bg-blue-100"
              onClick={() => handleChange(option)}
            >
              <ObjectiveSelectItem size="lg" objective={option} />
            </Clickable>
          )}
        </For>
      </div>
    </div>
  );
};
