import { isNil } from "lodash";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { For } from "common/controlFlow";
import { TimelineContext } from "common/event/TimelineContext/TimelineContext";
import { Dropdown } from "common/overlay/Dropdown";
import { twClass } from "utils/twClass";

export const GoalActivityHeader = () => {
  const { timelineFilter, filterOptions, setTimelineFilter, timelineData } =
    useContext(TimelineContext);

  return (
    <div className="flex justify-between w-full items-center">
      <div className="flex items-center space-x-2">
        <FormattedMessage
          tagName="div"
          defaultMessage="Activity"
          id="activity:title"
        />
        {!isNil(timelineData?.totalCount) && (
          <div className="py-0.25 rounded border px-1 text-xs bg-white">
            {timelineData.totalCount}
          </div>
        )}
      </div>
      <div className="flex space-x-4 items-center font-normal">
        <Dropdown>
          <Dropdown.Trigger>
            <TextButton
              className="text-sm text-blue-500"
              isDropdown
              size="small"
              text={timelineFilter?.name}
            />
          </Dropdown.Trigger>
          <Dropdown.Content placement="bottom-right">
            <For each={filterOptions}>
              {(option) => (
                <Dropdown.Item
                  data-cy="SUu7pLca_XUr4fwbUNVum"
                  className={twClass(
                    "flex items-center focus:bg-blue-300 active:bg-blue-300 text-sm",
                    {
                      "text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700":
                        timelineFilter?.id === option.id,
                    }
                  )}
                  onClick={() => setTimelineFilter(option)}
                >
                  {option.name}
                </Dropdown.Item>
              )}
            </For>
          </Dropdown.Content>
        </Dropdown>
      </div>
    </div>
  );
};
