import dayjs from "dayjs";
import { useKpiContext } from "common/context/kpiContext";
import { useCanUpdateProgress } from "common/goal/hooks/useCanUpdateProgress";
import { useProgressUpdate } from "common/goal/hooks/useProgressUpdate";
import { ProgressUpdateButton } from "common/goal/ProgressUpdateButton/ProgressUpdateButton";
import { useKpiData } from "common/kpi/hooks/useKpiData";
import { ProgressCTAButton } from "common/progress/ProgressCTAButton/ProgressCTAButton";
import { objectTypes } from "constants/objects";

export const KpiProgressUpdateButton = () => {
  const kpi = useKpiContext();
  const { handleSubmit } = useProgressUpdate(kpi);
  const { positiveMetric } = useKpiData(kpi );
  const { canUpdateProgress, reason } = useCanUpdateProgress(kpi);

  return (
    <ProgressUpdateButton
      backdating
      button={<ProgressCTAButton tooltip={reason} />}
      initialValues={{
        commitDate: dayjs().toISOString(),
        value: kpi.currentValue,
      }}
      disabled={!canUpdateProgress}
      metricUnit={kpi.metricUnit}
      onSubmit={handleSubmit}
      positiveMetric={positiveMetric}
      type={objectTypes.kpi}
    />
  );
};
