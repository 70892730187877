import { Field, Form, Formik, FormikHelpers } from "formik";
import includes from "lodash/includes";
import React from "react";
import { useIntl } from "react-intl";
import { routes } from "route-configs";
import { Button } from "common/buttons";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { Icon } from "common/icons/Icon/Icon";
import { AnchorNext } from "common/navigation";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { objectTypes } from "constants/objects";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { messages } from "./ArchiveResultForm.messages";

export interface ArchiveResultFormValues {
  comment: string;
}
interface ArchiveResultFormProps {
  initialValues: ArchiveResultFormValues;
  onSubmit: (
    values: ArchiveResultFormValues,
    actions: FormikHelpers<ArchiveResultFormValues>
  ) => void;
}

export const ArchiveResultForm = ({
  initialValues,
  onSubmit,
}: ArchiveResultFormProps): JSX.Element => {
  const intl = useIntl();
  const me = useCurrentUser();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ isSubmitting, submitForm }) => (
        <Form className="mt-2">
          <Field
            component={FormTextArea}
            data-cy="updateComment"
            label={intl.formatMessage(messages.commentLabel)}
            name="comment"
            optional
            placeholder={intl.formatMessage(messages.commentPlaceholder)}
          />
          <br />
          <ModalFooter className="flex-row justify-between">
            {includes(me?.canCreate, objectTypes.timeframe) ? (
              <AnchorNext href={routes.settings.company.general.templates}>
                <div className="mr-2 flex items-center">
                  <Icon className="mr-2" name="help" />
                  <span>
                    {intl.formatMessage({
                      defaultMessage: "Change your Archive Results Templates",
                      id: "P2gRW5",
                    })}
                  </span>
                </div>
              </AnchorNext>
            ) : (
              <span>
                {intl.formatMessage({
                  defaultMessage:
                    "Superadmins can customise the description template.",
                  id: "ck1A8T",
                })}
              </span>
            )}
            <Button
              data-cy="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={submitForm}
              type="submit"
            >
              {intl.formatMessage({
                defaultMessage: "Save",
                id: "global:save",
              })}
            </Button>
          </ModalFooter>
        </Form>
      )}
    />
  );
};
