import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";

type Props = {
  onClose?: () => void;
  onConfirm?: () => void;
};

const messages = defineMessages({
  cancel: {
    defaultMessage: "Cancel review",
    id: "global:cancelPerformanceReviewSubmission",
  },
  cancelSubtext: {
    defaultMessage:
      "Are you sure you want to cancel the review process? All the information you have input will be lost.",
    id: "global:cancelPerformanceReviewConfirmationMessage",
  },
});

export const PerformanceReviewConfirmCancel = ({
  onClose = () => {},
  onConfirm = () => {},
}: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <ConfirmationModal
      action={onConfirm}
      close={onClose}
      confirmText={
        <FormattedMessage defaultMessage="Confirm" id="global:confirm" />
      }
      title={intl.formatMessage(messages.cancel)}
    >
      <div className="space-y-3">
        <div>{intl.formatMessage(messages.cancelSubtext)}</div>
      </div>
    </ConfirmationModal>
  );
};
