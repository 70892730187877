import { useRouter } from "next/router";
import React from "react";
import { routes } from "route-configs";
import { auth } from "common/authHelper";
import { useIsProtectedRoute } from "common/topLevel/useIsProtectedRoute/useIsProtectedRoute";
import { useInTeams } from "hooks/useInTeams/useInTeams";

interface Props {
  children: React.ReactNode;
}

/**
 * Redirects users without a token to the login page, if they're trying to access a private page.
 * TODO: this duplicates what {@link AuthWrapper} is doing. remove this component.
 */
export const PagePermissions = ({ children }: Props): JSX.Element | null => {
  const router = useRouter();
  const isProtectedRoute = useIsProtectedRoute();
  const inTeams = useInTeams();

  const hasToken = !!auth.getAccessToken() || !!auth.getRefreshToken();

  const loginRoute = inTeams
    ? routes.msTeams.authenticate
    : routes.login.regular;

  if (isProtectedRoute && !hasToken) {
    const query = {
      ...router.query,
    };

    if (router.pathname !== "/") query.redirectTo = router.pathname;

    router.push({
      pathname: loginRoute,
      query,
    });

    return null;
  }

  return <>{children}</>;
};
