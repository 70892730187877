import React from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { DangerousHTML } from "common/misc/DangerousHTML/DangerousHTML";

type Props = {
  note: string;
  userName: string;
};
export const OneonOneCompletePublicAnswer = ({
  note,
  userName,
}: Props): JSX.Element => (
  <div className="w-full space-y-1">
    <span className="text-sm text-slate-800">
      <FormattedMessage
        defaultMessage="{userName}'s notes"
        id="VbsTTH"
        values={{ userName }}
      />
    </span>
    <div className="border-l border-slate-300">
      <Show
        fallback={
          <span className="pl-4 text-base font-normal text-slate-800">
            <FormattedMessage defaultMessage="No notes taken" id="7707GY" />
          </span>
        }
        when={Boolean(note)}
      >
        <div className="pl-4">
          <DangerousHTML html={note} />
        </div>
      </Show>
    </div>
  </div>
);
