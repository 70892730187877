import { defineMessages } from "react-intl";
import { objectTypes } from "constants/objects";

export const messages = defineMessages({
  [objectTypes.objective]: {
    defaultMessage:
      "This objective has more than 5 Key Results, which may affect your team's ability to focus on what's most important.",
    id: "objective:kr:overload:keyResults",
  },
  [objectTypes.timeframe]: {
    defaultMessage:
      "This timeframe has more than 5 Objectives, which may affect your team's ability to focus on what's most important.",
    id: "objective:kr:overload:objective",
  },
});
