import { namedOperations } from "@graphql";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { compact, isEmpty, isNil, isString } from "lodash";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { For, Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";
import { Dropdown } from "common/overlay/Dropdown";
import { ONE_ON_ONE_MEETING_CANCELLED } from "constants/tracking";
import {
  OneOnOneMeetingModalType,
  useModalRouter,
} from "hooks/useModalRouter/useModalRouter";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { useCompleteOneOnOneContext } from "../context/completeOneOnOneContext";
import { useHandleOneOnOneMutations } from "../hooks/useHandleOneOnOneMutations";
import { CompleteOneOnOneValues } from "./OneOnOneCompleteForm";

export type CompleteMeetingInput = {
  isCancelled?: boolean;
  values: CompleteOneOnOneValues;
};

type Props = {
  handleCompleteMeeting: (input: CompleteMeetingInput) => Promise<{
    hasError: boolean;
  }>;
};
export const OneOnOneCompleteDatePicker = ({
  handleCompleteMeeting,
}: Props): JSX.Element | null => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const intl = useIntl();
  const { query } = useRouter();
  const { values } = useFormikContext<CompleteOneOnOneValues>();
  const { goToOneOnOneMeeting } = useModalRouter();
  const { upsertMeetingItems } = useHandleOneOnOneMutations({
    refetchQueries: [namedOperations.Query.GetOneOnOneMeeting],
  });
  const { meetings, nextMeeting, handleCompleteCheck } =
    useCompleteOneOnOneContext();
  const { mId, seriesId } = query;

  const activeMeeting = meetings.find(({ id }) => id === mId);

  if (isNil(activeMeeting)) return null;

  const formatDate = (dueDate?: string | null) => {
    return dayjs(dueDate).format("MMM D, YYYY - h:mm A");
  };

  const handleClick = (id: string) => {
    if (id === activeMeeting.id) return;
    goToOneOnOneMeeting({
      mId: id,
      seriesId: seriesId as string,
      type: OneOnOneMeetingModalType.COMPLETE,
    });
  };

  const handleUpsertMeetingItems = async () => {
    const items = compact([
      ...(values.actionItems ?? []),
      ...(values.talkingPoints ?? []),
    ]);
    if (!isEmpty(items) && isString(mId)) {
      const { hasError } = await upsertMeetingItems(mId, items);
      return { hasError };
    }
    return { hasError: false };
  };

  const handleCancelMeeting = async () => {
    const isComplete = await handleCompleteCheck();
    if (isComplete) return;
    if (!isNil(activeMeeting)) {
      const { hasError: bulkItemsError } = await handleUpsertMeetingItems();
      if (bulkItemsError) {
        toast.failure(
          intl.$t({
            defaultMessage: "Error saving meeting items",
            id: "iK0rSB",
          })
        );
        return;
      }
      const { hasError } = await handleCompleteMeeting({
        isCancelled: true,
        values,
      });
      if (hasError) {
        toast.failure(
          intl.$t({
            defaultMessage: "Error cancelling meeting",
            id: "+fp++8",
          })
        );
        return;
      }
      toast.success(
        intl.formatMessage({
          defaultMessage: "Meeting cancelled",
          id: "lZfK82",
        })
      );
      track(ONE_ON_ONE_MEETING_CANCELLED, {
        meetingId: activeMeeting.id,
      });
    }
  };

  const activeMeetingIndex = meetings.findIndex(
    (meeting) => meeting.id === activeMeeting.id
  );

  const navigateToMeeting = (move: number) => {
    if (activeMeetingIndex === -1) return;
    const newMeeting = meetings[activeMeetingIndex + move];

    if (!isNil(newMeeting)) {
      goToOneOnOneMeeting({
        mId: newMeeting.id,
        seriesId: seriesId as string,
        type: OneOnOneMeetingModalType.COMPLETE,
      });
    }
  };

  const isMostRecentMeeting = nextMeeting?.id === mId;

  const canCancelOrReschedule =
    !activeMeeting.isComplete && !activeMeeting.gCalEvent;

  const isBackDisabled =
    meetings.length === 1 || meetings.length - 1 === activeMeetingIndex;

  return (
    <>
      <Show when={showCancelModal}>
        <ConfirmationModal
          action={handleCancelMeeting}
          close={() => setShowCancelModal(false)}
          confirmText={
            <FormattedMessage defaultMessage="Confirm" id="global:confirm" />
          }
          title={<FormattedMessage defaultMessage="Cancel 1:1" id="laBOdw" />}
        >
          <FormattedMessage
            defaultMessage={
              "Are you sure you want to cancel this 1:1 meeting? " +
              "All existing notes will be carried over to the next meeting."
            }
            id="8wjti+"
          />
        </ConfirmationModal>
      </Show>
      <div className="my-2 flex items-center">
        <IconButton
          data-cy="previousMeeting"
          data-testid="previousMeeting"
          className="h-10 rounded-l border border-r-0 p-1.5 border-slate-300"
          disabled={isBackDisabled}
          name="chevron_left"
          onClick={() => navigateToMeeting(1)}
        />
        <IconButton
          data-cy="nextMeeting"
          data-testid="nextMeeting"
          className="h-10 border border-r-0 p-1.5 border-slate-300"
          disabled={isMostRecentMeeting}
          name="chevron_right"
          onClick={() => navigateToMeeting(-1)}
        />
        <Dropdown className="h-10 border p-1.5 px-2.5 text-base font-normal border-slate-300">
          <Dropdown.Trigger>
            <Button
              className="flex min-w-[11rem] items-center space-x-2 text-slate-800"
              size="small"
              variant="negative"
            >
              <div
                className={twClass("flex items-center text-base font-normal", {
                  "line-through": activeMeeting.isCancelled,
                })}
              >
                <Show
                  fallback={
                    <FormattedMessage
                      defaultMessage="Not planned"
                      id="ygO7pY"
                    />
                  }
                  when={!isNil(activeMeeting.dueDate)}
                >
                  {formatDate(activeMeeting.dueDate)}
                </Show>
              </div>
            </Button>
          </Dropdown.Trigger>
          <Dropdown.Content
            className="max-h-60 w-56 overflow-y-auto focus:outline-0"
            placement="bottom-left"
          >
            <For each={meetings}>
              {({ id, isCancelled, dueDate }) => (
                <Dropdown.Item
                  data-cy="rz7oLHhBG24cQ63YC4Jnu"
                  className={twClass(
                    "flex items-center focus:bg-blue-300 active:bg-blue-300",
                    {
                      "text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700":
                        activeMeeting.id === id,
                    }
                  )}
                  onClick={() => handleClick(id)}
                >
                  <span
                    className={twClass({
                      "line-through text-slate-400": isCancelled,
                      "text-white": activeMeeting.id === id && isCancelled,
                    })}
                  >
                    <Show
                      fallback={
                        <FormattedMessage
                          defaultMessage="Not planned"
                          id="ygO7pY"
                        />
                      }
                      when={!isNil(dueDate)}
                    >
                      {formatDate(dueDate)}
                    </Show>
                  </span>
                </Dropdown.Item>
              )}
            </For>
          </Dropdown.Content>
        </Dropdown>
        <Dropdown>
          <Dropdown.Trigger>
            <IconButton
              className="h-10 rounded-r border border-l-0 p-2 border-slate-300"
              name="more_vert"
              size="xl"
            />
          </Dropdown.Trigger>
          <Dropdown.Content
            className="mt-1 divide-y text-base font-normal"
            placement="bottom-left"
          >
            {canCancelOrReschedule && (
              <div>
                <Dropdown.Item
                  data-cy="37fJDOd2ul3kTW5AY3WBY"
                  onClick={async () => {
                    const isComplete = await handleCompleteCheck();
                    if (isComplete) return;
                    goToOneOnOneMeeting({
                      reschedule: true,
                      seriesId: seriesId as string,
                    });
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Reschedule event"
                    id="6RIgWp"
                  />
                </Dropdown.Item>
                <Dropdown.Item
                  data-cy="9MlRtKHHQk0zIy-OftCxC"
                  onClick={() => setShowCancelModal(true)}
                >
                  <FormattedMessage defaultMessage="Cancel event" id="3TdxIh" />
                </Dropdown.Item>
              </div>
            )}
            {activeMeeting.gCalEvent && (
              <Dropdown.Item
                data-cy="yLeR_83KhKWJaFmu8g2T4"
                className="flex items-center space-x-2"
                onClick={() =>
                  window.open(activeMeeting.gCalEvent?.link, "_blank")
                }
              >
                <div>
                  <FormattedMessage
                    defaultMessage="View linked event"
                    id="4u0uP0"
                  />
                </div>
                <Icon name="open_in_new" />
              </Dropdown.Item>
            )}
            <div>
              <Dropdown.Item
                data-cy="iut7fe5LPvcZF5eXtpGao"
                onClick={() => {
                  goToOneOnOneMeeting({
                    editSeries: true,
                    seriesId: seriesId as string,
                  });
                }}
              >
                <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
              </Dropdown.Item>
            </div>
          </Dropdown.Content>
        </Dropdown>
      </div>
    </>
  );
};
