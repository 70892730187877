import { shuffle } from "lodash";
import React, { useRef } from "react";
import { ReportCardHeader } from "common/reports/ReportCardHeader/ReportCardHeader";

const POSSIBLE_WIDTHS = [16, 20, 28, 32, 48, 56, 64, 72];

/**
 * skeleton with header and a list of 5 animated bars, to be used as loading placeholder.
 * has variable random bar widths because why not.
 */
export const ReportCardSkeleton = (): JSX.Element => {
  const widths = useRef(shuffle(POSSIBLE_WIDTHS));

  return (
    <div
      className="flex flex-col gap-2"
      data-cy="reportCardSkeleton"
      data-testid="reportCardSkeleton"
    >
      <ReportCardHeader />
      <ul className="mt-3">
        <li className="flex h-[40px] items-center gap-2 border-b py-2 border-slate-300">
          <span
            className={`my-5 h-5 animate-pulse rounded-full bg-slate-200 w-${widths.current[0]}`}
          />
        </li>
        <li className="flex h-[40px] items-center gap-2 border-b py-2 border-slate-300">
          <span
            className={`my-5 h-5 animate-pulse rounded-full bg-slate-200 w-${widths.current[1]}`}
          />
        </li>
        <li className="flex h-[40px] items-center gap-2 border-b py-2 border-slate-300">
          <span
            className={`my-5 h-5 animate-pulse rounded-full bg-slate-200 w-${widths.current[2]}`}
          />
        </li>
        <li className="flex h-[40px] items-center gap-2 border-b py-2 border-slate-300">
          <span
            className={`my-5 h-5 animate-pulse rounded-full bg-slate-200 w-${widths.current[3]}`}
          />
        </li>
        <li className="flex h-[40px] items-center gap-2 border-b py-2 border-slate-300">
          <span
            className={`my-5 h-5 animate-pulse rounded-full bg-slate-200 w-${widths.current[4]}`}
          />
        </li>
      </ul>
    </div>
  );
};
