import {
  CommitStatus,
  namedOperations,
  PerdooApiIntegrationApplicationChoices,
  useUpsertResultMutation,
} from "@graphql";
import { FormikHelpers } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { OBJECTIVE_QUERY } from "common/objective/queries";
import { Modal } from "common/overlay/Modal/Modal";
import { RESULTS_QUERY } from "common/result/queries";
import { ResultForm } from "common/result/ResultForm/ResultForm";
import { ResultFormValues } from "common/result/ResultForm/ResultForm.types";
import { valuesToInput } from "common/result/ResultForm/ResultForm.utils";
import { MetricUnits } from "constants/metric";
import { resultTypes } from "constants/resultTypes";
import {
  ASANA_PROJECT_OR_TASK_LINKED,
  JIRA_ISSUE_LINKED,
  OPEN_ADD_MODAL,
} from "constants/tracking";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useRouteUserId } from "hooks/useRouteUserId/useRouteUserId";
import { setFormikGqlErrors } from "utils/forms";
import { isSet } from "utils/isSet";
import { toast } from "utils/toastr";
import { track, trackAddResult } from "utils/tracker";
import { ResultFilters } from "../../utils/types";

const messages = defineMessages({
  modalTitle: {
    defaultMessage: "Add Result",
    id: "results:modal:add:title",
  },
});

interface AddResultModalProps {
  callback?: () => void;
  filters?: ResultFilters;
  objectiveId: string;
  onRequestClosed: () => void;
  open: boolean;
  parentId?: string;
  reFetchQueries?: string[];
  sampleObjective?: { name: ReactNode };
  type?: string;
}

export const AddResultModal = ({
  open,
  onRequestClosed,
  objectiveId,
  parentId,
  type,
  callback,
  reFetchQueries = ["getUserOkrs"],
  filters = {},
  sampleObjective,
}: AddResultModalProps): JSX.Element => {
  const [addAnother, setAddAnother] = useState(false);
  const [currentObjective, setObjective] = useState(objectiveId);
  const intl = useIntl();
  const me = useCurrentUser();
  const [addResult] = useUpsertResultMutation({
    refetchQueries: [
      namedOperations.Query.searchResults,
      namedOperations.Query.getObjectiveTimeline,
      namedOperations.Query.getObjectiveResults,
      {
        query: OBJECTIVE_QUERY,
        variables: {
          objectiveId: currentObjective,
        },
      },
      {
        query: RESULTS_QUERY,
        variables: {
          objectiveId: currentObjective,
          ...filters,
        },
      },
      ...reFetchQueries,
    ],
  });

  useEffect(() => {
    if (open) {
      track(OPEN_ADD_MODAL, { goalType: "result" });
    }
  }, [open]);

  const pageUserId = useRouteUserId();

  const onSubmit = async (
    values: ResultFormValues,
    actions: FormikHelpers<ResultFormValues>,
    selectedSampleResult?: { name: ReactNode }
  ) => {
    try {
      const input = valuesToInput(values);
      const response = await addResult({ variables: { input } });
      actions.setSubmitting(false);
      const errors = response.data?.upsertResult?.errors;
      if (errors?.length) {
        setFormikGqlErrors(actions, errors);
        return;
      }

      const result = response.data?.upsertResult?.keyResult;

      const createdFromSample =
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        selectedSampleResult?.name === input.name ?? false;

      trackAddResult(result, createdFromSample);
      toast.success("Result created");

      if (isSet(result?.integrationField)) {
        switch (result.integration?.application) {
          case PerdooApiIntegrationApplicationChoices.Jira:
            track(JIRA_ISSUE_LINKED, {
              progressType: values.jiraProgressType,
            });
            break;
          case PerdooApiIntegrationApplicationChoices.Asana:
            track(ASANA_PROJECT_OR_TASK_LINKED);
            break;
          default:
            break;
        }
      }

      if (callback) {
        callback();
      }
      if (addAnother) {
        actions.resetForm();
        setAddAnother(false);
        track(OPEN_ADD_MODAL, { goalType: "result" });
        return;
      }
      onRequestClosed();
    } catch (e) {
      toast.failure("Error Creating result");
      actions.setSubmitting(false);
    }
  };

  const getInitialValues = (): ResultFormValues => {
    return {
      asanaProgressType: "project",
      contributors: [],
      endValue: 100,
      jiraSourceType: "jql",
      lead: pageUserId || me?.id,
      metricUnit: MetricUnits.NUMERICAL,
      name: "",
      objective: objectiveId,
      parent: parentId,
      startValue: 0,
      status: CommitStatus.NoStatus,
      tags: [],
      type: parentId ? resultTypes.initiative : type ?? "",
    };
  };

  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio"
      isOpen={open}
      onClose={onRequestClosed}
      title={intl.formatMessage(messages.modalTitle)}
    >
      <ResultForm
        initialValues={getInitialValues()}
        onObjectiveChange={setObjective}
        onSubmit={onSubmit}
        requestAddAnother={() => setAddAnother(true)}
        sampleObjective={sampleObjective}
      />
    </Modal>
  );
};
