import {
  PerdooApiKeyResultStatusChoices,
  ResultFiltersDataQueryQueryData,
} from "@graphql";
import { capitalize } from "lodash";
import { IntlShape } from "react-intl";
import { getOkrStatuses, StatusPair } from "common/charts/Status/Status";
import { resultTypes } from "constants/resultTypes";
import * as filterConstants from "legacy/components/filters/constants";
import { Filter } from "legacy/components/filters/types";
import { mapEdges } from "utils/mapEdges";
import { FormattedChartData } from "../../utils";
import { ResultStatsQuery } from "./ResultTable.types";

export const getResultFiltersConfiguration = (
  data: ResultFiltersDataQueryQueryData["currentCompany"] | undefined,
  intl: IntlShape
): Filter[] => {
  if (!data) return [];

  const { avatar, id, name } = data;
  const companyData = {
    avatar,
    id,
    name,
  };
  return [
    {
      ...filterConstants.objectiveStage,
      filterName: "objective_stage",
      name: "Stage",
    },
    {
      ...filterConstants.owner,
      filterName: "objective__owner",
      items: [companyData].concat(
        mapEdges(data.groups.edges).map((group) => ({
          ...group,
          subGroups: mapEdges(group.subGroups.edges),
        }))
      ),
    },
    {
      ...filterConstants.lead,
      items: mapEdges(data.users.edges),
    },
    filterConstants.progress,
    {
      ...filterConstants.status,
      items: transformStatuses(getOkrStatuses(intl)),
    },
    {
      ...filterConstants.timeframe,
      items: mapEdges(data.timeframes.edges),
    },
    {
      ...filterConstants.contributor,
      filterName: "contributors",
      items: mapEdges(data.users.edges),
    },
    {
      ...filterConstants.objective,
      filterName: "objective__in",
      items: mapEdges(data.objectives.edges),
    },
    {
      ...filterConstants.tags,
      filterName: "tags_Id",
      items: mapEdges(data.tags.edges),
    },
    {
      ...filterConstants.resultType,
      items: transformResultTypes(resultTypes),
    },
    {
      ...filterConstants.outdated,
    },
    {
      ...filterConstants.archived,
    },
    {
      ...filterConstants.alignedTo,
      filterName: "objective_AlignedTo",
      items: [
        ...mapEdges(data.objectives.edges),
        ...mapEdges(data.kpis.edges),
        ...mapEdges(data.strategicPillars.edges),
      ],
    },
  ];
};

export const transformStatuses = (statuses: Record<string, StatusPair>) => {
  const isValidResultStatus = ([type]: [type: string, s: StatusPair]) =>
    Object.values(PerdooApiKeyResultStatusChoices)
      .map((status) => status.toString())
      .includes(type);

  return Object.entries(statuses)
    .filter(([type]) => type !== "null")
    .filter(isValidResultStatus)
    .map(([type, status]) => ({
      color: status.color,
      id: type,
      name: status.title,
    }));
};

type Type = { initiative: string; keyResult: string };

export const transformResultTypes = (types: Type) =>
  Object.values(types).map((type) => ({
    id: type,
    name: capitalize(type).replace("_", " "),
  }));

interface ResultStatsChartData {
  krs_achieved: FormattedChartData;
  krs_by_status: StatusBreakdown;
}

export const transformRawDataToStats = (
  data: ResultStatsQuery
): ResultStatsChartData => ({
  krs_achieved: {
    total: data.results.totalCount,
    value: data.results.achievedCount,
  },
  krs_by_status: data.results.statusBreakdown,
});
