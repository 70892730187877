import { OneOnOneRepeatFrequency, OneOnOneSeries, User } from "@graphql";
import { Derive } from "@shoooe/derive";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { routes } from "route-configs";
import { Show } from "common/controlFlow";
import { FormControl } from "common/form/FormControl/FormControl";
import { GoogleCalendarButton } from "common/googleCalendar/GoogleCalendarButton/GoogleCalendarButton";
import {
  GoogleCalendarEventAction,
  GoogleCalendarEventActionField,
} from "common/googleCalendar/GoogleCalendarEventActionField/GoogleCalendarEventActionField";
import { GoogleCalendarEventDetails } from "common/googleCalendar/GoogleCalendarEventDetails/GoogleCalendarEventDetails";
import {
  GoogleCalendarEventField,
  GoogleCalendarEventFieldOption,
} from "common/googleCalendar/GoogleCalendarEventField/GoogleCalendarEventField";
import { AnchorNext } from "common/navigation";
import { oneOnOneDefaults } from "common/oneOnOnes/utils";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useGoogleCalendar } from "hooks/useGoogleCalendar/useGoogleCalendar";
import { RecurrenceFormSection } from "../RecurrenceFormSection/RecurrenceFormSection";

type Values = {
  attendee: string | null;
  dueDate: string | null;
  googleCalendarEvent: string | null;
  googleCalendarEventAction: GoogleCalendarEventAction | null;
  meetingTime: string | null;
  repeatFrequency: OneOnOneRepeatFrequency | null;
};
export type CalendarIntegrationValues = Values;

type OneOnOneProp = Derive<
  OneOnOneSeries,
  {
    gCalEventSeries: {
      id: true;
      recurrence: true;
      start: true;
      summary: true;
    };
    gCalIntegration: {
      id: true;
      user: {
        avatar: true;
        fullName: true;
        id: true;
      };
    };
  }
>;
export type CalendarIntegrationOneOnOneProp = OneOnOneProp;

type AttendeeProp = Derive<User, { id: true }>;

type Props = {
  attendee?: AttendeeProp | null;
  oneOnOne?: OneOnOneProp;
};

export const CalendarIntegrationFormSection = <TValues extends Values>({
  attendee,
  oneOnOne,
}: Props): JSX.Element | null => {
  const currentUser = useCurrentUser();
  const { integration } = useGoogleCalendar();

  const { values, setFieldValue } = useFormikContext<TValues>();

  const [selectedEvent, setSelectedEvent] =
    useState<GoogleCalendarEventFieldOption | null>(null);

  const onEventActionChange = (action: GoogleCalendarEventAction | null) => {
    if (action === "LINK_EXISTING_EVENT") {
      setFieldValue("repeatFrequency", OneOnOneRepeatFrequency.Integration);
      setFieldValue("dueDate", null);
      setFieldValue("meetingTime", null);
    } else {
      setFieldValue("googleCalendarEvent", null);
      setFieldValue("repeatFrequency", oneOnOneDefaults.defaultRepeatFrequency);
      setFieldValue("dueDate", oneOnOneDefaults.defaultDueDate());
      setFieldValue("meetingTime", oneOnOneDefaults.defaultMeetingTime());
    }
  };

  const label = (
    <FormattedMessage defaultMessage="Calendar integration" id="NSJsTm" />
  );

  if (
    oneOnOne &&
    oneOnOne.gCalEventSeries &&
    oneOnOne.gCalIntegration &&
    oneOnOne.gCalIntegration.user.id !== currentUser?.id
  ) {
    return (
      <FormControl label={label}>
        <GoogleCalendarEventDetails
          gCalEventSeries={oneOnOne.gCalEventSeries}
          gCalIntegration={oneOnOne.gCalIntegration}
        />
      </FormControl>
    );
  }

  if (!integration) {
    return (
      <FormControl label={label}>
        <div>
          <GoogleCalendarButton />
        </div>
      </FormControl>
    );
  }

  if (
    selectedEvent &&
    values.googleCalendarEventAction === "LINK_EXISTING_EVENT"
  ) {
    return (
      <FormControl label={label} optional>
        <GoogleCalendarEventDetails
          gCalEventSeries={selectedEvent}
          onUnlink={() => {
            setFieldValue("googleCalendarEventAction", null);
            setFieldValue("googleCalendarEvent", null);
            setFieldValue(
              "repeatFrequency",
              oneOnOneDefaults.defaultRepeatFrequency
            );
            setSelectedEvent(null);
          }}
        />
      </FormControl>
    );
  }

  const helperText = !values.attendee ? (
    <FormattedMessage defaultMessage="Choose the Meeting with" id="Y87LqU" />
  ) : undefined;
  return (
    <FormControl helperText={helperText} label={label} optional>
      <div>
        <GoogleCalendarEventActionField
          disabled={!values.attendee}
          name="googleCalendarEventAction"
          onChange={onEventActionChange}
        />
        {!!values.googleCalendarEventAction && !!values.attendee && (
          <div className="-mt-0.5 rounded-bl rounded-br border border-t-0 px-3 pb-3 pt-3.5">
            <Show
              when={values.googleCalendarEventAction === "LINK_EXISTING_EVENT"}
            >
              <GoogleCalendarEventField
                attendee={attendee?.id ?? values.attendee}
                name="googleCalendarEvent"
                onChange={setSelectedEvent}
              />
            </Show>
            <Show when={values.googleCalendarEventAction === "CREATE_EVENT"}>
              <RecurrenceFormSection />
            </Show>
          </div>
        )}
      </div>
      <div>
        <AnchorNext
          className="text-sm"
          href={routes.settings.user.integrations}
          target="_blank"
        >
          <FormattedMessage
            defaultMessage="Manage calendar integrations"
            id="mMNsRY"
          />
        </AnchorNext>
      </div>
    </FormControl>
  );
};
