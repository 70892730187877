import { ProgressDriver } from "@graphql";
import { FormattedMessage } from "react-intl";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Icon } from "common/icons";

export const ObjectiveResultsWarning = () => {
  const objective = useObjectiveContext();

  const dependsOnKeyResult =
    objective.progressDriver === ProgressDriver.KeyResults;
  const hasKeyResults = (objective.keyResults?.edgeCount ?? 0) > 0;
  if (!dependsOnKeyResult || hasKeyResults) {
    return null;
  }

  return (
    <div className="flex items-center space-x-1 mt-4 text-slate-500">
      <Icon name="info_outline" className="text-red-500" />
      <FormattedMessage
        defaultMessage="You haven't created any Key Results yet, while the Objective's progress and status depend on it."
        id="LqjVJU"
        tagName="div"
      />
    </div>
  );
};
