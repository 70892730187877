import { gql } from "@apollo/client";
import { CADENCE_DEFAULT_FRAGMENT } from "common/cadence/fragments";
import { TIMEFRAME_DEFAULT_FRAGMENT } from "common/timeframe/fragments";
import {
  COMPANY_DEFAULT_FRAGMENT,
  INTEGRATION_DEFAULT_FRAGMENT,
  SSO_DEFAULT_FRAGMENT,
} from "modules/company/Company/Company.graphql";
import { USER_DEFAULT_FRAGMENT } from "../users/[id]/UserProfile/UserProfile.fragments";

export const BOOT_PRELOAD_QUERY = gql`
  query bootPreloadQuery {
    me {
      ...UserDefaultFields
      canStartCompanyTrial
      userPreferences {
        trialBannerDismissed
      }
      latestCheckIn {
        ...LatestCheckInDefaultFields
      }
      # TODO: check if the following are even needed
      notificationsConfig
      notificationsSettings
    }
    currentCompany {
      ...CompanyDefaultFields
      cadences {
        edges {
          node {
            ...CadenceDefaultFields
          }
        }
      }
      integrations {
        edges {
          node {
            ...IntegrationDefaultFields
          }
        }
      }
      ssoconfig {
        ...SsoDefaultFields
      }
    }
    allGroups {
      edges {
        node {
          id
          name
          avatar
          archived
          archivedDate
          kpisEnabled
          canPatch
          cadence {
            id
          }
          groupLeader {
            id
          }
        }
      }
    }
    allTags {
      edges {
        node {
          id
          name
          description
          color
          count
        }
      }
    }
  }
  ${CADENCE_DEFAULT_FRAGMENT}
  ${USER_DEFAULT_FRAGMENT}
  ${COMPANY_DEFAULT_FRAGMENT}
  ${INTEGRATION_DEFAULT_FRAGMENT}
  ${SSO_DEFAULT_FRAGMENT}
`;

export const BOOT_PRELOAD_USER_QUERY = gql`
  query bootPreloadUsersQuery {
    allUsers {
      edges {
        node {
          id
          fullName
          email
          avatar
          jobPosition
          isActive
          createdDate
          manager {
            id
          }
        }
      }
    }
  }
`;

export const BOOT_PRELOAD_TIMEFRAME_QUERY = gql`
  query bootPreloadTimeframeQuery {
    timeframes {
      edges {
        node {
          ...TimeframeDefaultFields
          objectives {
            achievedCount
          }
        }
      }
    }
  }
  ${TIMEFRAME_DEFAULT_FRAGMENT}
`;
