import { useRouter } from "next/router";
import React from "react";
import { UpgradeModal } from "common/inAppUpgrade/modals/UpgradeModal/UpgradeModal";
import { ChatSalesModal } from "modules/home/Home/ChatSalesModal/ChatSalesModal";

export const SalesModals = (): JSX.Element | null => {
  const { query } = useRouter();
  const { chatSales, upgradeNow } = query;

  if (upgradeNow) {
    return <UpgradeModal />;
  }

  if (chatSales) {
    return <ChatSalesModal />;
  }

  return null;
};
