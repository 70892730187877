import dayjs from "dayjs";
import { omit } from "lodash";
import { useRouter } from "next/router";
import { useRef } from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { useResultContext } from "common/context/resultContext";
import { useCanUpdateProgress } from "common/goal/hooks/useCanUpdateProgress";
import { useResultProgressUpdate } from "common/goal/hooks/useResultProgressUpdate";
import {
  ProgressUpdateButton,
  ProgressUpdateButtonRef,
} from "common/goal/ProgressUpdateButton/ProgressUpdateButton";
import { Dropdown } from "common/overlay/Dropdown";
import { ProgressCTAButton } from "common/progress/ProgressCTAButton/ProgressCTAButton";
import { useResultActions } from "common/result";

export const ResultProgressUpdateButton = () => {
  const updateButtonRef = useRef<ProgressUpdateButtonRef>(null);
  const router = useRouter();
  const result = useResultContext();
  const { handleSubmit: handleProgressSubmit } =
    useResultProgressUpdate(result);
  const navigateToNewObjective = async () => {
    const queryWithoutGoals = omit(router.query, ["oId", "rId"]);
    await router.replace({ query: queryWithoutGoals });
    await router.replace({ query: { ...queryWithoutGoals, rId: result.id } });
  };
  const actions = useResultActions({
    onClickUpdate: () => updateButtonRef.current?.setIsOpen(true),
    onMove: navigateToNewObjective,
    onRemove: () => router.replace({ query: omit(router.query, "rId") }),
    result,
  });
  const { reason, canUpdateProgress } = useCanUpdateProgress(result);

  return (
    <div className="flex space-x-2">
      <div className="grow">
        <ProgressUpdateButton
          backdating
          ref={updateButtonRef}
          button={<ProgressCTAButton tooltip={reason} />}
          data-cy="updateKeyResultProgress"
          apiIntegration={result.pushIntegrationApplication}
          application={result.integration?.application}
          disabled={!canUpdateProgress}
          initialValues={{
            commitDate: dayjs().toISOString(),
            endValue: result.endValue,
            status: result.status,
            value: result.currentValue,
          }}
          lastCommit={result.lastCommit}
          metricUnit={result.metricUnit}
          onSubmit={handleProgressSubmit}
          positiveMetric={result.endValue >= result.startValue}
          type={result.type}
        />
      </div>
      <Dropdown>
        <Dropdown.Trigger>
          <IconButton
            ghost
            className="text-slate-800 h-full"
            data-cy="keyResult-dropdown"
            data-testid="resultDropdown"
            name="more_vert"
          />
        </Dropdown.Trigger>
        <Dropdown.Content
          className="text-sm text-slate-800"
          placement="bottom-right"
        >
          {actions?.map((action) => action)}
        </Dropdown.Content>
      </Dropdown>
    </div>
  );
};
