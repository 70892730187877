import { QueryResult } from "@apollo/client";
import { useEffect, useReducer } from "react";

const initialState = {
  status: "idle",
};

type Action =
  | { error: QueryResult["error"]; type: "error" }
  | { data: QueryResult["data"]; type: "success" }
  | { type: "started" };

type LoadingState = {
  data?: QueryResult["data"];
  reason?: QueryResult["error"];
  status: string; // union type doesn't work for some reason
};

const loadingStateReducer = (
  state: typeof initialState,
  action: Action
): LoadingState => {
  switch (action.type) {
    case "error": {
      return {
        ...state,
        reason: action.error,
        status: "rejected",
      };
    }
    case "success": {
      return {
        ...state,
        data: action.data,
        status: "resolved",
      };
    }
    case "started": {
      return {
        ...state,
        status: "pending",
      };
    }
    default: {
      return {
        status: "idle",
      };
    }
  }
};
export const useLoadingState = ({
  data,
  error,
  loading,
}: Pick<QueryResult, "data" | "error" | "loading">): LoadingState => {
  const [state, dispatch] = useReducer(loadingStateReducer, initialState);

  useEffect(() => {
    if (error) {
      dispatch({ error, type: "error" });
    }

    if (loading) {
      dispatch({ type: "started" });
    }

    if (data && !loading) {
      dispatch({ data, type: "success" });
    }
  }, [error, loading, data]);

  return state;
};
