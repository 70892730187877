import { OneOnOneMeetingDefaultFragment } from "@graphql";
import dayjs from "dayjs";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import { isNil } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { LocalTimeHelper } from "common/form/LocalTimeHelper";
import { TimePickerField } from "common/form/TimePickerField/TimePickerField";
import { Label } from "common/inputs";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { useCompany } from "hooks/useCompany/useCompany";
import { ONE_ON_ONE_TIME_INCREMENT } from "../constants";
import { useHandleOneOnOneMutations } from "../hooks/useHandleOneOnOneMutations";
import { formatOneOnOneMeetingDueDate } from "../oneOnOnemeeting.utils";
import {
  rescheduleMeetingValidationSchema,
  RescheduleValues,
} from "./RescheduleOneOnOneMeetingModal";

type Props = {
  close: () => void;
  handleScheduleNextMeetingResponse?: (
    nextMeeting?: OneOnOneMeetingDefaultFragment | null
  ) => Promise<void>;
  handleSkipAndSave?: () => void;

  isComplete: boolean;
  seriesId: string;
};

export const ScheduleNextOneOnOneMeetingModal = ({
  close,
  handleScheduleNextMeetingResponse,
  handleSkipAndSave,
  isComplete,
  seriesId,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const { upsertOneOnOneMeeting } = useHandleOneOnOneMutations();
  const { timezone } = useCompany();

  if (isNil(seriesId)) return null;

  const initialValues: RescheduleValues = {
    dueDate: null,
    meetingTime: dayjs().ceil("minutes", 30).format("HH:mm:ss"),
  };

  const handleSubmit = async (
    values: RescheduleValues,
    actions: FormikHelpers<RescheduleValues>
  ) => {
    const formattedDueDate = formatOneOnOneMeetingDueDate(
      values.dueDate,
      values.meetingTime
    );
    const { hasError, oneOnOneMeeting } = await upsertOneOnOneMeeting({
      dueDate: formattedDueDate,
      oneOnOneSeriesId: seriesId,
    });
    actions.setSubmitting(false);

    if (hasError) return;
    if (!isComplete) handleScheduleNextMeetingResponse?.(oneOnOneMeeting);
    toast.success(
      intl.formatMessage({
        defaultMessage: "1:1 meeting planned",
        id: "aLzjpS",
      })
    );
    close();
  };

  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio mt-[25%]"
      data-testid="scheduleNextOneOnOneModal"
      isOpen
      onClose={close}
      size="sm"
      title={
        <FormattedMessage defaultMessage="Plan next meeting" id="tbPXCm" />
      }
    >
      <Formik<RescheduleValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={rescheduleMeetingValidationSchema}
      >
        {({ values, handleBlur, isSubmitting, submitForm }) => {
          let meetingLocalTime;
          if (values.dueDate && values.meetingTime) {
            const meetingDate = dayjs(values.dueDate);
            const meetingDateTime = dayjs.tz(
              `${meetingDate.format("YYYY-MM-DD")} ${values.meetingTime}`,
              timezone
            );
            meetingLocalTime = meetingDateTime.local();
          }
          return (
            <>
              <div className="mb-1 mt-2">
                <Label>
                  <FormattedMessage
                    defaultMessage="Next meeting date & time"
                    id="G0PPdQ"
                  />
                </Label>
              </div>
              <div
                className="flex items-end gap-2"
                data-testid="scheduleNextOneOnOneModalBody"
              >
                <Field name="dueDate" onBlur={handleBlur("dueDate")}>
                  {({ field, form }: FieldProps) => (
                    <FormDatePicker
                      className="w-36"
                      formControlClassName="my-0"
                      field={field}
                      form={form}
                      id="nextMeetingDate"
                      onBlur={handleBlur("dueDate")}
                      placeholder={dayjs().format("MMM DD, YYYY")}
                    />
                  )}
                </Field>
                <TimePickerField
                  name="meetingTime"
                  increment={ONE_ON_ONE_TIME_INCREMENT}
                />
              </div>
              {meetingLocalTime && (
                <LocalTimeHelper
                  className="my-2"
                  localTime={meetingLocalTime}
                />
              )}
              <ModalFooter>
                <Button
                  data-cy="saveOneOnOneMeeting"
                  data-testid="submitForm"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  <FormattedMessage defaultMessage="Save" id="global:save" />
                </Button>
                <Show when={!isComplete}>
                  <Button
                    data-cy="Kx6DQ7JX8qqHw9nmkYEup"
                    onClick={handleSkipAndSave}
                    type="button"
                    variant="ghost"
                  >
                    <FormattedMessage
                      defaultMessage="Skip & Save"
                      id="seL2PA"
                    />
                  </Button>
                </Show>
                <Button
                  data-cy="a3hlNK-SRV9KAQdPQkOhs"
                  onClick={close}
                  type="button"
                  variant="ghost"
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="global:cancel"
                  />
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
