import classNames from "clsx";
import { isNil } from "lodash";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Placement } from "tippy.js";
import { Icon } from "common/icons/Icon/Icon";
import { IconNameKey, IconType } from "common/icons/Icon/Icon.types";
import { IconSize } from "common/misc/types";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { colorTheme } from "constants/colorTheme";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";

const Container = styled(Anchor)<{
  clickable: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  max-width: 360px;
  color: ${(props) =>
    props.isDisabled ? colorTheme.gray[400] : colorTheme.slate[500]};
  cursor: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.isDisabled ? "not-allowed" : props.clickable ? "pointer" : "initial"};
`;

type Props = {
  className?: string;
  "data-cy"?: string;
  "data-fs"?: string;
  disabled?: boolean;
  href?: string;
  icon?: IconNameKey;
  iconClass?: string;
  iconSize?: keyof typeof IconSize;
  iconType?: IconType;
  image?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  spanClass?: string;
  suffix?: ReactNode;
  text: ReactNode;
  tooltip?: ReactNode;
  tooltipPlacement?: Placement;
};

export class Subtext extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ): void {
    const { onClick, href } = this.props;
    onClick?.(e);

    if (!href) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  render(): JSX.Element {
    const {
      "data-cy": dataCy = "subtext",
      "data-fs": dataFs,
      className,
      disabled,
      href,
      icon,
      iconClass,
      iconSize = "xl",
      iconType,
      image,
      onClick,
      spanClass,
      text,
      tooltip,
      suffix,
      tooltipPlacement,
    } = this.props;
    return (
      <WithTooltip placement={tooltipPlacement ?? "bottom"} tooltip={tooltip}>
        <Container
          className={classNames(
            "flex items-center space-x-1 truncate text-slate-500 hover:text-blue-500",
            className
          )}
          clickable={!isNil(onClick) || !isNil(href)}
          data-cy={dataCy}
          data-fs={dataFs}
          href={href}
          isDisabled={disabled ?? false}
          onClick={isSet(onClick) ? this.handleClick : undefined}
        >
          {image ||
            (!isNil(icon) && (
              <Icon
                className={iconClass}
                name={icon}
                size={iconSize}
                type={iconType}
              />
            ))}
          <span
            className={twClass(
              "w-full truncate text-sm font-normal",
              spanClass
            )}
            data-fs={dataFs}
          >
            {text}
          </span>
          {suffix}
        </Container>
      </WithTooltip>
    );
  }
}
