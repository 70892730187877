import { PerdooApiObjectiveStageChoices } from "@graphql";
import React, { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { For } from "common/controlFlow";
import { Icon } from "common/icons";
import { useSelect } from "common/inputs/Select/useSelect";

type Value = PerdooApiObjectiveStageChoices;

type Option = {
  "data-cy"?: string;
  icon: ReactNode;
  id: Value;
  label: ReactNode;
};

const allOptions: Option[] = [
  {
    icon: <Icon name="library_add_check" type="outlined" />,
    id: PerdooApiObjectiveStageChoices.Active,
    label: <FormattedMessage defaultMessage="Active" id="3a5wL8" />,
  },
  {
    icon: <Icon name="flip" type="outlined" />,
    id: PerdooApiObjectiveStageChoices.Draft,
    label: <FormattedMessage defaultMessage="Draft" id="W6nwjo" />,
  },
  {
    icon: <Icon name="archive" />,
    id: PerdooApiObjectiveStageChoices.Closed,
    label: <FormattedMessage defaultMessage="Closed" id="Fv1ZSz" />,
  },
];

type Props = {
  className?: string;
  "data-cy"?: string;
  disabled?: boolean;
  exclude?: PerdooApiObjectiveStageChoices[];
  onChange?: (value: Value | null) => void;
  value: Value;
};

export const InlineObjectiveStageSelect = ({
  className,
  "data-cy": dataCy,
  onChange,
  value,
  exclude,
  disabled,
}: Props): JSX.Element | null => {
  const defaultItem = allOptions.find((option) => option.id === value);
  const options = useMemo(
    () => allOptions.filter((opt) => !exclude?.includes(opt.id)),
    [exclude]
  );
  const { Select, selected, visibleOptions, handleSelect } = useSelect<Option>({
    defaultItem,
    options,
  });

  const handleChange = (option: Option | null) => {
    handleSelect(option);
    onChange?.(option?.id ?? null);
  };

  const valueIsDisabled = exclude?.includes(value);

  return (
    <Select.Root
      data-cy={dataCy}
      onChange={handleChange}
      value={selected}
      className={className}
      disabled={disabled || valueIsDisabled}
    >
      <Select.Trigger>
        <Select.InlineValue>
          <div className="flex items-center space-x-2">
            {selected?.icon}
            <div>{selected?.label}</div>
          </div>
        </Select.InlineValue>
      </Select.Trigger>
      <Select.Dropdown>
        <Select.Options>
          <For each={visibleOptions}>
            {(option) => (
              <Select.Option value={option}>
                <div className="flex items-center space-x-2">
                  {option.icon}
                  <div>{option.label}</div>
                </div>
              </Select.Option>
            )}
          </For>
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
