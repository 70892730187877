import React, { ReactNode } from "react";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { twClass } from "utils/twClass";

type Props = {
  children?: ReactNode;
  className?: string;
};

/**
 * spinner which fits the container width and height and renders in the centre.
 */
export const SpinnerFit = ({ children, className }: Props): JSX.Element => {
  return (
    <div
      className={twClass(
        "flex h-full w-full flex-col items-center justify-center",
        className
      )}
    >
      <Spinner />
      {children}
    </div>
  );
};
