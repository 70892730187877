import { defineMessages } from "react-intl";

export const messages = defineMessages({
  asanaTask: {
    defaultMessage: "Asana Task",
    id: "result:form:asanaTask:label",
  },
  noResults: {
    defaultMessage: "No results",
    id: "asanaTaskSelect:noResults",
  },
  search: {
    defaultMessage: "Search...",
    id: "asanaTaskSelect:search",
  },
  searchTasks: {
    defaultMessage: "Search Asana tasks",
    id: "asanaTaskSelect:searchTasks",
  },
});
