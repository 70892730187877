import React from "react";
import { Searchbox } from "common/inputs/Searchbox/Searchbox";
import { useSelectContext } from "common/inputs/Select/Select.context";

export type Props = {
  onChange: (value: string) => void;
  placeholder?: string;
};

export const SelectSearchbox = ({
  onChange,
  placeholder,
}: Props): JSX.Element => {
  const { setSearchRef } = useSelectContext();

  const suppressSomeKeyboardShortcuts = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // in headless UI, spacebar = select, but want to be able to use it for typing in searchbox.
    if (e.key === " ") {
      e.stopPropagation();
    }

    // prevents default headless UI keyboard shortcut (which does nothing?), which allows to toggle between search and list
    if (e.key === "Tab") {
      e.stopPropagation();
    }
  };

  return (
    <Searchbox
      inputRef={setSearchRef}
      onKeyDown={suppressSomeKeyboardShortcuts}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
