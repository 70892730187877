import React, { ReactNode } from "react";
import { twClass } from "utils/twClass";
import { Header } from "./Header/Header";

export const HELP_CENTER_WIDTH = "250px";

type Props = {
  children: ReactNode;
};

export const HelpCenter = ({ children }: Props): JSX.Element | null => (
  <aside
    className={twClass(
      "shrink-0",
      "relative z-modal-top-prio flex h-full flex-col justify-between",
      "bg-white text-slate-800",
      "border-l border-slate-300",
      "overflow-auto"
    )}
    data-cy="helpCenter"
    style={{
      width: HELP_CENTER_WIDTH,
    }}
  >
    <div className="flex grow flex-col">
      <Header />
      <div className="grow">{children}</div>
    </div>
  </aside>
);
