import { isString } from "lodash";
import { useRouter } from "next/router";
import { routes } from "route-configs";

/**
 * on group routes (`/groups/[id]/...`), returns the id provided in the url.
 */
export const useRouteGroupId = (): string | undefined => {
  const router = useRouter();
  const isGroupRoute = router.asPath.startsWith(routes.groups.main);
  const routeEntityId = isString(router.query.id) ? router.query.id : undefined;

  return isGroupRoute ? routeEntityId : undefined;
};
