import { useLocalStorage } from "hooks/useLocalStorage/useLocalStorage";

export enum PeopleManagementTabOption {
  CHECK_INS = "CHECK_INS",
  GOALS = "GOALS",
  KUDOS = "KUDOS",
}

export enum SidebarUserOptions {
  ATTENDEE = "ATTENDEE",
  ORGANIZER = "ORGANIZER",
}

export type ContextualSidebarTabsHook = {
  handleTabChange: (newTab: PeopleManagementTabOption) => void;
  tab: PeopleManagementTabOption;
};

export const useContextualSidebarTabs = (
  meetingId: string
): ContextualSidebarTabsHook => {
  const [tab, setTab] = useLocalStorage<PeopleManagementTabOption>(
    `contextualSidebarTab-${meetingId}`,
    PeopleManagementTabOption.GOALS
  );

  return {
    handleTabChange: setTab,
    tab,
  };
};
