import Link from "next/link";
import React, { forwardRef } from "react";
import { UrlObject } from "url";
import { Icon } from "common/icons";
import { twClass } from "utils/twClass";

export type AnchorNextProps = {
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  external?: boolean;
  href?: string | UrlObject;
  /**
   * STOP: do not pass onClick to anchors. use a button and style it to look like a link.
   * if you need to attach a tracking event or similar, use {@link AnchorNextTrack}.
   */
  onClick?: never;
  shallow?: boolean;
} & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href">;

/**
 * light wrapper around HTML `<a>` element that uses next router.
 */
export const AnchorNext = forwardRef<HTMLAnchorElement, AnchorNextProps>(
  (
    {
      children,
      className,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      disabled,
      href = "",
      id,
      rel,
      shallow,
      target,
      external = false,
    },
    ref
  ): JSX.Element => {
    return (
      <Link href={href} legacyBehavior shallow={shallow}>
        <a
          ref={ref}
          className={twClass(
            "text-blue-500 hover:text-indigo-800",
            {
              "pointer-events-none": disabled,
            },
            className
          )}
          data-cy={dataCy}
          data-testid={dataTestId}
          id={id}
          rel={rel ?? external ? "noopener noreferrer" : undefined}
          target={target ?? external ? "_blank" : undefined}
        >
          {children}
          {external && (
            <Icon className="ml-1 align-text-bottom" name="open_in_new" />
          )}
        </a>
      </Link>
    );
  }
);
