import { useEffect } from "react";

type PossibleKeys =
  | "Escape"
  | "Enter"
  | "Alt"
  | "Control"
  | "Shift"
  | "Backspace"
  | "Tab";

type useKeyTrigger = (key: PossibleKeys, onTrigger?: () => void) => void;

export const useKeyTrigger: useKeyTrigger = (key, onTrigger) => {
  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.key === key && !event.shiftKey && onTrigger) onTrigger();
    };

    window.addEventListener("keydown", handleKey);

    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [onTrigger, key]);
};
