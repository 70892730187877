import { GoalUpdateCycle } from "@graphql";

export const countries = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo Democratic Republic",
  "Cook Islands",
  "Costa Rica",
  "Côte D'ivoire",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea Democratic People Republic",
  "Korea Republic",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Réunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and The Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and The South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "US Virgin Islands",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

/**
 * A company is considered large if it has at least this many users.
 *
 * @type {number}
 */
export const LARGE_COMPANY_USERS_COUNT = 25;

// TODO: warning: these don't match the constants used in company/models.py, see "TRIAL_EXP"
export const featureTiers = {
  EXPIRED_TRIAL: "EXPIRED_TRIAL",
  FREE: "FREE",
  PREMIUM: "PREMIUM",
  PROFESSIONAL: "PROFESSIONAL",
  SUPREME: "SUPREME",
  TRIAL: "TRIAL",
};

export const supportLinks = {
  autoCreateTimeframes: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/4515060-cadences-and-timeframes",
  },
  checkInsPage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/3960215-drive-execution-using-check-ins",
    page: "checkIn",
    videoUrl: "https://youtu.be/dIr1ft4BCbg",
  },
  companyPage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/4342501-organizing-your-goals-in-perdoo",
    page: "company",
  },
  customReports: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/3112213-custom-reports",
    page: "customReports",
    videoUrl: "https://youtu.be/xMPO6UWoEIY",
  },
  engagementReportsPage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/3036744-engagement-reports",
    page: "engagementReports",
    videoUrl: "https://youtu.be/azloq0mLKP0",
  },
  explorePage: {
    getStartedLink: "https://support.perdoo.com/en/articles/3112213-explore",
    page: "explore",
    videoUrl: "https://youtu.be/xMPO6UWoEIY",
  },
  flexibleObjectiveProgressDriver: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/4898961-understand-how-progress-is-calculated-for-okrs",
  },
  groupPage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/4342501-organizing-your-goals-in-perdoo",
    page: "group",
  },
  growthBoard: {
    getStartedLink:
      "http://support.perdoo.com/en/articles/5645323-growth-board-beta",
    page: "growthBoard",
    videoUrl: "https://youtu.be/sxzStkM8ga4",
  },
  homePage: {
    getStartedLink: "https://support.perdoo.com/en/articles/4212017-home",
    page: "home",
  },
  kpiConfigure: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/2298516-add-company-kpis",
    page: "kpiConfigure",
    videoUrl: "https://youtu.be/rilOtz4lGgs",
  },
  kudos: {
    getStartedLink: "https://support.perdoo.com/en/articles/6817338-kudos",
  },
  oneOnOnes: {
    getStartedLink:
      "http://support.perdoo.com/en/articles/7172624-1-1-meetings",
  },
  performanceReportsPage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/4358453-performance-reports",
    page: "performanceReports",
    videoUrl: "https://youtu.be/sHYqHmcL1l0",
  },
  performanceReviews: {
    getStartedLink: "http://support.perdoo.com/en/articles/6868456",
  },
  privateOKRsConfigure: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/5040665-make-an-okr-private",
  },
  pulse: {
    getStartedLink: "http://support.perdoo.com/en/articles/6715675-pulse",
  },
  reflections: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/6003545-reflections-in-check-ins",
  },
  roadmapPage: {
    getStartedLink: "https://support.perdoo.com/en/articles/3112288-roadmap",
    page: "roadmap",
    videoUrl: "https://youtu.be/Htev14Ekyzk",
  },
  rolesRights: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/1588568-roles-rights",
  },
  sharedGoals: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/6348504-shared-okrs-and-kpis",
  },
  strategyPage: {
    getStartedLink:
      "http://support.perdoo.com/en/articles/4725666-strategic-pillars",
  },
  timelinePage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/5384967-strategic-timeline",
    page: "timeline",
    videoUrl: "https://youtu.be/1yBYPDH-jjs",
  },
  userPage: {
    getStartedLink:
      "https://support.perdoo.com/en/articles/4342501-organizing-your-goals-in-perdoo",
    page: "user",
  },
};

export const goalUpdateCycleLabel = {
  [GoalUpdateCycle.Weekly]: "weekly",
  [GoalUpdateCycle.Biweekly]: "biweekly",
  [GoalUpdateCycle.Monthly]: "monthly",
  [GoalUpdateCycle.Quarterly]: "quarterly",
  [GoalUpdateCycle.Every_4Months]: "every 4 months",
  [GoalUpdateCycle.Every_6Months]: "every 6 months",
  [GoalUpdateCycle.Yearly]: "yearly",
};

export const checkInCycleLabel = {
  [GoalUpdateCycle.Weekly]: "week",
  [GoalUpdateCycle.Biweekly]: "cycle",
  [GoalUpdateCycle.Monthly]: "month",
};

export const UPLOAD_AVATAR_FAILURE = "#UPLOAD_AVATAR_FAILURE";
export const UPLOAD_AVATAR_REQUEST = "#UPLOAD_AVATAR_REQUEST";
export const UPLOAD_AVATAR_SUCCESS = "#UPLOAD_AVATAR_SUCCESS";
