import React from "react";
import { Icon } from "common/icons/Icon/Icon";
import { IconNameKey } from "common/icons/Icon/Icon.types";
import { IconSize } from "common/misc/types";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";

interface Props {
  content: React.ReactNode;
  icon?: IconNameKey;
  iconClassName?: string;
  iconSize?: keyof typeof IconSize;
}

export const HelpRollover = ({
  icon = "help_outline",
  content,
  iconSize = "xl",
  iconClassName,
}: Props): JSX.Element => {
  return (
    <Tooltip content={content} interactive>
      <div className="leading-[0] text-slate-500 hover:cursor-help hover:text-slate-800">
        <Icon name={icon} size={iconSize} className={iconClassName} />
      </div>
    </Tooltip>
  );
};
