import { useKpiContext } from "common/context/kpiContext";
import { DescriptionForm } from "common/form/DescriptionForm/DescriptionForm";
import { useInlineKpiActions } from "common/kpi/hooks/useInlineKpiActions/useInlineKpiActions";

export const KpiDescription = () => {
  const kpi = useKpiContext();
  const { setDescription } = useInlineKpiActions(kpi);

  const updateDescription = (input: { description: string }) => {
    if (input.description === kpi.description) return;
    setDescription(input.description);
  };

  return (
    <div className="my-5">
      <DescriptionForm
        data-testid="objectiveDescription"
        variant="borderless"
        description={kpi.description ?? ""}
        editVisible={kpi.canPatch}
        entityId={kpi.id}
        onUpdate={updateDescription}
      />
    </div>
  );
};
