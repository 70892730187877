import React, { ReactNode } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { WarningIcon } from "common/icons/WarningIcon/WarningIcon";
import { Margin } from "common/misc/Margin/Margin.styles";
import { Subtext } from "common/misc/Subtext/Subtext";
import { Branch } from "utils/utils";
import { TimeframeWarnings } from "../../timeframe/TimeframeWarnings/TimeframeWarnings";
import { SortValue } from "../constants";
import { ObjectiveWidget } from "../ObjectiveWidget/ObjectiveWidget";

const messages = defineMessages({
  ascending: {
    defaultMessage: "ascending",
    id: "objectives:sorting:ascending",
  },
  descending: {
    defaultMessage: "descending",
    id: "objectives:sorting:descending",
  },
  sortingByProgress: {
    defaultMessage: "Sorting by Progress",
    id: "objectives:sorting:sortingByProgress",
  },
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'objectives' implicitly has an 'any' typ... Remove this comment to see the full error message
const mapObjectivesDefault = (objectives) =>
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
  objectives.map((obj) => (
    <Margin key={obj.id} bottom={15}>
      <ObjectiveWidget objective={objectives} />
    </Margin>
  ));

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'objectives' implicitly has an 'an... Remove this comment to see the full error message
const SortByTimeframe = ({ objectives, mapObjectives }) => {
  const timeframeMap = {};
  const timeframes = [
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'orderedTfs' implicitly has an 'any' typ... Remove this comment to see the full error message
    ...objectives.reduce((orderedTfs, objective) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      timeframeMap[objective.timeframe.id] = objective.timeframe;
      return orderedTfs.add(objective.timeframe.id);
    }, new Set()),
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  ].map((timeframeId) => timeframeMap[timeframeId]);

  return timeframes.map((timeframe) => {
    const tfObjs = objectives.filter(
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'obj' implicitly has an 'any' type.
      (obj) => obj.timeframe.id === timeframe.id
    );

    return (
      <React.Fragment key={timeframe.id}>
        {tfObjs.length > 0 && (
          <div>
            <div className="mt-6 mb-4 flex items-center text-slate-500 print:my-6">
              <Subtext
                key="calendar_today"
                data-cy="commonTimeframe"
                icon="calendar_today"
                text={timeframe.name}
              />
              <WarningIcon
                className="ml-1"
                warnings={TimeframeWarnings({
                  timeframe,
                  timeframeObjectivesCount: tfObjs.length,
                })}
              />
            </div>
            <div>{mapObjectives(tfObjs)}</div>
          </div>
        )}
      </React.Fragment>
    );
  });
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'objectives' implicitly has an 'an... Remove this comment to see the full error message
export const SortByProgress = ({ objectives, mapObjectives, order }) => {
  return (
    <>
      <div className="mb-4 flex items-center text-slate-500">
        <FormattedMessage {...messages.sortingByProgress} /> (
        <Branch
          left={<FormattedMessage {...messages.ascending} />}
          right={<FormattedMessage {...messages.descending} />}
          test={order === "asc"}
        />
        )
      </div>
      <div>{mapObjectives(objectives)}</div>
    </>
  );
};

type Entries = Record<
  SortValue,
  {
    component: (props: any) => ReactNode;
    label: ReactNode;
    order: "asc" | "desc";
    queryParam: string;
  }
>;

export const sortingEntries: Entries = {
  progressAsc: {
    component: SortByProgress,
    label: (
      <div className="flex w-full items-center justify-between">
        <FormattedMessage defaultMessage="Progress" id="global:progress" />
        <Icon name="arrow_upward" size="lg" />
      </div>
    ),
    order: "asc",
    queryParam: "progress",
  },
  progressDesc: {
    component: SortByProgress,
    label: (
      <div className="flex w-full items-center justify-between">
        <FormattedMessage defaultMessage="Progress" id="global:progress" />
        <Icon name="arrow_downward" size="lg" />
      </div>
    ),
    order: "desc",
    queryParam: "-progress",
  },
  timeframeAsc: {
    component: SortByTimeframe,
    label: (
      <div className="flex w-full items-center justify-between">
        <FormattedMessage
          defaultMessage="Timeframe"
          id="objective:list:timeframe"
        />
        <Icon name="arrow_upward" size="lg" />
      </div>
    ),
    order: "asc",
    queryParam: "cadence_duration,timeframe__start_date,name",
  },
  timeframeDesc: {
    component: SortByTimeframe,
    label: (
      <div className="flex w-full items-center justify-between">
        <FormattedMessage
          defaultMessage="Timeframe"
          id="objective:list:timeframe"
        />
        <Icon name="arrow_downward" size="lg" />
      </div>
    ),
    order: "desc",
    queryParam: "cadence_duration,-timeframe__start_date,name",
  },
};

export const getSortingOptions = () =>
  Object.keys(sortingEntries).map((key) => ({
    label: sortingEntries[key as SortValue].label,
    value: key as SortValue,
  }));

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'sorting' implicitly has an 'any' ... Remove this comment to see the full error message
export const getSortingComponent = ({ sorting, objectives, objectiveMapper }) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  React.createElement(sortingEntries[sorting].component, {
    mapObjectives: objectiveMapper || mapObjectivesDefault,

    objectives,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    order: sortingEntries[sorting].order,
  });

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'sorting' implicitly has an 'any' type.
export const getSortingQueryParam = (sorting) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  sortingEntries[sorting].queryParam;
