export type Stage = "ACTIVE" | "CLOSED" | "DRAFT" | "";
export type ResultFilter = ResultType | "";
export type SortValue =
  | "timeframeAsc"
  | "timeframeDesc"
  | "progressAsc"
  | "progressDesc";

export const objectiveStages = {
  active: "ACTIVE",
  closed: "CLOSED",
  draft: "DRAFT",
} as const;

export const SORTING_VALUES: SMap<SortValue> = {
  PROGRESS_ASC: "progressAsc",
  PROGRESS_DESC: "progressDesc",
  TIMEFRAME_ASC: "timeframeAsc",
  TIMEFRAME_DESC: "timeframeDesc",
};

export const objectiveAlignToFields = {
  kpi: "kpi",
  parent: "parent",
  strategicPillar: "strategicPillar",
};

export const noAlignment = "none";

export type View = "folded" | "unfolded";
