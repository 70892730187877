import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, ButtonProps } from "common/buttons/Button/Button";

type Props = ButtonProps;

export const CancelButton = ({
  onClick,
  children,
  ...rest
}: Props): JSX.Element => (
  <Button data-cy="cancel" onClick={onClick} variant="ghost" {...rest}>
    {children ?? (
      <FormattedMessage defaultMessage="Cancel" id="global:cancel" />
    )}
  </Button>
);
