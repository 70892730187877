// this util is not included officially and tends to be moved around.
// if it breaks after upgrade, check https://github.com/vercel/next.js/discussions/22025
import { resolveHref } from "next/dist/client/resolve-href";
import { useRouter } from "next/router";

export type UseModalRouterHook = ReturnType<typeof useSalesModals>;

export const useSalesModals = () => {
  const router = useRouter();
  const { pathname } = window.location;

  const close = () => router.push(pathname, undefined, { shallow: true });

  const chatSalesHref = () =>
    resolveHref(router, { pathname, query: { chatSales: true } });

  const upgradeNowHref = () =>
    resolveHref(router, { pathname, query: { upgradeNow: true } });

  const openChatSales = () => router.push(chatSalesHref());

  const openUpgradeNow = () => router.push(upgradeNowHref());

  return {
    chatSalesHref,
    close,
    openChatSales,
    openUpgradeNow,
    upgradeNowHref,
  };
};
