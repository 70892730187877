import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { useResultContext } from "common/context/resultContext";
import { Show } from "common/controlFlow";
import { SubInitiatives } from "common/result/ResultList/ResultsWidget/SortableResultItem/SortableResultItem";
import { mapEdges } from "utils/mapEdges";

export const SupportingInitiatives = () => {
  const result = useResultContext();
  const initiatives = mapEdges(result.children.edges);

  return (
    <div className="my-5">
      <Show
        when={!isEmpty(initiatives)}
        fallback={
          <div className="text-slate-500">
            <FormattedMessage
              defaultMessage="No supporting initiatives"
              id="fgcWFv"
            />
          </div>
        }
      >
        <SubInitiatives result={result} showEdges={false} />
      </Show>
    </div>
  );
};
