import { useState } from "react";
import { useIntl } from "react-intl";
import { EventTypes } from "common/event/constants";
// TODO: other components still use the injectTimelineFilter HOC. once refactored, move the messages here.
import {
  timelineContextMessages,
  TimelineFilter,
} from "common/event/TimelineContext/TimelineContext";

const GOAL_TIMELINE_FILTER_OPTIONS = [
  {
    rawName: timelineContextMessages.everything,
  },
  {
    rawName: timelineContextMessages.updateAndNotes,
    value: `${EventTypes.CommentCreated},${EventTypes.CommitCreated}`,
  },
  {
    rawName: timelineContextMessages.updatesOnly,
    value: EventTypes.CommitCreated,
  },
  {
    rawName: timelineContextMessages.notesOnly,
    value: EventTypes.CommentCreated,
  },
];

type Hook = {
  filterOptions: TimelineFilter[];
  setTimelineFilter: (filter: TimelineFilter) => void;
  timelineFilter: TimelineFilter;
};

/**
 * Hook to get the filter options for the goal timeline. This is used on user page and goal modals.
 */
export const useGoalTimelineFilter = (): Hook => {
  const intl = useIntl();

  const filterOptions = GOAL_TIMELINE_FILTER_OPTIONS.map((option, index) => ({
    id: index + 1,
    name: intl.formatMessage(option.rawName),
    ...(option.value && { value: option.value }),
  }));

  const [timelineFilter, setTimelineFilter] = useState(filterOptions[0]);

  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value?: string | undefined; id: number; na... Remove this comment to see the full error message
  return { filterOptions, setTimelineFilter, timelineFilter };
};
