import classNames from "clsx";
import React from "react";
import { Icon } from "common/icons";

type Props = {
  max?: number;
  size?: "sm" | "lg";
  value: number;
};

export const Rating = ({ value, max = 5, size = "lg" }: Props): JSX.Element => (
  <div className="inline-block" data-testid="rating">
    <div className="flex items-center space-x-2 rounded py-2 pl-2 pr-3">
      <Icon
        className="text-yellow-500"
        name="star"
        size={size === "lg" ? "3xl" : "lg"}
      />
      <div
        className={classNames({
          "text-sm": size === "sm",
          "text-xl": size === "lg",
        })}
      >
        <span className="font-semibold">{value}</span>/{max}
      </div>
    </div>
  </div>
);
