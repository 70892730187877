type Props = {
  children: JSX.Element;
  condition: boolean;
  left: (children: JSX.Element) => JSX.Element;
  right: (children: JSX.Element) => JSX.Element;
};

export const BranchConditionalWrapper = ({
  condition,
  left,
  right,
  children,
}: Props): JSX.Element => (condition ? left(children) : right(children));
