import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon, IconBadge } from "common/icons";
import { twClass } from "utils/twClass";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const OneOnOneCompletePrivateNoteWrapper = ({
  children,
  className,
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <div
      className={twClass("w-full space-y-2 px-9", className)}
      data-testid="privateNotes"
    >
      <span className="flex items-center space-x-1 text-base font-medium text-slate-800">
        <span>
          <FormattedMessage defaultMessage="Private Notes" id="WYhT9s" />
        </span>
        <IconBadge
          size="small"
          tooltip={intl.$t({
            defaultMessage: "Visible only to you",
            id: "vPZf//",
          })}
        >
          <Icon name="lock" type="outlined" />
        </IconBadge>
      </span>
      {children}
    </div>
  );
};
