import { OneOnOneRepeatFrequency, useGetOneOnOneSeriesQuery } from "@graphql";
import dayjs from "dayjs";
import { Formik, FormikHelpers } from "formik";
import { isNil } from "lodash";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { useConfirmationModal } from "common/overlay/ConfirmationModal/hooks/useConfirmationModal";
import { Modal } from "common/overlay/Modal/Modal";
import {
  EDIT_ONE_ON_ONE_MEETING,
  GCAL_ONE_ON_ONE_EVENT_CREATED,
  GCAL_ONE_ON_ONE_EVENT_LINKED,
  GCAL_ONE_ON_ONE_EVENT_UNLINKED,
} from "constants/tracking";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useGoogleCalendar } from "hooks/useGoogleCalendar/useGoogleCalendar";
import { useModalRouter } from "hooks/useModalRouter/useModalRouter";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { useHandleOneOnOneMutations } from "../hooks/useHandleOneOnOneMutations";
import { formatOneOnOneMeetingDueDate } from "../oneOnOnemeeting.utils";
import {
  EditOneOnOneMeetingForm,
  EditOneOnOneMeetingFormValues,
} from "../OneOnOneMeetingForm/EditOneOnOneMeetingForm";

const validationSchema = Yup.object().shape({
  dueDate: Yup.date()
    .nullable()
    .when(["isActive", "googleCalendarEventAction"], {
      is: (isActive: boolean, googleCalendarEventAction: string | null) =>
        isActive && googleCalendarEventAction !== "LINK_EXISTING_EVENT",
      otherwise: (schema) => schema,
      then: (schema) =>
        schema
          .required("Required")
          .typeError("Required")
          .min(dayjs().add(-1, "day"), "Meeting date can't be in the past"),
    })
    .typeError("Meeting date must be set"),
  googleCalendarEvent: Yup.string()
    .nullable()
    .when("googleCalendarEventAction", {
      is: "LINK_EXISTING_EVENT",
      otherwise: (schema) => schema,
      then: (schema) => schema.required("Required"),
    }),
  googleCalendarEventAction: Yup.string().nullable(),
  isActive: Yup.bool(),
  meetingTime: Yup.string()
    .nullable()
    .when(["isActive", "googleCalendarEventAction"], {
      is: (isActive: boolean, googleCalendarEventAction: string | null) =>
        isActive && googleCalendarEventAction !== "LINK_EXISTING_EVENT",
      otherwise: (schema) => schema,
      then: (schema) => schema.required("Required"),
    }),
  repeatFrequency: Yup.string()
    .nullable()
    .when("googleCalendarEventAction", {
      is: "LINK_EXISTING_EVENT",
      otherwise: (schema) =>
        schema
          .oneOf(Object.values(OneOnOneRepeatFrequency))
          .required("Please select a valid repeat frequency."),
      then: (schema) => schema,
    }),
});

type Props = {
  seriesId: string;
};

export const EditOneOnOneMeetingModal = ({
  seriesId,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const { timezone } = useCompany();
  const { query } = useRouter();
  const { close } = useModalRouter();
  const { openModal: openConfirmationModal } = useConfirmationModal();

  const { integration: gcalIntegration } = useGoogleCalendar();

  const me = useCurrentUser();
  const { upsertOneOnOneSeries } = useHandleOneOnOneMutations();
  const { data } = useGetOneOnOneSeriesQuery({
    variables: {
      id: seriesId,
    },
  });

  if (!data?.oneOnOne) return null;

  const {
    id,
    description,
    nextMeeting,
    repeatFrequency,
    isActive,
    organizer,
    attendee,
    gCalIntegration,
    recurringEventId,
  } = data.oneOnOne;

  const isOrganizer = organizer.id === me?.id;

  const initialValues = (): EditOneOnOneMeetingFormValues => {
    const dueDate = nextMeeting?.dueDate ?? dayjs().toISOString();
    const meetingTime =
      recurringEventId && gCalIntegration
        ? null
        : dayjs(dueDate).tz(timezone).format("HH:mm:ss");
    const initial: EditOneOnOneMeetingFormValues = {
      attendee: attendee.id,
      description,
      dueDate,
      googleCalendarEvent: recurringEventId,
      googleCalendarEventAction:
        recurringEventId && gCalIntegration ? "LINK_EXISTING_EVENT" : null,
      id,
      isActive,
      meetingTime,
      repeatFrequency:
        recurringEventId && gCalIntegration
          ? OneOnOneRepeatFrequency.Integration
          : repeatFrequency,
    };
    return initial;
  };

  const editOneOnOne = async (
    values: EditOneOnOneMeetingFormValues,
    actions: FormikHelpers<EditOneOnOneMeetingFormValues>
  ) => {
    const {
      dueDate,
      meetingTime,
      googleCalendarEvent,
      googleCalendarEventAction,
      repeatFrequency,
      ...seriesInput
    } = values;

    const nextMeetingDueDate = isNil(meetingTime)
      ? dueDate
      : formatOneOnOneMeetingDueDate(dueDate, meetingTime, timezone);
    const { hasError } = await upsertOneOnOneSeries({
      ...seriesInput,
      gCalIntegration: googleCalendarEventAction ? gcalIntegration?.id : null,
      nextMeetingDueDate,
      recurringEventId: googleCalendarEvent,
      repeatFrequency: googleCalendarEvent
        ? OneOnOneRepeatFrequency.Integration
        : repeatFrequency,
    });
    actions.setSubmitting(false);
    if (hasError) return;

    if (!recurringEventId) {
      if (googleCalendarEventAction === "CREATE_EVENT") {
        track(GCAL_ONE_ON_ONE_EVENT_CREATED);
      }
      if (googleCalendarEventAction === "LINK_EXISTING_EVENT") {
        track(GCAL_ONE_ON_ONE_EVENT_LINKED);
      }
    } else if (!googleCalendarEvent) {
      track(GCAL_ONE_ON_ONE_EVENT_UNLINKED);
    }

    toast.success(
      intl.formatMessage({
        defaultMessage: "1:1 meeting updated",
        id: "LK+9i9",
      })
    );

    track(EDIT_ONE_ON_ONE_MEETING, {
      repeat_frequency: values.repeatFrequency,
      scheduled_date: values.dueDate,
    });

    close();
  };

  const handleSubmit = (
    values: EditOneOnOneMeetingFormValues,
    actions: FormikHelpers<EditOneOnOneMeetingFormValues>
  ) => {
    if (!values.googleCalendarEvent && !!recurringEventId) {
      return openConfirmationModal({
        action: () => editOneOnOne(values, actions),
        children: (
          <FormattedMessage
            defaultMessage="Are you sure you want to unlink this 1:1 meeting from Google Calendar? The date and time will no longer be automatically updated."
            id="r3buJ4"
          />
        ),
        close: () => actions.setSubmitting(false),
        confirmText: <FormattedMessage defaultMessage="Confirm" id="N2IrpM" />,
        title: (
          <FormattedMessage
            defaultMessage="Calendar synchronization"
            id="L/TgBi"
          />
        ),
      });
    }

    return editOneOnOne(values, actions);
  };

  const stack = query.editSeries ?? false;

  return (
    <Modal
      backdropClass={twClass({ "!z-modal-prio-backdrop": stack })}
      backdropClose={false}
      className={twClass({ "!z-modal-prio": stack })}
      isOpen
      loading={!data}
      onClose={close}
      size="sm"
      title={intl.formatMessage({
        defaultMessage: "Edit 1:1 meeting",
        id: "Mslfqt",
      })}
    >
      <Formik<EditOneOnOneMeetingFormValues>
        initialValues={initialValues()}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        <EditOneOnOneMeetingForm
          attendee={isOrganizer ? attendee : organizer}
          onCancel={close}
          oneOnOne={data.oneOnOne}
        />
      </Formik>
    </Modal>
  );
};
