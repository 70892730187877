import {
  AsanaProject,
  MakeOptional,
  useGetAsanaProjectForSelectLazyQuery,
  useGetAsanaProjectsForSelectLazyQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useField } from "formik";
import { isNil } from "lodash";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Props as ReactSelectProps } from "react-select";
import { AsanaProjectIcon } from "common/asana/AsanaProjectIcon/AsanaProjectIcon";
import { AllowedFieldProps } from "common/form/types";
import { useAsanaFormContext } from "common/integration/AsanaFormLogic/AsanaFormLogic";
import { Select } from "legacy/components/Select/Select";
import { messages } from "./AsanaProjectField.messages";

type Project = Derive<
  AsanaProject,
  {
    color: true;
    gid: true;
    name: true;
  }
>;
export type AsanaProjectFieldProject = Project;

type Props = ReactSelectProps & MakeOptional<AllowedFieldProps, "name">;

export const AsanaProjectField = ({
  name = "asanaProject",
  "data-cy": dataCy = "asanaProject",
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);

  const { project, workspace, onProjectSelected } = useAsanaFormContext();

  const [searchAsana, { loading }] = useGetAsanaProjectsForSelectLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getAsanaProject, { loading: lazyLoading }] =
    useGetAsanaProjectForSelectLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  const loadOptions = async (query: string) => {
    if (isNil(workspace)) return [];
    const result = await searchAsana({
      variables: { query, workspaceGid: workspace.gid },
    });

    return result.data?.asanaProjects;
  };

  const formatOptionLabel = (project: Project) => {
    return (
      <div className="flex items-center space-x-2">
        <AsanaProjectIcon color={project.color} />
        <div>{project.name}</div>
      </div>
    );
  };

  const fetchOptionFromServer = async (gid: string | undefined) => {
    if (isNil(gid)) {
      onProjectSelected?.(null);
      return;
    }

    const result = await getAsanaProject({ variables: { gid } });
    const project = result.data?.asanaProject;
    if (isNil(project)) {
      // eslint-disable-next-line no-console
      console.warn(
        "Couldn't set Asana project because the server didn't find it"
      );
      return;
    }

    onProjectSelected?.(project);
  };

  useEffect(() => {
    fetchOptionFromServer(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const forwardOnChange = (project: Project | null) => {
    helpers.setValue(project?.gid);
  };

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      data-cy={dataCy}
      async
      disabled={isNil(workspace)}
      errorText={meta.error}
      formatOptionLabel={formatOptionLabel}
      isClearable
      isInForm
      isLoading={loading || lazyLoading}
      label={intl.formatMessage(messages.asanaProject)}
      loadOptions={loadOptions}
      noOptionsMessage={({ inputValue }) =>
        inputValue
          ? intl.formatMessage(messages.noResults)
          : intl.formatMessage(messages.searchProjects)
      }
      onChange={forwardOnChange}
      placeholder={intl.formatMessage(messages.search)}
      value={project ?? null}
    />
  );
};
