import map from "lodash/map";
import React from "react";
import styled from "styled-components";
import { Button } from "common/buttons";
import { colorTheme } from "constants/colorTheme";
import { Modal } from "../Modal/Modal";
import { ModalFooter } from "../Modal/ModalFooter/ModalFooter";

const Row = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: ${colorTheme.slate[50]};
  }

  &:not(:first-child) {
    border-top: 1px solid ${colorTheme.slate[200]};
  }
`;

export const ItemsListModal = ({
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
  title,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'open' implicitly has an 'any' typ... Remove this comment to see the full error message
  open,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onRequestClosed' implicitly has a... Remove this comment to see the full error message
  onRequestClosed,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'items' implicitly has an 'any' ty... Remove this comment to see the full error message
  items,
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'renderItem' implicitly has an 'an... Remove this comment to see the full error message
  renderItem,
}): JSX.Element => {
  return (
    <Modal isOpen={open} onClose={onRequestClosed} size="sm" title={title}>
      {map(items, (item) => (
        <Row
          data-cy="HFvPs3znu9d3nEG_tHcoC"
          key={item.id}
          onClick={onRequestClosed}
        >
          {renderItem(item)}
        </Row>
      ))}
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; onSubmit: any; }' is no... Remove this comment to see the full error message */}
      <ModalFooter onSubmit={onRequestClosed}>
        <Button
          data-cy="wkHGnVnQKFt6LsUvyA-Pe"
          key={0}
          onClick={onRequestClosed}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
