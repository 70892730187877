import { isNil } from "lodash";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";

const formatDate = (date: string | null | undefined) => {
  if (isNil(date)) return null;
  return moment(date).format("MMM D, YYYY").toString();
};

export type Timeframe = {
  endDate?: string | null;
  id: string;
  name: string;
  objectives?: {
    totalCount: number;
  } | null;
  startDate?: string | null;
};

type Props = {
  showObjectiveCount?: boolean;
  timeframes: Timeframe[];
  title: string;
};

export const TimeframeTable = ({
  timeframes,
  title,
  showObjectiveCount = false,
}: Props): JSX.Element => {
  return (
    <div className="space-y-2">
      <div className="text-sm">{title}</div>
      <div className="rounded border border-slate-300">
        <table className="w-full border-collapse divide-y divide-slate-300 overflow-hidden rounded">
          <thead>
            <tr className="text-slate-500 bg-slate-50">
              <th className="px-2 py-1 text-left font-normal">
                <FormattedMessage
                  defaultMessage="Name"
                  id="timeframeTable:name"
                />
              </th>
              <th className="px-2 py-1 text-left font-normal">
                <FormattedMessage
                  defaultMessage="Start"
                  id="timeframeTable:start"
                />
              </th>
              <th className="px-2 py-1 text-left font-normal">
                <FormattedMessage
                  defaultMessage="End"
                  id="timeframeTable:end"
                />
              </th>
              {showObjectiveCount && (
                <th className="px-2 py-1 text-left font-normal">
                  <FormattedMessage
                    defaultMessage="Objectives"
                    id="objective:list:objectives"
                  />
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-slate-300">
            {timeframes.map((timeframe) => (
              <tr key={timeframe.id}>
                <td className="p-2">{timeframe.name}</td>
                <td className="p-2">{formatDate(timeframe.startDate)}</td>
                <td className="p-2">{formatDate(timeframe.endDate)}</td>
                {showObjectiveCount && (
                  <td className="p-2">
                    {timeframe.objectives?.totalCount ?? 0}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
