import { Objective } from "@graphql";
import { Derive } from "@shoooe/derive";
import { Field, FieldProps, Form, Formik } from "formik";
import { omit } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { CancelButton, SaveButton } from "common/buttons";
import { Show } from "common/controlFlow";
import { FormKpiSelect } from "common/form/FormKpiSelect/FormKpiSelect";
import { messages as alignMessages } from "common/form/FormObjectiveAlignToSelect/FormObjectiveAlignToSelect.messages";
import { FormObjectiveParentSelect } from "common/form/FormObjectiveParentSelect/FormObjectiveParentSelect";
import { FormStrategicPillarSelect } from "common/form/FormStrategicPillarSelect/FormStrategicPillarSelect";
import { RadioSelectField } from "common/form/RadioSelectField/RadioSelectField";
import { Icon } from "common/icons";
import { useInlineObjectiveActions } from "common/objective/hooks/useInlineObjectiveActions/useInlineObjectiveActions";
import { Modal } from "common/overlay/Modal/Modal";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { useCompanySettings } from "hooks/useCompany/useCompany";

type ObjectiveProp = Derive<
  Objective,
  {
    goalUpdateCycle: true;
    id: true;
    kpi: {
      id: true;
    };
    lead: { id: true };
    parent: {
      id: true;
    };
    private: true;
    progressDriver: true;
    stage: true;
    strategicPillar: {
      id: true;
    };
    timeframe: {
      id: true;
    };
  }
>;

type Props = {
  objective: ObjectiveProp;
  onClose?: () => void;
};
export type ObjectiveAlignmentModalProps = Props;

const alignmentTypes = {
  kpi: "KPI",
  noAlignment: "NO_ALIGNMENT",
  objective: "OBJECTIVE",
  strategicPillar: "STRATEGIC_PILLAR",
} as const;

type AlignmentType = ValueOf<typeof alignmentTypes>;

type Values = {
  kpi?: string;
  parent?: string;
  strategicPillar?: string;
  type: AlignmentType;
};

const schema = Yup.object().shape({
  kpi: Yup.string()
    .nullable()
    .when("type", {
      is: alignmentTypes.kpi,
      then: (s) => s.required("Required"),
    }),
  parent: Yup.string()
    .nullable()
    .when("type", {
      is: alignmentTypes.objective,
      then: (s) => s.required("Required"),
    }),
  strategicPillar: Yup.string()
    .nullable()
    .when("type", {
      is: alignmentTypes.strategicPillar,
      then: (s) => s.required("Required"),
    }),
  type: Yup.string().required().oneOf(Object.values(alignmentTypes)),
});

const getInitialValues = (objective: ObjectiveProp): Values => {
  if (objective.parent)
    return { parent: objective.parent.id, type: "OBJECTIVE" };
  if (objective.strategicPillar)
    return {
      strategicPillar: objective.strategicPillar.id,
      type: "STRATEGIC_PILLAR",
    };
  if (objective.kpi) return { kpi: objective.kpi.id, type: "KPI" };
  return { type: "NO_ALIGNMENT" };
};

export const ObjectiveAlignmentModal = ({
  objective,
  onClose,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { setAlignment } = useInlineObjectiveActions(objective);
  const { pillarsEnabled } = useCompanySettings();
  const initialValues = getInitialValues(objective);

  const onSubmit = async (values: Values) => {
    const success = await setAlignment(omit(values, "type"));
    if (success) {
      onClose?.();
    }
  };

  const onCancel = () => {
    onClose?.();
  };

  return (
    <Modal
      backdropClass="!z-modal-top-prio-backdrop"
      className="!z-modal-top-prio"
      id="modal-confirm"
      isOpen
      onClose={onClose}
      scrollable={false}
      size="sm"
      title={
        <FormattedMessage defaultMessage="Align this Objective" id="m/DRGl" />
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={schema}
      >
        {({ values, submitForm, setFieldValue }) => {
          const resetData = async (type: AlignmentType) => {
            if (type !== alignmentTypes.kpi) {
              await setFieldValue("kpi", null);
            }
            if (type !== alignmentTypes.strategicPillar) {
              await setFieldValue("strategicPillar", null);
            }
            if (type !== alignmentTypes.objective) {
              await setFieldValue("parent", null);
            }
          };

          return (
            <Form>
              <div className="p-6 space-y-2">
                <div>
                  <FormattedMessage
                    defaultMessage="What will this Objective do?"
                    id="BM9Rs+"
                  />
                </div>
                <RadioSelectField
                  name="type"
                  selectValue={alignmentTypes.noAlignment}
                  addOn={
                    <Icon
                      size="2xl"
                      name="close"
                      type="outlined"
                      className="text-red-500"
                    />
                  }
                  onClick={() => {
                    resetData(alignmentTypes.noAlignment);
                  }}
                  data-cy="noAlignment"
                >
                  <div className="space-y-1">
                    <div className="">
                      <FormattedMessage
                        defaultMessage="Not aligned"
                        id="iB8ggt"
                      />
                    </div>
                    <div className="text-sm">
                      <FormattedMessage
                        defaultMessage="Choose to not align this OKR. You can add alignment later."
                        id="xNzk5P"
                      />
                    </div>
                  </div>
                </RadioSelectField>
                <Show
                  when={
                    pillarsEnabled ||
                    initialValues.type === alignmentTypes.strategicPillar
                  }
                >
                  <RadioSelectField
                    name="type"
                    selectValue={alignmentTypes.strategicPillar}
                    addOn={
                      <Icon
                        size="2xl"
                        name="account_balance"
                        className="text-green-500"
                      />
                    }
                    onClick={() => {
                      resetData(alignmentTypes.strategicPillar);
                    }}
                    data-cy="alignStrategicPillar"
                  >
                    <div className="space-y-1">
                      <div className="">
                        <FormattedMessage
                          defaultMessage="Support a Strategic Pillar"
                          id="4xuOTh"
                        />
                      </div>
                      <div className="text-sm">
                        <FormattedMessage
                          defaultMessage="Align this OKR to company strategy, ideal for long-term OKRs."
                          id="Fpe4WW"
                        />
                      </div>
                      <Show
                        when={values.type === alignmentTypes.strategicPillar}
                      >
                        <Field name="strategicPillar">
                          {({ field, form }: FieldProps) => (
                            <FormStrategicPillarSelect
                              data-cy="strategicPillar"
                              field={field}
                              form={form}
                              isGql
                              placeholder={intl.formatMessage(
                                alignMessages.pillarAlignmentPlaceholder
                              )}
                            />
                          )}
                        </Field>
                      </Show>
                    </div>
                  </RadioSelectField>
                </Show>
                <RadioSelectField
                  name="type"
                  selectValue={alignmentTypes.objective}
                  addOn={<Icon size="2xl" name="flag" type="outlined" />}
                  onClick={() => {
                    resetData(alignmentTypes.objective);
                  }}
                  data-cy="alignObjective"
                >
                  <div className="space-y-1">
                    <div className="">
                      <FormattedMessage
                        defaultMessage="Support another OKR"
                        id="8lwwj5"
                      />
                    </div>
                    <div className="text-sm">
                      <FormattedMessage
                        defaultMessage="Align to another Objective, e.g quarterly OKR supports annual OKR."
                        id="TAUokz"
                      />
                    </div>
                    <Show when={values.type === alignmentTypes.objective}>
                      <Field name="parent">
                        {({ field, form }: FieldProps) => (
                          <FormObjectiveParentSelect
                            autoFocus
                            data-cy="objective"
                            field={field}
                            form={form}
                            objectiveId={objective.id}
                            placeholder={intl.formatMessage(
                              alignMessages.objAlignmentPlaceholder
                            )}
                          />
                        )}
                      </Field>
                    </Show>
                  </div>
                </RadioSelectField>
                <RadioSelectField
                  name="type"
                  selectValue={alignmentTypes.kpi}
                  addOn={
                    <Icon
                      size="2xl"
                      name="monitor_heart"
                      type="outlined"
                      className="text-indigo-500"
                    />
                  }
                  onClick={() => {
                    resetData(alignmentTypes.kpi);
                  }}
                  data-cy="alignKpi"
                >
                  <div className="space-y-1">
                    <div className="">
                      <FormattedMessage
                        defaultMessage="Improve a KPI"
                        id="2Pwvmi"
                      />
                    </div>
                    <div className="text-sm">
                      <FormattedMessage
                        defaultMessage="Align this OKR to a KPI to fix the KPI or take it to the next level."
                        id="iQ6RLn"
                      />
                    </div>
                    <Show when={values.type === alignmentTypes.kpi}>
                      <Field name="kpi">
                        {({ field, form }: FieldProps) => (
                          <FormKpiSelect
                            data-cy="kpi"
                            field={field}
                            form={form}
                            isGql
                          />
                        )}
                      </Field>
                    </Show>
                  </div>
                </RadioSelectField>
              </div>
              <ModalFooter showDivider>
                <SaveButton onClick={submitForm} />
                <CancelButton onClick={onCancel} />
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
