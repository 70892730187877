import { isEmpty } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { ResultFormValues } from "../ResultForm.types";
import { getResultFormWarnings } from "./ResultFormWarnings.utils";

type Props = { values: ResultFormValues };

export const ResultFormWarnings = ({ values }: Props): JSX.Element | null => {
  const user = useCurrentUser();
  const warnings = getResultFormWarnings(values, user?.language ?? "");

  if (isEmpty(warnings)) return null;

  return (
    <>
      {warnings.map((warning) => (
        <div key={warning.id} className="flex w-full text-sm text-red-500">
          <Icon className="mr-2" name="error_outline" />
          <div>
            {warning.element}
            <div>
              <Anchor
                href={warning.link}
                rel="noopener noreferrer"
                target="_blank"
                text={
                  <FormattedMessage
                    defaultMessage="Learn more."
                    id="result:form:percentage:learn:more"
                  />
                }
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
