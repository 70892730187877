import { Menu } from "@headlessui/react";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "common/buttons";
import { EntityIcon } from "common/icons";
import { useHelpCenter } from "common/layout/HelpCenter/hooks/useHelpCenter";
import { useHelpCenterContent } from "common/layout/HelpCenter/hooks/useHelpCenterContent";
import { ResultTypesInfoView } from "common/layout/HelpCenter/ResultTypesInfoView/ResultTypesInfoView";
import { Dropdown } from "common/overlay/Dropdown";
import { MODAL_TYPES } from "constants/index";
import { resultTypes } from "constants/resultTypes";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { showModal } from "legacy/actions/actions";
import { DropdownContext } from "legacy/components/Dropdown/DropdownContext";

interface Props {
  disabled?: boolean;
  objectiveId: string;
}

export const AddResultDropdown = ({
  objectiveId,
  disabled,
}: Props): JSX.Element => {
  const dropdownRef = useRef<(HTMLDivElement & DropdownContext) | null>(null);
  const [keepOpen, setKeepOpen] = useState(false);
  const { toggleHelpCenter } = useHelpCenter();
  useHelpCenterContent({ element: <ResultTypesInfoView />, type: "results" });

  useOutsideAlerter(dropdownRef, () => {
    if (keepOpen) setKeepOpen(false);
  });

  const dispatch = useDispatch();

  const addResult = (type: string) => {
    dispatch(
      showModal(MODAL_TYPES.ADD_RESULT, {
        filters: { parentIsnull: true },
        objectiveId,
        type,
      })
    );
  };

  return (
    <Menu
      ref={dropdownRef}
      data-cy="jr-6izolKsk0SbEAn6_xQ"
      as="div"
      className="relative"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <Dropdown.Trigger>
        <Button
          onClick={(event) => {
            event.stopPropagation();
          }}
          data-cy="addResult"
          disabled={disabled}
          icon="add"
          iconSize="base"
        >
          <FormattedMessage defaultMessage="Add" id="2/2yg+" />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content static={keepOpen} placement="bottom-right">
        <Dropdown.Item
          key="addKeyResult"
          data-cy="add-new-KeyResult"
          onClick={() => addResult(resultTypes.keyResult)}
        >
          <div className="flex space-x-2">
            <EntityIcon entity={resultTypes.keyResult} />
            <div>
              <FormattedMessage defaultMessage="Key Result" id="b+726N" />
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          key="addInitiative"
          data-cy="add-new-Initiative"
          onClick={() => addResult(resultTypes.initiative)}
        >
          <div className="flex space-x-2">
            <EntityIcon entity={resultTypes.initiative} />
            <div>
              <FormattedMessage defaultMessage="Initiative" id="jpmEhT" />
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          className="border-t"
          key="openHelpCenter"
          data-cy="openHelpCenter"
          onClick={toggleHelpCenter}
        >
          <FormattedMessage
            defaultMessage="What’s the difference?"
            id="GqKQOP"
          />
        </Dropdown.Item>
      </Dropdown.Content>
    </Menu>
  );
};
