import data, { EmojiMartData } from "@emoji-mart/data";
import { isObject } from "lodash";

// library types aren't up-to-date, so we are using a custom type-guard.
// see https://github.com/missive/emoji-mart/issues/576
const hasEmoji = (
  data: unknown | EmojiMartData,
  emojiId: string
): data is EmojiMartData => {
  const isEmojiData = isObject(data) && "emojis" in data;

  return isEmojiData && isObject(data.emojis) && emojiId in data.emojis;
};

export const getNativeEmoji = (emojiId: string): string | undefined => {
  if (!hasEmoji(data, emojiId)) return undefined;
  const emoji = data.emojis[emojiId];

  return emoji?.skins[0]?.native;
};
