import { namedOperations, useDeleteKudoMutation } from "@graphql";
import { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "utils/toastr";
import { showGqlErrorToast } from "utils/utils";

type Hook = {
  closeModal: () => void;
  deleteKudo: (id: string) => Promise<void>;
  loading: boolean;
  modalOpen: boolean;
  openModal: () => void;
};

export const useDeleteKudo = (): Hook => {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteKudoMutation, { loading }] = useDeleteKudoMutation({
    refetchQueries: [namedOperations.Query.getKudos],
  });

  const deleteKudo = async (id: string) => {
    const response = await deleteKudoMutation({
      variables: {
        input: {
          id,
        },
      },
    });

    if (response.errors) {
      showGqlErrorToast(response.errors);
    } else {
      toast.success(
        intl.formatMessage({
          defaultMessage: "Kudos deleted!",
          id: "deleteKudo:successMessage",
        })
      );
      setModalOpen(false);
    }
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return {
    closeModal,
    deleteKudo,
    loading,
    modalOpen,
    openModal,
  };
};
